import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faEdit,
  faEye,
  faPen,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getEntidades } from "../../api/requests/Entidades";
import {
  enviarEmailStatusPedido,
  getPedidoId,
  getPedidosAll,
  putPedido,
} from "../../api/requests/Pedidos";
import { deletePiscina, postStatusPiscina } from "../../api/requests/Piscinas";
import {
  deleteUtilizador,
  postStatusUtilizadores,
} from "../../api/requests/Utilizadores";
import Search5 from "../../components/DataTable/Search5";
import { listaCancelamentos } from "../../api/requests/PiscinaAgua";
import moment from "moment";
import Nav from "../../components/Nav";

function Cancelamentos() {
  const [cancelamentos, setCancelamentos] = useState([]);
  const [cancelamentosCopy, setCancelamentosCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [freeze, setFreeze] = useState(false);
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }
  //Traduções
  const { t, i18n } = useTranslation();

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "hora_inicio", "hora_fim"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "hora_inicio", "hora_fim"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCancelamentos({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_cancelamento,
              obs: element.obs,
              data: moment(element.data).format("DD-MM-YYYY"),
              hora_inicio: moment(element.hora_inicio, "HH:mm").format("HH:mm"),
              hora_fim: moment(element.hora_fim, "HH:mm").format("HH:mm"),
            };
            arrayFinal.push(linha);
            setCancelamentos(arrayFinal);
            setCancelamentosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    if (search) {
      let arrayCancelamentos = cancelamentosCopy;
      arrayCancelamentos = arrayCancelamentos.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.tipo.toLowerCase().includes(search.toLowerCase()) ||
          element.acao.toLowerCase().includes(search.toLowerCase())
      );
      setCancelamentos(arrayCancelamentos);
    } else {
      setCancelamentos(cancelamentosCopy);
    }
  }, [search]);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}
        <h3 className="titleList">{t("cancelamentos")}</h3>
        <div className="divTabelaM">
          {/* <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/dashboard_admin");
              }}
              size={window.innerWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}

          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Search5
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 100,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              { field: "obs", headerName: t("obs"), type: "string", flex: 1 },
              {
                field: "data",
                headerName: t("data"),
                flex: 1,
                type: "date",
              },
              {
                field: "hora_inicio",
                headerName: t("horainicio"),
                flex: 1,
                type: "date",
                hide: columnsHidden.includes("hora_inicio"),
              },
              {
                field: "hora_fim",
                headerName: t("horafim"),
                flex: 1,
                type: "date",
                hide: columnsHidden.includes("hora_fim"),
              },
            ]}
            rows={cancelamentos}
            checkboxSelection={isMobile ? false : true}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cancelamentos;
