import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  enviarEmailPostPiscina,
  postPiscinas,
  postPiscinasIdEntidadeLegal,
  verificarNifEntidade,
} from "../../api/requests/Piscinas";
import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";

function CriarPiscina() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  //Passos do Stepper
  const steps = [
    t("entidadeProprietariaAdmin"),
    t("entidadesExploradoras"),
    t("infoPiscina"),
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  //Variáveis
  const [edicao, setEdicao] = useState(false);
  const [edicaoEntidade, setEdicaoEntidade] = useState(false);
  const [fase1, setFase1] = useState(true);
  const [termos, setTermos] = useState(false);
  const idUtilizador = Cookies.get("uId");
  // Entidade
  const [idEntidade, setIdEntidade] = useState(0);
  const [nomeEntidade, setNomeEntidade] = useState("");
  const [nifEntidade, setNifEntidade] = useState("");
  const [emailEntidade, setEmailEntidade] = useState("");
  const [telefoneEntidade, setTelefoneEntidade] = useState("");
  const [ruaEntidade, setRuaEntidade] = useState("");
  const [codigoPostalEntidade, setCodigoPostalEntidade] = useState("");
  const [cidadeEntidade, setCidadeEntidade] = useState("");
  const [distritoEntidade, setDistritoEntidade] = useState("");
  const [paisEntidade, setPaisEntidade] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [entidadesExploramPiscina, setEntidadesExploramPiscina] = useState("");
  const [entidadesExploramPiscinaArray, setEntidadesExploramPiscinaArray] =
    useState([]);
  const [funcionariosAcessoGestao, setFuncionariosAcessoGestao] =
    useState(false);
  const [numFuncionariosAcesso, setNumFuncionariosAcesso] = useState("");
  const [unicaEntidade, setUnicaEntidade] = useState("");
  //Piscina
  const [nomePiscina, setNomePiscina] = useState("");
  const [nifPiscina, setNifPiscina] = useState("");
  const [emailPiscina, setEmailPiscina] = useState("");
  const [telefonePiscina, setTelefonePiscina] = useState("");
  const [telefone2Piscina, setTelefone2Piscina] = useState("");
  const [ruaPiscina, setRuaPiscina] = useState("");
  const [codigoPostalPiscina, setCodigoPostalPiscina] = useState("");
  const [cidadePiscina, setCidadePiscina] = useState("");
  const [distritoPiscina, setDistritoPiscina] = useState("");
  const [paisPiscina, setPaisPiscina] = useState("");
  const [latitudePiscina, setLatitudePiscina] = useState("");
  const [longitudePiscina, setLongitudePiscina] = useState("");
  const [fotoPiscina, setFotoPiscina] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [foto2, setFoto2] = useState([]);
  const [foto3, setFoto3] = useState([]);
  const [foto4, setFoto4] = useState([]);
  const [foto5, setFoto5] = useState([]);
  const [foto6, setFoto6] = useState([]);
  //Responsável
  const [nomeResponsavel, setNomeResponsavel] = useState("");
  const [emailResponsavel, setEmailResponsavel] = useState("");
  const [telefoneResponsavel, setTelefoneResponsavel] = useState("");
  const [cargoResponsavel, setCargoResponsavel] = useState("");
  const [fotoResponsavel, setFotoResponsavel] = useState([]);
  //Contrato
  const [contrato, setContrato] = useState([]);
  const [contrato2, setContrato2] = useState([]);

  //Validações
  //Entidade
  const [erroNomeEntidade, setErroNomeEntidade] = useState(false);
  const [erroNifEntidade, setErroNifEntidade] = useState(false);
  const [erroEmailEntidade, setErroEmailEntidade] = useState(false);
  const [erroTelefoneEntidade, setErroTelefoneEntidade] = useState(false);
  const [erroRuaEntidade, setErroRuaEntidade] = useState(false);
  const [erroCodigoPostalEntidade, setErroCodigoPostalEntidade] =
    useState(false);
  const [erroCidadeEntidade, setErroCidadeEntidade] = useState(false);
  const [erroDistritoEntidade, setErroDistritoEntidade] = useState(false);
  const [erroPaisEntidade, setErroPaisEntidade] = useState(false);
  const [erroEntidadesExploramPiscina, setErroEntidadesExploramPiscina] =
    useState(false);
  const [erroFuncionariosAcessoGestao, setErroFuncionariosAcessoGestao] =
    useState(false);
  const [ErroNumFuncionariosAcesso, setErroNumFuncionariosAcesso] =
    useState(false);
  const [erroUnicaEntidade, setErroUnicaEntidade] = useState(false);
  //Piscina
  const [erroNomePiscina, setErroNomePiscina] = useState("");
  const [erroNifPiscina, setErroNifPiscina] = useState("");
  const [erroEmailPiscina, setErroEmailPiscina] = useState("");
  const [erroTelefonePiscina, setErroTelefonePiscina] = useState("");
  const [erroTelefone2Piscina, setErroTelefone2Piscina] = useState("");
  const [erroRuaPiscina, setErroRuaPiscina] = useState("");
  const [erroCodigoPostalPiscina, setErroCodigoPostalPiscina] = useState("");
  const [erroCidadePiscina, setErroCidadePiscina] = useState("");
  const [erroDistritoPiscina, setErroDistritoPiscina] = useState("");
  const [erroPaisPiscina, setErroPaisPiscina] = useState("");
  const [erroLatitudePiscina, setErroLatitudePiscina] = useState("");
  const [erroLongitudePiscina, setErroLongitudePiscina] = useState("");
  const [erroFotoPiscina, setErroFotoPiscina] = useState("");
  const [erroGaleriaPiscina, setErroGaleriaPiscina] = useState([]);
  // Responsável
  const [erroNomeResponsavel, setErroNomeResponsavel] = useState("");
  const [erroEmailResponsavel, setErroEmailResponsavel] = useState("");
  const [erroTelefoneResponsavel, setErroTelefoneResponsavel] = useState("");
  const [erroCargoResponsavel, setErroCargoResponsavel] = useState("");

  //Mensagens de erro
  //Entidade
  const [mensagemErroNifEntidade, setMensagemErroNifEntidade] = useState("");
  const [mensagemErroEmailEntidade, setMensagemErroEmailEntidade] =
    useState("");
  const [mensagemErroTelefoneEntidade, setMensagemErroTelefoneEntidade] =
    useState("");
  const [
    mensagemErroCodigoPostalEntidade,
    setMensagemErroCodigoPostalEntidade,
  ] = useState("");
  //Piscina
  const [mensagemErroNifPiscina, setMensagemErroNifPiscina] = useState("");
  const [mensagemErroEmailPiscina, setMensagemErroEmailPiscina] = useState("");
  const [mensagemErroTelefonePiscina, setMensagemErroTelefonePiscina] =
    useState("");
  const [mensagemErroTelefone2Piscina, setMensagemErroTelefone2Piscina] =
    useState("");
  const [mensagemErroCodigoPostalPiscina, setMensagemErroCodigoPostalPiscina] =
    useState("");
  const [mensagemErroLatitudePiscina, setMensagemErroLatitudePiscina] =
    useState("");
  const [mensagemErroLongitudePiscina, setMensagemErroLongitudePiscina] =
    useState("");
  // Responsável
  const [mensagemErroEmailResponsavel, setMensagemErroEmailResponsavel] =
    useState("");
  const [mensagemErroTelefoneResponsavel, setMensagemErroTelefoneResponsavel] =
    useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  //Verificação do nif da entidade legal
  useEffect(() => {
    if (nifEntidade && nifEntidade.length == 9) {
      verificarNifEntidade({ nif: nifEntidade }).then((res) => {
        if (res.data.success) {
          let dados = res.data.data;
          //Desativar campos
          setEdicaoEntidade(true);
          //Preencher campos;
          setIdEntidade(dados.id_entidade_legal);
          setNomeEntidade(dados.nome);
          setErroNomeEntidade(false);
          setEmailEntidade(dados.email);
          setErroEmailEntidade(false);
          setMensagemErroEmailEntidade("");
          setTelefoneEntidade(dados.telefone);
          setErroTelefoneEntidade(false);
          setMensagemErroEmailEntidade("");
          setRuaEntidade(dados.morada);
          setErroRuaEntidade(false);
          setCodigoPostalEntidade(dados.codigo_postal);
          setErroCodigoPostalEntidade(false);
          setMensagemErroCodigoPostalEntidade("");
          setCidadeEntidade(dados.cidade);
          setErroCidadeEntidade(false);
          setDistritoEntidade(dados.distrito);
          setErroDistritoEntidade(false);
          const pais = dados.pais.split("-");
          setPaisEntidade({ value: pais[0], label: pais[1] });
          setErroPaisEntidade(false);
        }
      });
    }
  }, [nifEntidade]);

  function limparDadosEntidadeLegal() {
    //Definir valores vazios
    setIdEntidade(0);
    setNomeEntidade("");
    setNifEntidade("");
    setEmailEntidade("");
    setTelefoneEntidade("");
    setRuaEntidade("");
    setCodigoPostalEntidade("");
    setCidadeEntidade("");
    setDistritoEntidade("");
    setPaisEntidade("");
    //Ativar campos
    setEdicaoEntidade(false);
  }

  async function validacao1() {
    if (activeStep == 0) {
      // const vatNumber = paisEntidade.value + nifEntidade;
      // const checkedVat = await getVatNumberInfos(vatNumber);

      if (
        nomeEntidade &&
        nifEntidade &&
        emailEntidade &&
        telefoneEntidade &&
        ruaEntidade &&
        codigoPostalEntidade &&
        cidadeEntidade &&
        distritoEntidade &&
        paisEntidade &&
        nomePiscina &&
        nifPiscina &&
        emailPiscina &&
        telefonePiscina &&
        ruaPiscina &&
        codigoPostalPiscina &&
        cidadePiscina &&
        distritoPiscina &&
        paisPiscina &&
        latitudePiscina &&
        longitudePiscina &&
        fotoPiscina.length > 0 &&
        nomeResponsavel &&
        emailResponsavel &&
        telefoneResponsavel &&
        cargoResponsavel &&
        contrato
      ) {
        let confirmarDadosPreenchidos = true;

        if (nifEntidade.length < 9) {
          confirmarDadosPreenchidos = false;
          setErroNifEntidade(true);
          setMensagemErroNifEntidade(t("validacaoNif"));
        }

        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailEntidade
          );
        if (!validacaoEmail) {
          confirmarDadosPreenchidos = false;
          setErroEmailEntidade(true);
          setMensagemErroEmailEntidade(t("emailInvalido"));
        }
        if (!isValidPhoneNumber(telefoneEntidade)) {
          confirmarDadosPreenchidos = false;
          setErroTelefoneEntidade(true);
          setMensagemErroTelefoneEntidade(t("validacaoTelemovel"));
        }
        if (codigoPostalEntidade.includes("_")) {
          confirmarDadosPreenchidos = false;
          setErroCodigoPostalEntidade(true);
          setMensagemErroCodigoPostalEntidade(t("validacaoCodigoPostal"));
        }
        let validacaoEmail3 =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailResponsavel
          );
        if (!validacaoEmail3) {
          confirmarDadosPreenchidos = false;
          setErroEmailResponsavel(true);
          setMensagemErroEmailResponsavel(t("emailInvalido"));
        }
        if (!isValidPhoneNumber(telefoneResponsavel)) {
          confirmarDadosPreenchidos = false;
          setErroTelefoneResponsavel(true);
          setMensagemErroTelefoneResponsavel(t("validacaoTelemovel"));
        }
        if (emailResponsavel) {
          verificarEmail({ email: emailResponsavel }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                confirmarDadosPreenchidos = false;
                setErroEmailResponsavel(true);
                setMensagemErroEmailResponsavel(res.data.error);
              }
            }
          });
        }
        //OPÇÔES
        //1
        if (!unicaEntidade && !entidadesExploramPiscina) {
          confirmarDadosPreenchidos = false;
          setErroEntidadesExploramPiscina(true);
        }
        //2
        if (funcionariosAcessoGestao && !numFuncionariosAcesso) {
          confirmarDadosPreenchidos = false;
          setErroNumFuncionariosAcesso(true);
        }

        if (confirmarDadosPreenchidos) {
          handleComplete();
          //Scroll para o início da página
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        // ----ENTIDADE----
        //Validação do nome
        if (!nomeEntidade) {
          setErroNomeEntidade(true);
        }
        //Validação do nif
        if (!nifEntidade) {
          setErroNifEntidade(true);
        } else {
          if (nifEntidade.length < 9) {
            setErroNifEntidade(true);
            setMensagemErroNifEntidade(t("validacaoNif"));
          }
        }
        //Validação do email
        if (!emailEntidade) {
          setErroEmailEntidade(true);
        } else {
          let validacaoEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              emailEntidade
            );
          if (!validacaoEmail) {
            setErroEmailEntidade(true);
            setMensagemErroEmailEntidade(t("emailInvalido"));
          }
        }
        //Validação do telefone
        if (!telefoneEntidade) {
          setErroTelefoneEntidade(true);
        } else {
          if (!isValidPhoneNumber(telefoneEntidade)) {
            setErroTelefoneEntidade(true);
            setMensagemErroTelefoneEntidade(t("validacaoTelemovel"));
          }
        }
        //Validação da rua
        if (!ruaEntidade) {
          setErroRuaEntidade(true);
        }
        //Validação do código postal
        if (!codigoPostalEntidade) {
          setErroCodigoPostalEntidade(true);
        } else {
          if (codigoPostalEntidade.includes("_")) {
            setErroCodigoPostalEntidade(true);
            setMensagemErroCodigoPostalEntidade(t("validacaoCodigoPostal"));
          }
        }
        //Validação da cidade
        if (!cidadeEntidade) {
          setErroCidadeEntidade(true);
        }
        //Validação do distrito
        if (!distritoEntidade) {
          setErroDistritoEntidade(true);
        }
        //Validação do pais
        if (!paisEntidade) {
          setErroPaisEntidade(true);
        }
        // ----RESPONSÁVEL----
        //Validação do nome
        if (!nomeResponsavel) {
          setErroNomeResponsavel(true);
        }
        //Validação do email
        if (!emailResponsavel) {
          setErroEmailResponsavel(true);
        } else {
          let validacaoEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              emailResponsavel
            );
          if (!validacaoEmail) {
            setErroEmailResponsavel(true);
            setMensagemErroEmailResponsavel(t("emailInvalido"));
          } else {
            verificarEmail({ email: emailResponsavel }).then((res) => {
              if (res.success) {
                if (res.data.error) {
                  setErroEmailResponsavel(true);
                  setMensagemErroEmailResponsavel(res.data.error);
                }
              }
            });
          }
        }
        //Validação do telefone
        if (!telefoneResponsavel) {
          setErroTelefoneResponsavel(true);
        } else {
          if (!isValidPhoneNumber(telefoneResponsavel)) {
            setErroTelefoneResponsavel(true);
            setMensagemErroTelefoneResponsavel(t("validacaoTelemovel"));
          }
        }
        // Validação do cargo
        if (!cargoResponsavel) {
          setErroCargoResponsavel(true);
        }
        // ----CONTRATO----
        if (contrato.length == 0) {
          document.getElementById("contratoLabel").style.borderColor =
            Colors.inativo;
          document.getElementById("contrato").style.color = Colors.inativo;
        }

        //OPÇÔES
        //1
        if (!unicaEntidade && !entidadesExploramPiscina) {
          setErroEntidadesExploramPiscina(true);
        }
        //2
        if (funcionariosAcessoGestao && !numFuncionariosAcesso) {
          setErroNumFuncionariosAcesso(true);
        }
      }
    }

    //Introduzir dados
    // if (fase1) {
    //   if (
    //     nomeEntidade &&
    //     nifEntidade &&
    //     certidaoEntidade &&
    //     emailEntidade &&
    //     telefoneEntidade &&
    //     ruaEntidade &&
    //     codigoPostalEntidade &&
    //     cidadeEntidade &&
    //     distritoEntidade &&
    //     paisEntidade &&
    //     nomePiscina &&
    //     nifPiscina &&
    //     emailPiscina &&
    //     telefonePiscina &&
    //     ruaPiscina &&
    //     codigoPostalPiscina &&
    //     cidadePiscina &&
    //     distritoPiscina &&
    //     paisPiscina &&
    //     latitudePiscina &&
    //     longitudePiscina &&
    //     fotoPiscina.length > 0 &&
    //     nomeResponsavel &&
    //     emailResponsavel &&
    //     telefoneResponsavel &&
    //     cargoResponsavel &&
    //     contrato
    //   ) {
    //     let confirmarDadosPreenchidos = true;

    //     if (nifEntidade.length < 9) {
    //       confirmarDadosPreenchidos = false;
    //       setErroNifEntidade(true);
    //       setMensagemErroNifEntidade(t("validacaoNif"));
    //     }
    //     if (certidaoEntidade.includes("_")) {
    //       confirmarDadosPreenchidos = false;
    //       setErroCertidaoEntidade(true);
    //       setMensagemErroCertidaoEntidade(t("validacaoCertificacao"));
    //     }

    //     let validacaoEmail =
    //       /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //         emailEntidade
    //       );
    //     if (!validacaoEmail) {
    //       confirmarDadosPreenchidos = false;
    //       setErroEmailEntidade(true);
    //       setMensagemErroEmailEntidade(t("emailInvalido"));
    //     }
    //     if (!isValidPhoneNumber(telefoneEntidade)) {
    //       confirmarDadosPreenchidos = false;
    //       setErroTelefoneEntidade(true);
    //       setMensagemErroTelefoneEntidade(t("validacaoTelemovel"));
    //     }
    //     if (codigoPostalEntidade.includes("_")) {
    //       confirmarDadosPreenchidos = false;
    //       setErroCodigoPostalEntidade(true);
    //       setMensagemErroCodigoPostalEntidade(t("validacaoCodigoPostal"));
    //     }
    //     if (nifPiscina.length < 9) {
    //       confirmarDadosPreenchidos = false;
    //       setErroNifPiscina(true);
    //       setMensagemErroNifPiscina(t("validacaoNif"));
    //     }
    //     let validacaoEmail2 =
    //       /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //         emailPiscina
    //       );
    //     if (!validacaoEmail2) {
    //       confirmarDadosPreenchidos = false;
    //       setErroEmailPiscina(true);
    //       setMensagemErroEmailPiscina(t("emailInvalido"));
    //     }
    //     if (!isValidPhoneNumber(telefonePiscina)) {
    //       confirmarDadosPreenchidos = false;
    //       setErroTelefonePiscina(true);
    //       setMensagemErroTelefonePiscina(t("validacaoTelemovel"));
    //     }
    //     if (!isValidPhoneNumber(telefone2Piscina) && telefone2Piscina) {
    //       confirmarDadosPreenchidos = false;
    //       setErroTelefone2Piscina(true);
    //       setMensagemErroTelefone2Piscina(t("validacaoTelemovel"));
    //     }
    //     if (codigoPostalPiscina.includes("_")) {
    //       confirmarDadosPreenchidos = false;
    //       setErroCodigoPostalPiscina(true);
    //       setMensagemErroCodigoPostalPiscina(t("validacaoCodigoPostal"));
    //     }
    //     const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    //     if (!regexLat.test(latitudePiscina)) {
    //       confirmarDadosPreenchidos = false;
    //       setErroLatitudePiscina(true);
    //       setMensagemErroLatitudePiscina(t("validacaoLatitude"));
    //     }
    //     const regexLon =
    //       /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
    //     if (!regexLon.test(longitudePiscina)) {
    //       confirmarDadosPreenchidos = false;
    //       setErroLongitudePiscina(true);
    //       setMensagemErroLongitudePiscina(t("validacaoLongitude"));
    //     }
    //     let validacaoEmail3 =
    //       /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //         emailResponsavel
    //       );
    //     if (!validacaoEmail3) {
    //       confirmarDadosPreenchidos = false;
    //       setErroEmailResponsavel(true);
    //       setMensagemErroEmailResponsavel(t("emailInvalido"));
    //     }
    //     if (!isValidPhoneNumber(telefoneResponsavel)) {
    //       confirmarDadosPreenchidos = false;
    //       setErroTelefoneResponsavel(true);
    //       setMensagemErroTelefoneResponsavel(t("validacaoTelemovel"));
    //     }
    //     if (emailResponsavel) {
    //       verificarEmail({ email: emailResponsavel }).then((res) => {
    //         if (res.success) {
    //           if (res.data.error) {
    //             confirmarDadosPreenchidos = false;
    //             setErroEmailResponsavel(true);
    //             setMensagemErroEmailResponsavel(res.data.error);
    //           }
    //         }
    //       });
    //     }

    //     if (confirmarDadosPreenchidos) {
    //       setFase1(false);
    //       //Scroll para o início da página
    //       window.scrollTo(0, 0);
    //     } else {
    //       window.scrollTo(0, 0);
    //     }
    //   } else {
    //     //Scroll para o início da página
    //     window.scrollTo(0, 0);
    //     // ----ENTIDADE----
    //     //Validação do nome
    //     if (!nomeEntidade) {
    //       setErroNomeEntidade(true);
    //     }
    //     //Validação do nif
    //     if (!nifEntidade) {
    //       setErroNifEntidade(true);
    //     } else {
    //       if (nifEntidade.length < 9) {
    //         setErroNifEntidade(true);
    //         setMensagemErroNifEntidade(t("validacaoNif"));
    //       }
    //     }
    //     //Validação da certidão
    //     if (!certidaoEntidade) {
    //       setErroCertidaoEntidade(true);
    //     } else {
    //       if (certidaoEntidade.includes("_")) {
    //         setErroCertidaoEntidade(true);
    //         setMensagemErroCertidaoEntidade(t("validacaoCertificacao"));
    //       }
    //     }
    //     //Validação do email
    //     if (!emailEntidade) {
    //       setErroEmailEntidade(true);
    //     } else {
    //       let validacaoEmail =
    //         /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //           emailEntidade
    //         );
    //       if (!validacaoEmail) {
    //         setErroEmailEntidade(true);
    //         setMensagemErroEmailEntidade(t("emailInvalido"));
    //       }
    //     }
    //     //Validação do telefone
    //     if (!telefoneEntidade) {
    //       setErroTelefoneEntidade(true);
    //     } else {
    //       if (!isValidPhoneNumber(telefoneEntidade)) {
    //         setErroTelefoneEntidade(true);
    //         setMensagemErroTelefoneEntidade(t("validacaoTelemovel"));
    //       }
    //     }
    //     //Validação da rua
    //     if (!ruaEntidade) {
    //       setErroRuaEntidade(true);
    //     }
    //     //Validação do código postal
    //     if (!codigoPostalEntidade) {
    //       setErroCodigoPostalEntidade(true);
    //     } else {
    //       if (codigoPostalEntidade.includes("_")) {
    //         setErroCodigoPostalEntidade(true);
    //         setMensagemErroCodigoPostalEntidade(t("validacaoCodigoPostal"));
    //       }
    //     }
    //     //Validação da cidade
    //     if (!cidadeEntidade) {
    //       setErroCidadeEntidade(true);
    //     }
    //     //Validação do distrito
    //     if (!distritoEntidade) {
    //       setErroDistritoEntidade(true);
    //     }
    //     //Validação do pais
    //     if (!paisEntidade) {
    //       setErroPaisEntidade(true);
    //     }
    //     // ----PISCINA----
    //     //Validação do nome
    //     if (!nomePiscina) {
    //       setErroNomePiscina(true);
    //     }
    //     // Validação do nif
    //     if (!nifPiscina) {
    //       setErroNifPiscina(true);
    //     } else {
    //       if (nifPiscina.length < 9) {
    //         setErroNifPiscina(true);
    //         setMensagemErroNifPiscina(t("validacaoNif"));
    //       }
    //     }
    //     //Validação do email
    //     if (!emailPiscina) {
    //       setErroEmailPiscina(true);
    //     } else {
    //       let validacaoEmail =
    //         /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //           emailPiscina
    //         );
    //       if (!validacaoEmail) {
    //         setErroEmailPiscina(true);
    //         setMensagemErroEmailPiscina(t("emailInvalido"));
    //       }
    //     }
    //     //Validação telemóvel
    //     if (!telefonePiscina) {
    //       setErroTelefonePiscina(true);
    //     } else {
    //       if (!isValidPhoneNumber(telefonePiscina)) {
    //         setErroTelefonePiscina(true);
    //         setMensagemErroTelefonePiscina(t("validacaoTelemovel"));
    //       }
    //     }
    //     //Validação telemóvel 2
    //     if (telefone2Piscina) {
    //       if (!isValidPhoneNumber(telefone2Piscina)) {
    //         setErroTelefone2Piscina(true);
    //         setMensagemErroTelefone2Piscina(t("validacaoTelemovel"));
    //       }
    //     }
    //     //Validação da rua
    //     if (!ruaPiscina) {
    //       setErroRuaPiscina(true);
    //     }
    //     //Validação do código postal
    //     if (!codigoPostalPiscina) {
    //       setErroCodigoPostalPiscina(true);
    //     } else {
    //       if (codigoPostalPiscina.includes("_")) {
    //         setErroCodigoPostalPiscina(true);
    //         setMensagemErroCodigoPostalPiscina(t("validacaoCodigoPostal"));
    //       }
    //     }
    //     //Validação da cidade
    //     if (!cidadePiscina) {
    //       setErroCidadePiscina(true);
    //     }
    //     //Validação do distrito
    //     if (!distritoPiscina) {
    //       setErroDistritoPiscina(true);
    //     }
    //     //Validação do pais
    //     if (!paisPiscina) {
    //       setErroPaisPiscina(true);
    //     }
    //     //Validação da latitude
    //     if (!latitudePiscina) {
    //       setErroLatitudePiscina(true);
    //     } else {
    //       const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    //       if (!regexLat.test(latitudePiscina)) {
    //         setErroLatitudePiscina(true);
    //         setMensagemErroLatitudePiscina(t("validacaoLatitude"));
    //       }
    //     }
    //     //Validação da longitude
    //     if (!longitudePiscina) {
    //       setErroLongitudePiscina(true);
    //     } else {
    //       const regexLon =
    //         /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
    //       if (!regexLon.test(longitudePiscina)) {
    //         setErroLongitudePiscina(true);
    //         setMensagemErroLongitudePiscina(t("validacaoLongitude"));
    //       }
    //     }
    //     //Validação da foto da piscina
    //     if (fotoPiscina.length == 0) {
    //       document.getElementById("fotoPiscina").style.borderColor =
    //         Colors.inativo;
    //       document.getElementById("tituloFotoPiscina").style.color =
    //         Colors.inativo;
    //     }
    //     // ----RESPONSÁVEL----
    //     //Validação do nome
    //     if (!nomeResponsavel) {
    //       setErroNomeResponsavel(true);
    //     }
    //     //Validação do email
    //     if (!emailResponsavel) {
    //       setErroEmailResponsavel(true);
    //     } else {
    //       let validacaoEmail =
    //         /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //           emailResponsavel
    //         );
    //       if (!validacaoEmail) {
    //         setErroEmailResponsavel(true);
    //         setMensagemErroEmailResponsavel(t("emailInvalido"));
    //       } else {
    //         verificarEmail({ email: emailResponsavel }).then((res) => {
    //           if (res.success) {
    //             if (res.data.error) {
    //               setErroEmailResponsavel(true);
    //               setMensagemErroEmailResponsavel(res.data.error);
    //             }
    //           }
    //         });
    //       }
    //     }
    //     //Validação do telefone
    //     if (!telefoneResponsavel) {
    //       setErroTelefoneResponsavel(true);
    //     } else {
    //       if (!isValidPhoneNumber(telefoneResponsavel)) {
    //         setErroTelefoneResponsavel(true);
    //         setMensagemErroTelefoneResponsavel(t("validacaoTelemovel"));
    //       }
    //     }
    //     // Validação do cargo
    //     if (!cargoResponsavel) {
    //       setErroCargoResponsavel(true);
    //     }
    //     // ----CONTRATO----
    //     if (contrato.length == 0) {
    //       document.getElementById("contratoLabel").style.borderColor =
    //         Colors.inativo;
    //       document.getElementById("contrato").style.color = Colors.inativo;
    //     }
    //   }
    // } else {
    //   if (!termos) {
    //     toast.error(t("validacaoTermos"));
    //   } else {
    //     //Ativar o freeze da página
    //     setFreeze(true);
    //     if (idEntidade == 0 && edicaoEntidade) {
    //       // Webservice para adicionar piscina
    //       await postPiscinas({
    //         entidade: {
    //           nome: nomeEntidade,
    //           nif: nifEntidade,
    //           certidao: certidaoEntidade,
    //           email: emailEntidade,
    //           telefone: telefoneEntidade,
    //           morada: ruaEntidade,
    //           codigo_postal: codigoPostalEntidade,
    //           cidade: cidadeEntidade,
    //           distrito: distritoEntidade,
    //           pais: paisEntidade.value + "-" + paisEntidade.label,
    //         },
    //         piscina: {
    //           nome: nomePiscina,
    //           nif: nifPiscina,
    //           email: emailPiscina,
    //           contacto1: telefonePiscina,
    //           contacto2: telefone2Piscina,
    //           morada: ruaPiscina,
    //           codigo_postal: codigoPostalPiscina,
    //           cidade: cidadePiscina,
    //           distrito: distritoPiscina,
    //           pais: paisPiscina.value + "-" + paisEntidade.label,
    //           latitude: latitudePiscina,
    //           longitude: longitudePiscina,
    //           foto_perfil: fotoPiscina,
    //           identificacao: idUtilizador,
    //           contrato: contrato,
    //           foto1: foto1.length == 0 ? "" : foto1,
    //           foto2: foto2.length == 0 ? "" : foto2,
    //           foto3: foto3.length == 0 ? "" : foto3,
    //           foto4: foto4.length == 0 ? "" : foto4,
    //           foto5: foto5.length == 0 ? "" : foto5,
    //           foto6: foto6.length == 0 ? "" : foto6,
    //         },
    //         admin: {
    //           nome: nomeResponsavel,
    //           email: emailResponsavel,
    //           contacto: telefoneResponsavel,
    //           cargo: cargoResponsavel,
    //           foto: fotoResponsavel.length == 0 ? "" : fotoResponsavel,
    //         },
    //       }).then(async (res) => {
    //         if (res.success) {
    //           setTimeout(async function () {
    //             await enviarEmailPostPiscina({
    //               email: emailResponsavel,
    //               nome: nomeResponsavel,
    //               id: res.data.id_admin,
    //               id_piscina: res.data.id,
    //             }).then((res) => {
    //               if (res.success) {
    //                 toast.success(res.data.msg);
    //                 setTimeout(async function () {
    //                   await definePassEmail({
    //                     email: email,
    //                   }).then((res2) => {
    //                     if (res2.success) {
    //                       toast.success(t("emailDefinir"));
    //                       navigate("/pools");
    //                     } else {
    //                       toast.error(t("erroEmailDefinir"));
    //                       navigate("/pools");
    //                     }
    //                   });
    //                 }, 3000);
    //               } else {
    //                 toast.error(res.error);
    //                 navigate("/pools");
    //               }
    //             });
    //           }, 3000);
    //         } else {
    //           toast.error(res.error);
    //         }
    //       });
    //     } else {
    //       // Webservice para adicionar piscina com id da entidade legal
    //       await postPiscinasIdEntidadeLegal({
    //         entidade: {
    //           id: idEntidade,
    //         },
    //         piscina: {
    //           nome: nomePiscina,
    //           nif: nifPiscina,
    //           email: emailPiscina,
    //           contacto1: telefonePiscina,
    //           contacto2: telefone2Piscina,
    //           morada: ruaPiscina,
    //           codigo_postal: codigoPostalPiscina,
    //           cidade: cidadePiscina,
    //           distrito: distritoPiscina,
    //           pais: paisPiscina.value + "-" + paisEntidade.label,
    //           latitude: latitudePiscina,
    //           longitude: longitudePiscina,
    //           foto_perfil: fotoPiscina,
    //           identificacao: idUtilizador,
    //           contrato: contrato,
    //           foto1: foto1.length == 0 ? "" : foto1,
    //           foto2: foto2.length == 0 ? "" : foto2,
    //           foto3: foto3.length == 0 ? "" : foto3,
    //           foto4: foto4.length == 0 ? "" : foto4,
    //           foto5: foto5.length == 0 ? "" : foto5,
    //           foto6: foto6.length == 0 ? "" : foto6,
    //         },
    //         admin: {
    //           nome: nomeResponsavel,
    //           email: emailResponsavel,
    //           contacto: telefoneResponsavel,
    //           cargo: cargoResponsavel,
    //           foto: fotoResponsavel.length == 0 ? "" : fotoResponsavel,
    //         },
    //       })
    //         .then(async (res) => {
    //           if (res.success) {
    //             setTimeout(async function () {
    //               await enviarEmailPostPiscina({
    //                 email: emailResponsavel,
    //                 nome: nomeResponsavel,
    //                 id: res.data.id_admin,
    //                 id_piscina: res.data.id,
    //               }).then((res) => {
    //                 if (res.success) {
    //                   toast.success(res.data.msg);
    //                   navigate("/pools");
    //                 } else {
    //                   toast.error(res.error);
    //                   navigate("/pools");
    //                 }
    //               });
    //             }, 3000);
    //           } else {
    //             if (res.status == 500) {
    //               navigate("/500");
    //             }
    //             toast.error(res.error);
    //           }
    //         })
    //         .catch((error) => {
    //           //toast.warn(t("erroWebservice"));
    //         });
    //     }
    //   }
    // }
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <div className="divForm">
        <div className="containerForm">
          <div className="align-left">
            <IconButton
              className="arrowBack"
              variant="text"
              onClick={() => {
                {
                  fase1 ? navigate("/pools") : setFase1(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </div>
          {/* <div className="align-right">
            {fase1 ? (
              <h3 className="titleList">{t("adicionarPiscina")}</h3>
            ) : (
              <h3 className="titleList">{t("confirmarDados")}</h3>
            )}
          </div> */}
        </div>

        {fase1 ? (
          <div>
            <Box>
              <Stepper
                nonLinear
                activeStep={activeStep}
                className="formTextField5"
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton
                      color="inherit"
                      onClick={completed[index] ? handleStep(index) : null}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              {/* Primeiro Passo - Entidade proprietária e Responsável */}
              {activeStep == 0 && (
                <div>
                  <div className="formPage">
                    <Box className="formTextField">
                      {/* Entidade Legal */}
                      <h5 className="h5List">{t("entidadeLegal")}</h5>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          className="formTextField2"
                          style={{
                            textAlign: "left",
                            color: "#858585",
                            fontSize: 12,
                            float: "left",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ padding: 0, marginRight: 5 }}
                            icon={faCircleInfo}
                          />
                          {t("infoNif")}
                        </p>
                        {idEntidade != 0 ? (
                          <Button
                            className="formTextField4"
                            style={{
                              float: "right",
                            }}
                            onClick={() => {
                              limparDadosEntidadeLegal();
                            }}
                          >
                            {t("limparDados")}
                          </Button>
                        ) : null}
                      </div>

                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            label={t("nome")}
                            value={nomeEntidade}
                            error={erroNomeEntidade}
                            onChange={(e) => {
                              setNomeEntidade(e.target.value);
                              setErroNomeEntidade(false);
                            }}
                          />
                        </FormControl>
                        {/* NIF */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            label={t("nipc")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 11);
                            }}
                            value={nifEntidade}
                            error={erroNifEntidade}
                            helperText={mensagemErroNifEntidade}
                            onChange={(e) => {
                              setNifEntidade(e.target.value);
                              setErroNifEntidade(false);
                            }}
                          />
                        </FormControl>
                        {/* Email */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            label={t("email")}
                            value={emailEntidade}
                            error={erroEmailEntidade}
                            helperText={mensagemErroEmailEntidade}
                            onChange={(e) => {
                              setEmailEntidade(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroEmailEntidade(false);
                              setMensagemErroEmailEntidade("");
                            }}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={edicaoEntidade}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={telefoneEntidade}
                            error={erroTelefoneEntidade}
                            helperText={mensagemErroTelefoneEntidade}
                            onChange={(e) => {
                              setTelefoneEntidade(e);
                              setErroTelefoneEntidade(false);
                              setMensagemErroTelefoneEntidade("");
                            }}
                            inputComponent={PhoneNumber}
                          />
                        </FormControl>
                        {/* Rua */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            label={t("rua")}
                            value={ruaEntidade}
                            error={erroRuaEntidade}
                            onChange={(e) => {
                              setRuaEntidade(e.target.value);
                              setErroRuaEntidade(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            value={codigoPostalEntidade}
                            format="####-###"
                            error={erroCodigoPostalEntidade}
                            helperText={mensagemErroCodigoPostalEntidade}
                            onChange={(e) => {
                              setCodigoPostalEntidade(e.target.value);
                              setErroCodigoPostalEntidade(false);
                              setMensagemErroCodigoPostalEntidade("");
                            }}
                          />
                        </FormControl>
                        {/* Cidade */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            label={t("cidade")}
                            value={cidadeEntidade}
                            error={erroCidadeEntidade}
                            onChange={(e) => {
                              setCidadeEntidade(e.target.value);
                              setErroCidadeEntidade(false);
                            }}
                          />
                        </FormControl>
                        {/* Distrito */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicaoEntidade}
                            variant="standard"
                            label={t("distrito")}
                            value={distritoEntidade}
                            error={erroDistritoEntidade}
                            onChange={(e) => {
                              setDistritoEntidade(e.target.value);
                              setErroDistritoEntidade(false);
                            }}
                          />
                        </FormControl>
                        {/* País */}
                        <FormControl className="formTextField3" fullWidth>
                          <Autocomplete
                            label={t("pais")}
                            disabled={edicaoEntidade}
                            variant="standard"
                            options={options}
                            value={paisEntidade}
                            onChange={(event, newValue) => {
                              setPaisEntidade(newValue);
                              setErroPaisEntidade(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("pais")}
                                variant="standard"
                                error={erroPaisEntidade}
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </div>{" "}
                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Responsável */}
                      <h5 className="h5List">{t("responsavel")}</h5>
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("nome")}
                            value={nomeResponsavel}
                            error={erroNomeResponsavel}
                            onChange={(e) => {
                              setNomeResponsavel(e.target.value);
                              setErroNomeResponsavel(false);
                            }}
                          />
                        </FormControl>
                        {/* Email */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("email")}
                            value={emailResponsavel}
                            error={erroEmailResponsavel}
                            helperText={mensagemErroEmailResponsavel}
                            onChange={(e) => {
                              setEmailResponsavel(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroEmailResponsavel(false);
                              setMensagemErroEmailResponsavel("");
                            }}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={edicao}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={telefoneResponsavel}
                            error={erroTelefoneResponsavel}
                            helperText={mensagemErroTelefoneResponsavel}
                            onChange={(e) => {
                              setTelefoneResponsavel(e);
                              setErroTelefoneResponsavel(false);
                              setMensagemErroTelefoneResponsavel("");
                            }}
                            inputComponent={PhoneNumber}
                          />
                        </FormControl>
                        {/* Cargo */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("cargo")}
                            value={cargoResponsavel}
                            error={erroCargoResponsavel}
                            onChange={(e) => {
                              setCargoResponsavel(e.target.value);
                              setErroCargoResponsavel(false);
                            }}
                          />
                        </FormControl>
                        {/* Foto */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="tituloFotoResponsavel"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("foto")}
                          </p>
                          <label
                            id="fotoResponsavel"
                            htmlFor="file-upload3"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />{" "}
                            {t("carregarImagem")}
                          </label>
                          <input
                            id="file-upload3"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="image/*"
                            onChange={async (e) => {
                              document.getElementById(
                                "fotoResponsavel"
                              ).style.borderColor = Colors.ativo;
                              const file = e.target.files[0];

                              const reader = new FileReader();

                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                setFotoResponsavel(reader.result);
                              };
                            }}
                          ></input>
                          {/* Imagem carregada */}
                          <div>
                            {fotoResponsavel.length > 0 && (
                              <img
                                style={{ marginTop: 10 }}
                                width={150}
                                height={150}
                                src={fotoResponsavel}
                              />
                            )}
                          </div>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Responsável */}
                      <h5 className="h5List">{t("contrato")}</h5>
                      <div>
                        {/* Contrato */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="contrato"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("contrato")} * (*pdf)
                          </p>
                          <label
                            id="contratoLabel"
                            htmlFor="file-upload4"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />
                            {t("carregarContrato")}
                          </label>
                          <input
                            id="file-upload4"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="application/pdf"
                            onChange={async (e) => {
                              document.getElementById(
                                "contratoLabel"
                              ).style.borderColor = Colors.ativo;
                              document.getElementById("contrato").style.color =
                                Colors.textFields;
                              const file = e.target.files[0];

                              const reader = new FileReader();

                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                setContrato(reader.result);
                                const pdfContentType = "application/pdf";
                                const base64toBlob = (data) => {
                                  // Cut the prefix `data:application/pdf;base64` from the raw base 64
                                  const base64WithoutPrefix = data.substr(
                                    `data:${pdfContentType};base64,`.length
                                  );

                                  const bytes = atob(base64WithoutPrefix);
                                  let length = bytes.length;
                                  let out = new Uint8Array(length);

                                  while (length--) {
                                    out[length] = bytes.charCodeAt(length);
                                  }

                                  return new Blob([out], {
                                    type: pdfContentType,
                                  });
                                };
                                const url = URL.createObjectURL(
                                  base64toBlob(reader.result)
                                );
                                setContrato2(url);
                              };
                            }}
                          ></input>
                          {contrato2.length > 0 && (
                            <div
                              style={{
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                                borderColor: Colors.textFields,
                                height: "600px",
                                marginTop: 10,
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                            >
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                                <Viewer fileUrl={contrato2} />
                              </Worker>
                            </div>
                          )}
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Opções */}
                      <h5 className="h5List">{t("opcoesSelecionar")}</h5>
                      <div>
                        {/* Opções */}
                        <FormControl
                          error={erroUnicaEntidade}
                          className="formTextField3"
                          fullWidth
                        >
                          <FormLabel style={{ textAlign: "left" }} required>
                            {t("entidadeUnicaExploradora")}
                          </FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={unicaEntidade}
                                  onChange={() => {
                                    setUnicaEntidade(true);
                                    setErroUnicaEntidade(false);
                                    setEntidadesExploramPiscina(1);
                                  }}
                                />
                              }
                              label={t("sim")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!unicaEntidade}
                                  onChange={() => {
                                    setUnicaEntidade(false);
                                    setErroUnicaEntidade(false);
                                    setEntidadesExploramPiscina(0);
                                  }}
                                />
                              }
                              label={t("nao")}
                            />
                          </FormGroup>
                        </FormControl>
                        {!unicaEntidade && (
                          <div>
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="number"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("entidadesExploradorasQtd")}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 2);
                                }}
                                value={entidadesExploramPiscina}
                                error={erroEntidadesExploramPiscina}
                                onChange={(e) => {
                                  let arrayFinal = [];
                                  for (let i = 0; i <= e.target.value; i++) {
                                    let id = { id: i };
                                    arrayFinal.push(id);
                                  }
                                  setEntidadesExploramPiscinaArray(arrayFinal);
                                  setEntidadesExploramPiscina(e.target.value);
                                  setErroEntidadesExploramPiscina(false);
                                }}
                              />
                            </FormControl>
                            <FormControl
                              error={erroFuncionariosAcessoGestao}
                              className="formTextField3"
                              fullWidth
                            >
                              <FormLabel style={{ textAlign: "left" }} required>
                                {t("funcionariosAcessoGestão")}
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={funcionariosAcessoGestao}
                                      onChange={() => {
                                        setFuncionariosAcessoGestao(true);
                                        setErroFuncionariosAcessoGestao(false);
                                      }}
                                    />
                                  }
                                  label={t("sim")}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!funcionariosAcessoGestao}
                                      onChange={() => {
                                        setFuncionariosAcessoGestao(false);
                                        setErroFuncionariosAcessoGestao(false);
                                        setNumFuncionariosAcesso("");
                                      }}
                                    />
                                  }
                                  label={t("nao")}
                                />
                              </FormGroup>
                            </FormControl>
                            {funcionariosAcessoGestao && (
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="number"
                                  disabled={edicaoEntidade}
                                  variant="standard"
                                  label={t("funcionariosAcesso")}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 2);
                                  }}
                                  value={numFuncionariosAcesso}
                                  error={ErroNumFuncionariosAcesso}
                                  onChange={(e) => {
                                    setNumFuncionariosAcesso(e.target.value);
                                    setErroNumFuncionariosAcesso(false);
                                  }}
                                />
                              </FormControl>
                            )}
                          </div>
                        )}
                      </div>
                    </Box>
                  </div>
                </div>
              )}
              {/* Segundo Passo - Entidades exploradoras */}
              {activeStep == 1 && (
                <div>
                  {entidadesExploramPiscinaArray.map((element) => (
                    <div>
                      <div className="formPage">
                        <Box className="formTextField">
                          {/* Entidade Legal */}
                          <h5 className="h5List">{t("entidadeExploradora")}</h5>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              className="formTextField2"
                              style={{
                                textAlign: "left",
                                color: "#858585",
                                fontSize: 12,
                                float: "left",
                              }}
                            >
                              <FontAwesomeIcon
                                style={{ padding: 0, marginRight: 5 }}
                                icon={faCircleInfo}
                              />
                              {t("infoNif")}
                            </p>
                            {idEntidade != 0 ? (
                              <Button
                                className="formTextField4"
                                style={{
                                  float: "right",
                                }}
                                onClick={() => {
                                  limparDadosEntidadeLegal();
                                }}
                              >
                                {t("limparDados")}
                              </Button>
                            ) : null}
                          </div>

                          <div>
                            {/* Nome */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("nome")}
                                value={nomeEntidade}
                                error={erroNomeEntidade}
                                onChange={(e) => {
                                  setNomeEntidade(e.target.value);
                                  setErroNomeEntidade(false);
                                }}
                              />
                            </FormControl>
                            {/* NIF */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("nif")}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 9);
                                }}
                                value={nifEntidade}
                                error={erroNifEntidade}
                                helperText={mensagemErroNifEntidade}
                                onChange={(e) => {
                                  setNifEntidade(e.target.value);
                                  setErroNifEntidade(false);
                                }}
                              />
                            </FormControl>
                            {/* Email */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("email")}
                                value={emailEntidade}
                                error={erroEmailEntidade}
                                helperText={mensagemErroEmailEntidade}
                                onChange={(e) => {
                                  setEmailEntidade(
                                    e.target.value.toLocaleLowerCase()
                                  );
                                  setErroEmailEntidade(false);
                                  setMensagemErroEmailEntidade("");
                                }}
                              />
                            </FormControl>
                            {/* Telefone */}
                            <FormControl className="formTextField3" fullWidth>
                              <PhoneInput
                                disabled={edicaoEntidade}
                                defaultCountry="PT"
                                labels={
                                  i18n.language == "pt"
                                    ? pt
                                    : i18n.language == "es"
                                    ? es
                                    : en
                                }
                                value={telefoneEntidade}
                                error={erroTelefoneEntidade}
                                helperText={mensagemErroTelefoneEntidade}
                                onChange={(e) => {
                                  setTelefoneEntidade(e);
                                  setErroTelefoneEntidade(false);
                                  setMensagemErroTelefoneEntidade("");
                                }}
                                inputComponent={PhoneNumber}
                              />
                            </FormControl>
                            {/* Rua */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("rua")}
                                value={ruaEntidade}
                                error={erroRuaEntidade}
                                onChange={(e) => {
                                  setRuaEntidade(e.target.value);
                                  setErroRuaEntidade(false);
                                }}
                              />
                            </FormControl>
                            {/* Código Postal */}
                            <FormControl className="formTextField3" fullWidth>
                              <PatternFormat
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                {...{
                                  label: t("codigoPostal"),
                                  variant: "standard",
                                }}
                                customInput={TextField}
                                required
                                mask="_"
                                value={codigoPostalEntidade}
                                format="####-###"
                                error={erroCodigoPostalEntidade}
                                helperText={mensagemErroCodigoPostalEntidade}
                                onChange={(e) => {
                                  setCodigoPostalEntidade(e.target.value);
                                  setErroCodigoPostalEntidade(false);
                                  setMensagemErroCodigoPostalEntidade("");
                                }}
                              />
                            </FormControl>
                            {/* Cidade */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("cidade")}
                                value={cidadeEntidade}
                                error={erroCidadeEntidade}
                                onChange={(e) => {
                                  setCidadeEntidade(e.target.value);
                                  setErroCidadeEntidade(false);
                                }}
                              />
                            </FormControl>
                            {/* Distrito */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("distrito")}
                                value={distritoEntidade}
                                error={erroDistritoEntidade}
                                onChange={(e) => {
                                  setDistritoEntidade(e.target.value);
                                  setErroDistritoEntidade(false);
                                }}
                              />
                            </FormControl>
                            {/* País */}
                            <FormControl className="formTextField3" fullWidth>
                              <Autocomplete
                                label={t("pais")}
                                disabled={edicaoEntidade}
                                variant="standard"
                                options={options}
                                value={paisEntidade}
                                onChange={(event, newValue) => {
                                  setPaisEntidade(newValue);
                                  setErroPaisEntidade(false);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("pais")}
                                    variant="standard"
                                    error={erroPaisEntidade}
                                    fullWidth
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                          <br></br>
                          {/* Responsável */}
                          <h5 className="h5List">{t("funcionario_")}</h5>
                          <div>
                            {/* Nome */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("nome")}
                                value={nomeResponsavel}
                                error={erroNomeResponsavel}
                                onChange={(e) => {
                                  setNomeResponsavel(e.target.value);
                                  setErroNomeResponsavel(false);
                                }}
                              />
                            </FormControl>
                            {/* Email */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("email")}
                                value={emailResponsavel}
                                error={erroEmailResponsavel}
                                helperText={mensagemErroEmailResponsavel}
                                onChange={(e) => {
                                  setEmailResponsavel(
                                    e.target.value.toLocaleLowerCase()
                                  );
                                  setErroEmailResponsavel(false);
                                  setMensagemErroEmailResponsavel("");
                                }}
                              />
                            </FormControl>
                            {/* Telefone */}
                            <FormControl className="formTextField3" fullWidth>
                              <PhoneInput
                                disabled={edicao}
                                defaultCountry="PT"
                                labels={
                                  i18n.language == "pt"
                                    ? pt
                                    : i18n.language == "es"
                                    ? es
                                    : en
                                }
                                value={telefoneResponsavel}
                                error={erroTelefoneResponsavel}
                                helperText={mensagemErroTelefoneResponsavel}
                                onChange={(e) => {
                                  setTelefoneResponsavel(e);
                                  setErroTelefoneResponsavel(false);
                                  setMensagemErroTelefoneResponsavel("");
                                }}
                                inputComponent={PhoneNumber}
                              />
                            </FormControl>
                            {/* Cargo */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("cargo")}
                                value={cargoResponsavel}
                                error={erroCargoResponsavel}
                                onChange={(e) => {
                                  setCargoResponsavel(e.target.value);
                                  setErroCargoResponsavel(false);
                                }}
                              />
                            </FormControl>
                            {/* Foto */}
                            <FormControl className="formTextField3" fullWidth>
                              <p
                                id="tituloFotoResponsavel"
                                style={{
                                  marginTop: 10,
                                  color: Colors.textFields,
                                  fontSize: 15,
                                  fontWeight: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {t("foto")}
                              </p>
                              <label
                                id="fotoResponsavel"
                                htmlFor="file-upload3"
                                className="custom-file-upload"
                              >
                                <i className="fa fa-cloud-upload"></i>
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  style={{ paddingTop: 4, paddingRight: 4 }}
                                />{" "}
                                {t("carregarImagem")}
                              </label>
                              <input
                                id="file-upload3"
                                style={{ paddingTop: 0 }}
                                type="file"
                                accept="image/*"
                                onChange={async (e) => {
                                  document.getElementById(
                                    "fotoResponsavel"
                                  ).style.borderColor = Colors.ativo;
                                  const file = e.target.files[0];

                                  const reader = new FileReader();

                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    setFotoResponsavel(reader.result);
                                  };
                                }}
                              ></input>
                              {/* Imagem carregada */}
                              <div>
                                {fotoResponsavel.length > 0 && (
                                  <img
                                    style={{ marginTop: 10 }}
                                    width={150}
                                    height={150}
                                    src={fotoResponsavel}
                                  />
                                )}
                              </div>
                            </FormControl>
                          </div>
                          <br></br>
                          {/* Opções */}
                          <h5 className="h5List">{t("opcoesSelecionar")}</h5>
                          <div>
                            {/* Opções */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="number"
                                disabled={edicaoEntidade}
                                variant="standard"
                                label={t("entidadesExploradorasQtd")}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 2);
                                }}
                                value={entidadesExploramPiscina}
                                error={erroEntidadesExploramPiscina}
                                onChange={(e) => {
                                  let arrayFinal = [];
                                  for (let i = 0; i <= e.target.value; i++) {
                                    let id = { id: i };
                                    arrayFinal.push(id);
                                  }
                                  setEntidadesExploramPiscinaArray(arrayFinal);
                                  setEntidadesExploramPiscina(e.target.value);
                                  setErroEntidadesExploramPiscina(false);
                                }}
                              />
                            </FormControl>
                          </div>
                          <br></br>
                          {/* Contrato */}
                          <h5 className="h5List">{t("contrato")}</h5>
                          <div>
                            {/* Contrato */}
                            <FormControl className="formTextField3" fullWidth>
                              <p
                                id="contrato"
                                style={{
                                  marginTop: 10,
                                  color: Colors.textFields,
                                  fontSize: 15,
                                  fontWeight: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {t("contrato")} * (*pdf)
                              </p>
                              <label
                                id="contratoLabel"
                                htmlFor="file-upload4"
                                className="custom-file-upload"
                              >
                                <i className="fa fa-cloud-upload"></i>
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  style={{ paddingTop: 4, paddingRight: 4 }}
                                />
                                {t("carregarContrato")}
                              </label>
                              <input
                                id="file-upload4"
                                style={{ paddingTop: 0 }}
                                type="file"
                                accept="application/pdf"
                                onChange={async (e) => {
                                  document.getElementById(
                                    "contratoLabel"
                                  ).style.borderColor = Colors.ativo;
                                  document.getElementById(
                                    "contrato"
                                  ).style.color = Colors.textFields;
                                  const file = e.target.files[0];

                                  const reader = new FileReader();

                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    setContrato(reader.result);
                                    const pdfContentType = "application/pdf";
                                    const base64toBlob = (data) => {
                                      // Cut the prefix `data:application/pdf;base64` from the raw base 64
                                      const base64WithoutPrefix = data.substr(
                                        `data:${pdfContentType};base64,`.length
                                      );

                                      const bytes = atob(base64WithoutPrefix);
                                      let length = bytes.length;
                                      let out = new Uint8Array(length);

                                      while (length--) {
                                        out[length] = bytes.charCodeAt(length);
                                      }

                                      return new Blob([out], {
                                        type: pdfContentType,
                                      });
                                    };
                                    const url = URL.createObjectURL(
                                      base64toBlob(reader.result)
                                    );
                                    setContrato2(url);
                                  };
                                }}
                              ></input>
                              {contrato2.length > 0 && (
                                <div
                                  style={{
                                    borderWidth: "0.5px",
                                    borderStyle: "solid",
                                    borderColor: Colors.textFields,
                                    height: "600px",
                                    marginTop: 10,
                                    borderRadius: "10px",
                                    padding: "10px",
                                  }}
                                >
                                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                                    <Viewer fileUrl={contrato2} />
                                  </Worker>
                                </div>
                              )}
                            </FormControl>
                          </div>
                        </Box>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* Terceiro Passo - Informações sobre a Piscina */}
              {activeStep == 2 && (
                <div>
                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Piscina */}
                      <h5 className="h5List">{t("piscina")}</h5>
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("nome")}
                            value={nomePiscina}
                            error={erroNomePiscina}
                            onChange={(e) => {
                              setNomePiscina(e.target.value);
                              setErroNomePiscina(false);
                            }}
                          />
                        </FormControl>
                        {/* NIF */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("nif")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9);
                            }}
                            value={nifPiscina}
                            error={erroNifPiscina}
                            helperText={mensagemErroNifPiscina}
                            onChange={(e) => {
                              setNifPiscina(e.target.value);
                              setErroNifPiscina(false);
                              setMensagemErroNifPiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Email */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("email")}
                            value={emailPiscina}
                            error={erroEmailPiscina}
                            helperText={mensagemErroEmailPiscina}
                            onChange={(e) => {
                              setEmailPiscina(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroEmailPiscina(false);
                              setMensagemErroEmailPiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={edicao}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={telefonePiscina}
                            error={erroTelefonePiscina}
                            helperText={mensagemErroTelefonePiscina}
                            onChange={(e) => {
                              setTelefonePiscina(e);
                              setErroTelefonePiscina(false);
                              setMensagemErroTelefonePiscina("");
                            }}
                            inputComponent={PhoneNumber}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={edicao}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={telefone2Piscina}
                            error={erroTelefone2Piscina}
                            helperText={mensagemErroTelefone2Piscina}
                            onChange={(e) => {
                              setTelefone2Piscina(e);
                              setErroTelefone2Piscina(false);
                              setMensagemErroTelefone2Piscina("");
                            }}
                            inputComponent={PhoneNumber2}
                          />
                        </FormControl>
                        {/* Rua */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("rua")}
                            value={ruaPiscina}
                            error={erroRuaPiscina}
                            onChange={(e) => {
                              setRuaPiscina(e.target.value);
                              setErroRuaPiscina(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            format="####-###"
                            value={codigoPostalPiscina}
                            error={erroCodigoPostalPiscina}
                            helperText={mensagemErroCodigoPostalPiscina}
                            onChange={(e) => {
                              setCodigoPostalPiscina(e.target.value);
                              setErroCodigoPostalPiscina(false);
                              setMensagemErroCodigoPostalPiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Cidade */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("cidade")}
                            value={cidadePiscina}
                            error={erroCidadePiscina}
                            onChange={(e) => {
                              setCidadePiscina(e.target.value);
                              setErroCidadePiscina(false);
                            }}
                          />
                        </FormControl>
                        {/* Distrito */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("distrito")}
                            value={distritoPiscina}
                            error={erroDistritoPiscina}
                            onChange={(e) => {
                              setDistritoPiscina(e.target.value);
                              setErroDistritoPiscina(false);
                            }}
                          />
                        </FormControl>
                        {/* País */}
                        <FormControl className="formTextField3" fullWidth>
                          <Autocomplete
                            label={t("pais")}
                            variant="standard"
                            options={options}
                            disabled={edicao}
                            value={paisPiscina}
                            onChange={(event, newValue) => {
                              setPaisPiscina(newValue);
                              setErroPaisPiscina(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("pais")}
                                variant="standard"
                                error={erroPaisPiscina}
                                fullWidth
                                required
                              />
                            )}
                          />
                        </FormControl>
                        {/* Latitude */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            disabled={edicao}
                            label={t("latitude")}
                            placeholder="-34.11242"
                            variant="standard"
                            value={latitudePiscina}
                            options={options}
                            error={erroLatitudePiscina}
                            helperText={mensagemErroLatitudePiscina}
                            onChange={(e) => {
                              setLatitudePiscina(e.target.value);
                              setErroLatitudePiscina(false);
                              setMensagemErroLatitudePiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Longitude */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            label={t("longitude")}
                            placeholder="-58.11547"
                            variant="standard"
                            options={options}
                            value={longitudePiscina}
                            error={erroLongitudePiscina}
                            helperText={mensagemErroLongitudePiscina}
                            onChange={(e) => {
                              setLongitudePiscina(e.target.value);
                              setErroLongitudePiscina(false);
                              setMensagemErroLongitudePiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Foto */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="tituloFotoPiscina"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("foto")} *
                          </p>
                          <label
                            id="fotoPiscina"
                            htmlFor="file-upload"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />{" "}
                            {t("carregarImagem")}
                          </label>
                          <input
                            id="file-upload"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="image/*"
                            onChange={async (e) => {
                              document.getElementById(
                                "fotoPiscina"
                              ).style.borderColor = Colors.ativo;
                              document.getElementById(
                                "tituloFotoPiscina"
                              ).style.color = Colors.textFields;
                              const file = e.target.files[0];

                              const reader = new FileReader();

                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                setFotoPiscina(reader.result);
                              };
                            }}
                          ></input>
                          {/* Imagem carregada */}
                          <div>
                            {fotoPiscina.length > 0 && (
                              <img
                                style={{ marginTop: 10 }}
                                width={150}
                                height={150}
                                src={fotoPiscina}
                              />
                            )}
                          </div>
                        </FormControl>
                        {/* Galeria */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            style={{
                              marginTop: 10,
                              color: "#858585",
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                              marginBottom: 0,
                            }}
                          >
                            {t("galeria")} - {t("max6Fotos")}
                          </p>
                          <p
                            style={{
                              marginTop: 2,
                              color: "#858585",
                              fontSize: 12,
                              textAlign: "left",
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ padding: 0, marginRight: 5 }}
                              icon={faCircleInfo}
                            />
                            {t("tamanhoImagens")}
                          </p>
                          <label
                            id="galeriaPiscina"
                            htmlFor="file-upload2"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />{" "}
                            {t("carregarImagens")}
                          </label>
                          <input
                            id="file-upload2"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={async (e) => {
                              document.getElementById(
                                "galeriaPiscina"
                              ).style.borderColor = Colors.ativo;
                              setFoto1([]);
                              setFoto2([]);
                              setFoto3([]);
                              setFoto4([]);
                              setFoto5([]);
                              setFoto6([]);

                              let imagem1 = e.target.files[0];
                              let imagem2 = e.target.files[1];
                              let imagem3 = e.target.files[2];
                              let imagem4 = e.target.files[3];
                              let imagem5 = e.target.files[4];
                              let imagem6 = e.target.files[5];
                              if (imagem1) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem1);

                                reader.onload = () => {
                                  setFoto1(reader.result);
                                };
                              }
                              if (imagem2) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem2);

                                reader.onload = () => {
                                  setFoto2(reader.result);
                                };
                              }
                              if (imagem3) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem3);

                                reader.onload = () => {
                                  setFoto3(reader.result);
                                };
                              }
                              if (imagem4) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem4);

                                reader.onload = () => {
                                  setFoto4(reader.result);
                                };
                              }
                              if (imagem5) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem5);

                                reader.onload = () => {
                                  setFoto5(reader.result);
                                };
                              }
                              if (imagem6) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem6);

                                reader.onload = () => {
                                  setFoto6(reader.result);
                                };
                              }
                            }}
                          ></input>
                          {/* Imagem carregada */}
                          <div>
                            {/* Foto 1 */}
                            {foto1.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                width={150}
                                height={150}
                                src={foto1}
                              />
                            )}

                            {/* Foto 2 */}
                            {foto2.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                width={150}
                                height={150}
                                src={foto2}
                              />
                            )}

                            {/* Foto 3 */}
                            {foto3.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                width={150}
                                height={150}
                                src={foto3}
                              />
                            )}

                            {/* Foto 4 */}
                            {foto4.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                width={150}
                                height={150}
                                src={foto4}
                              />
                            )}

                            {/* Foto 5 */}
                            {foto5.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                width={150}
                                height={150}
                                src={foto5}
                              />
                            )}

                            {/* Foto 6 */}
                            {foto6.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                width={150}
                                height={150}
                                src={foto6}
                              />
                            )}
                          </div>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                </div>
              )}
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <div className="buttonBack">
                        <Button
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          {t("anterior")}
                        </Button>
                      </div>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <div className="buttonNext">
                        <Button
                          variant="contained"
                          onClick={() => validacao1()}
                          sx={{ mr: 1 }}
                        >
                          {t("seguinte")}
                        </Button>
                      </div>
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </div>
        ) : (
          <div>
            <div className="formPage">
              <Box className="formTextField">
                {/* Responsável */}
                <h5 className="h5List2">{t("entidadeLegal")}</h5>
                <div className="verificarDadosDiv">
                  <p className="verificarDados">
                    {t("nome")}: {nomeEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("nif")}: {nifEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("email")}: {emailEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("telefone")}: {telefoneEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("rua")}: {ruaEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("codigoPostal")}: {codigoPostalEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("cidade")}: {cidadeEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("distrito")}: {distritoEntidade}
                  </p>
                  <p className="verificarDados">
                    {t("pais")}: {paisEntidade.label}
                  </p>
                </div>
                <h5 className="h5List2">{t("piscina")}</h5>
                <div className="verificarDadosDiv">
                  <p className="verificarDados">
                    {t("nome")}: {nomePiscina}
                  </p>
                  <p className="verificarDados">
                    {t("nif")}: {nifPiscina}
                  </p>
                  <p className="verificarDados">
                    {t("email")}: {emailPiscina}
                  </p>
                  <p className="verificarDados">
                    {t("telefone")}: {telefonePiscina}
                  </p>
                  {telefone2Piscina != "" && (
                    <p className="verificarDados">
                      {t("telefone")} 2: {telefone2Piscina}
                    </p>
                  )}
                  <p className="verificarDados">
                    {t("rua")}: {ruaPiscina}
                  </p>
                  <p className="verificarDados">
                    {t("codigoPostal")}: {codigoPostalPiscina}
                  </p>
                  <p className="verificarDados">
                    {t("cidade")}: {cidadePiscina}
                  </p>
                  <p className="verificarDados">
                    {t("distrito")}: {distritoPiscina}
                  </p>
                  <p className="verificarDados">
                    {t("pais")}: {paisPiscina.label}
                  </p>
                  <p className="verificarDados">
                    {t("latitude")}: {latitudePiscina}
                  </p>
                  <p className="verificarDados">
                    {t("longitude")}: {longitudePiscina}
                  </p>
                  <p className="verificarDados">
                    {t("foto")}:{" "}
                    <div>
                      {fotoPiscina.length > 0 && (
                        <img
                          style={{ marginTop: 10 }}
                          width={150}
                          height={150}
                          src={fotoPiscina}
                        />
                      )}
                    </div>
                  </p>
                  {foto1.length > 0 ? (
                    <p className="verificarDados">
                      {t("galeria")}:{" "}
                      <div>
                        {/* Foto 1 */}
                        {foto1.length > 0 && (
                          <img
                            style={{
                              marginTop: 10,
                              marginRight: 5,
                            }}
                            width={150}
                            height={150}
                            src={foto1}
                          />
                        )}

                        {/* Foto 2 */}
                        {foto2.length > 0 && (
                          <img
                            style={{
                              marginTop: 10,
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                            width={150}
                            height={150}
                            src={foto2}
                          />
                        )}

                        {/* Foto 3 */}
                        {foto3.length > 0 && (
                          <img
                            style={{
                              marginTop: 10,
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                            width={150}
                            height={150}
                            src={foto3}
                          />
                        )}

                        {/* Foto 4 */}
                        {foto4.length > 0 && (
                          <img
                            style={{
                              marginTop: 10,
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                            width={150}
                            height={150}
                            src={foto4}
                          />
                        )}

                        {/* Foto 5 */}
                        {foto5.length > 0 && (
                          <img
                            style={{
                              marginTop: 10,
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                            width={150}
                            height={150}
                            src={foto5}
                          />
                        )}

                        {/* Foto 6 */}
                        {foto6.length > 0 && (
                          <img
                            style={{
                              marginTop: 10,
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                            width={150}
                            height={150}
                            src={foto6}
                          />
                        )}
                      </div>
                    </p>
                  ) : null}
                </div>
                <h5 className="h5List2">{t("responsavel")}</h5>
                <div className="verificarDadosDiv">
                  <p className="verificarDados">
                    {t("nome")}: {nomeResponsavel}
                  </p>
                  <p className="verificarDados">
                    {t("email")}: {emailResponsavel}
                  </p>
                  <p className="verificarDados">
                    {t("telefone")}: {telefonePiscina}
                  </p>
                  <p className="verificarDados">
                    {t("cargo")}: {cargoResponsavel}
                  </p>
                  {fotoResponsavel.length > 0 ? (
                    <p className="verificarDados">
                      {t("foto")}:{" "}
                      <div>
                        {fotoResponsavel.length > 0 && (
                          <img
                            style={{ marginTop: 10 }}
                            width={150}
                            height={150}
                            src={fotoResponsavel}
                          />
                        )}
                      </div>
                    </p>
                  ) : null}
                </div>
                <h5 className="h5List2">{t("contrato")}</h5>
                <div className="verificarDadosDiv">
                  <p className="verificarDados">
                    {t("contrato")}:{" "}
                    {contrato2.length > 0 && (
                      <div
                        style={{
                          borderWidth: "0.5px",
                          borderStyle: "solid",
                          borderColor: Colors.textFields,
                          height: "600px",
                          marginTop: 10,
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                          <Viewer fileUrl={contrato2} />
                        </Worker>
                      </div>
                    )}
                  </p>
                </div>
                <h5 className="h5List2">{t("termoesCondicoes")}</h5>
                <div className="verificarDadosDiv">
                  <p className="verificarDados">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Rhoncus dolor purus non enim. Dolor sit amet
                    consectetur adipiscing elit pellentesque habitant. Sapien
                    nec sagittis aliquam malesuada bibendum. Facilisis volutpat
                    est velit egestas dui id ornare arcu. Ut morbi tincidunt
                    augue interdum velit euismod in pellentesque massa. Aliquam
                    purus sit amet luctus. Cursus in hac habitasse platea
                    dictumst quisque sagittis. Senectus et netus et malesuada
                    fames. Platea dictumst quisque sagittis purus. Tincidunt dui
                    ut ornare lectus. Malesuada pellentesque elit eget gravida
                    cum sociis natoque penatibus. Lacus laoreet non curabitur
                    gravida arcu ac. Suspendisse interdum consectetur libero id
                    faucibus. Lorem ipsum dolor sit amet consectetur adipiscing
                    elit ut aliquam. Magna etiam tempor orci eu. Consectetur
                    lorem donec massa sapien faucibus et molestie ac feugiat.
                    Sit amet porttitor eget dolor morbi non arcu risus quis.
                    Proin libero nunc consequat interdum varius sit amet.
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={termos}
                        onChange={(e) => {
                          setTermos(!termos);
                        }}
                      />
                    }
                    label={t("aceitarTermos")}
                  />
                </div>
              </Box>
            </div>
          </div>
        )}
        {activeStep == 2 && (
          <div className="buttonAdicionar">
            <Button
              variant="contained"
              onClick={() => {
                validacao1();
              }}
            >
              {t("adicionar")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default CriarPiscina;
