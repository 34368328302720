import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";


export function listaEspacos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("ocupa_espaco/list", dados, token);
}

export function listaTiposEspacos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("ocupa_espaco/listtipos", dados, token);
}


export function adicionarEspacos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("ocupa_espaco/", dados, token);
}


export function editarEspacos(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("ocupa_espaco/", dados, token);
}


export function eliminarEspacos(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("ocupa_espaco/", dados, token);
}

export function getEspacos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("ocupa_espaco/id", dados, token);
}
