import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";

//Lista turmas
export function listaTurmas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("turmas/list", dados, token);
}

//Adicionar aula
export function adicionarTurma(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("turmas/", dados, token);
}

//Editar aula
export function editarTurma(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("turmas/", dados, token);
}

//Eliminar aula
export function eliminarTurma(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("turmas/", dados, token);
}

//Get aula
export function getTurma(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("turmas/id", dados, token);
}
