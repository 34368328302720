import Cookies from "js-cookie";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box } from "@mui/system";
import naobtn from "../../images/BotaoNao.svg";
import simbtn from "../../images/BotaoSim.svg";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import editarbtn from "../../images/BotaoEditar.svg";

import eliminarbtn from "../../images/BotaoEliminar.svg";

import {
  Badge,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import {
  faMobile,
  faCreditCard,
  faTicket,
  faMoneyBillTransfer,
  faGraduationCap,
  faPersonSwimming,
  faCalendarDay,
  faCalendarDays,
  faPlus,
  faClose,
  faWaterLadder,
  faMoneyBill1Wave,
  faPeopleRoof,
  faMagnifyingGlass,
  faQrcode,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proximasMarcacoes } from "../../api/requests/Marcacoes";
import IconButton from "@mui/material/IconButton";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  Select,
  DialogTitle,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import { listaEspacos } from "../../api/requests/OcupaEspaco";
import {
  calendarioF,
  cancelarEvento,
  cancelarEventoEmail,
} from "../../api/requests/PiscinaAgua";
import Nav from "../../components/Nav";
import { enviarEmailNovoPedido2, postPedidos } from "../../api/requests/Pedidos";
import { listaProfessores } from "../../api/requests/PiscinaAgua";
import { MenuItem } from "@react-pdf-viewer/core";


function CalendarioAdmin() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [idu, setIdU] = useState("");
  const [idPiscina, setIdPiscina] = useState("");

  const [eventInfo, setEventInfo] = useState(null);
  const [marcacoes, setMarcacoes] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setMotivoSuspensao("");
    setCancelar(false);
    setTrocarProf(false)
    setEventInfo(null);
  };
  

  const handleProfessorChange = (event) => {
    setProfessorEscolhido(event.target.value);
    setErroProfessor(false);
  };

  const [professores, setProfessores] = useState([]);
  const [professorEscolhido, setProfessorEscolhido] = useState("");

  const [cancelar, setCancelar] = useState(false);
  const [trocarProf, setTrocarProf] = useState(false);

  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleEventClick = (info) => {
    setEventInfo(info);

    setOpen(true);
  };
  useEffect(() => {
    const elements = document.getElementsByClassName("fc-event");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].getElementsByClassName("fc-cancelado")[0]) {
        elements[i].style.setProperty(
          "background-color",
          "#F04643",
          "important"
        );
        elements[i].style.setProperty("color", "white");
      }
    }
  }, [marcacoes]);
  const [weekDateRange, setWeekDateRange] = useState("");
  const calendarRef = useRef(null);
  const [bookedDays, setBookedDays] = useState([]);

  let today = new Date();
  const [freeze, setFreeze] = useState(false);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdU(id);
    setIdPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    } 
    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });


    calendarioF({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          let dados = res.data.data;
          dados.forEach((element) => {
            const dataParts = element.dia.split("/");
            const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
            const data = new Date(dataFormatada);
            const dataInicio = `${data.toISOString().substr(0, 10)}T${
              element.horaInicio
            }`;
            const dataFim = `${data.toISOString().substr(0, 10)}T${
              element.horaFim
            }`;
            const hoje = new Date().toISOString();
            const linha = {
              id: element.id_evento_aula,
              title: element.nome,
              description: element.nomePiscina,
              piscina: element.id_piscina=undefined ? 0 : element.id_piscina,
              cancelado: element.cancelado,
              tipo: element.id_turma != undefined ? 1 : 2,
              start: dataInicio,
              end: dataFim,
              cancelado: element.cancelado,
              id_turma:element.id_turma=undefined ? 0 :element.id_turma,

              color:
                element.cancelado == 1
                  ? "#F04643"
                  : dataFim < hoje
                  ? "#54DE90"
                  : "#00C4F5",
            };
            arrayFinal.push(linha);
          });
          setMarcacoes(arrayFinal);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const titleFormat = () => {
    const prefix = t("estasemana") + " (" + weekDateRange + ")";
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat(
      i18n.language,
      options
    ).format(date);

    return prefix;
  };
  const dayHeaderFormat = {
    weekday: "short",
    month: "numeric",
    day: "numeric",
  };
  const handleTodayClick = () => {
    calendarRef.current.getApi().today();
  };

  function handleEventMount(info) {
    const eventElement = info.el;

    eventElement.addEventListener("mouseover", () => {
      eventElement.classList.add("event-pointer");
    });

    eventElement.addEventListener("mouseout", () => {
      eventElement.classList.remove("event-pointer");
    });
  }

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    );
    calendarApi.gotoDate(newDate);
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 7
    );
    calendarApi.gotoDate(newDate);
  };

  function trocaProf() {
    if (motivoSuspensao) {
      setMotivoSuspensao("");
      setFreeze(true);
      // let id_piscina = eventInfo.event._def.extendedProps.piscina;
      let id_turma = eventInfo.event._def.extendedProps.id_turma;

      postPedidos({
        //Id da aula
        id: eventInfo.event.id,
        acao: 4,
        motivo: motivoSuspensao,
        tipo: 3,
        data: moment(eventInfo.event.start).format("YYYY-MM-DD"),
        hora_inicio: eventInfo.event.start.toLocaleTimeString(),
        hora_fim: eventInfo.event.end.toLocaleTimeString(),
        id_piscina: eventInfo.event._def.extendedProps.piscina,
        id_turma: eventInfo.event._def.extendedProps.id_turma,
        id_professor:professorEscolhido
      }).then((res) => {
        if (res.success) {
          setTimeout(async function () {
            enviarEmailNovoPedido2({
              piscina: eventInfo.event._def.extendedProps.piscina,
            }).then((res2) => {
              if (res2.success) {
                setFreeze(false);
                setCancelar(false);
                setProfessorEscolhido("")
                setMotivoSuspensao("")
                setOpen(false);
                toast.success(res.data.msg);
              } else {
                setFreeze(false);
                setProfessorEscolhido("")
                setMotivoSuspensao("")
                setCancelar(false);
                setOpen(false);
                toast.error(res.error);
              }
            });
          }, 3000);
        } else {
          setFreeze(false);
          setCancelar(false);
          setOpen(false);
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      });
    } else {
      setFreeze(false)
      setErroMotivoSuspensao(true);
    }
  }

  function cancelarAula() {
    if (motivoSuspensao) {
      setFreeze(true);
      let id_piscina = eventInfo.event._def.extendedProps.piscina;

      cancelarEvento({
        tipo: eventInfo.event._def.extendedProps.tipo,
        id: eventInfo.event.id,
        data: moment(eventInfo.event.start).format("YYYY-MM-DD"),
        hora_inicio: eventInfo.event.start.toLocaleTimeString(),
        hora_fim: eventInfo.event.end.toLocaleTimeString(),
        obs: motivoSuspensao,

      })
        .then((res) => {
          let idCancelamento = res.data.id;
          if (res.success) {
            setTimeout(async function () {
              cancelarEventoEmail({
                id_cancelamento: idCancelamento,
              }).then((res3) => {
                if (res3.success) {
                  setFreeze(false);
                  setOpen(false);
                  navigate("/dashboard_admin");
                  toast.success(t("pedidoAprovado"));
                } else {
                  setFreeze(false);
                  setOpen(false);
                  toast.error(res.error);
                }
              });
            }, 3000);
          } else {
            if (res.status == 500) {
              setFreeze(false)
              navigate("/500");
            }
            setFreeze(false);
            setOpen(false);
            toast.error(res.error);
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setFreeze(false)
      setErroMotivoSuspensao(true);
    }
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <div className="divTabela">
          <Paper
            elevation={0}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
              backgroundColor: "transparent",
            }}
          >
            {freeze && (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Dialog
                  open={true}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <DialogContent>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {t("aguarde")}
                    </Typography>
                    <CircularProgress disableShrink style={{ marginTop: 20 }} />
                  </DialogContent>
                </Dialog>
              </div>
            )}

            {/* <div className="topleftcliente">
            <IconButton
              onClick={goBack}
              size={window.innerWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
            <h1 className="dashboardTitle2">{t("proximasaulaspiscina")}</h1>

            <div className="calendar-container">
              <div className="calendar-wrapper">
                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  scrollTime={true}
                  contentHeight={420}
                  stickyFooterScrollbar={true}
                  titleFormat={titleFormat}
                  headerToolbar={{
                    start: isMobile ? "title" : "",
                    center: isMobile ? "prev next today" : "title",
                    end: isMobile ? "" : "prev next today",
                  }}
                  ref={calendarRef}
                  plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    bootstrap5Plugin,
                  ]}
                  customButtons={{
                    prev: {
                      text: "<",
                      click: handlePrevClick,
                      className: "custom-calendar-button",
                    },
                    next: {
                      text: ">",
                      click: handleNextClick,
                      className: "custom-calendar-button",
                    },
                    today: {
                      text: t("hoje"),
                      click: handleTodayClick,
                      classNames: "custom-calendar-button",
                    },
                  }}
                  dayHeaderFormat={dayHeaderFormat}
                  stickyHeaderDates={false}
                  initialView="listWeek"
                  selectable={true}
                  events={marcacoes}
                  eventDisplay="block"
                  eventClick={handleEventClick}
                  eventDidMount={handleEventMount}
                  datesSet={(dates) => {
                    if (Cookies.get("language") == "pt") {
                      moment.locale("pt-br");
                      const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    } else if (Cookies.get("language") == "en") {
                      moment.locale("en");
                      const startOfWeek = moment(dates.start).add(1, "day"); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    } else if (Cookies.get("language") == "es") {
                      moment.locale("es");
                      const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    }
                  }}
                  eventContent={(arg) => {
                    const { event } = arg;
                    const { title, extendedProps } = event;
                    const isCancelled = extendedProps.cancelado;
                    const canceladoElement = isCancelled
                      ? `<span className="fc-cancelado" style="background-color: #F04643; color: white; border-radius:4px">‎ ${t(
                          "cancelado".toUpperCase()
                        )}‎ ‎ </span>`
                      : "";
                    const elements =
                      document.getElementsByClassName("fc-event");
                    for (let i = 0; i < elements.length; i++) {
                      if (
                        elements[i].getElementsByClassName("fc-cancelado")[0]
                      ) {
                        //elements[i].style.setProperty("pointer-events", "none", "important");
                        elements[i].style.setProperty(
                          "background-color",
                          "#F04643"
                        );
                        elements[i].style.setProperty("color", "white");
                      }
                    }
                    return {
                      html: `<div className="fc-content">${title} ${canceladoElement}</div>`,
                    };
                  }}
                />
              </div>
            </div>

            {open && (
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                  style: {
                    width: 600,
                    height: trocarProf ? 408 : 308,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  style={{
                    backgroundColor: eventInfo.event.backgroundColor,
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {eventInfo && eventInfo.event.title}
                    {eventInfo.event.extendedProps.cancelado == 1 ? (
                      <span className="fc-cancelado2">‎ 
                        {t("cancelado").toUpperCase()}‎ ‎ 
                      </span>
                    ) : null}
                  </div>
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleClose}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                {!cancelar  && !trocarProf ? (
                  <DialogContent>
                    <div style={{ marginTop: "20px" }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        style={{
                          fontWeight: "bold",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {eventInfo &&
                          eventInfo.event.start.toLocaleDateString()}
                      </Typography>
                      <Typography gutterBottom>
                        <span
                          style={{
                            fontWeight: "100 !important",
                            color: "black",
                          }}
                        >
                          {t("inicio")}:{" "}
                        </span>
                        <span style={{ fontWeight: "900", color: "black" }}>
                          {eventInfo &&
                            eventInfo.event.start.toLocaleTimeString("en-US", {
                              hour12: false,
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          h
                        </span>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        <span
                          style={{
                            fontWeight: "100 !important",
                            color: "black",
                          }}
                        >
                          {t("fim")}:{" "}
                        </span>
                        <span style={{ fontWeight: "900", color: "black" }}>
                          {eventInfo &&
                            eventInfo.event.end.toLocaleTimeString("en-US", {
                              hour12: false,
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          h
                        </span>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        <span
                          style={{
                            fontWeight: "100 !important",
                            color: "black",
                          }}
                        >
                          {t("local")}:{" "}
                        </span>
                        <span style={{ fontWeight: "900", color: "black" }}>
                          {eventInfo &&
                            eventInfo.event.extendedProps.description}
                        </span>
                      </Typography>
                      {eventInfo.event.start > today &&
                        eventInfo.event.extendedProps.cancelado == 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: 10,
                            }}
                          >
                            <Button
                              className="btnEdicao"
                              style={{
                                backgroundImage: `url(${eliminarbtn})`,
                                width: 315,
                                height: 40,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "10px auto",
                              }}
                              onClick={() => {
                                setCancelar(true);
                              }}
                            >
                              {t("cancelar")}
                            </Button>
                          {eventInfo.event.extendedProps.tipo == 1 ? (
                            <Button
                              className="btnEdicao"
                              style={{
                                backgroundImage:isMobile
                                ? `url(${editarbtnpeq})`
                                : `url(${editarbtn})`,
                                width: 315,
                                height: 40,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "10px auto",
                              }}
                              onClick={() => {
                                setTrocarProf(true);
                              }}
                            >
                              {t("trocarProf")}
                            </Button>
):null}
                          </div>
                        )}
                    </div>
                  </DialogContent>
                ) : cancelar ?(
                  <DialogContent style={{ marginTop: 10 }}>
                    {t("justificacaoCancelamento")}*
                    <FormControl fullWidth>
                      <TextField
                        style={{ textAlign: "left", marginTop: 10 }}
                        variant="outlined"
                        multiline
                        rows={4}
                        value={motivoSuspensao}
                        error={erroMotivoSuspensao}
                        onChange={(e) => {
                          setMotivoSuspensao(e.target.value);
                          setErroMotivoSuspensao(false);
                        }}
                      />
                    </FormControl>
                  </DialogContent>
                )
                : trocarProf ? (

                  <DialogContent style={{ marginTop: 10 }}>
                  {t("justificacaoCancelamento")}*
                  <FormControl fullWidth>
                    <TextField
                      style={{ textAlign: "left", marginTop: 10 }}
                      variant="outlined"
                      multiline
                      rows={4}
                      value={motivoSuspensao}
                      error={erroMotivoSuspensao}
                      onChange={(e) => {
                        setMotivoSuspensao(e.target.value);
                        setErroMotivoSuspensao(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                        <InputLabel className="formTextField22">
                          {t("escolhaprofessor")}*
                        </InputLabel>
                        <Select
                          value={professorEscolhido}
                          onChange={handleProfessorChange}
                          fullWidth
                          variant="standard"
                        >
                          {professores.map((professor) => (
                            <MenuItem key={professor.id} value={professor.id}>
                              {professor.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                </DialogContent>
                ):null
            

              
              }
                {cancelar && (
                  <DialogActions>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 198,
                        height: 50,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        cancelarAula();
                      }}
                    >
                      {t("confirmar")}
                    </Button>
                  </DialogActions>
                )}
                {trocarProf && (
                  <DialogActions>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 198,
                        height: 50,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        trocaProf();
                      }}
                    >
                      {t("confirmar")}
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default CalendarioAdmin;
