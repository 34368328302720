import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { login } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FooterExterno from "../components/FooterExterno";
import { width } from "@mui/system";

function Login() {
  function goBack() {
    navigate("/");
  }

  //Variáveis
  const [email, setEmail] = useState("");
  const [palavraPasse, setPalavraPasse] = useState("");

  //Validações
  const [erroEmail, setErroEmail] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 799); // Defina o limite de largura para o que você considera um "telefone"
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      if (validacaoEmail) {
        setErroEmail(false);
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        setErroEmail(true);
        setMensagemErroEmail(t("emailInvalido"));
      }
    }
  }, [email, palavraPasse]);

  async function VerificaLogin() {
    if (email && palavraPasse && !erroEmail) {
      const hashedPassword = bcrypt.hashSync(
        palavraPasse,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );
      await login({
        email: email,
        password: hashedPassword,
      })
        .then(async (res) => {
          if (res.success) {
            //Definir cookie
            //Tempo em dias - 1hora
            Cookies.set("token", res.data.accessToken, {
              expires: 0.0416666667,
            });
            Cookies.set("refresh_token", res.data.refreshToken, {
              expires: 0.0416666667,
            });
            Cookies.set("language", i18n.language, {
              expires: 0.0416666667,
            });
            //Guardar id e nome do utilizador em Cookies
            Cookies.set("uId", res.data.id, {
              expires: 0.0416666667,
            });
            Cookies.set("uName", res.data.nome, {
              expires: 0.0416666667,
            });
            Cookies.set("uEmail", email, {
              expires: 0.0416666667,
            });
            Cookies.set("uKey", res.data.chave, {
              expires: 0.0416666667,
            });
            Cookies.set("uData", res.data.dados, {
              expires: 0.0416666667,
            });

            //Guardar o tipo de utilizador
            // SUPERADMIN
            if (res.data.id_superadmin == 1) {
              Cookies.set("utilizador", "superadmin", {
                expires: 0.0416666667,
              });
              // toast.success(
              //   t("loginSucesso") +
              //     " " +
              //     t("bemVindoUtilizador") +
              //     " " +
              //     res.data.nome +
              //     "!"
              // );
              toast.error(t("utilizadorBloqueado"));

              //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              if (res.data.dados) {
                // navigate("/dashboard_superadmin");
                toast.error(t("utilizadorBloqueado"));
              } else {
                navigate("/change_user_data");
              }
            }
            // SUPERCOMERCIAL
            else if (res.data.id_supercomercial == 1) {
              Cookies.set("utilizador", "supercomercial", {
                expires: 0.0416666667,
              });
              // toast.success(
              //   t("loginSucesso") +
              //     " " +
              //     t("bemVindoUtilizador") +
              //     " " +
              //     res.data.nome +
              //     "!"
              // );
              toast.error(t("utilizadorBloqueado"));

              //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              if (res.data.dados) {
                // navigate("/dashboard_supercommercial");
                toast.error(t("utilizadorBloqueado"));
              } else {
                navigate("/change_user_data");
              }
            }
            // COMERCIAL
            else if (res.data.id_comercial == 1) {
              Cookies.set("utilizador", "comercial", {
                expires: 0.0416666667,
              });
              // toast.success(
              //   t("loginSucesso") +
              //     " " +
              //     t("bemVindoUtilizador") +
              //     " " +
              //     res.data.nome +
              //     "!"
              // );
              toast.error(t("utilizadorBloqueado"));

              //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              if (res.data.dados) {
                // navigate("/dashboard_commercial");
                toast.error(t("utilizadorBloqueado"));
              } else {
                navigate("/change_user_data");
              }
            }
            // ADMIN
            else if (res.data.id_admin == 1 && res.data.id_cliente== 0) {
              // Cookies.set("utilizador", "admin", {
              //   expires: 0.0416666667,
              // });
              // Cookies.set("id_piscina", res.data.id_piscina, {
              //   expires: 0.0416666667,
              // });
              // Cookies.set("id_entidade_exploradora", res.data.id_entidade, {
              //   expires: 0.0416666667,
              // });
              // toast.success(
              //   t("loginSucesso") +
              //     " " +
              //     t("bemVindoUtilizador") +
              //     " " +
              //     res.data.nome +
              //     "!"
              // );
              // //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              // if (res.data.dados) {
              //   navigate("/dashboard_admin");
              // } else {
              //   navigate("/change_user_data");
              // }
              toast.warn(t("loginErradoGestao"))
            }
            // FUNCIONÁRIO
            else if (res.data.id_funcionario == 1 && res.data.id_cliente==0) {
              //caso seja funcionário vamos guardar o id_piscina a que o mesmo tem acesso e o id_entidade_exploradora a que pertence
              // Cookies.set("id_piscina", res.data.id_piscina, {
              //   expires: 0.0416666667,
              // });
              // Cookies.set("id_entidade_exploradora", res.data.id_entidade, {
              //   expires: 0.0416666667,
              // });
              // Cookies.set("utilizador", "funcionario", {
              //   expires: 0.0416666667,
              // });
              // //Guardar privilégios do funcionário
              // Cookies.set("privilegios", JSON.stringify(res.data.privilegios), {
              //   expires: 0.0416666667,
              // });
              // toast.success(
              //   t("loginSucesso") +
              //     " " +
              //     t("bemVindoUtilizador") +
              //     " " +
              //     res.data.nome +
              //     "!"
              // );
              // //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              // if (res.data.dados) {
              //   navigate("/dashboard_employee");
              // } else {
              //   navigate("/change_user_data");
              // }
              toast.warn(t("loginErradoGestao"))
            }
            // PROFESSOR
            else if (res.data.id_professor == 1 && res.data.id_cliente == 0) {
              // Cookies.set("utilizador", "professor", {
              //   expires: 0.0416666667,
              // });
              // toast.success(
              //   t("loginSucesso") +
              //     " " +
              //     t("bemVindoUtilizador") +
              //     " " +
              //     res.data.nome +
              //     "!"
              // );
              // //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              // if (res.data.dados) {
              //   navigate("/dashboard_teacher");
              // } else {
              //   navigate("/change_user_data");
              // }
              toast.warn(t("loginErradoGestao"))
            }
            // CLIENTE
            else if (res.data.id_cliente == 1) {
              Cookies.set("utilizador", "cliente", {
                expires: 0.0416666667,
              });
              toast.success(
                t("loginSucesso") +
                  " " +
                  t("bemVindoUtilizador") +
                  " " +
                  res.data.nome +
                  "!"
              );
              //Se tiver dados obrigatórios por preencher vai diretamente para a página de alteração de dados do utilizador para preencher
              if (res.data.dados) {
                navigate("/dashboard_customer");
              } else {
                navigate("/change_user_data");
              }
            }
          } else {
            // if (res.status == 500) {
            //   navigate("/500");
            // }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          // toast.warn(t("erroWebservice"));
        });
    } else {
      if (!email && palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
      } else if (!palavraPasse && email) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
      } else if (!email && !palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
        setErroPassword(true);
      }
    }
  }

  return (
    <div className="bodyLogin2">
      <div className="session2">
        <div className="topleftrel" >
          <IconButton onClick={goBack} size={"small"}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={goBack}
              style={{ width: "25px", color: "black"}}
            ></FontAwesomeIcon>
          </IconButton>
        </div>
        <div className="topright">
          <IconButton onClick={handleOpenHelp} size={"small"}>
            {" "}
            <QuestionMarkCircleIcon style={{ width: "25px", color: "black" }} />
          </IconButton>
        </div>
        <form
          className="inicioCol"
          style={{ paddingBottom: isMobile ? "10%" : "30px" }}
          onSubmit={(e) => {
            e.preventDefault();
            VerificaLogin();
          }}
        >
          {/* Email */}
          <TextField
            style={{
              marginTop: "1%",
              marginBottom: "2%",
            }}
            className="formLogin"
            id="email"
            label={t("email")}
            variant="standard"
            required={true}
            placeholder="email@email.com"
            error={erroEmail}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            helperText={mensagemErroEmail}
            color={sucessoEmail}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
            }}
          />
          {/* Palavra-passe */}
          <TextField
            style={{
              marginTop: "1%",
              marginBottom: "3%",
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="formLogin"
            id="password"
            label={t("palavraPasse")}
            variant="standard"
            error={erroPassword}
            type={showPassword ? "text" : "password"}
            helperText={mensagemErroPalavraPasse}
            onChange={(e) => {
              setPalavraPasse(e.target.value);
              setErroPassword(false);
              setMensagemErroPalavraPasse("");
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    disableFocusRipple
                    style={{
                      justifyContent: "right",
                      paddingRight: 0,
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      color: isFocused ? "#171E24" : "#FAFAFA",
                    }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility size={isMobile ? "small" : "large"} />
                    ) : (
                      <VisibilityOff size={isMobile ? "small" : "large"} />
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          ></TextField>

          <button
            type="submit"
            className="iniciobtn"
            style={{ marginTop: "20px", marginBottom: isMobile ? 0 : "20px" }}
          >
            {t("iniciarSessao")}
          </button>

          <Link
            className="textlinklogin2"
            style={{ paddingTop: "2%", paddingBottom: isMobile ? 0 : "1%" }}
            to={"/recoverpassword"}
          >
            {t("recuperarPalavraPasse")}
          </Link>
          <Link
            className="textlinklogin2"
            style={{ paddingTop: "1%", paddingBottom: isMobile ? 0 : "3%" }}
            to={"/active_account_send_email"}
          >
            {t("reenviarEmailAtivacaoConta")}
          </Link>
        </form>
      </div>

      <Dialog
        open={openHelp}
        onClose={handleCloseHelp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
        <DialogContent>
          <p className="ajuda">
            Esta é a página de login, introduz o email e a password que
            utilizaste para criar a tua conta para teres acesso ao GoPool. No
            caso de ainda não te teres registado volta para a página anterior.{" "}
            <br></br>
            Se te esqueceste da tua password, segue o link por baixo do campo da
            palavra-passe para acederes á página de recuperação de password!
            <br></br>
            Caso ainda não tenhas ativado a tua conta podes seguir o link que
            reenvia o email para ativação de conta!
          </p>
        </DialogContent>
      </Dialog>
      <FooterExterno />
    </div>
  );
}
export default Login;
