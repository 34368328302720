import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  enviarEmailPostSuperComercial,
  getSuperComercial,
  postSuperComercial,
  putSuperComercial,
} from "../../api/requests/SuperComerciais";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { enviarEmailNovoPedido, postPedidos } from "../../api/requests/Pedidos";

function EditarSuperComercial() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idSuperComercial } = state || {};

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeSuperComercial, setNomeSuperComercial] = useState("");
  const [emailSuperComercial, setEmailSuperComercial] = useState("");
  const [telefoneSuperComercial, setTelefoneSuperComercial] = useState("");
  const [cargoSuperComercial, setCargoSuperComercial] = useState("");
  const [nifSuperComercial, setNifSuperComercial] = useState("");
  const [moradaSuperComercial, setMoradaSuperComercial] = useState("");
  const [codigoPostalSuperComercial, setCodigoPostalSuperComercial] =
    useState("");
  const [fotoSuperComercial, setFotoSuperComercial] = useState([]);
  const [statusSuperComercial, setStatusSuperComercial] = useState("");
  const [dataNascimentoSuperComercial, setDataNascimentoSuperComercial] =
    useState(new Date());
  const [dadosOriginais, setDadosOriginais] = useState([]);

  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  //Validações
  // Responsável
  const [erroNomeSuperComercial, setErroNomeSuperComercial] = useState("");
  const [erroEmailSuperComercial, setErroEmailSuperComercial] = useState("");
  const [erroTelefoneSuperComercial, setErroTelefoneSuperComercial] =
    useState("");
  const [erroCargoSuperComercial, setErroCargoSuperComercial] = useState("");
  const [erroNifSuperComercial, setErroNifSuperComercial] = useState("");
  const [erroMoradaSuperComercial, setErroMoradaSuperComercial] = useState("");
  const [erroCodigoPostalSuperComercial, setErroCodigoPostalSuperComercial] =
    useState("");
  const [erroNascimentoSuperComercial, setErroNascimentoSuperComercial] =
    useState(false);

  // Responsável
  const [mensagemErroEmailSuperComercial, setMensagemErroEmailSuperComercial] =
    useState("");
  const [
    mensagemErroTelefoneSuperComercial,
    setMensagemErroTelefoneSuperComercial,
  ] = useState("");
  const [mensagemErroNifSuperComercial, setMensagemErroNifSuperComercial] =
    useState("");
  const [
    mensagemErroCodigoPostalSuperComercial,
    setMensagemErroCodigoPostalSuperComercial,
  ] = useState("");
  const [
    mensagemErroNascimentoSuperComercial,
    setMensagemErroNascimentoSuperComercial,
  ] = useState("");

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  const [motivoEliminacao, setMotivoEliminacao] = useState("");
  const [erroMotivoEliminacao, setErroMotivoEliminacao] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getSuperComercial(idSuperComercial)
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          setDadosOriginais(dados);
          setNomeSuperComercial(dados.nome);
          setEmailSuperComercial(dados.email);
          setTelefoneSuperComercial(dados.contacto);
          setCargoSuperComercial(dados.cargo);
          setNifSuperComercial(dados.nif);
          setMoradaSuperComercial(dados.morada);
          setCodigoPostalSuperComercial(dados.codigo_postal);
          if (dados.foto) {
            setFotoSuperComercial(dados.foto);
          }
          setDataNascimentoSuperComercial(dados.data_nascimento_final);
          setStatusSuperComercial(dados.status);
          setMotivo(res.data.obs);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function editarSuperComercial() {
    setFreeze(true);
    putSuperComercial({
      id: idSuperComercial,
      nome: nomeSuperComercial,
      cargo: cargoSuperComercial,
      nif: nifSuperComercial,
      email: emailSuperComercial,
      contacto: telefoneSuperComercial,
      morada: moradaSuperComercial,
      codigo_postal: codigoPostalSuperComercial,
      foto: fotoSuperComercial.length == 0 ? null : fotoSuperComercial,
      data_nascimento: new Date(dataNascimentoSuperComercial)
        .toISOString()
        .split("T")[0],
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      nomeSuperComercial &&
      emailSuperComercial &&
      telefoneSuperComercial &&
      cargoSuperComercial &&
      nifSuperComercial &&
      moradaSuperComercial &&
      codigoPostalSuperComercial &&
      dataNascimentoSuperComercial
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailSuperComercial
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailSuperComercial(true);
        setMensagemErroEmailSuperComercial(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneSuperComercial)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneSuperComercial(true);
        setMensagemErroTelefoneSuperComercial(t("validacaoTelemovel"));
      }
      if (emailSuperComercial) {
        verificarEmail({ email: emailSuperComercial }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != idSuperComercial) {
                confirmarDadosPreenchidos = false;
                setErroEmailSuperComercial(true);
                setMensagemErroEmailSuperComercial(res.data.error);
              }
            }
          }
        });
      }
      // Validação do nif
      if (nifSuperComercial.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifSuperComercial(true);
        setMensagemErroNifSuperComercial(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalSuperComercial < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalSuperComercial(true);
        setMensagemErroCodigoPostalSuperComercial(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoSuperComercial);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoSuperComercial(true);
        setMensagemErroNascimentoSuperComercial(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarSuperComercial();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeSuperComercial) {
        setErroNomeSuperComercial(true);
      }
      //Validação do email
      if (!emailSuperComercial) {
        setErroEmailSuperComercial(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailSuperComercial
          );
        if (!validacaoEmail) {
          setErroEmailSuperComercial(true);
          setMensagemErroEmailSuperComercial(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailSuperComercial }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != idSuperComercial) {
                  setErroEmailSuperComercial(true);
                  setMensagemErroEmailSuperComercial(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneSuperComercial) {
        setErroTelefoneSuperComercial(true);
      } else {
        if (!isValidPhoneNumber(telefoneSuperComercial)) {
          setErroTelefoneSuperComercial(true);
          setMensagemErroTelefoneSuperComercial(t("validacaoTelemovel"));
        }
      }
      // Validação do cargo
      if (!cargoSuperComercial) {
        setErroCargoSuperComercial(true);
      }
      // Validação do nif
      if (!nifSuperComercial) {
        setErroNifSuperComercial(true);
      } else {
        if (nifSuperComercial.length < 9) {
          setErroNifSuperComercial(true);
          setMensagemErroNifSuperComercial(t("validacaoNif"));
        }
      }
      //Validação da morada
      if (!moradaSuperComercial) {
        setErroMoradaSuperComercial(true);
      }
      //Validação do código postal
      if (!codigoPostalSuperComercial) {
        setErroCodigoPostalSuperComercial(true);
      } else {
        if (codigoPostalSuperComercial < 7) {
          setErroCodigoPostalSuperComercial(true);
          setMensagemErroCodigoPostalSuperComercial(t("validacaoCodigoPostal"));
        }
      }
      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoSuperComercial);
      if (isNaN(isValidDate)) {
        setErroNascimentoSuperComercial(true);
        setMensagemErroNascimentoSuperComercial(t("dataInvalida"));
      }
    }
  }
  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
        "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
      "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeSuperComercial(dadosOriginais.nome);
    setEmailSuperComercial(dadosOriginais.email);
    setTelefoneSuperComercial(dadosOriginais.contacto);
    setCargoSuperComercial(dadosOriginais.cargo);
    setNifSuperComercial(dadosOriginais.nif);
    setMoradaSuperComercial(dadosOriginais.morada);
    setCodigoPostalSuperComercial(dadosOriginais.codigo_postal);
    setFotoSuperComercial(dadosOriginais.foto);
    setDataNascimentoSuperComercial(dadosOriginais.data_nascimento_final);
    setStatusSuperComercial(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeSuperComercial(false);
    setErroEmailSuperComercial(false);
    setErroTelefoneSuperComercial(false);
    setErroCargoSuperComercial(false);
    setErroNifSuperComercial(false);
    setErroMoradaSuperComercial(false);
    setErroCodigoPostalSuperComercial(false);
    setErroNascimentoSuperComercial(false);
    setMensagemErroEmailSuperComercial("");
    setMensagemErroTelefoneSuperComercial("");
    setMensagemErroNifSuperComercial("");
    setMensagemErroCodigoPostalSuperComercial("");
    window.scrollTo(0, 0);
  }

  function suspenderSuperComercial() {
    if (motivoSuspensao) {
      setSuspender(false);
      setMotivoSuspensao("");
      setFreeze(true);
      postPedidos({
        id: idSuperComercial,
        acao: statusSuperComercial ? 1 : 3,
        motivo: motivoSuspensao,
        tipo: 2,
      })
        .then((res) => {
          if (res.success) {
            setTimeout(async function () {
              enviarEmailNovoPedido().then((res2) => {
                if (res2.success) {
                  setFreeze(false);
                  setFreeze(false);
                  toast.success(res.data.msg);
                } else {
                  setFreeze(false);
                  setFreeze(false);
                  toast.error(res.error);
                }
              });
            }, 3000);
          } else {
            setFreeze(false);
            if (res.status == 500) {
              setFreeze(false);
              navigate("/500");
            } else if (res.status == 406) {
              setFreeze(false);
              toast.info(res.error);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setErroMotivoSuspensao(true);
    }
  }

  function eliminarSuperComercial() {
    if (motivoEliminacao) {
      setEliminar(false);
      setMotivoEliminacao("");
      setFreeze(true);
      postPedidos({
        id: idSuperComercial,
        acao: 2,
        motivo: motivoEliminacao,
        tipo: 2,
      })
        .then((res) => {
          if (res.success) {
            setTimeout(async function () {
              enviarEmailNovoPedido().then((res2) => {
                if (res2.success) {
                  setFreeze(false);
                  toast.success(res.data.msg);
                } else {
                  setFreeze(false);
                  toast.error(res.error);
                }
              });
            }, 3000);
          } else {
            setFreeze(false);
            if (res.status == 500) {
              setFreeze(false);
              navigate("/500");
            } else if (res.status == 406) {
              setFreeze(false);
              toast.info(res.error);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setFreeze(false);
      setErroMotivoEliminacao(true);
    }
  }

  function enviarEmail() {
    definePassEmail({
      email: emailFuncionario,
    }).then((res) => {
      if (res.success) {
        setFreeze(false);
        toast.success(res.msg);
        //navigate("/superadmins");
      } else {
        setFreeze(false);
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="align-left">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/supercommercials");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>

            {/* <div className="align-right">
              <h3 className="titleList">{t("editarSuperComercial")}</h3>
            </div> */}
          </div>
          <div>
            <div className="formPage4">
              {/* Cards */}
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina2">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    {statusSuperComercial ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}

                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscinaReenviar2"
                      variant="outlined"
                      style={{
                        backgroundColor: "olivedrab",
                      }}
                      onClick={() => {
                        enviarEmail();
                      }}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faPaperPlane}
                      />
                      {t("reenviarAtivacao")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    {statusSuperComercial ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      style={{
                        backgroundColor: "olivedrab",
                      }}
                      onClick={() => {
                        enviarEmail();
                      }}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faPaperPlane}
                      />
                      {t("reenviarAtivacao")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {motivo && (
              <div className="formPageSuspensao">
                <Alert severity="error">
                  {statusSuperComercial
                    ? t("supercomercialAtivo")
                    : t("supercomercialSuspenso")}{" "}
                  | {t("motivo")}: {motivo}
                </Alert>
              </div>
            )}
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Eliminar supercomercial */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarSuperComercial")}
            </DialogTitle>
            <DialogContent>
              {t("justificacaoEliminacao")} *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoEliminacao}
                  error={erroMotivoEliminacao}
                  onChange={(e) => {
                    setMotivoEliminacao(e.target.value);
                    setErroMotivoEliminacao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              {" "}
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarSuperComercial();
                }}
                autoFocus
              >
                {t("fazerPedido")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Suspender Supercomercial */}
          <Dialog
            open={suspender}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {statusSuperComercial
                ? t("suspenderSuperComercial")
                : t("ativarSuperComercial")}
            </DialogTitle>
            <DialogContent>
              {statusSuperComercial
                ? t("justificacaoSuspencao")
                : t("justificacaoAtivacao")}{" "}
              *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoSuspensao}
                  error={erroMotivoSuspensao}
                  onChange={(e) => {
                    setMotivoSuspensao(e.target.value);
                    setErroMotivoSuspensao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupSuspender}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  suspenderSuperComercial();
                }}
                autoFocus
              >
                {t("fazerPedido")}
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <div className="formPage">
              <Box className="formTextField" ref={ref}>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nomeSuperComercial}
                      error={erroNomeSuperComercial}
                      onChange={(e) => {
                        setNomeSuperComercial(e.target.value);
                        setErroNomeSuperComercial(false);
                      }}
                    />
                  </FormControl>
                  {/* Email */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("email")}
                      value={emailSuperComercial}
                      error={erroEmailSuperComercial}
                      helperText={mensagemErroEmailSuperComercial}
                      onChange={(e) => {
                        setEmailSuperComercial(
                          e.target.value.toLocaleLowerCase()
                        );
                        setErroEmailSuperComercial(false);
                        setMensagemErroEmailSuperComercial("");
                      }}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={edicao}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefoneSuperComercial}
                      error={erroTelefoneSuperComercial}
                      helperText={mensagemErroTelefoneSuperComercial}
                      onChange={(e) => {
                        setTelefoneSuperComercial(e);
                        setErroTelefoneSuperComercial(false);
                        setMensagemErroTelefoneSuperComercial("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </FormControl>
                  {/* Cargo */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("cargo")}
                      value={cargoSuperComercial}
                      error={erroCargoSuperComercial}
                      onChange={(e) => {
                        setCargoSuperComercial(e.target.value);
                        setErroCargoSuperComercial(false);
                      }}
                    />
                  </FormControl>
                  {/* Nif */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nifSuperComercial}
                      error={erroNifSuperComercial}
                      helperText={mensagemErroNifSuperComercial}
                      onChange={(e) => {
                        setNifSuperComercial(e.target.value);
                        setErroNifSuperComercial(false);
                      }}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("rua")}
                      value={moradaSuperComercial}
                      error={erroMoradaSuperComercial}
                      onChange={(e) => {
                        setMoradaSuperComercial(e.target.value);
                        setErroMoradaSuperComercial(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      disabled={edicao}
                      type="text"
                      variant="standard"
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      value={codigoPostalSuperComercial}
                      required
                      format="####-###"
                      error={erroCodigoPostalSuperComercial}
                      helperText={mensagemErroCodigoPostalSuperComercial}
                      onChange={(e) => {
                        setCodigoPostalSuperComercial(e.target.value);
                        setErroCodigoPostalSuperComercial(false);
                        setMensagemErroCodigoPostalSuperComercial("");
                      }}
                    />
                  </FormControl>
                  {/* Data de nascimento */}
                  <FormControl className="formTextField3" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disableFuture
                        disabled={edicao}
                        label={t("dataNascimento")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataNascimentoSuperComercial}
                        onChange={(e) => {
                          setDataNascimentoSuperComercial(e);
                          setErroNascimentoSuperComercial(false);
                          setMensagemErroNascimentoSuperComercial("");
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroNascimentoSuperComercial}
                            helperText={mensagemErroNascimentoSuperComercial}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {/* Foto */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")}
                    </p>
                    <label
                      id="fotoPiscina"
                      htmlFor="file-upload"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarImagem")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        document.getElementById(
                          "fotoPiscina"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById(
                          "tituloFotoPiscina"
                        ).style.color = Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setFotoSuperComercial(reader.result);
                        };
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {fotoSuperComercial.length > 0 && (
                        <img
                          style={{ marginTop: 10 }}
                          width={150}
                          height={150}
                          src={fotoSuperComercial}
                        />
                      )}
                    </div>
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarSuperComercial;
