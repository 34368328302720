import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faStop,
  faTrash,
  faClose,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Scrollbar } from "react-scrollbars-custom";
import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Checkbox,
  DialogActions,
  DialogTitle,
  CircularProgress,
  DialogContentText,
  Box,
  FormControl,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import {
  editarPacks,
  eliminarPacks,
  getPack,
  listaPacks,
} from "../../api/requests/Packs";
import { adicionarPacks } from "../../api/requests/Packs";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import Search1 from "../../components/DataTable/Search1";
import Nav from "../../components/Nav";
import { height } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import moment from "moment";

function ListaPacks() {
  const [packs, setPacks] = useState([]);
  const [packsCopy, setPacksCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [freeze, setFreeze] = useState(false);
  const [edicao, setEdicao] = useState(true);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);
  //Traduções
  const { t, i18n } = useTranslation();
  const [erroPiscina, setErroPiscina] = useState(false);

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "categoria", "num_tickets"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "categoria", "num_tickets"] : []);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [openCriarPacks, setOpenCriarPacks] = useState(false);
  const handleCloseCriarPacks = () => {setOpenCriarPacks(false);
  
      window.location.reload();

  }

  const handleClickOpenCriarPacks = () => {
    setOpenCriarPacks(true);
  };

  const [openEditarPacks, setOpenEditarPacks] = useState(false);
  const handleCloseEditarPacks = () => {
    setOpenEditarPacks(false);
    cancelar();
    setEdicao(true);
 
      window.location.reload();
    
  };

  const handleClickOpenEditarPacks = () => {
    setOpenEditarPacks(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arrayPacks = packsCopy;
      arrayPacks = arrayPacks.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome.toLowerCase().includes(search.toLowerCase())
      );
      setPacks(arrayPacks);
    } else {
      setPacks(packsCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaPacks({ id_entidade_exploradora: idEntidade, id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_pack,
              nome: element.nome,
              categoria: element.nome_categoria,
              num_tickets: element.num_tickets,
              preco: element.preco.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
            };
            arrayFinal.push(linha);
            setPacks(arrayFinal);
            setPacksCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [idPack, setIdPack] = useState("");
  const [nome, setNome] = useState("");
  const [numtickets, setNumTickets] = useState(0);
  const [preco, setPreco] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [piscina, setPiscinas] = useState([]);

  const [erroNome, setErroNome] = useState(false);
  const [erroNumTickets, setErroNumTickets] = useState(false);
  const [erroPreco, setErroPreco] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroDataExpira, setErroDataExpira] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPool, setSelectedPool] = useState("");
  const [dataExpira, setDataExpira] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handlePoolChange = (event) => {
    setSelectedPool(event.target.value);
    setErroPiscina(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaPiscinasAgua({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function limparVariaveis() {
    setNome("");
    setSelectedPool();
    setSelectedCategory();
    setNumTickets(0);
    setPreco(0);
    setDataExpira("")
  }
  function adicionarPack() {
    let data_expiraf = moment(dataExpira).format("YYYY-MM-DD");

    setFreeze(true);
    adicionarPacks({
      nome: nome,
      id_piscina_agua: selectedPool,
      id_categoria: selectedCategory,
      num_tickets: numtickets,
      preco: preco,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
      data_expira:data_expiraf

    }).then((res) => {
      if (res.success) {
        toast.success(t("packAdicionar"));
        setFreeze(false);
        atualizarPacks();
        handleCloseCriarPacks();
        limparVariaveis();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (nome && numtickets && selectedCategory && selectedPool && preco && dataExpira) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarPack();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      if (!dataExpira) {
        setErroDataExpira(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (!selectedPool) {
        setErroPiscina(true);
      }
      if (!numtickets) {
        setErroNumTickets(true);
      }
      if (!preco) {
        setErroPreco(true);
      }
    }
  }

  const [nomeEditar, setNomeEditar] = useState("");
  const [numticketsEditar, setNumTicketsEditar] = useState(0);
  const [precoEditar, setPrecoEditar] = useState(0);
  const [categoriasEditar, setCategoriaEditar] = useState([]);
  const [piscinaEditar, setPiscinasEditar] = useState([]);
  const [dataExpiraEditar, setDataExpiraEditar] = useState("");
  const [erroDataExpirEditar, setErroDataExpiraEditar] = useState(false);


  const [erroNomeEditar, setErroNomeEditar] = useState(false);
  const [erroNumTicketsEditar, setErroNumTicketsEditar] = useState(false);
  const [erroPrecoEditar, setErroPrecoEditar] = useState(false);
  const [erroCategoriaEditar, setErroCategoriaEditar] = useState(false);
  const [erroPiscinaEditar, setErroPiscinaEditar] = useState(false);
  const [selectedCategoryEditar, setSelectedCategoryEditar] = useState(false);
  const [selectedPoolEditar, setSelectedPoolEditar] = useState(false);

  const handleCategoryChangee = (event) => {
    setSelectedCategoryEditar(event.target.value);
    setErroCategoriaEditar(false);
  };

  const handlePoolChangee = (event) => {
    setSelectedPoolEditar(event.target.value);
    setErroPiscinaEditar(false);
  };

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    if (idPack != "") {
      getPack({ id: idPack })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];

            setDadosOriginais(dados);
            setNomeEditar(dados.nome);
            setNumTicketsEditar(dados.num_tickets);
            setPrecoEditar(dados.preco);
            setSelectedCategoryEditar(dados.id_categoria);
            setSelectedPoolEditar(dados.id_piscina_agua);
            setDataExpiraEditar(dados.data_expira)

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoriaEditar(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaPiscinasAgua({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinasEditar(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, [idPack]);

  function editarPack() {
    let data_expiraf = moment(dataExpiraEditar).format("YYYY-MM-DD");

    setFreeze(true);
    editarPacks({
      id_pack: idPack,
      nome: nomeEditar,
      num_tickets: numticketsEditar,
      preco: precoEditar,
      id_categoria: selectedCategoryEditar,
      id_piscina_agua: selectedPoolEditar,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
      data_expira:data_expiraf

    })
      .then((res) => {
        if (res.success) {
          let linha = {
            id_pack: idPack,
            nome: nomeEditar,
            num_tickets: numticketsEditar,
            preco: precoEditar,
            id_categoria: selectedCategoryEditar,
            id_piscina_agua: selectedPoolEditar,
            id_piscina: localStorage.getItem("idPiscina"),
            id_entidade_exploradora: localStorage.getItem("idEntidade"),
          };

          setDadosOriginais(linha);
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
      });
  }

  function validacao() {
    if (
      nomeEditar &&
      numticketsEditar &&
      selectedCategoryEditar &&
      selectedPoolEditar &&
      precoEditar
      &&
      dataExpiraEditar
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarPack();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nomeEditar) {
        setErroNomeEditar(true);
      }
      if (!selectedCategoryEditar) {
        setErroCategoriaEditar(true);
      }
      if (!dataExpiraEditar) {
        setErroDataExpiraEditar(true);
      }
      if (!selectedPoolEditar) {
        setErroPiscinaEditar(true);
      }
      if (!numticketsEditar) {
        setErroNumTicketsEditar(true);
      }
      if (!precoEditar) {
        setErroPrecoEditar(true);
      }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeEditar(dadosOriginais.nome);
    setNumTicketsEditar(dadosOriginais.num_tickets);
    setPrecoEditar(dadosOriginais.preco);
    setSelectedCategoryEditar(dadosOriginais.id_categoria);
    setSelectedPoolEditar(dadosOriginais.id_piscina_agua);
    setDataExpiraEditar(dadosOriginais.data_expira)


    //Limpar erros e mensagens de erro
    setErroNomeEditar(false);
    setErroNumTicketsEditar(false);
    setErroPrecoEditar(false);
    setErroCategoriaEditar(false);
    setErroPiscinaEditar(false);
    setErroDataExpiraEditar(false);


    window.scrollTo(0, 0);
  }
  function atualizarPacks() {
    listaPacks({
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
      id_piscina: localStorage.getItem("idPiscina"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_pack,
              nome: element.nome,
              categoria: element.nome_categoria,
              num_tickets: element.num_tickets,
              preco: element.preco.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
            };
            arrayFinal.push(linha);
            setPacks(arrayFinal);
            setPacksCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  function eliminarPack() {
    setFreeze(true);
    eliminarPacks({
      id_pack: idPack,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("packEliminado"));
          atualizarPacks();
          handleClosePopupEliminar();
          handleCloseEditarPacks();
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/dashboard_admin");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <h3 className="titleList">{t("listaPacks")}</h3>
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleClickOpenCriarPacks();
              }}
            ></span>
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas

            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 70,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },

              {
                field: "categoria",
                headerName: t("categoria"),
                flex: 1,
                type: "string",
                hide: columnsHidden.includes("categoria"),
              },
              {
                field: "num_tickets",
                headerName: t("numtickets"),
                flex: 1,
                type: "number",
                hide: columnsHidden.includes("num_tickets"),
              },
              {
                field: "preco",
                headerName: t("preco"),
                flex: 1,
                type: "number",
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 70,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdPack(id);
                        handleClickOpenEditarPacks();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={packs}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
          {/* adicionar pack */}
          <Dialog
            open={openCriarPacks}
            onClose={handleCloseCriarPacks}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarPack")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarPacks}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              <div className="formPop" style={{ marginTop: 30 }}>
                <Box className="formTextField">
                  <div>
                    {/* Nome */}
                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="text"
                        variant="standard"
                        label={t("nome")}
                        value={nome}
                        error={erroNome}
                        onChange={(e) => {
                          setNome(e.target.value);
                          setErroNome(false);
                        }}
                      />
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                      <InputLabel
                        className="formTextField22"
                        error={erroCategoria}
                      >
                        {t("escolhapiscina")}*
                      </InputLabel>
                      <Select
                        value={selectedPool}
                        onChange={handlePoolChange}
                        error={erroPiscina}
                        fullWidth
                        variant="standard"
                      >
                        {piscina.map((piscina) => (
                          <MenuItem key={piscina.id} value={piscina.id}>
                            {piscina.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                      <InputLabel
                        className="formTextField22"
                        error={erroCategoria}
                      >
                        {t("escolhacategoria")}*
                      </InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        error={erroCategoria}
                        fullWidth
                        variant="standard"
                      >
                        {categorias.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.categoria}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="number"
                        variant="standard"
                        label={t("numtickets")}
                        value={numtickets}
                        error={erroNumTickets}
                        onChange={(e) => {
                          const value = e.target.value;

                          setNumTickets(value);
                          setErroNumTickets(false);
                        }}
                      />
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="number"
                        variant="standard"
                        label={`${t("preco")} (€)`}
                        value={preco}
                        error={erroPreco}
                        onChange={(e) => {
                          const value = e.target.value;

                          setPreco(value);
                          setErroPreco(false);
                        }}
                      />
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("dataExpira")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={dataExpira}
                      onChange={(e) => {
                        if(e){
                          setDataExpira(e.$d);
                          setErroDataExpira(false);
                        }
                        
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataExpira}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                  </div>
                </Box>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* editar pack */}
          <Dialog
            open={openEditarPacks}
            onClose={handleCloseEditarPacks}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "95%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 999,
                }}
                onClick={handleCloseEditarPacks}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Box className="formTextField">
                    <div>
                      {/* Nome */}
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          disabled={edicao}
                          required
                          type="text"
                          variant="standard"
                          label={t("nome")}
                          value={nomeEditar}
                          error={erroNomeEditar}
                          onChange={(e) => {
                            setNomeEditar(e.target.value);
                            setErroNomeEditar(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoria}
                        >
                          {t("escolhapiscina")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedPoolEditar}
                          onChange={handlePoolChangee}
                          error={erroPiscinaEditar}
                          fullWidth
                          variant="standard"
                        >
                          {piscinaEditar.map((piscina) => (
                            <MenuItem key={piscina.id} value={piscina.id}>
                              {piscina.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoria}
                        >
                          {t("escolhacategoria")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedCategoryEditar}
                          onChange={handleCategoryChangee}
                          error={erroCategoriaEditar}
                          fullWidth
                          variant="standard"
                        >
                          {categoriasEditar.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.categoria}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          disabled={edicao}
                          required
                          type="number"
                          variant="standard"
                          label={t("numtickets")}
                          value={numticketsEditar}
                          error={erroNumTicketsEditar}
                          onChange={(e) => {
                            const value = e.target.value;

                            setNumTicketsEditar(value);
                            setErroNumTicketsEditar(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          disabled={edicao}
                          required
                          type="number"
                          variant="standard"
                          label={`${t("preco")} (€)`}
                          value={precoEditar}
                          error={erroPrecoEditar}
                          onChange={(e) => {
                            const value = e.target.value;

                            setPrecoEditar(value);
                            setErroPrecoEditar(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("dataExpira")}
                      disabled={edicao}
                      
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={dataExpiraEditar}
                      onChange={(e) => {
                        if(e){
                          setDataExpiraEditar(e.$d);
                          setErroDataExpiraEditar(false);
                        }
                        
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataExpirEditar}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                    </div>
                  </Box>

                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Eliminar */}
                  <Dialog
                    open={eliminar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("eliminarPack")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("eliminarRegisto")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          eliminarPack();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                        }}
                        autoFocus
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("editar")}
                  </Button>

                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${eliminarbtnpeq})`
                        : `url(${eliminarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupEliminar();
                    }}
                  >
                    <TrashIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("eliminar")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,
                      width: isMobile ? 164 : 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaPacks;
