import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";

//Get Horarios
export function getHorarios(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/list", dados, token);
}

//Post Horarios
export function postHorario(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/", dados, token);
}


//Get por id
export function getHorario(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`horarios/id/${id}`, {}, token);
}

//Editar
export function putHorario(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`horarios/`, dados, token);
}

//Eliminar
export function deleteHorario(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2(`horarios/`, dados, token);
}

//Post Agendamento Aulas
export function postAgendamento(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/agendamento-aulas/", dados, token);
}

//Editar
export function putAgendamento(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`horarios/agendamento-aulas/`, dados, token);
}
//get agendamento aulas id
export function getAgendamentoAulas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/agendamento-aulas/id", dados, token);
}

//get agendamento nado-livre id
export function getAgendamentoNadoLivre(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/agendamento-nado-livre/id", dados, token);
}
//Eliminar
export function deleteAgendamento(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2(`horarios/agendamento-aulas/`, dados, token);
}

//Post Agendamento Nado livre
export function postAgendamentoNado(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/agendamento-nado-livre/", dados, token);
}

//Editar
export function putAgendamentoNado(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`horarios/agendamento-nado-livre/`, dados, token);
}

//Eliminar
export function deleteAgendamentoNado(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2(`horarios/agendamento-nado-livre/`, dados, token);
}

//Lista Agendamento por Piscina
export function getAgendamentoPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/agendamentos/list", dados, token);
}

//Disponibilidade Pistas
export function postDisponibilidadePistas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/agendamentos/disponibilidade", dados, token);
}

//Disponibilidade Profs
export function postDisponibilidadeProfs(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("horarios/profs", dados, token);
}