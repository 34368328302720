import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import {
  faPen,
  faTicketSimple,
  faWaterLadder,
  faTags,
  faQrcode,
  faBuilding,
  faCalendarCheck,
  faPerson,
  faTriangleExclamation,
  faChalkboardTeacher,
  faHandPaper,
  faGraduationCap,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button } from "@mui/material";
import { Box, margin } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { taxaOcupacao, vagasD } from "../../api/requests/PiscinaAgua";
import {
  OcupacaoHorasDias,
  statsNumClientes,
} from "../../api/requests/Dashboards";
import { getNumPedidos } from "../../api/requests/Pedidos";
import Nav from "../../components/Nav";
import { Line } from "react-chartjs-2";
import { getInscricoesId } from "../../api/requests/Utilizadores";

function Dashboard_Funcionario() {
  //Traduções
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [freeze, setFreeze] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //Navigator
  const navigate = useNavigate();
  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [selectedPool, setSelectedPool] = useState("");
  const [idEntidade, setIdentidade] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [lista, setLista] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [clientesDiaPassado, setClientesDiaPassado] = useState(0);
  const [clientesMesPassado, setClientesMesPassado] = useState(0);
  const [clientesSemanaPassada, setClientesSemanaPassada] = useState(0);
  const [horas, setHoras] = useState([]);
  const [ocupacao, setOcupacao] = useState([]);
  const [max, setMaxCapacidade] = useState(0);

  const isNegative = clientesSemanaPassada < 0;
  const isNegativeMes = clientesMesPassado < 0;
  const isNegativeDia = clientesDiaPassado < 0;

  const paragraphStyle = {
    color: isNegative ? "#F04643" : "#33D77A",
    fontSize: "12px",
  };

  const arrowIcon = isNegative ? "↙" : "↗";

  const paragraphStyleMes = {
    color: isNegativeMes ? "#F04643" : "#33D77A",
    fontSize: "12px",
  };

  const arrowIconMes = isNegativeMes ? "↙" : "↗";

  const paragraphStyleDia = {
    color: isNegativeDia ? "#F04643" : "#33D77A",
    fontSize: "12px",
  };

  const arrowIconDia = isNegativeDia ? "↙" : "↗";

  
  const dataGraficoTaxaOcupacao = {
    labels: horas,
    datasets: [
      {
        label: "Taxa de Ocupação",
        data: ocupacao,
        backgroundColor: "transparent", // Sem cor de fundo
        borderColor: "rgba(125, 164, 249, 1)", // Cor da linha mais clara
        borderWidth: 8,
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 0.4,
        pointHoverRadius: 2,
        pointBackgroundColor: "rgba(10, 86, 250, 1)",
        pointBorderColor: "#0A56FA",
      },
    ],
  };

  const optionsGraficoTaxaOcupacao = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: max,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };


  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    localStorage.setItem("idPiscina", idPiscina);
    localStorage.setItem("idEntidade", idEE);
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    const currentDate = new Date();
    const previousMonth =
      currentDate.getDate() > 15
        ? currentDate.getMonth() + 1
        : currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Tratamento para mês de janeiro
    if (previousMonth < 0) {
      previousMonth = 11; // Dezembro
      currentYear = currentYear - 1;
    }

    getNumPedidos({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          setNumPedidosPendentes(res.data.data);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    vagasD({ id_piscina: idPiscina }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_turma ? element.id_turma : element.id_ocupa_espaco,
            nome: element.nome,
            vagas: element.num_vagas,
            disponiveis: element.num_vagas_disponiveis,
          };
          arrayFinal.push(linha);
          setLista(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });

    getInscricoesId(id).then((res2) => {
      if (res2.success) {
        let arrayFinal2 = [];

        res2.data.data.forEach((element2) => {
          arrayFinal2.push(element2);
        });

        const selectedPoolId = localStorage.getItem("idPiscina");
        const selectedEntityId = localStorage.getItem("idEntidade");
        if (selectedPoolId && selectedEntityId) {
          arrayFinal2.find((piscina) => {
            if (piscina.id_piscina == selectedPoolId) {
              const dataAtual = new Date();
              const anoAtual = dataAtual.getFullYear();
              // Obter o mês atual
              const mesAtual = (dataAtual.getMonth() + 1)
                .toString()
                .padStart(2, "0");

              const diaAtual = dataAtual.getDate();
              OcupacaoHorasDias({
                id_entidade_legal: selectedEntityId,
                id_piscina: selectedPoolId,
                mes: mesAtual,
                ano: anoAtual,
                dia: diaAtual,
              })
                .then((res) => {
                  if (res.success) {
                    const data = new Date();

                    // Obter a hora atual sem interferir nas variáveis existentes
                    const horaA = data.getHours();
                    const minutosAtuais = data.getMinutes();

                    // Formatar a hora e os minutos como desejado
                    const horaAtual = `${horaA
                      .toString()
                      .padStart(2, "0")}:${minutosAtuais
                      .toString()
                      .padStart(2, "0")}`;

                    const [horaAtualHH, horaAtualMM] = horaAtual.split(":");
                    const dataAtual = new Date();
                    dataAtual.setHours(horaAtualHH, horaAtualMM);

                    // Encontrar a posição da hora atual no array de horas
                    const indiceHoraAtual = res.data.horas.findIndex(
                      (hora) => hora === `${dataAtual.getHours()}h`
                    );

                    // Verificar a ocupação na hora atual
                    const ocupacaoAtual = res.data.ocupacoes[indiceHoraAtual];

                    // Calcular a taxa de ocupação
                    const taxaDeOcupacao =
                      (ocupacaoAtual / res.data.capacidade) * 100;

                    setOcupacao(taxaDeOcupacao);
                    setHoras(res.data.horas);
                    setMaxCapacidade(res.data.capacidade);
                  } else {
                    if (res.status == 500) {
                      navigate("/500");
                    }
                    toast.error(res.error);
                  }
                })
                .catch((error) => {
                  //toast.warn(t("erroWebservice"));
                });
            }
          });
        }

        if (arrayFinal2.length == 0) {
          setShowButtons(false);
          toast.error(t("semPiscinas"));
        } else if (arrayFinal2.length == 1) {
          setShowButtons(true);
          setSelectedPool(arrayFinal2[0]);
          setIdPiscina(arrayFinal2[0].id_piscina);
          setIdentidade(arrayFinal2[0].id_entidade_legal);
          localStorage.setItem("idPiscina", arrayFinal2[0].id_piscina);
          localStorage.setItem("idEntidade", arrayFinal2[0].id_entidade_legal);

          getNumPedidos({ id_piscina: arrayFinal2[0].id_piscina })
            .then((res) => {
              if (res.success) {
                setNumPedidosPendentes(res.data.data);
              } else {
                if (res.status == 500) {
                  navigate("/500");
                }
                toast.error(res.error);
              }
            })
            .catch((error) => {
              //toast.warn(t("erroWebservice"));
            });

          // statsNumClientes({ id_piscina: arrayFinal2[0].id_piscina })
          //   .then((res) => {
          //     if (res.success) {
          //       setNumClientes(res.data.data);
          //     } else {
          //       if (res.status == 500) {
          //         navigate("/500");
          //       }
          //       toast.error(res.error);
          //     }
          //   })
          //   .catch((error) => {
          //     //toast.warn(t("erroWebservice"));
          //   });

          // statsNumAlunosProf({ id_piscina: arrayFinal2[0].id_piscina })
          //   .then((res) => {
          //     if (res.success) {
          //       let array = [];
          //       res.data.data.forEach((element) => {
          //         array.push(element);
          //       });
          //       setAlunos(array);
          //       let profs = [];
          //       profs = array.map((item) => Object.values(item)[0]);
          //       let valores = [];
          //       valores = array.map((item) => Object.values(item)[2]);
          //       setProfs(profs);
          //       setValores(valores);
          //     } else {
          //       if (res.status == 500) {
          //         navigate("/500");
          //       }
          //       toast.error(res.error);
          //     }
          //   })
          //   .catch((error) => {
          //     //toast.warn(t("erroWebservice"));
          //   });

          // statsFunc({ id_piscina: arrayFinal2[0].id_piscina })
          //   .then((res) => {
          //     if (res.success) {
          //       let array1 = [];
          //       res.data.data.forEach((ele1) => {
          //         array1.push(ele1);
          //       });

          //       let funcs = [];
          //       funcs = array1.map((item) => Object.values(item)[0]);
          //       let valores1 = [];
          //       valores1 = array1.map((item) => Object.values(item)[1]);
          //       setFuncs(funcs);
          //       setValores1(valores1);
          //     } else {
          //       if (res.status == 500) {
          //         navigate("/500");
          //       }
          //       toast.error(res.error);
          //     }
          //   })
          //   .catch((error) => {
          //     //toast.warn(t("erroWebservice"));
          //   });
        } else {
          setShowButtons(false);
        }
      } else {
        toast.error(res2.error);
      }
    });
  }, []);

  const [selectedOptionDia, setSelectedOptionDia] = useState("day"); // Pode ser 'month' ou 'year'
  const [numClientes, setNumClientes] = useState(0);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <div className="formPage">
          {isMobile ? (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                width: "100%",
                marginTop: "8vh",
              }}
            >
              <div className="divDashboard7">
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: "1vh",
                        marginLeft: "1.5vw",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: isMobile ? "16px" : "20px",
                          color: "black",
                          marginBottom: "5px",
                        }}
                      >
                        {t("taxaOcupacao")}
                      </h4>

                      <p
                        style={{
                          fontSize: isMobile ? "30px" : "40px",
                          color: "#0A56FA",
                          paddingTop: 50,
                          paddingRight: 90,
                        }}
                      >
                        {ocupacao}%
                      </p>
                    </div>

                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      {/* <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 0,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          maxWidth: "50px",
                        }}
                      >
                        <select
                          value={selectedOptionDia}
                          onChange={(e) => setSelectedOptionDia(e.target.value)}
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            textAlign: "right",
                          }}
                        >
                          <option value="week">{t("semana")}</option>
                          <option value="month">{t("mes")}</option>
                          <option value="day">{t("dia")}</option>
                        </select>
                      </div> */}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-end",

                          justifyContent: "flex-end",
                          position: "relative",
                          right: 20,
                          paddingTop: 80,
                        }}
                      >
                        {selectedOptionDia === "week" ? (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimasemana")}
                            </p>
                            <p style={paragraphStyle}>
                              {isNegative && arrowIcon}{" "}
                              {clientesSemanaPassada !== null &&
                              clientesSemanaPassada !== undefined
                                ? Math.abs(clientesSemanaPassada) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        ) : selectedOptionDia === "month" ? (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimomes")}
                            </p>
                            <p style={paragraphStyleMes}>
                              {isNegativeMes && arrowIconMes}{" "}
                              {clientesMesPassado !== null &&
                              clientesMesPassado !== undefined
                                ? Math.abs(clientesMesPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimahora")}
                            </p>
                            <p style={paragraphStyleDia}>
                              {isNegativeDia && arrowIconDia}{" "}
                              {clientesDiaPassado !== "NaN"
                                ? Math.abs(clientesDiaPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "80vw",
                      height: "20vh",
                      paddingLeft: 40,
                      paddingTop: 5,
                    }}
                  >
                    <Line
                      data={dataGraficoTaxaOcupacao}
                      options={optionsGraficoTaxaOcupacao}
                    />
                  </div>
                </div>
              </div>
              <div
                className="divDashboard7"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul
                  style={{
                    listStyleType: "none",
                    padding: "0",
                    display: "flex",

                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {lista.map((vaga, index) => (
                    <li
                      key={index}
                      style={{
                        width: "30%", // Define a largura de cada elemento para que haja no máximo 3 elementos por linha
                        margin: "1%",
                        boxSizing: "border-box",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <strong>{vaga.nome}</strong>
                        <br />
                        <span>
                          <strong>{t("vagastot")}</strong> {vaga.vagas}
                          <br />
                          <strong>{t("vagasdisp")}</strong> {vaga.disponiveis}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div className="divDashboard4">
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: "1vh",
                        marginLeft: "1.5vw",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: isMobile ? "16px" : "20px",
                          color: "black",
                          marginBottom: "5px",
                        }}
                      >
                        {t("taxaOcupacao")}
                      </h4>

                      <p
                        style={{
                          fontSize: isMobile ? "30px" : "40px",
                          color: "#0A56FA",
                          paddingTop: 50,
                          paddingRight: 90,
                        }}
                      >
                        {ocupacao}%
                      </p>
                    </div>

                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      {/* <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 0,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          maxWidth: "50px",
                        }}
                      >
                        <select
                          value={selectedOptionDia}
                          onChange={(e) => setSelectedOptionDia(e.target.value)}
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            textAlign: "right",
                          }}
                        >
                          <option value="week">{t("semana")}</option>
                          <option value="month">{t("mes")}</option>
                          <option value="day">{t("dia")}</option>
                        </select>
                      </div> */}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-end",

                          justifyContent: "flex-end",
                          position: "relative",
                          right: 20,
                          paddingTop: 80,
                        }}
                      >
                        {selectedOptionDia === "week" ? (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimasemana")}
                            </p>
                            <p style={paragraphStyle}>
                              {isNegative && arrowIcon}{" "}
                              {clientesSemanaPassada !== null &&
                              clientesSemanaPassada !== undefined
                                ? Math.abs(clientesSemanaPassada) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        ) : selectedOptionDia === "month" ? (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimomes")}
                            </p>
                            <p style={paragraphStyleMes}>
                              {isNegativeMes && arrowIconMes}{" "}
                              {clientesMesPassado !== null &&
                              clientesMesPassado !== undefined
                                ? Math.abs(clientesMesPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimahora")}
                            </p>
                            <p style={paragraphStyleDia}>
                              {isNegativeDia && arrowIconDia}{" "}
                              {clientesDiaPassado !== "NaN"
                                ? Math.abs(clientesDiaPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "45vw",
                      height: "43vh",
                      paddingLeft: 40,
                      paddingTop: 20,
                    }}
                  >
                    <Line
                      data={dataGraficoTaxaOcupacao}
                      options={optionsGraficoTaxaOcupacao}
                    />
                  </div>
                </div>
              </div>
              <div
                className="divDashboard4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul
                  style={{
                    listStyleType: "none",
                    padding: "0",
                    display: "flex",

                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {lista.map((vaga, index) => (
                    <li
                      key={index}
                      style={{
                        width: "30%", // Define a largura de cada elemento para que haja no máximo 3 elementos por linha
                        margin: "1%",
                        boxSizing: "border-box",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <strong>{vaga.nome}</strong>
                        <br />
                        <span>
                          <strong>{t("vagastot")}</strong> {vaga.vagas}
                          <br />
                          <strong>{t("vagasdisp")}</strong> {vaga.disponiveis}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard_Funcionario;
