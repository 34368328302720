import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faClose,
  faFloppyDisk,
  faHand,
  faStop,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
  MenuItem,
  DialogContentText,
  Typography,
} from "@mui/material";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";

import Search1 from "../../components/DataTable/Search1";
import {
  adicionarSI,
  editarSI,
  eliminarSI,
  getSI,
  listaSI,
} from "../../api/requests/SeguroInscricao";
import moment from "moment";
import Nav from "../../components/Nav";

import { Box } from "@mui/system";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation, useNavigate, useParams } from "react-router";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";

function ListaSI() {
  const [si, setSi] = useState([]);
  const [siCopy, setSiCopy] = useState([]);
  const [search, setSearch] = useState("");

  //Traduções
  const { t, i18n } = useTranslation();
  const privilegios = JSON.parse(Cookies.get("privilegios"));

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(isMobile ? ["id"] : []);
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Navigator
  const navigate = useNavigate();
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [openCriarSeguros, setOpenCriarSeguros] = useState(false);
  const handleCloseCriarSeguros = () => {
    setOpenCriarSeguros(false);
    setSelectedCategory("");
    setInscricao(0);
    setDataExpiraI("");
  };

  const handleClickOpenCriarSeguro = () => {
    setOpenCriarSeguros(true);
  };

  const [openEditarSeguro, setOpenEditarSeguro] = useState(false);
  const handleCloseEditarSeguro = () => {
    setOpenEditarSeguro(false);
    atualizarSI();
    cancelar();
    setEdicao(true);
  };

  const handleClickOpenEditarSeguro = () => {
    setOpenEditarSeguro(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arraySi = siCopy;
      arraySi = arraySi.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome.toLowerCase().includes(search.toLowerCase())
      );
      setSi(arraySi);
    } else {
      setSi(siCopy);
    }
  }, [search]);

  function atualizarSI() {
    listaSI({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_inscricao_seguro,
              inscricao: element.inscricao.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
              nome: element.nome,
              data_expira_inscricao: element.data_expira_inscricao,
            };
            arrayFinal.push(linha);
            setSi(arrayFinal);
            setSiCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaSI({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_inscricao_seguro,
              inscricao: element.inscricao.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
              nome: element.nome,
              data_expira_inscricao: element.data_expira_inscricao,
            };
            arrayFinal.push(linha);
            setSi(arrayFinal);
            setSiCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [inscricao, setInscricao] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [idPiscina, setPiscina] = useState("");
  const [dataExpiraI, setDataExpiraI] = useState("");

  const [erroInscricao, setErroInscricao] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroPiscina, setErroPiscina] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [erroDataExpiraI, setErroDataExpiraI] = useState(false);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    setPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarSegI() {
    setFreeze(true);
    adicionarSI({
      inscricao: inscricao,
      id_piscina: idPiscina,
      categoria: selectedCategory,
      data_inscricao: moment(dataExpiraI).format("YYYY-MM-DD"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false);
        toast.success(t("SIAdicionar"));
        handleCloseCriarSeguros();
        navigate("/silista");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (inscricao && selectedCategory && dataExpiraI) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarSegI();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (inscricao == 0) {
        setErroInscricao(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }

      if (!dataExpiraI) {
        setErroDataExpiraI(true);
      }
    }
  }

  //editar SEguro

  const [edicao, setEdicao] = useState(true);
  const [freeze, setFreeze] = useState(false);
  const [idSI, setIdSI] = useState("");
  const [inscricaoe, setInscricaoe] = useState(0);
  const [categoriase, setCategoriae] = useState([]);
  const [id_piscina_inscricao_seguroe, setIdPiscinae] = useState("");
  const [dataExpiraIe, setDataExpiraIe] = useState("");

  const [erroInscricaoe, setErroInscricaoe] = useState(false);
  const [erroCategoriae, setErroCategoriae] = useState(false);
  const [erroPiscinae, setErroPiscinae] = useState(false);
  const [selectedCategorye, setSelectedCategorye] = useState("");
  const [erroDataExpiraIe, setErroDataExpiraIe] = useState(false);

  const handleCategoryChangee = (event) => {
    setSelectedCategorye(event.target.value);
    setErroCategoriae(false);
  };

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscinae(idPiscina);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoriae(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    if (idSI != "") {
      getSI({ id: idSI })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];
            setDadosOriginais(dados);
            setInscricaoe(dados.inscricao);
            const dataExpiraIe = new Date(dados.data_expira_inscricao);
            setDataExpiraIe(dataExpiraIe);
            setSelectedCategorye(dados.id_categoria);

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idSI]);

  function cancelar() {
    setEdicao(!edicao);

    //Colocar os dados originais

    setInscricaoe(dadosOriginais.inscricao);
    setDataExpiraIe(dadosOriginais.data_expira_inscricao);
    setSelectedCategorye(dadosOriginais.id_categoria);

    //Limpar erros e mensagens de erro

    setErroInscricaoe(false);
    setErroDataExpiraIe(false);
    setErroCategoriae(false);

    handleClosePopupCancelar();
  }

  function editarSegI() {
    setFreeze(true);
    editarSI({
      id: idSI,
      inscricao: inscricaoe,
      id_piscina: idPiscina,
      categoria: selectedCategorye,
      data_inscricao: moment(dataExpiraIe).format("YYYY-MM-DD"),
    })
      .then((res) => {
        if (res.success) {
          let linha = {
            id: idSI,
            inscricao: inscricaoe,
            id_piscina: idPiscina,
            categoria: selectedCategorye,
            data_inscricao: moment(dataExpiraIe).format("YYYY-MM-DD"),
          };
          setDadosOriginais(linha);
          setFreeze(false);
          setEdicao(!edicao);
          // handleCloseEditarSeguro();
          atualizarSI();
          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (inscricaoe && selectedCategorye && dataExpiraIe) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarSegI();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (inscricaoe == 0) {
        setErroInscricaoe(true);
      }
      if (!selectedCategorye) {
        setErroCategoriae(true);
      }

      if (!dataExpiraIe) {
        setErroDataExpiraIe(true);
      }
    }
  }

  function eliminarSi() {
    setFreeze(true);
    eliminarSI({
      id: idSI,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          atualizarSI();
          toast.success(t("eliminarSI"));
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaSi")}</h3>
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            {privilegios.adicionar_si == 1 && (
              <span
                className="addButton"
                style={{ zIndex: "1" }}
                onClick={() => {
                  handleClickOpenCriarSeguro();
                }}
              ></span>
            )}
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                width: 5,
                hide: columnsHidden.includes("id"),
              },
              {
                field: "nome",
                headerName: t("categoria"),
                type: "string",
                flex: 1,
              },
              {
                field: "inscricao",
                headerName: t("inscricao"),
                flex: 1,
                type: "number",
              },

              {
                field: "data_expira_inscricao",
                headerName: t("dataexpirainscricao"),
                flex: 1,
                type: "string",
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdSI(id);
                        handleClickOpenEditarSeguro();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={si}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
          {/* adicionar seguro */}

          <Dialog
            open={openCriarSeguros}
            onClose={handleCloseCriarSeguros}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarsi")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 1,
                }}
                onClick={handleCloseCriarSeguros}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              <div className="formPop" style={{ marginTop: 30 }}>
                <Box className="formTextField">
                  <div>
                    <FormControl className="formTextField2" fullWidth>
                      <InputLabel
                        className="formTextField22"
                        error={erroCategoria}
                      >
                        {t("escolhacategoria")}*
                      </InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        error={erroCategoria}
                        fullWidth
                        variant="standard"
                      >
                        {categorias.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.categoria}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="number"
                        variant="standard"
                        label={`${t("inscricao")} (€)`}
                        value={inscricao}
                        error={erroInscricao}
                        onChange={(e) => {
                          const value = e.target.value;

                          setInscricao(value);
                          setErroInscricao(false);
                        }}
                      />
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <DatePicker
                          label={t("dataexpirai")}
                          openTo="day"
                          views={["day", "year", "month"]}
                          value={dataExpiraI}
                          onChange={(e) => {
                            if (e) {
                              setDataExpiraI(e.$d);
                              setErroDataExpiraI(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              required
                              error={erroDataExpiraI}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Box>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* editar pack */}
          <Dialog
            open={openEditarSeguro}
            onClose={handleCloseEditarSeguro}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "95%" : "50%",
                height: 580,
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 1,
                }}
                onClick={() => {
                  handleCloseEditarSeguro();
                }}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Box className="formTextField">
                    <div>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoriae}
                        >
                          {t("escolhacategoria")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedCategorye}
                          onChange={handleCategoryChangee}
                          error={erroCategoriae}
                          fullWidth
                          variant="standard"
                        >
                          {categoriase.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.categoria}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          disabled={edicao}
                          required
                          type="number"
                          variant="standard"
                          label={`${t("inscricao")} (€)`}
                          value={inscricaoe}
                          error={erroInscricaoe}
                          onChange={(e) => {
                            const value = e.target.value;

                            setInscricaoe(value);
                            setErroInscricaoe(false);
                          }}
                        />
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            disabled={edicao}
                            label={t("dataexpirai")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={dataExpiraIe}
                            onChange={(e) => {
                              if (e) {
                                setDataExpiraIe(e.$d);
                                setErroDataExpiraIe(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataExpiraIe}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </Box>

                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Eliminar */}
                  <Dialog
                    open={eliminar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("eliminarPack")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("eliminarRegisto")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          eliminarSi();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                        }}
                        autoFocus
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {privilegios.editar_si == 1 && (
                    <>
                      <Button
                        className="btnEdicao"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${editarbtnpeq})`
                            : `url(${editarbtn})`,
                          width: isMobile ? 153 : 315,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <PencilIcon
                          style={{
                            width: "15px",
                            color: "white",
                            marginRight: "10px",
                          }}
                        />

                        {t("editar")}
                      </Button>
                    </>
                  )}

                  {/* <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${eliminarbtnpeq})`
                        : `url(${eliminarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupEliminar();
                    }}
                  >
                    <TrashIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("eliminar")}
                  </Button> */}
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaSI;
