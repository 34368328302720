import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";

//Lista descontos
export function listaDescontos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("descontos/list", dados, token);
}

//Adicionar descontos
export function adicionarDescontos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("descontos/", dados, token);
}

//Editar descontos
export function editarDescontos(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("descontos/", dados, token);
}

//Eliminar descontos
export function eliminarDescontos(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("descontos/", dados, token);
}


//Get desconto
export function getDesconto(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("descontos/id", dados, token);
}