import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { login } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FooterExterno from "../components/FooterExterno";
import { width } from "@mui/system";
import { Colors } from "chart.js";
import { ActiveQrCodes } from "../api/requests/Qrcode";

function LeituraQrCode() {
  const { uuid, code } = useParams();

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  //Login
  const [login2, setLogin2] = useState(false);

  const [ativo, setAtivo] = useState(false);
  const [inativo, setInativo] = useState(false);
  const [loading, setLoading] = useState(true);

  //Variáveis
  const [email, setEmail] = useState("");
  const [palavraPasse, setPalavraPasse] = useState("");

  //Validações
  const [erroEmail, setErroEmail] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;

    // Verificacoes
    const id = Cookies.get("uId");

    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      setLogin2(true);
    } else {
      setLogin2(false);

      ActiveQrCodes({
        id: uuid,
        codigo: code,
      }).then((res2) => {
        if (res2.success) {
          setTimeout(async function () {
            setLoading(false);
            setAtivo(true);
          }, 2000);
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
          toast.success(t("entradaaprovado"));
        } else {
          setTimeout(async function () {
            setLoading(false);
            setInativo(true);
          }, 2000);
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
          toast.error(t("erroentrada"));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      if (validacaoEmail) {
        setErroEmail(false);
        setErroEmail("");
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        setErroEmail(true);
        setMensagemErroEmail(t("emailInvalido"));
      }
    }
  }, [email, palavraPasse]);

  async function VerificaLogin() {
    if (email && palavraPasse && !erroEmail) {
      const hashedPassword = bcrypt.hashSync(
        palavraPasse,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );
      await login({
        email: email,
        password: hashedPassword,
      })
        .then(async (res) => {
          if (res.success) {
            //Definir cookie
            //Tempo em dias - 1hora
            Cookies.set("token", res.data.accessToken, {
              expires: 0.0416666667,
            });
            Cookies.set("refresh_token", res.data.refreshToken, {
              expires: 0.0416666667,
            });
            Cookies.set("language", i18n.language, {
              expires: 0.0416666667,
            });
            //Guardar id e nome do utilizador em Cookies
            Cookies.set("uId", res.data.id, {
              expires: 0.0416666667,
            });
            Cookies.set("uName", res.data.nome, {
              expires: 0.0416666667,
            });
            Cookies.set("uEmail", email, {
              expires: 0.0416666667,
            });
            Cookies.set("uKey", res.data.chave, {
              expires: 0.0416666667,
            });
            Cookies.set("uData", res.data.dados, {
              expires: 0.0416666667,
            });

            //Guardar o tipo de utilizador
            // SUPERADMIN
            if (res.data.id_superadmin == 1) {
              Cookies.set("utilizador", "superadmin", {
                expires: 0.0416666667,
              });
            }
            // SUPERCOMERCIAL
            else if (res.data.id_supercomercial == 1) {
              Cookies.set("utilizador", "supercomercial", {
                expires: 0.0416666667,
              });
            }
            // COMERCIAL
            else if (res.data.id_comercial == 1) {
              Cookies.set("utilizador", "comercial", {
                expires: 0.0416666667,
              });
            }
            // ADMIN
            else if (res.data.id_admin == 1) {
              Cookies.set("utilizador", "admin", {
                expires: 0.0416666667,
              });
              Cookies.set("id_piscina", res.data.id_piscina, {
                expires: 0.0416666667,
              });
              Cookies.set("id_entidade_exploradora", res.data.id_entidade, {
                expires: 0.0416666667,
              });
            }
            // FUNCIONÁRIO
            else if (res.data.id_funcionario == 1) {
              //caso seja funcionário vamos guardar o id_piscina a que o mesmo tem acesso e o id_entidade_exploradora a que pertence
              Cookies.set("id_piscina", res.data.id_piscina, {
                expires: 0.0416666667,
              });
              Cookies.set("id_entidade_exploradora", res.data.id_entidade, {
                expires: 0.0416666667,
              });
              Cookies.set("utilizador", "funcionario", {
                expires: 0.0416666667,
              });
              //Guardar privilégios do funcionário
              Cookies.set("privilegios", JSON.stringify(res.data.privilegios), {
                expires: 0.0416666667,
              });
            }
            // PROFESSOR
            else if (res.data.id_professor == 1) {
              Cookies.set("utilizador", "professor", {
                expires: 0.0416666667,
              });
            }
            // CLIENTE
            else if (res.data.id_cliente == 1) {
              Cookies.set("utilizador", "cliente", {
                expires: 0.0416666667,
              });
            }
            if (res.data.id_admin == 1 || res.data.id_funcionario == 1) {
              setLogin2(false);
              setTimeout(() => {
                ActiveQrCodes({
                  id: uuid,
                  codigo: code,
                }).then((res2) => {
                  if (res2.success) {
                    setTimeout(async function () {
                      setLoading(false);
                      setAtivo(true);
                      let utilizador = Cookies.get("utilizador");
                      if (utilizador == "superadmin") {
                        navigate("/dashboard_superadmin");
                      } else if (utilizador == "supercomercial") {
                        navigate("/dashboard_supercommercial");
                      } else if (utilizador == "comercial") {
                        navigate("/dashboard_commercial");
                      } else if (utilizador == "admin") {
                        navigate("/dashboard_admin");
                      } else if (utilizador == "funcionario") {
                        navigate("/dashboard_employee");
                      } else if (utilizador == "professor") {
                        navigate("/dashboard_teacher");
                      } else if (utilizador == "cliente") {
                        navigate("/dashboard_customer");
                      }
                      toast.success(t("entradaaprovado"));
                    }, 3000);
                  } else {
                    setTimeout(async function () {
                      setLoading(false);
                      setInativo(true);
                      let utilizador = Cookies.get("utilizador");
                      if (utilizador == "superadmin") {
                        navigate("/dashboard_superadmin");
                      } else if (utilizador == "supercomercial") {
                        navigate("/dashboard_supercommercial");
                      } else if (utilizador == "comercial") {
                        navigate("/dashboard_commercial");
                      } else if (utilizador == "admin") {
                        navigate("/dashboard_admin");
                      } else if (utilizador == "funcionario") {
                        navigate("/dashboard_employee");
                      } else if (utilizador == "professor") {
                        navigate("/dashboard_teacher");
                      } else if (utilizador == "cliente") {
                        navigate("/dashboard_customer");
                      }
                      toast.error(t("erroentrada"));
                    }, 3000);
                  }
                });
              }, 4000);
            } else {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
              toast.error(t("semPrivilegios"));
            }
          } else {
            // if (res.status == 500) {
            //   navigate("/500");
            // }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          // toast.warn(t("erroWebservice"));
        });
    } else {
      if (!email && palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
      } else if (!palavraPasse && email) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
      } else if (!email && !palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
        setErroPassword(true);
      }
    }
  }

  return (
    <div>
      {login2 ? (
        <div className="bodyLogin2">
          <div className="session">
            <form
              className="log-in"
              onSubmit={(e) => {
                e.preventDefault(); // previne que o formulário seja enviado
                VerificaLogin();
              }}
            >
              {/* <div className="topleftrel">
            <IconButton
              onClick={goBack}
              size={window.innerWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
              <div className="topright">
                <IconButton
                  onClick={handleOpenHelp}
                  size={isMobile ? "small" : "medium"}
                >
                  {" "}
                  <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
                </IconButton>
              </div>
              <h4>
                <img
                  src={logo}
                  style={{
                    width: "150px",
                    height: "150px",
                    marginTop: 0,
                    marginBottom: 20,
                  }}
                />
                {/* Tectank <span>SAPI</span> */}
              </h4>
              <h5>
                <span>{t("ola")}</span>
              </h5>

              {/* Email */}
              <TextField
                style={{ width: "30vh", marginBottom: "20px" }}
                id="email"
                label={t("email")}
                variant="standard"
                required={true}
                placeholder="email@email.com"
                error={erroEmail}
                InputProps={{
                  style: {
                    fontSize: isMobile ? "12px" : "16px",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: isMobile ? "12px" : "16px",
                  },
                }}
                helperText={mensagemErroEmail}
                color={sucessoEmail}
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                }}
              />
              {/* Palavra-passe */}
              <TextField
                style={{
                  width: "30vh",
                  marginTop: "10px",
                }}
                id="password"
                label={t("palavraPasse")}
                variant="standard"
                error={erroPassword}
                type={showPassword ? "text" : "password"}
                helperText={mensagemErroPalavraPasse}
                onChange={(e) => {
                  setPalavraPasse(e.target.value);
                  setErroPassword(false);
                  setMensagemErroPalavraPasse("");
                }}
                InputLabelProps={{
                  style: {
                    fontSize: isMobile ? "12px" : "16px",
                  },
                }}
                InputProps={{
                  style: {
                    fontSize: isMobile ? "12px" : "16px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disableRipple
                        disableFocusRipple
                        style={{
                          justifyContent: "right",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                          color: "#8A8A8A",
                        }}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility size={isMobile ? "small" : "large"} />
                        ) : (
                          <VisibilityOff size={isMobile ? "small" : "large"} />
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                required={true}
              ></TextField>
              <Link className="textlinklogin" to={"/recoverpassword"}>
                {t("recuperarPalavraPasse")}
              </Link>
              <Link className="textlinklogin" to={"/active_account_send_email"}>
                {t("reenviarEmailAtivacaoConta")}
              </Link>

              <button
                type="submit"
                className="iniciobtn"
                style={{ marginTop: "20px" }}
              >
                {t("iniciarSessao")}
              </button>
            </form>
          </div>

          <Dialog
            open={openHelp}
            onClose={handleCloseHelp}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
            <DialogContent>
              <p className="ajuda">Socorro</p>
            </DialogContent>
          </Dialog>
          <FooterExterno />
        </div>
      ) : (
        <div>
          <div className="bodyLogin">
            <div className="session">
              <div className="left">
                <div className="slider">
                  <div className="wrapper">
                    <img
                      className="fundo"
                      src={require("../images/fundo1.jpg")}
                    ></img>
                    <img
                      className="fundo"
                      src={require("../images/fundo2.jpg")}
                    ></img>
                    <img
                      className="fundo"
                      src={require("../images/fundo3.jpg")}
                    ></img>
                    <img
                      className="fundo"
                      src={require("../images/fundo1.jpg")}
                    ></img>
                  </div>
                </div>
              </div>

              {loading === true && (
                <form className="log-in">
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </form>
              )}
              {ativo === true && loading === false ? (
                <form className="log-in">
                  <div className="wrapperc">
                    {" "}
                    <svg
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark__circle"
                        cx="30"
                        cy="30"
                        r="40"
                        fill="none !important"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                  <p className="ativarconta" style={{ marginBottom: "70px" }}>
                    {t("contaativada")}
                  </p>
                </form>
              ) : inativo === true && loading === false ? (
                <form className="log-in">
                  <div className="wrapperc">
                    {" "}
                    <svg
                      className="crossmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="crossmark__circle"
                        cx="30"
                        cy="30"
                        r="40"
                        fill="none !important"
                      />
                      <path
                        className="crossmark__check"
                        fill="none"
                        d="M16 16 36 36 M36 16 16 36"
                      />
                    </svg>
                  </div>
                  <p className="ativarconta" style={{ marginBottom: "70px" }}>
                    {t("contaativadaerro")}
                  </p>
                </form>
              ) : null}
            </div>
            <FooterExterno />
          </div>
        </div>
      )}
    </div>
  );
}
export default LeituraQrCode;
