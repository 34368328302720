import {
  Paper,
  Button,
  Icon,
  InputAdornment,
  TextField,
  unstable_composeClasses,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  faEuroSign,
  faFileInvoiceDollar,
  faFilePen,
  faArrowLeft,
  faTags,
  faQuestion,
  faCalendarDays,
  faPlus,
  faMapLocationDot,
  faLink,
  faPhone,
  faPersonSwimming,
  faWaterLadder,
  faPersonDrowning,
  faWater,
  faPersonPregnant,
  faBaby,
  faPersonCane,
  faBicycle,
  faDumbbell,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SimpleImageSlider from "react-simple-image-slider";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { getPiscinasId } from "../../api/requests/Piscinas";
import {
  inscreveCliente,
  enviarEmailInscreveCliente,
  anularInscricaoCliente,
  enviarEmailAnularInscreveCliente,
} from "../../api/requests/Inscricao";

function PoolPageCliente() {
  //mudar para o diretorio de piscinas
  function goBack() {
    if (explorar == 0) {
      navigate("/mypools");
    } else if (explorar == 1) {
      navigate("/explore");
    } else {
      navigate("/poolsu");
    }
  }

  //variaveis
  const { state } = useLocation();
  const { iduser, inscrito, explorar } = state || {};
  const { id } = useParams();
  const [idUser, setIdUser] = useState();
  const [idPiscina, setIdPiscina] = useState();
  //Piscina
  const [paisPiscinaa, setPaisPiscina] = useState("");
  const [termos, setTermos] = useState("");
  const [aceitatermos, setAceitaTermos] = useState(false);
  const [nomePiscina, setNomePiscina] = useState("");
  const [emailPiscina, setEmailPiscina] = useState("");
  const [telefonePiscina, setTelefonePiscina] = useState("");
  const [telefone2Piscina, setTelefone2Piscina] = useState("");
  const [ruaPiscina, setRuaPiscina] = useState("");
  const [codigoPostalPiscina, setCodigoPostalPiscina] = useState("");
  const [cidadePiscina, setCidadePiscina] = useState("");
  const [distritoPiscina, setDistritoPiscina] = useState("");
  const [latitudePiscina, setLatitudePiscina] = useState(0.0);
  const [longitudePiscina, setLongitudePiscina] = useState(0.0);
  const [fotoPiscina, setFotoPiscina] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [idFoto1, setIdFoto1] = useState("");
  const [foto2, setFoto2] = useState([]);
  const [idFoto2, setIdFoto2] = useState("");
  const [foto3, setFoto3] = useState([]);
  const [idFoto3, setIdFoto3] = useState("");
  const [foto4, setFoto4] = useState([]);
  const [idFoto4, setIdFoto4] = useState("");
  const [foto5, setFoto5] = useState([]);
  const [idFoto5, setIdFoto5] = useState("");
  const [foto6, setFoto6] = useState([]);
  const [idFoto6, setIdFoto6] = useState("");
  const [freeze, setFreeze] = useState(false);
  //Modalidades
  const [natacaoAdultos, setNatacaoAdultos] = useState(false);
  const [natacaoBebes, setNatacaoBebes] = useState(false);
  const [natacaoIdosos, setNatacaoIdosos] = useState(false);
  const [hidroginastica, setHidroginastica] = useState(false);
  const [escolaNatacao, setEscolaNatacao] = useState(false);
  const [hidrobike, setHidrobike] = useState(false);
  const [natacaoGravidas, setNatacaoGravidas] = useState(false);
  const [hidroterapia, setHidroterapia] = useState(false);
  const [nadoLivre, setNadoLivre] = useState(false);
  const [equipasFederadas, setEquipasFederadas] = useState(false);
  const [hidropower, setHidropower] = useState(false);
  //Horarios
  const [horario, setHorario] = useState([]);
  const [horario2, setHorario2] = useState([]);
  //Links uteis
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");

  //Dialogs
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);

  const [open6, setOpen6] = React.useState(false);
  const handleOpen6 = () => setOpen6(true);
  const handleClose6 = () => setOpen6(false);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [openInscrever, setOpenInscrever] = React.useState(false);
  const handleOpenInscrever = () => setOpenInscrever(true);
  const handleCloseInscrever = () => setOpenInscrever(false);

  const [openAnula, setOpenAnula] = React.useState(false);
  const handleOpenAnula = () => setOpenAnula(true);
  const handleCloseAnula = () => setOpenAnula(false);

  useEffect(() => {
    if (id) {
      const idU = Cookies.get("uId");
      setIdUser(id);
      setIdPiscina(id);
    }
  }, [iduser, id]);

  async function Inscrever() {
    setFreeze(true);
    await inscreveCliente({
      id_utilizador: idUser,
      id_piscina: idPiscina,
    }).then(async (res) => {
      if (res.success) {
        setTimeout(async function () {
          enviarEmailInscreveCliente({
            id: res.data.id,
          }).then((res2) => {
            if (res2.success) {
              setFreeze(false);
              toast.success(res2.data.msg);
              navigate("/mypools");
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  async function anularInscricao() {
    setFreeze(true);
    await anularInscricaoCliente({
      id_utilizador: iduser,
      id_piscina: id,
    }).then(async (res) => {
      if (res.success) {
        setTimeout(async function () {
          enviarEmailAnularInscreveCliente({
            id: id,
          }).then((res2) => {
            if (res2.success) {
              setFreeze(false);
              toast.success(res2.data.msg);
              navigate("/mypools");
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          });
        }, 2000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  useEffect(() => {
    getPiscinasId(id, {})
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          setNomePiscina(dados.nome);
          setEmailPiscina(dados.email);
          setTelefonePiscina(dados.contacto1);
          setTelefone2Piscina(dados.contacto2);
          setRuaPiscina(dados.morada);
          setCodigoPostalPiscina(dados.codigo_postal);
          setCidadePiscina(dados.cidade);
          setDistritoPiscina(dados.distrito);
          let paisPiscina = dados.pais.split("-");
          setPaisPiscina({ value: paisPiscina[0], label: paisPiscina[1] });
          setLatitudePiscina(parseFloat(dados.latitude));
          setLongitudePiscina(parseFloat(dados.longitude));
          setFotoPiscina(dados.foto_perfil_piscina);
          setTermos(dados.termos_condicoes);

          dados.galeria.forEach((element) => {
            if (element.ordem == 1) {
              setFoto1(element.foto);
              setIdFoto1(element.id_imagem);
            }
            if (element.ordem == 2) {
              setFoto2(element.foto);
              setIdFoto2(element.id_imagem);
            }
            if (element.ordem == 3) {
              setFoto3(element.foto);
              setIdFoto3(element.id_imagem);
            }
            if (element.ordem == 4) {
              setFoto4(element.foto);
              setIdFoto4(element.id_imagem);
            }
            if (element.ordem == 5) {
              setFoto5(element.foto);
              setIdFoto5(element.id_imagem);
            }
            if (element.ordem == 6) {
              setFoto6(element.foto);
              setIdFoto6(element.id_imagem);
            }
          });

          //Modalidades
          setNatacaoAdultos(dados.aulas_adultos);
          setNatacaoBebes(dados.aulas_bebes);
          setNatacaoIdosos(dados.aulas_idoso);
          setHidroginastica(dados.hidroginastica);
          setEscolaNatacao(dados.escola_natacao);
          setHidrobike(dados.hidrobike);
          setNatacaoGravidas(dados.aulas_gravidas);
          setHidroterapia(dados.hidro_terapia);
          setNadoLivre(dados.nado_livre);
          setEquipasFederadas(dados.equipas_federadas);
          setHidropower(dados.hidropower);
          //Horarios
          if (dados.horarios) {
            setHorario2(dados.horarios);
          }
          //Links uteis
          if (dados.link1) {
            setLink1(dados.link1);
          }
          if (dados.link2) {
            setLink2(dados.link2);
          }
          if (dados.link2) {
            setLink3(dados.link3);
          }
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const containerStyle2 = {
    width: "300px",
    height: "300px",
  };

  var center = {
    lat: latitudePiscina,
    lng: longitudePiscina,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAhwf3uzO-aTYJWm-TkeO6Rvf5NoTB3jJs",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();

  const [isActive, setIsActive] = useState(false);

  var slideImages = [
    { url: foto1 },
    { url: foto2 },
    { url: foto3 },
    { url: foto4 },
    { url: foto5 },
    { url: foto6 },
  ];

  var results = slideImages.filter((element) => {
    return element.url != "";
  });

  //Navigator
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="bodyPage2">
        <div className="topleftcliente">
          <IconButton onClick={goBack} size={isMobile ? "small" : "medium"}>
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div>
        <div className="center3">
          {!isMobile ? (
            <div className="topleftpoolp">
              <IconButton onClick={goBack} size={isMobile ? "small" : "medium"}>
                {" "}
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </IconButton>
            </div>
          ) : null}
          {freeze && (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <Dialog
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  textAlign: "center",
                }}
              >
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("aguarde")}
                  </Typography>
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </DialogContent>
              </Dialog>
            </div>
          )}
          <div className="row1">
            {/* Info da piscina */}
            <Stack
              direction={"column"}
              spacing={1}
              sx={{ margin: isMobile ? "4%" : "2%" }}
            >
              <h5 className="titulo">{nomePiscina}</h5>
              <h5 className="subtitulo">
                {ruaPiscina +
                  " " +
                  codigoPostalPiscina +
                  " " +
                  cidadePiscina +
                  " " +
                  paisPiscinaa.label}
              </h5>
            </Stack>
            <Stack
              direction={"column"}
              spacing={1}
              sx={{ margin: isMobile ? "4%" : "2%" }}
            >
              <Avatar
                src={fotoPiscina}
                sx={{
                  width: isMobile ? 90 : 120,
                  height: isMobile ? 90 : 120,
                }}
              />
              {foto1.length > 0 ? (
                <Button onClick={handleOpen2} size="small">
                  <a style={{ fontSize: isMobile ? 8 : 10 }}>
                    {t("maisfotos")}
                  </a>
                </Button>
              ) : null}
            </Stack>
          </div>

          {/* Modalidades */}
          <Paper
            elevation={0}
            style={{
              maxHeight: "40%",
              overflow: "auto",
              marginTop: 40,
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                flexGrow: 0.1,
                justifyContent: "center",
                margin: "10px",
                display: "flex",
              }}
            >
              {nadoLivre && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faWaterLadder}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("nado_livre")}
                  </Typography>
                </Box>
              )}

              {natacaoAdultos && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faPersonSwimming}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("natacao_adultos")}
                  </Typography>
                </Box>
              )}
              {natacaoBebes && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faBaby}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("natacao_bebes")}
                  </Typography>
                </Box>
              )}
              {natacaoIdosos && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faPersonCane}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("natacao_idosos")}
                  </Typography>
                </Box>
              )}
              {hidroginastica && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faPersonDrowning}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("hidroginastica")}
                  </Typography>
                </Box>
              )}
              {escolaNatacao && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faPersonSwimming}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("escolaNatacao")}
                  </Typography>
                </Box>
              )}
              {natacaoGravidas && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faPersonPregnant}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("natacao_gravidas")}
                  </Typography>
                </Box>
              )}
              {hidrobike && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faBicycle}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("hidrobike")}
                  </Typography>
                </Box>
              )}
              {hidropower && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faDumbbell}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("hidropower")}
                  </Typography>
                </Box>
              )}

              {equipasFederadas && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faWater}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("equipas_federadas")}
                  </Typography>
                </Box>
              )}
              {hidroterapia && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faUserNurse}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("hidroterapia")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
          {/* Cards Horario, Direçoes Links uteis, contactos */}
          <div className="containerP">
            <div className="containerrow1">
              <div className="cardpiscina">
                <FontAwesomeIcon
                  className="pageIconPool"
                  icon={faCalendarDays}
                />
                <h1 className="cardspool">{t("horarios")}</h1>
              </div>
              <div className="cardpiscina" onClick={handleOpen3}>
                <FontAwesomeIcon
                  className="pageIconPool"
                  icon={faMapLocationDot}
                />
                <h2 className="cardspool">{t("direcoes")}</h2>
              </div>
              <div className="cardpiscina" onClick={handleOpen6}>
                <FontAwesomeIcon className="pageIconPool" icon={faLink} />
                <h2 className="cardspool">{t("linksuteis")}</h2>
              </div>
              <div className="cardpiscina" onClick={handleOpen5}>
                <FontAwesomeIcon className="pageIconPool" icon={faPhone} />
                <h2 className="cardspool">{t("contactos")}</h2>
              </div>
            </div>
            <div className="containerrow3">
              {/* <div className="cardpiscina2" onClick={goRegister}>
            <h1 className="cardspool2">{t("registo")}</h1>
          </div> */}
              {inscrito == 1 ? (
                <button
                  className="shadow__btn"
                  onClick={() => {
                    navigate("/marcar", {
                      state: {
                        idPiscina: idPiscina,
                      },
                    });
                  }}
                >
                  {t("nadaragora")}
                </button>
              ) : null}

              {inscrito == 0 ? (
                <button className="shadow__btn" onClick={handleOpenInscrever}>
                  {t("inscreveragora")}
                </button>
              ) : null}
            </div>
            {/* Janelas conteudo */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("modalidades")}
              </DialogTitle>
              <DialogContent></DialogContent>
            </Dialog>
            {!isMobile ? (
              <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {t("galeria")}
                </DialogTitle>
                <DialogContent>
                  <div>
                    {results.length == 0 ? (
                      <p>{t("naotemfotos")}</p>
                    ) : (
                      <SimpleImageSlider
                        width={500}
                        height={500}
                        images={results}
                        showBullets={true}
                        showNavs={true}
                      />
                    )}
                  </div>
                </DialogContent>
              </Dialog>
            ) : (
              <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t("galeria")}
                </DialogTitle>
                <DialogContent>
                  <div>
                    <div>
                      {results.length == 0 ? (
                        <p>{t("naotemfotos")}</p>
                      ) : (
                        <SimpleImageSlider
                          width={300}
                          height={300}
                          images={results}
                          showBullets={true}
                          showNavs={true}
                        />
                      )}
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}
            <Dialog
              open={open3}
              onClose={handleClose3}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{t("direcoes")}</DialogTitle>
              <DialogContent>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={
                      !isMobile ? containerStyle : containerStyle2
                    }
                    center={center}
                    zoom={15}
                  >
                    <Marker position={center}></Marker>
                  </GoogleMap>
                ) : null}
              </DialogContent>
            </Dialog>
            <Dialog
              open={openHelp}
              onClose={handleCloseHelp}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
              <DialogContent>
                <p className="ajuda">
                  Nesta página podes encontrar alguma informação da piscina, no
                  caso de quereres saber mais terás de te inscrever nesta
                  piscina.<br></br>
                  Se tiveres algum problema podes entrar em contacto com a
                  equipa de suporte GoPool
                </p>
              </DialogContent>
            </Dialog>

            <Dialog
              open={open5}
              onClose={handleClose5}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("contactos")}
              </DialogTitle>
              <DialogContent>
                <div>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ marginTop: "10px" }}
                  >
                    <p className="conteudoboldblue">{t("contactoprincipal")}</p>{" "}
                    <a className="conteudoblue" href={"tel:" + telefonePiscina}>
                      {" "}
                      {telefonePiscina}
                    </a>
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <p className="conteudoboldblue">
                      {t("contactosecundario")}
                    </p>{" "}
                    <a
                      className="conteudoblue"
                      href={"tel:" + telefone2Piscina}
                    >
                      {" "}
                      {telefone2Piscina}
                    </a>
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <p className="conteudoboldblue">{t("emailc")}</p>{" "}
                    <a className="conteudoblue" href={"mailto:" + emailPiscina}>
                      {" "}
                      {emailPiscina}
                    </a>
                  </Stack>
                </div>
              </DialogContent>
            </Dialog>

            <Dialog
              open={open6}
              onClose={handleClose6}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("linksuteis")}
              </DialogTitle>
              <DialogContent>
                <div>
                  {link1 ? (
                    <Stack
                      direction={"row"}
                      spacing={0}
                      sx={{ marginTop: "10px" }}
                    >
                      <a className="conteudoboldblue">{link1}</a>{" "}
                    </Stack>
                  ) : (
                    <p>{t("semlinks")}</p>
                  )}

                  <Stack direction={"row"} spacing={0}>
                    <a className="conteudoboldblue">{link2}</a>{" "}
                  </Stack>
                  <Stack direction={"row"} spacing={0}>
                    <a className="conteudoboldblue">{link3}</a>{" "}
                  </Stack>
                </div>
              </DialogContent>
            </Dialog>

            <Dialog
              open={openInscrever}
              onClose={() => {
                setOpenInscrever(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                {termos}
                <Stack direction={"column"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ pointerEvents: "none", paddingBottom: 15 }}
                        value={aceitatermos}
                        onChange={(e) => {
                          setAceitaTermos(!aceitatermos);
                        }}
                      />
                    }
                    label={
                      <b>
                        {t("lieaceito")}
                        {t("termoesCondicoes")}
                      </b>
                    }
                  />
                  {aceitatermos == true ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        type="button"
                        className="regbtnfim"
                        style={{ marginTop: "40px" }}
                        onClick={() => {
                          Inscrever();
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("inscrever")}
                      </button>
                    </div>
                  ) : null}
                </Stack>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      {inscrito == 1 ? (
        <div className="bottomright4">
          <button
            className="btnHoras2"
            onClick={() => {
              setOpenAnula(true);
            }}
          >
            {t("anularinscricao")}
          </button>
        </div>
      ) : null}

      <Dialog
        open={openAnula}
        onClose={handleCloseAnula}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("anulaInscricao")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("AnulaInscricaoTexto")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="success"
            onClick={() => {
              anularInscricao();
            }}
            autoFocus
          >
            {t("sim")}
          </Button>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="error"
            onClick={handleCloseAnula}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default PoolPageCliente;
