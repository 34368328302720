import React from "react";
import Header from "../components/Header";

function User() {
  return (
    <div className="profile">
      <Header />
      <h1>User</h1>
    </div>
  );
}

export default User;
