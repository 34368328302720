import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { display, fontSize, maxWidth, Stack, width } from "@mui/system";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import { es as es2, id } from "date-fns/locale";
import { pt as pt2 } from "date-fns/locale";
import { enGB as en2 } from "date-fns/locale";
import Header from "../../components/Header";
import adicionarIcon from "../../images/adicionar.svg";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import mbImg from "./../../images/multibanco.png";
import mbwayfotoImg from "./../../images/mbway.png";
import balcaoImg from "./../../images/balcao.png";
import cartaocreditoImg from "./../../images/cartaocredito.png";
import es from "react-phone-number-input/locale/es";
import {
  RectangleStackIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon,
  BuildingLibraryIcon,
  BanknotesIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import graduationIcon from "../../images/graduationCap.svg";
import calendarIcon from "../../images/calendarPoint.svg";
import swimIcon from "../../images/personSwim.svg";
import swimIconBlack from "../../images/SwimIconBlack.svg";
import graduationIconBlack from "../../images/personSwimBlack.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Box, prepareCssVars } from "@mui/system";
import UnionIcon from "../../images/Union.svg";
import IconPacks from "../../images/packs2.svg";
import ProximasAtividadesIcon from "../../images/Vector.svg";
import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
  RadioGroup,
  Radio,
  FormLabel,
  Alert,
  List,
  Card,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Stepper,
  Step,
  StepContent,
} from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import mb from "../../images/multibanco.png";
import mbwayfoto from "../../images/mbway.png";
import errorgif from "../../images/error.gif";
import successgif from "../../images/success.gif";
import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { Colors } from "../../values/Colors";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  faArrowLeft,
  faCircleCheck,
  faClose,
  faCircleXmark,
  faPen,
  faCircleStop,
  faTrash,
  faLock,
  faUpload,
  faMobile,
  faCreditCard,
  faTicket,
  faMoneyBillTransfer,
  faGraduationCap,
  faPersonSwimming,
  faCalendarDay,
  faCalendarDays,
  faFaceFrown,
  faFaceSmile,
  faCashRegister,
  faArrowRight,
  faCheck,
  faPlusCircle,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useSearchParams } from "react-router-dom";
import {
  enviarEmailPostCliente,
  getCLientes,
  getCLientesApp,
  getCliente,
  postCliente,
  putCliente,
  putStatusCliente,
} from "../../api/requests/Clientes";
import {
  getCategoriaNadoLivre,
  getCategoriasTurmas,
  getCategoriaTurmas,
  getCategoriaUtilizador,
  getModalidadesAulasPiscina,
  getModalidadesPiscina,
  getPacksPiscina,
  getPiscinas,
  getPiscinasId,
  getTurmas,
} from "../../api/requests/Piscinas";
import {
  aprovarPagamentoBalcao,
  associarPagamentosMarcacoes,
  cancelarPagamentoBalcao,
  emailCompraPackMarcacao,
  emailInscricaoTurma,
  emailMarcacao,
  emailSucessoPagamento2,
  linhasCompra,
  listaDePagamentos,
  pagamentoCartaoCredito,
  pagamentoMbway,
  pagamentoMultibanco,
  verificarPagamentosEmDia,
} from "../../api/requests/Pagamentos";
import Search2 from "../../components/DataTable/Search2";
import {
  getPiscinaAgua,
  listaClientesPiscina,
} from "../../api/requests/PiscinaAgua";
import Nav from "../../components/Nav";
import { verificarInscricaoPagamentoPendente } from "../../api/requests/Inscricao";
import {
  getDatasBanhosLivres,
  getHorasBanhosLivres,
  getInfoCompra,
  getInfoCompra2,
  getListaPacks,
  getPackUtilizador,
  getVerificacaoPackUtilizador,
  getVerificacaoSobreposicao,
  marcacaoBalcao,
  registarMarcacao,
  registarMarcacaoInativa,
  proximasMarcacoes,
  proximasMarcacoesU,
  turmasInscrito,
  cancelarInscricao,
  emailCancelarInscricao,
  cancelarMarcacao,
  emailCancelarMarcacao,
} from "../../api/requests/Marcacoes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  definePassEmail,
  recuperaPass,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  anularInscricaoCliente,
  enviarEmailAnularInscreveCliente,
} from "../../api/requests/Inscricao";
import {
  deleteFilho,
  getFilho,
  getFilhos,
  postFilho,
  putFilho,
} from "../../api/requests/ContaFamiliar";
import {
  CheckIcon,
  PlusSmallIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { getQrCodes } from "../../api/requests/Qrcode";
import { format, parseISO } from "date-fns";
import {
  cancelarPagamentoPendente,
  emailErroPagamento,
} from "../../api/requests/Pagamentos";
import { getInscricoesId } from "../../api/requests/Utilizadores";
import { DayPicker } from "react-day-picker";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
// import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function ClientesPiscina() {
  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.inativo,
        contrastText: "#ffffff",
      },
      secondary: {
        main: Colors.ativo,
        contrastText: "#ffffff",
      },
    },
  });

  const [clientes, setClientes] = useState([]);
  const [editarCliente, setEditarCliente] = useState(false);
  const [clientesCopy, setClientesCopy] = useState([]);
  const [packs, setPacks] = useState([]);
  const [packsCopy, setPacksCopy] = useState([]);
  const [packsCompra, setPacksCompra] = useState([]);
  const [packsUtilizador, setPacksUtilizador] = useState([]);

  const [search, setSearch] = useState("");
  const [funcionarioSelecionadoId, setFuncionarioSelecionadoId] =
    useState(null);
  const [idFilho, setidFilho] = useState(null);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosOriginais2, setDadosOriginais2] = useState([]);
  const [selected, setSelected] = useState("1");
  const handleChange = (event, newAlignment) => {
    setSelected(newAlignment);
  };
  const [dataNascimento, setDataNascimento] = useState(new Date());
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [nomeFilho, setNomeFilho] = useState("");
  const [moradaFilho, setMoradaFilho] = useState("");
  const [nifFilho, setNifFilho] = useState("");
  const [codigoPostalFilho, setCodigoPostalFilho] = useState("");
  const [NascimentoFilho, setNascimentoFilho] = useState(new Date());
  const [generoFilho, setGeneroFilho] = useState("");
  const [erroNomeFilho, setErroNomeFilho] = useState(false);
  const [erroNifFilho, setErroNifFilho] = useState(false);
  const [erroMoradaFilho, setErroMoradaFilho] = useState(false);
  const [erroCodigoPostalFilho, setErroCodigoPostalFilho] = useState(false);
  const [erroNascimentoFilho, setErroNascimentoFilho] = useState(false);
  const [mensagemErroNifFilho, setMensagemErroNifFilho] = useState("");
  const [mensagemErroCodigoPostalFilho, setMensagemErroCodigoPostalFilho] =
    useState("");
  const [mensagemErroNascimentoFilho, setMensagemErroNascimentoFilho] =
    useState("");
  const [mensagemErroDataNascimento, setMensagemErroDataNascimento] =
    useState("");

  // proximas atividades
  const [atividades, setatividades] = useState([]);
  const [qrCode, setQrCode] = useState("");
  const [qrCodeStatus, setQrCodeStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  //MArcar
  const [aulas, setAulas] = useState(false);
  const [tipoEscolhido, setTipoEscolhido] = useState(false);
  const [aulaEscolhido, setAulaEscolhido] = useState(false);
  const [piscinas, setPiscinas] = useState([]);

  const [cartaocredito, setCartaoCredito] = useState(false);
  const [tenhopack, setTenhoPack] = useState(false);
  const [multibanco, setMultibanco] = useState(false);
  const [balcao, setBalcao] = useState(false);
  const [pagapack, setPagaPack] = useState(false);
  const [semDados, setSemDados] = useState(true);
  const [users, setUsers] = useState([]);
  let ArrayFamilia = [];
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [submittedData, setSubmittedData] = useState({});
  const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);
  const [escolhido, setEscolhido] = useState(false);
  const [horaEscolhida, setHoraEscolhida] = useState(0);
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState();
  const [aulasPiscina, setAulasPiscina] = useState(false);
  const [nadoLivrePiscina, setNadoLivrePiscina] = useState(false);
  const [bookedDays, setBookedDays] = useState([]);
  const [horasDisponiveis, setHorasDisponiveis] = useState([]);
  const [verificaPackUtilizador, setVerificaPackUtilizador] = useState(false);
  const [packsEscolhido, setPacksPiscina] = useState([]);
  //Se o utilizador é filho ou não
  const [filho, setFilho] = useState(false);
  //Pack escolhido
  const [packEscolhido, setPackEscolhido] = useState(0);
  //Lista packs do utilizador
  const [listaPacks, setListaPacks] = useState([]);
  //Pack do utilizador escolhido
  const [packUtilizadorEscolhido, setPackUtilizadorEscolhido] = useState(0);
  //Variável para fazer loading para reencaminhas para outra página
  //Dados da compra
  const [compraInscricao, setCompraInscricao] = useState(0);
  const [compraPreco, setCompraPreco] = useState(0);
  const [compraTotal, setCompraTotal] = useState(0);
  //Dados multibanco
  const [pagar, setPagar] = useState(false);

  //MbWay await
  const [mbwayAwait, setMbwayAwait] = useState(false);

  //Marcação com método de pagamento pack
  const [marcacaoConcluida, setMarcacaoConcluida] = useState(false);
  const [marcacaoConcluida2, setMarcacaoConcluida2] = useState(false);

  //Modalidades da piscina
  const [modalidadesAulas, setModalidadesAulas] = useState([]);
  //Modalidade escolhida
  const [modalidadeEscolhida, setModalidadeEscolhida] = useState();
  //Turmas
  //Turma escolhida
  const [turmaEscolhida, setTurmaEscolhida] = useState(0);
  //Precos
  const [idUtilizadorEscolhido, setIdUtilizadorEscolhido] = useState("");
  const [anual, setAnual] = useState(false);
  const [mensal, setMensal] = useState(false);
  const [desconto, setDesconto] = useState(0);
  const [pagamentoCancelado, setPagamentoCancelado] = useState(false);

  const [idDesconto, setIdDesconto] = useState(0);
  const [descricaoDesconto, setDescricaoDesconto] = useState("");
  const [valorDesconto, setValorDesconto] = useState(0);
  const [openPagamentoAulas, setOpenPagamentoAulas] = useState(false);

  const [cancelarPagamento, setCancelarPagamento] = useState(false);

  // PAgamentos

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [pagamentoMensal, setPagamentoMensal] = useState([]);
  const [pagamentoLocal, setPagamentoLocal] = useState([]);
  const [referencia, setReferencia] = useState("");
  const [entidade, setEntidade] = useState("");
  const [montante, setMontante] = useState("");
  const [dataExpira, setDataExpira] = useState("");
  const [buttonVisibility, setButtonVisibility] = useState(false);
  const [estado, setEstado] = useState(false);
  const [idEscolhida, setIdEscolhida] = useState("");
  const [pagamentos, setPagamentos] = useState([]);
  const [mbway, setMbway] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [erroTelefone, setErroTelefone] = useState(false);
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [mensagemMbway, setMensagemMbway] = useState(false);
  const [janelaPendente, setJanelaPendente] = useState(false);
  const [janelaPago, setJanelaPago] = useState(false);
  const [janelaCancelado, setJanelaCancelado] = useState(false);
  const [janelaFuturo, setJanelaFuturo] = useState(false);
  const [janelaNoLocal, setJanelaNoLocal] = useState(false);
  const [dadosPendente, setDadosPendente] = useState([]);

  //Validações

  const [turmas, setTurmas] = useState([]);
  const [nomeCliente, setNomeCliente] = useState("");
  const [emailCliente, setEmailCliente] = useState("");
  const [telefoneCliente, setTelefoneCliente] = useState("");
  const [cargoCliente, setCargoCliente] = useState("");
  const [nifCliente, setNifCliente] = useState("");
  const [moradaCliente, setMoradaCliente] = useState("");
  const [codigoPostalCliente, setCodigoPostalCliente] = useState("");
  const [fotoCliente, setFotoCliente] = useState([]);
  const [statusCliente, setStatusCliente] = useState("");
  const [NascimentoCliente, setNascimentoCliente] = useState(new Date());
  // Responsável
  const [erroNomeCliente, setErroNomeCliente] = useState(false);
  const [erroEmailCliente, setErroEmailCliente] = useState(false);
  const [erroTelefoneCliente, setErroTelefoneCliente] = useState(false);
  const [erroCargoCliente, setErroCargoCliente] = useState(false);
  const [erroNifCliente, setErroNifCliente] = useState(false);
  const [erroMoradaCliente, setErroMoradaCliente] = useState(false);
  const [erroCodigoPostalCliente, setErroCodigoPostalCliente] = useState(false);
  const [erroNascimentoCliente, setErroNascimentoCliente] = useState(false);

  // Responsável
  const [mensagemErroEmailCliente, setMensagemErroEmailCliente] = useState("");
  const [mensagemErroTelefoneCliente, setMensagemErroTelefoneCliente] =
    useState("");
  const [mensagemErroNifCliente, setMensagemErroNifCliente] = useState("");
  const [mensagemErroCodigoPostalCliente, setMensagemErroCodigoPostalCliente] =
    useState("");
  const [mensagemErroNascimentoCliente, setMensagemErroNascimentoCliente] =
    useState("");
  const [genero, setGenero] = useState("");
  const [erroGenero, seterroGenero] = useState(false);
  const [mensagemErroGenero, setMensagemErroGenero] = useState("");

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  const [cancelarEdicao2, setCancelarEdicao2] = useState(false);

  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  const [eliminar2, setEliminar2] = useState(false);

  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);
  const [reporPassword, setreporPassword] = useState(false);
  const [edicao, setEdicao] = useState(true);
  const [edicao2, setEdicao2] = useState(true);
  const [marcacoes, setMarcacoes] = useState([]);
  const [familia, setFamilia] = useState(false);
  const [marcar, setMarcar] = useState(false);
  const [turmap, setTurmaP] = useState(false);
  const [marcacaop, setMarcacaoP] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [idTurma, setIdTurma] = useState(null);
  const [Filhos, setFilhos] = useState([]);
  const [EditarFilho, setEditarFilho] = useState(false);
  const [CriarFilho, setCriarFilho] = useState(false);
  const [FilhosCopy, setFilhosCopy] = useState([]);
  const [idMarcacao, setIdMarcacao] = useState("");
  const [PacksCliente, SetPacksCliente] = useState("");
  const [AtividadesCliente, SetAtividadesCliente] = useState("");
  const [PagamentosCliente, setPagamentosCliente] = useState("");
  const [selecionado, setSelecionado] = useState(null);
  const handleClick = (botaoSelecionado) => {
    if (selecionado !== botaoSelecionado) {
      setSelecionado(botaoSelecionado);
    }
    if (botaoSelecionado != "marcar") {
      setActiveStep(0);
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      setAulas(false);
      setIdUtilizadorEscolhido("");
      setTipoEscolhido(false);
      setAulaEscolhido(false);
      setSelectedDay("");
      setAnual(false);
      setMensal(false);
      setTenhoPack(false);
    }
  };

  const [suspensoes, setSuspensoes] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [anular, setAnular] = useState(false);
  const [reporPwd, setReporPwd] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const handleClickOpenPopupReporPassword = () => {
    setreporPassword(true);
  };

  const handleClosePopupReporPassword = () => {
    setReporPwd(false);
    setreporPassword(false);
  };
  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };
  const handleClickOpenPopupCancelar2 = () => {
    setCancelarEdicao2(true);
  };

  const handleClosePopupCancelar2 = () => {
    setCancelarEdicao2(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };
  const handleClickOpenPopupEliminar2 = () => {
    setEliminar2(true);
  };

  const handleClosePopupEliminar2 = () => {
    setEliminar2(false);
  };
  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };
  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };
  const handleClickOpenPopupTurma = () => {
    setTurmaP(true);
  };

  const handleClosePopupTurma = () => {
    setTurmaP(false);
  };

  const handleClickOpenPopupMarcacao = () => {
    setMarcacaoP(true);
  };

  const handleClosePopupMarcacao = () => {
    setMarcacaoP(false);
  };

  const handleClickOpenContaFamiliar = () => {
    setFamilia(true);
  };
  const handleClickCloseContaFamiliar = () => {
    setFamilia(false);
  };
  const handleClickOpenEditarFilho = () => {
    setEditarFilho(true);
  };
  const handleClickCloseEditarFilho = () => {
    setEditarFilho(false);
    setEdicao2(true);

    //Colocar os dados originais
    setNomeFilho(dadosOriginais2.nome);
    setMoradaFilho(dadosOriginais2.morada);
    setNifFilho(dadosOriginais2.nif);
    setGeneroFilho(dadosOriginais2.genero);
    setCodigoPostalFilho(dadosOriginais2.codigo_postal);
    setNascimentoFilho(dadosOriginais2.data_nascimento_final);

    //Limpar erros e mensagens de erro
    setErroNomeFilho(false);
    setErroNifFilho(false);
    setErroMoradaFilho(false);
    setErroCodigoPostalFilho(false);
    setErroNascimentoFilho(false);
    setMensagemErroCodigoPostalFilho("");
    setMensagemErroNascimentoFilho("");
    setMensagemErroNifFilho("");
  };
  const handleClickOpenCriarFilho = () => {
    setNomeFilho("");
    setNifFilho("");
    setCodigoPostalFilho("");
    setNascimentoFilho("");
    setGeneroFilho("");
    setMoradaFilho("");
    setCriarFilho(true);
  };
  const handleClickCloseCriarFilho = () => {
    setCriarFilho(false);
  };
  const handleClickOpenPacksCliente = () => {
    SetPacksCliente(true);
  };
  const handleClickClosePacksCliente = () => {
    SetPacksCliente(false);
  };
  const handleClickOpenAtividadesCliente = () => {
    SetAtividadesCliente(true);
  };
  const handleClickCloseAtividadesCliente = () => {
    SetAtividadesCliente(false);
  };
  const handleClickOpenPagamentosCliente = () => {
    setPagamentosCliente(true);
  };
  const handleClickClosePagamentosCliente = () => {
    setPagamentosCliente(false);
  };
  const handleClickOpenMarcar = () => {
    setMarcar(true);
  };
  const handleClickCloseMarcar = () => {
    setMarcar(false);
  };

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [openCriarCliente, setOpenCriarCliente] = useState(false);
  const handleCloseCriarCliente = () => {
    setOpenCriarCliente(false);
    setNomeCliente("");
    setErroNomeCliente(false);
    setEmailCliente("");
    setErroEmailCliente(false);
    setTelefoneCliente("");
    setErroTelefoneCliente(false);
    setNascimentoCliente(new Date());
    setErroDataNascimento(false);
    setMensagemErroDataNascimento("");
    setGenero("");
    seterroGenero(false);
  };

  const handleClickOpenCriarCliente = () => {
    setOpenCriarCliente(true);
  };
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const mapIcon = (index) => {
    switch (index) {
      case 2:
        return (
          <CheckIcon
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        );
      case 1:
        return (
          <XMarkIcon
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        );
      default:
        return null;
    }
  };

  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "email", "status"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "email", "status"] : []);
  };
  const scrollToDiv = () => {
    setEdicao(!edicao);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arrayClientes = clientesCopy;
      arrayClientes = arrayClientes.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setClientes(arrayClientes);
    } else {
      setClientes(clientesCopy);
    }
  }, [search]);

  const [freeze, setFreeze] = useState(false);
  const [idPiscina, setIdPiscina] = useState("");
  const [motivo, setMotivo] = useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaClientesPiscina({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
              email: element.email,
              status: element.status,
              nif: element.nif,
            };
            arrayFinal.push(linha);
            setClientes(arrayFinal);
            setClientesCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const handleCloseCancelarPagamento = () => {
    setCancelarPagamento(false);
  };
  const handleClickOpenCancelarPagamento = () => {
    setCancelarPagamento(true);
  };
  const footer = selectedDay
    ? `${t("escolheuDia")} ${
        selectedDay.getDate() < 10
          ? "0" + selectedDay.getDate()
          : selectedDay.getDate()
      }/${
        selectedDay.getMonth() + 1 < 10
          ? "0" + (selectedDay.getMonth() + 1)
          : selectedDay.getMonth() + 1
      }/${selectedDay.getFullYear()}`
    : t("escolhaDia");

  function getPacks() {
    let filho =
      funcionarioSelecionadoId == idUtilizadorEscolhido
        ? 0
        : idUtilizadorEscolhido;

    getPacksPiscina({
      id_piscina: localStorage.getItem("idPiscina"),
      id_filho: filho,
      id_cliente: funcionarioSelecionadoId,
    }).then((res) => {
      if (res.success) {
        let dados = res.data.data;

        setPacksCompra(dados);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }
  getListaPacks({
    id_cliente: funcionarioSelecionadoId,
    id_piscina: localStorage.getItem("idPiscina"),
  }).then((res) => {
    if (res.success) {
      let arrayFinal = [];
      res.data.data.forEach((element) => {
        const linha = {
          id: element.id_pack_cliente,
          nomePack: element.nomePack,
          nomeUtilizador:element.nome_utilizador,
          nomePiscina: element.nomePiscina,
          num_tickets: element.num_tickets,
          num_tickets_utilizados: element.num_tickets_utilizados,
        };
        arrayFinal.push(linha);
     
        setPacksUtilizador(arrayFinal);
    
      });
    } else {
      if (res.status == 500) {
        navigate("/500");
      } else if (res.status == 403) {
        let utilizador = Cookies.get("utilizador");
        if (utilizador == "superadmin") {
          navigate("/dashboard_superadmin");
        } else if (utilizador == "supercomercial") {
          navigate("/dashboard_supercommercial");
        } else if (utilizador == "comercial") {
          navigate("/dashboard_commercial");
        } else if (utilizador == "admin") {
          navigate("/dashboard_admin");
        } else if (utilizador == "funcionario") {
          navigate("/dashboard_employee");
        } else if (utilizador == "professor") {
          navigate("/dashboard_teacher");
        } else if (utilizador == "cliente") {
          navigate("/dashboard_customer");
        }
      }
      toast.error(res.error);
    }
  })
  .catch((error) => {
    //toast.warn(t("erroWebservice"));
  });
  const handleClosePagamentoAulas = () => {
    setOpenPagamentoAulas(false);
  };
  const handleClickOpenPagamentoAulas = () => {
    getPacks(idEscolhida);
    setOpenPagamentoAulas(true);
  };
  //Variável popup balcão
  const [popupBalcao, setPopupBalcao] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeStepPagarAulas, setActiveStepPagarAulas] = useState(0);
  const handleDayClick = (day) => {
    bookedDays.some(function (d) {
      if (d.getTime() === day.getTime()) {
        setSelectedDay(day);
      }
    });
    useEffect(() => {
      setTimeout(() => {
        verificarPagamentosEmDia().then((res) => {
          if (res.data.success) {
            LimparVariaveis();
          }
        });
      }, 500);
    }, []);
  };
  useEffect(() => {
    if (!mbwayAwait && pagar) {
      const redirectTimeout = setTimeout(() => {
        let utilizador = Cookies.get("utilizador");
        if (utilizador == "superadmin") {
          navigate("/dashboard_superadmin");
        } else if (utilizador == "supercomercial") {
          navigate("/dashboard_supercommercial");
        } else if (utilizador == "comercial") {
          navigate("/dashboard_commercial");
        } else if (utilizador == "admin") {
          navigate("/dashboard_admin");
        } else if (utilizador == "funcionario") {
          navigate("/dashboard_employee");
        } else if (utilizador == "professor") {
          navigate("/dashboard_teacher");
        } else if (utilizador == "cliente") {
          navigate("/dashboard_customer");
        } else {
          navigate("/dashboard_customer");
        }
      }, 10000); // tempo limite em milissegundos
      return () => clearTimeout(redirectTimeout);
    }
  }, [mbwayAwait]);

  useEffect(() => {
    if (idFilho !== null) {
      getFilho({ idFilho: idFilho, id: funcionarioSelecionadoId })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];
            setDadosOriginais2(dados);
            setNomeFilho(dados.nome);
            setNifFilho(dados.nif);
            setMoradaFilho(dados.morada);
            setCodigoPostalFilho(dados.codigo_postal);
            setNascimentoFilho(dados.data_nascimento_final);
            setGeneroFilho(dados.genero);

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idFilho]);

  function formatDateAndTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  useEffect(() => {
    if (funcionarioSelecionadoId !== null) {
      let nomeUtilizadorF;
      getCliente(funcionarioSelecionadoId)
        .then((res) => {
          if (res.success) {
            let dados = res.data.data;
            setSuspensoes(res.data.suspensoes);
            const linha = {
              id: dados.id_utilizador,
              filho: dados.filho ? 1 : 0,
              nome: dados.nome,
              nif: dados.nif,
              img: dados.foto_perfil,
              data_nascimento: dados.data_nascimento,
            };

            ArrayFamilia.push(linha);

            setSemDados(false);
            setDadosOriginais(dados);
            setNomeCliente(dados.nome);
            nomeUtilizadorF = dados.nome;
            setEmailCliente(dados.email);
            setGenero(dados.genero);
            setTelefoneCliente(dados.contacto);
            setCargoCliente(dados.cargo);
            setNifCliente(dados.nif);
            setMoradaCliente(dados.morada);
            setCodigoPostalCliente(dados.codigo_postal);

            if (dados.foto_perfil) {
              setFotoCliente(dados.foto_perfil);
            }
            setNascimentoCliente(dados.data_nascimento_final);
            setStatusCliente(dados.status);
            setMotivo(res.data.obs);
            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
      let filhos;
      getFilhos({
        id: funcionarioSelecionadoId,
        idPiscina: localStorage.getItem("idPiscina"),
      })
        .then((res) => {
          if (res.success) {
            let arrayFinal = [];
            res.data.data.forEach((element) => {
              const linha = {
                id: element.id_filho,
                nome: element.nome,
                nif: element.nif,
                img: element.foto,
              };
              arrayFinal.push(linha);
              ArrayFamilia.push(linha);
              setFilhos(arrayFinal);
              setFilhosCopy(arrayFinal);
            });
            filhos = ArrayFamilia;
            setUsers(ArrayFamilia);
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
      listaDePagamentos({ id_cliente: funcionarioSelecionadoId })
        .then((res) => {
          if (res.success) {
            const pag = res.data.data;
            const arrayOrdenado = pag.sort(
              (a, b) => new Date(b.criado) - new Date(a.criado)
            );
            setPagamentos(arrayOrdenado);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
      proximasMarcacoes({ id_cliente: funcionarioSelecionadoId }).then(
        (res) => {
          if (res.success) {
            let arrayFinal = [];
            let dados = res.data.data;

            dados.forEach((element) => {
              const dataParts = element.dia.split("/");
              const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
              const data = new Date(dataFormatada);
              const dataInicio = `${data.toISOString().substr(0, 10)}T${
                element.horaInicio
              }`;
              const dataFim = `${data.toISOString().substr(0, 10)}T${
                element.horaFim
              }`;

              let titulo = element.nome;
              if (titulo == "Marcação de nado livre") {
                titulo = t("marcacaoNadoLivre");
              } else if (titulo == "Natação para adultos") {
                titulo = t("natacao_adultos");
              } else if (titulo == "Natação para bebés") {
                titulo = t("natacao_bebes");
              } else if (titulo == "Natação para idosos") {
                titulo = t("natacao_idosos");
              } else if (titulo == "Natação para grávidas") {
                titulo = t("natacao_gravidas");
              } else if (titulo == "Aulas de Hidroginástica") {
                titulo = t("hidroginastica");
              } else if (titulo == "Aulas de Hidrobike") {
                titulo = t("hidrobike");
              } else if (titulo == "Aulas de Hidroterapia") {
                titulo = t("hidroterapia");
              } else if (titulo == "Aulas de Hidropower") {
                titulo = t("hidropower");
              } else if (titulo == "Escola de Natação") {
                titulo = t("escolaNatacao");
              }

              if (element.cancelado != 1) {
                const linha = {
                  title: titulo,
                  dia: element.dia,
                  hI: element.horaInicio,
                  hF: element.horaFim,
                  description: element.nomePiscina,
                  id_filho: element.id_filho,
                  id_piscina: element.id_piscina,
                  id_turma: element.id_turma,
                  id_pack_cliente: element.id_pack_cliente,
                  start: dataInicio,
                  end: dataFim,
                  nome_turma: element.nome_turma,
                  color: element.id_turma != null ? "#ff6564" : "#84b6f4",
                };
                if (element.tipo != 2) {
                  arrayFinal.push(linha);
                }
                titulo = "";
              }
            });
            //Filtrar apenas para marcações futuras
            const hoje = new Date().toISOString().substr(0, 10);

            const marcacoesFuturas = arrayFinal.filter((marcacao) => {
              return marcacao.start >= hoje;
            });

            setatividades(marcacoesFuturas);
          }
        }
      );
      turmasInscrito({ id_cliente: funcionarioSelecionadoId })
        .then((res) => {
          if (res.success) {
            let arrayFinal = [];
            res.data.data.forEach((element, i) => {
              const linha = {
                id: i,
                nome: element.nomeTurma,
                utilizador:
                  element.id_filho != 0 && element.id_filho != null
                    ? filhos.find(
                        (element2) => element2.id === element.id_filho
                      )?.nome
                    : nomeUtilizadorF,
              };

              arrayFinal.push(linha);
            });
            setTurmas(arrayFinal);
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
      getListaPacks({
        id_piscina: localStorage.getItem("idPiscina"),
        id_cliente: funcionarioSelecionadoId,
      })
        .then((res) => {
          if (res.success) {
            let arrayFinal = [];
            res.data.data.forEach((element) => {
              const linha = {
                id: element.id_pack,
                nome: element.nomePack,
                categoria: element.nome_categoria,
                num_tickets: element.num_tickets,
                preco: element.preco,
              };
              arrayFinal.push(linha);
              setPacks(arrayFinal);
              setPacksCopy(arrayFinal);
            });
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
      proximasMarcacoesU({ id: funcionarioSelecionadoId }).then((res) => {
        if (res.success) {
          let arrayFinal = [];
          let dados = res.data.data;
          dados.forEach((element) => {
            if (element.id_usa_ticket) {
              const dataParts = element.dia.split("/");
              const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
              const data = new Date(dataFormatada);
              const dataInicio = new Date(
                `${data.toISOString().substr(0, 10)}T${element.horaInicio}`
              )
                .toISOString()
                .replace("T", " ")
                .replace("Z", "")
                .slice(0, -7);
              const dataFim = new Date(
                `${data.toISOString().substr(0, 10)}T${element.horaFim}`
              )
                .toISOString()
                .replace("T", " ")
                .replace("Z", "")
                .slice(0, -7);

              let titulo = element.nome;
              if (titulo == "Marcação de nado livre") {
                titulo = t("marcacaoNadoLivre");
              } else if (titulo == "Natação para adultos") {
                titulo = t("natacao_adultos");
              } else if (titulo == "Natação para bebés") {
                titulo = t("natacao_bebes");
              } else if (titulo == "Natação para idosos") {
                titulo = t("natacao_idosos");
              } else if (titulo == "Natação para grávidas") {
                titulo = t("natacao_gravidas");
              } else if (titulo == "Aulas de Hidroginástica") {
                titulo = t("hidroginastica");
              } else if (titulo == "Aulas de Hidrobike") {
                titulo = t("hidrobike");
              } else if (titulo == "Aulas de Hidroterapia") {
                titulo = t("hidroterapia");
              } else if (titulo == "Aulas de Hidropower") {
                titulo = t("hidropower");
              }

              const formatoData = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              };

              const dataInicioFormatada = new Date(dataInicio);
              const dataFormatada2 = dataInicioFormatada.toLocaleDateString(
                "pt-BR",
                formatoData
              );
              const horaInicioFormatada =
                dataInicioFormatada.toLocaleTimeString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                });

              const dataFimFormatada = new Date(dataFim);
              const horaFimFormatada = dataFimFormatada.toLocaleTimeString(
                "pt-BR",
                { hour: "2-digit", minute: "2-digit" }
              );

              const linha = {
                id: element.id_usa_ticket,
                title: titulo,
                description: element.nomePiscina,
                start: dataInicio,
                end: dataFim,
                dataFinal:
                  dataFormatada2 +
                  " " +
                  horaInicioFormatada +
                  " - " +
                  horaFimFormatada,
                nome_turma: element.nome_turma,
                utilizador:
                  element.id_filho != 0 && element.id_filho != null
                    ? filhos.find(
                        (element2) => element2.id === element.id_filho
                      )?.nome
                    : nomeUtilizadorF,
                color: element.id_turma != null ? "#ff6565" : "#84b6f4",
              };

              arrayFinal.push(linha);
              titulo = "";
            } else {
            }
          });
          //Filtrar apenas para marcações futuras
          const hoje = new Date().toISOString().substr(0, 10);

          const marcacoesFuturas = arrayFinal.filter((marcacao) => {
            return marcacao.start >= hoje;
          });

          setMarcacoes(marcacoesFuturas);
        }
      });
      setIdEscolhida(localStorage.getItem("idPiscina"));
    }
    getPiscinasId(localStorage.getItem("idPiscina"), {})
      .then((res) => {
        if (res.success) {
          let element = res.data.data;
          let arrayFinal = [];
          const linha = {
            id: element.id_piscina_edificio,
            nome: element.nome,
            cidade: element.cidade,
            morada: element.morada,
            img: element.foto_perfil,
          };
          arrayFinal.push(linha);
          setPiscinas(arrayFinal);
          // alert(arrayFinal)
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {});
  }, [funcionarioSelecionadoId]);

  useEffect(() => {
    if (idEscolhida && idUtilizadorEscolhido && tipoEscolhido == true) {
      handleNext();
    } else if (tipoEscolhido == true && !idEscolhida) {
      toast.error(t("escolhaumapiscina"));
    } else if (tipoEscolhido == true && !idUtilizadorEscolhido) {
      toast.error(t("escolhaumutilizador"));
      // }else if(tipoEscolhido == false && idUtilizadorEscolhido && idEscolhida){
      toast.error(t("escolhatipoaula"));
    }
  }, [idUtilizadorEscolhido, tipoEscolhido, idEscolhida, tipoEscolhido]);

  useEffect(() => {}, [activeStepPagarAulas]);

  useEffect(() => {
    if (selectedDay) {
      let year = selectedDay.getFullYear();
      let month =
        selectedDay.getMonth() + 1 < 10
          ? "0" + (selectedDay.getMonth() + 1)
          : selectedDay.getMonth() + 1;
      let day =
        selectedDay.getDate() < 10
          ? "0" + selectedDay.getDate()
          : selectedDay.getDate();
      let dataFinal = `${day}/${month}/${year}`;

      getHorasBanhosLivres({
        id_piscina: idEscolhida,
        id_cliente: funcionarioSelecionadoId,
        data: dataFinal,
      }).then((res) => {
        if (res.success) {
          let dados = res.data.data;

          dados.sort((a, b) => {
            const horaInicioA = a.horaInicio;
            const horaInicioB = b.horaInicio;

            if (horaInicioA < horaInicioB) {
              return -1;
            }
            if (horaInicioA > horaInicioB) {
              return 1;
            }

            return 0;
          });

          setHorasDisponiveis(dados);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (horaEscolhida) {
      handleNext();
    }
  }, [horaEscolhida]);

  useEffect(() => {}, [turmaEscolhida]);

  useEffect(() => {}, [packEscolhido]);

  useEffect(() => {}, [packUtilizadorEscolhido]);
  function getModalidadespiscinas(idPiscina) {
    let categoriasTurma = [];
    let categoriasNadoLivre = [];
    getCategoriaTurmas({
      id_piscina: idPiscina,
      id_cliente: funcionarioSelecionadoId,
    }).then((res) => {
      if (res.success) {
        categoriasTurma = res.data.data;
      }
    });
    getCategoriaNadoLivre({
      id_piscina: idPiscina,
      id_cliente: funcionarioSelecionadoId,
    }).then((res) => {
      if (res.success) {
        categoriasNadoLivre = res.data.data;
      }
    });
    let utilizadorEscolhido = filho
      ? users.find(
          (element) => element.filho == 1 && element.id == idUtilizadorEscolhido
        )
      : users.find(
          (element) => element.filho == 0 && element.id == idUtilizadorEscolhido
        );

    let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

    getModalidadesAulasPiscina({
      id_piscina: idPiscina,
      id_filho: id_filho,
      id_cliente: funcionarioSelecionadoId,
    }).then((res) => {
      setModalidadesAulas(res.data.data);
    });

    getModalidadesPiscina({
      id_piscina: idPiscina,
      id_cliente: funcionarioSelecionadoId,
    }).then(async (res) => {
      if (res.success) {
        let dados = res.data.data;

        let utilizadorEscolhido = filho
          ? users.find(
              (element) =>
                element.filho == 1 && element.id == idUtilizadorEscolhido
            )
          : users.find(
              (element) =>
                element.filho == 0 && element.id == idUtilizadorEscolhido
            );

        //Saber qual a categoria do utilizador
        let categoriaUtilizador;
        let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

        let result = await getCategoriaUtilizador({
          id_filho: id_filho,
          id_cliente: funcionarioSelecionadoId,
        });
        if (result.success) {
          categoriaUtilizador = result.data.data;
        }

        if (
          dados.aulas_adultos ||
          dados.aulas_bebes ||
          dados.aulas_gravidas ||
          dados.aulas_idoso ||
          dados.hidro_terapia ||
          dados.hidrobike ||
          dados.hidroginastica ||
          dados.escola_natacao ||
          dados.hidropower
        ) {
          if (
            categoriasTurma.find((element) => element.id == categoriaUtilizador)
          ) {
            setAulasPiscina(true);
          } else {
            setAulasPiscina(false);
          }
        }

        if (dados.nado_livre) {
          if (
            categoriasNadoLivre.find(
              (element) => element.id == categoriaUtilizador
            )
          ) {
            setNadoLivrePiscina(true);
          } else {
            setNadoLivrePiscina(false);
          }
        }
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  function getDatasLivresNadoLivre(idPiscina) {
    getDatasBanhosLivres({
      id_piscina: idPiscina,
      id_cliente: funcionarioSelecionadoId,
    }).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        let arrayFinal = [];
        dados.forEach((element) => {
          arrayFinal.push(new Date(element.year, element.month, element.day));
        });
        setBookedDays(arrayFinal);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  function getVerificaPackUtilizador(idPiscina) {
    let filho =
      funcionarioSelecionadoId == idUtilizadorEscolhido
        ? 0
        : idUtilizadorEscolhido;
    getPackUtilizador({
      id_piscina: idPiscina,
      id_cliente: funcionarioSelecionadoId,
    }).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setVerificaPackUtilizador(dados);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }
  function cancelar2() {
    handleClosePopupCancelar2();
    setEdicao2(!edicao2);

    //Colocar os dados originais
    setNomeFilho(dadosOriginais2.nome);
    setMoradaFilho(dadosOriginais2.morada);
    setNifFilho(dadosOriginais2.nif);
    setGeneroFilho(dadosOriginais2.genero);
    setCodigoPostalFilho(dadosOriginais2.codigo_postal);
    setNascimentoFilho(dadosOriginais2.data_nascimento_final);

    //Limpar erros e mensagens de erro
    setErroNomeFilho(false);
    setErroNifFilho(false);
    setErroMoradaFilho(false);
    setErroCodigoPostalFilho(false);
    setErroNascimentoFilho(false);
    setMensagemErroCodigoPostalFilho("");
    setMensagemErroNascimentoFilho("");
    setMensagemErroNifFilho("");
  }
  function cancelar() {
    handleClosePopupCancelar();
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeCliente(dadosOriginais.nome);
    setEmailCliente(dadosOriginais.email);
    setTelefoneCliente(dadosOriginais.contacto);
    setNifCliente(dadosOriginais.nif);
    setGenero(dadosOriginais.genero);
    setMoradaCliente(dadosOriginais.morada);
    setCodigoPostalCliente(dadosOriginais.codigo_postal);
    if (dadosOriginais.foto) {
      setFotoCliente(dadosOriginais.foto);
    } else {
      setFotoCliente([]);
    }
    setNascimentoCliente(dadosOriginais.data_nascimento_final);
    setStatusCliente(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeCliente(false);
    setErroEmailCliente(false);
    setErroTelefoneCliente(false);
    seterroGenero(false);
    setErroNifCliente(false);
    setErroMoradaCliente(false);
    setErroCodigoPostalCliente(false);
    setErroNascimentoCliente(false);
    setMensagemErroCodigoPostalCliente("");
    setMensagemErroEmailCliente("");
    setMensagemErroNascimentoCliente("");
    setMensagemErroNifCliente("");
  }
  function LimparVariaveis() {
    setEditarCliente(false);
    // Colocar os dados originais
    setNomeFilho("");
    setNifFilho("");
    setCodigoPostalFilho("");
    setNascimentoFilho("");
    setGeneroFilho("");
    setMoradaFilho("");
    setNomeCliente("");
    setEmailCliente("");
    setTelefoneCliente("");
    setNifCliente("");
    setGenero("");
    setMoradaCliente("");
    setCodigoPostalCliente("");
    setFotoCliente("");
    setNascimentoCliente("");
    setStatusCliente("");
    setFilhos([]);
    // Limpar erros e mensagens de erro
    setErroNomeCliente("");
    setErroEmailCliente("");
    setErroTelefone("");
    seterroGenero("");
    setErroNifCliente("");
    setErroMoradaCliente("");
    setErroCodigoPostalCliente("");
    setErroNascimentoCliente("");
    setMensagemErroCodigoPostalCliente("");
    setMensagemErroEmailCliente("");
    setMensagemErroNascimentoCliente("");
    setMensagemErroNifCliente("");
    setMarcacoes("");
    setTurmas("");
    setatividades([]);
    setPacks("");

    goBackPagarAulas();
    goBack();
    setMarcar(false);
    setPagamentosCliente(false);
    SetAtividadesCliente(false);
    SetPacksCliente(false);
    handleClickCloseContaFamiliar();
    setFuncionarioSelecionadoId(null);
    setidFilho(null);
    setSelecionado(null);
    setEditarCliente(false);
    handleClickCloseEditarFilho();

    setActiveStep(0);
    setCartaoCredito(false);
    setMultibanco(false);
    setMbway(false);
    setBalcao(false);
    setDesconto(0);
    setDescricaoDesconto("");
    setIdDesconto(0);
    setAulas(false);
    setIdUtilizadorEscolhido("");
    setTipoEscolhido(false);
    setAulaEscolhido(false);
    setSelectedDay("");
    setAnual(false);
    setMensal(false);
    setTenhoPack(false);
    setEdicao(true);
    setEdicao2(true);
  }
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    setIdPiscina(idPiscina);
    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  function criarCliente() {
    setFreeze(true);

    var datan = format(new Date(dataNascimento), "yyyy-MM-dd", {
      timeZone: "Europe/Lisbon",
    });
    postCliente({
      id: localStorage.getItem("idPiscina"),
      nome: nomeCliente,
      contacto: telefoneCliente,
      email: emailCliente,
      data_nascimento: datan,
      genero: genero,
      cargo: "",
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          await enviarEmailPostCliente({
            id: res.data.id,
          }).then((res) => {
            if (res.success) {
              toast.success(res.data.msg);
              handleCloseCriarCliente();
              setFreeze(false);
              setTimeout(() => {
                window.location.reload();
              }, 550);
            } else {
              toast.error(res.error);
              setFreeze(false);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }
  function eliminarFilho() {
    setFreeze(true);
    deleteFilho({
      id: idFilho,
      idPiscina: localStorage.getItem("idPiscina"),
      idUtilizador: funcionarioSelecionadoId,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("filhoEliminadaSucesso"));
          handleClosePopupEliminar2();
          LimparVariaveis();
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function editarFilho() {
    setFreeze(true);
    putFilho({
      id: idFilho,
      nome: nomeFilho,
      nif: nifFilho,
      morada: moradaFilho,
      codigo_postal: codigoPostalFilho,
      genero: generoFilho,
      data_nascimento: new Date(NascimentoFilho).toISOString().split("T")[0],
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao2(!edicao2);

          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function criarFilho() {
    setFreeze(true);
    postFilho({
      id: funcionarioSelecionadoId,
      idPiscina: localStorage.getItem("idPiscina"),
      nome: nomeFilho,
      morada: moradaFilho,
      genero: generoFilho,
      codigo_postal: codigoPostalFilho,
      data_nascimento: new Date(NascimentoFilho).toISOString().split("T")[0],
      nif: nifCliente,
    })
      .then((res) => {
        if (res.success) {
          toast.success(res.data.msg);
          setFreeze(false);
          // navigate("/familya", {
          //   state: {
          //     idPiscina: idPiscina,
          //     idPai: idPai,
          //   },
          // });
          handleClickCloseCriarFilho();
          LimparVariaveis();
        } else {
          toast.error(res.error);
          setFreeze(false);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }
  function validacao2() {
    if (
      nomeFilho &&
      moradaFilho &&
      nifCliente &&
      codigoPostalFilho &&
      NascimentoFilho &&
      generoFilho
    ) {
      let confirmarDadosPreenchidos = true;
      if (nifFilho.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifFilho(true);
        setMensagemErroNifFilho(t("validacaoNif"));
      }
      if (codigoPostalFilho.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalFilho(true);
        setMensagemErroCodigoPostalFilho(t("validacaoCodigoPostal"));
      }
      // Validação do nif
      if (nifFilho.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifCliente(true);
        setMensagemErroNifFilho(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalFilho < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalFilho(true);
        setMensagemErroCodigoPostalFilho(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      if (!NascimentoFilho) {
        setNascimentoFilho("");
        setErroNascimentoFilho(true);
      }
      let dataNascimentoDate = new Date(NascimentoFilho);
      let hoje = new Date();
      let idadeMinima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos > idadeMinima ||
        (diffAnos === idadeMinima && diffMeses > 0) ||
        (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
      ) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoFilho(true);
        setMensagemErroNascimentoFilho(t("menor16"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        if (!edicao2) {
          editarFilho();
        } else {
          criarFilho();
        }
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeFilho) {
        setErroNomeFilho(true);
      }

      if (!moradaFilho) {
        setErroMoradaFilho(true);
      }

      //Validação do nif
      if (!nifFilho) {
        setErroNifFilho(true);
      } else {
        if (nifCliente.length < 9) {
          setErroNifFilho(true);
          setMensagemErroNifFilho(t("validacaoNif"));
        }
      }

      if (!codigoPostalFilho) {
        setErroCodigoPostalFilho(true);
      } else {
        if (codigoPostalFilho.includes("_")) {
          setErroCodigoPostalFilho(true);
          setMensagemErroCodigoPostalFilho(t("validacaoCodigoPostal"));
        }
      }
    }
  }

  function validacao1() {
    if (nomeCliente && emailCliente && telefoneCliente && genero) {
      let confirmarDadosPreenchidos = true;
      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailCliente
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailCliente(true);
        setMensagemErroEmailCliente(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneCliente)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneCliente(true);
        setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
      }
      if (emailCliente) {
        verificarEmail({ email: emailCliente }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroEmailCliente(true);
              setMensagemErroEmailCliente(res.data.error);
            }
          }
        });
      }

      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
      } else {
        let dataNascimentoDate = new Date(dataNascimento);
        let hoje = new Date();
        let idadeMinima = 16;

        let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
        let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
        let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

        if (
          diffAnos <= idadeMinima ||
          (diffAnos === idadeMinima && diffMeses > 0) ||
          (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
        ) {
          confirmarDadosPreenchidos = false;
          setErroDataNascimento(true);
          setMensagemErroDataNascimento(t("idademin"));
        }
      }
      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        criarCliente();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeCliente) {
        setErroNomeCliente(true);
      }
      //Validação do email

      if (!emailCliente) {
        setErroEmailCliente(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailCliente
          );

        if (!validacaoEmail || emailCliente != "") {
          setErroEmailCliente(true);
          setMensagemErroEmailCliente(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailCliente }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                setErroEmailCliente(true);
                setMensagemErroEmailCliente(res.data.error);
              }
            }
          });
        }
      }
      //Validação data nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("validacaoData"));
      } else {
        let dataNascimentoDate = new Date(dataNascimento);
        let hoje = new Date();
        let idadeMinima = 16;

        let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
        let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
        let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

        if (
          diffAnos <= idadeMinima ||
          (diffAnos === idadeMinima && diffMeses > 0) ||
          (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
        ) {
          setErroDataNascimento(true);
          setMensagemErroDataNascimento(t("idademin"));
        }
      }

      //Validação do telefone
      if (!telefoneCliente) {
        setErroTelefoneCliente(true);
      } else {
        if (!isValidPhoneNumber(telefoneCliente)) {
          setErroTelefoneCliente(true);
          setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
        }
      }
    }
  }
  async function anularInscricao() {
    setFreeze(true);

    await anularInscricaoCliente({
      id_utilizador: funcionarioSelecionadoId,
      id_piscina: localStorage.getItem("idPiscina"),
    }).then(async (res) => {
      if (res.success) {
        setTimeout(async function () {
          enviarEmailAnularInscreveCliente({
            id: funcionarioSelecionadoId,
            id_piscina: localStorage.getItem("idPiscina"),
          }).then((res2) => {
            if (res2.success) {
              setFreeze(false);
              toast.success(res2.data.msg);
              setStatusCliente(!statusCliente);
              setSelecionado(null);
              setEditarCliente(false);
              setTimeout(() => {
                window.location.reload();
              }, 550);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          });
        }, 1000);
      } else {
        toast.error(res.error);
      }
    });
  }
  function EditarCliente() {
    setFreeze(true);

    var datan = format(new Date(NascimentoCliente), "yyyy-MM-dd", {
      timeZone: "Europe/Lisbon",
    });

    putCliente({
      id: funcionarioSelecionadoId,
      nome: nomeCliente,
      cargo: cargoCliente,
      nif: nifCliente,
      genero: genero,
      email: emailCliente,
      contacto: telefoneCliente,
      morada: moradaCliente,
      codigo_postal: codigoPostalCliente,
      foto: fotoCliente.length == 0 ? null : fotoCliente,
      data_nascimento: datan,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            // navigate("/500");
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      nomeCliente &&
      emailCliente &&
      telefoneCliente &&
      genero &&
      nifCliente &&
      moradaCliente &&
      codigoPostalCliente &&
      NascimentoCliente
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailCliente
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailCliente(true);
        setMensagemErroEmailCliente(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneCliente)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneCliente(true);
        setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
      }
      if (emailCliente) {
        verificarEmail({ email: emailCliente }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != funcionarioSelecionadoId) {
                confirmarDadosPreenchidos = false;
                setErroEmailCliente(true);
                setMensagemErroEmailCliente(res.data.error);
              }
            }
          }
        });
      }

      // Validação do nif
      if (nifCliente.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifCliente(true);
        setMensagemErroNifCliente(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalCliente < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalCliente(true);
        setMensagemErroCodigoPostalCliente(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento

      if (!NascimentoCliente) {
        setDataNascimento("");
        setErroDataNascimento(true);
      } else {
        const dataFormatada = new Date(NascimentoCliente).toLocaleDateString(
          "pt-BR"
        );

        let dataNascimentoDate = new Date(dataFormatada);

        let hoje = new Date();
        let idadeMinima = 16;

        let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
        let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
        let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

        if (
          diffAnos <= idadeMinima ||
          (diffAnos === idadeMinima && diffMeses > 0) ||
          (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
        ) {
          confirmarDadosPreenchidos = false;
          setErroNascimentoCliente(true);
          setMensagemErroNascimentoCliente(t("idademin"));
        }
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        EditarCliente();
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeCliente) {
        setErroNomeCliente(true);
      }
      //Validação do email
      if (!emailCliente) {
        setErroEmailCliente(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailCliente
          );
        if (!validacaoEmail) {
          setErroEmailCliente(true);
          setMensagemErroEmailCliente(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailCliente }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != funcionarioSelecionadoId) {
                  setErroEmailCliente(true);
                  setMensagemErroEmailCliente(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneCliente) {
        setErroTelefoneCliente(true);
      } else {
        if (!isValidPhoneNumber(telefoneCliente)) {
          setErroTelefoneCliente(true);
          setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
        }
      }
      // Validação do cargo
      if (!cargoCliente) {
        setErroCargoCliente(true);
      }
      // Validação do nif
      if (!nifCliente) {
        setErroNifCliente(true);
      } else {
        if (nifCliente.length < 9) {
          setErroNifCliente(true);
          setMensagemErroNifCliente(t("validacaoNif"));
        }
      }
      //Validação da morada
      if (!moradaCliente) {
        setErroMoradaCliente(true);
      }
      //Validação do código postal
      if (!codigoPostalCliente) {
        setErroCodigoPostalCliente(true);
      } else {
        if (codigoPostalCliente < 7) {
          setErroCodigoPostalCliente(true);
          setMensagemErroCodigoPostalCliente(t("validacaoCodigoPostal"));
        }
      }
      if (!NascimentoCliente) {
        setDataNascimento("");
        setErroDataNascimento(true);
      } else {
        const dataFormatada = new Date(NascimentoCliente).toLocaleDateString(
          "pt-BR"
        );

        let dataNascimentoDate = new Date(dataFormatada);

        let hoje = new Date();
        let idadeMinima = 16;

        let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
        let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
        let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

        if (
          diffAnos <= idadeMinima ||
          (diffAnos === idadeMinima && diffMeses > 0) ||
          (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
        ) {
          confirmarDadosPreenchidos = false;
          setErroNascimentoCliente(true);
          setMensagemErroNascimentoCliente(t("idademin"));
        }
      }
    }
  }
  function suspenderCliente() {
    if (motivoSuspensao) {
      setSuspender(false);
      setFreeze(true);
      putStatusCliente({
        id: funcionarioSelecionadoId,
        obs: motivoSuspensao,
      })
        .then((res) => {
          if (res.success) {
            setFreeze(false);
            toast.success(res.data.msg);
            setMotivoSuspensao("");
            setSelecionado(null);
            setEditarCliente(false);
            setMotivo(motivoSuspensao);
            setStatusCliente(!statusCliente);
            setTimeout(() => {
              window.location.reload();
            }, 550);
          } else {
            if (res.status == 500) {
              navigate("/500");
              setFreeze(false);
            }
            setFreeze(false);
            toast.error(res.error);
            setMotivoSuspensao("");
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setErroMotivoSuspensao(true);
    }
  }

  function enviarEmail() {
    recuperaPass({
      email: emailCliente,
    }).then((res) => {
      if (res.success) {
        toast.success(t("reporPswCliente"));
        //navigate("/superadmins");
        handleClosePopupReporPassword();
      } else {
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }
  const handleNext = async (modalidade) => {
    if (activeStep == 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      getModalidadespiscinas(idEscolhida);
      getDatasLivresNadoLivre(idEscolhida);
    } else if (activeStep == 1) {
      //Saber qual a categoria do utilizador
      let categoriaUtilizador;
      let utilizadorEscolhido = filho
        ? users.find(
            (element) =>
              element.filho == 1 && element.id == idUtilizadorEscolhido
          )
        : users.find((element) => element.id == idUtilizadorEscolhido);
      let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

      let result = await getCategoriaUtilizador({
        id_filho: id_filho,
        id_cliente: funcionarioSelecionadoId,
      });
      if (result.success) {
        categoriaUtilizador = result.data.data;

        // Get Turmas
        await getTurmas({
          id_cliente: funcionarioSelecionadoId,
          id_filho: id_filho,
          id_piscina: idEscolhida,
          categoria: categoriaUtilizador,
          modalidade: modalidadeEscolhida,
        }).then((res) => {
       
          // Adiciona a propriedade 'id' a cada elemento no array
          const turmasComId = res.data.data.map((turma, index) => ({
            ...turma,
            id: index,
          }));

          setTurmas(turmasComId);
        });
//aqui
        await getListaPacks({
          id_piscina: idEscolhida,
          id_cliente: funcionarioSelecionadoId,
        }).then((res) => {
          if (res.success) {
            let utilizadorEscolhido = filho
            ? users.find(
                (element) =>
                  element.filho == 1 && element.id == funcionarioSelecionadoId
              )
            : users.find(
                (element) =>
                  element.filho == 0 && element.id == funcionarioSelecionadoId
              );

          let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

          if (id_filho == 0) {
            const pacotesFiltrados = res.data.data.filter((pack) => {
              pack.num_tickets > pack.num_tickets_utilizados &&
                pack.id_filho === 0 &&
                id_filho === 0;
            });

            setListaPacks(pacotesFiltrados);
          } else {
            const pacotesFiltrados = res.data.data.filter((pack) => {
              pack.num_tickets > pack.num_tickets_utilizados &&
                pack.id_filho !== 0 &&
                id_filho !== 0;
            });

            setListaPacks(pacotesFiltrados);
          }

          // setListaPacks(res.data.data);
        
          }
        });
      }

      setActiveStep(2);
    } else if (activeStep == 2) {
    }
  };
  const handleNext2PagarAulas = async () => {
    setActiveStepPagarAulas(
      (prevActiveStepPagarAulas) => prevActiveStepPagarAulas + 2
    );
  };
  const handleNextPagarAulas = async () => {
    if (activeStepPagarAulas == 0) {
      setActiveStepPagarAulas(
        (prevActiveStepPagarAulas) => prevActiveStepPagarAulas + 1
      );
    } else if (activeStepPagarAulas == 1) {
      //Aulas
      if (aulas == true) {
        getInfoCompra2({
          id_cliente: funcionarioSelecionadoId,
          id_turma: turmaEscolhida,
          id_piscina: idEscolhida,
          id_filho: filho ? idUtilizadorEscolhido : 0,
          id_preco: anual ? 1 : mensal ? 2 : 0,
        }).then((res) => {
          if (res.success) {
            const linha = res.data.data;
            setCompraInscricao(linha.inscricao);
            setCompraPreco(anual ? linha.precoAnual : linha.precoMensal);
            setCompraTotal(linha.total);
            setDesconto(linha.desconto);
            setIdDesconto(linha.id_desconto);
            setDescricaoDesconto(linha.descricao);
            setValorDesconto(linha.valorDesconto.replace(".", ","));
          }
        });
      } else {
        //Packs
        getInfoCompra({
          id_cliente: funcionarioSelecionadoId,
          id_pack: packEscolhido,
          id_piscina: idEscolhida,
          id_filho: filho ? idUtilizadorEscolhido : 0,
        }).then((res) => {
          if (res.success) {
            const linha = res.data.data;
            setCompraInscricao(linha.inscricao);
            setCompraPreco(linha.pack);
            setCompraTotal(linha.total);
            setDesconto(linha.desconto);
            setIdDesconto(linha.id_desconto);
            setDescricaoDesconto(linha.descricao);
            setValorDesconto(linha.valorDesconto.replace(".", ","));
          }
        });
      }

      setActiveStepPagarAulas(
        (prevActiveStepPagarAulas) => prevActiveStepPagarAulas + 1
      );
    } else if (activeStepPagarAulas == 2) {
    } else if (activeStepPagarAulas == 3) {
    } else if (activeStepPagarAulas == 4) {
    } else if (activeStepPagarAulas == 5) {
    } else if (activeStepPagarAulas == 6) {
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackPagarAulas = () => {
    setActiveStepPagarAulas(
      (prevActiveStepPagarAulas) => prevActiveStepPagarAulas - 1
    );
  };
  function goBack() {
    if (activeStep == 0) {
    }
    if (activeStep == 1) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      setAulas(false);
      setIdUtilizadorEscolhido("");
      setTipoEscolhido(false);
      setAulaEscolhido(false);
      setSelectedDay("");
      handleBack();
    }
    if (activeStep == 2) {
      setCartaoCredito(false);
      setMultibanco(false);
      setAnual(false);
      setMensal(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      handleBack();
    }

    if (activeStep == 3) {
      setAnual(false);
      setMensal(false);
      setAulas(false);
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      setTipoEscolhido(false);
      setAulaEscolhido(false);

      setTenhoPack(false);
      handleBack();
    }
  }

  function goBackPagarAulas() {
    if (activeStepPagarAulas == 0) {
    }
    if (activeStepPagarAulas == 1) {
      setAnual(false);
      setPackEscolhido("");
      setPackUtilizadorEscolhido("");

      handleBackPagarAulas();
    }
    if (activeStepPagarAulas == 2) {
      if (tenhopack == true) {
        setActiveStepPagarAulas(
          (prevActiveStepPagarAulas) => prevActiveStepPagarAulas - 2
        );
        setTenhoPack(false);
      } else {
        setCartaoCredito(false);
        setMultibanco(false);
        setMbway(false);
        setTenhoPack(false);
        setBalcao(false);
        setDesconto(0);
        setDescricaoDesconto("");
        setIdDesconto(0);
        setCompraTotal(0);
        setCompraPreco(0);
        handleBackPagarAulas();
      }
    }

    if (activeStepPagarAulas == 3) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setTenhoPack(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);

      handleBackPagarAulas();
    }
    if (activeStepPagarAulas == 4) {
    }
    if (activeStepPagarAulas == 5) {
    }
  }
  useEffect(() => {
    if (activeStep == 3) {
      const timeout = setTimeout(() => {
        setActiveStep(0);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [activeStep]);

  const steps = [
    {
      description: (
        <div id="1" style={{ height: "100%" }}>
          <div className="divTabelaMarcar2">
            <h3 className="titleMarcar">{t("escolherutilizador")}</h3>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                width: "90%",
                justifyContent: users.length < 4 ? "center" : "flex-start",
                marginBottom: 30,
              }}
            >
              {users.map((element) => (
                <>
                  {idUtilizadorEscolhido != element.id &&
                  idUtilizadorEscolhido != "" ? (
                    <Card
                      key={element.id}
                      onClick={() => {
                        setIdUtilizadorEscolhido(element.id);

                        if (element.filho === 1) {
                          setFilho(true);
                        } else {
                          setFilho(false);
                        }
                      }}
                      className="cardUserNotSelected"
                      sx={{
                        flexShrink: 0,
                        marginRight: 5,
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <CardContent
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            marginLeft: 10,
                            marginTop: 10,
                          }}
                        >
                          <Avatar
                            src={element.img}
                            sx={{
                              width: 30,
                              height: 30,
                              border: "1px solid white",
                            }}
                          />
                          <div
                            style={{ flexDirection: "column", marginLeft: 20 }}
                          >
                            <Typography
                              component="div"
                              color="#171E24"
                              style={{ opacity: "40%", fontSize: "14px" }}
                            >
                               <a
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "10ch",
                                      display: "inline-block",
                                    }}
                                  >
                              {element.nome}
                              </a>
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="#171E24CC"
                              style={{ opacity: "40%", fontSize: "12px" }}
                              component="div"
                            >
                              {element.nif}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card
                      key={element.id}
                      onClick={() => {
                        setIdUtilizadorEscolhido(element.id);

                        if (element.filho === 1) {
                          setFilho(true);
                        } else {
                          setFilho(false);
                        }
                      }}
                      className="cardUser"
                      sx={{ flexShrink: 0, marginRight: 5 }}
                    >
                      <CardContent
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            marginLeft: 10,
                            marginTop: 10,
                          }}
                        >
                          <Avatar
                            src={element.img}
                            sx={{
                              width: 35,
                              height: 35,
                              border: "1px solid white",
                            }}
                          />
                          <div
                            style={{ flexDirection: "column", marginLeft: 20 }}
                          >
                            <Typography component="div" color="#0A56FA">
                            <a
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "10ch",
                                      display: "inline-block",
                                    }}
                                  >
                              {element.nome}
                              </a>
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="#1e2327"
                              component="div"
                            >
                              {element.nif}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </>
              ))}
            </div>
            <h3 className="titleMarcar" style={{ marginTop: 10 }}>
              {t("escolhertipoaula")}
            </h3>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                justifyContent: "center",
              }}
            >
              {aulas === false && tipoEscolhido === true ? (
                <Button
                  className="cardAulasNotSelected"
                  style={{
                    width: "230px",
                    height: "60px",
                    marginRight: 35,
                    marginLeft: "auto",
                    marginTop: "auto",
                    marginBottom: "auto",
                    transition: "all 0.5s ease-in-out",
                  }}
                  onClick={() => {
                    setAulas(true);
                    setTipoEscolhido(true);
                    setAulaEscolhido(false);
                  }}
                >
                  <span
                    style={{
                      width: 19,
                      height: 19,
                      marginRight: 20,
                      backgroundImage: `url(${graduationIconBlack})`,
                    }}
                  />
                  {t("aulas")}
                </Button>
              ) : (
                <Button
                  className="cardAulas"
                  style={{
                    width: "250px",
                    height: "80px",
                    marginRight: 35,
                    marginLeft: "auto",
                    marginTop: "auto",
                    marginBottom: "auto",
                    transition: "all 0.5s ease-in-out",
                  }}
                  onClick={() => {
                    setAulas(true);
                    setTipoEscolhido(true);
                    setAulaEscolhido(false);
                  }}
                >
                  <span
                    style={{
                      width: 24,
                      height: 19,
                      marginRight: 20,
                      backgroundImage: `url(${graduationIcon})`,
                    }}
                  />
                  {t("aulas")}
                </Button>
              )}

              {aulaEscolhido === false && tipoEscolhido === true ? (
                <Button
                  className="cardAulasNotSelected2"
                  style={{
                    width: "230px",
                    height: "60px",
                    marginRight: "auto",
                    marginLeft: 10,
                    marginTop: "auto",
                    marginBottom: "auto",
                    transition: "all 0.5s ease-in-out",
                  }}
                  onClick={() => {
                    setTipoEscolhido(true);
                    setAulaEscolhido(true);
                    setAulas(false);
                  }}
                >
                  <span
                    style={{
                      width: 19,
                      height: 19,
                      marginRight: 20,
                      backgroundImage: `url(${swimIconBlack})`,
                    }}
                  />
                  {t("banhosLivres")}
                </Button>
              ) : (
                <Button
                  className="cardBanhos"
                  style={{
                    width: "250px",
                    height: "80px",
                    marginRight: "auto",
                    marginLeft: 18,
                    marginTop: "auto",
                    marginBottom: "auto",
                    transition: "all 0.5s ease-in-out",
                  }}
                  onClick={() => {
                    setTipoEscolhido(true);
                    setAulaEscolhido(true);
                    setAulas(false);
                  }}
                >
                  <span
                    style={{
                      width: 22,
                      height: 21,
                      marginRight: 20,
                      backgroundImage: `url(${swimIcon})`,
                    }}
                  />
                  {t("banhosLivres")}
                </Button>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="2">
          {aulas === true ? (
            <div
              className="divTabelaMarcar2"
              style={{ height: "100% !important" }}
            >
              <h3 className="titleMarcar" style={{ marginTop: "10%" }}>
                {t("escolhermodalidade")}
              </h3>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  paddingTop: 5,

                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {modalidadesAulas.map((element) => (
                  <Card
                    onClick={() => {
                      setModalidadeEscolhida(element.id);
                      handleNext(element.id);
                    }}
                    className="btnsMarcar"
                    sx={{
                      margin: "3px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1,
                        marginTop: 2,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography component="div" color="#0A56FA">
                          {element.nome}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
                {modalidadesAulas.length == 0 && (
                  <Button
                    className="cardDashboardMarcar4"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.inativo,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "50%",
                    }}
                    disabled
                  >
                    <div style={{ display: "block" }}>
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faFaceFrown}
                      />
                    </div>
                    <span
                      style={{
                        marginTop: "8px",
                        fontSize: isMobile ? "10px" : "14px",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("indisponibilidadeMarcacoes")}
                    </span>
                  </Button>
                )}
              </Grid>
            </div>
          ) : (
            <div className="divTabelaMarcar">
              {!isMobile ? (
                <div
                  className="divTabelaMarcar2"
                  style={{ height: "40% !important" }}
                >
                  <h3 className="titleMarcar">{t("escolherespaco")}</h3>

                  <Stack direction={"row"} spacing={30}>
                    <DayPicker
                      className="DayPicker"
                      mode="single"
                      required
                      selected={selectedDay}
                      // onSelect={setSelectedDay}
                      onDayClick={handleDayClick}
                      styles={{
                        container: {
                          maxWidth: "400px",
                          margin: "0 auto",
                          fontFamily: "Arial, sans-serif",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                        },
                        caption: {
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          color: "#1976d2",
                        },
                        day: {
                          fontSize: "18px",
                          color: "#444444",
                        },
                        ".DayPicker-Weekday": {
                          fontSize: "14px",
                          color: "#666666",
                          textTransform: "uppercase",
                        },
                        ".DayPicker-Day--booked": {
                          backgroundColor: "#eeeeee",
                        },
                        ".DayPicker-Day--selected:not(.DayPicker-Day--booked)":
                          {
                            backgroundColor: "#1976d2",
                            color: "#ffffff",
                            fontWeight: "bold",
                          },
                        ".DayPicker-Day--booked:not(.DayPicker-Day--selected)":
                          {
                            color: "#999999",
                          },
                        ".DayPicker-Footer": {
                          fontSize: "14px",
                          marginTop: "10px",
                        },
                        ".DayPicker-NavButton--prev, .DayPicker-NavButton--next":
                          {
                            fontSize: "20px",
                            color: "#1976d2",
                            outline: "none",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "calc(50% - 10px)",
                            zIndex: "2",
                          },
                        ".DayPicker-NavButton--prev": {
                          left: "10px",
                        },
                        ".DayPicker-NavButton--next": {
                          right: "10px",
                        },
                      }}
                      modifiers={{
                        booked: bookedDays,
                      }}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: "#1976d2",
                          color: "white",
                        },
                        booked: { border: "2px solid #1976d2" },
                      }}
                      locale={
                        i18n.language == "pt"
                          ? pt2
                          : i18n.language == "es"
                          ? es2
                          : en2
                      }
                      footer={footer}
                    />
                    <div className="gridHoras">
                      <h2 className="dashboardTitle2">
                        {t("horasDisponiveis")}
                      </h2>

                      <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ marginTop: "30px !important" }}
                      >
                        {horasDisponiveis.map((element, index) => (
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            key={index}
                            style={{ marginLeft: 5 }}
                          >
                            <Button
                              className="btnHoras"
                              onClick={async () => {
                                if (horaEscolhida == index && selectedDay) {
                                  if (!aulas) {
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    const data = new Date(selectedDay);
                                    const dia = data
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const mes = (data.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const ano = data.getFullYear();
                                    const dataFormatada = `${dia}/${mes}/${ano}`;

                                    let verificacaoSobreposicao;
                                    try {
                                      const res =
                                        await getVerificacaoSobreposicao({
                                          id_filho: id_filho,
                                          id_piscina: idEscolhida,
                                          data: dataFormatada,
                                          horaInicio:
                                            horasDisponiveis[horaEscolhida]
                                              .horaInicio,
                                          horaFim:
                                            horasDisponiveis[horaEscolhida]
                                              .horaFim,
                                          id_cliente: funcionarioSelecionadoId,
                                        });

                                      if (res.success) {
                                        verificacaoSobreposicao =
                                          res.data.verificacao;
                                      }
                                    } catch (error) {
                                      verificacaoSobreposicao = true;
                                    }

                                    if (!verificacaoSobreposicao) {
                                      toast.error(t("sobreposicaoMarcacoes"));
                                    } else {
                                      getVerificaPackUtilizador(idEscolhida);
                                      getPacks(idEscolhida);
                                      handleClickOpenPagamentoAulas();
                                    }
                                  } else {
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );

                                    verificarInscricaoPagamentoPendente({
                                      id_turma: turmaEscolhida,
                                      id_filho: utilizadorEscolhido.filho
                                        ? utilizadorEscolhido.id
                                        : 0,
                                    }).then((res) => {
                                      if (res.status == 500) {
                                        toast.error(t("inscricaoAtiva"));
                                      } else {
                                        setActiveStep(
                                          (prevActiveStep) => prevActiveStep + 1
                                        );
                                      }
                                    });

                                    //Saber qual a categoria do utilizador
                                    let categoriaUtilizador;
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    let result = await getCategoriaUtilizador({
                                      id_filho: id_filho,
                                      id_cliente: funcionarioSelecionadoId,
                                    });
                                    if (result.success) {
                                      categoriaUtilizador = result.data.data;
                                    }
                                    // Get Turmas
                                    getTurmas({
                                      id_filho: id_filho,
                                      id_piscina: idEscolhida,
                                      categoria: categoriaUtilizador,
                                      modalidade: modalidadeEscolhida,
                                    }).then((res) => {
                                      const turmasComId = res.data.data.map(
                                        (turma, index) => ({
                                          ...turma,
                                          id: index,
                                        })
                                      );
                                      setTurmas(turmasComId);
                                    });
                                  }
                                  await getListaPacks({
                                    id_piscina: idEscolhida,
                                    id_cliente: funcionarioSelecionadoId,
                                  }).then((res) => {
                                    if (res.success) {
                                      let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 && element.id == funcionarioSelecionadoId
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 && element.id == funcionarioSelecionadoId
                                        );
                          
                                    let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;
                          
                                    if (id_filho == 0) {
                                      const pacotesFiltrados = res.data.data.filter((pack) => {
                                        pack.num_tickets > pack.num_tickets_utilizados &&
                                          pack.id_filho === 0 &&
                                          id_filho === 0;
                                      });
                          
                                      setListaPacks(pacotesFiltrados);
                                    } else {
                                      const pacotesFiltrados = res.data.data.filter((pack) => {
                                        pack.num_tickets > pack.num_tickets_utilizados &&
                                          pack.id_filho !== 0 &&
                                          id_filho !== 0;
                                      });
                          
                                      setListaPacks(pacotesFiltrados);
                                    }
                          
                                    // setListaPacks(res.data.data);
                                  
                                    }
                                  });
                                } else {
                                  setHoraEscolhida(index);
                                  handleClickOpenPagamentoAulas();
                                }
                              }}
                            >
                              {element.horaInicio}
                              <br /> {element.horaFim}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Stack>
                </div>
              ) : (
                <div className="divTabelaMarcar2">
                  <h3 className="titleMarcar">{t("escolherespaco")}</h3>

                  <Stack
                    direction={"column"}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <DayPicker
                      className="DayPicker2"
                      mode="single"
                      required
                      selected={selectedDay}
                      // onSelect={setSelectedDay}
                      onDayClick={handleDayClick}
                      styles={{
                        container: {
                          fontFamily: "Arial, sans-serif",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                        },
                        caption: {
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          color: "#1976d2",
                        },
                        day: {
                          fontSize: "12px",
                          color: "#444444",
                          width: window.innerWidth / 13,
                        },
                        month: {
                          flex: "1 0 30%",
                          padding: "0 10px",
                        },
                        months: {
                          flexWrap: "wrap",
                          margin: "0 5px",
                        },
                        booked: {
                          backgroundColor: "#eeeeee",
                        },
                        ".DayPicker-Weekday": {
                          fontSize: "10px !important",
                          color: "#666666",
                          textTransform: "uppercase",
                        },

                        ".DayPicker-Day--selected:not(.DayPicker-Day--booked)":
                          {
                            backgroundColor: "#1976d2",
                            color: "#ffffff",
                            fontWeight: "bold",
                          },
                        ".DayPicker-Day--booked:not(.DayPicker-Day--selected)":
                          {
                            color: "#999999",
                          },
                        ".DayPicker-Footer": {
                          fontSize: "12px",
                          marginTop: "10px",
                        },
                        ".DayPicker-NavButton--prev, .DayPicker-NavButton--next":
                          {
                            fontSize: "16px",
                            color: "#1976d2",
                            outline: "none",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "calc(50% - 10px)",
                            zIndex: "2",
                          },
                        ".DayPicker-NavButton--prev": {
                          left: "5px !important",
                        },
                        ".DayPicker-NavButton--next": {
                          right: "5px",
                        },

                        ".DayPicker-WeekdaysRow": {
                          display: "flex",
                          marginTop: "10px",
                          marginBottom: "10px",
                        },
                        ".DayPicker-Weekday": {
                          width: "calc(100% / 7)",
                          padding: "0",
                        },
                        ".DayPicker-Weekday abbr[title]": {
                          border: "none",
                          fontSize: "12px",
                          color: "#9e9e9e",
                        },
                      }}
                      modifiers={{
                        booked: bookedDays,
                      }}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: "#1976d2",
                          color: "white",
                        },
                        booked: { border: "2px solid #1976d2" },
                      }}
                      locale={
                        i18n.language == "pt"
                          ? pt2
                          : i18n.language == "es"
                          ? es2
                          : en2
                      }
                      footer={footer}
                    />
                    <div className="gridHoras2">
                      <h2 className="dashboardTitle2">
                        {t("horasDisponiveis")}
                      </h2>
                      <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 3, sm: 6, md: 9 }}
                        sx={{ marginTop: 10 }}
                      >
                        {horasDisponiveis.map((element, index) => (
                          <Grid
                            item
                            xs={1}
                            sm={3}
                            md={6}
                            key={index}
                            style={{ marginLeft: 5 }}
                          >
                            <Button
                              className="btnHoras"
                              sx={{
                                margin: "3px",
                              }}
                              onClick={() => {
                                if (horaEscolhida == index && selectedDay) {
                                  handleClickOpenPagamentoAulas();
                                } else {
                                  setHoraEscolhida(index);
                                  handleClickOpenPagamentoAulas();
                                }
                              }}
                            >
                              {element.horaInicio}
                              <br /> {element.horaFim}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Stack>
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      description: (
        <div id="3">
          <div className="divTabelaMarcar">
            {aulas === true ? (
              <div className="divTabelaMarcar2">
                <h3 className="titleMarcar">{t("escolherturma")}</h3>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    paddingTop: 5,

                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {turmas &&
                    turmas.map &&
                    turmas.map((element) => (
                      <Card
                        key={element.id_turma}
                        onClick={() => {
                          if (
                            element.num_vagas - element.num_vagas_ocupadas !=
                            0
                          ) {
                            setTurmaEscolhida(element.id_turma);
                            handleClickOpenPagamentoAulas();
                          }
                        }}
                        className="btnsMarcar"
                        sx={{
                          margin: "3px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            <Typography
                              component="div"
                              sx={{ color: "#0A56FA" }}
                            >
                              {element.nome}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{ fontWeight: 100 }}
                            >
                              {element.obs}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("nVagasDisponiveis")}:{" "}
                              {element.num_vagas - element.num_vagas_ocupadas}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    ))}
                </Grid>
                {turmas.length == 0 && (
                  <Button
                    className="cardDashboardMarcar4"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.ativo,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "50%",
                    }}
                    disabled
                  >
                    <div style={{ display: "block" }}>
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faFaceSmile}
                      />
                    </div>
                    <span
                      style={{
                        marginTop: "8px",
                        fontSize: isMobile ? "10px" : "14px",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("mensagemTurmas")}
                    </span>
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="4">
          {pagamentoCancelado == true ? (
            <div className="divTabelaMarcar2">
              <div style={{ marginTop: "20px" }}>
                <img src={errorgif} alt="Error" />
              </div>
              <span style={{ paddingTop: "10px" }}>
                {t("pagamentoCanceladoText")}
              </span>
            </div>
          ) : (
            <div className="divTabelaMarcar2">
              <div style={{ marginTop: "20px" }}>
                <img src={successgif} alt="Success" />
              </div>
              <span style={{ paddingTop: "10px" }}>
                {t("pagamentoSucessoText")}
              </span>
            </div>
          )}
        </div>
      ),
    },
  ];
  const stepsPagarAulas = [
    {
      description: (
        <div id="1" style={{ height: "100%" }}>
          {aulas === true && turmaEscolhida ? (
            <div className="cardsDivPagamentoAulas">
              <Button
                className="btnsMarcar"
                variant="contained"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                onClick={() => {
                  setMensal(true);
                  handleNextPagarAulas();
                }}
              >
                <div className="divCardsPrecos">
                  <span
                    style={{
                      width: 21,
                      height: 21,
                      backgroundImage: `url(${calendarIcon})`,
                    }}
                  />

                  <span
                    style={{
                      marginLeft: "0.5rem",
                      textTransform: "capitalize",
                      color: "#0A56FA ",
                    }}
                  >
                    {t("mensal")}
                  </span>
                </div>
                <div style={{ color: "#0A56FA " }}>
                  {turmas
                    .find((element) => element.id_turma == turmaEscolhida)
                    ?.preco_mensal.toLocaleString("pt-PT", {
                      style: "currency",

                      currency: "EUR",
                    })}
                </div>
              </Button>

              <Button
                className="btnsMarcar"
                variant="contained"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setAnual(true);
                  handleNextPagarAulas();
                }}
              >
                <div className="divCardsPrecos">
                  <CalendarDaysIcon
                    style={{ width: "25px", color: "#0A56FA" }}
                  />

                  <span
                    style={{
                      marginLeft: "0.5rem",
                      textTransform: "capitalize",
                      color: "#0A56FA ",
                    }}
                  >
                    {t("anual")}
                  </span>
                </div>

                <div style={{ color: "#0A56FA " }}>
                  {turmas
                    .find((element) => element.id_turma == turmaEscolhida)
                    ?.preco_anual.toLocaleString("pt-PT", {
                      style: "currency",
                      currency: "EUR",
                    })}
                </div>
              </Button>
            </div>
          ) : (
            <div className="divResumo" style={{ flexDirection: "column" }}>
              {tenhopack == true ? (
                <div className="gridContainer">
                  {listaPacks.map((element) => (
                    <Card
                      onClick={() => {
                        if (
                          element.id_pack_cliente == packUtilizadorEscolhido
                        ) {
                          handleNext2PagarAulas();
                        }
                        setPackUtilizadorEscolhido(element.id_pack_cliente);
                        handleNext2PagarAulas();
                      }}
                      className="cardPacks"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography
                            component="div"
                            style={{ fontSize: "14px" }}
                          >
                            {element.nomePack}
                            <br></br> {element.nomePiscina}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{ fontSize: "12px" }}
                          >
                            {element.nome_utilizador} ➔ {t("tickets")}:{" "}
                            {element.num_tickets_utilizados} /{" "}
                            {element.num_tickets}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="gridContainer">
                  {packsCompra.length == 0 ? (
                    <Button
                      className="cardDashboardMarcar4"
                      variant="contained"
                      style={{
                        backgroundColor: Colors.inativo,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "90%",
                      }}
                      disabled
                    >
                      <div style={{ display: "block" }}>
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faFaceFrown}
                        />
                      </div>
                      <span
                        style={{
                          marginTop: "8px",
                          fontSize: isMobile ? "10px" : "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("mensagemPacksVenda")}
                      </span>
                    </Button>
                  ) : (
                    <>
                      {packsCompra.map((element) => (
                        <Card
                          onClick={() => {
                            if (packEscolhido == element.id_pack) {
                              handleNextPagarAulas();
                            }
                            setPackEscolhido(element.id_pack);
                            handleNextPagarAulas();
                          }}
                          className="cardPacks"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",

                              width: "100%",
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {!isMobile ? (
                                <Typography
                                  component="div"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {element.nome}
                                    <span style={{ display: "flex-end" }}>
                                      {element.preco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  </div>
                                </Typography>
                              ) : (
                                <Typography
                                  component="div"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                    }}
                                  >
                                    {element.nome}
                                  </div>
                                </Typography>
                              )}
                              {!isMobile ? null : (
                                <Typography
                                  component="div"
                                  style={{ width: "100%" }}
                                >
                                  {element.preco}€
                                </Typography>
                              )}

                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                              >
                                {element.num_tickets} tickets
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                      ))}
                    </>
                  )}
                </div>
              )}
              {tenhopack == true ? (
                <Link
                  style={{ cursor: "pointer", marginTop: "20px" }}
                  onClick={() => {
                    setTenhoPack(false);
                  }}
                >
                  {t("comprarpack")}
                </Link>
              ) : (
                <div>
                  {listaPacks.length > 0 ? (
                    <div>
                      {verificaPackUtilizador && (
                        <Link
                          style={{ cursor: "pointer", marginTop: "20px" }}
                          onClick={() => {
                            setTenhoPack(true);
                          }}
                        >
                          {t("jatenhopack")}
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      description: (
        <div id="2">
          <Box
            sx={{
              paddingTop: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setCartaoCredito(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <CreditCardIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />
                    {t("cartaocredito")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setMultibanco(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <BuildingLibraryIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />

                    {t("multibanco")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setMbway(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <DevicePhoneMobileIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />

                    {t("mbway")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setBalcao(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <BanknotesIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />

                    {t("pagaraobalcao")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      ),
    },
    {
      description: (
        <div id="3">
          {cartaocredito == true ? (
            <div className="divResumo">
              <Paper
                elevation={0}
                style={{
                  overflow: "hidden",
                  marginTop: 20,
                  backgroundColor: "transparent",
                  width: "100%",
                }}
              >
                <Dialog
                  open={loading}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>{t("msgMb")}</DialogContentText>
                    <DialogContentText
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <CircularProgress
                        disableShrink
                        style={{
                          marginTop: 20,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                <Scrollbar style={{ height: 400 }}>
                  <Card
                    className="cardDashboardResumo"
                    variant="contained"
                    disabled
                    sx={{ paddingRight: 5 }}
                  >
                    <Card
                      sx={{
                        margin: "8px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          {/* NOME PACK/TURMA */}
                          <Typography className="teste" component="div">
                            <span>
                              {!aulas
                                ? packsCompra.find(
                                    (element) =>
                                      element.id_pack == packEscolhido
                                  )?.nome +
                                  ` (${
                                    packsCompra.find(
                                      (element) =>
                                        element.id_pack == packEscolhido
                                    )?.num_tickets
                                  } ${t("tickets")})`
                                : t("resumo10") +
                                  " - " +
                                  turmas.find(
                                    (element) =>
                                      element.id_turma == turmaEscolhida
                                  )?.nome}
                            </span>
                            {!isMobile ? (
                              !aulas ? (
                                <span>
                                  {compraPreco.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {anual ? (
                                    <span>
                                      {turmas
                                        .find(
                                          (element) =>
                                            element.id_turma == turmaEscolhida
                                        )
                                        ?.preco_anual.toLocaleString("pt-PT", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                    </span>
                                  ) : (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  )}
                                </span>
                              )
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              {!aulas ? (
                                <span>
                                  {compraPreco.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {anual ? (
                                    <span>
                                      {turmas
                                        .find(
                                          (element) =>
                                            element.id_turma == turmaEscolhida
                                        )
                                        ?.preco_anual.toLocaleString("pt-PT", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                    </span>
                                  ) : (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  )}
                                </span>
                              )}
                            </Typography>
                          )}
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {
                              piscinas.find(
                                (element) => element.id == idEscolhida
                              )?.nome
                            }
                          </Typography>
                          {aulas && (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {anual
                                ? t("pagamentoAnual")
                                : t("pagamentoMensal")}
                            </Typography>
                          )}
                          {!aulas ? (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 5,
                                marginLeft: 20,
                              }}
                            >
                              {t("utilizacao1Ticket")}:{" "}
                              {selectedDay.toLocaleDateString("pt-BR")}{" "}
                              {horasDisponiveis[horaEscolhida].horaInicio}h -{" "}
                              {horasDisponiveis[horaEscolhida].horaFim}h
                            </Typography>
                          ) : (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 5,
                                marginLeft: 20,
                              }}
                            >
                              {
                                turmas.find(
                                  (element) =>
                                    element.id_turma == turmaEscolhida
                                )?.obs
                              }
                            </Typography>
                          )}
                        </CardContent>
                      </Box>
                    </Card>

                    {compraInscricao != 0 && (
                      <Card
                        sx={{
                          margin: "10px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 10,
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                            paddingRight: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/*INSCRIÇÃO */}

                            <Typography className="teste" component="div">
                              <span>{t("resumo10")}</span>
                              <span>
                                {compraInscricao.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>

                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {
                                piscinas.find(
                                  (element) => element.id == idEscolhida
                                )?.nome
                              }
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}
                    {desconto != 0 && (
                      <Card
                        sx={{
                          margin: "10px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 15,
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                            paddingRight: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/* DESCONTO */}
                            <Typography className="teste" component="div">
                              <span>
                                {t("desconto")} {desconto}%
                              </span>
                              <span>
                                -{" "}
                                {valorDesconto.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}{" "}
                                €
                              </span>
                            </Typography>

                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {descricaoDesconto}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}

                    <Card
                      sx={{
                        margin: "10px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 15,
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            <span>{t("metodoPagamento")}</span>
                          </Typography>

                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {cartaocredito == true
                              ? t("cartaocredito")
                              : multibanco == true
                              ? t("multibanco")
                              : mbway == true
                              ? t("mbway")
                              : t("balcao")}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                    {compraTotal && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        sx={{
                          marginRight: 2,
                          marginBottom: 20,
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Card
                          sx={{
                            margin: "10px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "lightblue",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/*TOTAl */}

                              <Typography className="teste" component="div">
                                <span>{t("resumo13")}</span>
                                <span>
                                  {compraTotal.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                      </Stack>
                    )}
                  </Card>
                </Scrollbar>
              </Paper>
            </div>
          ) : null}

          {multibanco == true ? (
            <>
              {!loading2 ? (
                <div className="divResumo">
                  <Paper
                    elevation={0}
                    style={{
                      overflow: "hidden",
                      marginTop: 20,
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Scrollbar style={{ height: 400 }}>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packsCompra.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packsCompra.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "10px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "10px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}

                        <Card
                          sx={{
                            margin: "10px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {cartaocredito == true
                                  ? t("cartaocredito")
                                  : multibanco == true
                                  ? t("multibanco")
                                  : mbway == true
                                  ? t("mbway")
                                  : t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Card
                            sx={{
                              margin: "10px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "lightblue",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent sx={{ flex: "1 0 auto" }}>
                                {/*TOTAl */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo13")}</span>
                                  <span>
                                    {compraTotal.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </Card>
                    </Scrollbar>
                  </Paper>
                </div>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <form className="form2">
                    <img
                      src={mbImg}
                      style={{
                        width: "150px",
                        height: "150px",
                        marginTop: 20,
                        marginBottom: 30,
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                    <div>
                      <h2 className="multibanco">
                        {t("entidade")}
                        <span className="spanMb">{entidade}</span>
                      </h2>
                      <h2 className="multibanco">
                        {t("referencia")}{" "}
                        <span className="spanMb">{referencia}</span>
                      </h2>
                      <h2 className="multibanco">
                        {t("valorapagar")}{" "}
                        <span className="spanMb">
                          {compraTotal.toLocaleString("pt-PT", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </span>
                      </h2>
                      <p></p>
                      <h2 className="multibanco">
                        {t("dataLimite")}{" "}
                        <span className="spanMb">{dataExpira}</span>
                      </h2>
                    </div>

                    <p></p>
                  </form>
                </div>
              )}
            </>
          ) : null}

          {mbway == true ? (
            <>
              {!loading2 ? (
                <div className="divResumo">
                  <Paper
                    elevation={0}
                    style={{
                      overflow: "hidden",
                      marginTop: 20,
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Scrollbar style={{ height: 400 }}>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packsCompra.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packsCompra.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}

                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {cartaocredito == true
                                  ? t("cartaocredito")
                                  : multibanco == true
                                  ? t("multibanco")
                                  : mbway == true
                                  ? t("mbway")
                                  : t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "lightblue",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*TOTAl */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo13")}</span>
                                  <span>
                                    {compraTotal.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </Card>
                    </Scrollbar>
                  </Paper>
                </div>
              ) : (
                <div>
                  <Dialog
                    open={pagar}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent>
                      {mbwayAwait ? (
                        <CircularProgress
                          disableShrink
                          style={{
                            marginTop: 20,
                            backgroundColor: "transparent",
                          }}
                        />
                      ) : (
                        <DialogContentText>{t("msgMbway")}</DialogContentText>
                      )}
                    </DialogContent>
                  </Dialog>

                  <form className="form2">
                    <img
                      src={mbwayfotoImg}
                      style={{
                        width: "200px",
                        height: "100px",
                        marginBottom: "80px",
                        marginTop: 100,
                      }}
                    />
                    <p>
                      {t("valorapagar")}{" "}
                      {compraTotal.toLocaleString("pt-PT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </p>
                    <PhoneInput
                      defaultCountry="PT"
                      countries={["PT"]}
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefone}
                      error={erroTelefone}
                      helperText={mensagemErroTelefone}
                      onChange={(e) => {
                        setTelefone(e);
                        setErroTelefone(false);
                        setMensagemErroTelefone("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </form>
                </div>
              )}
            </>
          ) : null}
          {balcao == true ? (
            <>
              {!loading2 ? (
                <div className="divResumo">
                  <Paper
                    elevation={0}
                    style={{
                      overflow: "hidden",
                      marginTop: 20,
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>

                    <Scrollbar style={{ height: 400 }}>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packsCompra.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packsCompra.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}

                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {cartaocredito == true
                                  ? t("cartaocredito")
                                  : multibanco == true
                                  ? t("multibanco")
                                  : mbway == true
                                  ? t("mbway")
                                  : t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "lightblue",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*TOTAl */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo13")}</span>
                                  <span>
                                    {compraTotal.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </Card>
                    </Scrollbar>
                  </Paper>
                </div>
              ) : (
                <div style={{ paddingTop: "100px" }}>
                  <Button
                    className="cardDashboardMarcar4"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.ativo,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    disabled
                  >
                    <div style={{ display: "block" }}>
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faFaceSmile}
                      />
                    </div>
                    <span
                      style={{
                        marginTop: "8px",
                        fontSize: isMobile ? "10px" : "14px",
                      }}
                    >
                      {t("mensagembalcao")}
                    </span>
                  </Button>
                </div>
              )}
            </>
          ) : null}

          {tenhopack == true ? (
            <>
              <div className="divResumo">
                <Paper
                  elevation={0}
                  style={{
                    height: "auto",
                    overflow: "hidden",
                    marginTop: 20,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                >
                  <Dialog
                    open={loading}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent>
                      <DialogContentText>{t("msgMb")}</DialogContentText>
                      <DialogContentText
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f7f7f7",
                        }}
                      >
                        <CircularProgress
                          disableShrink
                          style={{
                            marginTop: 20,
                          }}
                        />
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>

                  <Card
                    className="cardDashboardResumo"
                    variant="contained"
                    disabled
                    sx={{ paddingRight: 5 }}
                  >
                    <Card
                      sx={{
                        margin: "8px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          {/* NOME PACK/TURMA */}
                          <Typography className="teste" component="div">
                            <span>
                              {listaPacks.find(
                                (element) =>
                                  element.id_pack_cliente ==
                                  packUtilizadorEscolhido
                              )?.nomePack +
                                ` (${
                                  listaPacks.find(
                                    (element) =>
                                      element.id_pack_cliente ==
                                      packUtilizadorEscolhido
                                  )?.num_tickets
                                } ${t("tickets")})`}
                            </span>
                            {!isMobile ? (
                              <span>
                                {compraPreco.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {compraPreco.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            <span>
                              {t("ticketsUtilizados")}:{" "}
                              {
                                listaPacks.find(
                                  (element) =>
                                    element.id_pack_cliente ==
                                    packUtilizadorEscolhido
                                )?.num_tickets_utilizados
                              }{" "}
                            </span>
                          </Typography>
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {
                              piscinas.find(
                                (element) => element.id == idEscolhida
                              )?.nome
                            }
                          </Typography>
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 5,
                              marginLeft: 20,
                            }}
                          >
                            {t("utilizacao1Ticket")}:{" "}
                            {selectedDay.toLocaleDateString("pt-BR")}{" "}
                            {horasDisponiveis[horaEscolhida].horaInicio}h -{" "}
                            {horasDisponiveis[horaEscolhida].horaFim}h
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                    {compraTotal == 0 && (
                      <Card
                        sx={{
                          margin: "8px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 10,
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "lightblue",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 5,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/*TOTAl */}
                            <Typography className="teste" component="div">
                              <span>{t("resumo13")}</span>
                              <span>
                                {compraTotal.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}
                  </Card>
                </Paper>
              </div>
            </>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div style={{}}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaClientes")}</h3>
        <div className="divTabelaM">
          {/* <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/dashboard_admin");
              }}
              size={window.innerWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}

          {editarCliente ? (
            <div style={{ width: "100%", height: "100%" }}>
              <IconButton
                onClick={() => {
                  LimparVariaveis();
                }}
                size={isMobile ? "small" : "medium"}
                style={{
                  position: "absolute",
                  marginTop: "20px",
                  marginLeft: "15px",
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </IconButton>

              {isMobile ? (
                isMobile ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        left: "50%",
                        paddingTop: "30px",
                      }}
                    >
                      <button
                        className={`botaolistaadmin ${
                          selecionado === "suspender"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "105px",

                          fontSize: isMobile ? "10px" : "12px",

                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("suspender");
                          handleClickOpenPopupSuspender();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faCircleStop}
                        />{" "}
                        {statusCliente === 1 ? t("suspender") : t("Reativar")}
                      </button>
                      <button
                        className={`botaolistaadmin ${
                          selecionado === "eliminar"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "105px",

                          fontSize: isMobile ? "10px" : "12px",

                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("eliminar");
                          handleClickOpenPopupEliminar();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faTrash}
                        />{" "}
                        {t("eliminar")}
                      </button>
                      </div>
                      <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        left: "50%",
                      }}
                    >
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "contaFamiliar"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "140px",
                          height: "40px",
                          fontSize: isMobile ? "10px" : "12px",
                        }}
                        onClick={() => {
                          handleClick("contaFamiliar");
                          handleClickOpenContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <UserGroupIcon
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "6px",
                          }}
                        />{" "}
                        {t("contaFamiliar")}
                      </button>
                    
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "ListaPagamentos"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "125px",
                          height: "40px",
                          fontSize: isMobile ? "10px" : "12px",
                        }}
                        onClick={() => {
                          handleClick("ListaPagamentos");
                          handleClickCloseAtividadesCliente();
                          handleClickCloseContaFamiliar();
                          handleClickOpenPagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          src={UnionIcon}
                        />{" "}
                        {t("ListaPagamentos")}
                      </button>
                      </div>
                      <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        left: "50%",
                      }}
                    >
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "marcar"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "85px",
                          fontSize: isMobile ? "10px" : "12px",

                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("marcar");
                          handleClickCloseContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickOpenMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faPlusCircle}
                        />{" "}
                        {t("marcar")}
                      </button>
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "packs"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "75px",
                          fontSize: isMobile ? "10px" : "12px",
                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("packs");
                          handleClickCloseContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickOpenPacksCliente();
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          src={IconPacks}
                        />{" "}
                        {t("packs")}
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        left: "50%",
                      }}
                    >
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "proximasAtividades"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "140px",
                          height: "40px",
                          fontSize: isMobile ? "10px" : "12px",
                        }}
                        onClick={() => {
                          handleClick("proximasAtividades");
                          handleClickCloseContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickOpenAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          src={ProximasAtividadesIcon}
                        />{" "}
                        {t("proximasAtividades")}
                      </button>
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "reporPalavraPasse2"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "140px" : "155px",

                          height: "40px",
                          fontSize: isMobile ? "10px" : "12px",
                        }}
                        onClick={() => {
                          handleClick("reporPalavraPasse2");
                          handleClickOpenPopupReporPassword();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faLock}
                        />{" "}
                        {t("reporPalavraPasse2")}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        left: "50%",
                      }}
                    >
                      <button
                        className={`botaolistaadmin ${
                          selecionado === "suspender"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "95px" : "105px",
                          fontSize: isMobile ? "12px" : "14px",
                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("suspender");
                          handleClickOpenPopupSuspender();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faCircleStop}
                        />{" "}
                        {statusCliente === 1 ? t("suspender") : t("Reativar")}
                      </button>
                      <button
                        className={`botaolistaadmin ${
                          selecionado === "eliminar"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "85px" : "95px",
                          height: "40px",
                          fontSize: isMobile ? "12px" : "14px",
                        }}
                        onClick={() => {
                          handleClick("eliminar");
                          handleClickOpenPopupEliminar();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faTrash}
                        />{" "}
                        {t("eliminar")}
                      </button>
                      <button
                        className={`botaolistaadmin ${
                          selecionado === "contaFamiliar"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "130px" : "140px",

                          height: "40px",
                          fontSize: isMobile ? "12px" : "14px",
                        }}
                        onClick={() => {
                          handleClick("contaFamiliar");
                          handleClickOpenContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <UserGroupIcon
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "6px",
                          }}
                        />{" "}
                        {t("contaFamiliar")}
                      </button>
                      <button
                        className={`botaolistaadmin ${
                          selecionado === "ListaPagamentos"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "110px" : "125px",
                          height: "40px",
                          fontSize: isMobile ? "12px" : "14px",
                        }}
                        onClick={() => {
                          handleClick("ListaPagamentos");
                          handleClickCloseContaFamiliar();
                          handleClickOpenPagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          src={UnionIcon}
                        />{" "}
                        {t("ListaPagamentos")}
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        left: "50%",
                      }}
                    >
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "marcar"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "75px" : "85px",
                          fontSize: isMobile ? "12px" : "14px",
                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("marcar");
                          handleClickCloseContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickOpenAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faPlusCircle}
                        />{" "}
                        {t("marcar")}
                      </button>
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "packs"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "65px" : "75px",
                          fontSize: isMobile ? "12px" : "14px",
                          height: "40px",
                        }}
                        onClick={() => {
                          handleClick("packs");
                          handleClickCloseContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickCloseAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickOpenPacksCliente();
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          src={IconPacks}
                        />{" "}
                        {t("packs")}
                      </button>
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "proximasAtividades"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "135px" : "140px",
                          height: "40px",
                          fontSize: isMobile ? "12px" : "14px",
                        }}
                        onClick={() => {
                          handleClick("proximasAtividades");
                          handleClickCloseContaFamiliar();
                          handleClickClosePagamentosCliente();
                          handleClickOpenAtividadesCliente();
                          handleClickCloseMarcar();
                          handleClickClosePacksCliente();
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          src={ProximasAtividadesIcon}
                        />{" "}
                        {t("proximasAtividades")}
                      </button>
                      <button
                        className={`botaolistaadmin2 ${
                          selecionado === "reporPalavraPasse2"
                            ? "botaolistaadminselecionado"
                            : ""
                        }`}
                        style={{
                          width: isMobile ? "135px" : "155px",
                          height: "40px",
                          fontSize: isMobile ? "12px" : "14px",
                        }}
                        onClick={() => {
                          handleClick("reporPalavraPasse2");
                          handleClickOpenPopupReporPassword();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "6px",
                          }}
                          icon={faLock}
                        />{" "}
                        {t("reporPalavraPasse2")}
                      </button>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      left: "50%",
                    }}
                  >
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "suspender"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "95px" : "165px",
                        fontSize: isMobile ? "10px" : "12px",
                        height: "40px",
                      }}
                      onClick={() => {
                        handleClick("suspender");
                        handleClickOpenPopupSuspender();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        icon={faCircleStop}
                      />{" "}
                      {statusCliente === 1 ? t("suspender") : t("Reativar")}
                    </button>
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "eliminar"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "85px" : "165px",
                        height: "40px",
                        fontSize: isMobile ? "10px" : "12px",
                      }}
                      onClick={() => {
                        handleClick("eliminar");
                        handleClickOpenPopupEliminar();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        icon={faTrash}
                      />{" "}
                      {t("eliminar")}
                    </button>
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "contaFamiliar"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "130px" : "165px",
                        height: "40px",
                        fontSize: isMobile ? "10px" : "12px",
                      }}
                      onClick={() => {
                        handleClick("contaFamiliar");
                        handleClickOpenContaFamiliar();
                        handleClickClosePagamentosCliente();
                        handleClickCloseAtividadesCliente();
                        handleClickCloseMarcar();
                        handleClickClosePacksCliente();
                      }}
                    >
                      <UserGroupIcon
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "6px",
                        }}
                      />{" "}
                      {t("contaFamiliar")}
                    </button>
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "ListaPagamentos"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "110px" : "165px",
                        height: "40px",
                        fontSize: isMobile ? "10px" : "12px",
                      }}
                      onClick={() => {
                        handleClick("ListaPagamentos");
                        handleClickCloseContaFamiliar();
                        handleClickOpenPagamentosCliente();
                        handleClickCloseAtividadesCliente();
                        handleClickCloseMarcar();
                        handleClickClosePacksCliente();
                      }}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        src={UnionIcon}
                      />{" "}
                      {t("ListaPagamentos")}
                    </button>
                     
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      left: "50%",
                    }}
                  >
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "marcar"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "75px" : "165px",
                        fontSize: isMobile ? "10px" : "12px",
                        height: "40px",
                      }}
                      onClick={() => {
                        handleClick("marcar");
                        handleClickClosePagamentosCliente();
                        handleClickCloseAtividadesCliente();
                        handleClickCloseContaFamiliar();
                        handleClickClosePacksCliente();
                        handleClickOpenMarcar();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        icon={faPlusCircle}
                      />{" "}
                      {t("marcar")}
                    </button>
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "packs"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "65px" : "165px",
                        fontSize: isMobile ? "10px" : "12px",
                        height: "40px",
                      }}
                      onClick={() => {
                        handleClick("packs");
                        handleClickCloseContaFamiliar();
                        handleClickClosePagamentosCliente();
                        handleClickCloseAtividadesCliente();
                        handleClickCloseMarcar();
                        handleClickOpenPacksCliente();
                      }}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        src={IconPacks}
                      />{" "}
                      {t("packs")}
                    </button>
                  
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "proximasAtividades"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "135px" : "165px",
                        height: "40px",
                        fontSize: isMobile ? "10px" : "12px",
                      }}
                      onClick={() => {
                        handleClick("proximasAtividades");
                        handleClickClosePacksCliente();
                        handleClickCloseContaFamiliar();
                        handleClickCloseMarcar();
                        handleClickClosePagamentosCliente();
                        handleClickOpenAtividadesCliente();
                      }}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        src={ProximasAtividadesIcon}
                      />{" "}
                      {t("proximasAtividades")}
                    </button>
                    <button
                      className={`botaolistaadmin ${
                        selecionado === "reporPalavraPasse2"
                          ? "botaolistaadminselecionado"
                          : ""
                      }`}
                      style={{
                        width: isMobile ? "135px" : "165px",
                        height: "40px",
                        fontSize: isMobile ? "10px" : "12px",
                      }}
                      onClick={() => {
                        handleClick("reporPalavraPasse2");
                        handleClickOpenPopupReporPassword();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "6px",
                        }}
                        icon={faLock}
                      />{" "}
                      {t("reporPalavraPasse2")}
                    </button>
                  </div>
                </>
              )}
              {/* Lista marcar */}
              {marcar ? (
                <div className="divForm">
                  <div className="formPage2">
                    {semDados == true ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress disableShrink />
                      </div>
                    ) : (
                      <Box
                        sx={{
                          minWidth: "65%",
                          maxWidth: "100%",
                          overflow: "hidden",
                          maxHeight: "100% !important",
                        }}
                      >
                        <Stepper
                          activeStep={activeStep}
                          orientation="vertical"
                          connector={null}
                        >
                          {steps.map((step, index) => (
                            <Step key={step.label} connector={null}>
                              <StepContent
                                connector={null}
                                sx={{ borderColor: "transparent" }}
                              >
                                {step.description}
                                <Box sx={{ mb: 2 }}></Box>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                        <Dialog
                          open={openPagamentoAulas}
                          onClose={
                            loading2 == true
                              ? null
                              : handleClickOpenCancelarPagamento
                          }
                          aria-labelledby="scroll-dialog-title"
                          aria-describedby="scroll-dialog-description"
                          PaperProps={{
                            style: {
                              minWidth: 686,
                              height: 580,
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <DialogTitle
                            id="alert-dialog-title"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {activeStepPagarAulas === 0 ? (
                              aulas == true ? (
                                <span>{t("escolhertipopagamento")}</span>
                              ) : tenhopack == true ? (
                                <span> {t("escolherpackpagamento")}</span>
                              ) : (
                                <span>{t("escolherpack")}</span>
                              )
                            ) : activeStepPagarAulas === 1 ? (
                              <span>{t("escolhermetodopagamento")}</span>
                            ) : activeStepPagarAulas === 2 ? (
                              <div>
                                {loading2 == false ? (
                                  <span>{t("resumo")}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : null}

                            {loading2 == false ? (
                              <FontAwesomeIcon
                                edge="end"
                                color="inherit"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  padding: "8px",
                                  zIndex: 2000,
                                }}
                                onClick={
                                  loading2 == true
                                    ? null
                                    : handleClickOpenCancelarPagamento
                                }
                                aria-label="Fechar"
                                icon={faClose}
                              />
                            ) : null}

                            {activeStepPagarAulas > 0 && loading2 == false && (
                              <FontAwesomeIcon
                                edge="end"
                                color="inherit"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  cursor: "pointer",
                                  padding: "8px",
                                  marginLeft: "5px",
                                  zIndex: 2000,
                                }}
                                onClick={goBackPagarAulas}
                                aria-label="Fechar"
                                icon={faArrowLeft}
                              />
                            )}
                          </DialogTitle>

                          <DialogContent>
                            <div className="divPagamento">
                              <Stepper
                                activeStep={activeStepPagarAulas}
                                orientation="vertical"
                                connector={null}
                              >
                                {stepsPagarAulas.map((step, index) => (
                                  <Step key={step.label} connector={null}>
                                    <StepContent
                                      connector={null}
                                      sx={{ borderColor: "transparent" }}
                                    >
                                      {step.description}
                                      <Box sx={{ mb: 2 }}></Box>
                                    </StepContent>
                                  </Step>
                                ))}
                              </Stepper>
                            </div>
                          </DialogContent>
                          {activeStepPagarAulas == 2 ? (
                            <DialogActions
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {cartaocredito == true ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setLoading(true);
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    const data = new Date(selectedDay);
                                    const dia = data
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const mes = (data.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const ano = data.getFullYear();
                                    const dataFormatada = `${dia}/${mes}/${ano}`;
                                    {
                                      !aulas
                                        ? registarMarcacaoInativa({
                                            id_pack: packEscolhido,
                                            id_piscina: idEscolhida,
                                            data: dataFormatada,
                                            horaInicio:
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio,
                                            horaFim:
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim,
                                            id_filho: id_filho,
                                            id_cliente:
                                              funcionarioSelecionadoId,
                                          }).then((res2) => {
                                            if (res2.success) {
                                              setTimeout(async function () {
                                                pagamentoCartaoCredito({
                                                  id_pack: packEscolhido,
                                                  id_piscina: idEscolhida,
                                                  id_turma: 0,
                                                  id_filho: id_filho,
                                                  language: i18n.language,
                                                  id_preco: 0,
                                                  id_desconto: idDesconto,
                                                  id_cliente:
                                                    funcionarioSelecionadoId,
                                                }).then((res) => {
                                                  if (res.success) {
                                                    setTimeout(
                                                      async function () {
                                                        let id_marcacao =
                                                          res2.data.id;
                                                        let id_pagamento =
                                                          res.data.id;
                                                        let paymentURL =
                                                          res.data.data
                                                            .PaymentUrl;
                                                        associarPagamentosMarcacoes(
                                                          {
                                                            id_marcacao:
                                                              id_marcacao,
                                                            id_pagamento:
                                                              id_pagamento,
                                                          }
                                                        ).then((res3) => {
                                                          if (res3.success) {
                                                            setTimeout(
                                                              async function () {
                                                                let produtos = [
                                                                  {
                                                                    nomeProduto:
                                                                      !aulas
                                                                        ? packs.find(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              element.id ==
                                                                              packEscolhido
                                                                          )
                                                                            ?.nome +
                                                                          ` (${
                                                                            packs.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id ==
                                                                                packEscolhido
                                                                            )
                                                                              ?.num_tickets
                                                                          } ${t(
                                                                            "tickets"
                                                                          )})`
                                                                        : t(
                                                                            "resumo10"
                                                                          ) +
                                                                          " - " +
                                                                          turmas.find(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              element.id_turma ==
                                                                              turmaEscolhida
                                                                          )
                                                                            ?.nome,
                                                                    precoProduto:
                                                                      !aulas
                                                                        ? compraPreco.toLocaleString(
                                                                            "pt-PT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "EUR",
                                                                            }
                                                                          )
                                                                        : anual.toLocaleString(
                                                                            "pt-PT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "EUR",
                                                                            }
                                                                          )
                                                                        ? turmas
                                                                            .find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id_turma ==
                                                                                turmaEscolhida
                                                                            )
                                                                            ?.preco_anual.toLocaleString(
                                                                              "pt-PT",
                                                                              {
                                                                                style:
                                                                                  "currency",
                                                                                currency:
                                                                                  "EUR",
                                                                              }
                                                                            )
                                                                        : compraPreco.toLocaleString(
                                                                            "pt-PT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "EUR",
                                                                            }
                                                                          ),
                                                                    desc1: aulas
                                                                      ? piscinas.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id ==
                                                                            idEscolhida
                                                                        )
                                                                          ?.nome +
                                                                        " - " +
                                                                        anual
                                                                        ? t(
                                                                            "pagamentoAnual"
                                                                          )
                                                                        : t(
                                                                            "pagamentoMensal"
                                                                          )
                                                                      : t(
                                                                          "utilizacao1Ticket"
                                                                        ) +
                                                                        " " +
                                                                        selectedDay.toLocaleDateString(
                                                                          "pt-BR"
                                                                        ) +
                                                                        " " +
                                                                        horasDisponiveis[
                                                                          horaEscolhida
                                                                        ]
                                                                          .horaInicio +
                                                                        "h - " +
                                                                        horasDisponiveis[
                                                                          horaEscolhida
                                                                        ]
                                                                          .horaFim +
                                                                        "h",
                                                                    desc2: aulas
                                                                      ? turmas.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id_turma ==
                                                                            turmaEscolhida
                                                                        )?.obs
                                                                      : piscinas.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id ==
                                                                            idEscolhida
                                                                        )?.nome,
                                                                    quantidade: 1,
                                                                    quantidadeTexto:
                                                                      t(
                                                                        "quantidade"
                                                                      ),
                                                                  },
                                                                ];

                                                                let produtosNum =
                                                                  [
                                                                    {
                                                                      nomeProduto:
                                                                        !aulas
                                                                          ? packs.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id ==
                                                                                packEscolhido
                                                                            )
                                                                              ?.nome +
                                                                            ` (${
                                                                              packs.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id ==
                                                                                  packEscolhido
                                                                              )
                                                                                ?.num_tickets
                                                                            } ${t(
                                                                              "tickets"
                                                                            )})`
                                                                          : t(
                                                                              "resumo10"
                                                                            ) +
                                                                            " - " +
                                                                            turmas.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id_turma ==
                                                                                turmaEscolhida
                                                                            )
                                                                              ?.nome,
                                                                      precoProduto:
                                                                        !aulas
                                                                          ? compraPreco
                                                                          : anual
                                                                          ? turmas.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id_turma ==
                                                                                turmaEscolhida
                                                                            )
                                                                              ?.preco_anual
                                                                          : compraPreco,
                                                                      desc1:
                                                                        aulas
                                                                          ? piscinas.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id ==
                                                                                idEscolhida
                                                                            )
                                                                              ?.nome +
                                                                            " - " +
                                                                            anual
                                                                            ? t(
                                                                                "pagamentoAnual"
                                                                              )
                                                                            : t(
                                                                                "pagamentoMensal"
                                                                              )
                                                                          : t(
                                                                              "utilizacao1Ticket"
                                                                            ) +
                                                                            " " +
                                                                            selectedDay.toLocaleDateString(
                                                                              "pt-BR"
                                                                            ) +
                                                                            " " +
                                                                            horasDisponiveis[
                                                                              horaEscolhida
                                                                            ]
                                                                              .horaInicio +
                                                                            "h - " +
                                                                            horasDisponiveis[
                                                                              horaEscolhida
                                                                            ]
                                                                              .horaFim +
                                                                            "h",
                                                                      desc2:
                                                                        aulas
                                                                          ? turmas.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id_turma ==
                                                                                turmaEscolhida
                                                                            )
                                                                              ?.obs
                                                                          : piscinas.find(
                                                                              (
                                                                                element
                                                                              ) =>
                                                                                element.id ==
                                                                                idEscolhida
                                                                            )
                                                                              ?.nome,
                                                                      quantidade: 1,
                                                                      quantidadeTexto:
                                                                        t(
                                                                          "quantidade"
                                                                        ),
                                                                    },
                                                                  ];

                                                                if (
                                                                  compraInscricao !=
                                                                  0
                                                                ) {
                                                                  produtos.push(
                                                                    {
                                                                      nomeProduto:
                                                                        t(
                                                                          "resumo10"
                                                                        ),
                                                                      precoProduto:
                                                                        compraInscricao.toLocaleString(
                                                                          "pt-PT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "EUR",
                                                                          }
                                                                        ),
                                                                      desc1:
                                                                        piscinas.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id ==
                                                                            idEscolhida
                                                                        )?.nome,
                                                                      desc2: "",
                                                                      quantidade: 1,
                                                                      quantidadeTexto:
                                                                        t(
                                                                          "quantidade"
                                                                        ),
                                                                    }
                                                                  );
                                                                  produtosNum.push(
                                                                    {
                                                                      nomeProduto:
                                                                        t(
                                                                          "resumo10"
                                                                        ),
                                                                      precoProduto:
                                                                        compraInscricao,
                                                                      desc1:
                                                                        piscinas.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id ==
                                                                            idEscolhida
                                                                        )?.nome,
                                                                      desc2: "",
                                                                      quantidade: 1,
                                                                      quantidadeTexto:
                                                                        t(
                                                                          "quantidade"
                                                                        ),
                                                                    }
                                                                  );
                                                                }
                                                                linhasCompra({
                                                                  id_cliente:
                                                                    funcionarioSelecionadoId,
                                                                  produtos:
                                                                    produtosNum,
                                                                  id_pagamento:
                                                                    id_pagamento,
                                                                }).then(
                                                                  (res4) => {
                                                                    setTimeout(
                                                                      () => {
                                                                        emailCompraPackMarcacao(
                                                                          {
                                                                            id_cliente:
                                                                              funcionarioSelecionadoId,
                                                                            datetime:
                                                                              dataFormatada +
                                                                              " " +
                                                                              horasDisponiveis[
                                                                                horaEscolhida
                                                                              ]
                                                                                .horaInicio +
                                                                              "h - " +
                                                                              horasDisponiveis[
                                                                                horaEscolhida
                                                                              ]
                                                                                .horaFim +
                                                                              "h",
                                                                            pack: packEscolhido,
                                                                            link: res
                                                                              .data
                                                                              .data
                                                                              .PaymentUrl,
                                                                            total:
                                                                              compraTotal,
                                                                            desconto:
                                                                              idDesconto,
                                                                            produtos:
                                                                              produtos,
                                                                            precoDesconto:
                                                                              valorDesconto,
                                                                            id_filho:
                                                                              id_filho,
                                                                          }
                                                                        ).then(
                                                                          (
                                                                            res5
                                                                          ) => {
                                                                            window.location.href =
                                                                              paymentURL;
                                                                          }
                                                                        );
                                                                      },
                                                                      3000
                                                                    );
                                                                  }
                                                                );
                                                              },
                                                              3000
                                                            );
                                                          }
                                                        });
                                                      },
                                                      3000
                                                    );
                                                  }
                                                });
                                              }, 3000);
                                            }
                                          })
                                        : pagamentoCartaoCredito({
                                            id_cliente:
                                              funcionarioSelecionadoId,
                                            id_pack: 0,
                                            id_piscina: idEscolhida,
                                            id_turma: turmaEscolhida,
                                            id_filho: id_filho,
                                            language: i18n.language,
                                            id_preco: anual
                                              ? 1
                                              : mensal
                                              ? 2
                                              : 0,
                                            id_desconto: idDesconto,
                                          }).then((res) => {
                                            if (res.success) {
                                              let id_pagamento = res.data.id;
                                              setTimeout(async function () {
                                                let produtos = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      : anual
                                                      ? turmas
                                                          .find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )
                                                          ?.preco_anual.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                      : compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                let produtosNum = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco
                                                      : anual
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.preco_anual
                                                      : compraPreco,
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                if (compraInscricao != 0) {
                                                  produtos.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      ),
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                  produtosNum.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao,
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                }
                                                linhasCompra({
                                                  id_cliente:
                                                    funcionarioSelecionadoId,
                                                  produtos: produtosNum,
                                                  id_pagamento: id_pagamento,
                                                }).then((res4) => {
                                                  setTimeout(() => {
                                                    emailInscricaoTurma({
                                                      id_cliente:
                                                        funcionarioSelecionadoId,
                                                      turma: turmaEscolhida,
                                                      link: res.data.data
                                                        .PaymentUrl,
                                                      total: compraTotal,
                                                      desconto: idDesconto,
                                                      produtos: produtos,
                                                      precoDesconto:
                                                        valorDesconto,
                                                      id_filho: id_filho,
                                                    }).then((res5) => {
                                                      window.location.href =
                                                        res.data.data.PaymentUrl;
                                                    });
                                                  }, 3000);
                                                });
                                              }, 3000);
                                            }
                                          });
                                    }
                                    setLoading(false);
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("confirmar")}
                                </Button>
                              ) : null}

                              {multibanco == true && loading2 == false ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setLoading(true);
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    const data = new Date(selectedDay);
                                    const dia = data
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const mes = (data.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const ano = data.getFullYear();
                                    const dataFormatada = `${dia}/${mes}/${ano}`;
                                    {
                                      !aulas
                                        ? registarMarcacaoInativa({
                                            id_pack: packEscolhido,
                                            id_piscina: idEscolhida,
                                            data: dataFormatada,
                                            horaInicio:
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio,
                                            horaFim:
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim,
                                            id_filho: id_filho,
                                            id_cliente:
                                              funcionarioSelecionadoId,
                                          }).then((res2) => {
                                            if (res2.success) {
                                              setTimeout(async function () {
                                                pagamentoMultibanco({
                                                  id_cliente:
                                                    funcionarioSelecionadoId,
                                                  id_pack: packEscolhido,
                                                  id_piscina: idEscolhida,
                                                  id_turma: 0,
                                                  id_filho: id_filho,
                                                  language: i18n.language,
                                                  id_preco: 0,
                                                  id_desconto: idDesconto,
                                                }).then((res) => {
                                                  if (res.success) {
                                                    setTimeout(
                                                      async function () {
                                                        let id_marcacao =
                                                          res2.data.id;
                                                        let id_pagamento =
                                                          res.data.id;
                                                        associarPagamentosMarcacoes(
                                                          {
                                                            id_marcacao:
                                                              id_marcacao,
                                                            id_pagamento:
                                                              id_pagamento,
                                                            id_cliente:
                                                              funcionarioSelecionadoId,
                                                          }
                                                        ).then((res3) => {
                                                          if (res3.success) {
                                                            setTimeout(
                                                              async function () {
                                                                let id_marcacao =
                                                                  res2.data.id;
                                                                let id_pagamento =
                                                                  res.data.id;
                                                                let paymentURL =
                                                                  res.data.data
                                                                    .PaymentUrl;
                                                                associarPagamentosMarcacoes(
                                                                  {
                                                                    id_marcacao:
                                                                      id_marcacao,
                                                                    id_pagamento:
                                                                      id_pagamento,
                                                                  }
                                                                ).then(
                                                                  (res3) => {
                                                                    if (
                                                                      res3.success
                                                                    ) {
                                                                      setTimeout(
                                                                        async function () {
                                                                          let produtos =
                                                                            [
                                                                              {
                                                                                nomeProduto:
                                                                                  !aulas
                                                                                    ? packs.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id ==
                                                                                          packEscolhido
                                                                                      )
                                                                                        ?.nome +
                                                                                      ` (${
                                                                                        packs.find(
                                                                                          (
                                                                                            element
                                                                                          ) =>
                                                                                            element.id ==
                                                                                            packEscolhido
                                                                                        )
                                                                                          ?.num_tickets
                                                                                      } ${t(
                                                                                        "tickets"
                                                                                      )})`
                                                                                    : t(
                                                                                        "resumo10"
                                                                                      ) +
                                                                                      " - " +
                                                                                      turmas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id_turma ==
                                                                                          turmaEscolhida
                                                                                      )
                                                                                        ?.nome,
                                                                                precoProduto:
                                                                                  !aulas
                                                                                    ? compraPreco.toLocaleString(
                                                                                        "pt-PT",
                                                                                        {
                                                                                          style:
                                                                                            "currency",
                                                                                          currency:
                                                                                            "EUR",
                                                                                        }
                                                                                      )
                                                                                    : anual.toLocaleString(
                                                                                        "pt-PT",
                                                                                        {
                                                                                          style:
                                                                                            "currency",
                                                                                          currency:
                                                                                            "EUR",
                                                                                        }
                                                                                      )
                                                                                    ? turmas
                                                                                        .find(
                                                                                          (
                                                                                            element
                                                                                          ) =>
                                                                                            element.id_turma ==
                                                                                            turmaEscolhida
                                                                                        )
                                                                                        ?.preco_anual.toLocaleString(
                                                                                          "pt-PT",
                                                                                          {
                                                                                            style:
                                                                                              "currency",
                                                                                            currency:
                                                                                              "EUR",
                                                                                          }
                                                                                        )
                                                                                    : compraPreco.toLocaleString(
                                                                                        "pt-PT",
                                                                                        {
                                                                                          style:
                                                                                            "currency",
                                                                                          currency:
                                                                                            "EUR",
                                                                                        }
                                                                                      ),
                                                                                desc1:
                                                                                  aulas
                                                                                    ? piscinas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id ==
                                                                                          idEscolhida
                                                                                      )
                                                                                        ?.nome +
                                                                                      " - " +
                                                                                      anual
                                                                                      ? t(
                                                                                          "pagamentoAnual"
                                                                                        )
                                                                                      : t(
                                                                                          "pagamentoMensal"
                                                                                        )
                                                                                    : t(
                                                                                        "utilizacao1Ticket"
                                                                                      ) +
                                                                                      " " +
                                                                                      selectedDay.toLocaleDateString(
                                                                                        "pt-BR"
                                                                                      ) +
                                                                                      " " +
                                                                                      horasDisponiveis[
                                                                                        horaEscolhida
                                                                                      ]
                                                                                        .horaInicio +
                                                                                      "h - " +
                                                                                      horasDisponiveis[
                                                                                        horaEscolhida
                                                                                      ]
                                                                                        .horaFim +
                                                                                      "h",
                                                                                desc2:
                                                                                  aulas
                                                                                    ? turmas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id_turma ==
                                                                                          turmaEscolhida
                                                                                      )
                                                                                        ?.obs
                                                                                    : piscinas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id ==
                                                                                          idEscolhida
                                                                                      )
                                                                                        ?.nome,
                                                                                quantidade: 1,
                                                                                quantidadeTexto:
                                                                                  t(
                                                                                    "quantidade"
                                                                                  ),
                                                                              },
                                                                            ];
                                                                          let produtosNum =
                                                                            [
                                                                              {
                                                                                nomeProduto:
                                                                                  !aulas
                                                                                    ? packs.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id ==
                                                                                          packEscolhido
                                                                                      )
                                                                                        ?.nome +
                                                                                      ` (${
                                                                                        packs.find(
                                                                                          (
                                                                                            element
                                                                                          ) =>
                                                                                            element.id ==
                                                                                            packEscolhido
                                                                                        )
                                                                                          ?.num_tickets
                                                                                      } ${t(
                                                                                        "tickets"
                                                                                      )})`
                                                                                    : t(
                                                                                        "resumo10"
                                                                                      ) +
                                                                                      " - " +
                                                                                      turmas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id_turma ==
                                                                                          turmaEscolhida
                                                                                      )
                                                                                        ?.nome,
                                                                                precoProduto:
                                                                                  !aulas
                                                                                    ? compraPreco
                                                                                    : anual
                                                                                    ? turmas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id_turma ==
                                                                                          turmaEscolhida
                                                                                      )
                                                                                        ?.preco_anual
                                                                                    : compraPreco,
                                                                                desc1:
                                                                                  aulas
                                                                                    ? piscinas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id ==
                                                                                          idEscolhida
                                                                                      )
                                                                                        ?.nome +
                                                                                      " - " +
                                                                                      anual
                                                                                      ? t(
                                                                                          "pagamentoAnual"
                                                                                        )
                                                                                      : t(
                                                                                          "pagamentoMensal"
                                                                                        )
                                                                                    : t(
                                                                                        "utilizacao1Ticket"
                                                                                      ) +
                                                                                      " " +
                                                                                      selectedDay.toLocaleDateString(
                                                                                        "pt-BR"
                                                                                      ) +
                                                                                      " " +
                                                                                      horasDisponiveis[
                                                                                        horaEscolhida
                                                                                      ]
                                                                                        .horaInicio +
                                                                                      "h - " +
                                                                                      horasDisponiveis[
                                                                                        horaEscolhida
                                                                                      ]
                                                                                        .horaFim +
                                                                                      "h",
                                                                                desc2:
                                                                                  aulas
                                                                                    ? turmas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id_turma ==
                                                                                          turmaEscolhida
                                                                                      )
                                                                                        ?.obs
                                                                                    : piscinas.find(
                                                                                        (
                                                                                          element
                                                                                        ) =>
                                                                                          element.id ==
                                                                                          idEscolhida
                                                                                      )
                                                                                        ?.nome,
                                                                                quantidade: 1,
                                                                                quantidadeTexto:
                                                                                  t(
                                                                                    "quantidade"
                                                                                  ),
                                                                              },
                                                                            ];

                                                                          if (
                                                                            compraInscricao !=
                                                                            0
                                                                          ) {
                                                                            produtos.push(
                                                                              {
                                                                                nomeProduto:
                                                                                  t(
                                                                                    "resumo10"
                                                                                  ),
                                                                                precoProduto:
                                                                                  compraInscricao.toLocaleString(
                                                                                    "pt-PT",
                                                                                    {
                                                                                      style:
                                                                                        "currency",
                                                                                      currency:
                                                                                        "EUR",
                                                                                    }
                                                                                  ),
                                                                                desc1:
                                                                                  piscinas.find(
                                                                                    (
                                                                                      element
                                                                                    ) =>
                                                                                      element.id ==
                                                                                      idEscolhida
                                                                                  )
                                                                                    ?.nome,
                                                                                desc2:
                                                                                  "",
                                                                                quantidade: 1,
                                                                                quantidadeTexto:
                                                                                  t(
                                                                                    "quantidade"
                                                                                  ),
                                                                              }
                                                                            );
                                                                            produtosNum.push(
                                                                              {
                                                                                nomeProduto:
                                                                                  t(
                                                                                    "resumo10"
                                                                                  ),
                                                                                precoProduto:
                                                                                  compraInscricao,
                                                                                desc1:
                                                                                  piscinas.find(
                                                                                    (
                                                                                      element
                                                                                    ) =>
                                                                                      element.id ==
                                                                                      idEscolhida
                                                                                  )
                                                                                    ?.nome,
                                                                                desc2:
                                                                                  "",
                                                                                quantidade: 1,
                                                                                quantidadeTexto:
                                                                                  t(
                                                                                    "quantidade"
                                                                                  ),
                                                                              }
                                                                            );
                                                                          }
                                                                          linhasCompra(
                                                                            {
                                                                              id_cliente:
                                                                                funcionarioSelecionadoId,
                                                                              produtos:
                                                                                produtosNum,
                                                                              id_pagamento:
                                                                                id_pagamento,
                                                                            }
                                                                          ).then(
                                                                            (
                                                                              res4
                                                                            ) => {
                                                                              setTimeout(
                                                                                () => {
                                                                                  emailCompraPackMarcacao(
                                                                                    {
                                                                                      id_cliente:
                                                                                        funcionarioSelecionadoId,
                                                                                      datetime:
                                                                                        dataFormatada +
                                                                                        " " +
                                                                                        horasDisponiveis[
                                                                                          horaEscolhida
                                                                                        ]
                                                                                          .horaInicio +
                                                                                        "h - " +
                                                                                        horasDisponiveis[
                                                                                          horaEscolhida
                                                                                        ]
                                                                                          .horaFim +
                                                                                        "h",
                                                                                      pack: packEscolhido,
                                                                                      total:
                                                                                        compraTotal,
                                                                                      desconto:
                                                                                        idDesconto,
                                                                                      produtos:
                                                                                        produtos,
                                                                                      precoDesconto:
                                                                                        valorDesconto,
                                                                                      entidade:
                                                                                        res
                                                                                          .data
                                                                                          .data
                                                                                          .Entity,
                                                                                      referencia:
                                                                                        res
                                                                                          .data
                                                                                          .data
                                                                                          .Reference,
                                                                                      dataExpira:
                                                                                        res
                                                                                          .data
                                                                                          .data
                                                                                          .ExpiryDate,
                                                                                      id_filho:
                                                                                        id_filho,
                                                                                    }
                                                                                  ).then(
                                                                                    (
                                                                                      res5
                                                                                    ) => {
                                                                                      setEntidade(
                                                                                        res
                                                                                          .data
                                                                                          .data
                                                                                          .Entity
                                                                                      );
                                                                                      setReferencia(
                                                                                        res
                                                                                          .data
                                                                                          .data
                                                                                          .Reference
                                                                                      );
                                                                                      setDataExpira(
                                                                                        res
                                                                                          .data
                                                                                          .data
                                                                                          .ExpiryDate
                                                                                      );
                                                                                      setLoading(
                                                                                        false
                                                                                      );
                                                                                      setLoading2(
                                                                                        true
                                                                                      );
                                                                                    }
                                                                                  );
                                                                                },
                                                                                3000
                                                                              );
                                                                            }
                                                                          );
                                                                        },
                                                                        3000
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              },
                                                              3000
                                                            );
                                                          }
                                                        });
                                                      },
                                                      3000
                                                    );
                                                  }
                                                });
                                              }, 3000);
                                            }
                                          })
                                        : pagamentoMultibanco({
                                            id_cliente:
                                              funcionarioSelecionadoId,
                                            id_pack: 0,
                                            id_piscina: idEscolhida,
                                            id_turma: turmaEscolhida,
                                            id_filho: id_filho,
                                            language: i18n.language,
                                            id_preco: anual
                                              ? 1
                                              : mensal
                                              ? 2
                                              : 0,
                                            id_desconto: idDesconto,
                                          }).then((res) => {
                                            if (res.success) {
                                              let id_pagamento = res.data.id;
                                              setTimeout(async function () {
                                                let produtos = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      : anual.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      ? turmas
                                                          .find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )
                                                          ?.preco_anual.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                      : compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                let produtosNum = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco
                                                      : anual
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.preco_anual
                                                      : compraPreco,
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                if (compraInscricao != 0) {
                                                  produtos.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      ),
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                  produtosNum.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao,
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                }
                                                linhasCompra({
                                                  id_cliente:
                                                    funcionarioSelecionadoId,
                                                  produtos: produtosNum,
                                                  id_pagamento: id_pagamento,
                                                }).then((res4) => {
                                                  setTimeout(() => {
                                                    emailInscricaoTurma({
                                                      id_cliente:
                                                        funcionarioSelecionadoId,
                                                      turma: turmaEscolhida,
                                                      entidade:
                                                        res.data.data.Entity,
                                                      referencia:
                                                        res.data.data.Reference,
                                                      dataExpira:
                                                        res.data.data
                                                          .ExpiryDate,
                                                      total: compraTotal,
                                                      desconto: idDesconto,
                                                      produtos: produtos,
                                                      precoDesconto:
                                                        valorDesconto,
                                                      id_filho: id_filho,
                                                    }).then((res5) => {
                                                      setEntidade(
                                                        res.data.data.Entity
                                                      );
                                                      setReferencia(
                                                        res.data.data.Reference
                                                      );
                                                      setDataExpira(
                                                        res.data.data.ExpiryDate
                                                      );
                                                      setLoading(false);
                                                      setLoading2(true);
                                                    });
                                                  }, 3000);
                                                });
                                              }, 3000);
                                            }
                                          });
                                    }
                                    setLoading(false);
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("confirmar")}
                                </Button>
                              ) : null}
                              {mbway == true && loading2 == false ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setLoading2(true);
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("confirmar")}
                                </Button>
                              ) : null}
                              {balcao == true && loading2 == false ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setLoading(true);

                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    const data = new Date(selectedDay);
                                    const dia = data
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const mes = (data.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const ano = data.getFullYear();
                                    const dataFormatada = `${dia}/${mes}/${ano}`;

                                    if (!aulas) {
                                      registarMarcacaoInativa({
                                        id_pack: packEscolhido,
                                        id_piscina: idEscolhida,
                                        data: dataFormatada,
                                        horaInicio:
                                          horasDisponiveis[horaEscolhida]
                                            .horaInicio,
                                        horaFim:
                                          horasDisponiveis[horaEscolhida]
                                            .horaFim,
                                        id_filho: id_filho,
                                        id_cliente: funcionarioSelecionadoId,
                                      }).then((res2) => {
                                        if (res2.success) {
                                          setTimeout(async function () {
                                            marcacaoBalcao({
                                              id_cliente:
                                                funcionarioSelecionadoId,
                                              id_pack: packEscolhido,
                                              id_piscina: idEscolhida,
                                              id_turma: 0,
                                              id_filho: id_filho,
                                              preco: 0,
                                              id_desconto: idDesconto,
                                            }).then((res) => {
                                              if (res.success) {
                                                setTimeout(async function () {
                                                  let id_marcacao =
                                                    res2.data.id;
                                                  let id_pagamento =
                                                    res.data.id;
                                                  associarPagamentosMarcacoes({
                                                    id_marcacao: id_marcacao,
                                                    id_pagamento: id_pagamento,
                                                  }).then((res3) => {
                                                    setTimeout(
                                                      async function () {
                                                        let produtos = [
                                                          {
                                                            nomeProduto: !aulas
                                                              ? packs.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    packEscolhido
                                                                )?.nome +
                                                                ` (${
                                                                  packs.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      packEscolhido
                                                                  )?.num_tickets
                                                                } ${t(
                                                                  "tickets"
                                                                )})`
                                                              : t("resumo10") +
                                                                " - " +
                                                                turmas.find(
                                                                  (element) =>
                                                                    element.id_turma ==
                                                                    turmaEscolhida
                                                                )?.nome,
                                                            precoProduto: !aulas
                                                              ? compraPreco.toLocaleString(
                                                                  "pt-PT",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      "EUR",
                                                                  }
                                                                )
                                                              : anual.toLocaleString(
                                                                  "pt-PT",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      "EUR",
                                                                  }
                                                                )
                                                              ? turmas
                                                                  .find(
                                                                    (element) =>
                                                                      element.id_turma ==
                                                                      turmaEscolhida
                                                                  )
                                                                  ?.preco_anual.toLocaleString(
                                                                    "pt-PT",
                                                                    {
                                                                      style:
                                                                        "currency",
                                                                      currency:
                                                                        "EUR",
                                                                    }
                                                                  )
                                                              : compraPreco.toLocaleString(
                                                                  "pt-PT",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      "EUR",
                                                                  }
                                                                ),
                                                            desc1: aulas
                                                              ? piscinas.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    idEscolhida
                                                                )?.nome +
                                                                " - " +
                                                                anual
                                                                ? t(
                                                                    "pagamentoAnual"
                                                                  )
                                                                : t(
                                                                    "pagamentoMensal"
                                                                  )
                                                              : t(
                                                                  "utilizacao1Ticket"
                                                                ) +
                                                                " " +
                                                                selectedDay.toLocaleDateString(
                                                                  "pt-BR"
                                                                ) +
                                                                " " +
                                                                horasDisponiveis[
                                                                  horaEscolhida
                                                                ].horaInicio +
                                                                "h - " +
                                                                horasDisponiveis[
                                                                  horaEscolhida
                                                                ].horaFim +
                                                                "h",
                                                            desc2: aulas
                                                              ? turmas.find(
                                                                  (element) =>
                                                                    element.id_turma ==
                                                                    turmaEscolhida
                                                                )?.obs
                                                              : piscinas.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    idEscolhida
                                                                )?.nome,
                                                            quantidade: 1,
                                                            quantidadeTexto:
                                                              t("quantidade"),
                                                          },
                                                        ];

                                                        let produtosNum = [
                                                          {
                                                            nomeProduto: !aulas
                                                              ? packs.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    packEscolhido
                                                                )?.nome +
                                                                ` (${
                                                                  packs.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      packEscolhido
                                                                  )?.num_tickets
                                                                } ${t(
                                                                  "tickets"
                                                                )})`
                                                              : t("resumo10") +
                                                                " - " +
                                                                turmas.find(
                                                                  (element) =>
                                                                    element.id_turma ==
                                                                    turmaEscolhida
                                                                )?.nome,
                                                            precoProduto: !aulas
                                                              ? compraPreco
                                                              : anual
                                                              ? turmas.find(
                                                                  (element) =>
                                                                    element.id_turma ==
                                                                    turmaEscolhida
                                                                )?.preco_anual
                                                              : compraPreco,
                                                            desc1: aulas
                                                              ? piscinas.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    idEscolhida
                                                                )?.nome +
                                                                " - " +
                                                                anual
                                                                ? t(
                                                                    "pagamentoAnual"
                                                                  )
                                                                : t(
                                                                    "pagamentoMensal"
                                                                  )
                                                              : t(
                                                                  "utilizacao1Ticket"
                                                                ) +
                                                                " " +
                                                                selectedDay.toLocaleDateString(
                                                                  "pt-BR"
                                                                ) +
                                                                " " +
                                                                horasDisponiveis[
                                                                  horaEscolhida
                                                                ].horaInicio +
                                                                "h - " +
                                                                horasDisponiveis[
                                                                  horaEscolhida
                                                                ].horaFim +
                                                                "h",
                                                            desc2: aulas
                                                              ? turmas.find(
                                                                  (element) =>
                                                                    element.id_turma ==
                                                                    turmaEscolhida
                                                                )?.obs
                                                              : piscinas.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    idEscolhida
                                                                )?.nome,
                                                            quantidade: 1,
                                                            quantidadeTexto:
                                                              t("quantidade"),
                                                          },
                                                        ];

                                                        if (
                                                          compraInscricao != 0
                                                        ) {
                                                          produtos.push({
                                                            nomeProduto:
                                                              t("resumo10"),
                                                            precoProduto:
                                                              compraInscricao.toLocaleString(
                                                                "pt-PT",
                                                                {
                                                                  style:
                                                                    "currency",
                                                                  currency:
                                                                    "EUR",
                                                                }
                                                              ),
                                                            desc1:
                                                              piscinas.find(
                                                                (element) =>
                                                                  element.id ==
                                                                  idEscolhida
                                                              )?.nome,
                                                            desc2: "",
                                                            quantidade: 1,
                                                            quantidadeTexto:
                                                              t("quantidade"),
                                                          });
                                                          produtosNum.push({
                                                            nomeProduto:
                                                              t("resumo10"),
                                                            precoProduto:
                                                              compraInscricao,
                                                            desc1:
                                                              piscinas.find(
                                                                (element) =>
                                                                  element.id ==
                                                                  idEscolhida
                                                              )?.nome,
                                                            desc2: "",
                                                            quantidade: 1,
                                                            quantidadeTexto:
                                                              t("quantidade"),
                                                          });
                                                        }
                                                        linhasCompra({
                                                          id_cliente:
                                                            funcionarioSelecionadoId,
                                                          produtos: produtosNum,
                                                          id_pagamento:
                                                            id_pagamento,
                                                        }).then((res4) => {
                                                          setTimeout(() => {
                                                            emailCompraPackMarcacao(
                                                              {
                                                                id_cliente:
                                                                  funcionarioSelecionadoId,
                                                                dataExpira: `${new Date(
                                                                  new Date().setDate(
                                                                    new Date().getDate() +
                                                                      1
                                                                  )
                                                                )
                                                                  .getDate()
                                                                  .toString()
                                                                  .padStart(
                                                                    2,
                                                                    "0"
                                                                  )}-${(
                                                                  new Date(
                                                                    new Date().setDate(
                                                                      new Date().getDate() +
                                                                        1
                                                                    )
                                                                  ).getMonth() +
                                                                  1
                                                                )
                                                                  .toString()
                                                                  .padStart(
                                                                    2,
                                                                    "0"
                                                                  )}-${new Date(
                                                                  new Date().setDate(
                                                                    new Date().getDate() +
                                                                      1
                                                                  )
                                                                ).getFullYear()}`,
                                                                datetime:
                                                                  dataFormatada +
                                                                  " " +
                                                                  horasDisponiveis[
                                                                    horaEscolhida
                                                                  ].horaInicio +
                                                                  "h - " +
                                                                  horasDisponiveis[
                                                                    horaEscolhida
                                                                  ].horaFim +
                                                                  "h",
                                                                pack: packEscolhido,
                                                                total:
                                                                  compraTotal,
                                                                desconto:
                                                                  idDesconto,
                                                                produtos:
                                                                  produtos,
                                                                precoDesconto:
                                                                  valorDesconto,
                                                                id_filho:
                                                                  id_filho,
                                                              }
                                                            ).then((res5) => {
                                                              setPopupBalcao(
                                                                false
                                                              );
                                                              setLoading(false);
                                                              setLoading2(true);
                                                            });
                                                          }, 3000);
                                                        });
                                                      },
                                                      3000
                                                    );
                                                  });
                                                }, 3000);
                                              }
                                            });
                                          }, 3000);
                                        }
                                      });
                                    } else {
                                      setLoading(true);
                                      setTimeout(async function () {
                                        marcacaoBalcao({
                                          id_cliente: funcionarioSelecionadoId,
                                          id_pack: packEscolhido,
                                          id_piscina: idEscolhida,
                                          id_turma: turmaEscolhida,
                                          id_filho: id_filho,
                                          id_preco: anual ? 1 : mensal ? 2 : 0,
                                          id_desconto: idDesconto,
                                        }).then((res) => {
                                          let id_pagamento = res.data.id;
                                          setTimeout(async function () {
                                            let produtos = [
                                              {
                                                nomeProduto: !aulas
                                                  ? packs.find(
                                                      (element) =>
                                                        element.id ==
                                                        packEscolhido
                                                    )?.nome +
                                                    ` (${
                                                      packs.find(
                                                        (element) =>
                                                          element.id ==
                                                          packEscolhido
                                                      )?.num_tickets
                                                    } ${t("tickets")})`
                                                  : t("resumo10") +
                                                    " - " +
                                                    turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.nome,
                                                precoProduto: !aulas
                                                  ? compraPreco.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )
                                                  : anual.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )
                                                  ? turmas
                                                      .find(
                                                        (element) =>
                                                          element.id_turma ==
                                                          turmaEscolhida
                                                      )
                                                      ?.preco_anual.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )
                                                  : compraPreco.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    ),
                                                desc1: aulas
                                                  ? piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome +
                                                    " - " +
                                                    anual
                                                    ? t("pagamentoAnual")
                                                    : t("pagamentoMensal")
                                                  : t("utilizacao1Ticket") +
                                                    " " +
                                                    selectedDay.toLocaleDateString(
                                                      "pt-BR"
                                                    ) +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                desc2: aulas
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.obs
                                                  : piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              },
                                            ];

                                            let produtosNum = [
                                              {
                                                nomeProduto: !aulas
                                                  ? packs.find(
                                                      (element) =>
                                                        element.id ==
                                                        packEscolhido
                                                    )?.nome +
                                                    ` (${
                                                      packs.find(
                                                        (element) =>
                                                          element.id ==
                                                          packEscolhido
                                                      )?.num_tickets
                                                    } ${t("tickets")})`
                                                  : t("resumo10") +
                                                    " - " +
                                                    turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.nome,
                                                precoProduto: !aulas
                                                  ? compraPreco
                                                  : anual
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.preco_anual
                                                  : compraPreco,
                                                desc1: aulas
                                                  ? piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome +
                                                    " - " +
                                                    anual
                                                    ? t("pagamentoAnual")
                                                    : t("pagamentoMensal")
                                                  : t("utilizacao1Ticket") +
                                                    " " +
                                                    selectedDay.toLocaleDateString(
                                                      "pt-BR"
                                                    ) +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                desc2: aulas
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.obs
                                                  : piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              },
                                            ];

                                            if (compraInscricao != 0) {
                                              produtos.push({
                                                nomeProduto: t("resumo10"),
                                                precoProduto:
                                                  compraInscricao.toLocaleString(
                                                    "pt-PT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  ),
                                                desc1: piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                                desc2: "",
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              });
                                              produtosNum.push({
                                                nomeProduto: t("resumo10"),
                                                precoProduto: compraInscricao,
                                                desc1: piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                                desc2: "",
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              });
                                            }
                                            linhasCompra({
                                              id_cliente:
                                                funcionarioSelecionadoId,
                                              produtos: produtosNum,
                                              id_pagamento: id_pagamento,
                                            }).then((res4) => {
                                              setTimeout(() => {
                                                emailInscricaoTurma({
                                                  id_cliente:
                                                    funcionarioSelecionadoId,
                                                  turma: turmaEscolhida,
                                                  dataExpira: `${new Date(
                                                    new Date().setDate(
                                                      new Date().getDate() + 1
                                                    )
                                                  )
                                                    .getDate()
                                                    .toString()
                                                    .padStart(2, "0")}-${(
                                                    new Date(
                                                      new Date().setDate(
                                                        new Date().getDate() + 1
                                                      )
                                                    ).getMonth() + 1
                                                  )
                                                    .toString()
                                                    .padStart(
                                                      2,
                                                      "0"
                                                    )}-${new Date(
                                                    new Date().setDate(
                                                      new Date().getDate() + 1
                                                    )
                                                  ).getFullYear()}`,
                                                  total: compraTotal,
                                                  desconto: idDesconto,
                                                  produtos: produtos,
                                                  precoDesconto: valorDesconto,
                                                  id_filho: id_filho,
                                                }).then((res5) => {
                                                  setPopupBalcao(false);
                                                  setLoading(false);
                                                  setLoading2(true);
                                                });
                                              }, 3000);
                                            });
                                          }, 3000);
                                        });
                                      }, 3000);
                                      setLoading(false);
                                    }
                                    setLoading(false);
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("confirmar")}
                                </Button>
                              ) : null}

                              {loading2 == true && balcao == true ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setOpenPagamentoAulas(false);
                                    setCartaoCredito(false);
                                    setTelefone("");
                                    setTurmaEscolhida("");
                                    setIdUtilizadorEscolhido("");
                                    setMbway(false);
                                    setMultibanco(false);
                                    setLoading2(false);
                                    setAnual(false);
                                    setAulas(false);
                                    setBalcao(false);
                                    setHoraEscolhida(0);
                                    setHorasDisponiveis([]);
                                    setTenhoPack(false);

                                    setActiveStepPagarAulas(0);
                                    setPagamentoCancelado(false);
                                    setActiveStep(3);

                                    handleCloseCancelarPagamento();
                                    handleClosePagamentoAulas();
                                    navigate("/dashboard_admin");
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("terminar")}
                                </Button>
                              ) : null}

                              {loading2 == true && multibanco == true ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setOpenPagamentoAulas(false);
                                    setCartaoCredito(false);
                                    setTelefone("");
                                    setTurmaEscolhida("");
                                    setIdUtilizadorEscolhido("");
                                    setMbway(false);
                                    setMultibanco(false);
                                    setLoading2(false);
                                    setAnual(false);
                                    setAulas(false);
                                    setBalcao(false);
                                    setHoraEscolhida(0);
                                    setHorasDisponiveis([]);
                                    setTenhoPack(false);

                                    setActiveStepPagarAulas(0);
                                    setPagamentoCancelado(false);
                                    setActiveStep(3);
                                    navigate("/dashboard_admin");
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("terminar")}
                                </Button>
                              ) : null}
                              {mbway == true && loading2 == true ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    if (telefone && !erroTelefone) {
                                      setPagar(true);
                                      setMbwayAwait(true);
                                      let utilizadorEscolhido = filho
                                        ? users.find(
                                            (element) =>
                                              element.filho == 1 &&
                                              element.id ==
                                                idUtilizadorEscolhido
                                          )
                                        : users.find(
                                            (element) =>
                                              element.filho == 0 &&
                                              element.id ==
                                                idUtilizadorEscolhido
                                          );
                                      let id_filho = utilizadorEscolhido.filho
                                        ? utilizadorEscolhido.id
                                        : 0;
                                      const data = new Date(selectedDay);
                                      const dia = data
                                        .getDate()
                                        .toString()
                                        .padStart(2, "0");
                                      const mes = (data.getMonth() + 1)
                                        .toString()
                                        .padStart(2, "0");
                                      const ano = data.getFullYear();
                                      const dataFormatada = `${dia}/${mes}/${ano}`;
                                      if (!aulas) {
                                        registarMarcacaoInativa({
                                          id_pack: packEscolhido,
                                          id_piscina: idEscolhida,
                                          data: dataFormatada,
                                          horaInicio:
                                            horasDisponiveis[horaEscolhida]
                                              .horaInicio,
                                          horaFim:
                                            horasDisponiveis[horaEscolhida]
                                              .horaFim,
                                          id_filho: id_filho,
                                          id_cliente: funcionarioSelecionadoId,
                                        }).then((res2) => {
                                          if (res2.success) {
                                            setTimeout(async function () {
                                              pagamentoMbway({
                                                id_pack: packEscolhido,
                                                id_piscina: idEscolhida,
                                                id_turma: 0,
                                                id_filho: id_filho,
                                                preco: 0,
                                                telemovel: telefone,
                                                id_desconto: idDesconto,
                                                id_cliente:
                                                  funcionarioSelecionadoId,
                                              }).then((res) => {
                                                if (res.success) {
                                                  setTimeout(async function () {
                                                    let id_marcacao =
                                                      res2.data.id;
                                                    let id_pagamento =
                                                      res.data.id;
                                                    associarPagamentosMarcacoes(
                                                      {
                                                        id_marcacao:
                                                          id_marcacao,
                                                        id_pagamento:
                                                          id_pagamento,
                                                      }
                                                    ).then((res3) => {
                                                      if (res3.success) {
                                                        setTimeout(
                                                          async function () {
                                                            let produtos = [
                                                              {
                                                                nomeProduto:
                                                                  !aulas
                                                                    ? packs.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id ==
                                                                          packEscolhido
                                                                      )?.nome +
                                                                      ` (${
                                                                        packs.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id ==
                                                                            packEscolhido
                                                                        )
                                                                          ?.num_tickets
                                                                      } ${t(
                                                                        "tickets"
                                                                      )})`
                                                                    : t(
                                                                        "resumo10"
                                                                      ) +
                                                                      " - " +
                                                                      turmas.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id_turma ==
                                                                          turmaEscolhida
                                                                      )?.nome,
                                                                precoProduto:
                                                                  !aulas
                                                                    ? compraPreco.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                    : anual.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                    ? turmas
                                                                        .find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id_turma ==
                                                                            turmaEscolhida
                                                                        )
                                                                        ?.preco_anual.toLocaleString(
                                                                          "pt-PT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "EUR",
                                                                          }
                                                                        )
                                                                    : compraPreco.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      ),
                                                                desc1: aulas
                                                                  ? piscinas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id ==
                                                                        idEscolhida
                                                                    )?.nome +
                                                                    " - " +
                                                                    anual
                                                                    ? t(
                                                                        "pagamentoAnual"
                                                                      )
                                                                    : t(
                                                                        "pagamentoMensal"
                                                                      )
                                                                  : t(
                                                                      "utilizacao1Ticket"
                                                                    ) +
                                                                    " " +
                                                                    selectedDay.toLocaleDateString(
                                                                      "pt-BR"
                                                                    ) +
                                                                    " " +
                                                                    horasDisponiveis[
                                                                      horaEscolhida
                                                                    ]
                                                                      .horaInicio +
                                                                    "h - " +
                                                                    horasDisponiveis[
                                                                      horaEscolhida
                                                                    ].horaFim +
                                                                    "h",
                                                                desc2: aulas
                                                                  ? turmas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_turma ==
                                                                        turmaEscolhida
                                                                    )?.obs
                                                                  : piscinas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id ==
                                                                        idEscolhida
                                                                    )?.nome,
                                                                quantidade: 1,
                                                                quantidadeTexto:
                                                                  t(
                                                                    "quantidade"
                                                                  ),
                                                              },
                                                            ];

                                                            let produtosNum = [
                                                              {
                                                                nomeProduto:
                                                                  !aulas
                                                                    ? packs.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id ==
                                                                          packEscolhido
                                                                      )?.nome +
                                                                      ` (${
                                                                        packs.find(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            element.id ==
                                                                            packEscolhido
                                                                        )
                                                                          ?.num_tickets
                                                                      } ${t(
                                                                        "tickets"
                                                                      )})`
                                                                    : t(
                                                                        "resumo10"
                                                                      ) +
                                                                      " - " +
                                                                      turmas.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id_turma ==
                                                                          turmaEscolhida
                                                                      )?.nome,
                                                                precoProduto:
                                                                  !aulas
                                                                    ? compraPreco
                                                                    : anual
                                                                    ? turmas.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id_turma ==
                                                                          turmaEscolhida
                                                                      )
                                                                        ?.preco_anual
                                                                    : compraPreco,
                                                                desc1: aulas
                                                                  ? piscinas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id ==
                                                                        idEscolhida
                                                                    )?.nome +
                                                                    " - " +
                                                                    anual
                                                                    ? t(
                                                                        "pagamentoAnual"
                                                                      )
                                                                    : t(
                                                                        "pagamentoMensal"
                                                                      )
                                                                  : t(
                                                                      "utilizacao1Ticket"
                                                                    ) +
                                                                    " " +
                                                                    selectedDay.toLocaleDateString(
                                                                      "pt-BR"
                                                                    ) +
                                                                    " " +
                                                                    horasDisponiveis[
                                                                      horaEscolhida
                                                                    ]
                                                                      .horaInicio +
                                                                    "h - " +
                                                                    horasDisponiveis[
                                                                      horaEscolhida
                                                                    ].horaFim +
                                                                    "h",
                                                                desc2: aulas
                                                                  ? turmas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_turma ==
                                                                        turmaEscolhida
                                                                    )?.obs
                                                                  : piscinas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id ==
                                                                        idEscolhida
                                                                    )?.nome,
                                                                quantidade: 1,
                                                                quantidadeTexto:
                                                                  t(
                                                                    "quantidade"
                                                                  ),
                                                              },
                                                            ];

                                                            if (
                                                              compraInscricao !=
                                                              0
                                                            ) {
                                                              produtos.push({
                                                                nomeProduto:
                                                                  t("resumo10"),
                                                                precoProduto:
                                                                  compraInscricao.toLocaleString(
                                                                    "pt-PT",
                                                                    {
                                                                      style:
                                                                        "currency",
                                                                      currency:
                                                                        "EUR",
                                                                    }
                                                                  ),
                                                                desc1:
                                                                  piscinas.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      idEscolhida
                                                                  )?.nome,
                                                                desc2: "",
                                                                quantidade: 1,
                                                                quantidadeTexto:
                                                                  t(
                                                                    "quantidade"
                                                                  ),
                                                              });
                                                              produtosNum.push({
                                                                nomeProduto:
                                                                  t("resumo10"),
                                                                precoProduto:
                                                                  compraInscricao,
                                                                desc1:
                                                                  piscinas.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      idEscolhida
                                                                  )?.nome,
                                                                desc2: "",
                                                                quantidade: 1,
                                                                quantidadeTexto:
                                                                  t(
                                                                    "quantidade"
                                                                  ),
                                                              });
                                                            }
                                                            linhasCompra({
                                                              id_cliente:
                                                                funcionarioSelecionadoId,
                                                              produtos:
                                                                produtosNum,
                                                              id_pagamento:
                                                                id_pagamento,
                                                            }).then((res4) => {
                                                              setTimeout(() => {
                                                                emailCompraPackMarcacao(
                                                                  {
                                                                    id_cliente:
                                                                      funcionarioSelecionadoId,
                                                                    telemovel:
                                                                      telefone,
                                                                    datetime:
                                                                      dataFormatada +
                                                                      " " +
                                                                      horasDisponiveis[
                                                                        horaEscolhida
                                                                      ]
                                                                        .horaInicio +
                                                                      "h - " +
                                                                      horasDisponiveis[
                                                                        horaEscolhida
                                                                      ]
                                                                        .horaFim +
                                                                      "h",
                                                                    pack: packEscolhido,
                                                                    total:
                                                                      compraTotal,
                                                                    desconto:
                                                                      idDesconto,
                                                                    produtos:
                                                                      produtos,
                                                                    precoDesconto:
                                                                      valorDesconto,
                                                                    id_filho:
                                                                      id_filho,
                                                                  }
                                                                ).then(
                                                                  (res5) => {
                                                                    setMbwayAwait(
                                                                      false
                                                                    );
                                                                  }
                                                                );
                                                              }, 3000);
                                                            });
                                                          },
                                                          3000
                                                        );
                                                      }
                                                    });
                                                  }, 3000);
                                                } else {
                                                  toast.error(
                                                    t("telefoneinvalido")
                                                  );
                                                }
                                              });
                                            }, 3000);
                                          }
                                        });
                                      } else {
                                        setPagar(true);
                                        setMbwayAwait(true);
                                        setTimeout(async function () {
                                          pagamentoMbway({
                                            id_cliente:
                                              funcionarioSelecionadoId,
                                            id_pack: 0,
                                            id_piscina: idEscolhida,
                                            id_turma: turmaEscolhida,
                                            id_filho: id_filho,
                                            telemovel: telefone,
                                            id_desconto: idDesconto,
                                            id_preco: anual
                                              ? 1
                                              : mensal
                                              ? 2
                                              : 0,
                                          }).then((res) => {
                                            if (res.success) {
                                              let id_pagamento = res.data.id;
                                              setTimeout(async function () {
                                                let produtos = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      : anual.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      ? turmas
                                                          .find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )
                                                          ?.preco_anual.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                      : compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                let produtosNum = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco
                                                      : anual
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.preco_anual
                                                      : compraPreco,
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                if (compraInscricao != 0) {
                                                  produtos.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      ),
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                  produtosNum.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao,
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                }
                                                linhasCompra({
                                                  id_cliente:
                                                    funcionarioSelecionadoId,
                                                  produtos: produtosNum,
                                                  id_pagamento: id_pagamento,
                                                }).then((res4) => {
                                                  setTimeout(() => {
                                                    emailInscricaoTurma({
                                                      id_cliente:
                                                        funcionarioSelecionadoId,
                                                      turma: turmaEscolhida,
                                                      telemovel: telefone,
                                                      total: compraTotal,
                                                      desconto: idDesconto,
                                                      produtos: produtos,
                                                      precoDesconto:
                                                        valorDesconto,
                                                      id_filho: id_filho,
                                                    }).then((res5) => {
                                                      setMbwayAwait(false);
                                                    });
                                                  }, 3000);
                                                });
                                              }, 3000);
                                            } else {
                                              toast.error(
                                                t("telefoneinvalido")
                                              );
                                            }
                                          });
                                        }, 3000);
                                      }
                                    } else {
                                      setErroTelefone(true);
                                      toast.error(t("telefoneinvalido"));
                                    }
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("pagar")}
                                </Button>
                              ) : null}

                              {tenhopack == true ? (
                                <Button
                                  className="btnSim"
                                  variant="contained"
                                  style={{
                                    backgroundImage: `url(${simbtn})`,
                                    width: 218,
                                    height: 40,
                                    cursor: "pointer",
                                    zIndex: 200,
                                  }}
                                  onClick={() => {
                                    setLoading(true);
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    const data = new Date(selectedDay);
                                    const dia = data
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const mes = (data.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const ano = data.getFullYear();
                                    const dataFormatada = `${dia}/${mes}/${ano}`;
                                    registarMarcacao({
                                      id_pack: packUtilizadorEscolhido,
                                      id_piscina: idEscolhida,
                                      data: dataFormatada,
                                      horaInicio:
                                        horasDisponiveis[horaEscolhida]
                                          .horaInicio,
                                      horaFim:
                                        horasDisponiveis[horaEscolhida].horaFim,
                                      id_filho: id_filho,
                                      id_cliente: funcionarioSelecionadoId,
                                    }).then((res) => {
                                      if (res.success) {
                                        setTimeout(async function () {
                                          emailMarcacao({
                                            id_cliente:
                                              funcionarioSelecionadoId,
                                            pack: packUtilizadorEscolhido,
                                            datetime:
                                              dataFormatada +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          }).then((res) => {
                                            setMarcacaoConcluida(true);
                                            setMarcacaoConcluida2(true);
                                            setTimeout(() => {
                                              setOpenPagamentoAulas(false);
                                              setCartaoCredito(false);
                                              setTelefone("");
                                              setTurmaEscolhida("");
                                              setIdUtilizadorEscolhido("");
                                              setTipoEscolhido(false);
                                              setMbway(false);
                                              setMultibanco(false);
                                              setLoading2(false);
                                              setAnual(false);
                                              setAulas(false);
                                              setBalcao(false);
                                              setActiveStepPagarAulas(0);
                                              setActiveStep(3);
                                              setTenhoPack(false);
                                              setSelectedDay("");
                                              setHoraEscolhida(0);
                                              setHorasDisponiveis([]);
                                              setPagamentoCancelado(false);
                                              handleCloseCancelarPagamento();
                                              handleClosePagamentoAulas();
                                              setLoading(false);
                                            }, 3000);
                                          });
                                        }, 3000);
                                      } else {
                                        setMarcacaoConcluida2(false);
                                        setTimeout(() => {
                                          setOpenPagamentoAulas(false);
                                          setCartaoCredito(false);
                                          setTelefone("");
                                          setTurmaEscolhida("");
                                          setIdUtilizadorEscolhido("");
                                          setTipoEscolhido(false);
                                          setMbway(false);
                                          setMultibanco(false);
                                          setLoading2(false);
                                          setAnual(false);
                                          setAulas(false);
                                          setBalcao(false);
                                          setActiveStepPagarAulas(0);
                                          setActiveStep(3);
                                          setTenhoPack(false);
                                          setSelectedDay("");
                                          setHoraEscolhida(0);
                                          setHorasDisponiveis([]);
                                          setPagamentoCancelado(true);
                                          handleCloseCancelarPagamento();
                                          handleClosePagamentoAulas();
                                          setLoading(false);
                                        }, 3000);
                                      }
                                    });
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {t("marcar")}
                                </Button>
                              ) : null}
                            </DialogActions>
                          ) : null}
                        </Dialog>
                      </Box>
                    )}

                    <Dialog
                      open={cancelarPagamento}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          width: 452,
                          height: 220,
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("cancelarPagamento")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {t("textoCancelarPagamento")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundImage: `url(${simbtn})`,
                            width: 218,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            if (loading2 == false) {
                              setOpenPagamentoAulas(false);
                              setCartaoCredito(false);
                              setTelefone("");
                              setTurmaEscolhida("");
                              setIdUtilizadorEscolhido("");
                              setMbway(false);
                              setMultibanco(false);
                              setLoading2(false);
                              setAnual(false);
                              setAulas(false);
                              setBalcao(false);
                              setTipoEscolhido(false);
                              setSelectedDay("");
                              setPackEscolhido("");
                              setHoraEscolhida(0);
                              setHorasDisponiveis([]);
                              setPackUtilizadorEscolhido("");
                              setTenhoPack(false);
                              setActiveStepPagarAulas(0);
                              setActiveStep(3);
                              setPagamentoCancelado(true);
                              handleCloseCancelarPagamento();
                              handleClosePagamentoAulas();
                            } else {
                              toast.error(t("pagamentoProcessar"));
                            }
                          }}
                        >
                          {t("sim")}
                        </Button>
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundImage: `url(${naobtn})`,
                            width: 198,
                            height: 30,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            handleCloseCancelarPagamento();
                          }}
                        >
                          {t("nao")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              ) : null}
              {/* Lista pagamentos */}
              {PagamentosCliente ? (
                <>
                  <div className="divForm">
                    <div className="formPage2">
                      {janelaPendente && (
                        <Dialog
                          open={janelaPendente}
                          onClose={() => {
                            setJanelaPendente(false);
                          }}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogTitle
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "darkorange",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {t("pagamentoPendente")}
                          </DialogTitle>

                          <DialogContent
                            style={{ backgroundColor: "rgb(245, 245, 245)" }}
                          >
                            {freeze && (
                              <div
                                style={{
                                  backgroundColor: "transparent",
                                  position: "absolute",
                                  width: window.innerWidth,
                                  height: window.innerHeight,
                                  zIndex: 200,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: 0,
                                }}
                              >
                                <Dialog
                                  open={true}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <DialogContent>
                                    <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      {t("aguarde")}
                                    </Typography>
                                    <CircularProgress
                                      disableShrink
                                      style={{ marginTop: 20 }}
                                    />
                                  </DialogContent>
                                </Dialog>
                              </div>
                            )}
                            <table
                              id="u_body"
                              style={{
                                borderCollapse: "collapse",
                                tableLayout: "fixed",
                                borderSpacing: 0,
                                msoTableLspace: "0pt",
                                msoTableRspace: "0pt",
                                verticalAlign: "top",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                              cellPadding={0}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr style={{ verticalAlign: "top" }}>
                                  <td
                                    style={{
                                      wordBreak: "break-word",
                                      borderCollapse: "collapse !important",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      className="u-row-container"
                                      style={{
                                        padding: 0,
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <div
                                        className="u-row"
                                        style={{
                                          margin: "0 auto",
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderCollapse: "collapse",
                                            display: "table",

                                            height: "100%",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <div
                                            className="u-col u-col-100"
                                            style={{
                                              display: "table-cell",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor:
                                                  "rgb(245, 245, 245)",
                                                height: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  boxSizing: "border-box",
                                                  height: "100%",
                                                  padding: 0,
                                                  borderTop:
                                                    "0px solid transparent",
                                                  borderLeft:
                                                    "0px solid transparent",
                                                  borderRight:
                                                    "0px solid transparent",
                                                  borderBottom:
                                                    "0px solid transparent",
                                                }}
                                              >
                                                <table
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: isMobile
                                                            ? 10
                                                            : 40,
                                                        }}
                                                        align="left"
                                                      >
                                                        <div
                                                          style={{
                                                            color: "#161a39",
                                                            lineHeight: "150%",
                                                            textAlign: "center",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                "#f5f5f5",
                                                              borderRadius: 10,
                                                              overflow:
                                                                "hidden",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <h2
                                                              style={{
                                                                color:
                                                                  "#2d2d2d",
                                                                fontSize:
                                                                  isMobile
                                                                    ? 16
                                                                    : 20,
                                                                marginBottom: 20,
                                                              }}
                                                            >
                                                              {t(
                                                                "detalhesCompra"
                                                              )}
                                                            </h2>
                                                            {dadosPendente.linhas.map(
                                                              (linha) => {
                                                                return (
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#fff",
                                                                      padding: 20,
                                                                      borderRadius: 10,
                                                                      marginBottom: 20,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}
                                                                    >
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 14
                                                                              : 18,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "left",
                                                                        }}
                                                                      >
                                                                        {
                                                                          linha.nomeProduto
                                                                        }
                                                                      </h3>
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 14
                                                                              : 18,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          color:
                                                                            "#36B6FF",
                                                                          fontWeight: 600,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "right",
                                                                        }}
                                                                      >
                                                                        {linha.precoProduto.toLocaleString(
                                                                          "pt-PT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "EUR",
                                                                          }
                                                                        )}
                                                                      </h3>
                                                                    </div>
                                                                    <hr
                                                                      style={{
                                                                        border:
                                                                          "none",
                                                                        borderTop:
                                                                          "1px solid #d4d4d4",
                                                                        margin:
                                                                          "20px 0",
                                                                      }}
                                                                    />
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 11
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc1
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 11
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc2
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 11
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "quantidade"
                                                                      )}
                                                                      : 1
                                                                    </p>
                                                                  </div>
                                                                );
                                                              }
                                                            )}

                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#fff",
                                                                padding: 20,
                                                                borderRadius: 10,
                                                                marginTop: 20,
                                                              }}
                                                            >
                                                              {dadosPendente.desconto !=
                                                                0 && (
                                                                <>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                    }}
                                                                  >
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 18,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "desconto"
                                                                      )}
                                                                      :{" "}
                                                                      {
                                                                        dadosPendente.desconto
                                                                      }
                                                                      %
                                                                    </h3>
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 18,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        color:
                                                                          "#36B6FF",
                                                                        fontWeight: 600,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "right",
                                                                      }}
                                                                    >
                                                                      -{" "}
                                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )}
                                                                    </h3>
                                                                  </div>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 12
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    {
                                                                      dadosPendente.desc_desconto
                                                                    }
                                                                  </p>
                                                                </>
                                                              )}

                                                              <hr
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  borderTop:
                                                                    "1px solid #d4d4d4",
                                                                  margin:
                                                                    "20px 0",
                                                                }}
                                                              />
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#fff",
                                                                  padding: 20,
                                                                  borderRadius: 10,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 18,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {t(
                                                                        "total"
                                                                      )}
                                                                      :
                                                                    </strong>
                                                                  </h3>
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 18,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      fontWeight: 600,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {dadosPendente.total.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    )}
                                                                  </h3>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 18,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,

                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "moradaFaturacao"
                                                            )}
                                                          </h2>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 1
                                                                : 18,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              marginTop: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nome}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.morada
                                                            }
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nif}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,
                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.codigo_postal
                                                            }
                                                          </p>
                                                          {/* Caso sejam os dados do filho não aparece email */}
                                                          {dadosPendente.email && (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  isMobile
                                                                    ? 12
                                                                    : 14,
                                                                marginBottom: 10,
                                                                textAlign:
                                                                  "start",
                                                              }}
                                                            >
                                                              {
                                                                dadosPendente.email
                                                              }
                                                            </p>
                                                          )}
                                                        </div>

                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "metodoPagamento"
                                                            )}
                                                          </h2>
                                                          {dadosPendente.id_metodo_pagamento ==
                                                            2 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  mbwayfotoImg
                                                                }
                                                                alt="MB Way"
                                                                style={{
                                                                  width: 120,
                                                                  marginBottom: 20,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            3 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  cartaocreditoImg
                                                                }
                                                                alt="Cartão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize: 14,
                                                                  marginBottom: 20,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            1 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={mbImg}
                                                                alt="Multibanco"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            4 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={balcaoImg}
                                                                alt="Balcão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <table
                                                  id="u_content_button_1"
                                                  style={{
                                                    fontFamily:
                                                      '"Lato",sans-serif',
                                                  }}
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  width="100%"
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: 10,
                                                          fontFamily:
                                                            '"Lato",sans-serif',
                                                        }}
                                                        align="left"
                                                      >
                                                        <div align="center">
                                                          <a
                                                            target="_blank"
                                                            className="v-button"
                                                            style={{
                                                              boxSizing:
                                                                "border-box",
                                                              display:
                                                                "inline-block",
                                                              fontFamily:
                                                                '"Lato",sans-serif',
                                                              textDecoration:
                                                                "none",
                                                              WebkitTextSizeAdjust:
                                                                "none",
                                                              textAlign:
                                                                "center",
                                                              color: "#FFFFFF",
                                                              backgroundColor:
                                                                Colors.inativo,
                                                              borderRadius: 40,
                                                              WebkitBorderRadius: 40,
                                                              MozBorderRadius: 40,
                                                              width: "auto",
                                                              maxWidth: "100%",
                                                              overflowWrap:
                                                                "break-word",
                                                              wordBreak:
                                                                "break-word",
                                                              wordWrap:
                                                                "break-word",
                                                              msoBorderAlt:
                                                                "none",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              setFreeze(true);
                                                              // Cancelar pagamento pendente
                                                              cancelarPagamentoPendente(
                                                                {
                                                                  id_pagamento:
                                                                    dadosPendente.id_pagamento,
                                                                  id_cliente:
                                                                    funcionarioSelecionadoId,
                                                                }
                                                              ).then((res) => {
                                                                if (
                                                                  res.success
                                                                ) {
                                                                  setTimeout(
                                                                    () => {
                                                                      emailErroPagamento(
                                                                        {
                                                                          orderId:
                                                                            res
                                                                              .data
                                                                              .orderId,
                                                                          requestId:
                                                                            res
                                                                              .data
                                                                              .requestId,
                                                                          id_cliente:
                                                                            funcionarioSelecionadoId,
                                                                        }
                                                                      ).then(
                                                                        (
                                                                          res2
                                                                        ) => {
                                                                          if (
                                                                            res2.success
                                                                          ) {
                                                                            let utilizador =
                                                                              Cookies.get(
                                                                                "utilizador"
                                                                              );
                                                                            if (
                                                                              utilizador ==
                                                                              "superadmin"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_superadmin"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "supercomercial"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_supercommercial"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "comercial"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_commercial"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "admin"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_admin"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "funcionario"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_employee"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "professor"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_teacher"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "cliente"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_customer"
                                                                              );
                                                                            }
                                                                            setFreeze(
                                                                              false
                                                                            );

                                                                            toast.success(
                                                                              t(
                                                                                "pagamentoCancelado"
                                                                              )
                                                                            );
                                                                          } else {
                                                                            setFreeze(
                                                                              false
                                                                            );

                                                                            toast.error(
                                                                              t(
                                                                                "erroCancelarPagamento"
                                                                              )
                                                                            );
                                                                          }
                                                                        }
                                                                      );
                                                                    },
                                                                    3000
                                                                  );
                                                                  setFreeze(
                                                                    false
                                                                  );
                                                                } else {
                                                                  //Erro
                                                                  setFreeze(
                                                                    false
                                                                  );
                                                                }
                                                              });
                                                            }}
                                                          >
                                                            <span
                                                              className="v-padding"
                                                              style={{
                                                                display:
                                                                  "block",
                                                                padding:
                                                                  "13px 40px",
                                                                lineHeight:
                                                                  "120%",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      isMobile
                                                                        ? 12
                                                                        : 16,

                                                                    fontFamily:
                                                                      "Lato, sans-serif",
                                                                  }}
                                                                >
                                                                  {t(
                                                                    "cancelarPagamento"
                                                                  )}
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </a>
                                                        </div>

                                                        {dadosPendente.metodo_pagamento ==
                                                          "Balcão" &&
                                                          dadosPendente.id_metodo_pagamento ==
                                                            4 &&
                                                          dadosPendente.status ==
                                                            1 && (
                                                            <div align="center">
                                                              <a
                                                                target="_blank"
                                                                className="v-button"
                                                                style={{
                                                                  boxSizing:
                                                                    "border-box",
                                                                  display:
                                                                    "inline-block",
                                                                  fontFamily:
                                                                    '"Lato",sans-serif',
                                                                  textDecoration:
                                                                    "none",
                                                                  WebkitTextSizeAdjust:
                                                                    "none",
                                                                  textAlign:
                                                                    "center",
                                                                  color:
                                                                    "#FFFFFF",
                                                                  backgroundColor:
                                                                    Colors.ativo,
                                                                  borderRadius: 40,
                                                                  WebkitBorderRadius: 40,
                                                                  MozBorderRadius: 40,
                                                                  width: "auto",
                                                                  maxWidth:
                                                                    "100%",
                                                                  overflowWrap:
                                                                    "break-word",
                                                                  wordBreak:
                                                                    "break-word",
                                                                  wordWrap:
                                                                    "break-word",
                                                                  msoBorderAlt:
                                                                    "none",
                                                                  cursor:
                                                                    "pointer",
                                                                  marginTop:
                                                                    "20px",
                                                                }}
                                                                onClick={() => {
                                                                  setFreeze(
                                                                    true
                                                                  );
                                                                  // Aprovar pagamento pendente
                                                                  aprovarPagamentoBalcao(
                                                                    {
                                                                      id_pagamento:
                                                                        dadosPendente.id_pagamento,
                                                                    }
                                                                  ).then(
                                                                    (res) => {
                                                                      if (
                                                                        res.success
                                                                      ) {
                                                                        setTimeout(
                                                                          () => {
                                                                            emailSucessoPagamento2(
                                                                              {
                                                                                id_pagamento:
                                                                                  dadosPendente.id_pagamento,
                                                                              }
                                                                            ).then(
                                                                              (
                                                                                res2
                                                                              ) => {
                                                                                if (
                                                                                  res2.success
                                                                                ) {
                                                                                  navigate(
                                                                                    "/dashboard_admin"
                                                                                  );
                                                                                  setFreeze(
                                                                                    false
                                                                                  );
                                                                                  toast.success(
                                                                                    t(
                                                                                      "pagamentoAprovado"
                                                                                    )
                                                                                  );
                                                                                } else {
                                                                                  setFreeze(
                                                                                    false
                                                                                  );
                                                                                  toast.success(
                                                                                    t(
                                                                                      "pagamentoAprovado"
                                                                                    )
                                                                                  );
                                                                                }
                                                                              }
                                                                            );
                                                                          },
                                                                          3000
                                                                        );
                                                                        setFreeze(
                                                                          false
                                                                        );
                                                                      } else {
                                                                        setFreeze(
                                                                          false
                                                                        );
                                                                        toast.error(
                                                                          t(
                                                                            "pagamentoErroAprovado"
                                                                          )
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <span
                                                                  className="v-padding"
                                                                  style={{
                                                                    display:
                                                                      "block",
                                                                    padding:
                                                                      "13px 40px",
                                                                    lineHeight:
                                                                      "120%",
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 16,

                                                                        fontFamily:
                                                                          "Lato, sans-serif",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "aprovarPagamento"
                                                                      )}
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </a>
                                                            </div>
                                                          )}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </DialogContent>
                        </Dialog>
                      )}

                      {janelaPago && (
                        <Dialog
                          open={janelaPago}
                          onClose={() => {
                            setJanelaPago(false);
                          }}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogTitle
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: Colors.ativo,
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {t("pagamentoConcluido")}
                          </DialogTitle>

                          <DialogContent
                            style={{ backgroundColor: "rgb(245, 245, 245)" }}
                          >
                            <table
                              id="u_body"
                              style={{
                                borderCollapse: "collapse",
                                tableLayout: "fixed",
                                borderSpacing: 0,
                                msoTableLspace: "0pt",
                                msoTableRspace: "0pt",
                                verticalAlign: "top",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                              cellPadding={0}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr style={{ verticalAlign: "top" }}>
                                  <td
                                    style={{
                                      wordBreak: "break-word",
                                      borderCollapse: "collapse !important",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      className="u-row-container"
                                      style={{
                                        padding: 0,
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <div
                                        className="u-row"
                                        style={{
                                          margin: "0 auto",
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderCollapse: "collapse",
                                            display: "table",

                                            height: "100%",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <div
                                            className="u-col u-col-100"
                                            style={{
                                              display: "table-cell",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor:
                                                  "rgb(245, 245, 245)",
                                                height: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  boxSizing: "border-box",
                                                  height: "100%",
                                                  padding: 0,
                                                  borderTop:
                                                    "0px solid transparent",
                                                  borderLeft:
                                                    "0px solid transparent",
                                                  borderRight:
                                                    "0px solid transparent",
                                                  borderBottom:
                                                    "0px solid transparent",
                                                }}
                                              >
                                                <table
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: isMobile
                                                            ? 10
                                                            : 40,
                                                        }}
                                                        align="left"
                                                      >
                                                        <div
                                                          style={{
                                                            color: "#161a39",
                                                            lineHeight: "150%",
                                                            textAlign: "center",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                "#f5f5f5",
                                                              borderRadius: 10,
                                                              overflow:
                                                                "hidden",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <h2
                                                              style={{
                                                                color:
                                                                  "#2d2d2d",
                                                                fontSize:
                                                                  isMobile
                                                                    ? 16
                                                                    : 18,
                                                                marginBottom: 20,
                                                              }}
                                                            >
                                                              {t(
                                                                "detalhesCompra"
                                                              )}
                                                            </h2>

                                                            {dadosPendente.linhas.map(
                                                              (linha) => {
                                                                return (
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#fff",
                                                                      padding: 20,
                                                                      borderRadius: 10,
                                                                      marginBottom: 20,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}
                                                                    >
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 12
                                                                              : 16,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "left",
                                                                        }}
                                                                      >
                                                                        {
                                                                          linha.nomeProduto
                                                                        }
                                                                      </h3>
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 12
                                                                              : 16,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          color:
                                                                            "#36B6FF",
                                                                          fontWeight: 600,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "right",
                                                                        }}
                                                                      >
                                                                        {linha.precoProduto.toLocaleString(
                                                                          "pt-PT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "EUR",
                                                                          }
                                                                        )}
                                                                      </h3>
                                                                    </div>
                                                                    <hr
                                                                      style={{
                                                                        border:
                                                                          "none",
                                                                        borderTop:
                                                                          "1px solid #d4d4d4",
                                                                        margin:
                                                                          "20px 0",
                                                                      }}
                                                                    />
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc1
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc2
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,

                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "quantidade"
                                                                      )}
                                                                      : 1
                                                                    </p>
                                                                  </div>
                                                                );
                                                              }
                                                            )}

                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#fff",
                                                                padding: 20,
                                                                borderRadius: 10,
                                                                marginTop: 20,
                                                              }}
                                                            >
                                                              {dadosPendente.desconto !=
                                                                0 && (
                                                                <>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                    }}
                                                                  >
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "desconto"
                                                                      )}
                                                                      :{" "}
                                                                      {
                                                                        dadosPendente.desconto
                                                                      }
                                                                      %
                                                                    </h3>
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        color:
                                                                          "#36B6FF",
                                                                        fontWeight: 600,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "right",
                                                                      }}
                                                                    >
                                                                      -{" "}
                                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )}
                                                                    </h3>
                                                                  </div>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 12
                                                                          : 14,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    {
                                                                      dadosPendente.desc_desconto
                                                                    }
                                                                  </p>
                                                                </>
                                                              )}

                                                              <hr
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  borderTop:
                                                                    "1px solid #d4d4d4",
                                                                  margin:
                                                                    "20px 0",
                                                                }}
                                                              />
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#fff",
                                                                  padding: 20,
                                                                  borderRadius: 10,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 12
                                                                          : 14,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {t(
                                                                        "total"
                                                                      )}
                                                                      :
                                                                    </strong>
                                                                  </h3>
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      fontWeight: 600,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {dadosPendente.total.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    )}
                                                                  </h3>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,
                                                              marginBottom: 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "moradaFaturacao"
                                                            )}
                                                          </h2>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nome}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.morada
                                                            }
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nif}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.codigo_postal
                                                            }
                                                          </p>
                                                          {/* Caso sejam os dados do filho não aparece email */}
                                                          {dadosPendente.email && (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  isMobile
                                                                    ? 12
                                                                    : 14,

                                                                marginBottom: 10,
                                                                textAlign:
                                                                  "start",
                                                              }}
                                                            >
                                                              {
                                                                dadosPendente.email
                                                              }
                                                            </p>
                                                          )}
                                                        </div>

                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "metodoPagamento"
                                                            )}
                                                          </h2>
                                                          {dadosPendente.id_metodo_pagamento ==
                                                            2 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  mbwayfotoImg
                                                                }
                                                                alt="MB Way"
                                                                style={{
                                                                  width: 120,
                                                                  marginBottom: 20,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t("dataPago")}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            3 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  cartaocreditoImg
                                                                }
                                                                alt="Cartão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t("dataPago")}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            1 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={mbImg}
                                                                alt="Multibanco"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t("dataPago")}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            4 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={balcaoImg}
                                                                alt="Balcão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t("dataPago")}{" "}
                                                                {new Date(
                                                                  element.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <table
                                                  id="u_content_button_1"
                                                  style={{
                                                    fontFamily:
                                                      '"Lato",sans-serif',
                                                  }}
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  width="100%"
                                                  border={0}
                                                ></table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </DialogContent>
                        </Dialog>
                      )}

                      {janelaCancelado && (
                        <Dialog
                          open={janelaCancelado}
                          onClose={() => {
                            setJanelaCancelado(false);
                          }}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogTitle
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: Colors.inativo,
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {t("pagamentoCancelado2")}
                          </DialogTitle>

                          <DialogContent
                            style={{ backgroundColor: "rgb(245, 245, 245)" }}
                          >
                            <table
                              id="u_body"
                              style={{
                                borderCollapse: "collapse",
                                tableLayout: "fixed",
                                borderSpacing: 0,
                                msoTableLspace: "0pt",
                                msoTableRspace: "0pt",
                                verticalAlign: "top",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                              cellPadding={0}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr style={{ verticalAlign: "top" }}>
                                  <td
                                    style={{
                                      wordBreak: "break-word",
                                      borderCollapse: "collapse !important",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      className="u-row-container"
                                      style={{
                                        padding: 0,
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <div
                                        className="u-row"
                                        style={{
                                          margin: "0 auto",
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderCollapse: "collapse",
                                            display: "table",

                                            height: "100%",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <div
                                            className="u-col u-col-100"
                                            style={{
                                              display: "table-cell",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor:
                                                  "rgb(245, 245, 245)",
                                                height: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  boxSizing: "border-box",
                                                  height: "100%",
                                                  padding: 0,
                                                  borderTop:
                                                    "0px solid transparent",
                                                  borderLeft:
                                                    "0px solid transparent",
                                                  borderRight:
                                                    "0px solid transparent",
                                                  borderBottom:
                                                    "0px solid transparent",
                                                }}
                                              >
                                                <table
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: isMobile
                                                            ? 10
                                                            : 40,
                                                        }}
                                                        align="left"
                                                      >
                                                        <div
                                                          style={{
                                                            color: "#161a39",
                                                            lineHeight: "150%",
                                                            textAlign: "center",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                "#f5f5f5",
                                                              borderRadius: 10,
                                                              overflow:
                                                                "hidden",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <h2
                                                              style={{
                                                                color:
                                                                  "#2d2d2d",
                                                                fontSize:
                                                                  isMobile
                                                                    ? 16
                                                                    : 18,
                                                                marginBottom: 20,
                                                              }}
                                                            >
                                                              {t(
                                                                "detalhesCompra"
                                                              )}
                                                            </h2>
                                                            {dadosPendente.linhas.map(
                                                              (linha) => {
                                                                return (
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#fff",
                                                                      padding: 20,
                                                                      borderRadius: 10,
                                                                      marginBottom: 20,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}
                                                                    >
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 14
                                                                              : 16,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "left",
                                                                        }}
                                                                      >
                                                                        {
                                                                          linha.nomeProduto
                                                                        }
                                                                      </h3>
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 14
                                                                              : 16,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          color:
                                                                            "#36B6FF",
                                                                          fontWeight: 600,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "right",
                                                                        }}
                                                                      >
                                                                        {linha.precoProduto.toLocaleString(
                                                                          "pt-PT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "EUR",
                                                                          }
                                                                        )}
                                                                      </h3>
                                                                    </div>
                                                                    <hr
                                                                      style={{
                                                                        border:
                                                                          "none",
                                                                        borderTop:
                                                                          "1px solid #d4d4d4",
                                                                        margin:
                                                                          "20px 0",
                                                                      }}
                                                                    />
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc1
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc2
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "quantidade"
                                                                      )}
                                                                      : 1
                                                                    </p>
                                                                  </div>
                                                                );
                                                              }
                                                            )}

                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#fff",
                                                                padding: 20,
                                                                borderRadius: 10,
                                                                marginTop: 20,
                                                              }}
                                                            >
                                                              {dadosPendente.desconto !=
                                                                0 && (
                                                                <>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                    }}
                                                                  >
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 14
                                                                            : 16,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "desconto"
                                                                      )}
                                                                      :{" "}
                                                                      {
                                                                        dadosPendente.desconto
                                                                      }
                                                                      %
                                                                    </h3>
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 14
                                                                            : 16,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        color:
                                                                          "#36B6FF",
                                                                        fontWeight: 600,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "right",
                                                                      }}
                                                                    >
                                                                      -{" "}
                                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )}
                                                                    </h3>
                                                                  </div>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    {
                                                                      dadosPendente.desc_desconto
                                                                    }
                                                                  </p>
                                                                </>
                                                              )}

                                                              <hr
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  borderTop:
                                                                    "1px solid #d4d4d4",
                                                                  margin:
                                                                    "20px 0",
                                                                }}
                                                              />
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#fff",
                                                                  padding: 20,
                                                                  borderRadius: 10,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {t(
                                                                        "total"
                                                                      )}
                                                                      :
                                                                    </strong>
                                                                  </h3>
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      fontWeight: 600,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {dadosPendente.total.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    )}
                                                                  </h3>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,
                                                              marginBottom: 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "moradaFaturacao"
                                                            )}
                                                          </h2>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nome}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.morada
                                                            }
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nif}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.codigo_postal
                                                            }
                                                          </p>
                                                          {/* Caso sejam os dados do filho não aparece email */}
                                                          {dadosPendente.email && (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  isMobile
                                                                    ? 12
                                                                    : 14,

                                                                marginBottom: 10,
                                                                textAlign:
                                                                  "start",
                                                              }}
                                                            >
                                                              {
                                                                dadosPendente.email
                                                              }
                                                            </p>
                                                          )}
                                                        </div>

                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "metodoPagamento"
                                                            )}
                                                          </h2>
                                                          {dadosPendente.id_metodo_pagamento ==
                                                            2 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  mbwayfotoImg
                                                                }
                                                                alt="MB Way"
                                                                style={{
                                                                  width: 120,
                                                                  marginBottom: 20,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            3 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  cartaocreditoImg
                                                                }
                                                                alt="Cartão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize: 14,
                                                                  marginBottom: 20,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            1 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={mbImg}
                                                                alt="Multibanco"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            4 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={balcaoImg}
                                                                alt="Balcão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </DialogContent>
                        </Dialog>
                      )}

                      {janelaFuturo && (
                        <Dialog
                          open={janelaFuturo}
                          onClose={() => {
                            setJanelaFuturo(false);
                            if (!estado) {
                              setOpen(false);
                              setButtonVisibility(false);
                              setReferencia("");
                              setMontante("");
                              setEntidade("");
                              setDataExpira("");
                              setMbway(false);
                              setMensagemMbway(false);
                              setTelefone(false);
                            }
                          }}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogTitle
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#4169e0",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {t("pagamentoFuturo")}
                          </DialogTitle>

                          <DialogContent
                            style={{ backgroundColor: "rgb(245, 245, 245)" }}
                          >
                            <table
                              id="u_body"
                              style={{
                                borderCollapse: "collapse",
                                tableLayout: "fixed",
                                borderSpacing: 0,
                                msoTableLspace: "0pt",
                                msoTableRspace: "0pt",
                                verticalAlign: "top",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                              cellPadding={0}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr style={{ verticalAlign: "top" }}>
                                  <td
                                    style={{
                                      wordBreak: "break-word",
                                      borderCollapse: "collapse !important",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      className="u-row-container"
                                      style={{
                                        padding: 0,
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <div
                                        className="u-row"
                                        style={{
                                          margin: "0 auto",
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderCollapse: "collapse",
                                            display: "table",

                                            height: "100%",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <div
                                            className="u-col u-col-100"
                                            style={{
                                              display: "table-cell",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor:
                                                  "rgb(245, 245, 245)",
                                                height: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  boxSizing: "border-box",
                                                  height: "100%",
                                                  padding: 0,
                                                  borderTop:
                                                    "0px solid transparent",
                                                  borderLeft:
                                                    "0px solid transparent",
                                                  borderRight:
                                                    "0px solid transparent",
                                                  borderBottom:
                                                    "0px solid transparent",
                                                }}
                                              >
                                                <table
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: isMobile
                                                            ? 10
                                                            : 40,
                                                        }}
                                                        align="left"
                                                      >
                                                        <div
                                                          style={{
                                                            color: "#161a39",
                                                            lineHeight: "150%",
                                                            textAlign: "center",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                "#f5f5f5",
                                                              borderRadius: 10,
                                                              overflow:
                                                                "hidden",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <h2
                                                              style={{
                                                                color:
                                                                  "#2d2d2d",
                                                                fontSize:
                                                                  isMobile
                                                                    ? 16
                                                                    : 18,

                                                                marginBottom: 20,
                                                              }}
                                                            >
                                                              {t(
                                                                "detalhesCompra"
                                                              )}
                                                            </h2>
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#fff",
                                                                padding: 20,
                                                                borderRadius: 10,
                                                                marginBottom: 20,
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "space-between",
                                                                }}
                                                              >
                                                                <h3
                                                                  style={{
                                                                    fontSize:
                                                                      isMobile
                                                                        ? 14
                                                                        : 16,
                                                                    marginBottom:
                                                                      isMobile
                                                                        ? 5
                                                                        : 10,
                                                                    width:
                                                                      "50%",
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  {
                                                                    pagamentoMensal.nome_turma
                                                                  }
                                                                </h3>
                                                                <h3
                                                                  style={{
                                                                    fontSize:
                                                                      isMobile
                                                                        ? 14
                                                                        : 16,
                                                                    marginBottom:
                                                                      isMobile
                                                                        ? 5
                                                                        : 10,
                                                                    color:
                                                                      "#36B6FF",
                                                                    fontWeight: 600,
                                                                    width:
                                                                      "50%",
                                                                    textAlign:
                                                                      "right",
                                                                  }}
                                                                >
                                                                  {pagamentoMensal.total.toLocaleString(
                                                                    "pt-PT",
                                                                    {
                                                                      style:
                                                                        "currency",
                                                                      currency:
                                                                        "EUR",
                                                                    }
                                                                  )}
                                                                </h3>
                                                              </div>
                                                              <hr
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  borderTop:
                                                                    "1px solid #d4d4d4",
                                                                  margin:
                                                                    "20px 0",
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 14
                                                                      : 16,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "start",
                                                                }}
                                                              >
                                                                {
                                                                  pagamentoMensal.nome_piscina
                                                                }
                                                              </p>

                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "start",
                                                                }}
                                                              >
                                                                {t(
                                                                  "quantidade"
                                                                )}
                                                                : 1
                                                              </p>
                                                            </div>

                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#fff",
                                                                padding: 20,
                                                                borderRadius: 10,
                                                                marginTop: 20,
                                                              }}
                                                            >
                                                              {/* {dadosPendente.desconto != 0 && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                          window.innerWidth >
                                                          600
                                                            ? 20
                                                            : 14,
                                                        marginBottom:
                                                          window.innerWidth >
                                                          600
                                                            ? 10
                                                            : 5,
                                                        width: "50%",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {t("desconto")}:{" "}
                                                      {dadosPendente.desconto}%
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                          window.innerWidth >
                                                          600
                                                            ? 20
                                                            : 14,
                                                        marginBottom:
                                                          window.innerWidth >
                                                          600
                                                            ? 10
                                                            : 5,
                                                        color: "#36B6FF",
                                                        fontWeight: 600,
                                                        width: "50%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize:
                                                        window.innerWidth > 600
                                                          ? 16
                                                          : 12,
                                                      marginBottom:
                                                        window.innerWidth > 600
                                                          ? 10
                                                          : 5,
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      dadosPendente.desc_desconto
                                                    }
                                                  </p>
                                                </>
                                              )} */}

                                                              <hr
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  borderTop:
                                                                    "1px solid #d4d4d4",
                                                                  margin:
                                                                    "20px 0",
                                                                }}
                                                              />
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#fff",
                                                                  padding: 20,
                                                                  borderRadius: 10,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {t(
                                                                        "total"
                                                                      )}
                                                                      :
                                                                    </strong>
                                                                  </h3>
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      fontWeight: 600,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {pagamentoMensal.total.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    )}
                                                                  </h3>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: "center",
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 14,
                                              marginBottom: 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("moradaFaturacao")}
                                          </h2>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.nome}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.morada}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.nif}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.codigo_postal}
                                          </p>
                                          //  Caso sejam os dados do filho não aparece email *
                                          {dadosPendente.email && (
                                            <p
                                              style={{
                                                fontSize:
                                                  window.innerWidth > 600
                                                    ? 20
                                                    : 12,
                                                marginBottom: 10,
                                              }}
                                            >
                                              {dadosPendente.email}
                                            </p>
                                          )}
                                        </div> */}

                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 30,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "metodoPagamento"
                                                            )}
                                                          </h2>

                                                          {referencia &&
                                                            entidade &&
                                                            montante &&
                                                            dataExpira && (
                                                              <form className="form2">
                                                                <img
                                                                  src={mbImg}
                                                                  style={{
                                                                    width:
                                                                      "150px",
                                                                    height:
                                                                      "150px",
                                                                    marginTop: 20,
                                                                    marginBottom: 30,
                                                                    justifyContent:
                                                                      "center",
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                />
                                                                <div>
                                                                  <h2 className="multibanco">
                                                                    {t(
                                                                      "entidade"
                                                                    )}
                                                                    <span className="spanMb">
                                                                      {entidade}
                                                                    </span>
                                                                  </h2>
                                                                  <h2 className="multibanco">
                                                                    {t(
                                                                      "referencia"
                                                                    )}
                                                                    <span className="spanMb">
                                                                      {
                                                                        referencia
                                                                      }
                                                                    </span>
                                                                  </h2>
                                                                  <h2 className="multibanco">
                                                                    {t(
                                                                      "valorapagar"
                                                                    )}{" "}
                                                                    <span className="spanMb">
                                                                      {montante.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )}
                                                                    </span>
                                                                  </h2>
                                                                  <h2 className="multibanco">
                                                                    {t(
                                                                      "dataLimite"
                                                                    )}
                                                                    {": "}
                                                                    <span className="spanMb">
                                                                      {
                                                                        dataExpira
                                                                      }
                                                                    </span>
                                                                  </h2>
                                                                </div>
                                                              </form>
                                                            )}
                                                          {mbway && (
                                                            <form className="form2">
                                                              <img
                                                                src={
                                                                  mbwayfotoImg
                                                                }
                                                                style={{
                                                                  width:
                                                                    "200px",
                                                                  height:
                                                                    "100px",
                                                                  marginBottom:
                                                                    "80px",
                                                                  marginTop: 100,
                                                                }}
                                                              />
                                                              <p>
                                                                {t(
                                                                  "valorapagar"
                                                                )}{" "}
                                                                {montante.toLocaleString(
                                                                  "pt-PT",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      "EUR",
                                                                  }
                                                                )}
                                                              </p>
                                                              <PhoneInput
                                                                defaultCountry="PT"
                                                                countries={[
                                                                  "PT",
                                                                ]}
                                                                labels={
                                                                  i18n.language ==
                                                                  "pt"
                                                                    ? pt
                                                                    : i18n.language ==
                                                                      "es"
                                                                    ? es
                                                                    : en
                                                                }
                                                                value={telefone}
                                                                error={
                                                                  erroTelefone
                                                                }
                                                                helperText={
                                                                  mensagemErroTelefone
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setTelefone(
                                                                    e
                                                                  );
                                                                  setErroTelefone(
                                                                    false
                                                                  );
                                                                  setMensagemErroTelefone(
                                                                    ""
                                                                  );
                                                                }}
                                                                inputComponent={
                                                                  PhoneNumber
                                                                }
                                                              />
                                                              {!buttonVisibility && (
                                                                <Stack
                                                                  direction={
                                                                    "row"
                                                                  }
                                                                  spacing={2}
                                                                  sx={{
                                                                    marginTop: 10,
                                                                    marginRight: 2,
                                                                    justifyContent:
                                                                      "flex-end",
                                                                  }}
                                                                >
                                                                  <button
                                                                    type="button"
                                                                    className="regbtn"
                                                                    onClick={() => {
                                                                      if (
                                                                        telefone &&
                                                                        !erroTelefone
                                                                      ) {
                                                                        setEstado(
                                                                          true
                                                                        );
                                                                        setButtonVisibility(
                                                                          true
                                                                        );
                                                                        setTimeout(
                                                                          () => {
                                                                            gerarPagamentoMensal(
                                                                              {
                                                                                id_pagamento:
                                                                                  pagamentoMensal.id_pagamento,
                                                                                id_entidade_exploradora:
                                                                                  pagamentoMensal.id_entidade_exploradora,
                                                                                id_inscricao:
                                                                                  pagamentoMensal.id_inscricao,
                                                                                pagamento:
                                                                                  "mbway",
                                                                                telemovel:
                                                                                  telefone,
                                                                              }
                                                                            ).then(
                                                                              (
                                                                                res
                                                                              ) => {
                                                                                setButtonVisibility(
                                                                                  false
                                                                                );
                                                                                setMensagemMbway(
                                                                                  true
                                                                                );
                                                                                setMbway(
                                                                                  false
                                                                                );
                                                                                if (
                                                                                  res.success
                                                                                ) {
                                                                                  let dados =
                                                                                    res
                                                                                      .data
                                                                                      .data;
                                                                                  setDataExpira(
                                                                                    dados.ExpiryDate
                                                                                  );
                                                                                  setTimeout(
                                                                                    () => {
                                                                                      emailPagamentoMensal(
                                                                                        {
                                                                                          id_pagamento:
                                                                                            pagamentoMensal.id_pagamento,
                                                                                        }
                                                                                      ).then(
                                                                                        (
                                                                                          res2
                                                                                        ) => {
                                                                                          setEstado(
                                                                                            false
                                                                                          );
                                                                                          setOpen(
                                                                                            false
                                                                                          );
                                                                                          setButtonVisibility(
                                                                                            false
                                                                                          );
                                                                                          setMbway(
                                                                                            false
                                                                                          );
                                                                                          setMensagemMbway(
                                                                                            false
                                                                                          );
                                                                                          setTelefone(
                                                                                            false
                                                                                          );
                                                                                        }
                                                                                      );
                                                                                    },
                                                                                    3000
                                                                                  );
                                                                                } else {
                                                                                  setOpen(
                                                                                    false
                                                                                  );
                                                                                  setMbway(
                                                                                    false
                                                                                  );
                                                                                  setEstado(
                                                                                    false
                                                                                  );
                                                                                  setMensagemMbway(
                                                                                    false
                                                                                  );
                                                                                  setTelefone(
                                                                                    false
                                                                                  );
                                                                                  setButtonVisibility(
                                                                                    false
                                                                                  );
                                                                                  toast.error(
                                                                                    res.error
                                                                                  );
                                                                                }
                                                                              }
                                                                            );
                                                                          },
                                                                          1000
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    {t("pagar")}
                                                                  </button>
                                                                </Stack>
                                                              )}
                                                            </form>
                                                          )}
                                                          {buttonVisibility &&
                                                          !referencia &&
                                                          !entidade &&
                                                          !montante &&
                                                          !dataExpira ? (
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                                alignItems:
                                                                  "center",
                                                                height: "100%",
                                                              }}
                                                            >
                                                              <CircularProgress
                                                                disableShrink
                                                                style={{
                                                                  marginTop: 20,
                                                                  textAlign:
                                                                    "center",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              />
                                                            </div>
                                                          ) : null}
                                                          {!mbway &&
                                                          mensagemMbway &&
                                                          !buttonVisibility ? (
                                                            <DialogContentText
                                                              style={{
                                                                paddingBottom: 3,
                                                                paddingTop: 10,
                                                              }}
                                                              id="alert-dialog-description"
                                                            >
                                                              {t("msgMbway")}
                                                            </DialogContentText>
                                                          ) : null}
                                                          {!buttonVisibility &&
                                                          mbway == false &&
                                                          !mensagemMbway ? (
                                                            <div>
                                                              <div className="cardsDivMarcarPagamento2">
                                                                <Button
                                                                  className="cardFuturo"
                                                                  variant="contained"
                                                                  style={{
                                                                    backgroundColor:
                                                                      Colors.cardAtalho1,
                                                                  }}
                                                                  onClick={() => {
                                                                    setEstado(
                                                                      true
                                                                    );
                                                                    setButtonVisibility(
                                                                      true
                                                                    );
                                                                    setTimeout(
                                                                      () => {
                                                                        gerarPagamentoMensal(
                                                                          {
                                                                            id_pagamento:
                                                                              pagamentoMensal.id_pagamento,
                                                                            id_entidade_exploradora:
                                                                              pagamentoMensal.id_entidade_exploradora,
                                                                            id_inscricao:
                                                                              pagamentoMensal.id_inscricao,
                                                                            pagamento:
                                                                              "cartao",
                                                                          }
                                                                        ).then(
                                                                          (
                                                                            res
                                                                          ) => {
                                                                            if (
                                                                              res.success
                                                                            ) {
                                                                              let dados =
                                                                                res
                                                                                  .data
                                                                                  .data;
                                                                              setDataExpira(
                                                                                dados.ExpiryDate
                                                                              );
                                                                              setTimeout(
                                                                                () => {
                                                                                  emailPagamentoMensal(
                                                                                    {
                                                                                      id_pagamento:
                                                                                        pagamentoMensal.id_pagamento,
                                                                                    }
                                                                                  ).then(
                                                                                    (
                                                                                      res2
                                                                                    ) => {
                                                                                      setEstado(
                                                                                        false
                                                                                      );
                                                                                      window.location.href =
                                                                                        dados.PaymentUrl;
                                                                                    }
                                                                                  );
                                                                                },
                                                                                3000
                                                                              );
                                                                            } else {
                                                                              setOpen(
                                                                                false
                                                                              );
                                                                              setEstado(
                                                                                false
                                                                              );
                                                                              setButtonVisibility(
                                                                                false
                                                                              );
                                                                              toast.error(
                                                                                res.error
                                                                              );
                                                                            }
                                                                          }
                                                                        );
                                                                      },
                                                                      1000
                                                                    );
                                                                  }}
                                                                >
                                                                  <FontAwesomeIcon
                                                                    className="iconCards"
                                                                    icon={
                                                                      faCreditCard
                                                                    }
                                                                  />
                                                                  {t(
                                                                    "cartaocredito"
                                                                  )}
                                                                </Button>

                                                                <Button
                                                                  className="cardFuturo"
                                                                  variant="contained"
                                                                  style={{
                                                                    backgroundColor:
                                                                      Colors.cardAtalho2,
                                                                  }}
                                                                  onClick={() => {
                                                                    setEstado(
                                                                      true
                                                                    );
                                                                    setButtonVisibility(
                                                                      true
                                                                    );
                                                                    setTimeout(
                                                                      () => {
                                                                        gerarPagamentoMensal(
                                                                          {
                                                                            id_pagamento:
                                                                              pagamentoMensal.id_pagamento,
                                                                            id_entidade_exploradora:
                                                                              pagamentoMensal.id_entidade_exploradora,
                                                                            id_inscricao:
                                                                              pagamentoMensal.id_inscricao,
                                                                            pagamento:
                                                                              "mb",
                                                                          }
                                                                        ).then(
                                                                          (
                                                                            res
                                                                          ) => {
                                                                            if (
                                                                              res.success
                                                                            ) {
                                                                              let dados =
                                                                                res
                                                                                  .data
                                                                                  .data;
                                                                              setEntidade(
                                                                                dados.Entity
                                                                              );
                                                                              setReferencia(
                                                                                dados.Reference
                                                                              );
                                                                              setMontante(
                                                                                dados.Amount
                                                                              );
                                                                              setDataExpira(
                                                                                dados.ExpiryDate
                                                                              );
                                                                              setTimeout(
                                                                                () => {
                                                                                  emailPagamentoMensal(
                                                                                    {
                                                                                      id_pagamento:
                                                                                        pagamentoMensal.id_pagamento,
                                                                                    }
                                                                                  ).then(
                                                                                    (
                                                                                      res2
                                                                                    ) => {
                                                                                      setEstado(
                                                                                        false
                                                                                      );
                                                                                    }
                                                                                  );
                                                                                },
                                                                                3000
                                                                              );
                                                                            } else {
                                                                              setOpen(
                                                                                false
                                                                              );
                                                                              setEstado(
                                                                                false
                                                                              );
                                                                              setButtonVisibility(
                                                                                false
                                                                              );
                                                                              toast.error(
                                                                                res.error
                                                                              );
                                                                            }
                                                                          }
                                                                        );
                                                                      },
                                                                      1000
                                                                    );
                                                                  }}
                                                                >
                                                                  <FontAwesomeIcon
                                                                    className="iconCards"
                                                                    icon={
                                                                      faMoneyBillTransfer
                                                                    }
                                                                  />
                                                                  {t(
                                                                    "multibanco"
                                                                  )}
                                                                </Button>
                                                              </div>
                                                              <div className="cardsDivMarcarPagamento2">
                                                                <Button
                                                                  className="cardFuturo"
                                                                  variant="contained"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "salmon",
                                                                  }}
                                                                  onClick={() => {
                                                                    setMbway(
                                                                      true
                                                                    );
                                                                  }}
                                                                >
                                                                  <FontAwesomeIcon
                                                                    className="iconCards"
                                                                    icon={
                                                                      faMobile
                                                                    }
                                                                  />
                                                                  {t("mbway")}
                                                                </Button>
                                                                <Button
                                                                  className="cardFuturo"
                                                                  variant="contained"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "khaki",
                                                                  }}
                                                                  onClick={() => {
                                                                    setEstado(
                                                                      true
                                                                    );
                                                                    setButtonVisibility(
                                                                      true
                                                                    );
                                                                    setTimeout(
                                                                      () => {
                                                                        gerarPagamentoMensal(
                                                                          {
                                                                            id_pagamento:
                                                                              pagamentoMensal.id_pagamento,
                                                                            id_entidade_exploradora:
                                                                              pagamentoMensal.id_entidade_exploradora,
                                                                            id_inscricao:
                                                                              pagamentoMensal.id_inscricao,
                                                                            pagamento:
                                                                              "balcao",
                                                                          }
                                                                        ).then(
                                                                          (
                                                                            res
                                                                          ) => {
                                                                            if (
                                                                              res.success
                                                                            ) {
                                                                              let dados =
                                                                                res
                                                                                  .data
                                                                                  .data;
                                                                              setTimeout(
                                                                                () => {
                                                                                  emailPagamentoMensal(
                                                                                    {
                                                                                      id_pagamento:
                                                                                        pagamentoMensal.id_pagamento,
                                                                                    }
                                                                                  ).then(
                                                                                    (
                                                                                      res2
                                                                                    ) => {
                                                                                      setOpen(
                                                                                        false
                                                                                      );
                                                                                      setEstado(
                                                                                        false
                                                                                      );
                                                                                      setButtonVisibility(
                                                                                        false
                                                                                      );
                                                                                      toast.success(
                                                                                        t(
                                                                                          "balcaoPag"
                                                                                        )
                                                                                      );
                                                                                    }
                                                                                  );
                                                                                },
                                                                                3000
                                                                              );
                                                                            } else {
                                                                              setOpen(
                                                                                false
                                                                              );
                                                                              setEstado(
                                                                                false
                                                                              );
                                                                              setButtonVisibility(
                                                                                false
                                                                              );
                                                                              toast.error(
                                                                                res.error
                                                                              );
                                                                            }
                                                                          }
                                                                        );
                                                                      },
                                                                      1000
                                                                    );
                                                                  }}
                                                                >
                                                                  <FontAwesomeIcon
                                                                    className="iconCards"
                                                                    icon={
                                                                      faCashRegister
                                                                    }
                                                                  />
                                                                  {t(
                                                                    "pagaraobalcao"
                                                                  )}
                                                                </Button>
                                                              </div>
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  marginTop:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  pagamentoMensal.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </DialogContent>
                        </Dialog>
                      )}

                      {janelaNoLocal && (
                        <Dialog
                          open={janelaNoLocal}
                          onClose={() => {
                            setJanelaNoLocal(false);
                          }}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogTitle
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: Colors.yellow,
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {t("pagamentoNoLocal")}
                          </DialogTitle>

                          <DialogContent
                            style={{ backgroundColor: "rgb(245, 245, 245)" }}
                          >
                            {freeze && (
                              <div
                                style={{
                                  backgroundColor: "transparent",
                                  position: "absolute",
                                  width: window.innerWidth,
                                  height: window.innerHeight,
                                  zIndex: 200,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: 0,
                                }}
                              >
                                <Dialog
                                  open={true}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <DialogContent>
                                    <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      {t("aguarde")}
                                    </Typography>
                                    <CircularProgress
                                      disableShrink
                                      style={{ marginTop: 20 }}
                                    />
                                  </DialogContent>
                                </Dialog>
                              </div>
                            )}
                            <table
                              id="u_body"
                              style={{
                                borderCollapse: "collapse",
                                tableLayout: "fixed",
                                borderSpacing: 0,
                                msoTableLspace: "0pt",
                                msoTableRspace: "0pt",
                                verticalAlign: "top",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                              cellPadding={0}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr style={{ verticalAlign: "top" }}>
                                  <td
                                    style={{
                                      wordBreak: "break-word",
                                      borderCollapse: "collapse !important",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      className="u-row-container"
                                      style={{
                                        padding: 0,
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <div
                                        className="u-row"
                                        style={{
                                          margin: "0 auto",
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderCollapse: "collapse",
                                            display: "table",

                                            height: "100%",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <div
                                            className="u-col u-col-100"
                                            style={{
                                              display: "table-cell",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor:
                                                  "rgb(245, 245, 245)",
                                                height: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  boxSizing: "border-box",
                                                  height: "100%",
                                                  padding: 0,
                                                  borderTop:
                                                    "0px solid transparent",
                                                  borderLeft:
                                                    "0px solid transparent",
                                                  borderRight:
                                                    "0px solid transparent",
                                                  borderBottom:
                                                    "0px solid transparent",
                                                }}
                                              >
                                                <table
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: isMobile
                                                            ? 10
                                                            : 40,
                                                        }}
                                                        align="left"
                                                      >
                                                        <div
                                                          style={{
                                                            color: "#161a39",
                                                            lineHeight: "150%",
                                                            textAlign: "center",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                "#f5f5f5",
                                                              borderRadius: 10,
                                                              overflow:
                                                                "hidden",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <h2
                                                              style={{
                                                                color:
                                                                  "#2d2d2d",
                                                                fontSize:
                                                                  isMobile
                                                                    ? 16
                                                                    : 18,
                                                                marginBottom: 20,
                                                              }}
                                                            >
                                                              {t(
                                                                "detalhesCompra"
                                                              )}
                                                            </h2>
                                                            {dadosPendente.linhas.map(
                                                              (linha) => {
                                                                return (
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#fff",
                                                                      padding: 20,
                                                                      borderRadius: 10,
                                                                      marginBottom: 20,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}
                                                                    >
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 14
                                                                              : 16,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "left",
                                                                        }}
                                                                      >
                                                                        {
                                                                          linha.nomeProduto
                                                                        }
                                                                      </h3>
                                                                      <h3
                                                                        style={{
                                                                          fontSize:
                                                                            isMobile
                                                                              ? 14
                                                                              : 16,
                                                                          marginBottom:
                                                                            isMobile
                                                                              ? 5
                                                                              : 10,
                                                                          color:
                                                                            "#36B6FF",
                                                                          fontWeight: 600,
                                                                          width:
                                                                            "50%",
                                                                          textAlign:
                                                                            "right",
                                                                        }}
                                                                      >
                                                                        {linha.precoProduto.toLocaleString(
                                                                          "pt-PT",
                                                                          {
                                                                            style:
                                                                              "currency",
                                                                            currency:
                                                                              "EUR",
                                                                          }
                                                                        )}
                                                                      </h3>
                                                                    </div>
                                                                    <hr
                                                                      style={{
                                                                        border:
                                                                          "none",
                                                                        borderTop:
                                                                          "1px solid #d4d4d4",
                                                                        margin:
                                                                          "20px 0",
                                                                      }}
                                                                    />
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc1
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {
                                                                        linha.desc2
                                                                      }
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        textAlign:
                                                                          "start",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "quantidade"
                                                                      )}
                                                                      : 1
                                                                    </p>
                                                                  </div>
                                                                );
                                                              }
                                                            )}

                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#fff",
                                                                padding: 20,
                                                                borderRadius: 10,
                                                                marginTop: 20,
                                                              }}
                                                            >
                                                              {dadosPendente.desconto !=
                                                                0 && (
                                                                <>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                    }}
                                                                  >
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 12
                                                                            : 14,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        "desconto"
                                                                      )}
                                                                      :{" "}
                                                                      {
                                                                        dadosPendente.desconto
                                                                      }
                                                                      %
                                                                    </h3>
                                                                    <h3
                                                                      style={{
                                                                        fontSize:
                                                                          isMobile
                                                                            ? 14
                                                                            : 16,
                                                                        marginBottom:
                                                                          isMobile
                                                                            ? 5
                                                                            : 10,
                                                                        color:
                                                                          "#36B6FF",
                                                                        fontWeight: 600,
                                                                        width:
                                                                          "50%",
                                                                        textAlign:
                                                                          "right",
                                                                      }}
                                                                    >
                                                                      -{" "}
                                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )}
                                                                    </h3>
                                                                  </div>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 12
                                                                          : 14,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    {
                                                                      dadosPendente.desc_desconto
                                                                    }
                                                                  </p>
                                                                </>
                                                              )}

                                                              <hr
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  borderTop:
                                                                    "1px solid #d4d4d4",
                                                                  margin:
                                                                    "20px 0",
                                                                }}
                                                              />
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#fff",
                                                                  padding: 20,
                                                                  borderRadius: 10,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {t(
                                                                        "total"
                                                                      )}
                                                                      :
                                                                    </strong>
                                                                  </h3>
                                                                  <h3
                                                                    style={{
                                                                      fontSize:
                                                                        isMobile
                                                                          ? 14
                                                                          : 16,
                                                                      marginBottom:
                                                                        isMobile
                                                                          ? 5
                                                                          : 10,
                                                                      fontWeight: 600,
                                                                      width:
                                                                        "50%",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {dadosPendente.total.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    )}
                                                                  </h3>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "moradaFaturacao"
                                                            )}
                                                          </h2>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,
                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nome}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,
                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.morada
                                                            }
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {dadosPendente.nif}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize: isMobile
                                                                ? 12
                                                                : 14,

                                                              marginBottom: 10,
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            {
                                                              dadosPendente.codigo_postal
                                                            }
                                                          </p>
                                                          {/* Caso sejam os dados do filho não aparece email */}
                                                          {dadosPendente.email && (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  isMobile
                                                                    ? 12
                                                                    : 14,
                                                                marginBottom: 10,
                                                                textAlign:
                                                                  "start",
                                                              }}
                                                            >
                                                              {
                                                                dadosPendente.email
                                                              }
                                                            </p>
                                                          )}
                                                        </div>

                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              "#fff",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                            padding: 20,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          <h2
                                                            style={{
                                                              color: "#2d2d2d",
                                                              fontSize: isMobile
                                                                ? 14
                                                                : 16,
                                                              marginBottom:
                                                                isMobile
                                                                  ? 5
                                                                  : 10,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {t(
                                                              "metodoPagamento"
                                                            )}
                                                          </h2>
                                                          {dadosPendente.id_metodo_pagamento ==
                                                            2 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  mbwayfotoImg
                                                                }
                                                                alt="MB Way"
                                                                style={{
                                                                  width: 120,
                                                                  marginBottom: 20,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleDateString(
                                                                    "pt-PT"
                                                                  )
                                                                  .split("/")
                                                                  .reverse()
                                                                  .join("/")
                                                                  .split("/")
                                                                  .reverse()
                                                                  .join("/")}
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            3 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  cartaocreditoImg
                                                                }
                                                                alt="Cartão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom: 20,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            1 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={mbImg}
                                                                alt="Multibanco"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}

                                                          {dadosPendente.id_metodo_pagamento ==
                                                            4 && (
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffff",
                                                                borderRadius: 10,
                                                                overflow:
                                                                  "hidden",
                                                                padding: 20,
                                                                marginTop: 20,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              <img
                                                                src={balcaoImg}
                                                                alt="Balcão"
                                                                style={{
                                                                  width: 120,
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#2d2d2d",
                                                                  fontSize:
                                                                    isMobile
                                                                      ? 12
                                                                      : 14,
                                                                  marginBottom:
                                                                    isMobile
                                                                      ? 5
                                                                      : 10,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {t(
                                                                  "dataLimite"
                                                                )}{" "}
                                                                {new Date(
                                                                  dadosPendente.data_expira
                                                                )
                                                                  .toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                      timeZone:
                                                                        "UTC",
                                                                      day: "2-digit",
                                                                      month:
                                                                        "2-digit",
                                                                      year: "numeric",
                                                                      hour: "2-digit",
                                                                      minute:
                                                                        "2-digit",
                                                                    }
                                                                  )
                                                                  .replace(
                                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                                    "$1/$2/$3 $4"
                                                                  )}
                                                                h
                                                              </p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <table
                                                  id="u_content_button_1"
                                                  style={{
                                                    fontFamily:
                                                      '"Lato",sans-serif',
                                                  }}
                                                  role="presentation"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  width="100%"
                                                  border={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          overflowWrap:
                                                            "break-word",
                                                          wordBreak:
                                                            "break-word",
                                                          padding: 10,
                                                          fontFamily:
                                                            '"Lato",sans-serif',
                                                        }}
                                                        align="left"
                                                      >
                                                        <div align="center">
                                                          <a
                                                            target="_blank"
                                                            className="v-button"
                                                            style={{
                                                              boxSizing:
                                                                "border-box",
                                                              display:
                                                                "inline-block",
                                                              fontFamily:
                                                                '"Lato",sans-serif',
                                                              textDecoration:
                                                                "none",
                                                              WebkitTextSizeAdjust:
                                                                "none",
                                                              textAlign:
                                                                "center",
                                                              color: "#FFFFFF",
                                                              backgroundColor:
                                                                Colors.inativo,
                                                              borderRadius: 40,
                                                              WebkitBorderRadius: 40,
                                                              MozBorderRadius: 40,
                                                              width: "auto",
                                                              maxWidth: "100%",
                                                              overflowWrap:
                                                                "break-word",
                                                              wordBreak:
                                                                "break-word",
                                                              wordWrap:
                                                                "break-word",
                                                              msoBorderAlt:
                                                                "none",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              setFreeze(true);
                                                              // Cancelar pagamento pendente
                                                              cancelarPagamentoBalcao(
                                                                {
                                                                  id_cliente:
                                                                    idUtilizadorEscolhido,
                                                                  id_pagamento:
                                                                    dadosPendente.id_pagamento,
                                                                }
                                                              ).then((res) => {
                                                                if (
                                                                  res.success
                                                                ) {
                                                                  setTimeout(
                                                                    () => {
                                                                      emailCancelarPagamentoBalcao(
                                                                        {
                                                                          id_pagamento:
                                                                            dadosPendente.id_pagamento,
                                                                        }
                                                                      ).then(
                                                                        (
                                                                          res2
                                                                        ) => {
                                                                          if (
                                                                            res2.success
                                                                          ) {
                                                                            let utilizador =
                                                                              Cookies.get(
                                                                                "utilizador"
                                                                              );
                                                                            if (
                                                                              utilizador ==
                                                                              "superadmin"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_superadmin"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "supercomercial"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_supercommercial"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "comercial"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_commercial"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "admin"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_admin"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "funcionario"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/editcustomerf",
                                                                                {
                                                                                  state:
                                                                                    {
                                                                                      idPiscina:
                                                                                        idPiscina,
                                                                                      idCliente:
                                                                                        idUtilizadorEscolhido,
                                                                                    },
                                                                                }
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "professor"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_teacher"
                                                                              );
                                                                            } else if (
                                                                              utilizador ==
                                                                              "cliente"
                                                                            ) {
                                                                              setFreeze(
                                                                                false
                                                                              );

                                                                              navigate(
                                                                                "/dashboard_customer"
                                                                              );
                                                                            }
                                                                            setFreeze(
                                                                              false
                                                                            );

                                                                            toast.success(
                                                                              t(
                                                                                "pagamentoCancelado"
                                                                              )
                                                                            );
                                                                          } else {
                                                                            setFreeze(
                                                                              false
                                                                            );

                                                                            toast.error(
                                                                              t(
                                                                                "erroCancelarPagamento"
                                                                              )
                                                                            );
                                                                          }
                                                                        }
                                                                      );
                                                                    },
                                                                    3000
                                                                  );
                                                                  setFreeze(
                                                                    false
                                                                  );
                                                                } else {
                                                                  //Erro
                                                                  setFreeze(
                                                                    false
                                                                  );
                                                                }
                                                              });
                                                            }}
                                                          >
                                                            <span
                                                              className="v-padding"
                                                              style={{
                                                                display:
                                                                  "block",
                                                                padding:
                                                                  "13px 40px",
                                                                lineHeight:
                                                                  "120%",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      isMobile
                                                                        ? 12
                                                                        : 14,
                                                                    lineHeight:
                                                                      "16.8px",
                                                                    fontFamily:
                                                                      "Lato, sans-serif",
                                                                  }}
                                                                >
                                                                  {t(
                                                                    "cancelarPagamento"
                                                                  )}
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </a>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </DialogContent>
                        </Dialog>
                      )}
                      <ToggleButtonGroup
                        color="primary"
                        value={selected}
                        exclusive
                        onChange={handleChange}
                        aria-label="Payment status"
                        sx={{
                          display: "flex",

                          width: "100%",
                          justifyContent: "center",
                          marginTop: "20px",
                          "& .MuiToggleButton-root": {
                            fontSize: "12px",
                            marginLeft: "25px",
                            marginRight: "25px",
                            border: "1px solid #B1AEAE",
                            borderLeft: "1px solid #B1AEAE !important",
                            borderRadius: "10px !important",
                            borderTopLeftRadius: "10px",
                            textTransform: "capitalize",
                            width: "100px",
                            height: "44px",
                          },
                          "& .Mui-selected": {
                            color: "#ffff !important",
                            "&.pendente": {
                              backgroundColor: "#F5C951",

                              "&:hover": {
                                backgroundColor: "#F5C951",
                              },
                            },
                            "&.pago": {
                              backgroundColor: "#33D77A",

                              "&:hover": {
                                backgroundColor: "#33D77A",
                              },
                            },
                            "&.cancelado": {
                              backgroundColor: "#F04643",
                              "&:hover": {
                                backgroundColor: "#F04643",
                              },
                            },
                            "&.balcao": {
                              backgroundColor: "#00C2F5",
                              "&:hover": {
                                backgroundColor: "#00C2F5",
                              },
                            },
                            "&.futuro": {
                              backgroundColor: "#0A56FA",
                              "&:hover": {
                                backgroundColor: "#0A56FA",
                              },
                            },
                          },
                          "@media (max-width: 600px)": {
                            fontSize: "10px",
                            "& .MuiToggleButton-root": {
                              padding: "5px 5px",
                              fontSize: "10px",
                            },
                          },
                          "@media (max-width: 400px)": {
                            fontSize: "8px",
                            "& .MuiToggleButton-root": {
                              padding: "2px 2px",
                              fontSize: "8px",
                            },
                          },
                        }}
                      >
                        <ToggleButton value="1" className="pendente">
                          {t("pendente")}
                        </ToggleButton>
                        <ToggleButton value="2" className="pago">
                          {t("pago")}
                        </ToggleButton>
                        <ToggleButton value="3" className="cancelado">
                          {t("cancelado")}
                        </ToggleButton>
                        <ToggleButton value="4" className="futuro">
                          {t("futuro")}
                        </ToggleButton>
                        <ToggleButton value="5" className="balcao">
                          {t("balcao")}
                        </ToggleButton>
                      </ToggleButtonGroup>

                      <Paper
                        elevation={0}
                        style={{
                          overflow: "auto",
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: 20,
                        }}
                      >
                        {selected == 1 ? (
                          pagamentos.filter((element) => element.status == 1)
                            .length > 0 ? (
                            <Scrollbar
                              style={{
                                height: "400px",
                                display: "flex",
                                marginRight: isMobile ? 0 : "8%",
                              }}
                            >
                              <List
                                style={{
                                  height: "auto",
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                  flexDirection: "column",
                                }}
                              >
                                {pagamentos
                                  .filter((element) => element.status == 1)
                                  .map((element) => (
                                    <Card
                                      onClick={() => {
                                        setDadosPendente(element);
                                        setJanelaPendente(true);
                                      }}
                                      sx={{
                                        background:
                                          "linear-gradient(270deg, rgba(245, 201, 81, 0.20) 0%, rgba(245, 201, 81, 0.00) 100%), rgba(255, 255, 255, 0.60)",
                                        ":hover": {
                                          backgroundColor: Colors.gray,
                                          boxShadow:
                                            "0px 5px 10px rgba(0, 0, 0, 0.1)",
                                          transition:
                                            "background .3s ease-in-out, box-shadow .3s ease-in-out",
                                        },
                                      }}
                                      className="cardListaPagamentos"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: 3,
                                          paddingTop: 2,
                                          paddingRight: 3,
                                        }}
                                      >
                                        <CardContent
                                          sx={{
                                            flex: "1 0 auto",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            className="teste"
                                            component="div"
                                          >
                                            {element.nome_piscina} -{" "}
                                            {element.id_pack
                                              ? t("pagamentoPack")
                                              : t("pagamentoAula")}
                                            {!isMobile ? (
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            ) : null}
                                          </Typography>
                                          {!isMobile ? null : (
                                            <Typography
                                              className="teste"
                                              component="div"
                                            >
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            component="div"
                                          >
                                            {t("metodoPagamento")}:{" "}
                                            {element.metodo_pagamento}
                                          </Typography>
                                          {element.pago ? (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataPagamento")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          ) : element.data_expira ? (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataLimite")}{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          ) : null}
                                        </CardContent>
                                      </Box>
                                    </Card>
                                  ))}
                              </List>
                            </Scrollbar>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "300px",
                              }}
                            >
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {t("sempagamentos")}
                              </p>
                            </div>
                          )
                        ) : null}

                        {selected == 2 ? (
                          pagamentos.filter((element) => element.status == 2)
                            .length > 0 ? (
                            <Scrollbar
                              style={{
                                height: "400px",
                                display: "flex",
                                marginRight: isMobile ? 0 : "8%",
                              }}
                            >
                              <List
                                style={{
                                  height: "auto",
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                  flexDirection: "column",
                                }}
                              >
                                {pagamentos
                                  .filter((element) => element.status == 2)
                                  .map((element) => (
                                    <Card
                                      onClick={() => {
                                        setDadosPendente(element);
                                        setJanelaPago(true);
                                      }}
                                      sx={{
                                        background:
                                          "linear-gradient(270deg, rgba(51, 215, 122, 0.20) 0%, rgba(51, 215, 122, 0.00) 100%), rgba(255, 255, 255, 0.60)",

                                        ":hover": {
                                          backgroundColor: Colors.gray,
                                          boxShadow:
                                            "0px 5px 10px rgba(0, 0, 0, 0.1)",
                                          transition:
                                            "background .3s ease-in-out, box-shadow .3s ease-in-out",
                                        },
                                      }}
                                      className="cardListaPagamentos"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: 3,
                                          paddingTop: 2,
                                          paddingRight: 3,
                                        }}
                                      >
                                        <CardContent
                                          sx={{
                                            flex: "1 0 auto",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            className="teste"
                                            component="div"
                                          >
                                            {element.nome_piscina} -{" "}
                                            {element.id_pack
                                              ? t("pagamentoPack")
                                              : t("pagamentoAula")}
                                            {!isMobile ? (
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            ) : null}
                                          </Typography>
                                          {!isMobile ? null : (
                                            <Typography
                                              className="teste"
                                              component="div"
                                            >
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            component="div"
                                          >
                                            {t("metodoPagamento")}:{" "}
                                            {element.metodo_pagamento}
                                          </Typography>
                                          {element.pago ? (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataPagamento")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataLimite")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          )}
                                        </CardContent>
                                      </Box>
                                    </Card>
                                  ))}
                              </List>
                            </Scrollbar>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "300px",
                              }}
                            >
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {t("sempagamentos")}
                              </p>
                            </div>
                          )
                        ) : null}

                        {selected == 3 ? (
                          pagamentos.filter(
                            (element) =>
                              element.status == 3 || element.status == 4
                          ).length > 0 ? (
                            <Scrollbar
                              style={{
                                height: "400px",
                                display: "flex",
                                marginRight: isMobile ? 0 : "8%",
                              }}
                            >
                              <List
                                style={{
                                  height: "auto",
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",

                                  flexDirection: "column",
                                }}
                              >
                                {pagamentos
                                  .filter(
                                    (element) =>
                                      element.status == 3 || element.status == 4
                                  )
                                  .map((element) => (
                                    <Card
                                      onClick={() => {
                                        setDadosPendente(element);
                                        setJanelaCancelado(true);
                                      }}
                                      sx={{
                                        background:
                                          "linear-gradient(270deg, rgba(240, 70, 67, 0.20) 0%, rgba(240, 70, 67, 0.00) 100%), rgba(255, 255, 255, 0.60)",
                                        ":hover": {
                                          backgroundColor: Colors.gray,
                                          boxShadow:
                                            "0px 5px 10px rgba(0, 0, 0, 0.1)",
                                          transition:
                                            "background .3s ease-in-out, box-shadow .3s ease-in-out",
                                        },
                                      }}
                                      className="cardListaPagamentos"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: 3,
                                          paddingTop: 2,
                                          paddingRight: 3,
                                        }}
                                      >
                                        <CardContent
                                          sx={{
                                            flex: "1 0 auto",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            className="teste"
                                            component="div"
                                          >
                                            {element.nome_piscina} -{" "}
                                            {element.id_pack
                                              ? t("pagamentoPack")
                                              : t("pagamentoAula")}
                                            {!isMobile ? (
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            ) : null}
                                          </Typography>
                                          {!isMobile ? null : (
                                            <Typography
                                              className="teste"
                                              component="div"
                                            >
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            component="div"
                                          >
                                            {t("metodoPagamento")}:{" "}
                                            {element.metodo_pagamento}
                                          </Typography>
                                          {element.pago ? (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataPagamento")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataLimite")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          )}
                                        </CardContent>
                                      </Box>
                                    </Card>
                                  ))}
                              </List>
                            </Scrollbar>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "300px",
                              }}
                            >
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {t("sempagamentos")}
                              </p>
                            </div>
                          )
                        ) : null}

                        {selected == 4 ? (
                          pagamentos.filter((element) => element.status == 5)
                            .length > 0 ? (
                            <Scrollbar
                              style={{
                                height: "400px",
                                display: "flex",
                                marginRight: isMobile ? 0 : "8%",
                              }}
                            >
                              <List
                                style={{
                                  height: "auto",
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",

                                  flexDirection: "column",
                                }}
                              >
                                {" "}
                                {pagamentos
                                  .filter((element) => element.status == 5)
                                  .map((element) => (
                                    <Card
                                      onClick={() => {
                                        setPagamentoMensal(element);
                                        setJanelaFuturo(true);
                                      }}
                                      sx={{
                                        background:
                                          "linear-gradient(270deg, rgba(10, 86, 250, 0.20) 0%, rgba(10, 86, 250, 0.00) 100%), rgba(255, 255, 255, 0.60)",
                                        ":hover": {
                                          backgroundColor: Colors.gray,
                                          boxShadow:
                                            "0px 5px 10px rgba(0, 0, 0, 0.1)",
                                          transition:
                                            "background .3s ease-in-out, box-shadow .3s ease-in-out",
                                        },
                                      }}
                                      className="cardListaPagamentos"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: 3,
                                          paddingTop: 2,
                                          paddingRight: 3,
                                        }}
                                      >
                                        <CardContent
                                          sx={{
                                            flex: "1 0 auto",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            className="teste"
                                            component="div"
                                          >
                                            {element.nome_piscina} -{" "}
                                            {element.id_pack
                                              ? t("pagamentoPack")
                                              : t("pagamentoAula")}
                                            {!isMobile ? (
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            ) : null}
                                          </Typography>
                                          {!isMobile ? null : (
                                            <Typography
                                              className="teste"
                                              component="div"
                                            >
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            component="div"
                                          >
                                            {t("metodoPagamento")}:{" "}
                                            {element.metodo_pagamento}
                                          </Typography>
                                          {element.pago ? (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataPagamento")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="div"
                                            >
                                              {t("dataLimite")}:{" "}
                                              {new Date(element.data_expira)
                                                .toLocaleString("pt-BR", {
                                                  timeZone: "UTC",
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })
                                                .replace(
                                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                  "$1/$2/$3 $4"
                                                )}
                                              h
                                            </Typography>
                                          )}
                                        </CardContent>
                                      </Box>
                                    </Card>
                                  ))}
                              </List>
                            </Scrollbar>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "300px",
                              }}
                            >
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {t("sempagamentos")}
                              </p>
                            </div>
                          )
                        ) : null}

                        {selected == 5 ? (
                          pagamentos.filter((element) => element.status === 5)
                            .length > 0 ? (
                            <Scrollbar
                              style={{
                                height: "400px",
                                display: "flex",
                                marginRight: isMobile ? 0 : "8%",
                              }}
                            >
                              <List
                                style={{
                                  height: "auto",
                                  display: "flex",

                                  alignItems: "end",
                                  justifyContent: "end",

                                  flexDirection: "column",
                                }}
                              >
                                {pagamentos
                                  .filter(
                                    (element) =>
                                      element.id_metodo_pagamento == 4 &&
                                      element.status == 1
                                  )
                                  .map((element) => (
                                    <Card
                                      onClick={() => {
                                        setDadosPendente(element);
                                        setJanelaNoLocal(true);
                                      }}
                                      sx={{
                                        background:
                                          "linear-gradient(270deg, rgba(10, 86, 250, 0.20) 0%, rgba(10, 86, 250, 0.00) 100%), rgba(255, 255, 255, 0.60)",
                                        ":hover": {
                                          backgroundColor: Colors.gray,
                                          boxShadow:
                                            "0px 5px 10px rgba(0, 0, 0, 0.1)",
                                          transition:
                                            "background .3s ease-in-out, box-shadow .3s ease-in-out",
                                        },
                                      }}
                                      className="cardListaPagamentos"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          paddingLeft: 3,
                                          paddingTop: 2,
                                          paddingRight: 3,
                                        }}
                                      >
                                        <CardContent
                                          sx={{
                                            flex: "1 0 auto",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            className="teste"
                                            component="div"
                                          >
                                            {element.nome_piscina} -{" "}
                                            {element.id_pack
                                              ? t("pagamentoPack")
                                              : t("pagamentoAula")}
                                            {!isMobile ? (
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            ) : null}
                                          </Typography>
                                          {!isMobile ? null : (
                                            <Typography
                                              className="teste"
                                              component="div"
                                            >
                                              <span>
                                                {element.total.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </span>
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            component="div"
                                          >
                                            {t("metodoPagamento")}:{" "}
                                            {element.metodo_pagamento}
                                          </Typography>
                                          <Typography>
                                            {element.pago ? (
                                              <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="div"
                                              >
                                                {t("dataPagamento")}:{" "}
                                                {new Date(element.data_expira)
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </Typography>
                                            ) : element.data_expira ? (
                                              <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="div"
                                              >
                                                {t("dataLimite")}:{" "}
                                                {new Date(element.data_expira)
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </Typography>
                                            ) : null}
                                          </Typography>
                                        </CardContent>
                                      </Box>
                                    </Card>
                                  ))}
                              </List>
                            </Scrollbar>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "300px",
                              }}
                            >
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {t("sempagamentos")}
                              </p>
                            </div>
                          )
                        ) : null}
                      </Paper>
                    </div>
                  </div>
                </>
              ) : null}
              {/* Lista atividades */}
              {AtividadesCliente ? (
                <>
                  <div className="divForm">
                    <div className="formPage2">
                      <Dialog
                        open={loading}
                        onClose={() => {
                          setLoading(false);
                          setQrCodeStatus(false);
                          setQrCode("");
                        }}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogContent
                          style={{
                            width: "452px",
                            height: "128px",
                            left: "auto",

                            borderradius: "10px",
                            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {qrCodeStatus && (
                            <DialogContentText>
                              {t("erroQrCode")}
                            </DialogContentText>
                          )}
                          {!qrCodeStatus && !qrCode ? (
                            <CircularProgress
                              disableShrink
                              style={{
                                marginTop: 20,
                              }}
                            />
                          ) : (
                            <img src={qrCode} />
                          )}
                        </DialogContent>
                      </Dialog>
                      {atividades.length == 0 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                            margin: "7% auto", // Centraliza horizontalmente
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            className="cardDashboardMarcar2"
                            variant="contained"
                            style={{
                              backgroundColor: Colors.cardAtalho5,
                              width: "85%",
                            }}
                            disabled
                          >
                            <div style={{ display: "block" }}>
                              <FontAwesomeIcon
                                className="iconCards"
                                icon={faFaceFrown}
                              />
                            </div>
                            <span
                              style={{
                                marginTop: "8px",
                                fontSize: isMobile ? "10px" : "14px",
                              }}
                            >
                              {t("semqrcodes")}
                            </span>
                          </Button>
                        </div>
                      ) : (
                        <Paper
                          elevation={0}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: 32,
                            backgroundColor: "transparent",
                            overflow: "auto",
                            maxHeight: "92%",
                          }}
                        >
                          <Scrollbar
                            style={{
                              height: 450,
                              display: "flex",
                              marginRight: "35%",
                            }}
                          >
                            <List
                              style={{
                                marginTop: 5,
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "end",
                                alignItems: "end",
                              }}
                            >
                              {atividades.map((element) => (
                                <Card
                                  onClick={() => {
                                    setLoading(true);
                                    getQrCodes({
                                      id_cliente: funcionarioSelecionadoId,
                                      id_filho:
                                        element.id_filho == null
                                          ? 0
                                          : element.id_filho,
                                      id_piscina: element.id_piscina,
                                      data: element.dia,
                                      horaInicio: element.hI,
                                      horaFim: element.hF,
                                      id_pack: element.id_pack_cliente,
                                      id_turma: element.id_turma,
                                    }).then((res) => {
                                      if (res.success) {
                                        setQrCode(res.data.qrcode);
                                      } else {
                                        setQrCodeStatus(true);
                                      }
                                    });
                                    setLoading(false);
                                  }}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "60%",
                                    justifyContent: "space-between",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    backgroundColor: "#00000",
                                    margin: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      color: "#00000",
                                      backgroundColor: "#C2D5FE",
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      {element.title}{" "}
                                      {element.nome_turma != undefined
                                        ? " - " + element.nome_turma
                                        : null}
                                    </Typography>
                                  </Box>
                                  <CardContent
                                    sx={{
                                      flex: "1 0 auto",
                                      cursor: "pointer",
                                      padding: 2,
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      component="div"
                                    >
                                      {format(
                                        parseISO(element.start),
                                        "dd/MM/yyyy"
                                      )}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      component="div"
                                    >
                                      {format(parseISO(element.start), "HH:mm")}
                                      h -{" "}
                                      {format(parseISO(element.end), "HH:mm")}h
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      component="div"
                                    >
                                      {element.description}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      component="div"
                                    >
                                      {element.cidade}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              ))}
                            </List>
                          </Scrollbar>
                        </Paper>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
              {/* Lista Packs */}
              {PacksCliente ? (
                <>
                  <Box
                    className="formTextField"
                    style={{
                      marginTop: 40,
                      height: 400,
                      paddingRight: "40px",
                      paddingLeft: "40px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      textAlign: "center",
                      overflowY: "auto",
                      backgroundColor: "transparent",
                    }}
                  >
                    <DataGrid
                      className={isMobile ? "tabela2" : "tabela"}
                      style={{ width: isMobile ? "150%" : "100%" }}
                      pageSize={10}
                      rowHeight={40}
                      //Colunas
                      columns={[
                        {
                          field: "id",
                          headerName: t("id"),
                          type: "number",
                          maxWidth: 70,
                          flex: 1,
                          hide: columnsHidden.includes("id"),
                        },
                        {
                          field: "nomePack",
                          headerName: t("nome"),
                          type: "string",
                          flex: 1,
                        },
                        // {
                        //   field: "categoria",
                        //   headerName: t("categoria"),
                        //   flex: 1,
                        //   type: "string",
                        // },
                        {
                          field: "num_tickets_utilizados",
                          headerName: t("numticketsutilizados"),
                          flex: 1,
                          type: "number",
                        },
                        {
                          field: "num_tickets",
                          headerName: t("numtickets"),
                          flex: 1,
                          type: "number",
                        },
                        // {
                        //   field: "preco",
                        //   headerName: t("preco"),
                        //   flex: 1,
                        //   type: "number",
                        // },
                      ]}
                      rows={packsUtilizador}
                      checkboxSelection={isMobile ? false : false}
                      onRowClick={(params) => {}}
                      getRowId={(row) => row.id}
                      //Idioma
                      localeText={
                        i18n.language == "pt"
                          ? ptBR.components.MuiDataGrid.defaultProps.localeText
                          : i18n.language == "es"
                          ? esES.components.MuiDataGrid.defaultProps.localeText
                          : enUS.components.MuiDataGrid.defaultProps.localeText
                      }
                    />
                  </Box>
                </>
              ) : null}
              {/* Lista filhos */}
              {familia ? (
                <>
                  <Box
                    className="formTextField"
                    style={{
                      marginTop: 40,
                      height: 330,
                      paddingRight: "40px",
                      paddingLeft: "40px",
                      maxWidth: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      textAlign: "center",
                      overflowY: "auto",
                      backgroundColor: "transparent",
                    }}
                  >
                    <DataGrid
                      className={isMobile ? "tabela2" : "tabela"}
                      style={{ width: isMobile ? "150%" : "100%" }}
                      pageSize={6}
                      rowHeight={30}
                      //Colunas
                      columns={[
                        {
                          field: "id",
                          headerName: t("id"),
                          type: "number",
                          maxWidth: 100,
                          flex: 1,
                          hide: columnsHidden.includes("id"),
                        },
                        {
                          field: "nome",
                          headerName: t("nome"),
                          type: "string",
                          flex: 1,
                        },
                        {
                          field: "nif",
                          headerName: t("nif"),
                          flex: 1,
                          type: "string",
                        },
                        {
                          field: "actions",
                          type: "actions",
                          headerName: t("acoes"),
                          width: 100,
                          cellClassName: "actions",
                          getActions: ({ id }) => {
                            return [
                              <GridActionsCellItem
                                icon={
                                  <FontAwesomeIcon
                                    style={{ fontSize: 18 }}
                                    icon={faPen}
                                  />
                                }
                                label="Save"
                                onClick={() => {
                                  // navigate("/editchilda", {
                                  //   state: {
                                  //     idPiscina: idPiscina,
                                  //     idCliente: idPai,
                                  //     idFilho: id,
                                  //   },
                                  // });

                                  setidFilho(id);
                                  getFilho({
                                    idFilho: id,
                                    id: funcionarioSelecionadoId,
                                  })
                                    .then((res) => {
                                      if (res.success) {
                                        let dados = res.data.data[0];
                                        setDadosOriginais2(dados);
                                        setNomeFilho(dados.nome);
                                        setNifFilho(dados.nif);
                                        setMoradaFilho(dados.morada);
                                        setCodigoPostalFilho(
                                          dados.codigo_postal
                                        );
                                        setNascimentoFilho(
                                          dados.data_nascimento_final
                                        );
                                        setGeneroFilho(dados.genero);

                                        setCarregamentoDadosCompleto(true);
                                      } else {
                                        if (res.status == 500) {
                                          navigate("/500");
                                        }
                                        toast.error(res.error);
                                      }
                                    })
                                    .catch((error) => {
                                      //toast.warn(t("erroWebservice"));
                                    });
                                  handleClickOpenEditarFilho();
                                }}
                              />,
                            ];
                          },
                        },
                      ]}
                      rows={Filhos}
                      checkboxSelection={isMobile ? false : false}
                      onRowClick={(params) => {}}
                      getRowId={(row) => row.id}
                      //Idioma
                      localeText={
                        i18n.language == "pt"
                          ? ptBR.components.MuiDataGrid.defaultProps.localeText
                          : i18n.language == "es"
                          ? esES.components.MuiDataGrid.defaultProps.localeText
                          : enUS.components.MuiDataGrid.defaultProps.localeText
                      }
                    />
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 0,
                      right: 0,
                      marginBottom: "100%",
                      zIndex: 999,
                    }}
                  >
                    <Button
                      className="btnEdicao"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${editarbtn})`,
                        width: 315,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClickOpenCriarFilho();
                      }}
                    >
                      <UserPlusIcon
                        style={{
                          width: "15px",
                          color: "white",
                          marginRight: "10px",
                        }}
                      />
                      {t("adicionarFilho")}
                    </Button>
                  </div>
                </>
              ) : null}

              {/* adicionar filho */}
              <Dialog
                open={CriarFilho}
                onClose={handleClickCloseCriarFilho}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    minWidth: isMobile ? "90%" : "50%",
                    height: 580,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("adicionarFilho")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                      zIndex: 2000,
                    }}
                    onClick={handleClickCloseCriarFilho}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  {freeze && (
                    <div
                      style={{
                        backgroundColor: "transparent",
                        position: "absolute",
                        width: window.innerWidth,
                        height: window.innerHeight,
                        zIndex: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        top: 0,
                      }}
                    >
                      <Dialog
                        open={true}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <DialogContent>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("aguarde")}
                          </Typography>
                          <CircularProgress
                            disableShrink
                            style={{ marginTop: 20 }}
                          />
                        </DialogContent>
                      </Dialog>
                    </div>
                  )}

                  <div className="formPop" style={{ marginTop: 30 }}>
                    <Box className="formTextField">
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            variant="standard"
                            label={t("nome")}
                            value={nomeFilho}
                            error={erroNomeFilho}
                            onChange={(e) => {
                              setNomeFilho(e.target.value);
                              setErroNomeFilho(false);
                            }}
                          />
                        </FormControl>
                        {/* NIPC */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            variant="standard"
                            label={t("nif")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9);
                            }}
                            value={nifFilho}
                            error={erroNifFilho}
                            helperText={mensagemErroNifFilho}
                            onChange={(e) => {
                              setNifFilho(e.target.value);
                              setErroNifFilho(false);
                            }}
                          />
                        </FormControl>
                        {/* Genero */}
                        <FormControl
                          className="formTextField3"
                          sx={{
                            display: "flex",
                            justifycontent: "flex-start",
                            alignitems: "flex-start",
                            marginTop: "5px",
                          }}
                        >
                          <FormLabel>{t("genero")}</FormLabel>
                          <RadioGroup
                            row
                            label={t("genero")}
                            defaultValue={generoFilho}
                            name="genero"
                            value={generoFilho}
                            onChange={(e) => {
                              setGeneroFilho(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="feminino"
                              control={<Radio size="small" />}
                              label={t("F")}
                            />
                            <FormControlLabel
                              value="masculino"
                              control={<Radio size="small" />}
                              label={t("M")}
                            />
                            <FormControlLabel
                              value="outro"
                              control={<Radio size="small" />}
                              label={t("O")}
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* Rua */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            variant="standard"
                            label={t("rua")}
                            value={moradaFilho}
                            error={erroMoradaFilho}
                            onChange={(e) => {
                              setMoradaFilho(e.target.value);
                              setErroMoradaFilho(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            type="text"
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            format="####-###"
                            value={codigoPostalFilho}
                            error={erroCodigoPostalFilho}
                            helperText={mensagemErroCodigoPostalFilho}
                            onChange={(e) => {
                              setCodigoPostalFilho(e.target.value);
                              setErroCodigoPostalFilho(false);
                              setMensagemErroCodigoPostalFilho("");
                            }}
                          />
                        </FormControl>
                        {/* Data de nascimento */}
                        <FormControl className="formTextField3" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disableFuture
                              label={t("dataNascimento")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={NascimentoFilho}
                              onChange={(e) => {
                                setNascimentoFilho(e);
                                setErroNascimentoFilho(false);
                                setMensagemErroNascimentoFilho("");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroNascimentoFilho}
                                  helperText={mensagemErroNascimentoFilho}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      validacao2();
                    }}
                  >
                    <PlusIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "white",
                        marginRight: "6px",
                      }}
                    />
                    {t("adicionar")}
                  </Button>
                </DialogActions>
              </Dialog>

              {/* editar filho */}
              <Dialog
                open={EditarFilho}
                onClose={handleClickCloseEditarFilho}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    minWidth: isMobile ? "90%" : "50%",
                    height: 580,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("editarFilho")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                      zIndex: 2000,
                    }}
                    onClick={handleClickCloseEditarFilho}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  {freeze && (
                    <div
                      style={{
                        backgroundColor: "transparent",
                        position: "absolute",
                        width: window.innerWidth,
                        height: window.innerHeight,
                        zIndex: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        top: 0,
                      }}
                    >
                      <Dialog
                        open={true}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <DialogContent>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("aguarde")}
                          </Typography>
                          <CircularProgress
                            disableShrink
                            style={{ marginTop: 20 }}
                          />
                        </DialogContent>
                      </Dialog>
                    </div>
                  )}

                  <div className="formPop" style={{ marginTop: 30 }}>
                    <Box className="formTextField">
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            disabled={edicao2}
                            type="text"
                            variant="standard"
                            label={t("nome")}
                            value={nomeFilho}
                            error={erroNomeFilho}
                            onChange={(e) => {
                              setNomeFilho(e.target.value);
                              setErroNomeFilho(false);
                            }}
                          />
                        </FormControl>
                        {/* NIPC */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao2}
                            variant="standard"
                            label={t("nif")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9);
                            }}
                            value={nifFilho}
                            error={erroNifFilho}
                            helperText={mensagemErroNifFilho}
                            onChange={(e) => {
                              setNifFilho(e.target.value);
                              setErroNifFilho(false);
                            }}
                          />
                        </FormControl>
                        {/* Genero */}
                        <FormControl
                          className="formTextField3"
                          disabled={edicao2}
                          sx={{
                            display: "flex",
                            justifycontent: "flex-start",
                            alignitems: "flex-start",
                            marginTop: "5px",
                          }}
                        >
                          <FormLabel>{t("genero")}</FormLabel>
                          <RadioGroup
                            row
                            label={t("genero")}
                            defaultValue={generoFilho}
                            name="genero"
                            value={generoFilho}
                            onChange={(e) => {
                              setGeneroFilho(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="feminino"
                              control={<Radio size="small" />}
                              label={t("F")}
                            />
                            <FormControlLabel
                              value="masculino"
                              control={<Radio size="small" />}
                              label={t("M")}
                            />
                            <FormControlLabel
                              value="outro"
                              control={<Radio size="small" />}
                              label={t("O")}
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* Rua */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao2}
                            required
                            type="text"
                            variant="standard"
                            label={t("rua")}
                            value={moradaFilho}
                            error={erroMoradaFilho}
                            onChange={(e) => {
                              setMoradaFilho(e.target.value);
                              setErroMoradaFilho(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            disabled={edicao2}
                            type="text"
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            format="####-###"
                            value={codigoPostalFilho}
                            error={erroCodigoPostalFilho}
                            helperText={mensagemErroCodigoPostalFilho}
                            onChange={(e) => {
                              setCodigoPostalFilho(e.target.value);
                              setErroCodigoPostalFilho(false);
                              setMensagemErroCodigoPostalFilho("");
                            }}
                          />
                        </FormControl>
                        {/* Data de nascimento */}
                        <FormControl className="formTextField3" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disableFuture
                              disabled={edicao2}
                              label={t("dataNascimento")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={NascimentoFilho}
                              onChange={(e) => {
                                setNascimentoFilho(e);
                                setErroNascimentoFilho(false);
                                setMensagemErroNascimentoFilho("");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroNascimentoFilho}
                                  helperText={mensagemErroNascimentoFilho}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  {edicao2 ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="btnEdicao"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${editarbtnpeq})`
                            : `url(${editarbtn})`,
                          width: isMobile ? 153 : 315,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setEdicao2(!edicao2);
                        }}
                      >
                        <PencilIcon
                          style={{
                            width: "15px",
                            color: "white",
                            marginRight: "10px",
                          }}
                        />

                        {t("editar")}
                      </Button>
                      <Button
                        className="btnEdicao"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${eliminarbtnpeq})`
                            : `url(${eliminarbtn})`,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClickOpenPopupEliminar2();
                        }}
                      >
                        <TrashIcon
                          style={{
                            width: "15px",
                            color: "white",
                            marginRight: "10px",
                          }}
                        />

                        {t("eliminar")}
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        className="btnGuardar"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${guardarbtnPeq})`
                            : `url(${guardarbtn})`,

                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          validacao2();
                        }}
                      >
                        {t("guardar")}
                      </Button>
                      <Button
                        className="btnGuardar"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${cancelarbtnPeq})`
                            : `url(${cancelarbtn})`,
                          width: isMobile ? 164 : 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClickOpenPopupCancelar2();
                        }}
                      >
                        {t("cancelar")}
                      </Button>
                    </div>
                  )}
                </DialogActions>
              </Dialog>
              {/* Eliminar filho */}
              <Dialog
                open={eliminar2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("eliminarCliente")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupEliminar2();
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("textoEliminarCliente")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      eliminarFilho();
                    }}
                    autoFocus
                  >
                    {t("sim")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupEliminar2();
                    }}
                  >
                    {t("nao")}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Cancelar edição Filho*/}
              <Dialog
                open={cancelarEdicao2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("cancelarEdicao")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("textoCancelarEdicao")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      cancelar2();
                    }}
                  >
                    {t("sim")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupCancelar2();
                    }}
                  >
                    {t("nao")}
                  </Button>
                </DialogActions>
              </Dialog>

              <Scrollbar style={{ height: isMobile ? "55%" : "65%" }}>
                {/* Aqui estao os dados carregados do Cliente, com os botoes de editar dados, ... */}
                {carregamentoDadosCompleto ? (
                  <>
                    <div className="divForm">
                      <div>
                        {motivo !== "" && statusCliente === 0 && (
                          <div
                            className="formPageSuspensao3"
                            style={{ borderRadius: "10px" }}
                          >
                            <Alert severity="error">
                              {t("cienteSuspenso")} - {t("motivo")}: {motivo}
                            </Alert>
                          </div>
                        )}

                        <div className="formPage2">
                          <Box className="formTextField">
                            {/* Piscina */}
                            <div style={{ marginBottom: 10 }}>
                              {/* Nome */}
                              <FormControl className="formTextField2" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("nome")}
                                  value={nomeCliente}
                                  error={erroNomeCliente}
                                  onChange={(e) => {
                                    setNomeCliente(e.target.value);
                                    setErroNomeCliente(false);
                                  }}
                                />
                              </FormControl>
                              {/* Email */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={true}
                                  variant="standard"
                                  label={t("email")}
                                  value={emailCliente}
                                  error={erroEmailCliente}
                                  helperText={mensagemErroEmailCliente}
                                  onChange={(e) => {
                                    setEmailCliente(
                                      e.target.value.toLocaleLowerCase()
                                    );
                                    setErroEmailCliente(false);
                                    setMensagemErroEmailCliente("");
                                  }}
                                />
                              </FormControl>
                              {/* NIF */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("nif")}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 9);
                                  }}
                                  value={nifCliente}
                                  error={erroNifCliente}
                                  helperText={mensagemErroNifCliente}
                                  onChange={(e) => {
                                    setNifCliente(e.target.value);
                                    setErroNifCliente(false);
                                  }}
                                />
                              </FormControl>

                              {/* Telefone */}
                              <FormControl className="formTextField3" fullWidth>
                                <PhoneInput
                                  disabled={edicao}
                                  defaultCountry="PT"
                                  labels={
                                    i18n.language == "pt"
                                      ? pt
                                      : i18n.language == "es"
                                      ? es
                                      : en
                                  }
                                  value={telefoneCliente}
                                  error={erroTelefoneCliente}
                                  helperText={mensagemErroTelefoneCliente}
                                  onChange={(e) => {
                                    setTelefoneCliente(e);
                                    setErroTelefoneCliente(false);
                                    setMensagemErroTelefoneCliente("");
                                  }}
                                  inputComponent={PhoneNumber}
                                />
                              </FormControl>
                              {/* Genero */}
                              <FormControl
                                className="formTextField3"
                                sx={{
                                  display: "flex",
                                  justifycontent: "flex-start",
                                  alignitems: "flex-start",
                                  marginTop: "5px",
                                }}
                                disabled={edicao}
                                required
                              >
                                <FormLabel
                                  sx={{
                                    display: "flex",
                                    justifycontent: "flex-start",
                                    alignitems: "flex-start",
                                  }}
                                >
                                  {t("genero")}
                                </FormLabel>

                                <RadioGroup
                                  row
                                  label={t("genero")}
                                  defaultValue={genero}
                                  name="genero"
                                  value={genero}
                                  onChange={(e) => {
                                    setGenero(e.target.value);
                                    seterroGenero(false);
                                  }}
                                >
                                  <FormControlLabel
                                    value="feminino"
                                    control={<Radio size="small" />}
                                    label={t("F")}
                                  />
                                  <FormControlLabel
                                    value="masculino"
                                    control={<Radio size="small" />}
                                    label={t("M")}
                                  />
                                  <FormControlLabel
                                    value="outro"
                                    control={<Radio size="small" />}
                                    label={t("O")}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {/* Rua */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("rua")}
                                  value={moradaCliente}
                                  error={erroMoradaCliente}
                                  onChange={(e) => {
                                    setMoradaCliente(e.target.value);
                                    setErroMoradaCliente(false);
                                  }}
                                />
                              </FormControl>
                              {/* Código Postal */}
                              <FormControl className="formTextField3" fullWidth>
                                <PatternFormat
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  {...{
                                    label: t("codigoPostal"),
                                    variant: "standard",
                                  }}
                                  customInput={TextField}
                                  required
                                  mask="_"
                                  format="####-###"
                                  value={codigoPostalCliente}
                                  error={erroCodigoPostalCliente}
                                  helperText={mensagemErroCodigoPostalCliente}
                                  onChange={(e) => {
                                    setCodigoPostalCliente(e.target.value);
                                    setErroCodigoPostalCliente(false);
                                    setMensagemErroCodigoPostalCliente("");
                                  }}
                                />
                              </FormControl>
                              {/* Data de nascimento */}
                              <FormControl className="formTextField3" fullWidth>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={i18n.language}
                                >
                                  <DatePicker
                                    disableFuture
                                    disabled={true}
                                    label={t("dataNascimento")}
                                    openTo="day"
                                    views={["day", "year", "month"]}
                                    value={NascimentoCliente}
                                    onChange={(e) => {
                                      setNascimentoCliente(e);
                                      setErroNascimentoCliente(false);
                                      setMensagemErroNascimentoCliente("");
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        variant="standard"
                                        {...params}
                                        required
                                        error={erroNascimentoCliente}
                                        helperText={
                                          mensagemErroNascimentoCliente
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              {/* Foto */}
                              <FormControl className="formTextField3" fullWidth>
                                <p
                                  id="tituloFotoPiscina"
                                  style={{
                                    marginTop: 10,
                                    color: Colors.textFields,
                                    fontSize: 15,
                                    fontWeight: "normal",
                                    textAlign: "left",
                                  }}
                                >
                                  {t("foto")} *
                                </p>
                                <label
                                  id="fotoPiscina"
                                  htmlFor="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="fa fa-cloud-upload"></i>
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    style={{ paddingTop: 4, paddingRight: 4 }}
                                  />{" "}
                                  {t("carregarImagem")}
                                </label>
                                <input
                                  disabled={edicao}
                                  id="file-upload"
                                  style={{ paddingTop: 0 }}
                                  type="file"
                                  accept="image/*"
                                  onChange={async (e) => {
                                    document.getElementById(
                                      "fotoPiscina"
                                    ).style.borderColor = Colors.ativo;
                                    document.getElementById(
                                      "tituloFotoPiscina"
                                    ).style.color = Colors.textFields;
                                    const file = e.target.files[0];

                                    const reader = new FileReader();

                                    reader.readAsDataURL(file);

                                    reader.onload = () => {
                                      setFotoCliente(reader.result);
                                    };
                                  }}
                                ></input>
                                {/* Imagem carregada */}
                                <div>
                                  {fotoCliente.length > 0 && (
                                    <img
                                      style={{ marginTop: 10 }}
                                      width={150}
                                      height={150}
                                      src={fotoCliente}
                                    />
                                  )}
                                </div>
                              </FormControl>
                            </div>
                          </Box>
                        </div>
                      </div>
                      {/* Cancelar edição Cliente*/}
                      <Dialog
                        open={cancelarEdicao}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                          style: {
                            width: 452,
                            height: 186,
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {t("cancelarEdicao")}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t("textoCancelarEdicao")}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            className="btnSim"
                            variant="contained"
                            style={{
                              backgroundImage: `url(${simbtn})`,
                              width: 218,
                              height: 40,
                              cursor: "pointer",
                              zIndex: 200,
                            }}
                            onClick={() => {
                              cancelar();
                            }}
                          >
                            {t("sim")}
                          </Button>
                          <Button
                            className="btnSim"
                            variant="contained"
                            style={{
                              backgroundImage: `url(${naobtn})`,
                              width: 198,
                              height: 30,
                              cursor: "pointer",
                              zIndex: 200,
                            }}
                            onClick={() => {
                              handleClosePopupCancelar();
                            }}
                          >
                            {t("nao")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    <div className="divForm">
                      <div className="formPage2">
                        <Box
                          className="formTextField"
                          style={{ marginTop: 40, height: 400 }}
                        >
                          <h3 className="titleList2">{t("listaTurmas")}</h3>
                          <div style={{ height: "470px", width: "100%" }}>
                            <DataGrid
                              className={isMobile ? "tabela2" : "tabela"}
                              pageSize={7}
                              rowHeight={30}
                              //Colunas
                              columns={[
                                // {
                                //   field: "id",
                                //   headerName: t("id"),
                                //   type: "number",
                                //   maxWidth: 70,
                                //   flex: 1,
                                //   hide: columnsHidden.includes("id"),
                                // },
                                {
                                  field: "nome",
                                  headerName: t("nome"),
                                  type: "string",
                                  flex: 1,
                                },
                                {
                                  field: "utilizador",
                                  headerName: t("cliente"),
                                  type: "string",
                                  flex: 1,
                                },
                                {
                                  field: "actions",
                                  type: "actions",
                                  headerName: t("acoes"),
                                  width: 100,
                                  cellClassName: "actions",
                                  getActions: ({ id }) => {
                                    return [
                                      <GridActionsCellItem
                                        icon={
                                          <FontAwesomeIcon
                                            style={{ fontSize: 18 }}
                                            icon={faX}
                                          />
                                        }
                                        label="Save"
                                        onClick={() => {
                                          setIdTurma(id);
                                          handleClickOpenPopupTurma();
                                        }}
                                      />,
                                    ];
                                  },
                                },
                              ]}
                              rows={turmas}
                              checkboxSelection={false}
                              onRowClick={(params) => {}}
                              getRowId={(row) => row.id}
                              //Idioma
                              localeText={
                                i18n.language == "pt"
                                  ? ptBR.components.MuiDataGrid.defaultProps
                                      .localeText
                                  : i18n.language == "es"
                                  ? esES.components.MuiDataGrid.defaultProps
                                      .localeText
                                  : enUS.components.MuiDataGrid.defaultProps
                                      .localeText
                              }
                            />
                          </div>
                        </Box>
                      </div>
                    </div>
                    <div className="divForm">
                      <div className="formPage2">
                        <Box
                          className="formTextField"
                          style={{ marginTop: 40, height: 400 }}
                        >
                          <h3 className="titleList2">{t("listaMarcacoes")}</h3>
                          <div style={{ height: "470px", width: "100%" }}>
                            <DataGrid
                              className={isMobile ? "tabela2" : "tabela"}
                              pageSize={7}
                              rowHeight={30}
                              //Colunas
                              columns={[
                                // {
                                //   field: "id",
                                //   headerName: t("id"),
                                //   type: "number",
                                //   maxWidth: 70,
                                //   flex: 1,
                                //   hide: columnsHidden.includes("id"),
                                // },
                                {
                                  field: "title",
                                  headerName: t("nome"),
                                  type: "string",
                                  flex: 1,
                                },

                                {
                                  field: "dataFinal",
                                  headerName: t("dataHora"),
                                  flex: 1,
                                  type: "date",
                                  hide: columnsHidden.includes("id"),
                                },

                                {
                                  field: "utilizador",
                                  headerName: t("cliente"),
                                  flex: 1,
                                  type: "string",
                                  hide: columnsHidden.includes("id"),
                                },

                                // {
                                //   field: "end",
                                //   headerName: t("horafim"),
                                //   flex: 1,
                                //   type: "date",
                                //   hide: columnsHidden.includes("id"),
                                // },
                                {
                                  field: "actions",
                                  type: "actions",
                                  headerName: t("acoes"),
                                  width: 100,
                                  cellClassName: "actions",
                                  getActions: ({ id }) => {
                                    return [
                                      <GridActionsCellItem
                                        icon={
                                          <FontAwesomeIcon
                                            style={{ fontSize: 18 }}
                                            icon={faX}
                                          />
                                        }
                                        label="Cancel"
                                        onClick={() => {
                                          setIdMarcacao(id);
                                          handleClickOpenPopupMarcacao();
                                        }}
                                      />,
                                    ];
                                  },
                                },
                              ]}
                              rows={marcacoes}
                              checkboxSelection={false}
                              onRowClick={(params) => {}}
                              getRowId={(row) => row.id}
                              //Idioma
                              localeText={
                                i18n.language == "pt"
                                  ? ptBR.components.MuiDataGrid.defaultProps
                                      .localeText
                                  : i18n.language == "es"
                                  ? esES.components.MuiDataGrid.defaultProps
                                      .localeText
                                  : enUS.components.MuiDataGrid.defaultProps
                                      .localeText
                              }
                            />
                          </div>
                        </Box>
                      </div>
                    </div>
                    <div className="divForm">
                      <div className="formPage2">
                        <Box
                          className="formTextField"
                          style={{ marginTop: 40 }}
                        >
                          <h3 className="titleList2">{t("cronologia")}</h3>
                          <div style={{ height: "auto", width: "100%" }}>
                            <ThemeProvider theme={theme}>
                              <Timeline
                                sx={{
                                  [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                  },
                                }}
                              >
                                {suspensoes.map((item, index) => (
                                  <TimelineItem key={item.id_suspensao}>
                                    <TimelineSeparator>
                                      <TimelineConnector />
                                      <TimelineDot
                                        color={
                                          item.tipo === 1
                                            ? "primary"
                                            : "secondary"
                                        }
                                      >
                                        {mapIcon(item.tipo)}
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px" }}>
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="flex-start"
                                      >
                                        <Grid item>
                                          <Typography
                                            variant="h6"
                                            component="span"
                                          >
                                            {item.tipo === 1
                                              ? "Suspensão"
                                              : "Ativação"}
                                          </Typography>
                                          <br></br>
                                          <Typography variant="text">
                                            {t("motivo")}:{" "}
                                          </Typography>
                                          <Typography variant="text">
                                            {item.obs}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="body2">
                                            {formatDateAndTime(item.criado)}
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            {item.nome_resp}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TimelineContent>
                                  </TimelineItem>
                                ))}
                              </Timeline>
                            </ThemeProvider>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      backgroundColor: "transparent",
                      marginTop: "25%",
                      zIndex: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: 0,
                    }}
                  >
                    <CircularProgress disableShrink />
                  </div>
                )}
              </Scrollbar>
              {/* Eliminar Cliente */}
              <Dialog
                open={eliminar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("eliminarCliente")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupEliminar();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                {anular ? (
                  <DialogContentText
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{
                        marginTop: 20,
                      }}
                    />
                  </DialogContentText>
                ) : (
                  <>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoEliminarCliente")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setAnular(true);
                          anularInscricao();
                          setSelecionado(null);
                        }}
                        autoFocus
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                          setSelecionado(null);
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
              {/* Repor password cliente */}
              <Dialog
                open={reporPassword}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 220,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("reenviarAtivacao")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupReporPassword();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                {reporPwd ? (
                  <DialogContentText
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{
                        marginTop: 20,
                      }}
                    />
                  </DialogContentText>
                ) : (
                  <>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoReporPasswordC")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setReporPwd(true);
                          enviarEmail();
                          setSelecionado(null);
                        }}
                        autoFocus
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupReporPassword();
                          setSelecionado(null);
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
              {/* Suspender Cliente */}
              <Dialog
                open={suspender}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {statusCliente ? t("suspenderCliente") : t("ativarCliente")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupSuspender();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  {statusCliente
                    ? t("justificacaoSuspencao")
                    : t("justificacaoAtivacao")}{" "}
                  <FormControl fullWidth>
                    <TextField
                      style={{ textAlign: "left", marginTop: 10 }}
                      variant="outlined"
                      multiline
                      rows={5}
                      value={motivoSuspensao}
                      error={erroMotivoSuspensao}
                      onChange={(e) => {
                        setMotivoSuspensao(e.target.value);
                        setErroMotivoSuspensao(false);
                      }}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, e.target.value)
                      //     .toString()
                      //     .slice(0, 500);
                      // }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupSuspender();
                      setSelecionado(null);
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      suspenderCliente();
                    }}
                    autoFocus
                  >
                    {statusCliente ? t("suspender") : t("ativar")}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Parte dos botoes de editar dados cliente */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {edicao ? (
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${editarbtn})`,
                      width: 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />
                    {t("editar")}
                  </Button>
                ) : (
                  <div>
                    <Button
                      className="btnGuardar"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${guardarbtn})`,

                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        validacao();
                      }}
                    >
                      {t("guardar")}
                    </Button>
                    <Button
                      className="btnGuardar"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${cancelarbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClickOpenPopupCancelar();
                      }}
                    >
                      {t("cancelar")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                className="col-0"
                style={{
                  textAlign: "start",
                  marginTop: 45,
                  marginLeft: 30,
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <span
                  className="addButton"
                  style={{ zIndex: "1" }}
                  onClick={() => {
                    handleClickOpenCriarCliente();
                  }}
                ></span>
                <Search2
                  onSearch={(value) => {
                    setSearch(value);
                  }}
                />
              </div>
              {/* tabela dos clientes todos */}
              <div style={{ height: "62vh", maxWidth: "100%" }}>
                <DataGrid
                  className={isMobile ? "tabela2" : "tabela"}
                  pageSize={10}
                  rowHeight={40}
                  //Colunas
                  columns={[
                    {
                      field: "id_utilizador",
                      headerName: t("id"),
                      type: "number",
                      width: 5,
                      hide: columnsHidden.includes("id"),
                    },
                    {
                      field: "nome",
                      headerName: t("nome"),
                      type: "string",
                      flex: 1,
                    },
                    {
                      field: "nif",
                      headerName: t("nif"),
                      type: "string",
                      flex: 1,
                    },

                    {
                      field: "email",
                      headerName: t("email"),
                      flex: 1,
                      type: "string",
                      hide: columnsHidden.includes("email"),
                    },
                    {
                      field: "status",
                      headerName: t("estado"),
                      maxWidth: 100,
                      flex: 1,
                      type: "string",

                      hide: columnsHidden.includes("status"),

                      renderCell: (params) => {
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {params.row.status == 1 ? (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    marginTop: "20px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {t("ativo")}
                                </p>
                                <FontAwesomeIcon
                                  color={Colors.ativo}
                                  fontSize={25}
                                  icon={faCircleCheck}
                                />
                              </>
                            ) : (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    marginTop: "20px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {t("inativo")}
                                </p>{" "}
                                <FontAwesomeIcon
                                  color={Colors.inativo}
                                  fontSize={25}
                                  icon={faCircleXmark}
                                />
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      field: "actions",
                      type: "actions",
                      headerName: t("acoes"),
                      width: 70,
                      cellClassName: "actions",
                      getActions: ({ id }) => {
                        return [
                          <GridActionsCellItem
                            icon={
                              <FontAwesomeIcon
                                style={{ fontSize: 18 }}
                                icon={faPen}
                              />
                            }
                            label="Save"
                            onClick={() => {
                              setFuncionarioSelecionadoId(id);
                              setEditarCliente(true);
                            }}
                          />,
                        ];
                      },
                    },
                  ]}
                  rows={clientes}
                  checkboxSelection={isMobile ? false : true}
                  getRowId={(row) => row.id}
                  //Idioma
                  localeText={
                    i18n.language == "pt"
                      ? ptBR.components.MuiDataGrid.defaultProps.localeText
                      : i18n.language == "es"
                      ? esES.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText
                  }
                  components={{
                    Toolbar: CustomToolbar,
                    BaseCheckbox: ({ value, ...otherProps }) => (
                      <Checkbox size="small" checked={value} {...otherProps} />
                    ),
                  }}
                />
              </div>
            </div>
          )}
          {/* adicionar cliente */}
          <Dialog
            open={openCriarCliente}
            onClose={handleCloseCriarCliente}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: 500,
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarCliente")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarCliente}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div className="formPop" style={{ marginTop: 30 }}>
                <Box className="formTextField">
                  <div>
                    {/* Nome */}
                    <FormControl className="formTextField3" fullWidth>
                      <TextField
                        required
                        type="text"
                        variant="standard"
                        label={t("nome")}
                        value={nomeCliente}
                        error={erroNomeCliente}
                        onChange={(e) => {
                          setNomeCliente(e.target.value);
                          setErroNomeCliente(false);
                        }}
                      />
                    </FormControl>
                    {/* Email */}
                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="text"
                        variant="standard"
                        label={t("email")}
                        value={emailCliente}
                        error={erroEmailCliente}
                        helperText={mensagemErroEmailCliente}
                        onChange={(e) => {
                          setEmailCliente(e.target.value.toLocaleLowerCase());
                          setErroEmailCliente(false);
                          setMensagemErroEmailCliente("");
                        }}
                      />
                    </FormControl>
                    {/* Telefone */}
                    <FormControl className="formTextField3" fullWidth>
                      <PhoneInput
                        defaultCountry="PT"
                        labels={
                          i18n.language == "pt"
                            ? pt
                            : i18n.language == "es"
                            ? es
                            : en
                        }
                        value={telefoneCliente}
                        error={erroTelefoneCliente}
                        helperText={mensagemErroTelefoneCliente}
                        onChange={(e) => {
                          setTelefoneCliente(e);
                          setErroTelefoneCliente(false);
                          setMensagemErroTelefoneCliente("");
                        }}
                        inputComponent={PhoneNumber}
                      />
                    </FormControl>
                    {/* Data de Nascimento */}
                    <FormControl className="formTextField3" fullWidth>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <DatePicker
                          disableFuture
                          label={t("dataNascimento") + " *"}
                          openTo="day"
                          views={["year", "month", "day"]}
                          value={dataNascimento}
                          minDate={new Date(1900, 0, 1)}
                          onChange={(e) => {
                            setDataNascimento(e);
                            setErroDataNascimento(false);
                            setMensagemErroDataNascimento("");
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              required
                              error={erroDataNascimento}
                              helperText={mensagemErroDataNascimento}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    {/* Genero */}
                    <FormControl
                      className="formTextField3"
                      sx={{
                        display: "flex",
                        justifycontent: "flex-start",
                        alignitems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      <FormLabel>{t("genero")}</FormLabel>
                      <RadioGroup
                        row
                        label={t("genero")}
                        defaultValue={genero}
                        name="genero"
                        value={genero}
                        onChange={(e) => {
                          setGenero(e.target.value);
                          seterroGenero(false);
                        }}
                      >
                        <FormControlLabel
                          value="feminino"
                          control={<Radio size="small" />}
                          label={t("F")}
                        />
                        <FormControlLabel
                          value="masculino"
                          control={<Radio size="small" />}
                          label={t("M")}
                        />
                        <FormControlLabel
                          value="outro"
                          control={<Radio size="small" />}
                          label={t("O")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>
          {/*Anular marcacao */}
          <Dialog
            open={marcacaop}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarmarcacao")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={() => {
                  handleClosePopupMarcacao();
                }}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarmarcacao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="btnSim"
                variant="contained"
                style={{
                  backgroundImage: `url(${simbtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  setLoading2(true);

                  cancelarMarcacao({
                    id: idMarcacao,
                    id_cliente: funcionarioSelecionadoId,
                  })
                    .then((res) => {
                      if (res.success) {
                        setTimeout(() => {
                          emailCancelarMarcacao({
                            id_ticket: idMarcacao,
                            id_cliente: funcionarioSelecionadoId,
                          }).then((res2) => {
                            toast.success(t("marcacaoCanceladaSucesso"));
                            handleClosePopupMarcacao();
                            LimparVariaveis();
                          });
                        }, 3000);
                      } else {
                        toast.error(t("marcacaoCanceladaErro"));
                      }
                    })
                    .catch((error) => {});
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                className="btnSim"
                variant="contained"
                style={{
                  backgroundImage: `url(${naobtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={handleClosePopupMarcacao}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          {/*Anular inscrição de turma */}
          <Dialog
            open={turmap}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                width: 452,
                height: 186,
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarturma")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={() => {
                  handleClosePopupTurma();
                }}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarturma")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="btnSim"
                variant="contained"
                style={{
                  backgroundImage: `url(${simbtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  setLoading2(true);
                  cancelarInscricao({
                    id_turma: idTurma,
                    id_cliente: funcionarioSelecionadoId,
                    id_filho: null,
                  })
                    .then((res) => {
                      if (res.success) {
                        setTimeout(() => {
                          emailCancelarInscricao({
                            id_turma: idTurma,
                            id_cliente: funcionarioSelecionadoId,
                          }).then((res2) => {
                            toast.success(t("inscricaoCanceladaSucesso"));
                            LimparVariaveis();
                            handleClosePopupTurma();
                          });
                        }, 3000);
                      } else {
                        toast.error(t("inscricaoCanceladaErro"));
                      }
                    })
                    .catch((error) => {});
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                className="btnSim"
                variant="contained"
                style={{
                  backgroundImage: `url(${naobtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={handleClosePopupTurma}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
export default ClientesPiscina;
