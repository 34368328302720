import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import Nav from "../../components/Nav";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/bundle";
import { startOfWeek, endOfWeek, format } from "date-fns";

import "swiper/css/bundle";

import {
  faPen,
  faTicketSimple,
  faWaterLadder,
  faTags,
  faQrcode,
  faBuilding,
  faCalendarCheck,
  faPerson,
  faTriangleExclamation,
  faChalkboardTeacher,
  faSchool,
  faGraduationCap,
  faCancel,
  faCity,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  MenuItem,
  Switch,
  RadioGroup,
  Radio,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button } from "@mui/material";
import { Box, borderColor, margin, padding } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { getInscricoesId } from "../../api/requests/Utilizadores";
import { getPiscinas } from "../../api/requests/Piscinas";
import { getNumPedidos } from "../../api/requests/Pedidos";
import { red } from "@mui/material/colors";
import {
  OcupacaoHorasDias,
  statsFaturacaoMensal,
  statsFaturacaoMensalComparar,
  statsFunc,
  statsNumAlunosProf,
  statsNumClientes,
  statsNumClientesGenero,
  statsFaturacaoModalidades,
  comparaModalidadesClientes,
} from "../../api/requests/Dashboards";
import Chart from "chart.js/auto";
import { CategoryScale, LinearScale, Title, BarController } from "chart.js";
import { Doughnut, Line, Bar } from "react-chartjs-2";

function Dashboard_Admin() {
  //Traduções
  const { t, i18n } = useTranslation();
  const currentDate = new Date();
  //Navigator
  const navigate = useNavigate();
  const [idPiscina, setIdPiscina] = useState("");
  const [idEntidade, setIdentidade] = useState("");
  const [piscinas, setPiscinas] = useState([]);
  const [inscritas, setInscritas] = useState([]);
  const [selectedPool, setSelectedPool] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [nomeMesAtual, setNomeMesAtual] = useState("");
  const [valorFaturacao, setValorFaturacao] = useState(0);
  const [valorFaturacaoCategoria, setValorFaturacaoCategoria] = useState([]);

  const [totalAlunos, setTotalAlunos] = useState([]);
  const [totalProfessores, setTotalProfessores] = useState([]);
  const [totalHoras, setTotalHoras] = useState([]);
  const [horas, setHoras] = useState([]);
  const [ocupacao, setOcupacao] = useState([]);
  const [ocupacoes, setOcupacoes] = useState([]);
  const [max, setMaxCapacidade] = useState(0);

  const [totalClientesGenero, setTotalClientesGenero] = useState([]);
  const [totalGenero, setTotalGenero] = useState([]);

  const [totalModalidades, setTotalModalidades] = useState([]);
  const [totalFaturacaoModalidades, setTotalFaturacaoModalidades] = useState(
    []
  );

  const [totalClientesModalidades, setTotalClientesModalidades] = useState([]);
  const [totalClientesModalidadesAtual, setTotalClientesModalidadesAtual] =
    useState([]);
  const [totalClientesModalidadesPassado, setTotalClientesModalidadesPassado] =
    useState([]);

  const [numClientes, setNumClientes] = useState(0);
  const [numClientesCategoria, setNumClientesCategoria] = useState([]);
  const [data, setData] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [profs, setProfs] = useState([]);
  const [valores, setValores] = useState([]);
  const [funcs, setFuncs] = useState([]);
  const [valores1, setValores1] = useState([]);
  const chartRef = useRef(null);
  const [categorias, setCategorias] = useState([]);

  const [faturacaoAnoPassado, setFaturacaoAnoPassado] = useState(0);
  const [faturacaoMesPassado, setFaturacaoMesPassado] = useState(0);

  const [clientesAnoPassado, setClientesAnoPassado] = useState(0);
  const [clientesMesPassado, setClientesMesPassado] = useState(0);
  const [clientesSemanaPassada, setClientesSemanaPassada] = useState(0);

  const [selectedOption, setSelectedOption] = useState("month"); // Pode ser 'month' ou 'year'
  const [selectedOptionOcupacao, setSelectedOptionOcupacao] = useState("year"); // Pode ser 'month' ou 'year'

  const [selectedOptionCliente, setSelectedOptionCliente] = useState("month"); // Pode ser 'month' ou 'year'
  const [selectedOptionDia, setSelectedOptionDia] = useState("day"); // Pode ser 'month' ou 'year'
  const [selectedOptionStats, setSelectedOptionStats] = useState("month");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isPhone, setIsPhone] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize2 = () => {
      setIsPhone(window.innerWidth < 500);
    };

    window.addEventListener("resize2", handleResize2);

    return () => {
      window.removeEventListener("resize2", handleResize2);
    };
  }, []);

  const swiper = new Swiper(".swiper", {
    direction: "horizontal",
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    slideToClickedSlide: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const isNegative = faturacaoMesPassado < 0;

  const paragraphStyle = {
    color: isNegative ? "#F04643" : "#33D77A",
    fontSize: "12px",
  };

  const arrowIcon = isNegative ? "↙" : "↗";

  const isNegativeAno = faturacaoAnoPassado < 0;

  const paragraphStyleAno = {
    color: isNegativeAno ? "#F04643" : "#33D77A",
    fontSize: "12px",
  };

  const arrowIconAno = isNegativeAno ? "↙" : "↗";

  const chartRef2 = useRef(null);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getInscricoesId(id).then((res2) => {
      setNomeMesAtual(obterNomeMesAtual(i18n.language));

      if (res2.success) {
        let arrayFinal2 = [];

        res2.data.data.forEach((element2) => {
          arrayFinal2.push(element2);
        });

        setInscritas(arrayFinal2);
        const selectedPoolId = localStorage.getItem("idPiscina");
        const selectedEntityId = localStorage.getItem("idEntidade");
        if (selectedPoolId && selectedEntityId) {
          arrayFinal2.find((piscina) => {
            if (piscina.id_piscina == selectedPoolId) {
              setSelectedPool(piscina);
              setIdPiscina(piscina.id_piscina);
              setIdentidade(piscina.id_entidade_legal);
              setShowButtons(true);

              getNumPedidos({ id_piscina: piscina.id_piscina })
                .then((res) => {
                  if (res.success) {
                    setNumPedidosPendentes(res.data.data);
                  } else {
                    if (res.status == 500) {
                      navigate("/500");
                    }
                    toast.error(res.error);
                  }
                })
                .catch((error) => {
                  //toast.warn(t("erroWebservice"));
                });

              statsFaturacaoMensalComparar({
                id_entidade_legal: piscina.id_entidade_legal,
              })
                .then((res) => {
                  if (res.success) {
                    setValorFaturacao(res.data.data.faturacao);
                    if (!res.data.data.CompararMesPassado == "NaN") {
                      // setFaturacaoMesPassado(res.data.data.CompararMesPassado);
                    }
                    if (!res.data.data.CompararAnoPassado == "NaN") {
                      // setFaturacaoAnoPassado(res.data.data.CompararAnoPassado);
                    }
                  } else {
                    if (res.status == 500) {
                      navigate("/500");
                    }
                    toast.error(res.error);
                  }
                })
                .catch((error) => {
                  //toast.warn(t("erroWebservice"));
                });
              selectedOption == "year"
                ? statsFaturacaoMensal({
                    id_entidade_legal: piscina.id_entidade_legal,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setValorFaturacaoCategoria(res.data.data.categorias);
                       
                        setFaturacaoAnoPassado(res.data.data.ComparaAno)
                        setFaturacaoMesPassado(res.data.data.comparaMes)
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : statsFaturacaoMensal({
                    id_entidade_legal: piscina.id_entidade_legal,
                    mes: new Date().getMonth() + 1,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setValorFaturacaoCategoria(res.data.data.categorias);
                        setFaturacaoAnoPassado(res.data.data.comparaAno)
                        setFaturacaoMesPassado(res.data.data.comparaMes)
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    });

              selectedOption == "year"
                ? statsFaturacaoModalidades({
                    id_entidade_legal: piscina.id_entidade_legal,

                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalModalidades(res.data.data.modalidades);
                        setTotalFaturacaoModalidades(res.data.data.valores);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : statsFaturacaoModalidades({
                    id_entidade_legal: piscina.id_entidade_legal,
                    mes: new Date().getMonth() + 1,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalModalidades(res.data.data.modalidades);

                        setTotalFaturacaoModalidades(res.data.data.valores);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    });

              selectedOptionStats == "year"
                ? comparaModalidadesClientes({
                    id_entidade_legal: piscina.id_entidade_legal,

                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalClientesModalidades(res.data.modalidades);
                        setTotalClientesModalidadesAtual(res.data.data.valores);
                        setTotalClientesModalidadesPassado(
                          res.data.data.valoresPassado
                        );
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : comparaModalidadesClientes({
                    id_entidade_legal: piscina.id_entidade_legal,
                    mes: new Date().getMonth() + 1,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalClientesModalidades(res.data.modalidades);
                        setTotalClientesModalidadesAtual(res.data.data.valores);
                        setTotalClientesModalidadesPassado(
                          res.data.data.valoresPassado
                        );
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    });

              const dataAtual = new Date();
              const anoAtual = dataAtual.getFullYear();
              // Obter o mês atual
              const mesAtual = (dataAtual.getMonth() + 1)
                .toString()
                .padStart(2, "0");

              const diaAtual = dataAtual.getDate();
              OcupacaoHorasDias({
                id_entidade_legal: piscina.id_entidade_legal,
                id_piscina: piscina.id_piscina,
                mes: mesAtual,
                ano: anoAtual,
                dia: diaAtual,
              })
                .then((res) => {
                  if (res.success) {
                    const data = new Date();

                    // Obter a hora atual sem interferir nas variáveis existentes
                    const horaA = data.getHours();
                    const minutosAtuais = data.getMinutes();

                    // Formatar a hora e os minutos como desejado
                    const horaAtual = `${horaA
                      .toString()
                      .padStart(2, "0")}:${minutosAtuais
                      .toString()
                      .padStart(2, "0")}`;

                    const [horaAtualHH, horaAtualMM] = horaAtual.split(":");
                    const dataAtual = new Date();
                    dataAtual.setHours(horaAtualHH, horaAtualMM);

                    // Encontrar a posição da hora atual no array de horas
                    const indiceHoraAtual = res.data.horas.findIndex(
                      (hora) => hora === `${dataAtual.getHours()}h`
                    );

                    // Verificar a ocupação na hora atual
                    const ocupacaoAtual = res.data.ocupacoes[indiceHoraAtual];

                    // Calcular a taxa de ocupação
                    const taxaDeOcupacao =
                      (ocupacaoAtual / res.data.capacidade) * 100;

                    setOcupacao(ocupacaoAtual);
                    setOcupacoes(res.data.ocupacoes)
                    setHoras(res.data.horas);
                    setMaxCapacidade(res.data.capacidade);
                  } else {
                    if (res.status == 500) {
                      navigate("/500");
                    }
                    toast.error(res.error);
                  }
                })
                .catch((error) => {
                  //toast.warn(t("erroWebservice"));
                });
              selectedOptionCliente == "month"
                ? statsNumClientes({
                    id_piscina: piscina.id_piscina,
                    mes: new Date().getMonth() + 1,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setClientesMesPassado(res.data.comparaMes)
                        setClientesAnoPassado(res.data.ComparaAno)
                        setNumClientesCategoria(res.data.categorias);
                        setNumClientes(res.data.data);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : statsNumClientes({
                    id_piscina: piscina.id_piscina,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setClientesMesPassado(res.data.comparaMes)
                        setClientesAnoPassado(res.data.ComparaAno)
                        setNumClientesCategoria(res.data.categorias);
                        setNumClientes(res.data.data);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    });
              selectedOptionCliente == "month"
                ? statsNumClientesGenero({
                    id_piscina: piscina.id_piscina,
                    mes: new Date().getMonth() + 1,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalClientesGenero(res.data.counts);
                        setTotalGenero(res.data.generos);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : selectedOptionCliente == "year"
                ? statsNumClientesGenero({
                    id_piscina: piscina.id_piscina,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalClientesGenero(res.data.counts);
                        setTotalGenero(res.data.generos);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : statsNumClientesGenero({
                    id_piscina: piscina.id_piscina,
                    mes: new Date().getMonth() + 1,
                    ano: new Date().getFullYear(),
                    semana: `${formattedFirstDayOfWeek}, ${formattedLastDayOfWeek}`,
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalClientesGenero(res.data.counts);
                        setTotalGenero(res.data.generos);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    });
              selectedOptionOcupacao == "month"
                ? statsNumAlunosProf({
                    id_piscina: piscina.id_piscina,
                    ano: new Date().getFullYear(),
                    mes: new Date().getMonth() + 1,
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalAlunos(res.data.totalAlunos);
                        setTotalHoras(res.data.totalHoras);
                        setTotalProfessores(res.data.totalProfessores);

                        let arrayF = [];
                        res.data.data.forEach((ele) => {
                          arrayF.push(ele);
                        });
                        setAlunos(arrayF);

                        let profs = [];
                        profs = arrayF.map((item) => Object.values(item)[0]);
                        let valores = [];
                        valores = arrayF.map((item) => Object.values(item)[2]);
                        setProfs(profs);
                        setValores(valores);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    })
                : statsNumAlunosProf({
                    id_piscina: piscina.id_piscina,
                    ano: new Date().getFullYear(),
                  })
                    .then((res) => {
                      if (res.success) {
                        setTotalAlunos(res.data.totalAlunos);
                        setTotalHoras(res.data.totalHoras);
                        setTotalProfessores(res.data.totalProfessores);

                        let arrayF = [];
                        res.data.data.forEach((ele) => {
                          arrayF.push(ele);
                        });
                        setAlunos(arrayF);

                        let profs = [];
                        profs = arrayF.map((item) => Object.values(item)[0]);
                        let valores = [];
                        valores = arrayF.map((item) => Object.values(item)[2]);
                        setProfs(profs);
                        setValores(valores);
                      } else {
                        if (res.status == 500) {
                          navigate("/500");
                        }
                        toast.error(res.error);
                      }
                    })
                    .catch((error) => {
                      //toast.warn(t("erroWebservice"));
                    });

              // statsFunc({ id_piscina: piscina.id_piscina })
              //   .then((res) => {
              //     if (res.success) {
              //       let array1 = [];
              //       res.data.data.forEach((ele1) => {
              //         array1.push(ele1);
              //       });

              //       let funcs = [];
              //       funcs = array1.map((item) => Object.values(item)[0]);
              //       let valores1 = [];
              //       valores1 = array1.map((item) => Object.values(item)[1]);
              //       setFuncs(funcs);
              //       setValores1(valores1);
              //     } else {
              //       if (res.status == 500) {
              //         navigate("/500");
              //       }
              //       toast.error(res.error);
              //     }
              //   })
              //   .catch((error) => {
              //     //toast.warn(t("erroWebservice"));
              //   });
            }
          });
        }

        if (arrayFinal2.length == 0) {
          setShowButtons(false);
          toast.error(t("semPiscinas"));
        } else if (arrayFinal2.length == 1) {
          setShowButtons(true);
          setSelectedPool(arrayFinal2[0]);
          setIdPiscina(arrayFinal2[0].id_piscina);
          setIdentidade(arrayFinal2[0].id_entidade_legal);
          localStorage.setItem("idPiscina", arrayFinal2[0].id_piscina);
          localStorage.setItem("idEntidade", arrayFinal2[0].id_entidade_legal);

          getNumPedidos({ id_piscina: arrayFinal2[0].id_piscina })
            .then((res) => {
              if (res.success) {
                setNumPedidosPendentes(res.data.data);
              } else {
                if (res.status == 500) {
                  navigate("/500");
                }
                toast.error(res.error);
              }
            })
            .catch((error) => {
              //toast.warn(t("erroWebservice"));
            });

          // statsNumClientes({ id_piscina: arrayFinal2[0].id_piscina })
          //   .then((res) => {
          //     if (res.success) {
          //       setNumClientes(res.data.data);
          //     } else {
          //       if (res.status == 500) {
          //         navigate("/500");
          //       }
          //       toast.error(res.error);
          //     }
          //   })
          //   .catch((error) => {
          //     //toast.warn(t("erroWebservice"));
          //   });

          // statsNumAlunosProf({ id_piscina: arrayFinal2[0].id_piscina })
          //   .then((res) => {
          //     if (res.success) {
          //       let array = [];
          //       res.data.data.forEach((element) => {
          //         array.push(element);
          //       });
          //       setAlunos(array);
          //       let profs = [];
          //       profs = array.map((item) => Object.values(item)[0]);
          //       let valores = [];
          //       valores = array.map((item) => Object.values(item)[2]);
          //       setProfs(profs);
          //       setValores(valores);
          //     } else {
          //       if (res.status == 500) {
          //         navigate("/500");
          //       }
          //       toast.error(res.error);
          //     }
          //   })
          //   .catch((error) => {
          //     //toast.warn(t("erroWebservice"));
          //   });

          // statsFunc({ id_piscina: arrayFinal2[0].id_piscina })
          //   .then((res) => {
          //     if (res.success) {
          //       let array1 = [];
          //       res.data.data.forEach((ele1) => {
          //         array1.push(ele1);
          //       });

          //       let funcs = [];
          //       funcs = array1.map((item) => Object.values(item)[0]);
          //       let valores1 = [];
          //       valores1 = array1.map((item) => Object.values(item)[1]);
          //       setFuncs(funcs);
          //       setValores1(valores1);
          //     } else {
          //       if (res.status == 500) {
          //         navigate("/500");
          //       }
          //       toast.error(res.error);
          //     }
          //   })
          //   .catch((error) => {
          //     //toast.warn(t("erroWebservice"));
          //   });
        } else {
          setShowButtons(false);
        }
      } else {
        toast.error(res2.error);
      }
    });
  }, [
    selectedOptionCliente,
    selectedOptionOcupacao,
    selectedOptionStats,
    selectedOption,
  ]);

  const dataGraficoFaturacao = {
    labels: [
      "Recém-nascidos",
      "Bebes",
      "Crianças",
      "Jovens",
      "Adultos",
      "Reformados",
    ],
    datasets: [
      {
        data: valorFaturacaoCategoria,
        backgroundColor: [
          "rgba(10, 86, 250, 1)",
          "rgba(10, 86, 250, 0.85)",
          "rgba(10, 86, 250, 0.6)",
          "rgba(10, 86, 250, 0.45)",
          "rgba(10, 86, 250, 0.3)",
          "rgba(10, 86, 250, 0.15)",
        ],
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "#e7f0f8",
      },
    ],
  };

  const dataGraficoClientes = {
    labels: [
      "Recém-nascidos",
      "Bebes",
      "Crianças",
      "Jovens",
      "Adultos",
      "Reformados",
    ],
    datasets: [
      {
        data: numClientesCategoria, // Substitua pelos valores reais de faturação por faixa etária
        backgroundColor: [
          "rgba(10, 86, 250, 1)",
          "rgba(10, 86, 250, 0.85)",
          "rgba(10, 86, 250, 0.6)",
          "rgba(10, 86, 250, 0.45)",
          "rgba(10, 86, 250, 0.3)",
          "rgba(10, 86, 250, 0.15)",
        ],
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "#eaf0f8",
      },
    ],
  };

  const dataGraficogenero = {
    labels: totalGenero,
    datasets: [
      {
        data: totalClientesGenero,
        backgroundColor: [
          "rgba(10, 86, 250, 1)",

          "rgba(10, 86, 250, 0.6)",

          "rgba(10, 86, 250, 0.15)",
        ],
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "#e3e9f8",
      },
    ],
  };
  const dataGraficoModalidadesFaturacao = {
    labels: totalModalidades,
    datasets: [
      {
        data: totalFaturacaoModalidades, // Substitua pelos valores reais de faturação por faixa etária
        backgroundColor: [
          "rgba(10, 86, 250, 1)",
          "rgba(10, 86, 250, 0.9)",
          "rgba(10, 86, 250, 0.8)",
          "rgba(10, 86, 250, 0.7)",
          "rgba(10, 86, 250, 0.6)",
          "rgba(10, 86, 250, 0.5)",
          "rgba(10, 86, 250, 0.4)",
          "rgba(10, 86, 250, 0.3)",
          "rgba(10, 86, 250, 0.2)",
          "rgba(10, 86, 250, 0.1)",
        ],
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "#e7f0f8",
      },
    ],
  };
  const optionsGraficoFaturacao = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    cutout: isPhone ? 30 : 44,
  };

  const dataprofessorhoras = {
    labels: totalProfessores,
    datasets: [
      {
        data: totalHoras,
        backgroundColor: [
          "rgba(10, 86, 250, 1)",

          "rgba(10, 86, 250, 0.7)",
          "rgba(10, 86, 250, 0.4)",
          "rgba(10, 86, 250, 0.15)",
        ],
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "#f1f1f4",
      },
    ],
  };
  const dataprofessorAlunos = {
    labels: totalProfessores,
    datasets: [
      {
        data: totalAlunos, // Substitua pelos valores reais de faturação por faixa etária
        backgroundColor: [
          "rgba(10, 86, 250, 1)",
          "rgba(10, 86, 250, 0.7)",
          "rgba(10, 86, 250, 0.4)",
          "rgba(10, 86, 250, 0.15)",
        ],
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "#f1f1f4",
      },
    ],
  };

  const dataGraficoTaxaOcupacao = {
    labels: horas,
    datasets: [
      {
        label: "Taxa de Ocupação",
        data: ocupacoes,
        backgroundColor: "transparent", // Sem cor de fundo
        borderColor: "rgba(125, 164, 249, 1)", // Cor da linha mais clara
        borderWidth: 8,
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 0.4,
        pointHoverRadius: 2,
        pointBackgroundColor: "rgba(10, 86, 250, 1)",
        pointBorderColor: "#0A56FA",
      },
    ],
  };

  const optionsGraficoTaxaOcupacao = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: max,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const dataGraficoModalidades = {
    labels: totalModalidades,
    datasets: [
      {
        label: t("atual"),
        data: totalClientesModalidadesAtual, // Substitua pelos dados reais de hoje
        backgroundColor: "#7DA4F9",
        borderRadius: 6,
        width: "10px", // Cor das barras
      },
      {
        label: t("passado"),
        data: totalClientesModalidadesPassado, // Substitua pelos dados reais do mês passado
        backgroundColor: "#0A56FA",
        borderRadius: 6,
        width: "10px", // Cor das barras
      },
    ],
  };

  const optionsGraficoModalidades = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          font: {
            size: isMobile ? 0 : 6,
          },
        },
      },
    },
    barThickness: 14,
  };

  const [numPedidosPendentes, setNumPedidosPendentes] = useState("");

  const handlePoolChange = (event) => {
    setSelectedPool(event.target.value);
    setIdPiscina(event.target.value.id_piscina);
    setIdentidade(event.target.value.id_entidade_legal);
    localStorage.setItem("idPiscina", event.target.value.id_piscina);
    localStorage.setItem("idEntidade", event.target.value.id_entidade_legal);

    setShowButtons(true);
    setErroPiscina(false);
  };

  const options = {
    responsive: true,
  };

  // Obtém o primeiro dia da semana atual
  const firstDayOfWeek = startOfWeek(currentDate);

  // Obtém o último dia da semana atual
  const lastDayOfWeek = endOfWeek(currentDate);

  // Formata as datas para o formato desejado (por exemplo, 'dd/MM')
  const formattedFirstDayOfWeek = format(firstDayOfWeek, "dd/MM");
  const formattedLastDayOfWeek = format(lastDayOfWeek, "dd/MM");

  function obterNomeMesAtual(idioma =i18n.language) {
    const meses = {
      pt: [
        "janeiro",
        "fevereiro",
        "março",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
      ],
      en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };

    const dataAtual = new Date();
    const mesAtual = dataAtual.getMonth();

    return meses[idioma][mesAtual];
  }

  const optionsDate = { month: "long" };
  const optionsDate2 = { year: "numeric" };
  const formattedDate = currentDate.toLocaleString(undefined, optionsDate);
  const formattedDate2 = currentDate.toLocaleString(undefined, optionsDate2);

  function getFormattedDateWeek(date) {
    const weekStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay()
    );
    const weekEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (6 - date.getDay())
    );
    return `${weekStart.getDate()} ${weekStart.toLocaleString("default", {
      month: "short",
    })} - ${weekEnd.getDate()} ${weekEnd.toLocaleString("default", {
      month: "short",
    })}`;
  }

  const formattedDateWeek = getFormattedDateWeek(currentDate);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div className="listPages">
        <div
          style={{
            maxWidth: isMobile ? "350px" : "500px",
            paddingTop: "12vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <p
            style={{
              fontSize: isMobile ? "12px" : "14px",
              color: "rgba(23, 30, 36, 0.6)",
              width: "300px",
            }}
          >
            {t("escolhapiscina")}:
          </p>

          <select
            value={selectedPool}
            onChange={(e) => handlePoolChange(findPoolById(e.target.value))}
            style={{
              fontSize: isMobile ? "12px" : "14px",

              border: "none",
              borderRadius: "4px",
              outline: "none",
              backgroundColor: "transparent",
              marginBottom: "15px",
            }}
          >
            {inscritas.map((piscina) => (
              <option key={piscina} value={piscina.id}>
                {piscina.nome}
              </option>
            ))}
          </select>
        </div>
        <>
          <div className="swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    className="divDashboard5"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: "0.5vh",
                        marginLeft: "1.5vw",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: isMobile ? "16px" : "20px",
                          color: "black",
                        }}
                      >
                        {t("faturacao")}
                      </h4>

                      {selectedOption == "year" ? (
                        <p style={{ fontSize: "12px", color: "black" }}>
                          {formattedDate2}
                        </p>
                      ) : (
                        <p style={{ fontSize: "12px", color: "black" }}>
                          {formattedDate}
                        </p>
                      )}

                      <p
                        style={{
                          fontSize: isMobile ? "30px" : "40px",
                          color: "#0A56FA",
                         
                        }}
                      >
                        {valorFaturacao}€
                      </p>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          paddingTop: isPhone ? "11vh" : "12vh",
                        }}
                      >
                        {selectedOption == "year" ? (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimoano")}
                            </p>
                            <p style={paragraphStyleAno}>
                              {isNegativeAno && arrowIconAno}{" "}
                              {faturacaoAnoPassado != "NaN"
                                ? Math.abs(faturacaoAnoPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimomes")}
                            </p>
                            <p style={paragraphStyle}>
                              {isNegative && arrowIcon}{" "}
                              {faturacaoMesPassado !== null &&
                              faturacaoMesPassado !== undefined
                                ? Math.abs(faturacaoMesPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        marginTop: isPhone ? 80 : 50,
                        marginBottom: 20,
                        marginRight: "5vw",
                        maxHeight: isPhone ? "12vh" : "18vh",
                      }}
                    >
                      <Doughnut
                        data={dataGraficoFaturacao}
                        options={optionsGraficoFaturacao}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "56%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "10px",
                            color: "black",
                            maxHeight: isPhone ? "12vh" : "18vh",
                          }}
                        >
                          {t("idades")}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        marginTop: isPhone ? 80 : 50,
                        marginBottom: 20,
                        maxHeight: isPhone ? "12vh" : "18vh",
                        marginRight: "12vw",
                      }}
                    >
                      <Doughnut
                        data={dataGraficoModalidadesFaturacao}
                        options={optionsGraficoFaturacao}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "56%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "10px",
                            color: "black",
                            fontWeight: 200,
                          }}
                        >
                          {t("modalidades")}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 20,

                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        maxWidth: "50px",
                      }}
                    >
                      <select
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        style={{
                          fontSize: "12px",
                          color: "rgba(23, 30, 36, 0.6)",
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                      >
                        <option value="month">{t("mes")}</option>
                        <option value="year">{t("ano")}</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="divDashboard5"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between", // Alinhar os elementos ao espaçamento máximo
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: "1vh",
                        marginLeft: "1.5vw",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: isMobile ? "16px" : "20px",
                          color: "black",
                        }}
                      >
                        {t("clientes")}
                      </h4>

                      {selectedOptionCliente === "month" ? (
                        <p style={{ fontSize: "12px", color: "black" }}>
                          {formattedDate}
                        </p>
                      ) : (
                        <p style={{ fontSize: "12px", color: "black" }}>
                          {formattedDate2}
                        </p>
                      )}

                      <p
                        style={{
                          fontSize: isMobile ? "30px" : "40px",
                          color: "#0A56FA",
                        }}
                      >
                        {numClientes}
                      </p>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          paddingTop: isPhone ? "11vh" : "14vh",
                        }}
                      >
                        {selectedOptionCliente === "month" ? (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimomes")}
                            </p>
                            <p style={paragraphStyle}>
                              {isNegative && arrowIcon}{" "}
                              {clientesMesPassado !== null &&
                              clientesMesPassado !== undefined
                                ? Math.abs(clientesMesPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                marginRight: "5px",
                              }}
                            >
                              {t("ultimoano")}
                            </p>
                            <p style={paragraphStyleAno}>
                              {isNegativeAno && arrowIconAno}{" "}
                              {clientesAnoPassado !== "NaN"
                                ? Math.abs(clientesAnoPassado) + "%"
                                : t("semdados")}
                            </p>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        position: "relative",
                        marginTop: isPhone ? 80 : 50,
                        marginBottom: 20,
                        maxHeight: isPhone ? "12vh" : "18vh",
                        marginRight: "5vw",
                      }}
                    >
                      <Doughnut
                        data={dataGraficoClientes}
                        options={optionsGraficoFaturacao}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "56%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            color: "black",
                            fontWeight: 200,
                          }}
                        >
                          {t("idades")}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        marginTop: isPhone ? 80 : 50,
                        marginBottom: 20,
                        maxHeight: isPhone ? "12vh" : "18vh",
                        marginRight: "5vw",
                      }}
                    >
                      <Doughnut
                        data={dataGraficogenero}
                        options={optionsGraficoFaturacao}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "56%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            color: "black",
                            fontWeight: 200,
                          }}
                        >
                          {t("genero")}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 300,
                        right: 20,

                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <select
                        value={selectedOptionCliente}
                        onChange={(e) =>
                          setSelectedOptionCliente(e.target.value)
                        }
                        style={{
                          fontSize: "12px",
                          color: "rgba(23, 30, 36, 0.6)",
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          textAlign: "right",
                        }}
                      >
                        {/* <option value="week">{t("semana")}</option> */}
                        <option value="month">{t("mes")}</option>
                        <option value="year">{t("ano")}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div className="divDashboard5">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                          }}
                        >
                          {t("estatistica")}
                        </h4>

                        { selectedOptionStats === "month" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate}
                          </p>
                        ) : (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate2}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 20,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          maxWidth: "50px",
                        }}
                      >
                        <select
                          value={selectedOptionStats}
                          onChange={(e) =>
                            setSelectedOptionStats(e.target.value)
                          }
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            textAlign: "right",
                          }}
                        >
                          {/* <option value="week">{t("semana")}</option> */}
                          <option value="month">{t("mes")}</option>
                          <option value="year">{t("ano")}</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        paddingInline: 10,
                      }}
                    >
                      <Bar
                        data={dataGraficoModalidades}
                        options={optionsGraficoModalidades}
                      />
                    </div>
                  </div>
                  <div className="divDashboard5">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                          }}
                        >
                          {t("taxaprofessor")}
                        </h4>

                        {selectedOptionOcupacao == "year" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate2}
                          </p>
                        ) : (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 5,

                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                      >
                        <select
                          value={selectedOptionOcupacao}
                          onChange={(e) =>
                            setSelectedOptionOcupacao(e.target.value)
                          }
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                        >
                          <option value="month">{t("mes")}</option>
                          <option value="year">{t("ano")}</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "20px",
                        }}
                      >
                        {dataprofessorhoras.labels.map((label, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "15px",
                              height: "0px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  dataprofessorhoras.datasets[0]
                                    .backgroundColor[index],
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "15px",
                                borderRadius: "2px",
                              }}
                            ></div>
                            <p
                              style={{
                                fontSize: "10px",
                                paddingTop: "15px",
                                color: "black",
                              }}
                            >
                              {label}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "center",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          maxHeight: isPhone ? "12vh" : "18vh",
                          marginRight: "5vw",
                        }}
                      >
                        <Doughnut
                          data={dataprofessorhoras}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {t("horas")}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          maxHeight: isPhone ? "12vh" : "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataprofessorAlunos}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 200,
                            }}
                          >
                            {t("alunos")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <div className="divDashboard6">
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                            marginBottom: "5px",
                          }}
                        >
                          {t("taxaOcupacao")}
                        </h4>

                        <p
                          style={{
                            fontSize: isMobile ? "30px" : "40px",
                            color: "#0A56FA",
                            paddingTop: 50,
                            paddingRight: 90,
                          }}
                        >
                          {ocupacao}%
                        </p>
                      </div>

                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        {/* <div
                          style={{
                            position: "relative",
                            top: 5,
                            right: 0,
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            maxWidth: "50px",
                          }}
                        >
                          <select
                            value={selectedOptionDia}
                            onChange={(e) =>
                              setSelectedOptionDia(e.target.value)
                            }
                            style={{
                              fontSize: "12px",
                              color: "rgba(23, 30, 36, 0.6)",
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                              textAlign: "right",
                            }}
                          >
                            <option value="week">{t("semana")}</option>
                            <option value="month">{t("mes")}</option>
                            <option value="day">{t("dia")}</option>
                          </select>
                        </div> */}
                     
                      </div>
                    </div>
                    <div
                      style={{
                        width: "80vw",
                        height: "28vh",
                        paddingLeft: 50,
                      }}
                    >
                      <Line
                        data={dataGraficoTaxaOcupacao}
                        options={optionsGraficoTaxaOcupacao}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            <div className="swiper-pagination"></div>
          </div>

          <div className="formPageSA">
            {selectedPool && (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginInline: "5px",
                    maxWidth: "100%",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "160%",
                    }}
                  >
                    <div
                      className="divDashboard"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                          }}
                        >
                          {t("faturacao")}
                        </h4>

                        {selectedOption == "year" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate2}
                          </p>
                        ) : (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate}
                          </p>
                        )}

                        <p
                          style={{
                            fontSize: isMobile ? "25px" : "35px",
                            color: "#0A56FA",
                            marginBottom:"0.7vh"
                          }}
                        >
                          {valorFaturacao}€
                        </p>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                         
                          }}
                        >
                          {selectedOption == "year" ? (
                            <>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(23, 30, 36, 0.6)",
                                  marginRight: "5px",
                                }}
                              >
                                {t("ultimoano")}
                              </p>
                              <p style={paragraphStyleAno}>
                                {isNegativeAno && arrowIconAno}{" "}
                                {faturacaoAnoPassado != "NaN"
                                  ? Math.abs(faturacaoAnoPassado) + "%"
                                  : t("semdados")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(23, 30, 36, 0.6)",
                                  marginRight: "5px",
                                }}
                              >
                                {t("ultimomes")}
                              </p>
                              <p style={paragraphStyle}>
                                {isNegative && arrowIcon}{" "}
                                {faturacaoMesPassado !== null &&
                                faturacaoMesPassado !== undefined
                                  ? Math.abs(faturacaoMesPassado) + "%"
                                  : t("semdados")}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          marginRight: "5vw",
                          maxHeight: "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataGraficoFaturacao}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 200,
                            }}
                          >
                            {t("idades")}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          maxHeight: "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataGraficoModalidadesFaturacao}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 200,
                            }}
                          >
                            {t("modalidades")}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 5,

                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          maxWidth: "50px",
                        }}
                      >
                        <select
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                        >
                          <option value="month">{t("mes")}</option>
                          <option value="year">{t("ano")}</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="divDashboard"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between", // Alinhar os elementos ao espaçamento máximo
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                          }}
                        >
                          {t("clientes")}
                        </h4>

                        {selectedOptionCliente === "week" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDateWeek}
                          </p>
                        ) : selectedOptionCliente === "month" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate}
                          </p>
                        ) : (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate2}
                          </p>
                        )}

                        <p
                          style={{
                            fontSize: isMobile ? "25px" : "35px",
                            color: "#0A56FA",
                            marginBottom:"0.7vh"
                          }}
                        >
                          {numClientes}
                        </p>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            paddingTop: isPhone ? "11vh" : "0vh",
                          }}
                        >
                          { selectedOptionCliente === "month" ? (
                            <>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(23, 30, 36, 0.6)",
                                  marginRight: "5px",
                                }}
                              >
                                {t("ultimomes")}
                              </p>
                              <p style={paragraphStyle}>
                                {isNegative && arrowIcon}{" "}
                                {clientesMesPassado !== null &&
                                clientesMesPassado !== undefined
                                  ? Math.abs(clientesMesPassado) + "%"
                                  : t("semdados")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(23, 30, 36, 0.6)",
                                  marginRight: "5px",
                                }}
                              >
                                {t("ultimoano")}
                              </p>
                              <p style={paragraphStyleAno}>
                                {isNegativeAno && arrowIconAno}{" "}
                                {clientesAnoPassado !== "NaN"
                                  ? Math.abs(clientesAnoPassado) + "%"
                                  : t("semdados")}
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          marginRight: "5vw",
                          maxHeight: "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataGraficoClientes}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 200,
                            }}
                          >
                            {t("idades")}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          maxHeight: "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataGraficogenero}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 200,
                            }}
                          >
                            {t("genero")}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 5,

                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                      >
                        <select
                          value={selectedOptionCliente}
                          onChange={(e) =>
                            setSelectedOptionCliente(e.target.value)
                          }
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            textAlign: "right",
                          }}
                        >
                          {/* <option value="week">{t("semana")}</option> */}
                          <option value="month">{t("mes")}</option>
                          <option value="year">{t("ano")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="divDashboard3">
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            marginTop: "1vh",
                            marginLeft: "1.5vw",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: isMobile ? "16px" : "20px",
                              color: "black",
                              marginBottom: "5px",
                            }}
                          >
                            {t("taxaOcupacao")}
                          </h4>

                          <p
                            style={{
                              fontSize: isMobile ? "30px" : "40px",
                              color: "#0A56FA",
                              paddingTop: 20,
                              paddingRight: 90,
                            }}
                          >
                            {ocupacao}%
                          </p>
                        </div>

                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                          }}
                        >
                          {/* <div
                            style={{
                              position: "relative",
                              top: 5,
                              right: 0,
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                              maxWidth: "50px",
                            }}
                          >
                            <select
                              value={selectedOptionDia}
                              onChange={(e) =>
                                setSelectedOptionDia(e.target.value)
                              }
                              style={{
                                fontSize: "12px",
                                color: "rgba(23, 30, 36, 0.6)",
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                                textAlign: "right",
                              }}
                            >
                              <option value="week">{t("semana")}</option>
                              <option value="month">{t("mes")}</option>
                              <option value="day">{t("dia")}</option>
                            </select>
                          </div> */}
                          
                        </div>
                      </div>
                      <div
                        style={{
                          width: "34vw",
                          height: "24vh",
                          paddingLeft: 50,
                        }}
                      >
                        <Line
                          data={dataGraficoTaxaOcupacao}
                          options={optionsGraficoTaxaOcupacao}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginInline: "5px",
                    height: "100%",
                  }}
                >
                  <div className="divDashboard2">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                          }}
                        >
                          {t("estatistica")}
                        </h4>

                        {selectedOptionStats === "month" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate}
                          </p>
                        ) : (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate2}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 20,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          maxWidth: "50px",
                        }}
                      >
                        <select
                          value={selectedOptionStats}
                          onChange={(e) =>
                            setSelectedOptionStats(e.target.value)
                          }
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            textAlign: "right",
                          }}
                        >
                          {/* <option value="week">{t("semana")}</option> */}
                          <option value="month">{t("mes")}</option>
                          <option value="year">{t("ano")}</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "60%",
                        paddingInline: 10,
                      }}
                    >
                      <Bar
                        data={dataGraficoModalidades}
                        options={optionsGraficoModalidades}
                      />
                    </div>
                  </div>
                  <div className="divDashboard2">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "1vh",
                          marginLeft: "1.5vw",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            color: "black",
                          }}
                        >
                          {t("taxaprofessor")}
                        </h4>

                        {selectedOptionOcupacao == "year" ? (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate2}
                          </p>
                        ) : (
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {formattedDate}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          right: 5,

                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                      >
                        <select
                          value={selectedOptionOcupacao}
                          onChange={(e) =>
                            setSelectedOptionOcupacao(e.target.value)
                          }
                          style={{
                            fontSize: "12px",
                            color: "rgba(23, 30, 36, 0.6)",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                        >
                          <option value="month">{t("mes")}</option>
                          <option value="year">{t("ano")}</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "20px",
                        }}
                      >
                        {dataprofessorhoras.labels.map((label, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "15px",
                              height: "0px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  dataprofessorhoras.datasets[0]
                                    .backgroundColor[index],
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "15px",
                                borderRadius: "2px",
                              }}
                            ></div>
                            <p
                              style={{
                                fontSize: "10px",
                                paddingTop: "15px",
                                color: "black",
                              }}
                            >
                              {label}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "center",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          marginRight: "5vw",
                          maxHeight: "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataprofessorhoras}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ fontSize: "12px", color: "black" }}>
                            {t("horas")}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginTop: 16,
                          marginBottom: 20,
                          maxHeight: "18vh",
                        }}
                      >
                        <Doughnut
                          data={dataprofessorAlunos}
                          options={optionsGraficoFaturacao}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "56%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 200,
                            }}
                          >
                            {t("alunos")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  );
}

export default Dashboard_Admin;
