import Cookies from "js-cookie";
import { makeRequestPost2, makeRequestPut2, makeRequestDelete2 } from "../api";

//Lista piscinas água
export function listaPiscinasAgua(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/list", dados, token);
}

export function listaAcoes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/acoes-descontos", dados, token);
}

export function listaModalidades(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/modalidades", dados, token);
}

export function listaCancelamentos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/list/cancelamentos", dados, token);
}

export function listaProfessores(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/professores", dados, token);
}

export function listaClientesPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/clientes", dados, token);
}

export function getPiscinaAgua(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/id", dados, token);
}

export function getPistas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/pistas", dados, token);
}
export function getTiposOcupaEspaco(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/tipos-ocupaespaco", dados, token);
}

export function adicionarPiscinaA(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/", dados, token);
}

export function editarPiscinaA(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("piscinaagua/", dados, token);
}

export function eliminarPiscinaA(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("piscinaagua/", dados, token);
}

//Cancelar evento
export function cancelarEvento(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/cancelamento", dados, token);
}

export function cancelarEventoEmail(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/email/cancelamento", dados, token);
}

export function AlteraProfEmail(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/email/substituto", dados, token);
}

export function AvisoProfEmail(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/email/substitutoprofessor", dados, token);
}


export function eliminarTurmaEmail(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/email/turmaeliminada", dados, token);
}

export function eliminarAulaEmail(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/email/aulaeliminada", dados, token);
}

export function calendarioF(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/calendario", dados, token);
}

export function taxaOcupacao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/taxa-ocupacao", dados, token);
}

export function vagasD(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinaagua/vagas", dados, token);
}

export function listaFechos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscina/list/fechos", dados, token);
}
