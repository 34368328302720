import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faEye,
  faFloppyDisk,
  faHand,
  faStop,
  faClose,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import countryList from "react-select-country-list";
import { Scrollbar } from "react-scrollbars-custom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import fechoIconBranco from "../../images/fechoBranco.svg";

import {
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Autocomplete,
  DialogTitle,
  DialogContentText,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat, PatternFormat } from "react-number-format";

import editarbtn from "../../images/BotaoEditar.svg";
import fechobtn from "../../images/fechoBtn.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import {
  PencilIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import Search1 from "../../components/DataTable/Search1";
import {
  deleteFecho,
  enviarEmailFecho,
  enviarEmailFechoReabertura,
  getFechoId,
  getListaEE,
  getListaFechos,

  postFechos,
} from "../../api/requests/Piscinas";
import Nav from "../../components/Nav";
import { getEE, putEE } from "../../api/requests/Piscinas";
import moment from "moment";

function ListaFechos() {
  const [fechos, setFechos] = useState([]);
  const [fechoCopy, setFechosCopy] = useState([]);

  const [eeFechos, seteeFechos] = useState([]);
  const [search, setSearch] = useState("");
  const [eliminar, setEliminar] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(isMobile ? ["id"] : []);
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id"] : []);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [openCriarFechos, setOpenCriarFechos] = useState(false);
  const handleCloseCriarFechos = () => setOpenCriarFechos(false);

  const handleClickOpenCriarFechos = () => {
    setOpenCriarFechos(true);
  };

  const [openEditarFechos, setOpenEditarFecho] = useState(false);
  const handleCloseEditarFecho = () => setOpenEditarFecho(false);

  const handleClickOpenEditarFecho = () => {
    setOpenEditarFecho(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arrayFecho = fechoCopy;
      arrayFecho = arrayEe.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome.toLowerCase().includes(search.toLowerCase())
      );
      setFechos(arrayFecho);
    } else {
      setFechos(fechoCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getListaFechos({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_fechos,
              data_inicio: moment(element.data_inicio).format("DD-MM-YYYY"),
              data_fim: moment(element.data_fim).format("DD-MM-YYYY"),
              obs: element.obs,
            };
            arrayFinal.push(linha);
            setFechos(arrayFinal);
            setFechosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [edicao, setEdicao] = useState(true);
  const [idFecho, setIdFecho] = useState("");
  const [idPiscina, setIdPiscina] = useState("");

  const [freeze, setFreeze] = useState(false);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [obs, setObs] = useState("");

  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroObs, setErroObs] = useState(false);

  const [dataInicioCriar, setDataInicioCriar] = useState("");
  const [dataFimCriar, setDataFimCriar] = useState("");
  const [obsCriar, setObsCriar] = useState("");

  const [erroDataInicioCriar, setErroDataInicioCriar] = useState(false);
  const [erroDataFimCriar, setErroDataFimCriar] = useState(false);
  const [erroObsCriar, setErroObsCriar] = useState(false);

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);

    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    setIdPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    if (idFecho != "") {
      getFechoId({ id: idFecho })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];

            setDadosOriginais(dados);
            setDataInicio(dados.data_inicio);
            setDataFim(dados.data_fim);
            setObs(dados.obs);

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idFecho]);

  function editarFecho() {
    setFreeze(true);
    putEE({
      id_fecho: idFecho,
      data_fim: dataFim,
      data_inicio: dataInicio,
      obs: obs,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          window.location.reload();
          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
      });
  }

  function validacao() {
    if (dataInicio && dataFim && obs) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarFecho();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      if (!dataInicio) {
        setErroDataInicio(true);
      }
      if (!dataFim) {
        setErroDataFim(true);
      }
      if (!obs) {
        setErroObs(true);
      }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    setDataFim(dadosOriginais.data_fim);
    setDataInicio(dadosOriginais.dataInicio);
    setObs(dadosOriginais.obs);

    setErroDataFim(false);
    setErroDataInicio(false);
    setErroObs(false);

    window.scrollTo(0, 0);
  }

  function cancelarFecho() {
    setFreeze(true);
    deleteFecho({
      id_fecho: idFecho,
    })
      .then((res) => {
        if (res.success) {
          setTimeout(async function () {
            enviarEmailFechoReabertura({
              id_piscina: idPiscina,
              id_fecho: idFecho,
            }).then((res2) => {
          
              if (res2.success) {
                setFreeze(false);
                // toast.success(res2);
               
          toast.success(t("fechoCancelado"));
          atualizarFechos(idPiscina);
      
          handleClosePopupEliminar();
          handleCloseEditarFecho();
          window.location.reload();
              } else {
                setFreeze(false);
                toast.error(res2);
              }
            });
          }, 2000);
          
        } else {
          setFreeze(false);
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 400) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function atualizarFechos(idPiscina) {
    getListaFechos({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_fechos,
              data_inicio: moment(element.data_inicio).format("DD-MM-YYYY"),
              data_fim: moment(element.data_fim).format("DD-MM-YYYY"),
              obs: element.obs,
            };
            arrayFinal.push(linha);
            setFechos(arrayFinal);
            setFechosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  function limparVariaveis() {
    setDataFimCriar("");
    setDataInicioCriar("");
    setObsCriar("");
  }

  function addFecho() {
    setFreeze(true);
    postFechos({
      id_piscina: idPiscina,
      data_inicio: format(dataInicioCriar, "yyyy-MM-dd HH:mm"),
      data_fim: format(dataFimCriar, "yyyy-MM-dd HH:mm"),
      obs: obsCriar,
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          enviarEmailFecho({
            id_piscina: idPiscina,
            data_inicio: format(dataInicioCriar, "yyyy-MM-dd HH:mm"),
            data_fim: format(dataFimCriar, "yyyy-MM-dd HH:mm"),
          }).then((res2) => {
            if (res2.success) {
              setFreeze(false);
              toast.success(res.data.msg);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          });
        }, 2000);
        toast.success(t("fechoCriado"));
        setFreeze(false);
        atualizarFechos(idPiscina);
        handleCloseCriarFechos();
        limparVariaveis();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if ((dataFimCriar, dataInicioCriar, obsCriar)) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        addFecho();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!dataFimCriar) {
        setErroDataFimCriar(true);
      }
      if (!dataInicioCriar) {
        setErroCategoria(true);
      }
      if (!selectedPool) {
        setErroPiscina(true);
      }
      if (!numtickets) {
        setErroNumTickets(true);
      }
      if (!preco) {
        setErroPreco(true);
      }
    }
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/dashboard_admin");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <h3 className="titleList">{t("listaFechos")}</h3>
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "52vh", maxWidth: "100%" }}>
            <DataGrid
              className={isMobile ? "tabela2" : "tabela"}
              pageSize={10}
              //Colunas
              columns={[
                {
                  field: "id",
                  headerName: t("id"),
                  type: "number",
                  maxWidth: 70,
                  flex: 1,
                  hide: columnsHidden.includes("id"),
                },
                {
                  field: "data_inicio",
                  headerName: t("datainicio"),
                  type: "string",
                  flex: 1,
                },
                {
                  field: "data_fim",
                  headerName: t("datafim"),
                  flex: 1,
                  type: "string",
                },
                {
                  field: "obs",
                  headerName: t("observacoes"),
                  flex: 1,
                  type: "string",
                },

                {
                  field: "actions",
                  type: "actions",
                  headerName: t("acoes"),
                  width: 100,
                  cellClassName: "actions",
                  getActions: ({ id }) => {
                    return [
                      <GridActionsCellItem
                        icon={
                          <MagnifyingGlassIcon
                            style={{
                              width: isMobile ? "18px" : "22px",
                            }}
                          />
                        }
                        label="Save"
                        onClick={() => {
                          setIdFecho(id);
                          handleClickOpenEditarFecho();
                        }}
                      />,
                    ];
                  },
                },
              ]}
              rows={fechos}
              checkboxSelection={isMobile ? false : true}
              onRowClick={(params) => {}}
              getRowId={(row) => row.id}
              //Idioma
              localeText={
                i18n.language == "pt"
                  ? ptBR.components.MuiDataGrid.defaultProps.localeText
                  : i18n.language == "es"
                  ? esES.components.MuiDataGrid.defaultProps.localeText
                  : enUS.components.MuiDataGrid.defaultProps.localeText
              }
              components={{
                Toolbar: CustomToolbar,
                BaseCheckbox: ({ value, ...otherProps }) => (
                  <Checkbox size="small" checked={value} {...otherProps} />
                ),
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="btnEdicao"
              variant="contained"
              style={{
                backgroundImage: `url(${fechobtn})`,
                width: 452,
                height: 50,
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={() => {
                handleClickOpenCriarFechos();
              }}
            >
              <span
                style={{
                  width: 21,
                  height: 21,
                  backgroundImage: `url(${fechoIconBranco})`,
                  marginRight: 10,
                }}
              />

              {t("fecharPiscina")}
            </Button>
          </div>

          {/* ver fecho */}
          <Dialog
            open={openEditarFechos}
            onClose={handleCloseEditarFecho}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("fecho2")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 9999,
                }}
                onClick={handleCloseEditarFecho}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Scrollbar style={{ height: 400 }}>
                    <Box className="formTextField">
                      <div>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disabled={edicao}
                              label={t("datainicio")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={dataInicio}
                              onChange={(e) => {
                                if (e) {
                                  setDataInicio(e.$d);
                                  setErroDataInicio(false);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroDataInicio}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disabled={edicao}
                              label={t("datafim")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={dataFim}
                              onChange={(e) => {
                                if (e) {
                                  setDataFim(e.$d);
                                  setErroDataFim(false);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroDataFim}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            rows={5}
                            value={obs}
                            label={t("observacoesFecho")}
                            error={erroObs}
                            onChange={(e) => {
                              setObs(e.target.value);
                              setErroObs(false);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Scrollbar>
                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${editarbtn})`,
                      width: 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      cancelarFecho();
                    }}
                  >
                    {/* <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    /> */}

                    {t("cancelarFecho")}
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${cancelarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>

          {/* Eliminar */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarFecho")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("eliminarRegisto")}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                className="btnSim"
                variant="contained"
                style={{
                  backgroundImage: `url(${simbtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  eliminarFecho();
                }}
              >
                {t("sim")}
              </Button>
              <Button
                className="btnSim"
                variant="contained"
                style={{
                  backgroundImage: `url(${naobtn})`,
                  width: 198,
                  height: 30,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  handleClosePopupEliminar();
                }}
                autoFocus
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* adicionar fecho */}
          <Dialog
            open={openCriarFechos}
            onClose={handleCloseCriarFechos}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("fecharPiscina")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarFechos}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              <div className="formPop" style={{ marginTop: 30 }}>
                <Box className="formTextField">
                  <FormControl className="formTextField2" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        label={t("datainicio")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataInicioCriar}
                        onChange={(e) => {
                          if (e) {
                            setDataInicioCriar(e.$d);
                            setErroDataInicioCriar(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroDataInicioCriar}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        label={t("datafim")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataFimCriar}
                        onChange={(e) => {
                          if (e) {
                            setDataFimCriar(e.$d);
                            setErroDataFimCriar(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroDataFimCriar}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>

                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={5}
                      value={obsCriar}
                      label={t("observacoesFecho")}
                      error={erroObsCriar}
                      onChange={(e) => {
                        setObsCriar(e.target.value);
                        setErroObsCriar(false);
                      }}
                    />
                  </FormControl>
                </Box>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: `url(${eliminarbtn})`,
                  width: 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("fechar")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaFechos;
