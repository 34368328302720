import Cookies from "js-cookie";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box, color, maxWidth, useTheme } from "@mui/system";
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  getInscricoesId,
  getUtilizadoresId,
} from "../../api/requests/Utilizadores";
import { getPiscinas } from "../../api/requests/Piscinas";
import { Search } from "../../components/DataTable";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import { Card, CardContent } from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import { format, max, parseISO } from "date-fns";
import {
  cancelarInscricao,
  cancelarMarcacao,
  emailCancelarInscricao,
  emailCancelarMarcacao,
  proximasMarcacoes,
} from "../../api/requests/Marcacoes";
import { faClose, faPen } from "@fortawesome/free-solid-svg-icons";
import { getQrCodes } from "../../api/requests/Qrcode";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import Nav from "../../components/Nav";
import { verificarPagamentosEmDia } from "../../api/requests/Pagamentos";

function Marcacoes() {
  //Traduções
  const { t, i18n } = useTranslation();
  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState("");
  const [inscritas, setInscritas] = useState([]);
  const [piscinasCopy, setPiscinasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);

  const [idEscolhida, setIdEscolhida] = useState("");
  const [marcacoes, setMarcacoes] = useState([]);
  const [marcacao, setMarcacao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const dataAtual = new Date();

  const [eventInfo, setEventInfo] = useState(null);
  const [weekDateRange, setWeekDateRange] = useState("");
  const calendarRef = useRef(null);

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
    setEventInfo(null);
  };

  useEffect(() => {
    const elements = document.getElementsByClassName("fc-event");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].getElementsByClassName("fc-cancelado")[0]) {
        elements[i].style.setProperty(
          "background-color",
          "#F04643",
          "important"
        );
        elements[i].style.setProperty("color", "white");
      }
    }
  }, [marcacoes]);

  const handleEventClick = (info) => {
    setEventInfo(info);
    setMarcacao([
      {
        id_turma: info.event._def.extendedProps.id_turma,
        id_filho: info.event._def.extendedProps.id_filho,
        id: info.event._def.publicId,
      },
    ]);
    setLoading(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function goBack() {
    navigate("/dashboard_customer");
  }
  useEffect(() => {
    setTimeout(() => {
      verificarPagamentosEmDia().then((res) => {
        if (res.data.success) {
          navigate("/dashboard_customer");
        }
      });
    }, 20);
  }, []);
  //Navigator
  const navigate = useNavigate();

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    proximasMarcacoes().then((res) => {
      if (res.success) {
        let arrayFinal = [];
        let dados = res.data.data;

        dados.forEach((element) => {
          const dataParts = element.dia.split("/");
          const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
          const data = new Date(dataFormatada);
          const dataInicio = `${data.toISOString().substr(0, 10)}T${
            element.horaInicio
          }`;
          const dataFim = `${data.toISOString().substr(0, 10)}T${
            element.horaFim
          }`;
          const hoje = new Date().toISOString();

          const linha = {
            id: element.id_usa_ticket,
            title: element.nome,
            dia: element.dia,
            hI: element.horaInicio,
            hF: element.horaFim,
            description: element.nomePiscina,
            id_filho: element.id_filho,
            id_piscina: element.id_piscina,
            id_turma: element.id_turma,
            nome_turma: element.nome_turma,
            obs: element.obs,
            id_pack_cliente: element.id_pack_cliente,
            cancelado: element.cancelado,
            start: dataInicio,
            end: dataFim,
            color:
              element.cancelado == 1
                ? "#F04643"
                : dataFim < hoje
                ? "#54DE90"
                : "#00C4F5",
          };
          if (element.tipo2 != 2) {
            arrayFinal.push(linha);
          }
        });
        //Filtrar apenas para marcações futuras
        const hoje = new Date().toISOString().substr(0, 10);

        const marcacoesFuturas = arrayFinal.filter((marcacao) => {
          return marcacao.start >= hoje;
        });

        setMarcacoes(marcacoesFuturas);
      }
    });
  }, []);
  const titleFormat = () => {
    const prefix = t("minhasemana") + " (" + weekDateRange + ")";
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat(
      i18n.language,
      options
    ).format(date);

    return prefix;
  };
  const dayHeaderFormat = {
    weekday: "short",
    month: "numeric",
    day: "numeric",
  };
  const handleTodayClick = () => {
    calendarRef.current.getApi().today();
  };
  function handleEventMount(info) {
    const eventElement = info.el;

    eventElement.addEventListener("mouseover", () => {
      eventElement.classList.add("event-pointer");
    });

    eventElement.addEventListener("mouseout", () => {
      eventElement.classList.remove("event-pointer");
    });

    eventElement.style.maxHeight = "30px !important";
  }

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    );
    calendarApi.gotoDate(newDate);
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 7
    );
    calendarApi.gotoDate(newDate);
  };
  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      {eventInfo && (
        <Dialog
          open={loading}
          onClose={() => {
            setLoading(false);
          }}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              width: 600,
              height: 308,
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle
            style={{
              backgroundColor: eventInfo.event.backgroundColor,
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {eventInfo && eventInfo.event.title}
              {eventInfo.event.extendedProps.cancelado == 1 ? (
                <span className="fc-cancelado2">‎ 
                  {t("cancelado").toUpperCase()}‎ ‎ 
                </span>
              ) : null}
            </div>
            <FontAwesomeIcon
              edge="end"
              color="inherit"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
                padding: "8px",
              }}
              onClick={handleClose}
              aria-label="Fechar"
              icon={faClose}
            />
          </DialogTitle>
          {eventInfo && eventInfo.event.extendedProps.id_turma != null ? (
            <DialogContent>
              <div style={{ marginTop: "20px" }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {eventInfo && eventInfo.event.start.toLocaleDateString()}
                </Typography>
                <Typography gutterBottom>
                  <span
                    style={{ fontWeight: "100 !important", color: "black" }}
                  >
                    {t("inicio")}:{" "}
                  </span>
                  <span style={{ fontWeight: "900", color: "black" }}>
                    {eventInfo &&
                      eventInfo.event.start.toLocaleTimeString("en-US", {
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    h
                  </span>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <span
                    style={{ fontWeight: "100 !important", color: "black" }}
                  >
                    {t("fim")}:{" "}
                  </span>
                  <span style={{ fontWeight: "900", color: "black" }}>
                    {eventInfo &&
                      eventInfo.event.end.toLocaleTimeString("en-US", {
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    h
                  </span>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <span
                    style={{ fontWeight: "100 !important", color: "black" }}
                  >
                    {t("local")}:{" "}
                  </span>
                  <span style={{ fontWeight: "900", color: "black" }}>
                    {eventInfo && eventInfo.event.extendedProps.description}
                  </span>
                </Typography>
              </div>

              {eventInfo.event.extendedProps.cancelado != 1 ? (
                <Button
                  className="btnMarcacoes"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                  onClick={() => {
                    setOpen2(true);
                  }}
                >
                  {t("desinscreverTurma")}
                </Button>
              ) : // <Button
              //   className="btnCancelado"
              //   style={{
              //     display: "flex",
              //     alignItems: "center",
              //     justifyContent: "center",
              //     margin: "7% auto",
              //     cursor: "not-allowed",
              //     fontSize: "20px"
              //   }}
              //   disabled
              // >
              //   {t("cancelado")}
              // </Button>
              null}
            </DialogContent>
          ) : (
            <DialogContent>
              <div style={{ marginTop: "20px" }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    fontWeight: "600",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {eventInfo && eventInfo.event.start.toLocaleDateString()}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {t("inicio")}:{" "}
                  {eventInfo && eventInfo.event.start.toLocaleTimeString()}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {t("fim")}:{" "}
                  {eventInfo && eventInfo.event.end.toLocaleTimeString()}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {t("local")}:{" "}
                  {eventInfo && eventInfo.event.extendedProps.description}
                </Typography>
              </div>
              {dataAtual >=
                new Date(`${eventInfo && eventInfo.event.start}`).getTime() &&
              dataAtual <=
                new Date(`${eventInfo && eventInfo.event.end}`).getTime() ? (
                <Button className="btnMarcacoes" style={{ color: "white" }}>
                  {t("infoMarcacoes")}
                </Button>
              ) : null}
              {eventInfo && eventInfo.event.title}
              {eventInfo.event.extendedProps.cancelado == 1 ? null : (
                <Button
                  className="btnMarcacoes"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto ",
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("cancelarMarcacao")}
                </Button>
              )}
            </DialogContent>
          )}
        </Dialog>
      )}
      ;{/* Inscrição */}
      <Dialog
        open={open2}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            width: 600,
            height: 310,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("cancelarInscricao")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("textoCancelamentoInscricao")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${naobtn})`,
              width: 198,
              height: 30,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              handleClose2();
            }}
          >
            {t("cancelar")}
          </Button>
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${simbtn})`,
              width: 218,
              height: 40,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              handleClose2();
              setLoading(false);
              setLoading2(true);
              cancelarInscricao({
                id_turma: eventInfo.event.extendedProps.id_turma,
                id_filho: eventInfo.event.extendedProps.id_filho,
              })
                .then((res) => {
                  if (res.success) {
                    setTimeout(() => {
                      emailCancelarInscricao({
                        id_turma: eventInfo.event.extendedProps.id_turma,
                      }).then((res2) => {
                        toast.success(t("inscricaoCanceladaSucesso"));
                        let utilizador = Cookies.get("utilizador");
                        if (utilizador == "superadmin") {
                          navigate("/dashboard_superadmin");
                        } else if (utilizador == "supercomercial") {
                          navigate("/dashboard_supercommercial");
                        } else if (utilizador == "comercial") {
                          navigate("/dashboard_commercial");
                        } else if (utilizador == "admin") {
                          navigate("/dashboard_admin");
                        } else if (utilizador == "funcionario") {
                          navigate("/dashboard_employee");
                        } else if (utilizador == "professor") {
                          navigate("/dashboard_teacher");
                        } else if (utilizador == "cliente") {
                          navigate("/dashboard_customer");
                        } else {
                          navigate("/dashboard_customer");
                        }
                      });
                    }, 3000);
                  } else {
                    toast.error(t("inscricaoCanceladaErro"));
                  }
                })
                .catch((error) => {});
            }}
          >
            {t("sim")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Marcações */}
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            width: 600,
            height: 310,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("confirmarCancelamentoMarcacao")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("textoCancelamentoMarcacao")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${naobtn})`,
              width: 198,
              height: 30,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              handleClose();
            }}
          >
            {t("cancelar")}
          </Button>
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${simbtn})`,
              width: 218,
              height: 40,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              handleClose();
              setLoading(false);
              setLoading2(true);
              cancelarMarcacao({
                id: marcacao[0].id,
              })
                .then((res) => {
                  if (res.success) {
                    setTimeout(() => {
                      emailCancelarMarcacao({
                        id_ticket: marcacao[0].id,
                      }).then((res2) => {
                        toast.success(t("marcacaoCanceladaSucesso"));
                        let utilizador = Cookies.get("utilizador");
                        if (utilizador == "superadmin") {
                          navigate("/dashboard_superadmin");
                        } else if (utilizador == "supercomercial") {
                          navigate("/dashboard_supercommercial");
                        } else if (utilizador == "comercial") {
                          navigate("/dashboard_commercial");
                        } else if (utilizador == "admin") {
                          navigate("/dashboard_admin");
                        } else if (utilizador == "funcionario") {
                          navigate("/dashboard_employee");
                        } else if (utilizador == "professor") {
                          navigate("/dashboard_teacher");
                        } else if (utilizador == "cliente") {
                          navigate("/dashboard_customer");
                        } else {
                          navigate("/dashboard_customer");
                        }
                      });
                    }, 3000);
                  } else {
                    toast.error(t("marcacaoCanceladaErro"));
                  }
                })
                .catch((error) => {});
            }}
          >
            {t("sim")}
          </Button>
        </DialogActions>
      </Dialog>
      {loading2 && (
        <Dialog
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            textAlign: "center",
          }}
        >
          <DialogContent>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t("aguarde")}
            </Typography>
            <CircularProgress disableShrink style={{ marginTop: 20 }} />
          </DialogContent>
        </Dialog>
      )}
      <Nav />
      <Header />
      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <div className="divTabela">
          <Paper
            elevation={0}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
              backgroundColor: "transparent",
            }}
          >
            <h3 className="titleMarcar">{t("proximasaulas2")}</h3>

            {/* <div className="topleftcliente">
          <IconButton
            onClick={goBack}
            size={window.innerWidth < 600 ? "small" : "medium"}
          >
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div> */}

            {/* <h1
            className="dashboardTitle"
            style={{ paddingTop: window.innerWidth > 800 ? null : "50px" }}
          >
            {t("proximasaulas2")}
          </h1> */}
            <div className="calendar-container">
              <div className="calendar-wrapper">
                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  scrollTime={true}
                  contentHeight={420}
                  titleFormat={titleFormat}
                  headerToolbar={{
                    start: isMobile ? "title" : "",
                    center: isMobile ? "prev next today" : "title",
                    end: isMobile ? "" : "prev next today",
                  }}
                  ref={calendarRef}
                  plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    bootstrap5Plugin,
                  ]}
                  customButtons={{
                    prev: {
                      text: "<",
                      click: handlePrevClick,
                      className: "custom-calendar-button",
                    },
                    next: {
                      text: ">",
                      click: handleNextClick,
                      className: "custom-calendar-button",
                    },
                    today: {
                      text: t("hoje"),
                      click: handleTodayClick,
                      classNames: "custom-calendar-button",
                    },
                  }}
                  dayHeaderFormat={dayHeaderFormat}
                  stickyHeaderDates={false}
                  initialView="listWeek"
                  selectable={true}
                  events={marcacoes}
                  eventDisplay="block"
                  eventClick={handleEventClick}
                  eventDidMount={handleEventMount}
                  datesSet={(dates) => {
                    if (Cookies.get("language") == "pt") {
                      moment.locale("pt-br");
                      const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    } else if (Cookies.get("language") == "en") {
                      moment.locale("en");
                      const startOfWeek = moment(dates.start).add(1, "day"); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    } else if (Cookies.get("language") == "es") {
                      moment.locale("es");
                      const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    }
                  }}
                  eventContent={(arg) => {
                    const { event } = arg;
                    const { title, extendedProps } = event;
                    const isCancelled = extendedProps.cancelado;
                    const canceladoElement = isCancelled
                      ? `<span className="fc-cancelado" style="background-color: #F04643; color: white; margin-left: 15%;">${t(
                          "cancelado".toUpperCase()
                        )}</span>`
                      : "";
                    const elements =
                      document.getElementsByClassName("fc-event");
                    for (let i = 0; i < elements.length; i++) {
                      if (
                        elements[i].getElementsByClassName("fc-cancelado")[0]
                      ) {
                        //elements[i].style.setProperty("pointer-events", "none", "important");
                        elements[i].style.setProperty(
                          "background-color",
                          "#F04643"
                        );
                        elements[i].style.setProperty("color", "white");
                      }
                    }
                    return {
                      html: `<div className="fc-content">${title} ${canceladoElement}</div>`,
                    };
                  }}
                />
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Marcacoes;
