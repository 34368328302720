import Cookies from "js-cookie";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box } from "@mui/system";
import naobtn from "../../images/BotaoNao.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import horariobtn from "../../images/horarioIcons.svg";
import horariobtn2 from "../../images/horariosNaoSelecionado.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import piscinasIconSmall from "../../images/piscinaIconSmall.svg";
import funcionarioIconSmall from "../../images/funcionarioPequeno.svg";
import addhorariosbtn from "../../images/adicionarHorarioBtn.svg";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { addYears, setMonth, setDay, endOfMonth } from "date-fns";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  faMobile,
  faCreditCard,
  faTicket,
  faMoneyBillTransfer,
  faGraduationCap,
  faPersonSwimming,
  faCalendarDay,
  faCalendarDays,
  faPlus,
  faClose,
  faWaterLadder,
  faMoneyBill1Wave,
  faPeopleRoof,
  faMagnifyingGlass,
  faQrcode,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDisponibilidade,
  proximasMarcacoes,
} from "../../api/requests/Marcacoes";
import IconButton from "@mui/material/IconButton";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import {
  calendarioF,
  cancelarEvento,
  cancelarEventoEmail,
  listaPiscinasAgua,
  listaProfessores,
} from "../../api/requests/PiscinaAgua";
import {
  adicionarEspacos,
  listaEspacos,
  listaTiposEspacos,
} from "../../api/requests/OcupaEspaco";
import Nav from "../../components/Nav";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import guardarbtn from "../../images/BotaoGuardar.svg";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import editarbtn from "../../images/BotaoEditar.svg";
import simbtn from "../../images/BotaoSim.svg";

import {
  getAgendamentoPiscina,
  getHorario,
  getHorarios,
  postHorario,
  putHorario,
  getAgendamentoAulas,
  getAgendamentoNadoLivre,
  postDisponibilidadePistas,
  postAgendamento,
  putAgendamento,
  putAgendamentoNado,
  postAgendamentoNado,
  deleteAgendamentoNado,
  deleteAgendamento,
} from "../../api/requests/Horarios";
import {
  deleteFuncionario,
  enviarEmailPostFuncionario,
  getFuncionario,
  getFuncionarios,
  postFuncionario,
  putFuncionario,
  putStatusFuncionario,
} from "../../api/requests/Funcionarios";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import dayjs from "dayjs";
import holidays from "./holidays";
import { format, parseISO, addDays } from "date-fns";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

function HorarioFuncionario() {
  //Traduções
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  //Navigator
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [cancelar, setCancelar] = useState(false);

  const [idu, setIdU] = useState("");
  const [idPiscina, setIdPiscina] = useState("");
  const [selecionado, setSelecionado] = useState(0);
  const [diaSemana, setDiaSemana] = useState(0);
  const [ErroDiaSemana, setErroDiaSemana] = useState(false);
  const [ErroDiaSemanaEditar, setErroDiaSemanaEditar] = useState(false);

  const [horaAbertura, setHoraAbertura] = useState("");
  const [horaFecho, setHoraFecho] = useState("");
  const [erroHoraAbertura, setErroHoraAbertura] = useState(false);
  const [erroHoraFecho, setErroHoraFecho] = useState(false);
  const [erroHoraAberturaEditar, setErroHoraAberturaEditar] = useState(false);
  const [erroHoraFechoEditar, setErroHoraFechoEditar] = useState(false);
  const [fechado, setFechado] = useState(0);
  const [erroProfessor, setErroProfessor] = useState(false);
  const [erroFuncionario, setErroFuncionario] = useState(false);
  const [erroProfessorEditar, setErroProfessorEditar] = useState(false);
  const [erroFuncionarioEditar, setErroFuncionarioEditar] = useState(false);
  const [erroPiscina, setErroPiscina] = useState(false);

  const [erroFechado, setErroFechado] = useState(false);
  const [erroFechadoEditar, setErroFechadoEditar] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);
  const [erroHoraInicio, setErroHoraInicio] = useState(false);
  const [erroHoraFim, setErroHoraFim] = useState(false);
  const [openCriarHorario, setOpenCriarHorario] = useState(false);
  const handleCloseCriarHorario = () => setOpenCriarHorario(false);
  const [openAgendamentos, setOpenAgendamentos] = useState(false);
  const handleCloseAgendamentos = () => {
    setOpenAgendamentos(false);
    setSelecionado(0);
  };
  const [idAula, setIdAula] = useState("");
  const [idNadoLivre, setIdNadoLivre] = useState("");
  const [isAula, setIsAula] = useState(false);
  const [dadosOriginaisAgendamento, setDadosOriginaisAgendamento] = useState(
    []
  );
  const [disponiveis, setDisponiveis] = useState([]);
  const [ocupadas, setOcupadas] = useState([]);

  const [hora_inicio, setHoraInicio] = useState("");
  const [hora_fim, setHoraFim] = useState("");
  const [anoInicio, setAnoInicio] = useState("");
  const [anoFim, setAnoFim] = useState("");
  const [pistasDisponiveis, setPistasDisponiveis] = useState("");
  const [diaSemanaId, setDiaSemanaId] = useState("");
  const [idPiscinaAgua, setIdPiscinaAgua] = useState("");
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);
  const [openEditarAgendamento, setOpenEditarAgendamento] = useState(false);
  const handleCloseEditarAgendamento = () => {
    setOpenEditarAgendamento(false);
    setHoraInicio("");
    setHoraFim("");
    setAnoFim("");
    setAnoInicio("");
    setOcupadas([]);
    setDisponiveis([]);
    setSelectedDay("");
    setSelectedPool("");
    setIdAula("");
    setIdNadoLivre("");
    setEdicaoAgendamentos(true);
  };
  const [openCriarAgendamento, setOpenCriarAgendamento] = useState(false);
  const handleCloseCriarAgendamento = () => {
    setOpenCriarAgendamento(false);
    setHoraInicio("");
    setHoraFim("");
    setAnoFim("");
    setAnoInicio("");
    setOcupadas([]);
    setDisponiveis([]);
    setSelectedDay("");
    setSegunda("");
    setTerca("");
    setQuarta("");
    setSelectedTipo("");
    setQuinta("");
    setSexta("");
    setSabado("");
    setDomingo("");
    setSelectedPool("");
    setIdAula("");
    setIdNadoLivre("");
    setSelectedPistas([]);
  };

  const handleClose = () => {
    setOpen(false);
    setEdicao(true);
    cancelarfn();
  };
  const handleClickOpenCriarHorario = () => {
    setOpenCriarHorario(true);
  };

  const [selectedDay, setSelectedDay] = useState("");

  const handleChange2 = (event) => {
    setSelectedDay(event.target.value);
    // O restante do seu código permanece o mesmo
    switch (event.target.value) {
      case "segunda":
        setSegunda(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTerca(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuarta(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuinta(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSexta(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabado(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingo(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  const [weekDateRange, setWeekDateRange] = useState("");
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const calendarRef = useRef(null);
  const [bookedDays, setBookedDays] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [idHorario, setIdHorario] = useState("");
  const [funcionarios, setFuncionarios] = useState([]);
  const [funcionariosCopy, setFuncionariosCopy] = useState([]);
  const [horariosCopy, setHorariosCopy] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [piscinas, setPiscinas] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState("");
  const [erroTipo, setErroTipo] = useState(false);
  const [data_inicio, setDataInicio] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [erroDataInicio, setErroDataInicio] = useState("");
  const [erroDataFim, setErroDataFim] = useState("");
  const [erroAnoInicio, setErroAnoInicio] = useState("");
  const [erroAnoFim, setErroAnoFim] = useState("");
  const [horarioProfessores, setHorarioProfessores] = useState([]);

  const [professoresCopy, setprofessoresCopy] = useState([]);

  const [selectedFuncionario, setSelectedFuncionario] = useState("");
  const [selectedPool, setSelectedPool] = useState("");

  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [selectedFuncionarioEditar, setSelectedFuncionarioEditar] =
    useState("");
  const [selectedProfessorEditar, setSelectedProfessorEditar] = useState("");
  const [horaAberturaEditar, setHoraAberturaEditar] = useState("00:00");
  const [horaFechoEditar, setHoraFechoEditar] = useState("00:00");
  const [diaSemanaEditar, setDiaSemanaEditar] = useState("");
  const [fechadoEditar, setFechadoEditar] = useState("");
  const [edicao, setEdicao] = useState(true);
  const [dia, setDia] = useState("");

  const [dadosAbertura, setDadosAbertura] = useState("");
  const [dadosFecho, setDadosFecho] = useState("");
  const [dadosDia, setDadosDiaSemana] = useState("");
  const [dadosFechado, setDadosFechado] = useState("");
  const [agendamentos, setAgendamentos] = useState([]);
  const [agendamentosCopy, setAgendamentosCopy] = useState([]);
  const dias = [
    t("domingo"),
    t("segunda"),
    t("terca"),
    t("quarta"),
    t("quinta"),
    t("sexta"),
    t("sabado"),
    t("feriado"),
  ];
  const [segunda, setSegunda] = useState(0);
  const [terca, setTerca] = useState(0);
  const [quarta, setQuarta] = useState(0);
  const [quinta, setQuinta] = useState(0);
  const [sexta, setSexta] = useState(0);
  const [sabado, setSabado] = useState(0);
  const [domingo, setDomingo] = useState(0);
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [selectedPistas, setSelectedPistas] = useState([]);

  const handlePistaChange = (event, pista) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected pista to the array
      setSelectedPistas((prevSelected) => [...prevSelected, pista]);
    } else {
      // Remove the unselected pista from the array
      setSelectedPistas((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== pista.id)
      );
    }
  };
  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };
  useEffect(() => {
    if (
      // data_inicio != "" &&
      // data_fim != "" &&
      selectedDay != "" &&
      selectedPool != "" &&
      (segunda != 0 ||
        terca != 0 ||
        quarta != 0 ||
        quinta != 0 ||
        sexta != 0 ||
        sabado != 0 ||
        domingo != 0) &&
      hora_inicio != "Invalid Date" &&
      hora_inicio != "" &&
      hora_fim != "Invalid Date" &&
      hora_fim != ""
    ) {
      let diaEscolhido;
      if (selectedDay == "segunda") {
        diaEscolhido = 1;
      } else if (selectedDay == "terca") {
        diaEscolhido = 2;
      } else if (selectedDay == "quarta") {
        diaEscolhido = 3;
      } else if (selectedDay == "quinta") {
        diaEscolhido = 4;
      } else if (selectedDay == "sexta") {
        diaEscolhido = 5;
      } else if (selectedDay == "sabado") {
        diaEscolhido = 6;
      } else if (selectedDay == "domingo") {
        diaEscolhido = 7;
      }
      setDia(diaEscolhido);

      postDisponibilidadePistas({
        id_piscina: localStorage.getItem("idPiscina"),
        id_piscina_agua: selectedPool,
        dia_semana: diaEscolhido,
        hora_inicio: hora_inicio,
        hora_fim: hora_fim,
      }).then((res) => {
        if (res.success) {
          let arrayFinal = [];
          let dados = res.data.data;
          dados.forEach((element) => {
            const linha = {
              id: element.id,
              nome: element.name,
            };
            arrayFinal.push(linha);
            setDisponiveis(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      });
    }
  }, [
    selectedPool,
    segunda,
    terca,
    quarta,
    quinta,
    sexta,
    sabado,
    domingo,
    hora_inicio,
    hora_fim,
    selectedDay,
  ]);

  useEffect(() => {
    if (
      anoInicio &&
      anoFim &&
      selectedPool &&
      selectedDay &&
      hora_inicio &&
      hora_fim &&
      (idAula || idNadoLivre)
    ) {
      if (selectedDay == "segunda") {
        setDia(1);
      } else if (selectedDay == "terca") {
        setDia(2);
      } else if (selectedDay == "quarta") {
        setDia(3);
      } else if (selectedDay == "quinta") {
        setDia(4);
      } else if (selectedDay == "sexta") {
        setDia(5);
      } else if (selectedDay == "sabado") {
        setDia(6);
      } else if (selectedDay == "domingo") {
        setDia(7);
      }

      if (dia != "") {
        postDisponibilidadePistas({
          id_piscina: localStorage.getItem("idPiscina"),
          id_piscina_agua: selectedPool,
          dia_semana: dia,
          hora_inicio: hora_inicio,
          hora_fim: hora_fim,
        }).then((res) => {
          if (res.success) {
            let arrayFinal = [];
            let dados = res.data.data;
            dados.forEach((element) => {
              const linha = {
                id: element.id,
                nome: element.name,
              };
              arrayFinal.push(linha);
              const todasPistas = ocupadas.concat(arrayFinal);

              const todasPistasComMeiaPista = todasPistas.flatMap((pista) => {
                const idSemM = pista.id.replace("M", "");
                const idComM = pista.id + "M";

                return [
                  { id: idSemM, nome: pista.nome },
                  { id: idComM, nome: pista.nome + t(" (Meia Pista)") },
                ];
              });

              const todasPistasAjustadas = todasPistasComMeiaPista.map(
                (pista) => {
                  const idMM = pista.id;
                  if (pista.id.includes("MM")) {
                    return { id: idMM.replace("MM", "M"), nome: pista.nome };
                  }
                  return pista;
                }
              );

              const todasPistasSemRepeticao = [
                ...new Set(todasPistasAjustadas.map((pista) => pista.id)),
              ].map((id) =>
                todasPistasAjustadas.find((pista) => pista.id === id)
              );

              setDisponiveis(todasPistasSemRepeticao);
            });
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        });
      }
    }
  }, [selectedPool, dia, hora_inicio, hora_fim, idAula, idNadoLivre]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }

  function adicionarAgendamento() {
    let ano_inicio = moment(data_inicio).format("YYYY");
    let ano_fim = moment(data_fim).format("YYYY");
    let hora_iniciof = moment(hora_inicio, "HH:mm").format("HH:mm:ss");
    let hora_fimf = moment(hora_fim, "HH:mm").format("HH:mm:ss");
    let pistasString = selectedPistas.map((pista) => pista.id).join(", ");

    setFreeze(true);
    if (selectedTipo == 2) {
      postAgendamento({
        id_piscina_agua: selectedPool,
        pistas_disponiveis: pistasString,
        ano_inicio: ano_inicio,
        ano_fim: ano_fim,
        hora_inicio: hora_iniciof,
        hora_fim: hora_fimf,
        dia_semana: dia,
        id_piscina: localStorage.getItem("idPiscina"),
      }).then((res) => {
        if (res.success) {
          atualizar();
          handleCloseCriarAgendamento();
          setFreeze(false);
          toast.success(t("agendamentoAdicionar"));
        } else {
          setFreeze(false);
          toast.error(res.error);
        }
      });
    } else if (selectedTipo == 1) {
      postAgendamentoNado({
        id_piscina_agua: selectedPool,
        pistas_disponiveis: pistasString,
        ano_inicio: ano_inicio,
        ano_fim: ano_fim,
        hora_inicio: hora_iniciof,
        hora_fim: hora_fimf,
        dia_semana: dia,
        id_piscina: localStorage.getItem("idPiscina"),
      }).then((res) => {
        if (res.success) {
          atualizar();
          handleCloseCriarAgendamento();
          setFreeze(false);
          toast.success(t("agendamentoNadoAdicionar"));
        } else {
          setFreeze(false);
          toast.error(res.error);
        }
      });
    }
    setFreeze(false);
  }
  const [edicaoAgendamentos, setEdicaoAgendamentos] = useState(true);

  function editarAgendamento() {
    let hora_iniciof = moment(hora_inicio, "HH:mm").format("HH:mm:ss");
    let hora_fimf = moment(hora_fim, "HH:mm").format("HH:mm:ss");
    let pistasString = selectedPistas.map((pista) => pista.id).join(", ");

    setFreeze(true);
    if (isAula == true) {
      putAgendamento({
        id_agendamento_aulas: idAula,
        // id_piscina_agua: selectedPool,
        pistas_disponiveis: pistasString,
        hora_inicio: hora_iniciof,
        hora_fim: hora_fimf,
        dia_semana: dia,
      }).then((res) => {
        if (res.success) {
          openEditarAgendamento(false);
          toast.success(t("agendamentoEditar"));
          atualizar();
          handleCloseEditarAgendamento();
          setFreeze(false);
        } else {
          setFreeze(false);
          toast.error(res.error);
        }
      });
    } else {
      putAgendamentoNado({
        id_agendamento_nado_livre: idNadoLivre,
        id_piscina_agua: selectedPool,
        pistas_disponiveis: pistasString,
        hora_inicio: hora_iniciof,
        hora_fim: hora_fimf,
        dia_semana: dia,
      }).then((res) => {
        if (res.success) {
          toast.success(t("agendamentoNadoEditar"));
          atualizar();
          handleCloseEditarAgendamento();

          setFreeze(false);
        } else {
          setFreeze(false);
          toast.error(res.error);
        }
      });
    }
    setFreeze(false);
  }

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  const [cancelarEdicaoAgendamento, setCancelarEdicaoAgendamento] =
    useState(false);

  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  const [horaEscolhida, setHoraEscolhida] = useState("");

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };
  const handleClickOpenPopupCancelarAgendamento = () => {
    setCancelarEdicaoAgendamento(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClosePopupCancelarAgendamento = () => {
    setCancelarEdicaoAgendamento(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  let today = new Date();
  const [freeze, setFreeze] = useState(false);

  const handleEventClick = (info) => {
    setEventInfo(info);
    setHoraAberturaEditar(
      dayjs(moment(info.event.start).format("HH:mm:ss"), "HH:mm:ss")
    );
    setHoraFechoEditar(
      dayjs(moment(info.event.end).format("HH:mm:ss"), "HH:mm:ss")
    );
    setFechadoEditar(info.event.extendedProps.fechado);
    setIdHorario(info.event.extendedProps.description);

    const data = new Date(info.event.start);
    const diaSemana = data.getUTCDay();
    const diaSemanaDesejado = diaSemana;
    const isHoliday = holidays.some((holiday) => {
      const holidayDate = new Date(holiday.start);
      return (
        holidayDate.getDate() === data.getDate() &&
        holidayDate.getMonth() === data.getMonth() &&
        holidayDate.getFullYear() === data.getFullYear()
      );
    });

    // Se for um feriado, defina o dia da semana como 7
    if (isHoliday) {
      setDiaSemanaEditar(7);
    } else {
      setDiaSemanaEditar(diaSemanaDesejado);
    }

    setDadosAbertura(
      dayjs(moment(info.event.start).format("HH:mm:ss"), "HH:mm:ss")
    );
    setDadosFecho(dayjs(moment(info.event.end).format("HH:mm:ss"), "HH:mm:ss"));
    setDadosFechado(info.event.extendedProps.fechado);
    setDadosDiaSemana(diaSemanaDesejado);
    setOpen(true);
    setEdicao(true);
  };
  useEffect(() => {}, [
    horaAberturaEditar,
    horaFechoEditar,
    handleEventClick,
    selecionado,
  ]);
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdU(id);
    setIdPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    if (isAula == true && idAula != "") {
      getAgendamentoAulas({ id: idAula })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];

            setDadosOriginaisAgendamento(dados);
            // setIdAula(dados.id_agendamento);
            var anoInicioF = dados.ano_inicio;

            var dataInicio = new Date(anoInicioF, 0, 1);
            var anoFimF = dados.ano_fim;

            var dataFim = new Date(anoFimF, 0, 1);
            setAnoInicio(dataInicio);
            setAnoFim(dataFim);
            var pistasDisponiveisString = dados.pistas_disponiveis;

            // Convertendo a string para um array usando o método split
            const disp = pistasDisponiveisString
              .split(",")
              .map((id) => ({ id: id.trim(), nome: id.trim() }));
            setOcupadas(disp);
            setSelectedPistas(disp);
            if (dados.dia_semana == 1) {
              setSelectedDay("segunda");
            } else if (dados.dia_semana == 2) {
              setSelectedDay("terca");
            } else if (dados.dia_semana == 3) {
              setSelectedDay("quarta");
            } else if (dados.dia_semana == 4) {
              setSelectedDay("quinta");
            } else if (dados.dia_semana == 5) {
              setSelectedDay("sexta");
            } else if (dados.dia_semana == 6) {
              setSelectedDay("sabado");
            } else if (dados.dia_semana == 7) {
              setSelectedDay("domingo");
            }
            setSelectedPool(dados.id_piscina_agua);
            setHoraInicio(parseISO(`1970-01-01T${dados.hora_inicio}`));
            setHoraFim(parseISO(`1970-01-01T${dados.hora_fim}`));
            setDiaSemanaId(dados.dia_semana);

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
    if (isAula == false && idNadoLivre != "") {
      getAgendamentoNadoLivre({ id: idNadoLivre })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];

            setDadosOriginaisAgendamento(dados);
            setIdNadoLivre(dados.id_agendamento_nado_livre);
            var anoInicioF = dados.ano_inicio;

            var dataInicio = new Date(anoInicioF, 0, 1);
            var anoFimF = dados.ano_fim;

            var dataFim = new Date(anoFimF, 0, 1);
            setAnoInicio(dataInicio);
            setAnoFim(dataFim);

            if (dados.dia_semana == 1) {
              setSelectedDay("segunda");
            } else if (dados.dia_semana == 2) {
              setSelectedDay("terca");
            } else if (dados.dia_semana == 3) {
              setSelectedDay("quarta");
            } else if (dados.dia_semana == 4) {
              setSelectedDay("quinta");
            } else if (dados.dia_semana == 5) {
              setSelectedDay("sexta");
            } else if (dados.dia_semana == 6) {
              setSelectedDay("sabado");
            } else if (dados.dia_semana == 7) {
              setSelectedDay("domingo");
            }
            setDiaSemanaId(dados.dia_semana);
            setSelectedPool(dados.id_piscina_agua);
            setHoraInicio(parseISO(`1970-01-01T${dados.hora_inicio}`));
            setHoraFim(parseISO(`1970-01-01T${dados.hora_fim}`));
            var pistasDisponiveisString = dados.pistas_disponiveis;

            const disp = pistasDisponiveisString
              .split(",")
              .map((id) => ({ id: id.trim(), nome: id.trim() }));
            setOcupadas(disp);
            setSelectedPistas(disp);

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idAula, idNadoLivre, isAula]);

  const handleTodayClick = () => {
    calendarRef.current.getApi().today();
  };

  function handleEventMount(info) {
    const eventElement = info.el;

    eventElement.addEventListener("mouseover", () => {
      eventElement.classList.add("event-pointer");
    });

    eventElement.addEventListener("mouseout", () => {
      eventElement.classList.remove("event-pointer");
    });
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [calendarMini, setCalendarMini] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setCalendarMini(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    getHorarios({ id_piscina_edificio: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];

          res.data.data.forEach((element) => {
            const startTimeStr = element.hora_abertura;
            const endTimeStr = element.hora_fecho;
            const diaSemana = element.dia_semana;

            if (diaSemana == 7) {
              holidays.forEach((holiday) => {
                const startDate = new Date(holiday.start);
                startDate.setHours(startTimeStr.split(":")[0]); // Define a hora de início
                startDate.setMinutes(startTimeStr.split(":")[1]); // Define os minutos de início

                const endDate = new Date(holiday.end);
                endDate.setHours(endTimeStr.split(":")[0]); // Define a hora de término
                endDate.setMinutes(endTimeStr.split(":")[1]);

                arrayFinal.push({
                  title: t("Feriado"),
                  // title: element.fechado === 1 ? t("fechado") : t("aberto"),
                  start: startDate,
                  end: endDate,
                  description: element.id_abertura_fecho_piscina,
                  fechado: element.fechado,
                });
              });
            } else {
              // Criar data de início com base no dia da semana e horário
              const dataAtual = new Date();

              // Calcula a diferença de dias entre o dia da semana desejado e o dia atual
              const diffDays = (diaSemana - dataAtual.getDay() + 7) % 7;

              // Define a data de início com a diferença de dias
              const startDate = new Date(dataAtual);
              startDate.setDate(dataAtual.getDate() + diffDays);

              // Divide a string de horário em partes (horas, minutos e segundos)
              const startTimeParts = startTimeStr.split(":");
              startDate.setHours(startTimeParts[0]);
              startDate.setMinutes(startTimeParts[1]);
              startDate.setSeconds(startTimeParts[2]);

              // Criar data de fim com base no horário de término
              const endTimeParts = endTimeStr.split(":");
              const endDate = new Date(startDate);
              endDate.setHours(endTimeParts[0]);
              endDate.setMinutes(endTimeParts[1]);
              endDate.setSeconds(endTimeParts[2]);
              const nextYear = addYears(new Date(), 1);
              const augustNextYear = setMonth(setDay(nextYear, 31), 7); // 7 representa agosto (0-11)
              const lastDayOfAugustNextYear = endOfMonth(augustNextYear);

              // Repetir o evento semanalmente até 31 de agosto do próximo ano
              while (startDate < lastDayOfAugustNextYear) {
                const isNotHoliday = !holidays.some((holiday) => {
                  const holidayDate = new Date(holiday.start);
                  return (
                    holidayDate.toDateString() === startDate.toDateString()
                  );
                });

                if (isNotHoliday) {
                  arrayFinal.push({
                    title: element.fechado == 1 ? t("fechado") : t("aberto"),
                    start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
                    end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
                    description: element.id_abertura_fecho_piscina,
                    fechado: element.fechado,
                  });
                }

                startDate.setDate(startDate.getUTCDate() + 7);
                endDate.setDate(endDate.getUTCDate() + 7);
              }
            }
          });
          setHorarios(arrayFinal);
        } else {
          toast.warn("erroWebservice");
        }
      })
      .catch((error) => {
        toast.error("erroWebservice");
      });

    getAgendamentoPiscina({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          setAgendamentos(res.data.data);
          setAgendamentosCopy(res.data.data);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });



    // listaTiposEspacos({})
    //   .then((res) => {
    //     if (res.success) {
    //       let arrayFinal = [];
    //       res.data.data.forEach((element) => {
    //         const linha = {
    //           id: element.id,
    //           nome: element.nome,
    //         };
    //         arrayFinal.push(linha);
    //         setTipos(arrayFinal);
    //       });
    //     } else {
    //       if (res.status == 500) {
    //         navigate("/500");
    //       } else if (res.status == 403) {
    //         let utilizador = Cookies.get("utilizador");
    //         if (utilizador == "superadmin") {
    //           navigate("/dashboard_superadmin");
    //         } else if (utilizador == "supercomercial") {
    //           navigate("/dashboard_supercommercial");
    //         } else if (utilizador == "comercial") {
    //           navigate("/dashboard_commercial");
    //         } else if (utilizador == "admin") {
    //           navigate("/dashboard_admin");
    //         } else if (utilizador == "funcionario") {
    //           navigate("/dashboard_employee");
    //         } else if (utilizador == "professor") {
    //           navigate("/dashboard_teacher");
    //         } else if (utilizador == "cliente") {
    //           navigate("/dashboard_customer");
    //         }
    //       }
    //       toast.error(res.error);
    //     }
    //   })
    //   .catch((error) => {
    //     //toast.warn(t("erroWebservice"));
    //   });
    setTipos([
      {
        id: 1,
        nome: t("banhosLivres"),
      },
      {
        id: 2,
        nome: t("aulas"),
      },
    ]);

    listaPiscinasAgua({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    // getFuncionarios({ id: localStorage.getItem("idPiscina") })
    //   .then((res) => {
    //     if (res.success) {
    //       let arrayFinal = [];
    //       res.data.data.forEach((element) => {
    //         const linha = {
    //           id: element.id_utilizador,
    //           nome: element.nome,
    //           email: element.email,
    //           status: element.status == 1 ? t("ativo") : t("inativo"),
    //         };
    //         arrayFinal.push(linha);
    //         setFuncionarios(arrayFinal);
    //         setFuncionariosCopy(arrayFinal);
    //       });
    //     } else {
    //       if (res.status == 500) {
    //         navigate("/500");
    //       } else if (res.status == 403) {
    //         let utilizador = Cookies.get("utilizador");
    //         if (utilizador == "superadmin") {
    //           navigate("/dashboard_superadmin");
    //         } else if (utilizador == "supercomercial") {
    //           navigate("/dashboard_supercommercial");
    //         } else if (utilizador == "comercial") {
    //           navigate("/dashboard_commercial");
    //         } else if (utilizador == "admin") {
    //           navigate("/dashboard_admin");
    //         } else if (utilizador == "funcionario") {
    //           navigate("/dashboard_employee");
    //         } else if (utilizador == "professor") {
    //           navigate("/dashboard_teacher");
    //         } else if (utilizador == "cliente") {
    //           navigate("/dashboard_customer");
    //         }
    //       }
    //       toast.error(res.error);
    //     }
    //   })
    //   .catch((error) => {
    //     //toast.warn(t("erroWebservice"));
    //   });
  }, []);

  function atualizar() {
    getHorarios({ id_piscina_edificio: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];

          res.data.data.forEach((element) => {
            const startTimeStr = element.hora_abertura;
            const endTimeStr = element.hora_fecho;
            const diaSemana = element.dia_semana;

            if (diaSemana == 7) {
              holidays.forEach((holiday) => {
                const startDate = new Date(holiday.start);
                startDate.setHours(startTimeStr.split(":")[0]); // Define a hora de início
                startDate.setMinutes(startTimeStr.split(":")[1]); // Define os minutos de início

                const endDate = new Date(holiday.end);
                endDate.setHours(endTimeStr.split(":")[0]); // Define a hora de término
                endDate.setMinutes(endTimeStr.split(":")[1]);

                arrayFinal.push({
                  title: t("Feriado"),
                  // title: element.fechado === 1 ? t("fechado") : t("aberto"),
                  start: startDate,
                  end: endDate,
                  description: element.id_abertura_fecho_piscina,
                  fechado: element.fechado,
                });
              });
            } else {
              // Criar data de início com base no dia da semana e horário
              const dataAtual = new Date();

              // Calcula a diferença de dias entre o dia da semana desejado e o dia atual
              const diffDays = (diaSemana - dataAtual.getDay() + 7) % 7;

              // Define a data de início com a diferença de dias
              const startDate = new Date(dataAtual);
              startDate.setDate(dataAtual.getDate() + diffDays);

              // Divide a string de horário em partes (horas, minutos e segundos)
              const startTimeParts = startTimeStr.split(":");
              startDate.setHours(startTimeParts[0]);
              startDate.setMinutes(startTimeParts[1]);
              startDate.setSeconds(startTimeParts[2]);

              // Criar data de fim com base no horário de término
              const endTimeParts = endTimeStr.split(":");
              const endDate = new Date(startDate);
              endDate.setHours(endTimeParts[0]);
              endDate.setMinutes(endTimeParts[1]);
              endDate.setSeconds(endTimeParts[2]);
              const nextYear = addYears(new Date(), 1);
              const augustNextYear = setMonth(setDay(nextYear, 31), 7); // 7 representa agosto (0-11)
              const lastDayOfAugustNextYear = endOfMonth(augustNextYear);

              // Repetir o evento semanalmente até 31 de agosto do próximo ano
              while (startDate < lastDayOfAugustNextYear) {
                const isNotHoliday = !holidays.some((holiday) => {
                  const holidayDate = new Date(holiday.start);
                  return (
                    holidayDate.toDateString() === startDate.toDateString()
                  );
                });

                if (isNotHoliday) {
                  arrayFinal.push({
                    title: element.fechado == 1 ? t("fechado") : t("aberto"),
                    start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
                    end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
                    description: element.id_abertura_fecho_piscina,
                    fechado: element.fechado,
                  });
                }

                startDate.setDate(startDate.getUTCDate() + 7);
                endDate.setDate(endDate.getUTCDate() + 7);
              }
            }
          });

          setHorarios(arrayFinal);
        } else {
          toast.warn("erroWebservice");
        }
      })
      .catch((error) => {
        toast.error("erroWebservice");
      });

    getAgendamentoPiscina({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          setAgendamentos(res.data.data);
          setAgendamentosCopy(res.data.data);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    // listaTiposEspacos({})
    //   .then((res) => {
    //     if (res.success) {
    //       let arrayFinal = [];
    //       res.data.data.forEach((element) => {
    //         const linha = {
    //           id: element.id,
    //           nome: element.nome,
    //         };
    //         arrayFinal.push(linha);
    //         setTipos(arrayFinal);
    //       });
    //     } else {
    //       if (res.status == 500) {
    //         navigate("/500");
    //       } else if (res.status == 403) {
    //         let utilizador = Cookies.get("utilizador");
    //         if (utilizador == "superadmin") {
    //           navigate("/dashboard_superadmin");
    //         } else if (utilizador == "supercomercial") {
    //           navigate("/dashboard_supercommercial");
    //         } else if (utilizador == "comercial") {
    //           navigate("/dashboard_commercial");
    //         } else if (utilizador == "admin") {
    //           navigate("/dashboard_admin");
    //         } else if (utilizador == "funcionario") {
    //           navigate("/dashboard_employee");
    //         } else if (utilizador == "professor") {
    //           navigate("/dashboard_teacher");
    //         } else if (utilizador == "cliente") {
    //           navigate("/dashboard_customer");
    //         }
    //       }
    //       toast.error(res.error);
    //     }
    //   })
    //   .catch((error) => {
    //     //toast.warn(t("erroWebservice"));
    //   });
    setTipos([
      {
        id: 1,
        nome: t("banhosLivres"),
      },
      {
        id: 2,
        nome: t("aulas"),
      },
    ]);

    listaPiscinasAgua({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    getFuncionarios({ id: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
              email: element.email,
              status: element.status == 1 ? t("ativo") : t("inativo"),
            };
            arrayFinal.push(linha);
            setFuncionarios(arrayFinal);
            setFuncionariosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  const handlePoolChange = (event) => {
    setSelectedPool(event.target.value);
    setErroPiscina(false);
  };
  const handleTipoChange = (event) => {
    setSelectedTipo(event.target.value);

    // if (event.target.value == 1) {
    //   setSelectedTipo({ id: 1, nome: t("nado_livre") });
    // } else {
    //   setSelectedTipo({ id: 2, nome: t("aulas") });
    // }

    setErroTipo(false);
  };
  const handleFuncionarioChange = (event) => {
    setSelectedFuncionario(event.target.value);
    setErroFuncionario(false);
  };

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
    setErroProfessor(false);
  };

  const handleFuncionarioChangeEditar = (event) => {
    setSelectedFuncionarioEditar(event.target.value);
    setErroFuncionarioEditar(false);
  };

  const handleProfessorChangeEditar = (event) => {
    setSelectedProfessorEditar(event.target.value);
    setErroProfessorEditar(false);
  };

  function limparVariaveis() {
    setHoraAbertura("");
    setHoraFecho("");
    setFechado(0);
    setDiaSemana(0);
  }

  function atualizarHorario() {
    getHorarios({ id_piscina_edificio: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];

          res.data.data.forEach((element) => {
            const startTimeStr = element.hora_abertura;
            const endTimeStr = element.hora_fecho;
            const diaSemana = element.dia_semana;

            if (diaSemana == 7) {
              holidays.forEach((holiday) => {
                const startDate = new Date(holiday.start);
                startDate.setHours(startTimeStr.split(":")[0]); // Define a hora de início
                startDate.setMinutes(startTimeStr.split(":")[1]); // Define os minutos de início

                const endDate = new Date(holiday.end);
                endDate.setHours(endTimeStr.split(":")[0]); // Define a hora de término
                endDate.setMinutes(endTimeStr.split(":")[1]);

                arrayFinal.push({
                  title: t("Feriado"),
                  // title: element.fechado === 1 ? t("fechado") : t("aberto"),
                  start: startDate,
                  end: endDate,
                  description: element.id_abertura_fecho_piscina,
                  fechado: element.fechado,
                });
              });
            } else {
              // Criar data de início com base no dia da semana e horário
              const dataAtual = new Date();

              // Calcula a diferença de dias entre o dia da semana desejado e o dia atual
              const diffDays = (diaSemana - dataAtual.getDay() + 7) % 7;

              // Define a data de início com a diferença de dias
              const startDate = new Date(dataAtual);
              startDate.setDate(dataAtual.getDate() + diffDays);

              // Divide a string de horário em partes (horas, minutos e segundos)
              const startTimeParts = startTimeStr.split(":");
              startDate.setHours(startTimeParts[0]);
              startDate.setMinutes(startTimeParts[1]);
              startDate.setSeconds(startTimeParts[2]);

              // Criar data de fim com base no horário de término
              const endTimeParts = endTimeStr.split(":");
              const endDate = new Date(startDate);
              endDate.setHours(endTimeParts[0]);
              endDate.setMinutes(endTimeParts[1]);
              endDate.setSeconds(endTimeParts[2]);

              // Verifica se o dia 31 de agosto já passou no ano atual
              const currentYear = startDate.getFullYear();
              const nextAugust31 = new Date(currentYear, 7, 31); // 7 representa agosto (0-11)

              if (dataAtual > nextAugust31) {
                // O dia 31 de agosto já passou, avança para o próximo ano
                currentYear++;
              }

              // Encontre a data de término como o próximo 31 de agosto
              const nextAugust31InCurrentYear = new Date(currentYear, 7, 31);

              // Repetir o evento semanalmente até o próximo 31 de agosto
              while (startDate < nextAugust31InCurrentYear) {
                const isNotHoliday = !holidays.some((holiday) => {
                  const holidayDate = new Date(holiday.start);
                  return (
                    holidayDate.toDateString() === startDate.toDateString()
                  );
                });

                if (isNotHoliday) {
                  arrayFinal.push({
                    title: element.fechado == 1 ? t("fechado") : t("aberto"),
                    start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
                    end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
                    description: element.id_abertura_fecho_piscina,
                    fechado: element.fechado,
                  });
                }

                startDate.setDate(startDate.getDate() + 7);
                endDate.setDate(endDate.getDate() + 7);
              }
            }
          });

          setHorarios(arrayFinal);
        } else {
          toast.warn("erroWebservice");
        }
      })
      .catch((error) => {
        toast.error("erroWebservice");
      });
  }
  function verHorario(idHorario) {
    getHorario({ id: idHorario })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];
          setDadosOriginais(dados);

          setDiaSemanaEditar(dados.diaSemana);
          setHoraAberturaEditar(dados.horaAbertura);
          setHoraFechoEditar(dados.horaFecho);
          setSelectedFuncionarioEditar(dados.funcionario);
          setSelectedProfessorEditar(dados.professor);
          setFechadoEditar(dados.fechado);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }

  function adicionarHorario() {
    setFreeze(true);

    let hora_aberturaf = moment(horaAbertura).format("HH:mm:ss");
    let hora_fechof = moment(horaFecho).format("HH:mm:ss");

    postHorario({
      id_piscina: localStorage.getItem("idPiscina"),
      dia_semana: diaSemana,
      hora_abertura: hora_aberturaf,
      hora_fecho: hora_fechof,
      fechado: fechado,
    }).then((res) => {
      if (res.success) {
        toast.success(t("horarioAdicionar"));
        setFreeze(false);
        atualizarHorario();
        limparVariaveis();
        handleCloseCriarHorario();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  const handleChange = () => {
    setFechado(fechado === 1 ? 0 : 1);
  };

  const handleChangeEditar = () => {
    setFechadoEditar(fechadoEditar === 1 ? 0 : 1);
  };
  const handleChangeDiaSemana = (event) => {
    setDiaSemana(event.target.value);
  };
  const handleChangeDiaSemanaEditar = (event) => {
    setDiaSemanaEditar(event.target.value);
  };
  function validacao1() {
    if (diaSemana !== "" && horaAbertura && horaFecho) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarHorario();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!diaSemana) {
        setErroDiaSemana(true);
      }
      if (!horaAbertura) {
        setErroHoraAbertura(true);
      }
      if (!horaFecho) {
        setErroHoraFecho(true);
      }
      if (!fechado) {
        setErroFechado(true);
      }
    }
  }

  function cancelarfn() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    //Colocar os dados originais
    setDiaSemanaEditar(dadosDia);
    setHoraAberturaEditar(dadosAbertura);
    setHoraFechoEditar(dadosFecho);
    // setSelectedFuncionarioEditar(dadosFuncionario);
    // setSelectedProfessorEditar(dadosProfessor);
    setFechadoEditar(dadosFechado);

    //Limpar erros e mensagens de erro
    setErroDiaSemanaEditar(false);
    setErroFechadoEditar(false);
    setErroFuncionarioEditar(false);
    setErroProfessorEditar(false);
    setErroHoraAberturaEditar(false);
    setErroHoraFechoEditar(false);

    window.scrollTo(0, 0);
  }

  function editarH() {
    setFreeze(true);

    const hora_aberturaf = horaAberturaEditar.format("HH:mm:ss");
    const hora_fechof = horaFechoEditar.format("HH:mm:ss");

    putHorario({
      id_abertura_fecho_piscina: idHorario,
      hora_abertura: hora_aberturaf,
      hora_fecho: hora_fechof,
      fechado: fechadoEditar,
      dia_semana: diaSemanaEditar,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          atualizarHorario();
          handleClose();
          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            navigate("/500");
            setFreeze(false);
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
      });
  }

  function validacao() {
    if (diaSemanaEditar !== "" && horaAberturaEditar && horaFechoEditar) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarH();
      } else {
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      if (!diaSemanaEditar) {
        setErroDiaSemanaEditar(true);
      }
      if (!horaAberturaEditar) {
        setErroHoraAberturaEditar(true);
      }
      if (!horaFechoEditar) {
        setErroHoraFechoEditar(true);
      }
      if (!fechadoEditar) {
        setErroFechadoEditar(true);
      }
    }
  }

  useEffect(() => {
    if (calendarMini && calendarRef.current) {
      // Verifique se a variável calendarMini é verdadeira e se a referência do calendário está disponível
      calendarRef.current.getApi().changeView("timeGridWeek");
    }
  }, [calendarMini]);

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "nome_piscina_agua", "tipo"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile < 800 ? ["id", "nome_piscina_agua", "tipo"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function eliminarAgendamento() {
    if (idAula != "") {
      deleteAgendamento({ id_agendamento_aulas: idAula }).then((res) => {
        if (res.success) {
          toast.success(t("agendamentoEliminado"));
          atualizar();
          handleCloseEditarAgendamento();
          setEliminar(false);
          setFreeze(false);
        } else {
          setFreeze(false);
          toast.error(res.error);
        }
      });
    } else if (idNadoLivre != "") {
      deleteAgendamentoNado({ id_agendamento_nado_livre: idNadoLivre }).then(
        (res) => {
          if (res.success) {
            toast.success(t("agendamentoEliminado"));
            atualizar();
            handleCloseEditarAgendamento();
            setEliminar(false);
            setFreeze(false);
          } else {
            setFreeze(false);
            toast.error(res.error);
          }
        }
      );
    }
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("horarios")}</h3>

        <div className="divTabelaM">
          <Paper
            elevation={0}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
              backgroundColor: "transparent",
            }}
          >
            {freeze && (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Dialog
                  open={true}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <DialogContent>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {t("aguarde")}
                    </Typography>
                    <CircularProgress disableShrink style={{ marginTop: 20 }} />
                  </DialogContent>
                </Dialog>
              </div>
            )}
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="btnHorario"
                  variant="contained"
                  style={{
                    backgroundImage:
                      selecionado == 0
                        ? `url(${horariobtn})`
                        : `url(${horariobtn2})`,
                    width: isMobile ? 98 : 129,
                    height: isMobile ? 30 : 40,
                    cursor: "pointer",
                    zIndex: 200,
                    marginInline: "5px",
                    fontSize: isMobile ? "8px" : "12px",
                  }}
                  onClick={() => {
                    setSelecionado(0);
                  }}
                >
                  <span
                    style={{
                      width: 17,
                      height: 8,
                      backgroundImage: `url(${piscinasIconSmall})`,
                      marginRight: "5px",
                    }}
                  />
                  {t("piscina")}
                </Button>
                {/* Funcionário */}
                {/* <Button
                  className="btnHorario"
                  variant="contained"
                  style={{
                    backgroundImage:
                      selecionado == 1
                        ? `url(${horariobtn})`
                        : `url(${horariobtn2})`,
                    width: isMobile ? 98 : 129,
                    height: isMobile ? 30 : 40,
                    cursor: "pointer",
                    zIndex: 200,
                    marginInline: "5px",
                    fontSize: isMobile ? "8px" : "12px",
                  }}
                  onClick={() => {
                    setSelecionado(1);
                  }}
                >
                  <span
                    style={{
                      width: 12,
                      height: 15,
                      backgroundImage: `url(${funcionarioIconSmall})`,
                      marginRight: isMobile ? "2px" : "5px",
                    }}
                  />
                  {t("funcionario")}
                </Button> */}
                {/* Professores */}
                {/* <Button
                  className="btnHorario"
                  variant="contained"
                  style={{
                    backgroundImage:
                      selecionado == 2
                        ? `url(${horariobtn})`
                        : `url(${horariobtn2})`,
                    width: isMobile ? 98 : 129,
                    height: isMobile ? 30 : 40,
                    cursor: "pointer",
                    zIndex: 200,
                    marginInline: "5px",
                    fontSize: isMobile ? "8px" : "12px",
                  }}
                  onClick={() => {
                    setSelecionado(2);
                  }}
                >
                  <BookOpenIcon
                    style={{
                      color: "white",
                      width: "16px",
                      marginRight: "5px",
                    }}
                  />
                  {t("professor")}
                </Button> */}
                <Button
                  className="btnHorario"
                  variant="contained"
                  style={{
                    backgroundImage:
                      selecionado == 3
                        ? `url(${horariobtn})`
                        : `url(${horariobtn2})`,
                    width: isMobile ? 98 : 129,
                    height: isMobile ? 30 : 40,
                    cursor: "pointer",
                    zIndex: 200,
                    marginInline: "5px",
                    fontSize: isMobile ? "8px" : "12px",
                  }}
                  onClick={() => {
                    setSelecionado(3);
                    setOpenAgendamentos(true);
                  }}
                >
                  {t("Agendamentos")}
                </Button>
              </div>
              <div
                style={{
                  marginTop: "1.5%",
                  overflowY: "scroll",
                  marginLeft: calendarMini ? (isMobile ? "20%" : "10%") : 0,
                  marginRight: calendarMini ? "10%" : 0,
                  width: calendarMini ? (isMobile ? "60%" : "80%") : "auto",
                }}
              >
                <FullCalendar
                  locales={allLocales}
                  ref={calendarRef}
                  locale={i18n.language}
                  scrollTime={true}
                  aspectRatio={2.3}
                  stickyFooterScrollbar={true}
                  allDaySlot={false}
                  headerToolbar={{
                    left: "prev,next,today",
                    center: "title",
                    right: calendarMini
                      ? ""
                      : "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                  }}
                  plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    bootstrap5Plugin,
                    timeGridPlugin,
                  ]}
                  classNames={{
                    prev: "custom-calendar-button",
                    next: "custom-calendar-button",
                    today: "custom-calendar-button",
                    button: "custom-calendar-button",
                  }}
                  stickyHeaderDates={false}
                  initialView={calendarMini ? "timeGridWeek" : "dayGridMonth"}
                  events={
                    selecionado == 0
                      ? horarios
                      : selecionado == 1
                      ? funcionarios
                      : selecionado == 2
                      ? horarioProfessores
                      : horarios
                  }
                  eventClick={handleEventClick}
                  eventDidMount={handleEventMount}
                  slotLabelFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    omitZeroMinute: false,
                    meridiem: "short",
                  }}
                  slotMinTime="06:00:00"
                  slotMaxTime="24:00:00"
                  eventColor="transparent"
                  eventContent={function (arg) {
                    const start = arg.event.start;
                    const end = arg.event.end;
                    const startDate = new Date(arg.event.start);
                    const endDate = new Date(arg.event.end);

                    const options = {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,

                      // Defina o fuso horário para UTC para evitar diferenças de fuso horário
                    };

                    const startDateFormatted = startDate.toLocaleTimeString(
                      "pt-PT",
                      options
                    );
                    const endDateFormatted = endDate.toLocaleTimeString(
                      "pt-PT",
                      options
                    );

                    let widthStyle = calendarMini
                      ? isMobile
                        ? "width: 91px"
                        : "width: 105% !important;"
                      : "width: 105% !important;";
                    let widthStyle2 = calendarMini
                      ? "width: 90% !important;"
                      : "width: 103% !important;";
                    let textColor = "#0b56fa";
                    if (arg.event.title === t("fechado")) {
                      textColor = "red";
                    }

                    if (arg.event.title === "Feriado") {
                      textColor = "green";
                    }

                    if (arg.view.type === "timeGridWeek") {
                      const diffInMinutes = (end - start) / (1000 * 60);

                      const height = diffInMinutes / 1.58;
                      return {
                        html: `<div style="background-color: rgba(255, 255, 255, 0.9); color: ${textColor};  padding: 10px;margin-top:-2px;margin-left:-2px;${widthStyle}; border-radius: 5px; height: ${height}px;max-height:683px;  border: 1px solid rgba(255, 255, 255, 0.1);"><div style="font-weight: bold; display:flex; align-items:center; justify-content:center">${arg.event.title}</div>
                        <br><div style=" display:flex; align-items:center; justify-content:center">${startDateFormatted}h <br>${endDateFormatted}h</div></div>`,
                      };
                    } else if (arg.view.type === "dayGridMonth") {
                      return {
                        html: `<div style="background-color: rgba(255, 255, 255, 0.9); color:${textColor};  text-align: center !important; padding: 5px;margin-top:-0px;margin-left:-2px;width:105% !important; border-radius: 3px;">${arg.event.title}</div>`,
                      };
                    } else if (
                      arg.view.type === "timeGridDay" ||
                      arg.view.type === "timeGridFourDay"
                    ) {
                      const diffInMinutes = (end - start) / (1000 * 60);

                      const height = diffInMinutes / 1.58;
                      // Estilo para as vistas diárias (timeGridDay e timeGridFourDay)
                      return {
                        html: `<div style="background-color: rgba(255, 255, 255, 0.9); color: ${textColor};  padding: 10px;margin-top:-2px;margin-left:-2px;${widthStyle2}; border-radius: 3px; height: ${height}px; max-height:683px;text-align: center;">
                        <div style="font-weight: bold;">${arg.event.title}</div>
                        <div>${startDateFormatted}h - ${endDateFormatted}h</div>
                      </div>`,
                      };
                    } else if (arg.view.type === "listWeek") {
                      let backgroundColor = textColor; // Define a cor de fundo igual à cor do texto

                      if (arg.event.title === t("fechado")) {
                        backgroundColor = "red"; // Define a cor de fundo como vermelho para eventos "fechado"
                      }

                      return {
                        html: `<div style="background-color: ${backgroundColor}; color:${textColor}; display: inline-block; border-radius: 50%; width: 20px; height: 20px; text-align: center; font-weight: bold;"><div style="margin-left:30px">${arg.event.title}</div></div>`,
                      };
                    } else {
                      // Estilo padrão para outras visualizações
                      return {
                        html: `<div style="background-color: transparent; color: ${textColor}; ">${arg.event.title}</div>`,
                      };
                    }
                  }}
                />

                {open && (
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minWidth: "50%",
                        height: "75%",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {selecionado === 0
                        ? t("editarHorarioPiscina")
                        : selecionado === 1
                        ? t("editarHorarioFuncionario")
                        : selecionado === 2
                        ? t("editarHorarioProfessor")
                        : t("editarHorario")}
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                          zIndex: 2000,
                        }}
                        onClick={handleClose}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        onClick={handleClose}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                    </DialogTitle>

                    <DialogContent>
                      {freeze && (
                        <div
                          style={{
                            backgroundColor: "transparent",
                            position: "absolute",
                            width: window.innerWidth,
                            height: window.innerHeight,
                            zIndex: 200,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            top: 0,
                          }}
                        >
                          <Dialog
                            open={true}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <DialogContent>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                {t("aguarde")}
                              </Typography>
                              <CircularProgress
                                disableShrink
                                style={{ marginTop: 20 }}
                              />
                            </DialogContent>
                          </Dialog>
                        </div>
                      )}
                      <div className="formPop" style={{ marginTop: 30 }}>
                        <Box className="formTextField">
                          {selecionado == 1 ? (
                            <FormControl className="formTextField2" fullWidth>
                              <InputLabel
                                className="formTextField22"
                                error={erroFuncionarioEditar}
                              >
                                {t("escolhafuncionario")}*
                              </InputLabel>
                              <Select
                                value={selectedFuncionarioEditar}
                                onChange={handleFuncionarioChangeEditar}
                                error={erroFuncionarioEditar}
                                disabled={edicao}
                                fullWidth
                                variant="standard"
                              >
                                {funcionarios.map((funcionario) => (
                                  <MenuItem
                                    key={funcionario.id}
                                    value={funcionario.id}
                                  >
                                    {funcionario.nome}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : null}

                          {selecionado == 2 ? (
                            <FormControl className="formTextField2" fullWidth>
                              <InputLabel
                                className="formTextField22"
                                error={erroProfessor}
                              >
                                {t("escolhaprofessor")}*
                              </InputLabel>
                              <Select
                                value={selectedProfessorEditar}
                                onChange={handleProfessorChangeEditar}
                                error={erroProfessorEditar}
                                fullWidth
                                disabled={edicao}
                                variant="standard"
                              >
                                {professores.map((professor) => (
                                  <MenuItem
                                    key={professor.id}
                                    value={professor.id}
                                  >
                                    {professor.nome}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : null}

                          <FormControl className="formTextField2" fullWidth>
                            <InputLabel
                              className="formTextField22"
                              error={ErroDiaSemanaEditar}
                            >
                              {t("diaSemana")}*
                            </InputLabel>{" "}
                            <Select
                              fullWidth
                              variant="standard"
                              id="diasDaSemana"
                              onChange={handleChangeDiaSemanaEditar}
                              disabled={edicao}
                              value={diaSemanaEditar}
                            >
                              {dias.map((dia, index) => (
                                <MenuItem key={index} value={index}>
                                  {`${dia}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl className="formTextField2" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label={
                                  selecionado == 0
                                    ? t("horaabertura")
                                    : t("horainicio")
                                }
                                disabled={edicao}
                                value={horaAberturaEditar}
                                onChange={(date) => setHoraAberturaEditar(date)}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    error={erroHoraAberturaEditar}
                                    required
                                  />
                                )}
                                ampm={false}
                              />
                            </LocalizationProvider>
                          </FormControl>
                          <FormControl className="formTextField2" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label={
                                  selecionado == 0
                                    ? t("horafecho")
                                    : t("horafim")
                                }
                                value={horaFechoEditar}
                                disabled={edicao}
                                onChange={(date) => setHoraFechoEditar(date)}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    error={erroHoraFechoEditar}
                                    required
                                  />
                                )}
                                ampm={false}
                              />
                            </LocalizationProvider>
                          </FormControl>

                          <FormControl className="formTextField2" fullWidth>
                            <Typography variant="subtitle" align="left">
                              {t("fechado")}
                            </Typography>

                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={edicao}
                                  className="switch"
                                  checked={fechadoEditar}
                                  onChange={handleChangeEditar}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={
                                fechadoEditar === 1 ? t("fechado") : t("aberto")
                              }
                            />
                          </FormControl>
                        </Box>
                      </div>
                    </DialogContent>

                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        marginTop: "-20px",
                      }}
                    >
                      {edicao ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            className="btnEdicao"
                            variant="contained"
                            style={{
                              backgroundImage: isMobile
                                ? `url(${editarbtnpeq})`
                                : `url(${editarbtn})`,
                              width: isMobile ? 153 : 250,
                              height: 40,
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                            onClick={() => {
                              setEdicao(false);
                            }}
                          >
                            <PencilIcon
                              style={{
                                width: "15px",
                                color: "white",
                                marginRight: "10px",
                              }}
                            />

                            {t("editar")}
                          </Button>

                          {/* <Button
                            className="btnEdicao"
                            variant="contained"
                            style={{
                              backgroundImage: isMobile
                                ? `url(${eliminarbtnpeq})`
                                : `url(${eliminarbtn})`,
                              width: isMobile ? 153 : 250,
                              height: 40,
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                            onClick={() => {
                              handleClickOpenPopupEliminar();
                            }}
                          >
                            <TrashIcon
                              style={{
                                width: "15px",
                                color: "white",
                                marginRight: "10px",
                              }}
                            />

                            {t("eliminar")}
                          </Button> */}
                        </div>
                      ) : (
                        <div>
                          <Button
                            className="btnGuardar"
                            variant="contained"
                            style={{
                              backgroundImage: isMobile
                                ? `url(${guardarbtnPeq})`
                                : `url(${guardarbtn})`,

                              height: 40,
                              width: isMobile ? 153 : 250,
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                            onClick={() => {
                              validacao();
                            }}
                          >
                            {t("guardar")}
                          </Button>
                          <Button
                            className="btnGuardar"
                            variant="contained"
                            style={{
                              backgroundImage: isMobile
                                ? `url(${cancelarbtnPeq})`
                                : `url(${cancelarbtn})`,

                              height: 40,
                              width: isMobile ? 153 : 250,
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                            onClick={() => {
                              handleClickOpenPopupCancelar();
                            }}
                          >
                            {t("cancelar")}
                          </Button>
                        </div>
                      )}
                    </DialogActions>
                  </Dialog>
                )}

                {/* adicionar horario */}
                <Dialog
                  open={openCriarHorario}
                  onClose={handleCloseCriarHorario}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: "50%",
                      height: "75%",
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {selecionado === 0
                      ? t("adicionarHorarioPiscina")
                      : selecionado === 1
                      ? t("adicionarHorarioFuncionario")
                      : selecionado === 2
                      ? t("adicionarHorarioProfessor")
                      : t("adicionarHorario")}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                        zIndex: 2000,
                      }}
                      onClick={handleCloseCriarHorario}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}
                    <div className="formPop" style={{ marginTop: 30 }}>
                      <Box className="formTextField">
                        {selecionado == 1 ? (
                          <FormControl className="formTextField2" fullWidth>
                            <InputLabel
                              className="formTextField22"
                              error={erroFuncionario}
                            >
                              {t("escolhafuncionario")}*
                            </InputLabel>
                            <Select
                              value={selectedFuncionario}
                              onChange={handleFuncionarioChange}
                              error={erroFuncionario}
                              fullWidth
                              variant="standard"
                            >
                              {funcionarios.map((funcionario) => (
                                <MenuItem
                                  key={funcionario.id}
                                  value={funcionario.id}
                                >
                                  {funcionario.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : null}

                        {selecionado == 2 ? (
                          <FormControl className="formTextField2" fullWidth>
                            <InputLabel
                              className="formTextField22"
                              error={erroProfessor}
                            >
                              {t("escolhaprofessor")}*
                            </InputLabel>
                            <Select
                              value={selectedProfessor}
                              onChange={handleProfessorChange}
                              error={erroProfessor}
                              fullWidth
                              variant="standard"
                            >
                              {professores.map((professor) => (
                                <MenuItem
                                  key={professor.id}
                                  value={professor.id}
                                >
                                  {professor.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : null}

                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={ErroDiaSemana}
                          >
                            {t("diaSemana")}*
                          </InputLabel>{" "}
                          <Select
                            fullWidth
                            variant="standard"
                            id="diasDaSemana"
                            onChange={handleChangeDiaSemana}
                          >
                            {dias.map((dia, index) => (
                              <MenuItem key={index} value={index}>
                                {`${dia}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={
                                selecionado == 0
                                  ? t("horaabertura")
                                  : t("horainicio")
                              }
                              value={horaAbertura}
                              onChange={(date) => {
                                if (date) {
                                  setHoraAbertura(date.$d);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraAbertura}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={
                                selecionado == 0 ? t("horafecho") : t("horafim")
                              }
                              value={horaFecho}
                              onChange={(date) =>{ if(date){setHoraFecho(date.$d)}}}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraFecho}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <Typography variant="subtitle" align="left">
                            {t("fechado")}
                          </Typography>

                          <FormControlLabel
                            control={
                              <Switch
                                className="switch"
                                checked={fechado == 1}
                                onChange={handleChange}
                                name="checked"
                                color="primary"
                              />
                            }
                            label={fechado === 1 ? t("fechado") : t("aberto")}
                          />
                        </FormControl>
                      </Box>
                    </div>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      className="btnEdicao"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${editarbtn})`,
                        width: 315,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        validacao1();
                      }}
                    >
                      {t("adicionar")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Cancelar edição */}
                <Dialog
                  open={cancelarEdicao}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      width: 452,
                      height: 186,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("cancelarEdicao")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("textoCancelarEdicao")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        cancelarfn();
                      }}
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClosePopupCancelar();
                      }}
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Eliminar */}
                <Dialog
                  open={eliminar}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("eliminarHorario")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("eliminarRegisto")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        eliminarAgendamento();
                      }}
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClosePopupEliminar();
                      }}
                      autoFocus
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openAgendamentos}
                  onClose={handleCloseAgendamentos}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: "80%",
                      height: "80%",
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("Agendamentos")}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                        zIndex: 2000,
                      }}
                      onClick={handleCloseAgendamentos}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}

                    <DataGrid
                      className={isMobile ? "tabela2" : "tabela"}
                      pageSize={8}
                      //Colunas
                      columns={[
                        {
                          field: "id",
                          headerName: t("id"),
                          width: 5,
                          type: "string",
                          hide: columnsHidden.includes("id"),
                        },
                        {
                          field: "nome_piscina_agua",
                          headerName: t("piscina"),
                          flex: 1,
                          type: "string",
                          hide: columnsHidden.includes("nome_piscina_agua"),
                        },
                        {
                          field: "dia_semana",
                          headerName: t("diaSemana"),
                          type: "string",
                          flex: 1,
                        },
                        {
                          field: "pistas_disponiveis",
                          headerName: t("pistas"),
                          type: "string",
                          flex: 1,
                        },
                        {
                          field: "hora_inicio",
                          headerName: t("horaInicio"),
                          type: "string",
                          flex: 1,
                        },
                        {
                          field: "hora_fim",
                          headerName: t("horaFim"),
                          type: "string",
                          flex: 1,
                        },
                        {
                          field: "tipo",
                          headerName: t("tipo"),
                          flex: 1,
                          type: "string",
                          hide: columnsHidden.includes("tipo"),
                        },
                        {
                          field: "actions",
                          type: "actions",
                          headerName: t("acoes"),
                          width: 70,
                          cellClassName: "actions",
                          getActions: ({ id }) => {
                            return [
                              <GridActionsCellItem
                                icon={
                                  <FontAwesomeIcon
                                    style={{ fontSize: isMobile ? 12 : 18 }}
                                    icon={faPen}
                                  />
                                }
                                label="Save"
                                onClick={() => {
                                  const numericId = parseInt(
                                    id.replace(/^[a-z]+/i, ""),
                                    10
                                  );

                                  if (id.includes("a")) {
                                    setIdAula(numericId);
                                    setIsAula(true);

                                    setOpenEditarAgendamento(true);
                                  } else if (id.includes("nl")) {
                                    setIsAula(false);
                                    setIdNadoLivre(numericId);
                                    setOpenEditarAgendamento(true);
                                  }
                                }}
                              />,
                            ];
                          },
                        },
                      ]}
                      rows={agendamentos}
                      checkboxSelection={isMobile ? false : true}
                      onRowClick={(params) => {}}
                      getRowId={(row) => row.id}
                      //Idioma
                      localeText={
                        i18n.language == "pt"
                          ? ptBR.components.MuiDataGrid.defaultProps.localeText
                          : i18n.language == "es"
                          ? esES.components.MuiDataGrid.defaultProps.localeText
                          : enUS.components.MuiDataGrid.defaultProps.localeText
                      }
                      components={{
                        Toolbar: CustomToolbar,
                        BaseCheckbox: ({ value, ...otherProps }) => (
                          <Checkbox
                            size="small"
                            checked={value}
                            {...otherProps}
                          />
                        ),
                      }}
                      columnsHidden={isMobile ? ["piscina", "tipo"] : []}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      className="btnEdicao"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${editarbtn})`,
                        width: 315,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        // validacao1();
                        setOpenCriarAgendamento(true);
                      }}
                    >
                      {t("adicionarAgendamento")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* editar agendamento */}
                <Dialog
                  open={openEditarAgendamento}
                  onClose={handleCloseEditarAgendamento}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: isMobile ? "95%" : "50%",
                      height: "75%",
                      borderRadius: "10px",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {isAula == true
                      ? t("editarAgendamentoAula")
                      : t("editarAgendamentoNadoLivre")}

                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                        zIndex: 999,
                      }}
                      onClick={handleCloseEditarAgendamento}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}

                    <div className="formPop" style={{ marginTop: 30 }}>
                      <Box className="formTextField">
                        <div>
                          <FormControl className="formTextField2" fullWidth>
                            <InputLabel
                              className="formTextField22"
                              error={erroPiscina}
                            >
                              {t("escolhapiscina")}*
                            </InputLabel>
                            <Select
                              disabled={edicaoAgendamentos}
                              value={selectedPool}
                              onChange={handlePoolChange}
                              error={erroPiscina}
                              fullWidth
                              variant="standard"
                            >
                              {piscinas.map((piscina) => (
                                <MenuItem key={piscina.id} value={piscina.id}>
                                  {piscina.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {/* <FormControl className="formTextField2" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language}
                            >
                              <DatePicker
                                label={t("datainicio")}
                                openTo="day"
                                views={["day", "year", "month"]}
                                value={anoInicio}
                                disabled={true}
                                onChange={(e) => {
                                  if (e) {
                                    setAnoInicio(e.$d);
                                    setErroAnoInicio(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    required
                                    error={erroAnoInicio}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                          <FormControl className="formTextField2" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language}
                            >
                              <DatePicker
                                label={t("datafim")}
                                openTo="day"
                                views={["day", "year", "month"]}
                                value={anoFim}
                                disabled={true}
                                onChange={(e) => {
                                  if (e) {
                                    setAnoFim(e.$d);
                                    setErroAnoFim(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    required
                                    error={erroAnoFim}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl> */}

                          <FormControl className="formTextField2" fullWidth>
                            <Typography variant="subtitle" align="left">
                              {t("escolhedia")}
                            </Typography>
                            <div style={{ maxWidth: 200 }}>
                              <RadioGroup
                                aria-label="diasSemana"
                                name="diasSemana"
                                value={selectedDay}
                                onChange={handleChange2}
                                style={{ flexDirection: "collumn" }}
                              >
                                <FormControlLabel
                                  disabled={edicaoAgendamentos}
                                  value={"segunda"}
                                  control={<Radio color="primary" />}
                                  label={t("segunda")}
                                />
                                <FormControlLabel
                                  value={"terca"}
                                  disabled={edicaoAgendamentos}
                                  control={<Radio color="primary" />}
                                  label={t("terca")}
                                />
                                <FormControlLabel
                                  value={"quarta"}
                                  disabled={edicaoAgendamentos}
                                  control={<Radio color="primary" />}
                                  label={t("quarta")}
                                />
                                <FormControlLabel
                                  value={"quinta"}
                                  disabled={edicaoAgendamentos}
                                  control={<Radio color="primary" />}
                                  label={t("quinta")}
                                />
                                <FormControlLabel
                                  value={"sexta"}
                                  disabled={edicaoAgendamentos}
                                  control={<Radio color="primary" />}
                                  label={t("sexta")}
                                />
                                <FormControlLabel
                                  value={"sabado"}
                                  disabled={edicaoAgendamentos}
                                  control={<Radio color="primary" />}
                                  label={t("sabado")}
                                />
                                <FormControlLabel
                                  value={"domingo"}
                                  disabled={edicaoAgendamentos}
                                  control={<Radio color="primary" />}
                                  label={t("domingo")}
                                />
                              </RadioGroup>
                            </div>
                          </FormControl>
                        </div>

                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={t("horainicio")}
                              value={hora_inicio}
                              disabled={edicaoAgendamentos}
                              onChange={(date) => {
                                if (date == null) {
                                  setHoraInicio("");
                                } else if (date.$d != "Invalid Date") {
                                  setHoraInicio(date.$d);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraInicio}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={t("horafim")}
                              value={hora_fim}
                              disabled={edicaoAgendamentos}
                              onChange={(date) => {
                                if (date == null) {
                                  setHoraFim("");
                                } else if (date.$d != "Invalid Date") {
                                  setHoraFim(date.$d);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraFim}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        {disponiveis != [] && (
                          <div
                            style={{
                              marginLeft: 10,
                              marginTop: 10,
                              marginBottom: 20,
                            }}
                          >
                            <FormControl
                              className="formTextField2"
                              style={{
                                width: "100%",
                                marginRight: "10px",
                                marginTop: 10,
                              }}
                            >
                              <Typography variant="subtitle" align="left">
                                {t("escolhapistas")}
                              </Typography>
                              {disponiveis.length == 0 && (
                                <p style={{ fontSize: 14, fontWeight: "500" }}>
                                  {t("semPistas")}
                                </p>
                              )}
                              <FormGroup>
                                {disponiveis.map((pista) => {
                                  const isChecked = selectedPistas.some(
                                    (selected) => selected.id === pista.id
                                  );

                                  const isMeiaPista = pista.id.includes("M");

                                  return (
                                    <div>
                                      <div>
                                        <FormControlLabel
                                          key={pista.id}
                                          disabled={edicaoAgendamentos}
                                          control={
                                            <Checkbox
                                              checked={
                                                isChecked &&
                                                !selectedPistas.some(
                                                  (selected) =>
                                                    selected.id ===
                                                    pista.id + "M"
                                                )
                                              }
                                              onChange={(event) =>
                                                handlePistaChange(event, pista)
                                              }
                                              name={pista.nome}
                                              disabled={
                                                selectedPistas.some(
                                                  (selected) =>
                                                    selected.id ===
                                                    pista.id + "M"
                                                ) || edicaoAgendamentos
                                              }
                                            />
                                          }
                                          label={`Pista ${pista.id.replace(
                                            "M",
                                            " (Meia Pista)"
                                          )}`}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </FormGroup>
                            </FormControl>
                          </div>
                        )}
                      </Box>

                      {/* Cancelar edição */}
                      <Dialog
                        open={cancelarEdicaoAgendamento}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                          style: {
                            width: 452,
                            height: 186,
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {t("cancelarEdicao")}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t("textoCancelarEdicao")}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            className="btnSim"
                            variant="contained"
                            style={{
                              backgroundImage: `url(${simbtn})`,
                              width: 218,
                              height: 40,
                              cursor: "pointer",
                              zIndex: 200,
                            }}
                            onClick={() => {
                              handleClosePopupCancelarAgendamento();
                              setEdicaoAgendamentos(true);
                            }}
                          >
                            {t("sim")}
                          </Button>
                          <Button
                            className="btnSim"
                            variant="contained"
                            style={{
                              backgroundImage: `url(${naobtn})`,
                              width: 198,
                              height: 30,
                              cursor: "pointer",
                              zIndex: 200,
                            }}
                            onClick={() => {
                              handleClosePopupCancelarAgendamento();
                            }}
                          >
                            {t("nao")}
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* Eliminar */}
                      <Dialog
                        open={eliminar}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {t("eliminarAgendamento")}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t("eliminarRegisto")}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            className="btnSim"
                            variant="contained"
                            style={{
                              backgroundImage: `url(${simbtn})`,
                              width: 218,
                              height: 40,
                              cursor: "pointer",
                              zIndex: 200,
                            }}
                            onClick={() => {
                              eliminarAgendamento();
                            }}
                          >
                            {t("sim")}
                          </Button>
                          <Button
                            className="btnSim"
                            variant="contained"
                            style={{
                              backgroundImage: `url(${naobtn})`,
                              width: 198,
                              height: 30,
                              cursor: "pointer",
                              zIndex: 200,
                            }}
                            onClick={() => {
                              handleClosePopupEliminar();
                            }}
                            autoFocus
                          >
                            {t("nao")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                      marginTop: "-20px",
                    }}
                  >
                    {edicaoAgendamentos ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {/* <Button
                          className="btnEdicao"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${editarbtnpeq})`
                              : `url(${editarbtn})`,
                            width: isMobile ? 153 : 315,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            setEdicaoAgendamentos(!edicaoAgendamentos);
                          }}
                        >
                          <PencilIcon
                            style={{
                              width: "15px",
                              color: "white",
                              marginRight: "10px",
                            }}
                          />

                          {t("editar")}
                        </Button> */}

                        <Button
                          className="btnEdicao"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${eliminarbtnpeq})`
                              : `url(${eliminarbtn})`,
                            width: isMobile ? 153 : 315,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            handleClickOpenPopupEliminar();
                          }}
                        >
                          <TrashIcon
                            style={{
                              width: "15px",
                              color: "white",
                              marginRight: "10px",
                            }}
                          />

                          {t("eliminar")}
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="btnGuardar"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${guardarbtnPeq})`
                              : `url(${guardarbtn})`,

                            height: 40,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            editarAgendamento();
                          }}
                        >
                          {t("guardar")}
                        </Button>
                        <Button
                          className="btnGuardar"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${cancelarbtnPeq})`
                              : `url(${cancelarbtn})`,
                            width: isMobile ? 164 : 218,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            handleClickOpenPopupCancelarAgendamento();
                          }}
                        >
                          {t("cancelar")}
                        </Button>
                      </div>
                    )}
                  </DialogActions>
                </Dialog>
                {/* criar agendamento */}
                <Dialog
                  open={openCriarAgendamento}
                  onClose={handleCloseCriarAgendamento}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: isMobile ? "95%" : "50%",
                      height: "75%",
                      borderRadius: "10px",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("criarAgendamento")}

                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                        zIndex: 999,
                      }}
                      onClick={handleCloseCriarAgendamento}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}

                    <div className="formPop" style={{ marginTop: 30 }}>
                      <Box className="formTextField">
                        <div>
                          <FormControl className="formTextField2" fullWidth>
                            <InputLabel
                              className="formTextField22"
                              error={erroPiscina}
                            >
                              {t("escolhapiscina")}*
                            </InputLabel>
                            <Select
                              value={selectedPool}
                              onChange={handlePoolChange}
                              error={erroPiscina}
                              fullWidth
                              variant="standard"
                            >
                              {piscinas.map((piscina) => (
                                <MenuItem key={piscina.id} value={piscina.id}>
                                  {piscina.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className="formTextField2" fullWidth>
                            <InputLabel
                              className="formTextField22"
                              error={erroTipo}
                            >
                              {t("escolhatipo")}*
                            </InputLabel>
                            <Select
                              value={selectedTipo}
                              onChange={handleTipoChange}
                              error={erroTipo}
                              fullWidth
                              variant="standard"
                            >
                              {tipos.map((tipo) => (
                                <MenuItem
                                  selected={selectedTipo.id}
                                  key={tipo.id}
                                  value={tipo.id}
                                >
                                  {tipo.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <FormControl className="formTextField2" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language}
                            >
                              <DatePicker
                                label={t("datainicio")}
                                openTo="day"
                                views={["day", "year", "month"]}
                                value={data_inicio}
                                onChange={(e) => {
                                  if (e) {
                                    setDataInicio(e.$d);
                                    setErroDataInicio(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    required
                                    error={erroDataInicio}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                          <FormControl className="formTextField2" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language}
                            >
                              <DatePicker
                                label={t("datafim")}
                                openTo="day"
                                views={["day", "year", "month"]}
                                value={data_fim}
                                onChange={(e) => {
                                  if (e) {
                                    setDataFim(e.$d);
                                    setErroDataFim(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    required
                                    error={erroDataFim}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl> */}

                          <FormControl className="formTextField2" fullWidth>
                            <Typography variant="subtitle" align="left">
                              {t("escolhedia")}
                            </Typography>
                            <div style={{ maxWidth: 200 }}>
                              <RadioGroup
                                aria-label="diasSemana"
                                name="diasSemana"
                                value={selectedDay}
                                onChange={handleChange2}
                                style={{ flexDirection: "collumn" }}
                              >
                                <FormControlLabel
                                  value={"segunda"}
                                  control={<Radio color="primary" />}
                                  label={t("segunda")}
                                />
                                <FormControlLabel
                                  value={"terca"}
                                  control={<Radio color="primary" />}
                                  label={t("terca")}
                                />
                                <FormControlLabel
                                  value={"quarta"}
                                  control={<Radio color="primary" />}
                                  label={t("quarta")}
                                />
                                <FormControlLabel
                                  value={"quinta"}
                                  control={<Radio color="primary" />}
                                  label={t("quinta")}
                                />
                                <FormControlLabel
                                  value={"sexta"}
                                  control={<Radio color="primary" />}
                                  label={t("sexta")}
                                />
                                <FormControlLabel
                                  value={"sabado"}
                                  control={<Radio color="primary" />}
                                  label={t("sabado")}
                                />
                                <FormControlLabel
                                  value={"domingo"}
                                  control={<Radio color="primary" />}
                                  label={t("domingo")}
                                />
                              </RadioGroup>
                            </div>
                          </FormControl>
                        </div>

                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={t("horainicio")}
                              value={hora_inicio}
                              onChange={(date) => {
                                if (date == null) {
                                  setHoraInicio("");
                                } else if (date.$d != "Invalid Date") {
                                  setHoraInicio(date.$d);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraInicio}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={t("horafim")}
                              value={hora_fim}
                              onChange={(date) => {
                                if (date == null) {
                                  setHoraFim("");
                                } else if (date.$d != "Invalid Date") {
                                  setHoraFim(date.$d);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraFim}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        {disponiveis != [] && (
                          <div
                            style={{
                              marginLeft: 10,
                              marginTop: 10,
                              marginBottom: 20,
                            }}
                          >
                            <FormControl
                              className="formTextField2"
                              style={{
                                width: "100%",
                                marginRight: "10px",
                                marginTop: 10,
                              }}
                            >
                              <Typography variant="subtitle" align="left">
                                {t("escolhapistas")}
                              </Typography>
                              {disponiveis.length == 0 && (
                                <p style={{ fontSize: 14, fontWeight: "500" }}>
                                  {t("semPistas")}
                                </p>
                              )}

                              {disponiveis.map((pista) => {
                                const isChecked = selectedPistas.some(
                                  (selected) => selected.id === pista.id
                                );

                                const isMeiaPista = pista.id.includes("M");

                                return (
                                  <div>
                                    <div>
                                      <FormControlLabel
                                        key={pista.id}
                                        control={
                                          <Checkbox
                                            checked={
                                              isChecked &&
                                              !selectedPistas.some(
                                                (selected) =>
                                                  selected.id === pista.id + "M"
                                              )
                                            }
                                            onChange={(event) =>
                                              handlePistaChange(event, pista)
                                            }
                                            name={pista.nome}
                                            disabled={selectedPistas.some(
                                              (selected) =>
                                                selected.id === pista.id + "M"
                                            )}
                                          />
                                        }
                                        label={`Pista ${pista.id.replace(
                                          "M",
                                          " (Meia Pista)"
                                        )}`}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </FormControl>
                          </div>
                        )}
                      </Box>
                    </div>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                      marginTop: "-20px",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="btnEdicao"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${editarbtnpeq})`
                            : `url(${editarbtn})`,
                          width: isMobile ? 153 : 315,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          adicionarAgendamento();
                        }}
                      >
                        {/* <PencilIcon
                          style={{
                            width: "15px",
                            color: "white",
                            marginRight: "10px",
                          }}
                        /> */}

                        {t("adicionar")}
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
              </div>

              <div
                style={{
                  flexDirection: "row",
                  display:
                    selecionado === 0 || selecionado === 1 ? "flex" : "none",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="btnAddHorario"
                  variant="contained"
                  style={{
                    backgroundImage: `url(${addhorariosbtn})`,
                    width: 180,
                    height: 30,
                    cursor: "pointer",
                    zIndex: 200,
                    marginTop: "8px",
                  }}
                  onClick={() => {
                    handleClickOpenCriarHorario();
                  }}
                >
                  {t("adicionar")}
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default HorarioFuncionario;
