import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = styled((theme) => ({
  input: {
    backgroundColor: "#fff",
  },
}));

const phoneInput2 = (props, ref) => {
  //Traduções
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <TextField
      {...props}
      InputProps={{
        className: classes.input,
      }}
      inputRef={ref}
      fullWidth
      label={t("telefone") + " 2"}
      variant="standard"
    />
  );
};
export default forwardRef(phoneInput2);
