import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";

import "react-phone-number-input/style.css";

import { toast } from "react-toastify";

import "@react-pdf-viewer/core/lib/styles/index.css";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import {
  editarDescontos,
  eliminarDescontos,
  getDesconto,
} from "../../api/requests/Descontos";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaAcoes, listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import moment from "moment";
function EditarDescontoA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idDesconto } = state || {};

  const [edicao, setEdicao] = useState(true);
  const [freeze, setFreeze] = useState(false);

  const [id_acao, setIdAcao] = useState("");
  const [id_piscina, setIdPiscina] = useState("");
  const [id_ee, setIdEE] = useState("");
  const [desconto, setDesconto] = useState("");
  const [descricao, setDescricao] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [status, setStatus] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [acoes, setAcao] = useState([]);
  const [numFilhos, setNumFilhos] = useState(0);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAcao, setSelectedAcao] = useState("");

  const [erroIdAcao, setErroIdAcao] = useState(false);
  const [erroDesconto, setErroDesconto] = useState(false);
  const [erroDescricao, setErroDescricao] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroAcao, setErroAcao] = useState(false);
  const [erroNumFilhos, setErroNumFilhos] = useState(false);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleAcaoChange = (event) => {
    setSelectedAcao(event.target.value);
    setErroAcao(false);
  };

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({}).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_categoria,
            categoria:
              element.nome +
              "(" +
              element.descricao_idade_min +
              " a " +
              element.descricao_idade_max +
              ")",
          };
          arrayFinal.push(linha);
          setCategoria(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });

    listaAcoes({}).then((res) => {
      if (res.success) {
        let arrayFinal = [];

        res.data.data.forEach((element) => {
          const linha = {
            id: element.id,
            acao: element.nome,
          };
          arrayFinal.push(linha);
          setAcao(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });

    getDesconto({ id: idDesconto }).then((res) => {
      if (res.success) {
        let dados = res.data.data[0];

        setDadosOriginais(dados);

        setDesconto(dados.desconto);
        setDescricao(dados.descricao);
        setDataInicio(dados.data_inicio);
        setDataFim(dados.data_fim);
        setSelectedCategory(dados.categoria_utilizador);
        setSelectedAcao(dados.acao);
        setStatus(dados.status);
        setNumFilhos(dados.filhos_conta_familiar);
        setCarregamentoDadosCompleto(true);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }, []);

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName(
        "cardOpcoesPiscinaEliminar3"
      )[0].style.cursor = "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName(
      "cardOpcoesPiscinaEliminar3"
    )[0].style.cursor = "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setDesconto(dadosOriginais.desconto);
    setDescricao(dadosOriginais.descricao);
    setDataInicio(dadosOriginais.data_inicio);
    setDataFim(dadosOriginais.data_fim);
    setSelectedCategory(dadosOriginais.categoria_utilizador);
    setSelectedAcao(dadosOriginais.acao);
    setStatus(dadosOriginais.status);
    setNumFilhos(dadosOriginais.filhos_conta_familiar);

    //Limpar erros e mensagens de erro

    setErroIdAcao(false);
    setErroDesconto(false);
    setErroDescricao(false);
    setErroDataFim(false);
    setErroDataInicio(false);
    setErroCategoria(false);
    setErroAcao(false);
    setErroNumFilhos(false);

    window.scrollTo(0, 0);
  }
  function editarDesconto() {
    setFreeze(true);
    editarDescontos({
      id_desconto: idDesconto,
      id_acao: selectedAcao,
      desconto: desconto,
      filhos_conta_familiar: numFilhos,
      categoria_utilizador: selectedCategory,
      data_inicio: moment(data_inicio).format("YYYY-MM-DD"),
      data_fim: data_fim ? moment(data_fim).format("YYYY-MM-DD") : null,
      descricao: descricao,
      status: status,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false);
        setEdicao(!edicao);
        //Scroll para o início da página
        window.scrollTo(0, 0);
        toast.success(t("dadosEditadosSucesso"));
        document.getElementsByClassName(
          "cardOpcoesPiscina1_1"
        )[0].style.cursor = "pointer";
      } else {
        if (res.status == 500) {
          navigate("/500");
          setFreeze(false)
        }
        setFreeze(false)
        toast.error(res.error);
      }
    });
  }

  function validacao() {
    if (
      desconto &&
      data_inicio &&
      descricao &&
      selectedCategory &&
      selectedAcao
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarDesconto();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);

      if (!desconto) {
        setErroDesconto(true);
      }

      if (!data_inicio) {
        setErroDataInicio(true);
      }
      if (!descricao) {
        setErroDescricao(true);
      }
      if (!id_acao) {
        setErroIdAcao(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (!selectedAcao) {
        setErroAcao(true);
      }
    }
  }

  function eliminarDesconto() {
    setFreeze(true);
    eliminarDescontos({
      id_desconto: idDesconto,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("eliminarDesconto"));
          navigate("/discountlista");
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  onClick={() => {
                    navigate("/discountlista");
                  }}
                  size={isMobile ? "small" : "medium"}
                >
                  {" "}
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </IconButton>
              ) : null}
            </div>

            {/* <div className="align-right">
              <h3 className="titleList">{t("editarDescontos")}</h3>
            </div> */}
          </div>
          <div>
            <div className="formPage4">
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina2">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>

                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarDesconto2")}
            </DialogTitle>
            <DialogContent>{t("eliminarRegisto")}</DialogContent>
            <DialogActions>
              {" "}
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarDesconto();
                }}
                autoFocus
              >
                {t("eliminar")}
              </Button>
            </DialogActions>
          </Dialog>

          <div>
            <div className="formPage">
              <Box className="formTextField">
                <div>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("desconto") + " (%)"}
                      value={desconto}
                      error={erroDesconto}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        // Verifica se o valor é maior que 100
                        if (inputValue > 100) {
                          inputValue = 100; // Define o valor como 100
                        }
                        setDesconto(e.target.value);
                        setErroDesconto(false);
                      }}
                    />
                  </FormControl>

                  <FormControl
                    className="formTextField2"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    <TextField
                      disabled={edicao}
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={2}
                      value={descricao}
                      label={t("observacoes")}
                      error={erroDescricao}
                      onChange={(e) => {
                        setDescricao(e.target.value);
                        setErroDescricao(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <FormControlLabel
                      disabled={edicao}
                      control={
                        <Switch
                          className="switch"
                          checked={status}
                          onChange={handleChange}
                          name="checked"
                          color="primary"
                        />
                      }
                      label={status ? "Ativo" : "Desativo"}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <InputLabel
                      className="formTextField22"
                      error={erroCategoria}
                    >
                      {t("escolhacategoria")}*
                    </InputLabel>
                    <Select
                      disabled={edicao}
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      error={erroCategoria}
                      fullWidth
                      variant="standard"
                    >
                      {categorias.map((categoria) => (
                        <MenuItem key={categoria.id} value={categoria.id}>
                          {categoria.categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="formTextField2" fullWidth>
                    <InputLabel className="formTextField22" error={erroAcao}>
                      {t("escolhaacao")}*
                    </InputLabel>
                    <Select
                      disabled={edicao}
                      value={selectedAcao}
                      onChange={handleAcaoChange}
                      error={erroAcao}
                      fullWidth
                      variant="standard"
                    >
                      {acoes.map((acao) => (
                        <MenuItem key={acao.id} value={acao.id}>
                          {acao.acao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      type="number"
                      variant="standard"
                      label={t("numfilhos")}
                      value={numFilhos}
                      error={erroNumFilhos}
                      onChange={(e) => {
                        setNumFilhos(e.target.value);
                        setErroNumFilhos(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disabled={edicao}
                        label={t("datainicio")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={data_inicio}
                        onChange={(e) => {
                          setDataInicio(e);
                          setErroDataInicio(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroDataInicio}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disabled={edicao}
                        label={t("datafim")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={data_fim}
                        onChange={(e) => {
                          setDataFim(e);
                          setErroDataFim(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            error={erroDataFim}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarDescontoA;
