import React, { useState, useEffect, useMemo, useRef } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { IconContext } from "react-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import editarbtn from "../images/BotaoEditar.svg";
import eliminarbtn from "../images/BotaoEliminar.svg";
import guardarbtn from "../images/BotaoGuardar.svg";
import cancelarbtn from "../images/BotaoCancelar.svg";
import simbtn from "../images/BotaoSim.svg";
import naobtn from "../images/BotaoNao.svg";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import bcrypt from "bcryptjs";
import PhoneNumber from "../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import { Scrollbar } from "react-scrollbars-custom";
import countryList from "react-select-country-list";
import {
  faQuestion,
  faRightFromBracket,
  faBell,
  faHome,
  faUser,
  faGear,
  faCircleExclamation,
  faPhone,
  faQuestionCircle,
  faClose,
  faTicketSimple,
  faTags,
  faCancel,
  faQrcode,
  faGraduationCap,
  faHandPaper,
  faTriangleExclamation,
  faBuilding,
  faCalendarCheck,
  faSwimmer,
  faSwimmingPool,
  faPen,
  faPeopleRoof,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { putUtilizador, putSerCliente } from "../api/requests/Utilizadores";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { alteraPass, alteraPass2, verificarNif } from "../api/requests/Registo";
import { getNumPedidos } from "../api/requests/Pedidos";

import logo from "../images/logosemfundoc.png";
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  DialogContentText,
  DialogTitle,
  Box,
  CircularProgress,
  Drawer,
  List,
  IconButton,
  Typography,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import { useTranslation } from "react-i18next";
import { Colors } from "../values/Colors";
import { getUtilizadoresId } from "../api/requests/Utilizadores";
import Cookies from "js-cookie";
import { logout } from "../api/requests/AuthServer";
import { verificarPagamentosEmDia } from "../api/requests/Pagamentos";
import { height, width } from "@mui/system";
import { useLocation } from "react-router";
import { getEL } from "../api/requests/Piscinas";

function Header() {
  //Navigator
  const navigate = useNavigate();

  //Traduções
  const { t, i18n } = useTranslation();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  const [numPedidosPendentes, setNumPedidosPendentes] = useState("");

  useEffect(() => {
    // Função para atualizar isSmallScreen quando o tamanho da janela mudar
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 799);
    };

    // Adicione um ouvinte de redimensionamento à janela
    window.addEventListener("resize", handleResize);

    // Certifique-se de remover o ouvinte quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [pagamentosEmDia, setPagamentosEmDia] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  async function Logout() {
    const refresh_token = Cookies.get("refresh_token");
    navigate("/");
    await logout({
      token: refresh_token,
    }).then(async (res) => {
      if (res.success) {
        //Remover os dados e encaminhar para a página de Login
        sessionStorage.removeItem("id");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("language");
        sessionStorage.removeItem("nome");
        sessionStorage.removeItem("dados");
        sessionStorage.removeItem("key");
        Cookies.remove("token");
        Cookies.remove("refresh_token");
        Cookies.remove("uId");
        Cookies.remove("uName");
        Cookies.remove("uEmail");
        Cookies.remove("uKey");
        Cookies.remove("uData");
        // toast.info(t("sessaoExpirada"));
      }
    });
  }
  useEffect(() => {
    const idU = Cookies.get("uId");
    setId(idU);
    const token = Cookies.get("token");
    setTokenU(token);
    const dadosIncompletos = Cookies.get("uData");
    let pagamento = localStorage.getItem("pagamentoEmDia");
    setPagamentosEmDia(pagamento);
    const selectedPoolId = localStorage.getItem("idPiscina");
    let utilizador = Cookies.get("utilizador");
    if (
      utilizador == "admin" ||
      utilizador == "funcionario" ||
      utilizador == "professor"
    ) {
      getNumPedidos({ id_piscina: selectedPoolId })
        .then((res) => {
          if (res.success) {
            setNumPedidosPendentes(res.data.data);
          } else {
            // if (res.status == 500) {
            //   navigate("/500");
            // }
            // toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }

    //get dados utilizador
    getUtilizadoresId(idU, {}).then((res) => {
      if (res.success) {
        let dados = res.data.data;

        if (dados.foto) {
          setFotoEditar(dados.foto);
        } else {
          setFotoEditar([]);
        }
      } else {
        // if (res.status == 500) {
        //   navigate("/500");
        // }
        toast.warn(res.error);
      }
    });
  }, []);

  const [mobileOpen, setMobileOpen] = React.useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleEditData = () => {
    navigate("/change_user_data", {
      state: {
        definicoes: 1,
      },
    });
    handleMenuClose();
  };
  const handleLogout = () => {
    handleClickOpenLogout();
    handleMenuClose(); // Fechar o menu antes de fazer logout
  };
  const handleChangePassword = () => {
    navigate("/alterarpassword");
    handleMenuClose();
  };

  //Funcionario
  const [content, setContent] = useState("default");

  const [foto, setFoto] = useState("");

  const handleClose2 = () => setOpenFuncionario(false);

  const [open, setOpen] = useState(true);
  const [openLogout, setOpenLogout] = useState(false);
  const handleCloseLogout = () => setOpenLogout(false);

  const [openEditarDados, setOpenEditarDados] = useState(false);
  const handleCloseEditarDados = () => {
    setEdicao(true);
    setOpenEditarDados(false);
    setId(dadosOriginais2.id_utilizador);

    setNome(dadosOriginais2.nome);
    setEmail(dadosOriginais2.email);
    setTelefone(dadosOriginais2.contacto);
    if (dadosOriginais2.morada) {
      setMorada(dadosOriginais2.morada);
    }
    if (dadosOriginais2.codigo_postal) {
      setCodigoPostal(dadosOriginais2.codigo_postal);
    }
    if (dadosOriginais2.genero) {
      setGenero(dadosOriginais2.genero);
    }
    if (dadosOriginais2.data_nascimento) {
      setDataNascimento(dadosOriginais2.data_nascimento.substring(0, 10));
    } else {
      setErroDataNascimento(false);
      setDataNascimento(null);
    }
    if (dadosOriginais2.foto) {
      setFoto(dadosOriginais2.foto);
    } else {
      setFoto("");
    }

    if (dadosOriginais2.nif) {
      setNif(dadosOriginais2.nif);
    }
  };

  const handleClickOpenEditarDados = () => {
    const idU = Cookies.get("uId");
    getUtilizadoresId(idU, {}).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setDadosOriginais2(dados);
        setId(dados.id_utilizador);

        setNome(dados.nome);
        setEmail(dados.email);
        setTelefone(dados.contacto);
        if (dados.morada) {
          setMorada(dados.morada);
        }
        if (dados.codigo_postal) {
          setCodigoPostal(dados.codigo_postal);
        }
        if (dados.genero) {
          setGenero(dados.genero);
        }
        if (dados.data_nascimento) {
          setDataNascimento(dados.data_nascimento.substring(0, 10));
        } else {
          setErroDataNascimento(false);
          setDataNascimento(null);
        }
        if (dados.foto) {
          setFoto(dados.foto);
        } else {
          setFoto("");
        }

        if (dados.nif) {
          setNif(dados.nif);
        }
      } else {
        // if (res.status == 500) {
        //   navigate("/500");
        // }
        toast.warn(res.error);
      }
    });
    setOpenEditarDados(true);
  };

  const [openEditarPass, setOpenEditarPass] = useState(false);
  const handleCloseEditarPass = () => setOpenEditarPass(false);

  const handleClickOpenEditarPass = () => {
    setOpenEditarPass(true);
  };

  const [openEditarEntidadeLegal, setOpenEntidadeLegal] = useState(false);
  const handleCloseEntidadeLegal = () => setOpenEntidadeLegal(false);

  const handleClickOpenEntidadeLegal = () => {
    setOpenEntidadeLegal(true);
  };

  const [openEditarEntidadeExploradora, setOpenEntidadeExploradora] =
    useState(false);
  const handleCloseEntidadeExploradora = () =>
    setOpenEntidadeExploradora(false);

  const handleClickOpenEntidadeExploradora = () => {
    setOpenEntidadeExploradora(true);
  };

  const handleClickOpenLogout = () => {
    setOpenLogout(true);
  };

  const [openA, setOpenA] = useState(true);

  const [openFuncionario, setOpenFuncionario] = useState(false);
  const drawerWidth = open ? "240px" : "55px";

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const handleAClick = () => {
    setOpenA(!openA);
  };

  const handleButtonClick = (option) => {
    setContent(option);
    setOpenA(false);
  };
  const getTypographyContent = () => {
    switch (content) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim, podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Com o método de pagamento mensal numa turma, como é feito o
              pagamento e em que dia?
            </Typography>
            <Typography paragraph color={"#333"}>
              Os pagamentos mensais devem ser feitos até dia 8 de cada mês, caso
              contrário o cliente perderá parte das ações disponíveis na
              aplicação. O pagamento pode ser feito através da aplicação,
              consultando o menu Pagamentos, pagamentos pendentes. Os métodos de
              pagamentos disponíveis são: multibanco, mbway, cartão de crédito
              ou no balcão da piscina.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Quando cancelo um evento os clientes são notificados?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como funcionam os QR Codes?
            </Typography>
            <Typography paragraph color={"#333"}>
              Os QR Codes apenas são gerados até 30 min antes do início da
              atividade e até ao final desta. O QR Code deve ser apresentado na
              entrada da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso o cliente não tenha telemóvel como dou entrada na piscina sem
              QR Code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Caso um cliente não tenha QR Code devemos ir ao menu Clientes, e
              procurar pelo cliente. De seguida devemos entrar no menu Próximas
              Atividades e carregar na atividade na qual pretendemos dar entrada
              e ele irá dar a entrada do cliente na piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Porque é que há opções que não estão disponíveis no meu menu?
            </Typography>
            <Typography paragraph color={"#333"}>
              O Administrador tem acesso sobre as tuas ações. Pelo que caso
              pretendas executar alguma ação que não esteja disponível deves
              contactar o administrador.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão os nossos contactos para que nos possas contactar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph color={"#333"}>
              suporte@tectank.pt
            </Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph color={"#333"}>
              +351 911 741 470
            </Typography>
          </>
        );
      case "appointment":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Marcações
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito às marcações para os clientes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer uma marcação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para fazeres uma marcação deves selecionar um cliente no menu
              Clientes. De seguida, deves selecionar o utilizador, que pode ser
              o cliente ou alguém que esteja registado na conta familiar e
              escolher a opção que prentendes, aulas ou nado livre.
            </Typography>
            <Typography paragraph color={"#333"}>
              No caso das aulas, deves primeiro escolher a modalidade e de
              seguida a turma. No passo seguinte deves escolher o tipo de
              pagamento: mensal ou anual. O pagamento mensal deve ser feito até
              dia 8 de cada mês, pela aplicação ou no balcão da piscina.
            </Typography>
            <Typography paragraph color={"#333"}>
              No caso dos nado livre deves de seguida escolher o dia e a hora.
              Seguidamente, indicas se pretende comprar um novo pack ou utilizar
              um que já tenha, optando assim por descontar a marcação desse
              pack.
            </Typography>
            <Typography paragraph color={"#333"}>
              Por fim, escolhes o método de pagamento: cartão de crédito,
              multibanco, mbway ou pagamento no local.<br></br> Confirma tudo
              antes de procederes ao pagamento.<br></br> O seguro e a inscrição
              são pagos uma vez por ano.<br></br> Assim que o pagamento for
              processado, a atividade irá aparecer no calendário do cliente.
              Podes também acompanhar o estado dos pagamentos do cliente no Menu
              de Pagamentos dentro da ficha do cliente.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Como cancelo uma marcação ao cliente?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para cancelar uma marcação deves aceder à ficha do cliente. De
              seguida, deves descer para a secção das proximas marcações e
              excluir aquela que pretendes. A marcação ficará cancelada e o
              ticket será automaticamente reposto.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como desinscrevo o cliente de uma turma?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para desinscrever de uma turma deves deves aceder à ficha do
              cliente. De seguida, deves descer para a secção das turma do
              cliente e excluir aquela que pretendes. O cliente irá sair da
              turma.
            </Typography>
          </>
        );
      case "pools":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Piscinas
            </Typography>
            <Typography paragraph color={"#333"}>
              {" "}
              Esta secção diz respeito às piscinas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso desinscrever o cliente da piscina?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para desinscrever o cliente da piscina basta aceder à ficha de
              cliente e carregar em desinscrever.
            </Typography>
          </>
        );
      case "family":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Família
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito à conta familiar. Dentro da ficha de
              cliente poderás gerir a conta familiar do mesmo.
            </Typography>
          </>
        );
      case "packs":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Packs
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos packs da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso criar um pack?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para criar um pack deves aceder ao menu Packs. De seguida deves
              carregar em adicionar e preencher os dados. Para o pack deves
              inserir um nome, deves selecionar a piscina para o qual ele irá
              ser utilizado, a categoria do utilizador, o número de tickets e o
              preço.
            </Typography>
          </>
        );
      case "descontos":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Descontos
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos descontos da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso criar um desconto?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para criar um pack deves aceder ao menu Packs. De seguida deves
              carregar em adicionar e preencher os dados. Para o desconto deves
              inserir a percentagem de desconto, o nome, deves indicar o número
              de crianças (caso queiras por exemplo fazer um desconto para as
              famílias), indicar a ação em que será aplicado, a categoria do
              utilizador, a data de início e a data de fim. Caso a data final
              seja deixada em branco o desconto fica ativo infinitamente.
            </Typography>
          </>
        );
      case "cancelados":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Cancelados
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos eventos cancelados.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso cancelar um evento?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para cancelar um evento deves consultar o calendário. De seguida,
              selecionar o evento que pretendes cancelar, e carregar em
              cancelar. Deves escrever uma pequena observação.
            </Typography>
          </>
        );
      case "qrcodes":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              QR Codes
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos QR Codes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso ativar um QR Code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para ativar um QR Code basta entrar no Menu QR Code e carregar
              sobre o QR Code que pretendemos ativar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como dou entrada de um cliente que não tem QR Code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Caso um cliente não tenha QR Code devemos ir ao menu Clientes, e
              procurar pelo cliente. De seguida devemos entrar no menu Próximas
              Atividades e carregar na atividade na qual pretendemos dar entrada
              e ele irá dar a entrada do cliente na piscina.
            </Typography>
          </>
        );
      case "professores":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Professores
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos professores.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso adicionar um professor?
            </Typography>
            <Typography paragraph color={"#333"}>
              Basta aceder ao menu Professor, carregar em adicionar e preencher
              os dados. O professor irá receber um email para definir a
              palavra-passe.
            </Typography>
          </>
        );
      case "seguros":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Seguros
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos seguros/inscrições da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso adicionar um seguro/inscrição?
            </Typography>
            <Typography paragraph color={"#333"}>
              Basta aceder ao menu Seguros, carregar em adicionar e preencher os
              dados. Deves indicar a categoria do utilizador, o valor do seguro,
              o valor da inscrição e a data em que expira cada um. Esses valores
              serão utilizados quando um cliente efetuar a primeira ação na
              piscina.
            </Typography>
          </>
        );
      case "pedidos":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Seguros
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos pedidos que são feitos por parte dos
              professores.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso aprove um pedido os clientes são notificados?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim.
            </Typography>
          </>
        );
      case "ocupaespaco":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Ocupa Espaço
            </Typography>
            <Typography paragraph color={"#333"}>
              Nesta secção poderás realizar todas as ações referentes a turmas,
              aulas e espaço.
            </Typography>
            {/* <Typography variant="h6" gutterBottom>
              Caso aprove um pedido os clientes são notificados?
            </Typography>
            <Typography paragraph  color={"#333"}>Sim.</Typography> */}
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph color={"#333"}>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };

  const getContentName = (content) => {
    switch (content) {
      case "faq":
        return "Perguntas Frequentes";
      case "contacts":
        return "Contactos";
      case "packs":
        return "Packs";
      case "descontos":
        return "Descontos";
      case "cancelados":
        return "Cancelados";
      case "qrcodes":
        return "QrCode";
      case "professores":
        return "Professores";
      case "seguros":
        return "Seguros";
      case "pedidos":
        return "Pedidos";
      case "ocupaespaco":
        return "Ocupa espaço";
      case "appointment":
        return "Marcar";
      case "pools":
        return "Piscinas";
      case "family":
        return "Família";
      default:
        return "Ajuda"; // Return null for default or unknown content
    }
  };

  //admin

  const [contentAdmin, setContentAdmin] = useState("default");

  const handleClose2Admin = () => {
    setOpenAdmin(false);
  };

  const [open2, setOpen2] = useState(true);
  const [openA2, setOpenA2] = useState(true);

  const [openAdmin, setOpenAdmin] = useState(false);

  const handleDrawerClick2 = () => {
    setOpen2(!open2);
  };

  const handleAClick2 = () => {
    setOpenA2(!openA2);
  };

  const handleButtonClick2 = (option) => {
    setContentAdmin(option);
    setOpenA2(false);
  };

  const getTypographyContent2 = () => {
    switch (contentAdmin) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim, podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Com o método de pagamento mensal numa turma, como é feito o
              pagamento e em que dia?
            </Typography>
            <Typography paragraph color={"#333"}>
              Os pagamentos mensais devem ser feitos até dia 8 de cada mês, caso
              contrário o cliente perderá parte das ações disponíveis na
              aplicação. O pagamento pode ser feito através da aplicação,
              consultando o menu Pagamentos, pagamentos pendentes. Os métodos de
              pagamentos disponíveis são: multibanco, mbway, cartão de crédito
              ou no balcão da piscina.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Quando cancelo um evento os clientes são notificados?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como funcionam os QR Codes?
            </Typography>
            <Typography paragraph color={"#333"}>
              Os QR Codes apenas são gerados até 30 min antes do início da
              atividade e até ao final desta. O QR Code deve ser apresentado na
              entrada da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso o cliente não tenha telemóvel como dou entrada na piscina sem
              QR Code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Caso um cliente não tenha QR Code devemos ir ao menu Clientes, e
              procurar pelo cliente. De seguida devemos entrar no menu Próximas
              Atividades e carregar na atividade na qual pretendemos dar entrada
              e ele irá dar a entrada do cliente na piscina.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão os nossos contactos para que nos possas contactar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph color={"#333"}>
              suporte@tectank.pt
            </Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph color={"#333"}>
              +351 911 741 470
            </Typography>
          </>
        );
      case "appointment":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Marcações
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito às marcações para os clientes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer uma marcação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para fazeres uma marcação deves selecionar um cliente no menu
              Clientes. De seguida, deves selecionar o utilizador, que pode ser
              o cliente ou alguém que esteja registado na conta familiar. De
              seguida deves escolher a opção que prentendes, aulas ou nado
              livre.
            </Typography>
            <Typography paragraph color={"#333"}>
              No caso das aulas, deves primeiro escolher a modalidade e de
              seguida a turma. No passo seguinte deves escolher o tipo de
              pagamento: mensal ou anual. O pagamento mensal deve ser feito até
              dia 8 de cada mês, pela aplicação ou no balcão da piscina.
            </Typography>
            <Typography paragraph color={"#333"}>
              No caso dos nado livre deves de seguida escolher o dia e a hora.
              Seguidamente, indicas se pretende comprar um novo pack ou utilizar
              um que já tenha, optando assim por descontar a marcação desse
              pack.
            </Typography>
            <Typography paragraph color={"#333"}>
              Por fim, escolhes o método de pagamento: cartão de crédito,
              multibanco, mbway ou pagamento no local.<br></br> Confirma tudo
              antes de procederes ao pagamento.<br></br> O seguro e a inscrição
              são pagos uma vez por ano.<br></br> Assim que o pagamento for
              processado, a atividade irá aparecer no calendário do cliente.
              Podes também acompanhar o estado dos pagamentos do cliente no Menu
              de Pagamentos dentro da ficha do cliente.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Como cancelo uma marcação ao cliente?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para cancelar uma marcação deves aceder à ficha do cliente. De
              seguida, deves descer para a secção das proximas marcações e
              excluir aquela que pretendes. A marcação ficará cancelada e o
              ticket será automaticamente reposto.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como desinscrevo o cliente de uma turma?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para desinscrever de uma turma deves deves aceder à ficha do
              cliente. De seguida, deves descer para a secção das turma do
              cliente e excluir aquela que pretendes. O cliente irá sair da
              turma.
            </Typography>
          </>
        );
      case "pools":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Piscinas
            </Typography>
            <Typography paragraph color={"#333"}>
              {" "}
              Esta secção diz respeito às piscinas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso desinscrever o cliente da piscina?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para desinscrever o cliente da piscina basta aceder à ficha de
              cliente e carregar em desinscrever.
            </Typography>
          </>
        );
      case "family":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Família
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito à conta familiar. Dentro da ficha de
              cliente poderás gerir a conta familiar do mesmo.
            </Typography>
          </>
        );
      case "packs":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Packs
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos packs da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso criar um pack?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para criar um pack deves aceder ao menu Packs. De seguida deves
              carregar em adicionar e preencher os dados. Para o pack deves
              inserir um nome, deves selecionar a piscina para o qual ele irá
              ser utilizado, a categoria do utilizador, o número de tickets e o
              preço.
            </Typography>
          </>
        );
      case "descontos":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Descontos
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos descontos da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso criar um desconto?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para criar um pack deves aceder ao menu Packs. De seguida deves
              carregar em adicionar e preencher os dados. Para o desconto deves
              inserir a percentagem de desconto, o nome, deves indicar o número
              de crianças (caso queiras por exemplo fazer um desconto para as
              famílias), indicar a ação em que será aplicado, a categoria do
              utilizador, a data de início e a data de fim. Caso a data final
              seja deixada em branco o desconto fica ativo infinitamente.
            </Typography>
          </>
        );
      case "cancelados":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Cancelados
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos eventos cancelados.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso cancelar um evento?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para cancelar um evento deves consultar o calendário. De seguida,
              selecionar o evento que pretendes cancelar, e carregar em
              cancelar. Deves escrever uma pequena observação.
            </Typography>
          </>
        );
      case "qrcodes":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Qr Codes
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos QR Codes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso ativar um QR Code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para ativar um QR Code basta entrar no Menu QR Code e carregar
              sobre o QR Code que pretendemos ativar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como dou entrada de um cliente que não tem QR Code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Caso um cliente não tenha QR Code devemos ir ao menu Clientes, e
              procurar pelo cliente. De seguida devemos entrar no menu Próximas
              Atividades e carregar na atividade na qual pretendemos dar entrada
              e ele irá dar a entrada do cliente na piscina.
            </Typography>
          </>
        );
      case "professores":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Professores
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos professores.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso adicionar um professor?
            </Typography>
            <Typography paragraph color={"#333"}>
              Basta aceder ao menu Professor, carregar em adicionar e preencher
              os dados. O professor irá receber um email para definir a
              palavra-passe.
            </Typography>
          </>
        );
      case "seguros":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Seguros
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos seguros/inscrições da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso adicionar um seguro/inscrição?
            </Typography>
            <Typography paragraph color={"#333"}>
              Basta aceder ao menu Seguros, carregar em adicionar e preencher os
              dados. Deves indicar a categoria do utilizador, o valor do seguro,
              o valor da inscrição e a data em que expira cada um. Esses valores
              serão utilizados quando um cliente efetuar a primeira ação na
              piscina.
            </Typography>
          </>
        );
      case "pedidos":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Seguros
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito aos pedidos que são feitos por parte dos
              professores.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso aprove um pedido os clientes são notificados?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim.
            </Typography>
          </>
        );
      case "ocupaespaco":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Ocupa Espaço
            </Typography>
            <Typography paragraph color={"#333"}>
              Nesta secção poderás realizar todas as ações referentes a turmas,
              aulas e espaço.
            </Typography>
            {/* <Typography variant="h6" gutterBottom>
            Caso aprove um pedido os clientes são notificados?
          </Typography>
          <Typography paragraph  color={"#333"}>Sim.</Typography> */}
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph color={"#333"}>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };

  const getContentName2 = (contentAdmin) => {
    switch (contentAdmin) {
      case "faq":
        return "Perguntas Frequentes";
      case "contacts":
        return "Contactos";
      case "packs":
        return "Packs";
      case "descontos":
        return "Descontos";
      case "cancelados":
        return "Cancelados";
      case "qrcodes":
        return "QrCode";
      case "professores":
        return "Professores";
      case "seguros":
        return "Seguros";
      case "pedidos":
        return "Pedidos";
      case "ocupaespaco":
        return "Ocupa espaço";
      case "appointment":
        return "Marcar";
      case "pools":
        return "Piscinas";
      case "family":
        return "Família";
      default:
        return "Ajuda"; // Return null for default or unknown content
    }
  };

  //professor

  const [contentProfessor, setContentProfessor] = useState("default");

  const handleClose2Professor = () => setOpenProfessor(false);

  const [open3, setOpen3] = useState(true);
  const [openA3, setOpenA3] = useState(true);

  const [openProfessor, setOpenProfessor] = useState(false);

  const handleDrawerClick3 = () => {
    setOpen3(!open3);
  };

  const handleAClick3 = () => {
    setOpenA3(!openA3);
  };

  const handleButtonClick3 = (option) => {
    setContentProfessor(option);
    setOpenA3(false);
  };

  const getTypographyContent3 = () => {
    switch (contentProfessor) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim, podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer um pedido de cancelamento de uma aula?
            </Typography>
            <Typography paragraph color={"#333"}>
              No calendário do dashboard, carregando na aula que pretendes fazer
              o pedido de cancelamento, ela vai abrir uma janela com mais
              informações. Nessa janela terás a opção de cancelar. Para fazer
              este pedido deves escrever uma observação para que o funcionário
              e/ou administrador possam avaliar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Onde posso ver os meus pedidos?
            </Typography>
            <Typography paragraph color={"#333"}>
              Poderás consultar os teus pedidos no teu Perfil em Pedidos.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso o meu pedido seja aprovado, a aula fica automaticamente
              cancelada?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim, a aula fica automaticamente cancelada, e serás avisado(a). A
              aula irá aparecer no teu calendário com a nota a dizer que está
              cancelada. Também irão ser notificados todos os alunos.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Uma vez que uma aula esteja cancelada é possível reverter a
              situação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Não. Se uma aula foi cancelada, mas posteriormente foi decidido
              que ela será realizada como planeado, é importante entrar em
              contato com o funcionário responsável para retificar a situação.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão os nossos contactos para que possas entrar em contacto
              connosco.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph color={"#333"}>
              suporte@tectank.pt
            </Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph color={"#333"}>
              +351 911 741 470
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph color={"#333"}>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };

  const getContentName3 = (contentProfessor) => {
    switch (contentProfessor) {
      case "faq":
        return "Perguntas Frequentes";
      case "contacts":
        return "Contactos";

      default:
        return "Ajuda"; // Return null for default or unknown content
    }
  };

  // Cliente

  const [contentCliente, setContentCliente] = useState("default");

  const handleClose2Cliente = () => setOpenCliente(false);

  const [open4, setOpen4] = useState(true);
  const [openA4, setOpenA4] = useState(true);

  const [openCliente, setOpenCliente] = useState(false);

  const handleDrawerClick4 = () => {
    setOpen4(!open4);
  };

  const handleAClick4 = () => {
    setOpenA4(!openA4);
  };

  const handleButtonClick4 = (option) => {
    setContentCliente(option);
    setOpenA4(false);
  };
  const getTypographyContent4 = () => {
    switch (contentCliente) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph color={"#333"}>
              Sim, podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#333", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Tenho o método de pagamento mensal numa turma, como é feito o
              pagamento e em que dia?
            </Typography>
            <Typography paragraph color={"#333"}>
              Os pagamentos mensais devem ser feitos até dia 8 de cada mês, caso
              contrário perderás parte das ações disponíveis na aplicação. O
              pagamento pode ser feito através da aplicação consultando o menu
              Pagamentos e indo a pagamentos pendentes. Poderás pagar por um dos
              seguintes métodos de pagamento: multibanco, mbway, cartão de
              crédito ou no balcão da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Quero desinscrever de uma turma, mas diz que não posso, porquê?
            </Typography>
            <Typography paragraph color={"#333"}>
              Caso tenhas um pagamento mensal pendente não te poderás
              desinscrever da turma até atualizares este pagamento.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Recebi um email a dizer que a marcação de nado de livre foi
              cancelada, fiquei sem o ticket?
            </Typography>
            <Typography paragraph color={"#333"}>
              Não, e caso a tua sessão de nado livre tenha sido cancelada, o teu
              ticket não foi descontado, pelo que poderás voltar a fazer uma
              marcação.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como funcionam os QR Codes?
            </Typography>
            <Typography paragraph color={"#333"}>
              Os QR Codes apenas são gerados até 30 min antes do início da
              atividade e até ao final desta. O qr code deve ser apresentado na
              entrada da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso eu não tenha telemóvel como dou entrada na piscina sem qr
              code?
            </Typography>
            <Typography paragraph color={"#333"}>
              Basta informares o funcionário, ele terá os poderes para dar a tua
              entrada para a tua marcação/aula.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph color={"#333"}>
              Aqui estão os nossos contactos para que nos possas contactar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph color={"#333"}>
              suporte@tectank.pt
            </Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph color={"#333"}>
              +351 911 741 470
            </Typography>
          </>
        );
      case "appointment":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Marcações
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito às marcações.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer uma marcação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para fazeres uma marcação deves primeiro indicar a piscina na qual
              predentes marcar nado livre ou inscrever numa turma. De seguida,
              deves selecionar o utilizador, que podes ser tu ou alguém que
              esteja registado na tua conta familiar e escolher a opção que
              prentendes, aulas ou nado livre.
            </Typography>
            <Typography paragraph color={"#333"}>
              No caso das aulas, deves primeiro escolher a modalidade e de
              seguida a turma que pretenderes. No passo seguinte deves escolher
              o tipo de pagamento: mensal ou anual. O pagamento mensal deve ser
              feito até dia 8 de cada mês, pela aplicação ou no balcão da
              piscina.
            </Typography>
            <Typography paragraph color={"#333"}>
              No caso do nado livre deves, de seguida, escolher o dia e a hora a
              que tens disponibilidade. Seguidamente, deves indicar se pretendes
              comprar um novo pack ou, caso já tenhas algum podes optar por
              descontar a marcação desse pack.
            </Typography>
            <Typography paragraph color={"#333"}>
              Por fim, escolher o método de pagamento: cartão de crédito,
              multibanco, mbway ou pagamento no local.<br></br> Confirma tudo
              antes de procederes ao pagamento.<br></br> O seguro e a inscrição
              são pagos uma vez por ano.<br></br> Assim que o pagamento for
              processado, a atividade irá aparecer no calendário. Podes também
              acompanhar o estado dos teus pagamentos no Menu de Pagamentos.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso ver as minhas marcações?
            </Typography>
            <Typography paragraph color={"#333"}>
              Poderás consultar as tuas marcações no calendário do dashboard.
              Carregando em cada evento poderás obter mais informações.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como cancelo uma marcação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para cancelar uma marcação deves aceder ao menu Editar Marcações.
              De seguida, deves carregar na marcação que pretendes alterar e
              carregar em cancelar marcação. A marcação ficará cancelada e o teu
              ticket será automaticamente reposto.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como me desinscrevo de uma turma?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para desinscrever de uma turma deves aceder ao menu Editar
              Marcações. De seguida, deves carregar numa das aulas da turma que
              pretendes sair e carregar em desinscrever desta turma. Sairás de
              seguida da turma. Não te esqueças que caso tenhas pagamentos
              pendentes não te poderás desinscrever até atualizares os
              pagamentos.
            </Typography>
          </>
        );
      case "pools":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Piscinas
            </Typography>
            <Typography paragraph color={"#333"}>
              {" "}
              Esta secção diz respeito às piscinas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como vejo as piscinas em que estou inscrito?
            </Typography>
            <Typography paragraph color={"#333"}>
              Deves aceder ao menu as minhas piscinas e aí irão aparecer todas
              as piscinas nas quais estás inscrito.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como me posso inscrever numa piscina?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para te inscreveres numa piscina, deves pesquisar pela piscina,
              selecionar e carregar em "Quero increver-me!". Terás de aceitar os
              termos e condições da piscina.<br></br> Assim que estiveres
              inscrito poderás usufruir de todas as ações da aplicação para a
              tua nova piscina!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como me posso desinscrever de uma piscina?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para te desinscreveres de uma piscina, deves aceder a "Minhas
              Piscinas" e carregar na piscina que pretendes desinscrever. De
              seguida, deves carregar em desincrever.
            </Typography>
          </>
        );
      case "family":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Família
            </Typography>
            <Typography paragraph color={"#333"}>
              Esta secção diz respeito à conta familiar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso inscrever o meu filho na aplicação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Para inscrever o teu filho deves aceder a Família e adicionar
              criança.
            </Typography>
            <Typography variant="h6" gutterBottom>
              O meu filho pode estar registado duas vezes na aplicação?
            </Typography>
            <Typography paragraph color={"#333"}>
              Não. Apenas é possível um único registo.
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph color={"#333"}>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };

  const getContentName4 = (contentCliente) => {
    switch (contentCliente) {
      case "faq":
        return "Perguntas Frequentes";
      case "contacts":
        return "Contactos";
        return "Ocupa espaço";
      case "appointment":
        return "Marcar";
      case "pools":
        return "Piscinas";
      case "family":
        return "Família";
      default:
        return "Ajuda"; // Return null for default or unknown content
    }
  };

  //EditarDados

  const [freeze, setFreeze] = useState(false);

  //Variáveis
  const [edicao, setEdicao] = useState(true);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosOriginais2, setDadosOriginais2] = useState([]);
  // Utilizador
  const [id, setId] = useState("");
  const [tokenu, setTokenU] = useState("");
  const [nome, setNome] = useState("");
  const [genero, setGenero] = useState("");
  const [nif, setNif] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [morada, setMorada] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [idcliente, setIdCliente] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const [fotoEditar, setFotoEditar] = useState([]);

  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  //password
  const [palavraPasse, setPalavraPasse] = useState("");
  const [palavraPasse2, setPalavraPasse2] = useState("");

  //Validações
  const [erroPassword2, setErroPassword2] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroPalavraPasse2, setMensagemErroPalavraPasse2] =
    useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoPalavraPasse, setSucessoPalavraPasse] = useState("");
  const [sucessoPalavraPasse2, setSucessoPalavraPasse2] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const ref = useRef(null);

  const scrollToDiv = () => {
    setEdicao(!edicao);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const idEL = localStorage.getItem("idEntidade");

  //Validações
  //Entidade
  const [erroNome, setErroNome] = useState(false);
  const [erroNif, setErroNif] = useState(false);
  const [erroGenero, seterroGenero] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [erroTelefone, setErroTelefone] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroCodigoPostal, setErroCodigoPostal] = useState(false);

  //Mensagens de erro

  const [mensagemErroNif, setMensagemErroNif] = useState("");
  const [mensagemErroGenero, setmensagemerroGenero] = useState("");
  const [mensagemErroDataNascimento, setMensagemErroDataNascimento] =
    useState("");
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroMorada, setMensagemErroMorada] = useState("");
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [mensagemErroCodigoPostal, setMensagemErroCodigoPostal] = useState("");
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const idU = Cookies.get("uId");
    setId(idU);
    const token = Cookies.get("token");
    setTokenU(token);
    const dadosIncompletos = Cookies.get("uData");
    //get dados utilizador
    getUtilizadoresId(idU, {}).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setCarregamentoDadosCompleto(true);

        setDadosOriginais(dados);
        setDadosOriginais2(dados);

        setId(dados.id_utilizador);

        setNome(dados.nome);
        setEmail(dados.email);
        setTelefone(dados.contacto);
        setIdCliente(dados.id_cliente);
        if (dados.morada) {
          setMorada(dados.morada);
        }
        if (dados.codigo_postal) {
          setCodigoPostal(dados.codigo_postal);
        }
        if (dados.genero) {
          setGenero(dados.genero);
        }
        if (dados.data_nascimento) {
          setDataNascimento(dados.data_nascimento.substring(0, 10));
        } else {
          setErroDataNascimento(false);
          setDataNascimento(null);
        }
        if (dados.foto) {
          setFoto(dados.foto);
        } else {
          setFoto("");
        }

        if (dados.nif) {
          setNif(dados.nif);
        }
      } else {
        // if (res.status == 500) {
        //   navigate("/500");
        // }
        toast.warn(res.error);
      }
    });
  }, []);

  function ChangePassword() {
    setFreeze(true);
    if (palavraPasse) {
      if (palavraPasse === palavraPasse2) {
        const hashedPassword = bcrypt.hashSync(
          palavraPasse,
          "$2a$10$CwTycUXWue0Thq9StjUM0u"
        );
        alteraPass2({
          newPwd: hashedPassword,
          id: id,
          token: tokenu,
        }).then((res) => {
          if (res.success) {
            setTimeout(function () {
              setFreeze(false);
              setOpenEditarPass(false);
              toast.success(t("passAlterada"));
            }, 3000);
          } else {
            setTimeout(function () {
              setFreeze(false);
              toast.error(t("alteraErro"));
            }, 3000);
          }
        });
      } else {
        setErroPassword(true);
        setErroPassword2(true);
        toast.error(t("iguaisPass"));
        setFreeze(false);
      }
    } else {
      setErroPassword(true);
      setErroPassword2(true);
      setMensagemErroPalavraPasse(t("passVazia"));
      setMensagemErroPalavraPasse2(t("passVazia"));
      toast.error(t("vaziaPass"));
      setFreeze(false);
    }
  }

  useEffect(() => {
    if (palavraPasse) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = palavraPasse.length;
      const uppercasePassword = uppercaseRegExp.test(palavraPasse);
      const lowercasePassword = lowercaseRegExp.test(palavraPasse);
      const digitsPassword = digitsRegExp.test(palavraPasse);
      const specialCharPassword = specialCharRegExp.test(palavraPasse);
      const minLengthPassword = minLengthRegExp.test(palavraPasse);

      if (passwordLength === 0) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("vazia"));
      } else if (!uppercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("uppercase"));
      } else if (!lowercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("lowercase"));
      } else if (!digitsPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracternumb"));
      } else if (!specialCharPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracterespecial"));
      } else if (!minLengthPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("8caracteres"));
      } else {
        setErroPassword(false);
        setMensagemErroPalavraPasse("");
        setSucessoPalavraPasse("success");
      }
    }

    if (palavraPasse2) {
      if (palavraPasse2 != palavraPasse) {
        setErroPassword(true);
        setErroPassword2(true);
        setMensagemErroPalavraPasse(t("passesigual"));
        setMensagemErroPalavraPasse2(t("passesigual"));
      } else {
        setErroPassword(false);
        setErroPassword2(false);
        setMensagemErroPalavraPasse("");
        setMensagemErroPalavraPasse2("");
        setSucessoPalavraPasse2("success");
      }
    }
  }, [palavraPasse2, palavraPasse]);

  async function validacao() {
    if (
      nome &&
      nif &&
      dataNascimento &&
      // genero &&
      email &&
      // telefone &&
      morada &&
      codigoPostal
    ) {
      let confirmarDadosPreenchidos = true;
      // Validação nome
      if (nome.length < 0) {
        confirmarDadosPreenchidos = false;
        setErroNome(true);
        setMensagemNome(t("validacaoNome"));
      }
      // Validação nif
      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }

      // Validação data de nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
      }

      // if (!genero) {
      //   setGenero("");
      //   seterroGenero(true);
      //   setmensagemerroGenero(t("validacaoGenero"));
      // }
      let dataNascimentoDate = new Date(dataNascimento);
      let hoje = new Date();
      let idadeMinima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos <= idadeMinima ||
        (diffAnos === idadeMinima && diffMeses > 0) ||
        (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
      ) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("menor16"));
      }
      // Validação email
      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmail(true);
        setMensagemErroEmail(t("emailInvalido"));
      }

      // if (!isValidPhoneNumber(telefone)) {
      //   confirmarDadosPreenchidos = false;
      //   setErroTelefone(true);
      //   setMensagemErroTelefone(t("validacaoTelemovel"));
      // }

      if (codigoPostal.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }

      if (confirmarDadosPreenchidos) {
        setFreeze(true);
        var datan = new Date(dataNascimento).toISOString().split("T")[0];

        putUtilizador({
          id_utilizador: id,
          nome: nome,
          data_nascimento: datan,
          nif: nif,
          genero: genero,
          email: email,
          contacto: telefone,
          morada: morada,
          codigo_postal: codigoPostal,
          foto: fotoEditar,
        })
          .then((res) => {
            if (res.success) {
              setFreeze(false);
              setEdicao(!edicao);
              let utilizador2 = [];
              let linha = {
                id: id,
                nome: nome,
                nif: nif,
                img: fotoEditar,
                genero: genero,
                filho: 0,
                codigo_postal: codigoPostal,
                data_nascimento: dataNascimento,
                morada: morada,
                contacto: telefone,
                email: email,
              };
              utilizador2.push(linha);
              setDadosOriginais(utilizador2);
              toast.success(t("dadosEditadosSucesso"));
              //Alterar a variável do session storage que obriga o utilizador a preencher os dados

              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            } else {
              // if (res.status == 500) {
              //   navigate("/500");
              // }
              setFreeze(false);
              toast.error(res.error);
            }
          })
          .catch((error) => {
            setFreeze(false);
            // toast.warn(t("erroWebservice"));
          });
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      //Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });
      if (!genero) {
        setGenero("");
        seterroGenero(true);
        setmensagemerroGenero(t("validacaoGenero"));
      }
      //Validação data nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("validacaoData"));
      }
      //Validação do email
      if (!email) {
        setErroEmail(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          );
        if (!validacaoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
      //Validação do telefone
      if (!telefone) {
        setErroTelefone(true);
      } else {
        if (!isValidPhoneNumber(telefone)) {
          setErroTelefone(true);
          setMensagemErroTelefone(t("validacaoTelemovel"));
        }
      }
      //Validação da rua
      if (!morada) {
        setErroMorada(true);
      }

      //Validação do código postal
      if (codigoPostal == "" || codigoPostal.length == 0) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal.length < 7) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }

      //Validação da foto
      // if (foto.length == 0) {
      //   document.getElementById("fotoPiscina").style.borderColor =
      //     Colors.inativo;
      //   document.getElementById("tituloFotoPiscina").style.color =
      //     Colors.inativo;
      // }
    }
    setFreeze(false);
  }
  function ativarCliente() {
    putSerCliente({
      id_utilizador: id,
    })
      .then((res) => {
        if (res.success) {
          toast.success(t("agoraCliente"));
          //Alterar a variável do session storage que obriga o utilizador a preencher os dados

          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        } else {
          // if (res.status == 500) {
          //   navigate("/500");
          // }

          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        // toast.warn(t("erroWebservice"));
      });
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);
    dadosOriginais.forEach((element) => {
      if (element.id == id) {
        setNome(element.nome);
        setNif(element.nif);
        setDataNascimento(element.data_nascimento);
        setCodigoPostal(element.codigo_postal);
        setMorada(element.morada);
        if (element.foto) {
          setFoto(element.foto);
        } else {
          setFoto("");
        }
      }
    });
    //Colocar os dados originais
    // setNome(dadosOriginais.nome);
    // setNif(dadosOriginais.nif);
    // setDataNascimento(dadosOriginais.dataNascimento.substring(0, 10));
    // setEmail(dadosOriginais.email);
    // setTelefone(dadosOriginais.contacto);
    // setMorada(dadosOriginais.morada);
    // setCodigoPostal(dadosOriginais.codigo_postal);
    // setFotoEditar(dadosOriginais.foto);

    //Limpar erros e mensagens de erro
    setErroNome(false);
    setErroNif(false);
    setErroDataNascimento(false);
    setErroEmail(false);
    setErroTelefone(false);
    setErroMorada(false);
    setErroCodigoPostal(false);
    seterroGenero(false);

    setMensagemErroNif("");
    setMensagemErroDataNascimento("");
    setMensagemErroEmail("");
    setMensagemErroTelefone("");
    setMensagemErroCodigoPostal("");
    setmensagemerroGenero("");
    window.scrollTo(0, 0);
  }

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        {isSmallScreen ? (
          <div className="navbarResponsivo">
            <div className="headerLeft" style={{ float: "left" }}>
              <span
                className={"logonav"}
                onClick={() => {
                  {
                    Cookies.get("utilizador") == "admin"
                      ? navigate("/dashboard_admin")
                      : Cookies.get("utilizador") == "funcionario"
                      ? navigate("/dashboard_employee")
                      : Cookies.get("utilizador") == "cliente"
                      ? navigate("/dashboard_customer")
                      : Cookies.get("utilizador") == "professor"
                      ? navigate("/dashboard_teacher")
                      : null;
                  }
                }}
              ></span>
            </div>
            <div className="headerRight" style={{ float: "right" }}>
              <li className="navbar-toggle" style={{ height: 0 }}>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    marginRight: "20px",
                  }}
                >
                  <a style={{ fontSize: 14, color: "#171E24" }}>
                    {Cookies.get("uName")}
                  </a>
                  <a style={{ fontSize: 12, color: "#B0B0B0" }}>
                    {Cookies.get("utilizador") == "admin"
                      ? t("admin")
                      : Cookies.get("utilizador") == "funcionario"
                      ? t("funcionario_")
                      : Cookies.get("utilizador") == "cliente"
                      ? t("cliente")
                      : Cookies.get("utilizador") == "professor"
                      ? t("professor")
                      : Cookies.get("utilizador") == "comercial"
                      ? t("comercial")
                      : Cookies.get("utilizador") == "supercomercial"
                      ? t("supercomercial")
                      : Cookies.get("utilizador") == "superadmin"
                      ? t("superadmin")
                      : null}
                  </a>
                </div>
                <div
                  style={{
                    marginRight: "-15px",
                  }}
                >
                  <Avatar
                    src={foto}
                    sx={{
                      width: 35,
                      height: 35,
                      border: "1px solid #171E24",
                      cursor: "pointer",
                    }}
                    onClick={handleMenuOpen}
                  />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      background: "#FFFFFF",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      borderColor: "transparent",
                      marginTop: "25px",
                      width: "214px",
                      height: "auto",
                    },
                  }}
                  MenuListProps={{
                    style: {
                      padding: "8px 0",
                    },
                  }}
                >
                  <MenuItem
                    onClick={handleClickOpenEditarDados}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("editarDados")}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      let utilizador = Cookies.get("utilizador");
                      if (utilizador == "admin") {
                        setOpenAdmin(true);
                      } else if (utilizador == "funcionario") {
                        setOpenFuncionario(true);
                      } else if (utilizador == "professor") {
                        setOpenProfessor(true);
                      } else if (utilizador == "cliente") {
                        setOpenCliente(true);
                      }
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {t("ajuda")}
                  </MenuItem>

                  {Cookies.get("utilizador") == "superadmin" ||
                  Cookies.get("utilizador") == "supercomercial" ||
                  Cookies.get("utilizador") == "comercial" ? (
                    <MenuItem
                      onClick={() => {
                        {
                          Cookies.get("utilizador") != "professor"
                            ? navigate("/user-orders")
                            : navigate("/teacher-orders");
                        }
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        fontSize: "14",
                      }}
                    >
                      {t("pedidos")}
                    </MenuItem>
                  ) : null}
                  {Cookies.get("utilizador") == "cliente" ? (
                    <MenuItem
                      onClick={() => {
                        navigate("/mypacks");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        fontSize: "14",
                      }}
                    >
                      {t("meuspacks")}
                    </MenuItem>
                  ) : null}
                  {Cookies.get("utilizador") == "cliente" ? (
                    <MenuItem
                      onClick={() => {
                        navigate("/myclass");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        fontSize: "14",
                      }}
                    >
                      {t("minhasturmas")}
                    </MenuItem>
                  ) : null}

                  {Cookies.get("utilizador") == "superadmin" ||
                  Cookies.get("utilizador") == "supercomercial" ||
                  Cookies.get("utilizador") == "comercial" ||
                  Cookies.get("utilizador") == "admin" ? (
                    <MenuItem
                      onClick={() =>
                        navigate("/elasee", {
                          state: { idEL: idEL },
                        })
                      }
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "14",
                      }}
                    >
                      {t("entidadeLegal")}
                    </MenuItem>
                  ) : null}

                  {Cookies.get("utilizador") == "superadmin" ||
                  Cookies.get("utilizador") == "supercomercial" ||
                  Cookies.get("utilizador") == "comercial" ||
                  Cookies.get("utilizador") == "admin" ? (
                    <MenuItem
                      onClick={() => navigate("/eelista")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "14",
                      }}
                    >
                      {t("entidadeExploradora")}
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={handleClickOpenEditarPass}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "14",
                    }}
                  >
                    {t("alteraPassword")}
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px !important",
                      fontSize: "14",
                    }}
                  >
                    {t("logout")}
                    <ArrowRightOnRectangleIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "black",
                        marginLeft: 10,
                      }}
                    />
                  </MenuItem>
                </Menu>
              </li>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="headerLeft" style={{ float: "left" }}>
              <li className="navbar-toggle" style={{ height: 0 }}>
                <Link
                  className="headerName"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    let utilizador = Cookies.get("utilizador");
                    if (utilizador == "superadmin") {
                      navigate("/dashboard_superadmin");
                    } else if (utilizador == "supercomercial") {
                      navigate("/dashboard_supercommercial");
                    } else if (utilizador == "comercial") {
                      navigate("/dashboard_commercial");
                    } else if (utilizador == "admin") {
                      navigate("/dashboard_admin");
                    } else if (utilizador == "funcionario") {
                      navigate("/dashboard_employee");
                    } else if (utilizador == "professor") {
                      navigate("/dashboard_teacher");
                    } else if (utilizador == "cliente") {
                      navigate("/dashboard_customer");
                    }
                  }}
                >
                  {t("ola2")}, {Cookies.get("uName")}
                </Link>
                {/* <img
                src={logo}
                style={{
                  width: "40px",
                  height: "4x0x",
                  cursor: "pointer",
                }}
               
              /> */}
              </li>
            </div>
            <div className="headerRight" style={{ float: "right" }}>
              <li className="navbar-toggle" style={{ height: 0 }}>
                <div
                  style={{
                    marginRight: 30,
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <a style={{ fontSize: 14, color: "#171E24" }}>
                    {Cookies.get("uName")}
                  </a>
                  <a style={{ fontSize: 12, color: "#B0B0B0" }}>
                    {Cookies.get("utilizador") == "admin"
                      ? t("admin")
                      : Cookies.get("utilizador") == "funcionario"
                      ? t("funcionario_")
                      : Cookies.get("utilizador") == "cliente"
                      ? t("cliente")
                      : Cookies.get("utilizador") == "professor"
                      ? t("professor")
                      : Cookies.get("utilizador") == "comercial"
                      ? t("comercial")
                      : Cookies.get("utilizador") == "supercomercial"
                      ? t("supercomercial")
                      : Cookies.get("utilizador") == "superadmin"
                      ? t("superadmin")
                      : null}
                  </a>
                </div>
                <div style={{ marginRight: 40 }}>
                  <Avatar
                    src={foto}
                    sx={{
                      width: 35,
                      height: 35,
                      border: "1px solid #171E24",
                      cursor: "pointer",
                    }}
                    onClick={handleMenuOpen}
                  />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      background: "#FFFFFF",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      borderColor: "transparent",
                      marginTop: "25px",
                      width: "214px",
                      height: "auto",
                    },
                  }}
                  MenuListProps={{
                    style: {
                      padding: "8px 0",
                    },
                  }}
                >
                  <MenuItem
                    onClick={handleClickOpenEditarDados}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {t("editarDados")}
                  </MenuItem>
                  {Cookies.get("utilizador") == "cliente" ? (
                    <MenuItem
                      onClick={() => {
                        navigate("/mypacks");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        fontSize: "14",
                      }}
                    >
                      {t("meuspacks")}
                    </MenuItem>
                  ) : null}
                  {Cookies.get("utilizador") == "cliente" ? (
                    <MenuItem
                      onClick={() => {
                        navigate("/myclass");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        fontSize: "14",
                      }}
                    >
                      {t("minhasturmas")}
                    </MenuItem>
                  ) : null}

                  {Cookies.get("utilizador") == "superadmin" ||
                  Cookies.get("utilizador") == "supercomercial" ||
                  Cookies.get("utilizador") == "comercial" ? (
                    <MenuItem
                      onClick={() => {
                        {
                          Cookies.get("utilizador") != "professor"
                            ? navigate("/user-orders")
                            : navigate("/teacher-orders");
                        }
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        fontSize: "14",
                      }}
                    >
                      {t("pedidos")}
                    </MenuItem>
                  ) : null}

                  {Cookies.get("utilizador") == "superadmin" ||
                  Cookies.get("utilizador") == "supercomercial" ||
                  Cookies.get("utilizador") == "comercial" ||
                  Cookies.get("utilizador") == "admin" ? (
                    <MenuItem
                      onClick={() =>
                        navigate("/elasee", {
                          state: { idEL: idEL },
                        })
                      }
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "14",
                      }}
                    >
                      {t("entidadeLegal")}
                    </MenuItem>
                  ) : null}

                  {Cookies.get("utilizador") == "superadmin" ||
                  Cookies.get("utilizador") == "supercomercial" ||
                  Cookies.get("utilizador") == "comercial" ||
                  Cookies.get("utilizador") == "admin" ? (
                    <MenuItem
                      onClick={() => navigate("/eelista")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "14",
                      }}
                    >
                      {t("entidadeExploradora")}
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={handleClickOpenEditarPass}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "14",
                    }}
                  >
                    {t("alteraPassword")}
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px !important",
                      fontSize: "14",
                    }}
                  >
                    {t("logout")}
                    <ArrowRightOnRectangleIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "black",
                        marginLeft: 10,
                      }}
                    />
                  </MenuItem>
                </Menu>

                <div
                  onClick={() => {
                    let utilizador = Cookies.get("utilizador");
                    if (utilizador == "admin") {
                      setOpenAdmin(true);
                    } else if (utilizador == "funcionario") {
                      setOpenFuncionario(true);
                    } else if (utilizador == "professor") {
                      setOpenProfessor(true);
                    } else if (utilizador == "cliente") {
                      setOpenCliente(true);
                    }
                  }}
                >
                  <Link>
                    <QuestionMarkCircleIcon
                      style={{ width: "25px", color: "black" }}
                    />
                  </Link>
                </div>
              </li>
            </div>
          </div>
        )}
        {/* {pagamentosEmDia == "true" && (
          <div className="navbar2">
            <div id="rssBlock">
              <p className="cnnContents">
                <span className="marqueeStyle">
                  <FontAwesomeIcon
                    color="#fff"
                    style={{ marginRight: 5 }}
                    icon={faCircleExclamation}
                  />
                  {t("pagamentosAtraso")}
                </span>
                <span className="marqueeStyle2">
                  <FontAwesomeIcon
                    color="#fff"
                    style={{ marginRight: 5 }}
                    icon={faCircleExclamation}
                  />
                  {t("pagamentosAtraso")}
                </span>
              </p>
            </div>
          </div>
        )} */}

        <Dialog
          open={openFuncionario}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{
            style: {
              width: "80%",
              maxWidth: "90vw",
              height: 700,
            },
          }}
          style={{
            textAlign: "flex-start",

            marginTop: 30,
            "@media (max-width: 800px)": {
              marginTop: 0,
            },
          }}
        >
          {!isSmallScreen ? (
            <DialogContent
              sx={{
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  "@media (max-width: 800px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <List
                  sx={{
                    width: open ? "20%" : "5%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      margin: 0,
                      "&:hover": {
                        outline: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={handleDrawerClick}
                  >
                    {open ? <ChevronLeftIcon /> : <MenuIcon />}
                    {open && (
                      <Typography
                        variant="body1"
                        sx={{
                          alignSelf: "center",
                          marginLeft: open ? 1 : 0,
                          fontSize: 18,
                          color: "#333333",
                        }}
                      >
                        Ajuda
                      </Typography>
                    )}
                  </IconButton>
                  {open ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "5%",
                        }}
                      ></div>
                      <ListItem key="FAQ" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "faq" ? "rgba(0, 196, 245, 1)" : null,
                            color: content == "faq" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("faq")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "faq" ? "#fff" : null}
                              icon={faQuestionCircle}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Perguntas Frequentes"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Contacts" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "contacts"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "contacts" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("contacts")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "contacts" ? "#fff" : null}
                              icon={faPhone}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Contactos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Packs" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "packs"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "packs" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("packs")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "packs" ? "#fff" : null}
                              icon={faTicketSimple}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Packs"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Descontos" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "descontos"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "descontos" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("descontos")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "descontos" ? "#fff" : null}
                              icon={faTags}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Descontos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Cancelados" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "cancelados"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "cancelados" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("cancelados")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "cancelados" ? "#fff" : null}
                              icon={faCancel}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Cancelados"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="QrCode" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "qrcodes"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "qrcodes" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("qrcodes")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "qrcodes" ? "#fff" : null}
                              icon={faQrcode}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="QrCode"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Professores" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "professores"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "professores" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("professores")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "professores" ? "#fff" : null}
                              icon={faGraduationCap}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Professores"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Seguros" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "seguros"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "seguros" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("seguros")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "seguros" ? "#fff" : null}
                              icon={faHandPaper}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Seguros"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Pedidos" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "pedidos"
                                ? numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : "rgba(0, 196, 245, 1)"
                                : numPedidosPendentes != 0
                                ? "rgba(240, 70, 67, 1)"
                                : null,
                            color:
                              content == "pedidos"
                                ? numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : "#fff"
                                : numPedidosPendentes != 0
                                ? "rgba(240, 70, 67, 1)"
                                : null,
                          }}
                          onClick={() => handleButtonClick("pedidos")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                content == "pedidos"
                                  ? numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : "#fff"
                                  : numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : null
                              }
                              icon={faTriangleExclamation}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Pedidos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Ocupa espaço" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "ocupaespaco"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "ocupaespaco" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("ocupaespaco")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "ocupaespaco" ? "#fff" : null}
                              icon={faCalendarCheck}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Ocupa espaço"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Appointment" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "appointment"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "appointment" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("appointment")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "appointment" ? "#fff" : null}
                              icon={faSwimmer}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Marcar"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Pools" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "pools"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "pools" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("pools")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "pools" ? "#fff" : null}
                              icon={faSwimmingPool}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Piscinas"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Family" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              content == "family"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: content == "family" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick("family")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={content == "family" ? "#fff" : null}
                              icon={faPeopleRoof}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Família"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  ) : null}
                </List>

                <Box
                  sx={{
                    flexGrow: 1,
                    textAlign: "justify",
                    overflowY: "auto",
                    width: "100%",
                    "@media (max-width: 800px)": {
                      height: "50vh",
                    },
                  }}
                >
                  <Typography component="div" sx={{ p: 4 }}>
                    {getTypographyContent()}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          ) : (
            <DialogContent>
              <Box sx={{ display: "flex", flex: "1 1 auto" }}>
                <Box
                  sx={{
                    width: "20%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <Accordion
                    sx={{
                      display: "none",
                      "@media (max-width: 800px)": {
                        display: "block",
                      },
                    }}
                    expanded={openA}
                    onChange={handleDrawerClick}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => handleAClick()}
                    >
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignContent: "flex-start",
                          margin: 0,
                          "&:hover": {
                            outline: "none",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            alignSelf: "center",
                            marginLeft: 1,
                            fontSize: 18,
                            color: "#333333",
                          }}
                        >
                          {openA
                            ? getContentName(content)
                            : getContentName(content) || "Ajuda"}
                        </Typography>
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem key="FAQ" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "faq"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "faq" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("faq")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "faq" ? "#fff" : null}
                                icon={faQuestionCircle}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Perguntas Frequentes"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="Contacts" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "contacts"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "contacts" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("contacts")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "contacts" ? "#fff" : null}
                                icon={faPhone}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Contactos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Packs" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "packs"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "packs" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("packs")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "packs" ? "#fff" : null}
                                icon={faTicketSimple}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Packs"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Descontos" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "descontos"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "descontos" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("descontos")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "descontos" ? "#fff" : null}
                                icon={faTags}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Descontos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Cancelados" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "cancelados"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "cancelados" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("cancelados")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "cancelados" ? "#fff" : null}
                                icon={faCancel}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Cancelados"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="QrCode" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "qrcodes"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "qrcodes" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("qrcodes")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "qrcodes" ? "#fff" : null}
                                icon={faQrcode}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="QrCode"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Professores" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "professores"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "professores" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("professores")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "professores" ? "#fff" : null}
                                icon={faGraduationCap}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Professores"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Seguros" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "seguros"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "seguros" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("seguros")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "seguros" ? "#fff" : null}
                                icon={faHandPaper}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Seguros"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Pedidos" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "pedidos"
                                  ? numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : "rgba(0, 196, 245, 1)"
                                  : numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : null,
                              color:
                                content == "pedidos"
                                  ? numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : "#fff"
                                  : numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : null,
                            }}
                            onClick={() => handleButtonClick("pedidos")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  content == "pedidos"
                                    ? numPedidosPendentes != 0
                                      ? "rgba(240, 70, 67, 1)"
                                      : "#fff"
                                    : numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : null
                                }
                                icon={faTriangleExclamation}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Pedidos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Ocupa espaço" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "ocupaespaco"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "ocupaespaco" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("ocupaespaco")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "ocupaespaco" ? "#fff" : null}
                                icon={faCalendarCheck}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Ocupa espaço"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Appointment" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "appointment"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "appointment" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("appointment")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "appointment" ? "#fff" : null}
                                icon={faSwimmer}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Marcar"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Pools" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "pools"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "pools" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("pools")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "pools" ? "#fff" : null}
                                icon={faSwimmingPool}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Piscinas"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Family" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                content == "family"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: content == "family" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick("family")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={content == "family" ? "#fff" : null}
                                icon={faPeopleRoof}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Família"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "justify",
                  overflowY: "auto",
                  "@media (max-width: 800px)": {
                    height: "50vh",
                  },
                }}
              >
                <Typography component="div" sx={{ p: 4 }}>
                  {getTypographyContent()}
                </Typography>
              </Box>
            </DialogContent>
          )}
        </Dialog>

        {/* Admin */}
        <Dialog
          open={openAdmin}
          onClose={handleClose2Admin}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{
            style: {
              width: "80%",
              maxWidth: "90vw",
              height: 700,
            },
          }}
          style={{
            textAlign: "flex-start",

            marginTop: 30,
            "@media (max-width: 800px)": {
              marginTop: 0,
            },
          }}
        >
          {!isSmallScreen ? (
            <DialogContent
              sx={{
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleClose2Admin}
                aria-label="Fechar"
                icon={faClose}
              />
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  "@media (max-width: 800px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <List
                  sx={{
                    width: open2 ? "20%" : "5%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      margin: 0,
                      "&:hover": {
                        outline: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={handleDrawerClick2}
                  >
                    {open2 ? <ChevronLeftIcon /> : <MenuIcon />}
                    {open2 && (
                      <Typography
                        variant="body1"
                        sx={{
                          alignSelf: "center",
                          marginLeft: open2 ? 1 : 0,
                          fontSize: 18,
                          color: "#333333",
                        }}
                      >
                        Ajuda
                      </Typography>
                    )}
                  </IconButton>
                  {open2 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "5%",
                        }}
                      ></div>
                      <ListItem key="FAQ" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "faq"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "faq" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("faq")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "faq" ? "#fff" : null}
                              icon={faQuestionCircle}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Perguntas Frequentes"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Contacts" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "contacts"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "contacts" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("contacts")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "contacts" ? "#fff" : null}
                              icon={faPhone}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Contactos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Packs" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "packs"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "packs" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("packs")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "packs" ? "#fff" : null}
                              icon={faTicketSimple}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Packs"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Descontos" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "descontos"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "descontos" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("descontos")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentAdmin == "descontos" ? "#fff" : null
                              }
                              icon={faTags}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Descontos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Cancelados" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "cancelados"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "cancelados" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("cancelados")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentAdmin == "cancelados" ? "#fff" : null
                              }
                              icon={faCancel}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Cancelados"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="QrCode" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "qrcodes" ? Colors.card1 : null,
                            color: contentAdmin == "qrcodes" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("qrcodes")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "qrcodes" ? "#fff" : null}
                              icon={faQrcode}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="QrCode"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Professores" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "professores"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color:
                              contentAdmin == "professores" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("professores")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentAdmin == "professores" ? "#fff" : null
                              }
                              icon={faGraduationCap}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Professores"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Seguros" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "seguros"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "seguros" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("seguros")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "seguros" ? "#fff" : null}
                              icon={faHandPaper}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Seguros"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Pedidos" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "pedidos"
                                ? numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : "rgba(0, 196, 245, 1)"
                                : null,
                            color:
                              contentAdmin == "pedidos"
                                ? numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : "#fff"
                                : numPedidosPendentes != 0
                                ? "rgba(240, 70, 67, 1)"
                                : null,
                          }}
                          onClick={() => handleButtonClick2("pedidos")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentAdmin == "pedidos"
                                  ? numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : "#fff"
                                  : numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : null
                              }
                              icon={faTriangleExclamation}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Pedidos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Ocupa espaço" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "ocupaespaco"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color:
                              contentAdmin == "ocupaespaco" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("ocupaespaco")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentAdmin == "ocupaespaco" ? "#fff" : null
                              }
                              icon={faCalendarCheck}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Ocupa espaço"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Appointment" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "appointment"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color:
                              contentAdmin == "appointment" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("appointment")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentAdmin == "appointment" ? "#fff" : null
                              }
                              icon={faSwimmer}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Marcar"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Pools" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "pools"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "pools" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("pools")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "pools" ? "#fff" : null}
                              icon={faSwimmingPool}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Piscinas"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Family" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentAdmin == "family"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentAdmin == "family" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick2("family")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "family" ? "#fff" : null}
                              icon={faPeopleRoof}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Família"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  ) : null}
                </List>

                <Box
                  sx={{
                    flexGrow: 1,
                    textAlign: "justify",
                    overflowY: "auto",
                    width: "100%",
                    "@media (max-width: 800px)": {
                      height: "50vh",
                    },
                  }}
                >
                  <Typography component="div" sx={{ p: 4 }}>
                    {getTypographyContent2()}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          ) : (
            <DialogContent>
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleClose2Admin}
                aria-label="Fechar"
                icon={faClose}
              />
              <Box sx={{ display: "flex", flex: "1 1 auto" }}>
                <Box
                  sx={{
                    width: "20%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <Accordion
                    sx={{
                      display: "none",
                      "@media (max-width: 800px)": {
                        display: "block",
                      },
                    }}
                    expanded={openA2}
                    onChange={handleDrawerClick2}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => handleAClick2()}
                    >
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignContent: "flex-start",
                          margin: 0,
                          "&:hover": {
                            outline: "none",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            alignSelf: "center",
                            marginLeft: 1,
                            fontSize: 18,
                            color: "#333333",
                          }}
                        >
                          {openA2
                            ? getContentName2(contentAdmin)
                            : getContentName2(contentAdmin) || "Ajuda"}
                        </Typography>
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem key="FAQ" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "faq"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "faq" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("faq")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={contentAdmin == "faq" ? "#fff" : null}
                                icon={faQuestionCircle}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Perguntas Frequentes"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="Contacts" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "contacts"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "contacts" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("contacts")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "contacts" ? "#fff" : null
                                }
                                icon={faPhone}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Contactos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Packs" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "packs"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "packs" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("packs")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={contentAdmin == "packs" ? "#fff" : null}
                                icon={faTicketSimple}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Packs"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Descontos" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "descontos"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentAdmin == "descontos" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("descontos")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "descontos" ? "#fff" : null
                                }
                                icon={faTags}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Descontos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Cancelados" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "cancelados"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentAdmin == "cancelados" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("cancelados")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "cancelados" ? "#fff" : null
                                }
                                icon={faCancel}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Cancelados"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="QrCode" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "qrcodes"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "qrcodes" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("qrcodes")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "qrcodes" ? "#fff" : null
                                }
                                icon={faQrcode}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="QrCode"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Professores" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "professores"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentAdmin == "professores" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("professores")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "professores" ? "#fff" : null
                                }
                                icon={faGraduationCap}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Professores"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Seguros" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "seguros"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "seguros" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("seguros")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "seguros" ? "#fff" : null
                                }
                                icon={faHandPaper}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Seguros"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Pedidos" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "pedidos"
                                  ? numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentAdmin == "pedidos"
                                  ? numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : "#fff"
                                  : numPedidosPendentes != 0
                                  ? "rgba(240, 70, 67, 1)"
                                  : null,
                            }}
                            onClick={() => handleButtonClick2("pedidos")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "pedidos"
                                    ? numPedidosPendentes != 0
                                      ? "rgba(240, 70, 67, 1)"
                                      : "#fff"
                                    : numPedidosPendentes != 0
                                    ? "rgba(240, 70, 67, 1)"
                                    : null
                                }
                                icon={faTriangleExclamation}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Pedidos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Ocupa espaço" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "ocupaespaco"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentAdmin == "ocupaespaco" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("ocupaespaco")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "ocupaespaco" ? "#fff" : null
                                }
                                icon={faCalendarCheck}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Ocupa espaço"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Appointment" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "appointment"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentAdmin == "appointment" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("appointment")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentAdmin == "appointment" ? "#fff" : null
                                }
                                icon={faSwimmer}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Marcar"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Pools" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "pools"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "pools" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("pools")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={contentAdmin == "pools" ? "#fff" : null}
                                icon={faSwimmingPool}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Piscinas"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Family" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentAdmin == "family"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentAdmin == "family" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick2("family")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={contentAdmin == "family" ? "#fff" : null}
                                icon={faPeopleRoof}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Família"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "justify",
                  overflowY: "auto",
                  "@media (max-width: 800px)": {
                    height: "50vh",
                  },
                }}
              >
                <Typography component="div" sx={{ p: 4 }}>
                  {getTypographyContent2()}
                </Typography>
              </Box>
            </DialogContent>
          )}
        </Dialog>

        {/* Professor */}
        <Dialog
          open={openProfessor}
          onClose={handleClose2Professor}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{
            style: {
              width: "80%",
              maxWidth: "90vw",
              height: 700,
            },
          }}
          style={{
            textAlign: "flex-start",

            marginTop: 30,
            "@media (max-width: 800px)": {
              marginTop: 0,
            },
          }}
        >
          {!isSmallScreen ? (
            <DialogContent
              sx={{
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleClose2Professor}
                aria-label="Fechar"
                icon={faClose}
              />
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  "@media (max-width: 800px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <List
                  sx={{
                    width: open3 ? "20%" : "5%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      margin: 0,
                      "&:hover": {
                        outline: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={handleDrawerClick3}
                  >
                    {open3 ? <ChevronLeftIcon /> : <MenuIcon />}
                    {open3 && (
                      <Typography
                        variant="body1"
                        sx={{
                          alignSelf: "center",
                          marginLeft: open3 ? 1 : 0,
                          fontSize: 18,
                          color: "#333333",
                        }}
                      >
                        Ajuda
                      </Typography>
                    )}
                  </IconButton>
                  {open3 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "5%",
                        }}
                      ></div>
                      <ListItem key="FAQ" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentProfessor == "faq"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentProfessor == "faq" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick3("faq")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentProfessor == "faq" ? "#fff" : null}
                              icon={faQuestionCircle}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Perguntas Frequentes"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Contacts" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentProfessor == "contacts"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color:
                              contentProfessor == "contacts" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick3("contacts")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentAdmin == "contacts" ? "#fff" : null}
                              icon={faPhone}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Contactos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  ) : null}
                </List>

                <Box
                  sx={{
                    flexGrow: 1,
                    textAlign: "justify",
                    overflowY: "auto",
                    width: "100%",
                    "@media (max-width: 800px)": {
                      height: "50vh",
                    },
                  }}
                >
                  <Typography component="div" sx={{ p: 4 }}>
                    {getTypographyContent3()}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          ) : (
            <DialogContent>
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleClose2Professor}
                aria-label="Fechar"
                icon={faClose}
              />
              <Box sx={{ display: "flex", flex: "1 1 auto" }}>
                <Box
                  sx={{
                    width: "20%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <Accordion
                    sx={{
                      display: "none",
                      "@media (max-width: 800px)": {
                        display: "block",
                      },
                    }}
                    expanded={openA3}
                    onChange={handleDrawerClick3}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => handleAClick3()}
                    >
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignContent: "flex-start",
                          margin: 0,
                          "&:hover": {
                            outline: "none",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            alignSelf: "center",
                            marginLeft: 1,
                            fontSize: 18,
                            color: "#333333",
                          }}
                        >
                          {openA3
                            ? getContentName3(contentProfessor)
                            : getContentName3(contentProfessor) || "Ajuda"}
                        </Typography>
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem key="FAQ" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentProfessor == "faq"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentProfessor == "faq" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick3("faq")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentProfessor == "faq" ? "#fff" : null
                                }
                                icon={faQuestionCircle}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Perguntas Frequentes"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="Contacts" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentProfessor == "contacts"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentProfessor == "contacts" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick3("contacts")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentProfessor == "contacts" ? "#fff" : null
                                }
                                icon={faPhone}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Contactos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "justify",
                  overflowY: "auto",
                  "@media (max-width: 800px)": {
                    height: "50vh",
                  },
                }}
              >
                <Typography component="div" sx={{ p: 4 }}>
                  {getTypographyContent3()}
                </Typography>
              </Box>
            </DialogContent>
          )}
        </Dialog>

        {/* cliente */}

        <Dialog
          open={openCliente}
          onClose={handleClose2Cliente}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{
            style: {
              width: "80%",
              maxWidth: "90vw",
              height: 700,
            },
          }}
          style={{
            textAlign: "flex-start",

            marginTop: 30,
            "@media (max-width: 800px)": {
              marginTop: 0,
            },
          }}
        >
          {window.innerWidth > 800 ? (
            <DialogContent
              sx={{
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleClose2Cliente}
                aria-label="Fechar"
                icon={faClose}
              />
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  "@media (max-width: 800px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <List
                  sx={{
                    width: open4 ? "20%" : "5%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      margin: 0,
                      "&:hover": {
                        outline: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={handleDrawerClick4}
                  >
                    {open4 ? <ChevronLeftIcon /> : <MenuIcon />}
                    {open4 && (
                      <Typography
                        variant="body1"
                        sx={{
                          alignSelf: "center",
                          marginLeft: open ? 1 : 0,
                          fontSize: 18,
                          color: "#333333",
                        }}
                      >
                        Ajuda
                      </Typography>
                    )}
                  </IconButton>
                  {open4 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "5%",
                        }}
                      ></div>
                      <ListItem key="FAQ" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentCliente == "faq"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentCliente == "faq" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick4("faq")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentCliente == "faq" ? "#fff" : null}
                              icon={faQuestionCircle}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Perguntas Frequentes"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Contacts" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentCliente == "contacts"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentCliente == "contacts" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick4("contacts")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentCliente == "contacts" ? "#fff" : null
                              }
                              icon={faPhone}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Contactos"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>

                      <ListItem key="Appointment" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentCliente == "appointment"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color:
                              contentCliente == "appointment" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick4("appointment")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={
                                contentCliente == "appointment" ? "#fff" : null
                              }
                              icon={faSwimmer}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Marcar"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Pools" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentCliente == "pools"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentCliente == "pools" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick4("pools")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentCliente == "pools" ? "#fff" : null}
                              icon={faSwimmingPool}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Piscinas"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="Family" disablePadding>
                        <ListItemButton
                          className="smallListItemButton"
                          style={{
                            backgroundColor:
                              contentCliente == "family"
                                ? "rgba(0, 196, 245, 1)"
                                : null,
                            color: contentCliente == "family" ? "#fff" : null,
                          }}
                          onClick={() => handleButtonClick4("family")}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              color={contentCliente == "family" ? "#fff" : null}
                              icon={faPeopleRoof}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Família"
                            primaryTypographyProps={{ fontSize: "12px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  ) : null}
                </List>

                <Box
                  sx={{
                    flexGrow: 1,
                    textAlign: "justify",
                    overflowY: "auto",
                    width: "100%",
                    "@media (max-width: 800px)": {
                      height: "50vh",
                    },
                  }}
                >
                  <Typography component="div" sx={{ p: 4 }}>
                    {getTypographyContent4()}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          ) : (
            <DialogContent>
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleClose2Cliente}
                aria-label="Fechar"
                icon={faClose}
              />
              <Box sx={{ display: "flex", flex: "1 1 auto" }}>
                <Box
                  sx={{
                    width: "20%",
                    margin: "0 !important",
                    padding: "1 !important",
                    marginTop: 0,
                    marginLeft: 0,
                    "@media (max-width: 800px)": {
                      width: "100%",
                      marginBottom: 20,
                    },
                  }}
                >
                  <Accordion
                    sx={{
                      display: "none",
                      "@media (max-width: 800px)": {
                        display: "block",
                      },
                    }}
                    expanded={openA4}
                    onChange={handleDrawerClick4}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => handleAClick4()}
                    >
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignContent: "flex-start",
                          margin: 0,
                          "&:hover": {
                            outline: "none",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            alignSelf: "center",
                            marginLeft: 1,
                            fontSize: 18,
                            color: "#333333",
                          }}
                        >
                          {openA4
                            ? getContentName4(contentCliente)
                            : getContentName4(contentCliente) || "Ajuda"}
                        </Typography>
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem key="FAQ" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentCliente == "faq"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentCliente == "faq" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick4("faq")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={contentCliente == "faq" ? "#fff" : null}
                                icon={faQuestionCircle}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Perguntas Frequentes"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="Contacts" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentCliente == "contacts"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentCliente == "contacts" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick4("contacts")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentCliente == "contacts" ? "#fff" : null
                                }
                                icon={faPhone}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Contactos"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="Appointment" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentCliente == "appointment"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color:
                                contentCliente == "appointment" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick4("appointment")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentCliente == "appointment"
                                    ? "#fff"
                                    : null
                                }
                                icon={faSwimmer}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Marcar"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Pools" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentCliente == "pools"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentCliente == "pools" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick4("pools")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentCliente == "pools" ? "#fff" : null
                                }
                                icon={faSwimmingPool}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Piscinas"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem key="Family" disablePadding>
                          <ListItemButton
                            className="smallListItemButton"
                            style={{
                              backgroundColor:
                                contentCliente == "family"
                                  ? "rgba(0, 196, 245, 1)"
                                  : null,
                              color: contentCliente == "family" ? "#fff" : null,
                            }}
                            onClick={() => handleButtonClick4("family")}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                color={
                                  contentCliente == "family" ? "#fff" : null
                                }
                                icon={faPeopleRoof}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Família"
                              primaryTypographyProps={{ fontSize: "12px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "justify",
                  overflowY: "auto",
                  "@media (max-width: 800px)": {
                    height: "50vh",
                  },
                }}
              >
                <Typography component="div" sx={{ p: 4 }}>
                  {getTypographyContent4()}
                </Typography>
              </Box>
            </DialogContent>
          )}
        </Dialog>
      </IconContext.Provider>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: 452,
            height: 216,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {t("terminarSessao")}{" "}
          <FontAwesomeIcon
            edge="end"
            color="inherit"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              padding: "8px",
            }}
            onClick={handleCloseLogout}
            aria-label="Fechar"
            icon={faClose}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("terminarSessaoTexto")}
            {t("terminarSessaoTexto2")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${simbtn})`,
              width: 218,
              height: 40,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              Logout();
            }}
          >
            {t("sim")}
          </Button>
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${naobtn})`,
              width: 198,
              height: 30,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              handleCloseLogout();
            }}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditarDados}
        onClose={handleCloseEditarDados}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: 686,
            height: 480,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {t("editarDados")}
          <FontAwesomeIcon
            edge="end"
            color="inherit"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              padding: "8px",
            }}
            onClick={handleCloseEditarDados}
            aria-label="Fechar"
            icon={faClose}
          />
        </DialogTitle>
        <DialogContent>
          {freeze && (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <Dialog
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  textAlign: "center",
                }}
              >
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("aguarde")}
                  </Typography>
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </DialogContent>
              </Dialog>
            </div>
          )}
          {carregamentoDadosCompleto ? (
            <div className="formPop">
              <Scrollbar style={{ height: 300 }}>
                <Box className="formTextField">
                  <div>
                    {/* Nome */}
                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="text"
                        disabled={edicao}
                        variant="standard"
                        label={t("nome")}
                        value={nome}
                        error={erroNome}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => {
                          setNome(e.target.value);
                          setErroNome(false);
                        }}
                      />
                    </FormControl>
                    {/* NIF */}
                    <FormControl className="formTextField3" fullWidth>
                      <TextField
                        required
                        type="number"
                        disabled={edicao}
                        variant="standard"
                        label={t("nif")}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 9);
                        }}
                        inputProps={{ maxLength: 9 }}
                        value={nif}
                        error={erroNif}
                        helperText={mensagemErroNif}
                        onChange={(e) => {
                          setNif(e.target.value);
                          setErroNif(false);
                        }}
                      />
                    </FormControl>
                    {/* Data de Nascimento */}
                    <FormControl className="formTextField3" fullWidth>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <DatePicker
                          disableFuture
                          label={t("dataNascimento") + " *"}
                          openTo="day"
                          views={["year", "month", "day"]}
                          value={dataNascimento}
                          error={erroDataNascimento}
                          minDate={new Date(1900, 0, 1)}
                          disabled={edicao}
                          helperText={mensagemErroDataNascimento}
                          onChange={(e) => {
                            setDataNascimento(e);
                            setErroDataNascimento(false);
                            setMensagemErroDataNascimento("");
                          }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    {/* Genero */}
                    <FormControl className="formTextField2" fullWidth>
                      <FormLabel id="demo-radio-buttons-group-label">
                        {t("genero")}
                      </FormLabel>

                      <RadioGroup
                        row
                        defaultValue={genero}
                        disabled={edicao}
                        label={t("genero")}
                        name="genero"
                        value={genero}
                        onChange={(e) => {
                          setGenero(e.target.value);
                          seterroGenero(false);
                        }}
                      >
                        <FormControlLabel
                          value="feminino"
                          disabled={edicao}
                          control={<Radio size="small" />}
                          label={t("F")}
                        />
                        <FormControlLabel
                          value="masculino"
                          disabled={edicao}
                          control={<Radio size="small" />}
                          label={t("M")}
                        />
                        <FormControlLabel
                          value="outro"
                          disabled={edicao}
                          control={<Radio size="small" />}
                          label={t("O")}
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* Email */}
                    <FormControl className="formTextField3" fullWidth>
                      <TextField
                        required
                        type="text"
                        disabled={true}
                        variant="standard"
                        label={t("email")}
                        value={email}
                        error={erroEmail}
                        helperText={mensagemErroEmail}
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => {
                          setEmail(e.target.value.toLocaleLowerCase());
                          setErroEmail(false);
                          setMensagemErroEmail("");
                        }}
                      />
                    </FormControl>
                    {/* Telefone */}
                    <FormControl className="formTextField3" fullWidth>
                      <PhoneInput
                        disabled={edicao}
                        defaultCountry="PT"
                        labels={
                          i18n.language == "pt"
                            ? pt
                            : i18n.language == "es"
                            ? es
                            : en
                        }
                        value={telefone}
                        error={erroTelefone}
                        helperText={mensagemErroTelefone}
                        inputProps={{ maxLength: 20 }}
                        onChange={(e) => {
                          setTelefone(e);
                          setErroTelefone(false);
                          setMensagemErroTelefone("");
                        }}
                        inputComponent={PhoneNumber}
                      />
                    </FormControl>
                    {/* Rua */}
                    <FormControl className="formTextField3" fullWidth>
                      <TextField
                        required
                        type="text"
                        disabled={edicao}
                        variant="standard"
                        label={t("morada")}
                        value={morada}
                        error={erroMorada}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => {
                          setMorada(e.target.value);
                          setErroMorada(false);
                        }}
                      />
                    </FormControl>
                    {/* Código Postal */}
                    <FormControl className="formTextField3" fullWidth>
                      <PatternFormat
                        type="text"
                        disabled={edicao}
                        variant="standard"
                        {...{
                          label: t("codigoPostal"),
                          variant: "standard",
                        }}
                        value={codigoPostal}
                        customInput={TextField}
                        required
                        format="####-###"
                        error={erroCodigoPostal}
                        helperText={mensagemErroCodigoPostal}
                        onChange={(e) => {
                          setCodigoPostal(e.target.value);
                          setErroCodigoPostal(false);
                          setMensagemErroCodigoPostal("");
                        }}
                      />
                    </FormControl>
                    {/* Foto */}
                    <FormControl className="formTextField3" fullWidth>
                      <p
                        id="tituloFotoPiscina"
                        style={{
                          marginTop: 10,
                          color: Colors.textFields,
                          fontSize: 15,
                          fontWeight: "normal",
                          textAlign: "left",
                        }}
                      >
                        {t("foto")}
                      </p>
                      <label
                        id="fotoPiscina"
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        <i className="fa fa-cloud-upload"></i>
                        <FontAwesomeIcon
                          icon={faUpload}
                          style={{ paddingTop: 4, paddingRight: 4 }}
                        />{" "}
                        {t("carregarImagem")}
                      </label>
                      <input
                        disabled={edicao}
                        id="file-upload"
                        style={{ paddingTop: 0 }}
                        type="file"
                        accept="image/*"
                        onChange={async (e) => {
                          document.getElementById(
                            "fotoPiscina"
                          ).style.borderColor = Colors.ativo;
                          document.getElementById(
                            "tituloFotoPiscina"
                          ).style.color = Colors.textFields;
                          const file = e.target.files[0];

                          const reader = new FileReader();

                          reader.readAsDataURL(file);

                          reader.onload = () => {
                            setFotoEditar(reader.result);
                          };
                        }}
                      ></input>
                      {/* Imagem carregada */}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {fotoEditar.length > 0 && (
                          <img
                            style={{ marginTop: 10, objectFit: "cover" }}
                            width={150}
                            height={150}
                            src={fotoEditar}
                          />
                        )}
                      </div>
                    </FormControl>
                    {/* let utilizador = Cookies.get("utilizador");
                    if (utilizador == "admin") {
                      setOpenAdmin(true);
                    } else if (utilizador == "funcionario") {
                      setOpenFuncionario(true);
                    } else if (utilizador == "professor") {
                      setOpenProfessor(true);
                    } else if (utilizador == "cliente") {
                      setOpenCliente(true);
                    } */}
                    {(Cookies.get("utilizador") == "funcionario" ||
                      Cookies.get("utilizador") == "professor") &&
                    idcliente == 0 ? (
                      <FormControl
                        className="formTextField3"
                        fullWidth
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "2%",
                        }}
                      >
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundColor: "#00C4F5",
                            width: 85,
                            height: 20,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            ativarCliente();
                          }}
                        >
                          {t("serCliente")}
                        </Button>
                      </FormControl>
                    ) : null}
                  </div>
                </Box>
              </Scrollbar>

              {/* Cancelar edição */}
              <Dialog
                open={cancelarEdicao}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("cancelarEdicao")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("textoCancelarEdicao")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      cancelar();
                    }}
                  >
                    {t("sim")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupCancelar();
                    }}
                  >
                    {t("nao")}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <CircularProgress disableShrink />
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {edicao ? (
            <Button
              className="btnEdicao"
              variant="contained"
              style={{
                backgroundImage: `url(${editarbtn})`,
                width: 315,
                height: 40,
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={() => {
                scrollToDiv();
              }}
            >
              {t("editar")}
            </Button>
          ) : (
            <div>
              <Button
                className="btnGuardar"
                variant="contained"
                style={{
                  backgroundImage: `url(${guardarbtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
              <Button
                className="btnGuardar"
                variant="contained"
                style={{
                  backgroundImage: `url(${cancelarbtn})`,
                  width: 218,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  handleClickOpenPopupCancelar();
                }}
              >
                {t("cancelar")}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditarPass}
        onClose={handleCloseEditarPass}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: 686,
            height: 380,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {t("alterarPalavraPasse")}
          <FontAwesomeIcon
            edge="end"
            color="inherit"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              padding: "8px",
            }}
            onClick={handleCloseEditarPass}
            aria-label="Fechar"
            icon={faClose}
          />
        </DialogTitle>
        <DialogContent>
          {freeze && (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <Dialog
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  textAlign: "center",
                }}
              >
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("aguarde")}
                  </Typography>
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </DialogContent>
              </Dialog>
            </div>
          )}
          <div className="formPop">
            <Box className="formTextFieldPass">
              <FormControl className="formTextField3" fullWidth>
                <TextField
                  id="password"
                  label={t("palavraPasse")}
                  variant="standard"
                  error={erroPassword}
                  type={showPassword ? "text" : "password"}
                  helperText={mensagemErroPalavraPasse}
                  color={sucessoPalavraPasse}
                  onChange={(e) => {
                    setPalavraPasse(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableRipple
                          disableFocusRipple
                          style={{
                            justifyContent: "right",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            color: "#8A8A8A",
                          }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                ></TextField>
              </FormControl>
              {/* Confirma Palavra-passe */}

              <FormControl className="formTextField3" fullWidth>
                <TextField
                  id="confirmarpassword"
                  label={t("confirmaPalavraPasse")}
                  variant="standard"
                  error={erroPassword2}
                  type={showPassword2 ? "text" : "password"}
                  helperText={mensagemErroPalavraPasse2}
                  color={sucessoPalavraPasse2}
                  onChange={(e) => {
                    setPalavraPasse2(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableRipple
                          disableFocusRipple
                          style={{
                            justifyContent: "right",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            color: "#8A8A8A",
                          }}
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                ></TextField>
              </FormControl>
            </Box>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            className="btnEdicao"
            variant="contained"
            style={{
              backgroundImage: `url(${editarbtn})`,
              width: 315,
              height: 40,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              ChangePassword();
            }}
          >
            {t("confirmar")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Header;
