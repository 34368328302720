import React, { useEffect, useState } from "react";
import {
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarDays,
  faGear,
  faHouse,
  faPersonSwimming,
  faUser,
  faUsers,
  faUserSecret,
  faUserTie,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

import { Colors } from "../values/Colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

function Footer() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [tipoUtilizador, setTipoUtilizador] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Faz com que mesmo que se abre a página diretamente pelo URL,
  // a cor esteja sempre de acordo com a página onde o utilizador está
  function getPageIndex(route) {
    if (tipoUtilizador == "superadmin") {
      switch (route) {
        case "/superadmins":
          return 0;
        case "/supercommercials":
          return 1;
        case "/dashboard_superadmin":
          return 2;
        case "/commercials":
          return 3;
        case "/pools":
          return 4;
        default:
          return 2;
      }
    } else if (tipoUtilizador == "supercomercial") {
      switch (route) {
        case "/commercials":
          return 0;
        case "/dashboard_supercommercial":
          return 1;
        case "/pools":
          return 2;
        default:
          return 1;
      }
    } else if (tipoUtilizador == "comercial") {
      switch (route) {
        case "/dashboard_commercial":
          return 0;
        case "/pools":
          return 1;
        default:
          return 0;
      }
    } else if (tipoUtilizador == "admin") {
      switch (route) {
        case "/poolcustomersa":
          return 0;
        case "/employeesa":
          return 1;
        case "/dashboard_admin":
          return 2;
        case "/poolcalendara":
          return 3;
        case "/waterlista":
          return 4;
        default:
          return 2;
      }
    } else if (tipoUtilizador == "funcionario") {
      switch (route) {
        case "/poolcustomers":
          return 0;
        // Página das marcações aqui
        // case "/employees":
        // return 1;
        case "/dashboard_employee":
          return 2;
        // Página do calendário aqui
        case "/waterlist":
          return 4;
        case "/poolcalendar":
          return 3;
        default:
          return 2;
      }
    } else if (tipoUtilizador == "professor") {
      switch (route) {
        // Página calendário aqui
        // case "/customers":
        // return 0;
        case "/dashboard_teacher":
          return 1;
        default:
          return 1;
      }
    } else if (tipoUtilizador == "cliente") {
      switch (route) {
        case "/mycalendar":
          return 0;
        case "/dashboard_customer":
          return 1;

        case "/qrcodes":

        case "/poolsu":
          return 2;
        default:
          return 1;
      }
    }
  }

  const value = getPageIndex(window.location.pathname);
  const [value2, setValue] = useState(0);

  useEffect(() => {
    //Ler a variável para perceber qual o tipo de utilizador
    var tipoUtilizador = Cookies.get("utilizador");
    setTipoUtilizador(tipoUtilizador);
  }, []);

  //Atualiza a página quando um botão no bottom navigation é clicado
  const handleChange = (event, newValue) => {
    if (tipoUtilizador == "superadmin") {
      if (newValue == 0) {
        navigate("/superadmins");
      } else if (newValue == 1) {
        navigate("/supercommercials");
      } else if (newValue == 2) {
        navigate("/dashboard_superadmin");
      } else if (newValue == 3) {
        navigate("/commercials");
      } else if (newValue == 4) {
        navigate("/pools");
      }
    } else if (tipoUtilizador == "supercomercial") {
      if (newValue == 0) {
        navigate("/commercials");
      } else if (newValue == 1) {
        navigate("/dashboard_supercommercial");
      } else if (newValue == 2) {
        navigate("/pools");
      }
    } else if (tipoUtilizador == "comercial") {
      if (newValue == 0) {
        navigate("/dashboard_commercial");
      } else if (newValue == 1) {
        navigate("/pools");
      }
    } else if (tipoUtilizador == "admin") {
      if (newValue == 0) {
        navigate("/poolcustomersa");
      } else if (newValue == 1) {
        navigate("/employeesa");
      } else if (newValue == 2) {
        navigate("/dashboard_admin");
      } else if (newValue == 3) {
        navigate("/poolcalendara");
      } else if (newValue == 4) {
        navigate("/waterlista");
      }
    } else if (tipoUtilizador == "funcionario") {
      if (newValue == 0) {
        navigate("/poolcustomers");
      } else if (newValue == 1) {
      } else if (newValue == 2) {
        navigate("/dashboard_employee");
      } else if (newValue == 3) {
        navigate("/poolcalendar");
      } else if (newValue == 4) {
        navigate("/waterlist");
      }
    } else if (tipoUtilizador == "professor") {
      if (newValue == 0) {
        // Página calendário aqui
        // navigate("/customers");
      } else if (newValue == 1) {
        navigate("/dashboard_teacher");
      }
    } else if (tipoUtilizador == "cliente") {
      if (newValue == 0) {
        // Página calendário aqui
        navigate("/mycalendar");
      } else if (newValue == 1) {
        navigate("/dashboard_customer");
      } else if (newValue == 2) {
        navigate("/qrcodes");
      }
    }
  };

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        {/* Super Admin */}
        {tipoUtilizador == "superadmin" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label={isMobile?  null : t("superadmin") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faUserSecret} />}
            />
            <BottomNavigationAction
              label={isMobile ? null : t("supercomercial") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faUserTie} />}
            />
            <BottomNavigationAction
                                      label={isMobile? null : t("Home")}

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />
            <BottomNavigationAction
              label={isMobile ? null : t("comercial") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faUser} />}
            />
            <BottomNavigationAction
              label={isMobile ? null :t("piscinas") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faPersonSwimming} />
              }
            />
          </BottomNavigation>
        )}
        {/* Super Comercial */}
        {tipoUtilizador == "supercomercial" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label={isMobile ? null : t("comercial")}
              icon={<FontAwesomeIcon className="footerIcons" icon={faUser} />}
            />
            <BottomNavigationAction
                                      label={isMobile ? null : t("Home") }

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />

            <BottomNavigationAction
              label={isMobile ? null :t("piscinas") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faPersonSwimming} />
              }
            />
          </BottomNavigation>
        )}
        {/* Comercial */}
        {tipoUtilizador == "comercial" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
                                      label={isMobile ? null : t("Home") }

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />

            <BottomNavigationAction
              label={isMobile ? null : t("piscinas") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faPersonSwimming} />
              }
            />
          </BottomNavigation>
        )}
        {/* Administrador */}
        {tipoUtilizador == "admin" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label={isMobile ?null: t("clientes") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faUsers} />}
            />
            <BottomNavigationAction
              label={isMobile ? null :t("funcionario") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faUserTie} />}
            />
            <BottomNavigationAction
                          label={isMobile ?null : t("Home") }

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />
            <BottomNavigationAction
              label={isMobile ?null : t("calendario") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faCalendarDays} />}
            />
            <BottomNavigationAction
              label={isMobile ? null :t("piscinas") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faPersonSwimming} />
              }
            />
          </BottomNavigation>
        )}
        {/* Funcionário */}
        {tipoUtilizador == "funcionario" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label={isMobile ?null : t("clientes") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faUsers} />}
            />
            <BottomNavigationAction
              label={isMobile ?null : t("marcacoes") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faCalendarCheck} />
              }
            />
            <BottomNavigationAction
                                      label={isMobile ?null: t("Home")}

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />
            <BottomNavigationAction
              label={isMobile ?null :t("calendario") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faCalendarDays} />
              }
            />
            <BottomNavigationAction
              label={isMobile ?null : t("piscina") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faPersonSwimming} />
              }
            />
          </BottomNavigation>
        )}
        {/* Professor*/}
        {tipoUtilizador == "professor" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label={isMobile ?null : t("calendario") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faCalendarDays} />
              }
            />
            <BottomNavigationAction
                                      label={isMobile ? null :t("Home") }

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />
          </BottomNavigation>
        )}
        {/* Cliente */}
        {tipoUtilizador == "cliente" && (
          <BottomNavigation
            style={{ backgroundColor: `${Colors.footer}` }}
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label={isMobile ?null : t("calendario") }
              icon={
                <FontAwesomeIcon className="footerIcons" icon={faCalendarDays} />
              }
            />
            <BottomNavigationAction
                                      label={isMobile ? null :t("Home") }

              icon={<FontAwesomeIcon className="footerIcons" icon={faHouse} />}
            />

            <BottomNavigationAction
              label={isMobile ? null : t("qrcodes") }
              icon={<FontAwesomeIcon className="footerIcons" icon={faQrcode} />}
            />
          </BottomNavigation>
        )}
      </Paper>
    </Box>
  );
}

export default Footer;
