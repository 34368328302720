import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  deleteProfessor,
  enviarEmailPostProfessor,
  getProfessor,
  postProfessor,
  putProfessor,
  putStatusProfessor,
} from "../../api/requests/Professores";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";

function EditarProfessorF() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  //Privilégios funcionario
  const privilegios = JSON.parse(Cookies.get("privilegios"));

  const { state } = useLocation();
  const { idProfessor } = state;

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeProfessor, setNomeProfessor] = useState("");
  const [emailProfessor, setEmailProfessor] = useState("");
  const [telefoneProfessor, setTelefoneProfessor] = useState("");
  const [cargoProfessor, setCargoProfessor] = useState("");
  const [nifProfessor, setNifProfessor] = useState("");
  const [moradaProfessor, setMoradaProfessor] = useState("");
  const [codigoPostalProfessor, setCodigoPostalProfessor] = useState("");
  const [fotoProfessor, setFotoProfessor] = useState([]);
  const [statusProfessor, setStatusProfessor] = useState("");
  const [dataNascimentoProfessor, setDataNascimentoProfessor] = useState(
    new Date()
  );
  const [dadosOriginais, setDadosOriginais] = useState([]);

  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [erroNomeProfessor, setErroNomeProfessor] = useState("");
  const [erroEmailProfessor, setErroEmailProfessor] = useState("");
  const [erroTelefoneProfessor, setErroTelefoneProfessor] = useState("");
  const [erroCargoProfessor, setErroCargoProfessor] = useState("");
  const [erroNifProfessor, setErroNifProfessor] = useState("");
  const [erroMoradaProfessor, setErroMoradaProfessor] = useState("");
  const [erroCodigoPostalProfessor, setErroCodigoPostalProfessor] =
    useState("");
  const [erroNascimentoProfessor, setErroNascimentoProfessor] = useState(false);

  // Responsável
  const [mensagemErroEmailProfessor, setMensagemErroEmailProfessor] =
    useState("");
  const [mensagemErroTelefoneProfessor, setMensagemErroTelefoneProfessor] =
    useState("");
  const [mensagemErroNifProfessor, setMensagemErroNifProfessor] = useState("");
  const [
    mensagemErroCodigoPostalProfessor,
    setMensagemErroCodigoPostalProfessor,
  ] = useState("");
  const [mensagemErroNascimentoProfessor, setMensagemErroNascimentoProfessor] =
    useState("");

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getProfessor(idProfessor)
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          setDadosOriginais(dados);
          setNomeProfessor(dados.nome);
          setEmailProfessor(dados.email);
          setTelefoneProfessor(dados.contacto);
          setNifProfessor(dados.nif);
          setMoradaProfessor(dados.morada);
          setCodigoPostalProfessor(dados.codigo_postal);
          if (dados.foto_perfil) {
            setFotoProfessor(dados.foto_perfil);
          }
          setDataNascimentoProfessor(dados.data_nascimento_final);
          setStatusProfessor(dados.status);
          setCarregamentoDadosCompleto(true);
          setMotivo(res.data.obs);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function editarProfessor() {
    setFreeze(true);
    putProfessor({
      id: idProfessor,
      nome: nomeProfessor,
      nif: nifProfessor,
      email: emailProfessor,
      contacto: telefoneProfessor,
      morada: moradaProfessor,
      codigo_postal: codigoPostalProfessor,
      foto_perfil: fotoProfessor.length == 0 ? null : fotoProfessor,
      data_nascimento: new Date(dataNascimentoProfessor)
        .toISOString()
        .split("T")[0],
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      nomeProfessor &&
      emailProfessor &&
      telefoneProfessor &&
      nifProfessor &&
      moradaProfessor &&
      codigoPostalProfessor &&
      dataNascimentoProfessor
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailProfessor
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailProfessor(true);
        setMensagemErroEmailProfessor(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneProfessor)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneProfessor(true);
        setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
      }
      if (emailProfessor) {
        verificarEmail({ email: emailProfessor }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != idProfessor) {
                confirmarDadosPreenchidos = false;
                setErroEmailProfessor(true);
                setMensagemErroEmailProfessor(res.data.error);
              }
            }
          }
        });
      }
      // Validação do nif
      if (nifProfessor.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifProfessor(true);
        setMensagemErroNifProfessor(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalProfessor < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalProfessor(true);
        setMensagemErroCodigoPostalProfessor(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoProfessor);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoProfessor(true);
        setMensagemErroNascimentoProfessor(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarProfessor();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeProfessor) {
        setErroNomeProfessor(true);
      }
      //Validação do email
      if (!emailProfessor) {
        setErroEmailProfessor(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailProfessor
          );
        if (!validacaoEmail) {
          setErroEmailProfessor(true);
          setMensagemErroEmailProfessor(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailProfessor }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != idProfessor) {
                  setErroEmailProfessor(true);
                  setMensagemErroEmailProfessor(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneProfessor) {
        setErroTelefoneProfessor(true);
      } else {
        if (!isValidPhoneNumber(telefoneProfessor)) {
          setErroTelefoneProfessor(true);
          setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
        }
      }

      // Validação do nif
      if (!nifProfessor) {
        setErroNifProfessor(true);
      } else {
        if (nifProfessor.length < 9) {
          setErroNifProfessor(true);
          setMensagemErroNifProfessor(t("validacaoNif"));
        }
      }
      //Validação da morada
      if (!moradaProfessor) {
        setErroMoradaProfessor(true);
      }
      //Validação do código postal
      if (!codigoPostalProfessor) {
        setErroCodigoPostalProfessor(true);
      } else {
        if (codigoPostalProfessor < 7) {
          setErroCodigoPostalProfessor(true);
          setMensagemErroCodigoPostalProfessor(t("validacaoCodigoPostal"));
        }
      }
      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoProfessor);
      if (isNaN(isValidDate)) {
        setErroNascimentoProfessor(true);
        setMensagemErroNascimentoProfessor(t("dataInvalida"));
      }
    }
  }
  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
        "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
      "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeProfessor(dadosOriginais.nome);
    setEmailProfessor(dadosOriginais.email);
    setTelefoneProfessor(dadosOriginais.contacto);
    setNifProfessor(dadosOriginais.nif);
    setMoradaProfessor(dadosOriginais.morada);
    setCodigoPostalProfessor(dadosOriginais.codigo_postal);
    setFotoProfessor(dadosOriginais.foto_perfil);
    setDataNascimentoProfessor(dadosOriginais.data_nascimento_final);
    setStatusProfessor(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeProfessor(false);
    setErroEmailProfessor(false);
    setErroTelefoneProfessor(false);
    setErroCargoProfessor(false);
    setErroNifProfessor(false);
    setErroMoradaProfessor(false);
    setErroCodigoPostalProfessor(false);
    setErroNascimentoProfessor(false);
    setMensagemErroEmailProfessor("");
    setMensagemErroTelefoneProfessor("");
    setMensagemErroNifProfessor("");
    setMensagemErroCodigoPostalProfessor("");
    window.scrollTo(0, 0);
  }

  function suspenderProfessor() {
    if (motivoSuspensao) {
      setSuspender(false);
      setFreeze(true);
      putStatusProfessor({
        id: idProfessor,
        obs: motivoSuspensao,
      })
        .then((res) => {
          if (res.success) {
            setFreeze(false);
            toast.success(res.data.msg);
            setMotivoSuspensao("");
            setMotivo(motivoSuspensao);
            setStatusProfessor(!statusProfessor);
          } else {
            if (res.status == 500) {
              setFreeze(false)
              navigate("/500");
            }
            setFreeze(false);
            toast.error(res.error);
            setMotivoSuspensao("");
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setErroMotivoSuspensao(true);
    }
  }

  function eliminarProfessor() {
    setEliminar(false);
    setFreeze(true);
    deleteProfessor({ id: idProfessor })
      .then((res) => {
        if (res.success) {
          toast.success(res.data.msg);
          navigate("/teacherf");
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function enviarEmail() {
    definePassEmail({
      email: emailProfessor,
    }).then((res) => {
      if (res.success) {
        toast.success(res.msg);
        //navigate("/superadmins");
      } else {
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="align-left">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/teacherf");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>

            <div className="align-right">
              {/* <h3 className="titleList">{t("editarProfessor")}</h3> */}
            </div>
          </div>

          <div>
            <div className="formPage4">
              <Box className="formTextField5">
                <div className="cardsDivPiscina2">
                  {edicao ? (
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscina1"
                      variant="outlined"
                      disabled={
                        !edicao || privilegios.editar_professor == 0
                          ? true
                          : false
                      }
                      style={{
                        backgroundColor:
                          !edicao || privilegios.editar_professor == 0
                            ? Colors.cardInativo
                            : Colors.card1,
                      }}
                      onClick={() => {
                        scrollToDiv();
                      }}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faPen} />
                      {t("editar")}
                    </Button>
                  ) : (
                    <Button
                      id="cardOpcoesPiscina1_1"
                      className="cardOpcoesPiscina1_1"
                      variant="outlined"
                      style={{
                        backgroundColor: Colors.footer,
                      }}
                      disabled={!edicao}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faPen} />
                      {t("modoEdicao")}
                    </Button>
                  )}
                </div>
                <div className="cardsDivPiscina2">
                  {statusProfessor ? (
                    <Button
                      className="cardOpcoesPiscina2"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card2_1
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupSuspender}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faCircleStop}
                      />
                      {t("suspender")}
                    </Button>
                  ) : (
                    <Button
                      className="cardOpcoesPiscina2_2"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card2
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupSuspender}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faCirclePlay}
                      />
                      {t("ativar")}
                    </Button>
                  )}

                  <Button
                    className="cardOpcoesPiscinaEliminar3"
                    variant="outlined"
                    disabled={
                      !edicao || privilegios.eliminar_professor == 0
                        ? true
                        : false
                    }
                    style={{
                      backgroundColor:
                        !edicao || privilegios.eliminar_professor == 0
                          ? Colors.cardInativo
                          : Colors.card3,
                    }}
                    onClick={handleClickOpenPopupEliminar}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faTrash} />
                    {t("eliminar")}
                  </Button>
                  <Button
                    id="editarCard"
                    className="cardOpcoesPiscinaReenviar2"
                    variant="outlined"
                    style={{
                      backgroundColor: "olivedrab",
                    }}
                    onClick={() => {
                      enviarEmail();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faPaperPlane}
                    />
                    {t("reenviarAtivacao")}
                  </Button>
                </div>
              </Box>
            </div>
            {motivo && (
              <div className="formPageSuspensao">
                <Alert severity="error">
                  {statusProfessor
                    ? t("professorAtivo")
                    : t("professorSuspenso")}{" "}
                  | {t("motivo")}: {motivo}
                </Alert>
              </div>
            )}
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Eliminar professor */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarProfessor")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoEliminarProfessor")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarProfessor();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Suspender professor */}
          <Dialog
            open={suspender}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {statusProfessor ? t("suspenderProfessor") : t("ativarProfessor")}
            </DialogTitle>
            <DialogContent>
              {statusProfessor
                ? t("justificacaoSuspencao")
                : t("justificacaoAtivacao")}{" "}
              *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoSuspensao}
                  error={erroMotivoSuspensao}
                  onChange={(e) => {
                    setMotivoSuspensao(e.target.value);
                    setErroMotivoSuspensao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupSuspender}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  suspenderProfessor();
                }}
                autoFocus
              >
                {statusProfessor ? t("suspender") : t("ativar")}
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <div className="formPage">
              <Box className="formTextField" ref={ref}>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nomeProfessor}
                      error={erroNomeProfessor}
                      onChange={(e) => {
                        setNomeProfessor(e.target.value);
                        setErroNomeProfessor(false);
                      }}
                    />
                  </FormControl>
                  {/* Email */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("email")}
                      value={emailProfessor}
                      error={erroEmailProfessor}
                      helperText={mensagemErroEmailProfessor}
                      onChange={(e) => {
                        setEmailProfessor(e.target.value.toLocaleLowerCase());
                        setErroEmailProfessor(false);
                        setMensagemErroEmailProfessor("");
                      }}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={edicao}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefoneProfessor}
                      error={erroTelefoneProfessor}
                      helperText={mensagemErroTelefoneProfessor}
                      onChange={(e) => {
                        setTelefoneProfessor(e);
                        setErroTelefoneProfessor(false);
                        setMensagemErroTelefoneProfessor("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </FormControl>

                  {/* Nif */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nifProfessor}
                      error={erroNifProfessor}
                      helperText={mensagemErroNifProfessor}
                      onChange={(e) => {
                        setNifProfessor(e.target.value);
                        setErroNifProfessor(false);
                      }}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("rua")}
                      value={moradaProfessor}
                      error={erroMoradaProfessor}
                      onChange={(e) => {
                        setMoradaProfessor(e.target.value);
                        setErroMoradaProfessor(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      disabled={edicao}
                      type="text"
                      variant="standard"
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      value={codigoPostalProfessor}
                      required
                      format="####-###"
                      error={erroCodigoPostalProfessor}
                      helperText={mensagemErroCodigoPostalProfessor}
                      onChange={(e) => {
                        setCodigoPostalProfessor(e.target.value);
                        setErroCodigoPostalProfessor(false);
                        setMensagemErroCodigoPostalProfessor("");
                      }}
                    />
                  </FormControl>
                  {/* Data de nascimento */}
                  <FormControl className="formTextField3" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disableFuture
                        disabled={edicao}
                        label={t("dataNascimento")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataNascimentoProfessor}
                        onChange={(e) => {
                          setDataNascimentoProfessor(e);
                          setErroNascimentoProfessor(false);
                          setMensagemErroNascimentoProfessor("");
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroNascimentoProfessor}
                            helperText={mensagemErroNascimentoProfessor}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {/* Foto */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")}
                    </p>
                    <label
                      id="fotoPiscina"
                      htmlFor="file-upload"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarImagem")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        document.getElementById(
                          "fotoPiscina"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById(
                          "tituloFotoPiscina"
                        ).style.color = Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setFotoProfessor(reader.result);
                        };
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {fotoProfessor.length > 0 && (
                        <img
                          style={{ marginTop: 10,objectFit:"cover" }}
                          width={150}
                          height={150}
                          src={fotoProfessor}
                        />
                      )}
                    </div>
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarProfessorF;
