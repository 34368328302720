import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_pt from "./locales/pt/translation.json";
import translation_en from "./locales/en/translation.json";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";

// Ficheiros com as traduções
const resources = {
  //Inglês
  en: { translation: translation_en },
  //Português
  pt: { translation: translation_pt },
};

// Opções para ir buscar o idioma predefinido
const options = {
  order: [
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
  lookupLocalStorage: "lng",
};

// Idiomas do site - en (Inglês) | pt (Português)
const Languages = ["en", "pt"];

i18next.use(I18nextBrowserLanguageDetector).use(initReactI18next).init({
  //   Detetar idioma
  detection: options,
  //   Idioma predefinido
  fallbackLng: "en",
  //   Idiomas suportados
  supportedLngs: Languages,
  //   Traduções
  resources,
});

export default i18n;
