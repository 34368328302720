import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faEye,
  faFloppyDisk,
  faHand,
  faStop,
  faClose,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import countryList from "react-select-country-list";
import { Scrollbar } from "react-scrollbars-custom";

import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";

import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import {
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Autocomplete,
  DialogTitle,
  DialogContentText,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat, PatternFormat } from "react-number-format";

import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { listaPacks } from "../../api/requests/Packs";
import Search1 from "../../components/DataTable/Search1";
import { getListaEE } from "../../api/requests/Piscinas";
import Nav from "../../components/Nav";
import { getEE, putEE } from "../../api/requests/Piscinas";

function ListaEEA() {
  const [ee, setEE] = useState([]);
  const [eeCopy, setEECopy] = useState([]);
  const [search, setSearch] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);
  const [openEditarEE, setOpenEditarEE] = useState(false);
  const handleCloseEditarEE = () => setOpenEditarEE(false);

  const handleClickOpenEditarEE = () => {
    setOpenEditarEE(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arrayEe = eeCopy;
      arrayEe = arrayEe.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome.toLowerCase().includes(search.toLowerCase())
      );
      setEE(arrayEe);
    } else {
      setEE(eeCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getListaEE({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_entidade_exploradora,
              nome: element.nome,
              email: element.email,
              cidade: element.cidade,
            };
            arrayFinal.push(linha);
            setEE(arrayFinal);
            setEECopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "cidade"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "cidade"] : []);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [edicao, setEdicao] = useState(true);
  const [idEE, setIdEE] = useState("");

  const [freeze, setFreeze] = useState(false);
  const [nome, setNome] = useState("");
  const [chave, setChave] = useState("");
  const [codPostal, setCodPostal] = useState("");
  const [contacto, setContacto] = useState("");
  const [contrato, setContrato] = useState("");
  const [distrito, setDistrito] = useState("");
  const [cidade, setCidade] = useState("");
  const [email, setEmail] = useState("");
  const [fotoP, setFotoP] = useState([]);
  const [morada, setMorada] = useState("");
  const [nipc, setNipc] = useState("");
  const [pais, setPais] = useState("");
  const [subCC, setSubCC] = useState("");
  const [suMB, setSubMB] = useState("");
  const [subMBWay, setSubMBWay] = useState("");

  const [erroNome, setErroNome] = useState(false);
  const [erroChave, setErroChave] = useState(false);
  const [erroCodPostal, setErroCodPostal] = useState(false);
  const [erroContacto, setErroContacto] = useState(false);
  const [erroContrato, setErroContrato] = useState(false);
  const [erroDistrito, setErroDistrito] = useState(false);
  const [erroCidade, setErroCidade] = useState(false);

  const [erroEmail, setErroEmail] = useState(false);
  const [erroFotoP, setErroFotoP] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroNipc, setErroNipc] = useState(false);
  const [erroPais, setErroPais] = useState(false);
  const [erroSubCC, setErroSubCC] = useState(false);
  const [erroSuMB, setErroSubMB] = useState(false);
  const [erroSubMBWay, setErroSubMBWay] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);

    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    if (idEE != "") {
      getEE({ id_entidade_exploradora: idEE })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];

            setDadosOriginais(dados);
            setNome(dados.nome);
            setChave(dados.chave);
            setCidade(dados.cidade);
            setCodPostal(dados.codigo_postal);
            setContacto(dados.contacto);
            let contratoBase64 = dados.contrato;



       
          // Encontra a posição do primeiro caractere após a vírgula
          let posicaoVirgula = contratoBase64.indexOf(",") + 1;

          // Remove a parte inicial até a vírgula
          let contratoBase64SemHeader =
            contratoBase64.substring(posicaoVirgula);

          // Convertendo de base64 para o tipo original
          let contratoBlob = atob(contratoBase64SemHeader);
            let contratoArray = [];

            for (let i = 0; i < contratoBlob.length; i++) {
              contratoArray.push(contratoBlob.charCodeAt(i));
            }

            let contratoUint8Array = new Uint8Array(contratoArray);
            let contratoBlobAgain = new Blob([contratoUint8Array], {
              type: "pdf",
            });
            setContrato(contratoBlobAgain);
            if (dados.distrito === 1) {
              setDistrito("Aveiro");
            } else if (dados.distrito === 2) {
              setDistrito("Beja");
            } else if (dados.distrito === 3) {
              setDistrito("Braga");
            } else if (dados.distrito === 4) {
              setDistrito("Bragança");
            } else if (dados.distrito === 5) {
              setDistrito("Castelo Branco");
            } else if (dados.distrito === 6) {
              setDistrito("Coimbra");
            } else if (dados.distrito === 7) {
              setDistrito("Évora");
            } else if (dados.distrito === 8) {
              setDistrito("Faro");
            } else if (dados.distrito === 9) {
              setDistrito("Guarda");
            } else if (dados.distrito === 10) {
              setDistrito("Leiria");
            } else if (dados.distrito === 11) {
              setDistrito("Lisboa");
            } else if (dados.distrito === 12) {
              setDistrito("Portalegre");
            } else if (dados.distrito === 13) {
              setDistrito("Porto");
            } else if (dados.distrito === 14) {
              setDistrito("Região Autónoma dos Açores");
            } else if (dados.distrito === 15) {
              setDistrito("Região Autónoma da Madeira");
            } else if (dados.distrito === 16) {
              setDistrito("Santarém");
            } else if (dados.distrito === 17) {
              setDistrito("Setúbal");
            } else if (dados.distrito === 18) {
              setDistrito("Viana do Castelo");
            } else if (dados.distrito === 19) {
              setDistrito("Vila Real");
            } else if (dados.distrito === 20) {
              setDistrito("Viseu");
            }
            setEmail(dados.email);
            if (dados.foto_perfil) {
              setFotoP(dados.foto_perfil);
            }

            setMorada(dados.morada);
            setNipc(dados.nipc);
            setPais(dados.pais);
            setSubCC(dados.subentidade_cc);
            setSubMB(dados.subentidade_mb);
            setSubMBWay(dados.subentidade_mbway);

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idEE]);

  function editarEE() {
    setFreeze(true);
    putEE({
      id_entidade_exploradora: idEE,
      subentidade_cc: subCC,
      subentidade_mb: suMB,
      subentidade_mbway: subMBWay,
      chave: chave,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
      });
  }

  function validacao() {
    if (chave && subCC && suMB && subMBWay) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarEE();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      if (!suMB) {
        setErroSubMB(true);
      }
      if (!subMBWay) {
        setErroSubMBWay(true);
      }
      if (!subCC) {
        setErroSubCC(true);
      }
      if (!chave) {
        setErroChave(true);
      }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    setChave(dadosOriginais.chave);
    setSubCC(dadosOriginais.subentidade_cc);
    setSubMB(dadosOriginais.subentidade_mb);
    setSubMBWay(dadosOriginais.subentidade_mbway);

    setErroNome(false);
    setErroChave(false);
    setErroCodPostal(false);
    setErroContacto(false);
    setErroContrato(false);
    setErroDistrito(false);
    setErroEmail(false);
    setErroFotoP(false);
    setErroMorada(false);
    setErroNipc(false);
    setErroPais(false);
    setErroSubCC(false);
    setErroSubMB(false);
    setErroSubMBWay(false);

    window.scrollTo(0, 0);
  }

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/dashboard_admin");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <h3 className="titleList">{t("listaEE")}</h3>
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 70,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              {
                field: "email",
                headerName: t("email"),
                flex: 1,
                type: "string",
              },
              {
                field: "cidade",
                headerName: t("cidade"),
                flex: 1,
                type: "number",
                hide: columnsHidden.includes("cidade"),
              },

              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: 18 }}
                          icon={faEye}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdEE(id);
                        handleClickOpenEditarEE();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={ee}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
          {/* editar pack */}
          <Dialog
            open={openEditarEE}
            onClose={handleCloseEditarEE}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleCloseEditarEE}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Scrollbar style={{ height: 400 }}>
                    <Box className="formTextField">
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={true}
                            required
                            type="text"
                            variant="standard"
                            label={t("nome")}
                            value={nome}
                            error={erroNome}
                            onChange={(e) => {
                              setNome(e.target.value);
                              setErroNome(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="number"
                            disabled={true}
                            variant="standard"
                            label={t("nipc")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9);
                            }}
                            value={nipc}
                            error={erroNipc}
                            onChange={(e) => {
                              setNipc(e.target.value);
                              setErroNipc(false);
                            }}
                          />
                        </FormControl>
                        {/* Email */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("email")}
                            value={email}
                            error={erroEmail}
                            onChange={(e) => {
                              setEmail(e.target.value.toLocaleLowerCase());
                              setErroEmail(false);
                            }}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={true}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={contacto}
                            error={erroContacto}
                            onChange={(e) => {
                              setContacto(e);
                              setErroContacto(false);
                            }}
                            inputComponent={PhoneNumber}
                          />
                        </FormControl>
                        {/* morada */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("morada")}
                            value={morada}
                            error={erroMorada}
                            onChange={(e) => {
                              setMorada(e.target.value);
                              setErroMorada(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            type="text"
                            disabled={true}
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            format="####-###"
                            value={codPostal}
                            error={erroCodPostal}
                            onChange={(e) => {
                              setCodPostal(e.target.value);
                              setErroCodPostal(false);
                            }}
                          />
                        </FormControl>
                        {/* Cidade */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("cidade")}
                            value={cidade}
                            error={erroCidade}
                            onChange={(e) => {
                              setCidade(e.target.value);
                              setErroCidade(false);
                            }}
                          />
                        </FormControl>
                        {/* Distrito */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("distrito")}
                            value={distrito}
                            error={erroDistrito}
                            onChange={(e) => {
                              setDistrito(e.target.value);
                              setErroDistrito(false);
                            }}
                          />
                        </FormControl>
                        {/* País */}
                        <FormControl
                          className="formTextField3"
                          fullWidth
                          ref={ref}
                        >
                          <Autocomplete
                            label={t("pais")}
                            variant="standard"
                            options={options}
                            value={pais}
                            onChange={(event, newValue) => {
                              setPais(newValue.label);
                              setErroPais(false);
                            }}
                            disabled={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("pais")}
                                variant="standard"
                                error={erroPais}
                                disabled={true}
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                        {/* Contrato */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="contrato"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("contrato")} * (*pdf)
                          </p>

                          {contrato && (
                            <div
                              style={{
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                                borderColor: Colors.textFields,
                                height: "600px",
                                marginTop: 10,
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                            >
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={URL.createObjectURL(contrato)}
                                />
                              </Worker>
                            </div>
                          )}
                        </FormControl>
                        {/* Foto */}
                        {fotoP != "" && (
                          <FormControl className="formTextField3" fullWidth>
                            <p
                              id="tituloFotoPiscina"
                              style={{
                                marginTop: 10,
                                color: Colors.textFields,
                                fontSize: 15,
                                fontWeight: "normal",
                                textAlign: "left",
                              }}
                            >
                              {t("foto")} *
                            </p>

                            {/* Imagem carregada */}
                            <div>
                              {fotoP && (
                                <img
                                  style={{ marginTop: 10,objectFit:"cover" }}
                                  width={150}
                                  height={150}
                                  src={fotoP}
                                />
                              )}
                            </div>
                          </FormControl>
                        )}
                        {/* <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("subentidadecc")}
                            value={subCC}
                            error={erroSubCC}
                            onChange={(e) => {
                              setSubCC(e.target.value);
                              setErroSubCC(false);
                            }}
                          />
                        </FormControl> */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("subentidademb")}
                            value={suMB}
                            error={erroSuMB}
                            onChange={(e) => {
                              setSubMB(e.target.value);
                              setErroSubMB(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("subentidadembway")}
                            value={subMBWay}
                            error={erroSubMBWay}
                            onChange={(e) => {
                              setSubMBWay(e.target.value);
                              setErroSubMBWay(false);
                            }}
                          />
                        </FormControl>
                        {/* Chave */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            required
                            type="text"
                            {...{
                              label: t("chave"),
                              variant: "standard",
                            }}
                            value={chave}
                            customInput={TextField}
                            format="####-####-####-####"
                            disabled={edicao}
                            error={erroChave}
                            onChange={(e) => {
                              setChave(e.target.value);
                              setErroChave(false);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Scrollbar>
                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("editar")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,
                      width: isMobile ? 164 : 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaEEA;
