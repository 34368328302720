import {
  Button,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  Modal,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from "@mui/material";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";

import {
  RectangleStackIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon,
  BuildingLibraryIcon,
  BanknotesIcon,
} from "@heroicons/react/24/solid";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { Scrollbar } from "react-scrollbars-custom";
import graduationIcon from "../../images/graduationCap.svg";
import calendarIcon from "../../images/calendarPoint.svg";
import swimIcon from "../../images/personSwim.svg";
import swimIconBlack from "../../images/SwimIconBlack.svg";
import graduationIconBlack from "../../images/personSwimBlack.svg";
import {
  faMobile,
  faCreditCard,
  faTicket,
  faMoneyBillTransfer,
  faGraduationCap,
  faPersonSwimming,
  faCalendarDay,
  faCalendarDays,
  faPen,
  faFaceFrown,
  faFaceSmile,
  faCashRegister,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Nav from "../../components/Nav";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { loginPost } from "../../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies, { set } from "js-cookie";
import {
  getInscricoesId,
  getUtilizadoresId,
} from "../../api/requests/Utilizadores";
import { getFilhos } from "../../api/requests/ContaFamiliar";
import {
  getCategoriaNadoLivre,
  getCategoriasTurmas,
  getCategoriaTurmas,
  getCategoriaUtilizador,
  getModalidadesAulasPiscina,
  getModalidadesPiscina,
  getPacksPiscina,
  getPiscinas,
  getTurmas,
} from "../../api/requests/Piscinas";
import { Search } from "../../components/DataTable";
import { display, fontSize, maxWidth, Stack, width } from "@mui/system";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import Cards from "react-credit-cards-2";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import { Colors } from "../../values/Colors";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import timeGridPlugin from "@fullcalendar/timegrid";
import "react-credit-cards-2/es/styles-compiled.css";
import { NumericFormat, PatternFormat } from "react-number-format";
import mb from "../../images/multibanco.png";
import mbwayfoto from "../../images/mbway.png";
import errorgif from "../../images/error.gif";
import successgif from "../../images/success.gif";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import { es as es2, id } from "date-fns/locale";
import { pt as pt2 } from "date-fns/locale";
import { enGB as en2 } from "date-fns/locale";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import { DayClickEventHandler, DayPicker } from "react-day-picker";
import Grid from "@mui/material/Grid";
import anime from "animejs";
import {
  getDatasBanhosLivres,
  getHorasBanhosLivres,
  getInfoCompra,
  getInfoCompra2,
  getListaPacks,
  getPackUtilizador,
  getVerificacaoPackUtilizador,
  getVerificacaoSobreposicao,
  marcacaoBalcao,
  registarMarcacao,
  registarMarcacaoInativa,
} from "../../api/requests/Marcacoes";
import {
  associarPagamentosMarcacoes,
  emailCompraPackMarcacao,
  emailInscricaoTurma,
  emailMarcacao,
  linhasCompra,
  pagamentoCartaoCredito,
  pagamentoMbway,
  pagamentoMultibanco,
  verificarPagamentosEmDia,
} from "../../api/requests/Pagamentos";
import { verificarInscricaoPagamentoPendente } from "../../api/requests/Inscricao";
import { useLocation } from "react-router-dom";
import { Cookie } from "@mui/icons-material";
import moment from "moment";


function Marcar() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const [freeze, setFreeze] = useState(false);
  const { state } = useLocation();

  const { idPiscina } = state || {};

  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState(Cookies.get("uId"));
  const [inscritas, setInscritas] = useState([]);
  const [aulas, setAulas] = useState(false);
  const [tipoEscolhido, setTipoEscolhido] = useState(false);
  const [aulaEscolhido, setAulaEscolhido] = useState(false);

  const [cartaocredito, setCartaoCredito] = useState(false);
  const [tenhopack, setTenhoPack] = useState(false);
  const [multibanco, setMultibanco] = useState(false);
  const [balcao, setBalcao] = useState(false);
  const [mbway, setMbway] = useState(false);
  const [pagapack, setPagaPack] = useState(false);
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);
  const [selected, setSelected] = useState(true);
  const [idEscolhida, setIdEscolhida] = useState("");
  const [idUtilizadorEscolhido, setIdUtilizadorEscolhido] = useState("");
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [telefone, setTelefone] = useState("");
  const [erroTelefone, setErroTelefone] = useState(false);
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [submittedData, setSubmittedData] = useState({});
  const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);
  const [escolhido, setEscolhido] = useState(false);
  const [horaEscolhida, setHoraEscolhida] = useState(0);
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState();
  const [aulasPiscina, setAulasPiscina] = useState(false);
  const [nadoLivrePiscina, setNadoLivrePiscina] = useState(false);
  const [bookedDays, setBookedDays] = useState([]);
  const [horasDisponiveis, setHorasDisponiveis] = useState([]);
  const [verificaPackUtilizador, setVerificaPackUtilizador] = useState(false);
  const [packs, setPacks] = useState([]);
  //Se o utilizador é filho ou não
  const [filho, setFilho] = useState(false);
  //Pack escolhido
  const [packEscolhido, setPackEscolhido] = useState(0);
  //Lista packs do utilizador
  const [listaPacks, setListaPacks] = useState([]);
  //Pack do utilizador escolhido
  const [packUtilizadorEscolhido, setPackUtilizadorEscolhido] = useState(0);
  //Variável para fazer loading para reencaminhas para outra página
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  //Dados da compra
  const [compraInscricao, setCompraInscricao] = useState(0);
  const [compraPreco, setCompraPreco] = useState(0);
  const [compraTotal, setCompraTotal] = useState(0);
  //Dados multibanco
  const [entidade, setEntidade] = useState("");
  const [referencia, setReferencia] = useState("");
  const [dataExpira, setDataExpira] = useState("");
  const [pagar, setPagar] = useState(false);

  //MbWay await
  const [mbwayAwait, setMbwayAwait] = useState(false);

  //Marcação com método de pagamento pack
  const [marcacaoConcluida, setMarcacaoConcluida] = useState(false);
  const [marcacaoConcluida2, setMarcacaoConcluida2] = useState(false);

  //Modalidades da piscina
  const [modalidadesAulas, setModalidadesAulas] = useState([]);
  //Modalidade escolhida
  const [modalidadeEscolhida, setModalidadeEscolhida] = useState();
  //Turmas
  const [turmas, setTurmas] = useState([]);
  //Turma escolhida
  const [turmaEscolhida, setTurmaEscolhida] = useState(0);
  //Precos
  const [anual, setAnual] = useState(false);
  const [mensal, setMensal] = useState(false);
  const [desconto, setDesconto] = useState(0);
  const [pagamentoCancelado, setPagamentoCancelado] = useState(false);

  const [idDesconto, setIdDesconto] = useState(0);
  const [descricaoDesconto, setDescricaoDesconto] = useState("");
  const [valorDesconto, setValorDesconto] = useState(0);
  const [openPagamentoAulas, setOpenPagamentoAulas] = useState(false);

  const [cancelarPagamento, setCancelarPagamento] = useState(false);

  const handleCloseCancelarPagamento = () => {
    setCancelarPagamento(false);
  };
  const handleClickOpenCancelarPagamento = () => {
    setCancelarPagamento(true);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getPacks(idPiscina) {
    let filho = idu == idUtilizadorEscolhido ? 0 : idUtilizadorEscolhido;

    getPacksPiscina({ id_piscina: idPiscina, id_filho: filho }).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setPacks(dados);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  const handleClosePagamentoAulas = () => {
    setOpenPagamentoAulas(false);
  };
  const handleClickOpenPagamentoAulas = () => {
    getPacks(idEscolhida);
    setOpenPagamentoAulas(true);
  };
  //Variável popup balcão
  const [popupBalcao, setPopupBalcao] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    setTimeout(() => {
      verificarPagamentosEmDia().then((res) => {
        if (res.data.success) {
          navigate("/dashboard_customer");
        }
      });
    }, 500);
  }, []);
  useEffect(() => {
    if (!mbwayAwait && pagar) {
      const redirectTimeout = setTimeout(() => {
        let utilizador = Cookies.get("utilizador");
        if (utilizador == "superadmin") {
          navigate("/dashboard_superadmin");
        } else if (utilizador == "supercomercial") {
          navigate("/dashboard_supercommercial");
        } else if (utilizador == "comercial") {
          navigate("/dashboard_commercial");
        } else if (utilizador == "admin") {
          navigate("/dashboard_admin");
        } else if (utilizador == "funcionario") {
          navigate("/dashboard_employee");
        } else if (utilizador == "professor") {
          navigate("/dashboard_teacher");
        } else if (utilizador == "cliente") {
          navigate("/dashboard_customer");
        } else {
          navigate("/dashboard_customer");
        }
      }, 10000); // tempo limite em milissegundos
      return () => clearTimeout(redirectTimeout);
    }
  }, [mbwayAwait]);

  const handleDayClick = (day) => {
    bookedDays.some(function (d) {
      if (d.getTime() === day.getTime()) {
        setSelectedDay(day);
      }
    });

    //setHoraEscolhida(0)
  };

  const theme = useTheme();

  const footer = selectedDay
    ? `${t("escolheuDia")} ${
        selectedDay.getDate() < 10
          ? "0" + selectedDay.getDate()
          : selectedDay.getDate()
      }/${
        selectedDay.getMonth() + 1 < 10
          ? "0" + (selectedDay.getMonth() + 1)
          : selectedDay.getMonth() + 1
      }/${selectedDay.getFullYear()}`
    : t("escolhaDia");

  const [activeStep, setActiveStep] = useState(0);
  const [activeStepPagarAulas, setActiveStepPagarAulas] = useState(0);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;

    // Verificacoes
    const id = Cookies.get("uId");
    setIdu(id);

    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getInscricoesId(id).then((res2) => {
      if (res2.success) {
        let arrayFinal2 = [];
        res2.data.data.forEach((element2) => {
          arrayFinal2.push(element2.id_piscina);
          setInscritas(arrayFinal2);
        });

        setTimeout(async function () {
          getPiscinas().then((res) => {
            if (res.success) {
              let arrayFinal = [];
              res.data.data.forEach((element) => {
                if (element.status == 1) {
                  if (arrayFinal2.includes(element.id_piscina_edificio)) {
                    const linha = {
                      id: element.id_piscina_edificio,
                      nome: element.nome,
                      cidade: element.cidade,
                      morada: element.morada,
                      img: element.foto_perfil,
                    };
                    arrayFinal.push(linha);
                  }
                }
              });
              setPiscinas(arrayFinal);
              if (idPiscina) {
                setIdEscolhida(idPiscina);
              } else {
                if (arrayFinal2.length == 1) {
                  setIdEscolhida(arrayFinal[0].id);
                }
              }
            } else {
              toast.error(res.error);
              setSemDados(true);
            }
          });
        }, 500);
      } else {
        toast.error(res2.error);
      }
    });

    getFilhos({ id: id, idPiscina: idPiscina }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_filho,
            filho: element.id_filho ? 1 : 0,
            nome: element.nome,
            nif: element.nif,
            img: element.foto,
            data_nascimento: element.data_nascimento_final,
          };

          arrayFinal.push(linha);
        });
        setTimeout(async function () {
          getUtilizadoresId(idu, {})
            .then((res) => {
              if (res.success) {
                let dados = res.data.data;
                const linha = {
                  id: dados.id_utilizador,
                  filho: dados.filho ? 1 : 0,
                  nome: dados.nome,
                  nif: dados.nif,
                  img: dados.foto,
                  data_nascimento: dados.data_nascimento,
                };

                arrayFinal.push(linha);
                setUsers(arrayFinal);

                setSemDados(false);
                if (arrayFinal.length == 1) {
                  setIdUtilizadorEscolhido(arrayFinal[0].id);

                  if (arrayFinal[0].filho === 1) {
                    setFilho(true);
                  } else {
                    setFilho(false);
                  }
                }
              } else {
                if (res.status == 500) {
                  navigate("/500");
                }
                toast.error(res.error);
              }
            })
            .catch((error) => {
              //toast.warn(t("erroWebservice"));
            });
        }, 1000);
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });

    document.addEventListener("click", scrollToTop);
    return () => {
      document.removeEventListener("click", scrollToTop);
    };
  }, [idEscolhida]);

  useEffect(() => {
    if (idEscolhida && idUtilizadorEscolhido && tipoEscolhido == true) {
      handleNext();
      // } else if (tipoEscolhido == true && !idEscolhida) {
      //   toast.error(t("escolhaumapiscina"));
      // } else if (tipoEscolhido == true && !idUtilizadorEscolhido) {
      //   toast.error(t("escolhaumutilizador"));
      //   // }else if(tipoEscolhido == false && idUtilizadorEscolhido && idEscolhida){
      //   toast.error(t("escolhatipoaula"));
    }
  }, [idUtilizadorEscolhido, tipoEscolhido, idEscolhida, tipoEscolhido]);

  useEffect(() => {}, [activeStepPagarAulas]);
  useEffect(() => {}, [listaPacks,tenhopack]);

  useEffect(() => {
    if (selectedDay) {
      let year = selectedDay.getFullYear();
      let month =
        selectedDay.getMonth() + 1 < 10
          ? "0" + (selectedDay.getMonth() + 1)
          : selectedDay.getMonth() + 1;
      let day =
        selectedDay.getDate() < 10
          ? "0" + selectedDay.getDate()
          : selectedDay.getDate();
      let dataFinal = `${day}/${month}/${year}`;

      getHorasBanhosLivres({
        id_piscina: idEscolhida,
        data: dataFinal,
      }).then((res) => {
        if (res.success) {
          let dados = res.data.data;

          dados.sort((a, b) => {
            const horaInicioA = a.horaInicio;
            const horaInicioB = b.horaInicio;

            if (horaInicioA < horaInicioB) {
              return -1;
            }
            if (horaInicioA > horaInicioB) {
              return 1;
            }

            return 0;
          });

          setHorasDisponiveis(dados);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (horaEscolhida) {
      handleNext();
    }
  }, [horaEscolhida]);

  useEffect(() => {}, [turmaEscolhida]);

  useEffect(() => {}, [packEscolhido]);
  useEffect(() => {}, [listaPacks]);
  useEffect(() => {}, [idEscolhida]);

  useEffect(() => {}, [packUtilizadorEscolhido]);

  function getModalidadespiscinas(idPiscina) {
    let categoriasTurma = [];
    let categoriasNadoLivre = [];
    getCategoriaTurmas({ id_piscina: idPiscina }).then((res) => {
      if (res.success) {
        categoriasTurma = res.data.data;
      }
    });
    getCategoriaNadoLivre({ id_piscina: idPiscina }).then((res) => {
      if (res.success) {
        categoriasNadoLivre = res.data.data;
      }
    });
    let utilizadorEscolhido = filho
      ? users.find(
          (element) => element.filho == 1 && element.id == idUtilizadorEscolhido
        )
      : users.find(
          (element) => element.filho == 0 && element.id == idUtilizadorEscolhido
        );

    let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

    getModalidadesAulasPiscina({
      id_piscina: idPiscina,
      id_filho: id_filho,
    }).then((res) => {
      setModalidadesAulas(res.data.data);
    });

    getModalidadesPiscina({ id_piscina: idPiscina }).then(async (res) => {
      if (res.success) {
        let dados = res.data.data;

        let utilizadorEscolhido = filho
          ? users.find(
              (element) =>
                element.filho == 1 && element.id == idUtilizadorEscolhido
            )
          : users.find(
              (element) =>
                element.filho == 0 && element.id == idUtilizadorEscolhido
            );

        //Saber qual a categoria do utilizador
        let categoriaUtilizador;
        let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

        let result = await getCategoriaUtilizador({ id_filho: id_filho });
        if (result.success) {
          categoriaUtilizador = result.data.data;
        }

        if (
          dados.aulas_adultos ||
          dados.aulas_bebes ||
          dados.aulas_gravidas ||
          dados.aulas_idoso ||
          dados.hidro_terapia ||
          dados.hidrobike ||
          dados.hidroginastica ||
          dados.escola_natacao ||
          dados.hidropower
        ) {
          if (
            categoriasTurma.find((element) => element.id == categoriaUtilizador)
          ) {
            setAulasPiscina(true);
          } else {
            setAulasPiscina(false);
          }
        }

        if (dados.nado_livre) {
          if (
            categoriasNadoLivre.find(
              (element) => element.id == categoriaUtilizador
            )
          ) {
            setNadoLivrePiscina(true);
          } else {
            setNadoLivrePiscina(false);
          }
        }
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  function getDatasLivresNadoLivre(idPiscina) {
    getDatasBanhosLivres({ id_piscina: idPiscina }).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        let arrayFinal = [];
        dados.forEach((element) => {
          arrayFinal.push(new Date(element.year, element.month, element.day));
        });
        setBookedDays(arrayFinal);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  function getVerificaPackUtilizador(idPiscina) {
    let filho = idu == idUtilizadorEscolhido ? 0 : idUtilizadorEscolhido;
    getPackUtilizador({ id_piscina: idPiscina }).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setVerificaPackUtilizador(dados);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  const handleNext = async (modalidade) => {
    if (activeStep == 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      getModalidadespiscinas(idEscolhida);
      getDatasLivresNadoLivre(idEscolhida);
    } else if (activeStep == 1) {
      //Saber qual a categoria do utilizador
      getListaPacks({
        id_piscina: idEscolhida,
      }).then((res) => {
        if (res.success) {
          
          const pacotesFiltrados = res.data.data.filter((pack) => {
            return pack.num_tickets > pack.num_tickets_utilizados; // Adicione a instrução 'return'
          });
          setListaPacks(pacotesFiltrados);
        }
      });
      let categoriaUtilizador;
      let utilizadorEscolhido = filho
        ? users.find(
            (element) =>
              element.filho == 1 && element.id == idUtilizadorEscolhido
          )
        : users.find(
            (element) =>
              element.filho == 0 && element.id == idUtilizadorEscolhido
          );

      let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

      let result = await getCategoriaUtilizador({ id_filho: id_filho });
      if (result.success) {
        categoriaUtilizador = result.data.data;

        // Get Turmas
        getTurmas({
          id_filho: id_filho,
          id_piscina: idEscolhida,
          categoria: categoriaUtilizador,
          modalidade: modalidade,
        }).then((res) => {
          if (res.success) {
            setTurmas(res.data.data);
          }
        });
      }

      setActiveStep(2);
    } else if (activeStep == 2) {
    }
  };

  const handleNext2PagarAulas = async () => {
    //Packs
    // getInfoCompra({
    //   id_pack: packEscolhido,
    //   id_piscina: idEscolhida,
    //   id_filho: filho ? idUtilizadorEscolhido : 0,
    // }).then((res) => {
    //   if (res.success) {
    //     const linha = res.data.data;
    //     setCompraInscricao(linha.inscricao);
    //     setCompraPreco(linha.pack);
    //     setCompraTotal(linha.total);
    //     setDesconto(linha.desconto);
    //     setIdDesconto(linha.id_desconto);
    //     setDescricaoDesconto(linha.descricao);
    //     setValorDesconto(linha.valorDesconto.replace(".", ","));
    //   }
    // });
    setActiveStepPagarAulas(
      (prevActiveStepPagarAulas) => prevActiveStepPagarAulas + 2
    );
  };

  const handleNextPagarAulas = async () => {
    if (activeStepPagarAulas == 0) {
      setActiveStepPagarAulas(
        (prevActiveStepPagarAulas) => prevActiveStepPagarAulas + 1
      );
    } else if (activeStepPagarAulas == 1) {
      //Aulas
      if (aulas == true) {
        getInfoCompra2({
          id_turma: turmaEscolhida,
          id_piscina: idEscolhida,
          id_filho: filho ? idUtilizadorEscolhido : 0,
          id_preco: anual ? 1 : mensal ? 2 : 0,
        }).then((res) => {
          if (res.success) {
            const linha = res.data.data;
            setCompraInscricao(linha.inscricao);
            setCompraPreco(anual ? linha.precoAnual : linha.precoMensal);
            setCompraTotal(linha.total);
            setDesconto(linha.desconto);
            setIdDesconto(linha.id_desconto);
            setDescricaoDesconto(linha.descricao);
            setValorDesconto(linha.valorDesconto.replace(".", ","));
          }
        });
      } else {
        //Packs
        getInfoCompra({
          id_pack: packEscolhido,
          id_piscina: idEscolhida,
          id_filho: filho ? idUtilizadorEscolhido : 0,
        }).then((res) => {
          if (res.success) {
            const linha = res.data.data;
            setCompraInscricao(linha.inscricao);
            setCompraPreco(linha.pack);
            setCompraTotal(linha.total);
            setDesconto(linha.desconto);
            setIdDesconto(linha.id_desconto);
            setDescricaoDesconto(linha.descricao);
            setValorDesconto(linha.valorDesconto.replace(".", ","));
          }
        });
      }

      setActiveStepPagarAulas(
        (prevActiveStepPagarAulas) => prevActiveStepPagarAulas + 1
      );
    } else if (activeStepPagarAulas == 2) {
    } else if (activeStepPagarAulas == 3) {
    } else if (activeStepPagarAulas == 4) {
    } else if (activeStepPagarAulas == 5) {
    } else if (activeStepPagarAulas == 6) {
    }
  };

  const handleBack = () => {
    if (activeStep == 1) {
      window.location.reload();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackPagarAulas = () => {
    setActiveStepPagarAulas(
      (prevActiveStepPagarAulas) => prevActiveStepPagarAulas - 1
    );
  };

  function goBack() {
    if (activeStep == 0) {
      navigate("/dashboard_customer");
    }
    if (activeStep == 1) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      setAulas(false);
      setIdEscolhida("");
      setIdUtilizadorEscolhido("");
      setTipoEscolhido(false);
      setAulaEscolhido(false);
      setSelectedDay("");
      handleBack();
    }
    if (activeStep == 2) {
      setCartaoCredito(false);
      setMultibanco(false);
      setAnual(false);
      setMensal(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      handleBack();
    }

    if (activeStep == 3) {
      setAnual(false);
      setMensal(false);
      setAulas(false);
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      setTipoEscolhido(false);
      setAulaEscolhido(false);

      setTenhoPack(false);
      handleBack();
    }
  }

  function goBackPagarAulas() {
    if (activeStepPagarAulas == 0) {
    }
    if (activeStepPagarAulas == 1) {
      setAnual(false);
      setPackEscolhido("");
      setPackUtilizadorEscolhido("");

      handleBackPagarAulas();
    }
    if (activeStepPagarAulas == 2) {
      if (tenhopack == true) {
        setActiveStepPagarAulas(
          (prevActiveStepPagarAulas) => prevActiveStepPagarAulas - 2
        );
        setTenhoPack(false);
      } else {
        setCartaoCredito(false);
        setMultibanco(false);
        setMbway(false);
        setTenhoPack(false);
        setBalcao(false);
        setDesconto(0);
        setDescricaoDesconto("");
        setIdDesconto(0);
        setCompraTotal(0);
        setCompraPreco(0);
        handleBackPagarAulas();
      }
    }

    if (activeStepPagarAulas == 3) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setTenhoPack(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);

      handleBackPagarAulas();
    }
    if (activeStepPagarAulas == 4) {
    }
    if (activeStepPagarAulas == 5) {
    }
  }

  useEffect(() => {
    if (activeStep == 3) {
      const timeout = setTimeout(() => {
        setActiveStep(0);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [activeStep]);

  const steps = [
    {
      description: (
        <div id="1" style={{ height: "100%" }}>
          <div className="divTabelaMarcar2">
            <h3 className="titleMarcar">{t("escolherpiscina")}</h3>
            {piscinas.length == 0 && (
              <div className="cardsDivMarcar">
                <Button
                  className="cardDashboardMarcar2"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onMouseEnter={() => {
                    // Add CSS styles for the hover effect when the mouse enters
                    const button = document.querySelector(
                      ".cardDashboardMarcar2"
                    );
                    button.style.transform = "scale(1.05)";
                    button.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
                  }}
                  onMouseLeave={() => {
                    // Reset the CSS styles when the mouse leaves
                    const button = document.querySelector(
                      ".cardDashboardMarcar2"
                    );
                    button.style.transform = "scale(1)";
                    button.style.boxShadow = "none";
                  }}
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  <div style={{ display: "block" }}>
                    <FontAwesomeIcon className="iconCards" icon={faFaceFrown} />
                  </div>
                  <span
                    style={{
                      marginTop: "8px",
                      fontSize: isMobile ? "10px" : "14px",
                    }}
                  >
                    {t("erroMarcar")}
                  </span>
                </Button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                width: "100%",
                justifyContent: piscinas.length < 4 ? "center" : "flex-start",
                marginBottom: 30,
              }}
            >
              {piscinas.map((element) => (
                <>
                  {idEscolhida != element.id && idEscolhida != "" ? (
                    <Card
                      onClick={() => {
                        setIdEscolhida(element.id);
                      }}
                      sx={{
                        backgroundImage: `url(data:image/png;base64,${element.img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        flexShrink: 0,
                        borderRadius: "10px",
                        backgroundImage: `url(${element.img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: isMobile ? "26vw" : "18vw",
                        height: "20vh",
                        marginTop: "auto",
                        marginBottom: "auto",
                        transition: "all 0.5s linear",
                      }}
                    >
                      <CardContent
                        className="cardPiscinasMarcarNotSelected"
                        sx={{
                          cursor: "pointer",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          position: "relative",
                          zIndex: 2,
                        }}
                      ></CardContent>
                    </Card>
                  ) : (
                    <Card
                      onClick={() => {
                        setIdEscolhida(element.id);
                      }}
                      sx={{
                        backgroundImage: `url(data:image/png;base64,${element.img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        flexShrink: 0,
                        borderRadius: "10px",
                        backgroundImage: `url(${element.img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: isMobile ? "26vw" : "18vw",
                        height: "20vh",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        marginInline: 2.5,
                      }}
                    >
                      <CardContent
                        className="cardPiscinasMarcar"
                        sx={{
                          cursor: "pointer",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          position: "relative",
                          zIndex: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="div"
                          sx={{
                            fontSize: isMobile
                              ? "8px !important"
                              : "12px !important",
                            fontWeight: "bold",
                            textAlign: "center",
                            marginTop: "60px",
                            color: "white",
                            position: "relative",
                          }}
                        >
                          {element.nome}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: isMobile
                              ? "8px !important"
                              : "12px !important",
                            textAlign: "center",
                            color: "white",
                            fontWeight: 100,
                            textAlign: "center",
                            marginTop: "5px",
                            color: "white",
                            position: "relative",
                          }}
                        >
                          {element.cidade}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                </>
              ))}
            </div>
            {piscinas.length == 0 ? null : (
              <h3 className="titleMarcar">{t("escolherutilizador")}</h3>
            )}

            <div
              style={{
                display: "flex",
                overflowX: "auto",
                width: "100%",
                justifyContent:
                  users.length < 4
                    ? isMobile
                      ? "flex-start"
                      : "center"
                    : "flex-start",
                marginBottom: 30,
              }}
            >
              {piscinas.length == 0 ? null : (
                <>
                  {users.map((element) => (
                    <>
                      {idUtilizadorEscolhido != element.id &&
                      idUtilizadorEscolhido != "" ? (
                        <Card
                          key={element.id}
                          onClick={() => {
                            setIdUtilizadorEscolhido(element.id);

                            if (element.filho === 1) {
                              setFilho(true);
                            } else {
                              setFilho(false);
                            }
                          }}
                          className="cardUserNotSelected"
                          sx={{
                            flexShrink: 0,
                            marginInline: 2.5,
                            marginTop: "auto",
                            marginBottom: "auto",
                            // width: isMobile? "28vw" :"18vw",
                            height: "9vh",
                          }}
                        >
                          <CardContent
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                marginLeft: 10,
                                marginTop: 10,
                              }}
                            >
                              <Avatar
                                src={element.img}
                                sx={{
                                  width: isMobile ? 25 : 30,
                                  height: isMobile ? 25 : 30,
                                  border: "1px solid white",
                                }}
                              />
                              <div
                                style={{
                                  flexDirection: "column",
                                  marginInline: 10,
                                }}
                              >
                                <Typography
                                  component="div"
                                  color="#171E24"
                                  style={{
                                    opacity: "40%",
                                    fontSize: isMobile ? "12px" : "14px",
                                  }}
                                >
                                  {element.nome}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="#171E24CC"
                                  style={{
                                    opacity: "40%",
                                    fontSize: isMobile ? "10px" : "12px",
                                  }}
                                  component="div"
                                >
                                  {element.nif}
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ) : (
                        <Card
                          key={element.id}
                          onClick={() => {
                            setIdUtilizadorEscolhido(element.id);

                            if (element.filho === 1) {
                              setFilho(true);
                            } else {
                              setFilho(false);
                            }
                          }}
                          className="cardUser"
                          sx={{
                            flexShrink: 0,
                            marginInline: 2.5,
                            marginTop: "auto",
                            marginBottom: "auto",
                            // width: isMobile? "30vw" :"20vw",
                            height: "10vh",
                          }}
                        >
                          <CardContent
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                marginInline: 5,
                                marginTop: 10,
                              }}
                            >
                              <Avatar
                                src={element.img}
                                sx={{
                                  width: isMobile ? 25 : 30,
                                  height: isMobile ? 25 : 30,
                                  border: "1px solid white",
                                }}
                              />
                              <div
                                style={{
                                  flexDirection: "column",
                                  marginInline: 10,
                                }}
                              >
                                <Typography component="div" color="#0A56FA">
                                  {element.nome}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="#1e2327"
                                  component="div"
                                >
                                  {element.nif}
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
            {piscinas.length == 0 ? null : (
              <h3 className="titleMarcar" style={{ marginTop: 10 }}>
                {t("escolhertipoaula")}
              </h3>
            )}

            <div
              style={{
                display: "flex",
                overflowX: "auto",
                justifyContent: "center",
              }}
            >
              {piscinas.length == 0 ? null : (
                <>
                  {aulas === false && tipoEscolhido === true ? (
                    <Button
                      className="cardAulasNotSelected"
                      style={{
                        width: isMobile ? "28vw" : "18vw",
                        height: "9vh",
                        marginRight: 35,
                        marginLeft: "auto",
                        marginTop: "auto",
                        marginBottom: "auto",
                        transition: "all 0.5s ease-in-out",
                      }}
                      onClick={() => {
                        setAulas(true);
                        setTipoEscolhido(true);
                        setAulaEscolhido(false);
                      }}
                    >
                      <span
                        style={{
                          width: 19,
                          height: 19,
                          marginRight: isMobile ? 5 : 20,
                          backgroundImage: `url(${graduationIconBlack})`,
                        }}
                      />
                      {t("aulas")}
                    </Button>
                  ) : (
                    <Button
                      className="cardAulas"
                      style={{
                        width: isMobile ? "30vw" : "20vw",
                        height: "10vh",
                        marginRight: 35,
                        marginLeft: "auto",
                        marginTop: "auto",
                        marginBottom: "auto",
                        transition: "all 0.5s ease-in-out",
                      }}
                      onClick={() => {
                        setAulas(true);
                        setTipoEscolhido(true);
                        setAulaEscolhido(false);
                      }}
                    >
                      <span
                        style={{
                          width: 24,
                          height: 19,
                          marginRight: isMobile ? 5 : 20,
                          backgroundImage: `url(${graduationIcon})`,
                        }}
                      />
                      {t("aulas")}
                    </Button>
                  )}

                  {aulaEscolhido === false && tipoEscolhido === true ? (
                    <Button
                      className="cardAulasNotSelected2"
                      style={{
                        width: isMobile ? "28vw" : "18vw",
                        height: "9vh",
                        marginRight: "auto",
                        marginLeft: 10,
                        marginTop: "auto",
                        marginBottom: "auto",
                        transition: "all 0.5s ease-in-out",
                      }}
                      onClick={() => {
                        setTipoEscolhido(true);
                        setAulaEscolhido(true);
                        setAulas(false);
                      }}
                    >
                      <span
                        style={{
                          width: 19,
                          height: 19,
                          marginRight: isMobile ? 5 : 20,
                          backgroundImage: `url(${swimIconBlack})`,
                        }}
                      />
                      {t("banhosLivres")}
                    </Button>
                  ) : (
                    <Button
                      className="cardBanhos"
                      style={{
                        width: isMobile ? "30vw" : "20vw",
                        height: "10vh",
                        marginRight: "auto",
                        marginLeft: 18,
                        marginTop: "auto",
                        marginBottom: "auto",
                        transition: "all 0.5s ease-in-out",
                      }}
                      onClick={() => {
                        setTipoEscolhido(true);
                        setAulaEscolhido(true);
                        setAulas(false);
                      }}
                    >
                      <span
                        style={{
                          width: 22,
                          height: 21,
                          marginRight: isMobile ? 5 : 20,
                          backgroundImage: `url(${swimIcon})`,
                        }}
                      />
                      {t("banhosLivres")}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="2">
          <div className="topleftmarcar">
            <IconButton onClick={goBack} size={"small"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>

          {aulas === true ? (
            <div
              className="divTabelaMarcar2"
              style={{ height: "100% !important" }}
            >
              <h3 className="titleMarcar" style={{ marginTop: "10%" }}>
                {t("escolhermodalidade")}
              </h3>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  paddingTop: 5,

                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {modalidadesAulas.map((element) => (
                  <Card
                    onClick={() => {
                      setModalidadeEscolhida(element.id);
                      handleNext(element.id);
                    }}
                    className="btnsMarcar"
                    sx={{
                      margin: "3px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1,
                        marginTop: 2,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography component="div" color="#0A56FA">
                          {element.nome}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
              </Grid>

              {modalidadesAulas.length == 0 && (
                <Button
                  className="cardDashboardMarcar4"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.inativo,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "50%",
                  }}
                  disabled
                >
                  <div style={{ display: "block" }}>
                    <FontAwesomeIcon className="iconCards" icon={faFaceFrown} />
                  </div>
                  <span
                    style={{
                      marginTop: "8px",
                      fontSize: isMobile ? "10px" : "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("indisponibilidadeMarcacoes")}
                  </span>
                </Button>
              )}
            </div>
          ) : (
            <div className="divTabelaMarcar">
              {!isMobile ? (
                <div
                  className="divTabelaMarcar2"
                  style={{ height: "40% !important", overflowY: "auto" }}
                >
                  <h3 className="titleMarcar">{t("escolherespaco")}</h3>

                  <Stack direction={"row"} spacing={30}>
                    <DayPicker
                      className="DayPicker"
                      mode="single"
                      required
                      selected={selectedDay}
                      // onSelect={setSelectedDay}
                      onDayClick={handleDayClick}
                      styles={{
                        container: {
                          maxWidth: "400px",
                          margin: "0 auto",
                          fontFamily: "Arial, sans-serif",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                        },
                        caption: {
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          color: "#1976d2",
                        },
                        day: {
                          fontSize: "18px",
                          color: "#444444",
                        },
                        ".DayPicker-Weekday": {
                          fontSize: "14px",
                          color: "#666666",
                          textTransform: "uppercase",
                        },
                        ".DayPicker-Day--booked": {
                          backgroundColor: "#eeeeee",
                        },
                        ".DayPicker-Day--selected:not(.DayPicker-Day--booked)":
                          {
                            backgroundColor: "#1976d2",
                            color: "#ffffff",
                            fontWeight: "bold",
                          },
                        ".DayPicker-Day--booked:not(.DayPicker-Day--selected)":
                          {
                            color: "#999999",
                          },
                        ".DayPicker-Footer": {
                          fontSize: "14px",
                          marginTop: "10px",
                        },
                        ".DayPicker-NavButton--prev, .DayPicker-NavButton--next":
                          {
                            fontSize: "20px",
                            color: "#1976d2",
                            outline: "none",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "calc(50% - 10px)",
                            zIndex: "2",
                          },
                        ".DayPicker-NavButton--prev": {
                          left: "10px",
                        },
                        ".DayPicker-NavButton--next": {
                          right: "10px",
                        },
                      }}
                      modifiers={{
                        booked: bookedDays,
                      }}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: "#1976d2",
                          color: "white",
                        },
                        booked: { border: "2px solid #1976d2" },
                      }}
                      locale={
                        i18n.language == "pt"
                          ? pt2
                          : i18n.language == "es"
                          ? es2
                          : en2
                      }
                      footer={footer}
                    />
                    <div className="gridHoras">
                      <h2 className="dashboardTitle2">
                        {t("horasDisponiveis")}
                      </h2>

                      <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 3, sm: 6, md: 9 }}
                        sx={{ marginTop: "30px !important" }}
                      >
                        {horasDisponiveis.map((element, index) => (
                          <Grid
                            item
                            xs={3}
                            sm={6}
                            md={9}
                            key={index}
                            style={{ marginLeft: 5 }}
                          >
                            <Button
                              className="btnHoras"
                              onClick={async () => {
                                if (horaEscolhida == index && selectedDay) {
                                  if (!aulas) {
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    const data = new Date(selectedDay);
                                    const dia = data
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");
                                    const mes = (data.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0");
                                    const ano = data.getFullYear();
                                    const dataFormatada = `${dia}/${mes}/${ano}`;

                                    let verificacaoSobreposicao;
                                    try {
                                      const res =
                                        await getVerificacaoSobreposicao({
                                          id_filho: id_filho,
                                          id_piscina: idEscolhida,
                                          data: dataFormatada,
                                          horaInicio:
                                            horasDisponiveis[horaEscolhida]
                                              .horaInicio,
                                          horaFim:
                                            horasDisponiveis[horaEscolhida]
                                              .horaFim,
                                        });

                                      if (res.success) {
                                        verificacaoSobreposicao =
                                          res.data.verificacao;
                                      }
                                    } catch (error) {
                                      verificacaoSobreposicao = true;
                                    }

                                    if (!verificacaoSobreposicao) {
                                      toast.error(t("sobreposicaoMarcacoes"));
                                    } else {
                                      getVerificaPackUtilizador(idEscolhida);
                                      getPacks(idEscolhida);
                                      //   getListaPacks({
                                      //     id_piscina: idEscolhida,
                                      //   }).then((res) => {
                                      //     console.log(res)
                                      //     if (res.success) {

                                      //       setListaPacks(res.data.data);
                                      //     }
                                      //   });
                                      //   handleClickOpenPagamentoAulas();
                                    }
                                  } else {
                                    let utilizadorEscolhido = filho
                                      ? users.find(
                                          (element) =>
                                            element.filho == 1 &&
                                            element.id == idUtilizadorEscolhido
                                        )
                                      : users.find(
                                          (element) =>
                                            element.filho == 0 &&
                                            element.id == idUtilizadorEscolhido
                                        );

                                    verificarInscricaoPagamentoPendente({
                                      id_turma: turmaEscolhida,
                                      id_filho: utilizadorEscolhido.filho
                                        ? utilizadorEscolhido.id
                                        : 0,
                                    }).then((res) => {
                                      if (res.status == 500) {
                                        toast.error(t("inscricaoAtiva"));
                                      } else {
                                        setActiveStep(
                                          (prevActiveStep) => prevActiveStep + 1
                                        );
                                      }
                                    });

                                    //Saber qual a categoria do utilizador
                                    let categoriaUtilizador;
                                    let id_filho = utilizadorEscolhido.filho
                                      ? utilizadorEscolhido.id
                                      : 0;
                                    let result = await getCategoriaUtilizador({
                                      id_filho: id_filho,
                                    });
                                    if (result.success) {
                                      categoriaUtilizador = result.data.data;
                                    }
                                    // Get Turmas
                                    await getTurmas({
                                      id_filho: id_filho,
                                      id_piscina: idEscolhida,
                                      categoria: categoriaUtilizador,
                                      modalidade: modalidadeEscolhida,
                                    }).then((res) => {
                                      setTurmas(res.data.data);
                                    });
                                  }
                                } else {
                                  setHoraEscolhida(index);
                                  handleClickOpenPagamentoAulas();
                                }
                              }}
                            >
                              {element.horaInicio}
                              <br /> {element.horaFim}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Stack>
                </div>
              ) : (
                <div className="divTabelaMarcar2" style={{ overflowY: "auto" }}>
                  <h3 className="titleMarcar">{t("escolherespaco")}</h3>
                  <Scrollbar style={{ height: 500 }}>
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <DayPicker
                        className="DayPicker2"
                        mode="single"
                        required
                        selected={selectedDay}
                        // onSelect={setSelectedDay}
                        onDayClick={handleDayClick}
                        styles={{
                          container: {
                            fontFamily: "Arial, sans-serif",
                            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                            backgroundColor: "#ffffff",
                          },
                          caption: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginBottom: "10px",
                            color: "#1976d2",
                          },
                          day: {
                            fontSize: "12px",
                            color: "#444444",
                            width: window.innerWidth / 13,
                          },
                          month: {
                            flex: "1 0 30%",
                            padding: "0 10px",
                          },
                          months: {
                            flexWrap: "wrap",
                            margin: "0 5px",
                          },
                          booked: {
                            backgroundColor: "#eeeeee",
                          },
                          ".DayPicker-Weekday": {
                            fontSize: "10px !important",
                            color: "#666666",
                            textTransform: "uppercase",
                          },

                          ".DayPicker-Day--selected:not(.DayPicker-Day--booked)":
                            {
                              backgroundColor: "#1976d2",
                              color: "#ffffff",
                              fontWeight: "bold",
                            },
                          ".DayPicker-Day--booked:not(.DayPicker-Day--selected)":
                            {
                              color: "#999999",
                            },
                          ".DayPicker-Footer": {
                            fontSize: "12px",
                            marginTop: "10px",
                          },
                          ".DayPicker-NavButton--prev, .DayPicker-NavButton--next":
                            {
                              fontSize: "16px",
                              color: "#1976d2",
                              outline: "none",
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              position: "absolute",
                              top: "calc(50% - 10px)",
                              zIndex: "2",
                            },
                          ".DayPicker-NavButton--prev": {
                            left: "5px !important",
                          },
                          ".DayPicker-NavButton--next": {
                            right: "5px",
                          },

                          ".DayPicker-WeekdaysRow": {
                            display: "flex",
                            marginTop: "10px",
                            marginBottom: "10px",
                          },
                          ".DayPicker-Weekday": {
                            width: "calc(100% / 7)",
                            padding: "0",
                          },
                          ".DayPicker-Weekday abbr[title]": {
                            border: "none",
                            fontSize: "12px",
                            color: "#9e9e9e",
                          },
                        }}
                        modifiers={{
                          booked: bookedDays,
                        }}
                        modifiersStyles={{
                          selected: {
                            backgroundColor: "#1976d2",
                            color: "white",
                          },
                          booked: { border: "2px solid #1976d2" },
                        }}
                        locale={
                          i18n.language == "pt"
                            ? pt2
                            : i18n.language == "es"
                            ? es2
                            : en2
                        }
                        footer={footer}
                      />
                      <div className="gridHoras2">
                        <h2 className="dashboardTitle2">
                          {t("horasDisponiveis")}
                        </h2>
                        <Grid
                          container
                          spacing={{ xs: 1, md: 1 }}
                          columns={{ xs: 3, sm: 6, md: 9 }}
                          sx={{ marginTop: 10 }}
                        >
                          {horasDisponiveis.map((element, index) => (
                            <Grid
                              item
                              xs={1}
                              sm={3}
                              md={6}
                              key={index}
                              style={{ marginLeft: 5 }}
                            >
                              <Button
                                className="btnHoras"
                                sx={{
                                  margin: "3px",
                                }}
                                onClick={() => {
                                  if (horaEscolhida == index && selectedDay) {
                                    handleClickOpenPagamentoAulas();
                                  } else {
                                    setHoraEscolhida(index);
                                    handleClickOpenPagamentoAulas();
                                  }
                                }}
                              >
                                {element.horaInicio}
                                <br /> {element.horaFim}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </Stack>
                  </Scrollbar>
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      description: (
        <div id="3">
          <div className="topleftmarcar">
            <IconButton onClick={goBack} size={"small"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          <div className="divTabelaMarcar">
            {aulas === true ? (
              <div className="divTabelaMarcar2">
                <h3 className="titleMarcar">{t("escolherturma")}</h3>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    paddingTop: 5,

                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {turmas.map((element) => (
                    <Card
                      onClick={() => {
                        if (
                          element.num_vagas - element.num_vagas_ocupadas !=
                          0
                        ) {
                          setTurmaEscolhida(element.id_turma);
                          handleClickOpenPagamentoAulas();
                        }
                      }}
                      className="btnsMarcar"
                      sx={{
                        margin: "3px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingInline: 1.5,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography
                            component="div"
                            sx={{
                              fontWeight: 500,
                              color: "#0A56FA",
                              fontSize: "14px",
                            }}
                          >
                            {element.nome}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{ fontWeight: 100, fontSize: "12px" }}
                          >
                            {element.obs}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{ fontSize: "12px" }}
                          >
                            {t("nVagasDisponiveis")}:{" "}
                            {element.num_vagas - element.num_vagas_ocupadas}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
                </Grid>
                {turmas.length == 0 && (
                  <Button
                    className="cardDashboardMarcar4"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.ativo,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "50%",
                    }}
                    disabled
                  >
                    <div style={{ display: "block" }}>
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faFaceSmile}
                      />
                    </div>
                    <span
                      style={{
                        marginTop: "8px",
                        fontSize: isMobile ? "10px" : "14px",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("mensagemTurmas")}
                    </span>
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="4">
          {pagamentoCancelado == true ? (
            <div className="divTabelaMarcar2">
              <div style={{ marginTop: "20px" }}>
                <img src={errorgif} alt="Error" />
              </div>
              <span style={{ paddingTop: "10px" }}>
                {t("pagamentoCanceladoText")}
              </span>
            </div>
          ) : (
            <div className="divTabelaMarcar2">
              <div style={{ marginTop: "20px" }}>
                <img src={successgif} alt="Success" />
              </div>
              <span style={{ paddingTop: "10px" }}>
                {t("pagamentoSucessoText")}
              </span>
            </div>
          )}
        </div>
      ),
    },
  ];
  const stepsPagarAulas = [
    {
      description: (
        <div id="1" style={{ height: "100%" }}>
          {aulas === true && turmaEscolhida ? (
            <div className="cardsDivPagamentoAulas">
              <Button
                className="btnsMarcar"
                variant="contained"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                onClick={() => {
                  setMensal(true);
                  handleNextPagarAulas();
                }}
              >
                <div className="divCardsPrecos">
                  <span
                    style={{
                      width: 21,
                      height: 21,
                      backgroundImage: `url(${calendarIcon})`,
                    }}
                  />

                  <span
                    style={{
                      marginLeft: "0.5rem",
                      textTransform: "capitalize",
                      color: "#0A56FA ",
                    }}
                  >
                    {t("mensal")}
                  </span>
                </div>
                <div style={{ color: "#0A56FA " }}>
                  {turmas
                    .find((element) => element.id_turma == turmaEscolhida)
                    ?.preco_mensal.toLocaleString("pt-PT", {
                      style: "currency",

                      currency: "EUR",
                    })}
                </div>
              </Button>

              <Button
                className="btnsMarcar"
                variant="contained"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setAnual(true);
                  handleNextPagarAulas();
                }}
              >
                <div className="divCardsPrecos">
                  <CalendarDaysIcon
                    style={{ width: "25px", color: "#0A56FA" }}
                  />

                  <span
                    style={{
                      marginLeft: "0.5rem",
                      textTransform: "capitalize",
                      color: "#0A56FA ",
                    }}
                  >
                    {t("anual")}
                  </span>
                </div>

                <div style={{ color: "#0A56FA " }}>
                  {turmas
                    .find((element) => element.id_turma == turmaEscolhida)
                    ?.preco_anual.toLocaleString("pt-PT", {
                      style: "currency",
                      currency: "EUR",
                    })}
                </div>
              </Button>
            </div>
          ) : (
            <div className="divResumo" style={{ flexDirection: "column",}}>
              {tenhopack == true ? (
                <div className="gridContainer" style={{marginTop:"100%"}}>
                {listaPacks.map((element) => (
                  <Card
                    key={element.id_pack_cliente} // Adicione uma chave única para cada Card
                    onClick={() => {
                      if(moment(element.data_expira).isBefore(moment(), "day")){
toast.error("packExpirado")
                      }else{
                        if (element.id_pack_cliente === packUtilizadorEscolhido) {
                          handleNext2PagarAulas();
                        }
                        setPackUtilizadorEscolhido(element.id_pack_cliente);
                        handleNext2PagarAulas();
                      }
                      
                    }}
                    className="cardPacks"
                    disabled={moment(element.data_expira).isBefore(moment(), "day")} // Desativa o Card se a data de expiração for anterior à data de hoje
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 3,
                        paddingRight: 3,
                      }}
                    >
                      <CardContent
                        sx={{ flex: "1 0 auto", cursor: "pointer" }}
                      >
                        <Typography
                          component="div"
                          style={{ fontSize: "13px" }}
                        >
                          {element.nomePack}
                          <br></br> {element.nomePiscina}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                          style={{ fontSize: "11px" }}
                        >
                          {element.nome_utilizador} ➔ {t("tickets")}:{" "}
                          {element.num_tickets_utilizados} /{" "}
                          {element.num_tickets}
                        </Typography>
                        {moment(element.data_expira).isBefore(moment(), "day") && ( // Adiciona um indicador de "expirado" se a data de expiração for anterior à data de hoje
                          <Typography
                            variant="subtitle2"
                            color="error"
                            component="div"
                            style={{ fontSize: "10px" }}
                          >
                             { t("Expirado")}
                          </Typography>
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                ))}
              </div>
              ) : (
                <div className="gridContainer">
                  {packs.length == 0 ? (
                    <Button
                      className="cardDashboardMarcar4"
                      variant="contained"
                      style={{
                        backgroundColor: Colors.inativo,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "90%",
                      }}
                      disabled
                    >
                      <div style={{ display: "block" }}>
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faFaceFrown}
                        />
                      </div>
                      <span
                        style={{
                          marginTop: "8px",
                          fontSize: isMobile ? "10px" : "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("mensagemPacksVenda")}
                      </span>
                    </Button>
                  ) : (
                    <>
                      {packs.map((element) => (
                        <Card
                          onClick={() => {
                            if (packEscolhido == element.id_pack) {
                              handleNextPagarAulas();
                            }
                            setPackEscolhido(element.id_pack);
                            handleNextPagarAulas();
                          }}
                          className="cardPacks"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",

                              width: "100%",
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {!isMobile ? (
                                <Typography
                                  component="div"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {element.nome}
                                    <span style={{ display: "flex-end" }}>
                                      {element.preco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  </div>
                                </Typography>
                              ) : (
                                <Typography
                                  component="div"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                    }}
                                  >
                                    {element.nome}
                                  </div>
                                </Typography>
                              )}
                              {!isMobile ? null : (
                                <Typography
                                  component="div"
                                  style={{ width: "100%" }}
                                >
                                  {element.preco}€
                                </Typography>
                              )}

                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                              >
                                {element.num_tickets} tickets
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                      ))}
                    </>
                  )}
                </div>
              )}
              {tenhopack == true ? (
                <Link
                  style={{ cursor: "pointer", marginTop: "20px" }}
                  onClick={() => {
                    setTenhoPack(false);
                  }}
                >
                  {t("comprarpack")}
                </Link>
              ) : (
                <div>
                  {listaPacks.length > 0 ? (
                    <div>
                      {/* {verificaPackUtilizador && ( */}
                      <Link
                        style={{ cursor: "pointer", marginTop: "20px" }}
                        onClick={() => {
                          setTenhoPack(true);
                        }}
                      >
                        {t("jatenhopack")}
                      </Link>
                      {/* )} */}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      description: (
        <div id="2">
          <Box
            sx={{
              paddingTop: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setCartaoCredito(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <CreditCardIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />
                    {t("cartaocredito")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setMultibanco(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <BuildingLibraryIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />

                    {t("multibanco")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setMbway(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <DevicePhoneMobileIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />

                    {t("mbway")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ margin: 1 }}>
                  <Button
                    className="btnsMarcar"
                    variant="contained"
                    onClick={() => {
                      setBalcao(true);
                      handleNextPagarAulas();
                    }}
                  >
                    <BanknotesIcon
                      style={{
                        width: "25px",
                        color: "#0A56FA",
                        marginRight: "10px",
                      }}
                    />

                    {t("pagaraobalcao")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      ),
    },
    {
      description: (
        <div id="3">
          {cartaocredito == true ? (
            <div className="divResumo">
              <Paper
                elevation={0}
                style={{
                  overflow: "hidden",
                  marginTop: 20,
                  backgroundColor: "transparent",
                  width: "100%",
                }}
              >
                <Dialog
                  open={loading}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>{t("msgMb")}</DialogContentText>
                    <DialogContentText
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <CircularProgress
                        disableShrink
                        style={{
                          marginTop: 20,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                <Scrollbar style={{ height: 400 }}>
                  <Card
                    className="cardDashboardResumo"
                    variant="contained"
                    disabled
                    sx={{ paddingRight: 5 }}
                  >
                    <Card
                      sx={{
                        margin: "8px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          {/* NOME PACK/TURMA */}
                          <Typography className="teste" component="div">
                            <span>
                              {!aulas
                                ? packs.find(
                                    (element) =>
                                      element.id_pack == packEscolhido
                                  )?.nome +
                                  ` (${
                                    packs.find(
                                      (element) =>
                                        element.id_pack == packEscolhido
                                    )?.num_tickets
                                  } ${t("tickets")})`
                                : t("resumo10") +
                                  " - " +
                                  turmas.find(
                                    (element) =>
                                      element.id_turma == turmaEscolhida
                                  )?.nome}
                            </span>
                            {!isMobile ? (
                              !aulas ? (
                                <span>
                                  {compraPreco.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {anual ? (
                                    <span>
                                      {turmas
                                        .find(
                                          (element) =>
                                            element.id_turma == turmaEscolhida
                                        )
                                        ?.preco_anual.toLocaleString("pt-PT", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                    </span>
                                  ) : (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  )}
                                </span>
                              )
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              {!aulas ? (
                                <span>
                                  {compraPreco.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {anual ? (
                                    <span>
                                      {turmas
                                        .find(
                                          (element) =>
                                            element.id_turma == turmaEscolhida
                                        )
                                        ?.preco_anual.toLocaleString("pt-PT", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                    </span>
                                  ) : (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  )}
                                </span>
                              )}
                            </Typography>
                          )}
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {
                              piscinas.find(
                                (element) => element.id == idEscolhida
                              )?.nome
                            }
                          </Typography>
                          {aulas && (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {anual
                                ? t("pagamentoAnual")
                                : t("pagamentoMensal")}
                            </Typography>
                          )}
                          {!aulas ? (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 5,
                                marginLeft: 20,
                              }}
                            >
                              {t("utilizacao1Ticket")}:{" "}
                              {selectedDay.toLocaleDateString("pt-BR")}{" "}
                              {horasDisponiveis[horaEscolhida].horaInicio}h -{" "}
                              {horasDisponiveis[horaEscolhida].horaFim}h
                            </Typography>
                          ) : (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 5,
                                marginLeft: 20,
                              }}
                            >
                              {
                                turmas.find(
                                  (element) =>
                                    element.id_turma == turmaEscolhida
                                )?.obs
                              }
                            </Typography>
                          )}
                        </CardContent>
                      </Box>
                    </Card>

                    {compraInscricao != 0 && (
                      <Card
                        sx={{
                          margin: "10px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 10,
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                            paddingRight: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/*INSCRIÇÃO */}

                            <Typography className="teste" component="div">
                              <span>{t("resumo10")}</span>
                              <span>
                                {compraInscricao.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>

                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {
                                piscinas.find(
                                  (element) => element.id == idEscolhida
                                )?.nome
                              }
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}
                    {desconto != 0 && (
                      <Card
                        sx={{
                          margin: "10px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 15,
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                            paddingRight: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/* DESCONTO */}
                            <Typography className="teste" component="div">
                              <span>
                                {t("desconto")} {desconto}%
                              </span>
                              <span>
                                -{" "}
                                {valorDesconto.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}{" "}
                                €
                              </span>
                            </Typography>

                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {descricaoDesconto}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}

                    <Card
                      sx={{
                        margin: "10px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 15,
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            <span>{t("metodoPagamento")}</span>
                          </Typography>

                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {cartaocredito == true
                              ? t("cartaocredito")
                              : multibanco == true
                              ? t("multibanco")
                              : mbway == true
                              ? t("mbway")
                              : t("balcao")}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                    {compraTotal && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        sx={{
                          marginRight: 2,
                          marginBottom: 20,
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Card
                          sx={{
                            margin: "10px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "lightblue",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/*TOTAl */}

                              <Typography className="teste" component="div">
                                <span>{t("resumo13")}</span>
                                <span>
                                  {compraTotal.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                      </Stack>
                    )}
                  </Card>
                </Scrollbar>
              </Paper>
            </div>
          ) : null}

          {multibanco == true ? (
            <>
              {!loading2 ? (
                <div className="divResumo">
                  <Paper
                    elevation={0}
                    style={{
                      overflow: "hidden",
                      marginTop: 20,
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Scrollbar style={{ height: 400 }}>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packs.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packs.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "10px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "10px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}

                        <Card
                          sx={{
                            margin: "10px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {cartaocredito == true
                                  ? t("cartaocredito")
                                  : multibanco == true
                                  ? t("multibanco")
                                  : mbway == true
                                  ? t("mbway")
                                  : t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Card
                            sx={{
                              margin: "10px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "lightblue",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent sx={{ flex: "1 0 auto" }}>
                                {/*TOTAl */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo13")}</span>
                                  <span>
                                    {compraTotal.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </Card>
                    </Scrollbar>
                  </Paper>
                </div>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <form className="form2">
                    <img
                      src={mb}
                      style={{
                        width: "150px",
                        height: "150px",
                        marginTop: 20,
                        marginBottom: 30,
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                    <div>
                      <h2 className="multibanco">
                        {t("entidade")}
                        <span className="spanMb">{entidade}</span>
                      </h2>
                      <h2 className="multibanco">
                        {t("referencia")}{" "}
                        <span className="spanMb">{referencia}</span>
                      </h2>
                      <h2 className="multibanco">
                        {t("valorapagar")}{" "}
                        <span className="spanMb">
                          {compraTotal.toLocaleString("pt-PT", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </span>
                      </h2>
                      <p></p>
                      <h2 className="multibanco">
                        {t("dataLimite")}{" "}
                        <span className="spanMb">{dataExpira}</span>
                      </h2>
                    </div>

                    <p></p>
                  </form>
                </div>
              )}
            </>
          ) : null}

          {mbway == true ? (
            <>
              {!loading2 ? (
                <div className="divResumo">
                  <Paper
                    elevation={0}
                    style={{
                      overflow: "hidden",
                      marginTop: 20,
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Scrollbar style={{ height: 400 }}>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packs.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packs.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}

                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {cartaocredito == true
                                  ? t("cartaocredito")
                                  : multibanco == true
                                  ? t("multibanco")
                                  : mbway == true
                                  ? t("mbway")
                                  : t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "lightblue",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*TOTAl */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo13")}</span>
                                  <span>
                                    {compraTotal.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </Card>
                    </Scrollbar>
                  </Paper>
                </div>
              ) : (
                <div>
                  <Dialog
                    open={pagar}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent>
                      {mbwayAwait ? (
                        <CircularProgress
                          disableShrink
                          style={{
                            marginTop: 20,
                            backgroundColor: "transparent",
                          }}
                        />
                      ) : (
                        <DialogContentText>{t("msgMbway")}</DialogContentText>
                      )}
                    </DialogContent>
                  </Dialog>

                  <form className="form2">
                    <img
                      src={mbwayfoto}
                      style={{
                        width: "200px",
                        height: "100px",
                        marginBottom: "80px",
                        marginTop: 100,
                      }}
                    />
                    <p>
                      {t("valorapagar")}{" "}
                      {compraTotal.toLocaleString("pt-PT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </p>
                    <PhoneInput
                      defaultCountry="PT"
                      countries={["PT"]}
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefone}
                      error={erroTelefone}
                      helperText={mensagemErroTelefone}
                      onChange={(e) => {
                        setTelefone(e);
                        setErroTelefone(false);
                        setMensagemErroTelefone("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </form>
                </div>
              )}
            </>
          ) : null}
          {balcao == true ? (
            <>
              {!loading2 ? (
                <div className="divResumo">
                  <Paper
                    elevation={0}
                    style={{
                      height: "auto",
                      overflow: "auto",
                      marginTop: 20,
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>

                    <Scrollbar style={{ height: 400 }}>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packs.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packs.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}

                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {cartaocredito == true
                                  ? t("cartaocredito")
                                  : multibanco == true
                                  ? t("multibanco")
                                  : mbway == true
                                  ? t("mbway")
                                  : t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              backgroundColor: "lightblue",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*TOTAl */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo13")}</span>
                                  <span>
                                    {compraTotal.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </Card>
                    </Scrollbar>
                  </Paper>
                </div>
              ) : (
                <div style={{ paddingTop: "100px" }}>
                  <Button
                    className="cardDashboardMarcar4"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.ativo,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    disabled
                  >
                    <Dialog
                      open={loading}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        <DialogContentText>{t("msgMb")}</DialogContentText>
                        <DialogContentText
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <CircularProgress
                            disableShrink
                            style={{
                              marginTop: 20,
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <div style={{ display: "block" }}>
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faFaceSmile}
                      />
                    </div>
                    <span
                      style={{
                        marginTop: "8px",
                        fontSize: isMobile ? "10px" : "14px",
                      }}
                    >
                      {t("mensagembalcao")}
                    </span>
                  </Button>
                </div>
              )}
            </>
          ) : null}

          {tenhopack == true ? (
            <>
              <div className="divResumo">
                <Paper
                  elevation={0}
                  style={{
                    height: "auto",
                    overflow: "hidden",
                    marginTop: 20,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                >
                  <Dialog
                    open={loading}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent>
                      <DialogContentText>{t("msgMb")}</DialogContentText>
                      <DialogContentText
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f7f7f7",
                        }}
                      >
                        <CircularProgress
                          disableShrink
                          style={{
                            marginTop: 20,
                          }}
                        />
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>

                  <Card
                    className="cardDashboardResumo"
                    variant="contained"
                    disabled
                    sx={{ paddingRight: 5 }}
                  >
                    <Card
                      sx={{
                        margin: "8px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          {/* NOME PACK/TURMA */}
                          <Typography className="teste" component="div">
                            <span>
                              {listaPacks.find(
                                (element) =>
                                  element.id_pack_cliente ==
                                  packUtilizadorEscolhido
                              )?.nomePack +
                                ` (${
                                  listaPacks.find(
                                    (element) =>
                                      element.id_pack_cliente ==
                                      packUtilizadorEscolhido
                                  )?.num_tickets
                                } ${t("tickets")})`}
                            </span>
                            {!isMobile ? (
                              <span>
                                {compraPreco.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {compraPreco.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            <span>
                              {t("ticketsUtilizados")}:{" "}
                              {
                                listaPacks.find(
                                  (element) =>
                                    element.id_pack_cliente ==
                                    packUtilizadorEscolhido
                                )?.num_tickets_utilizados
                              }{" "}
                            </span>
                          </Typography>
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {
                              piscinas.find(
                                (element) => element.id == idEscolhida
                              )?.nome
                            }
                          </Typography>
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 5,
                              marginLeft: 20,
                            }}
                          >
                            {t("utilizacao1Ticket")}:{" "}
                            {selectedDay.toLocaleDateString("pt-BR")}{" "}
                            {horasDisponiveis[horaEscolhida].horaInicio}h -{" "}
                            {horasDisponiveis[horaEscolhida].horaFim}h
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                    {compraTotal == 0 && (
                      <Card
                        sx={{
                          margin: "8px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 10,
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "lightblue",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 5,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/*TOTAl */}
                            <Typography className="teste" component="div">
                              <span>{t("resumo13")}</span>
                              <span>
                                {compraTotal.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}
                  </Card>
                </Paper>
              </div>
            </>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div
        className={isMobile ? "listPagesMobile" : "listPages"}
        style={{ overflow: "auto" }}
      >
        <div className="divTabela">
          {semDados == true ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress disableShrink />
            </div>
          ) : (
            <Box
              sx={{
                minWidth: "65%",
                maxWidth: "100%",
                overflow: "hidden",
                maxHeight: "100% !important",
              }}
            >
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                connector={null}
              >
                {steps.map((step, index) => (
                  <Step key={step.label} connector={null}>
                    <StepContent
                      connector={null}
                      sx={{ borderColor: "transparent" }}
                    >
                      {step.description}
                      <Box sx={{ mb: 2 }}></Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              <Dialog
                open={openPagamentoAulas}
                onClose={
                  loading2 == true ? null : handleClickOpenCancelarPagamento
                }
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                  style: {
                    minWidth: isMobile ? "75vw" : 686,
                    height: isMobile ? "75vh" : 580,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {activeStepPagarAulas === 0 ? (
                    aulas == true ? (
                      <span>{t("escolhertipopagamento")}</span>
                    ) : tenhopack == true ? (
                      <span> {t("escolherpackpagamento")}</span>
                    ) : (
                      <span>{t("escolherpack")}</span>
                    )
                  ) : activeStepPagarAulas === 1 ? (
                    <span>{t("escolhermetodopagamento")}</span>
                  ) : activeStepPagarAulas === 2 ? (
                    <div>
                      {loading2 == false ? <span>{t("resumo")}</span> : ""}
                    </div>
                  ) : null}

                  {loading2 == false ? (
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        marginRight: "5px",
                        padding: "8px",
                        zIndex: 2000,
                      }}
                      onClick={
                        loading2 == true
                          ? null
                          : handleClickOpenCancelarPagamento
                      }
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  ) : null}

                  {activeStepPagarAulas > 0 && loading2 == false && (
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        cursor: "pointer",
                        padding: "8px",
                        marginLeft: "5px",
                        zIndex: 2000,
                      }}
                      onClick={goBackPagarAulas}
                      aria-label="Fechar"
                      icon={faArrowLeft}
                    />
                  )}
                </DialogTitle>

                <DialogContent>
                  {freeze && (
                    <div
                      style={{
                        backgroundColor: "transparent",
                        position: "absolute",
                        width: window.innerWidth,
                        height: window.innerHeight,
                        zIndex: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        top: 0,
                      }}
                    >
                      <Dialog
                        open={true}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <DialogContent>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("aguarde")}
                          </Typography>
                          <CircularProgress
                            disableShrink
                            style={{ marginTop: 20 }}
                          />
                        </DialogContent>
                      </Dialog>
                    </div>
                  )}

                  <div className="divPagamento">
                    <Stepper
                      activeStep={activeStepPagarAulas}
                      orientation="vertical"
                      connector={null}
                    >
                      {stepsPagarAulas.map((step, index) => (
                        <Step key={step.label} connector={null}>
                          <StepContent
                            connector={null}
                            sx={{ borderColor: "transparent" }}
                          >
                            {step.description}
                            <Box sx={{ mb: 2 }}></Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </DialogContent>
                {activeStepPagarAulas == 2 ? (
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {cartaocredito == true ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setLoading(true);
                          let utilizadorEscolhido = filho
                            ? users.find(
                                (element) =>
                                  element.filho == 1 &&
                                  element.id == idUtilizadorEscolhido
                              )
                            : users.find(
                                (element) =>
                                  element.filho == 0 &&
                                  element.id == idUtilizadorEscolhido
                              );
                          let id_filho = utilizadorEscolhido.filho
                            ? utilizadorEscolhido.id
                            : 0;
                          const data = new Date(selectedDay);
                          const dia = data
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const mes = (data.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const ano = data.getFullYear();
                          const dataFormatada = `${dia}/${mes}/${ano}`;
                          {
                            !aulas
                              ? registarMarcacaoInativa({
                                  id_pack: packEscolhido,
                                  id_piscina: idEscolhida,
                                  data: dataFormatada,
                                  horaInicio:
                                    horasDisponiveis[horaEscolhida].horaInicio,
                                  horaFim:
                                    horasDisponiveis[horaEscolhida].horaFim,
                                  id_filho: id_filho,
                                }).then((res2) => {
                                  if (res2.success) {
                                    setTimeout(async function () {
                                      pagamentoCartaoCredito({
                                        id_pack: packEscolhido,
                                        id_piscina: idEscolhida,
                                        id_turma: 0,
                                        id_filho: id_filho,
                                        language: i18n.language,
                                        id_preco: 0,
                                        id_desconto: idDesconto,
                                      }).then((res) => {
                                        if (res.success) {
                                          setTimeout(async function () {
                                            let id_marcacao = res2.data.id;
                                            let id_pagamento = res.data.id;
                                            let paymentURL =
                                              res.data.data.PaymentUrl;
                                            associarPagamentosMarcacoes({
                                              id_marcacao: id_marcacao,
                                              id_pagamento: id_pagamento,
                                            }).then((res3) => {
                                              if (res3.success) {
                                                setTimeout(async function () {
                                                  let produtos = [
                                                    {
                                                      nomeProduto: !aulas
                                                        ? packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.nome +
                                                          ` (${
                                                            packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.num_tickets
                                                          } ${t("tickets")})`
                                                        : t("resumo10") +
                                                          " - " +
                                                          turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.nome,
                                                      precoProduto: !aulas
                                                        ? compraPreco.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                        : anual
                                                        ? turmas
                                                            .find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )
                                                            ?.preco_anual.toLocaleString(
                                                              "pt-PT",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "EUR",
                                                              }
                                                            )
                                                        : compraPreco.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          ),
                                                      desc1: aulas
                                                        ? piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome +
                                                          " - " +
                                                          anual
                                                          ? t("pagamentoAnual")
                                                          : t("pagamentoMensal")
                                                        : t(
                                                            "utilizacao1Ticket"
                                                          ) +
                                                          " " +
                                                          selectedDay.toLocaleDateString(
                                                            "pt-BR"
                                                          ) +
                                                          " " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaInicio +
                                                          "h - " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaFim +
                                                          "h",
                                                      desc2: aulas
                                                        ? turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.obs
                                                        : piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome,
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    },
                                                  ];

                                                  let produtosNum = [
                                                    {
                                                      nomeProduto: !aulas
                                                        ? packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.nome +
                                                          ` (${
                                                            packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.num_tickets
                                                          } ${t("tickets")})`
                                                        : t("resumo10") +
                                                          " - " +
                                                          turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.nome,
                                                      precoProduto: !aulas
                                                        ? compraPreco
                                                        : anual
                                                        ? turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.preco_anual
                                                        : compraPreco,
                                                      desc1: aulas
                                                        ? piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome +
                                                          " - " +
                                                          anual
                                                          ? t("pagamentoAnual")
                                                          : t("pagamentoMensal")
                                                        : t(
                                                            "utilizacao1Ticket"
                                                          ) +
                                                          " " +
                                                          selectedDay.toLocaleDateString(
                                                            "pt-BR"
                                                          ) +
                                                          " " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaInicio +
                                                          "h - " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaFim +
                                                          "h",
                                                      desc2: aulas
                                                        ? turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.obs
                                                        : piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome,
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    },
                                                  ];

                                                  if (compraInscricao != 0) {
                                                    produtos.push({
                                                      nomeProduto:
                                                        t("resumo10"),
                                                      precoProduto:
                                                        compraInscricao.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                      desc1: piscinas.find(
                                                        (element) =>
                                                          element.id ==
                                                          idEscolhida
                                                      )?.nome,
                                                      desc2: "",
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    });
                                                    produtosNum.push({
                                                      nomeProduto:
                                                        t("resumo10"),
                                                      precoProduto:
                                                        compraInscricao,
                                                      desc1: piscinas.find(
                                                        (element) =>
                                                          element.id ==
                                                          idEscolhida
                                                      )?.nome,
                                                      desc2: "",
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    });
                                                  }
                                                  linhasCompra({
                                                    produtos: produtosNum,
                                                    id_pagamento: id_pagamento,
                                                  }).then((res4) => {
                                                    setTimeout(() => {
                                                      emailCompraPackMarcacao({
                                                        datetime:
                                                          dataFormatada +
                                                          " " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaInicio +
                                                          "h - " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaFim +
                                                          "h",
                                                        pack: packs.find(
                                                          (element) =>
                                                            element.id_pack ==
                                                            packEscolhido
                                                        )?.id_pack,
                                                        link: res.data.data
                                                          .PaymentUrl,
                                                        total: compraTotal,
                                                        desconto: idDesconto,
                                                        produtos: produtos,
                                                        precoDesconto:
                                                          valorDesconto,
                                                        id_filho: id_filho,
                                                      }).then((res5) => {
                                                        window.location.href =
                                                          paymentURL;
                                                      });
                                                    }, 3000);
                                                  });
                                                }, 3000);
                                              }
                                            });
                                          }, 3000);
                                        }
                                      });
                                    }, 3000);
                                  }
                                })
                              : pagamentoCartaoCredito({
                                  id_pack: 0,
                                  id_piscina: idEscolhida,
                                  id_turma: turmaEscolhida,
                                  id_filho: id_filho,
                                  language: i18n.language,
                                  id_preco: anual ? 1 : mensal ? 2 : 0,
                                  id_desconto: idDesconto,
                                }).then((res) => {
                                  if (res.success) {
                                    let id_pagamento = res.data.id;
                                    setTimeout(async function () {
                                      let produtos = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              )
                                            : anual
                                            ? turmas
                                                .find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )
                                                ?.preco_anual.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )
                                            : compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              ),
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      let produtosNum = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco
                                            : anual
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.preco_anual
                                            : compraPreco,
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      if (compraInscricao != 0) {
                                        produtos.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto:
                                            compraInscricao.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            ),
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                        produtosNum.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto: compraInscricao,
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                      }
                                      linhasCompra({
                                        produtos: produtosNum,
                                        id_pagamento: id_pagamento,
                                      }).then((res4) => {
                                        setTimeout(() => {
                                          emailInscricaoTurma({
                                            turma: turmaEscolhida,
                                            link: res.data.data.PaymentUrl,
                                            total: compraTotal,
                                            desconto: idDesconto,
                                            produtos: produtos,
                                            precoDesconto: valorDesconto,
                                            id_filho: id_filho,
                                          }).then((res5) => {
                                            window.location.href =
                                              res.data.data.PaymentUrl;
                                          });
                                        }, 3000);
                                      });
                                    }, 3000);
                                  }
                                });
                          }
                          setLoading(false);
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("confirmar")}
                      </Button>
                    ) : null}

                    {multibanco == true && loading2 == false ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setLoading(true);
                          let utilizadorEscolhido = filho
                            ? users.find(
                                (element) =>
                                  element.filho == 1 &&
                                  element.id == idUtilizadorEscolhido
                              )
                            : users.find(
                                (element) =>
                                  element.filho == 0 &&
                                  element.id == idUtilizadorEscolhido
                              );
                          let id_filho = utilizadorEscolhido.filho
                            ? utilizadorEscolhido.id
                            : 0;
                          const data = new Date(selectedDay);
                          const dia = data
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const mes = (data.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const ano = data.getFullYear();
                          const dataFormatada = `${dia}/${mes}/${ano}`;
                          {
                            !aulas
                              ? registarMarcacaoInativa({
                                  id_pack: packEscolhido,
                                  id_piscina: idEscolhida,
                                  data: dataFormatada,
                                  horaInicio:
                                    horasDisponiveis[horaEscolhida].horaInicio,
                                  horaFim:
                                    horasDisponiveis[horaEscolhida].horaFim,
                                  id_filho: id_filho,
                                }).then((res2) => {
                                  if (res2.success) {
                                    setTimeout(async function () {
                                      pagamentoMultibanco({
                                        id_pack: packEscolhido,
                                        id_piscina: idEscolhida,
                                        id_turma: 0,
                                        id_filho: id_filho,
                                        language: i18n.language,
                                        id_preco: 0,
                                        id_desconto: idDesconto,
                                      }).then((res) => {
                                        if (res.success) {
                                          setTimeout(async function () {
                                            let id_marcacao = res2.data.id;
                                            let id_pagamento = res.data.id;
                                            associarPagamentosMarcacoes({
                                              id_marcacao: id_marcacao,
                                              id_pagamento: id_pagamento,
                                            }).then((res3) => {
                                              if (res3.success) {
                                                setTimeout(async function () {
                                                  let id_marcacao =
                                                    res2.data.id;
                                                  let id_pagamento =
                                                    res.data.id;
                                                  let paymentURL =
                                                    res.data.data.PaymentUrl;
                                                  associarPagamentosMarcacoes({
                                                    id_marcacao: id_marcacao,
                                                    id_pagamento: id_pagamento,
                                                  }).then((res3) => {
                                                    if (res3.success) {
                                                      setTimeout(
                                                        async function () {
                                                          let produtos = [
                                                            {
                                                              nomeProduto:
                                                                !aulas
                                                                  ? packs.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_pack ==
                                                                        packEscolhido
                                                                    )?.nome +
                                                                    ` (${
                                                                      packs.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id_pack ==
                                                                          packEscolhido
                                                                      )
                                                                        ?.num_tickets
                                                                    } ${t(
                                                                      "tickets"
                                                                    )})`
                                                                  : t(
                                                                      "resumo10"
                                                                    ) +
                                                                    " - " +
                                                                    turmas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_turma ==
                                                                        turmaEscolhida
                                                                    )?.nome,
                                                              precoProduto:
                                                                !aulas
                                                                  ? compraPreco.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    )
                                                                  : anual
                                                                  ? turmas
                                                                      .find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id_turma ==
                                                                          turmaEscolhida
                                                                      )
                                                                      ?.preco_anual.toLocaleString(
                                                                        "pt-PT",
                                                                        {
                                                                          style:
                                                                            "currency",
                                                                          currency:
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                  : compraPreco.toLocaleString(
                                                                      "pt-PT",
                                                                      {
                                                                        style:
                                                                          "currency",
                                                                        currency:
                                                                          "EUR",
                                                                      }
                                                                    ),
                                                              desc1: aulas
                                                                ? piscinas.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      idEscolhida
                                                                  )?.nome +
                                                                  " - " +
                                                                  anual
                                                                  ? t(
                                                                      "pagamentoAnual"
                                                                    )
                                                                  : t(
                                                                      "pagamentoMensal"
                                                                    )
                                                                : t(
                                                                    "utilizacao1Ticket"
                                                                  ) +
                                                                  " " +
                                                                  selectedDay.toLocaleDateString(
                                                                    "pt-BR"
                                                                  ) +
                                                                  " " +
                                                                  horasDisponiveis[
                                                                    horaEscolhida
                                                                  ].horaInicio +
                                                                  "h - " +
                                                                  horasDisponiveis[
                                                                    horaEscolhida
                                                                  ].horaFim +
                                                                  "h",
                                                              desc2: aulas
                                                                ? turmas.find(
                                                                    (element) =>
                                                                      element.id_turma ==
                                                                      turmaEscolhida
                                                                  )?.obs
                                                                : piscinas.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      idEscolhida
                                                                  )?.nome,
                                                              quantidade: 1,
                                                              quantidadeTexto:
                                                                t("quantidade"),
                                                            },
                                                          ];

                                                          let produtosNum = [
                                                            {
                                                              nomeProduto:
                                                                !aulas
                                                                  ? packs.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_pack ==
                                                                        packEscolhido
                                                                    )?.nome +
                                                                    ` (${
                                                                      packs.find(
                                                                        (
                                                                          element
                                                                        ) =>
                                                                          element.id_pack ==
                                                                          packEscolhido
                                                                      )
                                                                        ?.num_tickets
                                                                    } ${t(
                                                                      "tickets"
                                                                    )})`
                                                                  : t(
                                                                      "resumo10"
                                                                    ) +
                                                                    " - " +
                                                                    turmas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_turma ==
                                                                        turmaEscolhida
                                                                    )?.nome,
                                                              precoProduto:
                                                                !aulas
                                                                  ? compraPreco
                                                                  : anual
                                                                  ? turmas.find(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        element.id_turma ==
                                                                        turmaEscolhida
                                                                    )
                                                                      ?.preco_anual
                                                                  : compraPreco,
                                                              desc1: aulas
                                                                ? piscinas.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      idEscolhida
                                                                  )?.nome +
                                                                  " - " +
                                                                  anual
                                                                  ? t(
                                                                      "pagamentoAnual"
                                                                    )
                                                                  : t(
                                                                      "pagamentoMensal"
                                                                    )
                                                                : t(
                                                                    "utilizacao1Ticket"
                                                                  ) +
                                                                  " " +
                                                                  selectedDay.toLocaleDateString(
                                                                    "pt-BR"
                                                                  ) +
                                                                  " " +
                                                                  horasDisponiveis[
                                                                    horaEscolhida
                                                                  ].horaInicio +
                                                                  "h - " +
                                                                  horasDisponiveis[
                                                                    horaEscolhida
                                                                  ].horaFim +
                                                                  "h",
                                                              desc2: aulas
                                                                ? turmas.find(
                                                                    (element) =>
                                                                      element.id_turma ==
                                                                      turmaEscolhida
                                                                  )?.obs
                                                                : piscinas.find(
                                                                    (element) =>
                                                                      element.id ==
                                                                      idEscolhida
                                                                  )?.nome,
                                                              quantidade: 1,
                                                              quantidadeTexto:
                                                                t("quantidade"),
                                                            },
                                                          ];

                                                          if (
                                                            compraInscricao != 0
                                                          ) {
                                                            produtos.push({
                                                              nomeProduto:
                                                                t("resumo10"),
                                                              precoProduto:
                                                                compraInscricao.toLocaleString(
                                                                  "pt-PT",
                                                                  {
                                                                    style:
                                                                      "currency",
                                                                    currency:
                                                                      "EUR",
                                                                  }
                                                                ),
                                                              desc1:
                                                                piscinas.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    idEscolhida
                                                                )?.nome,
                                                              desc2: "",
                                                              quantidade: 1,
                                                              quantidadeTexto:
                                                                t("quantidade"),
                                                            });
                                                            produtosNum.push({
                                                              nomeProduto:
                                                                t("resumo10"),
                                                              precoProduto:
                                                                compraInscricao,
                                                              desc1:
                                                                piscinas.find(
                                                                  (element) =>
                                                                    element.id ==
                                                                    idEscolhida
                                                                )?.nome,
                                                              desc2: "",
                                                              quantidade: 1,
                                                              quantidadeTexto:
                                                                t("quantidade"),
                                                            });
                                                          }
                                                          linhasCompra({
                                                            produtos:
                                                              produtosNum,
                                                            id_pagamento:
                                                              id_pagamento,
                                                          }).then((res4) => {
                                                            setTimeout(() => {
                                                              emailCompraPackMarcacao(
                                                                {
                                                                  datetime:
                                                                    dataFormatada +
                                                                    " " +
                                                                    horasDisponiveis[
                                                                      horaEscolhida
                                                                    ]
                                                                      .horaInicio +
                                                                    "h - " +
                                                                    horasDisponiveis[
                                                                      horaEscolhida
                                                                    ].horaFim +
                                                                    "h",
                                                                  pack: packs.find(
                                                                    (element) =>
                                                                      element.id_pack ==
                                                                      packEscolhido
                                                                  )?.id_pack,
                                                                  total:
                                                                    compraTotal,
                                                                  desconto:
                                                                    idDesconto,
                                                                  produtos:
                                                                    produtos,
                                                                  precoDesconto:
                                                                    valorDesconto,
                                                                  entidade:
                                                                    res.data
                                                                      .data
                                                                      .Entity,
                                                                  referencia:
                                                                    res.data
                                                                      .data
                                                                      .Reference,
                                                                  dataExpira:
                                                                    res.data
                                                                      .data
                                                                      .ExpiryDate,
                                                                  id_filho:
                                                                    id_filho,
                                                                }
                                                              ).then((res5) => {
                                                                setEntidade(
                                                                  res.data.data
                                                                    .Entity
                                                                );
                                                                setReferencia(
                                                                  res.data.data
                                                                    .Reference
                                                                );
                                                                setDataExpira(
                                                                  res.data.data
                                                                    .ExpiryDate
                                                                );
                                                                setLoading(
                                                                  false
                                                                );
                                                                setLoading2(
                                                                  true
                                                                );
                                                              });
                                                            }, 3000);
                                                          });
                                                        },
                                                        3000
                                                      );
                                                    }
                                                  });
                                                }, 3000);
                                              }
                                            });
                                          }, 3000);
                                        }
                                      });
                                    }, 3000);
                                  }
                                })
                              : pagamentoMultibanco({
                                  id_pack: 0,
                                  id_piscina: idEscolhida,
                                  id_turma: turmaEscolhida,
                                  id_filho: id_filho,
                                  language: i18n.language,
                                  id_preco: anual ? 1 : mensal ? 2 : 0,
                                  id_desconto: idDesconto,
                                }).then((res) => {
                                  if (res.success) {
                                    let id_pagamento = res.data.id;
                                    setTimeout(async function () {
                                      let produtos = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              )
                                            : anual
                                            ? turmas
                                                .find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )
                                                ?.preco_anual.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )
                                            : compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              ),
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      let produtosNum = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco
                                            : anual
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.preco_anual
                                            : compraPreco,
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      if (compraInscricao != 0) {
                                        produtos.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto:
                                            compraInscricao.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            ),
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                        produtosNum.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto: compraInscricao,
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                      }
                                      linhasCompra({
                                        produtos: produtosNum,
                                        id_pagamento: id_pagamento,
                                      }).then((res4) => {
                                        setTimeout(() => {
                                          emailInscricaoTurma({
                                            turma: turmaEscolhida,
                                            entidade: res.data.data.Entity,
                                            referencia: res.data.data.Reference,
                                            dataExpira:
                                              res.data.data.ExpiryDate,
                                            total: compraTotal,
                                            desconto: idDesconto,
                                            produtos: produtos,
                                            precoDesconto: valorDesconto,
                                            id_filho: id_filho,
                                          }).then((res5) => {
                                            setEntidade(res.data.data.Entity);
                                            setReferencia(
                                              res.data.data.Reference
                                            );
                                            setDataExpira(
                                              res.data.data.ExpiryDate
                                            );
                                            setLoading(false);
                                            setLoading2(true);
                                          });
                                        }, 3000);
                                      });
                                    }, 3000);
                                  }
                                });
                          }
                          setLoading(false);
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("confirmar")}
                      </Button>
                    ) : null}
                    {mbway == true && loading2 == false ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setLoading2(true);
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("confirmar")}
                      </Button>
                    ) : null}
                    {balcao == true && loading2 == false ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setLoading(true);
                          let utilizadorEscolhido = filho
                            ? users.find(
                                (element) =>
                                  element.filho == 1 &&
                                  element.id == idUtilizadorEscolhido
                              )
                            : users.find(
                                (element) =>
                                  element.filho == 0 &&
                                  element.id == idUtilizadorEscolhido
                              );
                          let id_filho = utilizadorEscolhido.filho
                            ? utilizadorEscolhido.id
                            : 0;
                          const data = new Date(selectedDay);
                          const dia = data
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const mes = (data.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const ano = data.getFullYear();
                          const dataFormatada = `${dia}/${mes}/${ano}`;

                          if (!aulas) {
                            registarMarcacaoInativa({
                              id_pack: packEscolhido,
                              id_piscina: idEscolhida,
                              data: dataFormatada,
                              horaInicio:
                                horasDisponiveis[horaEscolhida].horaInicio,
                              horaFim: horasDisponiveis[horaEscolhida].horaFim,
                              id_filho: id_filho,
                            }).then((res2) => {
                              if (res2.success) {
                                setTimeout(async function () {
                                  marcacaoBalcao({
                                    id_pack: packEscolhido,
                                    id_piscina: idEscolhida,
                                    id_turma: 0,
                                    id_filho: id_filho,
                                    preco: 0,
                                    id_desconto: idDesconto,
                                  }).then((res) => {
                                    if (res.success) {
                                      setTimeout(async function () {
                                        let id_marcacao = res2.data.id;
                                        let id_pagamento = res.data.id;
                                        associarPagamentosMarcacoes({
                                          id_marcacao: id_marcacao,
                                          id_pagamento: id_pagamento,
                                        }).then((res3) => {
                                          setTimeout(async function () {
                                            let produtos = [
                                              {
                                                nomeProduto: !aulas
                                                  ? packs.find(
                                                      (element) =>
                                                        element.id_pack ==
                                                        packEscolhido
                                                    )?.nome +
                                                    ` (${
                                                      packs.find(
                                                        (element) =>
                                                          element.id_pack ==
                                                          packEscolhido
                                                      )?.num_tickets
                                                    } ${t("tickets")})`
                                                  : t("resumo10") +
                                                    " - " +
                                                    turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.nome,
                                                precoProduto: !aulas
                                                  ? compraPreco.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )
                                                  : anual
                                                  ? turmas
                                                      .find(
                                                        (element) =>
                                                          element.id_turma ==
                                                          turmaEscolhida
                                                      )
                                                      ?.preco_anual.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )
                                                  : compraPreco.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    ),
                                                desc1: aulas
                                                  ? piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome +
                                                    " - " +
                                                    anual
                                                    ? t("pagamentoAnual")
                                                    : t("pagamentoMensal")
                                                  : t("utilizacao1Ticket") +
                                                    " " +
                                                    selectedDay.toLocaleDateString(
                                                      "pt-BR"
                                                    ) +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                desc2: aulas
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.obs
                                                  : piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              },
                                            ];

                                            let produtosNum = [
                                              {
                                                nomeProduto: !aulas
                                                  ? packs.find(
                                                      (element) =>
                                                        element.id_pack ==
                                                        packEscolhido
                                                    )?.nome +
                                                    ` (${
                                                      packs.find(
                                                        (element) =>
                                                          element.id_pack ==
                                                          packEscolhido
                                                      )?.num_tickets
                                                    } ${t("tickets")})`
                                                  : t("resumo10") +
                                                    " - " +
                                                    turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.nome,
                                                precoProduto: !aulas
                                                  ? compraPreco
                                                  : anual
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.preco_anual
                                                  : compraPreco,
                                                desc1: aulas
                                                  ? piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome +
                                                    " - " +
                                                    anual
                                                    ? t("pagamentoAnual")
                                                    : t("pagamentoMensal")
                                                  : t("utilizacao1Ticket") +
                                                    " " +
                                                    selectedDay.toLocaleDateString(
                                                      "pt-BR"
                                                    ) +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                desc2: aulas
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.obs
                                                  : piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              },
                                            ];

                                            if (compraInscricao != 0) {
                                              produtos.push({
                                                nomeProduto: t("resumo10"),
                                                precoProduto:
                                                  compraInscricao.toLocaleString(
                                                    "pt-PT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  ),
                                                desc1: piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                                desc2: "",
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              });
                                              produtosNum.push({
                                                nomeProduto: t("resumo10"),
                                                precoProduto: compraInscricao,
                                                desc1: piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                                desc2: "",
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              });
                                            }
                                            linhasCompra({
                                              produtos: produtosNum,
                                              id_pagamento: id_pagamento,
                                            }).then((res4) => {
                                              setTimeout(() => {
                                                emailCompraPackMarcacao({
                                                  dataExpira: `${new Date(
                                                    new Date().setDate(
                                                      new Date().getDate() + 1
                                                    )
                                                  )
                                                    .getDate()
                                                    .toString()
                                                    .padStart(2, "0")}-${(
                                                    new Date(
                                                      new Date().setDate(
                                                        new Date().getDate() + 1
                                                      )
                                                    ).getMonth() + 1
                                                  )
                                                    .toString()
                                                    .padStart(
                                                      2,
                                                      "0"
                                                    )}-${new Date(
                                                    new Date().setDate(
                                                      new Date().getDate() + 1
                                                    )
                                                  ).getFullYear()}`,
                                                  datetime:
                                                    dataFormatada +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                  pack: packs.find(
                                                    (element) =>
                                                      element.id_pack ==
                                                      packEscolhido
                                                  )?.id_pack,
                                                  total: compraTotal,
                                                  desconto: idDesconto,
                                                  produtos: produtos,
                                                  precoDesconto: valorDesconto,
                                                  id_filho: id_filho,
                                                }).then((res5) => {
                                                  setLoading(false);
                                                  setLoading2(true);
                                                });
                                              }, 3000);
                                            });
                                          }, 3000);
                                        });
                                      }, 3000);
                                    }
                                  });
                                }, 3000);
                              }
                            });
                          } else {
                            setLoading(true);
                            setTimeout(async function () {
                              marcacaoBalcao({
                                id_pack: 0,
                                id_piscina: idEscolhida,
                                id_turma: turmaEscolhida,
                                id_filho: id_filho,
                                id_preco: anual ? 1 : mensal ? 2 : 0,
                                id_desconto: idDesconto,
                              }).then((res) => {
                                let id_pagamento = res.data.id;
                                setTimeout(async function () {
                                  let produtos = [
                                    {
                                      nomeProduto: !aulas
                                        ? packs.find(
                                            (element) =>
                                              element.id_pack == packEscolhido
                                          )?.nome +
                                          ` (${
                                            packs.find(
                                              (element) =>
                                                element.id_pack == packEscolhido
                                            )?.num_tickets
                                          } ${t("tickets")})`
                                        : t("resumo10") +
                                          " - " +
                                          turmas.find(
                                            (element) =>
                                              element.id_turma == turmaEscolhida
                                          )?.nome,
                                      precoProduto: !aulas
                                        ? compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })
                                        : anual
                                        ? turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )
                                        : compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          }),
                                      desc1: aulas
                                        ? piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome +
                                          " - " +
                                          anual
                                          ? t("pagamentoAnual")
                                          : t("pagamentoMensal")
                                        : t("utilizacao1Ticket") +
                                          " " +
                                          selectedDay.toLocaleDateString(
                                            "pt-BR"
                                          ) +
                                          " " +
                                          horasDisponiveis[horaEscolhida]
                                            .horaInicio +
                                          "h - " +
                                          horasDisponiveis[horaEscolhida]
                                            .horaFim +
                                          "h",
                                      desc2: aulas
                                        ? turmas.find(
                                            (element) =>
                                              element.id_turma == turmaEscolhida
                                          )?.obs
                                        : piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                      quantidade: 1,
                                      quantidadeTexto: t("quantidade"),
                                    },
                                  ];

                                  let produtosNum = [
                                    {
                                      nomeProduto: !aulas
                                        ? packs.find(
                                            (element) =>
                                              element.id_pack == packEscolhido
                                          )?.nome +
                                          ` (${
                                            packs.find(
                                              (element) =>
                                                element.id_pack == packEscolhido
                                            )?.num_tickets
                                          } ${t("tickets")})`
                                        : t("resumo10") +
                                          " - " +
                                          turmas.find(
                                            (element) =>
                                              element.id_turma == turmaEscolhida
                                          )?.nome,
                                      precoProduto: !aulas
                                        ? compraPreco
                                        : anual
                                        ? turmas.find(
                                            (element) =>
                                              element.id_turma == turmaEscolhida
                                          )?.preco_anual
                                        : compraPreco,
                                      desc1: aulas
                                        ? piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome +
                                          " - " +
                                          anual
                                          ? t("pagamentoAnual")
                                          : t("pagamentoMensal")
                                        : t("utilizacao1Ticket") +
                                          " " +
                                          selectedDay.toLocaleDateString(
                                            "pt-BR"
                                          ) +
                                          " " +
                                          horasDisponiveis[horaEscolhida]
                                            .horaInicio +
                                          "h - " +
                                          horasDisponiveis[horaEscolhida]
                                            .horaFim +
                                          "h",
                                      desc2: aulas
                                        ? turmas.find(
                                            (element) =>
                                              element.id_turma == turmaEscolhida
                                          )?.obs
                                        : piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                      quantidade: 1,
                                      quantidadeTexto: t("quantidade"),
                                    },
                                  ];

                                  if (compraInscricao != 0) {
                                    produtos.push({
                                      nomeProduto: t("resumo10"),
                                      precoProduto:
                                        compraInscricao.toLocaleString(
                                          "pt-PT",
                                          {
                                            style: "currency",
                                            currency: "EUR",
                                          }
                                        ),
                                      desc1: piscinas.find(
                                        (element) => element.id == idEscolhida
                                      )?.nome,
                                      desc2: "",
                                      quantidade: 1,
                                      quantidadeTexto: t("quantidade"),
                                    });
                                    produtosNum.push({
                                      nomeProduto: t("resumo10"),
                                      precoProduto: compraInscricao,
                                      desc1: piscinas.find(
                                        (element) => element.id == idEscolhida
                                      )?.nome,
                                      desc2: "",
                                      quantidade: 1,
                                      quantidadeTexto: t("quantidade"),
                                    });
                                  }
                                  linhasCompra({
                                    produtos: produtosNum,
                                    id_pagamento: id_pagamento,
                                  }).then((res4) => {
                                    setTimeout(() => {
                                      emailInscricaoTurma({
                                        turma: turmaEscolhida,
                                        dataExpira: `${new Date(
                                          new Date().setDate(
                                            new Date().getDate() + 1
                                          )
                                        )
                                          .getDate()
                                          .toString()
                                          .padStart(2, "0")}-${(
                                          new Date(
                                            new Date().setDate(
                                              new Date().getDate() + 1
                                            )
                                          ).getMonth() + 1
                                        )
                                          .toString()
                                          .padStart(2, "0")}-${new Date(
                                          new Date().setDate(
                                            new Date().getDate() + 1
                                          )
                                        ).getFullYear()}`,
                                        total: compraTotal,
                                        desconto: idDesconto,
                                        produtos: produtos,
                                        precoDesconto: valorDesconto,
                                        id_filho: id_filho,
                                      }).then((res5) => {
                                        setLoading(false);
                                        setLoading2(true);
                                      });
                                    }, 3000);
                                  });
                                }, 3000);
                              });
                            }, 3000);
                          }
                          setLoading(false);
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("confirmar")}
                      </Button>
                    ) : null}

                    {loading2 == true && balcao == true ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setOpenPagamentoAulas(false);
                          setCartaoCredito(false);
                          setTelefone("");
                          setTurmaEscolhida("");
                          setIdEscolhida("");
                          setIdUtilizadorEscolhido("");
                          setMbway(false);
                          setMultibanco(false);
                          setLoading2(false);
                          setAnual(false);
                          setAulas(false);
                          setBalcao(false);
                          setHoraEscolhida(0);
                          setHorasDisponiveis([]);
                          setTenhoPack(false);

                          setActiveStepPagarAulas(0);
                          setPagamentoCancelado(false);
                          navigate("/dashboard_customer");
                          setActiveStep(3);

                          handleCloseCancelarPagamento();
                          handleClosePagamentoAulas();
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("terminar")}
                      </Button>
                    ) : null}

                    {loading2 == true && multibanco == true ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setOpenPagamentoAulas(false);
                          setCartaoCredito(false);
                          setTelefone("");
                          setTurmaEscolhida("");
                          setIdEscolhida("");
                          setIdUtilizadorEscolhido("");
                          setMbway(false);
                          setMultibanco(false);
                          setLoading2(false);
                          setAnual(false);
                          setAulas(false);
                          setBalcao(false);
                          setHoraEscolhida(0);
                          setHorasDisponiveis([]);
                          setTenhoPack(false);

                          setActiveStepPagarAulas(0);
                          setPagamentoCancelado(false);
                          navigate("/dashboard_customer");
                          setActiveStep(3);
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("terminar")}
                      </Button>
                    ) : null}
                    {mbway == true && loading2 == true ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          if (telefone && !erroTelefone) {
                            setPagar(true);
                            setMbwayAwait(true);
                            let utilizadorEscolhido = filho
                              ? users.find(
                                  (element) =>
                                    element.filho == 1 &&
                                    element.id == idUtilizadorEscolhido
                                )
                              : users.find(
                                  (element) =>
                                    element.filho == 0 &&
                                    element.id == idUtilizadorEscolhido
                                );
                            let id_filho = utilizadorEscolhido.filho
                              ? utilizadorEscolhido.id
                              : 0;
                            const data = new Date(selectedDay);
                            const dia = data
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const mes = (data.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const ano = data.getFullYear();
                            const dataFormatada = `${dia}/${mes}/${ano}`;
                            if (!aulas) {
                              registarMarcacaoInativa({
                                id_pack: packEscolhido,
                                id_piscina: idEscolhida,
                                data: dataFormatada,
                                horaInicio:
                                  horasDisponiveis[horaEscolhida].horaInicio,
                                horaFim:
                                  horasDisponiveis[horaEscolhida].horaFim,
                                id_filho: id_filho,
                              }).then((res2) => {
                                if (res2.success) {
                                  setTimeout(async function () {
                                    pagamentoMbway({
                                      id_pack: packEscolhido,
                                      id_piscina: idEscolhida,
                                      id_turma: 0,
                                      id_filho: id_filho,
                                      preco: 0,
                                      telemovel: telefone,
                                      id_desconto: idDesconto,
                                    }).then((res) => {
                                      if (res.success) {
                                        setTimeout(async function () {
                                          let id_marcacao = res2.data.id;
                                          let id_pagamento = res.data.id;
                                          associarPagamentosMarcacoes({
                                            id_marcacao: id_marcacao,
                                            id_pagamento: id_pagamento,
                                          }).then((res3) => {
                                            if (res3.success) {
                                              setTimeout(async function () {
                                                let produtos = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id_pack ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      : anual
                                                      ? turmas
                                                          .find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )
                                                          ?.preco_anual.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                      : compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                let produtosNum = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id_pack ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco
                                                      : anual
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.preco_anual
                                                      : compraPreco,
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                if (compraInscricao != 0) {
                                                  produtos.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      ),
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                  produtosNum.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao,
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                }
                                                linhasCompra({
                                                  produtos: produtosNum,
                                                  id_pagamento: id_pagamento,
                                                }).then((res4) => {
                                                  setTimeout(() => {
                                                    emailCompraPackMarcacao({
                                                      telemovel: telefone,
                                                      datetime:
                                                        dataFormatada +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                      pack: packs.find(
                                                        (element) =>
                                                          element.id_pack ==
                                                          packEscolhido
                                                      )?.id_pack,
                                                      total: compraTotal,
                                                      desconto: idDesconto,
                                                      produtos: produtos,
                                                      precoDesconto:
                                                        valorDesconto,
                                                      id_filho: id_filho,
                                                    }).then((res5) => {
                                                      setMbwayAwait(false);
                                                    });
                                                  }, 3000);
                                                });
                                              }, 3000);
                                            }
                                          });
                                        }, 3000);
                                      } else {
                                        toast.error(t("telefoneinvalido"));
                                      }
                                    });
                                  }, 3000);
                                }
                              });
                            } else {
                              setPagar(true);
                              setMbwayAwait(true);
                              setTimeout(async function () {
                                pagamentoMbway({
                                  id_pack: 0,
                                  id_piscina: idEscolhida,
                                  id_turma: turmaEscolhida,
                                  id_filho: id_filho,
                                  telemovel: telefone,
                                  id_desconto: idDesconto,
                                  id_preco: anual ? 1 : mensal ? 2 : 0,
                                }).then((res) => {
                                  if (res.success) {
                                    let id_pagamento = res.data.id;
                                    setTimeout(async function () {
                                      let produtos = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              )
                                            : anual
                                            ? turmas
                                                .find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )
                                                ?.preco_anual.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )
                                            : compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              ),
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      let produtosNum = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco
                                            : anual
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.preco_anual
                                            : compraPreco,
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      if (compraInscricao != 0) {
                                        produtos.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto:
                                            compraInscricao.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            ),
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                        produtosNum.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto: compraInscricao,
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                      }
                                      linhasCompra({
                                        produtos: produtosNum,
                                        id_pagamento: id_pagamento,
                                      }).then((res4) => {
                                        setTimeout(() => {
                                          emailInscricaoTurma({
                                            turma: turmaEscolhida,
                                            telemovel: telefone,
                                            total: compraTotal,
                                            desconto: idDesconto,
                                            produtos: produtos,
                                            precoDesconto: valorDesconto,
                                            id_filho: id_filho,
                                          }).then((res5) => {
                                            setMbwayAwait(false);
                                          });
                                        }, 3000);
                                      });
                                    }, 3000);
                                  } else {
                                    toast.error(t("telefoneinvalido"));
                                  }
                                });
                              }, 3000);
                            }
                          } else {
                            setErroTelefone(true);
                            toast.error(t("telefoneinvalido"));
                          }
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("pagar")}
                      </Button>
                    ) : null}

                    {tenhopack == true ? (
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setLoading(true);
                          let utilizadorEscolhido = filho
                            ? users.find(
                                (element) =>
                                  element.filho == 1 &&
                                  element.id == idUtilizadorEscolhido
                              )
                            : users.find(
                                (element) =>
                                  element.filho == 0 &&
                                  element.id == idUtilizadorEscolhido
                              );
                          let id_filho = utilizadorEscolhido.filho
                            ? utilizadorEscolhido.id
                            : 0;
                          const data = new Date(selectedDay);
                          const dia = data
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const mes = (data.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const ano = data.getFullYear();
                          const dataFormatada = `${dia}/${mes}/${ano}`;
                          registarMarcacao({
                            id_pack: packUtilizadorEscolhido,
                            id_piscina: idEscolhida,
                            data: dataFormatada,
                            horaInicio:
                              horasDisponiveis[horaEscolhida].horaInicio,
                            horaFim: horasDisponiveis[horaEscolhida].horaFim,
                            id_filho: id_filho,
                          }).then((res) => {
                            if (res.success) {
                              setTimeout(async function () {
                                emailMarcacao({
                                  pack: packUtilizadorEscolhido,
                                  datetime:
                                    dataFormatada +
                                    " " +
                                    horasDisponiveis[horaEscolhida].horaInicio +
                                    "h - " +
                                    horasDisponiveis[horaEscolhida].horaFim +
                                    "h",
                                }).then((res) => {
                                  setMarcacaoConcluida(true);
                                  setMarcacaoConcluida2(true);
                                  setTimeout(() => {
                                    setOpenPagamentoAulas(false);
                                    setCartaoCredito(false);
                                    setTelefone("");
                                    setTurmaEscolhida("");
                                    setIdEscolhida("");
                                    setIdUtilizadorEscolhido("");
                                    setTipoEscolhido(false);
                                    setMbway(false);
                                    setMultibanco(false);
                                    setLoading2(false);
                                    setAnual(false);
                                    setAulas(false);
                                    setBalcao(false);
                                    setActiveStepPagarAulas(0);
                                    setActiveStep(3);
                                    setTenhoPack(false);
                                    setSelectedDay("");
                                    setHoraEscolhida(0);
                                    setHorasDisponiveis([]);
                                    setPagamentoCancelado(false);
                                    handleCloseCancelarPagamento();
                                    handleClosePagamentoAulas();
                                    setLoading(false);
                                  }, 3000);
                                });
                              }, 3000);
                            } else {
                              setMarcacaoConcluida2(false);
                              setTimeout(() => {
                                setOpenPagamentoAulas(false);
                                setCartaoCredito(false);
                                setTelefone("");
                                setTurmaEscolhida("");
                                setIdEscolhida("");
                                setIdUtilizadorEscolhido("");
                                setTipoEscolhido(false);
                                setMbway(false);
                                setMultibanco(false);
                                setLoading2(false);
                                setAnual(false);
                                setAulas(false);
                                setBalcao(false);
                                setActiveStepPagarAulas(0);
                                setActiveStep(3);
                                setTenhoPack(false);
                                setSelectedDay("");
                                setHoraEscolhida(0);
                                setHorasDisponiveis([]);
                                setPagamentoCancelado(true);
                                handleCloseCancelarPagamento();
                                handleClosePagamentoAulas();
                                setLoading(false);
                              }, 3000);
                            }
                          });
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("marcar")}
                      </Button>
                    ) : null}
                  </DialogActions>
                ) : null}
              </Dialog>
            </Box>
          )}
        </div>
      </div>

      {/* Cancelar pagamento */}
      <Dialog
        open={cancelarPagamento}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: isMobile ? "45vw" : 452,
            height: isMobile ? "25vh" : 186,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {t("cancelarPagamento")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("textoCancelarPagamento")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${simbtn})`,
              width: 218,
              height: 40,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              if (loading2 == false) {
                setOpenPagamentoAulas(false);
                setCartaoCredito(false);
                setTelefone("");
                setTurmaEscolhida("");
                setIdEscolhida("");
                setIdUtilizadorEscolhido("");
                setMbway(false);
                setMultibanco(false);
                setLoading2(false);
                setAnual(false);
                setAulas(false);
                setBalcao(false);
                setTipoEscolhido(false);
                setSelectedDay("");
                setPackEscolhido("");
                setHoraEscolhida(0);
                setHorasDisponiveis([]);
                setPackUtilizadorEscolhido("");
                setTenhoPack(false);
                setActiveStepPagarAulas(0);
                setActiveStep(3);
                setPagamentoCancelado(true);
                handleCloseCancelarPagamento();
                handleClosePagamentoAulas();
              } else {
                toast.error(t("pagamentoProcessar"));
              }
            }}
          >
            {t("sim")}
          </Button>
          <Button
            className="btnSim"
            variant="contained"
            style={{
              backgroundImage: `url(${naobtn})`,
              width: 198,
              height: 30,
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={() => {
              handleCloseCancelarPagamento();
            }}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Marcar;
