import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Nav from "../../components/Nav";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  enviarEmailPostPiscina,
  postPiscinas,
  postPiscinasIdEntidadeLegal,
  verificarNifEntidade,
} from "../../api/requests/Piscinas";
import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  enviarEmailPostCliente,
  postCliente,
} from "../../api/requests/Clientes";

function CriarClienteAdmin() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeCliente, setNomeCliente] = useState("");
  const [idPiscina, setIdPiscina] = useState("");
  const [emailCliente, setEmailCliente] = useState("");
  const [telefoneCliente, setTelefoneCliente] = useState("");

  //Validações
  // Responsável
  const [erroNomeCliente, setErroNomeCliente] = useState("");
  const [erroEmailCliente, setErroEmailCliente] = useState("");
  const [erroTelefoneCliente, setErroTelefoneCliente] = useState("");

  // Responsável
  const [mensagemErroEmailCliente, setMensagemErroEmailCliente] = useState("");
  const [mensagemErroTelefoneCliente, setMensagemErroTelefoneCliente] =
    useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    setIdPiscina(idPiscina);
    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  function criarCliente() {
    setFreeze(true);
    postCliente({
      id: localStorage.getItem("idPiscina"),
      nome: nomeCliente,
      contacto: telefoneCliente,
      email: emailCliente,
      cargo: "iii",
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          await enviarEmailPostCliente({
            id: res.data.id,
          }).then((res) => {
            if (res.success) {
              toast.success(res.data.msg);
              setFreeze(false);
              navigate("/poolcustomersa");
            } else {
              toast.error(res.error);
              setFreeze(false);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (nomeCliente && emailCliente && telefoneCliente) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailCliente
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailCliente(true);
        setMensagemErroEmailCliente(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneCliente)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneCliente(true);
        setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
      }
      if (emailCliente) {
        verificarEmail({ email: emailCliente }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroEmailCliente(true);
              setMensagemErroEmailCliente(res.data.error);
            }
          }
        });
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarCliente();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeCliente) {
        setErroNomeCliente(true);
      }
      //Validação do email
      if (!emailCliente) {
        setErroEmailCliente(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailCliente
          );
        if (!validacaoEmail) {
          setErroEmailCliente(true);
          setMensagemErroEmailCliente(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailCliente }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                setErroEmailCliente(true);
                setMensagemErroEmailCliente(res.data.error);
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneCliente) {
        setErroTelefoneCliente(true);
      } else {
        if (!isValidPhoneNumber(telefoneCliente)) {
          setErroTelefoneCliente(true);
          setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
        }
      }
    }
  }

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className="listPages">
        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}

        <div className="divTabela">
          <Box className="formTextField">
            <div>
              {/* Nome */}
              <FormControl className="formTextField3" fullWidth>
                <TextField
                  required
                  type="text"
                  variant="standard"
                  label={t("nome")}
                  value={nomeCliente}
                  error={erroNomeCliente}
                  onChange={(e) => {
                    setNomeCliente(e.target.value);
                    setErroNomeCliente(false);
                  }}
                />
              </FormControl>
              {/* Email */}
              <FormControl className="formTextField2" fullWidth>
                <TextField
                  required
                  type="text"
                  variant="standard"
                  label={t("email")}
                  value={emailCliente}
                  error={erroEmailCliente}
                  helperText={mensagemErroEmailCliente}
                  onChange={(e) => {
                    setEmailCliente(e.target.value.toLocaleLowerCase());
                    setErroEmailCliente(false);
                    setMensagemErroEmailCliente("");
                  }}
                />
              </FormControl>
              {/* Telefone */}
              <FormControl className="formTextField3" fullWidth>
                <PhoneInput
                  defaultCountry="PT"
                  labels={
                    i18n.language == "pt" ? pt : i18n.language == "es" ? es : en
                  }
                  value={telefoneCliente}
                  error={erroTelefoneCliente}
                  helperText={mensagemErroTelefoneCliente}
                  onChange={(e) => {
                    setTelefoneCliente(e);
                    setErroTelefoneCliente(false);
                    setMensagemErroTelefoneCliente("");
                  }}
                  inputComponent={PhoneNumber}
                />
              </FormControl>
            </div>
          </Box>
        </div>
      </div>
      <div className="buttonAdicionar">
        <Button
          variant="contained"
          onClick={() => {
            validacao1();
          }}
        >
          {t("adicionar")}
        </Button>
      </div>
    </div>
  );
}

export default CriarClienteAdmin;
