import Cookies, { set } from "js-cookie";
import { useTranslation } from "react-i18next";

import { Colors } from "../../values/Colors";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faPen,
  faStop,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  InputAdornment,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import bcrypt from "bcryptjs";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { createSearchParams, Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  getUtilizadoresId,
  putUtilizador,
} from "../../api/requests/Utilizadores";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { alteraPass, verificarNif } from "../../api/requests/Registo";
import Nav from "../../components/Nav";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { Scrollbar } from "react-scrollbars-custom";
import editarbtn from "../../images/BotaoEditar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import { PencilIcon } from "@heroicons/react/24/solid";

function AlterarDadosUtilizador() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { definicoes } = state || {};

  const [freeze, setFreeze] = useState(false);

  //Variáveis
  const [edicao, setEdicao] = useState(true);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  // Utilizador
  const [id, setId] = useState("");
  const [tokenu, setTokenU] = useState("");
  const [nome, setNome] = useState("");
  const [nif, setNif] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [morada, setMorada] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [Genero, setGenero] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const [foto, setFoto] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  //password
  const [palavraPasse, setPalavraPasse] = useState("");
  const [palavraPasse2, setPalavraPasse2] = useState("");

  //Validações
  const [erroPassword2, setErroPassword2] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroPalavraPasse2, setMensagemErroPalavraPasse2] =
    useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoPalavraPasse, setSucessoPalavraPasse] = useState("");
  const [sucessoPalavraPasse2, setSucessoPalavraPasse2] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const ref = useRef(null);

  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  //Validações
  //Entidade
  const [erroNome, setErroNome] = useState(false);
  const [erroNif, setErroNif] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [erroTelefone, setErroTelefone] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroCodigoPostal, setErroCodigoPostal] = useState(false);
  const [erroGenero, setErroGenero] = useState(false);

  //Mensagens de erro

  const [mensagemErroNif, setMensagemErroNif] = useState("");
  const [mensagemErroDataNascimento, setMensagemErroDataNascimento] =
    useState("");
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroMorada, setMensagemErroMorada] = useState("");
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [mensagemErroCodigoPostal, setMensagemErroCodigoPostal] = useState("");
  const [mensagemErroGenero, setMensagemErroGenero] = useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const idU = Cookies.get("uId");
    setId(idU);
    const token = Cookies.get("token");
    setTokenU(token);
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!idU || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    //get dados utilizador
    getUtilizadoresId(idU, {}).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setCarregamentoDadosCompleto(true);

        setDadosOriginais(dados);

        setId(dados.id_utilizador);
        setGenero(dados.genero);
        setNome(dados.nome);
        setEmail(dados.email);
        setTelefone(dados.contacto);
        if (dados.morada) {
          setMorada(dados.morada);
        }
        if (dados.codigo_postal) {
          setCodigoPostal(dados.codigo_postal);
        }

        if (dados.data_nascimento) {
          setDataNascimento(dados.data_nascimento.substring(0, 10));
        } else {
          setErroDataNascimento(false);
          setDataNascimento(null);
        }
        if (dados.foto) {
          setFoto(dados.foto);
        } else {
          setFoto([]);
        }

        if (dados.nif) {
          setNif(dados.nif);
        }
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }, []);

  function ChangePassword() {
    setFreeze(true);
    if (palavraPasse) {
      if (palavraPasse === palavraPasse2) {
        const hashedPassword = bcrypt.hashSync(
          palavraPasse,
          "$2a$10$CwTycUXWue0Thq9StjUM0u"
        );
        alteraPass(
          {
            newPwd: hashedPassword,
          },
          id,
          tokenu
        ).then((res) => {
          if (res.success) {
            setTimeout(function () {
              setFreeze(false);
              toast.success(t("passAlterada"));
              navigate("/login");
            }, 3000);
          } else {
            setTimeout(function () {
              setFreeze(false);
              toast.error(t("alteraErro"));
            }, 3000);
          }
        });
      } else {
        setErroPassword(true);
        setErroPassword2(true);
        toast.error(t("iguaisPass"));
        setFreeze(false);
      }
    } else {
      setErroPassword(true);
      setErroPassword2(true);
      setMensagemErroPalavraPasse(t("passVazia"));
      setMensagemErroPalavraPasse2(t("passVazia"));
      toast.error(t("vaziaPass"));
      setFreeze(false);
    }
  }

  useEffect(() => {
    if (palavraPasse) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = palavraPasse.length;
      const uppercasePassword = uppercaseRegExp.test(palavraPasse);
      const lowercasePassword = lowercaseRegExp.test(palavraPasse);
      const digitsPassword = digitsRegExp.test(palavraPasse);
      const specialCharPassword = specialCharRegExp.test(palavraPasse);
      const minLengthPassword = minLengthRegExp.test(palavraPasse);

      if (passwordLength === 0) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("vazia"));
      } else if (!uppercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("uppercase"));
      } else if (!lowercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("lowercase"));
      } else if (!digitsPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracternumb"));
      } else if (!specialCharPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracterespecial"));
      } else if (!minLengthPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("8caracteres"));
      } else {
        setErroPassword(false);
        setMensagemErroPalavraPasse("");
        setSucessoPalavraPasse("success");
      }
    }

    if (palavraPasse2) {
      if (palavraPasse2 != palavraPasse) {
        setErroPassword(true);
        setErroPassword2(true);
        setMensagemErroPalavraPasse(t("passesigual"));
        setMensagemErroPalavraPasse2(t("passesigual"));
      } else {
        setErroPassword(false);
        setErroPassword2(false);
        setMensagemErroPalavraPasse("");
        setMensagemErroPalavraPasse2("");
        setSucessoPalavraPasse2("success");
      }
    }
  }, [palavraPasse2, palavraPasse]);

  async function validacao() {
    if (
      nome &&
      nif &&
      dataNascimento &&
      email &&
      telefone &&
      morada &&
      codigoPostal &&
      Genero
    ) {
      let confirmarDadosPreenchidos = true;
      // Validação nome
      if (nome.length < 0) {
        confirmarDadosPreenchidos = false;
        setErroNome(true);
        setMensagemNome(t("validacaoNome"));
      }
      if (!Genero) {
        confirmarDadosPreenchidos = false;
        setErroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }
      // Validação nif
      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }
      // Validação data de nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
      }
      let dataNascimentoDate = new Date(dataNascimento);
      let hoje = new Date();
      let idadeMinima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos <= idadeMinima ||
        (diffAnos === idadeMinima && diffMeses > 0) ||
        (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
      ) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("menor16"));
      }
      // Validação email
      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmail(true);
        setMensagemErroEmail(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefone)) {
        confirmarDadosPreenchidos = false;
        setErroTelefone(true);
        setMensagemErroTelefone(t("validacaoTelemovel"));
      }
      if (codigoPostal.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }
      if (confirmarDadosPreenchidos) {
        setFreeze(true);
        var datan = new Date(dataNascimento).toISOString().split("T")[0];

        putUtilizador({
          id_utilizador: id,
          nome: nome,
          data_nascimento: datan,
          nif: nif,
          genero: Genero,
          email: email,
          contacto: telefone,
          morada: morada,
          codigo_postal: codigoPostal,
          foto: foto,
        })
          .then((res) => {
            if (res.success) {
              setFreeze(false);
              setEdicao(!edicao);
              //Scroll para o início da página
              window.scrollTo(0, 0);
              toast.success(t("dadosEditadosSucesso"));
              Cookies.set("uData", "true", {
                expires: 0.0416666667,
              });
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            } else {
              if (res.status == 500) {
                navigate("/500");
              }
              setFreeze(false)
              toast.error(res.error);
            }
          })
          .catch((error) => {
            setFreeze(false)
            //toast.warn(t("erroWebservice"));
          });
      } else {
        setFreeze(false)
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      //Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });
      if (!Genero) {
        confirmarDadosPreenchidos = false;
        setErroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }

      //Validação data nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("validacaoData"));
      }
      //Validação do email
      if (!email) {
        setErroEmail(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          );
        if (!validacaoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
      //Validação do telefone
      if (!telefone) {
        setErroTelefone(true);
      } else {
        if (!isValidPhoneNumber(telefone)) {
          setErroTelefone(true);
          setMensagemErroTelefone(t("validacaoTelemovel"));
        }
      }
      //Validação da rua
      if (!morada) {
        setErroMorada(true);
      }

      //Validação do código postal
      if (codigoPostal == "" || codigoPostal.length == 0) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal.length < 7) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }

      //Validação da foto
      // if (foto.length == 0) {
      //   document.getElementById("fotoPiscina").style.borderColor =
      //     Colors.inativo;
      //   document.getElementById("tituloFotoPiscina").style.color =
      //     Colors.inativo;
      // }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    //Colocar os dados originais
    setNome(dadosOriginais.nome);
    setGenero(dadosOriginais.genero);
    setNif(dadosOriginais.nif);
    setDataNascimento(dadosOriginais.dataNascimento.substring(0, 10));
    setEmail(dadosOriginais.email);
    setTelefone(dadosOriginais.contacto);
    setMorada(dadosOriginais.morada);
    setCodigoPostal(dadosOriginais.codigo_postal);
    setFoto(dadosOriginais.foto);

    //Limpar erros e mensagens de erro
    setErroNome(false);
    setErroNif(false);
    setErroGenero(false);
    setErroDataNascimento(false);
    setErroEmail(false);
    setErroTelefone(false);
    setErroMorada(false);
    setErroCodigoPostal(false);

    setMensagemErroNif("");
    setMensagemErroGenero("");
    setMensagemErroDataNascimento("");
    setMensagemErroEmail("");
    setMensagemErroTelefone("");
    setMensagemErroCodigoPostal("");
  }

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />

      <Header />
      <div className="listPages">
        <h3 className="titleList">{t("dadosUtilizador")}</h3>

        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}
        <div className="divTabelaM">
          <Scrollbar style={{ height: "85%" }}>
            {carregamentoDadosCompleto ? (
              <div className="divForm">
                {/* {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null} */}
                {/* <div className="formPage2" style={{ marginTop: "10px" }}> */}
                {/* <div className="align-left">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  size={window.innerWidth < 600 ? "small" : "medium"}
                  onClick={() => {
                    if (definicoes == 1) {
                      navigate("/definitions");
                    } else if (definicoes == 0) {
                      navigate("/myfamily");
                    } else {
                      toast.warn(t("preenchaDados"));
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div> */}
                {/* <div className="align-right">
              <h1 className="dashboardTitle">{t("editarDados")}</h1>
            </div> */}
                {/* </div> */}

                {/* <div className="formPage2">
              {window.innerWidth >= 600 ? (
                <Box className="formTextField">
                  <div className="cardsDivPiscina">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div> */}

                <div className="formPage2">
                  <Box className="formTextField">
                    <div>
                      {/* Nome */}
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="text"
                          disabled={edicao}
                          variant="standard"
                          label={t("nome")}
                          value={nome}
                          error={erroNome}
                          inputProps={{ maxLength: 200 }}
                          onChange={(e) => {
                            setNome(e.target.value);
                            setErroNome(false);
                          }}
                        />
                      </FormControl>
                      {/* NIF */}
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          required
                          type="number"
                          disabled={edicao}
                          variant="standard"
                          label={t("nif")}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 9);
                          }}
                          inputProps={{ maxLength: 9 }}
                          value={nif}
                          error={erroNif}
                          helperText={mensagemErroNif}
                          onChange={(e) => {
                            setNif(e.target.value);
                            setErroNif(false);
                          }}
                        />
                      </FormControl>
                      {/* Data de Nascimento */}
                      <FormControl className="formTextField3" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            disableFuture
                            label={t("dataNascimento") + " *"}
                            openTo="day"
                            views={["year", "month", "day"]}
                            value={dataNascimento}
                            error={erroDataNascimento}
                            minDate={new Date(1900, 0, 1)}
                            disabled={edicao}
                            helperText={mensagemErroDataNascimento}
                            onChange={(e) => {
                              setDataNascimento(e);
                              setErroDataNascimento(false);
                              setMensagemErroDataNascimento("");
                            }}
                            renderInput={(params) => (
                              <TextField variant="standard" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {/* Genero */}
                      <FormControl
                        className="formTextField3"
                        fullWidth
                        disabled={edicao}
                      >
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{ textAlign: "start" }}
                        >
                          {t("genero")}
                        </FormLabel>

                        <RadioGroup
                          row
                          label={t("genero")}
                          defaultValue={Genero}
                          name="genero"
                          value={Genero}
                          onChange={(e) => {
                            setGenero(e.target.value);
                            seterroGenero(false);
                          }}
                        >
                          <FormControlLabel
                            value="feminino"
                            control={<Radio size="small" />}
                            label={t("F")}
                          />
                          <FormControlLabel
                            value="masculino"
                            control={<Radio size="small" />}
                            label={t("M")}
                          />
                          <FormControlLabel
                            value="outro"
                            control={<Radio size="small" />}
                            label={t("O")}
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* Email */}
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          required
                          type="text"
                          disabled={edicao}
                          variant="standard"
                          label={t("email")}
                          value={email}
                          error={erroEmail}
                          helperText={mensagemErroEmail}
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => {
                            setEmail(e.target.value.toLocaleLowerCase());
                            setErroEmail(false);
                            setMensagemErroEmail("");
                          }}
                        />
                      </FormControl>
                      {/* Telefone */}
                      <FormControl className="formTextField3" fullWidth>
                        <PhoneInput
                          disabled={edicao}
                          defaultCountry="PT"
                          labels={
                            i18n.language == "pt"
                              ? pt
                              : i18n.language == "es"
                              ? es
                              : en
                          }
                          value={telefone}
                          error={erroTelefone}
                          helperText={mensagemErroTelefone}
                          inputProps={{ maxLength: 20 }}
                          onChange={(e) => {
                            setTelefone(e);
                            setErroTelefone(false);
                            setMensagemErroTelefone("");
                          }}
                          inputComponent={PhoneNumber}
                        />
                      </FormControl>
                      {/* Rua */}
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          required
                          type="text"
                          disabled={edicao}
                          variant="standard"
                          label={t("morada")}
                          value={morada}
                          error={erroMorada}
                          inputProps={{ maxLength: 200 }}
                          onChange={(e) => {
                            setMorada(e.target.value);
                            setErroMorada(false);
                          }}
                        />
                      </FormControl>
                      {/* Código Postal */}
                      <FormControl className="formTextField3" fullWidth>
                        <PatternFormat
                          type="text"
                          disabled={edicao}
                          variant="standard"
                          {...{
                            label: t("codigoPostal"),
                            variant: "standard",
                          }}
                          value={codigoPostal}
                          customInput={TextField}
                          required
                          format="####-###"
                          error={erroCodigoPostal}
                          helperText={mensagemErroCodigoPostal}
                          onChange={(e) => {
                            setCodigoPostal(e.target.value);
                            setErroCodigoPostal(false);
                            setMensagemErroCodigoPostal("");
                          }}
                        />
                      </FormControl>
                      {/* Foto */}
                      <FormControl className="formTextField3" fullWidth>
                        <p
                          id="tituloFotoPiscina"
                          style={{
                            marginTop: 10,
                            color: Colors.textFields,
                            fontSize: 15,
                            fontWeight: "normal",
                            textAlign: "left",
                          }}
                        >
                          {t("foto")}
                        </p>
                        <label
                          id="fotoPiscina"
                          htmlFor="file-upload"
                          className="custom-file-upload"
                        >
                          <i className="fa fa-cloud-upload"></i>
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ paddingTop: 4, paddingRight: 4 }}
                          />{" "}
                          {t("carregarImagem")}
                        </label>
                        <input
                          disabled={edicao}
                          id="file-upload"
                          style={{ paddingTop: 0 }}
                          type="file"
                          accept="image/*"
                          onChange={async (e) => {
                            document.getElementById(
                              "fotoPiscina"
                            ).style.borderColor = Colors.ativo;
                            document.getElementById(
                              "tituloFotoPiscina"
                            ).style.color = Colors.textFields;
                            const file = e.target.files[0];

                            const reader = new FileReader();

                            reader.readAsDataURL(file);

                            reader.onload = () => {
                              setFoto(reader.result);
                            };
                          }}
                        ></input>
                        {/* Imagem carregada */}
                        <div>
                          {foto.length > 0 && (
                            <img
                              style={{ marginTop: 10 }}
                              width={150}
                              height={150}
                              src={foto}
                            />
                          )}
                        </div>
                      </FormControl>
                    </div>
                  </Box>
                </div>

                {/* Cancelar edição */}
                <Dialog
                  open={cancelarEdicao}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      width: 452,
                      height: 186,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {t("cancelarEdicao")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("textoCancelarEdicao")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        cancelar();
                      }}
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClosePopupCancelar();
                      }}
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                }}
              >
                <CircularProgress disableShrink />
              </div>
            )}
          </Scrollbar>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {edicao ? (
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: `url(${editarbtn})`,
                  width: 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  scrollToDiv();
                }}
              >
                <PencilIcon
                  style={{ width: "15px", color: "white", marginRight: "10px" }}
                />
                {t("editar")}
              </Button>
            ) : (
              <div>
                <Button
                  className="btnGuardar"
                  variant="contained"
                  style={{
                    backgroundImage: `url(${guardarbtn})`,

                    height: 40,
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={() => {
                    validacao();
                  }}
                >
                  {t("guardar")}
                </Button>
                <Button
                  className="btnGuardar"
                  variant="contained"
                  style={{
                    backgroundImage: `url(${cancelarbtn})`,
                    width: 218,
                    height: 40,
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={() => {
                    handleClickOpenPopupCancelar();
                  }}
                >
                  {t("cancelar")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlterarDadosUtilizador;
