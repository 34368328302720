import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box, flexbox, useTheme } from "@mui/system";
import { Scrollbar } from "react-scrollbars-custom";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import {
  getInscricoesId,
  getUtilizadoresId,
} from "../../api/requests/Utilizadores";
import { getPiscinas } from "../../api/requests/Piscinas";
import { Search } from "../../components/DataTable";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Nav from "../../components/Nav";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFaceFrown,
  faFaceSmile,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import { Card, CardContent } from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import { format, parseISO } from "date-fns";
import { proximasMarcacoes } from "../../api/requests/Marcacoes";
import { getQrCodes } from "../../api/requests/Qrcode";
import { toast } from "react-toastify";
import { verificarPagamentosEmDia } from "../../api/requests/Pagamentos";

function MeusQr() {
  //Traduções
  const { t, i18n } = useTranslation();
  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState("");
  const [inscritas, setInscritas] = useState([]);
  const [piscinasCopy, setPiscinasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);

  const [idEscolhida, setIdEscolhida] = useState("");
  const [marcacoes, setMarcacoes] = useState([]);
  const [qrCode, setQrCode] = useState("");
  const [qrCodeStatus, setQrCodeStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function goBack() {
    navigate("/dashboard_customer");
  }

  //Navigator
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      verificarPagamentosEmDia().then((res) => {
        if (res.data.success) {
          navigate("/dashboard_customer");
        }
      });
    }, 20);
  }, []);
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    proximasMarcacoes().then((res) => {
      if (res.success) {
        let arrayFinal = [];
        let dados = res.data.data;

        dados.forEach((element) => {
          const dataParts = element.dia.split("/");
          const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
          const data = new Date(dataFormatada);
          const dataInicio = `${data.toISOString().substr(0, 10)}T${
            element.horaInicio
          }`;
          const dataFim = `${data.toISOString().substr(0, 10)}T${
            element.horaFim
          }`;

          let titulo = element.nome;
          if (titulo == "Marcação de nado livre") {
            titulo = t("marcacaoNadoLivre");
          } else if (titulo == "Natação para adultos") {
            titulo = t("natacao_adultos");
          } else if (titulo == "Natação para bebés") {
            titulo = t("natacao_bebes");
          } else if (titulo == "Natação para idosos") {
            titulo = t("natacao_idosos");
          } else if (titulo == "Natação para grávidas") {
            titulo = t("natacao_gravidas");
          } else if (titulo == "Aulas de Hidroginástica") {
            titulo = t("hidroginastica");
          } else if (titulo == "Aulas de Hidrobike") {
            titulo = t("hidrobike");
          } else if (titulo == "Aulas de Hidroterapia") {
            titulo = t("hidroterapia");
          } else if (titulo == "Aulas de Hidropower") {
            titulo = t("hidropower");
          }else if (titulo == "Escola de Natação") {
            titulo = t("escolaNatacao");
          }
          if (element.cancelado != 1) {
            const linha = {
              title: titulo,
              dia: element.dia,
              hI: element.horaInicio,
              hF: element.horaFim,
              description: element.nomePiscina,
              id_filho: element.id_filho,
              id_piscina: element.id_piscina,
              id_turma: element.id_turma,
              id_pack_cliente: element.id_pack_cliente,
              start: dataInicio,
              end: dataFim,
              nome_turma: element.nome_turma,
              color: element.id_turma != null ? "#ff6564" : "#84b6f4",
            };
            if (element.tipo2 != 2) {
              arrayFinal.push(linha);
            }

            titulo = "";
          }
        });
        //Filtrar apenas para marcações futuras
        const hoje = new Date().toISOString().substr(0, 10);

        const marcacoesFuturas = arrayFinal.filter((marcacao) => {
          return marcacao.start >= hoje;
        });

        setMarcacoes(marcacoesFuturas);
      }
    });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Dialog
        open={loading}
        onClose={() => {
          setLoading(false);
          setQrCodeStatus(false);
          setQrCode("");
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          style={{
            width: "452px",
            height: "220px",
            top: "448px",
            left: "90%",
            borderradius: "10px",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {qrCodeStatus && (
            <DialogContentText>{t("erroQrCode")}</DialogContentText>
          )}
          {!qrCodeStatus && !qrCode ? (
            <CircularProgress
              disableShrink
              style={{
                marginTop: 20,
              }}
            />
          ) : (
            <img src={qrCode} />
          )}
        </DialogContent>
      </Dialog>

      <Nav />

      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {/* <h1
            className="dashboardTitle"
            style={{ paddingTop: window.innerWidth > 800 ? null : "50px" }}
          >
            {t("teusqr")}
          </h1> */}

        <div className="divTabela">
          <Paper
            elevation={0}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 32,
              backgroundColor: "transparent",
              overflow: "auto",
              maxHeight: "92%",
            }}
          >
            <h3 className="titleMarcar">{t("teusqr")}</h3>
            {marcacoes.length == 0 && (
              <div className="cardsDivMarcar">
                <Button
                  className="cardDashboardMarcar2"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  disabled
                >
                  <div style={{ display: "block" }}>
                    <FontAwesomeIcon className="iconCards" icon={faFaceFrown} />
                  </div>
                  <span
                    style={{
                      marginTop: "8px",
                      fontSize: isMobile ? "10px" : "14px",
                    }}
                  >
                    {t("semqrcodes")}
                  </span>
                </Button>
              </div>
            )}
            <Scrollbar
              style={{ height: 600, display: "flex", marginRight: "35%" }}
            >
              <List
                style={{
                  marginTop: 5,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                {marcacoes.map((element) => (
                  <Card
                    onClick={() => {
                      setLoading(true);
                      getQrCodes({
                        id_filho:
                          element.id_filho == null ? 0 : element.id_filho,
                        id_piscina: element.id_piscina,
                        data: element.dia,
                        horaInicio: element.hI,
                        horaFim: element.hF,
                        id_pack: element.id_pack_cliente,
                        id_turma: element.id_turma,
                      }).then((res) => {
                        if (res.success) {
                          setQrCode(res.data.qrcode);
                        } else {
                          setQrCodeStatus(true);
                        }
                      });
                      setLoading(false)
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      cursor: "pointer",
                      backgroundColor: "#00000",
                      margin: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#00000",
                        backgroundColor: "#C2D5FE",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        padding: "10px 20px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {element.title}{" "}
                        {element.nome_turma != undefined
                          ? " - " + element.nome_turma
                          : null}
                      </Typography>
                    </Box>
                    <CardContent
                      sx={{ flex: "1 0 auto", cursor: "pointer", padding: 2 }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                      >
                        {format(parseISO(element.start), "dd/MM/yyyy")}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                      >
                        {format(parseISO(element.start), "HH:mm")}h -{" "}
                        {format(parseISO(element.end), "HH:mm")}h
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                      >
                        {element.description}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                      >
                        {element.cidade}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </List>
            </Scrollbar>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default MeusQr;
