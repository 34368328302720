import {
  Paper,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  unstable_composeClasses,
  Popover,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  faEuroSign,
  faFileInvoiceDollar,
  faFilePen,
  faArrowLeft,
  faTags,
  faQuestion,
  faCalendarDays,
  faPlus,
  faMapLocationDot,
  faLink,
  faPhone,
  faPersonSwimming,
  faWaterLadder,
  faPersonDrowning,
  faWater,
  faPersonPregnant,
  faBaby,
  faPersonCane,
  faBicycle,
  faDumbbell,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SimpleImageSlider from "react-simple-image-slider";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import FooterExterno from "../components/FooterExterno";
import { getPiscinasId } from "../api/requests/Piscinas";

function PoolPage() {
  //mudar para o diretorio de piscinas
  function goBack() {
    navigate("/poolslist");
  }

  function goRegister() {
    navigate("/register");
  }

  //variaveis

  const { id } = useParams();

  //Piscina
  const [paisPiscinaa, setPaisPiscina] = useState("");

  const [nomePiscina, setNomePiscina] = useState("");
  const [emailPiscina, setEmailPiscina] = useState("");
  const [telefonePiscina, setTelefonePiscina] = useState("");
  const [telefone2Piscina, setTelefone2Piscina] = useState("");
  const [ruaPiscina, setRuaPiscina] = useState("");
  const [codigoPostalPiscina, setCodigoPostalPiscina] = useState("");
  const [cidadePiscina, setCidadePiscina] = useState("");
  const [distritoPiscina, setDistritoPiscina] = useState("");
  const [latitudePiscina, setLatitudePiscina] = useState(0.0);
  const [longitudePiscina, setLongitudePiscina] = useState(0.0);
  const [fotoPiscina, setFotoPiscina] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [idFoto1, setIdFoto1] = useState("");
  const [foto2, setFoto2] = useState([]);
  const [idFoto2, setIdFoto2] = useState("");
  const [foto3, setFoto3] = useState([]);
  const [idFoto3, setIdFoto3] = useState("");
  const [foto4, setFoto4] = useState([]);
  const [idFoto4, setIdFoto4] = useState("");
  const [foto5, setFoto5] = useState([]);
  const [idFoto5, setIdFoto5] = useState("");
  const [foto6, setFoto6] = useState([]);
  const [idFoto6, setIdFoto6] = useState("");
  //Modalidades
  const [natacaoAdultos, setNatacaoAdultos] = useState(false);
  const [natacaoBebes, setNatacaoBebes] = useState(false);
  const [natacaoIdosos, setNatacaoIdosos] = useState(false);
  const [hidroginastica, setHidroginastica] = useState(false);
  const [escolaNatacao, setEscolaNatacao] = useState(false);
  const [hidrobike, setHidrobike] = useState(false);
  const [natacaoGravidas, setNatacaoGravidas] = useState(false);
  const [hidroterapia, setHidroterapia] = useState(false);
  const [nadoLivre, setNadoLivre] = useState(false);
  const [equipasFederadas, setEquipasFederadas] = useState(false);
  const [hidropower, setHidropower] = useState(false);
  //Horarios
  const [horario, setHorario] = useState([]);
  const [horario2, setHorario2] = useState([]);
  //Links uteis
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");

  //Dialogs
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);

  const [open6, setOpen6] = React.useState(false);
  const handleOpen6 = () => setOpen6(true);
  const handleClose6 = () => setOpen6(false);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);
  //Mapa

  useEffect(() => {
    getPiscinasId(id, {})
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;

          setNomePiscina(dados.nome);
          setEmailPiscina(dados.email);
          setTelefonePiscina(dados.contacto1);
          setTelefone2Piscina(dados.contacto2);
          setRuaPiscina(dados.morada);
          setCodigoPostalPiscina(dados.codigo_postal);
          setCidadePiscina(dados.cidade);
          setDistritoPiscina(dados.distrito);
          let paisPiscina = dados.pais.split("-");
          setPaisPiscina({ value: paisPiscina[0], label: paisPiscina[1] });
          setLatitudePiscina(parseFloat(dados.latitude));
          setLongitudePiscina(parseFloat(dados.longitude));

          setFotoPiscina(dados.foto_perfil_piscina);

          dados.galeria.forEach((element) => {
            if (element.ordem == 1) {
              setFoto1(element.foto);
              setIdFoto1(element.id_imagem);
            }
            if (element.ordem == 2) {
              setFoto2(element.foto);
              setIdFoto2(element.id_imagem);
            }
            if (element.ordem == 3) {
              setFoto3(element.foto);
              setIdFoto3(element.id_imagem);
            }
            if (element.ordem == 4) {
              setFoto4(element.foto);
              setIdFoto4(element.id_imagem);
            }
            if (element.ordem == 5) {
              setFoto5(element.foto);
              setIdFoto5(element.id_imagem);
            }
            if (element.ordem == 6) {
              setFoto6(element.foto);
              setIdFoto6(element.id_imagem);
            }
          });

          //Modalidades
          setNatacaoAdultos(dados.aulas_adultos);
          setNatacaoBebes(dados.aulas_bebes);
          setNatacaoIdosos(dados.aulas_idoso);
          setHidroginastica(dados.hidroginastica);
          setEscolaNatacao(dados.escola_natacao);
          setHidrobike(dados.hidrobike);
          setNatacaoGravidas(dados.aulas_gravidas);
          setHidroterapia(dados.hidro_terapia);
          setNadoLivre(dados.nado_livre);
          setEquipasFederadas(dados.equipas_federadas);
          setHidropower(dados.hidropower);
          //Horarios
          if (dados.horarios) {
            setHorario2(dados.horarios);
          }
          //Links uteis
          if (dados.link1) {
            setLink1(dados.link1);
          }
          if (dados.link2) {
            setLink2(dados.link2);
          }
          if (dados.link2) {
            setLink3(dados.link3);
          }
        } else {
          // if (res.status == 500) {
          //   navigate("/500");
          // }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        // toast.warn(t("erroWebservice"));
      });
  }, []);

  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const containerStyle2 = {
    width: "300px",
    height: "300px",
  };

  var center = {
    lat: latitudePiscina,
    lng: longitudePiscina,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAhwf3uzO-aTYJWm-TkeO6Rvf5NoTB3jJs",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();

  const [isActive, setIsActive] = useState(false);

  var slideImages = [
    { url: foto1 },
    { url: foto2 },
    { url: foto3 },
    { url: foto4 },
    { url: foto5 },
    { url: foto6 },
  ];

  var results = slideImages.filter((element) => {
    return element.url != "";
  });

  //Navigator
  const navigate = useNavigate();

  // Defina o estado para controlar a abertura/fechamento do popover
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // // Manipuladores de eventos para abrir/fechar o popover
  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);

  // };

  // const handlePopoverClose = () => {

  //     setAnchorEl(null);
  //  // Tempo de atraso para fechar o popover
  // };

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [popoverText, setPopoverText] = React.useState("");
  const popoverTexts = {
    nadoLivre: t("informacoes_adicionais_nado_livre"),
    natacaoAdultos: t("informacoes_adicionais_natacao_adultos"),
    natacaoBebes: t("informacoes_adicionais_natacao_bebes"),
    hidroginastica: t("informacoes_adicionais_hidroginastica"),
    escolaNatacao: t("informacoes_adicionais_escolaNatacao"),
    hidrobike: t("informacoes_adicionais_hidrobike"),
    hidropower: t("informacoes_adicionais_hidropower"),
    hidroterapia: t("informacoes_adicionais_hidroterapia"),
    natacaoGravidas: t("informacoes_adicionais_natacao_gravidas"),
    equipasFederadas: t("informacoes_adicionais_federadas"),
    natacaoIdosos: t("informacoes_adicionais_idosos"),
  };

  const handleMouseEnter = (text) => {
    setIsPopoverOpen(true);
    setPopoverText(text);
  };

  const handleMouseLeave = () => {
    setIsPopoverOpen(false);
    setPopoverText("");
  };
  useEffect(() => {}, [isPopoverOpen, popoverText]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="bodyPage2">
      <div className="topleft">
        <IconButton onClick={goBack} size="medium">
          {" "}
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        </IconButton>
      </div>
      <div className="topright">
        <IconButton onClick={handleOpenHelp} size="medium">
          {" "}
          <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
        </IconButton>
      </div>
      <div className="center">
        {isMobile ? (
          <div className="topleft">
            <IconButton onClick={goBack} size={isMobile ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
        ) : null}
        {isMobile ? (
          <div className="topright">
            <IconButton
              onClick={handleOpenHelp}
              size={isMobile ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon>
            </IconButton>
          </div>
        ) : null}
        <div className="row1">
          {/* Info da piscina */}
          <Stack direction={"column"} spacing={2} sx={{ margin: "4%" }}>
            <h5 className="titulo">{nomePiscina}</h5>
            <h5 className="subtitulo">
              {ruaPiscina +
                " " +
                codigoPostalPiscina +
                " " +
                cidadePiscina +
                " " +
                paisPiscinaa.label}
            </h5>
          </Stack>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{ margin: isMobile ? "2%" : "2%" }}
          >
            <Avatar
              src={fotoPiscina}
              sx={{
                width: isMobile ? 90 : 120,
                height: isMobile ? 90 : 120,
              }}
            />
            {foto1.length > 0 ? (
              <Button onClick={handleOpen2} size="small">
                <a style={{ fontSize: isMobile ? 8 : 10 }}>{t("maisfotos")}</a>
              </Button>
            ) : null}
          </Stack>
        </div>

        {/* Modalidades */}
        <Paper
          elevation={0}
          style={{
            maxHeight: "40%",
            overflow: "auto",
            marginTop: 40,
            backgroundColor: "transparent",
          }}
        >
          <Box
            sx={{
              flexGrow: 0.1,
              justifyContent: "center",
              margin: "10px",
              display: "flex",
            }}
          >
            {nadoLivre && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#27345b",
                    height: "80px",
                    width: "80px",
                    margin: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleMouseEnter(popoverTexts.nadoLivre)}
                >
                  <FontAwesomeIcon
                    className="pageIcon4"
                    icon={faWaterLadder}
                    size="2x"
                  />
                  <Typography variant="body2" className="conteudo2">
                    {t("nado_livre")}
                  </Typography>
                </Box>

                {isPopoverOpen == true ? (
                  <Popover
                    open={isPopoverOpen}
                    onClose={handleMouseLeave}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "10px",
                        width: window.innerWidth / 3,
                        height: 200,
                      }}
                    >
                      <Typography>{popoverText}</Typography>
                    </Box>
                  </Popover>
                ) : null}
              </div>
            )}

            {natacaoAdultos && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.natacaoAdultos)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faPersonSwimming}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("natacao_adultos")}
                </Typography>
              </Box>
            )}
          {escolaNatacao && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.escolaNatacao)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faPersonSwimming}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("escolaNatacao")}
                </Typography>
              </Box>
            )}
            {natacaoBebes && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.natacaoAdultos)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faBaby}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("natacao_bebes")}
                </Typography>
              </Box>
            )}
            {natacaoIdosos && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.natacaoIdosos)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faPersonCane}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("natacao_idosos")}
                </Typography>
              </Box>
            )}
            {hidroginastica && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.hidroginastica)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faPersonDrowning}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("hidroginastica")}
                </Typography>
              </Box>
            )}
            {natacaoGravidas && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.natacaoGravidas)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faPersonPregnant}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("natacao_gravidas")}
                </Typography>
              </Box>
            )}
            {hidrobike && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.hidrobike)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faBicycle}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("hidrobike")}
                </Typography>
              </Box>
            )}
            {hidropower && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.hidropower)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faDumbbell}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("hidropower")}
                </Typography>
              </Box>
            )}

            {equipasFederadas && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.equipasFederadas)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faWater}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("equipas_federadas")}
                </Typography>
              </Box>
            )}
            {hidroterapia && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#27345b",
                  height: "80px",
                  width: "80px",
                  margin: "10px",
                }}
                onClick={() => handleMouseEnter(popoverTexts.hidroterapia)}
              >
                <FontAwesomeIcon
                  className="pageIcon4"
                  icon={faUserNurse}
                  size="2x"
                />
                <Typography variant="body2" className="conteudo2">
                  {t("hidroterapia")}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
        {/* Cards Horario, Direçoes Links uteis, contactos */}
        <div className="containerP">
          <div className="containerrow1">
            <div
              className="cardpiscina"
              onClick={() => {
                const downloadFile = (base64String, fileName) => {
                  // Converte a string Base64 em um objeto Blob
                  const byteCharacters = atob(base64String);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const fileContent = new Uint8Array(byteNumbers);
                  const fileBlob = new Blob([fileContent], {
                    type: "application/pdf",
                  });

                  // Cria um link de download para o objeto Blob
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(fileBlob);
                  link.download = fileName;

                  // Adiciona o atributo 'target="_blank"' ao link
                  link.target = "_blank";

                  // Dispara um evento de clique no link
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                };

                // Exemplo de uso
                const base64String = horario2;
                const fileName = `${nomePiscina}.pdf`;
                downloadFile(base64String, fileName);
              }}
            >
              <FontAwesomeIcon className="pageIconPool" icon={faCalendarDays} />
              <h1 className="cardspool">{t("horarios")}</h1>
            </div>
            <div className="cardpiscina" onClick={handleOpen3}>
              <FontAwesomeIcon
                className="pageIconPool"
                icon={faMapLocationDot}
              />
              <h2 className="cardspool">{t("direcoes")}</h2>
            </div>
            <div className="cardpiscina" onClick={handleOpen6}>
              <FontAwesomeIcon className="pageIconPool" icon={faLink} />
              <h2 className="cardspool">{t("linksuteis")}</h2>
            </div>
            <div className="cardpiscina" onClick={handleOpen5}>
              <FontAwesomeIcon className="pageIconPool" icon={faPhone} />
              <h2 className="cardspool">{t("contactos")}</h2>
            </div>
          </div>

          <div className="containerrow3">
            {/* <div className="cardpiscina2" onClick={goRegister}>
            <h1 className="cardspool2">{t("registo")}</h1>
          </div> */}

            <button
              onClick={() => {
                navigate("/register");
              }}
              className="shadow__btn"
            >
              {t("registo")}
            </button>
          </div>

          {/* Janelas conteudo */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("modalidades")}
            </DialogTitle>
            <DialogContent></DialogContent>
          </Dialog>
          {!isMobile ? (
            <Dialog
              open={open2}
              onClose={handleClose2}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{t("galeria")}</DialogTitle>
              <DialogContent>
                <div>
                  {results.length == 0 ? (
                    <p>{t("naotemfotos")}</p>
                  ) : (
                    <SimpleImageSlider
                      width={500}
                      height={500}
                      images={results}
                      showBullets={true}
                      showNavs={true}
                    />
                  )}
                </div>
              </DialogContent>
            </Dialog>
          ) : (
            <Dialog
              open={open2}
              onClose={handleClose2}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{t("galeria")}</DialogTitle>
              <DialogContent>
                <div>
                  <div>
                    {results.length == 0 ? (
                      <p>{t("naotemfotos")}</p>
                    ) : (
                      <SimpleImageSlider
                        width={300}
                        height={300}
                        images={results}
                        showBullets={true}
                        showNavs={true}
                      />
                    )}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          )}
          <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("direcoes")}</DialogTitle>
            <DialogContent>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={
                    !isMobile ? containerStyle : containerStyle2
                  }
                  center={center}
                  zoom={15}
                  // onLoad={onLoad}
                  // onUnmount={onUnmount}
                >
                  <Marker position={center}></Marker>
                </GoogleMap>
              ) : null}
            </DialogContent>
          </Dialog>
          <Dialog
            open={openHelp}
            onClose={handleCloseHelp}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
            <DialogContent>
              <p className="ajuda">
                Nesta página podes encontrar alguma informação da piscina, no
                caso de quereres saber mais terás de te registar no GoPool,
                fazer login e inscrever-te na piscina.<br></br>
                Se tiveres algum problema podes entrar em contacto com a equipa
                de suporte GoPool
              </p>
            </DialogContent>
          </Dialog>

          <Dialog
            open={open5}
            onClose={handleClose5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("contactos")}</DialogTitle>
            <DialogContent>
              <div>
                <Stack direction={"row"} spacing={1} sx={{ marginTop: "10px" }}>
                  <p className="conteudoboldblue">{t("contactoprincipal")}</p>{" "}
                  <a className="conteudoblue" href={"tel:" + telefonePiscina}>
                    {" "}
                    {telefonePiscina}
                  </a>
                </Stack>
                {telefone2Piscina != "" && (
                  <Stack direction={"row"} spacing={1}>
                    <p className="conteudoboldblue">
                      {t("contactosecundario")}
                    </p>{" "}
                    <a
                      className="conteudoblue"
                      href={"tel:" + telefone2Piscina}
                    >
                      {" "}
                      {telefone2Piscina}
                    </a>
                  </Stack>
                )}

                <Stack direction={"row"} spacing={1}>
                  <p className="conteudoboldblue">{t("emailc")}</p>{" "}
                  <a className="conteudoblue" href={"mailto:" + emailPiscina}>
                    {" "}
                    {emailPiscina}
                  </a>
                </Stack>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            open={open6}
            onClose={handleClose6}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("linksuteis")}</DialogTitle>
            <DialogContent>
              <div>
                {link1 ? (
                  <Stack
                    direction={"row"}
                    spacing={0}
                    sx={{ marginTop: "10px" }}
                  >
                    <a
                      className="conteudoboldblue"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.location = link1;
                      }}
                    >
                      {link1}
                    </a>{" "}
                  </Stack>
                ) : (
                  <a>{t("semlinks")}</a>
                )}

                <Stack direction={"row"} spacing={0}>
                  <a
                    style={{ cursor: "pointer" }}
                    className="conteudoboldblue"
                    onClick={() => {
                      window.location = link2;
                    }}
                  >
                    {link2}
                  </a>{" "}
                </Stack>
                <Stack direction={"row"} spacing={0}>
                  <a
                    style={{ cursor: "pointer" }}
                    className="conteudoboldblue"
                    onClick={() => {
                      window.location = link3;
                    }}
                  >
                    {link3}
                  </a>{" "}
                </Stack>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <FooterExterno />
    </div>
  );
}
export default PoolPage;
