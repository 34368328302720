import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Scrollbar } from "react-scrollbars-custom";

import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { NumericFormat, PatternFormat } from "react-number-format";

import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import countryList from "react-select-country-list";

import { toast } from "react-toastify";

import "@react-pdf-viewer/core/lib/styles/index.css";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";

import { getEE, getEL, putEE } from "../../api/requests/Piscinas";
import Nav from "../../components/Nav";

function VerELA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idEL } = state || {};

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);
  const [nome, setNome] = useState("");
  const [codPostal, setCodPostal] = useState("");
  const [contacto, setContacto] = useState("");
  const [contrato, setContrato] = useState("");
  const [distrito, setDistrito] = useState("");
  const [cidade, setCidade] = useState("");
  const [email, setEmail] = useState("");
  const [fotoP, setFotoP] = useState([]);
  const [morada, setMorada] = useState("");
  const [nipc, setNipc] = useState("");
  const [pais, setPais] = useState("");

  const [erroNome, setErroNome] = useState(false);
  const [erroCodPostal, setErroCodPostal] = useState(false);
  const [erroContacto, setErroContacto] = useState(false);
  const [erroContrato, setErroContrato] = useState(false);
  const [erroDistrito, setErroDistrito] = useState(false);
  const [erroCidade, setErroCidade] = useState(false);

  const [erroEmail, setErroEmail] = useState(false);
  const [erroFotoP, setErroFotoP] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroNipc, setErroNipc] = useState(false);
  const [erroPais, setErroPais] = useState(false);

  const options = useMemo(() => countryList().getData(), []);

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [pdfData, setPdfData] = useState(null);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);

    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getEL({ id_piscina: idEL })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];

          setDadosOriginais(dados);
          setNome(dados.nome);
          setCidade(dados.cidade);
          setCodPostal(dados.codigo_postal);
          setContacto(dados.contacto);

          let contratoBase64 = dados.contrato;
       
          // Encontra a posição do primeiro caractere após a vírgula
          let posicaoVirgula = contratoBase64.indexOf(",") + 1;

          // Remove a parte inicial até a vírgula
          let contratoBase64SemHeader =
            contratoBase64.substring(posicaoVirgula);

          // Convertendo de base64 para o tipo original
          let contratoBlob = atob(contratoBase64SemHeader);
          let contratoArray = [];

          for (let i = 0; i < contratoBlob.length; i++) {
            contratoArray.push(contratoBlob.charCodeAt(i));
          }

          let contratoUint8Array = new Uint8Array(contratoArray);
          let contratoBlobAgain = new Blob([contratoUint8Array], {
            type: "pdf",
          });
          setContrato(contratoBlobAgain);
          if (dados.distrito === 1) {
            setDistrito("Aveiro");
          } else if (dados.distrito === 2) {
            setDistrito("Beja");
          } else if (dados.distrito === 3) {
            setDistrito("Braga");
          } else if (dados.distrito === 4) {
            setDistrito("Bragança");
          } else if (dados.distrito === 5) {
            setDistrito("Castelo Branco");
          } else if (dados.distrito === 6) {
            setDistrito("Coimbra");
          } else if (dados.distrito === 7) {
            setDistrito("Évora");
          } else if (dados.distrito === 8) {
            setDistrito("Faro");
          } else if (dados.distrito === 9) {
            setDistrito("Guarda");
          } else if (dados.distrito === 10) {
            setDistrito("Leiria");
          } else if (dados.distrito === 11) {
            setDistrito("Lisboa");
          } else if (dados.distrito === 12) {
            setDistrito("Portalegre");
          } else if (dados.distrito === 13) {
            setDistrito("Porto");
          } else if (dados.distrito === 14) {
            setDistrito("Região Autónoma dos Açores");
          } else if (dados.distrito === 15) {
            setDistrito("Região Autónoma da Madeira");
          } else if (dados.distrito === 16) {
            setDistrito("Santarém");
          } else if (dados.distrito === 17) {
            setDistrito("Setúbal");
          } else if (dados.distrito === 18) {
            setDistrito("Viana do Castelo");
          } else if (dados.distrito === 19) {
            setDistrito("Vila Real");
          } else if (dados.distrito === 20) {
            setDistrito("Viseu");
          }
          setEmail(dados.email);
          if (dados.foto_perfil) {
            setFotoP(dados.foto_perfil);
          }

          setMorada(dados.morada);
          setNipc(dados.nipc);

          setPais(dados.pais);

          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
    
        toast.warn(t("erroWebservice"));
      });
  }, [carregamentoDadosCompleto]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("verEL")}</h3>
        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}
        <div className="divTabelaM">
          <Scrollbar style={{ height: 495 }}>
            {carregamentoDadosCompleto ? (
              <div className="divForm">
                <div className="formPageSuspensao">
                  <Alert severity="error">{t("alteradados")}</Alert>
                </div>
                <div>
                  <div className="formPage2">
                    <Box
                      className="formTextField"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={true}
                            required
                            type="text"
                            variant="standard"
                            label={t("nome")}
                            value={nome}
                            error={erroNome}
                            onChange={(e) => {
                              setNome(e.target.value);
                              setErroNome(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="number"
                            disabled={true}
                            variant="standard"
                            label={t("nipc")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9);
                            }}
                            value={nipc}
                            error={erroNipc}
                            onChange={(e) => {
                              setNipc(e.target.value);
                              setErroNipc(false);
                            }}
                          />
                        </FormControl>
                        {/* Email */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("email")}
                            value={email}
                            error={erroEmail}
                            onChange={(e) => {
                              setEmail(e.target.value.toLocaleLowerCase());
                              setErroEmail(false);
                            }}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={true}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={contacto}
                            error={erroContacto}
                            onChange={(e) => {
                              setContacto(e);
                              setErroContacto(false);
                            }}
                            inputComponent={PhoneNumber}
                          />
                        </FormControl>
                        {/* morada */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("morada")}
                            value={morada}
                            error={erroMorada}
                            onChange={(e) => {
                              setMorada(e.target.value);
                              setErroMorada(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            type="text"
                            disabled={true}
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            format="####-###"
                            value={codPostal}
                            error={erroCodPostal}
                            onChange={(e) => {
                              setCodPostal(e.target.value);
                              setErroCodPostal(false);
                            }}
                          />
                        </FormControl>
                        {/* Cidade */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("cidade")}
                            value={cidade}
                            error={erroCidade}
                            onChange={(e) => {
                              setCidade(e.target.value);
                              setErroCidade(false);
                            }}
                          />
                        </FormControl>
                        {/* Distrito */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={true}
                            variant="standard"
                            label={t("distrito")}
                            value={distrito}
                            error={erroDistrito}
                            onChange={(e) => {
                              setDistrito(e.target.value);
                              setErroDistrito(false);
                            }}
                          />
                        </FormControl>
                        {/* País */}
                        <FormControl
                          className="formTextField3"
                          fullWidth
                          ref={ref}
                        >
                          <Autocomplete
                            label={t("pais")}
                            variant="standard"
                            options={options}
                            value={pais}
                            onChange={(event, newValue) => {
                              setPais(newValue.label);
                              setErroPais(false);
                            }}
                            disabled={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("pais")}
                                variant="standard"
                                error={erroPais}
                                disabled={true}
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                        {/* Contrato */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="contrato"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("contrato")} * (*pdf)
                          </p>

                          {contrato && (
                            <div
                              style={{
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                                borderColor: Colors.textFields,
                                height: "600px",
                                marginTop: 10,
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                            >
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={URL.createObjectURL(contrato)}
                                />
                              </Worker>
                            </div>
                          )}
                        </FormControl>
                        {/* Foto */}
                        {/* <FormControl className="formTextField3" fullWidth>
                          <p
                            id="tituloFotoPiscina"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("foto")} *
                          </p>

                          <div>
                            {fotoP.length > 0 && (
                              <img
                                style={{ marginTop: 10 }}
                                width={150}
                                height={150}
                                src={fotoP}
                              />
                            )}
                          </div>
                        </FormControl> */}
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  top: 0,
                }}
              >
                <CircularProgress disableShrink />
              </div>
            )}
          </Scrollbar>
        </div>
      </div>
    </div>
  );
}

export default VerELA;
