import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import { getSuperAdmin } from "../../api/requests/SuperAdministradores";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { postPedidos } from "../../api/requests/Pedidos";
import { enviarEmailPostAdministrador } from "../../api/requests/Administradores";

function VerSuperAdmin() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idSuperAdmin } = state;

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeSuperAdmin, setNomeSuperAdmin] = useState("");
  const [emailSuperAdmin, setEmailSuperAdmin] = useState("");
  const [telefoneSuperAdmin, setTelefoneSuperAdmin] = useState("");
  const [cargoSuperAdmin, setCargoSuperAdmin] = useState("");
  const [nifSuperAdmin, setNifSuperAdmin] = useState("");
  const [moradaSuperAdmin, setMoradaSuperAdmin] = useState("");
  const [codigoPostalSuperAdmin, setCodigoPostalSuperAdmin] = useState("");
  const [fotoSuperAdmin, setFotoSuperAdmin] = useState([]);
  const [statusSuperAdmin, setStatusSuperAdmin] = useState("");
  const [dataNascimentoSuperAdmin, setDataNascimentoSuperAdmin] = useState(
    new Date()
  );

  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  //Validações
  // Responsável
  const [erroNomeSuperAdmin, setErroNomeSuperAdmin] = useState("");
  const [erroEmailSuperAdmin, setErroEmailSuperAdmin] = useState("");
  const [erroTelefoneSuperAdmin, setErroTelefoneSuperAdmin] = useState("");
  const [erroCargoSuperAdmin, setErroCargoSuperAdmin] = useState("");
  const [erroNifSuperAdmin, setErroNifSuperAdmin] = useState("");
  const [erroMoradaSuperAdmin, setErroMoradaSuperAdmin] = useState("");
  const [erroCodigoPostalSuperAdmin, setErroCodigoPostalSuperAdmin] =
    useState("");
  const [erroNascimentoSuperAdmin, setErroNascimentoSuperAdmin] =
    useState(false);

  // Responsável
  const [mensagemErroEmailSuperAdmin, setMensagemErroEmailSuperAdmin] =
    useState("");
  const [mensagemErroTelefoneSuperAdmin, setMensagemErroTelefoneSuperAdmin] =
    useState("");
  const [mensagemErroNifSuperAdmin, setMensagemErroNifSuperAdmin] =
    useState("");
  const [
    mensagemErroCodigoPostalSuperAdmin,
    setMensagemErroCodigoPostalSuperAdmin,
  ] = useState("");
  const [
    mensagemErroNascimentoSuperAdmin,
    setMensagemErroNascimentoSuperAdmin,
  ] = useState("");

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (!dadosIncompletos) {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getSuperAdmin(idSuperAdmin)
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];
          setNomeSuperAdmin(dados.nome);
          setEmailSuperAdmin(dados.email);
          setTelefoneSuperAdmin(dados.contacto);
          setCargoSuperAdmin(dados.cargo);
          setNifSuperAdmin(dados.nif);
          setMoradaSuperAdmin(dados.morada);
          setCodigoPostalSuperAdmin(dados.codigo_postal);
          if (dados.foto) {
            setFotoSuperAdmin(dados.foto);
          }
          setDataNascimentoSuperAdmin(dados.data_nascimento_final);
          setStatusSuperAdmin(dados.status);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function enviarEmail() {
    definePassEmail({
      email: emailSuperAdmin,
    }).then((res) => {
      if (res.success) {
        toast.success(res.msg);
        //navigate("/superadmins");
      } else {
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          <div className="containerFormSA">
            <div className="align-left">
              <IconButton
                className="arrowBack"
                variant="text"
                onClick={() => {
                  navigate("/superadmins");
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </IconButton>
            </div>

            {/* <div className="align-rightSA">
              <h3 className="titleList">{nomeSuperAdmin}</h3>
            </div> */}
            <Box className="formTextField5">
              <div className="cardsDivPiscina">
                <Button
                  id="editarCard"
                  className="cardReenviarEmail"
                  variant="outlined"
                  style={{
                    backgroundColor: "olivedrab",
                  }}
                  onClick={() => {
                    enviarEmail();
                  }}
                >
                  <FontAwesomeIcon className="iconCards" icon={faPaperPlane} />
                  {t("reenviarAtivacao")}
                </Button>
              </div>
            </Box>
          </div>
          <div className="formPageSA">
            <Box className="formTextField" ref={ref}>
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    disabled={true}
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nomeSuperAdmin}
                    error={erroNomeSuperAdmin}
                    onChange={(e) => {
                      setNomeSuperAdmin(e.target.value);
                      setErroNomeSuperAdmin(false);
                    }}
                  />
                </FormControl>
                {/* Email */}
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    disabled={true}
                    required
                    type="text"
                    variant="standard"
                    label={t("email")}
                    value={emailSuperAdmin}
                    error={erroEmailSuperAdmin}
                    helperText={mensagemErroEmailSuperAdmin}
                    onChange={(e) => {
                      setEmailSuperAdmin(e.target.value.toLocaleLowerCase());
                      setErroEmailSuperAdmin(false);
                      setMensagemErroEmailSuperAdmin("");
                    }}
                  />
                </FormControl>
                {/* Telefone */}
                <FormControl className="formTextField3" fullWidth>
                  <PhoneInput
                    disabled={true}
                    defaultCountry="PT"
                    labels={
                      i18n.language == "pt"
                        ? pt
                        : i18n.language == "es"
                        ? es
                        : en
                    }
                    value={telefoneSuperAdmin}
                    error={erroTelefoneSuperAdmin}
                    helperText={mensagemErroTelefoneSuperAdmin}
                    onChange={(e) => {
                      setTelefoneSuperAdmin(e);
                      setErroTelefoneSuperAdmin(false);
                      setMensagemErroTelefoneSuperAdmin("");
                    }}
                    inputComponent={PhoneNumber}
                  />
                </FormControl>
                {/* Cargo */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    disabled={true}
                    required
                    type="text"
                    variant="standard"
                    label={t("cargo")}
                    value={cargoSuperAdmin}
                    error={erroCargoSuperAdmin}
                    onChange={(e) => {
                      setCargoSuperAdmin(e.target.value);
                      setErroCargoSuperAdmin(false);
                    }}
                  />
                </FormControl>
                {/* Nif */}
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    disabled={true}
                    required
                    type="number"
                    variant="standard"
                    label={t("nif")}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 9);
                    }}
                    value={nifSuperAdmin}
                    error={erroNifSuperAdmin}
                    helperText={mensagemErroNifSuperAdmin}
                    onChange={(e) => {
                      setNifSuperAdmin(e.target.value);
                      setErroNifSuperAdmin(false);
                    }}
                  />
                </FormControl>
                {/* Rua */}
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    disabled={true}
                    required
                    type="text"
                    variant="standard"
                    label={t("rua")}
                    value={moradaSuperAdmin}
                    error={erroMoradaSuperAdmin}
                    onChange={(e) => {
                      setMoradaSuperAdmin(e.target.value);
                      setErroMoradaSuperAdmin(false);
                    }}
                  />
                </FormControl>
                {/* Código Postal */}
                <FormControl className="formTextField3" fullWidth>
                  <PatternFormat
                    disabled={true}
                    type="text"
                    variant="standard"
                    {...{
                      label: t("codigoPostal"),
                      variant: "standard",
                    }}
                    customInput={TextField}
                    value={codigoPostalSuperAdmin}
                    required
                    format="####-###"
                    error={erroCodigoPostalSuperAdmin}
                    helperText={mensagemErroCodigoPostalSuperAdmin}
                    onChange={(e) => {
                      setCodigoPostalSuperAdmin(e.target.value);
                      setErroCodigoPostalSuperAdmin(false);
                      setMensagemErroCodigoPostalSuperAdmin("");
                    }}
                  />
                </FormControl>
                {/* Data de nascimento */}
                <FormControl className="formTextField3" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      disableFuture
                      disabled={true}
                      label={t("dataNascimento")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={dataNascimentoSuperAdmin}
                      onChange={(e) => {
                        setDataNascimentoSuperAdmin(e);
                        setErroNascimentoSuperAdmin(false);
                        setMensagemErroNascimentoSuperAdmin("");
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroNascimentoSuperAdmin}
                          helperText={mensagemErroNascimentoSuperAdmin}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                {/* Foto */}
                <FormControl className="formTextField3" fullWidth>
                  {fotoSuperAdmin.length > 0 && (
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")}
                    </p>
                  )}
                  {/* Imagem carregada */}
                  <div>
                    {fotoSuperAdmin.length > 0 && (
                      <img
                        style={{ marginTop: 10 }}
                        width={150}
                        height={150}
                        src={fotoSuperAdmin}
                      />
                    )}
                  </div>
                </FormControl>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default VerSuperAdmin;
