import Cookies from "js-cookie";
import { makeRequestPost2, makeRequestPut2 } from "../api";

//Get Administradores
export function getAdministradores(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("administrador/all", dados, token);
}

//Post admin
export function postAdministrador(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("administrador", dados, token);
}

//Enviar email -Post admin
export function enviarEmailPostAdministrador(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("administrador/active-account", dados, token);
}

//Get por id
export function getAdministrador(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`administrador/id/${id}`, {}, token);
}

//Editar
export function putAdministrador(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`administrador/`, dados, token);
}
