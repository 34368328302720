import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import { adicionarTurma } from "../../api/requests/Turmas";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import {
  listaPiscinasAgua,
  listaModalidades,
  listaProfessores,
} from "../../api/requests/PiscinaAgua";

function CriarTurma() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  const [nome, setNome] = useState("");
  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [numvagas, setNumVagas] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [piscina, setPiscinas] = useState([]);
  const [modalidades, setModalidade] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [precomensal, setPrecoMensal] = useState(0);
  const [precoanual, setPrecoAnual] = useState(0);
  const [obs, setObs] = useState("");
  const [obsES, setObsES] = useState("");
  const [obsEN, setObsEN] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [selectedModalidade, setSelectedModalidade] = useState("");

  const [erroNome, setErroNome] = useState("");
  const [erroObs, setErroObs] = useState("");
  const [erroObsEN, setErroObsEN] = useState("");
  const [erroObsES, setErroObsES] = useState("");
  const [erroPrecoMensal, setErroPrecoMensal] = useState("");
  const [erroPrecoAnual, setErroPrecoAnual] = useState("");
  const [erroNumVagas, setErroNumVagas] = useState("");
  const [erroPreco, setErroPreco] = useState("");
  const [erroCategoria, setErroCategoria] = useState("");
  const [erroProfessor, setErroProfessor] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [erroModalidade, setErroModalidade] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
    setErroProfessor(false);
  };

  const handleModalidadeChange = (event) => {
    setSelectedModalidade(event.target.value);
    setErroModalidade(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaModalidades({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_modalidade,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setModalidade(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarTurmas() {
    setFreeze(true);
    adicionarTurma({
      nome: nome,
      id_categoria: selectedCategory,
      id_professor: selectedProfessor,
      id_modalidade: selectedModalidade,
      num_vagas: numvagas,
      preco_mensal: precomensal,
      preco_anual: precoanual,
      obs1: obs,
      obs2: obsEN,
      obs3: obsES,
    }).then((res) => {
      if (res.success) {
        setFreeze(false)
        toast.success(t("turmaAdicionar"));
        navigate("/classlist");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      nome &&
      numvagas &&
      selectedCategory &&
      selectedProfessor &&
      selectedModalidade &&
      precomensal &&
      precoanual &&
      obs &&
      obsEN &&
      obsES
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarTurmas();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      if (!obs) {
        setErroObs(true);
      }
      if (!obsEN) {
        setErroObsEN(true);
      }
      if (!obsES) {
        setErroObsES(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (!selectedModalidade) {
        setErroModalidade(true);
      }
      if (!selectedProfessor) {
        setErroProfessor(true);
      }
      if (!selectedModalidade) {
        setErroModalidade(true);
      }
      if (numvagas <= 0) {
        setErroNumVagas(true);
      }
      if (precomensal <= 0) {
        setErroPrecoMensal(true);
      }
      if (precoanual <= 0) {
        setErroPrecoAnual(true);
      }
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/classlist");
              }}
              size={isMobile ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          <div className="align-right">
            {/* <h3 className="titleList">{t("adicionarTurma")}</h3> */}
          </div>
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nome}
                    error={erroNome}
                    onChange={(e) => {
                      setNome(e.target.value);
                      setErroNome(false);
                    }}
                  />
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroCategoria}>
                    {t("escolhacategoria")}*
                  </InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    error={erroCategoria}
                    fullWidth
                    variant="standard"
                  >
                    {categorias.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.categoria}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel
                    className="formTextField22"
                    error={erroModalidade}
                  >
                    {t("escolhamodalidade")}*
                  </InputLabel>
                  <Select
                    value={selectedModalidade}
                    onChange={handleModalidadeChange}
                    error={erroModalidade}
                    fullWidth
                    variant="standard"
                  >
                    {modalidades.map((modalidade) => (
                      <MenuItem key={modalidade.id} value={modalidade.id}>
                        {modalidade.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroProfessor}>
                    {t("escolhaprofessor")}*
                  </InputLabel>
                  <Select
                    value={selectedProfessor}
                    onChange={handleProfessorChange}
                    error={erroProfessor}
                    fullWidth
                    variant="standard"
                  >
                    {professores.map((professor) => (
                      <MenuItem key={professor.id} value={professor.id}>
                        {professor.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("numvagas")}
                    value={numvagas}
                    error={erroNumVagas}
                    onChange={(e) => {
                      const value = e.target.value;

                      setNumVagas(value);
                      setErroNumVagas(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={`${t("precomensal")} (€)`}
                    value={precomensal}
                    error={erroPrecoMensal}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPrecoMensal(value);
                      setErroPrecoMensal(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={`${t("precoanual")} (€)`}
                    value={precoanual}
                    error={erroPrecoAnual}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPrecoAnual(value);
                      setErroPrecoAnual(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    style={{ textAlign: "left" }}
                    variant="outlined"
                    multiline
                    rows={2}
                    value={obs}
                    label={t("observacoes")}
                    error={erroObs}
                    onChange={(e) => {
                      setObs(e.target.value);
                      setErroObs(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    style={{ textAlign: "left" }}
                    variant="outlined"
                    multiline
                    rows={2}
                    value={obsEN}
                    label={t("observacoesEN")}
                    error={erroObsEN}
                    onChange={(e) => {
                      setObsEN(e.target.value);
                      setErroObsEN(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    style={{ textAlign: "left" }}
                    variant="outlined"
                    multiline
                    rows={2}
                    value={obsES}
                    label={t("observacoesES")}
                    error={erroObsES}
                    onChange={(e) => {
                      setObsES(e.target.value);
                      setErroObsES(false);
                    }}
                  />
                </FormControl>
              </div>
            </Box>
          </div>
        </div>

        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarTurma;
