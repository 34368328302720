import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import { adicionarPacks } from "../../api/requests/Packs";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaPiscinasAgua } from "../../api/requests/PiscinaAgua";

function CriarPackA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  const [nome, setNome] = useState("");
  const [numtickets, setNumTickets] = useState(0);
  const [preco, setPreco] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [piscina, setPiscinas] = useState([]);

  const [erroNome, setErroNome] = useState("");
  const [erroNumTickets, setErroNumTickets] = useState("");
  const [erroPreco, setErroPreco] = useState("");
  const [erroCategoria, setErroCategoria] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPool, setSelectedPool] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handlePoolChange = (event) => {
    setSelectedPool(event.target.value);
    setErroPiscina(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaPiscinasAgua({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarPack() {
    setFreeze(true);
    adicionarPacks({
      nome: nome,
      id_piscina_agua: selectedPool,
      id_categoria: selectedCategory,
      num_tickets: numtickets,
      preco: preco,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false)
        toast.success(t("packAdicionar"));
        navigate("/packlista");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (nome && numtickets && selectedCategory && selectedPool && preco) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarPack();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (!selectedPool) {
        setErroPiscina(true);
      }
      if (!numtickets) {
        setErroNumTickets(true);
      }
      if (!preco) {
        setErroPreco(true);
      }
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/packlista");
              }}
              size={isMobile ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          {/* <div className="align-right">
            <h3 className="titleList">{t("adicionarPack")}</h3>
          </div> */}
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nome}
                    error={erroNome}
                    onChange={(e) => {
                      setNome(e.target.value);
                      setErroNome(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroCategoria}>
                    {t("escolhapiscina")}*
                  </InputLabel>
                  <Select
                    value={selectedPool}
                    onChange={handlePoolChange}
                    error={erroPiscina}
                    fullWidth
                    variant="standard"
                  >
                    {piscina.map((piscina) => (
                      <MenuItem key={piscina.id} value={piscina.id}>
                        {piscina.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroCategoria}>
                    {t("escolhacategoria")}*
                  </InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    error={erroCategoria}
                    fullWidth
                    variant="standard"
                  >
                    {categorias.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.categoria}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("numtickets")}
                    value={numtickets}
                    error={erroNumTickets}
                    onChange={(e) => {
                      const value = e.target.value;

                      setNumTickets(value);
                      setErroNumTickets(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={`${t("preco")} (€)`}
                    value={preco}
                    error={erroPreco}
                    onChange={(e) => {
                      const value = e.target.value;

                      setPreco(value);
                      setErroPreco(false);
                    }}
                  />
                </FormControl>
              </div>
            </Box>
          </div>
        </div>

        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarPackA;
