import React from "react";

function FooterExterno() {
  return (
    <div className="footerExterno">
      <a href="https://tectank.pt/" className="footerText">
        <small className="footerletra">Copyright Tectank &reg; 2023</small>
      </a>
    </div>
  );
}

export default FooterExterno;
