import Cookies from "js-cookie";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box, fontWeight, margin } from "@mui/system";
import { Alert, Badge, Button } from "@mui/material";

import {
  faPlus,
  faWaterLadder,
  faMoneyBill1Wave,
  faPeopleRoof,
  faMagnifyingGlass,
  faQrcode,
  faTicket,
  faMoneyBillTransfer,
  faGraduationCap,
  faPersonSwimming,
  faCalendarDay,
  faCalendarDays,
  faPen,
  faClose,
  faCalendarCheck,
  faFaceFrown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proximasMarcacoes } from "../../api/requests/Marcacoes";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  NumeroPagamentosPendentes,
  verificarPagamentosEmDia,
} from "../../api/requests/Pagamentos";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import { listaFechos } from "../../api/requests/PiscinaAgua";
import plusIcon from "../../images/plusIcons.svg";
import mypoolsIcon from "../../images/mypoolsIcon.svg";
import paymentIcon from "../../images/paymentIcon.svg";
import penIcon from "../../images/penIcon.svg";
import familyIcon from "../../images/familyIcon.svg";
import searchIcon from "../../images/searchIcon.svg";
import marcarbtn from "../../images/BotaoMarcar.svg";
import marcarmobile from "../../images/marcarMobile.svg";
import marcarmobiledisable from "../../images/marcarMobileDisable.svg";
import marcarmobile2 from "../../images/marcarMobilePequeno.svg";
import marcarmobiledisable2 from "../../images/marcarMobilePequenoDisable.svg";
import piscinasmobile from "../../images/piscinasMobile.svg";
import minhaspiscinasdisablemobile from "../../images/piscinasdisablemobile.svg";
import piscinasmobile2 from "../../images/piscinasMobilePequeno.svg";
import minhaspiscinasdisablemobile2 from "../../images/piscinasMobilePequenoDisable.svg";
import explorarmobile from "../../images/explorarMobile.svg";
import explorarmobile2 from "../../images/explorarMobilePequeno.svg";
import explorarmobiledisable from "../../images/explorarMobileDisable.svg";
import explorarmobiledisable2 from "../../images/explorarMobilePequenoDisable.svg";
import marcacoesmobile from "../../images/marcacoesMobile.svg";
import marcacoesmobile2 from "../../images/marcacoesMobilePequeno.svg";
import marcacoesmobiledisable from "../../images/marcacoesMobileDisable.svg";
import marcacoesmobiledisable2 from "../../images/marcacoesMobilePequenoDisable.svg";
import marcarbtndisable from "../../images/disabledCards/MarcarDisable.svg";
import minhaspiscinasbtndisable from "../../images/disabledCards/MinhasPiscinasDisable.svg";
import familiabtndisable from "../../images/disabledCards/FamiliaDisable.svg";
import explorarbtndisable from "../../images/disabledCards/ExplorarDisable.svg";
import marcacoesbtndisable from "../../images/disabledCards/MarcacoesDisable.svg";
import pagarbtn from "../../images/BotaoPagar.svg";
import pagarbtnmobile from "../../images/pagamentosMobile.svg";
import pagarbtnmobile2 from "../../images/pagamentoMobilePequeno.svg";
import familiabtnmobile from "../../images/familiaMobile.svg";
import familiabtnmobiledisable from "../../images/familiaMobileDisable.svg";
import familiabtnmobile2 from "../../images/familiaMobilePequeno.svg";
import familiabtnmobiledisable2 from "../../images/familiaMobilePequenoDisable.svg";
import familiabtn from "../../images/BotaoFamilia.svg";
import explorarbtn from "../../images/BotaoExplorar.svg";
import editarmarcacoesbtn from "../../images/BotaoEditarMarcacoes.svg";
import minhaspiscinasbtn from "../../images/BotaoPiscinas.svg";
import { green } from "@mui/material/colors";

function Dashboard_Cliente() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);
  const [marcacoes, setMarcacoes] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setEventInfo(null);
  };
  
  const [numPedidosPendentes, setPagamentos] = useState([]);
  const [pagamentosEmDia, setPagamentosEmDia] = useState(false);
  const [weekDateRange, setWeekDateRange] = useState("");
  const calendarRef = useRef(null);

  const handleEventClick = (info) => {
    setEventInfo(info);
    setOpen(true);
  };
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
     
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    //Ir buscar próximas marcações
    proximasMarcacoes().then((res) => {
      if (res.success) {
        let arrayFinal = [];
        let dados = res.data.data;
        dados.forEach((element) => {
          const dataParts = element.dia.split("/");
          const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
          const data = new Date(dataFormatada);
          const dataInicio = `${data.toISOString().substr(0, 10)}T${
            element.horaInicio
          }`;
          const dataFim = `${data.toISOString().substr(0, 10)}T${
            element.horaFim
          }`;

          let titulo = element.nome;
          if (titulo == "Marcação de nado livre") {
            titulo = t("marcacaoNadoLivre");
          } else if (titulo == "Natação para adultos") {
            titulo = t("natacao_adultos");
          } else if (titulo == "Natação para bebés") {
            titulo = t("natacao_bebes");
          } else if (titulo == "Natação para idosos") {
            titulo = t("natacao_idosos");
          } else if (titulo == "Natação para grávidas") {
            titulo = t("natacao_gravidas");
          } else if (titulo == "Aulas de Hidroginástica") {
            titulo = t("hidroginastica");
          } else if (titulo == "Aulas de Hidrobike") {
            titulo = t("hidrobike");
          } else if (titulo == "Aulas de Hidroterapia") {
            titulo = t("hidroterapia");
          } else if (titulo == "Aulas de Hidropower") {
            titulo = t("hidropower");
          }else if (titulo == "Escola de Natação") {
            titulo = t("escolaNatacao");
          }
          const hoje = new Date().toISOString();
          const linha = {
            title: titulo,
            description: element.nomePiscina,
            piscina: element.piscina,
            start: dataInicio,
            end: dataFim,
            nome_turma: element.nome_turma,
            cancelado: element.cancelado,
            id_filho: element.id_filho,
            nome_filho: element.filho,
            color:
              element.cancelado == 1
                ? "#F04643"
                : dataFim < hoje
                ? "#54DE90"
                : "#00C4F5",
          };
          arrayFinal.push(linha);
          titulo = "";
        });
        //Filtrar apenas para marcações futuras
        // const hoje = new Date().toISOString().substr(0, 10);

        // const marcacoesFuturas = arrayFinal.filter((marcacao) => {
        //   return marcacao.start >= hoje;
        // });

        setMarcacoes(arrayFinal);
      }
    });
    NumeroPagamentosPendentes().then((res) => {
      if (res.success) {
        const pag = res.data.data;
        setPagamentos(pag);
      }
    });
    /////////////////////////////////
    setTimeout(() => {
      verificarPagamentosEmDia().then((res) => {
        setPagamentosEmDia(res.data.success);

        localStorage.setItem("pagamentoEmDia", res.data.success);
      });
    }, 10);
  }, []);

  ///////////////////////////////

  // useEffect(() => {
  //   const elements = document.getElementsByClassName("fc-event fc-event-start fc-event-end fc-event-today fc-event-future fc-list-event");
  //   for (let i = 0; i < elements.length; i++) {
  //     if (elements[i].getElementsByClassName("fc-cancelado")[0]) {
  //       elements[i].style.setProperty("background-color", "#F04643", "important");
  //       elements[i].style.setProperty("color", "white", "important");
  //     }
  //   }
  // }, [marcacoes]);

  const titleFormat = () => {
    const prefix = `${weekDateRange}`;
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat(
      i18n.language,
      options
    ).format(date);

    return prefix;
  };

  const dayHeaderFormat = {
    weekday: "short",
    month: "numeric",
    day: "numeric",
  };
  const handleTodayClick = () => {
    calendarRef.current.getApi().today();
  };

  function handleEventMount(info) {
    const eventElement = info.el;

    eventElement.addEventListener("mouseover", () => {
      eventElement.classList.add("event-pointer");
    });

    eventElement.addEventListener("mouseout", () => {
      eventElement.classList.remove("event-pointer");
    });
  }

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    );
    calendarApi.gotoDate(newDate);
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 7
    );
    calendarApi.gotoDate(newDate);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div
        className={isMobile ? "listPagesMobile" : "listPages"}
        style={{ overflow: "hidden auto" }}
      >
        <>
          {marcacoes.length != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "8%",
              }}
            >
              {pagamentosEmDia ? null : (
                <h3
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    paddingLeft: isMobile ? 0 : 40,
                  }}
                >
                  {t("atuasemana")}
                </h3>
              )}
            </div>
          )}

          {marcacoes.length != 0 && !pagamentosEmDia && (
            <div className="calendar-container">
              <div className="calendar-wrapper">
                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  contentHeight={isMobile ? 120 : 240}
                  titleFormat={titleFormat}
                  headerToolbar={{
                    start: isMobile ? "title" : "",
                    center: isMobile ? "prev next today" : "title",
                    end: isMobile ? "" : "prev next today",
                  }}
                  ref={calendarRef}
                  plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    bootstrap5Plugin,
                  ]}
                  customButtons={{
                    prev: {
                      text: "<",
                      click: handlePrevClick,
                      className: "custom-calendar-button",
                    },
                    next: {
                      text: ">",
                      click: handleNextClick,
                      className: "custom-calendar-button",
                    },
                    today: {
                      text: t("hoje"),
                      click: handleTodayClick,
                      classNames: "custom-calendar-button",
                    },
                  }}
                  dayHeaderFormat={dayHeaderFormat}
                  stickyHeaderDates={false}
                  initialView="listWeek"
                  selectable={true}
                  events={marcacoes}
                  eventClick={handleEventClick}
                  eventDidMount={handleEventMount}
                  datesSet={(dates) => {
                    if (Cookies.get("language") == "pt") {
                      moment.locale("pt-br");
                      const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    } else if (Cookies.get("language") == "en") {
                      moment.locale("en");
                      const startOfWeek = moment(dates.start).add(1, "day"); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    } else if (Cookies.get("language") == "es") {
                      moment.locale("es");
                      const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                      const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                      const weekDateRange = `${startOfWeek.format(
                        "MMMM D"
                      )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                      setWeekDateRange(weekDateRange);
                    }
                  }}
                  eventContent={(arg) => {
                    const { event } = arg;
                    const { title, extendedProps } = event;
                    const isCancelled = extendedProps.cancelado;
                    const canceladoElement = isCancelled
                      ? `<span className="fc-cancelado" style="background-color: #F04643; color: white; border-radius:4px"> ‎ ${t(
                          "cancelado".toUpperCase()
                        )} ‎  ‎ </span>`
                      : "";
                    const nomeFilho = extendedProps.id_filho
                      ? `<span className="fc-filho" style="background-color: #00C4F5; ">‎ ${extendedProps.nome_filho}‎ </span>`
                      : "";
                    const elements =
                      document.getElementsByClassName("fc-event");
                    for (let i = 0; i < elements.length; i++) {
                      if (
                        elements[i].getElementsByClassName("fc-cancelado")[0]
                      ) {
                        //elements[i].style.setProperty("pointer-events", "none", "important");
                        elements[i].style.setProperty(
                          "background-color",
                          "#F04643"
                        );
                        elements[i].style.setProperty("color", "white");
                      }
                      if (
                        elements[i].getElementsByClassName("fc-filho")[0] &&
                        isCancelled
                      ) {
                        elements[i].style.setProperty(
                          "background-color",
                          "#F04643"
                        );
                        elements[i].style.setProperty("color", "white");
                      }
                      elements[i].style.setProperty(
                        "margin-bottom",
                        "5px",
                        "important"
                      ); // Adicione essa linha para definir a margem inferior
                    }

                    return {
                      html: isCancelled
                        ? `<div className="fc-content">${title} ‎ ${nomeFilho}‎  ${canceladoElement}</div>`
                        : `<div className="fc-content">${title} ‎ ${nomeFilho}‎ </div>`,
                    };
                  }}
                />
              </div>
            </div>
          )}

          {open && (
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="sm"
              fullWidth
              PaperProps={{
                style: {
                  width: 600,
                  height: 300,
                  borderRadius: "10px",
                },
              }}
            >
              <DialogTitle
                style={{
                  backgroundColor: eventInfo.event.backgroundColor,
                  color: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {eventInfo && eventInfo.event.title}
                </div>
                {eventInfo.event.extendedProps.cancelado == 1 ? (
                  <span className="fc-cancelado2">‎ 
                    {t("cancelado").toUpperCase()}‎ ‎ 
                  </span>
                ) : null}
                <FontAwesomeIcon
                  edge="end"
                  color="inherit"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "pointer",
                    padding: "8px",
                  }}
                  onClick={handleClose}
                  aria-label="Fechar"
                  icon={faClose}
                />
              </DialogTitle>
              <DialogContent>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {eventInfo && eventInfo.event.start.toLocaleDateString()}
                  </Typography>
                  <Typography gutterBottom>
                    <span
                      style={{ fontWeight: "100 !important", color: "black" }}
                    >
                      {t("inicio")}:{" "}
                    </span>
                    <span style={{ fontWeight: "900", color: "black" }}>
                      {eventInfo &&
                        eventInfo.event.start.toLocaleTimeString("en-US", {
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      h
                    </span>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    <span
                      style={{ fontWeight: "100 !important", color: "black" }}
                    >
                      {t("fim")}:{" "}
                    </span>
                    <span style={{ fontWeight: "900", color: "black" }}>
                      {eventInfo &&
                        eventInfo.event.end.toLocaleTimeString("en-US", {
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      h
                    </span>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    <span
                      style={{ fontWeight: "100 !important", color: "black" }}
                    >
                      {t("local")}:{" "}
                    </span>
                    <span style={{ fontWeight: "900", color: "black" }}>
                      {eventInfo && eventInfo.event.extendedProps.description}
                    </span>
                  </Typography>
                  {eventInfo.event.extendedProps.nome_filho && (
                    <Typography variant="subtitle1" gutterBottom>
                      <span
                        className="fc-filho"
                        style={{ backgroundColor: "#00C4F5" }}
                      >
                        {eventInfo.event.extendedProps.nome_filho}
                      </span>
                    </Typography>
                  )}
                </div>
              </DialogContent>
            </Dialog>
          )}

          {/* Cards */}

          {marcacoes.length == 0 ? (
            <Box sx={{ marginTop: pagamentosEmDia ? "150px" : "20px" }}>
              <div className="cardsDivDashboard">
                <Button
                  className="cardDashboardMarcarB"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${marcarmobiledisable})`
                        : `url(${marcarbtndisable})`
                      : isMobile
                      ? `url(${marcarmobile})`
                      : `url(${marcarbtn})`,

                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/marcar");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${plusIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${plusIcon})`,
                        }}
                      ></span>
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {" "}
                    {t("marcar")}
                  </div>
                </Button>
                <Button
                  className="cardDashboardMarcarB"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${minhaspiscinasdisablemobile})`
                        : `url(${minhaspiscinasbtndisable})`
                      : isMobile
                      ? `url(${piscinasmobile})`
                      : `url(${minhaspiscinasbtn})`,
                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/mypools");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",

                          backgroundImage: `url(${mypoolsIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${mypoolsIcon})`,
                        }}
                      ></span>
                    </>
                  )}
                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {t("asminhaspiscinas")}
                  </div>
                </Button>
                {numPedidosPendentes != 0 ? (
                  <Button
                    className="cardDashboardMarcarB"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${pagarbtnmobile})`
                        : `url(${pagarbtn})`,
                      width: "226px",
                      height: "120px",
                    }}
                    onClick={() => {
                      navigate("/mypayments", {
                        state: { pagamentosEmDia: pagamentosEmDia },
                      });
                    }}
                  >
                    <span
                      className="plusIcon"
                      style={{
                        top: "45px",
                        left: "16px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <span
                      className="plusshadowIcon"
                      style={{
                        top: "40px",
                        left: "25px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <div style={{ marginLeft: isMobile ? "350px" : "50%" }}>
                      {t("pagamentos")}{" "}
                    </div>
                    <Badge
                      className="cardDashboard2"
                      badgeContent={numPedidosPendentes}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          position: "absolute", // Define a posição como absoluta para o Badge
                          top: -37, // Ajusta o valor conforme necessário para posicionar no topo
                          right: 43, // Ajusta o valor conforme necessário para posicionar no canto direito
                          width: "25px", // Defina o tamanho do quadrado
                          height: "25px", // Defina o tamanho do quadrado
                          borderRadius: "6px",
                          zIndex: 99, // Define a ordem de sobreposição, coloque o Badge acima do botão
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: isMobile ? "200px" : "0px ",
                        },
                      }}
                      style={{
                        marginTop: isMobile ? "10px " : "0px",
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    className="cardDashboardMarcarB"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${pagarbtnmobile})`
                        : `url(${pagarbtn})`,
                      width: "226px",
                      height: "120px",
                    }}
                    onClick={() => {
                      navigate("/mypayments", {
                        state: { pagamentosEmDia: pagamentosEmDia },
                      });
                    }}
                  >
                    <span
                      className="plusIcon"
                      style={{
                        top: "45px",
                        left: "16px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <span
                      className="plusshadowIcon"
                      style={{
                        top: "40px",
                        left: "25px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <div style={{ marginLeft: "30px" }}>{t("pagamentos")} </div>
                  </Button>
                )}
              </div>
              <div className="cardsDivDashboard2">
                <Button
                  className="cardDashboardMarcarB"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${familiabtnmobiledisable})`
                        : `url(${familiabtndisable})`
                      : isMobile
                      ? `url(${familiabtnmobile})`
                      : `url(${familiabtn})`,

                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/myfamily");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${familyIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${familyIcon})`,
                        }}
                      ></span>
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {t("familia")}
                  </div>
                </Button>

                <Button
                  className="cardDashboardMarcarB"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${explorarmobiledisable})`
                        : `url(${explorarbtndisable})`
                      : isMobile
                      ? `url(${explorarmobile})`
                      : `url(${explorarbtn})`,
                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${searchIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${searchIcon})`,
                        }}
                      ></span>
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {" "}
                    {t("explorar")}
                  </div>
                </Button>
                <Button
                  className="cardDashboardMarcarB"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${marcacoesmobiledisable})`
                        : `url(${marcacoesbtndisable})`
                      : isMobile
                      ? `url(${marcacoesmobile})`
                      : `url(${editarmarcacoesbtn})`,
                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/appointments");
                  }}
                >
                  {pagamentosEmDia ? (
                    <span> </span>
                  ) : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${penIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${penIcon})`,
                        }}
                      ></span>{" "}
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {t("marcacoes2")}{" "}
                  </div>
                </Button>
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: pagamentosEmDia ? "150px" : "20px",
                flexDirection: isMobile ? "row" : "column",
                display: "flex",
                marginBottom: isMobile ? "50px" : 0,
              }}
            >
              <div className="cardsDivDashboard">
                <Button
                  className="cardDashboardMarcarB2"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${marcarmobiledisable2})`
                        : `url(${marcarbtndisable})`
                      : isMobile
                      ? `url(${marcarmobile2})`
                      : `url(${marcarbtn})`,

                    width: "165px",
                    height: "93px",
                  }}
                  onClick={() => {
                    navigate("/marcar");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${plusIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${plusIcon})`,
                        }}
                      ></span>
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {" "}
                    {t("marcar")}
                  </div>
                </Button>
                <Button
                  className="cardDashboardMarcarB2"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${minhaspiscinasdisablemobile2})`
                        : `url(${minhaspiscinasbtndisable})`
                      : isMobile
                      ? `url(${piscinasmobile2})`
                      : `url(${minhaspiscinasbtn})`,
                    width: "165px",
                    height: "93px",
                  }}
                  onClick={() => {
                    navigate("/mypools");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",

                          backgroundImage: `url(${mypoolsIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${mypoolsIcon})`,
                        }}
                      ></span>
                    </>
                  )}
                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {t("asminhaspiscinas")}
                  </div>
                </Button>
                {numPedidosPendentes != 0 ? (
                  <Button
                    className="cardDashboardMarcarB2"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${pagarbtnmobile2})`
                        : `url(${pagarbtn})`,
                      width: "226px",
                      height: "120px",
                    }}
                    onClick={() => {
                      navigate("/mypayments", {
                        state: { pagamentosEmDia: pagamentosEmDia },
                      });
                    }}
                  >
                    <span
                      className="plusIcon"
                      style={{
                        top: "45px",
                        left: "16px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <span
                      className="plusshadowIcon"
                      style={{
                        top: "40px",
                        left: "25px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <div style={{ marginLeft: isMobile ? "140%" : "50%" }}>
                      {t("pagamentos")}{" "}
                    </div>
                    <Badge
                      className="cardDashboard2"
                      badgeContent={numPedidosPendentes}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          position: "absolute", // Define a posição como absoluta para o Badge
                          top: isMobile ? -30 : -40, // Ajusta o valor conforme necessário para posicionar no topo
                          right: isMobile ? 150 : 43, // Ajusta o valor conforme necessário para posicionar no canto direito
                          width: "25px", // Defina o tamanho do quadrado
                          height: "25px", // Defina o tamanho do quadrado
                          borderRadius: "6px",
                          zIndex: 99, // Define a ordem de sobreposição, coloque o Badge acima do botão
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    className="cardDashboardMarcarB2"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${pagarbtnmobile2})`
                        : `url(${pagarbtn})`,
                      width: "226px",
                      height: "120px",
                    }}
                    onClick={() => {
                      navigate("/mypayments", {
                        state: { pagamentosEmDia: pagamentosEmDia },
                      });
                    }}
                  >
                    <span
                      className="plusIcon"
                      style={{
                        top: "45px",
                        left: "16px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <span
                      className="plusshadowIcon"
                      style={{
                        top: "40px",
                        left: "25px",
                        backgroundImage: `url(${paymentIcon})`,
                      }}
                    ></span>
                    <div style={{ marginLeft: "30px" }}>{t("pagamentos")} </div>
                  </Button>
                )}
              </div>
              <div className="cardsDivDashboard2">
                <Button
                  className="cardDashboardMarcarB2"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${familiabtnmobiledisable2})`
                        : `url(${familiabtndisable})`
                      : isMobile
                      ? `url(${familiabtnmobile2})`
                      : `url(${familiabtn})`,
                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/myfamily");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${familyIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${familyIcon})`,
                        }}
                      ></span>
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {t("familia")}
                  </div>
                </Button>

                <Button
                  className="cardDashboardMarcarB2"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${explorarmobiledisable2})`
                        : `url(${explorarbtndisable})`
                      : isMobile
                      ? `url(${explorarmobile2})`
                      : `url(${explorarbtn})`,
                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  {pagamentosEmDia ? null : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${searchIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${searchIcon})`,
                        }}
                      ></span>
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {" "}
                    {t("explorar")}
                  </div>
                </Button>
                <Button
                  className="cardDashboardMarcarB2"
                  variant="contained"
                  disabled={pagamentosEmDia}
                  style={{
                    backgroundImage: pagamentosEmDia
                      ? isMobile
                        ? `url(${marcacoesmobiledisable2})`
                        : `url(${marcacoesbtndisable})`
                      : isMobile
                      ? `url(${marcacoesmobile2})`
                      : `url(${editarmarcacoesbtn})`,
                    width: "226px",
                    height: "120px",
                  }}
                  onClick={() => {
                    navigate("/appointments");
                  }}
                >
                  {pagamentosEmDia ? (
                    <span> </span>
                  ) : (
                    <>
                      <span
                        className="plusIcon"
                        style={{
                          top: "45px",
                          left: "16px",
                          backgroundImage: `url(${penIcon})`,
                        }}
                      ></span>
                      <span
                        className="plusshadowIcon"
                        style={{
                          top: "40px",
                          left: "25px",
                          backgroundImage: `url(${penIcon})`,
                        }}
                      ></span>{" "}
                    </>
                  )}

                  <div
                    style={{
                      marginLeft: "30px",
                      color: pagamentosEmDia ? "#e5e5e5" : "white",
                    }}
                  >
                    {t("marcacoes2")}{" "}
                  </div>
                </Button>
              </div>
            </Box>
          )}

          {pagamentosEmDia ? (
            <div
              className="cardsDivMarcar"
              style={{
                width: "80%",
                maxHeight: "100px !important",
              }}
            >
              <Button
                className="cardDashboardMarcar2"
                variant="contained"
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                disabled
              >
                <span
                  style={{
                    marginTop: "2px",
                    fontSize: isMobile ? "10px" : "14px",
                    color: "#F04643",
                    textTransform: "none",
                  }}
                >
                  {t("pagamentosAtraso")}
                </span>
              </Button>
            </div>
          ) : null}
        </>
      </div>
    </div>
  );
}

export default Dashboard_Cliente;
