import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
  faPersonSwimming,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
  MenuItem,
  RadioGroup,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";

import "react-phone-number-input/style.css";

import { toast } from "react-toastify";

import "@react-pdf-viewer/core/lib/styles/index.css";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import {
  editarEspacos,
  eliminarEspacos,
  getEspacos,
  listaTiposEspacos,
} from "../../api/requests/OcupaEspaco";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import {
  listaAcoes,
  listaPiscinasAgua,
  listaProfessores,
  listaModalidades,
  getPistas,
} from "../../api/requests/PiscinaAgua";
import moment from "moment";
import { listaTurmas } from "../../api/requests/Turmas";
import { parseISO, format } from "date-fns";
import { getDisponibilidade } from "../../api/requests/Marcacoes";

function EditarEspacoA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idEspaco } = state || {};

  const [edicao, setEdicao] = useState(true);
  const [freeze, setFreeze] = useState(false);

  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [hora_inicio, setHoraInicio] = useState("");
  const [hora_fim, setHoraFim] = useState("");
  const [pista, setPista] = useState(0);
  const [vagas, setVagas] = useState(0);
  const [tamanho_pista, setTamanhoPista] = useState(0);
  const [segunda, setSegunda] = useState(0);
  const [terca, setTerca] = useState(0);
  const [quarta, setQuarta] = useState(0);
  const [quinta, setQuinta] = useState(0);
  const [sexta, setSexta] = useState(0);
  const [sabado, setSabado] = useState(0);
  const [domingo, setDomingo] = useState(0);
  const [espaco_pista, setEspacoPista] = useState(1);
  const [piscinas, setPiscinas] = useState([]);
  const [obs, setObs] = useState("");
  const [tipos, setTipos] = useState([]);
  const [exibirCheckboxes, setExibirCheckboxes] = useState(true);

  const [selectedTipo, setSelectedTipo] = useState("");

  const [selectedPool, setSelectedPool] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categorias, setCategoria] = useState([]);
  const [maxPista, setMaxPista] = useState(0);

  const [erroDataInicio, setErroDataInicio] = useState("");
  const [erroDataFim, setErroDataFim] = useState("");
  const [erroHoraInicio, setErroHoraInicio] = useState("");
  const [erroHoraFim, setErroHoraFim] = useState("");
  const [erroPista, setErroPista] = useState("");
  const [erroVagas, setErroVagas] = useState("");
  const [erroTamanhoPista, setErroTamanhoPista] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [erroObs, setErroObs] = useState("");
  const [erroCategoria, setErroCategoria] = useState("");
  const [erroTipo, setErroTipo] = useState("");
  const [pistasD, setPistasD] = useState([]);
  const [rows, setRows] = useState([{}]);
  const [pistasDisponiveis, setPistasDisponiveis] = useState([]);
  const [pistasOriginais, setPistasOriginais] = useState([]);

  const addRow = () => {
    if (rows.some((row) => row.id === "")) {
      toast.error(t("pistavazia"));
    } else {
      const newRow = { id: "", name: "", ocupacao: "" };
      setRows((prevRows) => [...prevRows, newRow]);
    }
  };

  const handlePistaChange = (event, index) => {
    const selectedPistaId = event.target.value;

    // Encontrar a pista selecionada em pistasD
    const selectedPista = pistasD.find((pista) => pista.id === selectedPistaId);

    // Copiar as rows existentes
    const updatedRows = [...rows];

    // Remover a pista selecionada das pistasD
    const updatedPistasD = pistasD.filter(
      (pista) => pista.id !== selectedPistaId
    );

    // Verificar se já existia uma pista selecionada na posição atual
    const currentPista = updatedRows[index];

    if (currentPista) {
      // Adicionar a pista atualmente em rows a pistasD
      updatedPistasD.push(currentPista);
    }

    // Adicionar a pista selecionada às rows na posição correta
    updatedRows[index] = selectedPista;

    // Atualizar os estados rows e pistasD
    setRows(updatedRows);
    setPistasD(updatedPistasD);
  };

  const handleOcupacaoChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index].ocupacao = event.target.value;
    setRows(updatedRows);
  };
  const handleRemoveRow = (index) => {
    if (rows.length === 1) {
      toast.error("sempistas");
    } else {
      const removedRow = rows.splice(index, 1)[0];
      setRows([...rows]);
      setPistasD((prevPistasD) => [...prevPistasD, removedRow]);
    }
  };

  useEffect(() => {
    if (exibirCheckboxes == false) {
      if (data_inicio && data_fim && hora_inicio && hora_fim && selectedPool) {
        let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
        let data_fimf = moment(data_fim).format("YYYY-MM-DD");
        let hora_iniciof = moment(hora_inicio).format("hh:mm:ss");
        let hora_fimf = moment(hora_fim).format("hh:mm:ss");
        getDisponibilidade({
          id_piscina: idPiscina,
          data_inicio: data_iniciof,
          data_fim: data_fimf,
          hora_inicio: hora_iniciof,
          hora_fim: hora_fimf,
          id_piscina_agua: selectedPool,
          segunda: segunda,
          terca: terca,
          quarta: quarta,
          quinta: quinta,
          sexta: sexta,
          sabado: sabado,
          domingo: domingo,
        }).then((res) => {
          if (res.success) {
            let arrayFinal = [];
            let dados = res.data.data;
            dados.forEach((element) => {
              const linha = {
                id: element.id,
                name: element.name,
                ocupacao: element.ocupacao,
              };
              arrayFinal.push(linha);
              setPistasD(arrayFinal);
            });
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        });
      } else {
      }
    } else {
      let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
      let data_fimf = moment(data_fim).format("YYYY-MM-DD");
      let hora_iniciof = moment(hora_inicio).format("hh:mm:ss");
      let hora_fimf = moment(hora_fim).format("hh:mm:ss");
      if (
        data_inicio &&
        data_fim &&
        hora_inicio &&
        hora_fim &&
        selectedPool &&
        (segunda || terca || quarta || quinta || sexta || sabado || domingo)
      ) {
        getDisponibilidade({
          id_piscina: idPiscina,
          data_inicio: data_iniciof,
          data_fim: data_fimf,
          hora_inicio: hora_iniciof,
          hora_fim: hora_fimf,
          id_piscina_agua: selectedPool,
          segunda: segunda,
          terca: terca,
          quarta: quarta,
          quinta: quinta,
          sexta: sexta,
          sabado: sabado,
          domingo: domingo,
        }).then((res) => {
          if (res.success) {
            let arrayFinal = [];
            let dados = res.data.data;
            dados.forEach((element) => {
              const linha = {
                id: element.id,
                name: element.name,
                ocupacao: element.ocupacao,
              };
              arrayFinal.push(linha);
              setPistasD(arrayFinal);
            });
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        });
      } else {
      }
    }
  }, [
    data_inicio,
    data_fim,
    hora_inicio,
    hora_fim,
    selectedPool,
    segunda,
    terca,
    quarta,
    quinta,
    sexta,
    sabado,
    domingo,
  ]);

  const handlePoolChange = (event) => {
    setSelectedPool(event.target.value);
    setErroPiscina(false);
  };

  useEffect(() => {
    const dataf = moment(data_fim).format("YYYY-MM-DD");
    const datai = moment(data_inicio).format("YYYY-MM-DD");
    // Verifica se as datas são iguais e atualiza o estado de exibição das checkboxes
    if (data_inicio && data_fim && datai === dataf) {
      setExibirCheckboxes(false);
      setSegunda(false);
      setTerca(false);
      setQuarta(false);
      setQuinta(false);
      setSexta(false);
      setSabado(false);
      setDomingo(false);
    } else {
      setExibirCheckboxes(true);
    }
  }, [data_inicio, data_fim]);

  const handleChange2 = (event) => {
    switch (event.target.name) {
      case "segunda":
        setSegunda(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTerca(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuarta(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuinta(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSexta(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabado(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingo(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };
  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleTipoChange = (event) => {
    setSelectedTipo(event.target.value);
    setErroTipo(false);
  };

  const handleChange = (event) => {
    const novoEspacoPista = event.target.checked ? 1 : 2;
    setEspacoPista(novoEspacoPista);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaTiposEspacos({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setTipos(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaPiscinasAgua({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    getEspacos({ id_ocupa_espaco: idEspaco })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];
          setDadosOriginais(dados);
          setObs(dados.obs);
          setSelectedTipo(dados.tipo);
          setEspacoPista(dados.espaco_pista);
          setDataInicio(dados.data_inicio);
          setDataFim(dados.data_fim);
          setVagas(dados.vagas);
          setSelectedPool(dados.id_piscina_agua);
          setRows(dados.pistas);

          setHoraInicio(parseISO(`1970-01-01T${dados.hora_inicio}`));
          setHoraFim(parseISO(`1970-01-01T${dados.hora_fim}`));
          setSegunda(dados.segunda);
          setTerca(dados.terca);
          setQuarta(dados.quarta);
          setQuinta(dados.quinta);
          setSexta(dados.sexta);
          setSabado(dados.sabado);
          setDomingo(dados.domingo);
          setSelectedCategory(dados.id_categoria);

          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName(
        "cardOpcoesPiscinaEliminar3"
      )[0].style.cursor = "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName(
      "cardOpcoesPiscinaEliminar3"
    )[0].style.cursor = "pointer";
    setEdicao(!edicao);

    setDataInicio(dadosOriginais.data_inicio);
    setDataFim(dadosOriginais.data_fim);
    setSelectedPool(dadosOriginais.id_piscina_agua);
    setSelectedTipo(dadosOriginais.tipo);
    setVagas(dadosOriginais.vagas);
    setRows(dadosOriginais.pistas);
    setEspacoPista(dadosOriginais.espaco_pista);
    setTamanhoPista(dadosOriginais.tamanho_pista);
    setHoraInicio(toISOString(`1970-01-01T${dadosOriginais.hora_inicio}`));
    setHoraFim(parseISO(`1970-01-01T${dadosOriginais.hora_fim}`));
    setSegunda(dadosOriginais.segunda);
    setTerca(dadosOriginais.terca);
    setQuarta(dadosOriginais.quarta);
    setQuinta(dadosOriginais.quinta);
    setSexta(dadosOriginais.sexta);
    setSabado(dadosOriginais.sabado);
    setDomingo(dadosOriginais.domingo);
    setObs(dadosOriginais.obs);
    setSelectedCategory(dadosOriginais.id_categoria);

    window.scrollTo(0, 0);
  }
  function editarEspaco() {
    let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
    let data_fimf = moment(data_fim).format("YYYY-MM-DD");
    let hora_iniciof = moment(hora_inicio).format("HH:mm");
    let hora_fimf = moment(hora_fim).format("HH:mm");

    setFreeze(true);
    editarEspacos({
      id_ocupa_espaco: idEspaco,
      id_piscina_agua: selectedPool,
      id_categoria: selectedCategory,
      pistas: rows,
      data_inicio: data_iniciof,
      data_fim: data_fimf,
      hora_inicio: hora_iniciof,
      hora_fim: hora_fimf,
      segunda: segunda,
      terca: terca,
      quarta: quarta,
      quinta: quinta,
      sexta: sexta,
      sabado: sabado,
      domingo: domingo,
      vagas: vagas,
      obs: obs,
      id_piscina: idPiscina,
      id_entidade_exploradora: idEE,
      tipo: selectedTipo,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            navigate("/500");
            setFreeze(false)
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      segunda == 0 &&
      terca == 0 &&
      quarta == 0 &&
      quinta == 0 &&
      sexta == 0 &&
      sabado == 0 &&
      domingo == 0
    ) {
      toast.error(t("selecionadia"));
    } else {
      if (
        (selectedPool &&
          selectedCategory &&
          selectedTipo &&
          rows &&
          data_inicio &&
          data_fim &&
          hora_inicio &&
          hora_fim &&
          segunda) ||
        terca ||
        quarta ||
        quinta ||
        sexta ||
        sabado ||
        domingo
      ) {
        let confirmarDadosPreenchidos = true;

        if (confirmarDadosPreenchidos) {
          //Scroll para o início da página
          window.scrollTo(0, 0);
          editarEspaco();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        //Validação do nome
        if (!data_inicio) {
          setErroDataInicio(true);
        }
        if (!rows) {
          setErroPista(true);
        }

        if (vagas == 0) {
          setErroVagas(true);
        }
        if (!data_fim) {
          setErroDataFim(true);
        }
        if (!hora_inicio) {
          setErroHoraInicio(true);
        }
        if (!hora_fim) {
          setErroHoraFim(true);
        }
        if (!selectedCategory) {
          setErroCategoria(true);
        }
        if (!selectedTipo) {
          setErroTipo(true);
        }
        if (!selectedPool) {
          setErroPiscina(true);
        }
        if (
          segunda == 0 &&
          terca == 0 &&
          quarta == 0 &&
          quinta == 0 &&
          sexta == 0 &&
          sabado == 0 &&
          domingo == 0
        ) {
          toast.error(t("selecionadia"));
        }
      }
    }
  }

  function eliminarEspacos() {
    setFreeze(true);
    eliminarEspacos({
      id_ocupa_espaco: idEspaco,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("espacoEliminado"));
          navigate("/spacelista");
        } else {
          setFreeze(false);
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 406) {
            toast.info(res.error);
          } else {
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  onClick={() => {
                    navigate("/spacelista");
                  }}
                  size={isMobile ? "small" : "medium"}
                >
                  {" "}
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </IconButton>
              ) : null}
            </div>

            {/* <div className="align-right">
              <h3 className="titleList">{t("editarEspacos")}</h3>
            </div> */}
          </div>
          <div>
            <div className="formPage4">
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina2">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>

                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarAula")}
            </DialogTitle>
            <DialogContent>{t("justificacaoEliminacao")}</DialogContent>
            <DialogActions>
              {" "}
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarEspacos();
                }}
                autoFocus
              >
                {t("eliminar")}
              </Button>
            </DialogActions>
          </Dialog>

          <div>
            <div className="divForm">
              <div className="containerForm"></div>
              <div>
                <div className="formPage">
                  <Box className="formTextField">
                    <div>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroTipo}
                        >
                          {t("escolhatipo")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedTipo}
                          onChange={handleTipoChange}
                          error={erroTipo}
                          fullWidth
                          variant="standard"
                        >
                          {tipos.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>
                              {tipo.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroPiscina}
                        >
                          {t("escolhapiscina")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedPool}
                          onChange={handlePoolChange}
                          error={erroPiscina}
                          fullWidth
                          variant="standard"
                        >
                          {piscinas.map((piscina) => (
                            <MenuItem key={piscina.id} value={piscina.id}>
                              {piscina.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoria}
                        >
                          {t("escolhacategoria")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          error={erroCategoria}
                          fullWidth
                          variant="standard"
                        >
                          {categorias.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.categoria}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          disabled={edicao}
                          required
                          type="number"
                          variant="standard"
                          label={t("vagas")}
                          value={vagas}
                          error={erroVagas}
                          onChange={(e) => {
                            const value = e.target.value;

                            setVagas(value);
                            setErroVagas(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            disabled={edicao}
                            label={t("datainicio")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_inicio}
                            onChange={(e) => {
                              setDataInicio(e.$d);
                              setErroDataInicio(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataInicio}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            disabled={edicao}
                            label={t("datafim")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_fim}
                            onChange={(e) => {
                              setDataFim(e.$d);
                              setErroDataFim(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataFim}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            disabled={edicao}
                            label={t("horainicio")}
                            value={hora_inicio}
                            onChange={(date) => setHoraInicio(date.$d)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                error={erroHoraInicio}
                                required
                              />
                            )}
                            ampm={false}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            disabled={edicao}
                            label={t("horafim")}
                            value={hora_fim}
                            onChange={(date) => setHoraFim(date.$d)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                error={erroHoraFim}
                                required
                              />
                            )}
                            ampm={false}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {exibirCheckboxes && (
                        <FormControl className="formTextField2" fullWidth>
                          <Typography variant="subtitle" align="left">
                            {t("escolhadia")}
                          </Typography>

                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={segunda}
                                  onChange={handleChange2}
                                  name="segunda"
                                  color="primary"
                                />
                              }
                              label={t("segunda")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={terca}
                                  onChange={handleChange2}
                                  name="terca"
                                  color="primary"
                                />
                              }
                              label={t("terca")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={quarta}
                                  onChange={handleChange2}
                                  name="quarta"
                                  color="primary"
                                />
                              }
                              label={t("quarta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={quinta}
                                  onChange={handleChange2}
                                  name="quinta"
                                  color="primary"
                                />
                              }
                              label={t("quinta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={sexta}
                                  onChange={handleChange2}
                                  name="sexta"
                                  color="primary"
                                />
                              }
                              label={t("sexta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={sabado}
                                  onChange={handleChange2}
                                  name="sabado"
                                  color="primary"
                                />
                              }
                              label={t("sabado")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={domingo}
                                  onChange={handleChange2}
                                  name="domingo"
                                  color="primary"
                                />
                              }
                              label={t("domingo")}
                            />
                          </div>
                        </FormControl>
                      )}
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          disabled={edicao}
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={2}
                          value={obs}
                          label={t("observacoes")}
                          error={erroObs}
                          onChange={(e) => {
                            setObs(e.target.value);
                            setErroObs(false);
                          }}
                        />
                      </FormControl>
                      <div style={{ marginLeft: 10, marginTop: 10 }}>
                        {rows.map((row, index) => (
                          <div
                            key={row.id}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormControl
                              className="formTextField2"
                              style={{
                                width: "40%",
                                marginRight: "10px",
                                marginTop: 10,
                              }}
                            >
                              <InputLabel
                                className="formTextField22"
                                disabled={edicao}
                              >
                                {t("pista")}
                              </InputLabel>
                              <Select
                                disabled={edicao}
                                value={row.id}
                                onChange={(event) =>
                                  handlePistaChange(event, index)
                                }
                                variant="standard"
                              >
                                {row.id ? (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                  </MenuItem>
                                ) : null}
                                {[...pistasD, ...rows].map((item) => {
                                  const isPistaSelected = rows.some(
                                    (r) => r.id === item.id && r !== row
                                  );

                                  if (isPistaSelected) {
                                    return null;
                                  }
                                  if (item.id != row.id) {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </Select>
                            </FormControl>

                            {row && (
                              <FormControl
                                className="formTextField2"
                                style={{ width: "40%" }}
                              >
                                <RadioGroup
                                  disabled={edicao}
                                  value={row.ocupacao}
                                  onChange={(event) =>
                                    handleOcupacaoChange(event, index)
                                  }
                                >
                                  {pistasOriginais.includes(row) &&
                                  row.ocupacao === 2 ? (
                                    <FormControlLabel
                                      key={row.id}
                                      value={row.ocupacao}
                                      control={<Radio />}
                                      label={"Meia Pista"}
                                      disabled={true}
                                    />
                                  ) : (
                                    <>
                                      {!pistasOriginais.includes(row) && (
                                        <FormControlLabel
                                          key={row.id + "_completa"}
                                          value={1}
                                          disabled={edicao}
                                          control={<Radio color="primary" />}
                                          label="Pista Completa"
                                        />
                                      )}
                                      <FormControlLabel
                                        key={row.id + "_meia"}
                                        disabled={edicao}
                                        value={2}
                                        control={<Radio color="primary" />}
                                        label="Meia Pista"
                                      />
                                    </>
                                  )}
                                </RadioGroup>
                              </FormControl>
                            )}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                disabled={edicao}
                                variant="contained"
                                onClick={() => handleRemoveRow(index)}
                                className="transparentButton"
                              >
                                X
                              </Button>
                            </div>
                          </div>
                        ))}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginLeft: 20,
                            marginTop: 10,
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={addRow}
                            className="inverted-button"
                            disabled={edicao}
                          >
                            {t("adicionarPista")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarEspacoA;
