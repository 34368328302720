import FullCalendar from "@fullcalendar/react";
import Cookies from "js-cookie";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { listaAulasProfessor } from "../../api/requests/Professores";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import {
  enviarEmailNovoPedido,
  enviarEmailNovoPedido2,
  postPedidos,
} from "../../api/requests/Pedidos";
import { compose } from "@mui/system";
import Nav from "../../components/Nav";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { listaProfessores } from "../../api/requests/PiscinaAgua";

function Dashboard_Professor() {
  //Traduções
  const { t, i18n } = useTranslation();

  const moment = require("moment");

  //Navigator
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);
  const [aulas, setAulas] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [professorEscolhido, setProfessorEscolhido] = useState("");

  const handleClose = () => {
    setOpen(false);
    setCancelar(false);
    setEventInfo(null);
    setMotivoSuspensao("");
  };

  let today = new Date();

  const [cancelar, setCancelar] = useState(false);
  const [freeze, setFreeze] = useState(false);

  const [weekDateRange, setWeekDateRange] = useState("");
  const calendarRef = useRef(null);

  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleEventClick = (info) => {
    setEventInfo(info);
    setOpen(true);
  };
  useEffect(() => {
    const elements = document.getElementsByClassName("fc-event");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].getElementsByClassName("fc-cancelado")[0]) {
        elements[i].style.setProperty(
          "background-color",
          "#F04643",
          "important"
        );
        elements[i].style.setProperty("color", "white");
      }
    }
  }, [aulas]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaAulasProfessor().then((res) => {
      if (res.success) {
        let arrayFinal = [];
        let dados = res.data.data;
        dados.forEach((element) => {
          const dataParts = element.dia.split("/");
          const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
          const data = new Date(dataFormatada);
          const dataInicio = `${data.toISOString().substr(0, 10)}T${
            element.horaInicio
          }`;
          const dataFim = `${data.toISOString().substr(0, 10)}T${
            element.horaFim
          }`;
          const hoje = new Date().toISOString();

          const linha = {
            id: element.id_aula,
            id_piscina: element.id_piscina,
            title: element.nome,
            cancelado: element.cancelado,
            description: element.nomePiscina,
            piscina: element.piscina,
            id_turma: element.id_turma,
            start: dataInicio,
            end: dataFim,
            color:
              element.cancelado == 1
                ? "#F04643"
                : dataFim < hoje
                ? "#54DE90"
                : "#00C4F5",
          };
          arrayFinal.push(linha);
        });
        setAulas(arrayFinal);
      }
    });
  }, []);

  function handleEventMount(info) {
    const eventElement = info.el;

    eventElement.addEventListener("mouseover", () => {
      eventElement.classList.add("event-pointer");
    });

    eventElement.addEventListener("mouseout", () => {
      eventElement.classList.remove("event-pointer");
    });
  }

  function cancelarAula() {
    if (motivoSuspensao) {
      setMotivoSuspensao("");
      setFreeze(true);
      let id_piscina = eventInfo.event._def.extendedProps.id_piscina;
      let id_turma = eventInfo.event._def.extendedProps.id_turma;
      postPedidos({
        //Id da aula
        id: eventInfo.event.id,
        acao: 4,
        motivo: motivoSuspensao,
        tipo: 3,
        data: moment(eventInfo.event.start).format("YYYY-MM-DD"),
        hora_inicio: eventInfo.event.start.toLocaleTimeString(),
        hora_fim: eventInfo.event.end.toLocaleTimeString(),
        id_piscina: id_piscina,
        id_turma: id_turma,
        id_professor:professorEscolhido
      }).then((res) => {
        if (res.success) {
          setTimeout(async function () {
            enviarEmailNovoPedido2({
              piscina: id_piscina,
            }).then((res2) => {
              if (res2.success) {
                setFreeze(false);
                setCancelar(false);
                setOpen(false);
                toast.success(res.data.msg);
              } else {
                setFreeze(false);
                setCancelar(false);
                setOpen(false);
                toast.error(res.error);
              }
            });
          }, 3000);
        } else {
          setFreeze(false);
          setCancelar(false);
          setOpen(false);
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      });
    } else {
      setFreeze(false)
      setErroMotivoSuspensao(true);
    }
  }

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    );
    calendarApi.gotoDate(newDate);
  };

  const handleProfessorChange = (event) => {
    setProfessorEscolhido(event.target.value);
    setErroProfessor(false);
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 7
    );
    calendarApi.gotoDate(newDate);
  };

  const titleFormat = () => {
    const prefix = t("minhasemana") + " (" + weekDateRange + ")";
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat(
      i18n.language,
      options
    ).format(date);

    return prefix;
  };

  const dayHeaderFormat = {
    weekday: "short",
    month: "numeric",
    day: "numeric",
  };
  const handleTodayClick = () => {
    calendarRef.current.getApi().today();
  };
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 799); // Defina o limite de largura para o que você considera um "telefone"
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "40px",
            backgroundColor: "transparent",
          }}
        >
          <h3 className="titleMarcar">{t("atuasemana")}</h3>
          {freeze && (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <Dialog
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  textAlign: "center",
                }}
              >
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("aguarde")}
                  </Typography>
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </DialogContent>
              </Dialog>
            </div>
          )}
          <div className="calendar-container">
            <div className="calendar-wrapper">
              {/* <h1 className="dashboardTitle" style={{ paddingBottom: "20px" }}>
            {t("ola2")} {Cookies.get("uName")}
            {t("proximasaulas")}
          </h1> */}
              <FullCalendar
                locales={allLocales}
                locale={i18n.language}
                contentHeight={420}
                titleFormat={titleFormat}
                headerToolbar={{
                  start: isMobile ? "title" : "",
                  center: isMobile ? "prev next today" : "title",
                  end: isMobile ? "" : "prev next today",
                }}
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  bootstrap5Plugin,
                ]}
                customButtons={{
                  prev: {
                    text: "<",
                    click: handlePrevClick,
                    className: "custom-calendar-button",
                  },
                  next: {
                    text: ">",
                    click: handleNextClick,
                    className: "custom-calendar-button",
                  },
                  today: {
                    text: t("hoje"),
                    click: handleTodayClick,
                    classNames: "custom-calendar-button",
                  },
                }}
                dayHeaderFormat={dayHeaderFormat}
                stickyHeaderDates={false}
                initialView="listWeek"
                selectable={true}
                events={aulas}
                eventClick={handleEventClick}
                eventDidMount={handleEventMount}
                datesSet={(dates) => {
                  if (Cookies.get("language") == "pt") {
                    moment.locale("pt-br");
                    const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                    const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                    const weekDateRange = `${startOfWeek.format(
                      "MMMM D"
                    )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                    setWeekDateRange(weekDateRange);
                  } else if (Cookies.get("language") == "en") {
                    moment.locale("en");
                    const startOfWeek = moment(dates.start).add(1, "day"); // início da semana (segunda-feira)
                    const endOfWeek = moment(dates.end); // fim da semana (domingo)
                    const weekDateRange = `${startOfWeek.format(
                      "MMMM D"
                    )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                    setWeekDateRange(weekDateRange);
                  } else if (Cookies.get("language") == "es") {
                    moment.locale("es");
                    const startOfWeek = moment(dates.start); // início da semana (segunda-feira)
                    const endOfWeek = moment(dates.end).subtract(1, "day"); // fim da semana (domingo)
                    const weekDateRange = `${startOfWeek.format(
                      "MMMM D"
                    )} - ${endOfWeek.format("MMMM D")}`; // formato "MMMM D"
                    setWeekDateRange(weekDateRange);
                  }
                }}
                eventContent={(arg) => {
                  const { event } = arg;
                  const { title, extendedProps } = event;
                  const isCancelled = extendedProps.cancelado;
                  const canceladoElement = isCancelled
                    ? `<span className="fc-cancelado" style="background-color: #F04643; color: white; border-radius:4px">‎ ${t(
                        "cancelado".toUpperCase()
                      )}‎ ‎ </span>`
                    : "";
                  const nomeFilho = extendedProps.id_filho
                    ? `<span className="fc-filho" style="background-color: #00C4F5; ">${extendedProps.nome_filho}</span>`
                    : "";
                  const elements = document.getElementsByClassName("fc-event");
                  for (let i = 0; i < elements.length; i++) {
                    if (elements[i].getElementsByClassName("fc-cancelado")[0]) {
                      //elements[i].style.setProperty("pointer-events", "none", "important");
                      elements[i].style.setProperty(
                        "background-color",
                        "#F04643"
                      );
                      elements[i].style.setProperty("color", "white");
                    }
                    if (
                      elements[i].getElementsByClassName("fc-filho")[0] &&
                      isCancelled
                    ) {
                      elements[i].style.setProperty(
                        "background-color",
                        "#F04643"
                      );
                      elements[i].style.setProperty("color", "white");
                    }
                    elements[i].style.setProperty(
                      "margin-bottom",
                      "5px",
                      "important"
                    ); // Adicione essa linha para definir a margem inferior
                  }

                  return {
                    html: isCancelled
                      ? `<div className="fc-content">${title} ${nomeFilho} ${canceladoElement}</div>`
                      : `<div className="fc-content">${title} ${nomeFilho}</div>`,
                  };
                }}
              />
            </div>
            {open && (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                  style: {
                    width: 600,
                    height: cancelar ? 408 : 308,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  style={{
                    backgroundColor: eventInfo.event.backgroundColor,
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {eventInfo && eventInfo.event.title}
                  </div>
                  {eventInfo.event.extendedProps.cancelado == 1 ? (
                    <span className="fc-cancelado2">‎ 
                      {t("cancelado").toUpperCase()}‎ ‎ 
                    </span>
                  ) : null}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleClose}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                {!cancelar ? (
                  <DialogContent>
                    <div style={{ marginTop: "20px" }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        style={{
                          fontWeight: "600",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {eventInfo &&
                          eventInfo.event.start.toLocaleDateString()}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("inicio")}:{" "}
                        {eventInfo &&
                          eventInfo.event.start.toLocaleTimeString()}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("fim")}:{" "}
                        {eventInfo && eventInfo.event.end.toLocaleTimeString()}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("local")}:{" "}
                        {eventInfo && eventInfo.event.extendedProps.description}
                      </Typography>
                      {eventInfo.event.start > today &&
                        eventInfo.event.extendedProps.cancelado == 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: 10,
                            }}
                          >
                            <Button
                              className="btnMarcacoes"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 auto ",
                              }}
                              onClick={() => {
                                setCancelar(true);
                              }}
                            >
                              {t("cancelar")}
                            </Button>
                          </div>
                        )}
                    </div>
                  </DialogContent>
                ) : (
                  <DialogContent style={{ marginTop: 10 }}>
                    {t("justificacaoCancelamento")}*
                    <FormControl fullWidth>
                      <TextField
                        style={{ textAlign: "left", marginTop: 10 }}
                        variant="outlined"
                        multiline
                        rows={5}
                        value={motivoSuspensao}
                        error={erroMotivoSuspensao}
                        onChange={(e) => {
                          setMotivoSuspensao(e.target.value);
                          setErroMotivoSuspensao(false);
                        }}
                      />
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                        <InputLabel className="formTextField22">
                          {t("escolhaprofessor")}*
                        </InputLabel>
                        <Select
                          value={professorEscolhido}
                          onChange={handleProfessorChange}
                          fullWidth
                          variant="standard"
                        >
                          {professores.map((professor) => (
                            <MenuItem key={professor.id} value={professor.id}>
                              {professor.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </DialogContent>
                )}
                {cancelar && (
                  <DialogActions>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        setCancelar(false);
                      }}
                    >
                      {t("cancelar")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        cancelarAula();
                      }}
                    >
                      {t("fazerPedido")}
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default Dashboard_Professor;
