import Cookies, { set } from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box, Stack, height } from "@mui/system";
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  getInscricoesId,
  getUtilizadoresId,
  putUtilizador,
} from "../../api/requests/Utilizadores";
import { useLocation, useNavigate, useParams } from "react-router";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { getPiscinas } from "../../api/requests/Piscinas";
import { Search } from "../../components/DataTable";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import {
  faArrowLeft,
  faClose,
  faPen,
  faPlus,
  faPlusCircle,
  faQuestion,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteFilho,
  deleteFilho2,
  getFilho,
  getFilhos2,
  postFilho2,
  putFilho,
} from "../../api/requests/ContaFamiliar";
import { Card, CardContent, CircularProgress } from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@mui/material/Grid";
import Nav from "../../components/Nav";
import { Scrollbar } from "react-scrollbars-custom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { PatternFormat } from "react-number-format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { verificarNif } from "../../api/requests/Registo";
import PhoneInput from "react-phone-number-input";
import en from "dayjs/locale/en";
import es from "dayjs/locale/es";
import pt from "dayjs/locale/pt";
import PhoneNumber from "../../components/PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";
import { verificarPagamentosEmDia } from "../../api/requests/Pagamentos";
import { FaLastfmSquare } from "react-icons/fa";

function MinhaFamilia() {
  //Traduções
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [semDados, setSemDados] = useState(true);
  const [idu, setidu] = useState("");
  const [freeze, setFreeze] = useState(false);

  const [edicao, setEdicao] = useState(true);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosOriginaisFilhos, setDadosOriginaisFilhos] = useState([]);

  const [dadosUtilizador, setDadosUtilizador] = useState([]);
  const [dadosFilhos, setDadosFilhos] = useState([]);
  const [eliminar, setEliminar] = useState(false);

  //Responsável
  const [id, setId] = useState("");
  const [tokenu, setTokenU] = useState("");
  const [nome, setNome] = useState("");
  const [nif, setNif] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [morada, setMorada] = useState("");
  const [genero, setGenero] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [foto, setFoto] = useState([]);
  const [fotoEditar, setFotoEditar] = useState([]);

  const [erroNome, setErroNome] = useState(false);
  const [erroGenero, seterroGenero] = useState(false);
  const [erroNif, setErroNif] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroCodigoPostal, setErroCodigoPostal] = useState(false);
  const [erroFoto, setErroFoto] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [erroTelefone, setErroTelefone] = useState(false);

  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroNif, setMensagemErroNif] = useState("");
  const [mensagemErroGenero, setMensagemErroGenero] = useState("");
  const [mensagemErroMorada, setMensagemErroMorada] = useState("");
  const [mensagemErroNome, setMensagemErroNome] = useState("");
  const [mensagemErroCodigoPostal, setMensagemErroCodigoPostal] = useState("");
  const [mensagemErroDataNascimento, setMensagemErroDataNascimento] =
    useState("");
  const [idFilhoEditado, setidFilhoEditado] = useState("");

  function goBack() {
    navigate("/dashboard_customer");
  }

  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [openEditarDados, setOpenEditarDados] = useState(false);
  const handleCloseEditarDados = () => {
    setEdicao(true);
    setOpenEditarDados(false);
  };

  const handleClickOpenEditarDados = () => {
    setOpenEditarDados(true);
  };
  const [openEditarDadosFilhos, setOpenEditarDadosFilhos] = useState(false);
  const handleCloseEditarDadosFilhos = () => {
    setEdicao(true);
    setOpenEditarDadosFilhos(false);
  };
  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };
  const handleClickOpenEditarDadosFilhos = () => {
    setOpenEditarDadosFilhos(true);
  };
  const ref = useRef(null);

  const scrollToDiv = () => {
    setEdicao(!edicao);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const idadeMinima = 16;
  const dataMinima = new Date();
  dataMinima.setFullYear(dataMinima.getFullYear() - idadeMinima);
  dataMinima.setHours(0, 0, 0, 0);

  useEffect(() => {
    setTimeout(() => {
      verificarPagamentosEmDia().then((res) => {
        if (res.data.success) {
          navigate("/dashboard_customer");
        }
      });
    }, 20);
  }, []);

  //Adicionar Filho
  const [openAdicionarFilho, setOpenAdicionarFilho] = useState(false);
  const handleCloseAdicionarFilho = () => setOpenAdicionarFilho(false);

  function limparVariaveis() {
    setNome("");
    setMorada("");
    setNif("");
    setCodigoPostal("");
    setDataNascimento("");
    setFoto("");
    setGenero("");
  }

  const handleClickOpenAdicionarFilho = () => {
    limparVariaveis();
    setOpenAdicionarFilho(true);
  };
  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    setidu(id);
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    getUtilizadoresId(id, {}).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setCarregamentoDadosCompleto(true);
        let utilizador = [];
        let linha = {
          id: dados.id_utilizador,
          nome: dados.nome,
          nif: dados.nif,
          img: dados.foto,
          genero: dados.genero,
          filho: 0,
          codigo_postal: dados.codigo_postal,
          data_nascimento: dados.data_nascimento,
          morada: dados.morada,
          contacto: dados.contacto,
          email: dados.email,
        };
        utilizador.push(linha);

        setDadosOriginais(dados);
        setDadosUtilizador(utilizador);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getFilhos2({ id: id }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_filho,
            nome: element.nome,
            nif: element.nif,
            img: element.foto,
            filho: 1,
            genero: element.genero,
            codigo_postal: element.codigo_postal,
            data_nascimento: element.data_nascimento_final,
            morada: element.morada,
          };

          arrayFinal.push(linha);
        });
        setUsers(arrayFinal);
        setDadosOriginaisFilhos(arrayFinal);
        setSemDados(false);
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });
  }, []);

  function atualizarListaFilhos() {
    getFilhos2({ id: idu }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_filho,
            nome: element.nome,
            nif: element.nif,
            img: element.foto,
            genero: element.genero,
            filho: 1,
            codigo_postal: element.codigo_postal,
            data_nascimento: element.data_nascimento_final,
            morada: element.morada,
          };

          arrayFinal.push(linha);
        });
        setUsers(arrayFinal);
        setDadosOriginaisFilhos(arrayFinal);
        setSemDados(false);
      } else {
        navigate("/dashboard_customer");
      }
    });
  }

  function criarFilho() {
    setFreeze(true);
    postFilho2({
      id: idu,
      nome: nome,
      morada: morada,
      codigo_postal: codigoPostal,
      data_nascimento: new Date(dataNascimento).toISOString().split("T")[0],
      nif: nif,
      genero: genero,
      foto: foto,
    }).then((res) => {
      if (res.success) {
        atualizarListaFilhos();
        setFreeze(false);
        handleCloseAdicionarFilho();
        toast.success(res.data.msg);
      } else {
        setFreeze(false);
        handleCloseAdicionarFilho();
        toast.error(res.error);
      }
    });
  }
  async function validacao1() {
    if (nome && morada && nif && codigoPostal && dataNascimento && genero) {
      let confirmarDadosPreenchidos = true;

      let dataNascimentoDate = new Date(dataNascimento);
      let hoje = new Date();
      let idadeMinima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos > idadeMinima ||
        (diffAnos === idadeMinima && diffMeses > 0) ||
        (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
      ) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("menor16"));
      }
      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }
      if (codigoPostal.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }

      if (!genero) {
        confirmarDadosPreenchidos = false;
        seterroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        criarFilho();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }

      if (!morada) {
        setErroMorada(true);
      }

      if (!dataNascimento) {
        setErroDataNascimento(true);
      }

      //Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });

      if (!codigoPostal) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal.includes("_")) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }
      if (!genero) {
        confirmarDadosPreenchidos = false;
        seterroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }
      //Validação da foto
      // if (foto.length == 0) {
      //   document.getElementById("fotoPiscina").style.borderColor =
      //     Colors.inativo;
      //   document.getElementById("tituloFotoPiscina").style.color =
      //     Colors.inativo;
      // }
    }
  }
  async function validacao() {
    if (
      nome &&
      nif &&
      dataNascimento &&
      email &&
      telefone &&
      morada &&
      genero &&
      codigoPostal
    ) {
      let confirmarDadosPreenchidos = true;
      // Validação nome
      if (nome.length < 0) {
        confirmarDadosPreenchidos = false;
        setErroNome(true);
        setMensagemErroNome(t("validacaoNome"));
      }
      // Validação nif
      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }
      if (!genero) {
        confirmarDadosPreenchidos = false;
        seterroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }
      // Validação data de nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
      }
      let dataNascimentoDate = new Date(dataNascimento);
      let hoje = new Date();
      let idadeMinima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos <= idadeMinima ||
        (diffAnos === idadeMinima && diffMeses > 0) ||
        (diffAnos === idadeMinima && diffMeses === 0 && diffDias > 0)
      ) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("menor16"));
      }

      // Validação email
      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmail(true);
        setMensagemErroEmail(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefone)) {
        confirmarDadosPreenchidos = false;
        setErroTelefone(true);
        setMensagemErroTelefone(t("validacaoTelemovel"));
      }
      if (codigoPostal.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }
      if (confirmarDadosPreenchidos) {
        setFreeze(true);
        var datan = new Date(dataNascimento).toISOString().split("T")[0];

        putUtilizador({
          id_utilizador: id,
          nome: nome,
          data_nascimento: datan,
          nif: nif,
          genero: genero,
          email: email,
          contacto: telefone,
          morada: morada,
          codigo_postal: codigoPostal,
          foto: fotoEditar,
        }).then((res) => {
          if (res.success) {
            let utilizador2 = [];
            let linha = {
              id: id,
              nome: nome,
              nif: nif,
              img: fotoEditar,
              genero: genero,
              filho: 0,
              codigo_postal: codigoPostal,
              data_nascimento: dataNascimento,
              morada: morada,
              contacto: telefone,
              email: email,
            };
            utilizador2.push(linha);
            setDadosUtilizador(utilizador2);
            setDadosOriginais(utilizador2);
            setFreeze(false);
            setEdicao(!edicao);
            //Scroll para o início da página
            window.scrollTo(0, 0);
            toast.success(t("dadosEditadosSucesso"));

            //Alterar a variável do session storage que obriga o utilizador a preencher os dados

            // let utilizador = Cookies.get("utilizador");
            // if (utilizador == "superadmin") {
            //   navigate("/dashboard_superadmin");
            // } else if (utilizador == "supercomercial") {
            //   navigate("/dashboard_supercommercial");
            // } else if (utilizador == "comercial") {
            //   navigate("/dashboard_commercial");
            // } else if (utilizador == "admin") {
            //   navigate("/dashboard_admin");
            // } else if (utilizador == "funcionario") {
            //   navigate("/dashboard_employee");
            // } else if (utilizador == "professor") {
            //   navigate("/dashboard_teacher");
            // } else if (utilizador == "cliente") {
            //   navigate("/dashboard_customer");
            // }
          } else {
            if (res.status == 500) {
              setFreeze(false);
              navigate("/500");
            }
            setFreeze(false);
            toast.error(res.error);
          }
        });
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      //Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });

      //Validação data nascimento
      if (!dataNascimento) {
        setDataNascimento("");
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("validacaoData"));
      }
      if (!genero) {
        confirmarDadosPreenchidos = false;
        seterroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }
      //Validação do email
      if (!email) {
        setErroEmail(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          );
        if (!validacaoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
      //Validação do telefone
      if (!telefone) {
        setErroTelefone(true);
      } else {
        if (!isValidPhoneNumber(telefone)) {
          setErroTelefone(true);
          setMensagemErroTelefone(t("validacaoTelemovel"));
        }
      }
      //Validação da rua
      if (!morada) {
        setErroMorada(true);
      }

      //Validação do código postal
      if (codigoPostal == "" || codigoPostal.length == 0) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal.length < 7) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }

      //Validação da foto
      // if (foto.length == 0) {
      //   document.getElementById("fotoPiscina").style.borderColor =
      //     Colors.inativo;
      //   document.getElementById("tituloFotoPiscina").style.color =
      //     Colors.inativo;
      // }
    }
  }
  function cancelarFilho() {
    handleClosePopupCancelar();

    setEdicao(!edicao);
    dadosOriginaisFilhos.forEach((element) => {
      if (element.id == id) {
        setNome(element.nome);
        setNif(element.nif);
        setGenero(element.genero);
        setDataNascimento(element.data_nascimento);
        setCodigoPostal(element.codigo_postal);
        setMorada(element.morada);
        if (element.foto) {
          setFoto(element.foto);
        } else {
          setFoto([]);
        }
      }
    });
    //Colocar os dados originais
    // setNome(dadosOriginais.nome);
    // setNif(dadosOriginais.nif);
    // setDataNascimento(dadosOriginais.dataNascimento.substring(0, 10));
    // setEmail(dadosOriginais.email);
    // setTelefone(dadosOriginais.contacto);
    // setMorada(dadosOriginais.morada);
    // setCodigoPostal(dadosOriginais.codigo_postal);
    // setFotoEditar(dadosOriginais.foto);

    //Limpar erros e mensagens de erro
    setErroNome(false);
    setErroNif(false);
    setErroDataNascimento(false);
    setErroEmail(false);
    seterroGenero(false);
    setErroTelefone(false);
    setErroMorada(false);
    setErroCodigoPostal(false);

    setMensagemErroNif("");
    setMensagemErroGenero("");
    setMensagemErroDataNascimento("");
    setMensagemErroEmail("");
    setMensagemErroTelefone("");
    setMensagemErroCodigoPostal("");
    window.scrollTo(0, 0);
  }

  function eliminarFilho() {
    setFreeze(true);
    deleteFilho2({ id: id, idUtilizador: idu }).then((res) => {
      if (res.success) {
        atualizarListaFilhos();
        setFreeze(false);
        handleCloseEditarDadosFilhos();
        toast.success(t("filhoEliminadaSucesso"));
      } else {
        setFreeze(false);
        handleCloseEditarDadosFilhos();
        toast.error(res.error);
      }
    });
    setFreeze(false);
    setEliminar(false);
  }

  function editarFilho() {
    setFreeze(true);
    putFilho({
      id: id,
      nome: nome,
      nif: nif,
      genero: genero,
      morada: morada,
      codigo_postal: codigoPostal,
      data_nascimento: new Date(dataNascimento).toISOString().split("T")[0],
      foto: fotoEditar.length == 0 ? null : fotoEditar,
    }).then((res) => {
      if (res.success) {
        let novoArray = [];
        users.forEach((element) => {
          if (element.id == id) {
            const linha = {
              id: id,
              nome: nome,
              nif: nif,
              img: foto,
              genero: genero,
              filho: 1,
              codigo_postal: codigoPostal,
              data_nascimento: dataNascimento,
              morada: morada,
            };
            novoArray.push(linha);
          } else {
            const linha = {
              id: element.id_filho,
              nome: element.nome,
              nif: element.nif,
              img: element.foto,
              genero: element.genero,
              filho: 1,
              codigo_postal: element.codigo_postal,
              data_nascimento: element.data_nascimento,
              morada: element.morada,
            };
            novoArray.push(linha);
          }
        });
        setDadosOriginaisFilhos(novoArray);
        setUsers(novoArray);
        setFreeze(false);
        setEdicao(!edicao);
        toast.success(t("dadosEditadosSucesso"));
      } else {
        if (res.status == 500) {
          setFreeze(false);
          navigate("/500");
        }
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  async function validacao2() {
    if (nome && nif && morada && codigoPostal && dataNascimento && genero) {
      let confirmarDadosPreenchidos = true;
      //Validação do código postal
      if (codigoPostal < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimento);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("dataInvalida"));
      }
      if (!genero) {
        confirmarDadosPreenchidos = false;
        seterroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }
      let dataNascimentoDate = new Date(dataNascimento);
      let hoje = new Date();
      let idadeMaxima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos > idadeMaxima ||
        (diffAnos === idadeMaxima && diffMeses > 0) ||
        (diffAnos === idadeMaxima && diffMeses === 0 && diffDias > 0)
      ) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("menor16"));
      }
      // Validação do nif
      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif, filho: id }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarFilho();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      if (!genero) {
        confirmarDadosPreenchidos = false;
        seterroGenero(true);
        setMensagemErroGenero(t("validacaoGenero"));
      }
      // Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });
      //Validação da morada
      if (!morada) {
        setErroMorada(true);
      }
      //Validação do código postal
      if (!codigoPostal) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal < 7) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }
      let dataNascimentoDate = new Date(dataNascimento);
      let hoje = new Date();
      let idadeMaxima = 16;

      let diffAnos = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      let diffMeses = hoje.getMonth() - dataNascimentoDate.getMonth();
      let diffDias = hoje.getDate() - dataNascimentoDate.getDate();

      if (
        diffAnos < idadeMaxima ||
        (diffAnos === idadeMaxima && diffMeses > 0) ||
        (diffAnos === idadeMaxima && diffMeses === 0 && diffDias > 0)
      ) {
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("menor16"));
      }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);
    dadosOriginais.forEach((element) => {
      if (element.id == id) {
        setNome(element.nome);
        setNif(element.nif);
        setGenero(element.genero);
        setDataNascimento(element.data_nascimento);
        setCodigoPostal(element.codigo_postal);
        setMorada(element.morada);
        if (element.foto) {
          setFoto(element.foto);
        } else {
          setFoto([]);
        }
      }
    });

    //Colocar os dados originais
    // setNome(dadosOriginais.nome);
    // setNif(dadosOriginais.nif);
    // setDataNascimento(dadosOriginais.dataNascimento.substring(0, 10));
    // setEmail(dadosOriginais.email);
    // setTelefone(dadosOriginais.contacto);
    // setMorada(dadosOriginais.morada);
    // setCodigoPostal(dadosOriginais.codigo_postal);
    // setFotoEditar(dadosOriginais.foto);

    //Limpar erros e mensagens de erro
    setErroNome(false);
    seterroGenero(false);
    setErroNif(false);
    setErroDataNascimento(false);
    setErroEmail(false);
    setErroTelefone(false);
    setErroMorada(false);
    setErroCodigoPostal(false);

    setMensagemErroNif("");
    setMensagemErroGenero("");
    setMensagemErroDataNascimento("");
    setMensagemErroEmail("");
    setMensagemErroTelefone("");
    setMensagemErroCodigoPostal("");
    window.scrollTo(0, 0);
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />

      <Header />
      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <div className="divTabela">
          {/* <div className="topleftcliente">
          <IconButton
            onClick={goBack}
            size={window.innerWidth < 600 ? "small" : "medium"}
          >
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div> */}
          <Paper
            elevation={0}
            style={{
              overflow: "auto",
              alignItems: "center",
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "transparent",
              width: "100%",
            }}
          >
            <h3 className="titleMarcar" style={{ marginTop: "2%" }}>
              {t("asuafamilia")}
            </h3>

            {semDados == true ? (
              <div
                className="divTabelaMarcar"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <CircularProgress disableShrink />
              </div>
            ) : (
              <div
                className="divTabelaMarcar2"
                style={{ overflow: "auto", maxHeight: "100%" }}
              >
                {/* <h1
              className="dashboardTitle"
              style={{ paddingTop: window.innerWidth > 800 ? null : "50px" }}
            >
              {t("asuafamilia")}
            </h1> */}
                <Scrollbar style={{ height: isMobile ? "48%" : "45%" }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <>
                      {users.map((element) => (
                        <Card
                          onClick={() => {
                            handleClickOpenEditarDadosFilhos();
                            setId(element.id);
                            setNome(element.nome);
                            setNif(element.nif);
                            setGenero(element.genero);
                            setDataNascimento(element.data_nascimento);
                            setMorada(element.morada);
                            setCodigoPostal(element.codigo_postal);

                            element.img != null
                              ? setFotoEditar(element.img)
                              : null;
                          }}
                          className="MenuCriancas"
                          sx={{
                            display: "flex",
                            margin: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            width: "300px",
                            height: "100px",
                            borderRadius: "10px",
                            backgroundColor: "rgb(194, 213, 254, 1)",
                          }}
                        >
                          <CardContent>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item sx={{ marginRight: "10px" }}>
                                <Avatar
                                  src={element.img}
                                  sx={{
                                    border: " 1px solid",
                                    borderColor: "rgba(255, 255, 255, 1)",
                                    width: "80px",
                                    height: "80px",
                                  }}
                                />
                              </Grid>
                              <Grid item sx={{ marginLeft: "10px" }}>
                                <Typography
                                  component="div"
                                  color="rgba(10, 86, 250, 1)"
                                >
                                  <a
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "10ch",
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    {element.nome}
                                  </a>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div"
                                >
                                  {element.nif}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}
                    </>
                    <>
                      {dadosUtilizador.map((element) => (
                        <Card
                          onClick={() => {
                            handleClickOpenEditarDados();
                            setNome(dadosUtilizador[0].nome);
                            setNif(dadosUtilizador[0].nif);
                            setDataNascimento(
                              dadosUtilizador[0].data_nascimento
                            );
                            setGenero(dadosUtilizador[0].genero);
                            setEmail(dadosUtilizador[0].email);
                            setTelefone(dadosUtilizador[0].contacto);
                            setMorada(dadosUtilizador[0].morada);
                            setCodigoPostal(dadosUtilizador[0].codigo_postal);

                            dadosUtilizador[0].img != null
                              ? setFotoEditar(dadosUtilizador[0].img)
                              : null;
                          }}
                          className="MenuCriancas"
                          sx={{
                            display: "flex",
                            margin: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            width: "300px",
                            height: "100px",
                            borderRadius: "10px",
                            backgroundColor: "rgb(194, 213, 254, 1)",
                          }}
                        >
                          <CardContent>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item sx={{ marginRight: "10px" }}>
                                <Avatar
                                  src={element.img}
                                  sx={{
                                    border: " 1px solid",
                                    borderColor: "rgba(255, 255, 255, 1)",
                                    width: "80px",
                                    height: "80px",
                                  }}
                                />
                              </Grid>
                              <Grid item sx={{ marginLeft: "10px" }}>
                                <Typography
                                  component="div"
                                  color="rgba(10, 86, 250, 1)"
                                >
                                  {element.nome}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div"
                                >
                                  {element.nif}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}
                    </>
                  </Grid>
                </Scrollbar>
                <Card
                  onClick={() => {
                    // navigate("/createchildclient", {
                    //   state: {
                    //     idCliente: idu,
                    //   },
                    // });
                    handleClickOpenAdicionarFilho();
                  }}
                  className="MenuCriancas2"
                  sx={{
                    display: "flex",
                    margin: isMobile ? "8vh" : "4vh",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "50px",
                    width: isMobile ? "191px" : "343px",
                    height: isMobile ? "50px" : "100px",
                    backgroundColor: "rgba(103, 110, 122, 1)",
                  }}
                >
                  <CardContent>
                    <Stack
                      direction={"row"}
                      style={{
                        alignItems: "center",
                        marginTop: "10px !important",
                      }}
                    >
                      <Grid item sx={{ marginRight: "10px" }}>
                        <FontAwesomeIcon
                          className="iconCardsFamilia"
                          icon={faPlusCircle}
                        />
                      </Grid>
                      <Grid item sx={{ marginLeft: "10px" }}>
                        <Typography
                          component="div"
                          style={{ color: "whitesmoke" }}
                        >
                          {t("adicionarfilho")}
                        </Typography>
                      </Grid>
                    </Stack>
                  </CardContent>
                </Card>

                {/* adicionar filhos */}
                <Dialog
                  open={openAdicionarFilho}
                  onClose={handleClickOpenAdicionarFilho}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: isMobile ? "90%" : 686,
                      height: 580,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("adicionarFilho")}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                        zIndex: 2000,
                      }}
                      onClick={handleCloseAdicionarFilho}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}

                    <div className="formPop" style={{ marginTop: 0 }}>
                      <Scrollbar style={{ height: 350 }}>
                        <Box className="formTextField">
                          <>
                            {/* Nome */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                variant="standard"
                                label={t("nome")}
                                value={nome}
                                error={erroNome}
                                onChange={(e) => {
                                  setNome(e.target.value);
                                  setErroNome(false);
                                }}
                              />
                            </FormControl>

                            {/* Morada */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                variant="standard"
                                label={t("morada")}
                                value={morada}
                                error={erroMorada}
                                onChange={(e) => {
                                  setMorada(e.target.value);
                                  setErroMorada(false);
                                }}
                              />
                            </FormControl>

                            {/* NIF */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="number"
                                variant="standard"
                                label={t("nif")}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 9);
                                }}
                                value={nif}
                                error={erroNif}
                                helperText={mensagemErroNif}
                                onChange={(e) => {
                                  setNif(e.target.value);
                                  setErroNif(false);
                                }}
                              />
                            </FormControl>
                            {/* Código Postal */}
                            <FormControl className="formTextField3" fullWidth>
                              <PatternFormat
                                type="text"
                                variant="standard"
                                {...{
                                  label: t("codigoPostal"),
                                  variant: "standard",
                                }}
                                customInput={TextField}
                                required
                                mask="_"
                                value={codigoPostal}
                                format="####-###"
                                error={erroCodigoPostal}
                                helperText={mensagemErroCodigoPostal}
                                onChange={(e) => {
                                  setCodigoPostal(e.target.value);
                                  setErroCodigoPostal(false);
                                  setMensagemErroCodigoPostal("");
                                }}
                              />
                            </FormControl>
                            {/* Data de nascimento */}
                            <FormControl className="formTextField3" fullWidth>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={i18n.language}
                              >
                                <DatePicker
                                  disableFuture
                                  label={t("dataNascimento")}
                                  openTo="day"
                                  value={dataNascimento}
                                  views={["day", "year", "month"]}
                                  minDate={dataMinima}
                                  minDateMessage={t("menor16")}
                                  onChange={(e) => {
                                    setDataNascimento(e);
                                    setErroDataNascimento(false);
                                    setMensagemErroDataNascimento("");
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="standard"
                                      {...params}
                                      required
                                      error={erroDataNascimento}
                                      helperText={mensagemErroDataNascimento}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            {/* Genero */}
                            <FormControl className="formTextField2" fullWidth>
                              <FormLabel id="demo-radio-buttons-group-label">
                                {t("genero")}
                              </FormLabel>

                              <RadioGroup
                                row
                                defaultValue="O"
                                label={t("genero")}
                                name="genero"
                                value={genero}
                                onChange={(e) => {
                                  setGenero(e.target.value);
                                  seterroGenero(false);
                                }}
                              >
                                <FormControlLabel
                                  value="feminino"
                                  control={<Radio size="small" />}
                                  label={t("F")}
                                />
                                <FormControlLabel
                                  value="masculino"
                                  control={<Radio size="small" />}
                                  label={t("M")}
                                />
                                <FormControlLabel
                                  value="outro"
                                  control={<Radio size="small" />}
                                  label={t("O")}
                                />
                              </RadioGroup>
                            </FormControl>
                            {/* Foto */}
                            <FormControl className="formTextField3" fullWidth>
                              <p
                                id="tituloFotoPiscina"
                                style={{
                                  marginTop: 10,
                                  color: Colors.textFields,
                                  fontSize: 15,
                                  fontWeight: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {t("foto")}
                              </p>
                              <label
                                id="fotoPiscina"
                                htmlFor="file-upload"
                                className="custom-file-upload"
                              >
                                <i className="fa fa-cloud-upload"></i>
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  style={{ paddingTop: 4, paddingRight: 4 }}
                                />{" "}
                                {t("carregarImagem")}
                              </label>
                              <input
                                id="file-upload"
                                style={{ paddingTop: 0 }}
                                type="file"
                                accept="image/*"
                                onChange={async (e) => {
                                  document.getElementById(
                                    "fotoPiscina"
                                  ).style.borderColor = Colors.ativo;
                                  document.getElementById(
                                    "tituloFotoPiscina"
                                  ).style.color = Colors.textFields;
                                  const file = e.target.files[0];

                                  const reader = new FileReader();

                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    setFoto(reader.result);
                                  };
                                }}
                              ></input>
                              {/* Imagem carregada */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {foto.length > 0 && (
                                  <img
                                    style={{
                                      marginTop: 10,
                                      objectFit: "cover",
                                    }}
                                    width={150}
                                    height={150}
                                    src={foto}
                                  />
                                )}
                              </div>
                            </FormControl>
                          </>
                        </Box>
                      </Scrollbar>
                    </div>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      className="btnEdicao"
                      variant="contained"
                      style={{
                        backgroundImage: isMobile
                          ? `url(${editarbtnpeq})`
                          : `url(${editarbtn})`,
                        width: isMobile ? 153 : 315,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        validacao1();
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ color: "#ffffff", marginRight: "7px" }}
                      />
                      {t("adicionar")}
                    </Button>
                  </DialogActions>
                  <div className="formPageSuspensao2">
                    <Alert
                      severity="info"
                      style={{
                        fontSize: "8px",
                        padding: "3px 4px",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {t("alteradadoscrianças")}
                    </Alert>
                  </div>
                </Dialog>

                {/* Editar User */}
                <Dialog
                  open={openEditarDados}
                  onClose={handleCloseEditarDados}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: isMobile ? "95%" : 686,
                      height: 580,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("editarDados")}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                      }}
                      onClick={handleCloseEditarDados}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}
                    {carregamentoDadosCompleto ? (
                      <div className="formPop">
                        <Scrollbar style={{ height: 500 }}>
                          <Box className="formTextField">
                            <div>
                              {/* Nome */}
                              <FormControl className="formTextField2" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("nome")}
                                  value={nome}
                                  error={erroNome}
                                  inputProps={{ maxLength: 200 }}
                                  onChange={(e) => {
                                    setNome(e.target.value);
                                    setErroNome(false);
                                  }}
                                />
                              </FormControl>
                              {/* NIF */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="number"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("nif")}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 9);
                                  }}
                                  inputProps={{ maxLength: 9 }}
                                  value={nif}
                                  error={erroNif}
                                  helperText={mensagemErroNif}
                                  onChange={(e) => {
                                    setNif(e.target.value);
                                    setErroNif(false);
                                  }}
                                />
                              </FormControl>
                              {/* Data de Nascimento */}
                              <FormControl className="formTextField3" fullWidth>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={i18n.language}
                                >
                                  <DatePicker
                                    disableFuture
                                    label={t("dataNascimento") + " *"}
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    value={dataNascimento}
                                    error={erroDataNascimento}
                                    maxDate={dataMinima}
                                    disabled={edicao}
                                    helperText={mensagemErroDataNascimento}
                                    onChange={(e) => {
                                      setDataNascimento(e);
                                      setErroDataNascimento(false);
                                      setMensagemErroDataNascimento("");
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        variant="standard"
                                        {...params}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              {/* Genero */}
                              <FormControl className="formTextField2" fullWidth>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  {t("genero")}
                                </FormLabel>

                                <RadioGroup
                                  row
                                  defaultValue={genero}
                                  label={t("genero")}
                                  name="genero"
                                  disabled={edicao}
                                  value={genero}
                                  onChange={(e) => {
                                    setGenero(e.target.value);
                                    seterroGenero(false);
                                  }}
                                >
                                  <FormControlLabel
                                    value="feminino"
                                    disabled={edicao}
                                    control={<Radio size="small" />}
                                    label={t("F")}
                                  />
                                  <FormControlLabel
                                    value="masculino"
                                    disabled={edicao}
                                    control={<Radio size="small" />}
                                    label={t("M")}
                                  />
                                  <FormControlLabel
                                    value="outro"
                                    disabled={edicao}
                                    control={<Radio size="small" />}
                                    label={t("O")}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {/* Email */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("email")}
                                  value={email}
                                  error={erroEmail}
                                  helperText={mensagemErroEmail}
                                  inputProps={{ maxLength: 100 }}
                                  onChange={(e) => {
                                    setEmail(
                                      e.target.value.toLocaleLowerCase()
                                    );
                                    setErroEmail(false);
                                    setMensagemErroEmail("");
                                  }}
                                />
                              </FormControl>
                              {/* Telefone */}
                              <FormControl className="formTextField3" fullWidth>
                                <PhoneInput
                                  disabled={edicao}
                                  defaultCountry="PT"
                                  labels={
                                    i18n.language == "pt"
                                      ? pt
                                      : i18n.language == "es"
                                      ? es
                                      : en
                                  }
                                  value={telefone}
                                  error={erroTelefone}
                                  helperText={mensagemErroTelefone}
                                  inputProps={{ maxLength: 20 }}
                                  onChange={(e) => {
                                    setTelefone(e);
                                    setErroTelefone(false);
                                    setMensagemErroTelefone("");
                                  }}
                                  inputComponent={PhoneNumber}
                                />
                              </FormControl>
                              {/* Rua */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("morada")}
                                  value={morada}
                                  error={erroMorada}
                                  inputProps={{ maxLength: 200 }}
                                  onChange={(e) => {
                                    setMorada(e.target.value);
                                    setErroMorada(false);
                                  }}
                                />
                              </FormControl>
                              {/* Código Postal */}
                              <FormControl className="formTextField3" fullWidth>
                                <PatternFormat
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  {...{
                                    label: t("codigoPostal"),
                                    variant: "standard",
                                  }}
                                  value={codigoPostal}
                                  customInput={TextField}
                                  required
                                  format="####-###"
                                  error={erroCodigoPostal}
                                  helperText={mensagemErroCodigoPostal}
                                  onChange={(e) => {
                                    setCodigoPostal(e.target.value);
                                    setErroCodigoPostal(false);
                                    setMensagemErroCodigoPostal("");
                                  }}
                                />
                              </FormControl>
                              {/* Foto */}
                              <FormControl className="formTextField3" fullWidth>
                                <p
                                  id="tituloFotoPiscina"
                                  style={{
                                    marginTop: 10,
                                    color: Colors.textFields,
                                    fontSize: 15,
                                    fontWeight: "normal",
                                    textAlign: "left",
                                  }}
                                >
                                  {t("foto")}
                                </p>
                                <label
                                  id="fotoPiscina"
                                  htmlFor="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="fa fa-cloud-upload"></i>
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    style={{ paddingTop: 4, paddingRight: 4 }}
                                  />{" "}
                                  {t("carregarImagem")}
                                </label>
                                <input
                                  disabled={edicao}
                                  id="file-upload"
                                  style={{ paddingTop: 0 }}
                                  type="file"
                                  accept="image/*"
                                  onChange={async (e) => {
                                    document.getElementById(
                                      "fotoPiscina"
                                    ).style.borderColor = Colors.ativo;
                                    document.getElementById(
                                      "tituloFotoPiscina"
                                    ).style.color = Colors.textFields;
                                    const file = e.target.files[0];

                                    const reader = new FileReader();

                                    reader.readAsDataURL(file);

                                    reader.onload = () => {
                                      setFotoEditar(reader.result);
                                    };
                                  }}
                                ></input>
                                {/* Imagem carregada */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {fotoEditar.length > 0 && (
                                    <img
                                      style={{
                                        marginTop: 10,
                                        objectFit: "cover",
                                      }}
                                      width={150}
                                      height={150}
                                      src={fotoEditar}
                                    />
                                  )}
                                </div>
                              </FormControl>
                            </div>
                          </Box>
                        </Scrollbar>

                        {/* Cancelar edição */}
                        <Dialog
                          open={cancelarEdicao}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          PaperProps={{
                            style: {
                              width: 452,
                              height: 186,
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <DialogTitle
                            id="alert-dialog-title"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {t("cancelarEdicao")}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {t("textoCancelarEdicao")}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Button
                              className="btnSim"
                              variant="contained"
                              style={{
                                backgroundImage: `url(${simbtn})`,
                                width: 218,
                                height: 40,
                                cursor: "pointer",
                                zIndex: 200,
                              }}
                              onClick={() => {
                                cancelar();
                              }}
                            >
                              {t("sim")}
                            </Button>
                            <Button
                              className="btnSim"
                              variant="contained"
                              style={{
                                backgroundImage: `url(${naobtn})`,
                                width: 198,
                                height: 30,
                                cursor: "pointer",
                                zIndex: 200,
                              }}
                              onClick={() => {
                                handleClosePopupCancelar();
                              }}
                            >
                              {t("nao")}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <CircularProgress disableShrink />
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {edicao ? (
                      <Button
                        className="btnEdicao"
                        variant="contained"
                        style={{
                          backgroundImage: isMobile
                            ? `url(${editarbtnpeq})`
                            : `url(${editarbtn})`,
                          width: isMobile ? 153 : 315,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ color: "#ffffff", marginRight: "7px" }}
                        />

                        {t("editar")}
                      </Button>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="btnGuardar"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${guardarbtnPeq})`
                              : `url(${guardarbtn})`,

                            height: 40,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            validacao();
                          }}
                        >
                          {t("guardar")}
                        </Button>
                        <Button
                          className="btnGuardar"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${cancelarbtnPeq})`
                              : `url(${cancelarbtn})`,

                            height: 40,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            handleClickOpenPopupCancelar();
                          }}
                        >
                          {t("cancelar")}
                        </Button>
                      </div>
                    )}
                  </DialogActions>
                </Dialog>
                {/* Eliminar */}
                <Dialog
                  open={eliminar}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("eliminarFilho")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("textoEliminarFilho")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        eliminarFilho();
                      }}
                      autoFocus
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={handleClosePopupEliminar}
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Editar Filhos */}
                <Dialog
                  open={openEditarDadosFilhos}
                  onClose={handleCloseEditarDadosFilhos}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: isMobile ? "95%" : 686,
                      height: 580,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("editarDados")}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                      }}
                      onClick={handleCloseEditarDadosFilhos}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                  </DialogTitle>
                  <DialogContent>
                    {freeze && (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <Dialog
                          open={true}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <DialogContent>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              {t("aguarde")}
                            </Typography>
                            <CircularProgress
                              disableShrink
                              style={{ marginTop: 20 }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    )}
                    {carregamentoDadosCompleto ? (
                      <div className="formPop">
                        <Scrollbar style={{ height: 500 }}>
                          <Box className="formTextField">
                            <div>
                              {/* Nome */}
                              <FormControl className="formTextField2" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("nome")}
                                  value={nome}
                                  error={erroNome}
                                  inputProps={{ maxLength: 200 }}
                                  onChange={(e) => {
                                    setNome(e.target.value);
                                    setErroNome(false);
                                  }}
                                />
                              </FormControl>
                              {/* NIF */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="number"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("nif")}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 9);
                                  }}
                                  inputProps={{ maxLength: 9 }}
                                  value={nif}
                                  error={erroNif}
                                  helperText={mensagemErroNif}
                                  onChange={(e) => {
                                    setNif(e.target.value);
                                    setErroNif(false);
                                  }}
                                />
                              </FormControl>
                              {/* Data de Nascimento */}
                              <FormControl className="formTextField3" fullWidth>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={i18n.language}
                                >
                                  <DatePicker
                                    disableFuture
                                    label={t("dataNascimento") + " *"}
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    value={dataNascimento}
                                    error={erroDataNascimento}
                                    minDate={dataMinima}
                                    minDateMessage={t("menor16")}
                                    disabled={edicao}
                                    helperText={mensagemErroDataNascimento}
                                    onChange={(e) => {
                                      setDataNascimento(e);
                                      setErroDataNascimento(false);
                                      setMensagemErroDataNascimento("");
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        variant="standard"
                                        {...params}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              {/* Genero */}
                              <FormControl className="formTextField3" fullWidth>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  {t("genero")}
                                </FormLabel>

                                <RadioGroup
                                  row
                                  label={t("genero")}
                                  defaultValue={genero}
                                  name="genero"
                                  disabled={edicao}
                                  value={genero}
                                  onChange={(e) => {
                                    setGenero(e.target.value);
                                    seterroGenero(false);
                                  }}
                                >
                                  <FormControlLabel
                                    value="feminino"
                                    disabled={edicao}
                                    control={<Radio size="small" />}
                                    label={t("F")}
                                  />
                                  <FormControlLabel
                                    value="masculino"
                                    disabled={edicao}
                                    control={<Radio size="small" />}
                                    label={t("M")}
                                  />
                                  <FormControlLabel
                                    value="outro"
                                    disabled={edicao}
                                    control={<Radio size="small" />}
                                    label={t("O")}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {/* Rua */}
                              <FormControl className="formTextField3" fullWidth>
                                <TextField
                                  required
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  label={t("morada")}
                                  value={morada}
                                  error={erroMorada}
                                  inputProps={{ maxLength: 200 }}
                                  onChange={(e) => {
                                    setMorada(e.target.value);
                                    setErroMorada(false);
                                  }}
                                />
                              </FormControl>
                              {/* Código Postal */}
                              <FormControl className="formTextField3" fullWidth>
                                <PatternFormat
                                  type="text"
                                  disabled={edicao}
                                  variant="standard"
                                  {...{
                                    label: t("codigoPostal"),
                                    variant: "standard",
                                  }}
                                  value={codigoPostal}
                                  customInput={TextField}
                                  required
                                  format="####-###"
                                  error={erroCodigoPostal}
                                  helperText={mensagemErroCodigoPostal}
                                  onChange={(e) => {
                                    setCodigoPostal(e.target.value);
                                    setErroCodigoPostal(false);
                                    setMensagemErroCodigoPostal("");
                                  }}
                                />
                              </FormControl>
                              {/* Foto */}
                              <FormControl className="formTextField3" fullWidth>
                                <p
                                  id="tituloFotoPiscina"
                                  style={{
                                    marginTop: 10,
                                    color: Colors.textFields,
                                    fontSize: 15,
                                    fontWeight: "normal",
                                    textAlign: "left",
                                  }}
                                >
                                  {t("foto")}
                                </p>
                                <label
                                  id="fotoPiscina"
                                  htmlFor="file-upload"
                                  className="custom-file-upload"
                                >
                                  <i className="fa fa-cloud-upload"></i>
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    style={{ paddingTop: 4, paddingRight: 4 }}
                                  />{" "}
                                  {t("carregarImagem")}
                                </label>
                                <input
                                  disabled={edicao}
                                  id="file-upload"
                                  style={{ paddingTop: 0 }}
                                  type="file"
                                  accept="image/*"
                                  onChange={async (e) => {
                                    document.getElementById(
                                      "fotoPiscina"
                                    ).style.borderColor = Colors.ativo;
                                    document.getElementById(
                                      "tituloFotoPiscina"
                                    ).style.color = Colors.textFields;
                                    const file = e.target.files[0];

                                    const reader = new FileReader();

                                    reader.readAsDataURL(file);

                                    reader.onload = () => {
                                      setFotoEditar(reader.result);
                                    };
                                  }}
                                ></input>
                                {/* Imagem carregada */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {fotoEditar.length > 0 && (
                                    <img
                                      style={{
                                        marginTop: 10,
                                        objectFit: "cover",
                                      }}
                                      width={150}
                                      height={150}
                                      src={fotoEditar}
                                    />
                                  )}
                                </div>
                              </FormControl>
                            </div>
                          </Box>
                        </Scrollbar>

                        {/* Cancelar edição */}
                        <Dialog
                          open={cancelarEdicao}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          PaperProps={{
                            style: {
                              width: 452,
                              height: 186,
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <DialogTitle
                            id="alert-dialog-title"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {t("cancelarEdicao")}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {t("textoCancelarEdicao")}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Button
                              className="btnSim"
                              variant="contained"
                              style={{
                                backgroundImage: `url(${simbtn})`,
                                width: 218,
                                height: 40,
                                cursor: "pointer",
                                zIndex: 200,
                              }}
                              onClick={() => {
                                cancelarFilho();
                              }}
                            >
                              {t("sim")}
                            </Button>
                            <Button
                              className="btnSim"
                              variant="contained"
                              style={{
                                backgroundImage: `url(${naobtn})`,
                                width: 198,
                                height: 30,
                                cursor: "pointer",
                                zIndex: 200,
                              }}
                              onClick={() => {
                                handleClosePopupCancelar();
                              }}
                            >
                              {t("nao")}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "transparent",
                          position: "absolute",
                          width: window.innerWidth,
                          height: window.innerHeight,
                          zIndex: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: 0,
                        }}
                      >
                        <CircularProgress disableShrink />
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {edicao ? (
                      <div>
                        <Button
                          className="btnEdicao"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${editarbtnpeq})`
                              : `url(${editarbtn})`,
                            width: isMobile ? 153 : 315,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                            marginRight: "1px !important",
                            marginLeft: "1px !important",
                          }}
                          onClick={() => {
                            scrollToDiv();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{ color: "#ffffff", marginRight: "7px" }}
                          />

                          {t("editar")}
                        </Button>
                        <Button
                          className="btnEdicao"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${eliminarbtnpeq})`
                              : `url(${eliminarbtn})`,
                            width: isMobile ? 153 : 315,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                            marginRight: "1px !important",
                            marginLeft: "1px !important",
                          }}
                          onClick={() => {
                            setEliminar(true);
                          }}
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ color: "#ffffff", marginRight: "7px" }}
                          />
                          {t("eliminar")}
                        </Button>
                        <Alert
                          severity="info"
                          style={{
                            fontSize: "8px",
                            padding: "3px 4px",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "8px",
                            width: "40%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            justifyContent: "center",
                          }}
                        >
                          {t("alteradadoscrianças")}
                        </Alert>
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="btnGuardar"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${guardarbtnPeq})`
                              : `url(${guardarbtn})`,

                            height: 40,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            validacao2();
                          }}
                        >
                          {t("guardar")}
                        </Button>
                        <Button
                          className="btnGuardar"
                          variant="contained"
                          style={{
                            backgroundImage: isMobile
                              ? `url(${cancelarbtnPeq})`
                              : `url(${cancelarbtn})`,

                            height: 40,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            handleClickOpenPopupCancelar();
                          }}
                        >
                          {t("cancelar")}
                        </Button>
                      </div>
                    )}
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default MinhaFamilia;
