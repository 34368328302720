import Cookies from "js-cookie";
import {
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestPost2,
  makeRequestPut2,
} from "../api";

//Get Clientes
export function getCLientes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("cliente/all", dados, token);
}

//Get Clientes app
export function getCLientesApp(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("cliente/all_app", dados, token);
}

//Post Cliente
export function postCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("cliente", dados, token);
}

//Enviar email -Post Cliente
export function enviarEmailPostCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("cliente/active-account", dados, token);
}

//Get por id
export function getCliente(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`cliente/id/${id}`, {}, token);
}

//Editar
export function putCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`cliente/`, dados, token);
}

//Alterar status
export function putStatusCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`cliente/status`, dados, token);
}

//Eliminar
export function deleteCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2(`cliente/`, dados, token);
}

//Lista de clientes da piscina
export function listaClientesPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestGet2(`piscinaagua/clientes`, dados, token);
}
