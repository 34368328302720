import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { adicionarDescontos } from "../../api/requests/Descontos";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaAcoes, listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";

function CriarDescontoA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  const [id_acao, setIdAcao] = useState("");
  const [id_piscina, setIdPiscina] = useState("");
  const [id_ee, setIdEE] = useState("");
  const [desconto, setDesconto] = useState("");
  const [descricao, setDescricao] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [categorias, setCategoria] = useState([]);
  const [acao, setAcao] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAcao, setSelectedAcao] = useState("");
  const [numFilhos, setNumFilhos] = useState(0);

  const [erroIdAcao, setErroIdAcao] = useState(false);
  const [erroDesconto, setErroDesconto] = useState(false);
  const [erroDescricao, setErroDescricao] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroAcao, setErroAcao] = useState(false);
  const [erroNumFilhos, setErroNumFilhos] = useState(false);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleAcaoChange = (event) => {
    setSelectedAcao(event.target.value);
    setErroAcao(false);
  };
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaAcoes({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];

          res.data.data.forEach((element) => {
            const linha = {
              id: element.id,
              acao: element.nome,
            };
            arrayFinal.push(linha);
            setAcao(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function criarDescontos() {
    setFreeze(true);
    adicionarDescontos({
      id_acao: selectedAcao,
      id_piscina: id_piscina,
      id_entidade_exploradora: id_ee,
      desconto: desconto,
      filhos_conta_familiar: numFilhos,
      categoria_utilizador: selectedCategory,
      data_inicio: data_inicio.toISOString().slice(0, 10),
      data_fim: data_fim.toISOString().slice(0, 10),
      descricao: descricao,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false)
        toast.success(t("descontoAdicionar"));
        navigate("/discountlista");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      desconto &&
      data_inicio &&
      descricao &&
      selectedCategory &&
      selectedAcao &&
      numFilhos
    ) {
      let confirmarDadosPreenchidos = true;
      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarDescontos();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!desconto) {
        setErroDesconto(true);
      }

      if (!data_inicio) {
        setErroDataInicio(true);
      }
      if (!descricao) {
        setErroDescricao(true);
      }
      if (!id_acao) {
        setErroIdAcao(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }

      if (!selectedCategory) {
        setErroCategoria(true);
      }

      if (!selectedAcao) {
        setErroAcao(true);
      }
      if (!numFilhos) {
        setErroNumFilhos(true);
      }
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/discountlista");
              }}
              size={isMobile ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          {/* <div className="align-right">
            <h3 className="titleList">{t("adicionarDesconto")}</h3>
          </div> */}
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("desconto") + " (%)"}
                    value={desconto}
                    error={erroDesconto}
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      // Verifica se o valor é maior que 100
                      if (inputValue > 100) {
                        inputValue = 100; // Define o valor como 100
                      }
                      setDesconto(inputValue);
                      setErroDesconto(false);
                    }}
                  />
                </FormControl>

                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    style={{ textAlign: "left" }}
                    variant="outlined"
                    multiline
                    rows={2}
                    value={descricao}
                    label={t("observacoes")}
                    error={erroDescricao}
                    onChange={(e) => {
                      setDescricao(e.target.value);
                      setErroDescricao(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("numfilhos")}
                    value={numFilhos}
                    error={erroNumFilhos}
                    onChange={(e) => {
                      const value = e.target.value;

                      setNumFilhos(value);
                      setErroNumFilhos(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroCategoria}>
                    {t("escolhaacao")}*
                  </InputLabel>
                  <Select
                    value={selectedAcao}
                    onChange={handleAcaoChange}
                    error={erroAcao}
                    fullWidth
                    variant="standard"
                  >
                    {acao.map((acao) => (
                      <MenuItem key={acao.id} value={acao.id}>
                        {acao.acao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroCategoria}>
                    {t("escolhacategoria")}*
                  </InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    error={erroCategoria}
                    fullWidth
                    variant="standard"
                  >
                    {categorias.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.categoria}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("datainicio")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={data_inicio}
                      onChange={(e) => {
                        if (e) {
                          setDataInicio(e);
                          setErroDataInicio(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataInicio}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("datafim")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={data_fim}
                      onChange={(e) => {
                        if (e) {
                          setDataFim(e);
                          setErroDataFim(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataFim}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>
            </Box>
          </div>
        </div>

        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarDescontoA;
