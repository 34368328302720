import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, IconButton, Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCircleCheck,
  faCircleXmark,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { getCLientes } from "../../api/requests/Clientes";
import Search2 from "../../components/DataTable/Search2";

function ListaClientes() {
  const [clientes, setClientes] = useState([]);
  const [clientesCopy, setClientesCopy] = useState([]);
  const [search, setSearch] = useState("");

  const { state } = useLocation();
  const { idPiscina, nomePiscina } = state || {};

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      let arrayClientes = clientesCopy;
      arrayClientes = arrayClientes.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setClientes(arrayClientes);
    } else {
      setClientes(clientesCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getCLientes({ id: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
              email: element.email,
              status: element.status == 1 ? t("ativo") : t("inativo"),
            };
            arrayFinal.push(linha);
            setClientes(arrayFinal);
            setClientesCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  return (
    <>
      <div className="listPages4">
        <Header />
        <div className="divTabela">
          <div className="align-left2">
            {idPiscina ? (
              <IconButton
                className="arrowBack"
                variant="text"
                onClick={() => {
                  navigate("/editpool", {
                    state: { id: idPiscina },
                  });
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </IconButton>
            ) : null}
          </div>
          {/* <h3 className="titleList">{t("listaClientes")}</h3> */}
          {idPiscina ? (
            <div className="col-0" id="divCaminhos">
              <div className="caminhoPaginas">
                <a className="caminhoPaginas_a" href="/pools">
                  {t("piscinas")}
                </a>
                <a className="caminhoPaginas_a2">{`>`}</a>
                <a
                  className="caminhoPaginas_a"
                  onClick={() => {
                    navigate("/editpool", {
                      state: { id: idPiscina },
                    });
                  }}
                >
                  {nomePiscina.substring(0, 25)}...
                </a>
              </div>

              <Button
                className="addButton"
                variant="contained"
                onClick={() => {
                  navigate("/createcustomer", {
                    state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                  });
                }}
              >
                {t("adicionar")}
              </Button>
            </div>
          ) : (
            <div className="col-0" style={{ textAlign: "end", marginTop: 20 }}>
              <Button
                className="addButton"
                variant="contained"
                onClick={() => {
                  navigate("/createcustomer", {
                    state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                  });
                }}
              >
                {t("adicionar")}
              </Button>
            </div>
          )}
          <Search2
            onSearch={(value) => {
              setSearch(value);
            }}
          />
          <DataGrid
            className="tabela"
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 100,
                flex: 1,
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              {
                field: "email",
                headerName: t("email"),
                flex: 1,
                type: "string",
              },
              {
                field: "status",
                headerName: t("estado"),
                maxWidth: 100,
                flex: 1,
                type: "string",

                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == t("ativo") ? (
                        <>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: 500,
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                          >
                            {t("ativo")}
                          </p>
                          <FontAwesomeIcon
                            color={Colors.ativo}
                            fontSize={25}
                            icon={faCircleCheck}
                          />
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: 500,
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                          >
                            {t("inativo")}
                          </p>{" "}
                          <FontAwesomeIcon
                            color={Colors.inativo}
                            fontSize={25}
                            icon={faCircleXmark}
                          />
                        </>
                      )}
                    </div>
                  );
                },
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        navigate("/editcustomer", {
                          state: {
                            idPiscina: idPiscina,
                            nomePiscina: nomePiscina,
                            idCliente: id,
                          },
                        });
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={clientes}
            checkboxSelection={true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: GridToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
        </div>
        {Cookies.get("utilizador") == "superadmin" ||
        Cookies.get("utilizador") == "supercomercial" ||
        Cookies.get("utilizador") == "comercial" ? null : (
          <Footer />
        )}
      </div>
    </>
  );
}

export default ListaClientes;
