import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { IconContext } from "react-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faRightFromBracket,
  faBell,
  faHome,
  faUser,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logosemfundoc.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { logout } from "../api/requests/AuthServer";

function HeaderExterno() {
  //Navigator
  const navigate = useNavigate();

  //Traduções
  const { t, i18n } = useTranslation();

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className="navbar">
          <div className="headerLeft" style={{ float: "left" }}>
            <li className="navbar-toggle" style={{ height: 0 }}>
              <img
                src={logo}
                style={{
                  width: "40px",
                  height: "4x0x",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                }}
              />
            </li>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default HeaderExterno;
