import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  enviarEmailPostPiscina,
  postPiscinas,
  postPiscinasIdEntidadeLegal,
  verificarNifEntidade,
} from "../../api/requests/Piscinas";
import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  enviarEmailPostFuncionario,
  postFuncionario,
} from "../../api/requests/Funcionarios";

function CriarFuncionarioA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idPiscina, nomePiscina } = state || {};

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [emailFuncionario, setEmailFuncionario] = useState("");
  const [telefoneFuncionario, setTelefoneFuncionario] = useState("");

  //Validações
  // Responsável
  const [erroNomeFuncionario, setErroNomeFuncionario] = useState("");
  const [erroEmailFuncionario, setErroEmailFuncionario] = useState("");
  const [erroTelefoneFuncionario, setErroTelefoneFuncionario] = useState("");

  //Privilégios
  const [editarPiscinaEdificio, setEditarPiscinaEdificio] = useState(false);
  const [editarPiscinaAgua, setEditarPiscinaAgua] = useState(false);
  const [adicionarPiscinaAgua, setAdicionarPiscinaAgua] = useState(false);
  const [eliminarPiscinaAgua, setEliminarPiscinaAgua] = useState(false);
  const [adicionarProfessor, setAdicionarProfessor] = useState(false);
  const [editarProfessor, setEditarProfessor] = useState(false);
  const [eliminarProfessor, setEliminarProfessor] = useState(false);
  const [ativarDesativarModalidades, setAtivarDesativarModalidades] =
    useState(false);
  const [adicionarDesconto, setAdicionarDesconto] = useState(false);
  const [editarDesconto, setEditarDesconto] = useState(false);
  const [eliminarDesconto, setEliminarDesconto] = useState(false);
  const [adicionarSeguroInscricao, setAdicionarSeguroInscricao] =
    useState(false);
  const [editarSeguroInscricao, setEditarSeguroInscricao] = useState(false);
  const [eliminarSeguroInscricao, setEliminarSeguroInscricao] = useState(false);
  const [pedidos, setPedidos] = useState(false);

  // Responsável
  const [mensagemErroEmailFuncionario, setMensagemErroEmailFuncionario] =
    useState("");
  const [mensagemErroTelefoneFuncionario, setMensagemErroTelefoneFuncionario] =
    useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  function criarFuncionario() {
    setFreeze(true);
    postFuncionario({
      id: localStorage.getItem("idPiscina"),
      nome: nomeFuncionario,
      contacto: telefoneFuncionario,
      email: emailFuncionario,
      editar_dados_piscina_edificio: editarPiscinaEdificio,
      editar_dados_piscina_agua: editarPiscinaAgua,
      adicionar_piscina_agua: adicionarPiscinaAgua,
      eliminar_piscina_agua: eliminarPiscinaAgua,
      adicionar_professor: adicionarProfessor,
      editar_professor: editarProfessor,
      eliminar_professor: eliminarProfessor,
      ativar_desativar_modalidades: ativarDesativarModalidades,
      adicionar_desconto: adicionarDesconto,
      editar_descontos: editarDesconto,
      eliminar_descontos: eliminarDesconto,
      adicionar_si: adicionarSeguroInscricao,
      editar_si: editarSeguroInscricao,
      eliminar_si: eliminarSeguroInscricao,
      pedidos: pedidos,
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          await enviarEmailPostFuncionario({
            id: res.data.id,
          }).then((res) => {
            if (res.success) {
              navigate("/employeesa", {
                state: {
                  idPiscina: localStorage.getItem("idPiscina"),
                  nomePiscina: nomePiscina,
                },
              });
              setFreeze(false)
              toast.success(res.data.msg);
            } else {
              navigate("/employeesa", {
                state: {
                  idPiscina: localStorage.getItem("idPiscina"),
                  nomePiscina: nomePiscina,
                },
              });
              setFreeze(false)
              toast.error(res.error);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (nomeFuncionario && emailFuncionario && telefoneFuncionario) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailFuncionario
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailFuncionario(true);
        setMensagemErroEmailFuncionario(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneFuncionario)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneFuncionario(true);
        setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
      }
      if (emailFuncionario) {
        verificarEmail({ email: emailFuncionario }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroEmailFuncionario(true);
              setMensagemErroEmailFuncionario(res.data.error);
            }
          }
        });
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarFuncionario();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeFuncionario) {
        setErroNomeFuncionario(true);
      }
      //Validação do email
      if (!emailFuncionario) {
        setErroEmailFuncionario(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailFuncionario
          );
        if (!validacaoEmail) {
          setErroEmailFuncionario(true);
          setMensagemErroEmailFuncionario(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailFuncionario }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                setErroEmailFuncionario(true);
                setMensagemErroEmailFuncionario(res.data.error);
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneFuncionario) {
        setErroTelefoneFuncionario(true);
      } else {
        if (!isValidPhoneNumber(telefoneFuncionario)) {
          setErroTelefoneFuncionario(true);
          setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
        }
      }
    }
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="align-left">
            <IconButton
              className="arrowBack"
              variant="text"
              onClick={() => {
                navigate("/employeesa", {
                  state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </div>

          {/* <div className="align-right">
            <h3 className="titleList">{t("adicionarFuncionario")}</h3>
          </div> */}
        </div>

        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nomeFuncionario}
                    error={erroNomeFuncionario}
                    onChange={(e) => {
                      setNomeFuncionario(e.target.value);
                      setErroNomeFuncionario(false);
                    }}
                  />
                </FormControl>
                {/* Email */}
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("email")}
                    value={emailFuncionario}
                    error={erroEmailFuncionario}
                    helperText={mensagemErroEmailFuncionario}
                    onChange={(e) => {
                      setEmailFuncionario(e.target.value.toLocaleLowerCase());
                      setErroEmailFuncionario(false);
                      setMensagemErroEmailFuncionario("");
                    }}
                  />
                </FormControl>
                {/* Telefone */}
                <FormControl className="formTextField3" fullWidth>
                  <PhoneInput
                    defaultCountry="PT"
                    labels={
                      i18n.language == "pt"
                        ? pt
                        : i18n.language == "es"
                        ? es
                        : en
                    }
                    value={telefoneFuncionario}
                    error={erroTelefoneFuncionario}
                    helperText={mensagemErroTelefoneFuncionario}
                    onChange={(e) => {
                      setTelefoneFuncionario(e);
                      setErroTelefoneFuncionario(false);
                      setMensagemErroTelefoneFuncionario("");
                    }}
                    inputComponent={PhoneNumber}
                  />
                </FormControl>
              </div>
            </Box>
          </div>
          <div className="formPage2">
            <Box className="formTextField">
              {/* Privilégios */}
              <h5 className="h5List">{t("privilegios")}</h5>
              <div>
                <FormControl className="formTextField3" fullWidth>
                  {/* Editar dados piscina edifício */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("editarDadosPiscinaEdificio")}
                    labelPlacement="start"
                    checked={editarPiscinaEdificio}
                    onChange={() => {
                      setEditarPiscinaEdificio(!editarPiscinaEdificio);
                    }}
                  />
                  {/* Adicionar piscina água */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("adicionarPiscinaAgua2")}
                    labelPlacement="start"
                    checked={adicionarPiscinaAgua}
                    onChange={() => {
                      setAdicionarPiscinaAgua(!adicionarPiscinaAgua);
                    }}
                  />
                  {/* Editar piscina água */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("editarDadosPiscinaAgua")}
                    labelPlacement="start"
                    checked={editarPiscinaAgua}
                    onChange={() => {
                      setEditarPiscinaAgua(!editarPiscinaAgua);
                    }}
                  />
                  {/* Eliminar piscina água */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("eliminarPiscinaAgua2")}
                    labelPlacement="start"
                    checked={eliminarPiscinaAgua}
                    onChange={() => {
                      setEliminarPiscinaAgua(!eliminarPiscinaAgua);
                    }}
                  />
                  {/* Adicionar professor */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("adicionarProfessor2")}
                    labelPlacement="start"
                    checked={adicionarProfessor}
                    onChange={() => {
                      setAdicionarProfessor(!adicionarProfessor);
                    }}
                  />
                  {/* Editar professor */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("editarProfessor2")}
                    labelPlacement="start"
                    checked={editarProfessor}
                    onChange={() => {
                      setEditarProfessor(!editarProfessor);
                    }}
                  />
                  {/* Eliminar professor */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("eliminarProfessor2")}
                    labelPlacement="start"
                    checked={eliminarProfessor}
                    onChange={() => {
                      setEliminarProfessor(!eliminarProfessor);
                    }}
                  />
                  {/* Ativar / Desativar modalidades */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("ativarDesativarModalidades")}
                    labelPlacement="start"
                    checked={ativarDesativarModalidades}
                    onChange={() => {
                      setAtivarDesativarModalidades(
                        !ativarDesativarModalidades
                      );
                    }}
                  />
                  {/* Adicionar desconto */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("adicionarDesconto2")}
                    labelPlacement="start"
                    checked={adicionarDesconto}
                    onChange={() => {
                      setAdicionarDesconto(!adicionarDesconto);
                    }}
                  />
                  {/* Editar desconto */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("editarDesconto2")}
                    labelPlacement="start"
                    checked={editarDesconto}
                    onChange={() => {
                      setEditarDesconto(!editarDesconto);
                    }}
                  />
                  {/* Eliminar desconto */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("eliminarDesconto2")}
                    labelPlacement="start"
                    checked={eliminarDesconto}
                    onChange={() => {
                      setEliminarDesconto(!eliminarDesconto);
                    }}
                  />
                  {/* Adicionar seguro/inscrição */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("adicionarsi")}
                    labelPlacement="start"
                    checked={adicionarSeguroInscricao}
                    onChange={() => {
                      setAdicionarSeguroInscricao(!adicionarSeguroInscricao);
                    }}
                  />
                  {/* Editar seguro/inscrição */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("editarsi")}
                    labelPlacement="start"
                    checked={editarSeguroInscricao}
                    onChange={() => {
                      setEditarSeguroInscricao(!editarSeguroInscricao);
                    }}
                  />
                  {/* Eliminar seguro/inscrição */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("eliminarsi")}
                    labelPlacement="start"
                    checked={eliminarSeguroInscricao}
                    onChange={() => {
                      setEliminarSeguroInscricao(!eliminarSeguroInscricao);
                    }}
                  />
                  {/* Pedidos */}
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label={t("pedidos")}
                    labelPlacement="start"
                    checked={pedidos}
                    onChange={() => {
                      setPedidos(!pedidos);
                    }}
                  />
                </FormControl>
              </div>
            </Box>
          </div>
        </div>
        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarFuncionarioA;
