import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FooterExterno from "../../components/FooterExterno";
import HeaderExterno from "../../components/HeaderExterno";

import chorar from "../../images/errorPages/chorar.gif";

function ContaSuspensa() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  // Definir background para esta página
  document.body.style = `background: white`;

  return (
    <>
      <HeaderExterno />
      <div className="containerErrorPage">
        <div className="divErrorPage">
          <div className="divErrorLeft">
            <img className="imageErrorPage" src={chorar} />
          </div>
          <div className="divErrorSuspensa">
            <h1 >{t("contaexcluida")}</h1>
            <h4 >{t("contaexcluidatexto")}</h4>
            <div className="buttonsErroPage">
              <button
               type="button"
                onClick={() => {
                 
                    navigate("/");
                  
                }}
              >
                {t("paginaInicial")}
              </button>
              {/* <button>Learn More</button> */}
            </div>
          </div>
        </div>
      </div>
      <FooterExterno />
    </>
  );
}

export default ContaSuspensa;
