//Cores utilizadas em todo o projeto
export const Colors = {
  footer: "#0b1d42",
  footerIcons: "#fff",
  headerTable: "#FAFBFB",
  backgroundColorLists: "#f7f7f7",
  ativo: "#35d77a",
  inativo: "#DD5D5B",
  textFields: "#858585",
  card1: "#50c2fe",
  card1_2: "#96bf49",
  card2: "#FF9E40",
  card2_1: "#969696",
  card3: "#db5757",
  card4: "#ffcd56",
  card5: "#4bc0c0",
  card6: "#9966FF",
  card7: "#FF6283",
  cardInativo: "#3333",
  cardAtalho1: "#085f63",
  cardAtalho2: "#49beb7",
  cardAtalho3: "#facf5a",
  cardAtalho4: "#ff5959",
  cardAtalho5: "#a5a5a5",
  yellow: "#FFCD56",
  green: "#35d77a",
};
