var holidays=[
    {
        "title": "Feriado",
        "start": "2023-01-01T00:00:00",
        "end": "2023-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-04-07T00:00:00",
        "end": "2023-04-07T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-04-09T00:00:00",
        "end": "2023-04-09T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-04-25T00:00:00",
        "end": "2023-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-05-01T00:00:00",
        "end": "2023-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-06-08T00:00:00",
        "end": "2023-06-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-06-10T00:00:00",
        "end": "2023-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-08-15T00:00:00",
        "end": "2023-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-10-05T00:00:00",
        "end": "2023-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-11-01T00:00:00",
        "end": "2023-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-12-01T00:00:00",
        "end": "2023-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-12-08T00:00:00",
        "end": "2023-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2023-12-25T00:00:00",
        "end": "2023-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-01-01T00:00:00",
        "end": "2024-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-03-29T00:00:00",
        "end": "2024-03-29T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-03-31T00:00:00",
        "end": "2024-03-31T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-04-25T00:00:00",
        "end": "2024-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-05-01T00:00:00",
        "end": "2024-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-05-30T00:00:00",
        "end": "2024-05-30T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-06-10T00:00:00",
        "end": "2024-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-08-15T00:00:00",
        "end": "2024-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-10-05T00:00:00",
        "end": "2024-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-11-01T00:00:00",
        "end": "2024-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-12-01T00:00:00",
        "end": "2024-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-12-08T00:00:00",
        "end": "2024-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2024-12-25T00:00:00",
        "end": "2024-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-01-01T00:00:00",
        "end": "2025-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-04-18T00:00:00",
        "end": "2025-04-18T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-04-20T00:00:00",
        "end": "2025-04-20T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-04-25T00:00:00",
        "end": "2025-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-05-01T00:00:00",
        "end": "2025-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-06-10T00:00:00",
        "end": "2025-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-06-19T00:00:00",
        "end": "2025-06-19T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-08-15T00:00:00",
        "end": "2025-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-10-05T00:00:00",
        "end": "2025-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-11-01T00:00:00",
        "end": "2025-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-12-01T00:00:00",
        "end": "2025-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-12-08T00:00:00",
        "end": "2025-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2025-12-25T00:00:00",
        "end": "2025-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-01-01T00:00:00",
        "end": "2026-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-04-03T00:00:00",
        "end": "2026-04-03T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-04-05T00:00:00",
        "end": "2026-04-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-04-25T00:00:00",
        "end": "2026-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-05-01T00:00:00",
        "end": "2026-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-06-04T00:00:00",
        "end": "2026-06-04T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-06-10T00:00:00",
        "end": "2026-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-08-15T00:00:00",
        "end": "2026-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-10-05T00:00:00",
        "end": "2026-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-11-01T00:00:00",
        "end": "2026-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-12-01T00:00:00",
        "end": "2026-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-12-08T00:00:00",
        "end": "2026-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2026-12-25T00:00:00",
        "end": "2026-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-01-01T00:00:00",
        "end": "2027-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-03-26T00:00:00",
        "end": "2027-03-26T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-03-28T00:00:00",
        "end": "2027-03-28T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-04-25T00:00:00",
        "end": "2027-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-05-01T00:00:00",
        "end": "2027-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-05-27T00:00:00",
        "end": "2027-05-27T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-06-10T00:00:00",
        "end": "2027-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-08-15T00:00:00",
        "end": "2027-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-10-05T00:00:00",
        "end": "2027-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-11-01T00:00:00",
        "end": "2027-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-12-01T00:00:00",
        "end": "2027-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-12-08T00:00:00",
        "end": "2027-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2027-12-25T00:00:00",
        "end": "2027-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-01-01T00:00:00",
        "end": "2028-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-04-14T00:00:00",
        "end": "2028-04-14T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-04-16T00:00:00",
        "end": "2028-04-16T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-04-25T00:00:00",
        "end": "2028-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-05-01T00:00:00",
        "end": "2028-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-06-10T00:00:00",
        "end": "2028-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-06-15T00:00:00",
        "end": "2028-06-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-08-15T00:00:00",
        "end": "2028-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-10-05T00:00:00",
        "end": "2028-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-11-01T00:00:00",
        "end": "2028-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-12-01T00:00:00",
        "end": "2028-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-12-08T00:00:00",
        "end": "2028-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2028-12-25T00:00:00",
        "end": "2028-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-01-01T00:00:00",
        "end": "2029-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-03-30T00:00:00",
        "end": "2029-03-30T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-04-01T00:00:00",
        "end": "2029-04-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-04-25T00:00:00",
        "end": "2029-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-05-01T00:00:00",
        "end": "2029-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-05-31T00:00:00",
        "end": "2029-05-31T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-06-10T00:00:00",
        "end": "2029-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-08-15T00:00:00",
        "end": "2029-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-10-05T00:00:00",
        "end": "2029-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-11-01T00:00:00",
        "end": "2029-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-12-01T00:00:00",
        "end": "2029-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-12-08T00:00:00",
        "end": "2029-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2029-12-25T00:00:00",
        "end": "2029-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-01-01T00:00:00",
        "end": "2030-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-04-19T00:00:00",
        "end": "2030-04-19T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-04-21T00:00:00",
        "end": "2030-04-21T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-04-25T00:00:00",
        "end": "2030-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-05-01T00:00:00",
        "end": "2030-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-06-10T00:00:00",
        "end": "2030-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-06-20T00:00:00",
        "end": "2030-06-20T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-08-15T00:00:00",
        "end": "2030-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-10-05T00:00:00",
        "end": "2030-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-11-01T00:00:00",
        "end": "2030-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-12-01T00:00:00",
        "end": "2030-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-12-08T00:00:00",
        "end": "2030-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2030-12-25T00:00:00",
        "end": "2030-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-01-01T00:00:00",
        "end": "2031-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-04-11T00:00:00",
        "end": "2031-04-11T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-04-13T00:00:00",
        "end": "2031-04-13T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-04-25T00:00:00",
        "end": "2031-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-05-01T00:00:00",
        "end": "2031-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-06-10T00:00:00",
        "end": "2031-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-06-12T00:00:00",
        "end": "2031-06-12T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-08-15T00:00:00",
        "end": "2031-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-10-05T00:00:00",
        "end": "2031-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-11-01T00:00:00",
        "end": "2031-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-12-01T00:00:00",
        "end": "2031-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-12-08T00:00:00",
        "end": "2031-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2031-12-25T00:00:00",
        "end": "2031-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-01-01T00:00:00",
        "end": "2032-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-03-26T00:00:00",
        "end": "2032-03-26T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-03-28T00:00:00",
        "end": "2032-03-28T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-03-28T00:00:00",
        "end": "2032-03-28T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-04-25T00:00:00",
        "end": "2032-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-05-01T00:00:00",
        "end": "2032-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-05-27T00:00:00",
        "end": "2032-05-27T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-06-10T00:00:00",
        "end": "2032-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-08-15T00:00:00",
        "end": "2032-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-10-05T00:00:00",
        "end": "2032-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-11-01T00:00:00",
        "end": "2032-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-12-01T00:00:00",
        "end": "2032-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-12-08T00:00:00",
        "end": "2032-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2032-12-25T00:00:00",
        "end": "2032-12-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-01-01T00:00:00",
        "end": "2033-01-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-04-15T00:00:00",
        "end": "2033-04-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-04-17T00:00:00",
        "end": "2033-04-17T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-04-25T00:00:00",
        "end": "2033-04-25T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-05-01T00:00:00",
        "end": "2033-05-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-06-10T00:00:00",
        "end": "2033-06-10T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-06-16T00:00:00",
        "end": "2033-06-16T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-08-15T00:00:00",
        "end": "2033-08-15T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-10-05T00:00:00",
        "end": "2033-10-05T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-11-01T00:00:00",
        "end": "2033-11-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-12-01T00:00:00",
        "end": "2033-12-01T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-12-08T00:00:00",
        "end": "2033-12-08T00:00:00"
    },
    {
        "title": "Feriado",
        "start": "2033-12-25T00:00:00",
        "end": "2033-12-25T00:00:00"
    }
]
export default holidays;