import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  MenuItem,
  Switch,
} from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import { adicionarAula } from "../../api/requests/Aulas";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import {
  listaPiscinasAgua,
  listaModalidades,
  listaProfessores,
  getPistas,
} from "../../api/requests/PiscinaAgua";
import { listaTurmas } from "../../api/requests/Turmas";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { format } from "date-fns";
import moment from "moment";

function CriarAulaA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [hora_inicio, setHoraInicio] = useState("");
  const [hora_fim, setHoraFim] = useState("");
  const [pista, setPista] = useState(0);
  const [tamanho_pista, setTamanhoPista] = useState(0);
  const [segunda, setSegunda] = useState(0);
  const [terca, setTerca] = useState(0);
  const [quarta, setQuarta] = useState(0);
  const [quinta, setQuinta] = useState(0);
  const [sexta, setSexta] = useState(0);
  const [sabado, setSabado] = useState(0);
  const [domingo, setDomingo] = useState(0);
  const [status, setStatus] = useState(0);
  const [turmas, setTurmas] = useState([]);
  const [piscinas, setPiscinas] = useState([]);
  const [maxPista, setMaxPista] = useState(0);

  const [professores, setProfessores] = useState([]);

  const [selectedPool, setSelectedPool] = useState("");
  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [selectedTurma, setSelectedTurma] = useState("");

  const [erroDataInicio, setErroDataInicio] = useState("");
  const [erroDataFim, setErroDataFim] = useState("");
  const [erroHoraInicio, setErroHoraInicio] = useState("");
  const [erroHoraFim, setErroHoraFim] = useState("");
  const [erroPista, setErroPista] = useState("");
  const [erroTamanhoPista, setErroTamanhoPista] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [erroTurma, setErroTurma] = useState("");
  const [erroProfessor, setErroProfessor] = useState("");
  const [exibirCheckboxes, setExibirCheckboxes] = useState(true);
  const [pistasD, setPistasD] = useState([]);
  const [rows, setRows] = useState([{ pista: "", espaco_pista: "" }]);
  const [pistasDisponiveis, setPistasDisponiveis] = useState([]);
  const [exibirEscolhaPistas, setExibirEscolhaPistas] = useState(false);
  const [exibirMaisInfo, setExibirMaisInfo] = useState(false);

  const addRow = () => {
    if (rows.some((row) => row.pista === "")) {
      toast.error(t("pistavazia"));
    } else {
      const newRow = { pista: "", espaco_pista: "" };
      setRows((prevRows) => [...prevRows, newRow]);
    }
  };

  const handlePistaChange = (event, index) => {
    const { value } = event.target;

    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].pista = value.id;
      updatedRows[index].espaco_pista = value.ocupacao;

      return updatedRows;
    });
    setExibirMaisInfo(true);
  };

  const handleOcupacaoChange = (event, index) => {
    const { value } = event.target;
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].espaco_pista = parseInt(value);
      return updatedRows;
    });
  };

  const handleRemoveRow = (index) => {
    if (rows.length === 1) {
      toast.error("sempistas");
    } else {
      setRows((prevRows) =>
        prevRows.filter((row, rowIndex) => rowIndex !== index)
      );
    }
  };

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
    setErroProfessor(false);
  };

  const handleTurmaChange = (event) => {
    setSelectedTurma(event.target.value);
    setErroTurma(false);
  };

  const handlePoolChange = (event) => {
    setPista(0);
    setSelectedPool(event.target.value);
    getPistas({ id_piscina_agua: event.target.value })
      .then((res) => {
        if (res.success) {
          setMaxPista(res.data.data);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    setErroPiscina(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  const handleChange2 = (event) => {
    switch (event.target.name) {
      case "segunda":
        setSegunda(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTerca(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuarta(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuinta(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSexta(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabado(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingo(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    // Verifica se as datas são iguais e atualiza o estado de exibição das checkboxes
    if (
      data_inicio &&
      data_fim &&
      data_inicio.getTime() === data_fim.getTime()
    ) {
      setExibirCheckboxes(false);
      setSegunda(false);
      setTerca(false);
      setQuarta(false);
      setQuinta(false);
      setSexta(false);
      setSabado(false);
      setDomingo(false);
    } else {
      setExibirCheckboxes(true);
    }
  }, [data_inicio, data_fim]);
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEntidade);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaPiscinasAgua({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaTurmas({ id_piscina: idPiscina, id_entidade_exploradora: idEntidade })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_turma,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setTurmas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({
      id_piscina: idPiscina,
      id_entidade_exploradora: idEntidade,
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarAulas() {
    let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
    let data_fimf = moment(data_fim).format("YYYY-MM-DD");
    let hora_iniciof = moment(hora_inicio).format("hh:mm:ss");
    let hora_fimf = moment(hora_fim).format("hh:mm:ss");
    // const data_iniciof = format(data_inicio, 'yyyy-MM-dd');
    // const data_fimf = format(data_fim, 'yyyy-MM-dd');
    // const hora_iniciof = format(hora_inicio, 'HH:mm:ss');
    // const hora_fimf = format(hora_fim, 'HH:mm:ss');

    setFreeze(true);
    adicionarAula({
      id_turma: selectedTurma,
      id_piscina_agua: selectedPool,
      id_professor: selectedProfessor,
      pistas: rows,
      tamanho_pista: tamanho_pista,
      data_inicio: data_iniciof,
      data_fim: data_fimf,
      hora_inicio: hora_iniciof,
      hora_fim: hora_fimf,
      segunda: segunda,
      terca: terca,
      quarta: quarta,
      quinta: quinta,
      sexta: sexta,
      sabado: sabado,
      domingo: domingo,
      status: 1,
    }).then((res) => {
      if (res.success) {
        setFreeze(false)
        toast.success(t("aulaAdicionar"));
        navigate("/lessonlista");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      segunda == 0 &&
      terca == 0 &&
      quarta == 0 &&
      quinta == 0 &&
      sexta == 0 &&
      sabado == 0 &&
      domingo == 0
    ) {
      toast.error(t("selecionadia"));
    } else {
      if (
        (selectedTurma &&
          selectedPool &&
          selectedProfessor &&
          rows &&
          tamanho_pista &&
          data_inicio &&
          data_fim &&
          hora_inicio &&
          hora_fim &&
          segunda) ||
        terca ||
        quarta ||
        quinta ||
        sexta ||
        sabado ||
        domingo
      ) {
        let confirmarDadosPreenchidos = true;

        if (confirmarDadosPreenchidos) {
          //Scroll para o início da página
          window.scrollTo(0, 0);
          adicionarAulas();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        //Validação do nome
        if (!data_inicio) {
          setErroDataInicio(true);
        }
        if (!rows) {
          setErroPista(true);
        }

        if (!data_fim) {
          setErroDataFim(true);
        }
        if (!hora_inicio) {
          setErroHoraInicio(true);
        }
        if (!hora_fim) {
          setErroHoraFim(true);
        }
        if (!selectedTurma) {
          setErroTurma(true);
        }
        if (!selectedProfessor) {
          setErroProfessor(true);
        }
        if (!selectedPool) {
          setErroPiscina(true);
        }
        if (
          segunda == 0 &&
          terca == 0 &&
          quarta == 0 &&
          quinta == 0 &&
          sexta == 0 &&
          sabado == 0 &&
          domingo == 0
        ) {
          toast.error(t("selecionadia"));
        }
      }
    }
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/lessonlista");
              }}
              size={erWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          {/* <div className="align-right">
            <h3 className="titleList">{t("adicionarAula")}</h3>
          </div> */}
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroTurma}>
                    {t("escolhaturma")}*
                  </InputLabel>
                  <Select
                    value={selectedTurma}
                    onChange={handleTurmaChange}
                    error={erroTurma}
                    fullWidth
                    variant="standard"
                  >
                    {turmas.map((turma) => (
                      <MenuItem key={turma.id} value={turma.id}>
                        {turma.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroPiscina}>
                    {t("escolhapiscina")}*
                  </InputLabel>
                  <Select
                    value={selectedPool}
                    onChange={handlePoolChange}
                    error={erroPiscina}
                    fullWidth
                    variant="standard"
                  >
                    {piscinas.map((piscina) => (
                      <MenuItem key={piscina.id} value={piscina.id}>
                        {piscina.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroProfessor}>
                    {t("escolhaprofessor")}*
                  </InputLabel>
                  <Select
                    value={selectedProfessor}
                    onChange={handleProfessorChange}
                    error={erroProfessor}
                    fullWidth
                    variant="standard"
                  >
                    {professores.map((professor) => (
                      <MenuItem key={professor.id} value={professor.id}>
                        {professor.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("datainicio")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={data_inicio}
                      onChange={(e) => {
                        if(e){
                          setDataInicio(e.$d);
                          setErroDataInicio(false);
                        }
                        
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataInicio}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("datafim")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={data_fim}
                      onChange={(e) => {
                        if(e){
                          setDataFim(e.$d);
                          setErroDataFim(false);
                        }
                        
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataFim}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label={t("horainicio")}
                      value={hora_inicio}
                      onChange={(date) => {if(date) {setHoraInicio(date.$d)}}}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          error={erroHoraInicio}
                          required
                        />
                      )}
                      ampm={false}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label={t("horafim")}
                      value={hora_fim}
                      onChange={(date) => {if(date){setHoraFim(date.$d)}}}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          error={erroHoraFim}
                          required
                        />
                      )}
                      ampm={false}
                    />
                  </LocalizationProvider>
                </FormControl>

                {exibirCheckboxes && (
                  <FormControl className="formTextField2" fullWidth>
                    <Typography variant="subtitle" align="left">
                      {t("escolhadia")}
                    </Typography>

                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={segunda}
                            onChange={handleChange2}
                            name="segunda"
                            color="primary"
                          />
                        }
                        label={t("segunda")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={terca}
                            onChange={handleChange2}
                            name="terca"
                            color="primary"
                          />
                        }
                        label={t("terca")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={quarta}
                            onChange={handleChange2}
                            name="quarta"
                            color="primary"
                          />
                        }
                        label={t("quarta")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={quinta}
                            onChange={handleChange2}
                            name="quinta"
                            color="primary"
                          />
                        }
                        label={t("quinta")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sexta}
                            onChange={handleChange2}
                            name="sexta"
                            color="primary"
                          />
                        }
                        label={t("sexta")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sabado}
                            onChange={handleChange2}
                            name="sabado"
                            color="primary"
                          />
                        }
                        label={t("sabado")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={domingo}
                            onChange={handleChange2}
                            name="domingo"
                            color="primary"
                          />
                        }
                        label={t("domingo")}
                      />
                    </div>
                  </FormControl>
                )}
                {exibirEscolhaPistas && (
                  <div style={{ marginLeft: 10, marginTop: 10 }}>
                    {rows.map((row, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FormControl
                          className="formTextField2"
                          style={{
                            width: "40%",
                            marginRight: "10px",
                            marginTop: 10,
                          }}
                        >
                          <InputLabel className="formTextField22">
                            {t("pista")}
                          </InputLabel>
                          <Select
                            value={row.pista.nome}
                            onChange={(event) =>
                              handlePistaChange(event, index)
                            }
                            variant="standard"
                          >
                            {pistasD.map((pista) => {
                              const isPistaSelected = rows.some(
                                (r) => r.pista === pista.id && r !== row
                              );

                              if (isPistaSelected) {
                                return null;
                              }

                              return (
                                <MenuItem key={pista.id} value={pista}>
                                  {pista.nome}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {row.pista && (
                          <FormControl
                            className="formTextField2"
                            style={{ width: "40%" }}
                          >
                            <RadioGroup
                              value={row.espaco_pista}
                              onChange={(event) =>
                                handleOcupacaoChange(event, index)
                              }
                            >
                              {pistasD.map((pista) =>
                                pista.id === row.pista ? (
                                  pista.ocupacao == 2 ? (
                                    <FormControlLabel
                                      value={pista.ocupacao}
                                      control={<Radio />}
                                      label={"Meia Pista"}
                                      disabled={true}
                                    />
                                  ) : (
                                    <>
                                      <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label="Pista Completa"
                                      />
                                      <FormControlLabel
                                        value={2}
                                        control={<Radio color="primary" />}
                                        label="Meia Pista"
                                      />
                                    </>
                                  )
                                ) : null
                              )}
                            </RadioGroup>
                          </FormControl>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => handleRemoveRow(index)}
                            className="transparentButton"
                          >
                            X
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: 20,
                        marginTop: 10,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={addRow}
                        className="inverted-button"
                      >
                        {t("adicionarPista")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Box>
          </div>
        </div>

        {exibirMaisInfo && exibirEscolhaPistas && (
          <div className="buttonAdicionar">
            <Button
              variant="contained"
              onClick={() => {
                validacao1();
              }}
            >
              {t("adicionar")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default CriarAulaA;
