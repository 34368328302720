import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faChildren,
  faPaperPlane,
  faMoneyBill,
  faPlus,
  faTicket,
  faPlusCircle,
  faCross,
  faX,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  definePass,
  definePassEmail,
  verificarEmail,
  verificarNif,
} from "../../api/requests/Registo";
import {
  deleteCliente,
  enviarEmailPostCliente,
  getCliente,
  postCliente,
  putCliente,
  putStatusCliente,
} from "../../api/requests/Clientes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { listaTurmas } from "../../api/requests/Turmas";
import {
  proximasMarcacoesU,
  turmasInscrito,
} from "../../api/requests/Marcacoes";
import {
  cancelarInscricao,
  cancelarMarcacao,
  emailCancelarInscricao,
  emailCancelarMarcacao,
  proximasMarcacoes,
} from "../../api/requests/Marcacoes";
import {
  inscreveCliente,
  enviarEmailInscreveCliente,
  anularInscricaoCliente,
  enviarEmailAnularInscreveCliente,
} from "../../api/requests/Inscricao";

function EditarClienteFuncionario() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { nomePiscina, idCliente } = state || {};

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);

  const [turmas, setTurmas] = useState([]);
  const [nomeCliente, setNomeCliente] = useState("");
  const [emailCliente, setEmailCliente] = useState("");
  const [telefoneCliente, setTelefoneCliente] = useState("");
  const [nifCliente, setNifCliente] = useState("");
  const [moradaCliente, setMoradaCliente] = useState("");
  const [codigoPostalCliente, setCodigoPostalCliente] = useState("");
  const [fotoCliente, setFotoCliente] = useState([]);
  const [statusCliente, setStatusCliente] = useState("");
  const [NascimentoCliente, setNascimentoCliente] = useState(new Date());
  const [dadosOriginais, setDadosOriginais] = useState([]);

  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  //Validações
  // Responsável
  const [erroNomeCliente, setErroNomeCliente] = useState(false);
  const [erroEmailCliente, setErroEmailCliente] = useState(false);
  const [erroTelefoneCliente, setErroTelefoneCliente] = useState(false);
  const [erroNifCliente, setErroNifCliente] = useState(false);
  const [erroMoradaCliente, setErroMoradaCliente] = useState(false);
  const [erroCodigoPostalCliente, setErroCodigoPostalCliente] = useState(false);
  const [erroNascimentoCliente, setErroNascimentoCliente] = useState(false);

  // Responsável
  const [mensagemErroEmailCliente, setMensagemErroEmailCliente] = useState("");
  const [mensagemErroTelefoneCliente, setMensagemErroTelefoneCliente] =
    useState("");
  const [mensagemErroNifCliente, setMensagemErroNifCliente] = useState("");
  const [mensagemErroCodigoPostalCliente, setMensagemErroCodigoPostalCliente] =
    useState("");
  const [mensagemErroNascimentoCliente, setMensagemErroNascimentoCliente] =
    useState("");

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  //Popup suspensao
  const [marcacoes, setMarcacoes] = useState([]);
  const [suspender, setSuspender] = useState(false);
  const [turmap, setTurmaP] = useState(false);
  const [marcacaop, setMarcacaoP] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [idPiscina, setIdPiscina] = useState("");
  const [idTurma, setIdTurma] = useState("");
  const [idMarcacao, setIdMarcacao] = useState("");

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  const handleClickOpenPopupTurma = () => {
    setTurmaP(true);
  };

  const handleClosePopupTurma = () => {
    setTurmaP(false);
  };

  const handleClickOpenPopupMarcacao = () => {
    setTurmaP(true);
  };

  const handleClosePopupMarcacao = () => {
    setMarcacaoP(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    setIdPiscina(idPiscina);
    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getCliente(idCliente)
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];
          setDadosOriginais(dados);
          setNomeCliente(dados.nome);
          setEmailCliente(dados.email);
          setTelefoneCliente(dados.contacto);
          setNifCliente(dados.nif);
          setMoradaCliente(dados.morada);
          setCodigoPostalCliente(dados.codigo_postal);
          if (dados.foto) {
            setFotoCliente(dados.foto);
          }
          setNascimentoCliente(dados.data_nascimento_final);
          setStatusCliente(dados.status);
          setMotivo(res.data.obs);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    turmasInscrito({ id_cliente: idCliente })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_turma,
              nome: element.nomeTurma,
            };
            arrayFinal.push(linha);
            setTurmas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    proximasMarcacoesU({ id: idCliente }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        let dados = res.data.data;
        dados.forEach((element) => {
          if (element.id_usa_ticket) {
            const dataParts = element.dia.split("/");
            const dataFormatada = `${dataParts[2]}-${dataParts[1]}-${dataParts[0]}`;
            const data = new Date(dataFormatada);
            const dataInicio = new Date(
              `${data.toISOString().substr(0, 10)}T${element.horaInicio}`
            )
              .toISOString()
              .replace("T", " ")
              .replace("Z", "")
              .slice(0, -7);
            const dataFim = new Date(
              `${data.toISOString().substr(0, 10)}T${element.horaFim}`
            )
              .toISOString()
              .replace("T", " ")
              .replace("Z", "")
              .slice(0, -7);

            let titulo = element.nome;
            if (titulo == "Marcação de nado livre") {
              titulo = t("marcacaoNadoLivre");
            } else if (titulo == "Natação para adultos") {
              titulo = t("natacao_adultos");
            } else if (titulo == "Natação para bebés") {
              titulo = t("natacao_bebes");
            } else if (titulo == "Natação para idosos") {
              titulo = t("natacao_idosos");
            } else if (titulo == "Natação para grávidas") {
              titulo = t("natacao_gravidas");
            } else if (titulo == "Aulas de Hidroginástica") {
              titulo = t("hidroginastica");
            } else if (titulo == "Aulas de Hidrobike") {
              titulo = t("hidrobike");
            } else if (titulo == "Aulas de Hidroterapia") {
              titulo = t("hidroterapia");
            } else if (titulo == "Aulas de Hidropower") {
              titulo = t("hidropower");
            }else if (titulo == "Escola de Natação") {
              titulo = t("escolaNatacao");
            }

            const linha = {
              id: element.id_usa_ticket,
              title: titulo,
              description: element.nomePiscina,
              start: dataInicio,
              end: dataFim,
              nome_turma: element.nome_turma,
              color: element.id_turma != null ? "#ff6565" : "#84b6f4",
            };

            arrayFinal.push(linha);
            titulo = "";
          } else {
          }
        });
        //Filtrar apenas para marcações futuras
        const hoje = new Date().toISOString().substr(0, 10);

        const marcacoesFuturas = arrayFinal.filter((marcacao) => {
          return marcacao.start >= hoje;
        });

        setMarcacoes(marcacoesFuturas);
      }
    });
  }, []);

  async function anularInscricao() {
    setFreeze(true);
    await anularInscricaoCliente({
      id_utilizador: idCliente,
      id_piscina: idPiscina,
    }).then(async (res) => {
      if (res.success) {
        setTimeout(async function () {
          enviarEmailAnularInscreveCliente({
            id: idCliente,
          }).then((res2) => {
            if (res2.success) {
              setFreeze(false);
              toast.success(res2.data.msg);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          });
        }, 2000);
      } else {
        setFreeze(false)
        toast.error(res.error);
      }
    });
  }
  function editarCliente() {
    setFreeze(true);
    putCliente({
      id: idCliente,
      nome: nomeCliente,
      nif: nifCliente,
      email: emailCliente,
      contacto: telefoneCliente,
      morada: moradaCliente,
      codigo_postal: codigoPostalCliente,
      foto: fotoCliente.length == 0 ? null : fotoCliente,
      data_nascimento: new Date(NascimentoCliente).toISOString().split("T")[0],
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesClientesF1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {

            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  async function validacao() {
    if (
      nomeCliente &&
      emailCliente &&
      telefoneCliente &&
      nifCliente &&
      moradaCliente &&
      codigoPostalCliente &&
      NascimentoCliente
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailCliente
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailCliente(true);
        setMensagemErroEmailCliente(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneCliente)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneCliente(true);
        setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
      }
      if (emailCliente) {
        verificarEmail({ email: emailCliente }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != idCliente) {
                confirmarDadosPreenchidos = false;
                setErroEmailCliente(true);
                setMensagemErroEmailCliente(res.data.error);
              }
            }
          }
        });
      }
      // Validação do nif
      if (nifCliente.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifCliente(true);
        setMensagemErroNifCliente(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nifCliente }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              setErroNifCliente(true);
              setMensagemErroNifCliente(res.data.error);
              confirmarDadosPreenchidos = false;
            }
          }
        });
      }

      //Validação do código postal

      if (codigoPostalCliente < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalCliente(true);
        setMensagemErroCodigoPostalCliente(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(NascimentoCliente);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoCliente(true);
        setMensagemErroNascimentoCliente(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarCliente();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeCliente) {
        setErroNomeCliente(true);
      }
      //Validação do email
      if (!emailCliente) {
        setErroEmailCliente(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailCliente
          );
        if (!validacaoEmail) {
          setErroEmailCliente(true);
          setMensagemErroEmailCliente(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailCliente }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != idCliente) {
                  setErroEmailCliente(true);
                  setMensagemErroEmailCliente(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneCliente) {
        setErroTelefoneCliente(true);
      } else {
        if (!isValidPhoneNumber(telefoneCliente)) {
          setErroTelefoneCliente(true);
          setMensagemErroTelefoneCliente(t("validacaoTelemovel"));
        }
      }
      // Validação do nif
      if (!nifCliente) {
        setErroNifCliente(true);
      } else {
        if (nifCliente.length < 9) {
          setErroNifCliente(true);
          setMensagemErroNifCliente(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nifCliente }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNifCliente(true);
            setMensagemErroNifCliente(res.data.error);
          }
        }
      });
      //Validação da morada
      if (!moradaCliente) {
        setErroMoradaCliente(true);
      }
      //Validação do código postal
      if (!codigoPostalCliente) {
        setErroCodigoPostalCliente(true);
      } else {
        if (codigoPostalCliente < 7) {
          setErroCodigoPostalCliente(true);
          setMensagemErroCodigoPostalCliente(t("validacaoCodigoPostal"));
        }
      }
      //Validação da data de nascimento
      let isValidDate = Date.parse(NascimentoCliente);
      if (isNaN(isValidDate)) {
        setErroNascimentoCliente(true);
        setMensagemErroNascimentoCliente(t("dataInvalida"));
      }
    }
  }
  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName(
        "cardOpcoesClientesF1_1"
      )[0].style.cursor = "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName("cardOpcoesClientesF1_1")[0].style.cursor =
      "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeCliente(dadosOriginais.nome);
    setEmailCliente(dadosOriginais.email);
    setTelefoneCliente(dadosOriginais.contacto);
    setNifCliente(dadosOriginais.nif);
    setMoradaCliente(dadosOriginais.morada);
    setCodigoPostalCliente(dadosOriginais.codigo_postal);
    setFotoCliente(dadosOriginais.foto);
    setNascimentoCliente(dadosOriginais.data_nascimento_final);
    setStatusCliente(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeCliente(false);
    setErroEmailCliente(false);
    setErroTelefoneCliente(false);
    setErroNifCliente(false);
    setErroMoradaCliente(false);
    setErroCodigoPostalCliente(false);
    setErroNascimentoCliente(false);
    setMensagemErroEmailCliente("");
    setMensagemErroTelefoneCliente("");
    setMensagemErroNifCliente("");
    setMensagemErroCodigoPostalCliente("");
    window.scrollTo(0, 0);
  }

  function suspenderCliente() {
    if (motivoSuspensao) {
      setSuspender(false);
      setFreeze(true);
      putStatusCliente({
        id: idCliente,
        obs: motivoSuspensao,
      })
        .then((res) => {
          if (res.success) {
            setFreeze(false);
            toast.success(res.data.msg);
            setMotivoSuspensao("");
            setMotivo(motivoSuspensao);
            setStatusCliente(!statusCliente);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            setFreeze(false);
            toast.error(res.error);
            setMotivoSuspensao("");
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setFreeze(false)
      setErroMotivoSuspensao(true);
    }
  }

  function enviarEmail() {
    definePassEmail({
      email: emailCliente,
    }).then((res) => {
      if (res.success) {
        toast.success(res.msg);
        //navigate("/superadmins");
      } else {
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/poolcustomers", {
                      state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>

            <div className="align-right">
              {/* <h3 className="titleList">{t("editarCliente")}</h3> */}
            </div>
          </div>

          <div>
            <div className="formPage4">
              {/* Cards */}
              {!isMobile ? (
                <Box>
                  <div className="cardsDivClienteF">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesClientesF1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesClientesF1_1"
                        className="cardOpcoesClientesF1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivClienteF2">
                    {statusCliente ? (
                      <Button
                        className="cardOpcoesPiscinaEliminar"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscinaEliminar"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}

                    <Button
                      className="cardOpcoesPiscinaEliminar"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("anularinscricao")}
                    </Button>
                    <Button
                      className="cardOpcoesPiscinaEliminar"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? "blueviolet"
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/familyf", {
                          state: {
                            idPiscina: idPiscina,
                            idPai: idCliente,
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faChildren}
                      />
                      {t("contaFamiliar")}
                    </Button>
                  </div>
                  <div className="cardsDivClienteF2">
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscinaEliminar"
                      variant="outlined"
                      style={{
                        backgroundColor: edicao
                          ? Colors.card2
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/clientpayments", {
                          state: {
                            idPiscina: idPiscina,
                            idCliente: idCliente,
                            nomeCliente: nomeCliente,
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faMoneyBill}
                      />
                      {t("ListaPagamentos")}
                    </Button>
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscinaEliminar
                      "
                      variant="outlined"
                      style={{
                        backgroundColor: edicao
                          ? Colors.card5
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/marcarf", {
                          state: {
                            idPiscina: idPiscina,
                            idCliente: idCliente,
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faPlus} />
                      {t("marcar")}
                    </Button>
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscinaEliminar"
                      variant="outlined"
                      style={{
                        backgroundColor: edicao
                          ? Colors.card4
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/packlistc", {
                          state: {
                            idPiscina: idPiscina,
                            idCliente: idCliente,
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTicket} />
                      {t("packs")}
                    </Button>
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscinaEliminar"
                      variant="outlined"
                      style={{
                        backgroundColor: edicao
                          ? Colors.card7
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/nextativities", {
                          state: {
                            idPiscina: idPiscina,
                            idCliente: idCliente,
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faWater} />
                      {t("proximasAtividades")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    {statusCliente ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("anularinscricao")}
                    </Button>
                  </div>
                  <Button
                    className="cardOpcoesPiscina3"
                    variant="outlined"
                    disabled={!edicao}
                    style={{
                      backgroundColor: edicao
                        ? "blueviolet"
                        : Colors.cardInativo,
                    }}
                    onClick={() => {
                      navigate("/familyf", {
                        state: {
                          idPiscina: idPiscina,

                          idPai: idCliente,
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faChildren} />
                    {t("contaFamiliar")}
                  </Button>

                  <Button
                    id="editarCard"
                    className="cardOpcoesPiscina3"
                    variant="outlined"
                    style={{
                      backgroundColor: Colors.cardAtalho3,
                    }}
                    onClick={() => {
                      navigate("/clientpayments", {
                        state: {
                          idPiscina: idPiscina,
                          idCliente: idCliente,
                          nomeCliente: nomeCliente,
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faPaperPlane}
                    />
                    {t("ListaPagamentos")}
                  </Button>

                  <Button
                    id="editarCard"
                    className="cardOpcoesPiscina3"
                    variant="outlined"
                    style={{
                      backgroundColor: edicao
                        ? Colors.card5
                        : Colors.cardInativo,
                    }}
                    onClick={() => {
                      navigate("/marcarf", {
                        state: {
                          idPiscina: idPiscina,
                          idCliente: idCliente,
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faPaperPlane}
                    />
                    {t("marcar")}
                  </Button>
                  <Button
                    id="editarCard"
                    className="cardOpcoesPiscina3"
                    variant="outlined"
                    style={{
                      backgroundColor: edicao
                        ? Colors.card4
                        : Colors.cardInativo,
                    }}
                    onClick={() => {}}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faPlus} />
                    {t("packs")}
                  </Button>
                </div>
              )}
            </div>
            {motivo && (
              <div className="formPageSuspensao">
                <Alert severity="error">
                  {statusCliente ? t("clienteAtivo") : t("cienteSuspenso")} |{" "}
                  {t("motivo")}: {motivo}
                </Alert>
              </div>
            )}
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Eliminar cliente */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("anularInscricao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoAnularinscricao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  anularInscricao();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Suspender cliente */}
          <Dialog
            open={suspender}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {statusCliente ? t("suspenderCliente") : t("ativarCliente")}
            </DialogTitle>
            <DialogContent>
              {statusCliente
                ? t("justificacaoSuspencao")
                : t("justificacaoAtivacao")}{" "}
              *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoSuspensao}
                  error={erroMotivoSuspensao}
                  onChange={(e) => {
                    setMotivoSuspensao(e.target.value);
                    setErroMotivoSuspensao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupSuspender}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  suspenderCliente();
                }}
                autoFocus
              >
                {statusCliente ? t("suspender") : t("ativar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/*Anular inscrição de turma */}
          <Dialog
            open={turmap}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarturma")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarturma")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  setLoading2(true);
                  cancelarInscricao({
                    id_turma: idTurma,
                    id_cliente: idCliente,
                    id_filho: 0,
                  })
                    .then((res) => {
                      if (res.success) {
                        setTimeout(() => {
                          emailCancelarInscricao({
                            id_turma: idTurma,
                            id_cliente: idCliente,
                          }).then((res2) => {
                            toast.success(t("inscricaoCanceladaSucesso"));
                            let utilizador = Cookies.get("utilizador");
                            if (utilizador == "superadmin") {
                              navigate("/dashboard_superadmin");
                            } else if (utilizador == "supercomercial") {
                              navigate("/dashboard_supercommercial");
                            } else if (utilizador == "comercial") {
                              navigate("/dashboard_commercial");
                            } else if (utilizador == "admin") {
                              navigate("/dashboard_admin");
                            } else if (utilizador == "funcionario") {
                              navigate("/dashboard_employee");
                            } else if (utilizador == "professor") {
                              navigate("/dashboard_teacher");
                            } else if (utilizador == "cliente") {
                              navigate("/dashboard_customer");
                            } else {
                              navigate("/dashboard_customer");
                            }
                          });
                        }, 3000);
                      } else {
                        toast.error(t("inscricaoCanceladaErro"));
                      }
                    })
                    .catch((error) => {});
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupTurma}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          {/*Anular marcacao */}
          <Dialog
            open={marcacaop}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarmarcacao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarmarcacao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  setLoading2(true);

                  cancelarMarcacao({
                    id: idMarcacao,
                  })
                    .then((res) => {
                      if (res.success) {
                        setTimeout(() => {
                          emailCancelarMarcacao({
                            id_ticket: idMarcacao,
                            id_cliente: idCliente,
                          }).then((res2) => {
                            toast.success(t("marcacaoCanceladaSucesso"));
                            let utilizador = Cookies.get("utilizador");
                            if (utilizador == "superadmin") {
                              navigate("/dashboard_superadmin");
                            } else if (utilizador == "supercomercial") {
                              navigate("/dashboard_supercommercial");
                            } else if (utilizador == "comercial") {
                              navigate("/dashboard_commercial");
                            } else if (utilizador == "admin") {
                              navigate("/dashboard_admin");
                            } else if (utilizador == "funcionario") {
                              navigate("/dashboard_employee");
                            } else if (utilizador == "professor") {
                              navigate("/dashboard_teacher");
                            } else if (utilizador == "cliente") {
                              navigate("/dashboard_customer");
                            } else {
                              navigate("/dashboard_customer");
                            }
                          });
                        }, 3000);
                      } else {
                        toast.error(t("marcacaoCanceladaErro"));
                      }
                    })
                    .catch((error) => {});
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupMarcacao}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <div className="formPage">
              <Box className="formTextField" ref={ref}>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nomeCliente}
                      error={erroNomeCliente}
                      onChange={(e) => {
                        setNomeCliente(e.target.value);
                        setErroNomeCliente(false);
                      }}
                    />
                  </FormControl>
                  {/* Email */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("email")}
                      value={emailCliente}
                      error={erroEmailCliente}
                      helperText={mensagemErroEmailCliente}
                      onChange={(e) => {
                        setEmailCliente(e.target.value.toLocaleLowerCase());
                        setErroEmailCliente(false);
                        setMensagemErroEmailCliente("");
                      }}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={edicao}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefoneCliente}
                      error={erroTelefoneCliente}
                      helperText={mensagemErroTelefoneCliente}
                      onChange={(e) => {
                        setTelefoneCliente(e);
                        setErroTelefoneCliente(false);
                        setMensagemErroTelefoneCliente("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </FormControl>
                  {/* Nif */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nifCliente}
                      error={erroNifCliente}
                      helperText={mensagemErroNifCliente}
                      onChange={(e) => {
                        setNifCliente(e.target.value);
                        setErroNifCliente(false);
                      }}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("rua")}
                      value={moradaCliente}
                      error={erroMoradaCliente}
                      onChange={(e) => {
                        setMoradaCliente(e.target.value);
                        setErroMoradaCliente(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      disabled={edicao}
                      type="text"
                      variant="standard"
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      value={codigoPostalCliente}
                      required
                      format="####-###"
                      error={erroCodigoPostalCliente}
                      helperText={mensagemErroCodigoPostalCliente}
                      onChange={(e) => {
                        setCodigoPostalCliente(e.target.value);
                        setErroCodigoPostalCliente(false);
                        setMensagemErroCodigoPostalCliente("");
                      }}
                    />
                  </FormControl>
                  {/* Data de nascimento */}
                  <FormControl className="formTextField3" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disableFuture
                        disabled={edicao}
                        label={t("dataNascimento")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={NascimentoCliente}
                        onChange={(e) => {
                          setNascimentoCliente(e);
                          setErroNascimentoCliente(false);
                          setMensagemErroNascimentoCliente("");
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroNascimentoCliente}
                            helperText={mensagemErroNascimentoCliente}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {/* Foto */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")}
                    </p>
                    <label
                      id="fotoPiscina"
                      htmlFor="file-upload"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarImagem")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        document.getElementById(
                          "fotoPiscina"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById(
                          "tituloFotoPiscina"
                        ).style.color = Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setFotoCliente(reader.result);
                        };
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {fotoCliente != null && fotoCliente.length > 0 && (
                        <img
                          style={{ marginTop: 10 }}
                          width={150}
                          height={150}
                          src={fotoCliente}
                        />
                      )}
                    </div>
                  </FormControl>
                  <a
                    style={{
                      paddingTop: 30,
                      display: "flex",
                      alignContent: "flex-end",
                      paddingLeft: 40,
                    }}
                    href="#"
                    onClick={() => {
                      enviarEmail();
                    }}
                  >
                    {t("reporPalavraPasse")}
                  </a>
                </div>
              </Box>
              <Box
                className="formTextField"
                style={{ marginTop: 40, height: 300, marginBottom: 60 }}
              >
                <h6 className="titleList">{t("listaTurmas")}</h6>
                <DataGrid
                  className="tabela"
                  pageSize={10}
                  //Colunas
                  columns={[
                    {
                      field: "id",
                      headerName: t("id"),
                      type: "number",
                      maxWidth: 70,
                      flex: 1,
                    },
                    {
                      field: "nome",
                      headerName: t("nome"),
                      type: "string",
                      flex: 1,
                    },

                    {
                      field: "actions",
                      type: "actions",
                      headerName: t("acoes"),
                      width: 100,
                      cellClassName: "actions",
                      getActions: ({ id }) => {
                        return [
                          <GridActionsCellItem
                            icon={
                              <FontAwesomeIcon
                                style={{ fontSize: 18 }}
                                icon={faX}
                              />
                            }
                            label="Save"
                            onClick={() => {
                              setIdTurma(id);
                              handleClickOpenPopupTurma();
                            }}
                          />,
                        ];
                      },
                    },
                  ]}
                  rows={turmas}
                  checkboxSelection={false}
                  onRowClick={(params) => {}}
                  getRowId={(row) => row.id}
                  //Idioma
                  localeText={
                    i18n.language == "pt"
                      ? ptBR.components.MuiDataGrid.defaultProps.localeText
                      : i18n.language == "es"
                      ? esES.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>

              <Box
                className="formTextField"
                style={{ marginTop: 40, height: 300 }}
              >
                <h6 className="titleList">{t("listaMarcacoes")}</h6>
                <DataGrid
                  className="tabela"
                  pageSize={10}
                  //Colunas
                  columns={[
                    {
                      field: "id",
                      headerName: t("id"),
                      type: "number",
                      maxWidth: 70,
                      flex: 1,
                    },
                    {
                      field: "title",
                      headerName: t("nome"),
                      type: "string",
                      flex: 1,
                    },

                    {
                      field: "start",
                      headerName: t("horainicio"),
                      flex: 1,
                      type: "date",
                    },

                    {
                      field: "end",
                      headerName: t("horafim"),
                      flex: 1,
                      type: "date",
                    },
                    {
                      field: "actions",
                      type: "actions",
                      headerName: t("acoes"),
                      width: 100,
                      cellClassName: "actions",
                      getActions: ({ id }) => {
                        return [
                          <GridActionsCellItem
                            icon={
                              <FontAwesomeIcon
                                style={{ fontSize: 18 }}
                                icon={faX}
                              />
                            }
                            label="Cancel"
                            onClick={() => {
                              setIdMarcacao(id);
                              handleClickOpenPopupMarcacao();
                            }}
                          />,
                        ];
                      },
                    },
                  ]}
                  rows={marcacoes}
                  checkboxSelection={false}
                  onRowClick={(params) => {}}
                  getRowId={(row) => row.id}
                  //Idioma
                  localeText={
                    i18n.language == "pt"
                      ? ptBR.components.MuiDataGrid.defaultProps.localeText
                      : i18n.language == "es"
                      ? esES.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
            </div>
          </div>
          {loading2 && (
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarClienteFuncionario;
