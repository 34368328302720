import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";

//Post Inscrever
export function inscreveCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("inscricoes/customer", dados, token);
}

//Enviar email -Post inscrever
export function enviarEmailInscreveCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("inscricoes/active-reg", dados, token);
}

//anular inscricao
export function anularInscricaoCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("inscricoes/customer2", dados, token);
}

//Enviar email anular isncricao
export function enviarEmailAnularInscreveCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("inscricoes/desactive-reg", dados, token);
}

//Verificar inscrição pendente pagamento
export function verificarInscricaoPagamentoPendente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("inscricoes/verifica_inscricao", dados, token);
}
