import React, { useEffect, useState } from "react";
import {
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNumPedidos } from "../api/requests/Pedidos";

import {
  faCalendarCheck,
  faCalendarDays,
  faGear,
  faHouse,
  faPersonSwimming,
  faUser,
  faUsers,
  faUserSecret,
  faUserTie,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import {
  HomeIcon,
  CalendarDaysIcon,
  QrCodeIcon,
  UserGroupIcon,
  BookOpenIcon,
  ReceiptPercentIcon,
  IdentificationIcon,
  NoSymbolIcon,
  BuildingOffice2Icon,
  BriefcaseIcon,
  ExclamationTriangleIcon,
  RectangleStackIcon,
  HandRaisedIcon,
} from "@heroicons/react/24/solid";
import { Colors } from "../values/Colors";
import funcionarioIcon from "../images/funcionario.svg";
import funcionarioIconAzul from "../images/funcionarioAzul.svg";
import funcionarioIconBranco from "../images/funcionarioBranco.svg";

import piscinasIcon from "../images/piscinas.svg";
import piscinasIconAzul from "../images/piscinazazul.svg";
import piscinasIconBranco from "../images/piscinaBranca.svg";

import horarioIcon from "../images/horario.svg";
import horarioIconAzul from "../images/horarioAzul.svg";
import horarioIconBranco from "../images/horarioBranco.svg";

import fechoIcon from "../images/fecho.svg";
import fechoIconAzul from "../images/fechoAzul.svg";
import fechoIconBranco from "../images/fechoBranco.svg";
import expandiricon from "../images/expnadir.svg";
import diminuir from "../images/diminuir.svg";

import ocupaespacoIcon from "../images/ocupaespaco.svg";
import ocupaespacoIconBranco from "../images/ocupaespacobranco.svg";

import ocupaespacoIconAzul from "../images/ocupaespacoazul.svg";

import packsIcon from "../images/packs.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { Stack } from "@mui/system";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/outline";

function Nav() {
  //Navigator
  const navigate = useNavigate();

  const [isHoveredfuncionario, setIsHoveredFuncionario] = useState(false);
  const [isHoveredhorario, setIsHoveredHorario] = useState(false);
  const [isHoveredPiscina, setIsHoveredPiscina] = useState(false);
  const [isHoveredOcupa, setIsHoveredOcupa] = useState(false);
  const [isHoveredFecho, setIsHoveredFecho] = useState(false);
  const [showalloptions, setshowalloptions] = useState(false);
  const [numPedidosPendentes, setNumPedidosPendentes] = useState("");

  const handleExpandClick = () => {
    setshowalloptions(!showalloptions);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 799); // Defina o limite de largura para o que você considera um "telefone"
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

  const handleOnHover = () => {
    setShowAdditionalOptions(true);
    setIsHoveredOcupa(true);
  };
  const handleOnLeave = () => {
    setShowAdditionalOptions(false);
  };

  const handleOnClick = () => {
    setShowAdditionalOptions(!showAdditionalOptions);
  };
  const privilegios =
    Cookies.get("utilizador") == "funcionario"
      ? JSON.parse(Cookies.get("privilegios"))
      : null;

  const [pagamentosEmDia, setPagamentosEmDia] = useState(false);
  const [tipoUtilizador, setTipoUtilizador] = useState("");

  const selectedPoolId = localStorage.getItem("idPiscina");
  const selectedEntityId = localStorage.getItem("idEntidade");

  useEffect(() => {
    const idU = Cookies.get("uId");
    var tipoUtilizador = Cookies.get("utilizador");

    setTipoUtilizador(tipoUtilizador);
    const selectedPoolId = localStorage.getItem("idPiscina");
    let utilizador = Cookies.get("utilizador");
    if (
      utilizador == "admin" ||
      utilizador == "funcionario" ||
      utilizador == "professor"
    ) {
      getNumPedidos({ id_piscina: selectedPoolId })
        .then((res) => {
          if (res.success) {
            setNumPedidosPendentes(res.data.data);
          } else {
            // if (res.status == 500) {
            //   navigate("/500");
            // }
            // toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, []);

  //Faz com que mesmo que se abre a página diretamente pelo URL,
  // a cor esteja sempre de acordo com a página onde o utilizador está
  function getPageIndex(route) {
    if (tipoUtilizador == "superadmin") {
      switch (route) {
        case "/superadmins":
          return 0;
        case "/supercommercials":
          return 1;
        case "/dashboard_superadmin":
          return 2;
        case "/commercials":
          return 3;
        case "/pools":
          return 4;
        default:
          return 2;
      }
    } else if (tipoUtilizador == "supercomercial") {
      switch (route) {
        case "/commercials":
          return 0;
        case "/dashboard_supercommercial":
          return 1;
        case "/pools":
          return 2;
        default:
          return 1;
      }
    } else if (tipoUtilizador == "comercial") {
      switch (route) {
        case "/dashboard_commercial":
          return 0;
        case "/pools":
          return 1;
        default:
          return 0;
      }
    } else if (tipoUtilizador == "admin") {
      switch (route) {
        case "/dashboard_admin":
          return 0;
        case "/poolcustomersa":
          return 1;
        case "/employeesa":
          return 2;
        case "/teachera":
          return 3;
        case "/poolcalendara":
          return 5;
        case "/poolschedulea":
          return 6;
        case "/packlista":
          return 7;
        case "/discountlista":
          return 8;
        case "/silista":
          return 9;
        case "/qradmin":
          return 10;
        case "/editpoola":
          return 11;
        case "/classlista":
          return 13;
        case "/lessonlista":
          return 14;
        case "/spacelista":
          return 15;
        case "/cancellista":
          return 16;
        case "/waterlista":
          return 17;
        case "/closelista":
          return 18;
        case "/lessonordersa":
          return 19;
        default:
          return 0;
      }
    } else if (tipoUtilizador == "funcionario") {
      switch (route) {
        case "/dashboard_employee":
          return 0;
        case "/poolcustomers":
          return 1;
        case "/teacherf":
          return 2;
        case "/poolcalendar":
          return 3;
        case "/poolschedule":
          return 4;
        case "/silist":
          return 5;
        case "/packlist":
          return 6;
        case "/discountlist":
          return 7;
        case "/qremployee":
          return 8;
        case "/editpoolf":
          return 9;
        case "/classlist":
          return 11;
        case "/lessonlist":
          return 12;
        case "/spacelist":
          return 13;
        case "/cancellist":
          return 14;
        case "/waterlist":
          return 15;
        case "/lessonorders":
          return 16;

        default:
          return 0;
      }
    } else if (tipoUtilizador == "professor") {
      switch (route) {
        case "/teacher-orders":
          return 1;
        case "/dashboard_teacher":
          return 0;
        default:
          return 0;
      }
    } else if (tipoUtilizador == "cliente") {
      switch (route) {
        case "/mycalendar":
          return 0;
        case "/dashboard_customer":
          return 1;

        case "/qrcodes":

        case "/poolsu":
          return 2;
        default:
          return 1;
      }
    }
  }

  const value = getPageIndex(window.location.pathname);
  const [value2, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    if (tipoUtilizador == "superadmin") {
      if (newValue == 0) {
        navigate("/superadmins");
      } else if (newValue == 1) {
        navigate("/supercommercials");
      } else if (newValue == 2) {
        navigate("/dashboard_superadmin");
      } else if (newValue == 3) {
        navigate("/commercials");
      } else if (newValue == 4) {
        navigate("/pools");
      }
    } else if (tipoUtilizador == "supercomercial") {
      if (newValue == 0) {
        navigate("/commercials");
      } else if (newValue == 1) {
        navigate("/dashboard_supercommercial");
      } else if (newValue == 2) {
        navigate("/pools");
      }
    } else if (tipoUtilizador == "comercial") {
      if (newValue == 0) {
        navigate("/dashboard_commercial");
      } else if (newValue == 1) {
        navigate("/pools");
      }
    } else if (tipoUtilizador == "admin") {
      if (newValue == 0) {
        navigate("/dashboard_admin");
      } else if (newValue == 1) {
        navigate("/poolcustomersa");
      } else if (newValue == 2) {
        navigate("/employeesa");
      } else if (newValue == 3) {
        navigate("/teachera");
      } else if (newValue == 4) {
      } else if (newValue == 5) {
        navigate("/poolcalendara");
      } else if (newValue == 6) {
        navigate("/poolschedulea");
      } else if (newValue == 7) {
        navigate("/packlista");
      } else if (newValue == 8) {
        navigate("/discountlista");
      } else if (newValue == 9) {
        navigate("/silista");
      } else if (newValue == 10) {
        navigate("/qradmin");
      } else if (newValue == 11) {
        navigate("/editpoola", {
          state: { id: selectedPoolId },
        });
      } else if (newValue == 12) {
      } else if (newValue == 13) {
        navigate("/classlista");
      } else if (newValue == 14) {
        navigate("/lessonlista");
      } else if (newValue == 15) {
        navigate("/spacelista");
      } else if (newValue == 16) {
        navigate("/cancellista");
      } else if (newValue == 17) {
        navigate("/waterlista");
      } else if (newValue == 18) {
        navigate("/closelista");
      } else if (newValue == 19) {
        navigate("/lessonordersa");
      }
    } else if (tipoUtilizador == "funcionario") {
      if (newValue == 0) {
        navigate("/dashboard_employee");
      } else if (newValue == 1) {
        navigate("/poolcustomers");
      } else if (newValue == 2) {
        navigate("/teacherf");
      } else if (newValue == 3) {
        navigate("/poolcalendar");
      } else if (newValue == 4) {
        navigate("/poolschedule");
      } else if (newValue == 5) {
        navigate("/silist");
      } else if (newValue == 6) {
        navigate("/packlist");
      } else if (newValue == 7) {
        navigate("/discountlist");
      } else if (newValue == 8) {
        navigate("/qremployee");
      } else if (newValue == 9) {
        navigate("/editpoolf", {
          state: { id: selectedPoolId },
        });
      } else if (newValue == 10) {
      } else if (newValue == 11) {
        navigate("/classlist");
      } else if (newValue == 12) {
        navigate("/lessonlist");
      } else if (newValue == 13) {
        navigate("/spacelist");
      } else if (newValue == 14) {
        navigate("/cancellist");
      } else if (newValue == 15) {
        navigate("/waterlist");
      } else if (newValue == 16) {
        navigate("/lessonorders");
      }
    } else if (tipoUtilizador == "professor") {
      if (newValue == 1) {
        // Página calendário aqui
        navigate("/teacher-orders");
      } else if (newValue == 0) {
        navigate("/dashboard_teacher");
      }
    } else if (tipoUtilizador == "cliente") {
      if (newValue == 0) {
        // Página calendário aqui
        navigate("/mycalendar");
      } else if (newValue == 1) {
        navigate("/dashboard_customer");
      } else if (newValue == 2) {
        navigate("/qrcodes");
      }
    }
  };

  
  return (
    <>
      {tipoUtilizador == "professor" && (
        <>
          {isMobile ? (
            <div
              className={
                showalloptions ? "navbarClienteBottom2" : "navbarClienteBottom"
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyItems: "center", // Centralize horizontalmente
                  alignItems: "center", // Centralize verticalmente
                  position: "absolute",
                  margin: "0 auto",
                }}
              >
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "row",
                  }}
                  onChange={handleChange}
                >
                  <BottomNavigationAction
                    icon={<HomeIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    icon={
                      <ArchiveBoxArrowDownIcon className="navIconsBottom" />
                    }
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                </BottomNavigation>
              </div>
            </div>
          ) : (
            <div className={"navbarCliente"}>
              <span
                className={"logonav"}
                onClick={() => {
                  navigate("/dashboard_teacher");
                }}
              ></span>
              <div
                style={{
                  justifyContent: "center",
                  position: "absolute",
                  marginLeft: "-15px",
                }}
              >
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "column",
                    position: "absolute",
                  }}
                  onChange={handleChange}
                  showLabels
                >
                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("Home")}</span>}
                    icon={<HomeIcon className="navIcons" />}
                    classes={{
                      root: "bottomNavigationAction",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("pedidos")}</span>
                    }
                    icon={<ArchiveBoxArrowDownIcon className="navIcons" />}
                    classes={{
                      root: "bottomNavigationAction",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                </BottomNavigation>
              </div>
            </div>
          )}
        </>
      )}
      {tipoUtilizador == "cliente" && (
        <>
          {isMobile ? (
            <div
              className={
                showalloptions ? "navbarClienteBottom2" : "navbarClienteBottom"
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyItems: "center", // Centralize horizontalmente
                  alignItems: "flex-start", // Centralize verticalmente
                  position: "absolute",
                  marginBottom: "-15px !important",
                }}
              >
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "row",
                  }}
                  onChange={handleChange}
                >
                  <BottomNavigationAction
                    icon={<CalendarDaysIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    icon={<HomeIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    icon={<QrCodeIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                </BottomNavigation>
              </div>
            </div>
          ) : (
            <div className={"navbarCliente"}>
              <span
                className={"logonav"}
                onClick={() => {
                  navigate("/dashboard_customer");
                }}
              ></span>
              <div
                style={{
                  justifyContent: "center",
                  position: "absolute",
                  marginLeft: "-15px",
                }}
              >
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "column",
                    position: "absolute",
                  }}
                  onChange={handleChange}
                  showLabels
                >
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("calendario")}</span>
                    }
                    icon={<CalendarDaysIcon className="navIcons" />}
                    classes={{
                      root: "bottomNavigationAction",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("Home")}</span>}
                    icon={<HomeIcon className="navIcons" />}
                    classes={{
                      root: "bottomNavigationAction",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("qrcodes")}</span>
                    }
                    icon={<QrCodeIcon className="navIcons" />}
                    classes={{
                      root: "bottomNavigationAction",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                </BottomNavigation>
              </div>
            </div>
          )}
        </>
      )}
      {tipoUtilizador == "admin" && (
        <>
          {isMobile ? (
            <div
              className={
                showalloptions ? "navbarClienteBottom2" : "navbarClienteBottom"
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyItems: "center", // Centralize horizontalmente
                  alignItems: "center", // Centralize verticalmente
                  position: "absolute",
                  margin: "0 auto",
                }}
              >
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "row",
                  }}
                  onChange={handleChange}
                >
                  <BottomNavigationAction
                    icon={<HomeIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction4",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    icon={<UserGroupIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction4",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />

                  <BottomNavigationAction
                    onMouseEnter={() => setIsHoveredFuncionario(true)}
                    onMouseLeave={() => setIsHoveredFuncionario(false)}
                    icon={
                      <span
                        style={{
                          width: 18,
                          height: 22,
                          backgroundImage:
                            tipoUtilizador == "admin" &&
                            isHoveredfuncionario &&
                            value != 2
                              ? `url(${funcionarioIconBranco})`
                              : tipoUtilizador == "admin" && value == 2
                              ? `url(${funcionarioIconAzul})`
                              : `url(${funcionarioIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: "bottomNavigationAction4",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />

                  <BottomNavigationAction
                    icon={<BookOpenIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction4",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    value={99}
                    icon={
                      <span
                        style={{
                          width: 25,
                          height: 25,
                          backgroundImage: showalloptions
                            ? `url(${diminuir})`
                            : `url(${expandiricon})`,
                        }}
                        onClick={handleExpandClick}
                      />
                    }
                  />
                </BottomNavigation>
              </div>
              {showalloptions && (
                <>
                  <div className="additionalOptions">
                    <div className="column">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Centralize horizontalmente
                          alignItems: "center", // Centralize verticalmente
                          margin: "0 auto",
                        }}
                      >
                        <BottomNavigation
                          value={value}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onChange={handleChange}
                        >
                          <BottomNavigationAction
                            value={5}
                            icon={
                              <CalendarDaysIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={6}
                            onMouseEnter={() => setIsHoveredHorario(true)}
                            onMouseLeave={() => setIsHoveredHorario(false)}
                            icon={
                              <span
                                style={{
                                  width: 21,
                                  height: 16,
                                  backgroundImage:
                                    tipoUtilizador == "admin" &&
                                    isHoveredhorario &&
                                    value != 6
                                      ? `url(${horarioIconBranco})`
                                      : tipoUtilizador == "admin" && value == 6
                                      ? `url(${horarioIconAzul})`
                                      : `url(${horarioIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={7}
                            icon={
                              <RectangleStackIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={8}
                            icon={
                              <ReceiptPercentIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                        </BottomNavigation>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Centralize horizontalmente
                          alignItems: "center", // Centralize verticalmente
                          margin: "0 auto",
                        }}
                      >
                        <BottomNavigation
                          value={value}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onChange={handleChange}
                        >
                          <BottomNavigationAction
                            value={9}
                            icon={<HandRaisedIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            value={10}
                            icon={<QrCodeIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={11}
                            icon={
                              <IdentificationIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                            onMouseEnter={handleOnLeave}
                          />

                          <BottomNavigationAction
                            value={13}
                            icon={<HomeIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={14}
                            icon={<UserGroupIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                        </BottomNavigation>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Centralize horizontalmente
                          alignItems: "center", // Centralize verticalmente
                          margin: "0 auto",
                        }}
                      >
                        {" "}
                        <BottomNavigation
                          value={value}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onChange={handleChange}
                        >
                          <BottomNavigationAction
                            value={15}
                            icon={
                              <span
                                style={{
                                  width: 18,
                                  height: 22,
                                  backgroundImage: `url(${funcionarioIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction4",

                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            value={16}
                            icon={<NoSymbolIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                            onMouseEnter={handleOnLeave}
                          />

                          <BottomNavigationAction
                            value={17}
                            onMouseEnter={() => setIsHoveredPiscina(true)}
                            onMouseLeave={() => setIsHoveredPiscina(false)}
                            icon={
                              <span
                                style={{
                                  width: 21,
                                  height: 10,
                                  backgroundImage:
                                    tipoUtilizador == "admin" &&
                                    isHoveredPiscina &&
                                    value != 17
                                      ? `url(${piscinasIconBranco})`
                                      : tipoUtilizador == "admin" && value == 17
                                      ? `url(${piscinasIconAzul})`
                                      : `url(${piscinasIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            value={18}
                            onMouseEnter={() => setIsHoveredFecho(true)}
                            onMouseLeave={() => setIsHoveredFecho(false)}
                            icon={
                              <span
                                style={{
                                  width: 21,
                                  height: 21,
                                  backgroundImage:
                                    tipoUtilizador == "admin" &&
                                    isHoveredFecho &&
                                    value != 18
                                      ? `url(${fechoIconBranco})`
                                      : tipoUtilizador == "admin" && value == 18
                                      ? `url(${fechoIconAzul})`
                                      : `url(${fechoIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            value={19}
                            icon={
                              numPedidosPendentes != 0 ? (
                                <ExclamationTriangleIcon className="navIconsBottomPedidos" />
                              ) : (
                                <ExclamationTriangleIcon className="navIconsBottom" />
                              )
                            }
                            classes={{
                              root:
                                numPedidosPendentes != 0
                                  ? "bottomNavigationAction4Pedidos"
                                  : "bottomNavigationAction4",
                              selected:
                                numPedidosPendentes != 0
                                  ? "bottomNavigationActionSelectedPedidos"
                                  : "bottomNavigationActionSelected",
                            }}
                          />
                        </BottomNavigation>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="navbarCliente2" onMouseLeave={handleOnLeave}>
              <span
                className="logonav"
                onClick={() => {
                  navigate("/dashboard_admin");
                }}
              ></span>
              <div style={{ position: "absolute", marginLeft: "-15px" }}>
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "column",
                    position: "absolute",
                  }}
                  onChange={handleChange}
                  showLabels
                >
                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("Home")}</span>}
                    icon={<HomeIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("clientes")}</span>
                    }
                    icon={<UserGroupIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("funcionario")}</span>
                    }
                    onMouseEnter={() => setIsHoveredFuncionario(true)}
                    onMouseLeave={() => setIsHoveredFuncionario(false)}
                    icon={
                      <span
                        style={{
                          width: 18,
                          height: 20,
                          backgroundImage:
                            tipoUtilizador == "admin" &&
                            isHoveredfuncionario &&
                            value != 2
                              ? `url(${funcionarioIconBranco})`
                              : tipoUtilizador == "admin" && value == 2
                              ? `url(${funcionarioIconAzul})`
                              : `url(${funcionarioIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("professores")}</span>
                    }
                    icon={<BookOpenIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  {showAdditionalOptions ? (
                    <div className="separador2"></div>
                  ) : (
                    <div className="separador"></div>
                  )}
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("calendario")}</span>
                    }
                    icon={<CalendarDaysIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("horario")}</span>
                    }
                    onMouseEnter={() => setIsHoveredHorario(true)}
                    onMouseLeave={() => setIsHoveredHorario(false)}
                    icon={
                      <span
                        style={{
                          width: 21,
                          height: 16,
                          backgroundImage:
                            tipoUtilizador == "admin" &&
                            isHoveredhorario &&
                            value != 6
                              ? `url(${horarioIconBranco})`
                              : tipoUtilizador == "admin" && value == 6
                              ? `url(${horarioIconAzul})`
                              : `url(${horarioIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("packs")}</span>}
                    icon={<RectangleStackIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("descontos")}</span>
                    }
                    icon={<ReceiptPercentIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("inscricoes")}</span>
                    }
                    icon={<HandRaisedIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("qrcodes")}</span>
                    }
                    icon={<QrCodeIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("dadospiscina")}</span>
                    }
                    icon={<IdentificationIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                    onMouseEnter={handleOnLeave}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("ocupaesoaco")}</span>
                    }
                    onMouseLeave={() => setIsHoveredOcupa(false)}
                    icon={
                      <span
                        style={{
                          width: 19,
                          height: 19,
                          backgroundImage: isHoveredOcupa
                            ? `url(${ocupaespacoIconBranco})`
                            : tipoUtilizador == "admin" &&
                              value < 16 &&
                              value > 12
                            ? `url(${ocupaespacoIconAzul})`
                            : `url(${ocupaespacoIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                    onMouseEnter={handleOnHover}
                  />
                  {showAdditionalOptions ? (
                    <BottomNavigationAction
                      style={{ left: 70 }}
                      label={
                        <span className="navOptionText">{t("turma")}</span>
                      }
                      icon={<HomeIcon className="navIcons" />}
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel2",
                      }}
                    />
                  ) : null}

                  {showAdditionalOptions ? (
                    <BottomNavigationAction
                      style={{ left: 70 }}
                      label={
                        <span className="navOptionText">{t("aulas")}</span>
                      }
                      icon={<UserGroupIcon className="navIcons" />}
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel2",
                      }}
                    />
                  ) : null}
                  {showAdditionalOptions ? (
                    <BottomNavigationAction
                      style={{ left: 70 }}
                      label={
                        <span className="navOptionText" p>
                          {t("espaco")}
                        </span>
                      }
                      icon={
                        <span
                          style={{
                            width: 18,
                            height: 22,
                            backgroundImage: `url(${funcionarioIcon})`,
                          }}
                        />
                      }
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel2",
                      }}
                    />
                  ) : null}

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("cancelados")}</span>
                    }
                    icon={<NoSymbolIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                    onMouseEnter={handleOnLeave}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("piscinas")}</span>
                    }
                    onMouseEnter={() => setIsHoveredPiscina(true)}
                    onMouseLeave={() => setIsHoveredPiscina(false)}
                    icon={
                      <span
                        style={{
                          width: 19,
                          height: 10,
                          backgroundImage:
                            tipoUtilizador == "admin" &&
                            isHoveredPiscina &&
                            value != 17
                              ? `url(${piscinasIconBranco})`
                              : tipoUtilizador == "admin" && value == 17
                              ? `url(${piscinasIconAzul})`
                              : `url(${piscinasIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("fecho")}</span>}
                    onMouseEnter={() => setIsHoveredFecho(true)}
                    onMouseLeave={() => setIsHoveredFecho(false)}
                    icon={
                      <span
                        style={{
                          width: 19,
                          height: 19,
                          backgroundImage:
                            tipoUtilizador == "admin" &&
                            isHoveredFecho &&
                            value != 18
                              ? `url(${fechoIconBranco})`
                              : tipoUtilizador == "admin" && value == 18
                              ? `url(${fechoIconAzul})`
                              : `url(${fechoIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      numPedidosPendentes != 0 ? (
                        <span className="navOptionTextPedidos">
                          {t("pedidos")}
                        </span>
                      ) : (
                        <span className="navOptionText">{t("pedidos")}</span>
                      )
                    }
                    icon={
                      numPedidosPendentes != 0 ? (
                        <ExclamationTriangleIcon className="navIconsPedidos" />
                      ) : (
                        <ExclamationTriangleIcon className="navIcons" />
                      )
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? numPedidosPendentes != 0
                          ? "bottomNavigationAction3Pedidos"
                          : "bottomNavigationAction3"
                        : numPedidosPendentes != 0
                        ? "bottomNavigationAction2Pedidos"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                </BottomNavigation>
              </div>
            </div>
          )}
        </>
      )}

      {tipoUtilizador == "funcionario" && (
        <>
          {isMobile ? (
            <div
              className={
                showalloptions ? "navbarClienteBottom2" : "navbarClienteBottom"
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyItems: "center", // Centralize horizontalmente
                  alignItems: "center", // Centralize verticalmente
                  position: "absolute",
                  margin: "0 auto",
                }}
              >
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "row",
                  }}
                  onChange={handleChange}
                >
                  <BottomNavigationAction
                    icon={<HomeIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    icon={<UserGroupIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />

                  <BottomNavigationAction
                    icon={<BookOpenIcon className="navIconsBottom" />}
                    classes={{
                      root: "bottomNavigationAction3",
                      selected: "bottomNavigationActionSelected",
                    }}
                  />
                  <BottomNavigationAction
                    value={99}
                    icon={
                      <span
                        style={{
                          width: 25,
                          height: 25,
                          backgroundImage: showalloptions
                            ? `url(${diminuir})`
                            : `url(${expandiricon})`,
                        }}
                        onClick={handleExpandClick}
                      />
                    }
                  />
                </BottomNavigation>
              </div>
              {showalloptions && (
                <>
                  <div className="additionalOptions">
                    <div className="column">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Centralize horizontalmente
                          alignItems: "center", // Centralize verticalmente
                          margin: "0 auto",
                        }}
                      >
                        <BottomNavigation
                          value={value}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onChange={handleChange}
                        >
                          <BottomNavigationAction
                            value={3}
                            icon={
                              <CalendarDaysIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            value={4}
                            onMouseEnter={() => setIsHoveredHorario(true)}
                            onMouseLeave={() => setIsHoveredHorario(false)}
                            icon={
                              <span
                                style={{
                                  width: 19,
                                  height: 16,
                                  backgroundImage:
                                    isHoveredhorario && value != 4
                                      ? `url(${horarioIconBranco})`
                                      : value == 4
                                      ? `url(${horarioIconAzul})`
                                      : `url(${horarioIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction4",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={5}
                            icon={<HandRaisedIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={6}
                            icon={
                              <RectangleStackIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            value={7}
                            icon={
                              <ReceiptPercentIcon className="navIconsBottom" />
                            }
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                        </BottomNavigation>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Centralize horizontalmente
                          alignItems: "center", // Centralize verticalmente
                          margin: "0 auto",
                        }}
                      >
                        <BottomNavigation
                          value={value}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onChange={handleChange}
                        >
                          <BottomNavigationAction
                            icon={<QrCodeIcon className="navIconsBottom" />}
                            value={8}
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          <BottomNavigationAction
                            icon={
                              <IdentificationIcon className="navIconsBottom" />
                            }
                            value={9}
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                            onMouseEnter={handleOnLeave}
                          />

                          <BottomNavigationAction
                            value={11}
                            icon={<HomeIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={12}
                            icon={<UserGroupIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                        </BottomNavigation>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Centralize horizontalmente
                          alignItems: "center", // Centralize verticalmente
                          margin: "0 auto",
                        }}
                      >
                        <BottomNavigation
                          value={value}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onChange={handleChange}
                        >
                          <BottomNavigationAction
                            value={13}
                            icon={
                              <span
                                style={{
                                  width: 18,
                                  height: 19,
                                  backgroundImage: `url(${funcionarioIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />

                          <BottomNavigationAction
                            value={14}
                            icon={<NoSymbolIcon className="navIconsBottom" />}
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                            onMouseEnter={handleOnLeave}
                          />

                          <BottomNavigationAction
                            value={15}
                            icon={
                              <span
                                style={{
                                  width: 19,
                                  height: 10,
                                  backgroundImage: `url(${piscinasIcon})`,
                                }}
                              />
                            }
                            classes={{
                              root: "bottomNavigationAction3",
                              selected: "bottomNavigationActionSelected",
                            }}
                          />
                          {privilegios.pedidos == 1 ? (
                            <BottomNavigationAction
                              value={16}
                              icon={
                                <ExclamationTriangleIcon className="navIconsBottom" />
                              }
                              classes={{
                                root: "bottomNavigationAction3",

                                selected: "bottomNavigationActionSelected",
                              }}
                            />
                          ) : null}
                        </BottomNavigation>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={"navbarCliente2"}>
              <span
                className={"logonav"}
                onClick={() => {
                  navigate("/dashboard_employee");
                }}
              ></span>
              <div style={{ position: "absolute", marginLeft: "-15px" }}>
                <BottomNavigation
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "column",
                    position: "absolute",
                  }}
                  onChange={handleChange}
                  showLabels
                >
                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("Home")}</span>}
                    icon={<HomeIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("clientes")}</span>
                    }
                    icon={<UserGroupIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("professores")}</span>
                    }
                    icon={<BookOpenIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("calendario")}</span>
                    }
                    icon={<CalendarDaysIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("horario")}</span>
                    }
                    onMouseEnter={() => setIsHoveredHorario(true)}
                    onMouseLeave={() => setIsHoveredHorario(false)}
                    icon={
                      <span
                        style={{
                          width: 19,
                          height: 16,
                          backgroundImage:
                            tipoUtilizador == "admin" &&
                            isHoveredhorario &&
                            value != 4
                              ? `url(${horarioIconBranco})`
                              : tipoUtilizador == "funcionario" && value == 4
                              ? `url(${horarioIconAzul})`
                              : `url(${horarioIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("inscricoes")}</span>
                    }
                    icon={<HandRaisedIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={<span className="navOptionText">{t("packs")}</span>}
                    icon={<RectangleStackIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("descontos")}</span>
                    }
                    icon={<ReceiptPercentIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("qrcodes")}</span>
                    }
                    icon={<QrCodeIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("dadospiscina")}</span>
                    }
                    icon={<IdentificationIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                    onMouseEnter={handleOnLeave}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("ocupaesoaco")}</span>
                    }
                    icon={
                      <span
                        style={{
                          width: 19,
                          height: 19,
                          backgroundImage: `url(${ocupaespacoIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                    onMouseEnter={handleOnHover}
                  />
                  {showAdditionalOptions ? (
                    <BottomNavigationAction
                      style={{ left: 70 }}
                      label={
                        <span className="navOptionText">{t("turma")}</span>
                      }
                      icon={<HomeIcon className="navIcons" />}
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel2",
                      }}
                    />
                  ) : null}

                  {showAdditionalOptions ? (
                    <BottomNavigationAction
                      style={{ left: 70 }}
                      label={
                        <span className="navOptionText">{t("aulas")}</span>
                      }
                      icon={<UserGroupIcon className="navIcons" />}
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel2",
                      }}
                    />
                  ) : null}
                  {showAdditionalOptions ? (
                    <BottomNavigationAction
                      style={{ left: 70 }}
                      label={
                        <span className="navOptionText" p>
                          {t("espaco")}
                        </span>
                      }
                      icon={
                        <span
                          style={{
                            width: 18,
                            height: 19,
                            backgroundImage: `url(${funcionarioIcon})`,
                          }}
                        />
                      }
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel2",
                      }}
                    />
                  ) : null}

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("cancelados")}</span>
                    }
                    icon={<NoSymbolIcon className="navIcons" />}
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                    onMouseEnter={handleOnLeave}
                  />

                  <BottomNavigationAction
                    label={
                      <span className="navOptionText">{t("piscinas")}</span>
                    }
                    icon={
                      <span
                        style={{
                          width: 19,
                          height: 10,
                          backgroundImage: `url(${piscinasIcon})`,
                        }}
                      />
                    }
                    classes={{
                      root: showAdditionalOptions
                        ? "bottomNavigationAction3"
                        : "bottomNavigationAction2",
                      selected: "bottomNavigationActionSelected",
                      label: "bottomNavigationLabel",
                    }}
                  />
                  {privilegios.pedidos == 1 ? (
                    <BottomNavigationAction
                      label={
                        <span className="navOptionText">{t("pedidos")}</span>
                      }
                      icon={<ExclamationTriangleIcon className="navIcons" />}
                      classes={{
                        root: showAdditionalOptions
                          ? "bottomNavigationAction3"
                          : "bottomNavigationAction2",
                        selected: "bottomNavigationActionSelected",
                        label: "bottomNavigationLabel",
                      }}
                    />
                  ) : null}
                </BottomNavigation>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Nav;
