import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import { Colors } from "../../values/Colors";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faPen,
  faStop,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import bcrypt from "bcryptjs";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { createSearchParams, Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  getUtilizadoresId,
  putUtilizador,
} from "../../api/requests/Utilizadores";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { alteraUserPass } from "../../api/requests/Registo";

function AlterarPassword() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  //password
  const [id, setId] = useState("");
  const [tokenu, setTokenU] = useState("");
  const [palavraPasse, setPalavraPasse] = useState("");
  const [palavraPasse2, setPalavraPasse2] = useState("");

  //Validações
  const [erroPassword2, setErroPassword2] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroPalavraPasse2, setMensagemErroPalavraPasse2] =
    useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoPalavraPasse, setSucessoPalavraPasse] = useState("");
  const [sucessoPalavraPasse2, setSucessoPalavraPasse2] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const ref = useRef(null);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const idU = Cookies.get("uId");
    setId(idU);
    const token = Cookies.get("token");
    setTokenU(token);
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!idU || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (!dadosIncompletos) {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  function ChangePassword() {
    setFreeze(true);
    if (palavraPasse) {
      if (palavraPasse === palavraPasse2) {
        const hashedPassword = bcrypt.hashSync(
          palavraPasse,
          "$2a$10$CwTycUXWue0Thq9StjUM0u"
        );
        alteraUserPass(
          {
            newPwd: hashedPassword,
          },
          id,
          tokenu
        ).then((res) => {
          if (res.success) {
            setTimeout(function () {
              setFreeze(false);
              toast.success(t("passAlterada"));
              navigate("/login");
            }, 3000);
          } else {
            setTimeout(function () {
              setFreeze(false);
              toast.error(t("alteraErro"));
            }, 3000);
          }
        });
      } else {
        setErroPassword(true);
        setErroPassword2(true);
        toast.error(t("iguaisPass"));
        setFreeze(false);
      }
    } else {
      setErroPassword(true);
      setErroPassword2(true);
      setMensagemErroPalavraPasse(t("passVazia"));
      setMensagemErroPalavraPasse2(t("passVazia"));
      toast.error(t("vaziaPass"));
      setFreeze(false);
    }
  }

  useEffect(() => {
    if (palavraPasse) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = palavraPasse.length;
      const uppercasePassword = uppercaseRegExp.test(palavraPasse);
      const lowercasePassword = lowercaseRegExp.test(palavraPasse);
      const digitsPassword = digitsRegExp.test(palavraPasse);
      const specialCharPassword = specialCharRegExp.test(palavraPasse);
      const minLengthPassword = minLengthRegExp.test(palavraPasse);

      if (passwordLength === 0) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("vazia"));
      } else if (!uppercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("uppercase"));
      } else if (!lowercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("lowercase"));
      } else if (!digitsPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracternumb"));
      } else if (!specialCharPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracterespecial"));
      } else if (!minLengthPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("8caracteres"));
      } else {
        setErroPassword(false);
        setMensagemErroPalavraPasse("");
        setSucessoPalavraPasse("success");
      }
    }

    if (palavraPasse2) {
      if (palavraPasse2 != palavraPasse) {
        setErroPassword(true);
        setErroPassword2(true);
        setMensagemErroPalavraPasse(t("passesigual"));
        setMensagemErroPalavraPasse2(t("passesigual"));
      } else {
        setErroPassword(false);
        setErroPassword2(false);
        setMensagemErroPalavraPasse("");
        setMensagemErroPalavraPasse2("");
        setSucessoPalavraPasse2("success");
      }
    }
  }, [palavraPasse2, palavraPasse]);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <div className="divForm">
        <div className="containerForm2" style={{ marginTop: "10px" }}>
        <div className="topleftcliente">
         
    
           <IconButton
             className="arrowBack"
             size={isMobile ? "small" : "medium"}
           
             onClick={() => {
              
                 navigate("/definitions");
              
             }}
           >
             {" "}
             <FontAwesomeIcon icon={faArrowLeft} />
           </IconButton>

       </div>
          <div className="align-right">
            <h1 className="dashboardTitle"
            >{t("alterarPalavraPasse")}</h1>
          </div>
        </div>
        <div>
          <div className="formPage5">
            <Box className="formTextFieldPass">
              
              <FormControl className="formTextField3" fullWidth>
                <TextField
                  id="password"
                  label={t("palavraPasse")}
                  variant="standard"
                  error={erroPassword}
                  type={showPassword ? "text" : "password"}
                  helperText={mensagemErroPalavraPasse}
                  color={sucessoPalavraPasse}
                  onChange={(e) => {
                    setPalavraPasse(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableRipple
                          disableFocusRipple
                          style={{
                            justifyContent: "right",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            color: "#8A8A8A",
                          }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                ></TextField>
              </FormControl>
              {/* Confirma Palavra-passe */}

              <FormControl className="formTextField3" fullWidth>
                <TextField
                  id="confirmarpassword"
                  label={t("confirmaPalavraPasse")}
                  variant="standard"
                  error={erroPassword2}
                  type={showPassword2 ? "text" : "password"}
                  helperText={mensagemErroPalavraPasse2}
                  color={sucessoPalavraPasse2}
                  onChange={(e) => {
                    setPalavraPasse2(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disableRipple
                          disableFocusRipple
                          style={{
                            justifyContent: "right",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            color: "#8A8A8A",
                          }}
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                ></TextField>
              </FormControl>
              <button
                type="button"
                style={{ marginTop: "2%", marginLeft: "3%" }}
                className="iniciobtn"
                onClick={() => {
                  ChangePassword();
                }}
              >
                {t("confirmar")}
              </button>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlterarPassword;
