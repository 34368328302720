import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";

import "react-phone-number-input/style.css";

import { toast } from "react-toastify";

import "@react-pdf-viewer/core/lib/styles/index.css";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import {
  editarDescontos,
  eliminarDescontos,
  getDesconto,
} from "../../api/requests/Descontos";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaAcoes, listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import moment from "moment";
import {
  editarSI,
  eliminarSI,
  getSI,
} from "../../api/requests/SeguroInscricao";

function EditarSI() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  //Privilégios funcionario
  const privilegios = JSON.parse(Cookies.get("privilegios"));

  const { state } = useLocation();
  const { idSI } = state || {};

  const [edicao, setEdicao] = useState(true);
  const [freeze, setFreeze] = useState(false);

  const [seguro, setSeguro] = useState(0);
  const [inscricao, setInscricao] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [idPiscina, setIdPiscina] = useState("");
  const [dataExpiraS, setDataExpiraS] = useState("");
  const [dataExpiraI, setDataExpiraI] = useState("");

  const [erroSeguro, setErroSeguro] = useState("");
  const [erroInscricao, setErroInscricao] = useState("");
  const [erroCategoria, setErroCategoria] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [erroDataExpiraS, setErroDataExpiraS] = useState("");
  const [erroDataExpiraI, setErroDataExpiraI] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    getSI({ id: idSI })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];
          setDadosOriginais(dados);

          setSeguro(dados.seguro);
          setInscricao(dados.inscricao);
          setDataExpiraI(dados.data_expira_inscricao);
          setDataExpiraS(dados.data_expira_seguro);
          setSelectedCategory(dados.id_categoria);

          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName(
        "cardOpcoesPiscinaEliminar3"
      )[0].style.cursor = "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName(
      "cardOpcoesPiscinaEliminar3"
    )[0].style.cursor = "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais

    setSeguro(dadosOriginais.seguro);
    setInscricao(dadosOriginais.inscricao);
    setDataExpiraI(dadosOriginais.data_expira_inscricao);
    setDataExpiraS(dadosOriginais.data_expira_seguro);
    setSelectedCategory(dadosOriginais.id_categoria);

    //Limpar erros e mensagens de erro

    setErroSeguro(false);
    setErroInscricao(false);
    setErroDataExpiraI(false);
    setErroDataExpiraS(false);
    setErroCategoria(false);

    window.scrollTo(0, 0);
  }
  function editarSegI() {
    setFreeze(true);
    editarSI({
      id: idSI,
      inscricao: inscricao,
      id_piscina: idPiscina,
      categoria: selectedCategory,
      seguro: seguro,
      data_seguro: moment(dataExpiraS).format("YYYY-MM-DD"),
      data_inscricao: moment(dataExpiraI).format("YYYY-MM-DD"),
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (inscricao && seguro && selectedCategory && dataExpiraS && dataExpiraI) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarSegI();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (inscricao == 0) {
        setErroInscricao(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (seguro == 0) {
        setErroSeguro(true);
      }
      if (!dataExpiraI) {
        setErroDataExpiraI(true);
      }
      if (!dataExpiraS) {
        setErroDataExpiraS(true);
      }
    }
  }

  function eliminarSi() {
    setFreeze(true);
    eliminarSI({
      id: idSI,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("eliminarSI"));
          navigate("/silist");
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  const [isMobile, setIsMobile] = useState(erWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  onClick={() => {
                    navigate("/silist");
                  }}
                  size={isMobile ? "small" : "medium"}
                >
                  {" "}
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </IconButton>
              ) : null}
            </div>

            <div className="align-right">
              {/* <h3 className="titleList">{t("editarDescontos")}</h3> */}
            </div>
          </div>
          <div>
            <div className="formPage4">
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina2">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        disabled={
                          !edicao || privilegios.editar_si == 0 ? true : false
                        }
                        style={{
                          backgroundColor:
                            !edicao || privilegios.editar_si == 0
                              ? Colors.cardInativo
                              : Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={
                        !edicao || privilegios.eliminar_si == 0 ? true : false
                      }
                      style={{
                        backgroundColor:
                          !edicao || privilegios.eliminar_si == 0
                            ? Colors.cardInativo
                            : Colors.card3,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>

                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarSeg")}
            </DialogTitle>
            <DialogContent>{t("acaoIrre")}</DialogContent>
            <DialogActions>
              {" "}
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarSi();
                }}
                autoFocus
              >
                {t("eliminar")}
              </Button>
            </DialogActions>
          </Dialog>

          <div>
            <div className="formPage">
              <Box className="formTextField">
                <div>
                  <FormControl className="formTextField2" fullWidth>
                    <InputLabel
                      className="formTextField22"
                      error={erroCategoria}
                    >
                      {t("escolhacategoria")}*
                    </InputLabel>
                    <Select
                      disabled={edicao}
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      error={erroCategoria}
                      fullWidth
                      variant="standard"
                    >
                      {categorias.map((categoria) => (
                        <MenuItem key={categoria.id} value={categoria.id}>
                          {categoria.categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={`${t("seguro")} (€)`}
                      value={seguro}
                      error={erroSeguro}
                      onChange={(e) => {
                        const value = e.target.value;

                        setSeguro(value);
                        setErroSeguro(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={`${t("inscricao")} (€)`}
                      value={inscricao}
                      error={erroInscricao}
                      onChange={(e) => {
                        const value = e.target.value;

                        setInscricao(value);
                        setErroInscricao(false);
                      }}
                    />
                  </FormControl>

                  <FormControl className="formTextField2" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disabled={edicao}
                        label={t("dataexpiras")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataExpiraS}
                        onChange={(e) => {
                          if (e) {
                            setDataExpiraS(e.$d);
                            setErroDataExpiraS(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroDataExpiraS}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disabled={edicao}
                        label={t("dataexpirai")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataExpiraI}
                        onChange={(e) => {
                          if (e) {
                            setDataExpiraI(e.$d);
                            setErroDataExpiraI(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroDataExpiraI}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarSI;
