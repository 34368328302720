import Cookies from "js-cookie";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import {
  Paper,
  Button,
  Icon,
  InputAdornment,
  TextField,
  unstable_composeClasses,
  Typography,
} from "@mui/material";
import {
  faFilePen,
  faArrowLeft,
  faTags,
  faQuestion,
  faRightFromBracket,
  faSwimmingPool,
  faSwimmer,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import bcrypt from "bcryptjs";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  getUtilizadoresId,
  suspendeUtilizador,
  suspendeUtilizadorEmail,
} from "../../api/requests/Utilizadores";
import { selectedIdsLookupSelector } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { logout } from "../../api/requests/AuthServer";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toolbar from "@mui/material/Toolbar";
import {
  faInbox,
  faEnvelope,
  faPhone,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

function AjudaProfessor(props) {
  const [freeze, setFreeze] = useState(false);

  const [mobileOpen, setMobileOpen] = React.useState(true);

  const [content, setContent] = useState("default");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = useState(true);
  const drawerWidth = open ? "240px" : "55px";

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const handleButtonClick = (option) => {
    setContent(option);
  };

  const getTypographyContent = () => {
    switch (content) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#8B9FB2", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph>
              Sim podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#8B9FB2", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer um pedido de cancelamento de uma aula?
            </Typography>
            <Typography paragraph>
              No calendário do dashboard, carregando na aula que pretendes fazer
              o pedido de cancelamento, ela vai abrir uma janela com mais
              informações. Nessa janela terás a opção de cancelar. Para fazer
              este pedido deves escrever uma observação para que o funcionário
              e/ou administrador possam avaliar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Onde posso ver os meus pedidos?
            </Typography>
            <Typography paragraph>
              Poderás consultar os teus pedidos no teu Perfil em Pedidos.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso o meu pedido seja aprovado, a aula fica automaticamente
              cancelada?
            </Typography>
            <Typography paragraph>
              Sim, a aula fica automaticamente cancelada, e serás avisado(a). A
              aula irá aparecer no teu calendário com a nota a dizer que está
              cancelada. Também irão ser notificados todos os alunos.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Uma vez que uma aula esteja cancelada é possível reverter a
              situação?
            </Typography>
            <Typography paragraph>
              Não. Se uma aula foi cancelada, mas posteriormente foi decidido
              que ela será realizada como planeado, é importante entrar em
              contato com o funcionário responsável para retificar a situação.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph>
              Aqui estão os nossos contactos para que possas entrar em contacto
              connosco.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph>suporte@tectank.pt</Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph>+351 911 741 470</Typography>
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="divForm">
      <Header />
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{
            width: { xs: drawerWidth, sm: drawerWidth },
            flexShrink: { sm: 0, xs: 0 },
          }}
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: "60px",
                border: "none",
              },
            }}
            open={open}
          >
            {
              <List sx={{ marginTop: "5%" }}>
                <IconButton
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    margin: 1,
                    "&:hover": {
                      outline: "none",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={handleDrawerClick}
                >
                  {open ? <ChevronLeftIcon /> : <MenuIcon />}
                  {open && (
                    <Typography
                      variant="body1"
                      sx={{
                        alignSelf: "center",
                        marginLeft: open ? 1 : 0,
                        fontSize: 20,
                        color: "#333333",
                      }}
                    >
                      Ajuda
                    </Typography>
                  )}
                </IconButton>
                {open ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5%",
                      }}
                    ></div>
                    <ListItem key="FAQ" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor: content == "faq" ? "#36B6FF" : null,
                          color: content == "faq" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("faq")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "faq" ? "#fff" : null}
                            icon={faQuestionCircle}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Perguntas Frequentes" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Contacts" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "contacts" ? "#FACF5A" : null,
                          color: content == "contacts" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("contacts")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "contacts" ? "#fff" : null}
                            icon={faPhone}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Contactos" />
                      </ListItemButton>
                    </ListItem>
                  </div>
                ) : null}
              </List>
            }
          </Drawer>
        </Box>

        <Box
          sx={{
            flexGrow: 0,
            display: open && isMobile ? "none" : "flex",
          }}
        >
          <Typography component="div" sx={{ p: 2 }}>
            {getTypographyContent()}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default AjudaProfessor;
