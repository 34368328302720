import Cookies from "js-cookie";
import { makeRequestPost2, makeRequestPut2 } from "../api";

//Get Super comerciais
export function getSuperComerciais(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("supercomercial/all", dados, token);
}


//Post super comercial
export function postSuperComercial(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("supercomercial/", dados, token);
}

//Enviar email -Post super comercial
export function enviarEmailPostSuperComercial(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("supercomercial/active-account", dados, token);
}

//Get por id
export function getSuperComercial(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`supercomercial/id/${id}`, {}, token);
}

//Editar
export function putSuperComercial(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`supercomercial/`, dados, token);
}
