import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box, Stack, width } from "@mui/system";
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Grid, ToggleButtonGroup } from "@mui/material";
import {
  getInscricoesId,
  getUtilizadoresId,
} from "../../api/requests/Utilizadores";
import { getPiscinas } from "../../api/requests/Piscinas";
import { Search } from "../../components/DataTable";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCashRegister,
  faCreditCard,
  faMobile,
  faMoneyBillTransfer,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import ToggleButton from "@mui/material/ToggleButton";
import { Card, CardContent } from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import {
  cancelarPagamentoBalcao,
  cancelarPagamentoPendente,
  aprovarPagamentoBalcao,
  emailCancelarPagamentoBalcao,
  emailCancelarPagamentoMensal,
  emailErroPagamento,
  emailPagamentoMensal,
  gerarPagamentoMensal,
  listaDePagamentos,
  emailSucessoPagamento2,
  emailErroPagamento2,
} from "../../api/requests/Pagamentos";
import { toast } from "react-toastify";
import mb from "../../images/multibanco.png";
import mbwayfoto from "../../images/mbway.png";
import balcao from "../../images/balcao.png";
import cartaocredito from "../../images/cartaocredito.png";
import PhoneInput from "react-phone-number-input";
import PhoneNumber from "../../components/PhoneNumber";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";

function PagamentosClienteA() {
  const location = useLocation();
  const pagamentosEmDia = location.state?.pagamentosEmDia;
  //Traduções
  const { t, i18n } = useTranslation();
  const [freeze, setFreeze] = useState(false);
  const { state } = useLocation();
  const { idCliente, nomeCliente, idPiscina } = state || {};

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [pagamentoMensal, setPagamentoMensal] = useState([]);
  const [pagamentoLocal, setPagamentoLocal] = useState([]);
  const [referencia, setReferencia] = useState("");
  const [entidade, setEntidade] = useState("");
  const [montante, setMontante] = useState("");
  const [dataExpira, setDataExpira] = useState("");
  const [buttonVisibility, setButtonVisibility] = useState(false);
  const [estado, setEstado] = useState(false);
  const [idEscolhida, setIdEscolhida] = useState("");
  const [pagamentos, setPagamentos] = useState([]);
  const [mbway, setMbway] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [erroTelefone, setErroTelefone] = useState(false);
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [mensagemMbway, setMensagemMbway] = useState(false);
  const [janelaPendente, setJanelaPendente] = useState(false);
  const [janelaPago, setJanelaPago] = useState(false);
  const [janelaCancelado, setJanelaCancelado] = useState(false);
  const [janelaFuturo, setJanelaFuturo] = useState(false);
  const [janelaNoLocal, setJanelaNoLocal] = useState(false);
  const [dadosPendente, setDadosPendente] = useState([]);

  function goBack() {
    navigate("/editcustomera", {
      state: {
        idPiscina: idPiscina,
        idCliente: idCliente,
      },
    });
  }

  //Navigator
  const navigate = useNavigate();

  const [selected, setSelected] = useState("1");

  const handleChange = (event, newAlignment) => {
    setSelected(newAlignment);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaDePagamentos({ id_cliente: idCliente }).then((res) => {
      if (res.success) {
        const pag = res.data.data;
        const arrayOrdenado = pag.sort(
          (a, b) => new Date(b.criado) - new Date(a.criado)
        );
        setPagamentos(arrayOrdenado);
      }
    });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {janelaPendente && (
        <Dialog
          open={janelaPendente}
          onClose={() => {
            setJanelaPendente(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "darkorange",
              color: "#fff",
              textAlign: "center",
            }}
          >
            {t("pagamentoPendente")}
          </DialogTitle>

          <DialogContent style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <table
              id="u_body"
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                borderSpacing: 0,
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                verticalAlign: "top",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
              }}
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    style={{
                      wordBreak: "break-word",
                      borderCollapse: "collapse !important",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      className="u-row-container"
                      style={{ padding: 0, backgroundColor: "transparent" }}
                    >
                      <div
                        className="u-row"
                        style={{
                          margin: "0 auto",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            borderCollapse: "collapse",
                            display: "table",

                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div
                            className="u-col u-col-100"
                            style={{
                              display: "table-cell",
                              verticalAlign: "top",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "rgb(245, 245, 245)",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  height: "100%",
                                  padding: 0,
                                  borderTop: "0px solid transparent",
                                  borderLeft: "0px solid transparent",
                                  borderRight: "0px solid transparent",
                                  borderBottom: "0px solid transparent",
                                }}
                              >
                                <table
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding:
                                            isMobile ? 10 : 40,
                                        }}
                                        align="left"
                                      >
                                        <div
                                          style={{
                                            color: "#161a39",
                                            lineHeight: "150%",
                                            textAlign: "center",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              borderRadius: 10,
                                              overflow: "hidden",
                                              padding: 0,
                                            }}
                                          >
                                            <h2
                                              style={{
                                                color: "#2d2d2d",
                                                fontSize:
                                                  isMobile
                                                    ? 16
                                                    : 20,
                                                marginBottom: 20,
                                              }}
                                            >
                                              {t("detalhesCompra")}
                                            </h2>
                                            {dadosPendente.linhas.map(
                                              (linha) => {
                                                return (
                                                  <div
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      padding: 20,
                                                      borderRadius: 10,
                                                      marginBottom: 20,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                          width: "50%",
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        {linha.nomeProduto}
                                                      </h3>
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                              
                                                          color: "#36B6FF",
                                                          fontWeight: 600,
                                                          width: "50%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {linha.precoProduto.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )}
                                                      </h3>
                                                    </div>
                                                    <hr
                                                      style={{
                                                        border: "none",
                                                        borderTop:
                                                          "1px solid #d4d4d4",
                                                        margin: "20px 0",
                                                      }}
                                                    />
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc1}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc2}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {t("quantidade")}: 1
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}

                                            <div
                                              style={{
                                                backgroundColor: "#fff",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginTop: 20,
                                              }}
                                            >
                                              {dadosPendente.desconto != 0 && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        width: "50%",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {t("desconto")}:{" "}
                                                      {dadosPendente.desconto}%
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        color: "#36B6FF",
                                                        fontWeight: 600,
                                                        width: "50%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      dadosPendente.desc_desconto
                                                    }
                                                  </p>
                                                </>
                                              )}

                                              <hr
                                                style={{
                                                  border: "none",
                                                  borderTop:
                                                    "1px solid #d4d4d4",
                                                  margin: "20px 0",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  backgroundColor: "#fff",
                                                  padding: 20,
                                                  borderRadius: 10,
                                                  marginTop: 20,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      width: "50%",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <strong>
                                                      {t("total")}:
                                                    </strong>
                                                  </h3>
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                      isMobile
                                                        ? 14
                                                        : 18,
                                                    marginBottom:
                                                      isMobile
                                                        ? 5
                                                        : 10,
                                                      fontWeight: 600,
                                                      width: "50%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {dadosPendente.total.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: "center",
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,

                                              textAlign: "center",
                                            }}
                                          >
                                            {t("moradaFaturacao")}
                                          </h2>
                                          <p
                                            style={{
                                              fontSize:
                                              isMobile
                                                ? 12
                                                : 14,
                                            marginBottom:
                                              isMobile
                                                ? 5
                                                : 10,
                                              marginTop: 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nome}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.morada}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nif}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.codigo_postal}
                                          </p>
                                          {/* Caso sejam os dados do filho não aparece email */}
                                          {dadosPendente.email && (
                                            <p
                                              style={{
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                textAlign: "start",
                                              }}
                                            >
                                              {dadosPendente.email}
                                            </p>
                                          )}
                                        </div>

                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                              isMobile
                                                ? 14
                                                : 18,
                                            marginBottom:
                                              isMobile
                                                ? 5
                                                : 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("metodoPagamento")}
                                          </h2>
                                          {dadosPendente.id_metodo_pagamento ==
                                            2 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mbwayfoto}
                                                alt="MB Way"
                                                style={{
                                                  width: 120,
                                                  marginBottom: 20,
                                                }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            3 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                src={cartaocredito}
                                                alt="Cartão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize: 14,
                                                  marginBottom: 20,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            1 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mb}
                                                alt="Multibanco"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            4 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={balcao}
                                                alt="Balcão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  id="u_content_button_1"
                                  style={{ fontFamily: '"Lato",sans-serif' }}
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  width="100%"
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding: 10,
                                          fontFamily: '"Lato",sans-serif',
                                        }}
                                        align="left"
                                      >
                                        <div align="center">
                                          <a
                                            target="_blank"
                                            className="v-button"
                                            style={{
                                              boxSizing: "border-box",
                                              display: "inline-block",
                                              fontFamily: '"Lato",sans-serif',
                                              textDecoration: "none",
                                              WebkitTextSizeAdjust: "none",
                                              textAlign: "center",
                                              color: "#FFFFFF",
                                              backgroundColor: Colors.inativo,
                                              borderRadius: 40,
                                              WebkitBorderRadius: 40,
                                              MozBorderRadius: 40,
                                              width: "auto",
                                              maxWidth: "100%",
                                              overflowWrap: "break-word",
                                              wordBreak: "break-word",
                                              wordWrap: "break-word",
                                              msoBorderAlt: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setFreeze(true);
                                              // Cancelar pagamento pendente
                                              cancelarPagamentoPendente({
                                                id_cliente: idCliente,
                                                id_pagamento:
                                                  dadosPendente.id_pagamento,
                                              }).then((res) => {
                                                if (res.success) {
                                                  setTimeout(() => {
                                                    emailErroPagamento2({
                                                      id_pagamento:
                                                        dadosPendente.id_pagamento,
                                                    }).then((res2) => {
                                                      if (res2.success) {
                                                        navigate(
                                                          "/editcustomerf",
                                                          {
                                                            state: {
                                                              idPiscina:
                                                                idPiscina,
                                                              idCliente:
                                                                idCliente,
                                                            },
                                                          }
                                                        );
                                                        setFreeze(false);

                                                        toast.success(
                                                          t(
                                                            "pagamentoCancelado"
                                                          )
                                                        );
                                                      } else {
                                                        setFreeze(false);

                                                        toast.error(
                                                          t(
                                                            "erroCancelarPagamento"
                                                          )
                                                        );
                                                      }
                                                    });
                                                  }, 3000);
                                                  setFreeze(false)
                                                } else {
                                                  //Erro
                                                  setFreeze(false)
                                                }
                                              });
                                            }}
                                          >
                                            <span
                                              className="v-padding"
                                              style={{
                                                display: "block",
                                                padding: "13px 40px",
                                                lineHeight: "120%",
                                              }}
                                            >
                                              <strong>
                                                <span
                                                  style={{
                                                    fontSize:
                                                    isMobile
                                                      ? 12
                                                      : 16,
                                                  

                                                    fontFamily:
                                                      "Lato, sans-serif",
                                                  }}
                                                >
                                                  {t("cancelarPagamento")}
                                                </span>
                                              </strong>
                                            </span>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  id="u_content_button_1"
                                  style={{ fontFamily: '"Lato",sans-serif' }}
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  width="100%"
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding: 10,
                                          fontFamily: '"Lato",sans-serif',
                                        }}
                                        align="left"
                                      >
                                        <div align="center">
                                          <a
                                            target="_blank"
                                            className="v-button"
                                            style={{
                                              boxSizing: "border-box",
                                              display: "inline-block",
                                              fontFamily: '"Lato",sans-serif',
                                              textDecoration: "none",
                                              WebkitTextSizeAdjust: "none",
                                              textAlign: "center",
                                              color: "#FFFFFF",
                                              backgroundColor: Colors.ativo,
                                              borderRadius: 40,
                                              WebkitBorderRadius: 40,
                                              MozBorderRadius: 40,
                                              width: 250,
                                              maxWidth: "100%",
                                              overflowWrap: "break-word",
                                              wordBreak: "break-word",
                                              wordWrap: "break-word",
                                              msoBorderAlt: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setFreeze(true);
                                              // Cancelar pagamento pendente
                                              aprovarPagamentoBalcao({
                                                id_pagamento:
                                                  dadosPendente.id_pagamento,
                                              }).then((res) => {
                                                if (res.success) {
                                                  setTimeout(() => {
                                                    emailSucessoPagamento2({
                                                      id_pagamento:
                                                        dadosPendente.id_pagamento,
                                                    }).then((res2) => {
                                                      if (res2.success) {
                                                        navigate(
                                                          "/editcustomerf",
                                                          {
                                                            state: {
                                                              idPiscina:
                                                                idPiscina,
                                                              idCliente:
                                                                idCliente,
                                                            },
                                                          }
                                                        );
                                                        setFreeze(false);
                                                        toast.success(
                                                          t("pagamentoAprovado")
                                                        );
                                                      } else {
                                                        setFreeze(false);
                                                        toast.success(
                                                          t("pagamentoAprovado")
                                                        );
                                                      }
                                                    });
                                                  }, 3000);
                                                  setFreeze(false)
                                                } else {
                                                  setFreeze(false)
                                                  toast.error(
                                                    t("pagamentoErroAprovado")
                                                  );
                                                }
                                              });
                                            }}
                                          >
                                            <span
                                              className="v-padding"
                                              style={{
                                                display: "block",
                                                padding: "13px 40px",
                                                lineHeight: "120%",
                                              }}
                                            >
                                              <strong>
                                                <span
                                                  style={{
                                                    fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                         
                                                    lineHeight: "16.8px",
                                                    fontFamily:
                                                      "Lato, sans-serif",
                                                  }}
                                                >
                                                  {t("PagamentoRecebido")}
                                                </span>
                                              </strong>
                                            </span>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      )}

      {janelaPago && (
        <Dialog
          open={janelaPago}
          onClose={() => {
            setJanelaPago(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: Colors.ativo,
              color: "#fff",
              textAlign: "center",
            }}
          >
            {t("pagamentoConcluido")}
          </DialogTitle>

          <DialogContent style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <table
              id="u_body"
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                borderSpacing: 0,
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                verticalAlign: "top",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
              }}
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    style={{
                      wordBreak: "break-word",
                      borderCollapse: "collapse !important",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      className="u-row-container"
                      style={{ padding: 0, backgroundColor: "transparent" }}
                    >
                      <div
                        className="u-row"
                        style={{
                          margin: "0 auto",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            borderCollapse: "collapse",
                            display: "table",

                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div
                            className="u-col u-col-100"
                            style={{
                              display: "table-cell",
                              verticalAlign: "top",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "rgb(245, 245, 245)",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  height: "100%",
                                  padding: 0,
                                  borderTop: "0px solid transparent",
                                  borderLeft: "0px solid transparent",
                                  borderRight: "0px solid transparent",
                                  borderBottom: "0px solid transparent",
                                }}
                              >
                                <table
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding:
                                            isMobile ? 10 : 40,
                                        }}
                                        align="left"
                                      >
                                        <div
                                          style={{
                                            color: "#161a39",
                                            lineHeight: "150%",
                                            textAlign: "center",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              borderRadius: 10,
                                              overflow: "hidden",
                                              padding: 0,
                                            }}
                                          >
                                            <h2
                                              style={{
                                                color: "#2d2d2d",
                                                fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                       
                                                marginBottom: 20,
                                              }}
                                            >
                                              {t("detalhesCompra")}
                                            </h2>

                                            {dadosPendente.linhas.map(
                                              (linha) => {
                                                return (
                                                  <div
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      padding: 20,
                                                      borderRadius: 10,
                                                      marginBottom: 20,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                          width: "50%",
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        {linha.nomeProduto}
                                                      </h3>
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                          color: "#36B6FF",
                                                          fontWeight: 600,
                                                          width: "50%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {linha.precoProduto.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )}
                                                      </h3>
                                                    </div>
                                                    <hr
                                                      style={{
                                                        border: "none",
                                                        borderTop:
                                                          "1px solid #d4d4d4",
                                                        margin: "20px 0",
                                                      }}
                                                    />
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc1}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc2}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {t("quantidade")}: 1
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}

                                            <div
                                              style={{
                                                backgroundColor: "#fff",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginTop: 20,
                                              }}
                                            >
                                              {dadosPendente.desconto != 0 && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        width: "50%",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {t("desconto")}:{" "}
                                                      {dadosPendente.desconto}%
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                        isMobile
                                                          ? 12
                                                          : 14,
                                                      marginBottom:
                                                        isMobile
                                                          ? 5
                                                          : 10,
                                                        color: "#36B6FF",
                                                        fontWeight: 600,
                                                        width: "50%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      dadosPendente.desc_desconto
                                                    }
                                                  </p>
                                                </>
                                              )}

                                              <hr
                                                style={{
                                                  border: "none",
                                                  borderTop:
                                                    "1px solid #d4d4d4",
                                                  margin: "20px 0",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  backgroundColor: "#fff",
                                                  padding: 20,
                                                  borderRadius: 10,
                                                  marginTop: 20,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                      isMobile
                                                        ? 14
                                                        : 16,
                                                    marginBottom:
                                                      isMobile
                                                        ? 5
                                                        : 10,
                                                      width: "50%",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <strong>
                                                      {t("total")}:
                                                    </strong>
                                                  </h3>
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      fontWeight: 600,
                                                      width: "50%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {dadosPendente.total.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: "center",
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("moradaFaturacao")}
                                          </h2>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nome}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.morada}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nif}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.codigo_postal}
                                          </p>
                                          {/* Caso sejam os dados do filho não aparece email */}
                                          {dadosPendente.email && (
                                            <p
                                              style={{
                                                fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                textAlign: "start",
                                              }}
                                            >
                                              {dadosPendente.email}
                                            </p>
                                          )}
                                        </div>

                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("metodoPagamento")}
                                          </h2>
                                          {dadosPendente.id_metodo_pagamento ==
                                            2 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mbwayfoto}
                                                alt="MB Way"
                                                style={{
                                                  width: 120,
                                                  marginBottom: 20,
                                                }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataPago")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            3 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                src={cartaocredito}
                                                alt="Cartão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataPago")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            1 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mb}
                                                alt="Multibanco"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataPago")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            4 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={balcao}
                                                alt="Balcão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataPago")}{" "}
                                                {new Date(element.data_expira)
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  id="u_content_button_1"
                                  style={{ fontFamily: '"Lato",sans-serif' }}
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  width="100%"
                                  border={0}
                                ></table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      )}

      {janelaCancelado && (
        <Dialog
          open={janelaCancelado}
          onClose={() => {
            setJanelaCancelado(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: Colors.inativo,
              color: "#fff",
              textAlign: "center",
            }}
          >
            {t("pagamentoCancelado2")}
          </DialogTitle>

          <DialogContent style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <table
              id="u_body"
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                borderSpacing: 0,
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                verticalAlign: "top",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
              }}
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    style={{
                      wordBreak: "break-word",
                      borderCollapse: "collapse !important",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      className="u-row-container"
                      style={{ padding: 0, backgroundColor: "transparent" }}
                    >
                      <div
                        className="u-row"
                        style={{
                          margin: "0 auto",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            borderCollapse: "collapse",
                            display: "table",

                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div
                            className="u-col u-col-100"
                            style={{
                              display: "table-cell",
                              verticalAlign: "top",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "rgb(245, 245, 245)",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  height: "100%",
                                  padding: 0,
                                  borderTop: "0px solid transparent",
                                  borderLeft: "0px solid transparent",
                                  borderRight: "0px solid transparent",
                                  borderBottom: "0px solid transparent",
                                }}
                              >
                                <table
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding:
                                            isMobile ? 10 : 40,
                                        }}
                                        align="left"
                                      >
                                        <div
                                          style={{
                                            color: "#161a39",
                                            lineHeight: "150%",
                                            textAlign: "center",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              borderRadius: 10,
                                              overflow: "hidden",
                                              padding: 0,
                                            }}
                                          >
                                            <h2
                                              style={{
                                                color: "#2d2d2d",
                                                fontSize:
                                                  ismobile
                                                    ? 16
                                                    : 18,
                                                marginBottom: 20,
                                              }}
                                            >
                                              {t("detalhesCompra")}
                                            </h2>
                                            {dadosPendente.linhas.map(
                                              (linha) => {
                                                return (
                                                  <div
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      padding: 20,
                                                      borderRadius: 10,
                                                      marginBottom: 20,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                          width: "50%",
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        {linha.nomeProduto}
                                                      </h3>
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                          isMobile
                                                            ? 14
                                                            : 16,
                                                        marginBottom:
                                                          isMobile
                                                            ? 5
                                                            : 10,
                                                          color: "#36B6FF",
                                                          fontWeight: 600,
                                                          width: "50%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {linha.precoProduto.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )}
                                                      </h3>
                                                    </div>
                                                    <hr
                                                      style={{
                                                        border: "none",
                                                        borderTop:
                                                          "1px solid #d4d4d4",
                                                        margin: "20px 0",
                                                      }}
                                                    />
                                                    <p
                                                      style={{
                                                        fontSize:
                                                        isMobile
                                                          ? 12
                                                          : 14,
                                                      marginBottom:
                                                        isMobile
                                                          ? 5
                                                          : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc1}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc2}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {t("quantidade")}: 1
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}

                                            <div
                                              style={{
                                                backgroundColor: "#fff",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginTop: 20,
                                              }}
                                            >
                                              {dadosPendente.desconto != 0 && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        width: "50%",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {t("desconto")}:{" "}
                                                      {dadosPendente.desconto}%
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        color: "#36B6FF",
                                                        fontWeight: 600,
                                                        width: "50%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      dadosPendente.desc_desconto
                                                    }
                                                  </p>
                                                </>
                                              )}

                                              <hr
                                                style={{
                                                  border: "none",
                                                  borderTop:
                                                    "1px solid #d4d4d4",
                                                  margin: "20px 0",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  backgroundColor: "#fff",
                                                  padding: 20,
                                                  borderRadius: 10,
                                                  marginTop: 20,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      width: "50%",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <strong>
                                                      {t("total")}:
                                                    </strong>
                                                  </h3>
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      fontWeight: 600,
                                                      width: "50%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {dadosPendente.total.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: "center",
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                     
                                              marginBottom: 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("moradaFaturacao")}
                                          </h2>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                             
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nome}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                              isMobile
                                                ? 12
                                                : 14,
                                            marginBottom:
                                              isMobile
                                                ? 5
                                                : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.morada}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nif}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.codigo_postal}
                                          </p>
                                          {/* Caso sejam os dados do filho não aparece email */}
                                          {dadosPendente.email && (
                                            <p
                                              style={{
                                                fontSize:
                                                isMobile
                                                  ? 12
                                                  : 14,
                                              marginBottom:
                                                isMobile
                                                  ? 5
                                                  : 10,
                                                textAlign: "start",
                                              }}
                                            >
                                              {dadosPendente.email}
                                            </p>
                                          )}
                                        </div>

                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("metodoPagamento")}
                                          </h2>
                                          {dadosPendente.id_metodo_pagamento ==
                                            2 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mbwayfoto}
                                                alt="MB Way"
                                                style={{
                                                  width: 120,
                                                  marginBottom: 20,
                                                }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            3 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                src={cartaocredito}
                                                alt="Cartão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize: 14,
                                                  marginBottom: 20,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            1 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mb}
                                                alt="Multibanco"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                  isMobile
                                                    ? 12
                                                    : 14,
                                                marginBottom:
                                                  isMobile
                                                    ? 5
                                                    : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            4 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={balcao}
                                                alt="Balcão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                  isMobile
                                                    ? 12
                                                    : 14,
                                                marginBottom:
                                                  isMobile
                                                    ? 5
                                                    : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      )}

      {janelaFuturo && (
        <Dialog
          open={janelaFuturo}
          onClose={() => {
            setJanelaFuturo(false);
            if (!estado) {
              setOpen(false);
              setButtonVisibility(false);
              setReferencia("");
              setMontante("");
              setEntidade("");
              setDataExpira("");
              setMbway(false);
              setMensagemMbway(false);
              setTelefone(false);
            }
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#4169e0",
              color: "#fff",
              textAlign: "center",
            }}
          >
            {t("pagamentoFuturo")}
          </DialogTitle>

          <DialogContent style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <table
              id="u_body"
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                borderSpacing: 0,
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                verticalAlign: "top",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
              }}
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    style={{
                      wordBreak: "break-word",
                      borderCollapse: "collapse !important",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      className="u-row-container"
                      style={{ padding: 0, backgroundColor: "transparent" }}
                    >
                      <div
                        className="u-row"
                        style={{
                          margin: "0 auto",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            borderCollapse: "collapse",
                            display: "table",

                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div
                            className="u-col u-col-100"
                            style={{
                              display: "table-cell",
                              verticalAlign: "top",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "rgb(245, 245, 245)",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  height: "100%",
                                  padding: 0,
                                  borderTop: "0px solid transparent",
                                  borderLeft: "0px solid transparent",
                                  borderRight: "0px solid transparent",
                                  borderBottom: "0px solid transparent",
                                }}
                              >
                                <table
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding:
                                            isMobile ? 10 : 40,
                                        }}
                                        align="left"
                                      >
                                        <div
                                          style={{
                                            color: "#161a39",
                                            lineHeight: "150%",
                                            textAlign: "center",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              borderRadius: 10,
                                              overflow: "hidden",
                                              padding: 0,
                                            }}
                                          >
                                            <h2
                                              style={{
                                                color: "#2d2d2d",
                                                fontSize:
                                                            isMobile
                                                              ? 16
                                                              : 18,
                                                          
                                                marginBottom: 20,
                                              }}
                                            >
                                              {t("detalhesCompra")}
                                            </h2>
                                            <div
                                              style={{
                                                backgroundColor: "#fff",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginBottom: 20,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <h3
                                                  style={{
                                                    fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                    width: "50%",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {pagamentoMensal.nome_turma}
                                                </h3>
                                                <h3
                                                  style={{
                                                    fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                    color: "#36B6FF",
                                                    fontWeight: 600,
                                                    width: "50%",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  {pagamentoMensal.total.toLocaleString(
                                                    "pt-PT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )}
                                                </h3>
                                              </div>
                                              <hr
                                                style={{
                                                  border: "none",
                                                  borderTop:
                                                    "1px solid #d4d4d4",
                                                  margin: "20px 0",
                                                }}
                                              />
                                              <p
                                                style={{
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "start",
                                                }}
                                              >
                                                {pagamentoMensal.nome_piscina}
                                              </p>

                                              <p
                                                style={{
                                                 
                                                  fontSize:
                                                  isMobile
                                                    ? 12
                                                    : 14,
                                                marginBottom:
                                                  isMobile
                                                    ? 5
                                                    : 10,
                                                  textAlign: "start",
                                                }}
                                              >
                                                {t("quantidade")}: 1
                                              </p>
                                            </div>

                                            <div
                                              style={{
                                                backgroundColor: "#fff",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginTop: 20,
                                              }}
                                            >
                                              {/* {dadosPendente.desconto != 0 && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                          window.innerWidth >
                                                          600
                                                            ? 20
                                                            : 14,
                                                        marginBottom:
                                                          window.innerWidth >
                                                          600
                                                            ? 10
                                                            : 5,
                                                        width: "50%",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {t("desconto")}:{" "}
                                                      {dadosPendente.desconto}%
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                          window.innerWidth >
                                                          600
                                                            ? 20
                                                            : 14,
                                                        marginBottom:
                                                          window.innerWidth >
                                                          600
                                                            ? 10
                                                            : 5,
                                                        color: "#36B6FF",
                                                        fontWeight: 600,
                                                        width: "50%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize:
                                                        window.innerWidth > 600
                                                          ? 16
                                                          : 12,
                                                      marginBottom:
                                                        window.innerWidth > 600
                                                          ? 10
                                                          : 5,
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      dadosPendente.desc_desconto
                                                    }
                                                  </p>
                                                </>
                                              )} */}

                                              <hr
                                                style={{
                                                  border: "none",
                                                  borderTop:
                                                    "1px solid #d4d4d4",
                                                  margin: "20px 0",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  backgroundColor: "#fff",
                                                  padding: 20,
                                                  borderRadius: 10,
                                                  marginTop: 20,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                      isMobile
                                                        ? 14
                                                        : 16,
                                                    marginBottom:
                                                      isMobile
                                                        ? 5
                                                        : 10,
                                                      width: "50%",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <strong>
                                                      {t("total")}:
                                                    </strong>
                                                  </h3>
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                      isMobile
                                                        ? 14
                                                        : 16,
                                                    marginBottom:
                                                      isMobile
                                                        ? 5
                                                        : 10,
                                                      fontWeight: 600,
                                                      width: "50%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {pagamentoMensal.total.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: "center",
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 14,
                                              marginBottom: 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("moradaFaturacao")}
                                          </h2>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.nome}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.morada}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.nif}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                window.innerWidth > 600
                                                  ? 20
                                                  : 12,
                                              marginBottom: 10,
                                            }}
                                          >
                                            {dadosPendente.codigo_postal}
                                          </p>
                                          //  Caso sejam os dados do filho não aparece email *
                                          {dadosPendente.email && (
                                            <p
                                              style={{
                                                fontSize:
                                                  window.innerWidth > 600
                                                    ? 20
                                                    : 12,
                                                marginBottom: 10,
                                              }}
                                            >
                                              {dadosPendente.email}
                                            </p>
                                          )}
                                        </div> */}

                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 30,
                                            marginTop: 20,
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("metodoPagamento")}
                                          </h2>

                                          {referencia &&
                                            entidade &&
                                            montante &&
                                            dataExpira && (
                                              <form className="form2">
                                                <img
                                                  src={mb}
                                                  style={{
                                                    width: "150px",
                                                    height: "150px",
                                                    marginTop: 20,
                                                    marginBottom: 30,
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                />
                                                <div>
                                                  <h2 className="multibanco">
                                                    {t("entidade")}
                                                    <span className="spanMb">
                                                      {entidade}
                                                    </span>
                                                  </h2>
                                                  <h2 className="multibanco">
                                                    {t("referencia")}
                                                    <span className="spanMb">
                                                      {referencia}
                                                    </span>
                                                  </h2>
                                                  <h2 className="multibanco">
                                                    {t("valorapagar")}{" "}
                                                    <span className="spanMb">
                                                      {montante.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </span>
                                                  </h2>
                                                  <h2 className="multibanco">
                                                    {t("dataLimite")}
                                                    {": "}
                                                    <span className="spanMb">
                                                      {dataExpira}
                                                    </span>
                                                  </h2>
                                                </div>
                                              </form>
                                            )}
                                          {mbway && (
                                            <form className="form2">
                                              <img
                                                src={mbwayfoto}
                                                style={{
                                                  width: "200px",
                                                  height: "100px",
                                                  marginBottom: "80px",
                                                  marginTop: 100,
                                                }}
                                              />
                                              <p>
                                                {t("valorapagar")}{" "}
                                                {montante.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </p>
                                              <PhoneInput
                                                defaultCountry="PT"
                                                countries={["PT"]}
                                                labels={
                                                  i18n.language == "pt"
                                                    ? pt
                                                    : i18n.language == "es"
                                                    ? es
                                                    : en
                                                }
                                                value={telefone}
                                                error={erroTelefone}
                                                helperText={
                                                  mensagemErroTelefone
                                                }
                                                onChange={(e) => {
                                                  setTelefone(e);
                                                  setErroTelefone(false);
                                                  setMensagemErroTelefone("");
                                                }}
                                                inputComponent={PhoneNumber}
                                              />
                                              {!buttonVisibility && (
                                                <Stack
                                                  direction={"row"}
                                                  spacing={2}
                                                  sx={{
                                                    marginTop: 10,
                                                    marginRight: 2,
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="regbtn"
                                                    onClick={() => {
                                                      if (
                                                        telefone &&
                                                        !erroTelefone
                                                      ) {
                                                        setEstado(true);
                                                        setButtonVisibility(
                                                          true
                                                        );
                                                        setTimeout(() => {
                                                          gerarPagamentoMensal({
                                                            id_pagamento:
                                                              pagamentoMensal.id_pagamento,
                                                            id_entidade_exploradora:
                                                              pagamentoMensal.id_entidade_exploradora,
                                                            id_inscricao:
                                                              pagamentoMensal.id_inscricao,
                                                            pagamento: "mbway",
                                                            telemovel: telefone,
                                                          }).then((res) => {
                                                            setButtonVisibility(
                                                              false
                                                            );
                                                            setMensagemMbway(
                                                              true
                                                            );
                                                            setMbway(false);
                                                            if (res.success) {
                                                              let dados =
                                                                res.data.data;
                                                              setDataExpira(
                                                                dados.ExpiryDate
                                                              );
                                                              setTimeout(() => {
                                                                emailPagamentoMensal(
                                                                  {
                                                                    id_pagamento:
                                                                      pagamentoMensal.id_pagamento,
                                                                  }
                                                                ).then(
                                                                  (res2) => {
                                                                    setEstado(
                                                                      false
                                                                    );
                                                                    setOpen(
                                                                      false
                                                                    );
                                                                    setButtonVisibility(
                                                                      false
                                                                    );
                                                                    setMbway(
                                                                      false
                                                                    );
                                                                    setMensagemMbway(
                                                                      false
                                                                    );
                                                                    setTelefone(
                                                                      false
                                                                    );
                                                                  }
                                                                );
                                                              }, 3000);
                                                            } else {
                                                              setOpen(false);
                                                              setMbway(false);
                                                              setEstado(false);
                                                              setMensagemMbway(
                                                                false
                                                              );
                                                              setTelefone(
                                                                false
                                                              );
                                                              setButtonVisibility(
                                                                false
                                                              );
                                                              toast.error(
                                                                res.error
                                                              );
                                                            }
                                                          });
                                                        }, 1000);
                                                      }
                                                    }}
                                                  >
                                                    {t("pagar")}
                                                  </button>
                                                </Stack>
                                              )}
                                            </form>
                                          )}
                                          {buttonVisibility &&
                                          !referencia &&
                                          !entidade &&
                                          !montante &&
                                          !dataExpira ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                              }}
                                            >
                                              <CircularProgress
                                                disableShrink
                                                style={{
                                                  marginTop: 20,
                                                  textAlign: "center",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                          {!mbway &&
                                          mensagemMbway &&
                                          !buttonVisibility ? (
                                            <DialogContentText
                                              style={{
                                                paddingBottom: 3,
                                                paddingTop: 10,
                                              }}
                                              id="alert-dialog-description"
                                            >
                                              {t("msgMbway")}
                                            </DialogContentText>
                                          ) : null}
                                          {!buttonVisibility &&
                                          mbway == false &&
                                          !mensagemMbway ? (
                                            <div>
                                              <div className="cardsDivMarcarPagamento2">
                                                <Button
                                                  className="cardFuturo"
                                                  variant="contained"
                                                  style={{
                                                    backgroundColor:
                                                      Colors.cardAtalho1,
                                                  }}
                                                  onClick={() => {
                                                    setEstado(true);
                                                    setButtonVisibility(true);
                                                    setTimeout(() => {
                                                      gerarPagamentoMensal({
                                                        id_pagamento:
                                                          pagamentoMensal.id_pagamento,
                                                        id_entidade_exploradora:
                                                          pagamentoMensal.id_entidade_exploradora,
                                                        id_inscricao:
                                                          pagamentoMensal.id_inscricao,
                                                        pagamento: "cartao",
                                                      }).then((res) => {
                                                        if (res.success) {
                                                          let dados =
                                                            res.data.data;
                                                          setDataExpira(
                                                            dados.ExpiryDate
                                                          );
                                                          setTimeout(() => {
                                                            emailPagamentoMensal(
                                                              {
                                                                id_pagamento:
                                                                  pagamentoMensal.id_pagamento,
                                                              }
                                                            ).then((res2) => {
                                                              setEstado(false);
                                                              window.location.href =
                                                                dados.PaymentUrl;
                                                            });
                                                          }, 3000);
                                                        } else {
                                                          setOpen(false);
                                                          setEstado(false);
                                                          setButtonVisibility(
                                                            false
                                                          );
                                                          toast.error(
                                                            res.error
                                                          );
                                                        }
                                                      });
                                                    }, 1000);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    className="iconCards"
                                                    icon={faCreditCard}
                                                  />
                                                  {t("cartaocredito")}
                                                </Button>

                                                <Button
                                                  className="cardFuturo"
                                                  variant="contained"
                                                  style={{
                                                    backgroundColor:
                                                      Colors.cardAtalho2,
                                                  }}
                                                  onClick={() => {
                                                    setEstado(true);
                                                    setButtonVisibility(true);
                                                    setTimeout(() => {
                                                      gerarPagamentoMensal({
                                                        id_pagamento:
                                                          pagamentoMensal.id_pagamento,
                                                        id_entidade_exploradora:
                                                          pagamentoMensal.id_entidade_exploradora,
                                                        id_inscricao:
                                                          pagamentoMensal.id_inscricao,
                                                        pagamento: "mb",
                                                      }).then((res) => {
                                                        if (res.success) {
                                                          let dados =
                                                            res.data.data;
                                                          setEntidade(
                                                            dados.Entity
                                                          );
                                                          setReferencia(
                                                            dados.Reference
                                                          );
                                                          setMontante(
                                                            dados.Amount
                                                          );
                                                          setDataExpira(
                                                            dados.ExpiryDate
                                                          );
                                                          setTimeout(() => {
                                                            emailPagamentoMensal(
                                                              {
                                                                id_pagamento:
                                                                  pagamentoMensal.id_pagamento,
                                                              }
                                                            ).then((res2) => {
                                                              setEstado(false);
                                                            });
                                                          }, 3000);
                                                        } else {
                                                          setOpen(false);
                                                          setEstado(false);
                                                          setButtonVisibility(
                                                            false
                                                          );
                                                          toast.error(
                                                            res.error
                                                          );
                                                        }
                                                      });
                                                    }, 1000);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    className="iconCards"
                                                    icon={faMoneyBillTransfer}
                                                  />
                                                  {t("multibanco")}
                                                </Button>
                                              </div>
                                              <div className="cardsDivMarcarPagamento2">
                                                <Button
                                                  className="cardFuturo"
                                                  variant="contained"
                                                  style={{
                                                    backgroundColor: "salmon",
                                                  }}
                                                  onClick={() => {
                                                    setMbway(true);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    className="iconCards"
                                                    icon={faMobile}
                                                  />
                                                  {t("mbway")}
                                                </Button>
                                                <Button
                                                  className="cardFuturo"
                                                  variant="contained"
                                                  style={{
                                                    backgroundColor: "khaki",
                                                  }}
                                                  onClick={() => {
                                                    setEstado(true);
                                                    setButtonVisibility(true);
                                                    setTimeout(() => {
                                                      gerarPagamentoMensal({
                                                        id_pagamento:
                                                          pagamentoMensal.id_pagamento,
                                                        id_entidade_exploradora:
                                                          pagamentoMensal.id_entidade_exploradora,
                                                        id_inscricao:
                                                          pagamentoMensal.id_inscricao,
                                                        pagamento: "balcao",
                                                      }).then((res) => {
                                                        if (res.success) {
                                                          let dados =
                                                            res.data.data;
                                                          setTimeout(() => {
                                                            emailPagamentoMensal(
                                                              {
                                                                id_pagamento:
                                                                  pagamentoMensal.id_pagamento,
                                                              }
                                                            ).then((res2) => {
                                                              setOpen(false);
                                                              setEstado(false);
                                                              setButtonVisibility(
                                                                false
                                                              );
                                                              toast.success(
                                                                t("balcaoPag")
                                                              );
                                                            });
                                                          }, 3000);
                                                        } else {
                                                          setOpen(false);
                                                          setEstado(false);
                                                          setButtonVisibility(
                                                            false
                                                          );
                                                          toast.error(
                                                            res.error
                                                          );
                                                        }
                                                      });
                                                    }, 1000);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    className="iconCards"
                                                    icon={faCashRegister}
                                                  />
                                                  {t("pagaraobalcao")}
                                                </Button>
                                              </div>
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                  isMobile
                                                    ? 12
                                                    : 14,
                                                marginBottom:
                                                  isMobile
                                                    ? 5
                                                    : 10,
                                                  marginTop:
                                                    isMobile
                                                      ? 5
                                                      : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  pagamentoMensal.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      )}

      {janelaNoLocal && (
        <Dialog
          open={janelaNoLocal}
          onClose={() => {
            setJanelaNoLocal(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: Colors.yellow,
              color: "#fff",
              textAlign: "center",
            }}
          >
            {t("pagamentoNoLocal")}
          </DialogTitle>

          <DialogContent style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <table
              id="u_body"
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                borderSpacing: 0,
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                verticalAlign: "top",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
              }}
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    style={{
                      wordBreak: "break-word",
                      borderCollapse: "collapse !important",
                      verticalAlign: "top",
                    }}
                  >
                    <div
                      className="u-row-container"
                      style={{ padding: 0, backgroundColor: "transparent" }}
                    >
                      <div
                        className="u-row"
                        style={{
                          margin: "0 auto",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            borderCollapse: "collapse",
                            display: "table",

                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div
                            className="u-col u-col-100"
                            style={{
                              display: "table-cell",
                              verticalAlign: "top",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "rgb(245, 245, 245)",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  height: "100%",
                                  padding: 0,
                                  borderTop: "0px solid transparent",
                                  borderLeft: "0px solid transparent",
                                  borderRight: "0px solid transparent",
                                  borderBottom: "0px solid transparent",
                                }}
                              >
                                <table
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding:
                                            isMobile ? 10 : 40,
                                        }}
                                        align="left"
                                      >
                                        <div
                                          style={{
                                            color: "#161a39",
                                            lineHeight: "150%",
                                            textAlign: "center",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              borderRadius: 10,
                                              overflow: "hidden",
                                              padding: 0,
                                            }}
                                          >
                                            <h2
                                              style={{
                                                color: "#2d2d2d",
                                                fontSize:
                                                            isMobile
                                                              ? 16
                                                              : 18,
                                                          marginBottom:
                                                            isMobile
                                                              ? 10
                                                              : 20,
                                               
                                              }}
                                            >
                                              {t("detalhesCompra")}
                                            </h2>
                                            {dadosPendente.linhas.map(
                                              (linha) => {
                                                return (
                                                  <div
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      padding: 20,
                                                      borderRadius: 10,
                                                      marginBottom: 20,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                          width: "50%",
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        {linha.nomeProduto}
                                                      </h3>
                                                      <h3
                                                        style={{
                                                          fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                          color: "#36B6FF",
                                                          fontWeight: 600,
                                                          width: "50%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {linha.precoProduto.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )}
                                                      </h3>
                                                    </div>
                                                    <hr
                                                      style={{
                                                        border: "none",
                                                        borderTop:
                                                          "1px solid #d4d4d4",
                                                        margin: "20px 0",
                                                      }}
                                                    />
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc1}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {linha.desc2}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        textAlign: "start",
                                                      }}
                                                    >
                                                      {t("quantidade")}: 1
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}

                                            <div
                                              style={{
                                                backgroundColor: "#fff",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginTop: 20,
                                              }}
                                            >
                                              {dadosPendente.desconto != 0 && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        width: "50%",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {t("desconto")}:{" "}
                                                      {dadosPendente.desconto}%
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                        color: "#36B6FF",
                                                        fontWeight: 600,
                                                        width: "50%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      -{" "}
                                                      {dadosPendente.valor_desconto.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize:
                                                      isMobile
                                                        ? 12
                                                        : 14,
                                                    marginBottom:
                                                      isMobile
                                                        ? 5
                                                        : 10,
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      dadosPendente.desc_desconto
                                                    }
                                                  </p>
                                                </>
                                              )}

                                              <hr
                                                style={{
                                                  border: "none",
                                                  borderTop:
                                                    "1px solid #d4d4d4",
                                                  margin: "20px 0",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  backgroundColor: "#fff",
                                                  padding: 20,
                                                  borderRadius: 10,
                                                  marginTop: 20,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      width: "50%",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <strong>
                                                      {t("total")}:
                                                    </strong>
                                                  </h3>
                                                  <h3
                                                    style={{
                                                      fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                      fontWeight: 600,
                                                      width: "50%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {dadosPendente.total.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: "center",
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("moradaFaturacao")}
                                          </h2>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                             
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nome}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.morada}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.nif}
                                          </p>
                                          <p
                                            style={{
                                              fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "start",
                                            }}
                                          >
                                            {dadosPendente.codigo_postal}
                                          </p>
                                          {/* Caso sejam os dados do filho não aparece email */}
                                          {dadosPendente.email && (
                                            <p
                                              style={{
                                                fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                textAlign: "start",
                                              }}
                                            >
                                              {dadosPendente.email}
                                            </p>
                                          )}
                                        </div>

                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            padding: 20,
                                            marginTop: 20,
                                          }}
                                        >
                                          <h2
                                            style={{
                                              color: "#2d2d2d",
                                              fontSize:
                                                            isMobile
                                                              ? 14
                                                              : 16,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                              textAlign: "center",
                                            }}
                                          >
                                            {t("metodoPagamento")}
                                          </h2>
                                          {dadosPendente.id_metodo_pagamento ==
                                            2 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mbwayfoto}
                                                alt="MB Way"
                                                style={{
                                                  width: 120,
                                                  marginBottom: 20,
                                                }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                          marginBottom:
                                                            isMobile
                                                              ? 5
                                                              : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleDateString("pt-PT")
                                                  .split("/")
                                                  .reverse()
                                                  .join("/")
                                                  .split("/")
                                                  .reverse()
                                                  .join("/")}
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            3 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                src={cartaocredito}
                                                alt="Cartão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                         
                                                  marginBottom: 20,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            1 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={mb}
                                                alt="Multibanco"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                  isMobile
                                                    ? 12
                                                    : 14,
                                                marginBottom:
                                                  isMobile
                                                    ? 5
                                                    : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}

                                          {dadosPendente.id_metodo_pagamento ==
                                            4 && (
                                            <div
                                              style={{
                                                backgroundColor: "#ffff",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                padding: 20,
                                                marginTop: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <img
                                                src={balcao}
                                                alt="Balcão"
                                                style={{ width: 120 }}
                                              />
                                              <p
                                                style={{
                                                  color: "#2d2d2d",
                                                  fontSize:
                                                  isMobile
                                                    ? 12
                                                    : 14,
                                                marginBottom:
                                                  isMobile
                                                    ? 5
                                                    : 10,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("dataLimite")}{" "}
                                                {new Date(
                                                  dadosPendente.data_expira
                                                )
                                                  .toLocaleString("pt-BR", {
                                                    timeZone: "UTC",
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })
                                                  .replace(
                                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                                    "$1/$2/$3 $4"
                                                  )}
                                                h
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  id="u_content_button_1"
                                  style={{ fontFamily: '"Lato",sans-serif' }}
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  width="100%"
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding: 10,
                                          fontFamily: '"Lato",sans-serif',
                                        }}
                                        align="left"
                                      >
                                        <div align="center">
                                          <a
                                            target="_blank"
                                            className="v-button"
                                            style={{
                                              boxSizing: "border-box",
                                              display: "inline-block",
                                              fontFamily: '"Lato",sans-serif',
                                              textDecoration: "none",
                                              WebkitTextSizeAdjust: "none",
                                              textAlign: "center",
                                              color: "#FFFFFF",
                                              backgroundColor: Colors.inativo,
                                              borderRadius: 40,
                                              WebkitBorderRadius: 40,
                                              MozBorderRadius: 40,
                                              width: 250,
                                              maxWidth: "100%",
                                              overflowWrap: "break-word",
                                              wordBreak: "break-word",
                                              wordWrap: "break-word",
                                              msoBorderAlt: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setFreeze(true);
                                              // Cancelar pagamento pendente
                                              cancelarPagamentoBalcao({
                                                id_cliente: idCliente,
                                                id_pagamento:
                                                  dadosPendente.id_pagamento,
                                              }).then((res) => {
                                                if (res.success) {
                                                  setTimeout(() => {
                                                    emailErroPagamento2({
                                                      id_pagamento:
                                                        dadosPendente.id_pagamento,
                                                    }).then((res2) => {
                                                      if (res2.success) {
                                                        navigate(
                                                          "/editcustomerf",
                                                          {
                                                            state: {
                                                              idPiscina:
                                                                idPiscina,
                                                              idCliente:
                                                                idCliente,
                                                            },
                                                          }
                                                        );
                                                        setFreeze(false);

                                                        toast.success(
                                                          t(
                                                            "pagamentoCancelado"
                                                          )
                                                        );
                                                      } else {
                                                        setFreeze(false);

                                                        toast.error(
                                                          t(
                                                            "erroCancelarPagamento"
                                                          )
                                                        );
                                                      }
                                                    });
                                                  }, 3000);
                                                  setFreeze(false)
                                                } else {
                                                  //Erro
                                                  setFreeze(false)
                                                }
                                              });
                                            }}
                                          >
                                            <span
                                              className="v-padding"
                                              style={{
                                                display: "block",
                                                padding: "13px 40px",
                                                lineHeight: "120%",
                                              }}
                                            >
                                              <strong>
                                                <span
                                                  style={{
                                                    fontSize:
                                                            isMobile
                                                              ? 12
                                                              : 14,
                                                         
                                                    lineHeight: "16.8px",
                                                    fontFamily:
                                                      "Lato, sans-serif",
                                                  }}
                                                >
                                                  {t("cancelarPagamento")}
                                                </span>
                                              </strong>
                                            </span>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  id="u_content_button_1"
                                  style={{ fontFamily: '"Lato",sans-serif' }}
                                  role="presentation"
                                  cellPadding={0}
                                  cellSpacing={0}
                                  width="100%"
                                  border={0}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                          padding: 10,
                                          fontFamily: '"Lato",sans-serif',
                                        }}
                                        align="left"
                                      >
                                        <div align="center">
                                          <a
                                            target="_blank"
                                            className="v-button"
                                            style={{
                                              boxSizing: "border-box",
                                              display: "inline-block",
                                              fontFamily: '"Lato",sans-serif',
                                              textDecoration: "none",
                                              WebkitTextSizeAdjust: "none",
                                              textAlign: "center",
                                              color: "#FFFFFF",
                                              backgroundColor: Colors.ativo,
                                              borderRadius: 40,
                                              WebkitBorderRadius: 40,
                                              MozBorderRadius: 40,
                                              width: 250,
                                              maxWidth: "100%",
                                              overflowWrap: "break-word",
                                              wordBreak: "break-word",
                                              wordWrap: "break-word",
                                              msoBorderAlt: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setFreeze(true);
                                              // Cancelar pagamento pendente
                                              aprovarPagamentoBalcao({
                                                id_pagamento:
                                                  dadosPendente.id_pagamento,
                                              }).then((res) => {
                                                if (res.success) {
                                                  setTimeout(() => {
                                                    emailSucessoPagamento2({
                                                      id_pagamento:
                                                        dadosPendente.id_pagamento,
                                                    }).then((res2) => {
                                                      if (res2.success) {
                                                        navigate(
                                                          "/editcustomerf",
                                                          {
                                                            state: {
                                                              idPiscina:
                                                                idPiscina,
                                                              idCliente:
                                                                idCliente,
                                                            },
                                                          }
                                                        );
                                                        setFreeze(false);
                                                        toast.success(
                                                          t("pagamentoAprovado")
                                                        );
                                                      } else {
                                                        setFreeze(false);
                                                        toast.success(
                                                          t("pagamentoAprovado")
                                                        );
                                                      }
                                                    });
                                                  }, 3000);
                                                  setFreeze(false)
                                                } else {
                                                  setFreeze(false)
                                                  toast.error(
                                                    t("pagamentoErroAprovado")
                                                  );
                                                }
                                              });
                                            }}
                                          >
                                            <span
                                              className="v-padding"
                                              style={{
                                                display: "block",
                                                padding: "13px 40px",
                                                lineHeight: "120%",
                                              }}
                                            >
                                              <strong>
                                                <span
                                                  style={{
                                                    fontSize:
                                                    isMobile
                                                      ? 12
                                                      : 14,
                                                 
                                                    lineHeight: "16.8px",
                                                    fontFamily:
                                                      "Lato, sans-serif",
                                                  }}
                                                >
                                                  {t("PagamentoRecebido")}
                                                </span>
                                              </strong>
                                            </span>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      )}

      <div className="bodyPage3">
        <Header />
        <div
          className="topleftcliente"
          style={{ paddingTop: pagamentosEmDia == true ? "5px" : null }}
        >
          {freeze && (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <Dialog
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  textAlign: "center",
                }}
              >
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("aguarde")}
                  </Typography>
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </DialogContent>
              </Dialog>
            </div>
          )}
          <IconButton
            onClick={goBack}
            size={isMobile ? "small" : "medium"}
          >
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div>
        <div
          className="divTabelaMarcar"
          style={{
            width: isMobile ? "80%" : "60%",
            height: "100%",
            paddingTop: pagamentosEmDia == true ? "30px" : null,
          }}
        >
          <h1
            className="dashboardTitle"
            style={{ paddingTop: isMobile ? "50px" : null }}
          >
            {t("pagamentosde")}
            {nomeCliente}
          </h1>

          <ToggleButtonGroup
            color="primary"
            value={selected}
            exclusive
            onChange={handleChange}
            aria-label="Payment status"
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
              "& .MuiToggleButton-root": {
                fontSize: "12px",
              },
              "& .Mui-selected": {
                color: "#ffff !important",
                "&.pendente": {
                  backgroundColor: "darkorange",
                  "&:hover": {
                    backgroundColor: "darkorange",
                  },
                },
                "&.pago": {
                  backgroundColor: Colors.ativo,
                  "&:hover": {
                    backgroundColor: Colors.ativo,
                  },
                },
                "&.cancelado": {
                  backgroundColor: Colors.inativo,
                  "&:hover": {
                    backgroundColor: Colors.inativo,
                  },
                },
                "&.balcao": {
                  backgroundColor: Colors.yellow,
                  "&:hover": {
                    backgroundColor: Colors.yellow,
                  },
                },
                "&.futuro": {
                  backgroundColor: "royalblue",
                  "&:hover": {
                    backgroundColor: "royalblue",
                  },
                },
              },
              "@media (max-width: 600px)": {
                fontSize: "10px",
                "& .MuiToggleButton-root": {
                  padding: "5px 5px",
                  fontSize: "10px",
                },
              },
              "@media (max-width: 400px)": {
                fontSize: "8px",
                "& .MuiToggleButton-root": {
                  padding: "2px 2px",
                  fontSize: "8px",
                },
              },
            }}
          >
            <ToggleButton value="1" className="pendente">
              {t("pendente")}
            </ToggleButton>
            <ToggleButton value="2" className="pago">
              {t("pago")}
            </ToggleButton>
            <ToggleButton value="3" className="cancelado">
              {t("cancelado")}
            </ToggleButton>
            <ToggleButton value="4" className="futuro">
              {t("futuro")}
            </ToggleButton>
            <ToggleButton value="5" className="balcao">
              {t("balcao")}
            </ToggleButton>
          </ToggleButtonGroup>

          <Paper
            elevation={0}
            style={{
              maxHeight: isMobile ? 600 : 450,
              overflow: "auto",
              marginTop: 10,
              backgroundColor: "#f7f7f7",
              minWidth: "35%",
            }}
          >
            {selected == 1 ? (
              <List style={{ marginTop: 10, minWidth: "35%" }}>
                {pagamentos
                  .filter((element) => element.status == 1)
                  .map((element) => (
                    <Card
                      onClick={() => {
                        setDadosPendente(element);
                        setJanelaPendente(true);
                      }}
                      sx={{
                        margin: "12px",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "35%",
                        backgroundColor: Colors.white,
                        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
                        transition:
                          "background .3s ease-in-out, box-shadow .3s ease-in-out",
                        ":hover": {
                          backgroundColor: Colors.gray,
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                        },
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "120px" : "150px",
                          height:
                            isMobile
                              ? "30%"
                              :  "70%",
                          backgroundColor:
                            element.status == 1
                              ? "darkorange"
                              : element.status == 2
                              ? Colors.ativo
                              : element.status == 3 || element.status == 4
                              ? Colors.inativo
                              : Colors.transparent,
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "skewY(-45deg)",
                          transformOrigin: "top right",
                        }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            {element.nome_piscina} -{" "}
                            {element.id_pack
                              ? t("pagamentoPack")
                              : t("pagamentoAula")}
                            {!isMobile ? (
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                          >
                            {t("metodoPagamento")}: {element.metodo_pagamento}
                          </Typography>
                          {element.pago ? (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataPagamento")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          ) : element.data_expira ? (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataLimite")}{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          ) : null}
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
              </List>
            ) : null}

            {selected == 2 ? (
              <List style={{ marginTop: 10, minWidth: "35%" }}>
                {pagamentos
                  .filter((element) => element.status == 2)
                  .map((element) => (
                    <Card
                      onClick={() => {
                        setDadosPendente(element);
                        setJanelaPago(true);
                      }}
                      sx={{
                        margin: "12px",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "35%",
                        backgroundColor: Colors.white,
                        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
                        transition:
                          "background .3s ease-in-out, box-shadow .3s ease-in-out",
                        ":hover": {
                          backgroundColor: Colors.gray,
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                        },
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "120px" : "150px",
                          height:
                            isMobile
                              ? "30%"
                              
                              : "70%",
                          backgroundColor:
                            element.status == 1
                              ? Colors.yellow
                              : element.status == 2
                              ? Colors.ativo
                              : element.status == 3 || element.status == 4
                              ? Colors.inativo
                              : Colors.transparent,
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "skewY(-45deg)",
                          transformOrigin: "top right",
                        }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            {element.nome_piscina} -{" "}
                            {element.id_pack
                              ? t("pagamentoPack")
                              : t("pagamentoAula")}
                            {!isMobile ? (
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                          >
                            {t("metodoPagamento")}: {element.metodo_pagamento}
                          </Typography>
                          {element.pago ? (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataPagamento")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataLimite")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          )}
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
              </List>
            ) : null}

            {selected == 3 ? (
              <List style={{ marginTop: 10, minWidth: "35%" }}>
                {pagamentos
                  .filter(
                    (element) => element.status == 3 || element.status == 4
                  )
                  .map((element) => (
                    <Card
                      onClick={() => {
                        setDadosPendente(element);
                        setJanelaCancelado(true);
                      }}
                      sx={{
                        margin: "12px",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "35%",
                        backgroundColor: Colors.white,
                        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
                        transition:
                          "background .3s ease-in-out, box-shadow .3s ease-in-out",
                        ":hover": {
                          backgroundColor: Colors.gray,
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                        },
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "120px" : "150px",
                          height:
                            isMobile
                              ? "30%"
                              :  "70%",
                          backgroundColor:
                            element.status == 1
                              ? Colors.yellow
                              : element.status == 2
                              ? Colors.ativo
                              : element.status == 3 || element.status == 4
                              ? Colors.inativo
                              : Colors.transparent,
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "skewY(-45deg)",
                          transformOrigin: "top right",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            {element.nome_piscina} -{" "}
                            {element.id_pack
                              ? t("pagamentoPack")
                              : t("pagamentoAula")}
                            {!isMobile ? (
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                          >
                            {t("metodoPagamento")}: {element.metodo_pagamento}
                          </Typography>
                          {element.pago ? (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataPagamento")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataLimite")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          )}
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
              </List>
            ) : null}

            {selected == 4 ? (
              <List style={{ marginTop: 10, minWidth: "35%" }}>
                {pagamentos
                  .filter((element) => element.status == 5)
                  .map((element) => (
                    <Card
                      onClick={() => {
                        setPagamentoMensal(element);
                        setJanelaFuturo(true);
                      }}
                      sx={{
                        margin: "12px",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "35%",
                        backgroundColor: Colors.white,
                        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
                        transition:
                          "background .3s ease-in-out, box-shadow .3s ease-in-out",
                        ":hover": {
                          backgroundColor: Colors.gray,
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                        },
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "120px" : "150px",
                          height:
                            isMobile
                              ? "30%"
                              : "70%",
                          backgroundColor:
                            element.status == 1
                              ? Colors.yellow
                              : element.status == 2
                              ? Colors.ativo
                              : element.status == 3 || element.status == 4
                              ? Colors.inativo
                              : Colors.transparent
                              ? element.status == 5
                              : "royalblue",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "skewY(-45deg)",
                          transformOrigin: "top right",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            {element.nome_piscina} -{" "}
                            {element.id_pack
                              ? t("pagamentoPack")
                              : t("pagamentoAula")}
                            {!isMobile ? (
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                          >
                            {t("metodoPagamento")}: {element.metodo_pagamento}
                          </Typography>
                          {element.pago ? (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataPagamento")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                            >
                              {t("dataLimite")}:{" "}
                              {new Date(element.data_expira)
                                .toLocaleString("pt-BR", {
                                  timeZone: "UTC",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                .replace(
                                  /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                  "$1/$2/$3 $4"
                                )}
                              h
                            </Typography>
                          )}
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
              </List>
            ) : null}

            {selected == 5 ? (
              <List style={{ marginTop: 10, minWidth: "35%" }}>
                {pagamentos
                  .filter(
                    (element) =>
                      element.id_metodo_pagamento == 4 && element.status == 1
                  )
                  .map((element) => (
                    <Card
                      onClick={() => {
                        setDadosPendente(element);
                        setJanelaNoLocal(true);
                      }}
                      sx={{
                        margin: "12px",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "35%",
                        backgroundColor: Colors.white,
                        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
                        transition:
                          "background .3s ease-in-out, box-shadow .3s ease-in-out",
                        ":hover": {
                          backgroundColor: Colors.gray,
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                        },
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "120px" : "150px",
                          height:
                            isMobile
                              ? "30%"
                              : "70%",
                          backgroundColor: Colors.yellow,
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "skewY(-45deg)",
                          transformOrigin: "top right",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            {element.nome_piscina} -{" "}
                            {element.id_pack
                              ? t("pagamentoPack")
                              : t("pagamentoAula")}
                            {!isMobile ? (
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              <span>
                                {element.total.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>
                          )}
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                          >
                            {t("metodoPagamento")}: {element.metodo_pagamento}
                          </Typography>
                          <Typography>
                            {element.pago ? (
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                              >
                                {t("dataPagamento")}:{" "}
                                {new Date(element.data_expira)
                                  .toLocaleString("pt-BR", {
                                    timeZone: "UTC",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                  .replace(
                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                    "$1/$2/$3 $4"
                                  )}
                                h
                              </Typography>
                            ) : element.data_expira ? (
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                              >
                                {t("dataLimite")}:{" "}
                                {new Date(element.data_expira)
                                  .toLocaleString("pt-BR", {
                                    timeZone: "UTC",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                  .replace(
                                    /(\d+)\/(\d+)\/(\d+), (\d+:\d+)/,
                                    "$1/$2/$3 $4"
                                  )}
                                h
                              </Typography>
                            ) : null}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
              </List>
            ) : null}
          </Paper>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PagamentosClienteA;
