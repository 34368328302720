import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";

//Get Funcionários
export function getFuncionarios(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("funcionario/all", dados, token);
}

//Post funcionario
export function postFuncionario(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("funcionario", dados, token);
}

//Enviar email -Post funcionario
export function enviarEmailPostFuncionario(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("funcionario/active-account", dados, token);
}

//Get por id
export function getFuncionario(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`funcionario/id/${id}`, {}, token);
}

//Editar
export function putFuncionario(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`funcionario/`, dados, token);
}

//Alterar status
export function putStatusFuncionario(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`funcionario/status`, dados, token);
}

//Eliminar
export function deleteFuncionario(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2(`funcionario/`, dados, token);
}
