import Cookies from "js-cookie";
import { makeRequestPost2, makeRequestPut2, makeRequestDelete2 } from "../api";

//Get Clientes
export function getFilhos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("contafamiliar/lista", dados, token);
}

//Get Clientes
export function getFilhos2(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("contafamiliar/lista2", dados, token);
}


//Suspender/Ativar contafamiliar
export function postStatusContaFamiliar(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("contafamiliar/status", dados, token);
}


//ver status contafa,iliar
export function verStatus(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("contafamiliar/getstatus", dados, token);
}

//add filho
export function postFilho(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("contafamiliar/filho", dados, token);

}


//add filho sem piscina
export function postFilho2(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("contafamiliar/filhosempiscina", dados, token);

}

//edit filho
export function putFilho(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("contafamiliar/filho", dados, token);
}
//getfilho
  export function getFilho(dados) {
    let token = Cookies.get("token");
    return makeRequestPost2(`contafamiliar/idfilho`, dados, token);
  }


  //Eliminar filho
export function deleteFilho(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("contafamiliar/filho", dados, token);
}


  //Eliminar filho
  export function deleteFilho2(dados) {
    let token = Cookies.get("token");
    return makeRequestDelete2("contafamiliar/filhosempiscina", dados, token);
  }
