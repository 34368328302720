import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
  RadioGroup,
  Radio,
  Alert,
  FormLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { Colors } from "../../values/Colors";
import { Scrollbar } from "react-scrollbars-custom";

import {
  faArrowLeft,
  faCircleCheck,
  faCircleStop,
  faCircleXmark,
  faClose,
  faL,
  faLock,
  faPen,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { Box, margin } from "@mui/system";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import {
  deleteFuncionario,
  enviarEmailPostFuncionario,
  getFuncionario,
  getFuncionarios,
  postFuncionario,
  putFuncionario,
  putStatusFuncionario,
} from "../../api/requests/Funcionarios";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import Search2 from "../../components/DataTable/Search2";
import Nav from "../../components/Nav";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { SlowMotionVideoOutlined } from "@mui/icons-material";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  definePassEmail,
  recuperaPass,
  verificarEmail,
} from "../../api/requests/Registo";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function ListaFuncionariosA() {
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [emailFuncionario, setEmailFuncionario] = useState("");
  const [telefoneFuncionario, setTelefoneFuncionario] = useState("");
  const [nifFuncionario, setNifFuncionario] = useState("");
  const [moradaFuncionario, setMoradaFuncionario] = useState("");
  const [codigoPostalFuncionario, setCodigoPostalFuncionario] = useState("");
  const [fotoFuncionario, setFotoFuncionario] = useState([]);
  const [statusFuncionario, setStatusFuncionario] = useState("");
  const [dataNascimentoFuncionario, setDataNascimentoFuncionario] = useState(
    new Date()
  );
  const [genero, setGenero] = useState("");
  const [erroGenero, seterroGenero] = useState(false);
  const [mensagemErroGenero, setMensagemErroGenero] = useState("");

  const [dadosOriginais, setDadosOriginais] = useState([]);

  //Validações
  // Responsável
  const [erroNomeFuncionario, setErroNomeFuncionario] = useState(false);
  const [erroEmailFuncionario, setErroEmailFuncionario] = useState(false);
  const [erroTelefoneFuncionario, setErroTelefoneFuncionario] = useState(false);
  const [erroNifFuncionario, setErroNifFuncionario] = useState(false);
  const [erroMoradaFuncionario, setErroMoradaFuncionario] = useState(false);
  const [erroCodigoPostalFuncionario, setErroCodigoPostalFuncionario] =
    useState(false);
  const [erroNascimentoFuncionario, setErroNascimentoFuncionario] =
    useState(false);

  //Privilégios
  const [editarPiscinaEdificio, setEditarPiscinaEdificio] = useState(false);
  const [editarPiscinaAgua, setEditarPiscinaAgua] = useState(false);
  const [adicionarPiscinaAgua, setAdicionarPiscinaAgua] = useState(false);
  const [eliminarPiscinaAgua, setEliminarPiscinaAgua] = useState(false);
  const [adicionarProfessor, setAdicionarProfessor] = useState(false);
  const [editarProfessor, setEditarProfessor] = useState(false);
  const [eliminarProfessor, setEliminarProfessor] = useState(false);
  const [ativarDesativarModalidades, setAtivarDesativarModalidades] =
    useState(false);
  const [adicionarDesconto, setAdicionarDesconto] = useState(false);
  const [editarDesconto, setEditarDesconto] = useState(false);
  const [eliminarDesconto, setEliminarDesconto] = useState(false);
  const [adicionarSeguroInscricao, setAdicionarSeguroInscricao] =
    useState(false);
  const [editarSeguroInscricao, setEditarSeguroInscricao] = useState(false);
  const [eliminarSeguroInscricao, setEliminarSeguroInscricao] = useState(false);
  const [pedidos, setPedidos] = useState(false);
  const [editarFuncionario, setEditarFuncionario] = useState(false);
  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [funcionarioSelecionadoId, setFuncionarioSelecionadoId] =
    useState(null);
  const [dadosFuncionario, setDadosFuncionario] = useState(null);

  // Responsável
  const [mensagemErroEmailFuncionario, setMensagemErroEmailFuncionario] =
    useState("");
  const [mensagemErroTelefoneFuncionario, setMensagemErroTelefoneFuncionario] =
    useState("");
  const [mensagemErroNifFuncionario, setMensagemErroNifFuncionario] =
    useState("");
  const [
    mensagemErroCodigoPostalFuncionario,
    setMensagemErroCodigoPostalFuncionario,
  ] = useState("");
  const [
    mensagemErroNascimentoFuncionario,
    setMensagemErroNascimentoFuncionario,
  ] = useState("");

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);
  const [reporPassword, setreporPassword] = useState(false);
  const [reporPwd, setReporPwd] = useState(false);

  const [suspensoes, setSuspensoes] = useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.inativo,
        contrastText: "#ffffff",
      },
      secondary: {
        main: Colors.ativo,
        contrastText: "#ffffff",
      },
    },
  });

  const mapIcon = (index) => {
    switch (index) {
      case 2:
        return (
          <CheckIcon
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        );
      case 1:
        return (
          <XMarkIcon
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        );
      default:
        return null;
    }
  };

  function formatDateAndTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  const handleClickOpenPopupReporPassword = () => {
    setreporPassword(true);
  };

  const handleClosePopupReporPassword = () => {
    setReporPwd(false);
    setreporPassword(false);
  };
  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };
  const scrollToDiv = () => {
    setEdicao(!edicao);
  };
  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  function criarFuncionario() {
    setFreeze(true);
    postFuncionario({
      id: localStorage.getItem("idPiscina"),
      nome: nomeFuncionario,
      contacto: telefoneFuncionario,
      email: emailFuncionario,
      genero: genero,
      editar_dados_piscina_edificio: editarPiscinaEdificio,
      editar_dados_piscina_agua: editarPiscinaAgua,
      adicionar_piscina_agua: adicionarPiscinaAgua,
      eliminar_piscina_agua: eliminarPiscinaAgua,
      adicionar_professor: adicionarProfessor,
      editar_professor: editarProfessor,
      eliminar_professor: eliminarProfessor,
      ativar_desativar_modalidades: ativarDesativarModalidades,
      adicionar_desconto: adicionarDesconto,
      editar_descontos: editarDesconto,
      eliminar_descontos: eliminarDesconto,
      adicionar_si: adicionarSeguroInscricao,
      editar_si: editarSeguroInscricao,
      eliminar_si: eliminarSeguroInscricao,
      pedidos: pedidos,
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          await enviarEmailPostFuncionario({
            id: res.data.id,
          }).then((res) => {
            if (res.success) {
              setFreeze(false);
              handleCloseCriarFuncionario();
              toast.success(res.data.msg);
              setTimeout(() => {
                window.location.reload();
              }, 550);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (nomeFuncionario && emailFuncionario && telefoneFuncionario && genero) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailFuncionario
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailFuncionario(true);
        setMensagemErroEmailFuncionario(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneFuncionario)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneFuncionario(true);
        setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
      }
      if (emailFuncionario) {
        verificarEmail({ email: emailFuncionario }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroEmailFuncionario(true);
              setMensagemErroEmailFuncionario(res.data.error);
            }
          }
        });
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarFuncionario();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeFuncionario) {
        setErroNomeFuncionario(true);
      }
      //Validação do email
      if (!emailFuncionario) {
        setErroEmailFuncionario(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailFuncionario
          );
        if (!validacaoEmail) {
          setErroEmailFuncionario(true);
          setMensagemErroEmailFuncionario(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailFuncionario }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                setErroEmailFuncionario(true);
                setMensagemErroEmailFuncionario(res.data.error);
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneFuncionario) {
        setErroTelefoneFuncionario(true);
      } else {
        if (!isValidPhoneNumber(telefoneFuncionario)) {
          setErroTelefoneFuncionario(true);
          setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
        }
      }
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "email", "status"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "email", "status"] : []);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selecionado, setSelecionado] = useState(null);
  const handleClick = (botaoSelecionado) => {
    if (selecionado !== botaoSelecionado) {
      setSelecionado(botaoSelecionado);
    }
  };
  const [funcionarios, setFuncionarios] = useState([]);
  const [funcionariosCopy, setFuncionariosCopy] = useState([]);
  const [search, setSearch] = useState("");

  const { state } = useLocation();
  const { idPiscina, nomePiscina } = state || {};

  //Traduções
  const { t, i18n } = useTranslation();
  const [freeze, setFreeze] = useState("");
  const [edicao, setEdicao] = useState(true);

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [openCriarFuncionario, setOpenCriarFuncionario] = useState(false);
  const handleCloseCriarFuncionario = () => setOpenCriarFuncionario(false);

  const handleClickOpenCriarFuncioanrio = () => {
    setOpenCriarFuncionario(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  function cancelar() {
    handleClosePopupCancelar();
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeFuncionario(dadosOriginais.nome);
    setEmailFuncionario(dadosOriginais.email);
    setTelefoneFuncionario(dadosOriginais.contacto);
    setNifFuncionario(dadosOriginais.nif);
    setGenero(dadosOriginais.genero);
    setMoradaFuncionario(dadosOriginais.morada);
    setCodigoPostalFuncionario(dadosOriginais.codigo_postal);
    setFotoFuncionario(dadosOriginais.foto_perfil);
    if (dadosOriginais.foto_perfil) {
      setFotoFuncionario(dadosOriginais.foto_perfil);
    } else {
      setFotoFuncionario([]);
    }
    setDataNascimentoFuncionario(dadosOriginais.data_nascimento_final);
    setStatusFuncionario(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeFuncionario(false);
    setErroEmailFuncionario(false);
    setErroTelefoneFuncionario(false);
    setErroNifFuncionario(false);
    setErroMoradaFuncionario(false);
    setErroCodigoPostalFuncionario(false);
    setErroNascimentoFuncionario(false);
    setMensagemErroEmailFuncionario("");
    setMensagemErroTelefoneFuncionario("");
    setMensagemErroNifFuncionario("");
    setMensagemErroCodigoPostalFuncionario("");
  }

  useEffect(() => {
    if (funcionarioSelecionadoId !== null) {
      getFuncionario(funcionarioSelecionadoId)
        .then((res) => {
          if (res.success) {
            let dados = res.data.data;
            let privilegios = res.data.privilegios;
            setSuspensoes(res.data.suspensoes);
            setDadosOriginais(dados);
            setNomeFuncionario(dados.nome);
            setEmailFuncionario(dados.email);
            setGenero(dados.genero);
            setTelefoneFuncionario(dados.contacto);
            setNifFuncionario(dados.nif);
            setMoradaFuncionario(dados.morada);
            setCodigoPostalFuncionario(dados.codigo_postal);
            if (dados.foto_perfil) {
              setFotoFuncionario(dados.foto_perfil);
            }
            setDataNascimentoFuncionario(dados.data_nascimento_final);
            setStatusFuncionario(dados.status);
            setMotivo(res.data.obs);
            setCarregamentoDadosCompleto(true);

            setEditarPiscinaEdificio(
              privilegios.editar_dados_piscina_edificio == 1 ? true : false
            );
            setAdicionarPiscinaAgua(
              privilegios.adicionar_piscina_agua == 1 ? true : false
            );
            setEditarPiscinaAgua(
              privilegios.editar_dados_piscina_agua == 1 ? true : false
            );
            setEliminarPiscinaAgua(
              privilegios.eliminar_piscina_agua == 1 ? true : false
            );
            setAdicionarProfessor(
              privilegios.adicionar_professor == 1 ? true : false
            );
            setEditarProfessor(
              privilegios.editar_professor == 1 ? true : false
            );
            setEliminarProfessor(
              privilegios.eliminar_professor == 1 ? true : false
            );
            setAtivarDesativarModalidades(
              privilegios.ativar_desativar_modalidades == 1 ? true : false
            );
            setAdicionarDesconto(
              privilegios.adicionar_desconto == 1 ? true : false
            );
            setEditarDesconto(privilegios.editar_descontos == 1 ? true : false);
            setEliminarDesconto(
              privilegios.eliminar_descontos == 1 ? true : false
            );
            setAdicionarSeguroInscricao(
              privilegios.adicionar_si == 1 ? true : false
            );
            setEditarSeguroInscricao(privilegios.editar_si == 1 ? true : false);
            setEliminarSeguroInscricao(
              privilegios.eliminar_si == 1 ? true : false
            );
            setPedidos(privilegios.pedidos == 1 ? true : false);
            setDadosFuncionario(res.data);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [funcionarioSelecionadoId]);

  useEffect(() => {
    if (search) {
      let arrayFuncionarios = funcionariosCopy;
      arrayFuncionarios = arrayFuncionarios.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setFuncionarios(arrayFuncionarios);
    } else {
      setFuncionarios(funcionariosCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getFuncionarios({ id: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
              email: element.email,
              status: element.status == 1 ? t("ativo") : t("inativo"),
            };
            arrayFinal.push(linha);
            setFuncionarios(arrayFinal);
            setFuncionariosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);
  useEffect(() => {
    if (openCriarFuncionario) {
      setNomeFuncionario("");
      setEmailFuncionario("");
      setTelefoneFuncionario("");
      setNifFuncionario("");
      setMoradaFuncionario("");
      setCodigoPostalFuncionario("");
      // if (dadosOriginais.foto) {
      //   setFotoFuncionario(dadosOriginais.foto);
      // } else {
      //   setFotoFuncionario([]);
      // }
      setDataNascimentoFuncionario("");
    }
  }, [openCriarFuncionario]);

  function suspenderFuncionario() {
    if (motivoSuspensao) {
      setSuspender(false);
      setFreeze(true);
      putStatusFuncionario({
        id: funcionarioSelecionadoId,
        obs: motivoSuspensao,
      })
        .then((res) => {
          if (res.success) {
            setFreeze(false);
            toast.success(res.data.msg);
            setMotivoSuspensao("");
            setEditarFuncionario(false);
            setMotivo(motivoSuspensao);
            setSelecionado(null);
            setStatusFuncionario(!statusFuncionario);
            setTimeout(() => {
              window.location.reload();
            }, 550);
          } else {
            if (res.status == 500) {
              setFreeze(false);
              navigate("/500");
            }
            setFreeze(false);
            toast.error(res.error);
            setMotivoSuspensao("");
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setErroMotivoSuspensao(true);
    }
  }
  function enviarEmail() {
    recuperaPass({
      email: emailFuncionario,
    }).then((res) => {
      if (res.success) {
        toast.success(t("reporPswFunc"));
        handleClosePopupReporPassword();
        //navigate("/superadmins");
      } else {
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }
  function EditarFuncionario() {
    setFreeze(true);

    putFuncionario({
      id: funcionarioSelecionadoId,
      nome: nomeFuncionario,
      genero: genero,
      nif: nifFuncionario,
      email: emailFuncionario,
      contacto: telefoneFuncionario,
      morada: moradaFuncionario,
      codigo_postal: codigoPostalFuncionario,
      foto: fotoFuncionario,
      data_nascimento: new Date(dataNascimentoFuncionario)
        .toISOString()
        .split("T")[0],
      editar_dados_piscina_edificio: editarPiscinaEdificio,
      editar_dados_piscina_agua: editarPiscinaAgua,
      adicionar_piscina_agua: adicionarPiscinaAgua,
      eliminar_piscina_agua: eliminarPiscinaAgua,
      adicionar_professor: adicionarProfessor,
      editar_professor: editarProfessor,
      eliminar_professor: eliminarProfessor,
      ativar_desativar_modalidades: ativarDesativarModalidades,
      adicionar_desconto: adicionarDesconto,
      editar_descontos: editarDesconto,
      eliminar_descontos: eliminarDesconto,
      adicionar_si: adicionarSeguroInscricao,
      editar_si: editarSeguroInscricao,
      eliminar_si: eliminarSeguroInscricao,
      pedidos: pedidos,
    })
      .then((res) => {
        if (res.success) {
          let novoArray = [];
          let linha = {
            id: funcionarioSelecionadoId,
            nome: nomeFuncionario,
            nif: nifFuncionario,
            genero: genero,
            email: emailFuncionario,
            contacto: telefoneFuncionario,
            morada: moradaFuncionario,
            codigo_postal: codigoPostalFuncionario,
            foto: fotoFuncionario,
            data_nascimento: new Date(dataNascimentoFuncionario)
              .toISOString()
              .split("T")[0],
            editar_dados_piscina_edificio: editarPiscinaEdificio,
            editar_dados_piscina_agua: editarPiscinaAgua,
            adicionar_piscina_agua: adicionarPiscinaAgua,
            eliminar_piscina_agua: eliminarPiscinaAgua,
            adicionar_professor: adicionarProfessor,
            editar_professor: editarProfessor,
            eliminar_professor: eliminarProfessor,
            ativar_desativar_modalidades: ativarDesativarModalidades,
            adicionar_desconto: adicionarDesconto,
            editar_descontos: editarDesconto,
            eliminar_descontos: eliminarDesconto,
            adicionar_si: adicionarSeguroInscricao,
            editar_si: editarSeguroInscricao,
            eliminar_si: eliminarSeguroInscricao,
            pedidos: pedidos,
          };
          setFreeze(false);

          toast.success(t("dadosEditadosSucesso"));
          
          setEdicao(!edicao);
          window.location.reload();
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      nomeFuncionario &&
      emailFuncionario &&
      telefoneFuncionario &&
      nifFuncionario &&
      moradaFuncionario &&
      codigoPostalFuncionario &&
      dataNascimentoFuncionario &&
      genero
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailFuncionario
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailFuncionario(true);
        setMensagemErroEmailFuncionario(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneFuncionario)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneFuncionario(true);
        setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
      }
      if (emailFuncionario) {
        verificarEmail({ email: emailFuncionario }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != funcionarioSelecionadoId) {
                confirmarDadosPreenchidos = false;
                setErroEmailFuncionario(true);
                setMensagemErroEmailFuncionario(res.data.error);
              }
            }
          }
        });
      }
      // Validação do nif
      if (nifFuncionario.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifFuncionario(true);
        setMensagemErroNifFuncionario(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalFuncionario < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalFuncionario(true);
        setMensagemErroCodigoPostalFuncionario(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoFuncionario);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoFuncionario(true);
        setMensagemErroNascimentoFuncionario(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        EditarFuncionario();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeFuncionario) {
        setErroNomeFuncionario(true);
      }
      //Validação do email
      if (!emailFuncionario) {
        setErroEmailFuncionario(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailFuncionario
          );
        if (!validacaoEmail) {
          setErroEmailFuncionario(true);
          setMensagemErroEmailFuncionario(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailFuncionario }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != funcionarioSelecionadoId) {
                  setErroEmailFuncionario(true);
                  setMensagemErroEmailFuncionario(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneFuncionario) {
        setErroTelefoneFuncionario(true);
      } else {
        if (!isValidPhoneNumber(telefoneFuncionario)) {
          setErroTelefoneFuncionario(true);
          setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
        }
      }
      // Validação do nif
      if (!nifFuncionario) {
        setErroNifFuncionario(true);
      } else {
        if (nifFuncionario.length < 9) {
          setErroNifFuncionario(true);
          setMensagemErroNifFuncionario(t("validacaoNif"));
        }
      }
      //Validação da morada
      if (!moradaFuncionario) {
        setErroMoradaFuncionario(true);
      }
      //Validação do código postal
      if (!codigoPostalFuncionario) {
        setErroCodigoPostalFuncionario(true);
      } else {
        if (codigoPostalFuncionario < 7) {
          setErroCodigoPostalFuncionario(true);
          setMensagemErroCodigoPostalFuncionario(t("validacaoCodigoPostal"));
        }
      }
      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoFuncionario);
      if (isNaN(isValidDate)) {
        setErroNascimentoFuncionario(true);
        setMensagemErroNascimentoFuncionario(t("dataInvalida"));
      }
    }
  }
  function eliminarFuncionario() {
    setEliminar(false);
    setFreeze(true);
    deleteFuncionario({ id: funcionarioSelecionadoId })
      .then((res) => {
        if (res.success) {
          toast.success(res.data.msg);
          setEditarFuncionario(false);
          window.location.reload();
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaFuncionario")}</h3>
        <div className="divTabelaM">
          {editarFuncionario ? (
            <div style={{ width: "100%", height: "100%" }}>
              <IconButton
                onClick={() => {
                  setEditarFuncionario(false);
                }}
                size={isMobile ? "small" : "medium"}
                style={{
                  position: "absolute",
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </IconButton>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  left: "50%",
                  paddingTop: isMobile ? "30px" : 0,
                }}
              >
                <button
                  className={`botaolistaadmin ${
                    selecionado === "suspender"
                      ? "botaolistaadminselecionado"
                      : ""
                  }`}
                  style={{
                    width: isMobile ? "95px" : "145px",
                    fontSize: isMobile ? "12px" : "14px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClick("suspender");
                    handleClickOpenPopupSuspender();
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "8px",
                    }}
                    icon={faCircleStop}
                  />{" "}
                  {statusFuncionario === 1 ? t("suspender") : t("Reativar")}
                </button>
                <button
                  className={`botaolistaadmin ${
                    selecionado === "eliminar"
                      ? "botaolistaadminselecionado"
                      : ""
                  }`}
                  style={{
                    width: isMobile ? "95px" : "145px",
                    fontSize: isMobile ? "12px" : "14px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClick("eliminar");
                    handleClickOpenPopupEliminar();
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "8px",
                    }}
                    icon={faTrash}
                  />{" "}
                  {t("eliminar")}
                </button>
                <button
                  className={`botaolistaadmin ${
                    selecionado === "reporPalavraPasse2"
                      ? "botaolistaadminselecionado"
                      : ""
                  }`}
                  style={{
                    width: isMobile ? "115px" : "200px",
                    fontSize: isMobile ? "12px" : "14px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClick("reporPalavraPasse2");
                    handleClickOpenPopupReporPassword();
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "8px",
                      marginLeft: "5px",
                    }}
                    icon={faLock}
                  />{" "}
                  {t("reporPalavraPasse2")}
                </button>
              </div>
              <Scrollbar
                style={{ height: isMobile ? "65%" : "70%", marginTop: "20px" }}
              >
                {carregamentoDadosCompleto ? (
                  <div className="divForm">
                    <div>
                      {motivo !== "" && statusFuncionario === 0 && (
                        <div
                          className="formPageSuspensao3"
                          style={{ borderRadius: "10px" }}
                        >
                          <Alert severity="error">
                            {t("funcionarioSuspenso")} - {t("motivo")}: {motivo}
                          </Alert>
                        </div>
                      )}

                      <div className="formPage2">
                        <Box className="formTextField">
                          {/* Piscina */}
                          <div style={{ marginBottom: 10 }}>
                            {/* Nome */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("nome")}
                                value={nomeFuncionario}
                                error={erroNomeFuncionario}
                                onChange={(e) => {
                                  setNomeFuncionario(e.target.value);
                                  setErroNomeFuncionario(false);
                                }}
                              />
                            </FormControl>

                            {/* Email */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("email")}
                                value={emailFuncionario}
                                error={erroEmailFuncionario}
                                helperText={mensagemErroEmailFuncionario}
                                onChange={(e) => {
                                  setEmailFuncionario(
                                    e.target.value.toLocaleLowerCase()
                                  );
                                  setErroEmailFuncionario(false);
                                  setMensagemErroEmailFuncionario("");
                                }}
                              />
                            </FormControl>
                            {/* NIPC */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("nif")}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 9);
                                }}
                                value={nifFuncionario}
                                error={erroNifFuncionario}
                                helperText={mensagemErroNifFuncionario}
                                onChange={(e) => {
                                  setNifFuncionario(e.target.value);
                                  setErroNifFuncionario(false);
                                }}
                              />
                            </FormControl>
                            {/* Telefone */}
                            <FormControl className="formTextField3" fullWidth>
                              <PhoneInput
                                disabled={edicao}
                                defaultCountry="PT"
                                labels={
                                  i18n.language == "pt"
                                    ? pt
                                    : i18n.language == "es"
                                    ? es
                                    : en
                                }
                                value={telefoneFuncionario}
                                error={erroTelefoneFuncionario}
                                helperText={mensagemErroTelefoneFuncionario}
                                onChange={(e) => {
                                  setTelefoneFuncionario(e);
                                  setErroTelefoneFuncionario(false);
                                  setMensagemErroTelefoneFuncionario("");
                                }}
                                inputComponent={PhoneNumber}
                              />
                            </FormControl>
                            {/* Genero */}
                            <FormControl
                              className="formTextField3"
                              sx={{
                                display: "flex",
                                justifycontent: "flex-start",
                                alignitems: "flex-start",
                                marginTop: "5px",
                              }}
                              disabled={edicao}
                            >
                              <FormLabel
                                sx={{
                                  display: "flex",
                                  justifycontent: "flex-start",
                                  alignitems: "flex-start",
                                }}
                              >
                                {t("genero")}
                              </FormLabel>

                              <RadioGroup
                                row
                                label={t("genero")}
                                defaultValue={genero}
                                name="genero"
                                value={genero}
                                onChange={(e) => {
                                  setGenero(e.target.value);
                                  seterroGenero(false);
                                }}
                              >
                                <FormControlLabel
                                  value="feminino"
                                  control={<Radio size="small" />}
                                  label={t("F")}
                                />
                                <FormControlLabel
                                  value="masculino"
                                  control={<Radio size="small" />}
                                  label={t("M")}
                                />
                                <FormControlLabel
                                  value="outro"
                                  control={<Radio size="small" />}
                                  label={t("O")}
                                />
                              </RadioGroup>
                            </FormControl>
                            {/* Rua */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("rua")}
                                value={moradaFuncionario}
                                error={erroMoradaFuncionario}
                                onChange={(e) => {
                                  setMoradaFuncionario(e.target.value);
                                  setErroMoradaFuncionario(false);
                                }}
                              />
                            </FormControl>
                            {/* Código Postal */}
                            <FormControl className="formTextField3" fullWidth>
                              <PatternFormat
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                {...{
                                  label: t("codigoPostal"),
                                  variant: "standard",
                                }}
                                customInput={TextField}
                                required
                                mask="_"
                                format="####-###"
                                value={codigoPostalFuncionario}
                                error={erroCodigoPostalFuncionario}
                                helperText={mensagemErroCodigoPostalFuncionario}
                                onChange={(e) => {
                                  setCodigoPostalFuncionario(e.target.value);
                                  setErroCodigoPostalFuncionario(false);
                                  setMensagemErroCodigoPostalFuncionario("");
                                }}
                              />
                            </FormControl>
                            {/* Data de nascimento */}
                            <FormControl className="formTextField3" fullWidth>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={i18n.language}
                              >
                                <DatePicker
                                  disableFuture
                                  disabled={edicao}
                                  label={t("dataNascimento")}
                                  openTo="day"
                                  views={["day", "year", "month"]}
                                  value={dataNascimentoFuncionario}
                                  onChange={(e) => {
                                    setDataNascimentoFuncionario(e);
                                    setErroNascimentoFuncionario(false);
                                    setMensagemErroNascimentoFuncionario("");
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="standard"
                                      {...params}
                                      required
                                      error={erroNascimentoFuncionario}
                                      helperText={
                                        mensagemErroNascimentoFuncionario
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            {/* Foto */}
                            <FormControl className="formTextField3" fullWidth>
                              <p
                                id="tituloFotoPiscina"
                                style={{
                                  marginTop: 10,
                                  color: Colors.textFields,
                                  fontSize: 15,
                                  fontWeight: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {t("foto")} *
                              </p>
                              <label
                                id="fotoPiscina"
                                htmlFor="file-upload"
                                className="custom-file-upload"
                              >
                                <i className="fa fa-cloud-upload"></i>
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  style={{ paddingTop: 4, paddingRight: 4 }}
                                />{" "}
                                {t("carregarImagem")}
                              </label>
                              <input
                                disabled={edicao}
                                id="file-upload"
                                style={{ paddingTop: 0 }}
                                type="file"
                                accept="image/*"
                                onChange={async (e) => {
                                  document.getElementById(
                                    "fotoPiscina"
                                  ).style.borderColor = Colors.ativo;
                                  document.getElementById(
                                    "tituloFotoPiscina"
                                  ).style.color = Colors.textFields;
                                  const file = e.target.files[0];

                                  const reader = new FileReader();

                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    setFotoFuncionario(reader.result);
                                  };
                                }}
                              ></input>
                              {/* Imagem carregada */}
                              <div>
                                {fotoFuncionario.length > 0 && (
                                  <img
                                    style={{ marginTop: 10,objectFit:"cover"}}
                                    width={150}
                                    height={150}
                                    src={fotoFuncionario}
                                  />
                                )}
                              </div>
                              <div>
                              
                              </div>
                            </FormControl>
                          </div>
                        </Box>
                      </div>
                      <div className="formPage2">
                        <Box className="formTextField">
                          {/* Privilégios */}
                          <h5 className="h5List">{t("privilegios")}</h5>
                          <div>
                            <FormControl className="formTextField3" fullWidth>
                              {/* Editar dados piscina edifício */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("editarDadosPiscinaEdificio")}
                                labelPlacement="start"
                                checked={editarPiscinaEdificio}
                                disabled={edicao}
                                onChange={() => {
                                  setEditarPiscinaEdificio(
                                    !editarPiscinaEdificio
                                  );
                                }}
                              />
                              {/* Adicionar piscina água */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("adicionarPiscinaAgua2")}
                                labelPlacement="start"
                                checked={adicionarPiscinaAgua}
                                disabled={edicao}
                                onChange={() => {
                                  setAdicionarPiscinaAgua(
                                    !adicionarPiscinaAgua
                                  );
                                }}
                              />
                              {/* Editar piscina água */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("editarDadosPiscinaAgua")}
                                labelPlacement="start"
                                checked={editarPiscinaAgua}
                                disabled={edicao}
                                onChange={() => {
                                  setEditarPiscinaAgua(!editarPiscinaAgua);
                                }}
                              />
                              {/* Eliminar piscina água */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("eliminarPiscinaAgua2")}
                                labelPlacement="start"
                                checked={eliminarPiscinaAgua}
                                disabled={edicao}
                                onChange={() => {
                                  setEliminarPiscinaAgua(!eliminarPiscinaAgua);
                                }}
                              />
                              {/* Adicionar professor */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("adicionarProfessor2")}
                                labelPlacement="start"
                                checked={adicionarProfessor}
                                disabled={edicao}
                                onChange={() => {
                                  setAdicionarProfessor(!adicionarProfessor);
                                }}
                              />
                              {/* Editar professor */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("editarProfessor2")}
                                labelPlacement="start"
                                checked={editarProfessor}
                                disabled={edicao}
                                onChange={() => {
                                  setEditarProfessor(!editarProfessor);
                                }}
                              />
                              {/* Eliminar professor */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("eliminarProfessor2")}
                                labelPlacement="start"
                                checked={eliminarProfessor}
                                disabled={edicao}
                                onChange={() => {
                                  setEliminarProfessor(!eliminarProfessor);
                                }}
                              />
                              {/* Ativar / Desativar modalidades */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("ativarDesativarModalidades")}
                                labelPlacement="start"
                                checked={ativarDesativarModalidades}
                                disabled={edicao}
                                onChange={() => {
                                  setAtivarDesativarModalidades(
                                    !ativarDesativarModalidades
                                  );
                                }}
                              />
                              {/* Adicionar desconto */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("adicionarDesconto2")}
                                labelPlacement="start"
                                checked={adicionarDesconto}
                                disabled={edicao}
                                onChange={() => {
                                  setAdicionarDesconto(!adicionarDesconto);
                                }}
                              />
                              {/* Editar desconto */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("editarDesconto2")}
                                labelPlacement="start"
                                checked={editarDesconto}
                                disabled={edicao}
                                onChange={() => {
                                  setEditarDesconto(!editarDesconto);
                                }}
                              />
                              {/* Eliminar desconto */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("eliminarDesconto2")}
                                labelPlacement="start"
                                checked={eliminarDesconto}
                                disabled={edicao}
                                onChange={() => {
                                  setEliminarDesconto(!eliminarDesconto);
                                }}
                              />
                              {/* Adicionar seguro/inscrição */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("adicionarsi")}
                                labelPlacement="start"
                                checked={adicionarSeguroInscricao}
                                disabled={edicao}
                                onChange={() => {
                                  setAdicionarSeguroInscricao(
                                    !adicionarSeguroInscricao
                                  );
                                }}
                              />
                              {/* Editar seguro/inscrição */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("editarsi")}
                                labelPlacement="start"
                                checked={editarSeguroInscricao}
                                disabled={edicao}
                                onChange={() => {
                                  setEditarSeguroInscricao(
                                    !editarSeguroInscricao
                                  );
                                }}
                              />
                              {/* Eliminar seguro/inscrição */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("eliminarsi")}
                                labelPlacement="start"
                                checked={eliminarSeguroInscricao}
                                disabled={edicao}
                                onChange={() => {
                                  setEliminarSeguroInscricao(
                                    !eliminarSeguroInscricao
                                  );
                                }}
                              />
                              {/* Pedidos */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("pedidos")}
                                labelPlacement="start"
                                checked={pedidos}
                                disabled={edicao}
                                onChange={() => {
                                  setPedidos(!pedidos);
                                }}
                              />
                            </FormControl>
                          </div>
                        </Box>
                      </div>
                      <div className="divForm">
                        <div className="formPage2">
                          <Box
                            className="formTextField"
                            style={{ marginTop: 40 }}
                          >
                            <h3 className="titleList2">{t("cronologia")}</h3>
                            <div style={{ height: "auto", width: "100%" }}>
                              <ThemeProvider theme={theme}>
                                <Timeline
                                  sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}
                                >
                                  {suspensoes.map((item, index) => (
                                    <TimelineItem key={item.id_suspensao}>
                                      <TimelineSeparator>
                                        <TimelineConnector />
                                        <TimelineDot
                                          color={
                                            item.tipo === 1
                                              ? "primary"
                                              : "secondary"
                                          }
                                        >
                                          {mapIcon(item.tipo)}
                                        </TimelineDot>
                                        <TimelineConnector />
                                      </TimelineSeparator>
                                      <TimelineContent sx={{ py: "12px" }}>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="flex-start"
                                        >
                                          <Grid item>
                                            <Typography
                                              variant="h6"
                                              component="span"
                                            >
                                              {item.tipo === 1
                                                ? "Suspensão"
                                                : "Ativação"}
                                            </Typography>
                                            <br></br>
                                            <Typography variant="text">
                                              {t("motivo")}:{" "}
                                            </Typography>
                                            <Typography variant="text">
                                              {item.obs}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="body2">
                                              {formatDateAndTime(item.criado)}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                              {item.nome_resp}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TimelineContent>
                                    </TimelineItem>
                                  ))}
                                </Timeline>
                              </ThemeProvider>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>
                    {/* Cancelar edição */}
                    <Dialog
                      open={cancelarEdicao}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          width: 452,
                          height: 186,
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("cancelarEdicao")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {t("textoCancelarEdicao")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundImage: `url(${simbtn})`,
                            width: 218,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            cancelar();
                          }}
                        >
                          {t("sim")}
                        </Button>
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundImage: `url(${naobtn})`,
                            width: 198,
                            height: 30,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            handleClosePopupCancelar();
                          }}
                        >
                          {t("nao")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "transparent",
                      position: "absolute",
                      width: window.innerWidth,
                      height: window.innerHeight,
                      zIndex: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: 0,
                    }}
                  >
                    <CircularProgress disableShrink />
                  </div>
                )}
              </Scrollbar>
              {/* Eliminar funcionario */}
              <Dialog
                open={eliminar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("eliminarFuncionario")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupEliminar();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("textoEliminarFuncionario")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      eliminarFuncionario();
                      setSelecionado(null);
                    }}
                    autoFocus
                  >
                    {t("sim")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupEliminar();
                      setSelecionado(null);
                    }}
                  >
                    {t("nao")}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Repor password funcionario */}
              <Dialog
                open={reporPassword}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 220,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("reenviarAtivacao")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupReporPassword();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                {reporPwd ? (
                  <DialogContentText
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{
                        marginTop: 20,
                      }}
                    />
                  </DialogContentText>
                ) : (
                  <>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoReporPassword")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setReporPwd(true);
                          enviarEmail();
                          setSelecionado(null);
                        }}
                        autoFocus
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupReporPassword();
                          setSelecionado(null);
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
              {/* Suspender funcionario */}
              <Dialog
                open={suspender}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {statusFuncionario
                    ? t("suspenderFuncionario")
                    : t("ativarFuncionario")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupSuspender();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  {statusFuncionario
                    ? t("justificacaoSuspencao")
                    : t("justificacaoAtivacao")}{" "}
                  *
                  <FormControl fullWidth>
                    <TextField
                      style={{ textAlign: "left", marginTop: 10 }}
                      variant="outlined"
                      multiline
                      rows={5}
                      value={motivoSuspensao}
                      error={erroMotivoSuspensao}
                      onChange={(e) => {
                        setMotivoSuspensao(e.target.value);
                        setErroMotivoSuspensao(false);
                      }}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, e.target.value)
                      //     .toString()
                      //     .slice(0, 500);
                      // }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupSuspender();
                      setSelecionado(null);
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      suspenderFuncionario();
                    }}
                    autoFocus
                  >
                    {statusFuncionario ? t("suspender") : t("ativar")}
                  </Button>
                </DialogActions>
              </Dialog>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {edicao ? (
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${editarbtn})`,
                      width: 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />
                    {t("editar")}
                  </Button>
                ) : (
                  <div>
                    <Button
                      className="btnGuardar"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${guardarbtn})`,

                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        validacao();
                      }}
                    >
                      {t("guardar")}
                    </Button>
                    <Button
                      className="btnGuardar"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${cancelarbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClickOpenPopupCancelar();
                      }}
                    >
                      {t("cancelar")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                className="col-0"
                style={{
                  textAlign: "start",
                  marginTop: 45,
                  marginLeft: 30,
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <span
                  className="addButton"
                  style={{ zIndex: "1" }}
                  onClick={() => {
                    handleClickOpenCriarFuncioanrio();
                  }}
                ></span>
                <Search2
                  onSearch={(value) => {
                    setSearch(value);
                  }}
                />
              </div>
              <div style={{ height: "62vh", maxWidth: "100%" }}>
                <DataGrid
                  className={isMobile ? "tabela2" : "tabela"}
                  pageSize={10}
                  //Colunas
                  columns={[
                    {
                      field: "id",
                      headerName: t("id"),
                      type: "number",
                      width: 5,
                      hide: columnsHidden.includes("id"),
                    },
                    {
                      field: "nome",
                      headerName: t("nome"),
                      type: "string",
                      flex: 1,
                    },
                    {
                      field: "email",
                      headerName: t("email"),
                      flex: 1,
                      type: "string",
                      hide: columnsHidden.includes("email"),
                    },
                    {
                      field: "status",
                      headerName: t("estado"),
                      maxWidth: 100,
                      flex: 1,
                      type: "string",
                      hide: columnsHidden.includes("status"),
                      renderCell: (params) => {
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {params.row.status == t("ativo") ? (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    marginTop: "20px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {t("ativo")}
                                </p>
                                <FontAwesomeIcon
                                  color={Colors.ativo}
                                  fontSize={15}
                                  icon={faCircleCheck}
                                />
                              </>
                            ) : (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    marginTop: "20px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {t("inativo")}
                                </p>{" "}
                                <FontAwesomeIcon
                                  color={Colors.inativo}
                                  fontSize={15}
                                  icon={faCircleXmark}
                                />
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      field: "actions",
                      type: "actions",
                      headerName: t("acoes"),
                      width: 70,
                      cellClassName: "actions",
                      getActions: ({ id }) => {
                        return [
                          <GridActionsCellItem
                            icon={
                              <FontAwesomeIcon
                                style={{ fontSize: isMobile ? 12 : 18 }}
                                icon={faPen}
                              />
                            }
                            label="Save"
                            onClick={() => {
                              setEditarFuncionario(true);
                              // navigate("/editemployeea", {
                              //   state: {
                              //     idPiscina: idPiscina,
                              //     nomePiscina: nomePiscina,
                              setFuncionarioSelecionadoId(id);
                              //   },
                              // });
                            }}
                          />,
                        ];
                      },
                    },
                  ]}
                  rows={funcionarios}
                  checkboxSelection={isMobile ? false : true}
                  onRowClick={(params) => {}}
                  getRowId={(row) => row.id}
                  //Idioma
                  localeText={
                    i18n.language == "pt"
                      ? ptBR.components.MuiDataGrid.defaultProps.localeText
                      : i18n.language == "es"
                      ? esES.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText
                  }
                  components={{
                    Toolbar: CustomToolbar,
                    BaseCheckbox: ({ value, ...otherProps }) => (
                      <Checkbox size="small" checked={value} {...otherProps} />
                    ),
                  }}
                  columnsHidden={isMobile ? ["id", "email", "status"] : []}
                />
              </div>
            </div>
          )}

          {/* adicionar cliente */}
          <Dialog
            open={openCriarFuncionario}
            onClose={handleCloseCriarFuncionario}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarFuncionario")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarFuncionario}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div className="formPop" style={{ marginTop: 30 }}>
                <Scrollbar style={{ height: 350 }}>
                  <Box className="formTextField">
                    <div>
                      {/* Nome */}
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="text"
                          variant="standard"
                          label={t("nome")}
                          value={nomeFuncionario}
                          error={erroNomeFuncionario}
                          onChange={(e) => {
                            setNomeFuncionario(e.target.value);
                            setErroNomeFuncionario(false);
                          }}
                        />
                      </FormControl>
                      {/* Email */}
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          required
                          type="text"
                          variant="standard"
                          label={t("email")}
                          value={emailFuncionario}
                          error={erroEmailFuncionario}
                          helperText={mensagemErroEmailFuncionario}
                          onChange={(e) => {
                            setEmailFuncionario(
                              e.target.value.toLocaleLowerCase()
                            );
                            setErroEmailFuncionario(false);
                            setMensagemErroEmailFuncionario("");
                          }}
                        />
                      </FormControl>
                      {/* Telefone */}
                      <FormControl className="formTextField3" fullWidth>
                        <PhoneInput
                          defaultCountry="PT"
                          labels={
                            i18n.language == "pt"
                              ? pt
                              : i18n.language == "es"
                              ? es
                              : en
                          }
                          value={telefoneFuncionario}
                          error={erroTelefoneFuncionario}
                          helperText={mensagemErroTelefoneFuncionario}
                          onChange={(e) => {
                            setTelefoneFuncionario(e);
                            setErroTelefoneFuncionario(false);
                            setMensagemErroTelefoneFuncionario("");
                          }}
                          inputComponent={PhoneNumber}
                        />
                      </FormControl>
                      {/* Genero */}
                      <FormControl
                        className="formTextField3"
                        sx={{
                          display: "flex",
                          justifycontent: "flex-start",
                          alignitems: "flex-start",
                          marginTop: "5px",
                        }}
                      >
                        <FormLabel>{t("genero")}</FormLabel>
                        <RadioGroup
                          row
                          label={t("genero")}
                          defaultValue={genero}
                          name="genero"
                          value={genero}
                          onChange={(e) => {
                            setGenero(e.target.value);
                            seterroGenero(false);
                          }}
                        >
                          <FormControlLabel
                            value="feminino"
                            control={<Radio size="small" />}
                            label={t("F")}
                          />
                          <FormControlLabel
                            value="masculino"
                            control={<Radio size="small" />}
                            label={t("M")}
                          />
                          <FormControlLabel
                            value="outro"
                            control={<Radio size="small" />}
                            label={t("O")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Privilégios */}
                      <h5 className="h5List">{t("privilegios")}</h5>
                      <div>
                        <FormControl className="formTextField3" fullWidth>
                          {/* Editar dados piscina edifício */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("editarDadosPiscinaEdificio")}
                            labelPlacement="start"
                            checked={editarPiscinaEdificio}
                            onChange={() => {
                              setEditarPiscinaEdificio(!editarPiscinaEdificio);
                            }}
                          />
                          {/* Adicionar piscina água */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("adicionarPiscinaAgua2")}
                            labelPlacement="start"
                            checked={adicionarPiscinaAgua}
                            onChange={() => {
                              setAdicionarPiscinaAgua(!adicionarPiscinaAgua);
                            }}
                          />
                          {/* Editar piscina água */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("editarDadosPiscinaAgua")}
                            labelPlacement="start"
                            checked={editarPiscinaAgua}
                            onChange={() => {
                              setEditarPiscinaAgua(!editarPiscinaAgua);
                            }}
                          />
                          {/* Eliminar piscina água */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("eliminarPiscinaAgua2")}
                            labelPlacement="start"
                            checked={eliminarPiscinaAgua}
                            onChange={() => {
                              setEliminarPiscinaAgua(!eliminarPiscinaAgua);
                            }}
                          />
                          {/* Adicionar professor */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("adicionarProfessor2")}
                            labelPlacement="start"
                            checked={adicionarProfessor}
                            onChange={() => {
                              setAdicionarProfessor(!adicionarProfessor);
                            }}
                          />
                          {/* Editar professor */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("editarProfessor2")}
                            labelPlacement="start"
                            checked={editarProfessor}
                            onChange={() => {
                              setEditarProfessor(!editarProfessor);
                            }}
                          />
                          {/* Eliminar professor */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("eliminarProfessor2")}
                            labelPlacement="start"
                            checked={eliminarProfessor}
                            onChange={() => {
                              setEliminarProfessor(!eliminarProfessor);
                            }}
                          />
                          {/* Ativar / Desativar modalidades */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("ativarDesativarModalidades")}
                            labelPlacement="start"
                            checked={ativarDesativarModalidades}
                            onChange={() => {
                              setAtivarDesativarModalidades(
                                !ativarDesativarModalidades
                              );
                            }}
                          />
                          {/* Adicionar desconto */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("adicionarDesconto2")}
                            labelPlacement="start"
                            checked={adicionarDesconto}
                            onChange={() => {
                              setAdicionarDesconto(!adicionarDesconto);
                            }}
                          />
                          {/* Editar desconto */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("editarDesconto2")}
                            labelPlacement="start"
                            checked={editarDesconto}
                            onChange={() => {
                              setEditarDesconto(!editarDesconto);
                            }}
                          />
                          {/* Eliminar desconto */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("eliminarDesconto2")}
                            labelPlacement="start"
                            checked={eliminarDesconto}
                            onChange={() => {
                              setEliminarDesconto(!eliminarDesconto);
                            }}
                          />
                          {/* Adicionar seguro/inscrição */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("adicionarsi")}
                            labelPlacement="start"
                            checked={adicionarSeguroInscricao}
                            onChange={() => {
                              setAdicionarSeguroInscricao(
                                !adicionarSeguroInscricao
                              );
                            }}
                          />
                          {/* Editar seguro/inscrição */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("editarsi")}
                            labelPlacement="start"
                            checked={editarSeguroInscricao}
                            onChange={() => {
                              setEditarSeguroInscricao(!editarSeguroInscricao);
                            }}
                          />
                          {/* Eliminar seguro/inscrição */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("eliminarsi")}
                            labelPlacement="start"
                            checked={eliminarSeguroInscricao}
                            onChange={() => {
                              setEliminarSeguroInscricao(
                                !eliminarSeguroInscricao
                              );
                            }}
                          />
                          {/* Pedidos */}
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label={t("pedidos")}
                            labelPlacement="start"
                            checked={pedidos}
                            onChange={() => {
                              setPedidos(!pedidos);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                </Scrollbar>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaFuncionariosA;
