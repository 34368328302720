import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2 } from "../api";

//Lista aulas
export function listaAulas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("aulas/list", dados, token);
}

//Adicionar aula
export function adicionarAula(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("aulas/", dados, token);
}

//Editar aula
export function editarAula(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("aulas/", dados, token);
}

//Eliminar aula
export function eliminarAula(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("aulas/", dados, token);
}

//Get aula
export function getAula(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("aulas/id", dados, token);
}
