import Cookies from "js-cookie";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import {
  Paper,
  Button,
  Icon,
  InputAdornment,
  TextField,
  unstable_composeClasses,
  Typography,
} from "@mui/material";
import {
  faFilePen,
  faArrowLeft,
  faTags,
  faQuestion,
  faRightFromBracket,
  faSwimmingPool,
  faSwimmer,
  faPeopleRoof,
  faTicket,
  faTicketSimple,
  faCancel,
  faQrcode,
  faGraduationCap,
  faHandPaper,
  faTriangleExclamation,
  faBuilding,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import bcrypt from "bcryptjs";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  getUtilizadoresId,
  suspendeUtilizador,
  suspendeUtilizadorEmail,
} from "../../api/requests/Utilizadores";
import { selectedIdsLookupSelector } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { logout } from "../../api/requests/AuthServer";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toolbar from "@mui/material/Toolbar";
import {
  faInbox,
  faEnvelope,
  faPhone,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

function AjudaFuncionario(props) {
  const [freeze, setFreeze] = useState(false);

  const [mobileOpen, setMobileOpen] = React.useState(true);

  const [content, setContent] = useState("default");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [open, setOpen] = useState(true);
  const drawerWidth = open ? "240px" : "55px";

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const handleButtonClick = (option) => {
    setContent(option);
  };

  const getTypographyContent = () => {
    switch (content) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#8B9FB2", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph>
              Sim podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#8B9FB2", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Com o método de pagamento mensal numa turma, como é feito o
              pagamento e em que dia?
            </Typography>
            <Typography paragraph>
              Os pagamentos mensais devem ser feitos até dia 8 de cada mês, caso
              contrário o cliente perderá parte das ações disponíveis na
              aplicação. O pagamento pode ser feito através da aplicação
              consultando o menu Pagamentos e indo a pagamentos pendentes. Os
              métodos de pagamentos disponíveis são: multibanco, mbway, cartão
              de crédito ou no balcão da piscina.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Quando cancelo um evento os clientes são notificados?
            </Typography>
            <Typography paragraph>Sim.</Typography>
            <Typography variant="h6" gutterBottom>
              Como funcionam os QR Codes?
            </Typography>
            <Typography paragraph>
              Os QR Codes apenas são gerados até 30 min antes do início da
              atividade e até ao final desta. O QR Code deve ser apresentado na
              entrada da piscina de modo a dar entrada na piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso o cliente não tenha telemóvel como dou entrada na piscina sem
              QR Code?
            </Typography>
            <Typography paragraph>
              Caso um cliente não tenha QR Code devemos ir ao menu Clientes, e
              procurar pelo cliente. De seguida devemos entrar no menu Próximas
              Atividades e carregar na atividade na qual pretendemos dar entrada
              e ele irá dar a entrada do cliente na piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Porque é que há opções que não estão disponíveis no meu menu?
            </Typography>
            <Typography paragraph>
              O Administrador tem acesso sobre as tuas ações. Pelo que caso
              pretendas executar alguma ação que não esteja disponível deves
              contactar o administrador.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph>
              Aqui estão os nossos contactos para que possas entrar em contacto
              connosco.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph>suporte@tectank.pt</Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph>+351 911 741 470</Typography>
          </>
        );
      case "appointment":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Marcações
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito às marcações para os clientes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer uma marcação?
            </Typography>
            <Typography paragraph>
              Para fazeres uma marcação deves selecionar um cliente no menu
              Clientes. De seguida, deves selecionar o utilizador, que pode ser
              o cliente ou alguém que esteja registado na conta familiar e
              escolher a opção que prentendes, aulas ou nado livre.
            </Typography>
            <Typography paragraph>
              No caso das aulas, deves primeiro escolher a modalidade e de
              seguida a turma. No passo seguinte deves escolher o tipo de
              pagamento: mensal ou anual. O pagamento mensal deve ser feito até
              dia 8 de cada mês, pela aplicação ou no balcão da piscina.
            </Typography>
            <Typography paragraph>
              No caso do nado livre deves, de seguida, escolher o dia e a hora.
              Seguidamente, indicas se pretende comprar um novo pack ou,
              utilizar um que já tenha, optando assim por descontar a marcação
              desse pack.
            </Typography>
            <Typography paragraph>
              Por fim, escolhes o método de pagamento: cartão de crédito,
              multibanco, mbway ou pagamento no local. <br></br> Confirma tudo
              antes de procederes ao pagamento.<br></br> O seguro e a inscrição
              são pagos uma vez por ano. <br></br>Assim que o pagamento for
              processado, a atividade irá aparecer no calendário do cliente.
              Podes também acompanhar o estado dos pagamentos do cliente no Menu
              de Pagamentos dentro da ficha do cliente.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Como cancelo uma marcação ao cliente?
            </Typography>
            <Typography paragraph>
              Para cancelar uma marcação deves aceder à ficha do cliente. De
              seguida, deves descer para a secção das proximas marcações e
              excluir aquela que pretendes. A marcação ficará cancelada e o
              ticket será automaticamente reposto.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como desinscrevo o cliente de uma turma?
            </Typography>
            <Typography paragraph>
              Para desinscrever de uma turma deves deves aceder à ficha do
              cliente. De seguida, deves descer para a secção das turma do
              cliente e excluir aquela que pretendes. O cliente irá sair da
              turma.
            </Typography>
          </>
        );
      case "pools":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Piscinas
            </Typography>
            <Typography paragraph>
              {" "}
              Esta secção diz respeito às piscinas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso desinscrever o cliente da piscina?
            </Typography>
            <Typography paragraph>
              Para desinscrever o cliente da piscina basta aceder à ficha de
              cliente e carregar em desinscrever.
            </Typography>
          </>
        );
      case "family":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Família
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito à conta familiar. Dentro da ficha de
              cliente poderás gerir a conta familiar do mesmo.
            </Typography>
          </>
        );
      case "packs":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Packs
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos packs da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso criar um pack?
            </Typography>
            <Typography paragraph>
              Para criar um pack deves aceder ao menu Packs. De seguida deves
              carregar em adicionar e preencher os dados. Para o pack deves
              inserir um nome, deves selecionar a piscina para o qual ele irá
              ser utilizado, a categoria do utilizador, o número de tickets e o
              preço.
            </Typography>
          </>
        );
      case "descontos":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Descontos
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos descontos da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso criar um desconto?
            </Typography>
            <Typography paragraph>
              Para criar um pack deves aceder ao menu Packs. De seguida deves
              carregar em adicionar e preencher os dados. Para o desconto deves
              inserir a percentagem de desconto, o nome, deves indicar o número
              de crianças (caso queiras por exemplo fazer um desconto para as
              famílias), indicar a ação em que será aplicado, a categoria do
              utilizador, a data de início e a data de fim. Caso a data final
              seja deixada em branco o desconto fica ativo infinitamente.
            </Typography>
          </>
        );
      case "cancelados":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Cancelados
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos eventos cancelados.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso cancelar um evento?
            </Typography>
            <Typography paragraph>
              Para cancelar um evento deves consultar o calendário. De seguida,
              selecionar o evento que pretendes cancelar, e carregar em
              cancelar. Deves escrever uma pequena observação.
            </Typography>
          </>
        );
      case "qrcodes":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              QR Codes
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos QR Codes.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso ativar um QR Code?
            </Typography>
            <Typography paragraph>
              Para ativar um QR Code basta entrar no Menu QR Code e carregar
              sobre o QR Code que pretendemos ativar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como dou entrada de um cliente que não tem QR Code?
            </Typography>
            <Typography paragraph>
              Caso um cliente não tenha QR Code, devemos ir ao menu Clientes, e
              procurar pelo cliente. De seguida devemos entrar no menu "Próximas
              Atividades" e carregar na atividade na qual pretendemos dar
              entrada e ele irá dar a entrada do cliente na piscina.
            </Typography>
          </>
        );
      case "professores":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Professores
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos professores.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso adicionar um professor?
            </Typography>
            <Typography paragraph>
              Basta aceder ao menu Professor, carregar em adicionar e preencher
              os dados. O professor irá receber um email para definir a
              palavra-passe.
            </Typography>
          </>
        );
      case "seguros":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Seguros
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos seguros/inscrições da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso adicionar um seguro/inscrição?
            </Typography>
            <Typography paragraph>
              Basta aceder ao menu Seguros, carregar em adicionar e preencher os
              dados. Deves indicar a categoria do utilizador, o valor do seguro,
              o valor da inscrição e a data em que expira cada um. Esses valores
              serão utilizados quando um cliente efetuar a primeira ação na
              piscina.
            </Typography>
          </>
        );
      case "pedidos":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Seguros
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito aos pedidos que são feitos por parte dos
              professores.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso aprove um pedido os clientes são notificados?
            </Typography>
            <Typography paragraph>Sim.</Typography>
          </>
        );
      case "ocupaespaco":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Ocupa Espaço
            </Typography>
            <Typography paragraph>
              Nesta secção poderás realizar todas as ações referentes a turmas,
              aulas e espaço.
            </Typography>
            {/* <Typography variant="h6" gutterBottom>
              Caso aprove um pedido os clientes são notificados?
            </Typography>
            <Typography paragraph>Sim.</Typography> */}
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };
  return (
    <div className="divForm">
      <Header />
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{
            width: { xs: drawerWidth, sm: drawerWidth },
            flexShrink: { sm: 0, xs: 0 },
          }}
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: "60px",
                border: "none",
              },
            }}
            open={open}
          >
            {
              <List sx={{ marginTop: "5%" }}>
                <IconButton
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    margin: 1,
                    "&:hover": {
                      outline: "none",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={handleDrawerClick}
                >
                  {open ? <ChevronLeftIcon /> : <MenuIcon />}
                  {open && (
                    <Typography
                      variant="body1"
                      sx={{
                        alignSelf: "center",
                        marginLeft: open ? 1 : 0,
                        fontSize: 20,
                        color: "#333333",
                      }}
                    >
                      Ajuda
                    </Typography>
                  )}
                </IconButton>
                {open ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5%",
                      }}
                    ></div>
                    <ListItem key="FAQ" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor: content == "faq" ? "#36B6FF" : null,
                          color: content == "faq" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("faq")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "faq" ? "#fff" : null}
                            icon={faQuestionCircle}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Perguntas Frequentes" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Contacts" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "contacts" ? "#FACF5A" : null,
                          color: content == "contacts" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("contacts")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "contacts" ? "#fff" : null}
                            icon={faPhone}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Contactos" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Packs" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "packs" ? Colors.cardAtalho1 : null,
                          color: content == "packs" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("packs")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "packs" ? "#fff" : null}
                            icon={faTicketSimple}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Packs" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Descontos" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "descontos" ? Colors.cardAtalho4 : null,
                          color: content == "descontos" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("descontos")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "descontos" ? "#fff" : null}
                            icon={faTags}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Descontos" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Cancelados" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "cancelados" ? "orchid" : null,
                          color: content == "cancelados" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("cancelados")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "cancelados" ? "#fff" : null}
                            icon={faCancel}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Cancelados" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="QrCode" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "qrcodes" ? Colors.card1 : null,
                          color: content == "qrcodes" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("qrcodes")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "qrcodes" ? "#fff" : null}
                            icon={faQrcode}
                          />
                        </ListItemIcon>
                        <ListItemText primary="QrCode" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Professores" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "professores" ? Colors.card1_2 : null,
                          color: content == "professores" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("professores")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "professores" ? "#fff" : null}
                            icon={faGraduationCap}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Professores" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Seguros" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "seguros" ? Colors.cardAtalho2 : null,
                          color: content == "seguros" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("seguros")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "seguros" ? "#fff" : null}
                            icon={faHandPaper}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Seguros" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Pedidos" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "pedidos" ? Colors.card7 : null,
                          color: content == "pedidos" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("pedidos")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "pedidos" ? "#fff" : null}
                            icon={faTriangleExclamation}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Pedidos" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Ocupa espaço" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "ocupaespaco" ? Colors.card6 : null,
                          color: content == "ocupaespaco" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("ocupaespaco")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "ocupaespaco" ? "#fff" : null}
                            icon={faCalendarCheck}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Ocupa espaço" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Appointment" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "appointment" ? "#065F63" : null,
                          color: content == "appointment" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("appointment")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "appointment" ? "#fff" : null}
                            icon={faSwimmer}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Marcar" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Pools" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "pools" ? "#49BEB6" : null,
                          color: content == "pools" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("pools")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "pools" ? "#fff" : null}
                            icon={faSwimmingPool}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Piscinas" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Family" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "family" ? "#FF5958" : null,
                          color: content == "family" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("family")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "family" ? "#fff" : null}
                            icon={faPeopleRoof}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Família" />
                      </ListItemButton>
                    </ListItem>
                  </div>
                ) : null}
              </List>
            }
          </Drawer>
        </Box>

        <Box
          sx={{
            flexGrow: 0,
            display: open && erWidth < 600 ? "none" : "flex",
          }}
        >
          <Typography component="div" sx={{ p: 2 }}>
            {getTypographyContent()}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default AjudaFuncionario;
