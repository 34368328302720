import {
  Button,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  Modal,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from "@mui/material";
import {
  faMobile,
  faCreditCard,
  faTicket,
  faMoneyBillTransfer,
  faGraduationCap,
  faPersonSwimming,
  faCalendarDay,
  faCalendarDays,
  faPen,
  faFaceFrown,
  faFaceSmile,
  faCashRegister,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { loginPost } from "../../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import {
  getInscricoesId,
  getUtilizadoresId,
} from "../../api/requests/Utilizadores";
import { getFilhos } from "../../api/requests/ContaFamiliar";
import {
  getCategoriaNadoLivre,
  getCategoriasTurmas,
  getCategoriaTurmas,
  getCategoriaUtilizador,
  getModalidadesAulasPiscina,
  getModalidadesPiscina,
  getPacksPiscina,
  getPiscinas,
  getTurmas,
} from "../../api/requests/Piscinas";
import { Search } from "../../components/DataTable";
import { display, fontSize, maxWidth, Stack, width } from "@mui/system";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import Cards from "react-credit-cards-2";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import { Colors } from "../../values/Colors";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import timeGridPlugin from "@fullcalendar/timegrid";
import "react-credit-cards-2/es/styles-compiled.css";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import mb from "../../images/multibanco.png";
import mbwayfoto from "../../images/mbway.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import { es as es2 } from "date-fns/locale";
import { pt as pt2 } from "date-fns/locale";
import { enGB as en2 } from "date-fns/locale";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import { DayClickEventHandler, DayPicker } from "react-day-picker";
import Grid from "@mui/material/Grid";
import {
  getDatasBanhosLivres,
  getHorasBanhosLivres,
  getInfoCompra,
  getInfoCompra2,
  getListaPacks,
  getPackUtilizador,
  getVerificacaoPackUtilizador,
  getVerificacaoSobreposicao,
  marcacaoBalcao,
  registarMarcacao,
  registarMarcacaoInativa,
} from "../../api/requests/Marcacoes";
import {
  associarPagamentosMarcacoes,
  emailCompraPackMarcacao,
  emailInscricaoTurma,
  emailMarcacao,
  linhasCompra,
  pagamentoCartaoCredito,
  pagamentoMbway,
  pagamentoMultibanco,
} from "../../api/requests/Pagamentos";
import { verificarInscricaoPagamentoPendente } from "../../api/requests/Inscricao";
import moment from "moment";


function MarcarF() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const [freeze, setFreeze] = useState(false);
  const { state } = useLocation();
  const { idCliente, idPiscina } = state || {};
  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState("");
  const [inscritas, setInscritas] = useState([]);
  const [aulas, setAulas] = useState(false);
  const [cartaocredito, setCartaoCredito] = useState(false);
  const [tenhopack, setTenhoPack] = useState(false);
  const [multibanco, setMultibanco] = useState(false);
  const [balcao, setBalcao] = useState(false);
  const [mbway, setMbway] = useState(false);
  const [pagapack, setPagaPack] = useState(false);
  const [piscinasCopy, setPiscinasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);
  const [selected, setSelected] = useState(true);
  const [idEscolhida, setIdEscolhida] = useState("");
  const [idUtilizadorEscolhido, setIdUtilizadorEscolhido] = useState("");
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [telefone, setTelefone] = useState("");
  const [erroTelefone, setErroTelefone] = useState(false);
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [submittedData, setSubmittedData] = useState({});
  const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);
  const [escolhido, setEscolhido] = useState(false);
  const [horaEscolhida, setHoraEscolhida] = useState(0);
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState();
  const [aulasPiscina, setAulasPiscina] = useState(false);
  const [nadoLivrePiscina, setNadoLivrePiscina] = useState(false);
  const [bookedDays, setBookedDays] = useState([]);
  const [horasDisponiveis, setHorasDisponiveis] = useState([]);
  const [verificaPackUtilizador, setVerificaPackUtilizador] = useState(false);
  const [packs, setPacks] = useState([]);
  //Se o utilizador é filho ou não
  const [filho, setFilho] = useState(false);
  //Pack escolhido
  const [packEscolhido, setPackEscolhido] = useState(0);
  //Lista packs do utilizador
  const [listaPacks, setListaPacks] = useState([]);
  //Pack do utilizador escolhido
  const [packUtilizadorEscolhido, setPackUtilizadorEscolhido] = useState(0);
  //Variável para fazer loading para reencaminhas para outra página
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  //Dados da compra
  const [compraInscricao, setCompraInscricao] = useState(0);
  const [compraPreco, setCompraPreco] = useState(0);
  const [compraTotal, setCompraTotal] = useState(0);
  //Dados multibanco
  const [entidade, setEntidade] = useState("");
  const [referencia, setReferencia] = useState("");
  const [dataExpira, setDataExpira] = useState("");
  const [pagar, setPagar] = useState(false);

  //MbWay await
  const [mbwayAwait, setMbwayAwait] = useState(false);

  //Marcação com método de pagamento pack
  const [marcacaoConcluida, setMarcacaoConcluida] = useState(false);
  const [marcacaoConcluida2, setMarcacaoConcluida2] = useState(false);

  //Modalidades da piscina
  const [modalidadesAulas, setModalidadesAulas] = useState([]);
  //Modalidade escolhida
  const [modalidadeEscolhida, setModalidadeEscolhida] = useState(0);
  //Turmas
  const [turmas, setTurmas] = useState([]);
  //Turma escolhida
  const [turmaEscolhida, setTurmaEscolhida] = useState(0);
  //Precos
  const [anual, setAnual] = useState(false);
  const [mensal, setMensal] = useState(false);
  const [desconto, setDesconto] = useState(0);
  const [idDesconto, setIdDesconto] = useState(0);
  const [descricaoDesconto, setDescricaoDesconto] = useState("");
  const [valorDesconto, setValorDesconto] = useState(0);

  //Variável popup balcão
  const [popupBalcao, setPopupBalcao] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    setCartaoCredito(false);
    setMultibanco(false);
    setMbway(false);
    setBalcao(false);
    setDesconto(0);
    setDescricaoDesconto("");
    setIdDesconto(0);
    setAulas(false);
  }, []);
  useEffect(() => {
    if (!mbwayAwait && pagar) {
      const redirectTimeout = setTimeout(() => {
        let utilizador = Cookies.get("utilizador");
        if (utilizador == "superadmin") {
          navigate("/dashboard_superadmin");
        } else if (utilizador == "supercomercial") {
          navigate("/dashboard_supercommercial");
        } else if (utilizador == "comercial") {
          navigate("/dashboard_commercial");
        } else if (utilizador == "admin") {
          navigate("/dashboard_admin");
        } else if (utilizador == "funcionario") {
          navigate("/dashboard_employee");
        } else if (utilizador == "professor") {
          navigate("/dashboard_teacher");
        } else if (utilizador == "cliente") {
          navigate("/dashboard_customer");
        } else {
          navigate("/dashboard_customer");
        }
      }, 10000); // tempo limite em milissegundos
      return () => clearTimeout(redirectTimeout);
    }
  }, [mbwayAwait]);

  const handleDayClick = (day) => {
    bookedDays.some(function (d) {
      if (d.getTime() === day.getTime()) {
        setSelectedDay(day);
      }
    });

    //setHoraEscolhida(0)
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const footer = selectedDay
    ? `${t("escolheuDia")} ${
        selectedDay.getDate() < 10
          ? "0" + selectedDay.getDate()
          : selectedDay.getDate()
      }/${
        selectedDay.getMonth() + 1 < 10
          ? "0" + (selectedDay.getMonth() + 1)
          : selectedDay.getMonth() + 1
      }/${selectedDay.getFullYear()}`
    : t("escolhaDia");

  function toggleCardFlip(e) {
    e.preventDefault();
    setIsFrontOfCardVisible(!isFrontOfCardVisible);
  }
  function handleSubmit(e) {
    e.preventDefault();
    setSubmittedData({ name, cardNumber, expiry, cvc });
  }

  //Variáveis
  var [formflow, setFormFlow] = useState(0);

  const [activeStep, setActiveStep] = React.useState(1);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;

    // Verificacoes
    const id = Cookies.get("uId");
    setIdu(idCliente);
    setIdEscolhida(idPiscina);

    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getInscricoesId(idCliente).then((res2) => {
      if (res2.success) {
        let arrayFinal2 = [];
        res2.data.data.forEach((element2) => {
          arrayFinal2.push(element2.id_piscina);
          setInscritas(arrayFinal2);
        });

        setTimeout(async function () {
          getPiscinas().then((res) => {
            if (res.success) {
              let arrayFinal = [];
              res.data.data.forEach((element) => {
                if (element.status == 1) {
                  if (arrayFinal2.includes(element.id_piscina_edificio)) {
                    const linha = {
                      id: element.id_piscina_edificio,
                      nome: element.nome,
                      cidade: element.cidade,
                      morada: element.morada,
                      img: element.foto_perfil,
                    };
                    arrayFinal.push(linha);
                  }
                }
              });
              setPiscinas(arrayFinal);
              setPiscinasCopy(arrayFinal);
              if (idPiscina) {
                setIdEscolhida(idPiscina);
                //getUserlist(id,idPiscina);
                setActiveStep(1);
              } else {
                if (arrayFinal2.length == 1) {
                  setIdEscolhida(arrayFinal[0].id);
                  //getUserlist(id, arrayFinal[0].id);
                  setActiveStep(1);
                }
              }
              setSemDados(false);
            } else {
              toast.error(res.error);
              setSemDados(true);
            }
          });
        }, 1000);
      } else {
        toast.error(res2.error);
      }
    });

    getFilhos({ id: idCliente, idPiscina: idPiscina }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_filho,
            filho: element.id_filho ? 1 : 0,
            nome: element.nome,
            nif: element.nif,
            img: element.foto,
            data_nascimento: element.data_nascimento_final,
          };

          arrayFinal.push(linha);
        });
        setTimeout(async function () {
          getUtilizadoresId(idCliente, {})
            .then((res) => {
              if (res.success) {
                let dados = res.data.data;
                const linha = {
                  id: dados.id_utilizador,
                  filho: dados.filho ? 1 : 0,
                  nome: dados.nome,
                  nif: dados.nif,
                  img: dados.foto,
                  data_nascimento: dados.data_nascimento,
                };
                arrayFinal.push(linha);
                setUsers(arrayFinal);
                setSemDados(false);
              } else {
                if (res.status == 500) {
                  navigate("/500");
                }
                toast.error(res.error);
              }
            })
            .catch((error) => {
              //toast.warn(t("erroWebservice"));
            });
        }, 1000);
      } else {
        // if (res.status == 500) {
        //   navigate("/500");
        // } else if (res.status == 403) {
        //   let utilizador = Cookies.get("utilizador");
        //   if (utilizador == "superadmin") {
        //     navigate("/dashboard_superadmin");
        //   } else if (utilizador == "supercomercial") {
        //     navigate("/dashboard_supercommercial");
        //   } else if (utilizador == "comercial") {
        //     navigate("/dashboard_commercial");
        //   } else if (utilizador == "admin") {
        //     navigate("/dashboard_admin");
        //   } else if (utilizador == "funcionario") {
        //     navigate("/dashboard_employee");
        //   } else if (utilizador == "professor") {
        //     navigate("/dashboard_teacher");
        //   } else if (utilizador == "cliente") {
        //     navigate("/dashboard_customer");
        //   }
        // }
        toast.error(res.error);
      }
    });

    document.addEventListener("click", scrollToTop);
    return () => {
      document.removeEventListener("click", scrollToTop);
    };
  }, [idEscolhida]);

  useEffect(() => {
    if (idUtilizadorEscolhido) {
      handleNext();
    }
  }, [idUtilizadorEscolhido]);

  useEffect(() => {
    if (aulas) {
      handleNext();
    }
  }, [aulas]);

  useEffect(() => {
    if (modalidadeEscolhida) {
      handleNext();
    }
  }, [modalidadeEscolhida]);

  useEffect(() => {
    if (selectedDay) {
      let year = selectedDay.getFullYear();
      let month =
        selectedDay.getMonth() + 1 < 10
          ? "0" + (selectedDay.getMonth() + 1)
          : selectedDay.getMonth() + 1;
      let day =
        selectedDay.getDate() < 10
          ? "0" + selectedDay.getDate()
          : selectedDay.getDate();
      let dataFinal = `${day}/${month}/${year}`;

      getHorasBanhosLivres({
        id_piscina: idEscolhida,
        id_cliente: idCliente,
        data: dataFinal,
      }).then((res) => {
        if (res.success) {
          let dados = res.data.data;

          dados.sort((a, b) => {
            const horaInicioA = a.horaInicio;
            const horaInicioB = b.horaInicio;

            if (horaInicioA < horaInicioB) {
              return -1;
            }
            if (horaInicioA > horaInicioB) {
              return 1;
            }

            return 0;
          });

          setHorasDisponiveis(dados);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (horaEscolhida) {
      handleNext();
    }
  }, [horaEscolhida]);

  useEffect(() => {
    if (turmaEscolhida) {
      handleNext();
    }
  }, [turmaEscolhida]);

  useEffect(() => {
    if (packEscolhido) {
      handleNext();
    }
  }, [packEscolhido]);

  useEffect(() => {
    if (packUtilizadorEscolhido) {
      handleNext();
    }
  }, [packUtilizadorEscolhido]);

  function getModalidadespiscinas(idPiscina) {
    let categoriasTurma = [];
    let categoriasNadoLivre = [];
    getCategoriaTurmas({ id_piscina: idPiscina, id_cliente: idCliente }).then(
      (res) => {
        if (res.success) {
          categoriasTurma = res.data.data;
        }
      }
    );
    getCategoriaNadoLivre({
      id_piscina: idPiscina,
      id_cliente: idCliente,
    }).then((res) => {
      if (res.success) {
        categoriasNadoLivre = res.data.data;
      }
    });
    let utilizadorEscolhido = filho
      ? users.find(
          (element) => element.filho == 1 && element.id == idUtilizadorEscolhido
        )
      : users.find(
          (element) => element.filho == 0 && element.id == idUtilizadorEscolhido
        );

    let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;
    getModalidadesAulasPiscina({
      id_piscina: idPiscina,
      id_cliente: idCliente,
      id_filho: id_filho,
    }).then((res) => {
      setModalidadesAulas(res.data.data);
    });

    getModalidadesPiscina({ id_piscina: idPiscina }).then(async (res) => {
      if (res.success) {
        let dados = res.data.data;

        let utilizadorEscolhido = filho
          ? users.find(
              (element) =>
                element.filho == 1 && element.id == idUtilizadorEscolhido
            )
          : users.find(
              (element) =>
                element.filho == 0 && element.id == idUtilizadorEscolhido
            );

        //Saber qual a categoria do utilizador
        let categoriaUtilizador;
        let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;

        let result = await getCategoriaUtilizador({
          id_filho: id_filho,
          id_cliente: idCliente,
        });
        if (result.success) {
          categoriaUtilizador = result.data.data;
        }

        if (
          dados.aulas_adultos ||
          dados.aulas_bebes ||
          dados.aulas_gravidas ||
          dados.aulas_idoso ||
          dados.hidro_terapia ||
          dados.hidrobike ||
          dados.hidroginastica ||
          dados.escola_natacao ||
          dados.hidropower
        ) {
          if (
            categoriasTurma.find((element) => element.id == categoriaUtilizador)
          ) {
            setAulasPiscina(true);
          } else {
            setAulasPiscina(false);
          }
        }

        if (dados.nado_livre) {
          if (
            categoriasNadoLivre.find(
              (element) => element.id == categoriaUtilizador
            )
          ) {
            setNadoLivrePiscina(true);
          } else {
            setNadoLivrePiscina(false);
          }
        }
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  function getUserlist(idPai, idPiscina) {
    getFilhos({ id: idPai, idPiscina: idPiscina }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_filho,
            filho: element.id_filho ? 1 : 0,
            nome: element.nome,
            nif: element.nif,
            img: element.foto,
            data_nascimento: element.data_nascimento_final,
          };

          arrayFinal.push(linha);
        });
        setTimeout(async function () {
          getUtilizadoresId(idCliente, {})
            .then((res) => {
              if (res.success) {
                let dados = res.data.data;
                const linha = {
                  id: dados.id_utilizador,
                  filho: dados.filho ? 1 : 0,
                  nome: dados.nome,
                  nif: dados.nif,
                  img: dados.foto,
                  data_nascimento: dados.data_nascimento,
                };
                arrayFinal.push(linha);
                setUsers(arrayFinal);
                setSemDados(false);
              } else {
                if (res.status == 500) {
                  navigate("/500");
                }
                toast.error(res.error);
              }
            })
            .catch((error) => {
              //toast.warn(t("erroWebservice"));
            });
        }, 1000);
      } else {
        // if (res.status == 500) {
        //   navigate("/500");
        // } else if (res.status == 403) {
        //   let utilizador = Cookies.get("utilizador");
        //   if (utilizador == "superadmin") {
        //     navigate("/dashboard_superadmin");
        //   } else if (utilizador == "supercomercial") {
        //     navigate("/dashboard_supercommercial");
        //   } else if (utilizador == "comercial") {
        //     navigate("/dashboard_commercial");
        //   } else if (utilizador == "admin") {
        //     navigate("/dashboard_admin");
        //   } else if (utilizador == "funcionario") {
        //     navigate("/dashboard_employee");
        //   } else if (utilizador == "professor") {
        //     navigate("/dashboard_teacher");
        //   } else if (utilizador == "cliente") {
        //     navigate("/dashboard_customer");
        //   }
        // }
        toast.error(res.error);
      }
    });
  }

  function getDatasLivresNadoLivre(idPiscina) {
    getDatasBanhosLivres({ id_piscina: idPiscina, id_cliente: idCliente }).then(
      (res) => {
        if (res.success) {
          let dados = res.data.data;
          let arrayFinal = [];
          dados.forEach((element) => {
            arrayFinal.push(new Date(element.year, element.month, element.day));
          });
          setBookedDays(arrayFinal);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      }
    );
  }

  function getVerificaPackUtilizador(idPiscina) {
    let filho = idCliente == idUtilizadorEscolhido ? 0 : idUtilizadorEscolhido;
    getPackUtilizador({ id_piscina: idPiscina, id_cliente: idCliente }).then(
      (res) => {
        if (res.success) {
          let dados = res.data.data;
          setVerificaPackUtilizador(dados);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      }
    );
  }

  function getPacks(idPiscina) {
    let filho = idCliente == idUtilizadorEscolhido ? 0 : idUtilizadorEscolhido;
    getPacksPiscina({
      id_piscina: idPiscina,
      id_filho: filho,
      id_cliente: idCliente,
    }).then((res) => {
      if (res.success) {
        let dados = res.data.data;
        setPacks(dados);
      } else {
        if (res.status == 500) {
          navigate("/500");
        }
        toast.error(res.error);
      }
    });
  }

  useEffect(() => {
    if (search) {
      let arrayPiscinas = piscinasCopy;
      arrayPiscinas = arrayPiscinas.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.cidade.toLowerCase().includes(search.toLowerCase())
      );
      setPiscinas(arrayPiscinas);
    } else {
      setPiscinas(piscinasCopy);
    }
  }, [search]);

  const handleNext = async () => {
    if (activeStep == 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      getUserlist(idu, idEscolhida);
    } else if (activeStep == 1) {
      getModalidadespiscinas(idEscolhida);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 2) {
      //Get datas disponíveis para banho livre na piscina
      getDatasLivresNadoLivre(idEscolhida);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 3) {
      if (!aulas) {
        let utilizadorEscolhido = filho
          ? users.find(
              (element) =>
                element.filho == 1 && element.id == idUtilizadorEscolhido
            )
          : users.find(
              (element) =>
                element.filho == 0 && element.id == idUtilizadorEscolhido
            );
        let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;
        const data = new Date(selectedDay);
        const dia = data.getDate().toString().padStart(2, "0");
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const ano = data.getFullYear();
        const dataFormatada = `${dia}/${mes}/${ano}`;

        let verificacaoSobreposicao;
        try {
          const res = await getVerificacaoSobreposicao({
            id_filho: id_filho,
            id_piscina: idEscolhida,
            id_cliente: idCliente,
            data: dataFormatada,
            horaInicio: horasDisponiveis[horaEscolhida].horaInicio,
            horaFim: horasDisponiveis[horaEscolhida].horaFim,
          });
          if (res.success) {
            verificacaoSobreposicao = res.data.verificacao;
          }
        } catch (error) {
          verificacaoSobreposicao = true;
        }

        if (!verificacaoSobreposicao) {
          toast.error(t("sobreposicaoMarcacoes"));
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          getVerificaPackUtilizador(idEscolhida);
          getPacks(idEscolhida);
        }
      } else {
        let utilizadorEscolhido = filho
          ? users.find(
              (element) =>
                element.filho == 1 && element.id == idUtilizadorEscolhido
            )
          : users.find(
              (element) =>
                element.filho == 0 && element.id == idUtilizadorEscolhido
            );
        //Saber qual a categoria do utilizador
        let categoriaUtilizador;
        let id_filho = utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0;
        let result = await getCategoriaUtilizador({
          id_filho: id_filho,
          id_cliente: idCliente,
        });
        if (result.success) {
          categoriaUtilizador = result.data.data;
        }
        // Get Turmas
        getTurmas({
          id_filho: id_filho,
          id_piscina: idEscolhida,
          id_cliente: idCliente,
          categoria: categoriaUtilizador,
          modalidade: modalidadeEscolhida,
        }).then((res) => {
          setTurmas(res.data.data);
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 4) {
      let utilizadorEscolhido = filho
        ? users.find(
            (element) =>
              element.filho == 1 && element.id == idUtilizadorEscolhido
          )
        : users.find(
            (element) =>
              element.filho == 0 && element.id == idUtilizadorEscolhido
          );
      verificarInscricaoPagamentoPendente({
        id_turma: turmaEscolhida,
        id_cliente: idCliente,
        id_filho: utilizadorEscolhido.filho ? utilizadorEscolhido.id : 0,
      }).then((res) => {
        if (res.status == 500) {
          toast.error(t("inscricaoAtiva"));
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      });
    } else if (activeStep == 5) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 6) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    }
  };
  const next2 = () => {
    if (activeStep == 5) {
      //Packs
      getInfoCompra({
        id_pack: packEscolhido,
        id_cliente: idCliente,
        id_piscina: idEscolhida,
        id_filho: filho ? idUtilizadorEscolhido : 0,
      }).then((res) => {
        if (res.success) {
          const linha = res.data.data;
          setCompraInscricao(linha.inscricao);
          setCompraPreco(linha.pack);
          setCompraTotal(linha.total);
          setDesconto(linha.desconto);
          setIdDesconto(linha.id_desconto);
          setDescricaoDesconto(linha.descricao);
          setValorDesconto(linha.valorDesconto.replace(".", ","));
        }
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else if (activeStep == 6) {
      //Aulas
      getInfoCompra2({
        id_turma: turmaEscolhida,
        id_piscina: idEscolhida,
        id_cliente: idCliente,
        id_filho: filho ? idUtilizadorEscolhido : 0,
        id_preco: anual ? 1 : mensal ? 2 : 0,
      }).then((res) => {
        if (res.success) {
          const linha = res.data.data;
          setCompraInscricao(linha.inscricao);
          setCompraPreco(anual ? linha.precoAnual : linha.precoMensal);
          setCompraTotal(linha.total);
          setDesconto(linha.desconto);
          setIdDesconto(linha.id_desconto);
          setDescricaoDesconto(linha.descricao);
          setValorDesconto(linha.valorDesconto.replace(".", ","));
        }
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const back2 = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const next2Pack = () => {
    setTenhoPack(true);
    getListaPacks({ id_piscina: idEscolhida, id_cliente: idCliente }).then(
      (res) => {
        if (res.success) {

          const pacotesFiltrados = res.data.data.filter((pack) => {
            return pack.num_tickets > pack.num_tickets_utilizados; // Adicione a instrução 'return'
          });
          setListaPacks(pacotesFiltrados);
        }
      }
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const back2Pack = () => {
    if (activeStep == 6) {
      setPackEscolhido(0);
      setPackUtilizadorEscolhido(0);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
    setTenhoPack(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function goBack() {
    if (activeStep == 0) {
    }
    if (activeStep == 1) {
      navigate("/editcustomerf", {
        state: {
          idPiscina: idPiscina,
          idCliente: idCliente,
        },
      });
    }
    if (activeStep == 2) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      setAulas(false);
      handleBack();
    }

    if (activeStep == 3) {
      setAulas(false);
      setCartaoCredito(false);
      setMultibanco(false);
      setAnual(false);
      setMensal(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);

      handleBack();
    }
    if (activeStep == 4) {
      setAnual(false);
      setMensal(false);

      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      handleBack();
    }
    if (activeStep == 5) {
      setAnual(false);
      setMensal(false);
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      handleBack();
    }
    if (activeStep == 6) {
      if (aulas === false) {
        setCartaoCredito(false);
        setMultibanco(false);
        setMbway(false);
        setBalcao(false);
        setDesconto(0);
        setDescricaoDesconto("");
        setIdDesconto(0);
        back2();
      } else {
        setCartaoCredito(false);
        setMultibanco(false);
        setMbway(false);
        setBalcao(false);
        setDesconto(0);
        setDescricaoDesconto("");
        setIdDesconto(0);
        handleBack();
      }
    }
    if (activeStep == 7) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      if (aulas == false) {
        back2();
      } else {
        setCartaoCredito(false);
        setMultibanco(false);
        setMbway(false);
        setBalcao(false);
        setDesconto(0);
        setDescricaoDesconto("");
        setIdDesconto(0);
        handleBack();
      }
    }
    if (activeStep == 8) {
      setCartaoCredito(false);
      setMultibanco(false);
      setMbway(false);
      setBalcao(false);
      setDesconto(0);
      setDescricaoDesconto("");
      setIdDesconto(0);
      back2Pack();
    }
  }

  function goNext() {
    if (activeStep == 0) {
      if (idEscolhida) {
        handleNext();
      } else {
        toast.error(t("escolhaumapiscina"));
      }
    }
    if (activeStep == 1) {
      if (idUtilizadorEscolhido) {
        handleNext();
      } else {
        toast.error(t("escolhaumutilizador"));
      }
    }
    if (activeStep == 2) {
      toast.error(t("escolhatipoaula"));
    }
    if (activeStep == 3) {
      if (aulas == true) {
        if (modalidadeEscolhida) {
          handleNext();
        } else {
          toast.error(t("escolhaumamodalidade"));
        }
      } else {
        if (selectedDay != null && horaEscolhida != null) {
          handleNext();
        } else {
          toast.error(t("escolhadiahora"));
        }
      }
    }

    if (activeStep == 4) {
      if (aulas == true) {
        if (turmaEscolhida) {
          handleNext();
        } else {
          toast.error(t("escolhaumaturma"));
        }
      } else {
        if (packEscolhido) {
          handleNext();
        } else {
          toast.error(t("escolhaumpack"));
        }
      }
    }
    if (activeStep == 5) {
      if (aulas == true) {
        toast.error(t("escolhatipopagamento"));
      } else {
        toast.error(t("escolhametodopagamento"));
      }
    }

    if (activeStep == 6) {
      if (aulas == true) {
        toast.error(t("escolhametodopagamento"));
      } else {
        if (!packUtilizadorEscolhido) {
          toast.error(t("escolhaseuspacks"));
        } else {
          handleNext();
        }
      }
    }

    if (activeStep == 7) {
      if (aulas == true) {
        toast.error(t("confirmedados"));
      } else {
        if (packUtilizadorEscolhido) {
          toast.error(t("confirmedados"));
        }
      }
    }
    if (activeStep == 8) {
      toast.error(t("confirmedados"));
    }
  }

  const steps = [
    {
      description: (
        <div id="1" style={{ height: "100%" }}>
          <div className="divTabelaMarcar2">
            <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
              {t("escolherpiscina")}
            </h1>
            {piscinas.length == 0 && (
              <div className="cardsDivMarcar">
                <Button
                  className="cardDashboardMarcar2"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  disabled
                >
                  <div style={{ display: "block" }}>
                    <FontAwesomeIcon className="iconCards" icon={faFaceFrown} />
                  </div>
                  <span
                    style={{
                      marginTop: "8px",
                      fontSize: isMobile ? "10px" : "14px",
                    }}
                  >
                    {t("erroMarcar")}
                  </span>
                </Button>
              </div>
            )}
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{
                paddingTop: 5,
                paddingBottom: 10,
                justifyContent: "center",
                display: "flex",
              }}
            >
              {piscinas.map((element) => (
                <Card
                  onClick={() => {
                    setIdEscolhida(element.id);
                    handleNext();
                  }}
                  sx={{
                    display: "flex",
                    margin: "16px",
                    justifyContent: "center",
                    boxShadow: 5,
                    alignItems: "center",
                    cursor: "pointer",
                    width: isMobile ? "150px" : "200px",
                    height: isMobile ? "150px" : "200px",
                    backgroundColor:
                      idEscolhida == element.id ? "lightblue" : "white",
                    borderRadius: "10%",
                    transition: "box-shadow 0.3s, transform 0.3s",
                    "&:hover": {
                      //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                      boxShadow: "5px 5px 5px 5px lightgrey",
                      transform: "translateY(-5px)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      height: "100%",
                      margin: 0,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid
                      container
                      sx={{ height: "100%", width: "100%", margin: 0 }}
                    >
                      <Grid item sx={{ width: "100%", margin: 0 }}>
                        <img
                          src={element.img}
                          alt={element.nome}
                          style={{
                            width: "100%",
                            objectFit: "fill",
                            height: isMobile ? "70px" : "100px",
                            margin: 0,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          sx={{
                            fontSize: isMobile
                              ? "8px !important"
                              : "10px !important",
                            fontWeight: "bold",
                            textAlign: "center",
                            marginTop: "5px",
                            padding: 1,
                          }}
                        >
                          {element.nome}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="div"
                          sx={{
                            fontSize: isMobile
                              ? "8px !important"
                              : "12px !important",
                            textAlign: "center",
                          }}
                        >
                          {element.cidade}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="2">
          <div className="divTabelaMarcar2">
            <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
              {t("escolherutilizador")}
            </h1>
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{
                paddingTop: 5,

                justifyContent: "center",
                display: "flex",
              }}
            >
              {users.length !== 0 ? (
                users.map((element) => (
                  <Card
                    key={element.id}
                    onClick={() => {
                      if (element.id === idUtilizadorEscolhido) {
                        goNext();
                      }
                      setIdUtilizadorEscolhido(element.id);

                      if (element.filho == 1) {
                        setFilho(true);
                      } else {
                        setFilho(false);
                      }
                    }}
                    sx={{
                      display: "flex",
                      margin: "16px",
                      justifyContent: "center",
                      boxShadow: 5,
                      alignItems: "center",
                      cursor: "pointer",
                      width: isMobile ? "150px" : "200px",
                      height: isMobile ? "150px" : "200px",
                      backgroundColor:
                        idUtilizadorEscolhido == element.id && filho == true
                          ? element.filho == 1 &&
                            idUtilizadorEscolhido == element.id
                            ? "lightblue"
                            : null
                          : element.filho == 0 &&
                            idUtilizadorEscolhido == element.id
                          ? "lightblue"
                          : null,
                      borderRadius: "10%",
                      transition: "box-shadow 0.3s, transform 0.3s",
                      "&:hover": {
                        //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                        boxShadow: "5px 5px 5px 5px lightgrey",
                        transform: "translateY(-5px)",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        cursor: "pointer",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Avatar
                        src={element.img}
                        sx={{
                          width: isMobile ? 50 : 90,
                          height: isMobile ? 50 : 90,
                          marginBottom: 1,
                        }}
                      />
                      <Typography component="div">{element.nome}</Typography>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                      >
                        {element.nif}
                      </Typography>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              )}
            </Grid>
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="3">
          <div
            className="divTabelaMarcar2"
            style={{ height: "100% !important" }}
          >
            <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
              {t("escolhertipoaula")}
            </h1>

            <div className="cardsDivMarcar">
              {aulasPiscina && (
                <Button
                  className="cardDashboardMarcar"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho1,
                  }}
                  onClick={() => {
                    setAulas(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="iconCards"
                    icon={faGraduationCap}
                  />
                  {t("aulas")}
                </Button>
              )}
              {nadoLivrePiscina && (
                <Button
                  className="cardDashboardMarcar"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho2,
                  }}
                  onClick={() => {
                    handleNext();
                  }}
                >
                  <FontAwesomeIcon
                    className="iconCards"
                    icon={faPersonSwimming}
                  />
                  {t("banhosLivres")}
                </Button>
              )}
              {!aulasPiscina && !nadoLivrePiscina && (
                <Button
                  className="cardDashboardMarcar2"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  disabled
                >
                  <div style={{ display: "block" }}>
                    <FontAwesomeIcon className="iconCards" icon={faFaceFrown} />
                  </div>
                  <span
                    style={{
                      marginTop: "8px",
                      fontSize: isMobile ? "10px" : "14px",
                    }}
                  >
                    {t("indisponibilidadeMarcacoes")}
                  </span>
                </Button>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="4">
          {aulas === true ? (
            <div
              className="divTabelaMarcar2"
              style={{ height: "80% !important" }}
            >
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolhermodalidade")}
              </h1>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  paddingTop: 5,

                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {modalidadesAulas.map((element) => (
                  <Card
                    onClick={() => {
                      if (element.id == modalidadeEscolhida) {
                        handleNext();
                      }
                      setModalidadeEscolhida(element.id);
                    }}
                    sx={{
                      display: "flex",

                      justifyContent: "center",
                      margin: 2,
                      boxShadow: 5,
                      alignItems: "center",
                      cursor: "pointer",
                      width: isMobile ? "150px" : "200px",
                      height: isMobile ? "150px" : "200px",
                      backgroundColor:
                        modalidadeEscolhida == element.id ? "lightblue" : null,
                      borderRadius: "10%",
                      transition: "box-shadow 0.3s, transform 0.3s",
                      "&:hover": {
                        //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                        boxShadow: "5px 5px 5px 5px lightgrey",
                        transform: "translateY(-5px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto", cursor: "pointer" }}>
                        <Typography component="div">{element.nome}</Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                        >
                          {element.nif}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
              </Grid>
            </div>
          ) : (
            <div
              className="divTabelaMarcar"
              style={{ height: "100% !important" }}
            >
              {!isMobile ? (
                <div
                  className="divTabelaMarcar2"
                  style={{ height: "40% !important" }}
                >
                  <h1
                    className="dashboardTitle2"
                    style={{ paddingTop: "100px" }}
                  >
                    {t("escolherespaco")}
                  </h1>

                  <Stack direction={"row"} spacing={30}>
                    <DayPicker
                      className="DayPicker"
                      mode="single"
                      required
                      selected={selectedDay}
                      // onSelect={setSelectedDay}
                      onDayClick={handleDayClick}
                      styles={{
                        container: {
                          maxWidth: "400px",
                          margin: "0 auto",
                          fontFamily: "Arial, sans-serif",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                        },
                        caption: {
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          color: "#1976d2",
                        },
                        day: {
                          fontSize: "18px",
                          color: "#444444",
                        },
                        ".DayPicker-Weekday": {
                          fontSize: "14px",
                          color: "#666666",
                          textTransform: "uppercase",
                        },
                        ".DayPicker-Day--booked": {
                          backgroundColor: "#eeeeee",
                        },
                        ".DayPicker-Day--selected:not(.DayPicker-Day--booked)":
                          {
                            backgroundColor: "#1976d2",
                            color: "#ffffff",
                            fontWeight: "bold",
                          },
                        ".DayPicker-Day--booked:not(.DayPicker-Day--selected)":
                          {
                            color: "#999999",
                          },
                        ".DayPicker-Footer": {
                          fontSize: "14px",
                          marginTop: "10px",
                        },
                        ".DayPicker-NavButton--prev, .DayPicker-NavButton--next":
                          {
                            fontSize: "20px",
                            color: "#1976d2",
                            outline: "none",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "calc(50% - 10px)",
                            zIndex: "2",
                          },
                        ".DayPicker-NavButton--prev": {
                          left: "10px",
                        },
                        ".DayPicker-NavButton--next": {
                          right: "10px",
                        },
                      }}
                      modifiers={{
                        booked: bookedDays,
                      }}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: "#1976d2",
                          color: "white",
                        },
                        booked: { border: "2px solid #1976d2" },
                      }}
                      locale={
                        i18n.language == "pt"
                          ? pt2
                          : i18n.language == "es"
                          ? es2
                          : en2
                      }
                      footer={footer}
                    />
                    <div className="gridHoras">
                      <h2 className="dashboardTitle2">
                        {t("horasDisponiveis")}
                      </h2>

                      <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ marginTop: "30px !important" }}
                      >
                        {horasDisponiveis.map((element, index) => (
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            key={index}
                            style={{ marginLeft: 5 }}
                          >
                            <Button
                              className="btnHoras"
                              variant="contained"
                              style={{
                                backgroundColor:
                                  horaEscolhida === index
                                    ? "lightblue"
                                    : "white",
                                color: "black",
                                transition: "box-shadow 0.3s, transform 0.3s",
                                "&:hover": {
                                  //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                                  boxShadow: "5px 5px 5px 5px lightgrey",
                                  transform: "translateY(-5px)",
                                },
                              }}
                              onClick={() => {
                                if (horaEscolhida == index && selectedDay) {
                                  handleNext();
                                } else {
                                  setHoraEscolhida(index);
                                }
                              }}
                            >
                              {element.horaInicio}
                              <br /> {element.horaFim}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Stack>
                </div>
              ) : (
                <div className="divTabelaMarcar">
                  <h1
                    className="dashboardTitle2"
                    style={{ paddingTop: "100px" }}
                  >
                    {t("escolherespaco")}
                  </h1>

                  <Stack
                    direction={"column"}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <DayPicker
                      className="DayPicker2"
                      mode="single"
                      required
                      selected={selectedDay}
                      // onSelect={setSelectedDay}
                      onDayClick={handleDayClick}
                      styles={{
                        container: {
                          fontFamily: "Arial, sans-serif",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                        },
                        caption: {
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          color: "#1976d2",
                        },
                        day: {
                          fontSize: "12px",
                          color: "#444444",
                          width: window.innerWidth / 13,
                        },
                        month: {
                          flex: "1 0 30%",
                          padding: "0 10px",
                        },
                        months: {
                          flexWrap: "wrap",
                          margin: "0 5px",
                        },
                        booked: {
                          backgroundColor: "#eeeeee",
                        },
                        ".DayPicker-Weekday": {
                          fontSize: "10px !important",
                          color: "#666666",
                          textTransform: "uppercase",
                        },

                        ".DayPicker-Day--selected:not(.DayPicker-Day--booked)":
                          {
                            backgroundColor: "#1976d2",
                            color: "#ffffff",
                            fontWeight: "bold",
                          },
                        ".DayPicker-Day--booked:not(.DayPicker-Day--selected)":
                          {
                            color: "#999999",
                          },
                        ".DayPicker-Footer": {
                          fontSize: "12px",
                          marginTop: "10px",
                        },
                        ".DayPicker-NavButton--prev, .DayPicker-NavButton--next":
                          {
                            fontSize: "16px",
                            color: "#1976d2",
                            outline: "none",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            position: "absolute",
                            top: "calc(50% - 10px)",
                            zIndex: "2",
                          },
                        ".DayPicker-NavButton--prev": {
                          left: "5px !important",
                        },
                        ".DayPicker-NavButton--next": {
                          right: "5px",
                        },

                        ".DayPicker-WeekdaysRow": {
                          display: "flex",
                          marginTop: "10px",
                          marginBottom: "10px",
                        },
                        ".DayPicker-Weekday": {
                          width: "calc(100% / 7)",
                          padding: "0",
                        },
                        ".DayPicker-Weekday abbr[title]": {
                          border: "none",
                          fontSize: "12px",
                          color: "#9e9e9e",
                        },
                      }}
                      modifiers={{
                        booked: bookedDays,
                      }}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: "#1976d2",
                          color: "white",
                        },
                        booked: { border: "2px solid #1976d2" },
                      }}
                      locale={
                        i18n.language == "pt"
                          ? pt2
                          : i18n.language == "es"
                          ? es2
                          : en2
                      }
                      footer={footer}
                    />
                    <div className="gridHoras2">
                      <h2 className="dashboardTitle2">
                        {t("horasDisponiveis")}
                      </h2>
                      <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 3, sm: 6, md: 9 }}
                        sx={{ marginTop: 10 }}
                      >
                        {horasDisponiveis.map((element, index) => (
                          <Grid
                            item
                            xs={1}
                            sm={3}
                            md={6}
                            key={index}
                            style={{ marginLeft: 5 }}
                          >
                            <Button
                              className="btnHoras"
                              variant="contained"
                              style={{
                                backgroundColor:
                                  horaEscolhida === index
                                    ? "lightblue"
                                    : "white",
                                color: "black",
                                transition: "box-shadow 0.3s, transform 0.3s",
                                "&:hover": {
                                  //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                                  boxShadow: "5px 5px 5px 5px lightgrey",
                                  transform: "translateY(-5px)",
                                },
                              }}
                              onClick={() => {
                                if (horaEscolhida == index && selectedDay) {
                                  handleNext();
                                } else {
                                  setHoraEscolhida(index);
                                }
                              }}
                            >
                              {element.horaInicio}
                              <br /> {element.horaFim}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Stack>
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      description: (
        <div id="5">
          {aulas === true ? (
            <div className="divTabelaMarcar2">
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolherturma")}
              </h1>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  paddingTop: 5,

                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {turmas.map((element) => (
                  <Card
                    onClick={() => {
                      if (turmaEscolhida == element.id_turma) {
                        handleNext();
                      }
                      if (element.num_vagas - element.num_vagas_ocupadas != 0) {
                        setTurmaEscolhida(element.id_turma);
                      }
                    }}
                    disabled={true}
                    sx={{
                      display: "flex",

                      justifyContent: "center",
                      boxShadow: 5,
                      alignItems: "center",
                      cursor: "pointer",
                      width: isMobile ? "150px" : "200px",
                      height: isMobile ? "150px" : "200px",
                      backgroundColor:
                        turmaEscolhida == element.id_turma ? "lightblue" : null,
                      borderRadius: "10%",
                      transition: "box-shadow 0.3s, transform 0.3s",
                      "&:hover": {
                        //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                        boxShadow: "5px 5px 5px 5px lightgrey",
                        transform: "translateY(-5px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 0,
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto", cursor: "pointer" }}>
                        <Typography component="div" sx={{ marginBottom: 1 }}>
                          {element.nome}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                        >
                          {element.obs}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                        >
                          {t("nVagasDisponiveis")}:{" "}
                          {element.num_vagas - element.num_vagas_ocupadas}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
              </Grid>
              {turmas.length == 0 && (
                <Button
                  className="cardDashboardMarcar4"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.ativo,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  disabled
                >
                  {/* aqui caixa */}
                  <div style={{ display: "block" }}>
                    <FontAwesomeIcon className="iconCards" icon={faFaceSmile} />
                  </div>
                  <span
                    style={{
                      marginTop: "8px",
                      fontSize: isMobile ? "10px" : "14px",
                    }}
                  >
                    {t("mensagemTurmas")}
                  </span>
                </Button>
              )}
            </div>
          ) : (
            <div className="divTabelaMarcar2">
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolherpack")}
              </h1>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  paddingTop: 5,
                  paddingBottom: 10,
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {packs.map((element) => (
                  <Card
                    onClick={() => {
                      if (packEscolhido == element.id_pack) {
                        handleNext();
                      }
                      setPackEscolhido(element.id_pack);
                    }}
                    sx={{
                      display: "flex",

                      justifyContent: "center",
                      boxShadow: 5,
                      alignItems: "center",
                      cursor: "pointer",
                      width: isMobile ? "150px" : "200px",
                      height: isMobile ? "150px" : "200px",
                      backgroundColor:
                        packEscolhido == element.id_pack ? "lightblue" : null,
                      borderRadius: "10%",
                      transition: "box-shadow 0.3s, transform 0.3s",
                      "&:hover": {
                        //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                        boxShadow: "5px 5px 5px 5px lightgrey",
                        transform: "translateY(-5px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",

                        width: "100%",
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto", cursor: "pointer" }}>
                        {!isMobile ? (
                          <Typography component="div" style={{ width: "100%" }}>
                            <div
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {element.nome}
                              <span style={{ display: "flex-end" }}>
                                {element.preco.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </div>
                          </Typography>
                        ) : (
                          <Typography component="div" style={{ width: "100%" }}>
                            <div
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              {element.nome}
                            </div>
                          </Typography>
                        )}
                        {!isMobile ? null : (
                          <Typography component="div" style={{ width: "100%" }}>
                            {element.preco}€
                          </Typography>
                        )}

                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                        >
                          {element.num_tickets} tickets
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
              </Grid>
              {verificaPackUtilizador && (
                <Link style={{ cursor: "pointer" }} onClick={next2Pack}>
                  {t("jatenhopack")}
                </Link>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      description: (
        <div id="6">
          <div
            className="divTabelaMarcar2"
            style={{ height: "100% !important" }}
          >
            {aulas === true ? (
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolhertipopagamento")}
              </h1>
            ) : (
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolhermetodopagamento")}
              </h1>
            )}
            {aulas === true && turmaEscolhida ? (
              <div className="divMarcar">
                <div
                  className="cardsDivMarcar"
                  sx={{ marginTop: "0px !important" }}
                >
                  <Button
                    className="cardDashboardMarcar"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setMensal(true);
                      handleNext();
                    }}
                  >
                    <div className="divCardsPrecos">
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faCalendarDay}
                      />
                      <span style={{ marginLeft: "0.5rem" }}>
                        {t("mensal")}
                      </span>
                    </div>
                    <div>
                      {turmas
                        .find((element) => element.id_turma == turmaEscolhida)
                        ?.preco_mensal.toLocaleString("pt-PT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                    </div>
                  </Button>

                  <Button
                    className="cardDashboardMarcar"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setAnual(true);
                      handleNext();
                    }}
                  >
                    <div className="divCardsPrecos">
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faCalendarDays}
                      />
                      <span style={{ marginLeft: "0.5rem" }}>{t("anual")}</span>
                    </div>

                    <div>
                      {turmas
                        .find((element) => element.id_turma == turmaEscolhida)
                        ?.preco_anual.toLocaleString("pt-PT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                    </div>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="divMarcar">
                <div className="cardsDivMarcarPagamento">
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho1,
                    }}
                    onClick={() => {
                      setCartaoCredito(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faCreditCard}
                    />
                    {t("cartaocredito")}
                  </Button>

                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho2,
                    }}
                    onClick={() => {
                      setMultibanco(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faMoneyBillTransfer}
                    />
                    {t("multibanco")}
                  </Button>
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: "salmon",
                    }}
                    onClick={() => {
                      setMbway(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faMobile} />
                    {t("mbway")}
                  </Button>
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: "khaki",
                    }}
                    onClick={() => {
                      setBalcao(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faCashRegister}
                    />
                    {t("pagaraobalcao")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="7">
          <div
            className="divTabelaMarcar2"
            style={{ height: "100% !important" }}
          >
            {aulas === true ? (
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolhermetodopagamento")}
              </h1>
            ) : (
              <h1 className="dashboardTitle2" style={{ paddingTop: "100px" }}>
                {t("escolherpackpagamento")}
              </h1>
            )}
            {aulas === true ? (
              <div className="divMarcar">
                <div className="cardsDivMarcarPagamento">
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho1,
                    }}
                    onClick={() => {
                      setCartaoCredito(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faCreditCard}
                    />
                    {t("cartaocredito")}
                  </Button>
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho2,
                    }}
                    onClick={() => {
                      setMultibanco(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faMoneyBillTransfer}
                    />
                    {t("multibanco")}
                  </Button>
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: "salmon",
                    }}
                    onClick={() => {
                      setMbway(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faMobile} />
                    {t("mbway")}
                  </Button>
                  <Button
                    className="cardDashboardMarcar3"
                    variant="contained"
                    style={{
                      backgroundColor: "khaki",
                    }}
                    onClick={() => {
                      setBalcao(true);
                      next2();
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconCards"
                      icon={faCashRegister}
                    />
                    {t("pagaraobalcao")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="divTabelaMarcar">
                <Grid
                  container
                  spacing={{ xs: 1, md: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    paddingTop: 5,
                    paddingBottom: 10,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {listaPacks.map((element) => (
                    <Card
                      onClick={() => {
                        if(moment(element.data_expira).isBefore(moment(), "day")){
                          toast.error("packExpirado")
                                                }else{
                        if (
                          element.id_pack_cliente == packUtilizadorEscolhido
                        ) {
                          handleNext();
                        }
                        setPackUtilizadorEscolhido(element.id_pack_cliente);
                      }
                      }}
                      disabled={moment(element.data_expira).isBefore(moment(), "day")}
                      sx={{
                        display: "flex",
                        margin: "16px",
                        justifyContent: "center",
                        boxShadow: 5,
                        alignItems: "center",
                        cursor: "pointer",
                        width: isMobile ? "150px" : "200px",
                        height: isMobile ? "150px" : "200px",
                        backgroundColor:
                          packUtilizadorEscolhido == element.id_pack_cliente
                            ? "lightblue"
                            : null,
                        borderRadius: "10%",
                        transition: "box-shadow 0.3s, transform 0.3s",
                        "&:hover": {
                          //backgroundColor: 'rgb(7, 177, 77, 0.42)',
                          boxShadow: "5px 5px 5px 5px lightgrey",
                          transform: "translateY(-5px)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography component="div"
                            style={{ fontSize: "13px" }}>
                            {element.nomePack} <br /> {element.nomePiscina}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                          >
                            {element.nome_utilizador}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{ fontSize: "11px" }}
                          >
                            {t("tickets")}: {element.num_tickets_utilizados} /{" "}
                            {element.num_tickets}
                          </Typography>
                          {moment(element.data_expira).isBefore(moment(), "day") && ( // Adiciona um indicador de "expirado" se a data de expiração for anterior à data de hoje
                          <Typography
                            variant="subtitle2"
                            color="error"
                            component="div"
                            style={{ fontSize: "10px" }}
                          >
                           { t("Expirado")}
                          </Typography>
                        )}
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
                </Grid>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="8">
          <div
            className="divTabelaMarcar"
            style={{ height: "100% !important" }}
          >
            {cartaocredito == true ? (
              <div className="divTabelaMarcar2">
                <h1 className="dashboardTitle2">{t("resumo")}</h1>
                <Paper
                  elevation={0}
                  style={{
                    height: "auto",
                    overflow: "auto",
                    marginTop: 20,
                    backgroundColor: "#f7f7f7",
                    width: "100%",
                  }}
                >
                  <Dialog
                    open={loading}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent>
                      <DialogContentText>{t("msgMb")}</DialogContentText>
                      <DialogContentText
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f7f7f7",
                        }}
                      >
                        <CircularProgress
                          disableShrink
                          style={{
                            marginTop: 20,
                          }}
                        />
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                  <Card
                    className="cardDashboardResumo"
                    variant="contained"
                    disabled
                    sx={{ paddingRight: 5 }}
                  >
                    <Card
                      sx={{
                        margin: "8px",

                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                          backgroundColor: null,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          {/* NOME PACK/TURMA */}
                          <Typography className="teste" component="div">
                            <span>
                              {!aulas
                                ? packs.find(
                                    (element) =>
                                      element.id_pack == packEscolhido
                                  )?.nome +
                                  ` (${
                                    packs.find(
                                      (element) =>
                                        element.id_pack == packEscolhido
                                    )?.num_tickets
                                  } ${t("tickets")})`
                                : t("resumo10") +
                                  " - " +
                                  turmas.find(
                                    (element) =>
                                      element.id_turma == turmaEscolhida
                                  )?.nome}
                            </span>
                            {!isMobile ? (
                              !aulas ? (
                                <span>
                                  {compraPreco.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {anual ? (
                                    <span>
                                      {turmas
                                        .find(
                                          (element) =>
                                            element.id_turma == turmaEscolhida
                                        )
                                        ?.preco_anual.toLocaleString("pt-PT", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                    </span>
                                  ) : (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  )}
                                </span>
                              )
                            ) : null}
                          </Typography>
                          {!isMobile ? null : (
                            <Typography className="teste" component="div">
                              {!aulas ? (
                                <span>
                                  {compraPreco.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {anual ? (
                                    <span>
                                      {turmas
                                        .find(
                                          (element) =>
                                            element.id_turma == turmaEscolhida
                                        )
                                        ?.preco_anual.toLocaleString("pt-PT", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                    </span>
                                  ) : (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  )}
                                </span>
                              )}
                            </Typography>
                          )}
                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {
                              piscinas.find(
                                (element) => element.id == idEscolhida
                              )?.nome
                            }
                          </Typography>
                          {aulas && (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {anual
                                ? t("pagamentoAnual")
                                : t("pagamentoMensal")}
                            </Typography>
                          )}
                          {!aulas ? (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 5,
                                marginLeft: 20,
                              }}
                            >
                              {t("utilizacao1Ticket")}:{" "}
                              {selectedDay.toLocaleDateString("pt-BR")}{" "}
                              {horasDisponiveis[horaEscolhida].horaInicio}h -{" "}
                              {horasDisponiveis[horaEscolhida].horaFim}h
                            </Typography>
                          ) : (
                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 5,
                                marginLeft: 20,
                              }}
                            >
                              {
                                turmas.find(
                                  (element) =>
                                    element.id_turma == turmaEscolhida
                                )?.obs
                              }
                            </Typography>
                          )}
                        </CardContent>
                      </Box>
                    </Card>

                    {compraInscricao != 0 && (
                      <Card
                        sx={{
                          margin: "8px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 10,
                          alignItems: "center",
                          cursor: "pointer",
                          minWidth: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                            paddingRight: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/*INSCRIÇÃO */}

                            <Typography className="teste" component="div">
                              <span>{t("resumo10")}</span>
                              <span>
                                {compraInscricao.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </span>
                            </Typography>

                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {
                                piscinas.find(
                                  (element) => element.id == idEscolhida
                                )?.nome
                              }
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}
                    {desconto != 0 && (
                      <Card
                        sx={{
                          margin: "8px",
                          justifyContent: "space-between",
                          boxShadow: 0,
                          borderRadius: 15,
                          alignItems: "center",
                          cursor: "pointer",
                          minWidth: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 3,
                            paddingRight: 3,
                          }}
                        >
                          <CardContent
                            sx={{ flex: "1 0 auto", cursor: "pointer" }}
                          >
                            {/* DESCONTO */}
                            <Typography className="teste" component="div">
                              <span>
                                {t("desconto")} {desconto}%
                              </span>
                              <span>
                                -{" "}
                                {valorDesconto.toLocaleString("pt-PT", {
                                  style: "currency",
                                  currency: "EUR",
                                })}{" "}
                                €
                              </span>
                            </Typography>

                            <Typography
                              className="teste"
                              variant="subtitle2"
                              color="text.secondary"
                              component="div"
                              style={{
                                textTransform: "initial",
                                textAlign: "left",
                                marginTop: 30,
                                marginLeft: 20,
                              }}
                            >
                              {descricaoDesconto}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    )}
                    <Card
                      sx={{
                        margin: "8px",
                        justifyContent: "space-between",
                        boxShadow: 0,
                        borderRadius: 15,
                        alignItems: "center",
                        cursor: "pointer",
                        minWidth: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <CardContent
                          sx={{ flex: "1 0 auto", cursor: "pointer" }}
                        >
                          <Typography className="teste" component="div">
                            <span>{t("metodoPagamento")}</span>
                          </Typography>

                          <Typography
                            className="teste"
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                            style={{
                              textTransform: "initial",
                              textAlign: "left",
                              marginTop: 30,
                              marginLeft: 20,
                            }}
                          >
                            {cartaocredito == true
                              ? t("cartaocredito")
                              : multibanco == true
                              ? t("multibanco")
                              : mbway == true
                              ? t("mbway")
                              : t("balcao")}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>

                    {compraTotal && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        sx={{
                          marginRight: 2,
                          marginBottom: 20,
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            backgroundColor: "lightblue",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/*TOTAl */}

                              <Typography className="teste" component="div">
                                <span>{t("resumo13")}</span>
                                <span>
                                  {compraTotal.toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </span>
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        <button
                          type="button"
                          className="regbtn"
                          onClick={() => {
                            setLoading(true);
                            let utilizadorEscolhido = filho
                              ? users.find(
                                  (element) =>
                                    element.filho == 1 &&
                                    element.id == idUtilizadorEscolhido
                                )
                              : users.find(
                                  (element) =>
                                    element.filho == 0 &&
                                    element.id == idUtilizadorEscolhido
                                );
                            let id_filho = utilizadorEscolhido.filho
                              ? utilizadorEscolhido.id
                              : 0;
                            const data = new Date(selectedDay);
                            const dia = data
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const mes = (data.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const ano = data.getFullYear();
                            const dataFormatada = `${dia}/${mes}/${ano}`;
                            {
                              !aulas
                                ? registarMarcacaoInativa({
                                    id_pack: packEscolhido,
                                    id_piscina: idEscolhida,
                                    id_cliente: idCliente,
                                    data: dataFormatada,
                                    horaInicio:
                                      horasDisponiveis[horaEscolhida]
                                        .horaInicio,
                                    horaFim:
                                      horasDisponiveis[horaEscolhida].horaFim,
                                    id_filho: id_filho,
                                  }).then((res2) => {
                                    if (res2.success) {
                                      setTimeout(async function () {
                                        pagamentoCartaoCredito({
                                          id_pack: packEscolhido,
                                          id_piscina: idEscolhida,
                                          id_turma: 0,
                                          id_filho: id_filho,
                                          language: i18n.language,
                                          id_preco: 0,
                                          id_desconto: idDesconto,
                                        }).then((res) => {
                                          if (res.success) {
                                            setTimeout(async function () {
                                              let id_marcacao = res2.data.id;
                                              let id_pagamento = res.data.id;
                                              let paymentURL =
                                                res.data.data.PaymentUrl;
                                              associarPagamentosMarcacoes({
                                                id_marcacao: id_marcacao,
                                                id_pagamento: id_pagamento,
                                              }).then((res3) => {
                                                if (res3.success) {
                                                  setTimeout(async function () {
                                                    let produtos = [
                                                      {
                                                        nomeProduto: !aulas
                                                          ? packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.nome +
                                                            ` (${
                                                              packs.find(
                                                                (element) =>
                                                                  element.id_pack ==
                                                                  packEscolhido
                                                              )?.num_tickets
                                                            } ${t("tickets")})`
                                                          : t("resumo10") +
                                                            " - " +
                                                            turmas.find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )?.nome,
                                                        precoProduto: !aulas
                                                          ? compraPreco.toLocaleString(
                                                              "pt-PT",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "EUR",
                                                              }
                                                            )
                                                          : anual
                                                          ? turmas
                                                              .find(
                                                                (element) =>
                                                                  element.id_turma ==
                                                                  turmaEscolhida
                                                              )
                                                              ?.preco_anual.toLocaleString(
                                                                "pt-PT",
                                                                {
                                                                  style:
                                                                    "currency",
                                                                  currency:
                                                                    "EUR",
                                                                }
                                                              )
                                                          : compraPreco.toLocaleString(
                                                              "pt-PT",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "EUR",
                                                              }
                                                            ),
                                                        desc1: aulas
                                                          ? piscinas.find(
                                                              (element) =>
                                                                element.id ==
                                                                idEscolhida
                                                            )?.nome +
                                                            " - " +
                                                            anual
                                                            ? t(
                                                                "pagamentoAnual"
                                                              )
                                                            : t(
                                                                "pagamentoMensal"
                                                              )
                                                          : t(
                                                              "utilizacao1Ticket"
                                                            ) +
                                                            " " +
                                                            selectedDay.toLocaleDateString(
                                                              "pt-BR"
                                                            ) +
                                                            " " +
                                                            horasDisponiveis[
                                                              horaEscolhida
                                                            ].horaInicio +
                                                            "h - " +
                                                            horasDisponiveis[
                                                              horaEscolhida
                                                            ].horaFim +
                                                            "h",
                                                        desc2: aulas
                                                          ? turmas.find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )?.obs
                                                          : piscinas.find(
                                                              (element) =>
                                                                element.id ==
                                                                idEscolhida
                                                            )?.nome,
                                                        quantidade: 1,
                                                        quantidadeTexto:
                                                          t("quantidade"),
                                                      },
                                                    ];

                                                    let produtosNum = [
                                                      {
                                                        nomeProduto: !aulas
                                                          ? packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.nome +
                                                            ` (${
                                                              packs.find(
                                                                (element) =>
                                                                  element.id_pack ==
                                                                  packEscolhido
                                                              )?.num_tickets
                                                            } ${t("tickets")})`
                                                          : t("resumo10") +
                                                            " - " +
                                                            turmas.find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )?.nome,
                                                        precoProduto: !aulas
                                                          ? compraPreco
                                                          : anual
                                                          ? turmas.find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )?.preco_anual
                                                          : compraPreco,
                                                        desc1: aulas
                                                          ? piscinas.find(
                                                              (element) =>
                                                                element.id ==
                                                                idEscolhida
                                                            )?.nome +
                                                            " - " +
                                                            anual
                                                            ? t(
                                                                "pagamentoAnual"
                                                              )
                                                            : t(
                                                                "pagamentoMensal"
                                                              )
                                                          : t(
                                                              "utilizacao1Ticket"
                                                            ) +
                                                            " " +
                                                            selectedDay.toLocaleDateString(
                                                              "pt-BR"
                                                            ) +
                                                            " " +
                                                            horasDisponiveis[
                                                              horaEscolhida
                                                            ].horaInicio +
                                                            "h - " +
                                                            horasDisponiveis[
                                                              horaEscolhida
                                                            ].horaFim +
                                                            "h",
                                                        desc2: aulas
                                                          ? turmas.find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )?.obs
                                                          : piscinas.find(
                                                              (element) =>
                                                                element.id ==
                                                                idEscolhida
                                                            )?.nome,
                                                        quantidade: 1,
                                                        quantidadeTexto:
                                                          t("quantidade"),
                                                      },
                                                    ];

                                                    if (compraInscricao != 0) {
                                                      produtos.push({
                                                        nomeProduto:
                                                          t("resumo10"),
                                                        precoProduto:
                                                          compraInscricao.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          ),
                                                        desc1: piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                        desc2: "",
                                                        quantidade: 1,
                                                        quantidadeTexto:
                                                          t("quantidade"),
                                                      });
                                                      produtosNum.push({
                                                        nomeProduto:
                                                          t("resumo10"),
                                                        precoProduto:
                                                          compraInscricao,
                                                        desc1: piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                        desc2: "",
                                                        quantidade: 1,
                                                        quantidadeTexto:
                                                          t("quantidade"),
                                                      });
                                                    }
                                                    linhasCompra({
                                                      produtos: produtosNum,
                                                      id_pagamento:
                                                        id_pagamento,
                                                    }).then((res4) => {
                                                      setTimeout(() => {
                                                        emailCompraPackMarcacao(
                                                          {
                                                            datetime:
                                                              dataFormatada +
                                                              " " +
                                                              horasDisponiveis[
                                                                horaEscolhida
                                                              ].horaInicio +
                                                              "h - " +
                                                              horasDisponiveis[
                                                                horaEscolhida
                                                              ].horaFim +
                                                              "h",
                                                            pack: packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.id_pack,
                                                            link: res.data.data
                                                              .PaymentUrl,
                                                            total: compraTotal,
                                                            desconto:
                                                              idDesconto,
                                                            produtos: produtos,
                                                            precoDesconto:
                                                              valorDesconto,
                                                          }
                                                        ).then((res5) => {
                                                          window.location.href =
                                                            paymentURL;
                                                        });
                                                      }, 3000);
                                                    });
                                                  }, 3000);
                                                }
                                              });
                                            }, 3000);
                                          }
                                        });
                                      }, 3000);
                                    }
                                  })
                                : pagamentoCartaoCredito({
                                    id_pack: 0,
                                    id_piscina: idEscolhida,
                                    id_turma: turmaEscolhida,
                                    id_filho: id_filho,
                                    language: i18n.language,
                                    id_preco: anual ? 1 : mensal ? 2 : 0,
                                    id_desconto: idDesconto,
                                  }).then((res) => {
                                    if (res.success) {
                                      let id_pagamento = res.data.id;
                                      setTimeout(async function () {
                                        let produtos = [
                                          {
                                            nomeProduto: !aulas
                                              ? packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.nome +
                                                ` (${
                                                  packs.find(
                                                    (element) =>
                                                      element.id_pack ==
                                                      packEscolhido
                                                  )?.num_tickets
                                                } ${t("tickets")})`
                                              : t("resumo10") +
                                                " - " +
                                                turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.nome,
                                            precoProduto: !aulas
                                              ? compraPreco.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )
                                              : anual
                                              ? turmas
                                                  .find(
                                                    (element) =>
                                                      element.id_turma ==
                                                      turmaEscolhida
                                                  )
                                                  ?.preco_anual.toLocaleString(
                                                    "pt-PT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )
                                              : compraPreco.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                ),
                                            desc1: aulas
                                              ? piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome +
                                                " - " +
                                                anual
                                                ? t("pagamentoAnual")
                                                : t("pagamentoMensal")
                                              : t("utilizacao1Ticket") +
                                                " " +
                                                selectedDay.toLocaleDateString(
                                                  "pt-BR"
                                                ) +
                                                " " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaInicio +
                                                "h - " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaFim +
                                                "h",
                                            desc2: aulas
                                              ? turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.obs
                                              : piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          },
                                        ];

                                        let produtosNum = [
                                          {
                                            nomeProduto: !aulas
                                              ? packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.nome +
                                                ` (${
                                                  packs.find(
                                                    (element) =>
                                                      element.id_pack ==
                                                      packEscolhido
                                                  )?.num_tickets
                                                } ${t("tickets")})`
                                              : t("resumo10") +
                                                " - " +
                                                turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.nome,
                                            precoProduto: !aulas
                                              ? compraPreco
                                              : anual
                                              ? turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.preco_anual
                                              : compraPreco,
                                            desc1: aulas
                                              ? piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome +
                                                " - " +
                                                anual
                                                ? t("pagamentoAnual")
                                                : t("pagamentoMensal")
                                              : t("utilizacao1Ticket") +
                                                " " +
                                                selectedDay.toLocaleDateString(
                                                  "pt-BR"
                                                ) +
                                                " " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaInicio +
                                                "h - " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaFim +
                                                "h",
                                            desc2: aulas
                                              ? turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.obs
                                              : piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          },
                                        ];

                                        if (compraInscricao != 0) {
                                          produtos.push({
                                            nomeProduto: t("resumo10"),
                                            precoProduto:
                                              compraInscricao.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              ),
                                            desc1: piscinas.find(
                                              (element) =>
                                                element.id == idEscolhida
                                            )?.nome,
                                            desc2: "",
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          });
                                          produtosNum.push({
                                            nomeProduto: t("resumo10"),
                                            precoProduto: compraInscricao,
                                            desc1: piscinas.find(
                                              (element) =>
                                                element.id == idEscolhida
                                            )?.nome,
                                            desc2: "",
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          });
                                        }
                                        linhasCompra({
                                          produtos: produtosNum,
                                          id_pagamento: id_pagamento,
                                        }).then((res4) => {
                                          setTimeout(() => {
                                            emailInscricaoTurma({
                                              turma: turmaEscolhida,
                                              link: res.data.data.PaymentUrl,
                                              total: compraTotal,
                                              desconto: idDesconto,
                                              produtos: produtos,
                                              precoDesconto: valorDesconto,
                                              id_cliente: idCliente,
                                              id_filho: id_filho,
                                            }).then((res5) => {
                                              window.location.href =
                                                res.data.data.PaymentUrl;
                                            });
                                          }, 3000);
                                        });
                                      }, 3000);
                                    }
                                  });
                            }
                            setLoading(false)
                          }}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {t("confirmar")}
                        </button>
                      </Stack>
                    )}
                  </Card>
                </Paper>
              </div>
            ) : null}

            {multibanco == true ? (
              <>
                {!loading2 ? (
                  <div className="divTabelaMarcar2">
                    <h1 className="dashboardTitle2">{t("resumo")}</h1>
                    <Paper
                      elevation={0}
                      style={{
                        height: "auto",
                        overflow: "auto",
                        marginTop: 20,
                        backgroundColor: "#f7f7f7",
                        width: "100%",
                      }}
                    >
                      <Dialog
                        open={loading}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogContent>
                          <DialogContentText>{t("msgMb")}</DialogContentText>
                          <DialogContentText
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              disableShrink
                              style={{
                                marginTop: 20,
                              }}
                            />
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packs.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packs.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 10,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {t("multibanco")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{
                              marginRight: 2,
                              marginBottom: 20,
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Card
                              sx={{
                                margin: "8px",
                                justifyContent: "space-between",
                                boxShadow: 0,
                                borderRadius: 15,
                                alignItems: "center",
                                cursor: "pointer",
                                width: "100%",
                                backgroundColor: "lightblue",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                }}
                              >
                                <CardContent
                                  sx={{ flex: "1 0 auto", cursor: "pointer" }}
                                >
                                  {/*TOTAl */}

                                  <Typography className="teste" component="div">
                                    <span>{t("resumo13")}</span>
                                    <span>
                                      {compraTotal.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  </Typography>
                                </CardContent>
                              </Box>
                            </Card>
                            <button
                              type="button"
                              className="regbtn"
                              onClick={() => {
                                setLoading(true);
                                let utilizadorEscolhido = filho
                                  ? users.find(
                                      (element) =>
                                        element.filho == 1 &&
                                        element.id == idUtilizadorEscolhido
                                    )
                                  : users.find(
                                      (element) =>
                                        element.filho == 0 &&
                                        element.id == idUtilizadorEscolhido
                                    );
                                let id_filho = utilizadorEscolhido.filho
                                  ? utilizadorEscolhido.id
                                  : 0;
                                const data = new Date(selectedDay);
                                const dia = data
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0");
                                const mes = (data.getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0");
                                const ano = data.getFullYear();
                                const dataFormatada = `${dia}/${mes}/${ano}`;
                                {
                                  !aulas
                                    ? registarMarcacaoInativa({
                                        id_pack: packEscolhido,
                                        id_piscina: idEscolhida,
                                        id_cliente: idCliente,
                                        data: dataFormatada,
                                        horaInicio:
                                          horasDisponiveis[horaEscolhida]
                                            .horaInicio,
                                        horaFim:
                                          horasDisponiveis[horaEscolhida]
                                            .horaFim,
                                        id_filho: id_filho,
                                      }).then((res2) => {
                                        if (res2.success) {
                                          setTimeout(async function () {
                                            pagamentoMultibanco({
                                              id_pack: packEscolhido,
                                              id_piscina: idEscolhida,
                                              id_turma: 0,
                                              id_filho: id_filho,
                                              language: i18n.language,
                                              id_preco: 0,
                                              id_desconto: idDesconto,
                                            }).then((res) => {
                                              if (res.success) {
                                                setTimeout(async function () {
                                                  let id_marcacao =
                                                    res2.data.id;
                                                  let id_pagamento =
                                                    res.data.id;
                                                  associarPagamentosMarcacoes({
                                                    id_marcacao: id_marcacao,
                                                    id_pagamento: id_pagamento,
                                                  }).then((res3) => {
                                                    if (res3.success) {
                                                      setTimeout(
                                                        async function () {
                                                          let id_marcacao =
                                                            res2.data.id;
                                                          let id_pagamento =
                                                            res.data.id;
                                                          let paymentURL =
                                                            res.data.data
                                                              .PaymentUrl;
                                                          associarPagamentosMarcacoes(
                                                            {
                                                              id_marcacao:
                                                                id_marcacao,
                                                              id_pagamento:
                                                                id_pagamento,
                                                            }
                                                          ).then((res3) => {
                                                            if (res3.success) {
                                                              setTimeout(
                                                                async function () {
                                                                  let produtos =
                                                                    [
                                                                      {
                                                                        nomeProduto:
                                                                          !aulas
                                                                            ? packs.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_pack ==
                                                                                  packEscolhido
                                                                              )
                                                                                ?.nome +
                                                                              ` (${
                                                                                packs.find(
                                                                                  (
                                                                                    element
                                                                                  ) =>
                                                                                    element.id_pack ==
                                                                                    packEscolhido
                                                                                )
                                                                                  ?.num_tickets
                                                                              } ${t(
                                                                                "tickets"
                                                                              )})`
                                                                            : t(
                                                                                "resumo10"
                                                                              ) +
                                                                              " - " +
                                                                              turmas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_turma ==
                                                                                  turmaEscolhida
                                                                              )
                                                                                ?.nome,
                                                                        precoProduto:
                                                                          !aulas
                                                                            ? compraPreco.toLocaleString(
                                                                                "pt-PT",
                                                                                {
                                                                                  style:
                                                                                    "currency",
                                                                                  currency:
                                                                                    "EUR",
                                                                                }
                                                                              )
                                                                            : anual
                                                                            ? turmas
                                                                                .find(
                                                                                  (
                                                                                    element
                                                                                  ) =>
                                                                                    element.id_turma ==
                                                                                    turmaEscolhida
                                                                                )
                                                                                ?.preco_anual.toLocaleString(
                                                                                  "pt-PT",
                                                                                  {
                                                                                    style:
                                                                                      "currency",
                                                                                    currency:
                                                                                      "EUR",
                                                                                  }
                                                                                )
                                                                            : compraPreco.toLocaleString(
                                                                                "pt-PT",
                                                                                {
                                                                                  style:
                                                                                    "currency",
                                                                                  currency:
                                                                                    "EUR",
                                                                                }
                                                                              ),
                                                                        desc1:
                                                                          aulas
                                                                            ? piscinas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id ==
                                                                                  idEscolhida
                                                                              )
                                                                                ?.nome +
                                                                              " - " +
                                                                              anual
                                                                              ? t(
                                                                                  "pagamentoAnual"
                                                                                )
                                                                              : t(
                                                                                  "pagamentoMensal"
                                                                                )
                                                                            : t(
                                                                                "utilizacao1Ticket"
                                                                              ) +
                                                                              " " +
                                                                              selectedDay.toLocaleDateString(
                                                                                "pt-BR"
                                                                              ) +
                                                                              " " +
                                                                              horasDisponiveis[
                                                                                horaEscolhida
                                                                              ]
                                                                                .horaInicio +
                                                                              "h - " +
                                                                              horasDisponiveis[
                                                                                horaEscolhida
                                                                              ]
                                                                                .horaFim +
                                                                              "h",
                                                                        desc2:
                                                                          aulas
                                                                            ? turmas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_turma ==
                                                                                  turmaEscolhida
                                                                              )
                                                                                ?.obs
                                                                            : piscinas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id ==
                                                                                  idEscolhida
                                                                              )
                                                                                ?.nome,
                                                                        quantidade: 1,
                                                                        quantidadeTexto:
                                                                          t(
                                                                            "quantidade"
                                                                          ),
                                                                      },
                                                                    ];

                                                                  let produtosNum =
                                                                    [
                                                                      {
                                                                        nomeProduto:
                                                                          !aulas
                                                                            ? packs.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_pack ==
                                                                                  packEscolhido
                                                                              )
                                                                                ?.nome +
                                                                              ` (${
                                                                                packs.find(
                                                                                  (
                                                                                    element
                                                                                  ) =>
                                                                                    element.id_pack ==
                                                                                    packEscolhido
                                                                                )
                                                                                  ?.num_tickets
                                                                              } ${t(
                                                                                "tickets"
                                                                              )})`
                                                                            : t(
                                                                                "resumo10"
                                                                              ) +
                                                                              " - " +
                                                                              turmas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_turma ==
                                                                                  turmaEscolhida
                                                                              )
                                                                                ?.nome,
                                                                        precoProduto:
                                                                          !aulas
                                                                            ? compraPreco
                                                                            : anual
                                                                            ? turmas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_turma ==
                                                                                  turmaEscolhida
                                                                              )
                                                                                ?.preco_anual
                                                                            : compraPreco,
                                                                        desc1:
                                                                          aulas
                                                                            ? piscinas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id ==
                                                                                  idEscolhida
                                                                              )
                                                                                ?.nome +
                                                                              " - " +
                                                                              anual
                                                                              ? t(
                                                                                  "pagamentoAnual"
                                                                                )
                                                                              : t(
                                                                                  "pagamentoMensal"
                                                                                )
                                                                            : t(
                                                                                "utilizacao1Ticket"
                                                                              ) +
                                                                              " " +
                                                                              selectedDay.toLocaleDateString(
                                                                                "pt-BR"
                                                                              ) +
                                                                              " " +
                                                                              horasDisponiveis[
                                                                                horaEscolhida
                                                                              ]
                                                                                .horaInicio +
                                                                              "h - " +
                                                                              horasDisponiveis[
                                                                                horaEscolhida
                                                                              ]
                                                                                .horaFim +
                                                                              "h",
                                                                        desc2:
                                                                          aulas
                                                                            ? turmas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_turma ==
                                                                                  turmaEscolhida
                                                                              )
                                                                                ?.obs
                                                                            : piscinas.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id ==
                                                                                  idEscolhida
                                                                              )
                                                                                ?.nome,
                                                                        quantidade: 1,
                                                                        quantidadeTexto:
                                                                          t(
                                                                            "quantidade"
                                                                          ),
                                                                      },
                                                                    ];

                                                                  if (
                                                                    compraInscricao !=
                                                                    0
                                                                  ) {
                                                                    produtos.push(
                                                                      {
                                                                        nomeProduto:
                                                                          t(
                                                                            "resumo10"
                                                                          ),
                                                                        precoProduto:
                                                                          compraInscricao.toLocaleString(
                                                                            "pt-PT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "EUR",
                                                                            }
                                                                          ),
                                                                        desc1:
                                                                          piscinas.find(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              element.id ==
                                                                              idEscolhida
                                                                          )
                                                                            ?.nome,
                                                                        desc2:
                                                                          "",
                                                                        quantidade: 1,
                                                                        quantidadeTexto:
                                                                          t(
                                                                            "quantidade"
                                                                          ),
                                                                      }
                                                                    );
                                                                    produtosNum.push(
                                                                      {
                                                                        nomeProduto:
                                                                          t(
                                                                            "resumo10"
                                                                          ),
                                                                        precoProduto:
                                                                          compraInscricao,
                                                                        desc1:
                                                                          piscinas.find(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              element.id ==
                                                                              idEscolhida
                                                                          )
                                                                            ?.nome,
                                                                        desc2:
                                                                          "",
                                                                        quantidade: 1,
                                                                        quantidadeTexto:
                                                                          t(
                                                                            "quantidade"
                                                                          ),
                                                                      }
                                                                    );
                                                                  }
                                                                  linhasCompra({
                                                                    produtos:
                                                                      produtosNum,
                                                                    id_pagamento:
                                                                      id_pagamento,
                                                                  }).then(
                                                                    (res4) => {
                                                                      setTimeout(
                                                                        () => {
                                                                          emailCompraPackMarcacao(
                                                                            {
                                                                              datetime:
                                                                                dataFormatada +
                                                                                " " +
                                                                                horasDisponiveis[
                                                                                  horaEscolhida
                                                                                ]
                                                                                  .horaInicio +
                                                                                "h - " +
                                                                                horasDisponiveis[
                                                                                  horaEscolhida
                                                                                ]
                                                                                  .horaFim +
                                                                                "h",
                                                                              pack: packs.find(
                                                                                (
                                                                                  element
                                                                                ) =>
                                                                                  element.id_pack ==
                                                                                  packEscolhido
                                                                              )
                                                                                ?.id_pack,
                                                                              total:
                                                                                compraTotal,
                                                                              desconto:
                                                                                idDesconto,
                                                                              produtos:
                                                                                produtos,
                                                                              precoDesconto:
                                                                                valorDesconto,
                                                                              entidade:
                                                                                res
                                                                                  .data
                                                                                  .data
                                                                                  .Entity,
                                                                              referencia:
                                                                                res
                                                                                  .data
                                                                                  .data
                                                                                  .Reference,
                                                                              dataExpira:
                                                                                res
                                                                                  .data
                                                                                  .data
                                                                                  .ExpiryDate,
                                                                            }
                                                                          ).then(
                                                                            (
                                                                              res5
                                                                            ) => {
                                                                              setEntidade(
                                                                                res
                                                                                  .data
                                                                                  .data
                                                                                  .Entity
                                                                              );
                                                                              setReferencia(
                                                                                res
                                                                                  .data
                                                                                  .data
                                                                                  .Reference
                                                                              );
                                                                              setDataExpira(
                                                                                res
                                                                                  .data
                                                                                  .data
                                                                                  .ExpiryDate
                                                                              );
                                                                              setLoading(
                                                                                false
                                                                              );
                                                                              setLoading2(
                                                                                true
                                                                              );
                                                                            }
                                                                          );
                                                                        },
                                                                        3000
                                                                      );
                                                                    }
                                                                  );
                                                                },
                                                                3000
                                                              );
                                                            }
                                                          });
                                                        },
                                                        3000
                                                      );
                                                    }
                                                  });
                                                }, 3000);
                                              }
                                            });
                                          }, 3000);
                                        }
                                      })
                                    : pagamentoMultibanco({
                                        id_pack: 0,
                                        id_piscina: idEscolhida,
                                        id_turma: turmaEscolhida,
                                        id_filho: id_filho,
                                        language: i18n.language,
                                        id_preco: anual ? 1 : mensal ? 2 : 0,
                                        id_desconto: idDesconto,
                                      }).then((res) => {
                                        if (res.success) {
                                          let id_pagamento = res.data.id;
                                          setTimeout(async function () {
                                            let produtos = [
                                              {
                                                nomeProduto: !aulas
                                                  ? packs.find(
                                                      (element) =>
                                                        element.id_pack ==
                                                        packEscolhido
                                                    )?.nome +
                                                    ` (${
                                                      packs.find(
                                                        (element) =>
                                                          element.id_pack ==
                                                          packEscolhido
                                                      )?.num_tickets
                                                    } ${t("tickets")})`
                                                  : t("resumo10") +
                                                    " - " +
                                                    turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.nome,
                                                precoProduto: !aulas
                                                  ? compraPreco.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )
                                                  : anual
                                                  ? turmas
                                                      .find(
                                                        (element) =>
                                                          element.id_turma ==
                                                          turmaEscolhida
                                                      )
                                                      ?.preco_anual.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )
                                                  : compraPreco.toLocaleString(
                                                      "pt-PT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    ),
                                                desc1: aulas
                                                  ? piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome +
                                                    " - " +
                                                    anual
                                                    ? t("pagamentoAnual")
                                                    : t("pagamentoMensal")
                                                  : t("utilizacao1Ticket") +
                                                    " " +
                                                    selectedDay.toLocaleDateString(
                                                      "pt-BR"
                                                    ) +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                desc2: aulas
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.obs
                                                  : piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              },
                                            ];

                                            let produtosNum = [
                                              {
                                                nomeProduto: !aulas
                                                  ? packs.find(
                                                      (element) =>
                                                        element.id_pack ==
                                                        packEscolhido
                                                    )?.nome +
                                                    ` (${
                                                      packs.find(
                                                        (element) =>
                                                          element.id_pack ==
                                                          packEscolhido
                                                      )?.num_tickets
                                                    } ${t("tickets")})`
                                                  : t("resumo10") +
                                                    " - " +
                                                    turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.nome,
                                                precoProduto: !aulas
                                                  ? compraPreco
                                                  : anual
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.preco_anual
                                                  : compraPreco,
                                                desc1: aulas
                                                  ? piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome +
                                                    " - " +
                                                    anual
                                                    ? t("pagamentoAnual")
                                                    : t("pagamentoMensal")
                                                  : t("utilizacao1Ticket") +
                                                    " " +
                                                    selectedDay.toLocaleDateString(
                                                      "pt-BR"
                                                    ) +
                                                    " " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaInicio +
                                                    "h - " +
                                                    horasDisponiveis[
                                                      horaEscolhida
                                                    ].horaFim +
                                                    "h",
                                                desc2: aulas
                                                  ? turmas.find(
                                                      (element) =>
                                                        element.id_turma ==
                                                        turmaEscolhida
                                                    )?.obs
                                                  : piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              },
                                            ];

                                            if (compraInscricao != 0) {
                                              produtos.push({
                                                nomeProduto: t("resumo10"),
                                                precoProduto:
                                                  compraInscricao.toLocaleString(
                                                    "pt-PT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  ),
                                                desc1: piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                                desc2: "",
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              });
                                              produtosNum.push({
                                                nomeProduto: t("resumo10"),
                                                precoProduto: compraInscricao,
                                                desc1: piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                                desc2: "",
                                                quantidade: 1,
                                                quantidadeTexto:
                                                  t("quantidade"),
                                              });
                                            }
                                            linhasCompra({
                                              produtos: produtosNum,
                                              id_pagamento: id_pagamento,
                                            }).then((res4) => {
                                              setTimeout(() => {
                                                emailInscricaoTurma({
                                                  turma: turmaEscolhida,
                                                  entidade:
                                                    res.data.data.Entity,
                                                  referencia:
                                                    res.data.data.Reference,
                                                  dataExpira:
                                                    res.data.data.ExpiryDate,
                                                  total: compraTotal,
                                                  desconto: idDesconto,
                                                  produtos: produtos,
                                                  precoDesconto: valorDesconto,
                                                  id_cliente: idCliente,
                                                  id_filho: id_filho,
                                                }).then((res5) => {
                                                  setEntidade(
                                                    res.data.data.Entity
                                                  );
                                                  setReferencia(
                                                    res.data.data.Reference
                                                  );
                                                  setDataExpira(
                                                    res.data.data.ExpiryDate
                                                  );
                                                  setLoading(false);
                                                  setLoading2(true);
                                                });
                                              }, 3000);
                                            });
                                          }, 3000);
                                        }
                                      });
                                }
                                setLoading(false)
                              }}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {t("confirmar")}
                            </button>
                          </Stack>
                        )}
                      </Card>
                    </Paper>
                  </div>
                ) : (
                  <div style={{ textAlign: "left" }}>
                    <form className="form2">
                      <img
                        src={mb}
                        style={{
                          width: "150px",
                          height: "150px",
                          marginTop: 20,
                          marginBottom: 30,
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                      <div>
                        <h2 className="multibanco">
                          {t("entidade")}
                          <span className="spanMb">{entidade}</span>
                        </h2>
                        <h2 className="multibanco">
                          {t("referencia")}{" "}
                          <span className="spanMb">{referencia}</span>
                        </h2>
                        <h2 className="multibanco">
                          {t("valorapagar")}{" "}
                          <span className="spanMb">
                            {compraTotal.toLocaleString("pt-PT", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </span>
                        </h2>
                        <p></p>
                        <h2 className="multibanco">
                          {t("dataLimite")}{" "}
                          <span className="spanMb">{dataExpira}</span>
                        </h2>
                      </div>

                      <p></p>
                    </form>
                  </div>
                )}
              </>
            ) : null}

            {mbway == true ? (
              <>
                {!loading2 ? (
                  <div className="divTabelaMarcar2">
                    <h1 className="dashboardTitle2">{t("resumo")}</h1>
                    <Paper
                      elevation={0}
                      style={{
                        height: "auto",
                        overflow: "auto",
                        marginTop: 20,
                        backgroundColor: "#f7f7f7",
                        width: "100%",
                      }}
                    >
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packs.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packs.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {t("mbway")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{
                              marginRight: 2,
                              marginBottom: 20,
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Card
                              sx={{
                                margin: "8px",
                                justifyContent: "space-between",
                                boxShadow: 0,
                                borderRadius: 10,
                                alignItems: "center",
                                cursor: "pointer",
                                width: "100%",
                                backgroundColor: "lightblue",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                }}
                              >
                                <CardContent
                                  sx={{ flex: "1 0 auto", cursor: "pointer" }}
                                >
                                  {/*TOTAl */}

                                  <Typography className="teste" component="div">
                                    <span>{t("resumo13")}</span>
                                    <span>
                                      {compraTotal.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  </Typography>
                                </CardContent>
                              </Box>
                            </Card>
                            <button
                              type="button"
                              className="regbtn"
                              onClick={() => {
                                setLoading2(true);
                              }}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {t("confirmar")}
                            </button>
                          </Stack>
                        )}
                      </Card>
                    </Paper>
                  </div>
                ) : (
                  <div>
                    <Dialog
                      open={pagar}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogContent>
                        {mbwayAwait ? (
                          <CircularProgress
                            disableShrink
                            style={{ marginTop: 20 }}
                          />
                        ) : (
                          <DialogContentText>{t("msgMbway")}</DialogContentText>
                        )}
                      </DialogContent>
                    </Dialog>

                    <form className="form2">
                      <img
                        src={mbwayfoto}
                        style={{
                          width: "200px",
                          height: "100px",
                          marginBottom: "80px",
                          marginTop: 100,
                        }}
                      />
                      <p>
                        {t("valorapagar")}{" "}
                        {compraTotal.toLocaleString("pt-PT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </p>
                      <PhoneInput
                        defaultCountry="PT"
                        countries={["PT"]}
                        labels={
                          i18n.language == "pt"
                            ? pt
                            : i18n.language == "es"
                            ? es
                            : en
                        }
                        value={telefone}
                        error={erroTelefone}
                        helperText={mensagemErroTelefone}
                        onChange={(e) => {
                          setTelefone(e);
                          setErroTelefone(false);
                          setMensagemErroTelefone("");
                        }}
                        inputComponent={PhoneNumber}
                      />
                    </form>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        marginTop: 10,
                        marginRight: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        type="button"
                        className="regbtn"
                        onClick={() => {
                          if (telefone && !erroTelefone) {
                            setPagar(true);
                            setMbwayAwait(true);
                            let utilizadorEscolhido = filho
                              ? users.find(
                                  (element) =>
                                    element.filho == 1 &&
                                    element.id == idUtilizadorEscolhido
                                )
                              : users.find(
                                  (element) =>
                                    element.filho == 0 &&
                                    element.id == idUtilizadorEscolhido
                                );
                            let id_filho = utilizadorEscolhido.filho
                              ? utilizadorEscolhido.id
                              : 0;
                            const data = new Date(selectedDay);
                            const dia = data
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const mes = (data.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const ano = data.getFullYear();
                            const dataFormatada = `${dia}/${mes}/${ano}`;
                            if (!aulas) {
                              registarMarcacaoInativa({
                                id_pack: packEscolhido,
                                id_piscina: idEscolhida,
                                id_cliente: idCliente,
                                data: dataFormatada,
                                horaInicio:
                                  horasDisponiveis[horaEscolhida].horaInicio,
                                horaFim:
                                  horasDisponiveis[horaEscolhida].horaFim,
                                id_filho: id_filho,
                              }).then((res2) => {
                                if (res2.success) {
                                  setTimeout(async function () {
                                    pagamentoMbway({
                                      id_pack: 1,
                                      id_piscina: idEscolhida,
                                      id_turma: 0,
                                      id_filho: id_filho,
                                      preco: 0,
                                      telemovel: telefone,
                                      id_desconto: idDesconto,
                                    }).then((res) => {
                                      if (res.success) {
                                        setTimeout(async function () {
                                          let id_marcacao = res2.data.id;
                                          let id_pagamento = res.data.id;
                                          associarPagamentosMarcacoes({
                                            id_marcacao: id_marcacao,
                                            id_pagamento: id_pagamento,
                                          }).then((res3) => {
                                            if (res3.success) {
                                              setTimeout(async function () {
                                                let produtos = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id_pack ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        )
                                                      : anual
                                                      ? turmas
                                                          .find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )
                                                          ?.preco_anual.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                      : compraPreco.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                let produtosNum = [
                                                  {
                                                    nomeProduto: !aulas
                                                      ? packs.find(
                                                          (element) =>
                                                            element.id_pack ==
                                                            packEscolhido
                                                        )?.nome +
                                                        ` (${
                                                          packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.num_tickets
                                                        } ${t("tickets")})`
                                                      : t("resumo10") +
                                                        " - " +
                                                        turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.nome,
                                                    precoProduto: !aulas
                                                      ? compraPreco
                                                      : anual
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.preco_anual
                                                      : compraPreco,
                                                    desc1: aulas
                                                      ? piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome +
                                                        " - " +
                                                        anual
                                                        ? t("pagamentoAnual")
                                                        : t("pagamentoMensal")
                                                      : t("utilizacao1Ticket") +
                                                        " " +
                                                        selectedDay.toLocaleDateString(
                                                          "pt-BR"
                                                        ) +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                    desc2: aulas
                                                      ? turmas.find(
                                                          (element) =>
                                                            element.id_turma ==
                                                            turmaEscolhida
                                                        )?.obs
                                                      : piscinas.find(
                                                          (element) =>
                                                            element.id ==
                                                            idEscolhida
                                                        )?.nome,
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  },
                                                ];

                                                if (compraInscricao != 0) {
                                                  produtos.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao.toLocaleString(
                                                        "pt-PT",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      ),
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                  produtosNum.push({
                                                    nomeProduto: t("resumo10"),
                                                    precoProduto:
                                                      compraInscricao,
                                                    desc1: piscinas.find(
                                                      (element) =>
                                                        element.id ==
                                                        idEscolhida
                                                    )?.nome,
                                                    desc2: "",
                                                    quantidade: 1,
                                                    quantidadeTexto:
                                                      t("quantidade"),
                                                  });
                                                }
                                                linhasCompra({
                                                  produtos: produtosNum,
                                                  id_pagamento: id_pagamento,
                                                }).then((res4) => {
                                                  setTimeout(() => {
                                                    emailCompraPackMarcacao({
                                                      telemovel: telefone,
                                                      datetime:
                                                        dataFormatada +
                                                        " " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaInicio +
                                                        "h - " +
                                                        horasDisponiveis[
                                                          horaEscolhida
                                                        ].horaFim +
                                                        "h",
                                                      pack: packs.find(
                                                        (element) =>
                                                          element.id_pack ==
                                                          packEscolhido
                                                      )?.id_pack,
                                                      total: compraTotal,
                                                      desconto: idDesconto,
                                                      produtos: produtos,
                                                      precoDesconto:
                                                        valorDesconto,
                                                    }).then((res5) => {
                                                      setMbwayAwait(false);
                                                    });
                                                  }, 3000);
                                                });
                                              }, 3000);
                                            }
                                          });
                                        }, 3000);
                                      } else {
                                        toast.error(t("telefoneinvalido"));
                                      }
                                    });
                                  }, 3000);
                                }
                              });
                            } else {
                              setPagar(true);
                              setMbwayAwait(true);
                              setTimeout(async function () {
                                pagamentoMbway({
                                  id_pack: 0,
                                  id_piscina: idEscolhida,
                                  id_turma: turmaEscolhida,
                                  id_filho: id_filho,
                                  telemovel: telefone,
                                  id_desconto: idDesconto,
                                  id_preco: anual ? 1 : mensal ? 2 : 0,
                                }).then((res) => {
                                  if (res.success) {
                                    let id_pagamento = res.data.id;
                                    setTimeout(async function () {
                                      let produtos = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              )
                                            : anual
                                            ? turmas
                                                .find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )
                                                ?.preco_anual.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )
                                            : compraPreco.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              ),
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      let produtosNum = [
                                        {
                                          nomeProduto: !aulas
                                            ? packs.find(
                                                (element) =>
                                                  element.id_pack ==
                                                  packEscolhido
                                              )?.nome +
                                              ` (${
                                                packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.num_tickets
                                              } ${t("tickets")})`
                                            : t("resumo10") +
                                              " - " +
                                              turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.nome,
                                          precoProduto: !aulas
                                            ? compraPreco
                                            : anual
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.preco_anual
                                            : compraPreco,
                                          desc1: aulas
                                            ? piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome +
                                              " - " +
                                              anual
                                              ? t("pagamentoAnual")
                                              : t("pagamentoMensal")
                                            : t("utilizacao1Ticket") +
                                              " " +
                                              selectedDay.toLocaleDateString(
                                                "pt-BR"
                                              ) +
                                              " " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaInicio +
                                              "h - " +
                                              horasDisponiveis[horaEscolhida]
                                                .horaFim +
                                              "h",
                                          desc2: aulas
                                            ? turmas.find(
                                                (element) =>
                                                  element.id_turma ==
                                                  turmaEscolhida
                                              )?.obs
                                            : piscinas.find(
                                                (element) =>
                                                  element.id == idEscolhida
                                              )?.nome,
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        },
                                      ];

                                      if (compraInscricao != 0) {
                                        produtos.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto:
                                            compraInscricao.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            ),
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                        produtosNum.push({
                                          nomeProduto: t("resumo10"),
                                          precoProduto: compraInscricao,
                                          desc1: piscinas.find(
                                            (element) =>
                                              element.id == idEscolhida
                                          )?.nome,
                                          desc2: "",
                                          quantidade: 1,
                                          quantidadeTexto: t("quantidade"),
                                        });
                                      }
                                      linhasCompra({
                                        produtos: produtosNum,
                                        id_pagamento: id_pagamento,
                                      }).then((res4) => {
                                        setTimeout(() => {
                                          emailInscricaoTurma({
                                            turma: turmaEscolhida,
                                            telemovel: telefone,
                                            total: compraTotal,
                                            desconto: idDesconto,
                                            produtos: produtos,
                                            precoDesconto: valorDesconto,
                                            id_cliente: idCliente,
                                            id_filho: id_filho,
                                          }).then((res5) => {
                                            setMbwayAwait(false);
                                          });
                                        }, 3000);
                                      });
                                    }, 3000);
                                  } else {
                                    toast.error(t("telefoneinvalido"));
                                  }
                                });
                              }, 3000);
                            }
                          }
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("pagar")}
                      </button>
                    </Stack>
                  </div>
                )}
              </>
            ) : null}
            {balcao == true ? (
              <>
                {!loading2 ? (
                  <div className="divTabelaMarcar2">
                    <h1 className="dashboardTitle2">{t("resumo")}</h1>
                    <Paper
                      elevation={0}
                      style={{
                        height: "auto",
                        overflow: "auto",
                        marginTop: 20,
                        backgroundColor: "#f7f7f7",
                        width: "100%",
                      }}
                    >
                      <Card
                        className="cardDashboardResumo"
                        variant="contained"
                        disabled
                        sx={{ paddingRight: 5 }}
                      >
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 10,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              {/* NOME PACK/TURMA */}
                              <Typography className="teste" component="div">
                                <span>
                                  {!aulas
                                    ? packs.find(
                                        (element) =>
                                          element.id_pack == packEscolhido
                                      )?.nome +
                                      ` (${
                                        packs.find(
                                          (element) =>
                                            element.id_pack == packEscolhido
                                        )?.num_tickets
                                      } ${t("tickets")})`
                                    : t("resumo10") +
                                      " - " +
                                      turmas.find(
                                        (element) =>
                                          element.id_turma == turmaEscolhida
                                      )?.nome}
                                </span>
                                {!isMobile ? (
                                  !aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )
                                ) : null}
                              </Typography>
                              {!isMobile ? null : (
                                <Typography className="teste" component="div">
                                  {!aulas ? (
                                    <span>
                                      {compraPreco.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  ) : (
                                    <span>
                                      {anual ? (
                                        <span>
                                          {turmas
                                            .find(
                                              (element) =>
                                                element.id_turma ==
                                                turmaEscolhida
                                            )
                                            ?.preco_anual.toLocaleString(
                                              "pt-PT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>
                                          {compraPreco.toLocaleString("pt-PT", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                }}
                              >
                                {
                                  piscinas.find(
                                    (element) => element.id == idEscolhida
                                  )?.nome
                                }
                              </Typography>
                              {aulas && (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                  }}
                                >
                                  {anual
                                    ? t("pagamentoAnual")
                                    : t("pagamentoMensal")}
                                </Typography>
                              )}
                              {!aulas ? (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                  }}
                                >
                                  {t("utilizacao1Ticket")}:{" "}
                                  {selectedDay.toLocaleDateString("pt-BR")}{" "}
                                  {horasDisponiveis[horaEscolhida].horaInicio}h
                                  - {horasDisponiveis[horaEscolhida].horaFim}h
                                </Typography>
                              ) : (
                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 5,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    turmas.find(
                                      (element) =>
                                        element.id_turma == turmaEscolhida
                                    )?.obs
                                  }
                                </Typography>
                              )}
                            </CardContent>
                          </Box>
                        </Card>

                        {compraInscricao != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/*INSCRIÇÃO */}

                                <Typography className="teste" component="div">
                                  <span>{t("resumo10")}</span>
                                  <span>
                                    {compraInscricao.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {
                                    piscinas.find(
                                      (element) => element.id == idEscolhida
                                    )?.nome
                                  }
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        {desconto != 0 && (
                          <Card
                            sx={{
                              margin: "8px",
                              justifyContent: "space-between",
                              boxShadow: 0,
                              borderRadius: 15,
                              alignItems: "center",
                              cursor: "pointer",
                              minWidth: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            >
                              <CardContent
                                sx={{ flex: "1 0 auto", cursor: "pointer" }}
                              >
                                {/* DESCONTO */}
                                <Typography className="teste" component="div">
                                  <span>
                                    {t("desconto")} {desconto}%
                                  </span>
                                  <span>
                                    -{" "}
                                    {valorDesconto.toLocaleString("pt-PT", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}{" "}
                                    €
                                  </span>
                                </Typography>

                                <Typography
                                  className="teste"
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  style={{
                                    textTransform: "initial",
                                    textAlign: "left",
                                    marginTop: 30,
                                    marginLeft: 20,
                                  }}
                                >
                                  {descricaoDesconto}
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                        <Card
                          sx={{
                            margin: "8px",
                            justifyContent: "space-between",
                            boxShadow: 0,
                            borderRadius: 15,
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: "100%",
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            <CardContent
                              sx={{ flex: "1 0 auto", cursor: "pointer" }}
                            >
                              <Typography className="teste" component="div">
                                <span>{t("metodoPagamento")}</span>
                              </Typography>

                              <Typography
                                className="teste"
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                style={{
                                  textTransform: "initial",
                                  textAlign: "left",
                                  marginTop: 30,
                                  marginLeft: 20,
                                }}
                              >
                                {t("balcao")}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                        {compraTotal && (
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{
                              marginRight: 2,
                              marginBottom: 20,
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Dialog
                              open={popupBalcao}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <DialogContent style={{ textAlign: "center" }}>
                                <CircularProgress
                                  disableShrink
                                  style={{
                                    marginTop: 20,
                                  }}
                                />
                                <DialogContentText>
                                  {t("aguarda")}
                                </DialogContentText>
                              </DialogContent>
                            </Dialog>

                            <Card
                              sx={{
                                margin: "8px",
                                justifyContent: "space-between",
                                boxShadow: 0,
                                borderRadius: 10,
                                alignItems: "center",
                                cursor: "pointer",
                                width: "100%",
                                backgroundColor: "lightblue",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                }}
                              >
                                <CardContent
                                  sx={{ flex: "1 0 auto", cursor: "pointer" }}
                                >
                                  {/*TOTAl */}

                                  <Typography className="teste" component="div">
                                    <span>{t("resumo13")}</span>
                                    <span>
                                      {compraTotal.toLocaleString("pt-PT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </span>
                                  </Typography>
                                </CardContent>
                              </Box>
                            </Card>
                            <button
                              type="button"
                              className="regbtn"
                              onClick={() => {
                                setPopupBalcao(true);
                                let utilizadorEscolhido = filho
                                  ? users.find(
                                      (element) =>
                                        element.filho == 1 &&
                                        element.id == idUtilizadorEscolhido
                                    )
                                  : users.find(
                                      (element) =>
                                        element.filho == 0 &&
                                        element.id == idUtilizadorEscolhido
                                    );
                                let id_filho = utilizadorEscolhido.filho
                                  ? utilizadorEscolhido.id
                                  : 0;
                                const data = new Date(selectedDay);
                                const dia = data
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0");
                                const mes = (data.getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0");
                                const ano = data.getFullYear();
                                const dataFormatada = `${dia}/${mes}/${ano}`;

                                if (!aulas) {
                                  registarMarcacaoInativa({
                                    id_pack: packEscolhido,
                                    id_piscina: idEscolhida,
                                    id_cliente: idCliente,
                                    data: dataFormatada,
                                    horaInicio:
                                      horasDisponiveis[horaEscolhida]
                                        .horaInicio,
                                    horaFim:
                                      horasDisponiveis[horaEscolhida].horaFim,
                                    id_filho: id_filho,
                                  }).then((res2) => {
                                    if (res2.success) {
                                      setTimeout(async function () {
                                        marcacaoBalcao({
                                          id_pack: 1,
                                          id_piscina: idEscolhida,
                                          id_cliente: idCliente,
                                          id_turma: 0,
                                          id_filho: id_filho,
                                          preco: 0,
                                          id_desconto: idDesconto,
                                        }).then((res) => {
                                          if (res.success) {
                                            setTimeout(async function () {
                                              let id_marcacao = res2.data.id;
                                              let id_pagamento = res.data.id;
                                              associarPagamentosMarcacoes({
                                                id_marcacao: id_marcacao,
                                                id_pagamento: id_pagamento,
                                              }).then((res3) => {
                                                setTimeout(async function () {
                                                  let produtos = [
                                                    {
                                                      nomeProduto: !aulas
                                                        ? packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.nome +
                                                          ` (${
                                                            packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.num_tickets
                                                          } ${t("tickets")})`
                                                        : t("resumo10") +
                                                          " - " +
                                                          turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.nome,
                                                      precoProduto: !aulas
                                                        ? compraPreco.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          )
                                                        : anual
                                                        ? turmas
                                                            .find(
                                                              (element) =>
                                                                element.id_turma ==
                                                                turmaEscolhida
                                                            )
                                                            ?.preco_anual.toLocaleString(
                                                              "pt-PT",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "EUR",
                                                              }
                                                            )
                                                        : compraPreco.toLocaleString(
                                                            "pt-PT",
                                                            {
                                                              style: "currency",
                                                              currency: "EUR",
                                                            }
                                                          ),
                                                      desc1: aulas
                                                        ? piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome +
                                                          " - " +
                                                          anual
                                                          ? t("pagamentoAnual")
                                                          : t("pagamentoMensal")
                                                        : t(
                                                            "utilizacao1Ticket"
                                                          ) +
                                                          " " +
                                                          selectedDay.toLocaleDateString(
                                                            "pt-BR"
                                                          ) +
                                                          " " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaInicio +
                                                          "h - " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaFim +
                                                          "h",
                                                      desc2: aulas
                                                        ? turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.obs
                                                        : piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome,
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    },
                                                  ];

                                                  let produtosNum = [
                                                    {
                                                      nomeProduto: !aulas
                                                        ? packs.find(
                                                            (element) =>
                                                              element.id_pack ==
                                                              packEscolhido
                                                          )?.nome +
                                                          ` (${
                                                            packs.find(
                                                              (element) =>
                                                                element.id_pack ==
                                                                packEscolhido
                                                            )?.num_tickets
                                                          } ${t("tickets")})`
                                                        : t("resumo10") +
                                                          " - " +
                                                          turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.nome,
                                                      precoProduto: !aulas
                                                        ? compraPreco
                                                        : anual
                                                        ? turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.preco_anual
                                                        : compraPreco,
                                                      desc1: aulas
                                                        ? piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome +
                                                          " - " +
                                                          anual
                                                          ? t("pagamentoAnual")
                                                          : t("pagamentoMensal")
                                                        : t(
                                                            "utilizacao1Ticket"
                                                          ) +
                                                          " " +
                                                          selectedDay.toLocaleDateString(
                                                            "pt-BR"
                                                          ) +
                                                          " " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaInicio +
                                                          "h - " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaFim +
                                                          "h",
                                                      desc2: aulas
                                                        ? turmas.find(
                                                            (element) =>
                                                              element.id_turma ==
                                                              turmaEscolhida
                                                          )?.obs
                                                        : piscinas.find(
                                                            (element) =>
                                                              element.id ==
                                                              idEscolhida
                                                          )?.nome,
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    },
                                                  ];

                                                  if (compraInscricao != 0) {
                                                    produtos.push({
                                                      nomeProduto:
                                                        t("resumo10"),
                                                      precoProduto:
                                                        compraInscricao.toLocaleString(
                                                          "pt-PT",
                                                          {
                                                            style: "currency",
                                                            currency: "EUR",
                                                          }
                                                        ),
                                                      desc1: piscinas.find(
                                                        (element) =>
                                                          element.id ==
                                                          idEscolhida
                                                      )?.nome,
                                                      desc2: "",
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    });
                                                    produtosNum.push({
                                                      nomeProduto:
                                                        t("resumo10"),
                                                      precoProduto:
                                                        compraInscricao,
                                                      desc1: piscinas.find(
                                                        (element) =>
                                                          element.id ==
                                                          idEscolhida
                                                      )?.nome,
                                                      desc2: "",
                                                      quantidade: 1,
                                                      quantidadeTexto:
                                                        t("quantidade"),
                                                    });
                                                  }
                                                  linhasCompra({
                                                    produtos: produtosNum,
                                                    id_pagamento: id_pagamento,
                                                  }).then((res4) => {
                                                    setTimeout(() => {
                                                      emailCompraPackMarcacao({
                                                        dataExpira: `${new Date(
                                                          new Date().setDate(
                                                            new Date().getDate() +
                                                              1
                                                          )
                                                        )
                                                          .getDate()
                                                          .toString()
                                                          .padStart(2, "0")}-${(
                                                          new Date(
                                                            new Date().setDate(
                                                              new Date().getDate() +
                                                                1
                                                            )
                                                          ).getMonth() + 1
                                                        )
                                                          .toString()
                                                          .padStart(
                                                            2,
                                                            "0"
                                                          )}-${new Date(
                                                          new Date().setDate(
                                                            new Date().getDate() +
                                                              1
                                                          )
                                                        ).getFullYear()}`,
                                                        datetime:
                                                          dataFormatada +
                                                          " " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaInicio +
                                                          "h - " +
                                                          horasDisponiveis[
                                                            horaEscolhida
                                                          ].horaFim +
                                                          "h",
                                                        pack: packs.find(
                                                          (element) =>
                                                            element.id_pack ==
                                                            packEscolhido
                                                        )?.id_pack,
                                                        total: compraTotal,
                                                        desconto: idDesconto,
                                                        produtos: produtos,
                                                        precoDesconto:
                                                          valorDesconto,
                                                      }).then((res5) => {
                                                        setPopupBalcao(false);
                                                        setLoading2(true);
                                                      });
                                                    }, 3000);
                                                  });
                                                }, 3000);
                                              });
                                            }, 3000);
                                          }
                                        });
                                      }, 3000);
                                    }
                                  });
                                } else {
                                  setTimeout(async function () {
                                    marcacaoBalcao({
                                      id_pack: 0,
                                      id_piscina: idEscolhida,
                                      id_turma: turmaEscolhida,
                                      id_cliente: idCliente,
                                      id_filho: id_filho,
                                      id_preco: anual ? 1 : mensal ? 2 : 0,
                                      id_desconto: idDesconto,
                                    }).then((res) => {
                                      let id_pagamento = res.data.id;
                                      setTimeout(async function () {
                                        let produtos = [
                                          {
                                            nomeProduto: !aulas
                                              ? packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.nome +
                                                ` (${
                                                  packs.find(
                                                    (element) =>
                                                      element.id_pack ==
                                                      packEscolhido
                                                  )?.num_tickets
                                                } ${t("tickets")})`
                                              : t("resumo10") +
                                                " - " +
                                                turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.nome,
                                            precoProduto: !aulas
                                              ? compraPreco.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )
                                              : anual
                                              ? turmas
                                                  .find(
                                                    (element) =>
                                                      element.id_turma ==
                                                      turmaEscolhida
                                                  )
                                                  ?.preco_anual.toLocaleString(
                                                    "pt-PT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )
                                              : compraPreco.toLocaleString(
                                                  "pt-PT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                ),
                                            desc1: aulas
                                              ? piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome +
                                                " - " +
                                                anual
                                                ? t("pagamentoAnual")
                                                : t("pagamentoMensal")
                                              : t("utilizacao1Ticket") +
                                                " " +
                                                selectedDay.toLocaleDateString(
                                                  "pt-BR"
                                                ) +
                                                " " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaInicio +
                                                "h - " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaFim +
                                                "h",
                                            desc2: aulas
                                              ? turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.obs
                                              : piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          },
                                        ];

                                        let produtosNum = [
                                          {
                                            nomeProduto: !aulas
                                              ? packs.find(
                                                  (element) =>
                                                    element.id_pack ==
                                                    packEscolhido
                                                )?.nome +
                                                ` (${
                                                  packs.find(
                                                    (element) =>
                                                      element.id_pack ==
                                                      packEscolhido
                                                  )?.num_tickets
                                                } ${t("tickets")})`
                                              : t("resumo10") +
                                                " - " +
                                                turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.nome,
                                            precoProduto: !aulas
                                              ? compraPreco
                                              : anual
                                              ? turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.preco_anual
                                              : compraPreco,
                                            desc1: aulas
                                              ? piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome +
                                                " - " +
                                                anual
                                                ? t("pagamentoAnual")
                                                : t("pagamentoMensal")
                                              : t("utilizacao1Ticket") +
                                                " " +
                                                selectedDay.toLocaleDateString(
                                                  "pt-BR"
                                                ) +
                                                " " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaInicio +
                                                "h - " +
                                                horasDisponiveis[horaEscolhida]
                                                  .horaFim +
                                                "h",
                                            desc2: aulas
                                              ? turmas.find(
                                                  (element) =>
                                                    element.id_turma ==
                                                    turmaEscolhida
                                                )?.obs
                                              : piscinas.find(
                                                  (element) =>
                                                    element.id == idEscolhida
                                                )?.nome,
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          },
                                        ];

                                        if (compraInscricao != 0) {
                                          produtos.push({
                                            nomeProduto: t("resumo10"),
                                            precoProduto:
                                              compraInscricao.toLocaleString(
                                                "pt-PT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              ),
                                            desc1: piscinas.find(
                                              (element) =>
                                                element.id == idEscolhida
                                            )?.nome,
                                            desc2: "",
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          });
                                          produtosNum.push({
                                            nomeProduto: t("resumo10"),
                                            precoProduto: compraInscricao,
                                            desc1: piscinas.find(
                                              (element) =>
                                                element.id == idEscolhida
                                            )?.nome,
                                            desc2: "",
                                            quantidade: 1,
                                            quantidadeTexto: t("quantidade"),
                                          });
                                        }
                                        linhasCompra({
                                          produtos: produtosNum,
                                          id_pagamento: id_pagamento,
                                        }).then((res4) => {
                                          setTimeout(() => {
                                            emailInscricaoTurma({
                                              turma: turmaEscolhida,
                                              id_cliente: idCliente,
                                              id_filho: id_filho,
                                              dataExpira: `${new Date(
                                                new Date().setDate(
                                                  new Date().getDate() + 1
                                                )
                                              )
                                                .getDate()
                                                .toString()
                                                .padStart(2, "0")}-${(
                                                new Date(
                                                  new Date().setDate(
                                                    new Date().getDate() + 1
                                                  )
                                                ).getMonth() + 1
                                              )
                                                .toString()
                                                .padStart(2, "0")}-${new Date(
                                                new Date().setDate(
                                                  new Date().getDate() + 1
                                                )
                                              ).getFullYear()}`,
                                              total: compraTotal,
                                              desconto: idDesconto,
                                              produtos: produtos,
                                              precoDesconto: valorDesconto,
                                            }).then((res5) => {
                                              setPopupBalcao(false);
                                              setLoading2(true);
                                            });
                                          }, 3000);
                                        });
                                      }, 3000);
                                    });
                                  }, 3000);
                                }
                              }}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {t("confirmar")}
                            </button>
                          </Stack>
                        )}
                      </Card>
                    </Paper>
                  </div>
                ) : (
                  <div style={{ paddingTop: "100px" }}>
                    <h1
                      className="dashboardTitle2"
                      style={{ paddingBottom: "20px" }}
                    >
                      {t("faltaPouco")}
                    </h1>
                    <Button
                      className="cardDashboardMarcar4"
                      variant="contained"
                      style={{
                        backgroundColor: Colors.ativo,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      disabled
                    >
                      <div style={{ display: "block" }}>
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faFaceSmile}
                        />
                      </div>
                      <span
                        style={{
                          marginTop: "8px",
                          fontSize: isMobile ? "10px" : "14px",
                        }}
                      >
                        {t("mensagembalcao")}
                      </span>
                    </Button>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        marginTop: 10,
                        marginRight: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        type="button"
                        className="regbtn"
                        onClick={() => {
                          navigate("/editcustomerf", {
                            state: {
                              idPiscina: idPiscina,
                              idCliente: idCliente,
                            },
                          });
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("terminar")}
                      </button>
                    </Stack>
                  </div>
                )}
              </>
            ) : null}
            {/* {aulas === false && pagapack == true ? <h1>{t("resumo")}</h1> : null} */}
          </div>
        </div>
      ),
    },
    {
      description: (
        <div id="8">
          {packUtilizadorEscolhido ? (
            <div>
              <h1
                className="dashboardTitle2"
                style={{
                  marginTop: isMobile ? 60 : 30,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {t("resumo")}
              </h1>
              <Dialog open={loading} aria-labelledby="responsive-dialog-title">
                {!marcacaoConcluida ? (
                  <DialogContent>
                    <DialogContentText>
                      {t("aguardaMarcacao")}
                    </DialogContentText>
                    <DialogContentText
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        disableShrink
                        style={{
                          marginTop: 20,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                ) : (
                  <DialogContent>
                    {marcacaoConcluida2 ? (
                      <div>
                        <svg
                          className="checkmark"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 52 52"
                        >
                          <circle
                            className="checkmark__circle"
                            cx="30"
                            cy="30"
                            r="40"
                            fill="none !important"
                          />
                          <path
                            className="checkmark__check"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                          />
                        </svg>

                        <p
                          className="ativarconta"
                          style={{ marginBottom: "70px" }}
                        >
                          {t("marcacaoSucesso")}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <svg
                          className="crossmark"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 52 52"
                        >
                          <circle
                            className="crossmark__circle"
                            cx="30"
                            cy="30"
                            r="40"
                            fill="none !important"
                          />
                          <path
                            className="crossmark__check"
                            fill="none"
                            d="M16 16 36 36 M36 16 16 36"
                          />
                        </svg>

                        <p
                          className="ativarconta"
                          style={{ marginBottom: "70px" }}
                        >
                          {t("marcacaoErro")}
                        </p>
                      </div>
                    )}
                  </DialogContent>
                )}
              </Dialog>
              {/* <Button
                className="cardDashboardResumo"
                variant="contained"
                disabled
              >
                <Card
                  sx={{
                    margin: "8px",
                    justifyContent: "space-between",
                    boxShadow: 0,
                    borderRadius: 15,
                    alignItems: "center",
                    cursor: "pointer",
                    minWidth: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                  >
                    <CardContent sx={{ flex: "1 0 auto", cursor: "pointer" }}>
                      <Typography className="teste" component="div">
                        <span>
                          {listaPacks.find(
                            (element) =>
                              element.id_pack_cliente == packUtilizadorEscolhido
                          )?.nomePack +
                            ` (${
                              listaPacks.find(
                                (element) =>
                                  element.id_pack_cliente ==
                                  packUtilizadorEscolhido
                              )?.num_tickets
                            } ${t("tickets")})`}
                        </span>
                        {erWidth > 600 ? (
                          <span>
                            {compraPreco.toLocaleString("pt-PT", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </span>
                        ) : null}
                      </Typography>
                      {innerWidth.width > 600 ? null : (
                        <Typography className="teste" component="div">
                          <span>
                            {compraPreco.toLocaleString("pt-PT", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </span>
                        </Typography>
                      )}
                      <Typography
                        className="teste"
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                        style={{
                          textTransform: "initial",
                          textAlign: "left",
                          marginTop: 30,
                          marginLeft: 20,
                        }}
                      >
                        <span>
                          {t("ticketsUtilizados")}:{" "}
                          {
                            listaPacks.find(
                              (element) =>
                                element.id_pack_cliente ==
                                packUtilizadorEscolhido
                            )?.num_tickets_utilizados
                          }{" "}
                        </span>
                      </Typography>
                      <Typography
                        className="teste"
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                        style={{
                          textTransform: "initial",
                          textAlign: "left",
                          marginTop: 30,
                          marginLeft: 20,
                        }}
                      >
                        {
                          piscinas.find((element) => element.id == idEscolhida)
                            ?.nome
                        }
                      </Typography>
                      <Typography
                        className="teste"
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                        style={{
                          textTransform: "initial",
                          textAlign: "left",
                          marginTop: 5,
                          marginLeft: 20,
                        }}
                      >
                        {t("utilizacao1Ticket")}:{" "}
                        {selectedDay.toLocaleDateString("pt-BR")}{" "}
                        {horasDisponiveis[horaEscolhida].horaInicio}h -{" "}
                        {horasDisponiveis[horaEscolhida].horaFim}h
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
                
                {compraTotal == 0 && (
                  <Card
                    sx={{
                      margin: "12px",
                      justifyContent: "space-between",
                      boxShadow: 0,
                      borderRadius: 10,
                      alignItems: "center",
                      cursor: "pointer",
                      minWidth: "100%",
                      backgroundColor: "lightblue",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 5,
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto", cursor: "pointer" }}>
                        <Typography className="teste" component="div">
                          <span>{t("resumo13")}</span>
                          <span>
                            {compraTotal.toLocaleString("pt-PT", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </span>
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                )}
              </Button>

              <Stack
                direction={"row"}
                spacing={2}
                sx={{ marginRight: 2, justifyContent: "flex-end" }}
              >
  
                <button
                  type="button"
                  className="regbtn"
                  onClick={() => {
                    setLoading(true);
                    let utilizadorEscolhido = filho
                      ? users.find(
                          (element) =>
                            element.filho == 1 &&
                            element.id == idUtilizadorEscolhido
                        )
                      : users.find(
                          (element) =>
                            element.filho == 0 &&
                            element.id == idUtilizadorEscolhido
                        );
                    let id_filho = utilizadorEscolhido.filho
                      ? utilizadorEscolhido.id
                      : 0;
                    const data = new Date(selectedDay);
                    const dia = data.getDate().toString().padStart(2, "0");
                    const mes = (data.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const ano = data.getFullYear();
                    const dataFormatada = `${dia}/${mes}/${ano}`;
                    registarMarcacao({
                      id_pack: packUtilizadorEscolhido,
                      id_piscina: idEscolhida,
                      data: dataFormatada,
                      horaInicio: horasDisponiveis[horaEscolhida].horaInicio,
                      horaFim: horasDisponiveis[horaEscolhida].horaFim,
                      id_filho: id_filho,
                    }).then((res) => {
                      if (res.success) {
                        setTimeout(async function () {
                          emailMarcacao({
                            pack: packUtilizadorEscolhido,
                            datetime:
                              dataFormatada +
                              " " +
                              horasDisponiveis[horaEscolhida].horaInicio +
                              "h - " +
                              horasDisponiveis[horaEscolhida].horaFim +
                              "h",
                          }).then((res) => {
                            setMarcacaoConcluida(true);
                            setMarcacaoConcluida2(true);
                            setTimeout(() => {
                              let utilizador = Cookies.get("utilizador");
                              if (utilizador == "superadmin") {
                                navigate("/dashboard_superadmin");
                              } else if (utilizador == "supercomercial") {
                                navigate("/dashboard_supercommercial");
                              } else if (utilizador == "comercial") {
                                navigate("/dashboard_commercial");
                              } else if (utilizador == "admin") {
                                navigate("/dashboard_admin");
                              } else if (utilizador == "funcionario") {
                                navigate("/editcustomerf", {
                                  state: {
                                    idPiscina: idPiscina,
                                    idCliente: idCliente,
                                  },
                                });
                              } else if (utilizador == "professor") {
                                navigate("/dashboard_teacher");
                              } else if (utilizador == "cliente") {
                                navigate("/dashboard_customer");
                              } else {
                                navigate("/dashboard_customer");
                              }
                            }, 6000);
                          });
                        }, 3000);
                      } else {
                        setMarcacaoConcluida2(false);
                        setTimeout(() => {
                          let utilizador = Cookies.get("utilizador");
                          if (utilizador == "superadmin") {
                            navigate("/dashboard_superadmin");
                          } else if (utilizador == "supercomercial") {
                            navigate("/dashboard_supercommercial");
                          } else if (utilizador == "comercial") {
                            navigate("/dashboard_commercial");
                          } else if (utilizador == "admin") {
                            navigate("/dashboard_admin");
                          } else if (utilizador == "funcionario") {
                            navigate("/editcustomerf", {
                              state: {
                                idPiscina: idPiscina,
                                idCliente: idCliente,
                              },
                            });
                          } else if (utilizador == "professor") {
                            navigate("/dashboard_teacher");
                          } else if (utilizador == "cliente") {
                            navigate("/dashboard_customer");
                          } else {
                            navigate("/dashboard_customer");
                          }
                        }, 6000);
                      }
                    });
                  }}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {t("marcar")}
                </button>
              </Stack> */}
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="listPages" style={{ overflow: "auto" }}>
        <Header />
        <div className="topleftcliente">
          <IconButton onClick={goBack} size={isMobile ? "small" : "medium"}>
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div>
        {cartaocredito == true ||
        multibanco == true ||
        mbway == true ||
        balcao == true ? null : (
          <div className="toprightcliente">
            <IconButton onClick={goNext} size={isMobile ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </IconButton>
          </div>
        )}

        {semDados == true ? (
          <CircularProgress disableShrink />
        ) : (
          <Box
            sx={{
              minWidth: "65%",
              maxWidth: "100%",
              overflow: "hidden",
              maxHeight: "100% !important",
            }}
          >
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={null}
            >
              {steps.map((step, index) => (
                <Step key={step.label} connector={null}>
                  <StepContent
                    connector={null}
                    sx={{ borderColor: "transparent" }}
                  >
                    {step.description}
                    <Box sx={{ mb: 2 }}></Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        )}

        <Footer />
      </div>
    </>
  );
}
export default MarcarF;
