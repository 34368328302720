import axios from "axios";
import Cookies from "js-cookie";
import {
  handleResponse,
  handleError,
  handleResponse2,
  handleError2,
} from "./response";

//Base url
//Localhot
// const BASE_URL = "http://localhost:9000";
// const BASE_URL2 = "http://localhost:8000";

// const BASE_URL = "http://192.168.1.84:9000";
// const BASE_URL2 = "http://192.168.1.84:8000";

//Ip Rita
// const BASE_URL = "http://192.168.1.79:9000";
// const BASE_URL2 = "http://192.168.1.79:8000";

// //Servidor
const BASE_URL = "https://gopool.tectank.pt:9001";
const BASE_URL2 = "https://gopool.tectank.pt:8001";

const refresh_token = Cookies.get("refresh_token");
const accessToken = Cookies.get("token");
const language = Cookies.get("language");

let config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Content-Type,Access-Control-Allow-Origin, Accept",
    "Accept-Language": language,
  },
};

//Pedido Get - ler dados - 9000
export const makeRequestGet = (resource) => {
  return axios
    .get(`${BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Get - ler dados - 8000
export const makeRequestGet2 = (resource, token) => {
  return axios
    .get(`${BASE_URL2}/${resource}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Post - adicionar dados - 9000
export const makeRequestPost = (resource, model) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Post - adicionar dados - 8000
export const makeRequestPost2 = (resource, model, token) => {
  return axios
    .post(`${BASE_URL2}/${resource}`, model, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Post - adicionar dados - 9000
export const makeRequestPost3 = (resource, model, token) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Put - alterar dados - 9000
export const makeRequestPut = (resource, model) => {
  return axios
    .put(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Put - alterar dados - 8000
export const makeRequestPut2 = (resource, model, token) => {
  return axios
    .put(`${BASE_URL2}/${resource}`, model, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Delete - eliminar dados - 9000
export const makeRequestDelete = (resource, model) => {
  return axios
    .delete(`${BASE_URL}/${resource}`, { headers: config, data: model })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Delete - eliminar dados - 8000
export const makeRequestDelete2 = (resource, model, token) => {
  return axios
    .delete(`${BASE_URL2}/${resource}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": language,
        Authorization: `Bearer ${token}`,
      },
      data: model,
    })
    .then(handleResponse)
    .catch(handleError);
};
