import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import NextPlusIcon from "../../images/NextPoolSaberMais.svg";
import { useSpring, animated } from "react-spring";

import { getPiscinas, getPiscinasId } from "../../api/requests/Piscinas";
import HeaderExterno from "../../components/HeaderExterno";
import FooterExterno from "../../components/FooterExterno";
import { Box, Stack, style } from "@mui/system";

import {
  faClose,
  faQuestion,
  faLink,
  faPhone,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import SimpleImageSlider from "react-simple-image-slider";
import calendarIcon from "../../images/calendar.svg";
import directionsIcon from "../../images/directions.svg";
import nadolivreIcon from "../../images/iconsModalidades/nadolivre.svg";
import adultosIcon from "../../images/iconsModalidades/adultos.svg";
import bebesIcon from "../../images/iconsModalidades/bebes.svg";
import federadosIcon from "../../images/iconsModalidades/federado.svg";
import gravidasIcon from "../../images/iconsModalidades/gravidas.svg";
import hidrobikeIcon from "../../images/iconsModalidades/hidrobike.svg";
import hidropowerIcon from "../../images/iconsModalidades/hidropower.svg";

import hidroterapiaIcon from "../../images/iconsModalidades/hidroterapia.svg";
import hidroginasticaIcon from "../../images/iconsModalidades/hidroginastica.svg";
import idososIcon from "../../images/iconsModalidades/idosos.svg";
import queronadarbtn from "../../images/queronadar.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { toast } from "react-toastify";
import Search4 from "../../components/DataTable/Search4";
import { Scrollbar } from "react-scrollbars-custom";

function DiretorioPiscinas() {
  function goBack() {
    navigate("/");
  }
  //Traduções
  const { t, i18n } = useTranslation();
  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState("");
  const [inscritas, setInscritas] = useState([]);
  const [piscinasCopy, setPiscinasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);
  const [freeze, setFreeze] = useState(false);

  const [idEscolhida, setIdEscolhida] = useState("");

  const [openPiscina, setOpenPiscina] = useState(false);
  const handleClosePiscina = () => {
    setOpenPiscina(false);
  };
  const handleClickOpenPiscina = () => {
    setOpenPiscina(true);
  };

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      let arrayPiscinas = piscinasCopy;
      arrayPiscinas = arrayPiscinas.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.cidade.toLowerCase().includes(search.toLowerCase())
      );
      setPiscinas(arrayPiscinas);
    } else {
      setPiscinas(piscinasCopy);
    }
  }, [search]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Piscina
  const [paisPiscinaa, setPaisPiscina] = useState("");
  const [termos, setTermos] = useState("");
  const [aceitatermos, setAceitaTermos] = useState(false);
  const [nomePiscina, setNomePiscina] = useState("");
  const [emailPiscina, setEmailPiscina] = useState("");
  const [telefonePiscina, setTelefonePiscina] = useState("");
  const [telefone2Piscina, setTelefone2Piscina] = useState("");
  const [ruaPiscina, setRuaPiscina] = useState("");
  const [codigoPostalPiscina, setCodigoPostalPiscina] = useState("");
  const [cidadePiscina, setCidadePiscina] = useState("");
  const [distritoPiscina, setDistritoPiscina] = useState("");
  const [latitudePiscina, setLatitudePiscina] = useState(0.0);
  const [longitudePiscina, setLongitudePiscina] = useState(0.0);
  const [fotoPiscina, setFotoPiscina] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [idFoto1, setIdFoto1] = useState("");
  const [foto2, setFoto2] = useState([]);
  const [idFoto2, setIdFoto2] = useState("");
  const [foto3, setFoto3] = useState([]);
  const [idFoto3, setIdFoto3] = useState("");
  const [foto4, setFoto4] = useState([]);
  const [idFoto4, setIdFoto4] = useState("");
  const [foto5, setFoto5] = useState([]);
  const [idFoto5, setIdFoto5] = useState("");
  const [foto6, setFoto6] = useState([]);
  const [idFoto6, setIdFoto6] = useState("");
  //Modalidades
  const [natacaoAdultos, setNatacaoAdultos] = useState(false);
  const [natacaoBebes, setNatacaoBebes] = useState(false);
  const [natacaoIdosos, setNatacaoIdosos] = useState(false);
  const [hidroginastica, setHidroginastica] = useState(false);
  const [escolaNatacao, setEscolaNatacao] = useState(false);
  const [hidrobike, setHidrobike] = useState(false);
  const [natacaoGravidas, setNatacaoGravidas] = useState(false);
  const [hidroterapia, setHidroterapia] = useState(false);
  const [nadoLivre, setNadoLivre] = useState(false);
  const [equipasFederadas, setEquipasFederadas] = useState(false);
  const [hidropower, setHidropower] = useState(false);
  //Horarios
  const [horario, setHorario] = useState([]);
  const [horario2, setHorario2] = useState([]);
  //Links uteis
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");

  //Dialogs
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);

  const [open6, setOpen6] = React.useState(false);
  const handleOpen6 = () => setOpen6(true);
  const handleClose6 = () => setOpen6(false);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [idPiscina, setIdPiscina] = useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;

    let arrayFinal2 = [];

    getPiscinas().then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          if (element.status == 1) {
            let pais = element.pais.split("-")[1];

            if (arrayFinal2.includes(element.id_piscina_edificio)) {
              const linha = {
                id: element.id_piscina_edificio,
                nome: element.nome,
                cidade: element.cidade,
                morada: element.morada,
                img: element.foto_perfil,
                pais: pais,
                inscrito: 1,
              };
              arrayFinal.push(linha);
            } else {
              const linha = {
                id: element.id_piscina_edificio,
                nome: element.nome,
                cidade: element.cidade,
                morada: element.morada,
                img: element.foto_perfil,
                pais: pais,
                inscrito: 0,
              };
              arrayFinal.push(linha);
            }
          }
        });
        setPiscinas(arrayFinal);
        setPiscinasCopy(arrayFinal);
        setSemDados(false);
      } else {
        toast.error(res.error);
        setSemDados(true);
      }
    });
  }, []);

  useEffect(() => {
    if (idPiscina != "") {
      getPiscinasId(idPiscina, {})
        .then((res) => {
          if (res.success) {
            let dados = res.data.data;
            setNomePiscina(dados.nome);
            setEmailPiscina(dados.email);
            setTelefonePiscina(dados.contacto1);
            setTelefone2Piscina(dados.contacto2);
            setRuaPiscina(dados.morada);
            setCodigoPostalPiscina(dados.codigo_postal);
            setCidadePiscina(dados.cidade);
            setDistritoPiscina(dados.distrito);
            let paisPiscina = dados.pais.split("-");
            setPaisPiscina({ value: paisPiscina[0], label: paisPiscina[1] });
            setLatitudePiscina(parseFloat(dados.latitude));
            setLongitudePiscina(parseFloat(dados.longitude));
            setFotoPiscina(dados.foto_perfil_piscina);
            setTermos(dados.termos_condicoes);

            dados.galeria.forEach((element) => {
              if (element.ordem == 1) {
                setFoto1(element.foto);
                setIdFoto1(element.id_imagem);
              }
              if (element.ordem == 2) {
                setFoto2(element.foto);
                setIdFoto2(element.id_imagem);
              }
              if (element.ordem == 3) {
                setFoto3(element.foto);
                setIdFoto3(element.id_imagem);
              }
              if (element.ordem == 4) {
                setFoto4(element.foto);
                setIdFoto4(element.id_imagem);
              }
              if (element.ordem == 5) {
                setFoto5(element.foto);
                setIdFoto5(element.id_imagem);
              }
              if (element.ordem == 6) {
                setFoto6(element.foto);
                setIdFoto6(element.id_imagem);
              }
            });

            //Modalidades
            setNatacaoAdultos(dados.aulas_adultos);
            setNatacaoBebes(dados.aulas_bebes);
            setNatacaoIdosos(dados.aulas_idoso);
            setHidroginastica(dados.hidroginastica);
            setEscolaNatacao(dados.escolaNatacao);
            setHidrobike(dados.hidrobike);
            setNatacaoGravidas(dados.aulas_gravidas);
            setHidroterapia(dados.hidro_terapia);
            setNadoLivre(dados.nado_livre);
            setEquipasFederadas(dados.equipas_federadas);
            setHidropower(dados.hidropower);
            //Horarios
            if (dados.horarios) {
              setHorario2(dados.horarios);
            }
            //Links uteis
            if (dados.link1) {
              setLink1(dados.link1);
            }
            if (dados.link2) {
              setLink2(dados.link2);
            }
            if (dados.link2) {
              setLink3(dados.link3);
            }
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idPiscina]);

  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const containerStyle2 = {
    width: "300px",
    height: "300px",
  };

  var center = {
    lat: latitudePiscina,
    lng: longitudePiscina,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAhwf3uzO-aTYJWm-TkeO6Rvf5NoTB3jJs",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  var slideImages = [
    { url: foto1 },
    { url: foto2 },
    { url: foto3 },
    { url: foto4 },
    { url: foto5 },
    { url: foto6 },
  ];

  var results = slideImages.filter((element) => {
    return element.url != "";
  });

  const buttonRef = useRef(null);

  const [buttonPosition, setButtonPosition] = useState({ x: 400, y: 300 });

  useEffect(() => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setButtonPosition({
        x: buttonRect.center,
        y: buttonRect.center,
      });
    }
  }, [buttonPosition]);

  const animationProps = useSpring({
    from: {
      transform: openPiscina ? "scale(0.5)" : "scale(0)",
      opacity: 0,
      transformOrigin: `${buttonPosition.x}px ${buttonPosition.y}px`,
    },
    to: {
      transform: openPiscina ? "scale(1)" : "scale(0)",
      opacity: openPiscina ? 1 : 0,
    },
    config: {
      tension: 200,
      friction: 40,
    },
  });

  return (
    <div className="listPagesDirectorioPiscinas">
      <Dialog
        open={openPiscina}
        onClose={handleClosePiscina}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={animated.div}
        PaperProps={{
          style: {
            minWidth: isMobile ? "55vw" : 790,
            height: isMobile ? "85vh" : 665,
            overflowX: "hidden",
            borderRadius: "10px",
            paddingLeft: isMobile ? "10px" : "30px",
            paddingRight: "10px",
            ...animationProps,
          },

          className: "infoPiscinas",
        }}
        style={{ width: "100%" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <FontAwesomeIcon
            edge="end"
            color="inherit"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              padding: "8px",
            }}
            onClick={handleClosePiscina}
            aria-label="Fechar"
            icon={faClose}
          />
        </DialogTitle>
        <DialogContent>
          {freeze && (
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                width: window.innerWidth,
                height: window.innerHeight,
                zIndex: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
              }}
            >
              <Dialog
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <DialogContent style={{ width: "100%" }}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("aguarde")}
                  </Typography>
                  <CircularProgress disableShrink style={{ marginTop: 20 }} />
                </DialogContent>
              </Dialog>
            </div>
          )}
          <div className="row1">
            {/* Info da piscina */}
            <Stack direction={"column"} spacing={1}>
              <img
                src={fotoPiscina}
                style={{
                  objectFit: "fill",
                  borderRadius: "10px",
                  height: isMobile ? "24vw" : "220px",
                  width: isMobile ? "32vw" : "336px ",
                }}
              />
              {foto1.length > 0 ? (
                <Button
                  onClick={handleOpen2}
                  size="small"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  style={{
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <a
                    style={{
                      fontSize: isMobile ? 8 : 10,
                      textTransform: "capitalize",
                    }}
                  >
                    {t("maisfotos")}
                  </a>
                </Button>
              ) : null}
            </Stack>
            <Stack
              direction={"column"}
              spacing={1}
              sx={{ marginInline: "6%", maxWidth: "250px !important" }}
            >
              <h5
                className="titulo"
                sx={{ fontWeight: "900", maxWidth: "100px !important" }}
              >
                {nomePiscina}
              </h5>
              <h5 className="subtitulo" sx={{ maxWidth: "100px !important" }}>
                {ruaPiscina + " "} {codigoPostalPiscina}
                <br />
                {cidadePiscina}
                <br />
                {paisPiscinaa.label}
              </h5>
              {isMobile ? null : (
                <div className="containerP">
                  <div className="containerrow1">
                    <div className="cardpiscina">
                      <span
                        className="pageIconPool"
                        style={{
                          width: 17,
                          height: 16,
                          backgroundImage: `url(${calendarIcon})`,
                        }}
                      />
                      <h1 className="cardspool">{t("horarios")}</h1>
                    </div>
                    <div className="cardpiscina" onClick={handleOpen3}>
                      <span
                        className="pageIconPool"
                        style={{
                          width: 17,
                          height: 16,
                          backgroundImage: `url(${directionsIcon})`,
                        }}
                      />

                      <h2 className="cardspool">{t("direcoes")}</h2>
                    </div>
                    <div className="cardpiscina" onClick={handleOpen6}>
                      <FontAwesomeIcon className="pageIconPool" icon={faLink} />
                      <h2 className="cardspool">{t("linksuteis")}</h2>
                    </div>
                    <div className="cardpiscina" onClick={handleOpen5}>
                      <FontAwesomeIcon
                        className="pageIconPool"
                        icon={faPhone}
                      />
                      <h2 className="cardspool">{t("contactos")}</h2>
                    </div>
                  </div>

                  {/* Janelas conteudo */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("modalidades")}{" "}
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        onClick={handleClose}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                    </DialogTitle>
                    <DialogContent></DialogContent>
                  </Dialog>
                  {!isMobile ? (
                    <Dialog
                      open={open2}
                      onClose={handleClose2}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {" "}
                        <FontAwesomeIcon
                          edge="end"
                          color="inherit"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            cursor: "pointer",
                            padding: "8px",
                          }}
                          onClick={handleClose2}
                          aria-label="Fechar"
                          icon={faClose}
                        />
                        {t("galeria")}
                      </DialogTitle>
                      <DialogContent>
                        <div>
                          {results.length == 0 ? (
                            <p>{t("naotemfotos")}</p>
                          ) : (
                            <SimpleImageSlider
                              width={500}
                              height={500}
                              images={results}
                              showBullets={true}
                              showNavs={true}
                            />
                          )}
                        </div>
                      </DialogContent>
                    </Dialog>
                  ) : (
                    <Dialog
                      open={open2}
                      onClose={handleClose2}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {" "}
                        <FontAwesomeIcon
                          edge="end"
                          color="inherit"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            cursor: "pointer",
                            padding: "8px",
                          }}
                          onClick={handleClose2}
                          aria-label="Fechar"
                          icon={faClose}
                        />
                        {t("galeria")}
                      </DialogTitle>
                      <DialogContent>
                        <div>
                          <div>
                            {results.length == 0 ? (
                              <p>{t("naotemfotos")}</p>
                            ) : (
                              <SimpleImageSlider
                                width={300}
                                height={300}
                                images={results}
                                showBullets={true}
                                showNavs={true}
                              />
                            )}
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  )}
                  <Dialog
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("direcoes")}{" "}
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        onClick={handleClose3}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                    </DialogTitle>
                    <DialogContent>
                      {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={
                            !isMobile ? containerStyle : containerStyle2
                          }
                          center={center}
                          zoom={15}
                        >
                          <Marker position={center}></Marker>
                        </GoogleMap>
                      ) : null}
                    </DialogContent>
                  </Dialog>
                  <Dialog
                    open={openHelp}
                    onClose={handleCloseHelp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("ajuda")}{" "}
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        onClick={handleCloseHelp}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                    </DialogTitle>
                    <DialogContent>
                      <p className="ajuda">
                        Nesta página podes encontrar alguma informação da
                        piscina, no caso de quereres saber mais terás de te
                        inscrever nesta piscina.<br></br>
                        Se tiveres algum problema podes entrar em contacto com a
                        equipa de suporte GoPool
                      </p>
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    open={open5}
                    onClose={handleClose5}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      {" "}
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        onClick={handleClose5}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                      {t("contactos")}
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          sx={{ marginTop: "10px" }}
                        >
                          <p className="conteudoboldblue">
                            {t("contactoprincipal")}
                          </p>{" "}
                          <a
                            className="conteudoblue"
                            href={"tel:" + telefonePiscina}
                          >
                            {" "}
                            {telefonePiscina}
                          </a>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <p className="conteudoboldblue">
                            {t("contactosecundario")}
                          </p>{" "}
                          <a
                            className="conteudoblue"
                            href={"tel:" + telefone2Piscina}
                          >
                            {" "}
                            {telefone2Piscina}
                          </a>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <p className="conteudoboldblue">{t("emailc")}</p>{" "}
                          <a
                            className="conteudoblue"
                            href={"mailto:" + emailPiscina}
                          >
                            {" "}
                            {emailPiscina}
                          </a>
                        </Stack>
                      </div>
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    open={open6}
                    onClose={handleClose6}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      {" "}
                      <FontAwesomeIcon
                        edge="end"
                        color="inherit"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        onClick={handleClose6}
                        aria-label="Fechar"
                        icon={faClose}
                      />
                      {t("linksuteis")}
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        {link1 ? (
                          <Stack
                            direction={"row"}
                            spacing={0}
                            sx={{ marginTop: "10px" }}
                          >
                            <a className="conteudoboldblue">{link1}</a>{" "}
                          </Stack>
                        ) : (
                          <p>{t("semlinks")}</p>
                        )}

                        <Stack direction={"row"} spacing={0}>
                          <a className="conteudoboldblue">{link2}</a>{" "}
                        </Stack>
                        <Stack direction={"row"} spacing={0}>
                          <a className="conteudoboldblue">{link3}</a>{" "}
                        </Stack>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              )}
            </Stack>
          </div>
          {isMobile ? (
            <div className="containerP">
              <div className="containerrow1">
                <div className="cardpiscina">
                  <span
                    className="pageIconPool"
                    style={{
                      width: 17,
                      height: 16,
                      backgroundImage: `url(${calendarIcon})`,
                    }}
                  />
                  {/* <h1 className="cardspool">{t("horarios")}</h1> */}
                </div>
                <div className="cardpiscina" onClick={handleOpen3}>
                  <span
                    className="pageIconPool"
                    style={{
                      width: 17,
                      height: 16,
                      backgroundImage: `url(${directionsIcon})`,
                    }}
                  />

                  {/* <h2 className="cardspool">{t("direcoes")}</h2> */}
                </div>
                <div className="cardpiscina" onClick={handleOpen6}>
                  <FontAwesomeIcon className="pageIconPool" icon={faLink} />
                  {/* <h2 className="cardspool">{t("linksuteis")}</h2> */}
                </div>
                <div className="cardpiscina" onClick={handleOpen5}>
                  <FontAwesomeIcon className="pageIconPool" icon={faPhone} />
                  {/* <h2 className="cardspool">{t("contactos")}</h2> */}
                </div>
              </div>

              {/* Janelas conteudo */}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t("modalidades")}{" "}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleClose}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent></DialogContent>
              </Dialog>
              {!isMobile ? (
                <Dialog
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle id="alert-dialog-title">
                    {" "}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                      }}
                      onClick={handleClose2}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                    {t("galeria")}
                  </DialogTitle>
                  <DialogContent>
                    <div>
                      {results.length == 0 ? (
                        <p>{t("naotemfotos")}</p>
                      ) : (
                        <SimpleImageSlider
                          width={500}
                          height={500}
                          images={results}
                          showBullets={true}
                          showNavs={true}
                        />
                      )}
                    </div>
                  </DialogContent>
                </Dialog>
              ) : (
                <Dialog
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle id="alert-dialog-title">
                    {" "}
                    <FontAwesomeIcon
                      edge="end"
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        padding: "8px",
                      }}
                      onClick={handleClose2}
                      aria-label="Fechar"
                      icon={faClose}
                    />
                    {t("galeria")}
                  </DialogTitle>
                  <DialogContent>
                    <div>
                      <div>
                        {results.length == 0 ? (
                          <p>{t("naotemfotos")}</p>
                        ) : (
                          <SimpleImageSlider
                            width={300}
                            height={300}
                            images={results}
                            showBullets={true}
                            showNavs={true}
                          />
                        )}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              )}
              <Dialog
                open={open3}
                onClose={handleClose3}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {t("direcoes")}{" "}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleClose3}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={
                        !isMobile ? containerStyle : containerStyle2
                      }
                      center={center}
                      zoom={15}
                    >
                      <Marker position={center}></Marker>
                    </GoogleMap>
                  ) : null}
                </DialogContent>
              </Dialog>
              <Dialog
                open={openHelp}
                onClose={handleCloseHelp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t("ajuda")}{" "}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleCloseHelp}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  <p className="ajuda">
                    Nesta página podes encontrar alguma informação da piscina,
                    no caso de quereres saber mais terás de te inscrever nesta
                    piscina.<br></br>
                    Se tiveres algum problema podes entrar em contacto com a
                    equipa de suporte GoPool
                  </p>
                </DialogContent>
              </Dialog>

              <Dialog
                open={open5}
                onClose={handleClose5}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {" "}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleClose5}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                  {t("contactos")}
                </DialogTitle>
                <DialogContent>
                  <div>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{ marginTop: "10px" }}
                    >
                      <p className="conteudoboldblue">
                        {t("contactoprincipal")}
                      </p>{" "}
                      <a
                        className="conteudoblue"
                        href={"tel:" + telefonePiscina}
                      >
                        {" "}
                        {telefonePiscina}
                      </a>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <p className="conteudoboldblue">
                        {t("contactosecundario")}
                      </p>{" "}
                      <a
                        className="conteudoblue"
                        href={"tel:" + telefone2Piscina}
                      >
                        {" "}
                        {telefone2Piscina}
                      </a>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <p className="conteudoboldblue">{t("emailc")}</p>{" "}
                      <a
                        className="conteudoblue"
                        href={"mailto:" + emailPiscina}
                      >
                        {" "}
                        {emailPiscina}
                      </a>
                    </Stack>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={open6}
                onClose={handleClose6}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {" "}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={handleClose6}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                  {t("linksuteis")}
                </DialogTitle>
                <DialogContent>
                  <div>
                    {link1 ? (
                      <Stack
                        direction={"row"}
                        spacing={0}
                        sx={{ marginTop: "10px" }}
                      >
                        <a className="conteudoboldblue">{link1}</a>{" "}
                      </Stack>
                    ) : (
                      <p>{t("semlinks")}</p>
                    )}

                    <Stack direction={"row"} spacing={0}>
                      <a className="conteudoboldblue">{link2}</a>{" "}
                    </Stack>
                    <Stack direction={"row"} spacing={0}>
                      <a className="conteudoboldblue">{link3}</a>{" "}
                    </Stack>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          ) : null}
          {/* Modalidades */}
          <Paper
            elevation={0}
            style={{
              marginTop: isMobile ? "2vh" : "5vh",
              backgroundColor: "transparent",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                width: isMobile ? "500px" : "auto",
              }}
            >
              <Grid
                columns={{ xs: 5, sm: 5, md: 5 }}
                container
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "12px",
                }}
                spacing={1}
              >
                {nadoLivre && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box className="cardModalidades">
                      <span
                        style={{
                          width: "42px",
                          height: "42px",
                          backgroundImage: `url(${nadolivreIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography variant="body2" className="conteudo2">
                        {t("nado_livre")}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {natacaoAdultos && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 15px" : null }}
                    >
                      <span
                        style={{
                          width: "14px",
                          height: "42px",
                          backgroundImage: `url(${adultosIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography
                        variant="body2"
                        className="conteudo2"
                        style={{ textAlign: "center" }}
                      >
                        {t("natacao_adultos")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {natacaoBebes && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 30px" : null }}
                    >
                      <span
                        style={{
                          width: "43px",
                          height: "41px",
                          backgroundImage: `url(${bebesIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography
                        variant="body2"
                        className="conteudo2"
                        style={{ textAlign: "center" }}
                      >
                        {t("natacao_bebes")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {natacaoIdosos && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 45px" : null }}
                    >
                      <span
                        style={{
                          width: "26px",
                          height: "41px",
                          backgroundImage: `url(${idososIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography
                        variant="body2"
                        className="conteudo2"
                        style={{ textAlign: "center" }}
                      >
                        {t("natacao_idosos")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                  {escolaNatacao && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 15px" : null }}
                    >
                      <span
                        style={{
                          width: "14px",
                          height: "42px",
                          backgroundImage: `url(${adultosIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography
                        variant="body2"
                        className="conteudo2"
                        style={{ textAlign: "center" }}
                      >
                        {t("escolaNatacao")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {hidroginastica && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 60px" : null }}
                    >
                      <span
                        style={{
                          width: "42px",
                          height: "41px",
                          backgroundImage: `url(${hidroginasticaIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography variant="body2" className="conteudo2">
                        {t("hidroginastica")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {natacaoGravidas && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box className="cardModalidades">
                      <span
                        style={{
                          width: "16px",
                          height: "42px",
                          backgroundImage: `url(${gravidasIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography
                        variant="body2"
                        className="conteudo2"
                        style={{ textAlign: "center" }}
                      >
                        {t("natacao_gravidas")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {hidrobike && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 15px" : null }}
                    >
                      <span
                        style={{
                          width: "43px",
                          height: "42px",
                          backgroundImage: `url(${hidrobikeIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography variant="body2" className="conteudo2">
                        {t("hidrobike")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {hidropower && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 30px" : null }}
                    >
                      <span
                        style={{
                          width: "42px",
                          height: "42px",
                          backgroundImage: `url(${hidropowerIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography variant="body2" className="conteudo2">
                        {t("hidropower")}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {equipasFederadas && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 45px" : null }}
                    >
                      <span
                        style={{
                          width: "40px",
                          height: "42px",
                          backgroundImage: `url(${federadosIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography variant="body2" className="conteudo2">
                        {t("equipas_federadas")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {hidroterapia && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Box
                      className="cardModalidades"
                      sx={{ margin: isMobile ? "0 60px" : null }}
                    >
                      <span
                        style={{
                          width: "40px",
                          height: "42px",
                          backgroundImage: `url(${hidroterapiaIcon})`,
                          display: "flex",
                          marginBottom: 5,
                        }}
                      />

                      <Typography variant="body2" className="conteudo2">
                        {t("hidroterapia")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </Paper>

          <div
            className="containerrow3"
            style={{ paddingTop: isMobile ? "5%" : "2%" }}
          >
            {/* <div className="cardpiscina2" onClick={goRegister}>
            <h1 className="cardspool2">{t("registo")}</h1>
          </div> */}
          </div>
        </DialogContent>
      </Dialog>
      <div className="topleft">
        <IconButton onClick={goBack} size={isMobile ? "small" : "medium"}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        </IconButton>
      </div>
      {semDados == true ? (
        <div
          className="divTabelaMarcar2"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <CircularProgress disableShrink />
        </div>
      ) : (
        <div
          style={{
            marginRight: "20px",
            width: isMobile ? "90%" : "70%",
            height: "92%",
          }}
        >
          {/* <h1
            className="dashboardTitle"
            style={{ paddingTop: window.innerWidth > 800 ? null : "80px" }}
          >
            {t("proximapiscina")}
          </h1> */}

          <Paper
            elevation={0}
            style={{
              overflow: "auto",
              alignItems: "center",
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "transparent",
              width: "100%",
            }}
          >
            <h3 className="titleMarcar">{t("proximapiscina")}</h3>
            <div
              style={{
                alignSelf: "center",
                marginTop: "10px",
              }}
            >
              <Search4
                onSearch={(value) => {
                  setSearch(value);
                }}
              />
            </div>
            <Scrollbar
              style={{
                height: "600px",
                display: "flex",
              }}
            >
              <List
                style={{
                  marginTop: 10,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                {piscinas.map((element) => (
                  <Card
                    key={element.id}
                    onClick={() => {
                      setIdPiscina(element.id);
                      handleClickOpenPiscina();
                    }}
                    sx={{
                      display: "flex",
                      margin: "10px",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      alignItems: "center",
                      cursor: "pointer",
                      width: "95%",
                      height: "150px",
                      backgroundColor: inscritas.includes(element.id)
                        ? "rgba(194, 213, 254, 1)"
                        : null,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flex: "0 0 auto", cursor: "pointer" }}>
                        <Typography component="div">{element.nome}</Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          {element.morada}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          {element.cidade}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          {element.pais}
                        </Typography>
                      </CardContent>
                    </Box>
                    {/* alterar para  a foto */}
                    <Card
                      sx={{
                        borderRadius: "10px",
                        backgroundImage: `url(${element.img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "130px",
                        width: "208px",
                        margin: "1.5%",
                      }}
                    >
                      <CardContent
                        className="cardPiscinasMarcar"
                        sx={{
                          cursor: "pointer",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          position: "relative",
                          zIndex: 2,
                        }}
                      ></CardContent>
                    </Card>
                  </Card>
                ))}
              </List>
            </Scrollbar>
          </Paper>
        </div>
      )}

      <Dialog
        open={openHelp}
        onClose={handleCloseHelp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
        <DialogContent>
          <p className="ajuda">
            Aqui podes encontrar as piscinas que são parceiras do GoPool, só
            nestas piscinas poderás usufruir das vantagens da nossa app!
          </p>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DiretorioPiscinas;
