import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Nav from "../../components/Nav";

import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
  RadioGroup,
  Radio,
  FormLabel,
  Alert,
} from "@mui/material";
import { toast } from "react-toastify";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCircleCheck,
  faClose,
  faCircleXmark,
  faPen,
  faCircleStop,
  faTrash,
  faLock,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/system";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useSearchParams } from "react-router-dom";
import { listaProfessores } from "../../api/requests/PiscinaAgua";
import Search2 from "../../components/DataTable/Search2";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";

import {
  deleteProfessor,
  enviarEmailPostProfessor,
  getProfessor,
  postProfessor,
  putProfessor,
  putStatusProfessor,
} from "../../api/requests/Professores";
import { Scrollbar } from "react-scrollbars-custom";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  definePassEmail,
  recuperaPass,
  verificarEmail,
} from "../../api/requests/Registo";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function ListaProfessoresA() {
  const [professores, setProfessores] = useState([]);
  const [editarProfessor, seteditarProfessor] = useState(false);
  const [professoresCopy, setProfessoresCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [idPiscina, setIdPiscina] = useState("");
  const { state } = useLocation();

  //Responsável
  const [nomeProfessor, setNomeProfessor] = useState("");
  const [emailProfessor, setEmailProfessor] = useState("");
  const [telefoneProfessor, setTelefoneProfessor] = useState("");
  const [nifProfessor, setNifProfessor] = useState("");
  const [moradaProfessor, setMoradaProfessor] = useState("");
  const [codigoPostalProfessor, setCodigoPostalProfessor] = useState("");
  const [fotoProfessor, setFotoProfessor] = useState([]);
  const [statusProfessor, setStatusProfessor] = useState("");
  const [dataNascimentoProfessor, setDataNascimentoProfessor] = useState(
    new Date()
  );
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [genero, setGenero] = useState("");
  const [erroGenero, seterroGenero] = useState(false);
  const [mensagemErroGenero, setMensagemErroGenero] = useState("");

  const [suspensoes, setSuspensoes] = useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.inativo,
        contrastText: "#ffffff",
      },
      secondary: {
        main: Colors.ativo,
        contrastText: "#ffffff",
      },
    },
  });

  const mapIcon = (index) => {
    switch (index) {
      case 2:
        return (
          <CheckIcon
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        );
      case 1:
        return (
          <XMarkIcon
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        );
      default:
        return null;
    }
  };

  function formatDateAndTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  //Traduções
  const { t, i18n } = useTranslation();
  //Navigator
  const navigate = useNavigate();
  const [funcionarioSelecionadoId, setFuncionarioSelecionadoId] =
    useState(null);
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [openCriarProfessor, setOpenCriarProfessor] = useState(false);
  const handleCloseCriarProfessor = () => setOpenCriarProfessor(false);

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);
  const [reporPassword, setreporPassword] = useState(false);
  const [edicao, setEdicao] = useState(true);
  const [reporPwd, setReporPwd] = useState(false);

  const [selecionado, setSelecionado] = useState(null);
  const handleClick = (botaoSelecionado) => {
    if (selecionado !== botaoSelecionado) {
      setSelecionado(botaoSelecionado);
    }
  };

  const handleClickOpenCriarProfessor = () => {
    setOpenCriarProfessor(true);
  };
  const handleClickOpenPopupReporPassword = () => {
    setreporPassword(true);
  };

  const handleClosePopupReporPassword = () => {
    setReporPwd(false);
    setreporPassword(false);
  };
  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };
  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayProfessores = professoresCopy;
      arrayProfessores = arrayProfessores.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setProfessores(arrayProfessores);
    } else {
      setProfessores(professoresCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");

    setIdPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaProfessores({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
              email: element.email,
              status: element.status == 1 ? t("ativo") : t("inativo"),
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
            setProfessoresCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [freeze, setFreeze] = useState(false);
  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [erroNomeProfessor, setErroNomeProfessor] = useState(false);
  const [erroEmailProfessor, setErroEmailProfessor] = useState(false);
  const [erroTelefoneProfessor, setErroTelefoneProfessor] = useState(false);
  const [erroNifProfessor, setErroNifProfessor] = useState(false);
  const [erroMoradaProfessor, setErroMoradaProfessor] = useState(false);
  const [erroCodigoPostalProfessor, setErroCodigoPostalProfessor] =
    useState(false);
  const [erroNascimentoProfessor, setErroNascimentoProfessor] = useState(false);

  // Responsável
  const [mensagemErroEmailProfessor, setMensagemErroEmailProfessor] =
    useState("");
  const [mensagemErroTelefoneProfessor, setMensagemErroTelefoneProfessor] =
    useState("");
  const [mensagemErroNifProfessor, setMensagemErroNifProfessor] = useState("");
  const [
    mensagemErroCodigoPostalProfessor,
    setMensagemErroCodigoPostalProfessor,
  ] = useState("");
  const [mensagemErroNascimentoProfessor, setMensagemErroNascimentoProfessor] =
    useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    setIdPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);
  const scrollToDiv = () => {
    setEdicao(!edicao);
  };
  function criarProfessor() {
    setFreeze(true);
    postProfessor({
      id: localStorage.getItem("idPiscina"),
      nome: nomeProfessor,
      contacto: telefoneProfessor,
      email: emailProfessor,
      genero: genero,
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          await enviarEmailPostProfessor({
            id: res.data.id,
          }).then((res) => {
            if (res.success) {
              toast.success(res.data.msg);
              setFreeze(false);
              handleCloseCriarProfessor();
              setTimeout(() => {
                window.location.reload();
              }, 550);
            } else {
              toast.error(res.error);
              setFreeze(false);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (nomeProfessor && emailProfessor && telefoneProfessor && genero) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailProfessor
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailProfessor(true);
        setMensagemErroEmailProfessor(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneProfessor)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneProfessor(true);
        setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
      }
      if (emailProfessor) {
        verificarEmail({ email: emailProfessor }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroEmailProfessor(true);
              setMensagemErroEmailProfessor(res.data.error);
            }
          }
        });
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarProfessor();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeProfessor) {
        setErroNomeProfessor(true);
      }
      //Validação do email
      if (!emailProfessor) {
        setErroEmailProfessor(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailProfessor
          );
        if (!validacaoEmail) {
          setErroEmailProfessor(true);
          setMensagemErroEmailProfessor(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailProfessor }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                setErroEmailProfessor(true);
                setMensagemErroEmailProfessor(res.data.error);
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneProfessor) {
        setErroTelefoneProfessor(true);
      } else {
        if (!isValidPhoneNumber(telefoneProfessor)) {
          setErroTelefoneProfessor(true);
          setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
        }
      }
    }
  }
  function EditarProfessor() {
    setFreeze(true);
    putProfessor({
      id: funcionarioSelecionadoId,
      nome: nomeProfessor,
      nif: nifProfessor,
      genero: genero,
      email: emailProfessor,
      contacto: telefoneProfessor,
      morada: moradaProfessor,
      codigo_postal: codigoPostalProfessor,
      foto_perfil: fotoProfessor.length == 0 ? null : fotoProfessor,
      data_nascimento: new Date(dataNascimentoProfessor)
        .toISOString()
        .split("T")[0],
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);

          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }
  useEffect(() => {
    if (openCriarProfessor) {
      setNomeProfessor("");
      setEmailProfessor("");
      setGenero("");
      setTelefoneProfessor("");
      setNifProfessor("");
      setMoradaProfessor("");
      setCodigoPostalProfessor("");
      // if (dadosOriginais.foto) {
      //   setFotoFuncionario(dadosOriginais.foto);
      // } else {
      //   setFotoFuncionario([]);
      // }
      setDataNascimentoProfessor("");
    }
  }, [openCriarProfessor]);

  function validacao() {
    if (
      nomeProfessor &&
      emailProfessor &&
      telefoneProfessor &&
      nifProfessor &&
      genero &&
      moradaProfessor &&
      codigoPostalProfessor &&
      dataNascimentoProfessor
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailProfessor
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailProfessor(true);
        setMensagemErroEmailProfessor(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneProfessor)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneProfessor(true);
        setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
      }
      if (emailProfessor) {
        verificarEmail({ email: emailProfessor }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != funcionarioSelecionadoId) {
                confirmarDadosPreenchidos = false;
                setErroEmailProfessor(true);
                setMensagemErroEmailProfessor(res.data.error);
              }
            }
          }
        });
      }
      // Validação do nif
      if (nifProfessor.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifProfessor(true);
        setMensagemErroNifProfessor(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalProfessor < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalProfessor(true);
        setMensagemErroCodigoPostalProfessor(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoProfessor);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoProfessor(true);
        setMensagemErroNascimentoProfessor(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        EditarProfessor();
      } else {
        scrollToDiv();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeProfessor) {
        setErroNomeProfessor(true);
      }
      //Validação do email
      if (!emailProfessor) {
        setErroEmailProfessor(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailProfessor
          );
        if (!validacaoEmail) {
          setErroEmailProfessor(true);
          setMensagemErroEmailProfessor(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailProfessor }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != funcionarioSelecionadoId) {
                  setErroEmailProfessor(true);
                  setMensagemErroEmailProfessor(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneProfessor) {
        setErroTelefoneProfessor(true);
      } else {
        if (!isValidPhoneNumber(telefoneProfessor)) {
          setErroTelefoneProfessor(true);
          setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
        }
      }
      // Validação do nif
      if (!nifProfessor) {
        setErroNifProfessor(true);
      } else {
        if (nifProfessor.length < 9) {
          setErroNifProfessor(true);
          setMensagemErroNifProfessor(t("validacaoNif"));
        }
      }
      //Validação da morada
      if (!moradaProfessor) {
        setErroMoradaProfessor(true);
      }
      //Validação do código postal
      if (!codigoPostalProfessor) {
        setErroCodigoPostalProfessor(true);
      } else {
        if (codigoPostalProfessor < 7) {
          setErroCodigoPostalProfessor(true);
          setMensagemErroCodigoPostalProfessor(t("validacaoCodigoPostal"));
        }
      }
      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoProfessor);
      if (isNaN(isValidDate)) {
        setErroNascimentoProfessor(true);
        setMensagemErroNascimentoProfessor(t("dataInvalida"));
      }
    }
  }

  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "email", "status"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "email", "status"] : []);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (funcionarioSelecionadoId !== null) {
      getProfessor(funcionarioSelecionadoId)
        .then((res) => {
          if (res.success) {
            let dados = res.data.data;
            setSuspensoes(res.data.suspensoes);
            setDadosOriginais(dados);
            setGenero(dados.genero);
            setNomeProfessor(dados.nome);
            setEmailProfessor(dados.email);
            setTelefoneProfessor(dados.contacto);
            setNifProfessor(dados.nif);
            setMoradaProfessor(dados.morada);
            setCodigoPostalProfessor(dados.codigo_postal);
            if (dados.foto_perfil) {
              setFotoProfessor(dados.foto_perfil);
            }
            setDataNascimentoProfessor(dados.data_nascimento_final);
            setStatusProfessor(dados.status);

            setMotivo(res.data.obs);
            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [funcionarioSelecionadoId]);

  function cancelar() {
    handleClosePopupCancelar();
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeProfessor(dadosOriginais.nome);
    setEmailProfessor(dadosOriginais.email);
    setTelefoneProfessor(dadosOriginais.contacto);
    setNifProfessor(dadosOriginais.nif);
    setGenero(dadosOriginais.genero);
    setMoradaProfessor(dadosOriginais.morada);
    setCodigoPostalProfessor(dadosOriginais.codigo_postal);
    if (dadosOriginais.foto) {
      setFotoProfessor(dadosOriginais.foto);
    } else {
      setFotoProfessor([]);
    }
    setDataNascimentoProfessor(dadosOriginais.data_nascimento_final);
    setStatusProfessor(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeProfessor(false);
    setErroEmailProfessor(false);
    setErroTelefoneProfessor(false);
    seterroGenero(false);
    setErroNifProfessor(false);
    setErroMoradaProfessor(false);
    setErroCodigoPostalProfessor(false);
    setErroNascimentoProfessor(false);
    setMensagemErroEmailProfessor("");
    setMensagemErroTelefoneProfessor("");
    setMensagemErroNifProfessor("");
    setMensagemErroCodigoPostalProfessor("");
  }

  function suspenderProfessor() {
    if (motivoSuspensao) {
      setSuspender(false);
      setFreeze(true);
      putStatusProfessor({
        id: funcionarioSelecionadoId,
        obs: motivoSuspensao,
      })
        .then((res) => {
          if (res.success) {
            setFreeze(false);
            toast.success(res.data.msg);
            setMotivoSuspensao("");
            setMotivo(motivoSuspensao);
            setSelecionado(null);
            seteditarProfessor(false);
            setStatusProfessor(!statusProfessor);
            setTimeout(() => {
              window.location.reload();
            }, 550);
          } else {
            if (res.status == 500) {
              setFreeze(false);
              navigate("/500");
            }
            setFreeze(false);
            toast.error(res.error);
            setMotivoSuspensao("");
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setErroMotivoSuspensao(true);
    }
  }

  function eliminarProfessor() {
    setEliminar(false);
    setFreeze(true);
    deleteProfessor({ id: funcionarioSelecionadoId })
      .then((res) => {
        if (res.success) {
          toast.success(res.data.msg);
          seteditarProfessor(false);
          setTimeout(() => {
            window.location.reload();
          }, 550);
        } else {
          if (res.status == 500) {
            setFreeze(false);
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function enviarEmail() {
    recuperaPass({
      email: emailProfessor,
    }).then((res) => {
      if (res.success) {
        toast.success(t("reporPswProf"));
        //navigate("/superadmins");
        setFreeze(false);
        handleClosePopupReporPassword();
      } else {
        setFreeze(false);
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaProfessor")}</h3>
        <div className="divTabelaM">
          {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/dashboard_admin");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
          {editarProfessor ? (
            <div style={{ width: "100%", height: "100%" }}>
              <IconButton
                onClick={() => {
                  seteditarProfessor(false);
                }}
                size={isMobile ? "small" : "medium"}
                style={{
                  position: "absolute",
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </IconButton>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  left: "50%",
                  paddingTop: isMobile ? "30px" : 0,
                }}
              >
                <button
                  className={`botaolistaadmin ${
                    selecionado === "suspender"
                      ? "botaolistaadminselecionado"
                      : ""
                  }`}
                  style={{
                    width: isMobile ? "95px" : "145px",
                    fontSize: isMobile ? "12px" : "14px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClick("suspender");
                    handleClickOpenPopupSuspender();
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "8px",
                    }}
                    icon={faCircleStop}
                  />{" "}
                  {statusProfessor === 1 ? t("suspender") : t("Reativar")}
                </button>
                <button
                  className={`botaolistaadmin ${
                    selecionado === "eliminar"
                      ? "botaolistaadminselecionado"
                      : ""
                  }`}
                  style={{
                    width: isMobile ? "95px" : "145px",
                    fontSize: isMobile ? "12px" : "14px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClick("eliminar");
                    handleClickOpenPopupEliminar();
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "8px",
                    }}
                    icon={faTrash}
                  />{" "}
                  {t("eliminar")}
                </button>
                <button
                  className={`botaolistaadmin ${
                    selecionado === "reporPalavraPasse2"
                      ? "botaolistaadminselecionado"
                      : ""
                  }`}
                  style={{
                    width: isMobile ? "115px" : "200px",
                    fontSize: isMobile ? "12px" : "14px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClick("reporPalavraPasse2");
                    handleClickOpenPopupReporPassword();
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "7px",
                      marginLeft: "3px",
                    }}
                    icon={faLock}
                  />{" "}
                  {t("reporPalavraPasse2")}
                </button>
              </div>
              <Scrollbar
                style={{ height: isMobile ? "65%" : "70%", marginTop: "20px" }}
              >
                {carregamentoDadosCompleto ? (
                  <div className="divForm">
                    <div>
                      {motivo !== "" && statusProfessor === 0 && (
                        <div
                          className="formPageSuspensao3"
                          style={{ borderRadius: "10px" }}
                        >
                          <Alert severity="error">
                            {t("funcionarioSuspenso")} - {t("motivo")}: {motivo}
                          </Alert>
                        </div>
                      )}

                      <div className="formPage2">
                        <Box className="formTextField">
                          {/* Piscina */}
                          <div style={{ marginBottom: 10 }}>
                            {/* Nome */}
                            <FormControl className="formTextField2" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("nome")}
                                value={nomeProfessor}
                                error={erroNomeProfessor}
                                onChange={(e) => {
                                  setNomeProfessor(e.target.value);
                                  setErroNomeProfessor(false);
                                }}
                              />
                            </FormControl>

                            {/* Email */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("email")}
                                value={emailProfessor}
                                error={erroEmailProfessor}
                                helperText={mensagemErroEmailProfessor}
                                onChange={(e) => {
                                  setEmailProfessor(
                                    e.target.value.toLocaleLowerCase()
                                  );
                                  setErroEmailProfessor(false);
                                  setMensagemErroEmailProfessor("");
                                }}
                              />
                            </FormControl>
                            {/* NIPC */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("nif")}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 9);
                                }}
                                value={nifProfessor}
                                error={erroNifProfessor}
                                helperText={mensagemErroNifProfessor}
                                onChange={(e) => {
                                  setNifProfessor(e.target.value);
                                  setErroNifProfessor(false);
                                }}
                              />
                            </FormControl>

                            {/* Telefone */}
                            <FormControl className="formTextField3" fullWidth>
                              <PhoneInput
                                disabled={edicao}
                                defaultCountry="PT"
                                labels={
                                  i18n.language == "pt"
                                    ? pt
                                    : i18n.language == "es"
                                    ? es
                                    : en
                                }
                                value={telefoneProfessor}
                                error={erroTelefoneProfessor}
                                helperText={mensagemErroTelefoneProfessor}
                                onChange={(e) => {
                                  setTelefoneProfessor(e);
                                  setErroTelefoneProfessor(false);
                                  setMensagemErroTelefoneProfessor("");
                                }}
                                inputComponent={PhoneNumber}
                              />
                            </FormControl>
                            {/* Genero */}
                            <FormControl
                              className="formTextField3"
                              sx={{
                                display: "flex",
                                justifycontent: "flex-start",
                                alignitems: "flex-start",
                                marginTop: "5px",
                              }}
                              disabled={edicao}
                            >
                              <FormLabel
                                sx={{
                                  display: "flex",
                                  justifycontent: "flex-start",
                                  alignitems: "flex-start",
                                }}
                              >
                                {t("genero")}
                              </FormLabel>

                              <RadioGroup
                                row
                                label={t("genero")}
                                defaultValue={genero}
                                name="genero"
                                value={genero}
                                onChange={(e) => {
                                  setGenero(e.target.value);
                                  seterroGenero(false);
                                }}
                              >
                                <FormControlLabel
                                  value="feminino"
                                  control={<Radio size="small" />}
                                  label={t("F")}
                                />
                                <FormControlLabel
                                  value="masculino"
                                  control={<Radio size="small" />}
                                  label={t("M")}
                                />
                                <FormControlLabel
                                  value="outro"
                                  control={<Radio size="small" />}
                                  label={t("O")}
                                />
                              </RadioGroup>
                            </FormControl>
                            {/* Rua */}
                            <FormControl className="formTextField3" fullWidth>
                              <TextField
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("rua")}
                                value={moradaProfessor}
                                error={erroMoradaProfessor}
                                onChange={(e) => {
                                  setMoradaProfessor(e.target.value);
                                  setErroMoradaProfessor(false);
                                }}
                              />
                            </FormControl>
                            {/* Código Postal */}
                            <FormControl className="formTextField3" fullWidth>
                              <PatternFormat
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                {...{
                                  label: t("codigoPostal"),
                                  variant: "standard",
                                }}
                                customInput={TextField}
                                required
                                mask="_"
                                format="####-###"
                                value={codigoPostalProfessor}
                                error={erroCodigoPostalProfessor}
                                helperText={mensagemErroCodigoPostalProfessor}
                                onChange={(e) => {
                                  setCodigoPostalProfessor(e.target.value);
                                  setErroCodigoPostalProfessor(false);
                                  setMensagemErroCodigoPostalProfessor("");
                                }}
                              />
                            </FormControl>
                            {/* Data de nascimento */}
                            <FormControl className="formTextField3" fullWidth>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={i18n.language}
                              >
                                <DatePicker
                                  disableFuture
                                  disabled={edicao}
                                  label={t("dataNascimento")}
                                  openTo="day"
                                  views={["day", "year", "month"]}
                                  value={dataNascimentoProfessor}
                                  onChange={(e) => {
                                    setDataNascimentoProfessor(e);
                                    setErroNascimentoProfessor(false);
                                    setMensagemErroNascimentoProfessor("");
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="standard"
                                      {...params}
                                      required
                                      error={erroNascimentoProfessor}
                                      helperText={
                                        mensagemErroNascimentoProfessor
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            {/* Foto */}
                            <FormControl className="formTextField3" fullWidth>
                              <p
                                id="tituloFotoPiscina"
                                style={{
                                  marginTop: 10,
                                  color: Colors.textFields,
                                  fontSize: 15,
                                  fontWeight: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {t("foto")} *
                              </p>
                              <label
                                id="fotoPiscina"
                                htmlFor="file-upload"
                                className="custom-file-upload"
                              >
                                <i className="fa fa-cloud-upload"></i>
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  style={{ paddingTop: 4, paddingRight: 4 }}
                                />{" "}
                                {t("carregarImagem")}
                              </label>
                              <input
                                disabled={edicao}
                                id="file-upload"
                                style={{ paddingTop: 0 }}
                                type="file"
                                accept="image/*"
                                onChange={async (e) => {
                                  document.getElementById(
                                    "fotoPiscina"
                                  ).style.borderColor = Colors.ativo;
                                  document.getElementById(
                                    "tituloFotoPiscina"
                                  ).style.color = Colors.textFields;
                                  const file = e.target.files[0];

                                  const reader = new FileReader();

                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    setFotoProfessor(reader.result);
                                  };
                                }}
                              ></input>
                              {/* Imagem carregada */}
                              <div>
                                {fotoProfessor.length > 0 && (
                                  <img
                                    style={{ marginTop: 10,objectFit:"cover" }}
                                    width={150}
                                    height={150}
                                    src={fotoProfessor}
                                  />
                                )}
                              </div>
                            </FormControl>
                          </div>
                        </Box>
                      </div>
                    </div>
                    <div className="divForm">
                      <div className="formPage2">
                        <Box
                          className="formTextField"
                          style={{ marginTop: 40 }}
                        >
                          <h3 className="titleList2">{t("cronologia")}</h3>
                          <div style={{ height: "auto", width: "100%" }}>
                            <ThemeProvider theme={theme}>
                              <Timeline
                                sx={{
                                  [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                  },
                                }}
                              >
                                {suspensoes.map((item, index) => (
                                  <TimelineItem key={item.id_suspensao}>
                                    <TimelineSeparator>
                                      <TimelineConnector />
                                      <TimelineDot
                                        color={
                                          item.tipo === 1
                                            ? "primary"
                                            : "secondary"
                                        }
                                      >
                                        {mapIcon(item.tipo)}
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px" }}>
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="flex-start"
                                      >
                                        <Grid item>
                                          <Typography
                                            variant="h6"
                                            component="span"
                                          >
                                            {item.tipo === 1
                                              ? "Suspensão"
                                              : "Ativação"}
                                          </Typography>
                                          <br></br>
                                          <Typography variant="text">
                                            {t("motivo")}:{" "}
                                          </Typography>
                                          <Typography variant="text">
                                            {item.obs}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="body2">
                                            {formatDateAndTime(item.criado)}
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            {item.nome_resp}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TimelineContent>
                                  </TimelineItem>
                                ))}
                              </Timeline>
                            </ThemeProvider>
                          </div>
                        </Box>
                      </div>
                    </div>
                    {/* Cancelar edição */}
                    <Dialog
                      open={cancelarEdicao}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          width: 452,
                          height: 186,
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("cancelarEdicao")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {t("textoCancelarEdicao")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundImage: `url(${simbtn})`,
                            width: 218,
                            height: 40,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            cancelar();
                          }}
                        >
                          {t("sim")}
                        </Button>
                        <Button
                          className="btnSim"
                          variant="contained"
                          style={{
                            backgroundImage: `url(${naobtn})`,
                            width: 198,
                            height: 30,
                            cursor: "pointer",
                            zIndex: 200,
                          }}
                          onClick={() => {
                            handleClosePopupCancelar();
                          }}
                        >
                          {t("nao")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "transparent",
                      position: "absolute",
                      width: window.innerWidth,
                      height: window.innerHeight,
                      zIndex: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: 0,
                    }}
                  >
                    <CircularProgress disableShrink />
                  </div>
                )}
              </Scrollbar>
              {/* Eliminar funcionario */}
              <Dialog
                open={eliminar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 186,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("eliminarProfessor2")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupEliminar();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("textoEliminarProfessor")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      eliminarProfessor();
                      setSelecionado(null);
                    }}
                    autoFocus
                  >
                    {t("sim")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupEliminar();
                      setSelecionado(null);
                    }}
                  >
                    {t("nao")}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Repor password funcionario */}
              <Dialog
                open={reporPassword}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    height: 220,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {t("reenviarAtivacao")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupReporPassword();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                {reporPwd ? (
                  <DialogContentText
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{
                        marginTop: 20,
                      }}
                    />
                  </DialogContentText>
                ) : (
                  <>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoReporPasswordF")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          setReporPwd(true);
                          enviarEmail();
                          setSelecionado(null);
                        }}
                        autoFocus
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupReporPassword();
                          setSelecionado(null);
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
              {/* Suspender funcionario */}
              <Dialog
                open={suspender}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    width: 452,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {statusProfessor
                    ? t("suspenderProfessor")
                    : t("ativarProfessor")}
                  <FontAwesomeIcon
                    edge="end"
                    color="inherit"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={() => {
                      handleClosePopupSuspender();
                      setSelecionado(null);
                    }}
                    aria-label="Fechar"
                    icon={faClose}
                  />
                </DialogTitle>
                <DialogContent>
                  {statusProfessor
                    ? t("justificacaoSuspencao")
                    : t("justificacaoAtivacao")}{" "}
                  <FormControl fullWidth>
                    <TextField
                      style={{ textAlign: "left", marginTop: 10 }}
                      variant="outlined"
                      multiline
                      rows={5}
                      value={motivoSuspensao}
                      error={erroMotivoSuspensao}
                      onChange={(e) => {
                        setMotivoSuspensao(e.target.value);
                        setErroMotivoSuspensao(false);
                      }}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, e.target.value)
                      //     .toString()
                      //     .slice(0, 500);
                      // }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${naobtn})`,
                      width: 198,
                      height: 30,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClosePopupSuspender();
                      setSelecionado(null);
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                  <Button
                    className="btnSim"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${simbtn})`,
                      width: 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      suspenderProfessor();
                    }}
                    autoFocus
                  >
                    {statusProfessor ? t("suspender") : t("ativar")}
                  </Button>
                </DialogActions>
              </Dialog>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {edicao ? (
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: `url(${editarbtn})`,
                      width: 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />
                    {t("editar")}
                  </Button>
                ) : (
                  <div>
                    <Button
                      className="btnGuardar"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${guardarbtn})`,

                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onClick={() => {
                        validacao();
                      }}
                    >
                      {t("guardar")}
                    </Button>
                    <Button
                      className="btnGuardar"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${cancelarbtn})`,

                        height: 40,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onClick={() => {
                        handleClickOpenPopupCancelar();
                      }}
                    >
                      {t("cancelar")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                className="col-0"
                style={{
                  textAlign: "start",
                  marginTop: 45,
                  marginLeft: 30,
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <span
                  className="addButton"
                  style={{ zIndex: "1" }}
                  onClick={() => {
                    handleClickOpenCriarProfessor();
                  }}
                ></span>
                <Search2
                  onSearch={(value) => {
                    setSearch(value);
                  }}
                />
              </div>
              <div style={{ height: "62vh", maxWidth: "100%" }}>
                <DataGrid
                  className={isMobile ? "tabela2" : "tabela"}
                  pageSize={10}
                  //Colunas
                  columns={[
                    {
                      field: "id",
                      headerName: t("id"),
                      type: "number",
                      width: 5,
                      hide: columnsHidden.includes("id"),
                    },
                    {
                      field: "nome",
                      headerName: t("nome"),
                      type: "string",
                      flex: 1,
                    },
                    {
                      field: "email",
                      headerName: t("email"),
                      flex: 1,
                      type: "string",
                      hide: columnsHidden.includes("email"),
                    },
                    {
                      field: "status",
                      headerName: t("estado"),
                      maxWidth: 100,
                      flex: 1,
                      type: "string",
                      hide: columnsHidden.includes("status"),

                      renderCell: (params) => {
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {params.row.status == t("ativo") ? (
                              <>
                                {isMobile ? null : (
                                  <p
                                    style={{
                                      color: "rgba(0, 0, 0, 0.87)",
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {t("ativo")}
                                  </p>
                                )}

                                <FontAwesomeIcon
                                  color={Colors.ativo}
                                  fontSize={25}
                                  icon={faCircleCheck}
                                />
                              </>
                            ) : (
                              <>
                                {isMobile ? null : (
                                  <p
                                    style={{
                                      color: "rgba(0, 0, 0, 0.87)",
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {t("inativo")}
                                  </p>
                                )}{" "}
                                <FontAwesomeIcon
                                  color={Colors.inativo}
                                  fontSize={25}
                                  icon={faCircleXmark}
                                />
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      field: "actions",
                      type: "actions",
                      headerName: t("acoes"),
                      width: 70,
                      cellClassName: "actions",
                      getActions: ({ id }) => {
                        return [
                          <GridActionsCellItem
                            icon={
                              <FontAwesomeIcon
                                style={{ fontSize: isMobile ? 12 : 18 }}
                                icon={faPen}
                              />
                            }
                            label="Edit"
                            onClick={() => {
                              setFuncionarioSelecionadoId(id);
                              seteditarProfessor(true);
                            }}
                          />,
                        ];
                      },
                    },
                  ]}
                  rows={professores}
                  checkboxSelection={isMobile ? false : true}
                  onRowClick={(params) => {}}
                  getRowId={(row) => row.id}
                  //Idioma
                  localeText={
                    i18n.language == "pt"
                      ? ptBR.components.MuiDataGrid.defaultProps.localeText
                      : i18n.language == "es"
                      ? esES.components.MuiDataGrid.defaultProps.localeText
                      : enUS.components.MuiDataGrid.defaultProps.localeText
                  }
                  components={{
                    Toolbar: CustomToolbar,
                    BaseCheckbox: ({ value, ...otherProps }) => (
                      <Checkbox size="small" checked={value} {...otherProps} />
                    ),
                  }}
                  columnsHidden={isMobile ? ["id", "email", "status"] : []}
                />
              </div>
            </div>
          )}
          {/* adicionar cliente */}
          <Dialog
            open={openCriarProfessor}
            onClose={handleCloseCriarProfessor}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: 450,
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarProfessor")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarProfessor}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div className="formPop" style={{ marginTop: 30 }}>
                <Box className="formTextField">
                  <div>
                    {/* Nome */}
                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="text"
                        variant="standard"
                        label={t("nome")}
                        value={nomeProfessor}
                        error={erroNomeProfessor}
                        onChange={(e) => {
                          setNomeProfessor(e.target.value);
                          setErroNomeProfessor(false);
                        }}
                      />
                    </FormControl>
                    {/* Email */}
                    <FormControl className="formTextField3" fullWidth>
                      <TextField
                        required
                        type="text"
                        variant="standard"
                        label={t("email")}
                        value={emailProfessor}
                        error={erroEmailProfessor}
                        helperText={mensagemErroEmailProfessor}
                        onChange={(e) => {
                          setEmailProfessor(e.target.value.toLocaleLowerCase());
                          setErroEmailProfessor(false);
                          setMensagemErroEmailProfessor("");
                        }}
                      />
                    </FormControl>
                    {/* Telefone */}
                    <FormControl className="formTextField3" fullWidth>
                      <PhoneInput
                        defaultCountry="PT"
                        labels={
                          i18n.language == "pt"
                            ? pt
                            : i18n.language == "es"
                            ? es
                            : en
                        }
                        value={telefoneProfessor}
                        error={erroTelefoneProfessor}
                        helperText={mensagemErroTelefoneProfessor}
                        onChange={(e) => {
                          setTelefoneProfessor(e);
                          setErroTelefoneProfessor(false);
                          setMensagemErroTelefoneProfessor("");
                        }}
                        inputComponent={PhoneNumber}
                      />
                    </FormControl>
                    {/* Genero */}
                    <FormControl
                      className="formTextField3"
                      sx={{
                        display: "flex",
                        justifycontent: "flex-start",
                        alignitems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      <FormLabel>{t("genero")}</FormLabel>
                      <RadioGroup
                        row
                        label={t("genero")}
                        defaultValue={genero}
                        name="genero"
                        value={genero}
                        onChange={(e) => {
                          setGenero(e.target.value);
                          seterroGenero(false);
                        }}
                      >
                        <FormControlLabel
                          value="feminino"
                          control={<Radio size="small" />}
                          label={t("F")}
                        />
                        <FormControlLabel
                          value="masculino"
                          control={<Radio size="small" />}
                          label={t("M")}
                        />
                        <FormControlLabel
                          value="outro"
                          control={<Radio size="small" />}
                          label={t("O")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaProfessoresA;
