import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { postFilho } from "../../api/requests/ContaFamiliar";
import { verificarNif } from "../../api/requests/Registo";
import dayjs from "dayjs";

function CriarFilhoF() {
  //Traduções
  const { t, i18n } = useTranslation();
  const maxDate = dayjs().subtract(16, "year").toDate();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idPiscina, idPai } = state || {};

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nome, setNome] = useState("");
  const [nif, setNif] = useState("");
  const [morada, setMorada] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [foto, setFoto] = useState([]);
  const [dataNascimento, setDataNascimento] = useState(null);

  const [erroNome, setErroNome] = useState(false);
  const [erroNif, setErroNif] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroCodigoPostal, setErroCodigoPostal] = useState(false);
  const [erroFoto, setErroFoto] = useState("");
  const [erroDataNascimento, setErroDataNascimento] = useState("");

  const [mensagemErroNif, setMensagemErroNif] = useState("");
  const [mensagemErroMorada, setMensagemErroMorada] = useState("");
  const [mensagemErroNome, setMensagemErroNome] = useState("");
  const [mensagemErroCodigoPostal, setMensagemErroCodigoPostal] = useState("");
  const [mensagemErroDataNascimento, setMensagemErroDataNascimento] =
    useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  function criarSuperAdmin() {
    setFreeze(true);
    postFilho({
      id: idPai,
      idPiscina: idPiscina,
      nome: nome,
      morada: morada,
      codigo_postal: codigoPostal,
      data_nascimento: new Date(dataNascimento).toISOString().split("T")[0],
      nif: nif,
      foto: foto,
    }).then((res) => {
      if (res.success) {
        toast.success(res.data.msg);
        setFreeze(false);
        navigate("/familyf", {
          state: {
            idPiscina: idPiscina,
            idPai: idPai,
          },
        });
      } else {
        
        toast.error(res.error);
        setFreeze(false);
      }
    });
  }

  async function validacao1() {
    if (nome && morada && nif && codigoPostal && dataNascimento) {
      let confirmarDadosPreenchidos = true;

      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }
      if (codigoPostal.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarSuperAdmin();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }

      if (!morada) {
        setErroMorada(true);
      }

      //Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });

      if (!codigoPostal) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal.includes("_")) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }

      //Validação da foto
      if (foto.length == 0) {
        document.getElementById("fotoPiscina").style.borderColor =
          Colors.inativo;
        document.getElementById("tituloFotoPiscina").style.color =
          Colors.inativo;
      }
    }
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="align-left">
            <IconButton
              className="arrowBack"
              variant="text"
              onClick={() => {
                navigate("/familyf", {
                  state: {
                    idPiscina: idPiscina,
                    idPai: idPai,
                  },
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </div>

          <div className="align-right">
            {/* <h3 className="titleList">{t("adicionarFilho")}</h3> */}
          </div>
        </div>
        <div className="caminhoPaginas2">
          <a
            className="caminhoPaginas_a"
            onClick={() => {
              navigate("/poolcustomers", {
                state: { idPiscina: idPiscina },
              });
            }}
          >
            {t("listaClientes")}
          </a>
          <a className="caminhoPaginas_a2">{`>`}</a>
          <a
            className="caminhoPaginas_a"
            onClick={() => {
              navigate("/editcustomerf", {
                state: {
                  idPiscina: idPiscina,
                  idCliente: idPai,
                },
              });
            }}
          >
            {t("editarCliente")}
          </a>
          <a className="caminhoPaginas_a2">{`>`}</a>
          <a
            className="caminhoPaginas_a"
            onClick={() => {
              navigate("/familyf", {
                state: {
                  idPiscina: idPiscina,
                  idPai: idPai,
                },
              });
            }}
          >
            {t("contaFamiliar")}
          </a>
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nome}
                    error={erroNome}
                    inputProps={{ maxLength: 200 }}
                    onChange={(e) => {
                      setNome(e.target.value);
                      setErroNome(false);
                    }}
                  />
                </FormControl>
                {/* Morada */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("morada")}
                    inputProps={{ maxLength: 200 }}
                    value={morada}
                    error={erroMorada}
                    onChange={(e) => {
                      setMorada(e.target.value);
                      setErroMorada(false);
                    }}
                  />
                </FormControl>

                {/* NIF */}
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("nif")}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 9);
                    }}
                    inputProps={{ maxLength: 9 }}
                    value={nif}
                    error={erroNif}
                    helperText={mensagemErroNif}
                    onChange={(e) => {
                      setNif(e.target.value);
                      setErroNif(false);
                    }}
                  />
                </FormControl>
                {/* Código Postal */}
                <FormControl className="formTextField3" fullWidth>
                  <PatternFormat
                    type="text"
                    variant="standard"
                    {...{
                      label: t("codigoPostal"),
                      variant: "standard",
                    }}
                    customInput={TextField}
                    required
                    mask="_"
                    value={codigoPostal}
                    format="####-###"
                    error={erroCodigoPostal}
                    helperText={mensagemErroCodigoPostal}
                    onChange={(e) => {
                      setCodigoPostal(e.target.value);
                      setErroCodigoPostal(false);
                      setMensagemErroCodigoPostal("");
                    }}
                  />
                </FormControl>
                {/* Data de nascimento */}
                <FormControl className="formTextField3" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      disableFuture
                      minDate={maxDate}
                      label={t("dataNascimento")}
                      openTo="day"
                      value={dataNascimento}
                      views={["day", "year", "month"]}
                      onChange={(e) => {
                        setDataNascimento(e);
                        setErroDataNascimento(false);
                        setMensagemErroDataNascimento("");
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataNascimento}
                          helperText={mensagemErroDataNascimento}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                {/* Foto */}
                <FormControl className="formTextField3" fullWidth>
                  <p
                    id="tituloFotoPiscina"
                    style={{
                      marginTop: 10,
                      color: Colors.textFields,
                      fontSize: 15,
                      fontWeight: "normal",
                      textAlign: "left",
                    }}
                  >
                    {t("foto")}
                  </p>
                  <label
                    id="fotoPiscina"
                    htmlFor="file-upload"
                    className="custom-file-upload"
                  >
                    <i className="fa fa-cloud-upload"></i>
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ paddingTop: 4, paddingRight: 4 }}
                    />{" "}
                    {t("carregarImagem")}
                  </label>
                  <input
                    id="file-upload"
                    style={{ paddingTop: 0 }}
                    type="file"
                    accept="image/*"
                    onChange={async (e) => {
                      document.getElementById("fotoPiscina").style.borderColor =
                        Colors.ativo;
                      document.getElementById("tituloFotoPiscina").style.color =
                        Colors.textFields;
                      const file = e.target.files[0];

                      const reader = new FileReader();

                      reader.readAsDataURL(file);

                      reader.onload = () => {
                        setFoto(reader.result);
                      };
                    }}
                  ></input>
                  {/* Imagem carregada */}
                  <div>
                    {foto.length > 0 && (
                      <img
                        style={{ marginTop: 10 }}
                        width={150}
                        height={150}
                        src={foto}
                      />
                    )}
                  </div>
                </FormControl>
              </div>
            </Box>
          </div>
        </div>
        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarFilhoF;
