import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCircleCheck,
  faCircleXmark,
  faPen,
  faChildren,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { getFilhos } from "../../api/requests/ContaFamiliar";
import Search2 from "../../components/DataTable/Search2";
import {
  verStatus,
  postStatusContaFamiliar,
} from "../../api/requests/ContaFamiliar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
} from "@mui/material";
import ListaClientes from "../Clientes/Clientes";

function ListaFilhos() {
  const [Filhos, setFilhos] = useState([]);
  const [FilhosCopy, setFilhosCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);

  const { state } = useLocation();
  const { idPiscina, nomePiscina, idPai } = state || {};

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  function mudaStatus() {
    postStatusContaFamiliar({ id: idPai, idPiscina: idPiscina }).then((res) => {
      if (res.success) {
        setStatus(res.data.contaFamiliar);
        if (res.data.contaFamiliar == 1) {
          toast.success(t("ativarConta"));
        } else {
          toast.warn(t("desativarConta"));
        }
      } else {
        toast.error(t("erroStatusConta"));
      }
    });
  }

  useEffect(() => {
    if (search) {
      let arrayFilhos = FilhosCopy;
      arrayFilhos = arrayFilhos.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setFilhos(arrayFilhos);
    } else {
      setFilhos(FilhosCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    verStatus({ id: idPai, idPiscina: idPiscina })
      .then((res) => {
        if (res.success) {
          setStatus(res.contaFamiliar);
        } else {
          toast.error("Não foi possivel obter o status da conta familiar");
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    getFilhos({ id: idPai, idPiscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_filho,
              nome: element.nome,
              nif: element.nif,
            };
            arrayFinal.push(linha);
            setFilhos(arrayFinal);
            setFilhosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  return (
    <>
      <Header />
      <div className="listPages">
        <div className="divTabela">
          <div className="align-left2">
            {idPiscina ? (
              <IconButton
                className="arrowBack"
                variant="text"
                onClick={() => {
                  navigate("/editcustomer", {
                    state: {
                      idPiscina: idPiscina,
                      nomePiscina: nomePiscina,
                      idCliente: idPai,
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </IconButton>
            ) : null}
          </div>
          {/* <h3 className="titleList">{t("listaFilhos")}</h3> */}
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {status == 0
                ? "Ativar Conta Familiar"
                : "Desativar Conta Familiar"}
            </DialogTitle>
            <DialogContent>
              {status == 0 ? (
                <DialogContentText id="alert-dialog-description">
                  {t("ativarContaF")}
                </DialogContentText>
              ) : (
                <DialogContentText id="alert-dialog-description">
                  {t("desativarContaF")}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClose}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  mudaStatus();
                  handleClose;
                }}
                autoFocus
              >
                {status == 0 ? "Ativar" : "Desativar"}
              </Button>
            </DialogActions>
          </Dialog>
          {idPiscina ? (
            <div className="col-0" id="divCaminhos">
              <div className="caminhoPaginas">
                <a className="caminhoPaginas_a" href="/pools">
                  {t("piscinas")}
                </a>
                <a className="caminhoPaginas_a2">{`>`}</a>
                <a
                  className="caminhoPaginas_a"
                  onClick={() => {
                    navigate("/editpool", {
                      state: { id: idPiscina },
                    });
                  }}
                >
                  {nomePiscina.substring(0, 15)}...
                </a>
                <a className="caminhoPaginas_a2">{`>`}</a>
                <a
                  className="caminhoPaginas_a"
                  onClick={() => {
                    navigate("/customers", {
                      state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                    });
                  }}
                >
                  {t("listaClientes")}
                </a>
                <a className="caminhoPaginas_a2">{`>`}</a>
                <a
                  className="caminhoPaginas_a"
                  onClick={() => {
                    navigate("/editcustomer", {
                      state: {
                        idPiscina: idPiscina,
                        nomePiscina: nomePiscina,
                        idCliente: idPai,
                      },
                    });
                  }}
                >
                  {t("editarCliente")}
                </a>
              </div>
              <div>
                {status == 0 ? (
                  <Button
                    className="addButton1"
                    variant="contained"
                    style={{
                      backgroundColor: "purple",
                      marginRight: 10,
                    }}
                    onClick={handleOpen}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faChildren} />
                    Ativar
                  </Button>
                ) : (
                  <Button
                    className="addButton1"
                    variant="contained"
                    style={{
                      backgroundColor: "purple",
                      marginRight: 10,
                    }}
                    onClick={handleOpen}
                  >
                    <FontAwesomeIcon className="iconCards" icon={faChildren} />
                    Desativar
                  </Button>
                )}

                <Button
                  className="addButton1"
                  variant="contained"
                  onClick={() => {
                    navigate("/createchild", {
                      state: {
                        idPiscina: idPiscina,
                        nomePiscina: nomePiscina,
                        idPai: idPai,
                      },
                    });
                  }}
                >
                  {t("adicionar")}
                </Button>
              </div>
            </div>
          ) : null}
          <Search2
            onSearch={(value) => {
              setSearch(value);
            }}
          />
          <DataGrid
            className="tabela"
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 100,
                flex: 1,
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              {
                field: "nif",
                headerName: t("nif"),
                flex: 1,
                type: "string",
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        navigate("/editchild", {
                          state: {
                            idPiscina: idPiscina,
                            nomePiscina: nomePiscina,
                            idCliente: idPai,
                            idFilho: id,
                          },
                        });
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={Filhos}
            checkboxSelection={true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: GridToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
        </div>
        {Cookies.get("utilizador") == "superadmin" ||
        Cookies.get("utilizador") == "supercomercial" ||
        Cookies.get("utilizador") == "comercial" ? null : (
          <Footer />
        )}
      </div>
    </>
  );
}

export default ListaFilhos;
