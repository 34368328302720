import Cookies from "js-cookie";
import { makeRequestPost2, makeRequestPut2 } from "../api";

//Get Comerciais
export function getComerciais(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("comercial/all", dados, token);
}


//Post super comercial
export function postComercial(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("comercial/", dados, token);
}

//Enviar email -Post super comercial
export function enviarEmailPostComercial(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("comercial/active-account", dados, token);
}

//Get por id
export function getComercial(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`comercial/id/${id}`, {}, token);
}

//Editar
export function putComercial(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`comercial/`, dados, token);
}
