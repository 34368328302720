import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faPen,
  faStop,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PencilIcon } from "@heroicons/react/24/solid";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { Scrollbar } from "react-scrollbars-custom";
import editarbtn from "../../images/BotaoEditar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";

import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";

import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { createSearchParams, Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  deletePiscina,
  getPiscinasId,
  postStatusPiscina,
  putPiscina,
} from "../../api/requests/Piscinas";
import { enviarEmailNovoPedido, postPedidos } from "../../api/requests/Pedidos";
import Nav from "../../components/Nav";
import { getDistritos } from "../../api/requests/Utilizadores";

function EditarPiscina() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const privilegios = JSON.parse(Cookies.get("privilegios"));

  const { state } = useLocation();
  const { id } = state || {};
  const id2 = id;
  const [freeze, setFreeze] = useState(false);

  //Variáveis
  const [edicao, setEdicao] = useState(true);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  // Entidade

  const options = useMemo(() => countryList().getData(), []);
  //Piscina
  const [nomePiscina, setNomePiscina] = useState("");
  const [statusPiscina, setStatusPiscina] = useState();
  const [emailPiscina, setEmailPiscina] = useState("");
  const [telefonePiscina, setTelefonePiscina] = useState("");
  const [telefone2Piscina, setTelefone2Piscina] = useState("");
  const [fotoPiscina, setFotoPiscina] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [idFoto1, setIdFoto1] = useState("");
  const [foto2, setFoto2] = useState([]);
  const [idFoto2, setIdFoto2] = useState("");
  const [foto3, setFoto3] = useState([]);
  const [idFoto3, setIdFoto3] = useState("");
  const [foto4, setFoto4] = useState([]);
  const [idFoto4, setIdFoto4] = useState("");
  const [foto5, setFoto5] = useState([]);
  const [idFoto5, setIdFoto5] = useState("");
  const [foto6, setFoto6] = useState([]);
  const [idFoto6, setIdFoto6] = useState("");
  const [codigoPostalPiscina, setCodigoPostalPiscina] = useState("");
  const [cidadePiscina, setCidadePiscina] = useState("");
  const [distritoPiscina, setDistritoPiscina] = useState("");
  const [paisPiscina, setPaisPiscina] = useState("");
  const [latitudePiscina, setLatitudePiscina] = useState("");
  const [longitudePiscina, setLongitudePiscina] = useState("");
  const [ruaPiscina, setRuaPiscina] = useState("");
  const [nipcPiscina, setNipcPiscina] = useState("");

  //Modalidades
  const [natacaoAdultos, setNatacaoAdultos] = useState(false);
  const [natacaoBebes, setNatacaoBebes] = useState(false);
  const [natacaoIdosos, setNatacaoIdosos] = useState(false);
  const [hidroginastica, setHidroginastica] = useState(false);
  const [escolaNatacao, setEscolaNatacao] = useState(false);
  const [hidrobike, setHidrobike] = useState(false);
  const [natacaoGravidas, setNatacaoGravidas] = useState(false);
  const [hidroterapia, setHidroterapia] = useState(false);
  const [nadoLivre, setNadoLivre] = useState(false);
  const [equipasFederadas, setEquipasFederadas] = useState(false);
  const [hidropower, setHidropower] = useState(false);
  //Horarios
  const [horario, setHorario] = useState([]);
  const [horario2, setHorario2] = useState([]);
  //Links uteis
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  //Termos e condições (quando o cliente se inscreve na piscina)
  const [termos, setTermos] = useState();
  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [distritos, setDistritos] = useState([]);
  const [idEntidadeLegal, setIdEntidadeLegal] = useState("");

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  //Validações

  //Piscina
  const [erroNomePiscina, setErroNomePiscina] = useState(false);
  const [erroNipcPiscina, setErroNipcPiscina] = useState(false);

  const [erroEmailPiscina, setErroEmailPiscina] = useState(false);
  const [erroTelefonePiscina, setErroTelefonePiscina] = useState(false);
  const [erroTelefone2Piscina, setErroTelefone2Piscina] = useState(false);
  const [erroCodigoPostalPiscina, setErroCodigoPostalPiscina] = useState(false);
  const [erroCidadePiscina, setErroCidadePiscina] = useState(false);
  const [erroDistritoPiscina, setErroDistritoPiscina] = useState(false);
  const [erroPaisPiscina, setErroPaisPiscina] = useState(false);
  const [erroLatitudePiscina, setErroLatitudePiscina] = useState(false);
  const [erroLongitudePiscina, setErroLongitudePiscina] = useState(false);
  const [erroRuaPiscina, setErroRuaPiscina] = useState(false);

  //Links uteis
  const [erroLink1, setErroLink1] = useState(false);
  const [erroLink2, setErroLink2] = useState(false);
  const [erroLink3, setErroLink3] = useState(false);
  //Termos
  const [erroTermos, setErroTermos] = useState(false);

  //Mensagens de erro

  //Piscina
  const [mensagemErroEmailPiscina, setMensagemErroEmailPiscina] = useState("");
  const [mensagemErroTelefonePiscina, setMensagemErroTelefonePiscina] =
    useState("");
  const [mensagemErroTelefone2Piscina, setMensagemErroTelefone2Piscina] =
    useState("");
  const [mensagemErroNipcPiscina, setMensagemErroNipcPiscina] = useState("");

  //Links uteis
  const [mensagemErroLink1, setMensagemErroLink1] = useState("");
  const [mensagemErroLink2, setMensagemErroLink2] = useState("");
  const [mensagemErroLink3, setMensagemErroLink3] = useState("");
  const [mensagemErroCodigoPostalPiscina, setMensagemErroCodigoPostalPiscina] =
    useState("");
  const [mensagemErroLatitudePiscina, setMensagemErroLatitudePiscina] =
    useState("");
  const [mensagemErroLongitudePiscina, setMensagemErroLongitudePiscina] =
    useState("");

  useEffect(() => {
    let distritos2 = [];

    getDistritos().then((res) => {
      let arrayFinal = [];
      res.data.data.forEach((element) => {
        let linha = {
          value: element.id_distrito,
          label: element.nome,
        };
        arrayFinal.push(linha);
      });

      setDistritos(arrayFinal);
      distritos2 = res.data.data;
    });
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const idU = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!idU || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getPiscinasId(id, {})
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          setDadosOriginais(dados);
          // //Preencher dados
          setNomePiscina(dados.nome);
          setIdEntidadeLegal(dados.id_entidade_legal);
          if (dados.termos_condicoes) {
            setTermos(dados.termos_condicoes);
          }

          let status = dados.status;
          if (status == 1) {
            setStatusPiscina(true);
          } else {
            setStatusPiscina(false);
          }

          setEmailPiscina(dados.email);
          setCidadePiscina(dados.cidade);

          let nomeDistrito = distritos2.filter(
            (element) => element.id_distrito == dados.distrito
          )[0].nome;
          setDistritoPiscina({ value: dados.distrito, label: nomeDistrito });

          setCodigoPostalPiscina(dados.codigo_postal);
          setLatitudePiscina(dados.latitude);
          setLongitudePiscina(dados.longitude);
          setRuaPiscina(dados.morada);
          let paisPiscina = dados.pais.split("-");
          setPaisPiscina({ value: paisPiscina[0], label: paisPiscina[1] });

          setNipcPiscina(dados.nipc);

          setTelefonePiscina(dados.contacto1);
          setTelefone2Piscina(dados.contacto2);

          if (dados.foto_perfil_piscina) {
            setFotoPiscina(dados.foto_perfil_piscina);
          }

          dados.galeria.forEach((element) => {
            if (element.ordem == 1) {
              setFoto1(element.foto);
              setIdFoto1(element.id_imagem);
            }
            if (element.ordem == 2) {
              setFoto2(element.foto);
              setIdFoto2(element.id_imagem);
            }
            if (element.ordem == 3) {
              setFoto3(element.foto);
              setIdFoto3(element.id_imagem);
            }
            if (element.ordem == 4) {
              setFoto4(element.foto);
              setIdFoto4(element.id_imagem);
            }
            if (element.ordem == 5) {
              setFoto5(element.foto);
              setIdFoto5(element.id_imagem);
            }
            if (element.ordem == 6) {
              setFoto6(element.foto);
              setIdFoto6(element.id_imagem);
            }
          });
          //Modalidades
          if (dados.aulas_adultos) {
            setNatacaoAdultos(true);
          }
          if (dados.aulas_bebes) {
            setNatacaoBebes(true);
          }
          if (dados.aulas_gravidas) {
            setNatacaoGravidas(true);
          }
          if (dados.aulas_idoso) {
            setNatacaoIdosos(true);
          }
          if (dados.equipas_federadas) {
            setEquipasFederadas(true);
          }
          if (dados.hidro_terapia) {
            setHidroterapia(true);
          }
          if (dados.hidrobike) {
            setHidrobike(true);
          }
          if (dados.hidroginastica) {
            setHidroginastica(true);
          }
          if (dados.escola_natacao) {
            setEscolaNatacao(true);
          }
          if (dados.hidropower) {
            setHidropower(true);
          }
          if (dados.nado_livre) {
            setNadoLivre(true);
          }

          // //Horarios
          if (dados.horario) {
            setHorario(dados.horario);
          }
          // //Links uteis
          if (dados.link1) {
            setLink1(dados.link1);
          }
          if (dados.link2) {
            setLink2(dados.link2);
          }
          if (dados.link2) {
            setLink3(dados.link3);
          }
          if (dados.obs) {
            setMotivo(dados.obs);
          }
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      disabled={edicao}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  function validacao() {
    if (
      nomePiscina &&
      emailPiscina &&
      telefonePiscina &&
      ruaPiscina &&
      codigoPostalPiscina &&
      cidadePiscina &&
      distritoPiscina &&
      paisPiscina &&
      nipcPiscina &&
      latitudePiscina &&
      longitudePiscina &&
      fotoPiscina.length > 0 &&
      termos
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail2 =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailPiscina
        );
      if (!validacaoEmail2) {
        confirmarDadosPreenchidos = false;
        setErroEmailPiscina(true);
        setMensagemErroEmailPiscina(t("emailInvalido"));
      }

      // Validação do nif
      if (!nipcPiscina) {
        setErroNipcPiscina(true);
      } else {
        if (nipcPiscina.length < 9) {
          setErroNipcPiscina(true);
          setMensagemErroNipcPiscina(t("validacaoNif"));
        }
      }
      if (!isValidPhoneNumber(telefonePiscina)) {
        confirmarDadosPreenchidos = false;
        setErroTelefonePiscina(true);
        setMensagemErroTelefonePiscina(t("validacaoTelemovel"));
      }

      if (!isValidPhoneNumber(telefone2Piscina) && telefone2Piscina) {
        confirmarDadosPreenchidos = false;
        setErroTelefone2Piscina(true);
        setMensagemErroTelefone2Piscina(t("validacaoTelemovel"));
      }

      // Links úteis
      var validURL = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (link1) {
        if (!validURL.test(link1)) {
          confirmarDadosPreenchidos = false;
          setErroLink1(true);
          setMensagemErroLink1("Link inválido!");
        }
      }
      if (link2) {
        if (!validURL.test(link2)) {
          confirmarDadosPreenchidos = false;
          setErroLink2(true);
          setMensagemErroLink2("Link inválido!");
        }
      }
      if (link3) {
        if (!validURL.test(link3)) {
          confirmarDadosPreenchidos = false;
          setErroLink3(true);
          setMensagemErroLink3("Link inválido!");
        }
      }
      if (!termos) {
        setErroTermos(true);
      }

      if (confirmarDadosPreenchidos) {
        setFreeze(true);
        putPiscina({
          piscina: {
            id: id,
            id_entidade_legal: idEntidadeLegal,
            nome: nomePiscina,
            email: emailPiscina,
            nipc: nipcPiscina,
            foto_perfil: fotoPiscina,
            contacto1: telefonePiscina,
            contacto2: telefone2Piscina,
            morada: ruaPiscina,
            codigo_postal: codigoPostalPiscina,
            cidade: cidadePiscina,
            distrito: distritoPiscina.value,
            pais: paisPiscina.value + "-" + paisPiscina.label,
            latitude: latitudePiscina,
            longitude: longitudePiscina,
            foto1: foto1.length == 0 ? null : foto1,
            id_foto1: idFoto1,
            foto2: foto2.length == 0 ? null : foto2,
            id_foto2: idFoto2,
            foto3: foto3.length == 0 ? null : foto3,
            id_foto3: idFoto3,
            foto4: foto4.length == 0 ? null : foto4,
            id_foto4: idFoto4,
            foto5: foto5.length == 0 ? null : foto5,
            id_foto5: idFoto5,
            foto6: foto6.length == 0 ? null : foto6,
            id_foto6: idFoto6,
          },
          aulas_adultos: natacaoAdultos ? 1 : 0,
          aulas_bebes: natacaoBebes ? 1 : 0,
          aulas_idoso: natacaoIdosos ? 1 : 0,
          hidroginastica: hidroginastica ? 1 : 0,
          escola_natacao: EscolaNatacao ? 1 : 0,
          hidrobike: hidrobike ? 1 : 0,
          aulas_gravidas: natacaoGravidas ? 1 : 0,
          hidro_terapia: hidroterapia ? 1 : 0,
          nado_livre: nadoLivre ? 1 : 0,
          equipas_federadas: equipasFederadas ? 1 : 0,
          hidropower: hidropower ? 1 : 0,
          horario: horario.length == 0 ? null : horario,
          link1: link1 ? link1 : null,
          link2: link2 ? link2 : null,
          link3: link3 ? link3 : null,
          termos_condicoes: termos,
        }).then((res) => {
          if (res.success) {
            setFreeze(false);
            setEdicao(!edicao);

            //Scroll para o início da página
            window.scrollTo(0, 0);
            toast.success(res.data.msg);
          } else {
            // if (res.status == 500) {
            //   navigate("/500");
            // }
            setFreeze(false)
            toast.error(res.error);
          }
        });
      } else {
        setFreeze(false)
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      // ----PISCINA----
      //Validação do nome
      if (!nomePiscina) {
        setErroNomePiscina(true);
      }

      //Validação do email
      if (!emailPiscina) {
        setErroEmailPiscina(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailPiscina
          );
        if (!validacaoEmail) {
          setErroEmailPiscina(true);
          setMensagemErroEmailPiscina(t("emailInvalido"));
        }
      }
      //Validação telemóvel
      if (!telefonePiscina) {
        setErroTelefonePiscina(true);
      } else {
        if (!isValidPhoneNumber(telefonePiscina)) {
          setErroTelefonePiscina(true);
          setMensagemErroTelefonePiscina(t("validacaoTelemovel"));
        }
      }
      //Validação telemóvel 2
      if (telefone2Piscina) {
        if (!isValidPhoneNumber(telefone2Piscina)) {
          setErroTelefone2Piscina(true);
          setMensagemErroTelefone2Piscina(t("validacaoTelemovel"));
        }
      }

      //Validação da rua
      if (!ruaPiscina) {
        setErroRuaPiscina(true);
      }
      //Validação do código postal
      if (!codigoPostalPiscina) {
        setErroCodigoPostalPiscina(true);
      } else {
        if (codigoPostalPiscina.includes("_")) {
          setErroCodigoPostalPiscina(true);
          setMensagemErroCodigoPostalPiscina(t("validacaoCodigoPostal"));
        }
      }
      //Validação da cidade
      if (!cidadePiscina) {
        setErroCidadePiscina(true);
      }
      //Validação do distrito
      if (!distritoPiscina) {
        setErroDistritoPiscina(true);
      }
      //Validação do pais
      if (!paisPiscina) {
        setErroPaisPiscina(true);
      }
      //Validação da latitude
      if (!latitudePiscina) {
        setErroLatitudePiscina(true);
      } else {
        const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
        if (!regexLat.test(latitudePiscina)) {
          setErroLatitudePiscina(true);
          setMensagemErroLatitudePiscina(t("validacaoLatitude"));
        }
      }
      //Validação da longitude
      if (!longitudePiscina) {
        setErroLongitudePiscina(true);
      } else {
        const regexLon =
          /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
        if (!regexLon.test(longitudePiscina)) {
          setErroLongitudePiscina(true);
          setMensagemErroLongitudePiscina(t("validacaoLongitude"));
        }
      }
      //Validação da foto da piscina
      if (fotoPiscina.length == 0) {
        document.getElementById("fotoPiscina").style.borderColor =
          Colors.inativo;
        document.getElementById("tituloFotoPiscina").style.color =
          Colors.inativo;
      }
      // ----CONTRATO----
      if (contrato.length == 0) {
        document.getElementById("contratoLabel").style.borderColor =
          Colors.inativo;
        document.getElementById("contrato").style.color = Colors.inativo;
      }

      // Links úteis
      var validURL = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (link1) {
        if (!validURL.test(link1)) {
          setErroLink1(true);
          setMensagemErroLink1("Link inválido!");
        }
      }
      if (link2) {
        if (!validURL.test(link2)) {
          setErroLink2(true);
          setMensagemErroLink2("Link inválido!");
        }
      }
      if (link3) {
        if (!validURL.test(link3)) {
          setErroLink3(true);
          setMensagemErroLink3("Link inválido!");
        }
      }
      if (!termos) {
        setErroTermos(true);
      }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    //Colocar os dados originais
    //Piscina
    setNomePiscina(dadosOriginais.nome);
    let status = dadosOriginais.status;
    if (status == 1) {
      setStatusPiscina(true);
    }
    setEmailPiscina(dadosOriginais.email);
    setTelefonePiscina(dadosOriginais.contacto1);
    setTelefone2Piscina(dadosOriginais.contacto2);
    setCidadePiscina(dadosOriginais.cidade);
    setDistritoPiscina(dadosOriginais.distrito);
    setCodigoPostalPiscina(dadosOriginais.codigo_postal);
    setLatitudePiscina(dadosOriginais.latitude);
    setLongitudePiscina(dadosOriginais.longitude);
    setRuaPiscina(dadosOriginais.morada);
    let paisPiscina = dadosOriginais.pais.split("-");
    setPaisPiscina({ value: dadosOriginais[0], label: paisPiscina[1] });

    setNipcPiscina(dadosOriginais.nipc);

    setFotoPiscina(dadosOriginais.foto_perfil_piscina);
    dadosOriginais.galeria.forEach((element) => {
      if (element.ordem == 1) {
        setFoto1(element.foto);
        setIdFoto1(element.id_imagem);
      }
      if (element.ordem == 2) {
        setFoto2(element.foto);
        setIdFoto2(element.id_imagem);
      }
      if (element.ordem == 3) {
        setFoto3(element.foto);
        setIdFoto3(element.id_imagem);
      }
      if (element.ordem == 4) {
        setFoto4(element.foto);
        setIdFoto4(element.id_imagem);
      }
      if (element.ordem == 5) {
        setFoto5(element.foto);
        setIdFoto5(element.id_imagem);
      }
      if (element.ordem == 6) {
        setFoto6(element.foto);
        setIdFoto6(element.id_imagem);
      }
    });

    // //Modalidades
    let aulas_adultos = dadosOriginais.aulas_adultos == 1 ? true : false;
    let aulas_bebes = dadosOriginais.aulas_bebes == 1 ? true : false;
    let aulas_idosos = dadosOriginais.aulas_idoso == 1 ? true : false;
    let hidroginastica = dadosOriginais.hidroginastica == 1 ? true : false;
    let escola_natacao = dadosOriginais.escola_natacao == 1 ? true : false;
    let hidrobike = dadosOriginais.hidrobike == 1 ? true : false;
    let aulas_gravidas = dadosOriginais.aulas_gravidas == 1 ? true : false;
    let hidro_terapia = dadosOriginais.hidro_terapia == 1 ? true : false;
    let nado_livre = dadosOriginais.nado_livre == 1 ? true : false;
    let equipas_federadas =
      dadosOriginais.equipas_federadas == 1 ? true : false;
    let hidropower = dadosOriginais.hidropower == 1 ? true : false;
    setNatacaoAdultos(aulas_adultos);
    setNatacaoBebes(aulas_bebes);
    setNatacaoIdosos(aulas_idosos);
    setHidroginastica(hidroginastica);
    setEscolaNatacao(escola_natacao)
    setHidrobike(hidrobike);
    setNatacaoGravidas(aulas_gravidas);
    setHidroterapia(hidro_terapia);
    setNadoLivre(nado_livre);
    setEquipasFederadas(equipas_federadas);
    setHidropower(hidropower);
    //Horarios
    if (dadosOriginais.horario) {
      setHorario2(dadosOriginais.horario);
    }
    //Links uteis
    if (dadosOriginais.link1) {
      setLink1(dadosOriginais.link1);
    }
    if (dadosOriginais.link2) {
      setLink2(dadosOriginais.link2);
    }
    if (dadosOriginais.link2) {
      setLink3(dadosOriginais.link3);
    }
    //Limpar erros e mensagens de erro

    setErroNomePiscina(false);
    setErroEmailPiscina(false);
    setErroTelefonePiscina(false);
    setErroTelefone2Piscina(false);

    document.getElementById("fotoPiscina").style.borderColor = Colors.ativo;
    document.getElementById("tituloFotoPiscina").style.color =
      Colors.textFields;
    document.getElementById("galeriaPiscina").style.borderColor =
      Colors.textFields;
    setErroLink1(false);
    setErroLink2(false);
    setErroLink3(false);

    setMensagemErroEmailPiscina("");
    setMensagemErroTelefonePiscina("");
    setMensagemErroTelefone2Piscina("");

    setMensagemErroLink1("");
    setMensagemErroLink2("");
    setMensagemErroLink3("");
    window.scrollTo(0, 0);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("piscina")}</h3>

        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}

        <div className="divTabelaM">
          <Scrollbar style={{ height: 445 }}>
            {carregamentoDadosCompleto ? (
              <div className="divForm">
                {/* <div className="containerForm2" style={{ marginTop: "10px" }}>
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/dashboard_admin");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>
            <div className="align-right">
              <h3 className="titleList">{t("editarPiscina")}</h3>
            </div>
          </div> */}

                <div>
                  {motivo && (
                    <div className="formPageSuspensao">
                      <Alert severity="error">
                        {statusPiscina
                          ? t("piscinaAtiva")
                          : t("piscinaSuspensa")}{" "}
                        | {t("motivo")}: {motivo}
                      </Alert>
                    </div>
                  )}

                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Piscina */}
                      <div style={{ marginBottom: 10 }}>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("nome")}
                            value={nomePiscina}
                            error={erroNomePiscina}
                            onChange={(e) => {
                              setNomePiscina(e.target.value);
                              setErroNomePiscina(false);
                            }}
                          />
                        </FormControl>

                        {/* Email */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("email")}
                            value={emailPiscina}
                            error={erroEmailPiscina}
                            helperText={mensagemErroEmailPiscina}
                            onChange={(e) => {
                              setEmailPiscina(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroEmailPiscina(false);
                              setMensagemErroEmailPiscina("");
                            }}
                          />
                        </FormControl>
                        {/* NIPC */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("nipc")}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9);
                            }}
                            value={nipcPiscina}
                            error={erroNipcPiscina}
                            helperText={mensagemErroNipcPiscina}
                            onChange={(e) => {
                              setNipcPiscina(e.target.value);
                              setErroNipcPiscina(false);
                              setMensagemErroNipcPiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={edicao}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={telefonePiscina}
                            error={erroTelefonePiscina}
                            helperText={mensagemErroTelefonePiscina}
                            inputProps={{ maxLength: 20 }}
                            onChange={(e) => {
                              setTelefonePiscina(e);
                              setErroTelefonePiscina(false);
                              setMensagemErroTelefonePiscina("");
                            }}
                            inputComponent={PhoneNumber}
                          />
                        </FormControl>
                        {/* Telefone */}
                        <FormControl className="formTextField3" fullWidth>
                          <PhoneInput
                            disabled={edicao}
                            defaultCountry="PT"
                            labels={
                              i18n.language == "pt"
                                ? pt
                                : i18n.language == "es"
                                ? es
                                : en
                            }
                            value={telefone2Piscina}
                            error={erroTelefone2Piscina}
                            helperText={mensagemErroTelefone2Piscina}
                            inputProps={{ maxLength: 20 }}
                            onChange={(e) => {
                              setTelefone2Piscina(e);
                              setErroTelefone2Piscina(false);
                              setMensagemErroTelefone2Piscina("");
                            }}
                            inputComponent={PhoneNumber2}
                          />
                        </FormControl>
                        {/* Rua */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("rua")}
                            value={ruaPiscina}
                            error={erroRuaPiscina}
                            onChange={(e) => {
                              setRuaPiscina(e.target.value);
                              setErroRuaPiscina(false);
                            }}
                          />
                        </FormControl>
                        {/* Código Postal */}
                        <FormControl className="formTextField3" fullWidth>
                          <PatternFormat
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            {...{
                              label: t("codigoPostal"),
                              variant: "standard",
                            }}
                            customInput={TextField}
                            required
                            mask="_"
                            format="####-###"
                            value={codigoPostalPiscina}
                            error={erroCodigoPostalPiscina}
                            helperText={mensagemErroCodigoPostalPiscina}
                            onChange={(e) => {
                              setCodigoPostalPiscina(e.target.value);
                              setErroCodigoPostalPiscina(false);
                              setMensagemErroCodigoPostalPiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Cidade */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            type="text"
                            disabled={edicao}
                            variant="standard"
                            label={t("cidade")}
                            value={cidadePiscina}
                            error={erroCidadePiscina}
                            onChange={(e) => {
                              setCidadePiscina(e.target.value);
                              setErroCidadePiscina(false);
                            }}
                          />
                        </FormControl>
                        {/* Distrito */}
                        <FormControl className="formTextField3" fullWidth>
                          <Autocomplete
                            options={distritos}
                            value={distritoPiscina}
                            disabled={edicao}
                            onChange={(event, newValue) => {
                              setDistritoPiscina(newValue);
                              setErroDistritoPiscina(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                type="text"
                                disabled={edicao}
                                variant="standard"
                                label={t("distrito")}
                                error={erroDistritoPiscina}
                              />
                            )}
                          />
                        </FormControl>
                        {/* País */}
                        <FormControl className="formTextField3" fullWidth>
                          <Autocomplete
                            label={t("pais")}
                            variant="standard"
                            options={options}
                            disabled={edicao}
                            value={paisPiscina}
                            onChange={(event, newValue) => {
                              setPaisPiscina(newValue);
                              setErroPaisPiscina(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("pais")}
                                variant="standard"
                                error={erroPaisPiscina}
                                value={paisPiscina}
                                fullWidth
                                required
                              />
                            )}
                          />
                        </FormControl>
                        {/* Latitude */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            required
                            disabled={edicao}
                            label={t("latitude")}
                            placeholder="-34.11242"
                            variant="standard"
                            value={latitudePiscina}
                            options={options}
                            error={erroLatitudePiscina}
                            helperText={mensagemErroLatitudePiscina}
                            onChange={(e) => {
                              setLatitudePiscina(e.target.value);
                              setErroLatitudePiscina(false);
                              setMensagemErroLatitudePiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Longitude */}
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            label={t("longitude")}
                            placeholder="-58.11547"
                            variant="standard"
                            options={options}
                            value={longitudePiscina}
                            error={erroLongitudePiscina}
                            helperText={mensagemErroLongitudePiscina}
                            onChange={(e) => {
                              setLongitudePiscina(e.target.value);
                              setErroLongitudePiscina(false);
                              setMensagemErroLongitudePiscina("");
                            }}
                          />
                        </FormControl>
                        {/* Foto */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="tituloFotoPiscina"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("foto")} *
                          </p>
                          <label
                            id="fotoPiscina"
                            htmlFor="file-upload"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />{" "}
                            {t("carregarImagem")}
                          </label>
                          <input
                            disabled={edicao}
                            id="file-upload"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="image/*"
                            onChange={async (e) => {
                              document.getElementById(
                                "fotoPiscina"
                              ).style.borderColor = Colors.ativo;
                              document.getElementById(
                                "tituloFotoPiscina"
                              ).style.color = Colors.textFields;
                              const file = e.target.files[0];

                              const reader = new FileReader();

                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                setFotoPiscina(reader.result);
                              };
                            }}
                          ></input>
                          {/* Imagem carregada */}
                          <div>
                            {fotoPiscina.length > 0 && (
                              <img
                                style={{ marginTop: 10 ,objectFit:"cover" }}
                                width={150}
                                height={150}
                                src={fotoPiscina}
                              />
                            )}
                          </div>
                        </FormControl>
                        {/* Galeria */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            style={{
                              marginTop: 10,
                              color: "#858585",
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                              marginBottom: 0,
                            }}
                          >
                            {t("galeria")} - {t("max6Fotos")}
                          </p>
                          <p
                            style={{
                              marginTop: 2,
                              color: "#858585",
                              fontSize: 12,
                              textAlign: "left",
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ padding: 0, marginRight: 5 }}
                              icon={faCircleInfo}
                            />
                            {t("tamanhoImagens")}
                          </p>
                          <label
                            id="galeriaPiscina"
                            htmlFor="file-upload2"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />{" "}
                            {t("carregarImagens")}
                          </label>
                          <input
                            disabled={edicao}
                            id="file-upload2"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={async (e) => {
                              document.getElementById(
                                "galeriaPiscina"
                              ).style.borderColor = Colors.ativo;
                              setFoto1([]);
                              setIdFoto1("");
                              setFoto2([]);
                              setIdFoto2("");
                              setFoto3([]);
                              setIdFoto3("");
                              setFoto4([]);
                              setIdFoto4("");
                              setFoto5([]);
                              setIdFoto5("");
                              setFoto6([]);
                              setIdFoto6("");

                              let imagem1 = e.target.files[0];
                              let imagem2 = e.target.files[1];
                              let imagem3 = e.target.files[2];
                              let imagem4 = e.target.files[3];
                              let imagem5 = e.target.files[4];
                              let imagem6 = e.target.files[5];
                              if (imagem1) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem1);

                                reader.onload = () => {
                                  setFoto1(reader.result);
                                };
                              }
                              if (imagem2) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem2);

                                reader.onload = () => {
                                  setFoto2(reader.result);
                                };
                              }
                              if (imagem3) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem3);

                                reader.onload = () => {
                                  setFoto3(reader.result);
                                };
                              }
                              if (imagem4) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem4);

                                reader.onload = () => {
                                  setFoto4(reader.result);
                                };
                              }
                              if (imagem5) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem5);

                                reader.onload = () => {
                                  setFoto5(reader.result);
                                };
                              }
                              if (imagem6) {
                                const reader = new FileReader();

                                reader.readAsDataURL(imagem6);

                                reader.onload = () => {
                                  setFoto6(reader.result);
                                };
                              }
                            }}
                          ></input>
                          {/* Imagem carregada */}
                          <div>
                            {/* Foto 1 */}
                            {foto1.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                  objectFit:"cover"
                                }}
                                width={150}
                                height={150}
                                src={foto1}
                              />
                            )}

                            {/* Foto 2 */}
                            {foto2.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,objectFit:"cover"
                                }}
                                width={150}
                                height={150}
                                src={foto2}
                              />
                            )}

                            {/* Foto 3 */}
                            {foto3.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,objectFit:"cover"
                                }}
                                width={150}
                                height={150}
                                src={foto3}
                              />
                            )}

                            {/* Foto 4 */}
                            {foto4.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,objectFit:"cover"
                                }}
                                width={150}
                                height={150}
                                src={foto4}
                              />
                            )}

                            {/* Foto 5 */}
                            {foto5.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,objectFit:"cover"
                                }}
                                width={150}
                                height={150}
                                src={foto5}
                              />
                            )}

                            {/* Foto 6 */}
                            {foto6.length > 0 && (
                              <img
                                style={{
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,objectFit:"cover"
                                }}
                                width={150}
                                height={150}
                                src={foto6}
                              />
                            )}
                          </div>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                  {privilegios.ativar_desativar_modalidades == 1 && (
                    <div className="formPage2">
                      <Box className="formTextField">
                        {/* Modalidades */}
                        <h5 className="h5List">{t("modalidades")}</h5>
                        <Grid
                          container
                          spacing={10}
                          justifyContent="center"
                          alignItems="center"
                          style={{ marginBottom: 10 }}
                        >
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <FormControl className="formTextField3" fullWidth>
                              {/* Natação adultos */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("natacao_adultos")}
                                labelPlacement="start"
                                checked={natacaoAdultos}
                                disabled={edicao}
                                onChange={() => {
                                  setNatacaoAdultos(!natacaoAdultos);
                                }}
                              />
                              {/* Natação bebés */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("natacao_bebes")}
                                labelPlacement="start"
                                checked={natacaoBebes}
                                disabled={edicao}
                                onChange={() => {
                                  setNatacaoBebes(!natacaoBebes);
                                }}
                              />
                              {/* Natação Idosos */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("natacao_idosos")}
                                labelPlacement="start"
                                checked={natacaoIdosos}
                                disabled={edicao}
                                onChange={() => {
                                  setNatacaoIdosos(!natacaoIdosos);
                                }}
                              />
                              {/* Hidroginástica */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("hidroginastica")}
                                labelPlacement="start"
                                checked={hidroginastica}
                                disabled={edicao}
                                onChange={() => {
                                  setHidroginastica(!hidroginastica);
                                }}
                              />
                               <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("escolaNatacao")}
                                labelPlacement="start"
                                checked={escolaNatacao}
                                disabled={edicao}
                                onChange={() => {
                                  setEscolaNatacao(!escolaNatacao);
                                }}
                              />
                              {/* Hidrobike */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("hidrobike")}
                                labelPlacement="start"
                                checked={hidrobike}
                                disabled={edicao}
                                onChange={() => {
                                  setHidrobike(!hidrobike);
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={5} xl={2}>
                            <FormControl className="formTextField3" fullWidth>
                              {/* Natação grávidas */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("natacao_gravidas")}
                                labelPlacement="start"
                                checked={natacaoGravidas}
                                disabled={edicao}
                                onChange={() => {
                                  setNatacaoGravidas(!natacaoGravidas);
                                }}
                              />
                              {/* Hidroterapia */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("hidroterapia")}
                                labelPlacement="start"
                                checked={hidroterapia}
                                disabled={edicao}
                                onChange={() => {
                                  setHidroterapia(!hidroterapia);
                                }}
                              />
                              {/* Nado livre */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("nado_livre")}
                                labelPlacement="start"
                                checked={nadoLivre}
                                disabled={edicao}
                                onChange={() => {
                                  setNadoLivre(!nadoLivre);
                                }}
                              />
                              {/* Equipas federadas */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                checked={equipasFederadas}
                                label={t("equipas_federadas")}
                                labelPlacement="start"
                                disabled={edicao}
                                onChange={() => {
                                  setEquipasFederadas(!equipasFederadas);
                                }}
                              />
                              {/* Hidropower */}
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label={t("hidropower")}
                                checked={hidropower}
                                labelPlacement="start"
                                disabled={edicao}
                                onChange={() => {
                                  setHidropower(!hidropower);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  )}

                  <div className="formPage2">
                    <Box className="formTextField">
                      {/* Termos e Condições */}
                      <h5 className="h5List">{t("termoesCondicoes")} *</h5>
                      <div style={{ width: "80%", marginBottom: 10 }}>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            width={500}
                            rows={20}
                            value={termos}
                            error={erroTermos}
                            onChange={(e) => {
                              setTermos(e.target.value);
                              setErroTermos(false);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                  {/* Horários */}
                  <div className="formPage2">
                    <Box className="formTextField">
                      <h5 className="h5List">{t("horarios")}</h5>
                      <div style={{ marginBottom: 10 }}>
                        {/* Horário */}
                        <FormControl className="formTextField3" fullWidth>
                          <p
                            id="horario"
                            style={{
                              marginTop: 10,
                              color: Colors.textFields,
                              fontSize: 15,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {t("horario")} (*pdf)
                          </p>
                          <label
                            id="horarioLabel"
                            htmlFor="file-upload5-h"
                            className="custom-file-upload"
                          >
                            <i className="fa fa-cloud-upload"></i>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ paddingTop: 4, paddingRight: 4 }}
                            />
                            {t("carregarHorarios")}
                          </label>
                          <input
                            disabled={edicao}
                            id="file-upload5-h"
                            style={{ paddingTop: 0 }}
                            type="file"
                            accept="application/pdf"
                            onChange={async (e) => {
                              document.getElementById(
                                "horarioLabel"
                              ).style.borderColor = Colors.ativo;
                              document.getElementById("horario").style.color =
                                Colors.textFields;
                              const file = e.target.files[0];

                              const reader = new FileReader();

                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                setHorario(reader.result);
                                const pdfContentType = "application/pdf";
                                const base64toBlob = (data) => {
                                  // Cut the prefix `data:application/pdf;base64` from the raw base 64
                                  const base64WithoutPrefix = data.substr(
                                    `data:${pdfContentType};base64,`.length
                                  );

                                  const bytes = atob(base64WithoutPrefix);
                                  let length = bytes.length;
                                  let out = new Uint8Array(length);

                                  while (length--) {
                                    out[length] = bytes.charCodeAt(length);
                                  }

                                  return new Blob([out], {
                                    type: pdfContentType,
                                  });
                                };
                                const url = URL.createObjectURL(
                                  base64toBlob(reader.result)
                                );
                                setHorario2(url);
                              };
                            }}
                          ></input>
                          {horario2.length > 0 && (
                            <div
                              style={{
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                                borderColor: Colors.textFields,
                                height: "600px",
                                marginTop: 10,
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                            >
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                                <Viewer fileUrl={horario2} />
                              </Worker>
                            </div>
                          )}
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                  {/* Links úteis */}
                  <div className="formPage2">
                    <Box className="formTextField">
                      <h5 className="h5List">{t("linksuteis")}</h5>
                      <div style={{ marginBottom: 10 }}>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            value={link1}
                            label={t("link") + " 1"}
                            variant="standard"
                            error={erroLink1}
                            helperText={mensagemErroLink1}
                            onChange={(e) => {
                              setLink1(e.target.value);
                              setErroLink1(false);
                              setMensagemErroLink1("");
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            value={link2}
                            label={t("link") + " 2"}
                            variant="standard"
                            error={erroLink2}
                            helperText={mensagemErroLink2}
                            onChange={(e) => {
                              setLink2(e.target.value);
                              setErroLink2(false);
                              setMensagemErroLink2("");
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            value={link3}
                            label={t("link") + " 3"}
                            variant="standard"
                            error={erroLink3}
                            helperText={mensagemErroLink3}
                            onChange={(e) => {
                              setLink3(e.target.value);
                              setErroLink3(false);
                              setMensagemErroLink3("");
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                </div>
                {/* Cancelar edição */}
                <Dialog
                  open={cancelarEdicao}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      width: 452,
                      height: 186,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("cancelarEdicao")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("textoCancelarEdicao")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        cancelar();
                      }}
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClosePopupCancelar();
                      }}
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                }}
              >
                <CircularProgress disableShrink />
              </div>
            )}
          </Scrollbar>
          {privilegios.editar_dados_piscina_edificio == 1 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {edicao ? (
                <Button
                  className="btnEdicao"
                  variant="contained"
                  style={{
                    backgroundImage: isMobile
                      ? `url(${editarbtnpeq})`
                      : `url(${editarbtn})`,
                    width: isMobile ? 153 : 315,
                    height: 40,
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={() => {
                    scrollToDiv();
                  }}
                >
                  <PencilIcon
                    style={{
                      width: "15px",
                      color: "white",
                      marginRight: "10px",
                    }}
                  />
                  {t("editar")}
                </Button>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,
                      width: isMobile ? 164 : 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditarPiscina;
