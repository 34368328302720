import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUpload } from "@fortawesome/free-solid-svg-icons";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import Cookies from "js-cookie";
import { Scrollbar } from "react-scrollbars-custom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import {
  editarAula,
  eliminarAula,
  getAula,
  listaAulas,
} from "../../api/requests/Aulas";
import Search1 from "../../components/DataTable/Search1";
import moment from "moment";
import Nav from "../../components/Nav";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { format } from "date-fns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box } from "@mui/system";
import {
  listaPiscinasAgua,
  listaProfessores,
  getPistas,
} from "../../api/requests/PiscinaAgua";
import { listaTurmas } from "../../api/requests/Turmas";
import { parseISO } from "date-fns";
import { adicionarAula } from "../../api/requests/Aulas";
import { getDisponibilidade } from "../../api/requests/Marcacoes";

function ListaAulas() {
  const [aulas, setAulas] = useState([]);
  const [aulasCopy, setAulasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "data_inicio", "data_fim"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "data_inicio", "data_fim"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [openCriarAula, setOpenCriarAula] = useState(false);
  const handleCloseCriarAula = () => setOpenCriarAula(false);

  const handleClickOpenCriarAula = () => {
    setOpenCriarAula(true);
  };

  const [openEditarAula, setOpenEditarAula] = useState(false);
  const handleCloseEditarAula = () => setOpenEditarAula(false);

  const handleClickOpenEditarAula = () => {
    setOpenEditarAula(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };
  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      let arrayAulas = aulasCopy;
      arrayAulas = arrayAulas.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome_turma.toLowerCase().includes(search.toLowerCase())
      );
      setAulas(arrayAulas);
    } else {
      setAulas(aulasCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaAulas({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              nome_turma: element.nome_turma,
              obs: element.obs,
              num_vagas: element.num_vagas,
              id: element.id_aula,
              data_inicio: moment(element.data_inicio).format("DD-MM-YYYY"),
              data_fim: moment(element.data_fim).format("DD-MM-YYYY"),
              id_professor: element.id_professor,
              status: element.status,
            };
            arrayFinal.push(linha);
            setAulas(arrayFinal);
            setAulasCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  // criar aula
  const [freeze, setFreeze] = useState(false);

  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [hora_inicio, setHoraInicio] = useState("");
  const [hora_fim, setHoraFim] = useState("");
  const [duracao, setDuracao] = useState("");

  const [pista, setPista] = useState(0);
  const [tamanho_pista, setTamanhoPista] = useState(0);
  const [segunda, setSegunda] = useState(0);
  const [terca, setTerca] = useState(0);
  const [quarta, setQuarta] = useState(0);
  const [quinta, setQuinta] = useState(0);
  const [sexta, setSexta] = useState(0);
  const [sabado, setSabado] = useState(0);
  const [domingo, setDomingo] = useState(0);
  const [status, setStatus] = useState(0);
  const [turmas, setTurmas] = useState([]);
  const [piscinas, setPiscinas] = useState([]);
  const [maxPista, setMaxPista] = useState(0);

  const [professores, setProfessores] = useState([]);
  const [disponiveis, setDisponiveis] = useState([]);
  const [horaEscolhida, setHoraEscolhida] = useState("");
  const [escolha, setEscolha] = useState([]);

  const [selectedPool, setSelectedPool] = useState("");
  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [selectedTurma, setSelectedTurma] = useState("");

  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroHoraInicio, setErroHoraInicio] = useState(false);
  const [erroHoraFim, setErroHoraFim] = useState(false);
  const [erroDuracao, setErroDuracao] = useState(false);

  const [erroPista, setErroPista] = useState(false);
  const [erroTamanhoPista, setErroTamanhoPista] = useState(false);
  const [erroPiscina, setErroPiscina] = useState(false);
  const [erroTurma, setErroTurma] = useState(false);
  const [erroProfessor, setErroProfessor] = useState(false);
  const [exibirCheckboxes, setExibirCheckboxes] = useState(true);
  const [pistasD, setPistasD] = useState([]);
  const [rows, setRows] = useState([{ pista: "", espaco_pista: "" }]);
  const [pistasDisponiveis, setPistasDisponiveis] = useState([]);
  const [exibirEscolhaPistas, setExibirEscolhaPistas] = useState(false);
  const [exibirMaisInfo, setExibirMaisInfo] = useState(false);

  const [checkboxes, setCheckboxes] = useState([]);

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
  };

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
    setErroProfessor(false);
  };

  const handleTurmaChange = (event) => {
    setSelectedTurma(event.target.value);
    setErroTurma(false);
  };

  const handlePoolChange = (event) => {
    setPista(0);
    setSelectedPool(event.target.value);
    getPistas({ id_piscina_agua: event.target.value })
      .then((res) => {
        if (res.success) {
          setMaxPista(res.data.data);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    setErroPiscina(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  const handleChange2 = (event) => {
    switch (event.target.name) {
      case "segunda":
        setSegunda(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTerca(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuarta(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuinta(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSexta(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabado(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingo(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data_inicio && data_fim) {
      if (data_inicio && data_fim && data_inicio === data_fim) {
        setExibirCheckboxes(false);
        setSegunda(false);
        setTerca(false);
        setQuarta(false);
        setQuinta(false);
        setSexta(false);
        setSabado(false);
        setDomingo(false);
      } else {
        setExibirCheckboxes(true);
      }
    }
  }, [data_inicio, data_fim]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEntidade);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaPiscinasAgua({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaTurmas({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_turma,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setTurmas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarAulas() {
    let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
    let data_fimf = moment(data_fim).format("YYYY-MM-DD");

    let hora_iniciof = moment(escolha.hora_inicio, "HH:mm").format("HH:mm:ss");
    let hora_fimf = moment(escolha.hora_fim, "HH:mm").format("HH:mm:ss");

    setFreeze(true);
    adicionarAula({
      id_turma: selectedTurma,
      id_piscina_agua: selectedPool,
      id_professor: selectedProfessor,
      pistas: selectedPistas,
      data_inicio: data_iniciof,
      data_fim: data_fimf,
      hora_inicio: hora_iniciof,
      hora_fim: hora_fimf,
      segunda: segunda,
      terca: terca,
      quarta: quarta,
      quinta: quinta,
      sexta: sexta,
      sabado: sabado,
      domingo: domingo,
      status: 1,
    }).then((res) => {
      if (res.success) {
        setFreeze(false);
        handleCloseCriarAula();
        setSelectedTurma("");
        setSelectedPistas([]);
        setSelectedPool("");
        setSelectedProfessor();
        setSegunda(0);
        setTerca(0);
        setQuarta(0);
        setQuinta(0);
        setSexta(0);
        setSabado(0);
        setDomingo(0);
        setDuracao("");
        setHoraEscolhida([]);

        toast.success(t("aulaAdicionar"));
        navigate("/lessonlista");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      segunda == 0 &&
      terca == 0 &&
      quarta == 0 &&
      quinta == 0 &&
      sexta == 0 &&
      sabado == 0 &&
      domingo == 0
    ) {
      toast.error(t("selecionadia"));
    } else {
      if (
        (selectedTurma &&
          selectedPool &&
          selectedProfessor &&
          selectedPistas &&
          data_inicio &&
          data_fim &&
          hora_inicio &&
          hora_fim &&
          segunda) ||
        terca ||
        quarta ||
        quinta ||
        sexta ||
        sabado ||
        domingo
      ) {
        let confirmarDadosPreenchidos = true;

        if (confirmarDadosPreenchidos) {
          //Scroll para o início da página
          window.scrollTo(0, 0);
          adicionarAulas();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        //Validação do nome
        if (!data_inicio) {
          setErroDataInicio(true);
        }
        if (!rows) {
          setErroPista(true);
        }

        if (!data_fim) {
          setErroDataFim(true);
        }
        if (!hora_inicio) {
          setErroHoraInicio(true);
        }
        if (!hora_fim) {
          setErroHoraFim(true);
        }
        if (!selectedTurma) {
          setErroTurma(true);
        }
        if (!selectedProfessor) {
          setErroProfessor(true);
        }
        if (!selectedPool) {
          setErroPiscina(true);
        }
        if (
          segunda == 0 &&
          terca == 0 &&
          quarta == 0 &&
          quinta == 0 &&
          sexta == 0 &&
          sabado == 0 &&
          domingo == 0
        ) {
          toast.error(t("selecionadia"));
        }
      }
    }
  }

  //editar aula

  const [idAula, setIdAula] = useState("");

  const [edicao, setEdicao] = useState(true);

  const [idPiscinae, setIdPiscinae] = useState("");
  const [idEEe, setIdEEe] = useState("");
  const [data_inicioe, setDataInicioe] = useState("");
  const [data_fime, setDataFime] = useState("");
  const [hora_inicioe, setHoraInicioe] = useState("");
  const [hora_fime, setHoraFime] = useState("");
  const [segundae, setSegundae] = useState(0);
  const [tercae, setTercae] = useState(0);
  const [quartae, setQuartae] = useState(0);
  const [quintae, setQuintae] = useState(0);
  const [sextae, setSextae] = useState(0);
  const [sabadoe, setSabadoe] = useState(0);
  const [domingoe, setDomingoe] = useState(0);
  const [statuse, setStatuse] = useState(0);
  const [turmase, setTurmase] = useState([]);
  const [piscinase, setPiscinase] = useState([]);

  const [professorese, setProfessorese] = useState([]);

  const [selectedPoole, setSelectedPoole] = useState("");
  const [selectedProfessore, setSelectedProfessore] = useState("");
  const [selectedTurmae, setSelectedTurmae] = useState("");
  const [maxPistae, setMaxPistae] = useState(0);
  const [erroDataInicioe, setErroDataInicioe] = useState(false);
  const [erroDataFime, setErroDataFime] = useState(false);
  const [erroHoraInicioe, setErroHoraInicioe] = useState(false);
  const [erroHoraFime, setErroHoraFime] = useState(false);
  const [erroPistae, setErroPistae] = useState(false);
  const [erroTamanhoPistae, setErroTamanhoPistae] = useState(false);
  const [erroPiscinae, setErroPiscinae] = useState(false);
  const [erroTurmae, setErroTurmae] = useState(false);
  const [erroProfessore, setErroProfessore] = useState(false);
  const [exibirCheckboxese, setExibirCheckboxese] = useState(true);
  const [pistasDe, setPistasDe] = useState([]);
  const [rowse, setRowse] = useState([{}]);
  const [pistasDisponiveise, setPistasDisponiveise] = useState([]);
  const [pistasOriginais, setPistasOriginais] = useState([]);

  const [selectedPistas, setSelectedPistas] = useState([]);

  const handlePistaChange = (event, pista) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected pista to the array
      setSelectedPistas((prevSelected) => [...prevSelected, pista]);
    } else {
      // Remove the unselected pista from the array
      setSelectedPistas((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== pista.id)
      );
    }
  };

  useEffect(() => {
    let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
    let data_fimf = moment(data_fim).format("YYYY-MM-DD");
    if (
      data_inicio &&
      data_fim &&
      selectedPool &&
      selectedProfessor &&
      selectedTurma &&
      duracao &&
      (segunda || terca || quarta || quinta || sexta || sabado || domingo)
    ) {
      getDisponibilidade({
        id_piscina: localStorage.getItem("idPiscina"),
        data_inicio: data_iniciof,
        data_fim: data_fimf,
        id_piscina_agua: selectedPool,
        segunda: segunda,
        terca: terca,
        quarta: quarta,
        quinta: quinta,
        sexta: sexta,
        sabado: sabado,
        domingo: domingo,
        tipo: "aulas",
        duracao,
      }).then((res) => {
        if (res.success) {
          let arrayFinal = [];
          let dados = res.data.data;

          dados.forEach((element) => {
            const horaInicio = element.horaInicio
              .split(":")
              .slice(0, 2)
              .join(":");
            const horaFim = element.horaFim.split(":").slice(0, 2).join(":");

            const linha = {
              hora_inicio: horaInicio,
              hora_fim: horaFim,
              pistas: element.pistas,
            };
            arrayFinal.push(linha);
            setDisponiveis(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      });
    }
  }, [
    data_inicio,
    data_fim,
    selectedPool,
    segunda,
    terca,
    quarta,
    quinta,
    sexta,
    sabado,
    domingo,
    selectedProfessor,
    selectedTurma,
    duracao,
  ]);

  const handleProfessorChangee = (event) => {
    setSelectedProfessore(event.target.value);
    setErroProfessore(false);
  };

  const handleTurmaChangee = (event) => {
    setSelectedTurmae(event.target.value);
    setErroTurmae(false);
  };

  const handlePoolChangee = (event) => {
    setSelectedPoole(event.target.value);
    setErroPiscinae(false);
  };

  const handleChangee = (event) => {
    const novoStatuse = event.target.checked ? 1 : 0;
    setStatuse(novoStatuse);
  };

  const handleChange2e = (event) => {
    switch (event.target.name) {
      case "segunda":
        setSegundae(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTercae(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuartae(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuintae(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSextae(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabadoe(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingoe(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };
  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  useEffect(() => {
    const datafe = moment(data_fime).format("YYYY-MM-DD");
    const dataie = moment(data_inicioe).format("YYYY-MM-DD");
    // Verifica se as datas são iguais e atualiza o estado de exibição das checkboxes
    if (data_inicioe && data_fime && dataie === datafe) {
      setExibirCheckboxese(false);
      setSegundae(false);
      setTercae(false);
      setQuartae(false);
      setQuintae(false);
      setSextae(false);
      setSabadoe(false);
      setDomingoe(false);
    } else {
      setExibirCheckboxese(true);
    }
  }, [data_inicioe, data_fime]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEntidade);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaPiscinasAgua({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinase(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaTurmas({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_turma,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setTurmase(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessorese(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    if (idAula != "") {
      getAula({ id: idAula })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];
            setDadosOriginais(dados);
            setSelectedTurmae(dados.id_turma);
            setDataInicioe(dados.data_inicio);
            setDataFime(dados.data_fim);
            setSelectedPoole(dados.id_piscina_agua);
            setSelectedProfessore(dados.id_professor);
            setRowse(dados.pistas);
            setHoraInicioe(parseISO(`1970-01-01T${dados.hora_inicio}`));
            setHoraFime(parseISO(`1970-01-01T${dados.hora_fim}`));
            setSegundae(dados.segunda);
            setPistasOriginais(JSON.parse(JSON.stringify(dados.pistas)));
            setSegundae(dados.segunda);
            setTercae(dados.terca);
            setQuartae(dados.quarta);
            setQuintae(dados.quinta);
            setSextae(dados.sexta);
            setSabadoe(dados.sabado);
            setDomingoe(dados.domingo);
            setStatuse(dados.status);

            const pistasArray = dados.pistas.split(", ");
            setCheckboxes(
              pistasArray.map((pista) => ({ value: pista, checked: true }))
            );

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idAula]);

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);
    setSelectedTurmae(dadosOriginais.id_turma);
    setDataInicioe(dadosOriginais.data_inicio);
    setDataFime(dadosOriginais.data_fim);
    setSelectedPoole(dadosOriginais.id_piscina_agua);
    setSelectedProfessore(dadosOriginais.id_professor);
    setRowse(
      pistasOriginais.map((pista) => ({
        id: pista.id,
        name: pista.name,
        ocupacao: pista.ocupacao,
      }))
    );
    setHoraInicioe(parseISO(`1970-01-01T${dadosOriginais.hora_inicio}`));
    setHoraFime(parseISO(`1970-01-01T${dadosOriginais.hora_fim}`));
    setSegundae(dadosOriginais.segunda);
    setTercae(dadosOriginais.terca);
    setQuartae(dadosOriginais.quarta);
    setQuintae(dadosOriginais.quinta);
    setSextae(dadosOriginais.sexta);
    setSabadoe(dadosOriginais.sabado);
    setDomingoe(dadosOriginais.domingo);
    setStatuse(dadosOriginais.status);

    window.scrollTo(0, 0);
  }
  function editarAulas() {
    let data_iniciofe = moment(data_inicioe).format("YYYY-MM-DD");
    let data_fimfe = moment(data_fime).format("YYYY-MM-DD");
    let hora_iniciofe = moment(hora_inicioe).format("HH:mm");
    let hora_fimfe = moment(hora_fime).format("HH:mm");
    setFreeze(true);
    editarAula({
      id_aula: idAula,
      id_turma: selectedTurmae,
      id_piscina_agua: selectedPoole,
      id_professor: selectedProfessore,
      pistas: rowse,
      data_inicio: data_iniciofe,
      data_fim: data_fimfe,
      hora_inicio: hora_iniciofe,
      hora_fim: hora_fimfe,
      segunda: segundae,
      terca: tercae,
      quarta: quartae,
      quinta: quintae,
      sexta: sextae,
      sabado: sabadoe,
      domingo: domingoe,
      status: statuse,
    })
      .then((res) => {
        if (res.success) {
          let linha = {
            id_aula: idAula,
            id_turma: selectedTurmae,
            id_piscina_agua: selectedPoole,
            id_professor: selectedProfessore,
            pistas: rowse,
            data_inicio: data_iniciofe,
            data_fim: data_fimfe,
            hora_inicio: hora_iniciofe,
            hora_fim: hora_fimfe,
            segunda: segundae,
            terca: tercae,
            quarta: quartae,
            quinta: quintae,
            sexta: sextae,
            sabado: sabadoe,
            domingo: domingoe,
            status: statuse,
          };

          setDadosOriginais(linha);
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      segundae == 0 &&
      tercae == 0 &&
      quartae == 0 &&
      quintae == 0 &&
      sextae == 0 &&
      sabadoe == 0 &&
      domingoe == 0
    ) {
      toast.error(t("selecionadia"));
    } else {
      if (
        (selectedTurmae &&
          selectedPoole &&
          selectedProfessore &&
          rowse &&
          data_inicioe &&
          data_fime &&
          hora_inicioe &&
          hora_fime &&
          segundae) ||
        tercae ||
        quartae ||
        quintae ||
        sextae ||
        sabadoe ||
        domingoe
      ) {
        let confirmarDadosPreenchidos = true;

        if (confirmarDadosPreenchidos) {
          //Scroll para o início da página
          window.scrollTo(0, 0);
          editarAulas();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        //Validação do nome
        if (!data_inicioe) {
          setErroDataInicioe(true);
        }
        if (!rowse) {
          setErroPistae(true);
        }

        if (!data_fime) {
          setErroDataFime(true);
        }
        if (!hora_inicioe) {
          setErroHoraInicioe(true);
        }
        if (!hora_fime) {
          setErroHoraFime(true);
        }
        if (!selectedTurmae) {
          setErroTurmae(true);
        }
        if (!selectedProfessore) {
          setErroProfessore(true);
        }
        if (!selectedPoole) {
          setErroPiscinae(true);
        }
        if (
          segundae == 0 &&
          tercae == 0 &&
          quartae == 0 &&
          quintae == 0 &&
          sextae == 0 &&
          sabadoe == 0 &&
          domingoe == 0
        ) {
          toast.error(t("selecionadia"));
        }
      }
    }
  }

  function eliminarAulas() {
    setFreeze(true);
    eliminarAula({
      id_aula: idAula,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("eliminarAula"));
          handleClosePopupCancelar();
          handleClosePopupEliminar();
          handleCloseEditarAula();
          navigate("/lessonlista");
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaAulas")}</h3>
        {/* <div className="topleftcliente">
          <IconButton
            onClick={() => {
              navigate("/ocupaespacoa");
            }}
            size={window.innerWidth < 600 ? "small" : "medium"}
          >
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div> */}
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleClickOpenCriarAula();
              }}
            ></span>
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 70,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              {
                field: "nome_turma",
                headerName: t("nometurma"),
                type: "string",
                flex: 1,
              },
              {
                field: "num_vagas",
                headerName: t("numvagas"),
                flex: 1,
                type: "number",
              },
              {
                field: "obs",
                headerName: t("observacoes"),
                flex: 1,
                type: "string",
              },
              {
                field: "data_inicio",
                headerName: t("datainicio"),
                flex: 1,
                type: "string",
                hide: columnsHidden.includes("data_inicio"),
              },
              {
                field: "data_fim",
                headerName: t("datafim"),
                flex: 1,
                type: "string",
                hide: columnsHidden.includes("data_fim"),
              },
              {
                field: "status",
                headerName: t("estado"),
                maxWidth: 100,
                flex: 1,
                type: "string",

                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == 1 ? (
                        <>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: 500,
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                          >
                            {t("ativo")}
                          </p>
                          <FontAwesomeIcon
                            color={Colors.ativo}
                            fontSize={25}
                            icon={faCircleCheck}
                          />
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: 500,
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                          >
                            {t("inativo")}
                          </p>{" "}
                          <FontAwesomeIcon
                            color={Colors.inativo}
                            fontSize={25}
                            icon={faCircleXmark}
                          />
                        </>
                      )}
                    </div>
                  );
                },
              },

              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{
                            fontSize: isMobile ? 12 : 18,
                          }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdAula(id);
                        handleClickOpenEditarAula();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={aulas}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>

          {/* adicionar aula */}
          <Dialog
            open={openCriarAula}
            onClose={handleCloseCriarAula}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarAula")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarAula}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div className="formPop" style={{ marginTop: 30 }}>
                <Scrollbar style={{ height: 400 }}>
                  <Box className="formTextField">
                    <div>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel className="formTextField22">
                          {t("escolhaturma")}*
                        </InputLabel>
                        <Select
                          value={selectedTurma}
                          onChange={handleTurmaChange}
                          fullWidth
                          variant="standard"
                        >
                          {turmas.map((turma) => (
                            <MenuItem key={turma.id} value={turma.id}>
                              {turma.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel className="formTextField22">
                          {t("escolhapiscina")}*
                        </InputLabel>
                        <Select
                          value={selectedPool}
                          onChange={handlePoolChange}
                          fullWidth
                          variant="standard"
                        >
                          {piscinas.map((piscina) => (
                            <MenuItem key={piscina.id} value={piscina.id}>
                              {piscina.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel className="formTextField22">
                          {t("escolhaprofessor")}*
                        </InputLabel>
                        <Select
                          value={selectedProfessor}
                          onChange={handleProfessorChange}
                          fullWidth
                          variant="standard"
                        >
                          {professores.map((professor) => (
                            <MenuItem key={professor.id} value={professor.id}>
                              {professor.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            label={t("datainicio")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_inicio}
                            onChange={(e) => {
                              setDataInicio(e);
                              setErroDataInicio(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataInicio}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            label={t("datafim")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_fim}
                            onChange={(e) => {
                              setDataFim(e);
                              setErroDataFim(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataFim}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {/* <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label={t("horainicio")}
                            value={hora_inicio}
                            onChange={(date) => setHoraInicio(date.$d)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                error={erroHoraInicio}
                                required
                              />
                            )}
                            ampm={false}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label={t("horafim")}
                            value={hora_fim}
                            onChange={(date) => setHoraFim(date.$d)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                error={erroHoraFim}
                                required
                              />
                            )}
                            ampm={false}
                          />
                        </LocalizationProvider>
                      </FormControl> */}
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          label={t("duracao") + " (min)"}
                          type="number"
                          value={duracao}
                          onChange={(e) => setDuracao(e.target.value)}
                          variant="standard"
                          error={erroDuracao}
                          required
                        />
                      </FormControl>
                      {exibirCheckboxes && (
                        <FormControl className="formTextField2" fullWidth>
                          <Typography variant="subtitle" align="left">
                            {t("escolhadia")}
                          </Typography>

                          <div style={{ maxWidth: 200 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={segunda == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="segunda"
                                  color="primary"
                                />
                              }
                              label={t("segunda")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={terca == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="terca"
                                  color="primary"
                                />
                              }
                              label={t("terca")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={quarta == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="quarta"
                                  color="primary"
                                />
                              }
                              label={t("quarta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={quinta == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="quinta"
                                  color="primary"
                                />
                              }
                              label={t("quinta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sexta == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="sexta"
                                  color="primary"
                                />
                              }
                              label={t("sexta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sabado == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="sabado"
                                  color="primary"
                                />
                              }
                              label={t("sabado")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={domingo == 1 ? true : false}
                                  onChange={handleChange2}
                                  name="domingo"
                                  color="primary"
                                />
                              }
                              label={t("domingo")}
                            />
                          </div>
                        </FormControl>
                      )}

                      <Grid
                        container
                        spacing={1}
                        columns={{
                          xs: isMobile ? 12 : innerWidth * 0.006,
                          sm: isMobile ? 12 : innerWidth * 0.006,
                          md: isMobile ? 12 : innerWidth * 0.006,
                          lg: isMobile ? 12 : innerWidth * 0.006,
                        }}
                        sx={{
                          marginTop: "30px !important",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginBottom: "10px",
                        }}
                      >
                        {disponiveis.map((element, index) => (
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            key={index}
                            style={{ marginLeft: 7, marginRight: 7 }}
                          >
                            <Button
                              className="btnHorasD"
                              variant="contained"
                              style={{
                                backgroundColor:
                                  horaEscolhida === index
                                    ? "#0956fa"
                                    : "#C2D5FE",
                                color:
                                  horaEscolhida === index
                                    ? "#C2D5FE"
                                    : "#0956fa",
                                transition: "box-shadow 0.3s, transform 0.3s",
                                "&:hover": {
                                  boxShadow:
                                    horaEscolhida === index
                                      ? "10px 10px 10px 10px lightgrey"
                                      : "5px 5px 5px 5px lightgrey",
                                },
                                fontSize: 11,
                              }}
                              onClick={() => {
                                if (horaEscolhida === index) {
                                  setHoraEscolhida(index);
                                  setEscolha(element);
                                } else {
                                  setHoraEscolhida(index);
                                  setEscolha(element);
                                }
                              }}
                            >
                              {element.hora_inicio}h → {element.hora_fim}h
                            </Button>
                          </Grid>
                        ))}
                      </Grid>

                      {horaEscolhida != "" && (
                        <div style={{ marginLeft: 10, marginTop: 10 }}>
                          <FormControl
                            className="formTextField2"
                            style={{
                              width: "60%",
                              marginRight: "10px",
                              marginTop: 10,
                            }}
                          >
                            <FormGroup>
                              {escolha.pistas.map((pista) => {
                                const isChecked = selectedPistas.some(
                                  (selected) => selected.id === pista.id
                                );

                                const isMeiaPista = pista.id.includes("M");

                                return (
                                  <div>
                                    {pista.ocupacao === 1 && (
                                      <div>
                                        <FormControlLabel
                                          key={pista.id}
                                          control={
                                            <Checkbox
                                              checked={isChecked}
                                              onChange={(event) =>
                                                handlePistaChange(event, pista)
                                              }
                                              name={pista.nome}
                                              disabled={selectedPistas.some(
                                                (selected) =>
                                                  selected.id === pista.id + "M"
                                              )}
                                            />
                                          }
                                          label={<div>{pista.nome}</div>}
                                        />
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={selectedPistas.some(
                                                (selected) =>
                                                  selected.id === `${pista.id}M`
                                              )}
                                              onChange={(event) =>
                                                handlePistaChange(event, {
                                                  id: `${pista.id}M`,
                                                  nome: `${pista.id} Meia Pista`,
                                                })
                                              }
                                              name={pista.nome}
                                              disabled={selectedPistas.some(
                                                (selected) =>
                                                  selected.id === pista.id
                                              )}
                                            />
                                          }
                                          label={
                                            t("Pista") +
                                            " " +
                                            pista.id +
                                            t(" (Meia Pista)")
                                          }
                                        />
                                      </div>
                                    )}
                                    {pista.ocupacao == 2 && (
                                      <FormControlLabel
                                        key={pista.id}
                                        control={
                                          <Checkbox
                                            checked={isChecked}
                                            onChange={(event) =>
                                              handlePistaChange(event, pista)
                                            }
                                            name={pista.nome}
                                          />
                                        }
                                        label={`Pista ${pista.id.replace(
                                          "M",
                                          " (Meia Pista)"
                                        )}`}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        </div>
                      )}
                    </div>
                  </Box>
                </Scrollbar>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* editar aula */}

          <Dialog
            open={openEditarAula}
            onClose={handleCloseEditarAula}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "95%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleCloseEditarAula}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Scrollbar style={{ height: 400 }}>
                    <Box className="formTextField">
                      <div>
                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroTurma}
                          >
                            {t("escolhaturma")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedTurmae}
                            onChange={handleTurmaChangee}
                            error={erroTurmae}
                            fullWidth
                            variant="standard"
                          >
                            {turmase.map((turma) => (
                              <MenuItem key={turma.id} value={turma.id}>
                                {turma.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroPiscinae}
                          >
                            {t("escolhapiscina")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedPoole}
                            onChange={handlePoolChangee}
                            error={erroPiscinae}
                            fullWidth
                            variant="standard"
                          >
                            {piscinase.map((piscina) => (
                              <MenuItem key={piscina.id} value={piscina.id}>
                                {piscina.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroProfessore}
                          >
                            {t("escolhaprofessor")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedProfessore}
                            onChange={handleProfessorChangee}
                            error={erroProfessore}
                            fullWidth
                            variant="standard"
                          >
                            {professorese.map((professor) => (
                              <MenuItem key={professor.id} value={professor.id}>
                                {professor.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disabled={edicao}
                              label={t("datainicio")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={data_inicioe}
                              onChange={(e) => {
                                setDataInicioe(e);
                                setErroDataInicioe(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroDataInicioe}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disabled={edicao}
                              label={t("datafim")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={data_fime}
                              onChange={(e) => {
                                setDataFime(e);
                                setErroDataFime(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroDataFime}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              disabled={edicao}
                              label={t("horainicio")}
                              value={hora_inicioe}
                              onChange={(date) => setHoraInicioe(date.$d)}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraInicioe}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              disabled={edicao}
                              label={t("horafim")}
                              value={hora_fime}
                              onChange={(date) => setHoraFime(date)}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroHoraFime}
                                  required
                                />
                              )}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <Typography variant="subtitle" align="left">
                            Status
                          </Typography>

                          <FormControlLabel
                            control={
                              <Switch
                                disabled={edicao}
                                className="switch"
                                checked={statuse}
                                onChange={handleChangee}
                                name="checked"
                                color="primary"
                              />
                            }
                            label={status ? t("ativo") : t("desativo")}
                          />
                        </FormControl>
                        {exibirCheckboxese && (
                          <FormControl className="formTextField2" fullWidth>
                            <Typography variant="subtitle" align="left">
                              {t("escolhadia")}
                            </Typography>

                            <div style={{ maxWidth: 200 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={segundae}
                                    onChange={handleChange2e}
                                    name="segunda"
                                    color="primary"
                                  />
                                }
                                label={t("segunda")}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={tercae}
                                    onChange={handleChange2e}
                                    name="terca"
                                    color="primary"
                                  />
                                }
                                label={t("terca")}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={quartae}
                                    onChange={handleChange2e}
                                    name="quarta"
                                    color="primary"
                                  />
                                }
                                label={t("quarta")}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={quintae}
                                    onChange={handleChange2e}
                                    name="quinta"
                                    color="primary"
                                  />
                                }
                                label={t("quinta")}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={sextae}
                                    onChange={handleChange2e}
                                    name="sexta"
                                    color="primary"
                                  />
                                }
                                label={t("sexta")}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={sabadoe}
                                    onChange={handleChange2e}
                                    name="sabado"
                                    color="primary"
                                  />
                                }
                                label={t("sabado")}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={edicao}
                                    checked={domingoe}
                                    onChange={handleChange2e}
                                    name="domingo"
                                    color="primary"
                                  />
                                }
                                label={t("domingo")}
                              />
                            </div>
                          </FormControl>
                        )}
                        <Typography variant="subtitle" align="left">
                          {t("Pistas")}
                        </Typography>
                        {checkboxes.map((checkbox, index) => (
                          <div key={index}>
                            <Checkbox
                              disabled={edicao}
                              value={checkbox.value}
                              checked={checkbox.checked}
                              onChange={() => handleCheckboxChange(index)}
                            />
                            <label htmlFor={`pista-${index}`}>{`${t(
                              "Pista"
                            )} ${checkbox.value.replace(
                              "M",
                              t(" (Meia Pista)")
                            )}`}</label>
                          </div>
                        ))}
                      </div>
                    </Box>
                  </Scrollbar>

                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Eliminar */}
                  <Dialog
                    open={eliminar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("eliminarAulaT")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("eliminarRegisto")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          eliminarAulas();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                        }}
                        autoFocus
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("editar")}
                  </Button> */}

                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${eliminarbtnpeq})`
                        : `url(${eliminarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupEliminar();
                    }}
                  >
                    <TrashIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("eliminar")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,
                      width: isMobile ? 164 : 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaAulas;
