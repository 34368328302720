import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FooterExterno from "../../components/FooterExterno";
import HeaderExterno from "../../components/HeaderExterno";

import gif500 from "../../images/errorPages/500.gif";

function ErrorPage_500() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  // Definir background para esta página
  document.body.style = `background: white`;

  return (
    <>
      <HeaderExterno />
      <div className="containerErrorPage">
        <div className="divErrorPage">
          {/* <div className="divErrorLeft">
            <img className="imageErrorPage" src={gif500} />
          </div> */}
          <div className="divErrorRight">
            <h1>500</h1>
            <h4>{t("500")}</h4>
            <div className="buttonsErroPage">
              <button
                onClick={() => {
                  let utilizador = Cookies.get("utilizador");
                  const id = Cookies.get("uId");
                  const token = Cookies.get("token");
                  if (
                    utilizador != "" &&
                    utilizador != null &&
                    id != "" &&
                    id != null &&
                    token != "" &&
                    token != undefined
                  ) {
                    if (utilizador == "superadmin") {
                      navigate("/dashboard_superadmin");
                    } else if (utilizador == "supercomercial") {
                      navigate("/dashboard_supercommercial");
                    } else if (utilizador == "comercial") {
                      navigate("/dashboard_commercial");
                    } else if (utilizador == "admin") {
                      navigate("/dashboard_admin");
                    } else if (utilizador == "funcionario") {
                      navigate("/dashboard_employee");
                    } else if (utilizador == "professor") {
                      navigate("/dashboard_teacher");
                    } else if (utilizador == "cliente") {
                      navigate("/dashboard_customer");
                    }
                  } else {
                    navigate("/");
                  }
                }}
              >
                {t("paginaInicial")}
              </button>
              {/* <button>Learn More</button> */}
            </div>
          </div>
        </div>
      </div>
      <FooterExterno />
    </>
  );
}

export default ErrorPage_500;
