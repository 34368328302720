import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { loginPost } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import HeaderExterno from "../components/HeaderExterno";
import FooterExterno from "../components/FooterExterno";
import { definePass, definePassword } from "../api/requests/Registo";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router";

function DefinePassword() {
  //Variáveis

  const [palavraPasse, setPalavraPasse] = useState("");
  const [palavraPasse2, setPalavraPasse2] = useState("");

  //Validações
  const [erroPassword2, setErroPassword2] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroPalavraPasse2, setMensagemErroPalavraPasse2] =
    useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoPalavraPasse, setSucessoPalavraPasse] = useState("");
  const [sucessoPalavraPasse2, setSucessoPalavraPasse2] = useState("");

  const [freeze, setFreeze] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { id, token } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (palavraPasse) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = palavraPasse.length;
      const uppercasePassword = uppercaseRegExp.test(palavraPasse);
      const lowercasePassword = lowercaseRegExp.test(palavraPasse);
      const digitsPassword = digitsRegExp.test(palavraPasse);
      const specialCharPassword = specialCharRegExp.test(palavraPasse);
      const minLengthPassword = minLengthRegExp.test(palavraPasse);

      if (passwordLength === 0) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("vazia"));
      } else if (!uppercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("uppercase"));
      } else if (!lowercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("lowercase"));
      } else if (!digitsPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracternumb"));
      } else if (!specialCharPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracterespecial"));
      } else if (!minLengthPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("8caracteres"));
      } else {
        setErroPassword(false);
        setMensagemErroPalavraPasse("");
        setSucessoPalavraPasse("success");
      }
    }
    if (palavraPasse2) {
      if (palavraPasse2 != palavraPasse) {
        setErroPassword(true);
        setErroPassword2(true);
        setMensagemErroPalavraPasse(t("passesigual"));
        setMensagemErroPalavraPasse2(t("passesigual"));
      } else {
        setErroPassword(false);
        setErroPassword2(false);
        setMensagemErroPalavraPasse("");
        setMensagemErroPalavraPasse2("");
      }
    }
  }, [palavraPasse2, palavraPasse]);

  function DefinePass() {
    setFreeze(true);
    if (palavraPasse) {
      if (palavraPasse === palavraPasse2) {
        const hashedPassword = bcrypt.hashSync(
          palavraPasse,
          "$2a$10$CwTycUXWue0Thq9StjUM0u"
        );
        definePassword(
          {
            newPwd: hashedPassword,
          },
          id,
          token
        ).then((res) => {
          if (res.success) {
            toast.success(t("passDefinida"));
            navigate("/login");
          } else {
            setFreeze(false);
            toast.error(t("defineErro"));
          }
        });
      } else {
        setErroPassword(true);
        setErroPassword2(true);
        toast.error(t("iguaisPass"));
      }
    } else {
      setErroPassword(true);
      setErroPassword2(true);
      setMensagemErroPalavraPasse(t("vaziaPass"));
      setMensagemErroPalavraPasse2(t("vaziaPass"));
      setFreeze(false);
    }
  }
  return (
    <div className="bodyLogin">
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <div className="session2">
        <form className="inicioCol" style={{ paddingBottom: "5%" }}>
          <h5 className="titulo">
            <span style={{ fontSize: 18 }}>{t("definirPalavraPasse")}</span>
          </h5>

          {/* Palavra-passe */}
          <TextField
            style={{
              width: "30vh",
              marginTop: "20px",
              marginBottom: "10px",
            }}
            id="password"
            className="formLogin"
            label={t("palavraPasse")}
            variant="standard"
            error={erroPassword}
            type={showPassword ? "text" : "password"}
            helperText={mensagemErroPalavraPasse}
            color={sucessoPalavraPasse}
            onChange={(e) => {
              setPalavraPasse(e.target.value);
              setErroPassword(false);
              setMensagemErroPalavraPasse("");
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    disableFocusRipple
                    style={{
                      justifyContent: "right",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      color: "#8A8A8A",
                    }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          ></TextField>

          {/* Confirma Palavra-passe */}
          <TextField
            style={{
              width: "30vh",
              marginTop: "20px",
              marginBottom: "10px",
            }}
            className="formLogin"
            id="confirmarpassword"
            label={t("confirmaPalavraPasse")}
            variant="standard"
            error={erroPassword2}
            type={showPassword2 ? "text" : "password"}
            helperText={mensagemErroPalavraPasse2}
            color={sucessoPalavraPasse2}
            onChange={(e) => {
              setPalavraPasse2(e.target.value);
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    disableFocusRipple
                    style={{
                      justifyContent: "right",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      color: "#8A8A8A",
                    }}
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          ></TextField>
          <Link className="textlinklogin" to={"/login"}>
            {t("iniciarSessao")}
          </Link>

          <button
            type="button"
            className="iniciobtn"
            style={{ marginTop: "40px" }}
            onClick={() => {
              DefinePass();
            }}
          >
            {t("confirmar")}
          </button>
        </form>
      </div>
    </div>
  );
}
export default DefinePassword;
