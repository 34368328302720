import { makeRequestDelete, makeRequestGet, makeRequestPost } from "../api";

//Login
export function login(dados) {
  return makeRequestPost("login", dados);
}

//Logout
export function logout(dados) {
  return makeRequestDelete("logout", dados);
}
