import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faStop,
  faPen,
  faClose,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  adicionarPiscinaA,
  editarPiscinaA,
  eliminarPiscinaA,
  getPiscinaAgua,
} from "../../api/requests/PiscinaAgua";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";

import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  Button,
  IconButton,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  DialogTitle,
  Box,
  DialogActions,
  FormControlLabel,
  FormLabel,
  DialogContentText,
} from "@mui/material";

import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import { listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import Search1 from "../../components/DataTable/Search1";
import Nav from "../../components/Nav";
import { Scrollbar } from "react-scrollbars-custom";

function ListaPiscinasAguaA() {
  const [piscinas, setPiscinas] = useState([]);
  const [piscinasCopy, setPiscinasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [openCriarPiscina, setOpenCriarPiscina] = useState(false);
  const handleCloseCriarPiscina = () => setOpenCriarPiscina(false);

  const handleClickOpenCriarPiscina = () => {
    setOpenCriarPiscina(true);
  };

  const [openEditarPiscina, setOpenEditarPiscina] = useState(false);
  const handleCloseEditarPiscina = () => setOpenEditarPiscina(false);

  const handleClickOpenEditarPiscina = () => {
    setOpenEditarPiscina(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  //Adicionar Piscina Agua

  const [freeze, setFreeze] = useState(false);

  const [nomePiscinaAgua, setNomePiscinaAgua] = useState("");
  const [numeroPistas, setNumeroPistas] = useState(1);
  const [tipoAguaPiscinaAgua, setTipoAguaPiscinaAgua] = useState("");
  const [localizacaoPiscinaAgua, setLocalizacaoPiscinaAgua] = useState("");
  const [exteriorinterior, setExteriorInterior] = useState(0);
  const [profundidadeMaxPiscinaAgua, setProfundidadeMaxPiscinaAgua] =
    useState("");
  const [profundidadeMinPiscinaAgua, setProfundidadeMinPiscinaAgua] =
    useState("");
  const [comprimentoPiscinaAgua, setComprimentoPiscinaAgua] = useState("");
  const [larguraPiscinaAgua, setLarguraPiscinaAgua] = useState("");
  const [capacidade, setCapacidade] = useState("");
  const [lotacaoPorPista, setLotacao] = useState("");
  const [obs, setObs] = useState("");

  const [erroNomePiscinaAgua, setErroNomePiscinaAgua] = useState(false);
  const [erroNumeroPistas, setErroNumeroPistas] = useState(false);
  const [erroTipoAguaPiscinaAgua, setErroTipoAguaPiscinaAgua] = useState(false);
  const [erroProfundidadeMaxPiscinaAgua, setErroProfundidadeMaxPiscinaAgua] =
    useState(false);
  const [erroProfundidadeMinPiscinaAgua, setErroProfundidadeMinPiscinaAgua] =
    useState(false);
  const [erroComprimentoPiscinaAgua, setErroComprimentoPiscinaAgua] =
    useState(false);
  const [erroLarguraPiscinaAgua, setErroLarguraPiscinaAgua] = useState(false);
  const [erroCapacidade, setErroCapacidade] = useState(false);
  const [erroObs, setErroObs] = useState(false);
  const [erroLotacao, setErroLotacao] = useState(false);

  const [mensagemErroTipoAguaPiscinaAgua, setMensagemErroTipoAguaPiscinaAgua] =
    useState("");
  const [
    mensagemErroProfundidadeMinPiscinaAgua,
    setMensagemErroProfundidadeMinPiscinaAgua,
  ] = useState("");

  const [
    mensagemErroProfundidadeMaxPiscinaAgua,
    setMensagemErroProfundidadeMaxPiscinaAgua,
  ] = useState("");
  const [
    mensagemErroComprimentoPiscinaAgua,
    setMensagemErroComprimentoPiscinaAgua,
  ] = useState("");
  const [mensagemErroLarguraPiscinaAgua, setMensagemErroLarguraPiscinaAgua] =
    useState("");
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);
  function limparVariaveis() {
    setCapacidade("");
    setProfundidadeMaxPiscinaAgua("");
    setProfundidadeMinPiscinaAgua("");
    setComprimentoPiscinaAgua("");
    setLarguraPiscinaAgua("");
    setExteriorInterior("");
    setTipoAguaPiscinaAgua("");
    setNomePiscinaAgua("");
    setNumeroPistas("");
    setLotacao("");
    setObs("");
  }
  function AddPiscina() {
    setFreeze(true);
    adicionarPiscinaA({
      nome: nomePiscinaAgua,
      numero_pistas: numeroPistas,
      tipo_agua: tipoAguaPiscinaAgua,
      exterior_interior: exteriorinterior,
      profundidade_max: profundidadeMaxPiscinaAgua,
      profundidade_min: profundidadeMinPiscinaAgua,
      capacidade: capacidade,
      comprimento: comprimentoPiscinaAgua,
      largura: larguraPiscinaAgua,
      lotacao_pista: lotacaoPorPista,
      obs: obs,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        toast.success(t("piscinaCriada"));
        setFreeze(false);
        atualizarlista();
        handleCloseCriarPiscina();
        limparVariaveis();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }
  function validacao1() {
    if (
      nomePiscinaAgua &&
      numeroPistas &&
      tipoAguaPiscinaAgua &&
      profundidadeMaxPiscinaAgua &&
      profundidadeMinPiscinaAgua &&
      capacidade &&
      comprimentoPiscinaAgua &&
      larguraPiscinaAgua &&
      lotacaoPorPista &&
      obs
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        AddPiscina();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nomePiscinaAgua) {
        setErroNomePiscinaAgua(true);
      }
      if (numeroPistas == 0) {
        setErroNumeroPistas(true);
      }
      if (!tipoAguaPiscinaAgua) {
        setErroTipoAguaPiscinaAgua(true);
      }
      if (!profundidadeMaxPiscinaAgua) {
        setErroProfundidadeMaxPiscinaAgua(true);
      }
      if (!profundidadeMinPiscinaAgua) {
        setErroProfundidadeMinPiscinaAgua(true);
      }
      if (!capacidade) {
        setErroCapacidade(true);
      }
      if (!comprimentoPiscinaAgua) {
        setErroComprimentoPiscinaAgua(true);
      }
      if (!larguraPiscinaAgua) {
        setErroLarguraPiscinaAgua(true);
      }
      if (!lotacaoPorPista) {
        setErroLotacao(true);
      }
      if (!obs) {
        setErroObs(true);
      }
    }
  }

  useEffect(() => {
    if (search) {
      let arrayPiscinas = piscinasCopy;
      arrayPiscinas = arrayPiscinas.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setPiscinas(arrayPiscinas);
    } else {
      setPiscinas(piscinasCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaPiscinasAgua({
      id_entidade_exploradora: idEntidade,
      id_piscina: idPiscina,
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
              numeropistas: element.numero_pistas,
              capacidade: element.capacidade,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  //editar piscina agua

  const [edicao, setEdicao] = useState(true);

  const [nomePiscinaAguae, setNomePiscinaAguae] = useState("");
  const [idPiscinaAgua, setIDPiscinaAgua] = useState("");
  const [numeroPistase, setNumeroPistase] = useState(1);
  const [tipoAguaPiscinaAguae, setTipoAguaPiscinaAguae] = useState("");
  const [localizacaoPiscinaAguae, setLocalizacaoPiscinaAguae] = useState("");
  const [profundidadeMaxPiscinaAguae, setProfundidadeMaxPiscinaAguae] =
    useState("");
  const [profundidadeMinPiscinaAguae, setProfundidadeMinPiscinaAguae] =
    useState("");
  const [comprimentoPiscinaAguae, setComprimentoPiscinaAguae] = useState("");
  const [larguraPiscinaAguae, setLarguraPiscinaAguae] = useState("");
  const [capacidadee, setCapacidadee] = useState("");
  const [lotacaoPorPistae, setLotacaoe] = useState("");
  const [obse, setObse] = useState("");
  const [exteriorinteriore, setExteriorInteriore] = useState(0);

  const [erroNomePiscinaAguae, setErroNomePiscinaAguae] = useState(false);
  const [erroNumeroPistase, setErroNumeroPistase] = useState(false);
  const [erroTipoAguaPiscinaAguae, setErroTipoAguaPiscinaAguae] =
    useState(false);
  const [erroProfundidadeMaxPiscinaAguae, setErroProfundidadeMaxPiscinaAguae] =
    useState(false);
  const [erroProfundidadeMinPiscinaAguae, setErroProfundidadeMinPiscinaAguae] =
    useState(false);
  const [erroComprimentoPiscinaAguae, setErroComprimentoPiscinaAguae] =
    useState(false);
  const [erroLarguraPiscinaAguae, setErroLarguraPiscinaAguae] = useState(false);
  const [erroCapacidadee, setErroCapacidadee] = useState(false);
  const [erroObse, setErroObse] = useState(false);
  const [erroLotacaoe, setErroLotacaoe] = useState(false);

  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [
    mensagemErroTipoAguaPiscinaAguae,
    setMensagemErroTipoAguaPiscinaAguae,
  ] = useState("");

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "numeropistas"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "numeropistas"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [
    mensagemErroProfundidadeMinPiscinaAguae,
    setMensagemErroProfundidadeMinPiscinaAguae,
  ] = useState("");
  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };
  const [
    mensagemErroProfundidadeMaxPiscinaAguae,
    setMensagemErroProfundidadeMaxPiscinaAguae,
  ] = useState("");
  const [
    mensagemErroComprimentoPiscinaAguae,
    setMensagemErroComprimentoPiscinaAguae,
  ] = useState("");
  const [mensagemErroLarguraPiscinaAguae, setMensagemErroLarguraPiscinaAguae] =
    useState("");
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    if (idPiscinaAgua != "") {
      getPiscinaAgua({ id: idPiscinaAgua })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];
            setDadosOriginais(dados);
            setNomePiscinaAguae(dados.nome);
            setNumeroPistase(dados.numero_pistas);
            setTipoAguaPiscinaAguae(dados.tipo_agua);
            setExteriorInteriore(dados.exterior_interior);
            setProfundidadeMinPiscinaAguae(dados.profundidade_min);
            setProfundidadeMaxPiscinaAguae(dados.profundidade_max);
            setCapacidadee(dados.capacidade);
            setComprimentoPiscinaAguae(dados.comprimento);
            setLarguraPiscinaAguae(dados.largura);
            setLotacaoe(dados.lotacao_pista);
            setObse(dados.obs);
            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {});
    }
  }, [idPiscinaAgua]);
  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };
  function EditarPiscina() {
    setFreeze(true);
    editarPiscinaA({
      id_piscina_agua: idPiscinaAgua,
      nome: nomePiscinaAguae,
      numero_pistas: numeroPistase,
      tipo_agua: tipoAguaPiscinaAguae,
      exterior_interior: exteriorinteriore,
      profundidade_max: profundidadeMaxPiscinaAguae,
      profundidade_min: profundidadeMinPiscinaAguae,
      capacidade: capacidadee,
      comprimento: comprimentoPiscinaAguae,
      largura: larguraPiscinaAguae,
      lotacao_pista: lotacaoPorPistae,
      obs: obse,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let linha = {
            id_piscina_agua: idPiscinaAgua,
            nome: nomePiscinaAguae,
            numero_pistas: numeroPistase,
            tipo_agua: tipoAguaPiscinaAguae,
            exterior_interior: exteriorinteriore,
            profundidade_max: profundidadeMaxPiscinaAguae,
            profundidade_min: profundidadeMinPiscinaAguae,
            capacidade: capacidadee,
            comprimento: comprimentoPiscinaAguae,
            largura: larguraPiscinaAguae,
            lotacao_pista: lotacaoPorPistae,
            obs: obse,
            id_piscina: localStorage.getItem("idPiscina"),
            id_entidade_exploradora: localStorage.getItem("idEntidade"),
          };

          setDadosOriginais(linha);
          setFreeze(false);
          setEdicao(!edicao);
          handleCloseEditarPiscina();
          atualizarlista();
          toast.success(t("dadosPiscina"));
          //Scroll para o início da página
          window.scrollTo(0, 0);
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
      });
  }
  function atualizarlista() {
    listaPiscinasAgua({
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
      id_piscina: localStorage.getItem("idPiscina"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
              numeropistas: element.numero_pistas,
              capacidade: element.capacidade,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  function validacao() {
    if (
      nomePiscinaAguae &&
      numeroPistase &&
      tipoAguaPiscinaAguae &&
      profundidadeMaxPiscinaAguae &&
      profundidadeMinPiscinaAguae &&
      capacidadee &&
      comprimentoPiscinaAguae &&
      larguraPiscinaAguae &&
      lotacaoPorPistae &&
      obse
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        EditarPiscina();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nomePiscinaAguae) {
        setErroNomePiscinaAguae(true);
      }
      if (numeroPistase == 0) {
        setErroNumeroPistase(true);
      }
      if (!tipoAguaPiscinaAguae) {
        setErroTipoAguaPiscinaAguae(true);
      }
      if (!profundidadeMaxPiscinaAguae) {
        setErroProfundidadeMaxPiscinaAguae(true);
      }
      if (!profundidadeMinPiscinaAguae) {
        setErroProfundidadeMinPiscinaAguae(true);
      }
      if (!capacidadee) {
        setErroCapacidadee(true);
      }
      if (!comprimentoPiscinaAguae) {
        setErroComprimentoPiscinaAguae(true);
      }
      if (!larguraPiscinaAguae) {
        setErroLarguraPiscinaAguae(true);
      }
      if (!lotacaoPorPistae) {
        setErroLotacaoe(true);
      }
      if (!obse) {
        setErroObse(true);
      }
    }
  }

  function eliminarPiscinaAgua() {
    setFreeze(true);
    eliminarPiscinaA({
      id_piscina_agua: idPiscinaAgua,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("piscinaEliminada"));
          atualizarlista();
          handleClosePopupEliminar();
          handleCloseEditarPiscina();
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        // //toast.warn(t("erroWebservice"));
      });
  }

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    //Colocar os dados originais

    setNomePiscinaAguae(dadosOriginais.nome);
    setNumeroPistase(dadosOriginais.numero_pistas);
    setTipoAguaPiscinaAguae(dadosOriginais.tipo_agua);
    setExteriorInteriore(dadosOriginais.exterior_interior);
    setProfundidadeMinPiscinaAguae(dadosOriginais.profundiade_min);
    setProfundidadeMaxPiscinaAguae(dadosOriginais.profundiade_max);
    setCapacidadee(dadosOriginais.capacidade);
    setComprimentoPiscinaAguae(dadosOriginais.comprimento);
    setLarguraPiscinaAguae(dadosOriginais.largura);
    setLotacaoe(dadosOriginais.lotacao_pista);
    setObse(dadosOriginais.obs);

    //Limpar erros e mensagens de erro
    setErroNomePiscinaAguae(false);
    setErroNumeroPistase(false);
    setErroTipoAguaPiscinaAguae(false);
    setErroProfundidadeMaxPiscinaAguae(false);
    setErroProfundidadeMinPiscinaAguae(false);
    setErroCapacidadee(false);
    setErroComprimentoPiscinaAguae(false);
    setErroLarguraPiscinaAguae(false);
    setErroLotacaoe(false);
    setErroObse(false);

    window.scrollTo(0, 0);
  }

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {/* <div className="topleftcliente">
          <IconButton
            onClick={() => {
              navigate("/dashboard_admin");
            }}
            size={window.innerWidth < 600 ? "small" : "medium"}
          >
            {" "}
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </IconButton>
        </div> */}
        <h3 className="titleList">{t("listaPiscinasAgua")}</h3>
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleClickOpenCriarPiscina();
              }}
            ></span>
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={7}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 70,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              {
                field: "numeropistas",
                headerName: t("numeropistas"),
                flex: 1,
                type: "number",
                hide: columnsHidden.includes("numeropistas"),
              },
              {
                field: "capacidade",
                headerName: t("capacidade"),
                flex: 1,
                type: "number",
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 70,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIDPiscinaAgua(id);
                        handleClickOpenEditarPiscina();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={piscinas}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>

          {/* adicionar piscina */}
          <Dialog
            open={openCriarPiscina}
            onClose={handleCloseCriarPiscina}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarPiscina")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarPiscina}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div className="formPop" style={{ marginTop: 30 }}>
                <Scrollbar style={{ height: 400 }}>
                  <Box className="formTextField">
                    <div>
                      {/* Nome */}
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="text"
                          variant="standard"
                          label={t("nome")}
                          value={nomePiscinaAgua}
                          error={erroNomePiscinaAgua}
                          onChange={(e) => {
                            setNomePiscinaAgua(e.target.value);
                            setErroNomePiscinaAgua(false);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        className="formTextField2"
                        style={{
                          width: "30%",
                          display: "flex",
                          justifyContent: "flex-start !important",
                        }}
                      >
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("numeropistas")}
                          value={numeroPistas}
                          error={erroNumeroPistas}
                          InputProps={{
                            inputProps: { min: 1, max: 12 },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value < 1 || value > 12) {
                              setErroNumeroPistas(true);
                            } else {
                              setNumeroPistas(value);
                              setErroNumeroPistas(false);
                            }
                          }}
                        />
                      </FormControl>
                      {/*tipo de agua */}
                      {/* <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="text"
                          variant="standard"
                          label={t("tipodeagua")}
                          value={tipoAguaPiscinaAgua}
                          error={erroTipoAguaPiscinaAgua}
                          helperText={mensagemErroTipoAguaPiscinaAgua}
                          onChange={(e) => {
                            setTipoAguaPiscinaAgua(
                              e.target.value.toLocaleLowerCase()
                            );
                            setErroTipoAguaPiscinaAgua(false);
                            setMensagemErroTipoAguaPiscinaAgua("");
                          }}
                        />
                      </FormControl> */}
                      <FormControl className="formTextField2" fullWidth>
                        <FormLabel
                          component="legend"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start !important",
                          }}
                        >
                          {t("localizacaodapiscina")}
                        </FormLabel>

                        <RadioGroup
                          name={"localização"}
                          value={exteriorinterior}
                          onChange={(e) => setExteriorInterior(e.target.value)}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Interior"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Exterior"
                          />
                        </RadioGroup>
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={
                            t("profundidademaxima") + "(" + t("metros") + ")"
                          }
                          value={profundidadeMaxPiscinaAgua}
                          error={erroProfundidadeMaxPiscinaAgua}
                          helperText={mensagemErroProfundidadeMaxPiscinaAgua}
                          onChange={(e) => {
                            setProfundidadeMaxPiscinaAgua(
                              e.target.value.toLocaleLowerCase()
                            );
                            setErroProfundidadeMaxPiscinaAgua(false);
                            setMensagemErroProfundidadeMaxPiscinaAgua("");
                          }}
                        />
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={
                            t("profundidademinima") + "(" + t("metros") + ")"
                          }
                          value={profundidadeMinPiscinaAgua}
                          error={erroProfundidadeMinPiscinaAgua}
                          onChange={(e) => {
                            setProfundidadeMinPiscinaAgua(e.target.value);
                            setErroProfundidadeMinPiscinaAgua(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("capacidade")}
                          value={capacidade}
                          error={erroCapacidade}
                          onChange={(e) => {
                            const value = e.target.value;

                            setCapacidade(value);
                            setErroCapacidade(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("comprimento") + "(" + t("metros") + ")"}
                          value={comprimentoPiscinaAgua}
                          error={erroComprimentoPiscinaAgua}
                          helperText={mensagemErroComprimentoPiscinaAgua}
                          onChange={(e) => {
                            setComprimentoPiscinaAgua(
                              e.target.value.toLocaleLowerCase()
                            );
                            setErroComprimentoPiscinaAgua(false);
                            setMensagemErroComprimentoPiscinaAgua("");
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("largura") + "(" + t("metros") + ")"}
                          value={larguraPiscinaAgua}
                          error={erroLarguraPiscinaAgua}
                          helperText={mensagemErroLarguraPiscinaAgua}
                          onChange={(e) => {
                            setLarguraPiscinaAgua(
                              e.target.value.toLocaleLowerCase()
                            );
                            setErroLarguraPiscinaAgua(false);
                            setMensagemErroLarguraPiscinaAgua("");
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("lotacaoporpista")}
                          value={lotacaoPorPista}
                          error={erroLotacao}
                          onChange={(e) => {
                            const value = e.target.value;

                            setLotacao(value);
                            setErroLotacao(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={10}
                          value={obs}
                          label={t("observacoes")}
                          error={erroObs}
                          onChange={(e) => {
                            setObs(e.target.value);
                            setErroObs(false);
                          }}
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Scrollbar>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* editar piscina */}
          <Dialog
            open={openEditarPiscina}
            onClose={handleCloseEditarPiscina}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleCloseEditarPiscina}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Scrollbar style={{ height: 400 }}>
                    <Box className="formTextField">
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="text"
                            variant="standard"
                            label={t("nome")}
                            value={nomePiscinaAguae}
                            error={erroNomePiscinaAguae}
                            onChange={(e) => {
                              setNomePiscinaAguae(e.target.value);
                              setErroNomePiscinaAguae(false);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          className="formTextField2"
                          style={{
                            width: "30%",
                            display: "flex",
                            justifyContent: "flex-start !important",
                          }}
                        >
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("numeropistas")}
                            value={numeroPistase}
                            error={erroNumeroPistase}
                            InputProps={{
                              inputProps: { min: 1, max: 12 },
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value < 1 || value > 12) {
                                setErroNumeroPistase(true);
                              } else {
                                setNumeroPistase(value);
                                setErroNumeroPistase(false);
                              }
                            }}
                          />
                        </FormControl>
                        {/*tipo de agua */}
                        {/* <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="text"
                            variant="standard"
                            label={t("tipodeagua")}
                            value={tipoAguaPiscinaAgua}
                            error={erroTipoAguaPiscinaAgua}
                            helperText={mensagemErroTipoAguaPiscinaAgua}
                            onChange={(e) => {
                              setTipoAguaPiscinaAguae(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroTipoAguaPiscinaAguae(false);
                              setMensagemErroTipoAguaPiscinaAguae("");
                            }}
                          />
                        </FormControl> */}
                        <FormControl className="formTextField2" fullWidth>
                          <FormLabel
                            component="legend"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start !important",
                            }}
                          >
                            {t("localizacaodapiscina")}
                          </FormLabel>

                          <RadioGroup
                            name={"localização"}
                            value={exteriorinterior}
                            onChange={(e) =>
                              setExteriorInteriore(e.target.value)
                            }
                          >
                            <FormControlLabel
                              disabled={edicao}
                              value={0}
                              control={<Radio />}
                              label="Interior"
                            />
                            <FormControlLabel
                              disabled={edicao}
                              value={1}
                              control={<Radio />}
                              label="Exterior"
                            />
                          </RadioGroup>
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={
                              t("profundidademaxima") + "(" + t("metros") + ")"
                            }
                            value={profundidadeMaxPiscinaAguae}
                            error={erroProfundidadeMaxPiscinaAguae}
                            helperText={mensagemErroProfundidadeMaxPiscinaAguae}
                            onChange={(e) => {
                              setProfundidadeMaxPiscinaAguae(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroProfundidadeMaxPiscinaAguae(false);
                              setMensagemErroProfundidadeMaxPiscinaAguae("");
                            }}
                          />
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={
                              t("profundidademinima") + "(" + t("metros") + ")"
                            }
                            value={profundidadeMinPiscinaAguae}
                            error={erroProfundidadeMinPiscinaAguae}
                            onChange={(e) => {
                              setProfundidadeMinPiscinaAguae(e.target.value);
                              setErroProfundidadeMinPiscinaAguae(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("capacidade")}
                            value={capacidadee}
                            error={erroCapacidadee}
                            onChange={(e) => {
                              setCapacidadee(e.target.value);
                              setErroCapacidadee(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("comprimento") + "(" + t("metros") + ")"}
                            value={comprimentoPiscinaAguae}
                            error={erroComprimentoPiscinaAguae}
                            helperText={mensagemErroComprimentoPiscinaAguae}
                            onChange={(e) => {
                              setComprimentoPiscinaAguae(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroComprimentoPiscinaAguae(false);
                              setMensagemErroComprimentoPiscinaAguae("");
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("largura") + "(" + t("metros") + ")"}
                            value={larguraPiscinaAguae}
                            error={erroLarguraPiscinaAguae}
                            helperText={mensagemErroLarguraPiscinaAguae}
                            onChange={(e) => {
                              setLarguraPiscinaAguae(
                                e.target.value.toLocaleLowerCase()
                              );
                              setErroLarguraPiscinaAguae(false);
                              setMensagemErroLarguraPiscinaAguae("");
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("lotacaoporpista")}
                            value={lotacaoPorPistae}
                            error={erroLotacaoe}
                            onChange={(e) => {
                              const value = e.target.value;

                              setLotacaoe(value);
                              setErroLotacaoe(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            rows={10}
                            value={obse}
                            label={t("observacoes")}
                            error={erroObse}
                            onChange={(e) => {
                              setObse(e.target.value);
                              setErroObse(false);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Scrollbar>

                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Eliminar */}
                  <Dialog
                    open={eliminar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("eliminarPiscina")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("eliminarPiscinaTexto")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          eliminarPiscinaAgua();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                        }}
                        autoFocus
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("editar")}
                  </Button>

                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${eliminarbtnpeq})`
                        : `url(${eliminarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupEliminar();
                    }}
                  >
                    <TrashIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("eliminar")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,
                      width: isMobile ? 164 : 218,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaPiscinasAguaA;
