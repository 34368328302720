import Cookies from "js-cookie";
import { makeRequestPost2 } from "../api";

export function getQrCodes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("qrcode/code", dados, token);
}


export function listQrCodes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("qrcode/list", dados, token);
}

export function ActiveQrCodes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("qrcode/active", dados, token);
}