import Cookies from "js-cookie";
import { makeRequestGet2, makeRequestPost2 } from "../api";

//Pagamento mbway
export function pagamentoMbway(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(
    "pagamentos/api/mbway/reference/sandbox",
    dados,
    token
  );
}

//Pagamento cartão de crédito
export function pagamentoCartaoCredito(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(
    "pagamentos/api/credito/reference/sandbox",
    dados,
    token
  );
}

//Pagamento cartão de crédito - SUCESSO
export function pagamentoCartaoCreditoSucesso(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(
    "pagamentos/api/credito/reference/sandbox/response1",
    dados,
    token
  );
}

//Pagamento cartão de crédito - ERRO
export function pagamentoCartaoCreditoErro(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(
    "pagamentos/api/credito/reference/sandbox/response2",
    dados,
    token
  );
}

//Pagamento cartão de crédito - CANCELADA
export function pagamentoCartaoCreditoCancelada(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(
    "pagamentos/api/credito/reference/sandbox/response3",
    dados,
    token
  );
}

//Pagamento multibanco
export function pagamentoMultibanco(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(
    "pagamentos/api/multibanco/reference/sandbox",
    dados,
    token
  );
}

// Associar pagamentos a marcação
export function associarPagamentosMarcacoes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/marcacoes", dados, token);
}

//Lista de pagamentos
export function listaDePagamentos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/list", dados, token);
}

//Numero de pagamentos pendentes
export function NumeroPagamentosPendentes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/pendentes", dados, token);
}

//Enviar email após marcação
export function emailMarcacao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/marcacao", dados, token);
}

//Enviar email após marcação
export function emailCompraPackMarcacao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/compra-marcacao", dados, token);
}

//Enviar email após inscrição turma
export function emailInscricaoTurma(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/inscricao", dados, token);
}

//Enviar email sucesso pagamento
export function emailSucessoPagamento(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/sucesso", dados, token);
}

//Enviar email sucesso pagamento balcao
export function emailSucessoPagamento2(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/sucesso2", dados, token);
}

//Enviar email erro pagamento
export function emailErroPagamento(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/erro", dados, token);
}

//Enviar email erro pagamento - balcão
export function emailErroPagamento2(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/erro2", dados, token);
}

// Verificar pagamentos mensais em dia
export function verificarPagamentosEmDia(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/mensais", dados, token);
}

// Gerar pagamento mensal
export function gerarPagamentoMensal(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/api/multibanco/mensal", dados, token);
}

// Email pagamento mensal
export function emailPagamentoMensal(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/pendente", dados, token);
}

// Cancelar pagamento balcão
export function cancelarPagamentoBalcao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/cancelar/balcao", dados, token);
}

// Aprovar pagamento balcão
export function aprovarPagamentoBalcao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/aprovar/balcao", dados, token);
}

// Email cancelamento pagamento balcão
export function emailCancelarPagamentoBalcao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/erro2", dados, token);
}

//Adicionar linhas da compra
export function linhasCompra(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/linhas/compra", dados, token);
}

// Email cancelamento pagamento balcão
export function emailCancelarPagamentoMensal(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/erro3", dados, token);
}

// Email confirmacao pagamento balcão
export function emailConfPagamentoMensal(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/sucesso3", dados, token);
}

// Cancelar pagamento pendente
export function cancelarPagamentoPendente(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/cancelar", dados, token);
}
