import Cookies from "js-cookie";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { Box, color, maxWidth, useTheme } from "@mui/system";
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import Nav from "../../components/Nav";
import { verificarPagamentosEmDia } from "../../api/requests/Pagamentos";
import Search1 from "../../components/DataTable/Search1";
import { getListaPacks } from "../../api/requests/Marcacoes";

function MeusPacks() {
  //Traduções
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState("");
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);

  const [idEscolhida, setIdEscolhida] = useState("");
  const [packs, setPacks] = useState([]);
  const [packsCopy, setPacksCopy] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const dataAtual = new Date();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setTimeout(() => {
      verificarPagamentosEmDia().then((res) => {
        if (res.data.success) {
          navigate("/dashboard_customer");
        }
      });
    }, 20);
  }, []);
  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);
  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "nomePiscina", "num_tickets"] : []
  );
  const handleResize = () => {
    setColumnsHidden(
      isMobile ? ["id", "nomePiscina", "num_tickets"] : []
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getListaPacks({
      id_cliente: id,
      id_piscina: localStorage.getItem("idPiscina"),
    }).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_pack_cliente,
            nomePack: element.nomePack,
            nomeUtilizador:element.nome_utilizador,
            nomePiscina: element.nomePiscina,
            num_tickets: element.num_tickets,
            num_tickets_utilizados: element.num_tickets_utilizados,
            data_expira:moment(element.data_expira).format("DD-MM-YYYY"),
          };
          arrayFinal.push(linha);
       
          setPacks(arrayFinal);
          setPacksCopy(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    })
    .catch((error) => {
      //toast.warn(t("erroWebservice"));
    });
}, []);

  const getRowId = (row) => {
    return row.id; // Você pode usar qualquer propriedade que seja única para cada linha
  };

  useEffect(() => {
    if (search) {
      let arrayPacks = packsCopy;
      arrayPacks = arrayPacks.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nomePack.toLowerCase().includes(search.toLowerCase())
      );
      setPacks(arrayPacks);
    } else {
      setPacks(packsCopy);
    }
  }, [search]);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div className={isMobile ? "listPagesMobile" : "listPages"}>
       
          
            <h3 className="titleMarcar">{t("meuspacks")}</h3>
            <div className="divTabela">
              <div
                className="col-0"
                style={{
                  textAlign: "start",
                  marginTop: 45,
                  marginLeft: 30,
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <Search1
                  onSearch={(value) => {
                    setSearch(value);
                  }}
                />
              </div>
              <DataGrid
                className={isMobile ? "tabela2" : "tabela"}
                pageSize={10}
                //Colunas
                columns={[
                  {
                    field: "id",
                    headerName: t("id"),
                    type: "number",
                    maxWidth: 70,
                    flex: 1,
                    hide: columnsHidden.includes("id"),
                  },
                  {
                    field: "nomePack",
                    headerName: t("nome"),
                    type: "string",
                    flex: 1,
                  },
                  {
                    field: "nomeUtilizador",
                    headerName: t("utilizador"),
                    flex: 1,
                    type: "string",
                   
                  },

                  {
                    field: "nomePiscina",
                    headerName: t("piscina"),
                    flex: 1,
                    type: "string",
                    hide: columnsHidden.includes("piscina"),
                  },
                  {
                    field: "num_tickets",
                    headerName: t("numtickets"),
                    flex: 1,
                    type: "number",
                    hide: columnsHidden.includes("num_tickets"),
                  },
                  {
                    field: "num_tickets_utilizados",
                    headerName: t("numticketsutilizados"),
                    flex: 1,
                    type: "number",
                    hide: columnsHidden.includes("num_tickets_utilizados"),
                  },
                  {
                    field: "data_expira",
                    headerName: t("dataExpira"),
                    flex: 1,
                    type: "date",
                 
                  },
                ]}
                rows={packs}
                checkboxSelection={isMobile ? false : true}
                // getRowId={getRowId}
                //Idioma
                localeText={
                  i18n.language == "pt"
                    ? ptBR.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  Toolbar: CustomToolbar,
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                }}
              />
           
      
        </div>
      </div>
    </div>
  );
}

export default MeusPacks;
