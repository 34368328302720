import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Paper,
  Select,
  TextField,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { adicionarPiscinaA } from "../../api/requests/PiscinaAgua";

function CriarPiscinaAguaA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  const [nomePiscinaAgua, setNomePiscinaAgua] = useState("");
  const [numeroPistas, setNumeroPistas] = useState(1);
  const [tipoAguaPiscinaAgua, setTipoAguaPiscinaAgua] = useState("");
  const [localizacaoPiscinaAgua, setLocalizacaoPiscinaAgua] = useState("");
  const [exteriorinterior, setExteriorInterior] = useState(0);
  const [profundidadeMaxPiscinaAgua, setProfundidadeMaxPiscinaAgua] =
    useState("");
  const [profundidadeMinPiscinaAgua, setProfundidadeMinPiscinaAgua] =
    useState("");
  const [comprimentoPiscinaAgua, setComprimentoPiscinaAgua] = useState("");
  const [larguraPiscinaAgua, setLarguraPiscinaAgua] = useState("");
  const [capacidade, setCapacidade] = useState("");
  const [lotacaoPorPista, setLotacao] = useState("");
  const [obs, setObs] = useState("");

  const [erroNomePiscinaAgua, setErroNomePiscinaAgua] = useState("");
  const [erroNumeroPistas, setErroNumeroPistas] = useState("");
  const [erroTipoAguaPiscinaAgua, setErroTipoAguaPiscinaAgua] = useState("");
  const [erroProfundidadeMaxPiscinaAgua, setErroProfundidadeMaxPiscinaAgua] =
    useState("");
  const [erroProfundidadeMinPiscinaAgua, setErroProfundidadeMinPiscinaAgua] =
    useState("");
  const [erroComprimentoPiscinaAgua, setErroComprimentoPiscinaAgua] =
    useState("");
  const [erroLarguraPiscinaAgua, setErroLarguraPiscinaAgua] = useState("");
  const [erroCapacidade, setErroCapacidade] = useState("");
  const [erroObs, setErroObs] = useState("");
  const [erroLotacao, setErroLotacao] = useState("");

  const [mensagemErroTipoAguaPiscinaAgua, setMensagemErroTipoAguaPiscinaAgua] =
    useState("");
  const [
    mensagemErroProfundidadeMinPiscinaAgua,
    setMensagemErroProfundidadeMinPiscinaAgua,
  ] = useState("");

  const [
    mensagemErroProfundidadeMaxPiscinaAgua,
    setMensagemErroProfundidadeMaxPiscinaAgua,
  ] = useState("");
  const [
    mensagemErroComprimentoPiscinaAgua,
    setMensagemErroComprimentoPiscinaAgua,
  ] = useState("");
  const [mensagemErroLarguraPiscinaAgua, setMensagemErroLarguraPiscinaAgua] =
    useState("");
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  function AddPiscina() {
    setFreeze(true);
    adicionarPiscinaA({
      nome: nomePiscinaAgua,
      numero_pistas: numeroPistas,
      tipo_agua: tipoAguaPiscinaAgua,
      exterior_interior: exteriorinterior,
      profundidade_max: profundidadeMaxPiscinaAgua,
      profundidade_min: profundidadeMinPiscinaAgua,
      capacidade: capacidade,
      comprimento: comprimentoPiscinaAgua,
      largura: larguraPiscinaAgua,
      lotacao_pista: lotacaoPorPista,
      obs: obs,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false)
        toast.success(t("piscinaCriada"));
        navigate("/waterlista");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }
  function validacao1() {
    if (
      nomePiscinaAgua &&
      numeroPistas &&
      tipoAguaPiscinaAgua &&
      profundidadeMaxPiscinaAgua &&
      profundidadeMinPiscinaAgua &&
      capacidade &&
      comprimentoPiscinaAgua &&
      larguraPiscinaAgua &&
      lotacaoPorPista &&
      obs
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        AddPiscina();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nomePiscinaAgua) {
        setErroNomePiscinaAgua(true);
      }
      if (numeroPistas == 0) {
        setErroNumeroPistas(true);
      }
      if (!tipoAguaPiscinaAgua) {
        setErroTipoAguaPiscinaAgua(true);
      }
      if (!profundidadeMaxPiscinaAgua) {
        setErroProfundidadeMaxPiscinaAgua(true);
      }
      if (!profundidadeMinPiscinaAgua) {
        setErroProfundidadeMinPiscinaAgua(true);
      }
      if (!capacidade) {
        setErroCapacidade(true);
      }
      if (!comprimentoPiscinaAgua) {
        setErroComprimentoPiscinaAgua(true);
      }
      if (!larguraPiscinaAgua) {
        setErroLarguraPiscinaAgua(true);
      }
      if (!lotacaoPorPista) {
        setErroLotacao(true);
      }
      if (!obs) {
        setErroObs(true);
      }
    }
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/waterlista");
              }}
              size={isMobile ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          {/* <div className="align-right">
            <h3 className="titleList">{t("adicionarPiscinaAgua")}</h3>
          </div> */}
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nomePiscinaAgua}
                    error={erroNomePiscinaAgua}
                    onChange={(e) => {
                      setNomePiscinaAgua(e.target.value);
                      setErroNomePiscinaAgua(false);
                    }}
                  />
                </FormControl>
                <FormControl
                  className="formTextField2"
                  style={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-start !important",
                  }}
                >
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("numeropistas")}
                    value={numeroPistas}
                    error={erroNumeroPistas}
                    InputProps={{
                      inputProps: { min: 1, max: 12 },
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 1 || value > 12) {
                        setErroNumeroPistas(true);
                      } else {
                        setNumeroPistas(value);
                        setErroNumeroPistas(false);
                      }
                    }}
                  />
                </FormControl>
                {/*tipo de agua */}
                {/* <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("tipodeagua")}
                    value={tipoAguaPiscinaAgua}
                    error={erroTipoAguaPiscinaAgua}
                    helperText={mensagemErroTipoAguaPiscinaAgua}
                    onChange={(e) => {
                      setTipoAguaPiscinaAgua(
                        e.target.value.toLocaleLowerCase()
                      );
                      setErroTipoAguaPiscinaAgua(false);
                      setMensagemErroTipoAguaPiscinaAgua("");
                    }}
                  />
                </FormControl> */}
                <FormControl className="formTextField2" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start !important",
                    }}
                  >
                    {t("localizacaodapiscina")}
                  </FormLabel>

                  <RadioGroup
                    name={"localização"}
                    value={exteriorinterior}
                    onChange={(e) => setExteriorInterior(e.target.value)}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Interior"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Exterior"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("profundidademaxima") + "(" + t("metros") + ")"}
                    value={profundidadeMaxPiscinaAgua}
                    error={erroProfundidadeMaxPiscinaAgua}
                    helperText={mensagemErroProfundidadeMaxPiscinaAgua}
                    onChange={(e) => {
                      setProfundidadeMaxPiscinaAgua(
                        e.target.value.toLocaleLowerCase()
                      );
                      setErroProfundidadeMaxPiscinaAgua(false);
                      setMensagemErroProfundidadeMaxPiscinaAgua("");
                    }}
                  />
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("profundidademinima") + "(" + t("metros") + ")"}
                    value={profundidadeMinPiscinaAgua}
                    error={erroProfundidadeMinPiscinaAgua}
                    onChange={(e) => {
                      setProfundidadeMinPiscinaAgua(e.target.value);
                      setErroProfundidadeMinPiscinaAgua(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("capacidade")}
                    value={capacidade}
                    error={erroCapacidade}
                    onChange={(e) => {
                      const value = e.target.value;

                      setCapacidade(value);
                      setErroCapacidade(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("comprimento") + "(" + t("metros") + ")"}
                    value={comprimentoPiscinaAgua}
                    error={erroComprimentoPiscinaAgua}
                    helperText={mensagemErroComprimentoPiscinaAgua}
                    onChange={(e) => {
                      setComprimentoPiscinaAgua(
                        e.target.value.toLocaleLowerCase()
                      );
                      setErroComprimentoPiscinaAgua(false);
                      setMensagemErroComprimentoPiscinaAgua("");
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("largura") + "(" + t("metros") + ")"}
                    value={larguraPiscinaAgua}
                    error={erroLarguraPiscinaAgua}
                    helperText={mensagemErroLarguraPiscinaAgua}
                    onChange={(e) => {
                      setLarguraPiscinaAgua(e.target.value.toLocaleLowerCase());
                      setErroLarguraPiscinaAgua(false);
                      setMensagemErroLarguraPiscinaAgua("");
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={t("lotacaoporpista")}
                    value={lotacaoPorPista}
                    error={erroLotacao}
                    onChange={(e) => {
                      const value = e.target.value;

                      setLotacao(value);
                      setErroLotacao(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    style={{ textAlign: "left" }}
                    variant="outlined"
                    multiline
                    rows={10}
                    value={obs}
                    label={t("observacoes")}
                    error={erroObs}
                    onChange={(e) => {
                      setObs(e.target.value);
                      setErroObs(false);
                    }}
                  />
                </FormControl>
              </div>
            </Box>
          </div>
        </div>

        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarPiscinaAguaA;
