import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  emailConfPagamentoMensal,
  emailSucessoPagamento,
  pagamentoCartaoCreditoSucesso,
} from "../../api/requests/Pagamentos";
import FooterExterno from "../../components/FooterExterno";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import Nav from "../../components/Nav";
import successgif from "../../images/success.gif";

function CompraSucessoMensalMobile() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    const urlSearchParams = new URLSearchParams(window.location.search);

    const id2 = urlSearchParams.get("id");
    const requestId = urlSearchParams.get("requestId");
    const sk = urlSearchParams.get("sk");

    pagamentoCartaoCreditoSucesso({
      orderId: id2,
      requestId: requestId,
      balcao: 1,
    }).then((res) => {
      setTimeout(() => {
        emailConfPagamentoMensal({
          orderId: id2,
          requestId: requestId,
          mobile: true,
        }).then((res2) => {
          // Redireciona para o esquema de URL personalizado
          window.location.href = "gopool://";
        });
      }, 3000);
    });
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      {/* <Nav />
      <Header /> */}
      <div className="listPages" style={{ overflow: "auto" }}>
        <div className="divTabela">
          <div className="divTabelaMarcar2">
            <div style={{ marginTop: "20px" }}>
              <img src={successgif} alt="Success" />
            </div>
            <span style={{ paddingTop: "10px" }}>
              {t("pagamentoSucessoText")}
            </span>
            <p
              style={{
                paddingTop: "50px",
                color: "#111111",
                fontWeight: "200",
                fontSize: 20,
              }}
            >
              {t("redirecionarApp")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompraSucessoMensalMobile;
