import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2,makeRequestGet2 } from "../api";


export function listaSI(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("seguroInscricao/list", dados, token);
}


export function getSI(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("seguroInscricao/id", dados, token);
}


export function adicionarSI(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("seguroInscricao/", dados, token);
}


export function editarSI(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("seguroInscricao/", dados, token);
}


export function eliminarSI(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("seguroInscricao/", dados, token);
}
