import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../values/Colors";

const Search = ({ onSearch }) => {
  //Traduções
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <TextField
      variant="outlined"
      label={t("procurar")}
      placeholder={t("pesquisa")}
      className="search"
      type={"text"}
      id="search"
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputAdornment>
        ),
      }}
      value={search}
      onChange={(e) => onInputChange(e.target.value)}
    />
  );
};

export default Search;
