import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  emailErroPagamento,
  pagamentoCartaoCreditoCancelada,
} from "../../api/requests/Pagamentos";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import Nav from "../../components/Nav";
import errorgif from "../../images/error.gif";
import successgif from "../../images/success.gif";
function CompraCancelada() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    // if (!id && !token) {
    //   navigate("/");
    //   //Remover os dados e encaminhar para a página de Login
    //   Cookies.remove("token");
    //   Cookies.remove("refresh_token");
    //   Cookies.remove("uId");
    //   Cookies.remove("uName");
    //   Cookies.remove("uEmail");
    //   Cookies.remove("uKey");
    //   Cookies.remove("uData");
    //   toast.info(t("sessaoExpirada"));
    // } else {
    //   //Caso os dados estejam incompletos
    //   if (dadosIncompletos === "false") {
    //     navigate("/change_user_data");
    //     toast.info(t("preencherDadosObg"));
    //   }
    // }
    const urlSearchParams = new URLSearchParams(window.location.search);

    const id2 = urlSearchParams.get("id");
    const requestId = urlSearchParams.get("requestId");
    const sk = urlSearchParams.get("sk");

    pagamentoCartaoCreditoCancelada({
      orderId: id2,
      requestId: requestId,
    }).then((res) => {
      // setTimeout(() => {
      //   let utilizador = Cookies.get("utilizador");
      //   if (utilizador == "superadmin") {
      //     navigate("/dashboard_superadmin");
      //   } else if (utilizador == "supercomercial") {
      //     navigate("/dashboard_supercommercial");
      //   } else if (utilizador == "comercial") {
      //     navigate("/dashboard_commercial");
      //   } else if (utilizador == "admin") {
      //     navigate("/dashboard_admin");
      //   } else if (utilizador == "funcionario") {
      //     navigate("/dashboard_employee");
      //   } else if (utilizador == "professor") {
      //     navigate("/dashboard_teacher");
      //   } else if (utilizador == "cliente") {
      //     navigate("/marcar");
      //   } else {
      //     navigate("/marcar");
      //   }
      // }, 3000);
    });
  }, []);

  // function paginaInicial() {
  //   let utilizador = Cookies.get("utilizador");
  //   if (utilizador == "superadmin") {
  //     navigate("/dashboard_superadmin");
  //   } else if (utilizador == "supercomercial") {
  //     navigate("/dashboard_supercommercial");
  //   } else if (utilizador == "comercial") {
  //     navigate("/dashboard_commercial");
  //   } else if (utilizador == "admin") {
  //     navigate("/dashboard_admin");
  //   } else if (utilizador == "funcionario") {
  //     navigate("/dashboard_employee");
  //   } else if (utilizador == "professor") {
  //     navigate("/dashboard_teacher");
  //   } else if (utilizador == "cliente") {
  //     navigate("/dashboard_customer");
  //   } else {
  //     navigate("/dashboard_customer");
  //   }
  // }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div className="listPages" style={{ overflow: "auto" }}>
        <div className="divTabela">
          <div className="divTabelaMarcar2">
            <div style={{ marginTop: "20px" }}>
              <img src={errorgif} alt="Error" />
            </div>
            <span style={{ paddingTop: "10px" }}>
              {t("pagamentoCanceladoText")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompraCancelada;
