import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faPen,
  faStop,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { createSearchParams, Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  deletePiscina,
  getPiscinasId,
  postStatusPiscina,
  putPiscina,
} from "../../api/requests/Piscinas";
import { enviarEmailNovoPedido, postPedidos } from "../../api/requests/Pedidos";

function EditarPiscina() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { id } = state || {};
  const id2 = id;
  const [freeze, setFreeze] = useState(false);

  //Variáveis
  const [edicao, setEdicao] = useState(true);
  const [dadosOriginais, setDadosOriginais] = useState([]);
  // Entidade
  const [nomeEntidade, setNomeEntidade] = useState("");
  const [nifEntidade, setNifEntidade] = useState("");
  const [certidaoEntidade, setCertidaoEntidade] = useState("");
  const [emailEntidade, setEmailEntidade] = useState("");
  const [telefoneEntidade, setTelefoneEntidade] = useState("");
  const [ruaEntidade, setRuaEntidade] = useState("");
  const [codigoPostalEntidade, setCodigoPostalEntidade] = useState("");
  const [cidadeEntidade, setCidadeEntidade] = useState("");
  const [distritoEntidade, setDistritoEntidade] = useState("");
  const [paisEntidade, setPaisEntidade] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  //Piscina
  const [nomePiscina, setNomePiscina] = useState("");
  const [statusPiscina, setStatusPiscina] = useState();
  const [nifPiscina, setNifPiscina] = useState("");
  const [emailPiscina, setEmailPiscina] = useState("");
  const [telefonePiscina, setTelefonePiscina] = useState("");
  const [telefone2Piscina, setTelefone2Piscina] = useState("");
  const [ruaPiscina, setRuaPiscina] = useState("");
  const [codigoPostalPiscina, setCodigoPostalPiscina] = useState("");
  const [cidadePiscina, setCidadePiscina] = useState("");
  const [distritoPiscina, setDistritoPiscina] = useState("");
  const [paisPiscina, setPaisPiscina] = useState("");
  const [latitudePiscina, setLatitudePiscina] = useState("");
  const [longitudePiscina, setLongitudePiscina] = useState("");
  const [fotoPiscina, setFotoPiscina] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [idFoto1, setIdFoto1] = useState("");
  const [foto2, setFoto2] = useState([]);
  const [idFoto2, setIdFoto2] = useState("");
  const [foto3, setFoto3] = useState([]);
  const [idFoto3, setIdFoto3] = useState("");
  const [foto4, setFoto4] = useState([]);
  const [idFoto4, setIdFoto4] = useState("");
  const [foto5, setFoto5] = useState([]);
  const [idFoto5, setIdFoto5] = useState("");
  const [foto6, setFoto6] = useState([]);
  const [idFoto6, setIdFoto6] = useState("");
  //Contrato
  const [contrato, setContrato] = useState([]);
  const [contrato2, setContrato2] = useState([]);
  //Modalidades
  const [natacaoAdultos, setNatacaoAdultos] = useState(false);
  const [natacaoBebes, setNatacaoBebes] = useState(false);
  const [natacaoIdosos, setNatacaoIdosos] = useState(false);
  const [hidroginastica, setHidroginastica] = useState(false);
  const [escolaNatacao, setEscolaNatacao] = useState(false);

  const [hidrobike, setHidrobike] = useState(false);
  const [natacaoGravidas, setNatacaoGravidas] = useState(false);
  const [hidroterapia, setHidroterapia] = useState(false);
  const [nadoLivre, setNadoLivre] = useState(false);
  const [equipasFederadas, setEquipasFederadas] = useState(false);
  const [hidropower, setHidropower] = useState(false);
  //Horarios
  const [horario, setHorario] = useState([]);
  const [horario2, setHorario2] = useState([]);
  //Links uteis
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  //Termos e condições (quando o cliente se inscreve na piscina)
  const [termos, setTermos] = useState();
  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  const [motivoEliminacao, setMotivoEliminacao] = useState("");
  const [erroMotivoEliminacao, setErroMotivoEliminacao] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  //Validações
  //Entidade
  const [erroNomeEntidade, setErroNomeEntidade] = useState(false);
  const [erroNifEntidade, setErroNifEntidade] = useState(false);
  const [erroCertidaoEntidade, setErroCertidaoEntidade] = useState(false);
  const [erroEmailEntidade, setErroEmailEntidade] = useState(false);
  const [erroTelefoneEntidade, setErroTelefoneEntidade] = useState(false);
  const [erroRuaEntidade, setErroRuaEntidade] = useState(false);
  const [erroCodigoPostalEntidade, setErroCodigoPostalEntidade] =
    useState(false);
  const [erroCidadeEntidade, setErroCidadeEntidade] = useState(false);
  const [erroDistritoEntidade, setErroDistritoEntidade] = useState(false);
  const [erroPaisEntidade, setErroPaisEntidade] = useState(false);
  //Piscina
  const [erroNomePiscina, setErroNomePiscina] = useState(false);
  const [erroNifPiscina, setErroNifPiscina] = useState(false);
  const [erroEmailPiscina, setErroEmailPiscina] = useState(false);
  const [erroTelefonePiscina, setErroTelefonePiscina] = useState(false);
  const [erroTelefone2Piscina, setErroTelefone2Piscina] = useState(false);
  const [erroRuaPiscina, setErroRuaPiscina] = useState(false);
  const [erroCodigoPostalPiscina, setErroCodigoPostalPiscina] = useState(false);
  const [erroCidadePiscina, setErroCidadePiscina] = useState(false);
  const [erroDistritoPiscina, setErroDistritoPiscina] = useState(false);
  const [erroPaisPiscina, setErroPaisPiscina] = useState(false);
  const [erroLatitudePiscina, setErroLatitudePiscina] = useState(false);
  const [erroLongitudePiscina, setErroLongitudePiscina] = useState(false);
  //Links uteis
  const [erroLink1, setErroLink1] = useState(false);
  const [erroLink2, setErroLink2] = useState(false);
  const [erroLink3, setErroLink3] = useState(false);
  //Termos
  const [erroTermos, setErroTermos] = useState(false);

  //Mensagens de erro
  //Entidade
  const [mensagemErroNifEntidade, setMensagemErroNifEntidade] = useState("");
  const [mensagemErroCertidaoEntidade, setMensagemErroCertidaoEntidade] =
    useState("");
  const [mensagemErroEmailEntidade, setMensagemErroEmailEntidade] =
    useState("");
  const [mensagemErroTelefoneEntidade, setMensagemErroTelefoneEntidade] =
    useState("");
  const [
    mensagemErroCodigoPostalEntidade,
    setMensagemErroCodigoPostalEntidade,
  ] = useState("");
  //Piscina
  const [mensagemErroNifPiscina, setMensagemErroNifPiscina] = useState("");
  const [mensagemErroEmailPiscina, setMensagemErroEmailPiscina] = useState("");
  const [mensagemErroTelefonePiscina, setMensagemErroTelefonePiscina] =
    useState("");
  const [mensagemErroTelefone2Piscina, setMensagemErroTelefone2Piscina] =
    useState("");
  const [mensagemErroCodigoPostalPiscina, setMensagemErroCodigoPostalPiscina] =
    useState("");
  const [mensagemErroLatitudePiscina, setMensagemErroLatitudePiscina] =
    useState("");
  const [mensagemErroLongitudePiscina, setMensagemErroLongitudePiscina] =
    useState("");
  //Links uteis
  const [mensagemErroLink1, setMensagemErroLink1] = useState("");
  const [mensagemErroLink2, setMensagemErroLink2] = useState("");
  const [mensagemErroLink3, setMensagemErroLink3] = useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const idU = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!idU || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getPiscinasId(id, {})
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          //Guardar copia
          setDadosOriginais(dados);
          // //Preencher dados
          // //Entidade
          setNomeEntidade(dados.nome_entidade_legal);
          setNifEntidade(dados.nif_entidade_legal);
          setEmailEntidade(dados.email_entidade_legal);
          setTelefoneEntidade(dados.telefone_entidade_legal);
          setRuaEntidade(dados.morada_entidade_legal);
          setCodigoPostalEntidade(dados.codigo_postal_entidade_legal);
          setCidadeEntidade(dados.cidade_entidade_legal);
          setDistritoEntidade(dados.distrito_entidade_legal);
          let paisEntidade = dados.pais_entidade_legal.split("-");
          setPaisEntidade({ value: paisEntidade[0], label: paisEntidade[1] });
          // //Piscina
          setNomePiscina(dados.nome);
          if (dados.termos_condicoes) {
            setTermos(dados.termos_condicoes);
          }
          setNifPiscina(dados.nif);
          let status = dados.status;
          if (status == 1) {
            setStatusPiscina(true);
          } else {
            setStatusPiscina(false);
          }
          setEmailPiscina(dados.email);
          setTelefonePiscina(dados.contacto1);
          setTelefone2Piscina(dados.contacto2);
          setRuaPiscina(dados.morada);
          setCodigoPostalPiscina(dados.codigo_postal);
          setCidadePiscina(dados.cidade);
          setDistritoPiscina(dados.distrito);
          let paisPiscina = dados.pais.split("-");
          setPaisPiscina({ value: paisPiscina[0], label: paisPiscina[1] });
          setLatitudePiscina(dados.latitude);
          setLongitudePiscina(dados.longitude);
          if (dados.foto_perfil_piscina) {
            setFotoPiscina(dados.foto_perfil_piscina);
          }

          dados.galeria.forEach((element) => {
            if (element.ordem == 1) {
              setFoto1(element.foto);
              setIdFoto1(element.id_imagem);
            }
            if (element.ordem == 2) {
              setFoto2(element.foto);
              setIdFoto2(element.id_imagem);
            }
            if (element.ordem == 3) {
              setFoto3(element.foto);
              setIdFoto3(element.id_imagem);
            }
            if (element.ordem == 4) {
              setFoto4(element.foto);
              setIdFoto4(element.id_imagem);
            }
            if (element.ordem == 5) {
              setFoto5(element.foto);
              setIdFoto5(element.id_imagem);
            }
            if (element.ordem == 6) {
              setFoto6(element.foto);
              setIdFoto6(element.id_imagem);
            }
          });
          // //Contrato
          // setContrato2(dados.contrato);
          // //Horarios
          if (dados.horarios) {
            setHorario2(dados.horarios);
          }
          // //Links uteis
          if (dados.link1) {
            setLink1(dados.link1);
          }
          if (dados.link2) {
            setLink2(dados.link2);
          }
          if (dados.link2) {
            setLink3(dados.link3);
          }
          if (dados.obs) {
            setMotivo(dados.obs);
          }
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      disabled={edicao}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  function validacao() {
    if (
      nomeEntidade &&
      nifEntidade &&
      certidaoEntidade &&
      emailEntidade &&
      telefoneEntidade &&
      ruaEntidade &&
      codigoPostalEntidade &&
      cidadeEntidade &&
      distritoEntidade &&
      paisEntidade &&
      nomePiscina &&
      nifPiscina &&
      emailPiscina &&
      telefonePiscina &&
      ruaPiscina &&
      codigoPostalPiscina &&
      cidadePiscina &&
      distritoPiscina &&
      paisPiscina &&
      latitudePiscina &&
      longitudePiscina &&
      fotoPiscina.length > 0 &&
      contrato &&
      termos
    ) {
      let confirmarDadosPreenchidos = true;

      if (nifEntidade.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifEntidade(true);
        setMensagemErroNifEntidade(t("validacaoNif"));
      }
      if (certidaoEntidade.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCertidaoEntidade(true);
        setMensagemErroCertidaoEntidade(t("validacaoCertificacao"));
      }

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailEntidade
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailEntidade(true);
        setMensagemErroEmailEntidade(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneEntidade)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneEntidade(true);
        setMensagemErroTelefoneEntidade(t("validacaoTelemovel"));
      }
      if (codigoPostalEntidade.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalEntidade(true);
        setMensagemErroCodigoPostalEntidade(t("validacaoCodigoPostal"));
      }
      if (nifPiscina.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifPiscina(true);
        setMensagemErroNifPiscina(t("validacaoNif"));
      }
      let validacaoEmail2 =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailPiscina
        );
      if (!validacaoEmail2) {
        confirmarDadosPreenchidos = false;
        setErroEmailPiscina(true);
        setMensagemErroEmailPiscina(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefonePiscina)) {
        confirmarDadosPreenchidos = false;
        setErroTelefonePiscina(true);
        setMensagemErroTelefonePiscina(t("validacaoTelemovel"));
      }
      if (!isValidPhoneNumber(telefone2Piscina) && telefone2Piscina) {
        confirmarDadosPreenchidos = false;
        setErroTelefone2Piscina(true);
        setMensagemErroTelefone2Piscina(t("validacaoTelemovel"));
      }
      if (codigoPostalPiscina.includes("_")) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalPiscina(true);
        setMensagemErroCodigoPostalPiscina(t("validacaoCodigoPostal"));
      }
      const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
      if (!regexLat.test(latitudePiscina)) {
        confirmarDadosPreenchidos = false;
        setErroLatitudePiscina(true);
        setMensagemErroLatitudePiscina(t("validacaoLatitude"));
      }
      const regexLon =
        /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
      if (!regexLon.test(longitudePiscina)) {
        confirmarDadosPreenchidos = false;
        setErroLongitudePiscina(true);
        setMensagemErroLongitudePiscina(t("validacaoLongitude"));
      }
      // Links úteis
      var validURL = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (link1) {
        if (!validURL.test(link1)) {
          confirmarDadosPreenchidos = false;
          setErroLink1(true);
          setMensagemErroLink1("Link inválido!");
        }
      }
      if (link2) {
        if (!validURL.test(link2)) {
          confirmarDadosPreenchidos = false;
          setErroLink2(true);
          setMensagemErroLink2("Link inválido!");
        }
      }
      if (link3) {
        if (!validURL.test(link3)) {
          confirmarDadosPreenchidos = false;
          setErroLink3(true);
          setMensagemErroLink3("Link inválido!");
        }
      }
      if (!termos) {
        setErroTermos(true);
      }
      if (confirmarDadosPreenchidos) {
        setFreeze(true);
        putPiscina({
          piscina: {
            id: id,
            nome: nomePiscina,
            nif: nifPiscina,
            email: emailPiscina,
            contacto1: telefonePiscina,
            contacto2: telefone2Piscina,
            morada: ruaPiscina,
            codigo_postal: codigoPostalPiscina,
            cidade: cidadePiscina,
            distrito: distritoPiscina,
            pais: paisPiscina.value + "-" + paisPiscina.label,
            latitude: latitudePiscina,
            longitude: longitudePiscina,
            foto_perfil: fotoPiscina,
            contrato: contrato2,
            foto1: foto1.length == 0 ? null : foto1,
            id_foto1: idFoto1,
            foto2: foto2.length == 0 ? null : foto2,
            id_foto2: idFoto2,
            foto3: foto3.length == 0 ? null : foto3,
            id_foto3: idFoto3,
            foto4: foto4.length == 0 ? null : foto4,
            id_foto4: idFoto4,
            foto5: foto5.length == 0 ? null : foto5,
            id_foto5: idFoto5,
            foto6: foto6.length == 0 ? null : foto6,
            id_foto6: idFoto6,
          },
          aulas_adultos: natacaoAdultos ? 1 : 0,
          aulas_bebes: natacaoBebes ? 1 : 0,
          aulas_idoso: natacaoIdosos ? 1 : 0,
          hidroginastica: hidroginastica ? 1 : 0,
          escola_natacao: escolaNatacao ? 1 : 0,
          hidrobike: hidrobike ? 1 : 0,
          aulas_gravidas: natacaoGravidas ? 1 : 0,
          hidro_terapia: hidroterapia ? 1 : 0,
          nado_livre: nadoLivre ? 1 : 0,
          equipas_federadas: equipasFederadas ? 1 : 0,
          hidropower: hidropower ? 1 : 0,
          horario: horario.length == 0 ? null : horario,
          link1: link1 ? link1 : null,
          link2: link2 ? link2 : null,
          link3: link3 ? link3 : null,
          termos_condicoes: termos,
        })
          .then((res) => {
            if (res.success) {
              setFreeze(false);
              setEdicao(!edicao);
              //Scroll para o início da página
              window.scrollTo(0, 0);

              toast.success(t("dadosEditadosSucesso"));
              document.getElementsByClassName(
                "cardOpcoesPiscina1_1"
              )[0].style.cursor = "pointer";
            } else {
              if (res.status == 500) {
                setFreeze(false);
                navigate("/500");
              }
              setFreeze(false);
              toast.error(res.error);
            }
          })
          .catch((error) => {
            setFreeze(false);
            //toast.warn(t("erroWebservice"));
          });
      } else {
        setFreeze(false);
        scrollToDiv2();
      }
    } else {
      setFreeze(false);
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----ENTIDADE----
      //Validação do nome
      if (!nomeEntidade) {
        setErroNomeEntidade(true);
      }
      //Validação do nif
      if (!nifEntidade) {
        setErroNifEntidade(true);
      } else {
        if (nifEntidade.length < 9) {
          setErroNifEntidade(true);
          setMensagemErroNifEntidade(t("validacaoNif"));
        }
      }
      //Validação da certidão
      if (!certidaoEntidade) {
        setErroCertidaoEntidade(true);
      } else {
        if (certidaoEntidade < 14) {
          setErroCertidaoEntidade(true);
          setMensagemErroCertidaoEntidade(t("validacaoCertificacao"));
        }
      }
      //Validação do email
      if (!emailEntidade) {
        setErroEmailEntidade(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailEntidade
          );
        if (!validacaoEmail) {
          setErroEmailEntidade(true);
          setMensagemErroEmailEntidade(t("emailInvalido"));
        }
      }
      //Validação do telefone
      if (!telefoneEntidade) {
        setErroTelefoneEntidade(true);
      } else {
        if (!isValidPhoneNumber(telefoneEntidade)) {
          setErroTelefoneEntidade(true);
          setMensagemErroTelefoneEntidade(t("validacaoTelemovel"));
        }
      }
      //Validação da rua
      if (!ruaEntidade) {
        setErroRuaEntidade(true);
      }
      //Validação do código postal
      if (!codigoPostalEntidade) {
        setErroCodigoPostalEntidade(true);
      } else {
        if (codigoPostalEntidade < 7) {
          setErroCodigoPostalEntidade(true);
          setMensagemErroCodigoPostalEntidade(t("validacaoCodigoPostal"));
        }
      }
      //Validação da cidade
      if (!cidadeEntidade) {
        setErroCidadeEntidade(true);
      }
      //Validação do distrito
      if (!distritoEntidade) {
        setErroDistritoEntidade(true);
      }
      //Validação do pais
      if (!paisEntidade) {
        setErroPaisEntidade(true);
      }
      // ----PISCINA----
      //Validação do nome
      if (!nomePiscina) {
        setErroNomePiscina(true);
      }
      // Validação do nif
      if (!nifPiscina) {
        setErroNifPiscina(true);
      } else {
        if (nifPiscina.length < 9) {
          setErroNifPiscina(true);
          setMensagemErroNifPiscina(t("validacaoNif"));
        }
      }
      //Validação do email
      if (!emailPiscina) {
        setErroEmailPiscina(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailPiscina
          );
        if (!validacaoEmail) {
          setErroEmailPiscina(true);
          setMensagemErroEmailPiscina(t("emailInvalido"));
        }
      }
      //Validação telemóvel
      if (!telefonePiscina) {
        setErroTelefonePiscina(true);
      } else {
        if (!isValidPhoneNumber(telefonePiscina)) {
          setErroTelefonePiscina(true);
          setMensagemErroTelefonePiscina(t("validacaoTelemovel"));
        }
      }
      //Validação telemóvel 2
      if (telefone2Piscina) {
        if (!isValidPhoneNumber(telefone2Piscina)) {
          setErroTelefone2Piscina(true);
          setMensagemErroTelefone2Piscina(t("validacaoTelemovel"));
        }
      }
      //Validação da rua
      if (!ruaPiscina) {
        setErroRuaPiscina(true);
      }
      //Validação do código postal
      if (!codigoPostalPiscina) {
        setErroCodigoPostalPiscina(true);
      } else {
        if (codigoPostalPiscina < 7) {
          setErroCodigoPostalPiscina(true);
          setMensagemErroCodigoPostalPiscina(t("validacaoCodigoPostal"));
        }
      }
      //Validação da cidade
      if (!cidadePiscina) {
        setErroCidadePiscina(true);
      }
      //Validação do distrito
      if (!distritoPiscina) {
        setErroDistritoPiscina(true);
      }
      //Validação do pais
      if (!paisPiscina) {
        setErroPaisPiscina(true);
      }
      //Validação da latitude
      if (!latitudePiscina) {
        setErroLatitudePiscina(true);
      } else {
        const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
        if (!regexLat.test(latitudePiscina)) {
          setErroLatitudePiscina(true);
          setMensagemErroLatitudePiscina(t("validacaoLatitude"));
        }
      }
      //Validação da longitude
      if (!longitudePiscina) {
        setErroLongitudePiscina(true);
      } else {
        const regexLon =
          /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
        if (!regexLon.test(longitudePiscina)) {
          setErroLongitudePiscina(true);
          setMensagemErroLongitudePiscina(t("validacaoLongitude"));
        }
      }
      //Validação da foto da piscina
      if (fotoPiscina.length == 0) {
        document.getElementById("fotoPiscina").style.borderColor =
          Colors.inativo;
        document.getElementById("tituloFotoPiscina").style.color =
          Colors.inativo;
      }
      // ----CONTRATO----
      if (contrato.length == 0) {
        document.getElementById("contratoLabel").style.borderColor =
          Colors.inativo;
        document.getElementById("contrato").style.color = Colors.inativo;
      }

      // Links úteis
      var validURL = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (link1) {
        if (!validURL.test(link1)) {
          setErroLink1(true);
          setMensagemErroLink1("Link inválido!");
        }
      }
      if (link2) {
        if (!validURL.test(link2)) {
          setErroLink2(true);
          setMensagemErroLink2("Link inválido!");
        }
      }
      if (link3) {
        if (!validURL.test(link3)) {
          setErroLink3(true);
          setMensagemErroLink3("Link inválido!");
        }
      }
      if (!termos) {
        setErroTermos(true);
      }
    }
  }

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
      "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    //Entidade
    setNomeEntidade(dadosOriginais.nome_entidade_legal);
    setNifEntidade(dadosOriginais.nif_entidade_legal);
    setCertidaoEntidade(dadosOriginais.certidao_entidade_legal);
    setEmailEntidade(dadosOriginais.email_entidade_legal);
    setTelefoneEntidade(dadosOriginais.telefone_entidade_legal);
    setRuaEntidade(dadosOriginais.morada_entidade_legal);
    setCodigoPostalEntidade(dadosOriginais.codigo_postal_entidade_legal);
    setCidadeEntidade(dadosOriginais.cidade_entidade_legal);
    setDistritoEntidade(dadosOriginais.distrito_entidade_legal);
    let paisEntidade = dadosOriginais.pais_entidade_legal.split("-");
    setPaisEntidade({ value: paisEntidade[0], label: paisEntidade[1] });
    //Piscina
    setNomePiscina(dadosOriginais.nome);
    setNifPiscina(dadosOriginais.nif);
    let status = dadosOriginais.status;
    if (status == 1) {
      setStatusPiscina(true);
    }
    setEmailPiscina(dadosOriginais.email);
    setTelefonePiscina(dadosOriginais.contacto1);
    setTelefone2Piscina(dadosOriginais.contacto2);
    setRuaPiscina(dadosOriginais.morada);
    setCodigoPostalPiscina(dadosOriginais.codigo_postal);
    setCidadePiscina(dadosOriginais.cidade);
    setDistritoPiscina(dadosOriginais.distrito);
    let paisPiscina = dadosOriginais.pais.split("-");
    setPaisPiscina({ value: paisPiscina[0], label: paisPiscina[1] });
    setLatitudePiscina(dadosOriginais.latitude);
    setLongitudePiscina(dadosOriginais.longitude);
    setFotoPiscina(dadosOriginais.foto_perfil_piscina);
    dadosOriginais.galeria.forEach((element) => {
      if (element.ordem == 1) {
        setFoto1(element.caminho);
        setIdFoto1(element.id_imagem);
      }
      if (element.ordem == 2) {
        setFoto2(element.caminho);
        setIdFoto2(element.id_imagem);
      }
      if (element.ordem == 3) {
        setFoto3(element.caminho);
        setIdFoto3(element.id_imagem);
      }
      if (element.ordem == 4) {
        setFoto4(element.caminho);
        setIdFoto4(element.id_imagem);
      }
      if (element.ordem == 5) {
        setFoto5(element.caminho);
        setIdFoto5(element.id_imagem);
      }
      if (element.ordem == 6) {
        setFoto6(element.caminho);
        setIdFoto6(element.id_imagem);
      }
    });
    //Contrato
    setContrato2(dadosOriginais.contrato);
    //Modalidades
    let aulas_adultos = dadosOriginais.aulas_adultos == 1 ? true : false;
    let aulas_bebes = dadosOriginais.aulas_bebes == 1 ? true : false;
    let aulas_idosos = dadosOriginais.aulas_idoso == 1 ? true : false;
    let hidroginastica = dadosOriginais.hidroginastica == 1 ? true : false;
    let escola_natacao = dadosOriginais.escola_natacao == 1 ? true : false;
    let hidrobike = dadosOriginais.hidrobike == 1 ? true : false;
    let aulas_gravidas = dadosOriginais.aulas_gravidas == 1 ? true : false;
    let hidro_terapia = dadosOriginais.hidro_terapia == 1 ? true : false;
    let nado_livre = dadosOriginais.nado_livre == 1 ? true : false;
    let equipas_federadas =
      dadosOriginais.equipas_federadas == 1 ? true : false;
    let hidropower = dadosOriginais.hidropower == 1 ? true : false;
    setNatacaoAdultos(aulas_adultos);
    setNatacaoBebes(aulas_bebes);
    setNatacaoIdosos(aulas_idosos);
    setHidroginastica(hidroginastica);
    setEscolaNatacao(escola_natacao);
    setHidrobike(hidrobike);
    setNatacaoGravidas(aulas_gravidas);
    setHidroterapia(hidro_terapia);
    setNadoLivre(nado_livre);
    setEquipasFederadas(equipas_federadas);
    setHidropower(hidropower);
    //Horarios
    if (dadosOriginais.horarios) {
      setHorario2(dadosOriginais.horarios);
    }
    //Links uteis
    if (dadosOriginais.link1) {
      setLink1(dadosOriginais.link1);
    }
    if (dadosOriginais.link2) {
      setLink2(dadosOriginais.link2);
    }
    if (dadosOriginais.link2) {
      setLink3(dadosOriginais.link3);
    }
    //Limpar erros e mensagens de erro
    setErroNomeEntidade(false);
    setErroNifEntidade(false);
    setErroCertidaoEntidade(false);
    setErroEmailEntidade(false);
    setErroTelefoneEntidade(false);
    setErroRuaEntidade(false);
    setErroCodigoPostalEntidade(false);
    setErroCidadeEntidade(false);
    setErroDistritoEntidade(false);
    setErroPaisEntidade(false);
    setErroNomePiscina(false);
    setErroNifPiscina(false);
    setErroEmailPiscina(false);
    setErroTelefonePiscina(false);
    setErroTelefone2Piscina(false);
    setErroRuaPiscina(false);
    setErroCodigoPostalPiscina(false);
    setErroCidadePiscina(false);
    setErroDistritoPiscina(false);
    setErroPaisPiscina(false);
    setErroLatitudePiscina(false);
    setErroLongitudePiscina(false);
    document.getElementById("fotoPiscina").style.borderColor = Colors.ativo;
    document.getElementById("tituloFotoPiscina").style.color =
      Colors.textFields;
    document.getElementById("galeriaPiscina").style.borderColor =
      Colors.textFields;
    setErroLink1(false);
    setErroLink2(false);
    setErroLink3(false);
    setMensagemErroNifEntidade("");
    setMensagemErroCertidaoEntidade("");
    setMensagemErroEmailEntidade("");
    setMensagemErroTelefoneEntidade("");
    setMensagemErroCodigoPostalEntidade("");
    setMensagemErroNifPiscina("");
    setMensagemErroEmailPiscina("");
    setMensagemErroTelefonePiscina("");
    setMensagemErroTelefone2Piscina("");
    setMensagemErroCodigoPostalPiscina("");
    setMensagemErroLatitudePiscina("");
    setMensagemErroLongitudePiscina("");
    setMensagemErroLink1("");
    setMensagemErroLink2("");
    setMensagemErroLink3("");
    window.scrollTo(0, 0);
  }

  function eliminarPiscina() {
    if (motivoEliminacao) {
      setEliminar(false);
      setMotivoEliminacao("");
      setFreeze(true);
      postPedidos({ id: id, acao: 2, motivo: motivoEliminacao, tipo: 1 })
        .then((res) => {
          if (res.success) {
            setTimeout(async function () {
              enviarEmailNovoPedido().then((res2) => {
                if (res2.success) {
                  setFreeze(false);
                  toast.success(res.data.msg);
                } else {
                  setFreeze(false);
                  toast.error(res.error);
                }
              });
            }, 3000);
          } else {
            setFreeze(false);
            if (res.status == 500) {
              setFreeze(false);
              navigate("/500");
            } else if (res.status == 406) {
              setFreeze(false);
              toast.info(res.error);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setFreeze(false);
      setErroMotivoEliminacao(true);
    }
  }

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm2")[0].style.marginTop =
        "40px";
      document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
        "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm2")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function suspenderPiscina() {
    if (motivoSuspensao) {
      setSuspender(false);
      setMotivoSuspensao("");
      setFreeze(true);
      postPedidos({
        id: id,
        acao: statusPiscina ? 1 : 3,
        motivo: motivoSuspensao,
        tipo: 1,
      })
        .then((res) => {
          if (res.success) {
            setTimeout(async function () {
              enviarEmailNovoPedido().then((res2) => {
                if (res2.success) {
                  setFreeze(false);
                  toast.success(res.data.msg);
                } else {
                  setFreeze(false);
                  toast.error(res.error);
                }
              });
            }, 3000);
          } else {
            setFreeze(false);
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 406) {
              setFreeze(false);
              toast.info(res.error);
            } else {
              setFreeze(false);
              toast.error(res.error);
            }
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setFreeze(false);
      setErroMotivoSuspensao(true);
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm2" style={{ marginTop: "10px" }}>
            <div className="align-left">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/pools");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>
            {/* <div className="align-right">
              <h3 className="titleList">{t("editarPiscina")}</h3>
            </div> */}
          </div>

          <div>
            <div className="formPage3">
              {/* Cards */}
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    <Button
                      className="cardOpcoesPiscina4"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card4
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/administrators", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("admin")}
                    </Button>
                    <Button
                      className="cardOpcoesPiscina5"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card5
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/employees", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("funcionario")}
                    </Button>
                    <Button
                      className="cardOpcoesPiscina6"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card6
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/teachers", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("professores")}
                    </Button>
                    <Button
                      className="cardOpcoesPiscina7"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card7
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/customers", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("clientes")}
                    </Button>
                  </div>
                  <div className="cardsDivPiscina2">
                    {statusPiscina ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}

                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    {statusPiscina ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina4"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card4
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/administrators", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("admin")}
                    </Button>
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina5"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card5
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/employees", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("funcionario")}
                    </Button>
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina6"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card6
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/teachers", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("professores")}
                    </Button>
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina7"
                      variant="contained"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card7
                          : Colors.cardInativo,
                      }}
                      onClick={() => {
                        navigate("/customers", {
                          state: { idPiscina: id, nomePiscina: nomePiscina },
                        });
                      }}
                    >
                      {t("clientes")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {motivo && (
              <div className="formPageSuspensao">
                <Alert severity="error">
                  {statusPiscina ? t("piscinaAtiva") : t("piscinaSuspensa")} |{" "}
                  {t("motivo")}: {motivo}
                </Alert>
              </div>
            )}
            <div className="formPage">
              <Box className="formTextField">
                {/* Entidade Legal */}
                <h5 className="h5List">{t("entidadeLegal")}</h5>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={true}
                      variant="standard"
                      label={t("nome")}
                      value={nomeEntidade}
                      error={erroNomeEntidade}
                      onChange={(e) => {
                        setNomeEntidade(e.target.value);
                        setErroNomeEntidade(false);
                      }}
                    />
                  </FormControl>
                  {/* NIF */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="number"
                      disabled={true}
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nifEntidade}
                      error={erroNifEntidade}
                      helperText={mensagemErroNifEntidade}
                      onChange={(e) => {
                        setNifEntidade(e.target.value);
                        setErroNifEntidade(false);
                      }}
                    />
                  </FormControl>
                  {/* Certidão */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      required
                      type="text"
                      {...{
                        label: t("certidao"),
                        variant: "standard",
                      }}
                      value={certidaoEntidade}
                      customInput={TextField}
                      format="####-####-####"
                      disabled={true}
                      error={erroCertidaoEntidade}
                      helperText={mensagemErroCertidaoEntidade}
                      onChange={(e) => {
                        setCertidaoEntidade(e.target.value);
                        setErroCertidaoEntidade(false);
                        setMensagemErroCertidaoEntidade("");
                      }}
                    />
                  </FormControl>
                  {/* Email */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={true}
                      variant="standard"
                      label={t("email")}
                      value={emailEntidade}
                      error={erroEmailEntidade}
                      helperText={mensagemErroEmailEntidade}
                      onChange={(e) => {
                        setEmailEntidade(e.target.value.toLocaleLowerCase());
                        setErroEmailEntidade(false);
                        setMensagemErroEmailEntidade("");
                      }}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={true}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefoneEntidade}
                      error={erroTelefoneEntidade}
                      helperText={mensagemErroTelefoneEntidade}
                      onChange={(e) => {
                        setTelefoneEntidade(e);
                        setErroTelefoneEntidade(false);
                        setMensagemErroTelefoneEntidade("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={true}
                      variant="standard"
                      label={t("rua")}
                      value={ruaEntidade}
                      error={erroRuaEntidade}
                      onChange={(e) => {
                        setRuaEntidade(e.target.value);
                        setErroRuaEntidade(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      type="text"
                      disabled={true}
                      variant="standard"
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      required
                      format="####-###"
                      error={erroCodigoPostalEntidade}
                      helperText={mensagemErroCodigoPostalEntidade}
                      onChange={(e) => {
                        setCodigoPostalEntidade(e.target.value);
                        setErroCodigoPostalEntidade(false);
                        setMensagemErroCodigoPostalEntidade("");
                      }}
                    />
                  </FormControl>
                  {/* Cidade */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={true}
                      variant="standard"
                      label={t("cidade")}
                      value={cidadeEntidade}
                      error={erroCidadeEntidade}
                      onChange={(e) => {
                        setCidadeEntidade(e.target.value);
                        setErroCidadeEntidade(false);
                      }}
                    />
                  </FormControl>
                  {/* Distrito */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={true}
                      variant="standard"
                      label={t("distrito")}
                      value={distritoEntidade}
                      error={erroDistritoEntidade}
                      onChange={(e) => {
                        setDistritoEntidade(e.target.value);
                        setErroDistritoEntidade(false);
                      }}
                    />
                  </FormControl>
                  {/* País */}
                  <FormControl className="formTextField3" fullWidth ref={ref}>
                    <Autocomplete
                      label={t("pais")}
                      variant="standard"
                      options={options}
                      value={paisEntidade}
                      onChange={(event, newValue) => {
                        setPaisEntidade(newValue.label);
                        setErroPaisEntidade(false);
                      }}
                      disabled={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("pais")}
                          variant="standard"
                          error={erroPaisEntidade}
                          disabled={true}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
            <div className="formPage2">
              <Box className="formTextField">
                {/* Piscina */}
                <h5 className="h5List">{t("piscina")}</h5>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={edicao}
                      variant="standard"
                      label={t("nome")}
                      value={nomePiscina}
                      error={erroNomePiscina}
                      onChange={(e) => {
                        setNomePiscina(e.target.value);
                        setErroNomePiscina(false);
                      }}
                    />
                  </FormControl>
                  {/* NIF */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="number"
                      disabled={edicao}
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nifPiscina}
                      error={erroNifPiscina}
                      helperText={mensagemErroNifPiscina}
                      onChange={(e) => {
                        setNifPiscina(e.target.value);
                        setErroNifPiscina(false);
                        setMensagemErroNifPiscina("");
                      }}
                    />
                  </FormControl>
                  {/* Email */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={edicao}
                      variant="standard"
                      label={t("email")}
                      value={emailPiscina}
                      error={erroEmailPiscina}
                      helperText={mensagemErroEmailPiscina}
                      onChange={(e) => {
                        setEmailPiscina(e.target.value.toLocaleLowerCase());
                        setErroEmailPiscina(false);
                        setMensagemErroEmailPiscina("");
                      }}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={edicao}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefonePiscina}
                      error={erroTelefonePiscina}
                      helperText={mensagemErroTelefonePiscina}
                      onChange={(e) => {
                        setTelefonePiscina(e);
                        setErroTelefonePiscina(false);
                        setMensagemErroTelefonePiscina("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={edicao}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefone2Piscina}
                      error={erroTelefone2Piscina}
                      helperText={mensagemErroTelefone2Piscina}
                      onChange={(e) => {
                        setTelefone2Piscina(e);
                        setErroTelefone2Piscina(false);
                        setMensagemErroTelefone2Piscina("");
                      }}
                      inputComponent={PhoneNumber2}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={edicao}
                      variant="standard"
                      label={t("rua")}
                      value={ruaPiscina}
                      error={erroRuaPiscina}
                      onChange={(e) => {
                        setRuaPiscina(e.target.value);
                        setErroRuaPiscina(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      type="text"
                      disabled={edicao}
                      variant="standard"
                      value={codigoPostalPiscina}
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      required
                      format="####-###"
                      error={erroCodigoPostalPiscina}
                      helperText={mensagemErroCodigoPostalPiscina}
                      onChange={(e) => {
                        setCodigoPostalPiscina(e.target.value);
                        setErroCodigoPostalPiscina(false);
                        setMensagemErroCodigoPostalPiscina("");
                      }}
                    />
                  </FormControl>
                  {/* Cidade */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={edicao}
                      variant="standard"
                      label={t("cidade")}
                      value={cidadePiscina}
                      error={erroCidadePiscina}
                      onChange={(e) => {
                        setCidadePiscina(e.target.value);
                        setErroCidadePiscina(false);
                      }}
                    />
                  </FormControl>
                  {/* Distrito */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      type="text"
                      disabled={edicao}
                      variant="standard"
                      label={t("distrito")}
                      value={distritoPiscina}
                      error={erroDistritoPiscina}
                      onChange={(e) => {
                        setDistritoPiscina(e.target.value);
                        setErroDistritoPiscina(false);
                      }}
                    />
                  </FormControl>
                  {/* País */}
                  <FormControl className="formTextField3" fullWidth>
                    <Autocomplete
                      label={t("pais")}
                      variant="standard"
                      options={options}
                      value={paisPiscina}
                      disabled={edicao}
                      onChange={(event, newValue) => {
                        setPaisPiscina(newValue.label);
                        setErroPaisPiscina(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("pais")}
                          variant="standard"
                          error={erroPaisPiscina}
                          disabled={edicao}
                          fullWidth
                          required
                        />
                      )}
                    />
                  </FormControl>
                  {/* Latitude */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      required
                      disabled={edicao}
                      value={latitudePiscina}
                      label={t("latitude")}
                      variant="standard"
                      options={options}
                      error={erroLatitudePiscina}
                      helperText={mensagemErroLatitudePiscina}
                      onChange={(e) => {
                        setLatitudePiscina(e.target.value);
                        setErroLatitudePiscina(false);
                        setMensagemErroLatitudePiscina("");
                      }}
                    />
                  </FormControl>
                  {/* Longitude */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      label={t("longitude")}
                      value={longitudePiscina}
                      variant="standard"
                      options={options}
                      error={erroLongitudePiscina}
                      helperText={mensagemErroLongitudePiscina}
                      onChange={(e) => {
                        setLongitudePiscina(e.target.value);
                        setErroLongitudePiscina(false);
                        setMensagemErroLongitudePiscina("");
                      }}
                    />
                  </FormControl>
                  {/* Foto */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")} *
                    </p>
                    <label
                      id="fotoPiscina"
                      htmlFor="file-upload"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />{" "}
                      {t("carregarImagem")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        document.getElementById(
                          "fotoPiscina"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById(
                          "tituloFotoPiscina"
                        ).style.color = Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setFotoPiscina(reader.result);
                        };
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {fotoPiscina.length > 0 && (
                        <img
                          style={{ marginTop: 10,objectFit:"cover" }}
                          width={150}
                          height={150}
                          src={fotoPiscina}
                        />
                      )}
                    </div>
                  </FormControl>
                  {/* Galeria */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      style={{
                        marginTop: 10,
                        color: "#858585",
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                        marginBottom: 0,
                      }}
                    >
                      {t("galeria")} - {t("max6Fotos")}
                    </p>
                    <p
                      style={{
                        marginTop: 2,
                        color: "#858585",
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ padding: 0, marginRight: 5 }}
                        icon={faCircleInfo}
                      />
                      {t("tamanhoImagens")}
                    </p>
                    <label
                      id="galeriaPiscina"
                      htmlFor="file-upload2"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />{" "}
                      {t("carregarImagens")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload2"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={async (e) => {
                        document.getElementById(
                          "galeriaPiscina"
                        ).style.borderColor = Colors.ativo;
                        setFoto1([]);
                        setIdFoto1("");
                        setFoto2([]);
                        setIdFoto2("");
                        setFoto3([]);
                        setIdFoto3("");
                        setFoto4([]);
                        setIdFoto4("");
                        setFoto5([]);
                        setIdFoto5("");
                        setFoto6([]);
                        setIdFoto6("");

                        let imagem1 = e.target.files[0];
                        let imagem2 = e.target.files[1];
                        let imagem3 = e.target.files[2];
                        let imagem4 = e.target.files[3];
                        let imagem5 = e.target.files[4];
                        let imagem6 = e.target.files[5];
                        if (imagem1) {
                          const reader = new FileReader();

                          reader.readAsDataURL(imagem1);

                          reader.onload = () => {
                            setFoto1(reader.result);
                          };
                        }
                        if (imagem2) {
                          const reader = new FileReader();

                          reader.readAsDataURL(imagem2);

                          reader.onload = () => {
                            setFoto2(reader.result);
                          };
                        }
                        if (imagem3) {
                          const reader = new FileReader();

                          reader.readAsDataURL(imagem3);

                          reader.onload = () => {
                            setFoto3(reader.result);
                          };
                        }
                        if (imagem4) {
                          const reader = new FileReader();

                          reader.readAsDataURL(imagem4);

                          reader.onload = () => {
                            setFoto4(reader.result);
                          };
                        }
                        if (imagem5) {
                          const reader = new FileReader();

                          reader.readAsDataURL(imagem5);

                          reader.onload = () => {
                            setFoto5(reader.result);
                          };
                        }
                        if (imagem6) {
                          const reader = new FileReader();

                          reader.readAsDataURL(imagem6);

                          reader.onload = () => {
                            setFoto6(reader.result);
                          };
                        }
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {/* Foto 1 */}
                      {foto1.length > 0 && (
                        <img
                          style={{
                            marginTop: 10,
                            marginLeft: 5,
                            marginRight: 5,
                            objectFit:"cover"
                          }}
                          width={150}
                          height={150}
                          src={foto1}
                        />
                      )}

                      {/* Foto 2 */}
                      {foto2.length > 0 && (
                        <img
                          style={{
                            marginTop: 10,
                            marginLeft: 5,
                            marginRight: 5,objectFit:"cover"
                          }}
                          width={150}
                          height={150}
                          src={foto2}
                        />
                      )}

                      {/* Foto 3 */}
                      {foto3.length > 0 && (
                        <img
                          style={{
                            marginTop: 10,
                            marginLeft: 5,
                            marginRight: 5,objectFit:"cover"
                          }}
                          width={150}
                          height={150}
                          src={foto3}
                        />
                      )}

                      {/* Foto 4 */}
                      {foto4.length > 0 && (
                        <img
                          style={{
                            marginTop: 10,
                            marginLeft: 5,
                            marginRight: 5,objectFit:"cover"
                          }}
                          width={150}
                          height={150}
                          src={foto4}
                        />
                      )}

                      {/* Foto 5 */}
                      {foto5.length > 0 && (
                        <img
                          style={{
                            marginTop: 10,
                            marginLeft: 5,
                            marginRight: 5,objectFit:"cover"
                          }}
                          width={150}
                          height={150}
                          src={foto5}
                        />
                      )}

                      {/* Foto 6 */}
                      {foto6.length > 0 && (
                        <img
                          style={{
                            marginTop: 10,
                            marginLeft: 5,
                            marginRight: 5,objectFit:"cover"
                          }}
                          width={150}
                          height={150}
                          src={foto6}
                        />
                      )}
                    </div>
                  </FormControl>
                </div>
              </Box>
            </div>
            <div className="formPage2">
              <Box className="formTextField">
                {/* Modalidades */}
                <h5 className="h5List">{t("modalidades")}</h5>
                <div>
                  <FormControl className="formTextField3" fullWidth>
                    {/* Natação adultos */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("natacao_adultos")}
                      labelPlacement="start"
                      checked={natacaoAdultos}
                      onChange={() => {
                        setNatacaoAdultos(!natacaoAdultos);
                      }}
                    />
                    {/* Natação bebés */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("natacao_bebes")}
                      labelPlacement="start"
                      checked={natacaoBebes}
                      onChange={() => {
                        setNatacaoBebes(!natacaoBebes);
                      }}
                    />
                    {/* Natação Idosos */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("natacao_idosos")}
                      labelPlacement="start"
                      checked={natacaoIdosos}
                      onChange={() => {
                        setNatacaoIdosos(!natacaoIdosos);
                      }}
                    />
                    {/* Hidroginástica */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("hidroginastica")}
                      labelPlacement="start"
                      checked={hidroginastica}
                      onChange={() => {
                        setHidroginastica(!hidroginastica);
                      }}
                    />

                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("escolaNatacao")}
                      labelPlacement="start"
                      checked={escolaNatacao}
                      onChange={() => {
                        setEscolaNatacao(!escolaNatacao);
                      }}
                    />
                    {/* Hidrobike */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("hidrobike")}
                      labelPlacement="start"
                      checked={hidrobike}
                      onChange={() => {
                        setHidrobike(!hidrobike);
                      }}
                    />
                    {/* Natação grávidas */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("natacao_gravidas")}
                      labelPlacement="start"
                      checked={natacaoGravidas}
                      onChange={() => {
                        setNatacaoGravidas(!natacaoGravidas);
                      }}
                    />
                    {/* Hidroterapia */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("hidroterapia")}
                      labelPlacement="start"
                      checked={hidroterapia}
                      onChange={() => {
                        setHidroterapia(!hidroterapia);
                      }}
                    />
                    {/* Nado livre */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={t("nado_livre")}
                      labelPlacement="start"
                      checked={nadoLivre}
                      onChange={() => {
                        setNadoLivre(!nadoLivre);
                      }}
                    />
                    {/* Equipas federadas */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      checked={equipasFederadas}
                      label={t("equipas_federadas")}
                      labelPlacement="start"
                      onChange={() => {
                        setEquipasFederadas(!equipasFederadas);
                      }}
                    />
                    {/* Hidropower */}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} disabl />}
                      label={t("hidropower")}
                      checked={hidropower}
                      labelPlacement="start"
                      onChange={() => {
                        setHidropower(!hidropower);
                      }}
                    />
                  </FormControl>
                </div>
              </Box>
            </div>

            <div className="formPage2">
              <Box className="formTextField">
                {/* Termos e Condições */}
                <h5 className="h5List">{t("termoesCondicoes")}</h5>
                <div>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={20}
                      value={termos}
                      error={erroTermos}
                      onChange={(e) => {
                        setTermos(e.target.value);
                        setErroTermos(false);
                      }}
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
            {/* Horários */}
            <div className="formPage2">
              <Box className="formTextField">
                <h5 className="h5List">{t("horarios")}</h5>
                <div>
                  {/* Horário */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="horario"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("horarios")} (*pdf)
                    </p>
                    <label
                      id="horarioLabel"
                      htmlFor="file-upload5-h"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarHorarios")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload5-h"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="application/pdf"
                      onChange={async (e) => {
                        document.getElementById(
                          "horarioLabel"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById("horario").style.color =
                          Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setHorario(reader.result);
                          const pdfContentType = "application/pdf";
                          const base64toBlob = (data) => {
                            // Cut the prefix `data:application/pdf;base64` from the raw base 64
                            const base64WithoutPrefix = data.substr(
                              `data:${pdfContentType};base64,`.length
                            );

                            const bytes = atob(base64WithoutPrefix);
                            let length = bytes.length;
                            let out = new Uint8Array(length);

                            while (length--) {
                              out[length] = bytes.charCodeAt(length);
                            }

                            return new Blob([out], { type: pdfContentType });
                          };
                          const url = URL.createObjectURL(
                            base64toBlob(reader.result)
                          );
                          setHorario2(url);
                        };
                      }}
                    ></input>
                    {horario2.length > 0 && (
                      <div
                        style={{
                          borderWidth: "0.5px",
                          borderStyle: "solid",
                          borderColor: Colors.textFields,
                          height: "600px",
                          marginTop: 10,
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                          <Viewer fileUrl={horario2} />
                        </Worker>
                      </div>
                    )}
                  </FormControl>
                </div>
              </Box>
            </div>
            {/* Links úteis */}
            <div className="formPage2">
              <Box className="formTextField">
                <h5 className="h5List">{t("linksuteis")}</h5>
                <div>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      value={link1}
                      label={t("link") + " 1"}
                      variant="standard"
                      error={erroLink1}
                      helperText={mensagemErroLink1}
                      onChange={(e) => {
                        setLink1(e.target.value);
                        setErroLink1(false);
                        setMensagemErroLink1("");
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      value={link2}
                      label={t("link") + " 2"}
                      variant="standard"
                      error={erroLink2}
                      helperText={mensagemErroLink2}
                      onChange={(e) => {
                        setLink2(e.target.value);
                        setErroLink2(false);
                        setMensagemErroLink2("");
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      value={link3}
                      label={t("link") + " 3"}
                      variant="standard"
                      error={erroLink3}
                      helperText={mensagemErroLink3}
                      onChange={(e) => {
                        setLink3(e.target.value);
                        setErroLink3(false);
                        setMensagemErroLink3("");
                      }}
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
            <div className="formPage2">
              <Box className="formTextField">
                <h5 className="h5List">{t("contrato")}</h5>
                <div>
                  {/* Contrato */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="contrato"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("contrato")} * (*pdf)
                    </p>
                    <label
                      id="contratoLabel"
                      htmlFor="file-upload4"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarContrato")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload4"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="application/pdf"
                      onChange={async (e) => {
                        document.getElementById(
                          "contratoLabel"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById("contrato").style.color =
                          Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setContrato(reader.result);
                          const pdfContentType = "application/pdf";
                          const base64toBlob = (data) => {
                            // Cut the prefix `data:application/pdf;base64` from the raw base 64
                            const base64WithoutPrefix = data.substr(
                              `data:${pdfContentType};base64,`.length
                            );

                            const bytes = atob(base64WithoutPrefix);
                            let length = bytes.length;
                            let out = new Uint8Array(length);

                            while (length--) {
                              out[length] = bytes.charCodeAt(length);
                            }

                            return new Blob([out], { type: pdfContentType });
                          };
                          const url = URL.createObjectURL(
                            base64toBlob(reader.result)
                          );
                          setContrato2(url);
                        };
                      }}
                    ></input>
                    {contrato2.length > 0 && (
                      <div
                        style={{
                          borderWidth: "0.5px",
                          borderStyle: "solid",
                          borderColor: Colors.textFields,
                          height: "600px",
                          marginTop: 10,
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                          <Viewer fileUrl={contrato2} />
                        </Worker>
                      </div>
                    )}
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Eliminar piscina */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("pedidoEliminarPiscina")}
            </DialogTitle>
            <DialogContent>
              {t("justificacaoEliminacao")} *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoEliminacao}
                  error={erroMotivoEliminacao}
                  onChange={(e) => {
                    setMotivoEliminacao(e.target.value);
                    setErroMotivoEliminacao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              {" "}
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarPiscina();
                }}
                autoFocus
              >
                {t("fazerPedido")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Suspender Piscina */}
          <Dialog
            open={suspender}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {statusPiscina
                ? t("pedidoSuspenderPiscina")
                : t("pedidoAtivarPiscina")}
            </DialogTitle>
            <DialogContent>
              {statusPiscina
                ? t("justificacaoSuspencao")
                : t("justificacaoAtivacao")}{" "}
              *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoSuspensao}
                  error={erroMotivoSuspensao}
                  onChange={(e) => {
                    setMotivoSuspensao(e.target.value);
                    setErroMotivoSuspensao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupSuspender}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  suspenderPiscina();
                }}
                autoFocus
              >
                {t("fazerPedido")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: erWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarPiscina;
