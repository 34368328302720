import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Cookies, { set } from "js-cookie";
import { Scrollbar } from "react-scrollbars-custom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box, prepareCssVars } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  editarEspacos,
  eliminarEspacos,
  getEspacos,
} from "../../api/requests/OcupaEspaco";
import { parseISO, format } from "date-fns";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
  RadioGroup,
  Radio,
  Alert,
} from "@mui/material";
import Nav from "../../components/Nav";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import {
  adicionarEspacos,
  listaEspacos,
  listaTiposEspacos,
} from "../../api/requests/OcupaEspaco";
import Search1 from "../../components/DataTable/Search1";
import { listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import moment from "moment";
import { getDisponibilidade } from "../../api/requests/Marcacoes";

function ListaEspacos() {
  const [espacos, setEspacos] = useState([]);
  const [espacosCopy, setEspacosCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "pista", "categoria"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "pista", "categoria"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();

  const [openCriarEspaco, setOpenCriarEspaco] = useState(false);
  const handleCloseCriarEspaco = () => {
    setOpenCriarEspaco(false);
    limparVariaveis();
  };

  const handleClickOpenCriarEspaco = () => {
    setOpenCriarEspaco(true);
  };

  const [openEditarEspaco, setOpenEditarEspaco] = useState(false);
  const handleCloseEditarEspaco = () => setOpenEditarEspaco(false);

  const handleClickOpenEditarEspaco = () => {
    setOpenEditarEspaco(true);
  };

  const [freeze, setFreeze] = useState(false);
  const [edicao, setEdicao] = useState(true);

  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [hora_inicio, setHoraInicio] = useState("");
  const [hora_fim, setHoraFim] = useState("");
  const [pista, setPista] = useState(0);
  const [vagas, setVagas] = useState(0);
  const [tamanho_pista, setTamanhoPista] = useState(0);
  const [segunda, setSegunda] = useState(0);
  const [terca, setTerca] = useState(0);
  const [quarta, setQuarta] = useState(0);
  const [quinta, setQuinta] = useState(0);
  const [sexta, setSexta] = useState(0);
  const [sabado, setSabado] = useState(0);
  const [domingo, setDomingo] = useState(0);
  const [piscinas, setPiscinas] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [obs, setObs] = useState("");
  const [exibirCheckboxes, setExibirCheckboxes] = useState(false);
  const [exibirEscolhaPistas, setExibirEscolhaPistas] = useState(false);
  const [exibirMaisInfo, setExibirMaisInfo] = useState(false);
  const [selectedPool, setSelectedPool] = useState("");
  const [selectedTipo, setSelectedTipo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categorias, setCategoria] = useState([]);
  const [duracao, setDuracao] = useState(0);

  const [espaco_pista, setEspacoPista] = useState(1);
  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);
  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroHoraInicio, setErroHoraInicio] = useState(false);
  const [erroHoraFim, setErroHoraFim] = useState(false);
  const [erroPista, setErroPista] = useState(false);
  const [erroVagas, setErroVagas] = useState(false);
  const [erroTamanhoPista, setErroTamanhoPista] = useState(false);
  const [erroPiscina, setErroPiscina] = useState(false);
  const [erroObs, setErroObs] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroTipo, setErroTipo] = useState(false);
  const [maxPista, setMaxPista] = useState(0);

  const [pistasD, setPistasD] = useState([]);
  const [rows, setRows] = useState([{ pista: "", espaco_pista: "" }]);
  const [pistasDisponiveis, setPistasDisponiveis] = useState([]);

  const [checkboxes, setCheckboxes] = useState([]);

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
  };

  const handlePoolChange = (event) => {
    setSelectedPool(event.target.value);
    setErroPiscina(false);
  };

  useEffect(() => {
    if (data_inicio && data_fim) {
      if (data_inicio && data_fim && data_inicio === data_fim) {
        setExibirCheckboxes(false);
        setSegunda(false);
        setTerca(false);
        setQuarta(false);
        setQuinta(false);
        setSexta(false);
        setSabado(false);
        setDomingo(false);
      } else {
        setExibirCheckboxes(true);
      }
    }
  }, [data_inicio, data_fim]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleTipoChange = (event) => {
    setSelectedTipo(event.target.value);
    setErroTipo(false);
  };

  const handleChange2 = (event) => {
    switch (event.target.name) {
      case "segunda":
        setSegunda(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTerca(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuarta(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuinta(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSexta(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabado(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingo(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const novoEspacoPista = event.target.checked ? 1 : 2;
    setEspacoPista(novoEspacoPista);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEntidade);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
      
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaPiscinasAgua({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinas(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaTiposEspacos({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setTipos(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);
  useEffect(() => {
    if (exibirCheckboxes == false) {
      if (
        data_inicio &&
        data_fim &&
        (segunda != 0 ||
          terca != 0 ||
          quarta != 0 ||
          quinta != 0 ||
          sexta != 0 ||
          sabado != 0 ||
          domingo != 0) &&
        selectedPool != "" &&
        duracao != 0
      ) {
        let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
        let data_fimf = moment(data_fim).format("YYYY-MM-DD");
        let hora_iniciof = moment(hora_inicio).format("HH:mm:ss");
        let hora_fimf = moment(hora_fim).format("HH:mm:ss");
        getDisponibilidade({
          tipo: 1,
          duracao: duracao,
          id_piscina: idPiscina,
          data_inicio: data_iniciof,
          data_fim: data_fimf,
          hora_inicio: hora_iniciof,
          hora_fim: hora_fimf,
          id_piscina_agua: selectedPool,
          segunda: segunda == "" ? 0 : segunda,
          terca: terca == "" ? 0 : terca,
          quarta: quarta == "" ? 0 : quarta,
          quinta: quinta == "" ? 0 : quinta,
          sexta: sexta == "" ? 0 : sexta,
          sabado: sabado == "" ? 0 : sabado,
          domingo: domingo == "" ? 0 : segunda,
        }).then((res) => {
          if (res.success) {
            let arrayFinal = [];
            let dados = res.data.data;
            dados.forEach((element) => {
              const linha = {
                id: element.id,
                nome: element.name,
                ocupacao: element.ocupacao,
              };
              arrayFinal.push(linha);
              setPistasD(arrayFinal);
            });
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        });
        setExibirEscolhaPistas(true);
      } else {
        setExibirEscolhaPistas(false);
      }
    } else {
      let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
      let data_fimf = moment(data_fim).format("YYYY-MM-DD");
      let hora_iniciof = moment(hora_inicio).format("HH:mm:ss");
      let hora_fimf = moment(hora_fim).format("HH:mm:ss");

      if (
        data_inicio &&
        data_fim &&
        (segunda != 0 ||
          terca != 0 ||
          quarta != 0 ||
          quinta != 0 ||
          sexta != 0 ||
          sabado != 0 ||
          domingo != 0) &&
        selectedPool != "" &&
        duracao != 0
      ) {
        getDisponibilidade({
          id_piscina: idPiscina,
          data_inicio: data_iniciof,
          data_fim: data_fimf,
          hora_inicio: hora_iniciof,
          hora_fim: hora_fimf,
          id_piscina_agua: selectedPool,
          tipo: 1,
          duracao: duracao,
          segunda: segunda,
          terca: terca,
          quarta: quarta,
          quinta: quinta,
          sexta: sexta,
          sabado: sabado,
          domingo: domingo,
        }).then((res) => {
          if (res.success) {
            let arrayFinal = [];
            let dados = res.data.data;
            dados.forEach((element) => {
              const linha = {
                id: element.id,
                nome: element.name,
                ocupacao: element.ocupacao,
              };
              arrayFinal.push(linha);
              setPistasD(arrayFinal);
            });
          } else {
            if (res.status == 500) {
              navigate("/500");
            } else if (res.status == 403) {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              } else if (utilizador == "admin") {
                navigate("/dashboard_admin");
              } else if (utilizador == "funcionario") {
                navigate("/dashboard_employee");
              } else if (utilizador == "professor") {
                navigate("/dashboard_teacher");
              } else if (utilizador == "cliente") {
                navigate("/dashboard_customer");
              }
            }
            toast.error(res.error);
          }
        });
        setExibirEscolhaPistas(true);
      } else {
        setExibirEscolhaPistas(false);
      }
    }
  }, [
    data_inicio,
    data_fim,
    selectedPool,
    segunda,
    terca,
    quarta,
    quinta,
    sexta,
    sabado,
    domingo,
    duracao,
  ]);
  function adicionarEspaco() {
    let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
    let data_fimf = moment(data_fim).format("YYYY-MM-DD");
    let hora_iniciof = moment(escolha.hora_inicio, "HH:mm").format("HH:mm:ss");
    let hora_fimf = moment(escolha.hora_fim, "HH:mm").format("HH:mm:ss");

    setFreeze(true);
    adicionarEspacos({
      id_piscina_agua: selectedPool,
      id_categoria: selectedCategory,
      pistas: selectedPistas,
      data_inicio: data_iniciof,
      data_fim: data_fimf,
      hora_inicio: hora_iniciof,
      hora_fim: hora_fimf,
      segunda: segunda,
      terca: terca,
      quarta: quarta,
      quinta: quinta,
      sexta: sexta,
      sabado: sabado,
      domingo: domingo,
      vagas: vagas,
      obs: obs,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
      espaco_pista: espaco_pista,
      tipo: selectedTipo,
    }).then((res) => {

      if (res.success) {
        
        toast.success(t("espaçoAdicionar"));
        setFreeze(false);
        atualizarlista();
        limparVariaveis();
        handleCloseCriarEspaco();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      segunda == 0 &&
      terca == 0 &&
      quarta == 0 &&
      quinta == 0 &&
      sexta == 0 &&
      sabado == 0 &&
      domingo == 0
    ) {
      toast.error(t("selecionadia"));
    } else {
      if (
        (selectedPool &&
          selectedPistas &&
          selectedCategory &&
          obs &&
          vagas &&
          selectedTipo &&
          data_inicio &&
          data_fim &&
          hora_inicio &&
          hora_fim &&
          segunda) ||
        terca ||
        quarta ||
        quinta ||
        sexta ||
        sabado ||
        domingo
      ) {
        let confirmarDadosPreenchidos = true;

        if (confirmarDadosPreenchidos) {
          //Scroll para o início da página
          window.scrollTo(0, 0);
          adicionarEspaco();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        //Validação do nome
        if (!data_inicio) {
          setErroDataInicio(true);
        }
        if (!rows) {
          setErroPista(true);
        }

        if (vagas == 0) {
          setErroVagas(true);
        }
        if (!data_fim) {
          setErroDataFim(true);
        }
        if (!hora_inicio) {
          setErroHoraInicio(true);
        }
        if (!hora_fim) {
          setErroHoraFim(true);
        }
        if (!selectedCategory) {
          setErroCategoria(true);
        }
        if (!selectedTipo) {
          setErroTipo(true);
        }
        if (!selectedPool) {
          setErroPiscina(true);
        }
        if (
          segunda == 0 &&
          terca == 0 &&
          quarta == 0 &&
          quinta == 0 &&
          sexta == 0 &&
          sabado == 0 &&
          domingo == 0
        ) {
          toast.error(t("selecionadia"));
        }
      }
    }
  }

  //Navigator
  const navigate = useNavigate();
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };
  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayEspacos = espacosCopy;
      arrayEspacos = arrayEspacos.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.obs.toLowerCase().includes(search.toLowerCase())
      );
      setEspacos(arrayEspacos);
    } else {
      setEspacos(espacosCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaEspacos({ id_entidade_exploradora: idEntidade, id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_ocupa_espaco,
              pista: element.pista,
              tamanho_pista: element.tamanho_pista,
              categoria: element.id_categoria,
              categoriaText: categorias
                .filter((element2) => element2.id === element.id_categoria)
                .map((element2) => element2.categoria),
              data_inicio: element.data_inicio,
              data_fim: element.data_fim,
              hora_inicio: element.hora_inicio,
              hora_fim: element.hora_fim,
              vagas: element.vagas,
              vagas_ocupadas:
                element.vagas_ocupadas == null ? 0 : element.vagas_ocupadas,
              id_piscina: element.id_piscina,
              id_piscina_agua: element.id_piscina_agua,
              id_entidade_exploradora: element.id_entidade_exploradora,
              segunda: element.segunda,
              terca: element.terca,
              quarta: element.quarta,
              quinta: element.quinta,
              sexta: element.sexta,
              sabado: element.sabado,
              domingo: element.domingo,
              obs: element.obs,
              preco_mensal: element.preco_mensal,
              preco_anual: element.preco_anual,
            };
            arrayFinal.push(linha);
            setEspacos(arrayFinal);
            setEspacosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [idEspaco, setIdEspaco] = useState("");

  const [idPiscinae, setIdPiscinae] = useState("");
  const [idEEe, setIdEEe] = useState("");
  const [data_inicioe, setDataInicioe] = useState("");
  const [data_fime, setDataFime] = useState("");
  const [hora_inicioe, setHoraInicioe] = useState("");
  const [hora_fime, setHoraFime] = useState("");
  const [pistae, setPistae] = useState(0);
  const [vagase, setVagase] = useState(0);
  const [tamanho_pistae, setTamanhoPistae] = useState(0);
  const [segundae, setSegundae] = useState(0);
  const [tercae, setTercae] = useState(0);
  const [quartae, setQuartae] = useState(0);
  const [quintae, setQuintae] = useState(0);
  const [sextae, setSextae] = useState(0);
  const [sabadoe, setSabadoe] = useState(0);
  const [domingoe, setDomingoe] = useState(0);
  const [espaco_pistae, setEspacoPistae] = useState(1);
  const [piscinase, setPiscinase] = useState([]);
  const [obse, setObse] = useState("");
  const [tipose, setTipose] = useState([]);
  const [exibirCheckboxese, setExibirCheckboxese] = useState(true);

  const [selectedTipoe, setSelectedTipoe] = useState("");

  const [selectedPoole, setSelectedPoole] = useState("");
  const [selectedCategorye, setSelectedCategorye] = useState("");
  const [categoriase, setCategoriae] = useState([]);
  const [maxPistae, setMaxPistae] = useState(0);

  const [erroDataInicioe, setErroDataInicioe] = useState(false);
  const [erroDataFime, setErroDataFime] = useState(false);
  const [erroHoraInicioe, setErroHoraInicioe] = useState(false);
  const [erroHoraFime, setErroHoraFime] = useState(false);
  const [erroPistae, setErroPistae] = useState(false);
  const [erroVagase, setErroVagase] = useState(false);
  const [erroTamanhoPistae, setErroTamanhoPistae] = useState(false);
  const [erroPiscinae, setErroPiscinae] = useState(false);
  const [erroObse, setErroObse] = useState(false);
  const [erroCategoriae, setErroCategoriae] = useState(false);
  const [erroTipoe, setErroTipoe] = useState(false);
  const [pistasDe, setPistasDe] = useState([]);
  const [rowse, setRowse] = useState([{}]);
  const [disponiveis, setDisponiveis] = useState([]);
  const [horaEscolhida, setHoraEscolhida] = useState("");
  const [escolha, setEscolha] = useState([]);

  const [selectedPistas, setSelectedPistas] = useState([]);

  const handlePistaChange = (event, pista) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected pista to the array
      setSelectedPistas((prevSelected) => [...prevSelected, pista]);
    } else {
      // Remove the unselected pista from the array
      setSelectedPistas((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== pista.id)
      );
    }
  };

  useEffect(() => {
    let data_iniciof = moment(data_inicio).format("YYYY-MM-DD");
    let data_fimf = moment(data_fim).format("YYYY-MM-DD");
    if (
      data_inicio &&
      data_fim &&
      vagas &&
      duracao &&
      obs &&
      (segunda || terca || quarta || quinta || sexta || sabado || domingo)
    ) {
      getDisponibilidade({
        id_piscina: localStorage.getItem("idPiscina"),
        data_inicio: data_iniciof,
        data_fim: data_fimf,
        id_piscina_agua: selectedPool,
        segunda: segunda,
        terca: terca,
        quarta: quarta,
        quinta: quinta,
        sexta: sexta,
        sabado: sabado,
        domingo: domingo,
        tipo: selectedTipo,
        duracao,
      }).then((res) => {
        if (res.success) {
          let arrayFinal = [];
          let dados = res.data.data;

          dados.forEach((element) => {
            const horaInicio = element.horaInicio
              .split(":")
              .slice(0, 2)
              .join(":");
            const horaFim = element.horaFim.split(":").slice(0, 2).join(":");

            const linha = {
              hora_inicio: horaInicio,
              hora_fim: horaFim,
              pistas: element.pistas,
            };
            arrayFinal.push(linha);
            setDisponiveis(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      });
    }
  }, [
    data_inicio,
    data_fim,
    selectedPool,
    segunda,
    terca,
    quarta,
    quinta,
    sexta,
    sabado,
    domingo,
    vagas,
    obs,
    selectedTipo,
    duracao,
  ]);

  const handlePoolChangee = (event) => {
    setSelectedPoole(event.target.value);
    setErroPiscinae(false);
  };

  useEffect(() => {
    const datafe = moment(data_fime).format("YYYY-MM-DD");
    const dataie = moment(data_inicioe).format("YYYY-MM-DD");
    if (data_inicioe && data_fime && dataie === datafe) {
      setExibirCheckboxese(false);
      setSegundae(false);
      setTercae(false);
      setQuartae(false);
      setQuintae(false);
      setSextae(false);
      setSabadoe(false);
      setDomingoe(false);
    } else {
      setExibirCheckboxese(true);
    }
  }, [data_inicioe, data_fime]);

  const handleChange2e = (event) => {
    switch (event.target.name) {
      case "segunda":
        setSegundae(event.target.checked ? 1 : 0);
        break;
      case "terca":
        setTercae(event.target.checked ? 1 : 0);
        break;
      case "quarta":
        setQuartae(event.target.checked ? 1 : 0);
        break;
      case "quinta":
        setQuintae(event.target.checked ? 1 : 0);
        break;
      case "sexta":
        setSextae(event.target.checked ? 1 : 0);
        break;
      case "sabado":
        setSabadoe(event.target.checked ? 1 : 0);
        break;
      case "domingo":
        setDomingoe(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleCategoryChangee = (event) => {
    setSelectedCategorye(event.target.value);
    setErroCategoriae(false);
  };

  const handleTipoChangee = (event) => {
    setSelectedTipoe(event.target.value);
    setErroTipoe(false);
  };

  const handleChangee = (event) => {
    const novoEspacoPista = event.target.checked ? 1 : 2;
    setEspacoPistae(novoEspacoPista);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaTiposEspacos({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setTipose(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoriae(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaPiscinasAgua({ id_piscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_piscina_agua,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setPiscinase(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    if (idEspaco != "") {
      getEspacos({ id_ocupa_espaco: idEspaco })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];
            setDadosOriginais(dados);
            setObse(dados.obs);
            setSelectedTipoe(dados.tipo);
            setEspacoPistae(dados.espaco_pista);
            setDataInicioe(dados.data_inicio);
            setDataFime(dados.data_fim);
            setVagase(dados.vagas);
            setSelectedPoole(dados.id_piscina_agua);
            setHoraInicioe(parseISO(`1970-01-01T${dados.hora_inicio}`));
            setHoraFime(parseISO(`1970-01-01T${dados.hora_fim}`));
            setSegundae(dados.segunda);
            setTercae(dados.terca);
            setQuartae(dados.quarta);
            setQuintae(dados.quinta);
            setSextae(dados.sexta);
            setSabadoe(dados.sabado);
            setDomingoe(dados.domingo);
            setSelectedCategorye(dados.id_categoria);

            const pistasArray = dados.pistas.split(", ");
            setCheckboxes(
              pistasArray.map((pista) => ({ value: pista, checked: true }))
            );

            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }
  }, [idEspaco]);

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    setDataInicioe(dadosOriginais.data_inicio);
    setDataFime(dadosOriginais.data_fim);
    setSelectedPoole(dadosOriginais.id_piscina_agua);
    setSelectedTipoe(dadosOriginais.tipo);
    setVagase(dadosOriginais.vagas);
    setRowse(dadosOriginais.pistas);
    setEspacoPistae(dadosOriginais.espaco_pista);
    setTamanhoPistae(dadosOriginais.tamanho_pista);
    setHoraInicioe(toISOString(`1970-01-01T${dadosOriginais.hora_inicio}`));
    setHoraFime(parseISO(`1970-01-01T${dadosOriginais.hora_fim}`));
    setSegundae(dadosOriginais.segunda);
    setTercae(dadosOriginais.terca);
    setQuartae(dadosOriginais.quarta);
    setQuintae(dadosOriginais.quinta);
    setSextae(dadosOriginais.sexta);
    setSabadoe(dadosOriginais.sabado);
    setDomingoe(dadosOriginais.domingo);
    setObse(dadosOriginais.obs);
    setSelectedCategorye(dadosOriginais.id_categoria);

    window.scrollTo(0, 0);
  }
  function editarEspaco() {
    let data_iniciofe = moment(data_inicioe).format("YYYY-MM-DD");
    let data_fimfe = moment(data_fime).format("YYYY-MM-DD");
    let hora_iniciofe = moment(hora_inicioe).format("HH:mm");
    let hora_fimfe = moment(hora_fime).format("HH:mm");

    setFreeze(true);
    editarEspacos({
      id_ocupa_espaco: idEspaco,
      id_piscina_agua: selectedPoole,
      id_categoria: selectedCategorye,
      pistas: rowse,
      data_inicio: data_iniciofe,
      data_fim: data_fimfe,
      hora_inicio: hora_iniciofe,
      hora_fim: hora_fimfe,
      segunda: segundae,
      terca: tercae,
      quarta: quartae,
      quinta: quintae,
      sexta: sextae,
      sabado: sabadoe,
      domingo: domingoe,
      vagas: vagase,
      obs: obse,
      id_piscina: idPiscina,
      id_entidade_exploradora: idEE,
      tipo: selectedTipoe,
    })
      .then((res) => {
        if (res.success) {
          let linha = {
            id_ocupa_espaco: idEspaco,
            id_piscina_agua: selectedPoole,
            id_categoria: selectedCategorye,
            pistas: rowse,
            data_inicio: data_iniciofe,
            data_fim: data_fimfe,
            hora_inicio: hora_iniciofe,
            hora_fim: hora_fimfe,
            segunda: segundae,
            terca: tercae,
            quarta: quartae,
            quinta: quintae,
            sexta: sextae,
            sabado: sabadoe,
            domingo: domingoe,
            vagas: vagase,
            obs: obse,
            id_piscina: idPiscina,
            id_entidade_exploradora: idEE,
            tipo: selectedTipoe,
          };

          setDadosOriginais(linha);
          setFreeze(false);
          atualizarlista();
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      segundae == 0 &&
      tercae == 0 &&
      quartae == 0 &&
      quintae == 0 &&
      sextae == 0 &&
      sabadoe == 0 &&
      domingoe == 0
    ) {
      toast.error(t("selecionadia"));
    } else {
      if (
        (selectedPoole &&
          selectedCategorye &&
          selectedTipoe &&
          rowse &&
          data_inicioe &&
          data_fime &&
          hora_inicioe &&
          hora_fime &&
          segundae) ||
        tercae ||
        quartae ||
        quintae ||
        sextae ||
        sabadoe ||
        domingoe
      ) {
        let confirmarDadosPreenchidos = true;

        if (confirmarDadosPreenchidos) {
          //Scroll para o início da página
          window.scrollTo(0, 0);
          editarEspaco();
          handleCloseEditarEspaco();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        //Validação do nome
        if (!data_inicioe) {
          setErroDataInicioe(true);
        }
        if (!rowse) {
          setErroPistae(true);
        }

        if (vagase == 0) {
          setErroVagase(true);
        }
        if (!data_fime) {
          setErroDataFime(true);
        }
        if (!hora_inicioe) {
          setErroHoraInicioe(true);
        }
        if (!hora_fime) {
          setErroHoraFime(true);
        }
        if (!selectedCategorye) {
          setErroCategoriae(true);
        }
        if (!selectedTipoe) {
          setErroTipoe(true);
        }
        if (!selectedPoole) {
          setErroPiscinae(true);
        }
        if (
          segundae == 0 &&
          tercae == 0 &&
          quartae == 0 &&
          quintae == 0 &&
          sextae == 0 &&
          sabadoe == 0 &&
          domingoe == 0
        ) {
          toast.error(t("selecionadia"));
        }
      }
    }
  }

  function eliminarEspacos2() {
    setFreeze(true);
    eliminarEspacos({
      id_ocupa_espaco: idEspaco,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("espacoEliminado"));
          atualizarlista();
          handleClosePopupEliminar();
          handleCloseEditarEspaco();
        } else {
          setFreeze(false);
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }
  function atualizarlista() {
    listaEspacos({
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
      id_piscina: localStorage.getItem("idPiscina"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_ocupa_espaco,
              pista: element.pista,
              tamanho_pista: element.tamanho_pista,
              categoria: element.id_categoria,
              data_inicio: element.data_inicio,
              data_fim: element.data_fim,
              hora_inicio: element.hora_inicio,
              hora_fim: element.hora_fim,
              vagas: element.vagas,
              vagas_ocupadas: element.vagas_ocupadas,
              id_piscina: element.id_piscina,
              id_piscina_agua: element.id_piscina_agua,
              id_entidade_exploradora: element.id_entidade_exploradora,
              segunda: element.segunda,
              terca: element.terca,
              quarta: element.quarta,
              quinta: element.quinta,
              sexta: element.sexta,
              sabado: element.sabado,
              domingo: element.domingo,
              obs: element.obs,
              preco_mensal: element.preco_mensal,
              preco_anual: element.preco_anual,
            };
            arrayFinal.push(linha);
            setEspacos(arrayFinal);
            setEspacosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  function limparVariaveis() {
    setSelectedPool("");
    // setCategoria("");
    // setSelectedModalidade("");
    setSelectedCategory("");
    setSelectedPool("");
    setSelectedTipo("");
    setDataInicio("");
    setDataFim("");
    setHoraInicio("");
    setHoraFim("");
    setSabado("");
    setDomingo("");
    setSegunda("");
    setTerca("");
    setQuarta("");
    setQuinta("");
    setSexta("");
    setObs("");
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/ocupaespacoa");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <h3 className="titleList">{t("listaespacos")}</h3>

        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleClickOpenCriarEspaco();
              }}
            ></span>
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 70,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              {
                field: "obs",
                headerName: t("observacoes"),
                type: "string",
                flex: 1,
              },
              // {
              //   field: "pista",
              //   headerName: t("pista"),
              //   flex: 1,
              //   type: "string",
              //   hide: columnsHidden.includes("pista"),
              // },
              {
                field: "categoriaText",
                headerName: t("categoria"),
                flex: 1,
                type: "number",
                hide: columnsHidden.includes("categoria"),
              },
              {
                field: "vagas",
                headerName: t("vagas"),
                flex: 1,
                type: "string",
              },
              {
                field: "vagas_ocupadas",
                headerName: t("vagasocupadas"),
                flex: 1,
                type: "string",
              },

              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdEspaco(id);
                        handleClickOpenEditarEspaco();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={espacos}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
        </div>
        {/* adicionar espaço */}
        <Dialog
          open={openCriarEspaco}
          onClose={handleCloseCriarEspaco}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              minWidth: isMobile ? "90%" : "50%",
              height: "75%",
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {t("adicionarEspaco")}
            <FontAwesomeIcon
              edge="end"
              color="inherit"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
                padding: "8px",
                zIndex: 2000,
              }}
              onClick={handleCloseCriarEspaco}
              aria-label="Fechar"
              icon={faClose}
            />
          </DialogTitle>
          <DialogContent>
            {freeze && (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                }}
              >
                <Dialog
                  open={true}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <DialogContent>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {t("aguarde")}
                    </Typography>
                    <CircularProgress disableShrink style={{ marginTop: 20 }} />
                  </DialogContent>
                </Dialog>
              </div>
            )}

            <div className="formPop" style={{ marginTop: 30 }}>
              <Scrollbar style={{ height: 400 }}>
                <Box className="formTextField">
                  <div>
                    <FormControl className="formTextField2" fullWidth>
                      <InputLabel
                        className="formTextField22"
                        error={erroPiscina}
                      >
                        {t("escolhapiscina")}*
                      </InputLabel>
                      <Select
                        value={selectedPool}
                        onChange={handlePoolChange}
                        error={erroPiscina}
                        fullWidth
                        variant="standard"
                      >
                        {piscinas.map((piscina) => (
                          <MenuItem key={piscina.id} value={piscina.id}>
                            {piscina.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <InputLabel className="formTextField22" error={erroTipo}>
                        {t("escolhatipo")}*
                      </InputLabel>
                      <Select
                        value={selectedTipo}
                        onChange={handleTipoChange}
                        error={erroTipo}
                        fullWidth
                        variant="standard"
                      >
                        {tipos.map((tipo) => (
                          <MenuItem key={tipo.id} value={tipo.id}>
                            {tipo.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                      <InputLabel
                        className="formTextField22"
                        error={erroCategoria}
                      >
                        {t("escolhacategoria")}*
                      </InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        error={erroCategoria}
                        fullWidth
                        variant="standard"
                      >
                        {categorias.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.categoria}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        required
                        type="number"
                        variant="standard"
                        label={t("vagas")}
                        value={vagas}
                        error={erroVagas}
                        onChange={(e) => {
                          const value = e.target.value;

                          setVagas(value);
                          setErroVagas(false);
                        }}
                      />
                    </FormControl>

                    <FormControl className="formTextField3" fullWidth>
                      <TextField
                        style={{ textAlign: "left" }}
                        variant="outlined"
                        multiline
                        rows={2}
                        value={obs}
                        label={t("observacoes")}
                        error={erroObs}
                        onChange={(e) => {
                          setObs(e.target.value);
                          setErroObs(false);
                        }}
                      />
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <DatePicker
                          label={t("datainicio")}
                          openTo="day"
                          views={["day", "year", "month"]}
                          value={data_inicio}
                          onChange={(e) => {
                            if (e) {
                              setDataInicio(e.$d);
                              setErroDataInicio(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              required
                              error={erroDataInicio}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <DatePicker
                          label={t("datafim")}
                          openTo="day"
                          views={["day", "year", "month"]}
                          value={data_fim}
                          onChange={(e) => {
                            if (e) {
                              setDataFim(e.$d);
                              setErroDataFim(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              required
                              error={erroDataFim}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <Typography variant="subtitle" align="left">
                        {t("escolhadia")}
                      </Typography>

                      <div style={{ maxWidth: 200 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={segunda == 1}
                              onChange={handleChange2}
                              name="segunda"
                              color="primary"
                            />
                          }
                          label={t("segunda")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={terca == 1}
                              onChange={handleChange2}
                              name="terca"
                              color="primary"
                            />
                          }
                          label={t("terca")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={quarta == 1}
                              onChange={handleChange2}
                              name="quarta"
                              color="primary"
                            />
                          }
                          label={t("quarta")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={quinta == 1}
                              onChange={handleChange2}
                              name="quinta"
                              color="primary"
                            />
                          }
                          label={t("quinta")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sexta == 1}
                              onChange={handleChange2}
                              name="sexta"
                              color="primary"
                            />
                          }
                          label={t("sexta")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sabado == 1}
                              onChange={handleChange2}
                              name="sabado"
                              color="primary"
                            />
                          }
                          label={t("sabado")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={domingo == 1}
                              onChange={handleChange2}
                              name="domingo"
                              color="primary"
                            />
                          }
                          label={t("domingo")}
                        />
                      </div>
                    </FormControl>

                    <FormControl className="formTextField2" fullWidth>
                      <TextField
                        label={t("duracao") + " (min)"}
                        type="number"
                        value={duracao}
                        onChange={(e) => setDuracao(e.target.value)}
                        variant="standard"
                        required
                      />
                    </FormControl>

                    <Grid
                      container
                      spacing={1}
                      columns={{
                        xs: isMobile ? 12 : innerWidth * 0.006,
                        sm: isMobile ? 12 : innerWidth * 0.006,
                        md: isMobile ? 12 : innerWidth * 0.006,
                        lg: isMobile ? 12 : innerWidth * 0.006,
                      }}
                      sx={{
                        marginTop: "30px !important",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      {disponiveis.map((element, index) => (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          key={index}
                          style={{ marginLeft: 7, marginRight: 7 }}
                        >
                          <Button
                            className="btnHorasD"
                            variant="contained"
                            style={{
                              backgroundColor:
                                horaEscolhida === index ? "#0956fa" : "#C2D5FE",
                              color:
                                horaEscolhida === index ? "#C2D5FE" : "#0956fa",
                              transition: "box-shadow 0.3s, transform 0.3s",
                              "&:hover": {
                                boxShadow:
                                  horaEscolhida === index
                                    ? "10px 10px 10px 10px lightgrey"
                                    : "5px 5px 5px 5px lightgrey",
                              },
                              fontSize: 11,
                            }}
                            onClick={() => {
                              if (horaEscolhida === index) {
                                setHoraEscolhida(index);
                                setEscolha(element);
                              } else {
                                setHoraEscolhida(index);
                                setEscolha(element);
                              }
                            }}
                          >
                            {element.hora_inicio}h → {element.hora_fim}h
                          </Button>
                        </Grid>
                      ))}
                    </Grid>

                    {horaEscolhida != "" && (
                      <div style={{ marginLeft: 10, marginTop: 10 }}>
                        <FormControl
                          className="formTextField2"
                          style={{
                            width: "60%",
                            marginRight: "10px",
                            marginTop: 10,
                          }}
                        >
                          <FormGroup>
                            {escolha.pistas.map((pista) => {
                              const isChecked = selectedPistas.some(
                                (selected) => selected.id === pista.id
                              );

                              // const isMeiaPista = pista.id.includes("M");

                              return (
                                <div>
                                  {pista.ocupacao === 1 && (
                                    <div>
                                      <FormControlLabel
                                        key={pista.id}
                                        control={
                                          <Checkbox
                                            checked={isChecked}
                                            onChange={(event) =>
                                              handlePistaChange(event, pista)
                                            }
                                            name={pista.nome}
                                            disabled={selectedPistas.some(
                                              (selected) =>
                                                selected.id === pista.id + "M"
                                            )}
                                          />
                                        }
                                        label={<div>{pista.nome}</div>}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={selectedPistas.some(
                                              (selected) =>
                                                selected.id === `${pista.id}M`
                                            )}
                                            onChange={(event) =>
                                              handlePistaChange(event, {
                                                id: `${pista.id}M`,
                                                nome: `${pista.id} Meia Pista`,
                                              })
                                            }
                                            name={pista.nome}
                                            disabled={selectedPistas.some(
                                              (selected) =>
                                                selected.id === pista.id
                                            )}
                                          />
                                        }
                                        label={
                                          t("Pista") +
                                          " " +
                                          pista.id +
                                          t(" (Meia Pista)")
                                        }
                                      />
                                    </div>
                                  )}
                                  {pista.ocupacao == 2 && (
                                    <FormControlLabel
                                      key={pista.id}
                                      control={
                                        <Checkbox
                                          checked={isChecked}
                                          onChange={(event) =>
                                            handlePistaChange(event, pista)
                                          }
                                          name={pista.nome}
                                        />
                                      }
                                      label={`Pista ${pista.id.replace(
                                        "M",
                                        " (Meia Pista)"
                                      )}`}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      </div>
                    )}

                    {/* Hora inicio e Hora fim */}
                    {/* <FormControl className="formTextField2" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label={t("horainicio")}
                          value={hora_inicio}
                          onChange={(date) => setHoraInicio(date.$d)}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              error={erroHoraInicio}
                              required
                            />
                          )}
                          ampm={false}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl className="formTextField2" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label={t("horafim")}
                          value={hora_fim}
                          onChange={(date) => setHoraFim(date.$d)}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              error={erroHoraFim}
                              required
                            />
                          )}
                          ampm={false}
                        />
                      </LocalizationProvider>
                    </FormControl> */}
                  </div>
                </Box>
              </Scrollbar>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              className="btnEdicao"
              variant="contained"
              style={{
                backgroundImage: isMobile
                  ? `url(${editarbtnpeq})`
                  : `url(${editarbtn})`,
                width: isMobile ? 153 : 315,
                height: 40,
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={() => {
                validacao1();
              }}
            >
              {t("adicionar")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* editar espaço */}

        <Dialog
          open={openEditarEspaco}
          onClose={handleCloseEditarEspaco}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              minWidth: isMobile ? "95%" : "50px",
              height: "75%",
              borderRadius: "10px",
            },
          }}
          style={{ width: "100%" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {t("editarDados")}
            <FontAwesomeIcon
              edge="end"
              color="inherit"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
                padding: "8px",
              }}
              onClick={handleCloseEditarEspaco}
              aria-label="Fechar"
              icon={faClose}
            />
          </DialogTitle>
          <DialogContent>
            {freeze && (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                }}
              >
                <Dialog
                  open={true}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <DialogContent>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {t("aguarde")}
                    </Typography>
                    <CircularProgress disableShrink style={{ marginTop: 20 }} />
                  </DialogContent>
                </Dialog>
              </div>
            )}
            {carregamentoDadosCompleto ? (
              <div className="formPop" style={{ marginTop: 30 }}>
                <Scrollbar style={{ height: 400, minWidth: 400 }}>
                  <Box className="formTextField">
                    <div>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroTipoe}
                        >
                          {t("escolhatipo")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedTipoe}
                          onChange={handleTipoChangee}
                          error={erroTipoe}
                          fullWidth
                          variant="standard"
                        >
                          {tipose.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>
                              {tipo.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroPiscinae}
                        >
                          {t("escolhapiscina")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedPoole}
                          onChange={handlePoolChangee}
                          error={erroPiscinae}
                          fullWidth
                          variant="standard"
                        >
                          {piscinase.map((piscina) => (
                            <MenuItem key={piscina.id} value={piscina.id}>
                              {piscina.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoriae}
                        >
                          {t("escolhacategoria")}*
                        </InputLabel>
                        <Select
                          disabled={edicao}
                          value={selectedCategorye}
                          onChange={handleCategoryChangee}
                          error={erroCategoriae}
                          fullWidth
                          variant="standard"
                        >
                          {categoriase.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.categoria}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          disabled={edicao}
                          required
                          type="number"
                          variant="standard"
                          label={t("vagas")}
                          value={vagase}
                          error={erroVagase}
                          onChange={(e) => {
                            const value = e.target.value;

                            setVagase(value);
                            setErroVagase(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            disabled={edicao}
                            label={t("datainicio")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_inicioe}
                            onChange={(e) => {
                              if(e){
                                setDataInicioe(e.$d);
                                setErroDataInicioe(false);
                              }
                              
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataInicioe}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            disabled={edicao}
                            label={t("datafim")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_fime}
                            onChange={(e) => {
                              setDataFime(e.$d);
                              setErroDataFime(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataFime}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            disabled={edicao}
                            label={t("horainicio")}
                            value={hora_inicioe}
                            onChange={(date) => setHoraInicio(date.$d)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                error={erroHoraInicio}
                                required
                              />
                            )}
                            ampm={false}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            disabled={edicao}
                            label={t("horafim")}
                            value={hora_fime}
                            onChange={(date) => setHoraFime(date.$d)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                error={erroHoraFime}
                                required
                              />
                            )}
                            ampm={false}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {exibirCheckboxese && (
                        <FormControl className="formTextField2" fullWidth>
                          <Typography variant="subtitle" align="left">
                            {t("escolhadia")}
                          </Typography>

                          <div style={{ maxWidth: 200 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={segundae == 1}
                                  onChange={handleChange2e}
                                  name="segunda"
                                  color="primary"
                                />
                              }
                              label={t("segunda")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={tercae == 1}
                                  onChange={handleChange2e}
                                  name="terca"
                                  color="primary"
                                />
                              }
                              label={t("terca")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={quartae == 1}
                                  onChange={handleChange2e}
                                  name="quarta"
                                  color="primary"
                                />
                              }
                              label={t("quarta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={quintae == 1}
                                  onChange={handleChange2e}
                                  name="quinta"
                                  color="primary"
                                />
                              }
                              label={t("quinta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={sextae == 1}
                                  onChange={handleChange2}
                                  name="sexta"
                                  color="primary"
                                />
                              }
                              label={t("sexta")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={sabadoe == 1}
                                  onChange={handleChange2e}
                                  name="sabado"
                                  color="primary"
                                />
                              }
                              label={t("sabado")}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={edicao}
                                  checked={domingoe == 1}
                                  onChange={handleChange2e}
                                  name="domingo"
                                  color="primary"
                                />
                              }
                              label={t("domingo")}
                            />
                          </div>
                        </FormControl>
                      )}
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          disabled={edicao}
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={2}
                          value={obse}
                          label={t("observacoes")}
                          error={erroObse}
                          onChange={(e) => {
                            setObse(e.target.value);
                            setErroObse(false);
                          }}
                        />
                      </FormControl>
                      <Typography
                        variant="subtitle"
                        align="left"
                        style={{ marginLeft: 10 }}
                      >
                        {t("Pistas")}
                      </Typography>
                      {checkboxes.map((checkbox, index) => (
                        <div key={index}>
                          <Checkbox
                            disabled={edicao}
                            value={checkbox.value}
                            checked={checkbox.checked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          <label htmlFor={`pista-${index}`}>{`${t(
                            "Pista"
                          )} ${checkbox.value.replace(
                            "M",
                            t(" (Meia Pista)")
                          )}`}</label>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Scrollbar>

                {/* Cancelar edição */}
                <Dialog
                  open={cancelarEdicao}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      width: 452,
                      height: 186,
                      borderRadius: "10px",
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("cancelarEdicao")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("textoCancelarEdicao")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        cancelar();
                      }}
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClosePopupCancelar();
                      }}
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Eliminar */}
                <Dialog
                  open={eliminar}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("eliminarEspaco")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("eliminarRegisto")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${simbtn})`,
                        width: 218,
                        height: 40,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        eliminarEspacos2();
                      }}
                    >
                      {t("sim")}
                    </Button>
                    <Button
                      className="btnSim"
                      variant="contained"
                      style={{
                        backgroundImage: `url(${naobtn})`,
                        width: 198,
                        height: 30,
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                      onClick={() => {
                        handleClosePopupEliminar();
                      }}
                      autoFocus
                    >
                      {t("nao")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  width: window.innerWidth,
                  height: window.innerHeight,
                  zIndex: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                }}
              >
                <CircularProgress disableShrink />
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              marginTop: "-20px",
            }}
          >
            {edicao ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <Button
                  className="btnEdicao"
                  variant="contained"
                  style={{
                    backgroundImage: isMobile
                      ? `url(${editarbtnpeq})`
                      : `url(${editarbtn})`,
                    width: isMobile ? 153 : 315,
                    height: 40,
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={() => {
                    scrollToDiv();
                  }}
                >
                  <PencilIcon
                    style={{
                      width: "15px",
                      color: "white",
                      marginRight: "10px",
                    }}
                  />

                  {t("editar")}
                </Button> */}

                <Button
                  className="btnEdicao"
                  variant="contained"
                  style={{
                    backgroundImage: isMobile
                      ? `url(${eliminarbtnpeq})`
                      : `url(${eliminarbtn})`,
                    width: isMobile ? 153 : 315,
                    height: 40,
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={() => {
                    handleClickOpenPopupEliminar();
                  }}
                >
                  <TrashIcon
                    style={{
                      width: "15px",
                      color: "white",
                      marginRight: "10px",
                    }}
                  />

                  {t("eliminar")}
                </Button>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="btnGuardar"
                  variant="contained"
                  style={{
                    backgroundImage: isMobile
                      ? `url(${guardarbtnPeq})`
                      : `url(${guardarbtn})`,

                    height: 40,
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={() => {
                    validacao();
                  }}
                >
                  {t("guardar")}
                </Button>
                <Button
                  className="btnGuardar"
                  variant="contained"
                  style={{
                    backgroundImage: isMobile
                      ? `url(${cancelarbtnPeq})`
                      : `url(${cancelarbtn})`,
                    width: isMobile ? 164 : 218,
                    height: 40,
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={() => {
                    handleClickOpenPopupCancelar();
                  }}
                >
                  {t("cancelar")}
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default ListaEspacos;
