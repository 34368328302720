import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestGet2 } from "../api";
import { makeRequestPost2, makeRequestPut2 } from "../api";

//Get utilizadores
export function getUtilizadores(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("utilizadores/pag", dados, token);
}

//Alterar status dos utilizadores - APENAS PARA OS PEDIDOS
export function postStatusUtilizadores(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("utilizadores/status_pedidos", dados, token);
}

//Eliminar utilizador - APENAS PARA OS PEDIDOS
export function deleteUtilizador(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("utilizadores/", dados, token);
}

//Get utilizador
export function getUtilizadoresId(id, dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`utilizadores/id/${id}`, dados, token);
}

//Editar utilizador
export function putUtilizador(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("utilizadores/dados", dados, token);
}

//Editar utilizador
export function putSerCliente(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("utilizadores/sercliente", dados, token);
}

export function suspendeUtilizador(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("utilizadores/status", dados, token);
}

export function suspendeUtilizadorEmail(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("utilizadores/desactive-account", dados, token);
}

//Get piscinas inscrito
export function getInscricoesId(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`inscricoes/id/${id}`, {}, token);
}

//Get distritos
export function getDistritos(dados) {
  let token = Cookies.get("token");
  return makeRequestGet2(`utilizadores/distritos`, token);
}
