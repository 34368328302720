import Cookies from "js-cookie";
import { makeRequestPost2 } from "../api";


export function OcupacaoHorasDias(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/ocupacao-horas-dias", dados, token);
}


export function statsDescontos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/descontos", dados, token);
}


export function statsFaturacaoMensal(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/faturacao-mensal", dados, token);
}

export function statsFaturacaoModalidades(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/faturacao-modalidades", dados, token);
}

export function statsFaturacaoMensalComparar(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/faturacao-mensal/comparar", dados, token);
}

export function statsNumClientes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/num-clientes", dados, token);
}

export function statsNumClientesGenero(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/num-clientes-genero", dados, token);
}



export function statsNumAlunosProf(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/alunos-professor", dados, token);
}


export function statsFunc(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/func-esta", dados, token);
}


export function comparaModalidadesClientes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("dashboards/clientes-modalidades-compara", dados, token);
}

