import {
  Button,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { loginPost } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../components/PhoneNumber";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../components/PhoneNumber2";
import { display, fontSize, Stack } from "@mui/system";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  CircularProgress,
} from "@mui/material";
import Link from "@mui/material/Link";
import HeaderExterno from "../components/HeaderExterno";
import FooterExterno from "../components/FooterExterno";
import {
  confirmaEmail,
  registo,
  verificarEmail,
} from "../api/requests/Registo";

function Register() {
  function goBack() {
    navigate("/");
  }

  function goLogin() {
    navigate("/login");
  }
  //Variáveis
  var [formflow, setFormFlow] = useState(0);

  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [palavraPasse, setPalavraPasse] = useState("");
  const [palavraPasse2, setPalavraPasse2] = useState("");
  const [genero, setGenero] = useState("");

  const [termos, setTermos] = useState(false);
  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);
  //Validações
  const [erroNome, setErroNome] = useState(false);
  const [erroTelefone, setErroTelefone] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [emailRepetido, setEmailRepetido] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [erroPassword2, setErroPassword2] = useState(false);
  const [erroGenero, seterroGenero] = useState(false);

  const [mensagemErroNome, setMensagemErroNome] = useState("");
  const [mensagemErroTelefone, setMensagemErroTelefone] = useState("");
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [mensagemErroPalavraPasse2, setMensagemErroPalavraPasse2] =
    useState("");

  const [mensagemErroGenero, setMensagemErroGenero] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");
  const [sucessoTelefone, setSucessoTelefone] = useState("");
  const [sucessoNome, setSucessoNome] = useState("");
  const [sucessoPalavraPasse, setSucessoPalavraPasse] = useState("");
  const [sucessoPalavraPasse2, setSucessoPalavraPasse2] = useState("");

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [openTermos, setOpenTermos] = React.useState(false);
  const handleOpenTermos = () => setOpenTermos(true);
  const handleCloseTermos = () => setOpenTermos(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 799); // Defina o limite de largura para o que você considera um "telefone"
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    setNome(capitalizeFirstLetter(nome));
  }, [nome]);

  async function Registar() {
    if (
      (nome,
      email,
      // telefone,
      // genero,
      palavraPasse,
      palavraPasse2,
      !erroPassword2,
      !erroPassword,
      !erroEmail,
      // !erroTelefone,
      termos == true)
    ) {
      const hashedPassword = bcrypt.hashSync(
        palavraPasse,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );
      setFreeze(true);
      await registo({
        nome: nome,
        email: email,
        genero: genero,
        contacto: telefone,
        password: hashedPassword,
      }).then(async (res) => {
        if (res.success) {
          setTimeout(async function () {
            confirmaEmail({
              nome: nome,
              email: email,
              id: res.data.id,
            }).then((res2) => {
              if (res2.success) {
                setFreeze(false);
                toast.success(res2.data.msg);
                navigate("/login");
              } else {
                setFreeze(false);
                toast.error(res.error);
              }
            });
          }, 3000);
        } else {
          setFreeze(false);
          toast.error(res.error);
        }
      });
    } else {
      setFreeze(false);
      toast.error(t("termos"));
    }
  }

  useEffect(() => {
    if (nome) {
      setErroNome(false);
      setSucessoNome("success");
      setMensagemErroNome("");
    }
    // if (telefone) {
    //   if (!isValidPhoneNumber(telefone)) {
    //     setMensagemErroTelefone(t("telefoneinvalido"));

    //     setErroTelefone(true);
    //   } else if (isValidPhoneNumber(telefone)) {
    //     setErroTelefone(false);
    //     setMensagemErroTelefone("");
    //   }
    // }

    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        email
      );

      if (validacaoEmail) {
        setErroEmail(false);
        setErroEmail("");
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        let validacaoFormatoEmail = /\S+@\S+\.\S+/.test(email);

        if (validacaoFormatoEmail) {
        } else {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
    }

    if (palavraPasse) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = palavraPasse.length;
      const uppercasePassword = uppercaseRegExp.test(palavraPasse);
      const lowercasePassword = lowercaseRegExp.test(palavraPasse);
      const digitsPassword = digitsRegExp.test(palavraPasse);
      const specialCharPassword = specialCharRegExp.test(palavraPasse);
      const minLengthPassword = minLengthRegExp.test(palavraPasse);

      if (passwordLength === 0) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("vazia"));
      } else if (!uppercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("uppercase"));
      } else if (!lowercasePassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("lowercase"));
      } else if (!digitsPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracternumb"));
      } else if (!specialCharPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("caracterespecial"));
      } else if (!minLengthPassword) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("8caracteres"));
      } else if (palavraPasse != palavraPasse2) {
        setErroPassword(true);
        setErroPassword2(true);
        setMensagemErroPalavraPasse(t("passeigual"));
        setMensagemErroPalavraPasse2(t("passeigual"));
      } else {
        setErroPassword(false);
        setErroPassword2(false);
        setMensagemErroPalavraPasse("");
        setMensagemErroPalavraPasse2("");
        setSucessoPalavraPasse("success");
        setSucessoPalavraPasse2("success");
      }
    }
    if (palavraPasse2) {
      if (palavraPasse2 == palavraPasse) {
        setErroPassword(false);
        setErroPassword2(false);
        setMensagemErroPalavraPasse("");
        setMensagemErroPalavraPasse2("");
        setSucessoPalavraPasse("success");
        setSucessoPalavraPasse2("success");
      }
    }
  }, [email, nome, telefone, genero, palavraPasse, palavraPasse2]);

  function verificar() {
    if (
      // (genero && !erroGenero && nome && !erroNome && telefone && !erroTelefone,
      // email &&
      //   !erroEmail &&
      //   palavraPasse &&
      //   !erroPassword &&
      //   palavraPasse2 &&
      //   !erroPassword2)
      (nome && !erroNome,
      email &&
        !erroEmail &&
        palavraPasse &&
        !erroPassword &&
        palavraPasse2 &&
        !erroPassword2)
    ) {
      verificarEmail({ email: email }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroEmail(true);
            setMensagemErroEmail(res.data.error);
            setEmailRepetido(true);
          } else {
            setEmailRepetido(false);
          }
        }
      });
      setEdicao(false);
    } else {
      setSucessoNome("");
      setMensagemErroNome(t("nomevazio"));
      setErroNome(true);
      toast.error(t("erro"));
    }
  }

  return (
    <div className="bodyLogin">
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <div className="session2">
        <div className="topleftrel">
          <IconButton onClick={goBack} size={"small"}>
            {" "}
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ width: "25px", color: "black", zIndex: 999 }}
            ></FontAwesomeIcon>
          </IconButton>
        </div>

        <div className="topright">
          <IconButton onClick={handleOpenHelp} size={"small"}>
            {" "}
            <QuestionMarkCircleIcon style={{ width: "25px", color: "black" }} />
          </IconButton>
        </div>
        <div className="inicioCol" style={{ paddingBottom: "40px" }}>
          <h5 className="titulo">
            <span style={{ fontSize: isMobile ? "14px" : "18px" }}>
              {t("registo")}
            </span>
          </h5>
          <TextField
            className="formLogin"
            disabled={!edicao}
            style={{
              marginBottom: "10px",
              marginTop: edicao == false ? "20px" : "5px",
            }}
            id="nome"
            label="Nome"
            variant="standard"
            required
            type="text"
            value={nome}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            error={erroNome}
            helperText={mensagemErroNome}
            color={sucessoNome}
            onChange={(e) => {
              setNome(e.target.value);
              setErroNome(false);
            }}
          />
          {/* <PhoneInput
            className="formLoginPhone"
            style={{
              marginBottom: "10px",
              marginTop: edicao == false ? "10px" : "15px",
            }}
            defaultCountry="PT"
            labels={
              i18n.language == "pt" ? pt : i18n.language == "es" ? es : en
            }
            value={telefone}
            disabled={!edicao}
            error={erroTelefone}
            helperText={mensagemErroTelefone}
            onChange={(e) => {
              setTelefone(e);
              setErroTelefone(false);
              setMensagemErroTelefone("");
            }}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            inputComponent={PhoneNumber}
          /> */}
          {/* Genero */}
          {/* <FormControl className="formLoginPhone2" disabled={!edicao}>
            <RadioGroup
              sx={{
                display: "flex",
                justifycontent: "center",
                alignitems: "center",
                marginTop: "15px",
              }}
              row
              label={t("genero")}
              defaultValue={genero}
              name="genero"
              value={genero}
              onChange={(e) => {
                setGenero(e.target.value);
                seterroGenero(false);
              }}
            >
              <FormControlLabel
                value="feminino"
                control={<Radio size="small" />}
                label={t("F")}
              />
              <FormControlLabel
                value="masculino"
                control={<Radio size="small" />}
                label={t("M")}
              />
              <FormControlLabel
                value="outro"
                control={<Radio size="small" />}
                label={t("O")}
              />
            </RadioGroup>
          </FormControl> */}
          <TextField
            className="formLogin"
            style={{
              marginBottom: "10px",
              marginTop: edicao === false ? "10px" : "25px",
            }}
            id="email"
            disabled={!edicao}
            label={t("email")}
            variant="standard"
            required={true}
            placeholder="email@email.com"
            value={email}
            error={erroEmail}
            InputProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? "12px" : "16px" },
            }}
            helperText={
              emailRepetido === true ? (
                <Link
                  sx={{ color: "#dc6d7c", fontSize: "12px", cursor: "pointer" }}
                  onClick={(e) => {
                    goLogin();
                  }}
                >
                  {mensagemErroEmail} {t("facalogin")}
                </Link>
              ) : (
                mensagemErroEmail
              )
            }
            color={sucessoEmail}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
            }}
          />
          {/* Palavra-passe */}

          {edicao == true ? (
            <TextField
              disabled={!edicao}
              className="formLogin"
              style={{
                marginTop: "15px",
                marginBottom: "10px",
              }}
              id="palavraPasse"
              label={t("palavraPasse")}
              value={palavraPasse}
              variant="standard"
              error={erroPassword}
              type={showPassword ? "text" : "password"}
              helperText={mensagemErroPalavraPasse}
              color={sucessoPalavraPasse}
              onChange={(e) => {
                setPalavraPasse(e.target.value);
              }}
              InputLabelProps={{
                style: { fontSize: isMobile ? "12px" : "16px" },
              }}
              InputProps={{
                style: { fontSize: isMobile ? "12px" : "16px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disableRipple
                      disableFocusRipple
                      style={{
                        justifyContent: "right",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        color: "#8A8A8A",
                      }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </Button>
                  </InputAdornment>
                ),
              }}
              required={true}
            ></TextField>
          ) : null}

          {/*Confirma Palavra-passe */}
          {edicao == true ? (
            <TextField
              disabled={!edicao}
              className="formLogin"
              style={{
                marginTop: "15px",
                marginBottom: "5px",
              }}
              id="palavraPasse2"
              label={t("confirmapalavrapasse")}
              variant="standard"
              error={erroPassword2}
              value={palavraPasse2}
              type={showPassword2 ? "text" : "password"}
              helperText={mensagemErroPalavraPasse2}
              color={sucessoPalavraPasse2}
              onChange={(e) => {
                setPalavraPasse2(e.target.value);
              }}
              InputLabelProps={{
                style: { fontSize: isMobile ? "12px" : "16px" },
              }}
              InputProps={{
                style: { fontSize: isMobile ? "12px" : "16px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disableRipple
                      disableFocusRipple
                      style={{
                        justifyContent: "right",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        color: "#8A8A8A",
                      }}
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </Button>
                  </InputAdornment>
                ),
              }}
              required={true}
            ></TextField>
          ) : null}

          {edicao == true ? null : (
            <FormGroup row style={{ paddingBottom: "100px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ pointerEvents: "none" }}
                    value={termos}
                    onChange={(e) => {
                      setTermos(!termos);
                    }}
                  />
                }
                label={
                  <b style={{ fontSize: 14 }}>
                    Li e Aceito os &nbsp;
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenTermos();
                      }}
                    >
                      {t("termoesCondicoes")}
                    </Link>
                  </b>
                }
              />
            </FormGroup>
          )}

          {edicao === true ? (
            <button
              type="button"
              className="iniciobtn"
              style={{ marginTop: "35px" }}
              onClick={() => {
                verificar();
              }}
              sx={{ mt: 1, mr: 1 }}
            >
              {t("avancar")}
            </button>
          ) : (
            <Stack direction={"column"} spacing={2}>
              <button
                type="button"
                className="anteriorbtn"
                style={{ marginTop: "5px" }}
                onClick={() => {
                  setEdicao(true);
                }}
                sx={{ mt: 1, mr: 1 }}
              >
                {t("anterior")}
              </button>
              <button
                type="button"
                className="iniciobtn"
                style={{ marginTop: "5px" }}
                onClick={() => {
                  Registar();
                }}
                sx={{ mt: 1, mr: 1 }}
              >
                {t("confirmar")}
              </button>
            </Stack>
          )}
        </div>
      </div>

      <Dialog
        open={openHelp}
        onClose={handleCloseHelp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
        <DialogContent>
          <p className="ajuda">
            Aqui podes registar-te no GoPool, segue os passos e preenche os
            campos de acordo com aquilo que te é pedido, depois de aceitares os
            termos e condições receberás um email a confirmar a tua nova conta,
            em caso de dúvida podes sempre entrar em contacto com a equipa de
            Suporte do GoPool!
          </p>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openTermos}
        onClose={handleCloseTermos}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("termoesCondicoes")}
        </DialogTitle>
        <DialogContent>
          <DialogTitle>Termos e Condições de Uso</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1" gutterBottom>
              Estes Termos e Condições de Uso regulam a utilização da aplicação
              móvel GoPool fornecida por Tectank, Lda. Ao aceder ou usar a
              Aplicação, concorda em estar vinculado a estes Termos. Se não
              concordar com estes Termos, por favor, não use a Aplicação.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              1. Aceitação dos Termos
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ao transferir, instalar ou usar a Aplicação, concorda com estes
              Termos e com a nossa Política de Privacidade, que é incorporada
              por referência. Se não concordar com qualquer parte destes Termos,
              não deve usar a Aplicação.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              2. Uso da Aplicação
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              2.1 Elegibilidade
            </Typography>
            <Typography variant="body1" gutterBottom>
              Deve ter pelo menos 16 anos de idade para usar a Aplicação. Ao
              usar a Aplicação, declara e garante que tem a idade legal
              necessária para formar um contrato vinculativo e que todas as
              informações de registo que submeter são precisas e verdadeiras.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              2.2 Licença de Uso
            </Typography>
            <Typography variant="body1" gutterBottom>
              A Empresa concede-lhe uma licença limitada, não exclusiva, não
              transferível e revogável para usar a Aplicação para seu uso
              pessoal e não comercial, sujeita aos Termos.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              3. Conta de Utilizador
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              3.1 Registo
            </Typography>
            <Typography variant="body1" gutterBottom>
              Para usar certas funcionalidades da Aplicação, deve registar-se e
              criar uma conta. Concorda em fornecer informações precisas e
              completas durante o processo de registo e em manter essas
              informações atualizadas.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              3.2 Segurança da Conta
            </Typography>
            <Typography variant="body1" gutterBottom>
              É responsável por manter a confidencialidade das suas credenciais
              de login e por todas as atividades que ocorram na sua conta.
              Concorda em notificar imediatamente a Empresa de qualquer uso não
              autorizado da sua conta.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              4. Conduta do Utilizador
            </Typography>
            <Typography variant="body1" gutterBottom>
              Concorda em não usar a Aplicação para:
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              - Qualquer propósito ilegal ou não autorizado;
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              - Transmitir qualquer material que seja, na opinião da Empresa,
              ameaçador, abusivo, difamatório, obsceno ou de qualquer outra
              forma censurável;
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              - Infringir os direitos de propriedade intelectual da Empresa ou
              de terceiros.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              5. Propriedade Intelectual
            </Typography>
            <Typography variant="body1" gutterBottom>
              Todo o conteúdo da Aplicação, incluindo, mas não se limitando a
              textos, gráficos, logótipos, ícones, imagens, clipes de áudio e
              software, é propriedade da Empresa ou dos seus licenciadores e
              está protegido por leis de direitos de autor, marcas registadas e
              outras leis de propriedade intelectual.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              6. Isenção de Garantias
            </Typography>
            <Typography variant="body1" gutterBottom>
              A Aplicação é fornecida no estado em que se encontra, sem
              quaisquer garantias, expressas ou implícitas. Isso inclui, mas não
              se limita a, garantias de qualidade, adequação a um propósito
              específico ou de não violação de direitos.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              7. Limitação de Responsabilidade
            </Typography>
            <Typography variant="body1" gutterBottom>
              Na máxima extensão permitida por lei, a Empresa não será
              responsável por quaisquer danos indiretos, incidentais, especiais,
              consequenciais ou punitivos, ou por qualquer perda de lucros ou
              receitas, incorridos direta ou indiretamente, ou qualquer perda de
              dados, uso, goodwill ou outras perdas intangíveis, resultantes de:
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              - O seu uso ou incapacidade de usar a Aplicação;
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              - Qualquer acesso não autorizado ou uso dos nossos servidores e/ou
              qualquer informação pessoal armazenada neles;
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              - Qualquer interrupção ou cessação de transmissão de ou para a
              Aplicação.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              8. Modificações dos Termos
            </Typography>
            <Typography variant="body1" gutterBottom>
              A Empresa reserva-se o direito de modificar estes Termos a
              qualquer momento. Quaisquer alterações serão publicadas na
              Aplicação e notificaremos o utilizador de tais mudanças. O seu uso
              continuado da Aplicação após a publicação de alterações constitui
              a sua aceitação dos novos termos.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              9. Lei Aplicável
            </Typography>
            <Typography variant="body1" gutterBottom>
              Estes Termos serão regidos e interpretados de acordo com as leis
              de Portugal, sem considerar o seu conflito de disposições legais.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              10. Contacto
            </Typography>
            <Typography variant="body1">
              Se tiver alguma dúvida sobre estes Termos, entre em contacto
              connosco através do suporte@tectank.pt.
            </Typography>
          </DialogContent>
        </DialogContent>
      </Dialog>
      <FooterExterno />
    </div>
  );
}
export default Register;
