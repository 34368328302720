import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { loginPost } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { recuperaPass } from "../api/requests/Registo";
import { width } from "@mui/system";
import {QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

function RecoverPassword() {
  function goBack() {
    navigate("/login");
  }
  //Variáveis
  const [email, setEmail] = useState("");
  const [freeze, setFreeze] = useState(false);

  //Validações
  const [erroEmail, setErroEmail] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  function Recuperar() {
    if (email && erroEmail == false) {
      setFreeze(true);
      recuperaPass({
        email: email,
      }).then((res) => {
        if (res.success) {
          setTimeout(function () {
            setFreeze(false);
            toast.success(t("recuperaEnviado"));
            navigate("/");
          }, 3000);
        } else {
          setTimeout(function () {
            setFreeze(false);
            toast.error(t("recuperaErro"));
          }, 2000);
        }
      });
    } else {
      setFreeze(false);
      toast.error(t("emailInvalido"));
    }
  }

  useEffect(() => {
    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail = /^\"?[\w-_\.]*\"?@tectank\.pt$/.test(email);
      if (validacaoEmail) {
        setErroEmail(false);
        setErroEmail("");
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        let validacaoFormatoEmail = /\S+@\S+\.\S+/.test(email);

        if (validacaoFormatoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("naoExisteConta"));
        } else {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
    }
  }, [email]);

  return (
    <div className="bodyLogin">
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <div className="session2">
      

       
          <div className="topleftrel">
            <IconButton
              onClick={goBack}
              size={"small" }
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft} style={{width:"25px", color:"black"}}></FontAwesomeIcon>
            </IconButton>
          </div>
          <div className="topright">
            <IconButton
              onClick={handleOpenHelp}
              size={ "small" }
            >
              {" "}
              <QuestionMarkCircleIcon style={{width:"25px", color:"black"}}/>

            </IconButton>
          </div>
          <div
            className="inicioCol"
            style={{paddingBottom:"100px"}}
          >
            
            <h5>
              <span style={{fontSize:18}}>{t("recuperarPP")}</span>
            </h5>

            {/* Email */}
            <TextField
              style={{ marginBottom: "20px", marginTop: "20px" }}
              className="formLogin"

              id="email"
              label={t("email")}
              variant="standard"
              required={true}
              placeholder="email@email.com"
              error={erroEmail}
              InputProps={{
                style: { fontSize:isMobile ? "12px" : "16px" },
              }}
              InputLabelProps={{
                style: { fontSize: isMobile ? "12px" : "16px" },
              }}
              helperText={mensagemErroEmail}
              color={sucessoEmail}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
              }}
            />

            <Link className="textlinklogin" to={"/login"}>
              {t("iniciarSessao")}
            </Link>

            <button
              type="button"
              className="iniciobtn"
              style={{ marginTop: "5%" }}
              onClick={() => {
                Recuperar();
              }}
            >
              {t("recuperar")}
            </button>
       
        </div>

        <Dialog
          open={openHelp}
          onClose={handleCloseHelp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("ajuda")}</DialogTitle>
          <DialogContent>
            <p className="ajuda">
             Introduz o email com o qual te registaste e será enviado para lá um link para reposição de password!
             <br></br>
             Caso o problema persista podes entrar em contacto com a equipa de suporte do GoPool
            </p>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
export default RecoverPassword;
