import Cookies from "js-cookie";
import { makeRequestPost2 } from "../api";

//Get Super administradores
export function getSuperAdministradores(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("superadmin/all", dados, token);
}

//Post super admin
export function postSuperAdministrador(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("superadmin/", dados, token);
}

//Enviar email -Post super admin
export function enviarEmailPostSuperAdministrador(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("superadmin/active-account", dados, token);
}

//Get por id
export function getSuperAdmin(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`superadmin/id/${id}`, {}, token);
}
