import Cookies from "js-cookie";
import { makeRequestPost, makeRequestPost2, makeRequestPost3 } from "../api";

//Verificar se o email já está registado
export function verificarEmail(dados) {
  return makeRequestPost("email-signup", dados);
}

//Verificar se o email já está registado
export function verificarNif(dados) {
  let token = Cookies.get("token");
  return makeRequestPost3("nif", dados, token);
}

export function registo(dados) {
  return makeRequestPost("signup", dados);
}

export function confirmaEmail(dados) {
  return makeRequestPost("active-account", dados);
}

export function recuperaPass(dados) {
  return makeRequestPost("reset-password", dados);
}

export function alteraPass(dados, id, token) {
  return makeRequestPost(`reset-password/${id}/${token}`, dados);
}

export function alteraPass2(dados) {
  return makeRequestPost(`define-password2`, dados);
}

export function definePassEmail(dados) {
  return makeRequestPost("define-password", dados);
}

export function definePassword(dados, id, token) {
  return makeRequestPost(`define-password/${id}/${token}`, dados);
}

export function confirmacaoEmail(id, token) {
  return makeRequestPost(`active-account/${id}/${token}`);
}

// Reenviar email ativação de conta
export function reenviarEmailAtivacaoConta(dados) {
  return makeRequestPost("active-account2", dados);
}

export function alteraUserPass(dados, id, token) {
  return makeRequestPost(`reset-password-user/${id}/${token}`, dados);
}
