import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faEdit,
  faEye,
  faPen,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getEntidades } from "../../api/requests/Entidades";
import {
  enviarEmailStatusPedido,
  getPedidoId,
  getPedidosAll,
  putPedido,
} from "../../api/requests/Pedidos";
import { deletePiscina, postStatusPiscina } from "../../api/requests/Piscinas";
import {
  deleteUtilizador,
  postStatusUtilizadores,
} from "../../api/requests/Utilizadores";
import Search5 from "../../components/DataTable/Search5";

function Pedidos() {
  const [pedidos, setPedidos] = useState([]);
  const [pedidosCopy, setPedidosCopy] = useState([]);
  const [search, setSearch] = useState("");

  const [detalhes, setDetalhes] = useState(false);
  const [arrayDetalhes, setArrayDetalhes] = useState([]);
  const [vistaDetalhes, setVistaDetalhes] = useState(false);

  const [confirmacaoStatus, setConfirmacaoStatus] = useState(false);
  const [confirmacaoStatusId, setConfirmacaoStatusId] = useState("");
  const [tipoStatus, setTipoStatus] = useState("");
  const [tipo, setTipo] = useState("");
  const [acao, setAcao] = useState("");
  const [idUtilizadorPiscina, setIdUtilizadorPiscina] = useState("");

  const [freeze, setFreeze] = useState(false);

  const handleClickOpenPopupDetalhes = () => {
    setDetalhes(true);
  };

  const handleClosePopupDetalhes = () => {
    setDetalhes(false);
  };

  const handleClickOpenPopupStatus = () => {
    setConfirmacaoStatus(true);
  };

  const handleClosePopupStatus = () => {
    setConfirmacaoStatusId("");
    setTipoStatus("");
    setConfirmacaoStatus(false);
  };

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getPedidosAll({ chave: Cookies.get("uKey") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_pedido,
              tipo: element.tipo == 1 ? t("piscina") : t("utilizador"),
              acao:
                element.acao == 1
                  ? t("suspender")
                  : element.acao == 2
                  ? t("eliminar")
                  : element.acao == 3
                  ? t("ativar")
                  : null,
              status:
                element.status == 0
                  ? t("pendente")
                  : element.status == 1
                  ? t("aprovado")
                  : t("reprovado"),
              id_utilizador_piscina: element.id_utilizador_piscina,
            };
            arrayFinal.push(linha);
          });
          setPedidos(arrayFinal);
          setPedidosCopy(arrayFinal);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    if (search) {
      let arrayPedidos = pedidosCopy;
      arrayPedidos = arrayPedidos.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.tipo.toLowerCase().includes(search.toLowerCase()) ||
          element.acao.toLowerCase().includes(search.toLowerCase())
      );
      setPedidos(arrayPedidos);
    } else {
      setPedidos(pedidosCopy);
    }
  }, [search]);

  function verDetalhes(id) {
    handleClickOpenPopupDetalhes();
    getPedidoId(id)
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          let dados2 = res.data;
          let arrayFinal = [];
          let data = {
            id_pedido: id,
            tipo: dados.tipo,
            status: dados.status,
            acao: dados.acao,
            criado: dados.criado,
            id_utilizador_piscina: dados.id_utilizador_piscina,
            nome_utilizador_piscina:
              dados2.nomeUtilizadorPiscinaEliminarSuspender,
            tipo_utilizador_piscina: dados2.tipoUtilizadorAEliminarSuspender,
            id_utilizador_pediu: dados.id_utilizador_pedido,
            nome_utilizador_pediu: dados2.nomeQuemFezPedido,
            tipo_utilizador_pediu: dados2.tipoUtilizadorQuemFezPedido,
            motivo: dados.motivo,
            id_utilizador_aprovou: dados.id_utilizador_aprovou,
            nome_utilizador_aprovou: dados2.nomeQuemAprovouReprovou,
            tipo_utilizador_aprovou: dados2.tipoUtilizadorQuemAprovouReprovou,
          };
          arrayFinal.push(data);
          setArrayDetalhes(arrayFinal);
          setVistaDetalhes(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }

  function alterarStatus(id, tipo, acao, idUP) {
    setConfirmacaoStatusId(id);
    setTipo(tipo);
    setAcao(acao);
    setIdUtilizadorPiscina(idUP);
    handleClickOpenPopupStatus();
  }

  function alterarStatusPedido() {
    setConfirmacaoStatus(false);
    setFreeze(true);
    var statusFinal = "";
    if (tipoStatus == "aprovado") {
      statusFinal = 1;
    } else if (tipoStatus == "reprovado") {
      statusFinal = 2;
    }
    if (statusFinal) {
      //Executar a ação e aprovar pedido
      if (statusFinal == 1) {
        //Suspender/Ativar piscina
        if (
          (tipo == t("piscina") && acao == t("suspender")) ||
          (tipo == t("piscina") && acao == t("ativar"))
        ) {
          postStatusPiscina({
            id: idUtilizadorPiscina,
            pedido: confirmacaoStatusId,
          })
            .then((res) => {
              if (res.success) {
                putPedido({
                  id: confirmacaoStatusId,
                  status: statusFinal,
                })
                  .then((res) => {
                    if (res.success) {
                      setTimeout(async function () {
                        enviarEmailStatusPedido({
                          id: confirmacaoStatusId,
                        }).then((res2) => {
                          if (res2.success) {
                            setFreeze(false);
                            toast.success(t("pedidoAprovado"));

                            let utilizador = Cookies.get("utilizador");
                            if (utilizador == "superadmin") {
                              navigate("/dashboard_superadmin");
                            } else if (utilizador == "supercomercial") {
                              navigate("/dashboard_supercommercial");
                            }
                            setTipoStatus("");
                            setConfirmacaoStatusId("");
                          } else {
                            setFreeze(false);
                            toast.error(res.error);
                          }
                        });
                      }, 3000);
                    } else {
                      if (res.status == 500) {
                        setFreeze(false);
                        navigate("/500");
                      }
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  })
                  .catch((error) => {
                    setFreeze(false);
                    //toast.warn(t("erroWebservice"));
                  });
              } else {
                if (res.status == 500) {
                  setFreeze(false);
                  navigate("/500");
                }
                setFreeze(false);
                toast.error(res.error);
              }
            })
            .catch((error) => {
              setFreeze(false);
              //toast.warn(t("erroWebservice"));
            });
        } else if (tipo == t("piscina") && acao == t("eliminar")) {
          deletePiscina({
            id: idUtilizadorPiscina,
          })
            .then((res) => {
              if (res.success) {
                putPedido({
                  id: confirmacaoStatusId,
                  status: statusFinal,
                })
                  .then((res) => {
                    if (res.success) {
                      setTimeout(async function () {
                        enviarEmailStatusPedido({
                          id: confirmacaoStatusId,
                        }).then((res2) => {
                          if (res2.success) {
                            setFreeze(false);
                            toast.success(t("pedidoAprovado"));

                            let utilizador = Cookies.get("utilizador");
                            if (utilizador == "superadmin") {
                              navigate("/dashboard_superadmin");
                            } else if (utilizador == "supercomercial") {
                              navigate("/dashboard_supercommercial");
                            }
                            setTipoStatus("");
                            setConfirmacaoStatusId("");
                          } else {
                            setFreeze(false);
                            toast.error(res.error);
                          }
                        });
                      }, 3000);
                    } else {
                      if (res.status == 500) {
                        setFreeze(false);
                        navigate("/500");
                      }
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  })
                  .catch((error) => {
                    setFreeze(false);
                    //toast.warn(t("erroWebservice"));
                  });
              } else {
                if (res.status == 500) {
                  setFreeze(false);
                  navigate("/500");
                }
                setFreeze(false);
                toast.error(res.error);
              }
            })
            .catch((error) => {
              setFreeze(false);
              //toast.warn(t("erroWebservice"));
            });
        } else if (
          (tipo == t("utilizador") && acao == t("suspender")) ||
          (tipo == t("utilizador") && acao == t("ativar"))
        ) {
          postStatusUtilizadores({
            id: idUtilizadorPiscina,
            pedido: confirmacaoStatusId,
          })
            .then((res) => {
              if (res.success) {
                putPedido({
                  id: confirmacaoStatusId,
                  status: statusFinal,
                })
                  .then((res) => {
                    if (res.success) {
                      setTimeout(async function () {
                        enviarEmailStatusPedido({
                          id: confirmacaoStatusId,
                        }).then((res2) => {
                          if (res2.success) {
                            setFreeze(false);
                            toast.success(t("pedidoAprovado"));

                            let utilizador = Cookies.get("utilizador");
                            if (utilizador == "superadmin") {
                              navigate("/dashboard_superadmin");
                            } else if (utilizador == "supercomercial") {
                              navigate("/dashboard_supercommercial");
                            }
                            setTipoStatus("");
                            setConfirmacaoStatusId("");
                          } else {
                            setFreeze(false);
                            toast.error(res.error);
                          }
                        });
                      }, 3000);
                    } else {
                      if (res.status == 500) {
                        setFreeze(false);
                        navigate("/500");
                      }
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  })
                  .catch((error) => {
                    setFreeze(false);
                    //toast.warn(t("erroWebservice"));
                  });
              } else {
                if (res.status == 500) {
                  setFreeze(false);
                  navigate("/500");
                }
                setFreeze(false);
                toast.error(res.error);
              }
            })
            .catch((error) => {
              setFreeze(false);
              //toast.warn(t("erroWebservice"));
            });
        } else if (tipo == t("utilizador") && acao == t("eliminar")) {
          deleteUtilizador({
            id: idUtilizadorPiscina,
          })
            .then((res) => {
              if (res.success) {
                putPedido({
                  id: confirmacaoStatusId,
                  status: statusFinal,
                })
                  .then((res) => {
                    if (res.success) {
                      setTimeout(async function () {
                        enviarEmailStatusPedido({
                          id: confirmacaoStatusId,
                        }).then((res2) => {
                          if (res2.success) {
                            setFreeze(false);
                            toast.success(t("pedidoAprovado"));

                            let utilizador = Cookies.get("utilizador");
                            if (utilizador == "superadmin") {
                              navigate("/dashboard_superadmin");
                            } else if (utilizador == "supercomercial") {
                              navigate("/dashboard_supercommercial");
                            }
                            setTipoStatus("");
                            setConfirmacaoStatusId("");
                          } else {
                            setFreeze(false);
                            toast.error(res.error);
                          }
                        });
                      }, 3000);
                    } else {
                      if (res.status == 500) {
                        setFreeze(false);
                        navigate("/500");
                      }
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  })
                  .catch((error) => {
                    setFreeze(false);
                    //toast.warn(t("erroWebservice"));
                  });
              } else {
                if (res.status == 500) {
                  setFreeze(false);
                  navigate("/500");
                }
                setFreeze(false);
                toast.error(res.error);
              }
            })
            .catch((error) => {
              setFreeze(false);
              //toast.warn(t("erroWebservice"));
            });
        }
      }
      //Reprovar pedido
      else {
        putPedido({
          id: confirmacaoStatusId,
          status: statusFinal,
        })
          .then((res) => {
            if (res.success) {
              setTimeout(async function () {
                enviarEmailStatusPedido({ id: confirmacaoStatusId }).then(
                  (res2) => {
                    if (res2.success) {
                      setFreeze(false);
                      toast.success(t("pedidoReprovado"));

                      let utilizador = Cookies.get("utilizador");
                      if (utilizador == "superadmin") {
                        navigate("/dashboard_superadmin");
                      } else if (utilizador == "supercomercial") {
                        navigate("/dashboard_supercommercial");
                      }
                      setTipoStatus("");
                      setConfirmacaoStatusId("");
                    } else {
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  }
                );
              }, 3000);
            } else {
              if (res.status == 500) {
                setFreeze(false);
                navigate("/500");
              }
              toast.error(res.error);
            }
          })
          .catch((error) => {
            setFreeze(false);
            //toast.warn(t("erroWebservice"));
          });
      }
    }
  }

  return (
    <>
      <div className="listPages4">
        <Header />
        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}

        <div className="divTabela">
          <IconButton
            className="arrowBack"
            variant="text"
            onClick={() => {
              let utilizador = Cookies.get("utilizador");
              if (utilizador == "superadmin") {
                navigate("/dashboard_superadmin");
              } else if (utilizador == "supercomercial") {
                navigate("/dashboard_supercommercial");
              } else if (utilizador == "comercial") {
                navigate("/dashboard_commercial");
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
          {/* <h3 className="titleList">{t("listaPedidos")}</h3> */}
          <Search5
            onSearch={(value) => {
              setSearch(value);
            }}
          />
          <DataGrid
            className="tabela"
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 100,
                flex: 1,
              },
              { field: "tipo", headerName: t("tipo"), type: "string", flex: 1 },
              {
                field: "acao",
                headerName: t("acao"),
                flex: 1,
                type: "string",
              },
              {
                field: "detalhes",
                type: "actions",
                headerName: t("detalhes"),
                width: 100,
                cellClassName: "detalhes",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: 18 }}
                          icon={faEye}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        verDetalhes(id);
                      }}
                    />,
                  ];
                },
              },
              {
                field: "status",
                headerName: t("estado"),
                Width: 250,
                type: "actions",

                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == t("pendente") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12 }}
                            variant="contained"
                            color="warning"
                          >
                            {t("pendente")}
                          </Button>
                        </>
                      )}
                      {params.row.status == t("aprovado") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12 }}
                            variant="contained"
                            color="success"
                          >
                            {t("aprovado")}
                          </Button>
                        </>
                      )}
                      {params.row.status == t("reprovado") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12 }}
                            variant="contained"
                            color="error"
                          >
                            {t("reprovado")}
                          </Button>
                        </>
                      )}
                    </div>
                  );
                },
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 200,
                cellClassName: "actions",
                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == t("pendente") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12, marginRight: 2 }}
                            variant="contained"
                            color="success"
                            onClick={() => {
                              setTipoStatus("aprovado");
                              alterarStatus(
                                params.row.id,
                                params.row.tipo,
                                params.row.acao,
                                params.row.id_utilizador_piscina
                              );
                            }}
                          >
                            {t("aprovado")}
                          </Button>
                          <Button
                            style={{ width: 80, fontSize: 12, marginLeft: 2 }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setTipoStatus("reprovado");
                              alterarStatus(
                                params.row.id,
                                params.row.tipo,
                                params.row.acao,
                                params.row.id_utilizador_piscina
                              );
                            }}
                          >
                            {t("reprovado")}
                          </Button>
                        </>
                      )}
                    </div>
                  );
                },
              },
            ]}
            rows={pedidos}
            checkboxSelection={true}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
        </div>
      </div>

      <Dialog
        onClose={handleClosePopupDetalhes}
        open={detalhes}
        aria-labelledby="customized-dialog-title"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{t("detalhes")}</DialogTitle>
        {vistaDetalhes ? (
          <DialogContent>
            {arrayDetalhes[0].status == 0 && (
              <>
                <Button
                  style={{ width: 80, fontSize: 12 }}
                  variant="contained"
                  color="warning"
                >
                  {t("pendente")}
                </Button>
              </>
            )}
            {arrayDetalhes[0].status == 1 && (
              <>
                <Button
                  style={{ width: 80, fontSize: 12 }}
                  variant="contained"
                  color="success"
                >
                  {t("aprovado")}
                </Button>
              </>
            )}
            {arrayDetalhes[0].status == 2 && (
              <>
                <Button
                  style={{ width: 80, fontSize: 12 }}
                  variant="contained"
                  color="error"
                >
                  {t("reprovado")}
                </Button>
              </>
            )}
            <h5 className="h5Detalhes">{t("infoPedido")}</h5>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("idPedido")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].id_pedido}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("tipoEAcao")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].acao == 1
                  ? t("suspender")
                  : arrayDetalhes[0].acao == 2
                  ? t("eliminar")
                  : t("ativar")}{" "}
                {arrayDetalhes[0].tipo == 1
                  ? t("piscina").toLowerCase()
                  : t("utilizador").toLowerCase()}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("dataPedido")}:{" "}
              <span className="detalhesTexto">{arrayDetalhes[0].criado}</span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("motivo")}:{" "}
              <span className="detalhesTexto">{arrayDetalhes[0].motivo}</span>
            </DialogContentText>
            {arrayDetalhes[0].tipo == 1 && (
              <h5 className="h5Detalhes">{t("infoPiscina")}</h5>
            )}
            {arrayDetalhes[0].tipo == 2 && (
              <h5 className="h5Detalhes">{t("infoUtilizador")}</h5>
            )}
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("id")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].id_utilizador_piscina}
              </span>
            </DialogContentText>
            {arrayDetalhes[0].nome_utilizador_piscina && (
              <DialogContentText
                style={{ paddingBottom: 3, paddingTop: 3 }}
                id="alert-dialog-description"
              >
                {t("nome")}:{" "}
                <span className="detalhesTexto">
                  {arrayDetalhes[0].nome_utilizador_piscina}
                </span>
              </DialogContentText>
            )}
            {arrayDetalhes[0].tipo == 2 &&
              arrayDetalhes[0].tipo_utilizador_piscina != 0 && (
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("tipoUtilizador")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].tipo_utilizador_piscina == 1
                      ? t("cliente")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 2
                      ? t("professor")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 3
                      ? t("professorCliente")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 4
                      ? t("funcionario")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 5
                      ? t("admin_")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 6
                      ? t("comercial")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 7
                      ? t("supercomercial")
                      : arrayDetalhes[0].tipo_utilizador_piscina == 8
                      ? t("superadmin")
                      : null}
                  </span>
                </DialogContentText>
              )}
            <h5 className="h5Detalhes">{t("infoUtilizadorPedido")}</h5>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("idUtilizador")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].id_utilizador_pediu}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("nome")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].nome_utilizador_pediu}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("tipoUtilizador")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].tipo_utilizador_pediu == 1
                  ? t("cliente")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 2
                  ? t("professor")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 3
                  ? t("professorCliente")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 4
                  ? t("funcionario")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 5
                  ? t("admin_")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 6
                  ? t("comercial")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 7
                  ? t("supercomercial")
                  : arrayDetalhes[0].tipo_utilizador_pediu == 8
                  ? t("superadmin")
                  : null}
              </span>
            </DialogContentText>
            {arrayDetalhes[0].status == 2 && (
              <div>
                <h5 className="h5Detalhes">{t("infoUtilizadorAR")}</h5>
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("id")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].id_utilizador_aprovou}
                  </span>
                </DialogContentText>
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("nome")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].nome_utilizador_aprovou}
                  </span>
                </DialogContentText>
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("tipoUtilizador")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].tipo_utilizador_aprovou == 1
                      ? t("cliente")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 2
                      ? t("professor")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 3
                      ? t("professorCliente")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 4
                      ? t("funcionario")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 5
                      ? t("admin_")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 6
                      ? t("comercial")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 7
                      ? t("supercomercial")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 8
                      ? t("superadmin")
                      : null}
                  </span>
                </DialogContentText>
              </div>
            )}
          </DialogContent>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </Dialog>
      <Dialog
        open={confirmacaoStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {tipoStatus == "aprovado"
            ? t("aprovarPedido")
            : tipoStatus == "reprovado"
            ? t("reprovarPedido")
            : null}
        </DialogTitle>
        <DialogContent>
          {tipoStatus == "aprovado" && (
            <DialogContentText id="alert-dialog-description">
              {t("textoAprovar")} {confirmacaoStatusId}? {t("textAcaoPedido")}
            </DialogContentText>
          )}
          {tipoStatus == "reprovado" && (
            <DialogContentText id="alert-dialog-description">
              {t("textoReprovar")} {confirmacaoStatusId}?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="success"
            onClick={() => {
              alterarStatusPedido();
            }}
            autoFocus
          >
            {t("sim")}
          </Button>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="error"
            onClick={handleClosePopupStatus}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Pedidos;
