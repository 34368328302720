import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  enviarEmailPostPiscina,
  postPiscinas,
  postPiscinasIdEntidadeLegal,
  verificarNifEntidade,
} from "../../api/requests/Piscinas";
import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  deleteFuncionario,
  enviarEmailPostFuncionario,
  getFuncionario,
  postFuncionario,
  putFuncionario,
  putStatusFuncionario,
} from "../../api/requests/Funcionarios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";

function EditarFuncionario() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idPiscina, nomePiscina, idFuncionario } = state || {};

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [emailFuncionario, setEmailFuncionario] = useState("");
  const [telefoneFuncionario, setTelefoneFuncionario] = useState("");
  const [cargoFuncionario, setCargoFuncionario] = useState("");
  const [nifFuncionario, setNifFuncionario] = useState("");
  const [moradaFuncionario, setMoradaFuncionario] = useState("");
  const [codigoPostalFuncionario, setCodigoPostalFuncionario] = useState("");
  const [fotoFuncionario, setFotoFuncionario] = useState([]);
  const [statusFuncionario, setStatusFuncionario] = useState("");
  const [dataNascimentoFuncionario, setDataNascimentoFuncionario] = useState(
    new Date()
  );
  const [dadosOriginais, setDadosOriginais] = useState([]);

  //Motivo recebido
  const [motivo, setMotivo] = useState("");
  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  //Validações
  // Responsável
  const [erroNomeFuncionario, setErroNomeFuncionario] = useState("");
  const [erroEmailFuncionario, setErroEmailFuncionario] = useState("");
  const [erroTelefoneFuncionario, setErroTelefoneFuncionario] = useState("");
  const [erroCargoFuncionario, setErroCargoFuncionario] = useState("");
  const [erroNifFuncionario, setErroNifFuncionario] = useState("");
  const [erroMoradaFuncionario, setErroMoradaFuncionario] = useState("");
  const [erroCodigoPostalFuncionario, setErroCodigoPostalFuncionario] =
    useState("");
  const [erroNascimentoFuncionario, setErroNascimentoFuncionario] =
    useState(false);

  // Responsável
  const [mensagemErroEmailFuncionario, setMensagemErroEmailFuncionario] =
    useState("");
  const [mensagemErroTelefoneFuncionario, setMensagemErroTelefoneFuncionario] =
    useState("");
  const [mensagemErroNifFuncionario, setMensagemErroNifFuncionario] =
    useState("");
  const [
    mensagemErroCodigoPostalFuncionario,
    setMensagemErroCodigoPostalFuncionario,
  ] = useState("");
  const [
    mensagemErroNascimentoFuncionario,
    setMensagemErroNascimentoFuncionario,
  ] = useState("");

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getFuncionario(idFuncionario)
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;

          setDadosOriginais(dados);
          setNomeFuncionario(dados.nome);
          setEmailFuncionario(dados.email);
          setTelefoneFuncionario(dados.contacto);
          setCargoFuncionario(dados.cargo);
          setNifFuncionario(dados.nif);
          setMoradaFuncionario(dados.morada);
          setCodigoPostalFuncionario(dados.codigo_postal);
          if (dados.foto) {
            setFotoFuncionario(dados.foto);
          }
          setDataNascimentoFuncionario(dados.data_nascimento_final);
          setStatusFuncionario(dados.status);
          setMotivo(res.data.obs);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function editarFuncionario() {
    setFreeze(true);
    putFuncionario({
      id: idFuncionario,
      nome: nomeFuncionario,
      cargo: cargoFuncionario,
      nif: nifFuncionario,
      email: emailFuncionario,
      contacto: telefoneFuncionario,
      morada: moradaFuncionario,
      codigo_postal: codigoPostalFuncionario,
      foto: fotoFuncionario.length == 0 ? null : fotoFuncionario,
      data_nascimento: new Date(dataNascimentoFuncionario)
        .toISOString()
        .split("T")[0],
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function validacao() {
    if (
      nomeFuncionario &&
      emailFuncionario &&
      telefoneFuncionario &&
      cargoFuncionario &&
      nifFuncionario &&
      moradaFuncionario &&
      codigoPostalFuncionario &&
      dataNascimentoFuncionario
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailFuncionario
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailFuncionario(true);
        setMensagemErroEmailFuncionario(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneFuncionario)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneFuncionario(true);
        setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
      }
      if (emailFuncionario) {
        verificarEmail({ email: emailFuncionario }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              if (res.data.id != idFuncionario) {
                confirmarDadosPreenchidos = false;
                setErroEmailFuncionario(true);
                setMensagemErroEmailFuncionario(res.data.error);
              }
            }
          }
        });
      }
      // Validação do nif
      if (nifFuncionario.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNifFuncionario(true);
        setMensagemErroNifFuncionario(t("validacaoNif"));
      }

      //Validação do código postal

      if (codigoPostalFuncionario < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostalFuncionario(true);
        setMensagemErroCodigoPostalFuncionario(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoFuncionario);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroNascimentoFuncionario(true);
        setMensagemErroNascimentoFuncionario(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarFuncionario();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeFuncionario) {
        setErroNomeFuncionario(true);
      }
      //Validação do email
      if (!emailFuncionario) {
        setErroEmailFuncionario(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailFuncionario
          );
        if (!validacaoEmail) {
          setErroEmailFuncionario(true);
          setMensagemErroEmailFuncionario(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailFuncionario }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                if (res.data.id != idFuncionario) {
                  setErroEmailFuncionario(true);
                  setMensagemErroEmailFuncionario(res.data.error);
                }
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneFuncionario) {
        setErroTelefoneFuncionario(true);
      } else {
        if (!isValidPhoneNumber(telefoneFuncionario)) {
          setErroTelefoneFuncionario(true);
          setMensagemErroTelefoneFuncionario(t("validacaoTelemovel"));
        }
      }
      // Validação do cargo
      if (!cargoFuncionario) {
        setErroCargoFuncionario(true);
      }
      // Validação do nif
      if (!nifFuncionario) {
        setErroNifFuncionario(true);
      } else {
        if (nifFuncionario.length < 9) {
          setErroNifFuncionario(true);
          setMensagemErroNifFuncionario(t("validacaoNif"));
        }
      }
      //Validação da morada
      if (!moradaFuncionario) {
        setErroMoradaFuncionario(true);
      }
      //Validação do código postal
      if (!codigoPostalFuncionario) {
        setErroCodigoPostalFuncionario(true);
      } else {
        if (codigoPostalFuncionario < 7) {
          setErroCodigoPostalFuncionario(true);
          setMensagemErroCodigoPostalFuncionario(t("validacaoCodigoPostal"));
        }
      }
      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimentoFuncionario);
      if (isNaN(isValidDate)) {
        setErroNascimentoFuncionario(true);
        setMensagemErroNascimentoFuncionario(t("dataInvalida"));
      }
    }
  }
  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
        "default";
      document.getElementsByClassName("caminhoPaginas2")[0].style.width =
        "100%";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
      "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setNomeFuncionario(dadosOriginais.nome);
    setEmailFuncionario(dadosOriginais.email);
    setTelefoneFuncionario(dadosOriginais.contacto);
    setCargoFuncionario(dadosOriginais.cargo);
    setNifFuncionario(dadosOriginais.nif);
    setMoradaFuncionario(dadosOriginais.morada);
    setCodigoPostalFuncionario(dadosOriginais.codigo_postal);
    setFotoFuncionario(dadosOriginais.foto);
    setDataNascimentoFuncionario(dadosOriginais.data_nascimento_final);
    setStatusFuncionario(dadosOriginais.status);
    //Limpar erros e mensagens de erro
    setErroNomeFuncionario(false);
    setErroEmailFuncionario(false);
    setErroTelefoneFuncionario(false);
    setErroCargoFuncionario(false);
    setErroNifFuncionario(false);
    setErroMoradaFuncionario(false);
    setErroCodigoPostalFuncionario(false);
    setErroNascimentoFuncionario(false);
    setMensagemErroEmailFuncionario("");
    setMensagemErroTelefoneFuncionario("");
    setMensagemErroNifFuncionario("");
    setMensagemErroCodigoPostalFuncionario("");
    window.scrollTo(0, 0);
  }

  function suspenderFuncionario() {
    if (motivoSuspensao) {
      setSuspender(false);
      setFreeze(true);
      putStatusFuncionario({
        id: idFuncionario,
        obs: motivoSuspensao,
      })
        .then((res) => {
          if (res.success) {
            setFreeze(false);
            toast.success(res.data.msg);
            setMotivoSuspensao("");
            setMotivo(motivoSuspensao);
            setStatusFuncionario(!statusFuncionario);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            setFreeze(false);
            toast.error(res.error);
            setMotivoSuspensao("");
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    } else {
      setFreeze(false)
      setErroMotivoSuspensao(true);
    }
  }

  function eliminarFuncionario() {
    setEliminar(false);
    setFreeze(true);
    deleteFuncionario({ id: idFuncionario })
      .then((res) => {
        if (res.success) {
          toast.success(res.data.msg);
          navigate("/employees", {
            state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
          });
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false);
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  function enviarEmail() {
    definePassEmail({
      email: emailFuncionario,
    }).then((res) => {
      if (res.success) {
        toast.success(res.msg);
        //navigate("/superadmins");
      } else {
        toast.error(res.error);
        //navigate("/superadmins");
      }
    });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="align-left">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/employees", {
                      state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>

            <div className="align-right">
              {/* <h3 className="titleList">{t("editarFuncionario")}</h3> */}
            </div>
          </div>
          <div className="caminhoPaginas2">
            <a className="caminhoPaginas_a" href="/pools">
              {t("piscinas")}
            </a>
            <a className="caminhoPaginas_a2">{`>`}</a>
            <a
              className="caminhoPaginas_a"
              onClick={() => {
                navigate("/editpool", {
                  state: { id: idPiscina },
                });
              }}
            >
              {nomePiscina.substring(0, 25)}...
            </a>
            <a className="caminhoPaginas_a2">{`>`}</a>
            <a
              className="caminhoPaginas_a"
              onClick={() => {
                navigate("/employees", {
                  state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                });
              }}
            >
              {t("listaFuncionario").substring(0, 25)}...
            </a>
          </div>
          <div>
            <div className="formPage4">
              {/* Cards */}
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina2">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    {statusFuncionario ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}

                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscinaReenviar2"
                      variant="outlined"
                      style={{
                        backgroundColor: "olivedrab",
                      }}
                      onClick={() => {
                        enviarEmail();
                      }}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faPaperPlane}
                      />
                      {t("reenviarAtivacao")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    {statusFuncionario ? (
                      <Button
                        className="cardOpcoesPiscina2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2_1
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCircleStop}
                        />
                        {t("suspender")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina2_2"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card2
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupSuspender}
                      >
                        <FontAwesomeIcon
                          className="iconCards"
                          icon={faCirclePlay}
                        />
                        {t("ativar")}
                      </Button>
                    )}
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                  <div className="divCardsPiscinaMobile">
                    <Button
                      id="editarCard"
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      style={{
                        backgroundColor: "olivedrab",
                      }}
                      onClick={() => {
                        enviarEmail();
                      }}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faPaperPlane}
                      />
                      {t("reenviarAtivacao")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {motivo && (
              <div className="formPageSuspensao">
                <Alert severity="error">
                  {statusFuncionario
                    ? t("functionarioAtivo")
                    : t("funcionarioSuspenso")}{" "}
                  | {t("motivo")}: {motivo}
                </Alert>
              </div>
            )}
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Eliminar funcionario */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarFuncionario")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoEliminarFuncionario")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarFuncionario();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Suspender funcionario */}
          <Dialog
            open={suspender}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {statusFuncionario
                ? t("suspenderFuncionario")
                : t("ativarFuncionario")}
            </DialogTitle>
            <DialogContent>
              {statusFuncionario
                ? t("justificacaoSuspencao")
                : t("justificacaoAtivacao")}{" "}
              *
              <FormControl fullWidth>
                <TextField
                  style={{ textAlign: "left", marginTop: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  value={motivoSuspensao}
                  error={erroMotivoSuspensao}
                  onChange={(e) => {
                    setMotivoSuspensao(e.target.value);
                    setErroMotivoSuspensao(false);
                  }}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, e.target.value)
                  //     .toString()
                  //     .slice(0, 500);
                  // }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupSuspender}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  suspenderFuncionario();
                }}
                autoFocus
              >
                {statusFuncionario ? t("suspender") : t("ativar")}
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <div className="formPage">
              <Box className="formTextField" ref={ref}>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nomeFuncionario}
                      error={erroNomeFuncionario}
                      onChange={(e) => {
                        setNomeFuncionario(e.target.value);
                        setErroNomeFuncionario(false);
                      }}
                    />
                  </FormControl>
                  {/* Email */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("email")}
                      value={emailFuncionario}
                      error={erroEmailFuncionario}
                      helperText={mensagemErroEmailFuncionario}
                      onChange={(e) => {
                        setEmailFuncionario(e.target.value.toLocaleLowerCase());
                        setErroEmailFuncionario(false);
                        setMensagemErroEmailFuncionario("");
                      }}
                    />
                  </FormControl>
                  {/* Telefone */}
                  <FormControl className="formTextField3" fullWidth>
                    <PhoneInput
                      disabled={edicao}
                      defaultCountry="PT"
                      labels={
                        i18n.language == "pt"
                          ? pt
                          : i18n.language == "es"
                          ? es
                          : en
                      }
                      value={telefoneFuncionario}
                      error={erroTelefoneFuncionario}
                      helperText={mensagemErroTelefoneFuncionario}
                      onChange={(e) => {
                        setTelefoneFuncionario(e);
                        setErroTelefoneFuncionario(false);
                        setMensagemErroTelefoneFuncionario("");
                      }}
                      inputComponent={PhoneNumber}
                    />
                  </FormControl>
                  {/* Cargo */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("cargo")}
                      value={cargoFuncionario}
                      error={erroCargoFuncionario}
                      onChange={(e) => {
                        setCargoFuncionario(e.target.value);
                        setErroCargoFuncionario(false);
                      }}
                    />
                  </FormControl>
                  {/* Nif */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nifFuncionario}
                      error={erroNifFuncionario}
                      helperText={mensagemErroNifFuncionario}
                      onChange={(e) => {
                        setNifFuncionario(e.target.value);
                        setErroNifFuncionario(false);
                      }}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("rua")}
                      value={moradaFuncionario}
                      error={erroMoradaFuncionario}
                      onChange={(e) => {
                        setMoradaFuncionario(e.target.value);
                        setErroMoradaFuncionario(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      disabled={edicao}
                      type="text"
                      variant="standard"
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      value={codigoPostalFuncionario}
                      required
                      format="####-###"
                      error={erroCodigoPostalFuncionario}
                      helperText={mensagemErroCodigoPostalFuncionario}
                      onChange={(e) => {
                        setCodigoPostalFuncionario(e.target.value);
                        setErroCodigoPostalFuncionario(false);
                        setMensagemErroCodigoPostalFuncionario("");
                      }}
                    />
                  </FormControl>
                  {/* Data de nascimento */}
                  <FormControl className="formTextField3" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disableFuture
                        disabled={edicao}
                        label={t("dataNascimento")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        value={dataNascimentoFuncionario}
                        onChange={(e) => {
                          setDataNascimentoFuncionario(e);
                          setErroNascimentoFuncionario(false);
                          setMensagemErroNascimentoFuncionario("");
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroNascimentoFuncionario}
                            helperText={mensagemErroNascimentoFuncionario}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {/* Foto */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")}
                    </p>
                    <label
                      id="fotoPiscina"
                      htmlFor="file-upload"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarImagem")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        document.getElementById(
                          "fotoPiscina"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById(
                          "tituloFotoPiscina"
                        ).style.color = Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setFotoFuncionario(reader.result);
                        };
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {fotoFuncionario.length > 0 && (
                        <img
                          style={{ marginTop: 10 }}
                          width={150}
                          height={150}
                          src={fotoFuncionario}
                        />
                      )}
                    </div>
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarFuncionario;
