import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Search2 from "../../components/DataTable/Search2";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faEdit,
  faEye,
  faPen,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ActiveQrCodes, listQrCodes } from "../../api/requests/Qrcode";
import { listaClientesPiscina } from "../../api/requests/PiscinaAgua";
import moment from "moment";
import Nav from "../../components/Nav";

function QrcodeFuncionario() {
  const [freeze, setFreeze] = useState(false);
  const [qrcodes, setQrcodes] = useState([]);
  const [qrcodesCopy, setQrcodesCopy] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clientesCopy, setClientesCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [codigo, setCodigo] = useState("");
  const [uuid, setuuid] = useState("");

  const [qrCodeStatus, setQrCodeStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["hora_fim", "hora_inicio"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["hora_fim", "hora_inicio"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        {/* <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        /> */}
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arrayQrcodes = qrcodesCopy;
      arrayQrcodes = arrayQrcodes.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.id_aula_qr.toLowerCase().includes(search.toLowerCase())
      );
      setQrcodes(arrayQrcodes);
    } else {
      setQrcodes(qrcodesCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaClientesPiscina({})
      .then((res) => {
        let arrayFinal = [];
        if (res.success) {
          res.data.data.forEach((element) => {
            const linha = {
              id_utilizador: element.id_utilizador,
              nome: element.nome,
              nif: element.nif,
            };
            arrayFinal.push(linha);
            setClientes(arrayFinal);
            setClientesCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
        listQrCodes({
          id_piscina: idPiscina,
          id_entidade_exploradora: idEntidade,
        })
          .then((res2) => {
            if (res2.success) {
              let arrayFinal2 = [];
              res2.data.data.forEach((element) => {
                for (let i = 0; i < arrayFinal.length; i++) {
                  if (element.id_utilizador == arrayFinal[i].id_utilizador) {
                    const linha = {
                      id: element.uuid,
                      nome: arrayFinal[i].nome,
                      nif: arrayFinal[i].nif,
                      data: moment(element.data).format("DD-MM-YYYY"),
                      hora_inicio: moment(element.hora_inicio, "HH:mm").format(
                        "HH:mm"
                      ),
                      hora_fim: moment(element.hora_fim, "HH:mm").format(
                        "HH:mm"
                      ),
                      qr_code: element.qr_code,
                      codigo: element.id_usa_ticket ? "cGFjaw==" : "dHVybWE=",
                    };
                    arrayFinal2.push(linha);
                  }
                }
                setQrcodes(arrayFinal2);
                setQrcodesCopy(arrayFinal2);
              });
            } else {
              if (res2.status == 500) {
                navigate("/500");
              } else if (res2.status == 403) {
                let utilizador = Cookies.get("utilizador");
                if (utilizador == "superadmin") {
                  navigate("/dashboard_superadmin");
                } else if (utilizador == "supercomercial") {
                  navigate("/dashboard_supercommercial");
                } else if (utilizador == "comercial") {
                  navigate("/dashboard_commercial");
                } else if (utilizador == "admin") {
                  navigate("/dashboard_admin");
                } else if (utilizador == "funcionario") {
                  navigate("/dashboard_employee");
                } else if (utilizador == "professor") {
                  navigate("/dashboard_teacher");
                } else if (utilizador == "cliente") {
                  navigate("/dashboard_customer");
                }
              }
              toast.error(res2.error);
            }
          })
          .catch((error) => {
            //toast.warn(t("erroWebservice"));
          });
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Dialog
        open={loading}
        onClose={() => {
          setLoading(false);
          setQrCodeStatus(false);
          setQrCode("");
          setCodigo("");
          setuuid("");
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">{t("Ativarqrcode")}</DialogTitle>
          {qrCodeStatus && (
            <DialogContentText>{t("erroQrCode")}</DialogContentText>
          )}
          {!qrCodeStatus && !qrCode ? (
            <CircularProgress
              disableShrink
              style={{
                marginTop: 20,
              }}
            />
          ) : (
            <img
              src={qrCode}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                maxHeight: "80%",
              }}
            />
          )}

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("textoAtivar")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ borderRadius: "20px" }}
              variant="contained"
              color="success"
              onClick={() => {
                setFreeze(true);
                ActiveQrCodes({
                  id: uuid,
                  codigo: codigo,
                }).then((res) => {
                  if (res.success) {
                    setFreeze(false);
                    toast.success(t("entradaaprovado"));
                    navigate("/dashboard_admin");
                  } else {
                    setFreeze(false)
                    toast.error(t("erroentrada"));
                  }
                });
              }}
              autoFocus
            >
              {t("ativarEntrada")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}
        <h3 className="titleList">{t("qrcode")}</h3>
        <div className="divTabelaM">
          {/* <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/dashboard_admin");
              }}
              size={window.innerWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Search2
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },

              {
                field: "nif",
                headerName: t("nif"),
                flex: 1,
                type: "string",
              },

              {
                field: "data",
                headerName: t("data"),
                flex: 1,
                type: "date",
              },
              {
                field: "hora_inicio",
                headerName: t("horainicio"),
                flex: 1,
                type: "date",
                hide: columnsHidden.includes("hora_inicio"),
              },
              {
                field: "hora_fim",
                headerName: t("horafim"),
                flex: 1,
                type: "date",
                hide: columnsHidden.includes("hora_fim"),
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 70,
                cellClassName: "actions",
                getActions: ({ row }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={({}) => {
                        setCodigo(row.codigo);
                        setuuid(row.id);
                        setQrCode(row.qr_code);
                        setLoading(true);
                        setLoading(false)
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={qrcodes}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrcodeFuncionario;
