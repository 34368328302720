import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faChildren,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  enviarEmailPostPiscina,
  postPiscinas,
  postPiscinasIdEntidadeLegal,
  verificarNifEntidade,
} from "../../api/requests/Piscinas";
import {
  definePass,
  definePassEmail,
  verificarEmail,
  verificarNif,
} from "../../api/requests/Registo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import {
  putFilho,
  getFilho,
  deleteFilho2,
} from "../../api/requests/ContaFamiliar";
import dayjs from "dayjs";

function EditarFilhoCliente() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idCliente, idFilho } = state || {};
  const maxDate = dayjs().subtract(16, "year").toDate();

  const [edicao, setEdicao] = useState(true);

  const [freeze, setFreeze] = useState(false);

  const [nome, setNome] = useState("");
  const [nif, setNif] = useState("");
  const [morada, setMorada] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [foto, setFoto] = useState([]);
  const [dataNascimento, setDataNascimento] = useState(new Date());

  const [erroNome, setErroNome] = useState(false);
  const [erroNif, setErroNif] = useState(false);
  const [erroMorada, setErroMorada] = useState(false);
  const [erroCodigoPostal, setErroCodigoPostal] = useState(false);
  const [erroFoto, setErroFoto] = useState("");
  const [erroDataNascimento, setErroDataNascimento] = useState(false);

  const [mensagemErroNif, setMensagemErroNif] = useState("");
  const [mensagemErroMorada, setMensagemErroMorada] = useState("");
  const [mensagemErroNome, setMensagemErroNome] = useState("");
  const [mensagemErroCodigoPostal, setMensagemErroCodigoPostal] = useState("");
  const [mensagemErroDataNascimento, setMensagemErroDataNascimento] =
    useState("");
  const [dadosOriginais, setDadosOriginais] = useState([]);

  //Carregamento dos dados
  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  //Popup suspensao
  const [suspender, setSuspender] = useState(false);
  const [motivoSuspensao, setMotivoSuspensao] = useState("");
  const [erroMotivoSuspensao, setErroMotivoSuspensao] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };

  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getFilho({ idFilho: idFilho, id: id })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];
          setDadosOriginais(dados);
          setNome(dados.nome);
          setNif(dados.nif);
          setMorada(dados.morada);
          setCodigoPostal(dados.codigo_postal);
          setDataNascimento(dados.data_nascimento_final);

          if (dados.foto) {
            setFoto(dados.foto);
          }
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function eliminarFilho() {
    setFreeze(true);
    deleteFilho2({ id: idFilho, idUtilizador: idCliente })
      .then((res) => {
        if (res.success) {
          toast.success(t("filhoEliminadaSucesso"));
          navigate("/myfamily");
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  function editarFilho() {
    setFreeze(true);
    putFilho({
      id: idFilho,
      nome: nome,
      nif: nif,
      morada: morada,
      codigo_postal: codigoPostal,
      data_nascimento: new Date(dataNascimento).toISOString().split("T")[0],
      foto: foto.length == 0 ? null : foto,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
      setFreeze(false)
  }

  async function validacao() {
    if (nome && nif && morada && codigoPostal && dataNascimento) {
      let confirmarDadosPreenchidos = true;

      // Validação do nif
      if (nif.length < 9) {
        confirmarDadosPreenchidos = false;
        setErroNif(true);
        setMensagemErroNif(t("validacaoNif"));
      } else {
        await verificarNif({ nif: nif }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroNif(true);
              setMensagemErroNif(res.data.error);
            }
          }
        });
      }

      //Validação do código postal

      if (codigoPostal < 7) {
        confirmarDadosPreenchidos = false;
        setErroCodigoPostal(true);
        setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimento);
      if (isNaN(isValidDate)) {
        confirmarDadosPreenchidos = false;
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("dataInvalida"));
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        editarFilho();
        window.scrollTo(0, 0);
      } else {
        scrollToDiv2();
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }

      // Validação do nif
      if (!nif) {
        setErroNif(true);
      } else {
        if (nif.length < 9) {
          setErroNif(true);
          setMensagemErroNif(t("validacaoNif"));
        }
      }
      verificarNif({ nif: nif }).then((res) => {
        if (res.success) {
          if (res.data.error) {
            setErroNif(true);
            setMensagemErroNif(res.data.error);
          }
        }
      });
      //Validação da morada
      if (!morada) {
        setErroMorada(true);
      }
      //Validação do código postal
      if (!codigoPostal) {
        setErroCodigoPostal(true);
      } else {
        if (codigoPostal < 7) {
          setErroCodigoPostal(true);
          setMensagemErroCodigoPostal(t("validacaoCodigoPostal"));
        }
      }

      //Validação da data de nascimento
      let isValidDate = Date.parse(dataNascimento);
      if (isNaN(isValidDate)) {
        setErroDataNascimento(true);
        setMensagemErroDataNascimento(t("dataInvalida"));
      }
    }
  }

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
        "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName("cardOpcoesPiscina1_1")[0].style.cursor =
      "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setNome(dadosOriginais.nome);
    setNif(dadosOriginais.nif);
    setMorada(dadosOriginais.morada);
    setCodigoPostal(dadosOriginais.codigo_postal);
    setFoto(dadosOriginais.foto);
    setDataNascimento(dadosOriginais.data_nascimento_final);

    //Limpar erros e mensagens de erro
    setErroNome(false);
    setErroNif(false);
    setErroMorada(false);
    setErroCodigoPostal(false);
    setErroDataNascimento(false);
    setMensagemErroNif("");
    setMensagemErroCodigoPostal("");
    window.scrollTo(0, 0);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="align-left">
              {edicao ? (
                <IconButton
                  className="arrowBack"
                  variant="text"
                  onClick={() => {
                    navigate("/myfamily");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
              ) : null}
            </div>

            {/* <div className="align-right">
              <h3 className="titleList">{t("editarFilho")}</h3>
            </div> */}
          </div>

          <div>
            <div className="formPage4">
              {/* Cards */}
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  {/* <div className="cardsDivPiscina22">
                    <Button
                      className="cardOpcoesFilhoEliminar"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div> */}
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>

                  {/* <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Eliminar */}
          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarFilho")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoEliminarFilho")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarFilho();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <div className="formPage">
              <Box className="formTextField" ref={ref}>
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nome}
                      error={erroNome}
                      onChange={(e) => {
                        setNome(e.target.value);
                        setErroNome(false);
                      }}
                    />
                  </FormControl>
                  {/* Nif */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={true}
                      required
                      type="number"
                      variant="standard"
                      label={t("nif")}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={nif}
                      error={erroNif}
                      helperText={mensagemErroNif}
                      onChange={(e) => {
                        setNif(e.target.value);
                        setErroNif(false);
                      }}
                    />
                  </FormControl>
                  {/* Rua */}
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("morada")}
                      value={morada}
                      error={erroMorada}
                      onChange={(e) => {
                        setMorada(e.target.value);
                        setErroMorada(false);
                      }}
                    />
                  </FormControl>
                  {/* Código Postal */}
                  <FormControl className="formTextField3" fullWidth>
                    <PatternFormat
                      disabled={edicao}
                      type="text"
                      variant="standard"
                      {...{
                        label: t("codigoPostal"),
                        variant: "standard",
                      }}
                      customInput={TextField}
                      value={codigoPostal}
                      required
                      format="####-###"
                      error={erroCodigoPostal}
                      helperText={mensagemErroCodigoPostal}
                      onChange={(e) => {
                        setCodigoPostal(e.target.value);
                        setErroCodigoPostal(false);
                        setMensagemErroCodigoPostal("");
                      }}
                    />
                  </FormControl>
                  {/* Data de nascimento */}
                  <FormControl className="formTextField3" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        disableFuture
                        disabled={edicao}
                        label={t("dataNascimento")}
                        openTo="day"
                        views={["day", "year", "month"]}
                        minDate={maxDate}
                        value={dataNascimento}
                        onChange={(e) => {
                          setDataNascimento(e);
                          setErroDataNascimento(false);
                          setMensagemErroDataNascimento("");
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            required
                            error={erroDataNascimento}
                            helperText={mensagemErroDataNascimento}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {/* Foto */}
                  <FormControl className="formTextField3" fullWidth>
                    <p
                      id="tituloFotoPiscina"
                      style={{
                        marginTop: 10,
                        color: Colors.textFields,
                        fontSize: 15,
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {t("foto")}
                    </p>
                    <label
                      id="fotoPiscina"
                      htmlFor="file-upload"
                      className="custom-file-upload"
                    >
                      <i className="fa fa-cloud-upload"></i>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ paddingTop: 4, paddingRight: 4 }}
                      />
                      {t("carregarImagem")}
                    </label>
                    <input
                      disabled={edicao}
                      id="file-upload"
                      style={{ paddingTop: 0 }}
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        document.getElementById(
                          "fotoPiscina"
                        ).style.borderColor = Colors.ativo;
                        document.getElementById(
                          "tituloFotoPiscina"
                        ).style.color = Colors.textFields;
                        const file = e.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onload = () => {
                          setFoto(reader.result);
                        };
                      }}
                    ></input>
                    {/* Imagem carregada */}
                    <div>
                      {foto.length > 0 && (
                        <img
                          style={{ marginTop: 10 }}
                          width={150}
                          height={150}
                          src={foto}
                        />
                      )}
                    </div>
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarFilhoCliente;
