import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, IconButton, Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCircleCheck,
  faCircleXmark,
  faPen,
  faChildren,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { getFilhos } from "../../api/requests/ContaFamiliar";
import Search2 from "../../components/DataTable/Search2";
import {
  verStatus,
  postStatusContaFamiliar,
} from "../../api/requests/ContaFamiliar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ListaClientes from "../Clientes/Clientes";

function ListaFilhosA() {
  const [Filhos, setFilhos] = useState([]);
  const [FilhosCopy, setFilhosCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);

  const { state } = useLocation();
  const { idPiscina, idPai } = state || {};

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayFilhos = FilhosCopy;
      arrayFilhos = arrayFilhos.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setFilhos(arrayFilhos);
    } else {
      setFilhos(FilhosCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getFilhos({ id: idPai, idPiscina: idPiscina })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_filho,
              nome: element.nome,
              nif: element.nif,
            };
            arrayFinal.push(linha);
            setFilhos(arrayFinal);
            setFilhosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="listPages4">
        <Header />

        <div className="topleftcliente">
          <IconButton
            className="arrowBack"
            variant="text"
            size={isMobile ? "small" : "medium"}
            onClick={() => {
              navigate("/editcustomera", {
                state: {
                  idPiscina: idPiscina,

                  idCliente: idPai,
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        </div>

        <div className="divTabela">
          {/* <h3 className="titleList">{t("listaFilhos")}</h3> */}

          <div className="col-0" style={{ textAlign: "end" }}>
            <div className="caminhoPaginas3">
              <a
                className="caminhoPaginas_a"
                onClick={() => {
                  navigate("/poolcustomersa", {
                    state: { idPiscina: idPiscina },
                  });
                }}
              >
                {t("listaClientes")}
              </a>
              <a className="caminhoPaginas_a2">{`>`}</a>
              <a
                className="caminhoPaginas_a"
                onClick={() => {
                  navigate("/editcustomera", {
                    state: {
                      idPiscina: idPiscina,
                      idCliente: idPai,
                    },
                  });
                }}
              >
                {t("editarCliente")}
              </a>
            </div>
            <Button
              className="addButton1"
              variant="contained"
              onClick={() => {
                navigate("/createchilda", {
                  state: {
                    idPiscina: idPiscina,
                    idPai: idPai,
                  },
                });
              }}
            >
              {t("adicionar")}
            </Button>
          </div>

          <DataGrid
            className="tabela"
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 100,
                flex: 1,
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              {
                field: "nif",
                headerName: t("nif"),
                flex: 1,
                type: "string",
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        navigate("/editchilda", {
                          state: {
                            idPiscina: idPiscina,
                            idCliente: idPai,
                            idFilho: id,
                          },
                        });
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={Filhos}
            checkboxSelection={true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
        </div>
        {Cookies.get("utilizador") == "superadmin" ||
        Cookies.get("utilizador") == "supercomercial" ||
        Cookies.get("utilizador") == "comercial" ||
        Cookies.get("utilizador") == "funcionario" ? null : (
          <Footer />
        )}
      </div>
    </>
  );
}

export default ListaFilhosA;
