import Cookies from "js-cookie";
import {
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestPost2,
  makeRequestPost3,
  makeRequestPut2,
} from "../api";

//Get Piscinas
export function getPiscinas() {
  let token = Cookies.get("token");
  return makeRequestGet2("piscinas/all", token);
}

//Get Piscinas sem foto
export function getPiscinasSemFotos() {
  let token = Cookies.get("token");
  return makeRequestGet2("piscinas/all2", token);
}

//Post Piscinas
export function postPiscinas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/", dados, token);
}

//Get Piscinas pelo Id
export function getPiscinasId(id, dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`piscinas/id/${id}`, dados, token);
}

//Post Piscinas - c/ id da entidade legal
export function postPiscinasIdEntidadeLegal(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/post2", dados, token);
}

//Enviar email apos criar piscina
export function enviarEmailPostPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/email-active-account", dados, token);
}

//Verificar nif da entidade legal
export function verificarNifEntidade(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/confirmVAT", dados, token);
}

//Editar piscina
export function putPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("piscinas/", dados, token);
}

//Eliminar piscina
export function deletePiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("piscinas/", dados, token);
}

//Suspender/Ativar piscina
export function postStatusPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/status", dados, token);
}

//Lista de modalidades da piscina
export function getModalidadesPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/modalidades", dados, token);
}

//Lista de packs da piscina de acordo com a categoria do utilizador
export function getPacksPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/packs", dados, token);
}

//Categorias das turmas de uma piscina
export function getCategoriaTurmas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/aulas_categoria", dados, token);
}

//Categorias de nado livre de uma piscina
export function getCategoriaNadoLivre(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/nadolivre_categoria", dados, token);
}

//Categoria do utilizador
export function getCategoriaUtilizador(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/categoria", dados, token);
}

//Get modalidades de aulas da piscina tendo em conta a categoria do utilizador
export function getModalidadesAulasPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/modalidades2", dados, token);
}

//Get turmas de uma piscina e de uma certa categoria e modalidade
export function getTurmas(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/turmas", dados, token);
}


export function getListaEE(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/entidades-exploradoras", dados, token);
}


export function getEL(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/entidade-legal", dados, token);
}


export function getEE(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/entidade-exploradora", dados, token);
}

export function putEE(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("piscinas/entidade-exploradora", dados, token);
}

export function getListaFechos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/list/fechos", dados, token);
}

//Post Fecho
export function postFechos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/fecho", dados, token);
}


//Enviar email fecho piscina
export function enviarEmailFecho(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/fecho/email", dados, token);
}


//Eliminar fecho
export function deleteFecho(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("piscinas/fecho", dados, token);
}


//Enviar email reabertura piscina
export function enviarEmailFechoReabertura(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/fecho/email2", dados, token);
}


//Get fecho
export function getFechoId(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("piscinas/fecho/id", dados, token);
}