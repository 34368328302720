import Cookies from "js-cookie";
import {
  makeRequestGet2,
  makeRequestPost,
  makeRequestPost2,
  makeRequestPut2,
} from "../api";

//Post pedido
export function postPedidos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pedidos/", dados, token);
}

//Get pedidos superadmin e supercomercial
export function getPedidosAll(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pedidos/all", dados, token);
}

//Get pedido pelo id
export function getPedidoId(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`pedidos/${id}`, {}, token);
}

//Alterar status do pedido
export function putPedido(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`pedidos/status`, dados, token);
}

//Get pedidos - num de pedidos
export function getNumPedidos(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`pedidos/all_count`, dados, token);
}

//Get pedidos - do utilizador
export function getPedidosUtilizador(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`pedidos/all_user`, dados, token);
}

//Email após a criação de um pedido
export function enviarEmailNovoPedido() {
  let token = Cookies.get("token");
  return makeRequestPost2(`pedidos/email/new`, {}, token);
}

//Email após a criação de um pedido
export function enviarEmailNovoPedido2(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`pedidos/email/new2`, dados, token);
}

//Email após a atualização do estado de um pedido
export function enviarEmailStatusPedido(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`pedidos/email/status`, dados, token);
}
