import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";
import es from "react-phone-number-input/locale/es";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneNumber from "../../components/PhoneNumber";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneNumber2 from "../../components/PhoneNumber2";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  enviarEmailPostPiscina,
  postPiscinas,
  postPiscinasIdEntidadeLegal,
  verificarNifEntidade,
} from "../../api/requests/Piscinas";
import {
  definePass,
  definePassEmail,
  verificarEmail,
} from "../../api/requests/Registo";
import {
  enviarEmailPostProfessor,
  postProfessor,
} from "../../api/requests/Professores";

function CriarProfessor() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const { state } = useLocation();
  const { idPiscina, nomePiscina } = state || {};

  const [freeze, setFreeze] = useState(false);

  //Responsável
  const [nomeProfessor, setNomeProfessor] = useState("");
  const [emailProfessor, setEmailProfessor] = useState("");
  const [telefoneProfessor, setTelefoneProfessor] = useState("");
  const [cargoProfessor, setCargoProfessor] = useState("");

  //Validações
  // Responsável
  const [erroNomeProfessor, setErroNomeProfessor] = useState("");
  const [erroEmailProfessor, setErroEmailProfessor] = useState("");
  const [erroTelefoneProfessor, setErroTelefoneProfessor] = useState("");
  const [erroCargoProfessor, setErroCargoProfessor] = useState("");

  // Responsável
  const [mensagemErroEmailProfessor, setMensagemErroEmailProfessor] =
    useState("");
  const [mensagemErroTelefoneProfessor, setMensagemErroTelefoneProfessor] =
    useState("");

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
  }, []);

  function criarSuperAdmin() {
    setFreeze(true);
    postProfessor({
      id: idPiscina,
      nome: nomeProfessor,
      contacto: telefoneProfessor,
      email: emailProfessor,
      cargo: cargoProfessor,
    }).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          await enviarEmailPostProfessor({
            id: res.data.id,
          }).then((res) => {
            if (res.success) {
              toast.success(res.data.msg);
              setFreeze(false);
              navigate("/teachers", {
                state: { idPiscina: id, nomePiscina: nomePiscina },
              });
            } else {
              toast.error(res.error);
              setFreeze(false);
            }
          });
        }, 3000);
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      nomeProfessor &&
      emailProfessor &&
      telefoneProfessor &&
      cargoProfessor
    ) {
      let confirmarDadosPreenchidos = true;

      let validacaoEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailProfessor
        );
      if (!validacaoEmail) {
        confirmarDadosPreenchidos = false;
        setErroEmailProfessor(true);
        setMensagemErroEmailProfessor(t("emailInvalido"));
      }
      if (!isValidPhoneNumber(telefoneProfessor)) {
        confirmarDadosPreenchidos = false;
        setErroTelefoneProfessor(true);
        setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
      }
      if (emailProfessor) {
        verificarEmail({ email: emailProfessor }).then((res) => {
          if (res.success) {
            if (res.data.error) {
              confirmarDadosPreenchidos = false;
              setErroEmailProfessor(true);
              setMensagemErroEmailProfessor(res.data.error);
            }
          }
        });
      }

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarSuperAdmin();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!nomeProfessor) {
        setErroNomeProfessor(true);
      }
      //Validação do email
      if (!emailProfessor) {
        setErroEmailProfessor(true);
      } else {
        let validacaoEmail =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            emailProfessor
          );
        if (!validacaoEmail) {
          setErroEmailProfessor(true);
          setMensagemErroEmailProfessor(t("emailInvalido"));
        } else {
          verificarEmail({ email: emailProfessor }).then((res) => {
            if (res.success) {
              if (res.data.error) {
                setErroEmailProfessor(true);
                setMensagemErroEmailProfessor(res.data.error);
              }
            }
          });
        }
      }
      //Validação do telefone
      if (!telefoneProfessor) {
        setErroTelefoneProfessor(true);
      } else {
        if (!isValidPhoneNumber(telefoneProfessor)) {
          setErroTelefoneProfessor(true);
          setMensagemErroTelefoneProfessor(t("validacaoTelemovel"));
        }
      }
      // Validação do cargo
      if (!cargoProfessor) {
        setErroCargoProfessor(true);
      }
    }
  }

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="align-left">
            <IconButton
              className="arrowBack"
              variant="text"
              onClick={() => {
                navigate("/teachers", {
                  state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </div>

          {/* <div className="align-right">
            <h3 className="titleList">{t("adicionarProfessor")}</h3>
          </div> */}
        </div>
        <div className="caminhoPaginas2">
          <a className="caminhoPaginas_a" href="/pools">
            {t("piscinas")}
          </a>
          <a className="caminhoPaginas_a2">{`>`}</a>
          <a
            className="caminhoPaginas_a"
            onClick={() => {
              navigate("/editpool", {
                state: { id: idPiscina },
              });
            }}
          >
            {nomePiscina.substring(0, 25)}...
          </a>
          <a className="caminhoPaginas_a2">{`>`}</a>
          <a
            className="caminhoPaginas_a"
            onClick={() => {
              navigate("/teachers", {
                state: { idPiscina: idPiscina, nomePiscina: nomePiscina },
              });
            }}
          >
            {t("listaProfessor").substring(0, 25)}...
          </a>
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                {/* Nome */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("nome")}
                    value={nomeProfessor}
                    error={erroNomeProfessor}
                    onChange={(e) => {
                      setNomeProfessor(e.target.value);
                      setErroNomeProfessor(false);
                    }}
                  />
                </FormControl>
                {/* Email */}
                <FormControl className="formTextField3" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("email")}
                    value={emailProfessor}
                    error={erroEmailProfessor}
                    helperText={mensagemErroEmailProfessor}
                    onChange={(e) => {
                      setEmailProfessor(e.target.value.toLocaleLowerCase());
                      setErroEmailProfessor(false);
                      setMensagemErroEmailProfessor("");
                    }}
                  />
                </FormControl>
                {/* Telefone */}
                <FormControl className="formTextField3" fullWidth>
                  <PhoneInput
                    defaultCountry="PT"
                    labels={
                      i18n.language == "pt"
                        ? pt
                        : i18n.language == "es"
                        ? es
                        : en
                    }
                    value={telefoneProfessor}
                    error={erroTelefoneProfessor}
                    helperText={mensagemErroTelefoneProfessor}
                    onChange={(e) => {
                      setTelefoneProfessor(e);
                      setErroTelefoneProfessor(false);
                      setMensagemErroTelefoneProfessor("");
                    }}
                    inputComponent={PhoneNumber}
                  />
                </FormControl>
                {/* Cargo */}
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="text"
                    variant="standard"
                    label={t("cargo")}
                    value={cargoProfessor}
                    error={erroCargoProfessor}
                    onChange={(e) => {
                      setCargoProfessor(e.target.value);
                      setErroCargoProfessor(false);
                    }}
                  />
                </FormControl>
              </div>
            </Box>
          </div>
        </div>
        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarProfessor;
