import Cookies from "js-cookie";
import { makeRequestPost2 } from "../api";

//Get datas  disponíveis para banho livre na piscina
export function getDatasBanhosLivres(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/datas/nado_livre", dados, token);
}

//Get horas  disponíveis para banho livre na piscina
export function getHorasBanhosLivres(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/horas/nado_livre", dados, token);
}

//Verificar se o utilizador tem pack ou não
export function getPackUtilizador(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/pack/nado_livre", dados, token);
}

//Verificar sobreposição de marcações
export function getVerificacaoSobreposicao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/verificacao", dados, token);
}

//Lista de packs
export function getListaPacks(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/lista_pack", dados, token);
}

//Informação compra - Packs
export function getInfoCompra(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/preco/nado_livre", dados, token);
}

//Informação compra - Aulas
export function getInfoCompra2(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/preco/aulas", dados, token);
}

//Registar marcação inativa
export function registarMarcacaoInativa(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/pack/registo-inativo", dados, token);
}

//Registar marcação
export function registarMarcacao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/pack/registo", dados, token);
}

//Próximas marcações
export function proximasMarcacoes(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/lista", dados, token);
}

export function proximasMarcacoesU(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/listau", dados, token);
}

//Cancelar marcação
export function cancelarMarcacao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/marcacao/cancel", dados, token);
}

//Cancelar inscrição
export function cancelarInscricao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/inscricao/cancel", dados, token);
}

//Email cancelamento marcação
export function emailCancelarMarcacao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/cancel1", dados, token);
}

//Email cancelamento inscrição
export function emailCancelarInscricao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("pagamentos/email/cancel2", dados, token);
}

//Pagamento balcão
export function marcacaoBalcao(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/marcacao/balcao", dados, token);
}

export function turmasInscrito(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/lista/turmas", dados, token);
}

export function getDisponibilidade(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("marcacoes/disponibilidade-pistas", dados, token);
}
