import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Paper,
  Select,
  TextField,
  Radio,
  RadioGroup,
  Typography,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";

import "react-phone-number-input/style.css";

import { toast } from "react-toastify";

import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  getPiscinaAgua,
  editarPiscinaA,
  eliminarPiscinaA,
} from "../../api/requests/PiscinaAgua";

function EditarPiscinaAguaA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const { state } = useLocation();
  const { idPiscinaAgua } = state || {};

  const [edicao, setEdicao] = useState(true);
  const [freeze, setFreeze] = useState(false);

  const [nomePiscinaAgua, setNomePiscinaAgua] = useState("");
  const [numeroPistas, setNumeroPistas] = useState(1);
  const [tipoAguaPiscinaAgua, setTipoAguaPiscinaAgua] = useState("");
  const [localizacaoPiscinaAgua, setLocalizacaoPiscinaAgua] = useState("");
  const [profundidadeMaxPiscinaAgua, setProfundidadeMaxPiscinaAgua] =
    useState("");
  const [profundidadeMinPiscinaAgua, setProfundidadeMinPiscinaAgua] =
    useState("");
  const [comprimentoPiscinaAgua, setComprimentoPiscinaAgua] = useState("");
  const [larguraPiscinaAgua, setLarguraPiscinaAgua] = useState("");
  const [capacidade, setCapacidade] = useState("");
  const [lotacaoPorPista, setLotacao] = useState("");
  const [obs, setObs] = useState("");
  const [exteriorinterior, setExteriorInterior] = useState(0);

  const [erroNomePiscinaAgua, setErroNomePiscinaAgua] = useState(false);
  const [erroNumeroPistas, setErroNumeroPistas] = useState(false);
  const [erroTipoAguaPiscinaAgua, setErroTipoAguaPiscinaAgua] = useState(false);
  const [erroProfundidadeMaxPiscinaAgua, setErroProfundidadeMaxPiscinaAgua] =
    useState(false);
  const [erroProfundidadeMinPiscinaAgua, setErroProfundidadeMinPiscinaAgua] =
    useState(false);
  const [erroComprimentoPiscinaAgua, setErroComprimentoPiscinaAgua] =
    useState(false);
  const [erroLarguraPiscinaAgua, setErroLarguraPiscinaAgua] = useState(false);
  const [erroCapacidade, setErroCapacidade] = useState(false);
  const [erroObs, setErroObs] = useState(false);
  const [erroLotacao, setErroLotacao] = useState(false);

  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);
  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const [mensagemErroTipoAguaPiscinaAgua, setMensagemErroTipoAguaPiscinaAgua] =
    useState("");
  const [
    mensagemErroProfundidadeMinPiscinaAgua,
    setMensagemErroProfundidadeMinPiscinaAgua,
  ] = useState("");
  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };
  const [
    mensagemErroProfundidadeMaxPiscinaAgua,
    setMensagemErroProfundidadeMaxPiscinaAgua,
  ] = useState("");
  const [
    mensagemErroComprimentoPiscinaAgua,
    setMensagemErroComprimentoPiscinaAgua,
  ] = useState("");
  const [mensagemErroLarguraPiscinaAgua, setMensagemErroLarguraPiscinaAgua] =
    useState("");
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    getPiscinaAgua({ id: idPiscinaAgua })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];

          setDadosOriginais(dados);
          setNomePiscinaAgua(dados.nome);
          setNumeroPistas(dados.numero_pistas);
          setTipoAguaPiscinaAgua(dados.tipo_agua);
          setExteriorInterior(dados.exterior_interior);
          setProfundidadeMinPiscinaAgua(dados.profundidade_min);
          setProfundidadeMaxPiscinaAgua(dados.profundidade_max);
          setCapacidade(dados.capacidade);
          setComprimentoPiscinaAgua(dados.comprimento);
          setLarguraPiscinaAgua(dados.largura);
          setLotacao(dados.lotacao_pista);
          setObs(dados.obs);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {});
  }, []);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };
  function EditarPiscina() {
    setFreeze(true);
    editarPiscinaA({
      id_piscina_agua: idPiscinaAgua,
      nome: nomePiscinaAgua,
      numero_pistas: numeroPistas,
      tipo_agua: tipoAguaPiscinaAgua,
      exterior_interior: exteriorinterior,
      profundidade_max: profundidadeMaxPiscinaAgua,
      profundidade_min: profundidadeMinPiscinaAgua,
      capacidade: capacidade,
      comprimento: comprimentoPiscinaAgua,
      largura: larguraPiscinaAgua,
      lotacao_pista: lotacaoPorPista,
      obs: obs,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          setEdicao(!edicao);
          //Scroll para o início da página
          window.scrollTo(0, 0);
          toast.success(t("dadosEditadosSucesso"));
          document.getElementsByClassName(
            "cardOpcoesPiscina1_1"
          )[0].style.cursor = "pointer";
          window.location.reload();
        } else {
          if (res.status == 500) {
            navigate("/500");
            setFreeze(false)
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
      });
  }

  function validacao() {
    if (
      nomePiscinaAgua &&
      numeroPistas &&
      tipoAguaPiscinaAgua &&
      profundidadeMaxPiscinaAgua &&
      profundidadeMinPiscinaAgua &&
      capacidade &&
      comprimentoPiscinaAgua &&
      larguraPiscinaAgua &&
      lotacaoPorPista &&
      obs
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);

        EditarPiscina();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nomePiscinaAgua) {
        setErroNomePiscinaAgua(true);
      }
      if (numeroPistas == 0) {
        setErroNumeroPistas(true);
      }
      if (!tipoAguaPiscinaAgua) {
        setErroTipoAguaPiscinaAgua(true);
      }
      if (!profundidadeMaxPiscinaAgua) {
        setErroProfundidadeMaxPiscinaAgua(true);
      }
      if (!profundidadeMinPiscinaAgua) {
        setErroProfundidadeMinPiscinaAgua(true);
      }
      if (!capacidade) {
        setErroCapacidade(true);
      }
      if (!comprimentoPiscinaAgua) {
        setErroComprimentoPiscinaAgua(true);
      }
      if (!larguraPiscinaAgua) {
        setErroLarguraPiscinaAgua(true);
      }
      if (!lotacaoPorPista) {
        setErroLotacao(true);
      }
      if (!obs) {
        setErroObs(true);
      }
    }
  }

  function eliminarPiscinaAgua() {
    setFreeze(true);
    eliminarPiscinaA({
      id_piscina_agua: idPiscinaAgua,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("piscinaEliminada"));
          navigate("/waterlista");
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        // //toast.warn(t("erroWebservice"));
      });
  }

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName(
        "cardOpcoesPiscinaEliminar3"
      )[0].style.cursor = "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName(
      "cardOpcoesPiscinaEliminar3"
    )[0].style.cursor = "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais

    setNomePiscinaAgua(dadosOriginais.nome);
    setNumeroPistas(dadosOriginais.numero_pistas);
    setTipoAguaPiscinaAgua(dadosOriginais.tipo_agua);
    setExteriorInterior(dadosOriginais.exterior_interior);
    setProfundidadeMinPiscinaAgua(dadosOriginais.profundiade_min);
    setProfundidadeMaxPiscinaAgua(dadosOriginais.profundiade_max);
    setCapacidade(dadosOriginais.capacidade);
    setComprimentoPiscinaAgua(dadosOriginais.comprimento);
    setLarguraPiscinaAgua(dadosOriginais.largura);
    setLotacao(dadosOriginais.lotacao_pista);
    setObs(dadosOriginais.obs);

    //Limpar erros e mensagens de erro
    setErroNomePiscinaAgua(false);
    setErroNumeroPistas(false);
    setErroTipoAguaPiscinaAgua(false);
    setErroProfundidadeMaxPiscinaAgua(false);
    setErroProfundidadeMinPiscinaAgua(false);
    setErroCapacidade(false);
    setErroComprimentoPiscinaAgua(false);
    setErroLarguraPiscinaAgua(false);
    setErroLotacao(false);
    setErroObs(false);

    window.scrollTo(0, 0);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  onClick={() => {
                    navigate("/waterlista");
                  }}
                  size={isMobile ? "small" : "medium"}
                >
                  {" "}
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </IconButton>
              ) : null}
            </div>
            {/* <div className="align-right">
              <h3 className="titleList">{t("editarPiscinaAgua")}</h3>
            </div> */}
          </div>
          <div>
            <div>
              <div className="formPage4">
                {!isMobile ? (
                  <Box className="formTextField5">
                    <div className="cardsDivPiscina2">
                      {edicao ? (
                        <Button
                          id="editarCard"
                          className="cardOpcoesPiscinaEliminar3"
                          variant="outlined"
                          style={{
                            backgroundColor: Colors.card1,
                          }}
                          onClick={() => {
                            scrollToDiv();
                          }}
                        >
                          <FontAwesomeIcon className="iconCards" icon={faPen} />
                          {t("editar")}
                        </Button>
                      ) : (
                        <Button
                          id="cardOpcoesPiscina1_1"
                          className="cardOpcoesPiscinaEliminar3"
                          variant="outlined"
                          style={{
                            backgroundColor: Colors.footer,
                          }}
                          disabled={!edicao}
                        >
                          <FontAwesomeIcon className="iconCards" icon={faPen} />
                          {t("modoEdicao")}
                        </Button>
                      )}
                    </div>
                    <div className="cardsDivPiscina2">
                      <Button
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: !edicao
                            ? Colors.cardInativo
                            : Colors.card3,
                        }}
                        onClick={handleClickOpenPopupEliminar}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faTrash} />
                        {t("eliminar")}
                      </Button>
                    </div>
                  </Box>
                ) : (
                  <div>
                    <div className="divCardsPiscinaMobile">
                      {edicao ? (
                        <Button
                          id="editarCard"
                          className="cardOpcoesPiscina1"
                          variant="outlined"
                          style={{
                            backgroundColor: Colors.card1,
                          }}
                          onClick={() => {
                            scrollToDiv();
                          }}
                        >
                          <FontAwesomeIcon className="iconCards" icon={faPen} />
                          {t("editar")}
                        </Button>
                      ) : (
                        <Button
                          className="cardOpcoesPiscina1_1"
                          variant="outlined"
                          style={{
                            backgroundColor: Colors.footer,
                          }}
                          disabled={!edicao}
                        >
                          <FontAwesomeIcon className="iconCards" icon={faPen} />
                          {t("modoEdicao")}
                        </Button>
                      )}
                    </div>

                    <div className="divCardsPiscinaMobile">
                      <Button
                        className="cardOpcoesPiscina3"
                        variant="outlined"
                        disabled={!edicao}
                        style={{
                          backgroundColor: edicao
                            ? Colors.card3
                            : Colors.cardInativo,
                        }}
                        onClick={handleClickOpenPopupEliminar}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faTrash} />
                        {t("eliminar")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Cancelar edição */}
            <Dialog
              open={cancelarEdicao}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("cancelarEdicao")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("textoCancelarEdicao")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ borderRadius: "20px" }}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    cancelar();
                  }}
                  autoFocus
                >
                  {t("sim")}
                </Button>
                <Button
                  sx={{ borderRadius: "20px" }}
                  variant="contained"
                  color="error"
                  onClick={handleClosePopupCancelar}
                >
                  {t("nao")}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={eliminar}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("eliminarPiscinaAgua")}
              </DialogTitle>
              <DialogContent>{t("eliminarRegisto")}</DialogContent>
              <DialogActions>
                {" "}
                <Button
                  sx={{ borderRadius: "20px" }}
                  variant="contained"
                  color="error"
                  onClick={handleClosePopupEliminar}
                >
                  {t("cancelar")}
                </Button>
                <Button
                  sx={{ borderRadius: "20px" }}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    eliminarPiscinaAgua();
                  }}
                  autoFocus
                >
                  {t("eliminar")}
                </Button>
              </DialogActions>
            </Dialog>

            <div className="formPage">
              <Box className="formTextField">
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nomePiscinaAgua}
                      error={erroNomePiscinaAgua}
                      onChange={(e) => {
                        setNomePiscinaAgua(e.target.value);
                        setErroNomePiscinaAgua(false);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    className="formTextField2"
                    style={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "flex-start !important",
                    }}
                  >
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("numeropistas")}
                      value={numeroPistas}
                      error={erroNumeroPistas}
                      InputProps={{
                        inputProps: { min: 1, max: 12 },
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value < 1 || value > 12) {
                          setErroNumeroPistas(true);
                        } else {
                          setNumeroPistas(value);
                          setErroNumeroPistas(false);
                        }
                      }}
                    />
                  </FormControl>
                  {/*tipo de agua */}
                  {/* <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("tipodeagua")}
                      value={tipoAguaPiscinaAgua}
                      error={erroTipoAguaPiscinaAgua}
                      helperText={mensagemErroTipoAguaPiscinaAgua}
                      onChange={(e) => {
                        setTipoAguaPiscinaAgua(
                          e.target.value.toLocaleLowerCase()
                        );
                        setErroTipoAguaPiscinaAgua(false);
                        setMensagemErroTipoAguaPiscinaAgua("");
                      }}
                    />
                  </FormControl> */}
                  <FormControl className="formTextField2" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start !important",
                      }}
                    >
                      {t("localizacaodapiscina")}
                    </FormLabel>

                    <RadioGroup
                      name={"localização"}
                      value={exteriorinterior}
                      onChange={(e) => setExteriorInterior(e.target.value)}
                    >
                      <FormControlLabel
                        disabled={edicao}
                        value={0}
                        control={<Radio />}
                        label="Interior"
                      />
                      <FormControlLabel
                        disabled={edicao}
                        value={1}
                        control={<Radio />}
                        label="Exterior"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("profundidademaxima") + "(" + t("metros") + ")"}
                      value={profundidadeMaxPiscinaAgua}
                      error={erroProfundidadeMaxPiscinaAgua}
                      helperText={mensagemErroProfundidadeMaxPiscinaAgua}
                      onChange={(e) => {
                        setProfundidadeMaxPiscinaAgua(
                          e.target.value.toLocaleLowerCase()
                        );
                        setErroProfundidadeMaxPiscinaAgua(false);
                        setMensagemErroProfundidadeMaxPiscinaAgua("");
                      }}
                    />
                  </FormControl>

                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("profundidademinima") + "(" + t("metros") + ")"}
                      value={profundidadeMinPiscinaAgua}
                      error={erroProfundidadeMinPiscinaAgua}
                      onChange={(e) => {
                        setProfundidadeMinPiscinaAgua(e.target.value);
                        setErroProfundidadeMinPiscinaAgua(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("capacidade")}
                      value={capacidade}
                      error={erroCapacidade}
                      onChange={(e) => {
                        setCapacidade(e.target.value);
                        setErroCapacidade(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("comprimento") + "(" + t("metros") + ")"}
                      value={comprimentoPiscinaAgua}
                      error={erroComprimentoPiscinaAgua}
                      helperText={mensagemErroComprimentoPiscinaAgua}
                      onChange={(e) => {
                        setComprimentoPiscinaAgua(
                          e.target.value.toLocaleLowerCase()
                        );
                        setErroComprimentoPiscinaAgua(false);
                        setMensagemErroComprimentoPiscinaAgua("");
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("largura") + "(" + t("metros") + ")"}
                      value={larguraPiscinaAgua}
                      error={erroLarguraPiscinaAgua}
                      helperText={mensagemErroLarguraPiscinaAgua}
                      onChange={(e) => {
                        setLarguraPiscinaAgua(
                          e.target.value.toLocaleLowerCase()
                        );
                        setErroLarguraPiscinaAgua(false);
                        setMensagemErroLarguraPiscinaAgua("");
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("lotacaoporpista")}
                      value={lotacaoPorPista}
                      error={erroLotacao}
                      onChange={(e) => {
                        const value = e.target.value;

                        setLotacao(value);
                        setErroLotacao(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={10}
                      value={obs}
                      label={t("observacoes")}
                      error={erroObs}
                      onChange={(e) => {
                        setObs(e.target.value);
                        setErroObs(false);
                      }}
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarPiscinaAguaA;
