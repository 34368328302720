import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faWater,
  faPeopleGroup,
  faStop,
  faTrash,
  faUpload,
  faPersonSwimming,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Search1 from "../../components/DataTable/Search1";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { Button, IconButton, Box } from "@mui/material";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import { getSuperComerciais } from "../../api/requests/SuperComerciais";
import Search2 from "../../components/DataTable/Search2";
import Nav from "../../components/Nav";

function OcupaEspacoA() {
  const [supercomerciais, setSuperComerciais] = useState([]);
  const [supercomerciaisCopy, setSuperComerciaisCopy] = useState([]);
  const [search, setSearch] = useState("");

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      let arraySuperComerciais = supercomerciaisCopy;
      arraySuperComerciais = arraySuperComerciais.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setSuperComerciais(arraySuperComerciais);
    } else {
      setSuperComerciais(supercomerciaisCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    // getSuperComerciais({})
    //   .then((res) => {
    //     if (res.success) {
    //       let arrayFinal = [];
    //       res.data.data.forEach((element) => {
    //         const linha = {
    //           id: element.id_utilizador,
    //           nome: element.nome,
    //           email: element.email,
    //           status: element.status == 1 ? t("ativo") : t("inativo"),
    //         };
    //         arrayFinal.push(linha);
    //         setSuperComerciais(arrayFinal);
    //         setSuperComerciaisCopy(arrayFinal);
    //       });
    //     } else {
    //       if (res.status == 500) {
    //         navigate("/500");
    //       } else if (res.status == 403) {
    //         let utilizador = Cookies.get("utilizador");
    //         if (utilizador == "superadmin") {
    //           navigate("/dashboard_superadmin");
    //         } else if (utilizador == "supercomercial") {
    //           navigate("/dashboard_supercommercial");
    //         } else if (utilizador == "comercial") {
    //           navigate("/dashboard_commercial");
    //         } else if (utilizador == "admin") {
    //           navigate("/dashboard_admin");
    //         } else if (utilizador == "funcionario") {
    //           navigate("/dashboard_employee");
    //         } else if (utilizador == "professor") {
    //           navigate("/dashboard_teacher");
    //         } else if (utilizador == "cliente") {
    //           navigate("/dashboard_customer");
    //         }
    //       }
    //       toast.error(res.error);
    //     }
    //   })
    //   .catch((error) => {
    //     //toast.warn(t("erroWebservice"));
    //   });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className="listPages">
        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/dashboard_admin");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <div className="divTabela">
          {/* <h3 className="titleList">{t("ocupaespaco")}</h3> */}
          <Box sx={{ padding: 10 }}>
            <div className="cardsDivDashboard">
              <Button
                className="cardDashboardMarcar"
                variant="contained"
                style={{
                  backgroundColor: Colors.cardAtalho1,
                }}
                onClick={() => {
                  navigate("/classlista");
                }}
              >
                <FontAwesomeIcon className="iconCards" icon={faPeopleGroup} />
                {t("turmas")}
              </Button>
              <Button
                className="cardDashboardMarcar"
                variant="contained"
                style={{
                  backgroundColor: Colors.card7,
                }}
                onClick={() => {
                  navigate("/lessonlista");
                }}
              >
                <FontAwesomeIcon
                  className="iconCards"
                  icon={faPersonSwimming}
                />
                {t("aulas")}
              </Button>
              <Button
                className="cardDashboardMarcar"
                variant="contained"
                style={{
                  backgroundColor: Colors.cardAtalho3,
                }}
                onClick={() => {
                  navigate("/spacelista");
                }}
              >
                <FontAwesomeIcon className="iconCards" icon={faWater} />
                {t("espaco")}
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default OcupaEspacoA;
