import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import "./i18next";
import Login from "./routes/Login";
import RecoverPassword from "./routes/RecoverPassword";
import ChangePassword from "./routes/ChangePassword";
import User from "./routes/User";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import ListaSuperAdministradores from "./routes/SuperAdmin/SuperAdmin";
import ListaSuperComerciais from "./routes/SuperComercial/SuperComercial";
import ListaComerciais from "./routes/Comercial/Comerciais";
import ListaAdministradores from "./routes/Administrador/Administradores";
import ListaFuncionarios from "./routes/Funcionário/Funcionarios";
import ListaProfessores from "./routes/Professor/Professores";
import ListaClientes from "./routes/Clientes/Clientes";
import ListaUtilizadores from "./routes/Utilizadores/Utilizadores";
import ListaPiscinas from "./routes/Piscinas/Piscinas";
import Dashboard_SuperAdmin from "./routes/Dashboards/Dashboard_SuperAdmin";
import Dashboard_SuperComercial from "./routes/Dashboards/Dashboard_SuperComercial";
import Dashboard_Comercial from "./routes/Dashboards/Dashboard_Comercial";
import Dashboard_Admin from "./routes/Dashboards/Dashboard_Admin";
import Dashboard_Funcionario from "./routes/Dashboards/Dashboard_Funcionario";
import Dashboard_Professor from "./routes/Dashboards/Dashboard_Professor";
import Dashboard_Cliente from "./routes/Dashboards/Dashboard_Cliente";
import Manutencao from "./routes/PaginaManutencao/Manutencao";
import CriarPiscina from "./routes/Piscinas/CriarPiscina";
import Inicio from "./routes/Inicio";
import DefinePassword from "./routes/DefinePassword";
import Register from "./routes/Register";
import PoolPage from "./routes/PoolPage";
import ActiveAccount from "./routes/ActiveAccount";
import Definicoes from "./routes/Definicoes/Definicoes";
import AlterarDados from "./routes/Definicoes/AlterarDadosUtilizador";
import AlterarDadosUtilizador from "./routes/Definicoes/AlterarDadosUtilizador";
import { logout } from "./api/requests/AuthServer";
import EditarPiscina from "./routes/Piscinas/EditarPiscina";
import ErrorPage_404 from "./routes/PaginasErros/ErrorPage_404";
import ErrorPage_401 from "./routes/PaginasErros/ErrorPage_401";
import ErrorPage_500 from "./routes/PaginasErros/ErrorPage_500";
import DiretorioPiscinas from "./routes/Piscinas/DiretorioPiscinas";
import Entidades from "./routes/Entidades/Entidades";
import ClientesApp from "./routes/Clientes/ClientesApp";
import CriarSuperAdmin from "./routes/SuperAdmin/CriarSuperAdmin";
import CriarAdmin from "./routes/Administrador/CriarAdmin";
import EditarAdmin from "./routes/Administrador/EditarAdmin";
import Pedidos from "./routes/Pedidos/Pedidos";
import ContaSuspensa from "./routes/Definicoes/ContaSuspensa";
import CriarFuncionario from "./routes/Funcionário/CriarFuncionario";
import EditarFuncionario from "./routes/Funcionário/EditarFuncionario";
import EditarProfessor from "./routes/Professor/EditarProfessor";
import CriarProfessor from "./routes/Professor/CriarProfessor";
import CriarCliente from "./routes/Clientes/CriarCliente";
import EditarCliente from "./routes/Clientes/EditarCliente";
import CriarSuperComercial from "./routes/SuperComercial/CriarSuperComercial";
import EditarSuperComercial from "./routes/SuperComercial/EditarSuperComercial";
import CriarComercial from "./routes/Comercial/CriarComercial";
import EditarComercial from "./routes/Comercial/EditarComercial";
import PedidosUtilizador from "./routes/Definicoes/PedidosUtilizador";
import ListaFilhos from "./routes/ContaFamiliar/ContaFamiliar";
import CriarFilho from "./routes/ContaFamiliar/CriarFilho";
import EditarFilho from "./routes/ContaFamiliar/EditarFilho";
import AlterarPassword from "./routes/Definicoes/AlterarPassword";
import VerSuperAdmin from "./routes/SuperAdmin/VerSuperAdmin";
import ListaPiscinasU from "./routes/Piscinas/ListaPiscinasCliente";
import PoolPageCliente from "./routes/Piscinas/PoolPageCliente";
import Ajuda from "./routes/Definicoes/Ajuda";
import ActiveAccountSendEmail from "./routes/ActiveAccountSendEmail";
import Marcar from "./routes/PaginasCliente/Marcar";
import MinhasPiscinas from "./routes/PaginasCliente/MinhasPiscinas";
import MeusPagamentos from "./routes/PaginasCliente/MeusPagamentos";
import MinhaFamilia from "./routes/PaginasCliente/MinhaFamilia";
import EditarFilhoCliente from "./routes/PaginasCliente/EditarFilhoCliente";
import CriarFilhoCliente from "./routes/PaginasCliente/CriarFilhoCliente";
import ExplorarPiscinas from "./routes/PaginasCliente/ExplorarPiscinas";
import CalendarioCliente from "./routes/PaginasCliente/CalendarioCliente";
import MeusQr from "./routes/PaginasCliente/MeusQrCodes";
import CompraSucesso from "./routes/PaginasCliente/CompraSucesso";
import CompraCancelada from "./routes/PaginasCliente/CompraCancelada";
import CompraErro from "./routes/PaginasCliente/CompraErro";
import Marcacoes from "./routes/PaginasCliente/Marcacoes";
import CompraSucesso2 from "./routes/PaginasCliente/CompraSucesso2";
import CompraCancelada2 from "./routes/PaginasCliente/CompraCancelada2";
import CompraErro2 from "./routes/PaginasCliente/CompraErro2";
import EditarPiscinaF from "./routes/Funcionário/EditarMinhaPiscina";
import ListaPiscinasAgua from "./routes/Funcionário/PiscinasAgua";
import CriarPiscinaAgua from "./routes/Funcionário/CriarPiscinaAgua";
import CriarPack from "./routes/Funcionário/CriarPack";
import ListaPacks from "./routes/Funcionário/Packs";
import ListaDescontos from "./routes/Funcionário/Descontos";
import CriarDesconto from "./routes/Funcionário/CriarDesconto";
import ClientesPiscina from "./routes/Funcionário/ClientesPiscina";
import CriarClienteFuncionario from "./routes/Funcionário/CriarClienteFuncionario";
import EditarClienteFuncionario from "./routes/Funcionário/EditarClienteFuncionario";
import PedidosAula from "./routes/Funcionário/PedidosAula";
import OcupaEspaco from "./routes/Funcionário/OcupaEspaco";
import EditarPack from "./routes/Funcionário/EditaPack";
import EditarDesconto from "./routes/Funcionário/EditaDesconto";
import ListaTurmas from "./routes/Funcionário/Turmas";
import CriarTurma from "./routes/Funcionário/CriarTurma";
import ListaEspacos from "./routes/Funcionário/Espaco";
import EditarPiscinaAgua from "./routes/Funcionário/EditarPiscinaAgua";
import EditarTurma from "./routes/Funcionário/EditaTurma";
import ListaAulas from "./routes/Funcionário/Aulas";
import CriarAula from "./routes/Funcionário/CriarAula";
import EditarAula from "./routes/Funcionário/EditaAula";
import QrcodeFuncionario from "./routes/Funcionário/QrCodesFuncionario";
import ListaProfessoresF from "./routes/Funcionário/ProfessoresF";
import EditarProfessorF from "./routes/Funcionário/EditarProfessorF";
import EditarEspaco from "./routes/Funcionário/EditEspaco";
import CriarEspaco from "./routes/Funcionário/CriarEspaco";
import ListaFilhosF from "./routes/Funcionário/ContaFamiliarFuncionario";
import CriarFilhoF from "./routes/Funcionário/CriarFilhoF";
import EditarFilhoF from "./routes/Funcionário/EditarFilhoF";
import PagamentosCliente from "./routes/Funcionário/PagamentosCliente";
import MarcarF from "./routes/Funcionário/MarcarF";
import ListaPacksC from "./routes/Funcionário/PacksCliente";
import CriarProfessorF from "./routes/Funcionário/CriarProfessorF";
import CalendarioFuncionario from "./routes/Funcionário/CalendarioFuncionario";
import Cancelamentos from "./routes/Funcionário/Cancelamentos";
import ListaAulasA from "./routes/PaginasAdmin/Aulas";
import CalendarioAdmin from "./routes/PaginasAdmin/CalendarioAdmin";
import CancelamentosA from "./routes/PaginasAdmin/Cancelamentos";
import ClientesPiscinaA from "./routes/PaginasAdmin/ClientesPiscina";
import ListaFilhosA from "./routes/PaginasAdmin/ContaFamiliarAdmin";
import CriarAulaA from "./routes/PaginasAdmin/CriarAula";
import CriarClienteAdmin from "./routes/PaginasAdmin/CriarClienteAdmin";
import CriarDescontoA from "./routes/PaginasAdmin/CriarDesconto";
import CriarFuncionarioA from "./routes/PaginasAdmin/CriarFuncionario";
import CriarPackA from "./routes/PaginasAdmin/CriarPack";
import CriarPiscinaAguaA from "./routes/PaginasAdmin/CriarPiscinaAgua";
import CriarProfessorA from "./routes/PaginasAdmin/CriarProfessorA";
import CriarTurmaA from "./routes/PaginasAdmin/CriarTurma";
import ListaDescontosA from "./routes/PaginasAdmin/Descontos";
import EditarAulaA from "./routes/PaginasAdmin/EditaAula";
import EditarDescontoA from "./routes/PaginasAdmin/EditaDesconto";
import EditarPackA from "./routes/PaginasAdmin/EditaPack";
import EditarFilhoA from "./routes/PaginasAdmin/EditarFilhoA";
import EditarFuncionarioA from "./routes/PaginasAdmin/EditarFuncionario";
import EditarPiscinaA from "./routes/PaginasAdmin/EditarMinhaPiscina";
import EditarPiscinaAguaA from "./routes/PaginasAdmin/EditarPiscinaAgua";
import EditarProfessorA from "./routes/PaginasAdmin/EditarProfessorA";
import EditarTurmaA from "./routes/PaginasAdmin/EditaTurma";
import EditarEspacoA from "./routes/PaginasAdmin/EditEspaco";
import ListaEspacosA from "./routes/PaginasAdmin/Espaco";
import ListaFuncionariosA from "./routes/PaginasAdmin/Funcionarios";
import MarcarA from "./routes/PaginasAdmin/MarcarA";
import OcupaEspacoA from "./routes/PaginasAdmin/OcupaEspaco";
import ListaPacksA from "./routes/PaginasAdmin/Packs";
import ListaPacksCA from "./routes/PaginasAdmin/PacksCliente";
import PagamentosClienteA from "./routes/PaginasAdmin/PagamentosCliente";
import PedidosAulaA from "./routes/PaginasAdmin/PedidosAula";
import ListaPiscinasAguaA from "./routes/PaginasAdmin/PiscinasAgua";
import ListaProfessoresA from "./routes/PaginasAdmin/ProfessoresA";
import QrcodeAdmin from "./routes/PaginasAdmin/QrCodesAdmin";
import ListaTurmasA from "./routes/PaginasAdmin/Turmas";
import EditarClienteAdmin from "./routes/PaginasAdmin/EditarClienteAdmin";
import CriarFilhoA from "./routes/PaginasAdmin/CriarFilhoA";
import CriarEspacoA from "./routes/PaginasAdmin/CriarEspaco";
import ListaSI from "./routes/Funcionário/SeguroInscricao";
import CriarSeguroInscricao from "./routes/Funcionário/CriarSeguroInscricao";
import EditarSI from "./routes/Funcionário/EditaSeguroInscricao";
import PedidosProfessor from "./routes/Professor/Pedidos";
import ListaEEA from "./routes/PaginasAdmin/ListaEExploradora";
import VerEEA from "./routes/PaginasAdmin/VerEntidadeExploradora";
import VerELA from "./routes/PaginasAdmin/VerEntidadeLegal";
import ProximasAtividades from "./routes/Funcionário/ProximasAtividades";
import ProximasAtividadesA from "./routes/PaginasAdmin/ProximasAtividades";
import LeituraQrCode from "./routes/LeituraQrCode";
import AjudaProfessor from "./routes/Professor/AjudaProfessor";
import AjudaFuncionario from "./routes/Funcionário/AjudaFuncionario";
import AjudaAdmin from "./routes/PaginasAdmin/AjudaAdmin";
import ListaFechos from "./routes/PaginasAdmin/ListaFechos";
import HorarioAdmin from "./routes/PaginasAdmin/HorariosAdmin";
import ListaSIA from "./routes/PaginasAdmin/SeguroInscricaoAdmin";
import CompraSucessoMobile from "./routes/PaginasCliente/CompraSucessoMobile";
import CompraErroMobile from "./routes/PaginasCliente/CompraErroMobile";
import CompraSucessoMensalMobile from "./routes/PaginasCliente/CompraSucessoMensalMobile";
import CompraErroMensalMobile from "./routes/PaginasCliente/CompraErroMensalMobile";
import HorarioFuncionario from "./routes/Funcionário/HorariosFuncionario";
import MeusPacks from "./routes/PaginasCliente/MeusPacks";
import MinhasTurmas from "./routes/PaginasCliente/MinhasTurmas";
import LoginGestao from "./routes/LoginGestao";
import Policy from "./routes/Policy";

const AppLayout = () => {
  //Traduções
  const { t, i18n } = useTranslation();
  //Navigator
  const navigate = useNavigate();

  const manutencao = false;

  //Controlar token
  Cookies.set("language", i18n.language);
  const id = Cookies.get("uId");
  const dados = Cookies.get("uData");
  const token = Cookies.get("token");
  const refresh_token = Cookies.get("refresh_token");
  if (id) {
    if (token == undefined && window.location.pathname != "/") {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
    }
  }

  return (
    <>
      {manutencao ? (
        <Manutencao />
      ) : (
        <>
          <Outlet />
          <ToastContainer
            style={{ marginTop: "50px" }}
            position="top-right"
            autoClose={3000}
            limit={1}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            containerId={1}
          />
        </>
      )}
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path="/" element={<Inicio />} />
      <Route path="/login" element={<Login />} />
      <Route path="/loginbo" element={<LoginGestao />} />
      <Route path="/recoverpassword" element={<RecoverPassword />} />
      <Route path="/activeaccount/:token/:id" element={<ActiveAccount />} />
      <Route path="/changepassword/:id/:token" element={<ChangePassword />} />
      <Route path="/definepassword/:id/:token" element={<DefinePassword />} />
      <Route path="/register" element={<Register />} />
      <Route path="/poolpage/:id" element={<PoolPage />} />
      <Route path="/poolpageu/:id" element={<PoolPageCliente />} />

      {/* Página das Listas */}
      {/* COLOCAR ROUTES PROTECTES APOS IMPLEMENTAR LOGIN */}
      {/* Lista Super Admins */}
      <Route path="/superadmins" element={<ListaSuperAdministradores />} />
      {/* Lista Super Comerciais */}
      <Route path="/supercommercials" element={<ListaSuperComerciais />} />
      {/* Lista Comerciais */}
      <Route path="/commercials" element={<ListaComerciais />} />
      {/* Lista Administradores */}
      <Route path="/administrators" element={<ListaAdministradores />} />
      {/* Lista Funcionários */}
      <Route path="/employees" element={<ListaFuncionarios />} />
      {/* Lista Professores */}
      <Route path="/teachers" element={<ListaProfessores />} />
      {/* Lista Clientes */}
      <Route path="/customers" element={<ListaClientes />} />
      {/* Lista Utilizadores */}
      <Route path="/users" element={<ListaUtilizadores />} />
      {/* Lista Piscinas */}
      <Route path="/pools" element={<ListaPiscinas />} />

      {/* Lista Piscinas */}
      <Route path="/poolsu" element={<ListaPiscinasU />} />
      {/* Criar Piscina */}
      <Route path="/createpool" element={<CriarPiscina />} />
      {/* Editar Piscina */}
      <Route path="/editpool" element={<EditarPiscina />} />
      {/* Dashboards */}
      <Route path="/dashboard_superadmin" element={<Dashboard_SuperAdmin />} />
      <Route
        path="/dashboard_supercommercial"
        element={<Dashboard_SuperComercial />}
      />
      <Route path="/dashboard_commercial" element={<Dashboard_Comercial />} />
      <Route path="/dashboard_admin" element={<Dashboard_Admin />} />
      <Route path="/dashboard_employee" element={<Dashboard_Funcionario />} />
      <Route path="/dashboard_teacher" element={<Dashboard_Professor />} />
      <Route path="/dashboard_customer" element={<Dashboard_Cliente />} />
      {/* Página de Definições do utilizador */}
      <Route path="/definitions" element={<Definicoes />} />
      <Route path="/change_user_data" element={<AlterarDadosUtilizador />} />
      {/* Páginas de Erros */}
      <Route path="*" element={<ErrorPage_404 />} />
      <Route path="/401" element={<ErrorPage_401 />} />
      <Route path="/excluded" element={<ContaSuspensa />} />
      <Route path="/500" element={<ErrorPage_500 />} />
      <Route path="/poolslist" element={<DiretorioPiscinas />} />
      {/* Entidades */}
      <Route path="/entities" element={<Entidades />} />
      {/* Clientes App */}
      <Route path="/customers_app" element={<ClientesApp />} />
      {/* Criar superadmin */}
      <Route path="/createsuperadmin" element={<CriarSuperAdmin />} />
      {/* Criar admin */}
      <Route path="/createadmin" element={<CriarAdmin />} />
      {/* Editar admin */}
      <Route path="/editadmin" element={<EditarAdmin />} />
      {/* Lista pedidos */}
      <Route path="/orders" element={<Pedidos />} />
      {/* Criar funcionário */}
      <Route path="/createemployee" element={<CriarFuncionario />} />
      {/* Editar funcionario */}
      <Route path="/editemployee" element={<EditarFuncionario />} />
      {/* Criar professor */}
      <Route path="/createteacher" element={<CriarProfessor />} />
      {/* Editar professor*/}
      <Route path="/editteacher" element={<EditarProfessor />} />
      {/* Criar cliente */}
      <Route path="/createcustomer" element={<CriarCliente />} />
      {/* Editar cliente*/}
      <Route path="/editcustomer" element={<EditarCliente />} />
      {/* Criar supercomercial */}
      <Route path="/createsupercommercial" element={<CriarSuperComercial />} />
      {/* Editar supercomercial */}
      <Route path="/editsupercommercial" element={<EditarSuperComercial />} />
      {/* Criar comercial */}
      <Route path="/createcommercial" element={<CriarComercial />} />
      {/* Editar comercial */}
      <Route path="/editcommercial" element={<EditarComercial />} />

      {/* Pedidos do utilizador */}
      <Route path="/user-orders" element={<PedidosUtilizador />} />
      <Route path="/teacher-orders" element={<PedidosProfessor />} />

      {/* Conta Familiar */}
      <Route path="/familyaccount" element={<ListaFilhos />} />
      {/* Adicionar Filho */}
      <Route path="/createchild" element={<CriarFilho />} />
      {/* Editar Filho */}
      <Route path="/editchild" element={<EditarFilho />} />

      {/* Alterar Password*/}
      <Route path="/alterarpassword" element={<AlterarPassword />} />

      {/* Ver SuperAdmin*/}
      <Route path="/seesuperadmin" element={<VerSuperAdmin />} />

      {/* Ajuda*/}
      <Route path="/help" element={<Ajuda />} />
      <Route path="/helpteacher" element={<AjudaProfessor />} />
      <Route path="/helpemloyee" element={<AjudaFuncionario />} />
      <Route path="/helpadmin" element={<AjudaAdmin />} />

      {/* Reenviar email para ativar conta */}

      <Route
        path="/active_account_send_email"
        element={<ActiveAccountSendEmail />}
      />

      <Route path="/marcar" element={<Marcar />} />

      <Route path="/mypools" element={<MinhasPiscinas />} />

      <Route path="/mypayments" element={<MeusPagamentos />} />

      <Route path="/myfamily" element={<MinhaFamilia />} />
      <Route path="/editchildclient" element={<EditarFilhoCliente />} />

      <Route path="/createchildclient" element={<CriarFilhoCliente />} />

      <Route path="/explore" element={<ExplorarPiscinas />} />

      <Route path="/mycalendar" element={<CalendarioCliente />} />
      <Route path="/qrcodes" element={<MeusQr />} />

      <Route path="/marcar" element={<Marcar />} />
      {/* Páginas de Compra - Cartão de Crédito */}
      <Route path="/:id/success" element={<CompraSucesso />} />
      <Route path="/:id/cancel" element={<CompraCancelada />} />
      <Route path="/:id/error" element={<CompraErro />} />
      {/* Páginas de Compra - Cartão de Crédito - Mobile */}
      <Route path="/:id/success/mobile" element={<CompraSucessoMobile />} />
      <Route path="/:id/error/mobile" element={<CompraErroMobile />} />
      {/* Páginas de Compra - Cartão de Crédito Pagamento Mensal */}
      <Route path="/order_id/month/success" element={<CompraSucesso2 />} />
      <Route
        path="/order_id/month/success/mobile"
        element={<CompraSucessoMensalMobile />}
      />
      <Route
        path="/order_id/month/error/mobile"
        element={<CompraErroMensalMobile />}
      />
      <Route path="/order_id/month/cancel" element={<CompraCancelada2 />} />
      <Route path="/order_id/month/error" element={<CompraErro2 />} />
      <Route path="/appointments" element={<Marcacoes />} />
      {/* Editar Piscina */}
      <Route path="/editpoolf" element={<EditarPiscinaF />} />
      <Route path="/waterlist" element={<ListaPiscinasAgua />} />
      <Route path="/watercreate" element={<CriarPiscinaAgua />} />
      <Route path="/editwater" element={<EditarPiscinaAgua />} />
      <Route path="/packlist" element={<ListaPacks />} />
      <Route path="/spacelist" element={<ListaEspacos />} />
      <Route path="/classlist" element={<ListaTurmas />} />
      <Route path="/lessonlist" element={<ListaAulas />} />
      <Route path="/packcreate" element={<CriarPack />} />
      <Route path="/classcreate" element={<CriarTurma />} />
      <Route path="/lessoncreate" element={<CriarAula />} />
      <Route path="/discountlist" element={<ListaDescontos />} />
      <Route path="/discountcreate" element={<CriarDesconto />} />
      <Route path="/poolcustomers" element={<ClientesPiscina />} />
      <Route path="/createpoolcustomer" element={<CriarClienteFuncionario />} />
      <Route path="/editcustomerf" element={<EditarClienteFuncionario />} />
      {/* Lista pedidos alteração*/}
      <Route path="/lessonorders" element={<PedidosAula />} />
      <Route path="/ocupaespaco" element={<OcupaEspaco />} />
      <Route path="/editpack" element={<EditarPack />} />
      <Route path="/editdiscount" element={<EditarDesconto />} />
      <Route path="/editwater" element={<EditarPiscinaAgua />} />
      <Route path="/editclass" element={<EditarTurma />} />
      <Route path="/editlesson" element={<EditarAula />} />
      <Route path="/qremployee" element={<QrcodeFuncionario />} />
      <Route path="/teacherf" element={<ListaProfessoresF />} />
      <Route path="/editteacherf" element={<EditarProfessorF />} />
      <Route path="/editespaco" element={<EditarEspaco />} />
      <Route path="/espacocreate" element={<CriarEspaco />} />
      <Route path="/familyf" element={<ListaFilhosF />} />
      <Route path="/createchildf" element={<CriarFilhoF />} />
      <Route path="/editchildf" element={<EditarFilhoF />} />
      <Route path="/clientpayments" element={<PagamentosCliente />} />
      <Route path="/marcarf" element={<MarcarF />} />
      <Route path="/packlistc" element={<ListaPacksC />} />
      <Route path="/createteacherf" element={<CriarProfessorF />} />
      <Route path="/poolcalendar" element={<CalendarioFuncionario />} />
      <Route path="/cancellist" element={<Cancelamentos />} />
      <Route path="/lessonlista" element={<ListaAulasA />} />
      <Route path="/poolcalendara" element={<CalendarioAdmin />} />
      <Route path="/cancellista" element={<CancelamentosA />} />
      <Route path="/poolcustomersa" element={<ClientesPiscinaA />} />
      <Route path="/familya" element={<ListaFilhosA />} />
      <Route path="/lessoncreatea" element={<CriarAulaA />} />
      <Route path="/createpoolcustomera" element={<CriarClienteAdmin />} />
      <Route path="/discountcreatea" element={<CriarDescontoA />} />
      <Route path="/createemployeea" element={<CriarFuncionarioA />} />
      <Route path="/packcreatea" element={<CriarPackA />} />
      <Route path="/watercreatea" element={<CriarPiscinaAguaA />} />
      <Route path="/createteachera" element={<CriarProfessorA />} />
      <Route path="/classcreatea" element={<CriarTurmaA />} />
      <Route path="/discountlista" element={<ListaDescontosA />} />
      <Route path="/editlessona" element={<EditarAulaA />} />
      <Route path="/editdiscounta" element={<EditarDescontoA />} />
      <Route path="/editpacka" element={<EditarPackA />} />
      <Route path="/editcustomera" element={<EditarClienteAdmin />} />
      <Route path="/editchilda" element={<EditarFilhoA />} />
      <Route path="/editemployeea" element={<EditarFuncionarioA />} />
      <Route path="/editpoola" element={<EditarPiscinaA />} />
      <Route path="/editwatera" element={<EditarPiscinaAguaA />} />
      <Route path="/editteachera" element={<EditarProfessorA />} />
      <Route path="/editclassa" element={<EditarTurmaA />} />
      <Route path="/editespacoa" element={<EditarEspacoA />} />
      <Route path="/spacelista" element={<ListaEspacosA />} />
      <Route path="/employeesa" element={<ListaFuncionariosA />} />
      <Route path="/marcara" element={<MarcarA />} />
      <Route path="/ocupaespacoa" element={<OcupaEspacoA />} />
      <Route path="/packlista" element={<ListaPacksA />} />
      <Route path="/packlistca" element={<ListaPacksCA />} />
      <Route path="/clientpaymentsa" element={<PagamentosClienteA />} />
      <Route path="/lessonordersa" element={<PedidosAulaA />} />
      <Route path="/waterlista" element={<ListaPiscinasAguaA />} />
      <Route path="/teachera" element={<ListaProfessoresA />} />
      <Route path="/qradmin" element={<QrcodeAdmin />} />
      <Route path="/classlista" element={<ListaTurmasA />} />
      <Route path="/createchilda" element={<CriarFilhoA />} />
      <Route path="/espacocreatea" element={<CriarEspacoA />} />
      <Route path="/silist" element={<ListaSI />} />
      <Route path="/sicreate" element={<CriarSeguroInscricao />} />
      <Route path="/editsi" element={<EditarSI />} />
      <Route path="/eelista" element={<ListaEEA />} />
      <Route path="/eeasee" element={<VerEEA />} />
      <Route path="/elasee" element={<VerELA />} />
      <Route path="/closelista" element={<ListaFechos />} />
      <Route path="/poolschedulea" element={<HorarioAdmin />} />
      <Route path="/poolschedule" element={<HorarioFuncionario />} />
      <Route path="/nextativities" element={<ProximasAtividades />} />
      <Route path="/nextativitiesa" element={<ProximasAtividadesA />} />
      <Route path="/read_qrcode/:uuid/:code" element={<LeituraQrCode />} />
      <Route path="/silista" element={<ListaSIA />} />
      <Route path="/mypacks" element={<MeusPacks />} />
      <Route path="/myclass" element={<MinhasTurmas />} />
      <Route path="/policy" element={<Policy />} />
    </Route>
  )
);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
