import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faClose,
  faStop,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Search1 from "../../components/DataTable/Search1";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Scrollbar } from "react-scrollbars-custom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";

import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
  RadioGroup,
  Radio,
} from "@mui/material";
import Nav from "../../components/Nav";

import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import {
  adicionarTurma,
  editarTurma,
  eliminarTurma,
  getTurma,
  listaTurmas,
} from "../../api/requests/Turmas";
import Search2 from "../../components/DataTable/Search2";
import {
  eliminarTurmaEmail,
  listaModalidades,
  listaProfessores,
} from "../../api/requests/PiscinaAgua";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { Box } from "@mui/system";

function ListaTurmasA() {
  const [turmas, setTurmas] = useState([]);
  const [turmasCopy, setTurmasCopy] = useState([]);
  const [search, setSearch] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "preco_anual", "preco_mensal"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "preco_anual", "preco_mensal"] : []);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const [freeze, setFreeze] = useState(false);
  const [idTurma, setIdTurma] = useState("");

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);
  const [edicao, setEdicao] = useState(true);

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const [openCriarTurma, setOpenCriarTurma] = useState(false);
  const handleCloseCriarTurma = () => {
    setOpenCriarTurma(false);
    limparVariaveis();
  };

  const handleClickOpenCriarTurma = () => {
    setOpenCriarTurma(true);
  };

  const [openEditarTurma, setOpenEditarTurma] = useState(false);
  const handleCloseEditarTurma = () => {
    setOpenEditarTurma(false);
    getTurma({ id: idTurma })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];

          setDadosOriginais(dados);

          setNomee(dados.nome);
          setSelectedCategorye(dados.id_categoria);
          setSelectedModalidadee(dados.id_modalidade);
          setSelectedProfessore(dados.id_professor);
          setNumVagase(dados.num_vagas);
          setPrecoAnuale(dados.preco_anual);
          setPrecoMensale(dados.preco_mensal);
          setObse(dados.obs);
          setObsENe(dados.obs_en);
          setObsESe(dados.obs_es);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    setEdicao(true);
  };

  const handleClickOpenEditarTurma = () => {
    setOpenEditarTurma(true);
  };

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };
  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (search) {
      let arrayTurmas = turmasCopy;
      arrayTurmas = arrayTurmas.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome.toLowerCase().includes(search.toLowerCase())
      );
      setTurmas(arrayTurmas);
    } else {
      setTurmas(turmasCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaTurmas({ id_piscina: idPiscina, id_entidade_exploradora: idEntidade })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_turma,
              nome: element.nome,
              categoria: element.id_categoria,
              modalidade: element.id_modalidade,
              vagas: element.num_vagas,
              vagas_ocupadas: element.num_vagas_ocupadas,
              obs: element.obs,
              preco_mensal: element.preco_mensal.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
              preco_anual: element.preco_anual.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
            };
            arrayFinal.push(linha);
            setTurmas(arrayFinal);
            setTurmasCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  const [nome, setNome] = useState("");
  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [numvagas, setNumVagas] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [piscina, setPiscinas] = useState([]);
  const [modalidades, setModalidade] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [precomensal, setPrecoMensal] = useState(0);
  const [precoanual, setPrecoAnual] = useState(0);
  const [obs, setObs] = useState("");
  const [obsES, setObsES] = useState("");
  const [obsEN, setObsEN] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [selectedModalidade, setSelectedModalidade] = useState("");

  const [erroNome, setErroNome] = useState(false);
  const [erroObs, setErroObs] = useState(false);
  const [erroObsEN, setErroObsEN] = useState(false);
  const [erroObsES, setErroObsES] = useState(false);
  const [erroPrecoMensal, setErroPrecoMensal] = useState(false);
  const [erroPrecoAnual, setErroPrecoAnual] = useState(false);
  const [erroNumVagas, setErroNumVagas] = useState(false);
  const [erroPreco, setErroPreco] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroProfessor, setErroProfessor] = useState(false);
  const [erroPiscina, setErroPiscina] = useState(false);
  const [erroModalidade, setErroModalidade] = useState(false);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
    setErroProfessor(false);
  };

  const handleModalidadeChange = (event) => {
    setSelectedModalidade(event.target.value);
    setErroModalidade(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
      listaModalidades({entidadeExploradora:localStorage.getItem("idEntidade")})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_modalidade,
              nome: element.nome_modalidade,
            };
            arrayFinal.push(linha);
            setModalidade(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarTurmas() {
    setFreeze(true);
    adicionarTurma({
      nome: nome,
      id_categoria: selectedCategory,
      id_professor: selectedProfessor,
      id_modalidade: selectedModalidade,
      num_vagas: numvagas,
      preco_mensal: precomensal,
      preco_anual: precoanual,
      obs1: obs,
      obs2: obsEN,
      obs3: obsES,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        toast.success(t("turmaAdicionar"));
        atualizarlista();
        setFreeze(false);
        handleCloseCriarTurma();
        limparVariaveis();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      nome &&
      numvagas &&
      selectedCategory &&
      selectedProfessor &&
      selectedModalidade &&
      precomensal &&
      precoanual &&
      obs 
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarTurmas();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      if (!obs) {
        setErroObs(true);
      }
      // if (!obsEN) {
      //   setErroObsEN(true);
      // }
      // if (!obsES) {
      //   setErroObsES(true);
      // }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (!selectedModalidade) {
        setErroModalidade(true);
      }
      if (!selectedProfessor) {
        setErroProfessor(true);
      }
      if (!selectedModalidade) {
        setErroModalidade(true);
      }
      if (numvagas <= 0) {
        setErroNumVagas(true);
      }
      if (precomensal <= 0) {
        setErroPrecoMensal(true);
      }
      if (precoanual <= 0) {
        setErroPrecoAnual(true);
      }
    }
  }

  //editar turmas

  const [nomee, setNomee] = useState("");
  const [idPiscinae, setIdPiscinae] = useState("");
  const [idEEe, setIdEEe] = useState("");
  const [numvagase, setNumVagase] = useState(0);
  const [categoriase, setCategoriae] = useState([]);
  const [piscinae, setPiscinase] = useState([]);
  const [modalidadese, setModalidadee] = useState([]);
  const [professorese, setProfessorese] = useState([]);
  const [precomensale, setPrecoMensale] = useState(0);
  const [precoanuale, setPrecoAnuale] = useState(0);
  const [obse, setObse] = useState("");
  const [obsESe, setObsESe] = useState("");
  const [obsENe, setObsENe] = useState("");
  const [selectedCategorye, setSelectedCategorye] = useState("");
  const [selectedProfessore, setSelectedProfessore] = useState("");
  const [selectedModalidadee, setSelectedModalidadee] = useState("");
  const [erroNomee, setErroNomee] = useState(false);
  const [erroObse, setErroObse] = useState(false);
  const [erroObsENe, setErroObsENe] = useState(false);
  const [erroObsESe, setErroObsESe] = useState(false);
  const [erroPrecoMensale, setErroPrecoMensale] = useState(false);
  const [erroPrecoAnuale, setErroPrecoAnuale] = useState(false);
  const [erroNumVagase, setErroNumVagase] = useState(false);
  const [erroPrecoe, setErroPrecoe] = useState(false);
  const [erroCategoriae, setErroCategoriae] = useState(false);
  const [erroProfessore, setErroProfessore] = useState(false);
  const [erroPiscinae, setErroPiscinae] = useState(false);
  const [erroModalidadee, setErroModalidadee] = useState(false);

  const handleCategoryChangee = (event) => {
    setSelectedCategorye(event.target.value);
    setErroCategoriae(false);
  };

  const handleProfessorChangee = (event) => {
    setSelectedProfessore(event.target.value);
    setErroProfessore(false);
  };

  const handleModalidadeChangee = (event) => {
    setSelectedModalidadee(event.target.value);
    setErroModalidadee(false);
  };

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleChange = (event) => {
    const novoStatuse = event.target.checked ? 1 : 0;
    setStatuse(novoStatus);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscinae(idPiscina);
    setIdEEe(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoriae(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
    if (idTurma != "") {
      getTurma({ id: idTurma })
        .then((res) => {
          if (res.success) {
            let dados = res.data.data[0];

            setDadosOriginais(dados);

            setNomee(dados.nome);
            setSelectedCategorye(dados.id_categoria);
            setSelectedModalidadee(dados.id_modalidade);
            setSelectedProfessore(dados.id_professor);
            setNumVagase(dados.num_vagas);
            setPrecoAnuale(dados.preco_anual);
            setPrecoMensale(dados.preco_mensal);
            setObse(dados.obs);
            setObsENe(dados.obs_en);
            setObsESe(dados.obs_es);
            setCarregamentoDadosCompleto(true);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          //toast.warn(t("erroWebservice"));
        });
    }

    listaModalidades({entidadeExploradora:localStorage.getItem("idEntidade")})
    .then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_modalidade,
            nome: element.nome_modalidade,
          };
          arrayFinal.push(linha);
          setModalidade(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    })
    .catch((error) => {
      //toast.warn(t("erroWebservice"));
    });
    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessorese(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, [idTurma]);

  function cancelar() {
    handleClosePopupCancelar();

    setEdicao(!edicao);

    //Colocar os dados originais
    setNomee(dadosOriginais.nome);
    setSelectedCategorye(dadosOriginais.id_categoria);
    setSelectedModalidadee(dadosOriginais.id_modalidade);
    setSelectedProfessore(dadosOriginais.id_professor);
    setNumVagase(dadosOriginais.num_vagas);
    setPrecoAnuale(dadosOriginais.preco_anual);
    setPrecoMensale(dadosOriginais.preco_mensal);
    setObse(dadosOriginais.obs);
    setObsENe(dadosOriginais.obs_en);
    setObsESe(dadosOriginais.obs_es);

    //Limpar erros e mensagens de erro
    setErroNomee(false);
    setErroCategoriae(false);
    setErroModalidadee(false);
    setErroProfessore(false);
    setErroNumVagase(false);
    setErroPrecoAnuale(false);
    setErroPrecoMensale(false);
    setErroObse(false);
    setErroObsENe(false);
    setErroObsESe(false);

    window.scrollTo(0, 0);
  }
  function editarTurmas() {
    setFreeze(true);
    editarTurma({
      id_turma: idTurma,
      nome: nomee,
      id_categoria: selectedCategorye,
      id_professor: selectedProfessore,
      id_modalidade: selectedModalidadee,
      num_vagas: numvagase,
      preco_mensal: precomensale,
      preco_anual: precoanuale,
      obs1: obse,
      obs2: obsENe,
      obs3: obsESe,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        let linha = {
          id_turma: idTurma,
          nome: nomee,
          id_categoria: selectedCategorye,
          id_professor: selectedProfessore,
          id_modalidade: selectedModalidadee,
          num_vagas: numvagase,
          preco_mensal: precomensale,
          preco_anual: precoanuale,
          obs1: obse,
          obs2: obsENe,
          obs3: obsESe,
          id_piscina: localStorage.getItem("idPiscina"),
          id_entidade_exploradora: localStorage.getItem("idEntidade"),
        };

        setDadosOriginais(linha);

        setFreeze(false);
        setEdicao(!edicao);
        //Scroll para o início da página
        window.scrollTo(0, 0);
        toast.success(t("dadosEditadosSucesso"));
        atualizarlista();

        handleCloseEditarTurma();
        window.location.reload();
      } else {
        if (res.status == 500) {
          setFreeze(false)
          navigate("/500");
        }
        setFreeze(false)
        toast.error(res.error);
      }
    });
  }

  function validacao() {
    if (
      nomee &&
      numvagase &&
      selectedCategorye &&
      selectedProfessore &&
      selectedModalidadee &&
      precomensale &&
      precoanuale &&
      obse 
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarTurmas();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nomee) {
        setErroNomee(true);
      }
      if (!obse) {
        setErroObse(true);
      }
      // if (!obsENe) {
      //   setErroObsENe(true);
      // }
      // if (!obsESe) {
      //   setErroObsESe(true);
      // }
      if (!selectedCategorye) {
        setErroCategoriae(true);
      }
      if (!selectedProfessore) {
        setErroProfessore(true);
      }
      if (!selectedModalidadee) {
        setErroModalidadee(true);
      }
      if (numvagase <= 0) {
        setErroNumVagase(true);
      }
      if (precomensale <= 0) {
        setErroPrecoMensale(true);
      }
      if (precoanuale <= 0) {
        setErroPrecoAnuale(true);
      }
    }
  }
  function atualizarlista() {
    listaTurmas({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_turma,
              nome: element.nome,
              categoria: element.id_categoria,
              modalidade: element.id_modalidade,
              vagas: element.num_vagas,
              vagas_ocupadas: element.num_vagas_ocupadas,
              obs: element.obs,
              preco_mensal: element.preco_mensal.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
              preco_anual: element.preco_anual.toLocaleString("pt-PT", {
                style: "currency",
                currency: "EUR",
              }),
            };
            arrayFinal.push(linha);
            setTurmas(arrayFinal);
            setTurmasCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  function limparVariaveis() {
    setNome("");
    setSelectedProfessor("");
    setSelectedModalidade("");
    setSelectedCategory("");
    setNumVagas("");
    setPrecoAnual("");
    setPrecoMensal("");
    setObs("");
    setObsEN("");
    setObsES("");
  }
  function eliminarTurmas() {
    setFreeze(true);
    eliminarTurma({
      id_turma: idTurma,
    })
      .then((res) => {
        if (res.success) {

          setTimeout(async function () {
            eliminarTurmaEmail({
              id_turma: idTurma,
            }).then((res2) => {
              if (res2.success) {
        
                toast.success(t("turmaEliminada"));
              } else {
                setFreeze(false);
                toast.error(res.error);
              }
            });
          }, 2000);
          setFreeze(false);
          atualizarlista();
          handleClosePopupEliminar();
          handleCloseEditarTurma();
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaTurmas")}</h3>

        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/ocupaespacoa");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleClickOpenCriarTurma();
              }}
            ></span>
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 70,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              // {
              //   field: "categoria",
              //   headerName: t("categoria"),
              //   flex: 1,
              //   type: "string",
              // },
              // {
              //   field: "id_categoria",
              //   headerName: t("categoria"),
              //   flex: 1,
              //   type: "string",
              // },
              // {
              //   field: "id_modalidade",
              //   headerName: t("modalidade"),
              //   flex: 1,
              //   type: "string",
              // },
              // {
              //   field: "id_professor",
              //   headerName: t("professor"),
              //   flex: 1,
              //   type: "string",
              // },
              {
                field: "obs",
                headerName: t("observacoes"),
                flex: 1,
                type: "string",
              },
              // {
              //   field: "num_vagas",
              //   headerName: t("totalvagas"),
              //   flex: 1,
              //   type: "number",
              // },
              // {
              //   field: "vagas_ocupadas",
              //   headerName: t("vagasocupadas"),
              //   flex: 1,
              //   type: "number",
              // },
              {
                field: "preco_mensal",
                headerName: t("precomensal"),
                flex: 1,
                type: "number",
                hide: columnsHidden.includes("preco_mensal"),
              },
              {
                field: "preco_anual",
                headerName: t("precoanual"),
                flex: 1,
                type: "number",
                hide: columnsHidden.includes("preco_anual"),
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdTurma(id);
                        handleClickOpenEditarTurma();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={turmas}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>

          {/* adicionar aula */}
          <Dialog
            open={openCriarTurma}
            onClose={handleCloseCriarTurma}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarTurma")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarTurma}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div className="formPop" style={{ marginTop: 30 }}>
                <Scrollbar style={{ height: 370 }}>
                  <Box className="formTextField">
                    <div>
                      {/* Nome */}
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="text"
                          variant="standard"
                          label={t("nome")}
                          value={nome}
                          error={erroNome}
                          onChange={(e) => {
                            setNome(e.target.value);
                            setErroNome(false);
                          }}
                        />
                      </FormControl>

                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoria}
                        >
                          {t("escolhacategoria")}*
                        </InputLabel>
                        <Select
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          error={erroCategoria}
                          fullWidth
                          variant="standard"
                        >
                          {categorias.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.categoria}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroModalidade}
                        >
                          {t("escolhamodalidade")}*
                        </InputLabel>
                        <Select
                          value={selectedModalidade}
                          onChange={handleModalidadeChange}
                          error={erroModalidade}
                          fullWidth
                          variant="standard"
                        >
                          {modalidades.map((modalidade) => (
                            <MenuItem key={modalidade.id} value={modalidade.id}>
                              {modalidade.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroProfessor}
                        >
                          {t("escolhaprofessor")}*
                        </InputLabel>
                        <Select
                          value={selectedProfessor}
                          onChange={handleProfessorChange}
                          error={erroProfessor}
                          fullWidth
                          variant="standard"
                        >
                          {professores.map((professor) => (
                            <MenuItem key={professor.id} value={professor.id}>
                              {professor.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("numvagas")}
                          value={numvagas}
                          error={erroNumVagas}
                          onChange={(e) => {
                            const value = e.target.value;

                            setNumVagas(value);
                            setErroNumVagas(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={`${t("precomensal")} (€)`}
                          value={precomensal}
                          error={erroPrecoMensal}
                          onChange={(e) => {
                            const value = e.target.value;
                            setPrecoMensal(value);
                            setErroPrecoMensal(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={`${t("precoanual")} (€)`}
                          value={precoanual}
                          error={erroPrecoAnual}
                          onChange={(e) => {
                            const value = e.target.value;
                            setPrecoAnual(value);
                            setErroPrecoAnual(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={2}
                          value={obs}
                          label={t("descricao")}
                          error={erroObs}
                          onChange={(e) => {
                            setObs(e.target.value);
                            setErroObs(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={2}
                          value={obsEN}
                          label={t("observacoesEN")}
                          error={erroObsEN}
                          onChange={(e) => {
                            setObsEN(e.target.value);
                            setErroObsEN(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={2}
                          value={obsES}
                          label={t("observacoesES")}
                          error={erroObsES}
                          onChange={(e) => {
                            setObsES(e.target.value);
                            setErroObsES(false);
                          }}
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Scrollbar>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* editar turma */}

          <Dialog
            open={openEditarTurma}
            onClose={handleCloseEditarTurma}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "95%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleCloseEditarTurma}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Scrollbar style={{ height: 400 }}>
                    <Box className="formTextField">
                      <div>
                        {/* Nome */}
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="text"
                            variant="standard"
                            label={t("nome")}
                            value={nomee}
                            error={erroNomee}
                            onChange={(e) => {
                              setNomee(e.target.value);
                              setErroNomee(false);
                            }}
                          />
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroCategoriae}
                          >
                            {t("escolhacategoria")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedCategorye}
                            onChange={handleCategoryChangee}
                            error={erroCategoriae}
                            fullWidth
                            variant="standard"
                          >
                            {categoriase.map((categoria) => (
                              <MenuItem key={categoria.id} value={categoria.id}>
                                {categoria.categoria}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroModalidadee}
                          >
                            {t("escolhamodalidade")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedModalidadee}
                            onChange={handleModalidadeChangee}
                            error={erroModalidadee}
                            fullWidth
                            variant="standard"
                          >
                            {modalidadese.map((modalidade) => (
                              <MenuItem
                                key={modalidade.id}
                                value={modalidade.id}
                              >
                                {modalidade.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroProfessore}
                          >
                            {t("escolhaprofessor")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedProfessore}
                            onChange={handleProfessorChangee}
                            error={erroProfessore}
                            fullWidth
                            variant="standard"
                          >
                            {professorese.map((professor) => (
                              <MenuItem key={professor.id} value={professor.id}>
                                {professor.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("numvagas")}
                            value={numvagase}
                            error={erroNumVagase}
                            onChange={(e) => {
                              const value = e.target.value;

                              setNumVagase(value);
                              setErroNumVagase(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={`${t("precomensal")} (€)`}
                            value={precomensale}
                            error={erroPrecoMensale}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrecoMensale(value);
                              setErroPrecoMensale(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={`${t("precoanual")} (€)`}
                            value={precoanuale}
                            error={erroPrecoAnuale}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrecoAnuale(value);
                              setErroPrecoAnuale(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            rows={2}
                            value={obse}
                            label={t("descricao")}
                            error={erroObse}
                            onChange={(e) => {
                              setObse(e.target.value);
                              setErroObse(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            rows={2}
                            value={obsENe}
                            label={t("observacoesEN")}
                            error={erroObsENe}
                            onChange={(e) => {
                              setObsENe(e.target.value);
                              setErroObsENe(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField3" fullWidth>
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            rows={2}
                            value={obsESe}
                            label={t("observacoesES")}
                            error={erroObsESe}
                            onChange={(e) => {
                              setObsESe(e.target.value);
                              setErroObsESe(false);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Scrollbar>

                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Eliminar */}
                  <Dialog
                    open={eliminar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("eliminarTurma")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("eliminarRegisto")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          eliminarTurmas();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                        }}
                        autoFocus
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("editar")}
                  </Button>

                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${eliminarbtnpeq})`
                        : `url(${eliminarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupEliminar();
                    }}
                  >
                    <TrashIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("eliminar")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaTurmasA;
