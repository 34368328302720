import Cookies from "js-cookie";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import {
  Paper,
  Button,
  Icon,
  InputAdornment,
  TextField,
  unstable_composeClasses,
  Typography,
} from "@mui/material";
import {
  faFilePen,
  faArrowLeft,
  faTags,
  faQuestion,
  faRightFromBracket,
  faSwimmingPool,
  faSwimmer,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import bcrypt from "bcryptjs";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  getUtilizadoresId,
  suspendeUtilizador,
  suspendeUtilizadorEmail,
} from "../../api/requests/Utilizadores";
import { selectedIdsLookupSelector } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { logout } from "../../api/requests/AuthServer";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toolbar from "@mui/material/Toolbar";
import {
  faInbox,
  faEnvelope,
  faPhone,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

function Ajuda(props) {
  const [freeze, setFreeze] = useState(false);

  const [mobileOpen, setMobileOpen] = React.useState(true);

  const [content, setContent] = useState("default");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = useState(true);
  const drawerWidth = open ? "240px" : "55px";

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const handleButtonClick = (option) => {
    setContent(option);
  };

  const getTypographyContent = () => {
    switch (content) {
      case "faq":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Perguntas Frequentes
            </Typography>
            <Typography paragraph>
              Aqui estão algumas perguntas frequentes e as respetivas respostas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso excluir a minha conta?
            </Typography>
            <Typography paragraph>
              Para excluires a tua conta deves entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#8B9FB2", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Posso desativar a minha conta temporariamente?
            </Typography>
            <Typography paragraph>
              Sim podes. Para isso terás de entrar em contacto com o suporte
              através do email{" "}
              <a
                style={{ color: "#8B9FB2", textDecoration: "none" }}
                href="mailto:suporte@tectank.pt"
              >
                suporte@tectank.pt
              </a>
              .
            </Typography>
            <Typography variant="h6" gutterBottom>
              Tenho o método de pagamento mensal numa turma, como é feito o
              pagamento e em que dia?
            </Typography>
            <Typography paragraph>
              Os pagamentos mensais devem ser feitos até dia 8 de cada mês, caso
              contrário perderás parte das ações disponíveis na aplicação. O
              pagamento pode ser feito através da aplicação consultando o menu
              Pagamentos e indo a pagamentos pendentes. Poderás pagar por um dos
              seguintes métodos de pagamento: multibanco, mbway, cartão de
              crédito ou no balcão da piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Quero desinscrever de uma turma, mas diz que não posso, porquê?
            </Typography>
            <Typography paragraph>
              Caso tenhas um pagamento mensal pendente não te poderás
              desinscrever da turma até atualizares este pagamento.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Recebi um email a dizer que a marcação de nado de livre foi
              cancelada, fiquei sem o ticket?
            </Typography>
            <Typography paragraph>
              Não, caso a tua sessão de nado livre tenha sido cancelada, o teu
              ticket não foi descontado, pelo que poderás voltar a fazer uma
              marcação.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como funcionam os QR Codes?
            </Typography>
            <Typography paragraph>
              Os QR Codes apenas são gerados até 30 min antes do início da
              atividade e até ao final desta. O qr code deve ser apresentado na
              entrada da piscina de modo a dar entrada na piscina.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso eu não tenha telemóvel como dou entrada na piscina sem qr
              code?
            </Typography>
            <Typography paragraph>
              Basta informares o funcionário, ele terá os poderes para dar a tua
              entrada para a tua marcação/aula.
            </Typography>
          </>
        );
      case "contacts":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Contactos
            </Typography>
            <Typography paragraph>
              Aqui estão os nossos contactos para que possas entrar em contacto
              connosco.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
            </Typography>
            <Typography paragraph>suporte@tectank.pt</Typography>
            <Typography variant="h6" gutterBottom>
              Telefone:
            </Typography>
            <Typography paragraph>+351 911 741 470</Typography>
          </>
        );
      case "appointment":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Marcações
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito às marcações.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso fazer uma marcação?
            </Typography>
            <Typography paragraph>
              Para fazeres uma marcação deves primeiro indicar a piscina na qual
              predentes marcar nado livre ou inscrever numa turma. De seguida,
              deves selecionar o utilizador, que podes ser tu ou alguém que
              esteja registado na tua conta familiar e escolher a opção que
              prentendes, aulas ou nado livre.
            </Typography>
            <Typography paragraph>
              No caso das aulas, deves primeiro escolher a modalidade e de
              seguida a turma que pretenderes. No passo seguinte deves escolher
              o tipo de pagamento: mensal ou anual. O pagamento mensal deve ser
              feito até dia 8 de cada mês, pela aplicação ou no balcão da
              piscina.
            </Typography>
            <Typography paragraph>
              No caso do nado livre deves, de seguida, escolher o dia e a hora a
              que tens disponibilidade. Seguidamente, indicas se pretendes
              comprar um novo pack ou, caso já tenhas algum podes optar por
              descontar a marcação desse pack.
            </Typography>
            <Typography paragraph>
              Por fim, escolhes o método de pagamento: cartão de crédito,
              multibanco, mbway ou pagamento no local. <br></br> Confirma tudo
              antes de procederes ao pagamento. <br></br> O seguro e a inscrição
              são pagos uma vez por ano.<br></br> Assim que o pagamento for
              processado, a atividade irá aparecer no calendário. Podes também
              acompanhar o estado dos teus pagamentos no Menu de Pagamentos.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso ver as minhas marcações?
            </Typography>
            <Typography paragraph>
              Poderás consultar as tuas marcações no calendário do dashboard.
              Carregando em cada evento poderás obter mais informações.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como cancelo uma marcação?
            </Typography>
            <Typography paragraph>
              Para cancelar uma marcação deves aceder ao menu Editar Marcações.
              De seguida, deves carregar na marcação que pretendes alterar e
              carregar em cancelar marcação. A marcação ficará cancelada e o teu
              ticket será automaticamente reposto.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como me desinscrevo de uma turma?
            </Typography>
            <Typography paragraph>
              Para desinscrever de uma turma deves aceder ao menu Editar
              Marcações. De seguida, deves carregar numa das aulas da turma que
              pretendes sair e carregar em desinscrever desta turma. Sairás de
              seguida da turma. Não te esqueças que caso tenhas pagamentos
              pendentes não te poderás desinscrever até atualizares os
              pagamentos.
            </Typography>
          </>
        );
      case "pools":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Piscinas
            </Typography>
            <Typography paragraph>
              {" "}
              Esta secção diz respeito às piscinas.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como vejo as piscinas em que estou inscrito?
            </Typography>
            <Typography paragraph>
              Deves aceder ao menu as minhas piscinas e aí irão aparecer todas
              as piscinas nas quais estás inscrito.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como me posso inscrever numa piscina?
            </Typography>
            <Typography paragraph>
              Para te inscrever numa piscina, deves pesquisar pela piscina,
              selecionar e carregar em Quero increver-me! Terás de aceitar os
              termos e condições da piscina. Assim que estiveres inscrito
              poderás usufruir de todas as ações da aplicação para a tua nova
              piscina!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como me posso desinscrever de uma piscina?
            </Typography>
            <Typography paragraph>
              Para desinscrever de uma piscina, deves aceder a minhas piscinas e
              carregar na piscina que pretendes desinscrever. De seguida, deves
              carregar em desincrever.
            </Typography>
          </>
        );
      case "family":
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Família
            </Typography>
            <Typography paragraph>
              Esta secção diz respeito à conta familiar.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Como posso inscrever o meu filho na aplicação?
            </Typography>
            <Typography paragraph>
              Para inscrever o teu filho deves aceder a Família e adicionar
              criança.
            </Typography>
            <Typography variant="h6" gutterBottom>
              O meu filho pode estar registado duas vezes na aplicação?
            </Typography>
            <Typography paragraph>
              Não. Apenas é possível um único registo.
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Bem-vindo!
            </Typography>
            <Typography paragraph>
              Este é a página de ajuda, escolhe uma opção para saber mais!
            </Typography>
          </>
        );
    }
  };
  return (
    <div className="divForm">
      <Header />
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{
            width: { xs: drawerWidth, sm: drawerWidth },
            flexShrink: { sm: 0, xs: 0 },
          }}
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: "60px",
                border: "none",
              },
            }}
            open={open}
          >
            {
              <List sx={{ marginTop: "5%" }}>
                <IconButton
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    margin: 1,
                    "&:hover": {
                      outline: "none",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={handleDrawerClick}
                >
                  {open ? <ChevronLeftIcon /> : <MenuIcon />}
                  {open && (
                    <Typography
                      variant="body1"
                      sx={{
                        alignSelf: "center",
                        marginLeft: open ? 1 : 0,
                        fontSize: 20,
                        color: "#333333",
                      }}
                    >
                      Ajuda
                    </Typography>
                  )}
                </IconButton>
                {open ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5%",
                      }}
                    ></div>
                    <ListItem key="FAQ" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor: content == "faq" ? "#36B6FF" : null,
                          color: content == "faq" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("faq")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "faq" ? "#fff" : null}
                            icon={faQuestionCircle}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Perguntas Frequentes" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Contacts" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "contacts" ? "#FACF5A" : null,
                          color: content == "contacts" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("contacts")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "contacts" ? "#fff" : null}
                            icon={faPhone}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Contactos" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Appointment" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "appointment" ? "#065F63" : null,
                          color: content == "appointment" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("appointment")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "appointment" ? "#fff" : null}
                            icon={faSwimmer}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Marcar" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Pools" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "pools" ? "#49BEB6" : null,
                          color: content == "pools" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("pools")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "pools" ? "#fff" : null}
                            icon={faSwimmingPool}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Piscinas" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key="Family" disablePadding>
                      <ListItemButton
                        style={{
                          backgroundColor:
                            content == "family" ? "#FF5958" : null,
                          color: content == "family" ? "#fff" : null,
                        }}
                        onClick={() => handleButtonClick("family")}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon
                            color={content == "family" ? "#fff" : null}
                            icon={faPeopleRoof}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Família" />
                      </ListItemButton>
                    </ListItem>
                  </div>
                ) : null}
              </List>
            }
          </Drawer>
        </Box>

        <Box
          sx={{
            flexGrow: 0,
            display: open && isMobile ? "none" : "flex",
          }}
        >
          <Typography component="div" sx={{ p: 2 }}>
            {getTypographyContent()}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default Ajuda;
