import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Button, Menu, MenuItem, requirePropFactory } from "@mui/material";
import { Colors } from "../../values/Colors";
import { getPiscinas } from "../../api/requests/Piscinas";
import HeaderExterno from "../../components/HeaderExterno";
import FooterExterno from "../../components/FooterExterno";
import {
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faEdit,
  faPen,
  faSave,
  faQuestion,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { toast } from "react-toastify";
import Search4 from "../../components/DataTable/Search4";
import {
  getInscricoesId,
  getUtilizadores,
} from "../../api/requests/Utilizadores";

function ListaPiscinasU() {
  function goBack() {
    navigate("/");
  }
  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [piscinas, setPiscinas] = useState([]);
  const [idu, setIdu] = useState("");
  const [inscritas, setInscritas] = useState([]);
  const [piscinasCopy, setPiscinasCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [semDados, setSemDados] = useState(true);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;

    // Verificacoes
    const id = Cookies.get("uId");
    setIdu(id);
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    getInscricoesId(id).then((res) => {
      if (res.success) {
        let arrayFinal2 = [];
        res.data.data.forEach((element2) => {
          arrayFinal2.push(element2.id_piscina);
          setInscritas(arrayFinal2);
        });
      }
    });

    getPiscinas().then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          if (element.status == 1) {
            const linha = {
              id: element.id_piscina_edificio,
              nome: element.nome,
              cidade: element.cidade,
              morada: element.morada,
              img: element.foto_perfil,
              inscrito: 1,
            };
            arrayFinal.push(linha);
          }
        });
        setPiscinas(arrayFinal);
        setPiscinasCopy(arrayFinal);
        setSemDados(false);
      } else {
        toast.error(res.error);
        setSemDados(true);
      }
    });
  }, []);

  useEffect(() => {
    if (search) {
      let arrayPiscinas = piscinasCopy;
      arrayPiscinas = arrayPiscinas.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.nome.toLowerCase().includes(search.toLowerCase()) ||
          element.cidade.toLowerCase().includes(search.toLowerCase())
      );
      setPiscinas(arrayPiscinas);
    } else {
      setPiscinas(piscinasCopy);
    }
  }, [search]);

  return (
    <div className="bodyPage3">
      <Header />
      <div className="listPages">
        {semDados == true ? (
          <CircularProgress disableShrink style={{ marginTop: 20 }} />
        ) : (
          <div className="divTabela">
            <Search
              onSearch={(value) => {
                setSearch(value);
              }}
            />
            <Paper
              elevation={0}
              style={{
                maxHeight: 700,
                overflow: "auto",
                marginTop: 20,
                backgroundColor: "#f7f7f7",
              }}
            >
              <List style={{ marginTop: 10 }}>
                {piscinas.map((element) => (
                  <Card
                    onClick={() => {
                      navigate("/poolpageu/" + element.id, {
                        state: {
                          iduser: idu,
                        },
                      });
                    }}
                    sx={{
                      display: "flex",
                      margin: "8px",
                      justifyContent: "space-between",
                      boxShadow: 0,
                      borderRadius: 15,
                      alignItems: "center",
                      cursor: "pointer",
                      backgroundColor: inscritas.includes(element.id)
                        ? "red"
                        : "white",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 5,
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto", cursor: "pointer" }}>
                        <Typography component="div">{element.nome}</Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                        >
                          {element.morada}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                        >
                          {element.cidade}
                        </Typography>
                      </CardContent>
                    </Box>
                    {/* alterar para  a foto */}
                    <Avatar
                      src={element.img}
                      sx={{ width: 100, height: 100, marginRight: "50px" }}
                    />
                  </Card>
                ))}
              </List>
            </Paper>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ListaPiscinasU;
