import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { login } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import { faArrowLeft, faQuestion } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FooterExterno from "../components/FooterExterno";
import { width } from "@mui/system";

function Policy() {
  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);

  return (
    <div className="bodyLoginpolicy">
      <div className="session2">
        <div className="topright">
          <IconButton onClick={handleOpenHelp} size={"small"}>
            {" "}
            <QuestionMarkCircleIcon style={{ width: "25px", color: "black" }} />
          </IconButton>
        </div>
        <p className="ajuda2">
          <br></br>
          <br></br>A sua privacidade é importante para nós.<br></br> É política
          do GoPool respeitar a sua privacidade em relação a qualquer informação
          sua que possamos coletar no site GoPool, e outros sites que possuímos
          e operamos.<br></br>Solicitamos informações pessoais apenas quando
          realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por
          meios justos e legais, com o seu conhecimento e consentimento.
          <br></br> Também informamos por que estamos coletando e como será
          usado.Apenas retemos as informações coletadas pelo tempo necessário
          para fornecer o serviço solicitado. Quando armazenamos dados,
          protegemos dentro de meios comercialmente aceitáveis ​​para evitar
          perdas e roubos, bem como acesso, divulgação, cópia, uso ou
          modificação não autorizados.
          <br></br>Não compartilhamos informações de identificação pessoal
          publicamente ou com terceiros, exceto quando exigido por lei.
          <br></br>O nosso site pode ter links para sites externos que não são
          operados por nós.<br></br> Esteja ciente de que não temos controle
          sobre o conteúdo e práticas desses sites e não podemos aceitar
          responsabilidade por suas respectivas políticas de privacidade. Você é
          livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.O uso continuado de nosso site será considerado como
          aceitação de nossas práticas em torno de privacidade e informações
          pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do
          usuário e informações pessoais, entre em contacto connosco.O serviço
          Google AdSense que usamos para veicular publicidade usa um cookie
          DoubleClick para veicular anúncios mais relevantes em toda a Web e
          limitar o número de vezes que um determinado anúncio é exibido para
          você.Para mais informações sobre o Google AdSense, consulte as FAQs
          oficiais sobre privacidade do Google AdSense.<br></br>
          Utilizamos anúncios para compensar os custos de funcionamento deste
          site e fornecer financiamento para futuros desenvolvimentos.
          <br></br> Os cookies de publicidade comportamental usados ​​por este
          site foram projetados para garantir que você forneça os anúncios mais
          relevantes sempre que possível, rastreando anonimamente seus
          interesses e apresentando coisas semelhantes que possam ser do seu
          interesse.Vários parceiros anunciam em nosso nome e os cookies de
          rastreamento de afiliados simplesmente nos permitem ver se nossos
          clientes acessaram o site através de um dos sites de nossos parceiros,
          para que possamos creditá-los adequadamente e, quando aplicável,
          permitir que nossos parceiros afiliados ofereçam qualquer promoção que
          pode fornecê-lo para fazer uma compra.Compromisso do Usuário O usuário
          se compromete a fazer uso adequado dos conteúdos e da informação que o
          GoPool oferece no site e com caráter enunciativo, mas não limitativo:
          <br></br>
          <br></br>A) Não se envolver em atividades que sejam ilegais ou
          contrárias à boa fé a à ordem pública;<br></br>
          <br></br>B) Não difundir propaganda ou conteúdo de natureza racista,
          xenofóbica, betano ou azar, qualquer tipo de pornografia ilegal, de
          apologia ao terrorismo ou contra os direitos humanos;<br></br>
          <br></br>C) Não causar danos aos sistemas físicos (hardwares) e
          lógicos (softwares) do GoPool, de seus fornecedores ou terceiros, para
          introduzir ou disseminar vírus informáticos ou quaisquer outros
          sistemas de hardware ou software que sejam capazes de causar danos
          anteriormente mencionados.<br></br>
          <br></br>
          <br></br>Esperemos que esteja esclarecido e, como mencionado
          anteriormente, se houver algo que você não tem certeza se precisa ou
          não, geralmente é mais seguro deixar os cookies ativados, caso
          interaja com um dos recursos que você usa em nosso site.
          <br></br>Esta política é efetiva a partir de 29 Maio 2023.
        </p>
      </div>

      <FooterExterno />
    </div>
  );
}

export default Policy;
