import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faPen,
  faTrash,
  faUpload,
  faPaperPlane,
  faPersonSwimming,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import "react-phone-input-2/lib/bootstrap.css";

import "react-phone-number-input/style.css";

import { toast } from "react-toastify";

import "@react-pdf-viewer/core/lib/styles/index.css";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import {
  editarTurma,
  eliminarTurma,
  getTurma,
} from "../../api/requests/Turmas";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import {
  listaAcoes,
  listaPiscinasAgua,
  listaProfessores,
  listaModalidades,
} from "../../api/requests/PiscinaAgua";
import moment from "moment";

function EditarTurmaA() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const { state } = useLocation();
  const { idTurma } = state || {};
  const [edicao, setEdicao] = useState(true);
  const [freeze, setFreeze] = useState(false);
  const [nome, setNome] = useState("");
  const [idPiscina, setIdPiscina] = useState("");
  const [idEE, setIdEE] = useState("");
  const [numvagas, setNumVagas] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [piscina, setPiscinas] = useState([]);
  const [modalidades, setModalidade] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [precomensal, setPrecoMensal] = useState(0);
  const [precoanual, setPrecoAnual] = useState(0);
  const [obs, setObs] = useState("");
  const [obsES, setObsES] = useState("");
  const [obsEN, setObsEN] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [selectedModalidade, setSelectedModalidade] = useState("");
  const [erroNome, setErroNome] = useState("");
  const [erroObs, setErroObs] = useState("");
  const [erroObsEN, setErroObsEN] = useState("");
  const [erroObsES, setErroObsES] = useState("");
  const [erroPrecoMensal, setErroPrecoMensal] = useState("");
  const [erroPrecoAnual, setErroPrecoAnual] = useState("");
  const [erroNumVagas, setErroNumVagas] = useState("");
  const [erroPreco, setErroPreco] = useState("");
  const [erroCategoria, setErroCategoria] = useState("");
  const [erroProfessor, setErroProfessor] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [erroModalidade, setErroModalidade] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
    setErroProfessor(false);
  };

  const handleModalidadeChange = (event) => {
    setSelectedModalidade(event.target.value);
    setErroModalidade(false);
  };

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);

  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    getTurma({ id: idTurma })
      .then((res) => {
        if (res.success) {
          let dados = res.data.data[0];

          setDadosOriginais(dados);

          setNome(dados.nome);
          setSelectedCategory(dados.id_categoria);
          setSelectedModalidade(dados.id_modalidade);
          setSelectedProfessor(dados.id_professor);
          setNumVagas(dados.num_vagas);
          setPrecoAnual(dados.preco_anual);
          setPrecoMensal(dados.preco_mensal);
          setObs(dados.obs);
          setObsEN(dados.obs_en);
          setObsES(dados.obs_es);

          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaModalidades({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_modalidade,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setModalidade(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaProfessores({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    if (!edicao) {
      document.getElementsByClassName("containerForm3")[0].style.marginTop =
        "40px";
      document.getElementsByClassName(
        "cardOpcoesPiscinaEliminar3"
      )[0].style.cursor = "default";
    } else {
      if (carregamentoDadosCompleto) {
        document.getElementsByClassName("containerForm3")[0].style.marginTop =
          "10px";
      }
    }
  }, [edicao]);

  function cancelar() {
    handleClosePopupCancelar();
    document.getElementsByClassName(
      "cardOpcoesPiscinaEliminar3"
    )[0].style.cursor = "pointer";
    setEdicao(!edicao);

    //Colocar os dados originais
    setNome(dadosOriginais.nome);
    setSelectedCategory(dadosOriginais.id_categoria);
    setSelectedModalidade(dadosOriginais.id_modalidade);
    setSelectedProfessor(dadosOriginais.id_professor);
    setNumVagas(dadosOriginais.num_vagas);
    setPrecoAnual(dadosOriginais.preco_anual);
    setPrecoMensal(dadosOriginais.preco_mensal);
    setObs(dadosOriginais.obs);
    setObsEN(dadosOriginais.obs_en);
    setObsES(dadosOriginais.obs_es);

    //Limpar erros e mensagens de erro
    setErroNome(false);
    setErroCategoria(false);
    setErroModalidade(false);
    setErroProfessor(false);
    setErroNumVagas(false);
    setErroPrecoAnual(false);
    setErroPrecoMensal(false);
    setErroObs(false);
    setErroObsEN(false);
    setErroObsES(false);

    window.scrollTo(0, 0);
  }
  function editarTurmas() {
    setFreeze(true);
    editarTurma({
      id_turma: idTurma,
      nome: nome,
      id_categoria: selectedCategory,
      id_professor: selectedProfessor,
      id_modalidade: selectedModalidade,
      num_vagas: numvagas,
      preco_mensal: precomensal,
      preco_anual: precoanual,
      obs1: obs,
      obs2: obsEN,
      obs3: obsES,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false);
        setEdicao(!edicao);
        //Scroll para o início da página
        window.scrollTo(0, 0);
        toast.success(t("dadosEditadosSucesso"));
        document.getElementsByClassName(
          "cardOpcoesPiscina1_1"
        )[0].style.cursor = "pointer";
      } else {
        if (res.status == 500) {
          setFreeze(false)
          navigate("/500");
        }
        setFreeze(false)
        toast.error(res.error);
      }
    });
  }

  function validacao() {
    if (
      nome &&
      numvagas &&
      selectedCategory &&
      selectedProfessor &&
      selectedModalidade &&
      precomensal &&
      precoanual &&
      obs &&
      obsEN &&
      obsES
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarTurmas();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (!nome) {
        setErroNome(true);
      }
      if (!obs) {
        setErroObs(true);
      }
      if (!obsEN) {
        setErroObsEN(true);
      }
      if (!obsES) {
        setErroObsES(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (!selectedProfessor) {
        setErroProfessor(true);
      }
      if (!selectedModalidade) {
        setErroModalidade(true);
      }
      if (numvagas <= 0) {
        setErroNumVagas(true);
      }
      if (precomensal <= 0) {
        setErroPrecoMensal(true);
      }
      if (precoanual <= 0) {
        setErroPrecoAnual(true);
      }
    }
  }

  function eliminarTurmas() {
    setFreeze(true);
    eliminarTurma({
      id_turma: idTurma,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
          toast.success(t("turmaEliminada"));
          navigate("/classlista");
        } else {
          setFreeze(false);
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 406) {
            toast.info(res.error);
          } else {
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      {carregamentoDadosCompleto ? (
        <div className="divForm">
          {!edicao ? (
            <div className="headerModoEdicao">
              <Button
                className="cancelarEdicao"
                variant="contained"
                color="error"
                onClick={handleClickOpenPopupCancelar}
              >
                {t("cancelar")}
              </Button>
              <Button
                className="guardarEdicao"
                variant="contained"
                color="success"
                onClick={() => {
                  validacao();
                }}
              >
                {t("guardar")}
              </Button>
            </div>
          ) : null}
          <div className="containerForm3">
            <div className="topleftcliente">
              {edicao ? (
                <IconButton
                  onClick={() => {
                    navigate("/classlista");
                  }}
                  size={isMobile ? "small" : "medium"}
                >
                  {" "}
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </IconButton>
              ) : null}
            </div>

            {/* <div className="align-right">
              <h3 className="titleList">{t("editarTurmas")}</h3>
            </div> */}
          </div>
          <div>
            <div className="formPage4">
              {!isMobile ? (
                <Box className="formTextField5">
                  <div className="cardsDivPiscina2">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        id="cardOpcoesPiscina1_1"
                        className="cardOpcoesPiscinaEliminar3"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>
                  <div className="cardsDivPiscina2">
                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                  {/* <div className="cardsDivPiscina2">
                    <Button
                      className="cardOpcoesPiscinaEliminar3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card5
                          : Colors.cardInativo,
                      }}
                      onClick={{}}
                    >
                      <FontAwesomeIcon
                        className="iconCards"
                        icon={faPersonSwimming}
                      />
                      {t("veraulas")}
                    </Button>
                  </div> */}
                </Box>
              ) : (
                <div>
                  <div className="divCardsPiscinaMobile">
                    {edicao ? (
                      <Button
                        id="editarCard"
                        className="cardOpcoesPiscina1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.card1,
                        }}
                        onClick={() => {
                          scrollToDiv();
                        }}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("editar")}
                      </Button>
                    ) : (
                      <Button
                        className="cardOpcoesPiscina1_1"
                        variant="outlined"
                        style={{
                          backgroundColor: Colors.footer,
                        }}
                        disabled={!edicao}
                      >
                        <FontAwesomeIcon className="iconCards" icon={faPen} />
                        {t("modoEdicao")}
                      </Button>
                    )}
                  </div>

                  <div className="divCardsPiscinaMobile">
                    <Button
                      className="cardOpcoesPiscina3"
                      variant="outlined"
                      disabled={!edicao}
                      style={{
                        backgroundColor: edicao
                          ? Colors.card3
                          : Colors.cardInativo,
                      }}
                      onClick={handleClickOpenPopupEliminar}
                    >
                      <FontAwesomeIcon className="iconCards" icon={faTrash} />
                      {t("eliminar")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Cancelar edição */}
          <Dialog
            open={cancelarEdicao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("cancelarEdicao")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("textoCancelarEdicao")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  cancelar();
                }}
                autoFocus
              >
                {t("sim")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupCancelar}
              >
                {t("nao")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={eliminar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("eliminarTurma")}
            </DialogTitle>
            <DialogContent>{t("eliminarRegisto")}</DialogContent>
            <DialogActions>
              {" "}
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="error"
                onClick={handleClosePopupEliminar}
              >
                {t("cancelar")}
              </Button>
              <Button
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="success"
                onClick={() => {
                  eliminarTurmas();
                }}
                autoFocus
              >
                {t("eliminar")}
              </Button>
            </DialogActions>
          </Dialog>

          <div>
            <div className="formPage">
              <Box className="formTextField">
                <div>
                  {/* Nome */}
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="text"
                      variant="standard"
                      label={t("nome")}
                      value={nome}
                      error={erroNome}
                      onChange={(e) => {
                        setNome(e.target.value);
                        setErroNome(false);
                      }}
                    />
                  </FormControl>

                  <FormControl className="formTextField2" fullWidth>
                    <InputLabel
                      className="formTextField22"
                      error={erroCategoria}
                    >
                      {t("escolhacategoria")}*
                    </InputLabel>
                    <Select
                      disabled={edicao}
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      error={erroCategoria}
                      fullWidth
                      variant="standard"
                    >
                      {categorias.map((categoria) => (
                        <MenuItem key={categoria.id} value={categoria.id}>
                          {categoria.categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <InputLabel
                      className="formTextField22"
                      error={erroModalidade}
                    >
                      {t("escolhamodalidade")}*
                    </InputLabel>
                    <Select
                      disabled={edicao}
                      value={selectedModalidade}
                      onChange={handleModalidadeChange}
                      error={erroModalidade}
                      fullWidth
                      variant="standard"
                    >
                      {modalidades.map((modalidade) => (
                        <MenuItem key={modalidade.id} value={modalidade.id}>
                          {modalidade.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <InputLabel
                      className="formTextField22"
                      error={erroProfessor}
                    >
                      {t("escolhaprofessor")}*
                    </InputLabel>
                    <Select
                      disabled={edicao}
                      value={selectedProfessor}
                      onChange={handleProfessorChange}
                      error={erroProfessor}
                      fullWidth
                      variant="standard"
                    >
                      {professores.map((professor) => (
                        <MenuItem key={professor.id} value={professor.id}>
                          {professor.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={t("numvagas")}
                      value={numvagas}
                      error={erroNumVagas}
                      onChange={(e) => {
                        const value = e.target.value;

                        setNumVagas(value);
                        setErroNumVagas(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={`${t("precomensal")} (€)`}
                      value={precomensal}
                      error={erroPrecoMensal}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPrecoMensal(value);
                        setErroPrecoMensal(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField2" fullWidth>
                    <TextField
                      disabled={edicao}
                      required
                      type="number"
                      variant="standard"
                      label={`${t("precoanual")} (€)`}
                      value={precoanual}
                      error={erroPrecoAnual}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPrecoAnual(value);
                        setErroPrecoAnual(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={2}
                      value={obs}
                      label={t("observacoes")}
                      error={erroObs}
                      onChange={(e) => {
                        setObs(e.target.value);
                        setErroObs(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={2}
                      value={obsEN}
                      label={t("observacoesEN")}
                      error={erroObsEN}
                      onChange={(e) => {
                        setObsEN(e.target.value);
                        setErroObsEN(false);
                      }}
                    />
                  </FormControl>
                  <FormControl className="formTextField3" fullWidth>
                    <TextField
                      disabled={edicao}
                      style={{ textAlign: "left" }}
                      variant="outlined"
                      multiline
                      rows={2}
                      value={obsES}
                      label={t("observacoesES")}
                      error={erroObsES}
                      onChange={(e) => {
                        setObsES(e.target.value);
                        setErroObsES(false);
                      }}
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
}

export default EditarTurmaA;
