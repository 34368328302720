import Cookies from "js-cookie";
import { makeRequestDelete2, makeRequestPost2, makeRequestPut2,makeRequestGet2 } from "../api";

//Lista packs
export function listaPacks(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("packs/list", dados, token);
}

//Get pack
export function getPack(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("packs/id", dados, token);
}

//Adicionar packs
export function adicionarPacks(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("packs/", dados, token);
}

//Editar packs
export function editarPacks(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2("packs/", dados, token);
}

//Eliminar packs
export function eliminarPacks(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2("packs/", dados, token);
}
