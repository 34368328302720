import Cookies from "js-cookie";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import {
  Paper,
  Button,
  Icon,
  InputAdornment,
  TextField,
  unstable_composeClasses,
  Typography,
} from "@mui/material";
import {
  faFilePen,
  faArrowLeft,
  faTags,
  faQuestion,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import bcrypt from "bcryptjs";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  getUtilizadoresId,
  suspendeUtilizador,
  suspendeUtilizadorEmail,
} from "../../api/requests/Utilizadores";
import { selectedIdsLookupSelector } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { logout } from "../../api/requests/AuthServer";

function Definicoes() {
  function goBack() {
    navigate("/");
  }

  function goAlteraDados() {
    navigate("/change_user_data", {
      state: {
        definicoes: 1,
      },
    });
  }

  function goAlteraPassword() {
    navigate("/alterarpassword");
  }

  //variaveis

  const { id } = useParams();

  const [nome, setNome] = useState("");
  const [foto, setFoto] = useState("");
  const [IDU, setIDU] = useState("");
  const [freeze, setFreeze] = useState(false);
  //Dialogs
  const [suspender, setSuspender] = useState(false);
  const handleClickOpenPopupSuspender = () => {
    setSuspender(true);
  };
  const handleClosePopupSuspender = () => {
    setSuspender(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [excluir, setExcluir] = useState(false);
  const handleClickOpenPopupExcluir = () => {
    setExcluir(true);
  };
  const handleClosePopupExcluir = () => {
    setExcluir(false);
  };
  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  //Traduções
  const { t, i18n } = useTranslation();
  //Navigator
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    setIDU(id);
    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    //get dados utilizador
    getUtilizadoresId(id, {})
      .then((res) => {
        if (res.success) {
          let dados = res.data.data;
          setNome(dados.nome);
          setFoto(dados.foto);
          setIDU(id);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  async function Logout() {
    const refresh_token = Cookies.get("refresh_token");
    navigate("/");
    await logout({
      token: refresh_token,
    }).then(async (res) => {
      if (res.success) {
        //Remover os dados e encaminhar para a página de Login
        sessionStorage.removeItem("id");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("language");
        sessionStorage.removeItem("nome");
        sessionStorage.removeItem("dados");
        sessionStorage.removeItem("key");
        Cookies.remove("token");
        Cookies.remove("refresh_token");
        Cookies.remove("uId");
        Cookies.remove("uName");
        Cookies.remove("uEmail");
        Cookies.remove("uKey");
        Cookies.remove("uData");
        // toast.info(t("sessaoExpirada"));
      }
    });
  }

  async function suspenderConta() {
    setFreeze(true);
    await suspendeUtilizador({
      status: 0,
    })
      .then(async (res) => {
        if (res.success) {
          setTimeout(async function () {
            suspendeUtilizadorEmail({
              id: IDU,
            }).then((res) => {
              if (res.success) {
                setFreeze(false);
                toast.success(t("suspensoSucesso"));
                navigate("/excluded");
              } else {
                if (res.status == 500) {
                  setFreeze(false)
                  navigate("/500");
                }
                setFreeze(false)
                toast.error(res.error);
              }
            });
          }, 3000);
        } else {
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          }
          setFreeze(false)
          toast.error(res.error);
        }
      })
      .catch((error) => {
        setFreeze(false)
        //toast.warn(t("erroWebservice"));
      });
  }

  return (
    <div className="divForm">
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <Header />
      <div
        className="toprightDefinicoes"
        onClick={() => {
          handleClickOpen();
        }}
      >
        <Link className="headerIcons2">
          <FontAwesomeIcon icon={faRightFromBracket} />
        </Link>
      </div>
      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          marginTop: "40px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        {!isMobile ? (
          <Avatar src={foto} sx={{ width: 200, height: 200 }} />
        ) : (
          <Avatar src={foto} sx={{ width: 100, height: 100 }} />
        )}
        <h5 className="titulonome" style={{ margin: 20 }}>
          {nome}
        </h5>
        <Stack
          direction="column"
          spacing={3}
          sx={{ marginTop: "5%", alignContent: "center" }}
        >
          <button type="button" className="userbtn" onClick={goAlteraDados}>
            {t("editarDados")}
          </button>
          {Cookies.get("utilizador") == "superadmin" ||
          Cookies.get("utilizador") == "supercomercial" ||
          Cookies.get("utilizador") == "comercial" ||
          Cookies.get("utilizador") == "professor" ? (
            <button
              type="button"
              className="pedidosbtn"
              onClick={() => {
                {
                  Cookies.get("utilizador") == "professor"
                    ? navigate("/teacher-orders")
                    : navigate("/user-orders");
                }
              }}
            >
              {t("pedidos")}
            </button>
          ) : null}

          {/* <button
            type="button"
            className="excluirbtn"
            onClick={handleClickOpenPopupExcluir}
          >
            {t("excluirConta")}
          </button> */}
          <button type="button" className="userbtn" onClick={goAlteraPassword}>
            {t("alteraPassword")}
          </button>
        </Stack>
      </Stack>

      <Dialog
        open={excluir}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("excluirConta")}</DialogTitle>
        <DialogContent>
          <p>{t("confirmaExcluir")}</p>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="error"
            onClick={handleClosePopupExcluir}
          >
            {t("cancelar")}
          </Button>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="success"
            onClick={suspenderConta}
            autoFocus
          >
            {t("confirmar")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("terminarSessao")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("terminarSessaoTexto")}
            {t("terminarSessaoTexto2")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="success"
            onClick={() => {
              Logout();
            }}
            autoFocus
          >
            {t("sim")}
          </Button>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Definicoes;
