import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getNumPedidos } from "../../api/requests/Pedidos";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Colors } from "../../values/Colors";
import Nav from "../../components/Nav";

function Dashboard_SuperAdmin() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Variável que guarda o número de pedidos pendentes
  const [numPedidosPendentes, setNumPedidosPendentes] = useState("");
  const [chave, setChave] = useState(Cookies.get("uKey"));

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    let chave = Cookies.get("uKey");
    if (chave != "null") {
      getNumPedidos({ chave: chave })
        .then((res) => {
          if (res.success) {
            setNumPedidosPendentes(res.data.data);
          } else {
            if (res.status == 500) {
              navigate("/500");
            }
            toast.error(res.error);
          }
        })
        .catch((error) => {
          setFreeze(false);
          //toast.warn(t("erroWebservice"));
        });
    }
  }, []);

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />
      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "40px",
            backgroundColor: "transparent",
          }}
        >
          <h3 className="titleMarcar">{t("DashboardSuperAdmin")}</h3>
          <Box>
            <div className="cardsDivDashboard">
              <Button
                className="cardDashboard"
                variant="contained"
                style={{
                  backgroundColor: Colors.cardAtalho1,
                }}
                onClick={() => {
                  navigate("/entities");
                }}
              >
                {t("entidades")}
              </Button>
              <Button
                className="cardDashboard"
                variant="contained"
                style={{
                  backgroundColor: Colors.cardAtalho2,
                }}
                onClick={() => {
                  navigate("/customers_app");
                }}
              >
                {t("clientes")}
              </Button>
              <Button
                className="cardDashboard"
                variant="contained"
                style={{
                  backgroundColor: Colors.cardAtalho3,
                }}
                onClick={() => {
                  navigate("/users");
                }}
              >
                {t("utilizadores")}
              </Button>
              {numPedidosPendentes &&
              (chave == "d9fab3fa-85fe-4447-ad93-76b2144ab8b1" ||
                chave == "971a7c18-dbf8-4b87-88a2-3f563c354f07") ? (
                <Badge
                  className="cardDashboard2"
                  badgeContent={numPedidosPendentes}
                  color="primary"
                >
                  <Button
                    className="cardDashboard"
                    variant="contained"
                    style={{
                      backgroundColor: Colors.cardAtalho4,
                    }}
                    onClick={() => {
                      navigate("/orders");
                    }}
                  >
                    {t("pedidos")}
                  </Button>
                </Badge>
              ) : chave == "d9fab3fa-85fe-4447-ad93-76b2144ab8b1" ||
                chave == "971a7c18-dbf8-4b87-88a2-3f563c354f07" ? (
                <Button
                  className="cardDashboard"
                  variant="contained"
                  style={{
                    backgroundColor: Colors.cardAtalho4,
                  }}
                  onClick={() => {
                    navigate("/orders");
                  }}
                >
                  {t("pedidos")}
                </Button>
              ) : null}
            </div>
          </Box>
        </Paper>
      </div>
    </div>
  );
}

export default Dashboard_SuperAdmin;
