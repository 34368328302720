import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Colors } from "../../values/Colors";
import {
  faArrowLeft,
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faEdit,
  faEye,
  faPen,
  faSave,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getEntidades } from "../../api/requests/Entidades";
import {
  enviarEmailStatusPedido,
  getPedidoId,
  getPedidosAll,
  putPedido,
} from "../../api/requests/Pedidos";
import { deletePiscina, postStatusPiscina } from "../../api/requests/Piscinas";
import {
  deleteUtilizador,
  postStatusUtilizadores,
} from "../../api/requests/Utilizadores";
import Search5 from "../../components/DataTable/Search5";
import {
  AlteraProfEmail,
  AvisoProfEmail,
  cancelarEvento,
  cancelarEventoEmail,
} from "../../api/requests/PiscinaAgua";
import Nav from "../../components/Nav";
import { listaProfessores } from "../../api/requests/PiscinaAgua";

function PedidosAulaA() {
  const [pedidos, setPedidos] = useState([]);
  const [pedidosCopy, setPedidosCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [dados, setDados] = useState("");

  const [detalhes, setDetalhes] = useState(false);
  const [arrayDetalhes, setArrayDetalhes] = useState([]);
  const [vistaDetalhes, setVistaDetalhes] = useState(false);

  const [confirmacaoStatus, setConfirmacaoStatus] = useState(false);
  const [confirmacaoStatusId, setConfirmacaoStatusId] = useState("");
  const [tipoStatus, setTipoStatus] = useState("");
  const [tipo, setTipo] = useState("");
  const [acao, setAcao] = useState("");
  const [idUtilizadorPiscina, setIdUtilizadorPiscina] = useState("");

  const [freeze, setFreeze] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "acao", "actions"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "acao", "actions"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const moment = require("moment");

  const handleClickOpenPopupDetalhes = () => {
    setDetalhes(true);
  };

  const handleClosePopupDetalhes = () => {
    setDetalhes(false);
  };

  const handleClickOpenPopupStatus = () => {
    setConfirmacaoStatus(true);
  };

  const handleClosePopupStatus = () => {
    setConfirmacaoStatusId("");
    setTipoStatus("");
    setConfirmacaoStatus(false);
  };
  const [professores, setProfessores] = useState([]);
  const [professorEscolhido, setProfessorEscolhido] = useState("");
  const handleProfessorChange = (event) => {
    setProfessorEscolhido(event.target.value);
  };
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }
    listaProfessores({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_utilizador,
              nome: element.nome,
            };
            arrayFinal.push(linha);
            setProfessores(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    getPedidosAll({ id_piscina: localStorage.getItem("idPiscina") })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_pedido,
              tipo:
                element.tipo == 1
                  ? t("piscina")
                  : element.tipo == 2
                  ? t("utilizador")
                  : t("aula"),
              acao:
                element.acao == 1
                  ? t("suspender")
                  : element.acao == 2
                  ? t("eliminar")
                  : element.acao == 3
                  ? t("ativar")
                  : element.acao == 4
                  ? t("cancelar")
                  : null,
              status:
                element.status == 0
                  ? t("pendente")
                  : element.status == 1
                  ? t("aprovado")
                  : t("reprovado"),
              id_utilizador_piscina: element.id_utilizador_piscina,
              hora_inicio: element.hora_inicio,
              hora_fim: element.hora_fim,
              data: element.data,
              motivo: element.motivo,
            };
            arrayFinal.push(linha);
          });
          setPedidos(arrayFinal);
          setPedidosCopy(arrayFinal);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  useEffect(() => {
    if (search) {
      let arrayPedidos = pedidosCopy;
      arrayPedidos = arrayPedidos.filter(
        (element) =>
          // Elementos pelos quais se vai pesquisar
          element.tipo.toLowerCase().includes(search.toLowerCase()) ||
          element.acao.toLowerCase().includes(search.toLowerCase())
      );
      setPedidos(arrayPedidos);
    } else {
      setPedidos(pedidosCopy);
    }
  }, [search]);

  function verDetalhes(id) {
    handleClickOpenPopupDetalhes();
    getPedidoId(id)
      .then((res) => {
        if (res.success) {
          let dados = res.data;
          let dados2 = res.data;
          let arrayFinal = [];
          let data = {
            id_pedido: id,
            tipo: dados.tipo,
            status: dados.status,
            acao: dados.acao,
            criado: dados2.criado,
            id_utilizador_piscina: dados.id_utilizador_piscina,
            nome_utilizador_piscina:
              dados2.nomeUtilizadorPiscinaEliminarSuspender,
            tipo_utilizador_piscina: dados2.tipoUtilizadorAEliminarSuspender,
            id_utilizador_pediu: dados.id_utilizador_pedido,
            nome_utilizador_pediu: dados2.nomeQuemFezPedido,
            tipo_utilizador_pediu: dados2.tipoUtilizadorQuemFezPedido,
            motivo: dados2.motivo,
            id_utilizador_aprovou: dados.id_utilizador_aprovou,
            nome_utilizador_aprovou: dados2.nomeQuemAprovouReprovou,
            tipo_utilizador_aprovou: dados2.tipoUtilizadorQuemAprovouReprovou,
            hora_inicio: dados2.hora_inicio,
            hora_fim: dados2.hora_fim,
            data: dados2.data,
            modalidade: dados2.id_modalidade,
            id_substituto: dados2.id_substituto,
          };
          arrayFinal.push(data);
          setProfessorEscolhido(arrayFinal[0].id_substituto);
          setArrayDetalhes(arrayFinal);
          setVistaDetalhes(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }

  function alterarStatus(id, tipo, acao, idUP, dados) {
    setConfirmacaoStatusId(id);
    setTipo(tipo);
    setAcao(acao);
    setIdUtilizadorPiscina(idUP);
    setDados(dados);
    handleClickOpenPopupStatus();
  }

  function alterarStatusPedido() {
    setConfirmacaoStatus(false);
    setFreeze(true);
    var statusFinal = "";
    if (tipoStatus == "aprovado") {
      statusFinal = 1;
    } else if (tipoStatus == "reprovado") {
      statusFinal = 2;
    }
    if (statusFinal) {
      //Executar a ação e aprovar pedido
      if (statusFinal == 1) {
        //Cancelar aula
   
        if (professorEscolhido == "") {
          cancelarEvento({
            tipo: 1,
            id: dados.id,
            data: moment(dados.data).format("YYYY-MM-DD"),
            hora_inicio: dados.hora_inicio.split(":").slice(0, 2).join(":"),
            hora_fim: dados.hora_fim.split(":").slice(0, 2).join(":"),
            obs: dados.motivo,
          })
            .then((res) => {
              let idCancelamento = res.data.id;
              if (res.success) {
                putPedido({
                  id: confirmacaoStatusId,
                  status: statusFinal,
                  id_substituto: 0,
                })
                  .then((res) => {
                    if (res.success) {
                      setTimeout(async function () {
                        enviarEmailStatusPedido({
                          id: confirmacaoStatusId,
                        }).then((res2) => {
                          if (res2.success) {
                            setTimeout(async function () {
                              cancelarEventoEmail({
                                id_cancelamento: idCancelamento,
                              }).then((res3) => {
                                if (res3.success) {
                                  toast.success(t("pedidoAprovado"));
                                  setTipoStatus("");
                                  setConfirmacaoStatusId("");
                                  setFreeze(false);
                                  navigate("/dashboard_admin");
                                } else {
                                  setFreeze(false);
                                  toast.error(res.error);
                                }
                              });
                            }, 3000);
                          } else {
                            setFreeze(false);
                            toast.error(res.error);
                          }
                        });
                      }, 3000);
                    } else {
                      if (res.status == 500) {
                        setFreeze(false);
                        navigate("/500");
                      }
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  })
                  .catch((error) => {
                    setFreeze(false);
                    //toast.warn(t("erroWebservice"));
                  });
              } else {
                if (res.status == 500) {
                  setFreeze(false);
                  navigate("/500");
                }
                setFreeze(false);
                toast.error(res.error);
              }
            })
            .catch((error) => {
              setFreeze(false);
              //toast.warn(t("erroWebservice"));
            });
        } else {
          putPedido({
            id: confirmacaoStatusId,
            status: statusFinal,
            id_substituto: professorEscolhido,
          })
            .then((res) => {
              if (res.success) {
                setTimeout(async function () {
                  enviarEmailStatusPedido({
                    id: confirmacaoStatusId,
                  }).then((res2) => {
                    if (res2.success) {
                      setTimeout(async function () {
                      
                        AlteraProfEmail({
                          id_pedido: confirmacaoStatusId,
                        }).then((res3) => {
                          if (res3.success) {
                            AvisoProfEmail({
                              id_pedido: confirmacaoStatusId,
                            }).then((res4) => {
                              if (res4.success) {
                                toast.success(t("pedidoAprovado"));
                                setTipoStatus("");
                                setConfirmacaoStatusId("");
                                setFreeze(false);
                                navigate("/dashboard_admin");
                              } else {
                                setFreeze(false);
                                toast.error(res.error);
                              }
                            });
                          } else {
                            setFreeze(false);
                            toast.error(res.error);
                          }
                        });
                      }, 3000);
                      
                    } else {
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  });
                }, 3000);
              } else {
                if (res.status == 500) {
                  setFreeze(false);
                  navigate("/500");
                }
                setFreeze(false);
                toast.error(res.error);
              }
            })
            .catch((error) => {
              setFreeze(false);
              //toast.warn(t("erroWebservice"));
            });
        }
      }
      //Reprovar pedido
      else {
        putPedido({
          id: confirmacaoStatusId,
          status: statusFinal,
        })
          .then((res) => {
            if (res.success) {
              setTimeout(async function () {
                enviarEmailStatusPedido({ id: confirmacaoStatusId }).then(
                  (res2) => {
                    if (res2.success) {
                      toast.success(t("pedidoReprovado"));
                      setTipoStatus("");
                      setConfirmacaoStatusId("");
                      setFreeze(false);
                      navigate("/dashboard_admin");
                    } else {
                      setFreeze(false);
                      toast.error(res.error);
                    }
                  }
                );
              }, 3000);
            } else {
              if (res.status == 500) {
                navigate("/500");
                setFreeze(false);
              }
              setFreeze(false);
              toast.error(res.error);
            }
          })
          .catch((error) => {
            setFreeze(false);
            //toast.warn(t("erroWebservice"));
          });
      }
    }
  }

  return (
    <div style={{ backgroundColor: "#171e23" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        {freeze && (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              width: window.innerWidth,
              height: window.innerHeight,
              zIndex: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
            }}
          >
            <Dialog
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                textAlign: "center",
              }}
            >
              <DialogContent>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("aguarde")}
                </Typography>
                <CircularProgress disableShrink style={{ marginTop: 20 }} />
              </DialogContent>
            </Dialog>
          </div>
        )}
        <h3 className="titleList">{t("listaPedidoAlteracaoAula")}</h3>
        <div className="divTabelaM">
          {/* <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/dashboard_admin");
              }}
              size={window.innerWidth < 600 ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}

          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Search5
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}
            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                maxWidth: 100,
                flex: 1,
                hide: columnsHidden.includes("id"),
              },
              { field: "tipo", headerName: t("tipo"), type: "string", flex: 1 },
              {
                field: "acao",
                headerName: t("acao"),
                flex: 1,
                type: "string",
                hide: columnsHidden.includes("acao"),
              },
              {
                field: "detalhes",
                type: "actions",
                headerName: t("detalhes"),
                width: 100,
                cellClassName: "detalhes",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faEye}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        verDetalhes(id);
                      }}
                    />,
                  ];
                },
              },
              {
                field: "status",
                headerName: t("estado"),
                Width: 250,
                type: "actions",

                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == t("pendente") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12 }}
                            variant="contained"
                            color="warning"
                          >
                            {t("pendente")}
                          </Button>
                        </>
                      )}
                      {params.row.status == t("aprovado") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12 }}
                            variant="contained"
                            color="success"
                          >
                            {t("aprovado")}
                          </Button>
                        </>
                      )}
                      {params.row.status == t("reprovado") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12 }}
                            variant="contained"
                            color="error"
                          >
                            {t("reprovado")}
                          </Button>
                        </>
                      )}
                    </div>
                  );
                },
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 200,
                cellClassName: "actions",
                hide: columnsHidden.includes("actions"),

                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == t("pendente") && (
                        <>
                          <Button
                            style={{ width: 80, fontSize: 12, marginRight: 2 }}
                            variant="contained"
                            color="success"
                            onClick={() => {
                              setTipoStatus("aprovado");
                              alterarStatus(
                                params.row.id,
                                params.row.tipo,
                                params.row.acao,
                                params.row.id_utilizador_piscina,
                                params.row
                              );
                            }}
                          >
                            {t("aprovado")}
                          </Button>
                          <Button
                            style={{ width: 80, fontSize: 12, marginLeft: 2 }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setTipoStatus("reprovado");
                              alterarStatus(
                                params.row.id,
                                params.row.tipo,
                                params.row.acao,
                                params.row.id_utilizador_piscina,
                                params.row
                              );
                            }}
                          >
                            {t("reprovado")}
                          </Button>
                        </>
                      )}
                    </div>
                  );
                },
              },
            ]}
            rows={pedidos}
            checkboxSelection={isMobile ? false : true}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>
        </div>
      </div>

      <Dialog
        onClose={handleClosePopupDetalhes}
        open={detalhes}
        aria-labelledby="customized-dialog-title"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{t("detalhes")}</DialogTitle>
        {vistaDetalhes ? (
          <DialogContent>
            {arrayDetalhes[0].status == 0 && (
              <>
                <Button
                  style={{ width: 80, fontSize: 12 }}
                  variant="contained"
                  color="warning"
                >
                  {t("pendente")}
                </Button>
              </>
            )}
            {arrayDetalhes[0].status == 1 && (
              <>
                <Button
                  style={{ width: 80, fontSize: 12 }}
                  variant="contained"
                  color="success"
                >
                  {t("aprovado")}
                </Button>
              </>
            )}
            {arrayDetalhes[0].status == 2 && (
              <>
                <Button
                  style={{ width: 80, fontSize: 12 }}
                  variant="contained"
                  color="error"
                >
                  {t("reprovado")}
                </Button>
              </>
            )}
            <h5 className="h5Detalhes">{t("infoPedido")}</h5>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("idPedido")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].id_pedido}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("tipoEAcao")}:{" "}
              <span className="detalhesTexto">
                <span className="detalhesTexto">
                  {t("cancelar") + " "}
                  {t("aula").toLowerCase()}
                </span>
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("dataPedido")}:{" "}
              <span className="detalhesTexto">{arrayDetalhes[0].criado}</span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("motivo")}:{" "}
              <span className="detalhesTexto">{arrayDetalhes[0].motivo}</span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("professorSubstituto")}:{" "}
              <Select
                value={professorEscolhido}
                onChange={handleProfessorChange}
                fullWidth
                variant="standard"
                disabled={arrayDetalhes[0].status == 0 ? false : true}
              >
                {professores.map((professor) => (
                  <MenuItem key={professor.id} value={professor.id}>
                    {professor.nome}
                  </MenuItem>
                ))}
              </Select>
            </DialogContentText>
            <h5 className="h5Detalhes">{t("infoAula")}</h5>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("modalidade")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].modalidade == 1
                  ? t("natacao_adultos")
                  : arrayDetalhes[0].modalidade == 2
                  ? t("natacao_bebes")
                  : arrayDetalhes[0].modalidade == 3
                  ? t("natacao_idosos")
                  : arrayDetalhes[0].modalidade == 4
                  ? t("natacao_gravidas")
                  : arrayDetalhes[0].modalidade == 5
                  ? t("hidroginastica")
                  : arrayDetalhes[0].modalidade == 6
                  ? t("hidrobike")
                  : arrayDetalhes[0].modalidade == 7
                  ? t("hidroterapia")
                  : arrayDetalhes[0].modalidade == 8
                  ? t("marcacaoNadoLivre")
                  : arrayDetalhes[0].modalidade == 9
                  ? t("hidropower")
                  : arrayDetalhes[0].modalidade == 10
                  ? t("equipas_federadas")
                  : arrayDetalhes[0].modalidade == 11
                  ? t("escolaNatacao")
                  : null}
              </span>
            </DialogContentText>

            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("data")}:{" "}
              <span className="detalhesTexto">
                {moment(arrayDetalhes[0].data).format("DD/MM/YYYY")}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("horaInicio")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].hora_inicio.split(":").slice(0, 2).join(":")}
              </span>
            </DialogContentText>
            <DialogContentText
              style={{ paddingBottom: 3, paddingTop: 3 }}
              id="alert-dialog-description"
            >
              {t("horaFim")}:{" "}
              <span className="detalhesTexto">
                {arrayDetalhes[0].hora_fim.split(":").slice(0, 2).join(":")}
              </span>
            </DialogContentText>

            {arrayDetalhes[0].status == 2 && (
              <div>
                <h5 className="h5Detalhes">{t("infoUtilizadorAR")}</h5>
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("id")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].id_utilizador_aprovou}
                  </span>
                </DialogContentText>
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("nome")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].nome_utilizador_aprovou}
                  </span>
                </DialogContentText>
                <DialogContentText
                  style={{ paddingBottom: 3, paddingTop: 3 }}
                  id="alert-dialog-description"
                >
                  {t("tipoUtilizador")}:{" "}
                  <span className="detalhesTexto">
                    {arrayDetalhes[0].tipo_utilizador_aprovou == 1
                      ? t("cliente")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 2
                      ? t("professor")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 3
                      ? t("professorCliente")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 4
                      ? t("funcionario")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 5
                      ? t("admin_")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 6
                      ? t("comercial")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 7
                      ? t("supercomercial")
                      : arrayDetalhes[0].tipo_utilizador_aprovou == 8
                      ? t("superadmin")
                      : null}
                  </span>
                </DialogContentText>
              </div>
            )}
            <div
              style={{
                cursor: "pointer",
                alignItems: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {arrayDetalhes[0].status == 0 && (
                <>
                  <Button
                    style={{ width: 80, fontSize: 12, marginRight: 5 }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setTipoStatus("aprovado");
                      alterarStatus(
                        arrayDetalhes[0].id_pedido,
                        arrayDetalhes[0].tipo,
                        arrayDetalhes[0].acao,
                        arrayDetalhes[0].id_utilizador_piscina,
                        arrayDetalhes[0]
                      );
                    }}
                  >
                    {t("aprovado")}
                  </Button>
                  <Button
                    style={{ width: 80, fontSize: 12, marginLeft: 5 }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setTipoStatus("reprovado");
                      alterarStatus(
                        arrayDetalhes[0].id_pedido,
                        arrayDetalhes[0].tipo,
                        arrayDetalhes[0].acao,
                        arrayDetalhes[0].id_utilizador_piscina,
                        arrayDetalhes[0]
                      );
                    }}
                  >
                    {t("reprovado")}
                  </Button>
                </>
              )}
            </div>
          </DialogContent>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </Dialog>
      <Dialog
        open={confirmacaoStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {tipoStatus == "aprovado"
            ? t("aprovarPedido")
            : tipoStatus == "reprovado"
            ? t("reprovarPedido")
            : null}
        </DialogTitle>
        <DialogContent>
          {tipoStatus == "aprovado" && (
            <DialogContentText id="alert-dialog-description">
              {t("textoAprovar")} {confirmacaoStatusId}? {t("textAcaoPedido")}
            </DialogContentText>
          )}
          {tipoStatus == "reprovado" && (
            <DialogContentText id="alert-dialog-description">
              {t("textoReprovar")} {confirmacaoStatusId}?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="success"
            onClick={() => {
              alterarStatusPedido();
            }}
            autoFocus
          >
            {t("sim")}
          </Button>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="error"
            onClick={handleClosePopupStatus}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PedidosAulaA;
