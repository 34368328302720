import {
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faTemperatureQuarter,
} from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { loginPost } from "../api/requests/AuthServer";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import FooterExterno from "../components/FooterExterno";
import { confirmacaoEmail } from "../api/requests/Registo";
import { useParams } from "react-router";
import errorgif from "../images/error.gif";
import successgif from "../images/success.gif";

function ActiveAccount() {
  const [ativo, setAtivo] = useState(false);
  const [inativo, setInativo] = useState(false);
  const [loading, setLoading] = useState(true);

  const { id, token } = useParams();
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    confirmacaoEmail(id, token).then((res) => {
      if (res.success) {
        setTimeout(async function () {
          setLoading(false);
          setAtivo(true);
        }, 2000);
      } else {
        setTimeout(async function () {
          setLoading(false);
          setInativo(true);
        }, 2000);
      }
    });
  }, []);
  function goLogin() {
    navigate("/login");
  }

  return (
    <div className="bodyLogin2">
      <div className="session2">
        {loading === true && (
          <div className="inicioCol" style={{ paddingBottom: "100px" }}>
            <CircularProgress disableShrink style={{ marginTop: 20 }} />
          </div>
        )}

        {ativo === true && loading === false ? (
          <div className="inicioCol" style={{ paddingBottom: "100px" }}>
            <img src={successgif} alt="Success" />

            <p className="ativarconta" style={{ marginBottom: "150px" }}>
              {t("contaativada")}
            </p>
            <button className="iniciobtn" onClick={goLogin}>
              {t("iniciarSessao")}
            </button>
          </div>
        ) : inativo === true && loading === false ? (
          <div className="inicioCol" style={{ paddingBottom: "100px" }}>
            <img src={errorgif} alt="Error" />

            <p className="ativarconta" style={{ marginBottom: "150px" }}>
              {t("contaativadaerro")}
            </p>
            <button type="button" className="iniciobtn" onClick={goLogin}>
              {t("iniciarSessao")}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default ActiveAccount;
