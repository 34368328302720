import Cookies from "js-cookie";
import {
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestPost2,
  makeRequestPut2,
} from "../api";

//Get Professores
export function getProfessores(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("professor/all", dados, token);
}

//Post Professor
export function postProfessor(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("professor", dados, token);
}

//Enviar email -Post Professor
export function enviarEmailPostProfessor(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2("professor/active-account", dados, token);
}

//Get por id
export function getProfessor(id) {
  let token = Cookies.get("token");
  return makeRequestPost2(`professor/id/${id}`, {}, token);
}

//Editar
export function putProfessor(dados) {
  let token = Cookies.get("token");
  return makeRequestPut2(`professor/`, dados, token);
}

//Alterar status
export function putStatusProfessor(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`professor/status`, dados, token);
}

//Eliminar
export function deleteProfessor(dados) {
  let token = Cookies.get("token");
  return makeRequestDelete2(`professor/`, dados, token);
}

// Lista de aulas
export function listaAulasProfessor(dados) {
  let token = Cookies.get("token");
  return makeRequestPost2(`professor/lista/aulas`, dados, token);
}

//Lista de professores da piscina
export function listaProfessoresPiscina(dados) {
  let token = Cookies.get("token");
  return makeRequestGet2(`piscinaagua/professores`, dados, token);
}
