import React, { useEffect, useState } from "react";
import logo from "../images/logosemfundoc.png";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import FooterExterno from "../components/FooterExterno";
import { toast } from "react-toastify";


function Inicio() {
  //Traduções
  const { t, i18n } = useTranslation();
  toast.clearWaitingQueue({containerId:1});
  toast.clearWaitingQueue();
  //Navigator
  const navigate = useNavigate();

  function goLogin() {
    navigate("/login");
  }


  function goDiretorio() {
    navigate("/poolslist");
  }


  return (
    
    <div className="session">
{/* <img src={require("../images/Fundo Iniciar.png")} style={{ width: 'auto', height: 'auto', display: 'block', margin: '0 auto' }} /> */}

      <form className="inicioCol">
        
        <Stack direction="column" spacing={0}>
          <button  type="button" className="iniciobtn" onClick={goLogin} style={{marginBottom:"10%"}}>
            {t("iniciarSessao")}
          </button>
          <button  type="button" className="iniciobtn2" onClick={goDiretorio}style={{marginBottom:"15%"}}>
         
 {t("diretorioPiscina")}</button>
        </Stack>
        <Link className="textlinklogin2" to={"/register"}>
      {t("registar")}
        </Link>
        <Link
            className="textlinklogin2"
            style={{paddingTop:"1%",paddingBottom:"1.5%"}}
            to={"/loginbo"}
          >
            {t("loginGestao")}
          </Link>
      </form>
      <FooterExterno/>
    </div>
    
  );
}
export default Inicio;
