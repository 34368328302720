import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../values/Colors";

const Search5 = ({ onSearch }) => {
  //Traduções
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleSearchButtonClick = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };
  return (
    <div style={{ textAlign: "start", flexDirection: "row", display: "flex" }}>
      <span
        className="searchButton"
        onClick={handleSearchButtonClick}
        style={{
          marginLeft: "10px",
          zIndex: 2,
        }}
      ></span>
      <TextField
        variant="outlined"
        label={t("procurar")}
        placeholder={t("pesquisa5")}
        className="search"
        type={"text"}
        id="search"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputLabel-root": {
            content: '""',
            display: "inline-block",
            marginLeft: "10px",
            marginTop: "-7px",
            opacity: isFocused ? 0 : 1,
          },
          "& .MuiInputLabel-root::before": {
            content: '""',
            display: "inline-block",
            marginLeft: "10px",
            marginTop: "-7px",
          },

          "& .MuiInputBase-root": {
            marginTop: "-7px",
            marginLeft: "10px",
          },
          width: isSearchExpanded ? "300px" : "0px",
          zIndex: 1,
        }}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
      />
    </div>
  );
};

export default Search5;
