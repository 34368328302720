import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faFloppyDisk,
  faHand,
  faStop,
  faTrash,
  faClose,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Cookies, { set } from "js-cookie";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Search } from "../../components/DataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Scrollbar } from "react-scrollbars-custom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import editarbtnpeq from "../../images/BotaoEditarPequeno.svg";
import eliminarbtnpeq from "../../images/BotaoEliminarPequeno.svg";
import cancelarbtnPeq from "../../images/BotaoCancelarPequeno.svg";
import guardarbtnPeq from "../../images/BotaoGuardarPequeno.svg";
import {
  DataGrid,
  esES,
  ptBR,
  enUS,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import editarbtn from "../../images/BotaoEditar.svg";
import eliminarbtn from "../../images/BotaoEliminar.svg";
import guardarbtn from "../../images/BotaoGuardar.svg";
import cancelarbtn from "../../images/BotaoCancelar.svg";
import simbtn from "../../images/BotaoSim.svg";
import naobtn from "../../images/BotaoNao.svg";
import {
  Button,
  IconButton,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
  DialogContentText,
} from "@mui/material";
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../values/Colors";
import { Box } from "@mui/system";

import {
  editarDescontos,
  eliminarDescontos,
  getDesconto,
  listaDescontos,
} from "../../api/requests/Descontos";
import Search1 from "../../components/DataTable/Search1";
import moment from "moment";
import Nav from "../../components/Nav";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import { adicionarDescontos } from "../../api/requests/Descontos";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaAcoes, listaPiscinasAgua } from "../../api/requests/PiscinaAgua";

function ListaDescontosA() {
  const [descontos, setDescontos] = useState([]);
  const [descontosCopy, setDescontosCopy] = useState([]);
  const [search, setSearch] = useState("");
  
  // const privilegios = JSON.parse(Cookies.get("privilegios"));

  //Traduções
  const { t, i18n } = useTranslation();

  const [openCriarDescontos, setOpenCriarDescontos] = useState(false);
  const handleCloseCriarDescontos = () => setOpenCriarDescontos(false);

  const handleClickOpenCriarDescontos = () => {
    setOpenCriarDescontos(true);
  };

  const [openEditarDescontos, setOpenEditarDescontos] = useState(false);
  const handleCloseEditarDescontos = () => {
    setOpenEditarDescontos(false);
    cancelar();
    setEdicao(true);
  };

  const handleClickOpenEditarDescontos = () => {
    setOpenEditarDescontos(true);
  };

  //Navigator
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //esconder colunas quando o ecra fica mais pequeno
  const [columnsHidden, setColumnsHidden] = useState(
    isMobile ? ["id", "datainicio", "datafim"] : []
  );
  const handleResize = () => {
    setColumnsHidden(isMobile ? ["id", "datainicio", "datafim"] : []);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (search) {
      let arrayDescontos = descontosCopy;
      arrayDescontos = arrayDescontos.filter((element) =>
        // Elementos pelos quais se vai pesquisar
        element.nome.toLowerCase().includes(search.toLowerCase())
      );
      setDescontos(arrayDescontos);
    } else {
      setDescontos(descontosCopy);
    }
  }, [search]);

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = localStorage.getItem("idPiscina");
    const idEntidade = localStorage.getItem("idEntidade");

    //Caso não tenha id nem token
    if (!id && !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaDescontos({
      id_piscina: idPiscina,
      id_entidade_exploradora: idEntidade,
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_desconto,
              nome: element.nome,
              nomeacao: element.nome_acao,
              status: element.status,
              datainicio: moment(element.data_inicio).format("DD-MM-YYYY"),
              datafim: moment(element.data_fim).format("DD-MM-YYYY"),
            };

            arrayFinal.push(linha);
            setDescontos(arrayFinal);
            setDescontosCopy(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, [descontos,descontosCopy]);
  const [isHoveredFilterButton, setIsHoveredFilterButton] = useState(false);
  const [isHoveredExportButton, setIsHoveredExportButton] = useState(false);

  const handleFilterButtonMouseEnter = () => {
    setIsHoveredFilterButton(true);
  };

  const handleFilterButtonMouseLeave = () => {
    setIsHoveredFilterButton(false);
  };

  const handleExportButtonMouseEnter = () => {
    setIsHoveredExportButton(true);
  };

  const handleExportButtonMouseLeave = () => {
    setIsHoveredExportButton(false);
  };

  const filterButtonStyles = {
    backgroundColor: isHoveredFilterButton ? "white" : "",
    boxShadow: isHoveredFilterButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    marginRight: "1%",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  const exportButtonStyles = {
    backgroundColor: isHoveredExportButton ? "white" : "",
    boxShadow: isHoveredExportButton ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "6px",
    color: "#0A56FA",
    textTransform: "capitalize",
    fontSize: isMobile ? "9px" : "12px",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: isMobile ? "45px" : "0", // Defina o marginRight condicionalmente
        }}
      >
        {/* <GridToolbarFilterButton
          style={filterButtonStyles}
          onMouseEnter={handleFilterButtonMouseEnter}
          onMouseLeave={handleFilterButtonMouseLeave}
        /> */}
        <GridToolbarExport
          style={exportButtonStyles}
          // onMouseEnter={handleExportButtonMouseEnter}
          // onMouseLeave={handleExportButtonMouseLeave}
        />
      </GridToolbarContainer>
    );
  }

  //Criar Desconto

  const [freeze, setFreeze] = useState(false);

  const [id_acao, setIdAcao] = useState("");
  const [id_piscina, setIdPiscina] = useState("");
  const [id_ee, setIdEE] = useState("");
  const [desconto, setDesconto] = useState("");
  const [descricao, setDescricao] = useState("");
  const [data_fim, setDataFim] = useState("");
  const [data_inicio, setDataInicio] = useState("");
  const [categorias, setCategoria] = useState([]);
  const [acao, setAcao] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAcao, setSelectedAcao] = useState("");
  const [numFilhos, setNumFilhos] = useState(0);

  const [erroIdAcao, setErroIdAcao] = useState(false);
  const [erroDesconto, setErroDesconto] = useState(false);
  const [erroDescricao, setErroDescricao] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroCategoria, setErroCategoria] = useState(false);
  const [erroAcao, setErroAcao] = useState(false);
  const [erroNumFilhos, setErroNumFilhos] = useState(false);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  const handleAcaoChange = (event) => {
    setSelectedAcao(event.target.value);
    setErroAcao(false);
  };
  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaAcoes({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];

          res.data.data.forEach((element) => {
            const linha = {
              id: element.id,
              acao: element.nome,
            };
            arrayFinal.push(linha);
            setAcao(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function atualizarDescontos() {
    listaDescontos({
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    })
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_desconto,
              nome: element.nome,
              nomeacao: element.nome_acao,
              status: element.status,
              datainicio: moment(element.data_inicio).format("DD-MM-YYYY"),
              datafim: moment(element.data_fim).format("DD-MM-YYYY"),
            };
            arrayFinal.push(linha);
            setDescontos(arrayFinal);
            setDescontosCopy(arrayFinal);


          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }
  function limparVariaveis() {
    setDesconto("");
    setDescricao("");
    setSelectedAcao("");
    setSelectedCategory("");
    setDataInicio("");
    setDataFim("");
  }
  function criarDescontos() {
    setFreeze(true);
    adicionarDescontos({
      id_acao: selectedAcao,
      id_piscina: id_piscina,
      id_entidade_exploradora: id_ee,
      desconto: desconto,
      filhos_conta_familiar: numFilhos,
      categoria_utilizador: selectedCategory,
      data_inicio: data_inicio.toISOString().slice(0, 10),
      data_fim: data_fim.toISOString().slice(0, 10),
      descricao: descricao,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        toast.success(t("descontoAdicionar"));
        setFreeze(false);
        handleCloseCriarDescontos();
        atualizarDescontos();
        limparVariaveis();
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (
      desconto &&
      data_inicio &&
      descricao &&
      selectedCategory &&
      selectedAcao &&
      numFilhos
    ) {
      let confirmarDadosPreenchidos = true;
      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        criarDescontos();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);
      // ----RESPONSÁVEL----
      //Validação do nome
      if (!desconto) {
        setErroDesconto(true);
      }

      if (!data_inicio) {
        setErroDataInicio(true);
      }
      if (!descricao) {
        setErroDescricao(true);
      }
      if (!id_acao) {
        setErroIdAcao(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }

      if (!selectedCategory) {
        setErroCategoria(true);
      }

      if (!selectedAcao) {
        setErroAcao(true);
      }
      if (!numFilhos) {
        setErroNumFilhos(true);
      }
    }
  }
  // editar desconto

  const [edicao, setEdicao] = useState(true);
  const [idDesconto, setIdDesconto] = useState("");

  const [id_acaoe, setIdAcaoe] = useState("");
  const [id_piscinae, setIdPiscinae] = useState("");
  const [id_eee, setIdEEe] = useState("");
  const [descontoe, setDescontoe] = useState("");
  const [descricaoe, setDescricaoe] = useState("");
  const [data_fime, setDataFime] = useState("");
  const [data_inicioe, setDataInicioe] = useState("");
  const [status, setStatus] = useState(0);
  const [categoriase, setCategoriae] = useState([]);
  const [acoese, setAcaoe] = useState([]);
  const [numFilhose, setNumFilhose] = useState(0);

  const [selectedCategorye, setSelectedCategorye] = useState("");
  const [selectedAcaoe, setSelectedAcaoe] = useState("");

  const [erroIdAcaoe, setErroIdAcaoe] = useState(false);
  const [erroDescontoe, setErroDescontoe] = useState(false);
  const [erroDescricaoe, setErroDescricaoe] = useState(false);
  const [erroDataFime, setErroDataFime] = useState(false);
  const [erroDataInicioe, setErroDataInicioe] = useState(false);
  const [erroCategoriae, setErroCategoriae] = useState(false);
  const [erroAcaoe, setErroAcaoe] = useState(false);
  const [erroNumFilhose, setErroNumFilhose] = useState(false);

  const handleCategoryChangee = (event) => {
    setSelectedCategorye(event.target.value);
    setErroCategoriae(false);
  };

  const handleAcaoChangee = (event) => {
    setSelectedAcaoe(event.target.value);
    setErroAcaoe(false);
  };

  const [dadosOriginais, setDadosOriginais] = useState([]);

  const [carregamentoDadosCompleto, setCarregamentoDadosCompleto] =
    useState(false);

  const ref = useRef(null);
  const scrollToDiv = () => {
    setEdicao(!edicao);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const scrollToDiv2 = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //Popup cancelar edicao
  const [cancelarEdicao, setCancelarEdicao] = useState(false);
  //Popup eliminar piscina
  const [eliminar, setEliminar] = useState(false);

  const handleClickOpenPopupCancelar = () => {
    setCancelarEdicao(true);
  };

  const handleClosePopupCancelar = () => {
    setCancelarEdicao(false);
  };

  const handleClickOpenPopupEliminar = () => {
    setEliminar(true);
  };

  const handleClosePopupEliminar = () => {
    setEliminar(false);
  };

  const handleChange = (event) => {
    const novoStatus = event.target.checked ? 1 : 0;
    setStatus(novoStatus);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    const idEE = Cookies.get("id_entidade_exploradora");
    setIdPiscina(idPiscina);
    setIdEE(idEE);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({}).then((res) => {
      if (res.success) {
        let arrayFinal = [];
        res.data.data.forEach((element) => {
          const linha = {
            id: element.id_categoria,
            categoria:
              element.nome +
              "(" +
              element.descricao_idade_min +
              " a " +
              element.descricao_idade_max +
              ")",
          };
          arrayFinal.push(linha);
          setCategoriae(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });

    listaAcoes({}).then((res) => {
      if (res.success) {
        let arrayFinal = [];

        res.data.data.forEach((element) => {
          const linha = {
            id: element.id,
            acao: element.nome,
          };
          arrayFinal.push(linha);
          setAcaoe(arrayFinal);
        });
      } else {
        if (res.status == 500) {
          navigate("/500");
        } else if (res.status == 403) {
          let utilizador = Cookies.get("utilizador");
          if (utilizador == "superadmin") {
            navigate("/dashboard_superadmin");
          } else if (utilizador == "supercomercial") {
            navigate("/dashboard_supercommercial");
          } else if (utilizador == "comercial") {
            navigate("/dashboard_commercial");
          } else if (utilizador == "admin") {
            navigate("/dashboard_admin");
          } else if (utilizador == "funcionario") {
            navigate("/dashboard_employee");
          } else if (utilizador == "professor") {
            navigate("/dashboard_teacher");
          } else if (utilizador == "cliente") {
            navigate("/dashboard_customer");
          }
        }
        toast.error(res.error);
      }
    });
    if (idDesconto != "") {
      getDesconto({ id: idDesconto }).then((res) => {
        if (res.success) {
          let dados = res.data.data[0];

          setDadosOriginais(dados);

          setDescontoe(dados.desconto);
          setDescricaoe(dados.descricao);
          setDataInicioe(dados.data_inicio);
          setDataFime(dados.data_fim);
          setSelectedCategorye(dados.categoria_utilizador);
          setSelectedAcaoe(dados.acao);
          setStatus(dados.status);
          setNumFilhose(dados.filhos_conta_familiar);
          setCarregamentoDadosCompleto(true);
        } else {
          if (res.status == 500) {
            navigate("/500");
          }
          toast.error(res.error);
        }
      });
    }
  }, [idDesconto]);

  function cancelar() {
    handleClosePopupCancelar();
    setEdicao(!edicao);

    //Colocar os dados originais
    setDescontoe(dadosOriginais.desconto);
    setDescricaoe(dadosOriginais.descricao);
    setDataInicioe(dadosOriginais.data_inicio);
    setDataFime(dadosOriginais.data_fim);
    setSelectedCategorye(dadosOriginais.categoria_utilizador);
    setSelectedAcaoe(dadosOriginais.acao);
    setStatus(dadosOriginais.status);
    setNumFilhose(dadosOriginais.filhos_conta_familiar);

    //Limpar erros e mensagens de erro

    setErroIdAcaoe(false);
    setErroDescontoe(false);
    setErroDescricaoe(false);
    setErroDataFime(false);
    setErroDataInicioe(false);
    setErroCategoriae(false);
    setErroAcaoe(false);
    setErroNumFilhose(false);

    window.scrollTo(0, 0);
  }
  function editarDesconto() {
    setFreeze(true);
    editarDescontos({
      id_desconto: idDesconto,
      id_acao: selectedAcaoe,
      desconto: descontoe,
      filhos_conta_familiar: numFilhose,
      categoria_utilizador: selectedCategorye,
      data_inicio: moment(data_inicioe).format("YYYY-MM-DD"),
      data_fim: data_fime ? moment(data_fime).format("YYYY-MM-DD") : null,
      descricao: descricaoe,
      status: status,
      id_piscina: localStorage.getItem("idPiscina"),
      id_entidade_exploradora: localStorage.getItem("idEntidade"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false);
        setEdicao(!edicao);

        let linha = {
          id_desconto: idDesconto,
          id_acao: selectedAcaoe,
          desconto: descontoe,
          filhos_conta_familiar: numFilhose,
          categoria_utilizador: selectedCategorye,
          data_inicio: moment(data_inicioe).format("YYYY-MM-DD"),
          data_fim: data_fime ? moment(data_fime).format("YYYY-MM-DD") : null,
          descricao: descricaoe,
          status: status,
          id_piscina: localStorage.getItem("idPiscina"),
          id_entidade_exploradora: localStorage.getItem("idEntidade"),
        };

        setDadosOriginais(linha);
        atualizarDescontos()

        window.scrollTo(0, 0);
        toast.success(t("dadosEditadosSucesso"));
      } else {
        if (res.status == 500) {
          setFreeze(false)
          navigate("/500");
        }
        setFreeze(false)
        toast.error(res.error);
      }
    });
  }

  function validacao() {
    if (
      descontoe &&
      data_inicioe &&
      descricaoe &&
      selectedCategorye &&
      selectedAcaoe
    ) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        editarDesconto();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);

      if (!descontoe) {
        setErroDescontoe(true);
      }

      if (!data_inicio) {
        setErroDataInicioe(true);
      }
      if (!descricaoe) {
        setErroDescricaoe(true);
      }
      if (!id_acaoe) {
        setErroIdAcaoe(true);
      }
      if (!selectedCategorye) {
        setErroCategoriae(true);
      }
      if (!selectedAcaoe) {
        setErroAcaoe(true);
      }
    }
  }

  function eliminarDesconto() {
    setFreeze(true);
    eliminarDescontos({
      id_desconto: idDesconto,
    })
      .then((res) => {
        if (res.success) {
          setFreeze(false);
         
          toast.success(t("eliminarDesconto"));
          atualizarDescontos();
          handleClosePopupEliminar();
          handleCloseEditarDescontos();
          window.location.reload();
        } else {
          setFreeze(false);
          if (res.status == 500) {
            setFreeze(false)
            navigate("/500");
          } else if (res.status == 406) {
            setFreeze(false)
            toast.info(res.error);
          } else {
            setFreeze(false)
            toast.error(res.error);
          }
        }
      })
      .catch((error) => {
        setFreeze(false);
        //toast.warn(t("erroWebservice"));
      });
      
  }

  return (
    <div style={{ backgroundColor: "#171e23", overflow: "hidden" }}>
      <Nav />
      <Header />

      <div className={isMobile ? "listPagesMobile" : "listPages"}>
        <h3 className="titleList">{t("listaDescontos")}</h3>

        {/* <div className="topleftcliente">
            <IconButton   onClick={() => {
                    navigate("/dashboard_admin");
                  }}
            size={window.innerWidth < 600 ? "small" : "medium"}>
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div> */}
        <div className="divTabelaM">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleClickOpenCriarDescontos();
              }}
            ></span>

            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "62vh", maxWidth: "100%" }}>

          <DataGrid
            className={isMobile ? "tabela2" : "tabela"}
            pageSize={10}

            //Colunas
            columns={[
              {
                field: "id",
                headerName: t("id"),
                type: "number",
                width: 5,
                hide: columnsHidden.includes("id"),
              },
              { field: "nome", headerName: t("nome"), type: "string", flex: 1 },
              {
                field: "nomeacao",
                headerName: t("nomeacao"),
                flex: 1,
                type: "string",
              },
              {
                field: "status",
                headerName: t("estado"),
                maxWidth: 100,
                flex: 1,
                type: "string",

                renderCell: (params) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {params.row.status == 1 ? (
                        <>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: 500,
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                          >
                            {t("ativo")}
                          </p>
                          <FontAwesomeIcon
                            color={Colors.ativo}
                            fontSize={25}
                            icon={faCircleCheck}
                          />
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: 500,
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                          >
                            {t("inativo")}
                          </p>{" "}
                          <FontAwesomeIcon
                            color={Colors.inativo}
                            fontSize={25}
                            icon={faCircleXmark}
                          />
                        </>
                      )}
                    </div>
                  );
                },
              },
              {
                field: "datainicio",
                headerName: t("datainicio"),
                flex: 1,
                type: "string",
                hide: columnsHidden.includes("datainicio"),
              },
              {
                field: "datafim",
                headerName: t("datafim"),
                flex: 1,
                type: "string",
                hide: columnsHidden.includes("datafim"),
              },
              {
                field: "actions",
                type: "actions",
                headerName: t("acoes"),
                width: 70,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  return [
                    <GridActionsCellItem
                      icon={
                        <FontAwesomeIcon
                          style={{ fontSize: isMobile ? 12 : 18 }}
                          icon={faPen}
                        />
                      }
                      label="Save"
                      onClick={() => {
                        setIdDesconto(id);
                        handleClickOpenEditarDescontos();
                      }}
                    />,
                  ];
                },
              },
            ]}
            rows={descontos}
            checkboxSelection={isMobile ? false : true}
            onRowClick={(params) => {}}
            getRowId={(row) => row.id}
            //Idioma
            localeText={
              i18n.language == "pt"
                ? ptBR.components.MuiDataGrid.defaultProps.localeText
                : i18n.language == "es"
                ? esES.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
            components={{
              Toolbar: CustomToolbar,
              BaseCheckbox: ({ value, ...otherProps }) => (
                <Checkbox size="small" checked={value} {...otherProps} />
              ),
            }}
          />
          </div>

          {/* adicionar desconto */}
          <Dialog
            open={openCriarDescontos}
            onClose={handleCloseCriarDescontos}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "90%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("adicionarDesconto")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                  zIndex: 2000,
                }}
                onClick={handleCloseCriarDescontos}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}

              <div
                className="formPop"
                style={{ marginTop: 30, marginInline: isMobile ? 0 : {} }}
              >
                <Scrollbar style={{ height: 400 }}>
                  <Box className="formTextField">
                    <div>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("desconto") + " (%)"}
                          value={desconto}
                          error={erroDesconto}
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            // Verifica se o valor é maior que 100
                            if (inputValue > 100) {
                              inputValue = 100; // Define o valor como 100
                            }
                            setDesconto(inputValue);
                            setErroDesconto(false);
                          }}
                        />
                      </FormControl>

                      <FormControl className="formTextField3" fullWidth>
                        <TextField
                          style={{ textAlign: "left" }}
                          variant="outlined"
                          multiline
                          rows={2}
                          value={descricao}
                          label={t("observacoes")}
                          error={erroDescricao}
                          onChange={(e) => {
                            setDescricao(e.target.value);
                            setErroDescricao(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <TextField
                          required
                          type="number"
                          variant="standard"
                          label={t("numfilhos")}
                          value={numFilhos}
                          error={erroNumFilhos}
                          onChange={(e) => {
                            const value = e.target.value;

                            setNumFilhos(value);
                            setErroNumFilhos(false);
                          }}
                        />
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoria}
                        >
                          {t("escolhaacao")}*
                        </InputLabel>
                        <Select
                          value={selectedAcao}
                          onChange={handleAcaoChange}
                          error={erroAcao}
                          fullWidth
                          variant="standard"
                        >
                          {acao.map((acao) => (
                            <MenuItem key={acao.id} value={acao.id}>
                              {acao.acao}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <InputLabel
                          className="formTextField22"
                          error={erroCategoria}
                        >
                          {t("escolhacategoria")}*
                        </InputLabel>
                        <Select
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          error={erroCategoria}
                          fullWidth
                          variant="standard"
                        >
                          {categorias.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.categoria}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            label={t("datainicio")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_inicio}
                            onChange={(e) => {
                              if (e) {
                                setDataInicio(e);
                                setErroDataInicio(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataInicio}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl className="formTextField2" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <DatePicker
                            label={t("datafim")}
                            openTo="day"
                            views={["day", "year", "month"]}
                            value={data_fim}
                            onChange={(e) => {
                              if (e) {
                                setDataFim(e);
                                setErroDataFim(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                required
                                error={erroDataFim}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </Box>
                </Scrollbar>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className="btnEdicao"
                variant="contained"
                style={{
                  backgroundImage: isMobile
                    ? `url(${editarbtnpeq})`
                    : `url(${editarbtn})`,
                  width: isMobile ? 153 : 315,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onClick={() => {
                  validacao1();
                }}
              >
                {t("adicionar")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* editar desconto */}

          <Dialog
            open={openEditarDescontos}
            onClose={handleCloseEditarDescontos}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                minWidth: isMobile ? "95%" : "50%",
                height: "75%",
                borderRadius: "10px",
              },
            }}
            style={{ width: "100%" }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("editarDados")}
              <FontAwesomeIcon
                edge="end"
                color="inherit"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={handleCloseEditarDescontos}
                aria-label="Fechar"
                icon={faClose}
              />
            </DialogTitle>
            <DialogContent>
              {freeze && (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <Dialog
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <DialogContent>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("aguarde")}
                      </Typography>
                      <CircularProgress
                        disableShrink
                        style={{ marginTop: 20 }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {carregamentoDadosCompleto ? (
                <div className="formPop" style={{ marginTop: 30 }}>
                  <Scrollbar style={{ height: 400 }}>
                    <Box className="formTextField">
                      <div>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            required
                            type="number"
                            variant="standard"
                            label={t("desconto") + " (%)"}
                            value={descontoe}
                            error={erroDescontoe}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              // Verifica se o valor é maior que 100
                              if (inputValue > 100) {
                                inputValue = 100; // Define o valor como 100
                              }
                              setDescontoe(e.target.value);
                              setErroDescontoe(false);
                            }}
                          />
                        </FormControl>

                        <FormControl
                          className="formTextField2"
                          fullWidth
                          sx={{ marginTop: 2 }}
                        >
                          <TextField
                            disabled={edicao}
                            style={{ textAlign: "left" }}
                            variant="outlined"
                            multiline
                            rows={2}
                            value={descricaoe}
                            label={t("observacoes")}
                            error={erroDescricaoe}
                            onChange={(e) => {
                              setDescricaoe(e.target.value);
                              setErroDescricaoe(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <FormControlLabel
                            disabled={edicao}
                            control={
                              <Switch
                                className="switch"
                                checked={status}
                                onChange={handleChange}
                                name="checked"
                                color="primary"
                              />
                            }
                            label={status ? t("ativo") : t("inativo")}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroCategoria}
                          >
                            {t("escolhacategoria")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedCategorye}
                            onChange={handleCategoryChangee}
                            error={erroCategoriae}
                            fullWidth
                            variant="standard"
                          >
                            {categoriase.map((categoria) => (
                              <MenuItem key={categoria.id} value={categoria.id}>
                                {categoria.categoria}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl className="formTextField2" fullWidth>
                          <InputLabel
                            className="formTextField22"
                            error={erroAcao}
                          >
                            {t("escolhaacao")}*
                          </InputLabel>
                          <Select
                            disabled={edicao}
                            value={selectedAcaoe}
                            onChange={handleAcaoChangee}
                            error={erroAcaoe}
                            fullWidth
                            variant="standard"
                          >
                            {acoese.map((acao) => (
                              <MenuItem key={acao.id} value={acao.id}>
                                {acao.acao}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <TextField
                            disabled={edicao}
                            type="number"
                            variant="standard"
                            label={t("numfilhos")}
                            value={numFilhose}
                            error={erroNumFilhose}
                            onChange={(e) => {
                              setNumFilhose(e.target.value);
                              setErroNumFilhose(false);
                            }}
                          />
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disabled={edicao}
                              label={t("datainicio")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={data_inicioe}
                              onChange={(e) => {
                                setDataInicioe(e);
                                setErroDataInicioe(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  required
                                  error={erroDataInicioe}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl className="formTextField2" fullWidth>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              disabled={edicao}
                              label={t("datafim")}
                              openTo="day"
                              views={["day", "year", "month"]}
                              value={data_fime}
                              onChange={(e) => {
                                setDataFime(e);
                                setErroDataFime(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={erroDataFime}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                    </Box>
                  </Scrollbar>

                  {/* Cancelar edição */}
                  <Dialog
                    open={cancelarEdicao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        width: 452,
                        height: 186,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {t("cancelarEdicao")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("textoCancelarEdicao")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          cancelar();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupCancelar();
                        }}
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Eliminar */}
                  <Dialog
                    open={eliminar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("eliminarDesconto")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("eliminarRegisto")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${simbtn})`,
                          width: 218,
                          height: 40,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          eliminarDesconto();
                        }}
                      >
                        {t("sim")}
                      </Button>
                      <Button
                        className="btnSim"
                        variant="contained"
                        style={{
                          backgroundImage: `url(${naobtn})`,
                          width: 198,
                          height: 30,
                          cursor: "pointer",
                          zIndex: 200,
                        }}
                        onClick={() => {
                          handleClosePopupEliminar();
                        }}
                        autoFocus
                      >
                        {t("nao")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    zIndex: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: 0,
                  }}
                >
                  <CircularProgress disableShrink />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "-20px",
              }}
            >
              {edicao ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${editarbtnpeq})`
                        : `url(${editarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      scrollToDiv();
                    }}
                  >
                    <PencilIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("editar")}
                  </Button>

                  <Button
                    className="btnEdicao"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${eliminarbtnpeq})`
                        : `url(${eliminarbtn})`,
                      width: isMobile ? 153 : 315,
                      height: 40,
                      cursor: "pointer",
                      zIndex: 200,
                    }}
                    onClick={() => {
                      handleClickOpenPopupEliminar();
                    }}
                  >
                    <TrashIcon
                      style={{
                        width: "15px",
                        color: "white",
                        marginRight: "10px",
                      }}
                    />

                    {t("eliminar")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${guardarbtnPeq})`
                        : `url(${guardarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      validacao();
                    }}
                  >
                    {t("guardar")}
                  </Button>
                  <Button
                    className="btnGuardar"
                    variant="contained"
                    style={{
                      backgroundImage: isMobile
                        ? `url(${cancelarbtnPeq})`
                        : `url(${cancelarbtn})`,

                      height: 40,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      handleClickOpenPopupCancelar();
                    }}
                  >
                    {t("cancelar")}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ListaDescontosA;
