import {
  faArrowCircleLeft,
  faArrowLeft,
  faCircleInfo,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import Header from "../../components/Header";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Colors } from "../../values/Colors";
import { toast } from "react-toastify";
import { adicionarPacks } from "../../api/requests/Packs";
import { listaCategoriasUtilizador } from "../../api/requests/CategoriaUtilizador";
import { listaPiscinasAgua } from "../../api/requests/PiscinaAgua";
import { adicionarSI } from "../../api/requests/SeguroInscricao";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import moment from "moment";

function CriarSeguroInscricao() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  const [freeze, setFreeze] = useState(false);

  const [seguro, setSeguro] = useState(0);
  const [inscricao, setInscricao] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [idPiscina, setPiscina] = useState("");
  const [dataExpiraS, setDataExpiraS] = useState("");
  const [dataExpiraI, setDataExpiraI] = useState("");

  const [erroSeguro, setErroSeguro] = useState("");
  const [erroInscricao, setErroInscricao] = useState("");
  const [erroCategoria, setErroCategoria] = useState("");
  const [erroPiscina, setErroPiscina] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [erroDataExpiraS, setErroDataExpiraS] = useState("");
  const [erroDataExpiraI, setErroDataExpiraI] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setErroCategoria(false);
  };

  useEffect(() => {
    // Definir background para esta página
    document.body.style = `background: ${Colors.backgroundColorLists}`;
    // Verificacoes
    const id = Cookies.get("uId");
    const token = Cookies.get("token");
    const dadosIncompletos = Cookies.get("uData");
    const idPiscina = Cookies.get("id_piscina");
    setPiscina(idPiscina);

    //Caso não tenha id nem token
    if (!id || !token) {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      Cookies.remove("token");
      Cookies.remove("refresh_token");
      Cookies.remove("uId");
      Cookies.remove("uName");
      Cookies.remove("uEmail");
      Cookies.remove("uKey");
      Cookies.remove("uData");
      toast.info(t("sessaoExpirada"));
    } else {
      //Caso os dados estejam incompletos
      if (dadosIncompletos === "false") {
        navigate("/change_user_data");
        toast.info(t("preencherDadosObg"));
      }
    }

    listaCategoriasUtilizador({})
      .then((res) => {
        if (res.success) {
          let arrayFinal = [];
          res.data.data.forEach((element) => {
            const linha = {
              id: element.id_categoria,
              categoria:
                element.nome +
                "(" +
                element.descricao_idade_min +
                " a " +
                element.descricao_idade_max +
                ")",
            };
            arrayFinal.push(linha);
            setCategoria(arrayFinal);
          });
        } else {
          if (res.status == 500) {
            navigate("/500");
          } else if (res.status == 403) {
            let utilizador = Cookies.get("utilizador");
            if (utilizador == "superadmin") {
              navigate("/dashboard_superadmin");
            } else if (utilizador == "supercomercial") {
              navigate("/dashboard_supercommercial");
            } else if (utilizador == "comercial") {
              navigate("/dashboard_commercial");
            } else if (utilizador == "admin") {
              navigate("/dashboard_admin");
            } else if (utilizador == "funcionario") {
              navigate("/dashboard_employee");
            } else if (utilizador == "professor") {
              navigate("/dashboard_teacher");
            } else if (utilizador == "cliente") {
              navigate("/dashboard_customer");
            }
          }
          toast.error(res.error);
        }
      })
      .catch((error) => {
        //toast.warn(t("erroWebservice"));
      });
  }, []);

  function adicionarSegI() {
    setFreeze(true);
    adicionarSI({
      inscricao: inscricao,
      id_piscina: idPiscina,
      categoria: selectedCategory,
      seguro: seguro,
      data_seguro: moment(dataExpiraS).format("YYYY-MM-DD"),
      data_inscricao: moment(dataExpiraI).format("YYYY-MM-DD"),
    }).then((res) => {
      if (res.success) {
        setFreeze(false)
        toast.success(t("SIAdicionar"));
        navigate("/silist");
      } else {
        setFreeze(false);
        toast.error(res.error);
      }
    });
  }

  function validacao1() {
    if (inscricao && seguro && selectedCategory && dataExpiraS && dataExpiraI) {
      let confirmarDadosPreenchidos = true;

      if (confirmarDadosPreenchidos) {
        //Scroll para o início da página
        window.scrollTo(0, 0);
        adicionarSegI();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      //Scroll para o início da página
      window.scrollTo(0, 0);

      //Validação do nome
      if (inscricao == 0) {
        setErroInscricao(true);
      }
      if (!selectedCategory) {
        setErroCategoria(true);
      }
      if (seguro == 0) {
        setErroSeguro(true);
      }
      if (!dataExpiraI) {
        setErroDataExpiraI(true);
      }
      if (!dataExpiraS) {
        setErroDataExpiraS(true);
      }
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguarde")}
              </Typography>
              <CircularProgress disableShrink style={{ marginTop: 20 }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <div className="divForm">
        <div className="containerForm">
          <div className="topleftcliente">
            <IconButton
              onClick={() => {
                navigate("/silist");
              }}
              size={isMobile ? "small" : "medium"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
          </div>
          <div className="align-right">
            {/* <h3 className="titleList">{t("adicionarSi")}</h3> */}
          </div>
        </div>
        <div>
          <div className="formPage">
            <Box className="formTextField">
              <div>
                <FormControl className="formTextField2" fullWidth>
                  <InputLabel className="formTextField22" error={erroCategoria}>
                    {t("escolhacategoria")}*
                  </InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    error={erroCategoria}
                    fullWidth
                    variant="standard"
                  >
                    {categorias.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.categoria}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={`${t("seguro")} (€)`}
                    value={seguro}
                    error={erroSeguro}
                    onChange={(e) => {
                      const value = e.target.value;

                      setSeguro(value);
                      setErroSeguro(false);
                    }}
                  />
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <TextField
                    required
                    type="number"
                    variant="standard"
                    label={`${t("inscricao")} (€)`}
                    value={inscricao}
                    error={erroInscricao}
                    onChange={(e) => {
                      const value = e.target.value;

                      setInscricao(value);
                      setErroInscricao(false);
                    }}
                  />
                </FormControl>

                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("dataexpiras")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={dataExpiraS}
                      onChange={(e) => {
                        if (e) {
                          setDataExpiraS(e.$d);
                          setErroDataExpiraS(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataExpiraS}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl className="formTextField2" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      label={t("dataexpirai")}
                      openTo="day"
                      views={["day", "year", "month"]}
                      value={dataExpiraI}
                      onChange={(e) => {
                        if (e) {
                          setDataExpiraI(e.$d);
                          setErroDataExpiraI(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          error={erroDataExpiraI}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>
            </Box>
          </div>
        </div>

        <div className="buttonAdicionar">
          <Button
            variant="contained"
            onClick={() => {
              validacao1();
            }}
          >
            {t("adicionar")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CriarSeguroInscricao;
